import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { MutationFunction, isApolloError } from '@apollo/client';
import { Errors } from '../../Shared/Errors';
import {
  AuthApiResponse,
  AuthPayload,
  AuthStatus,
  getAuthPayload,
} from '../../App/Auth';

export const LOGIN_WITH_TOKEN_MUTATION = gql`
  mutation LoginWithToken($token: String!, $isMobile: Boolean) {
    loginWithToken(token: $token, isMobile: $isMobile) {
      id
      token
      iris_token
      email
      firstName
      lastName
      createdAt
      groupId
      groupName
      clientTier
      subdomain
      praiseDirectory {
        value
      }
      departmentId
      departmentName
      locationId
      locationName
      userType {
        name
      }
      permissions {
        value
      }
      abTestCohorts
    }
  }
`;

export interface WithLoginWithTokenProps {
  loginWithToken(variables: LoginWithTokenVariables): Promise<AuthPayload>;
}

export interface LoginWithTokenVariables {
  token: string;
  isMobile?: boolean;
}

export interface LoginWithTokenMutationResponse {
  loginWithToken: AuthApiResponse;
}

export const loginWithToken = async (
  variables: LoginWithTokenVariables,
  mutate?: MutationFunction<
    LoginWithTokenMutationResponse,
    LoginWithTokenVariables
  >,
): Promise<AuthPayload> => {
  try {
    if (mutate) {
      const response = await mutate({
        variables: variables,
      });

      if (response) {
        if (response.data && response.data.loginWithToken) {
          return getAuthPayload(response.data.loginWithToken);
        }
      }
    }
  } catch (error) {
    if (error instanceof Error && isApolloError(error)) {
      const errorType =
        error.graphQLErrors[0] !== undefined ? error.graphQLErrors[0].name : '';

      let errorTypeToReturn = Errors.ServerError;

      if (errorType === Errors[Errors.InvalidCredentialsError]) {
        errorTypeToReturn = Errors.InvalidCredentialsError;
      }

      if (errorType === Errors[Errors.TokenExpiredError]) {
        errorTypeToReturn = Errors.TokenExpiredError;
      }

      return {
        error: errorTypeToReturn,
        status: AuthStatus.ERROR,
      };
    }
  }

  return {
    error: Errors.ServerError,
    status: AuthStatus.ERROR,
  };
};

export const withLoginWithToken = graphql<
  Record<string, unknown>,
  LoginWithTokenMutationResponse,
  LoginWithTokenVariables,
  WithLoginWithTokenProps
>(LOGIN_WITH_TOKEN_MUTATION, {
  options: {
    context: {
      timeout: 15000,
    },
  },
  props: ({ mutate }) => ({
    loginWithToken: async (variables: LoginWithTokenVariables) =>
      loginWithToken(variables, mutate),
  }),
});
