import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelledCheckbox, { LabelledCheckboxProps } from './LabelledCheckbox';

const ExplicitConsentCheckbox = (props: LabelledCheckboxProps) => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <LabelledCheckbox {...props} data-testid="sign-up-explicit-consent">
      {translate('sign_up.forms.privacy_consent.checkbox_label')}
    </LabelledCheckbox>
  );
};

export default ExplicitConsentCheckbox;
