import { Accordion, BodyText } from '@unmind/design-system-components-web';
import { Namespace } from 'i18next';
import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';
import { Link } from 'react-router-dom';
import RoutePath from 'App/RoutePath';
import { LEARN_MORE_ANCHOR } from './CompassHeader';

const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(24)};
  margin-top: ${rem(64)};
`;

const FAQContent = styled.div`
  white-space: pre-line;
  padding: ${rem(16)};
  line-height: 150%;
`;

const FAQHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  text-align: left;
`;

const FaqContainer = styled.div`
  margin-bottom: ${rem(120)};
  max-width: ${rem(700)};
  transition: scroll-behavior 200ms ease;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const FaqImage = styled.img<{ $widthPerc: number }>`
  width: ${({ $widthPerc }) => `${$widthPerc}%`};
`;

const faqKeys = [
  {
    question: 'faq-1.question',
    answer: 'faq-1.answer',
    images: [],
  },
  {
    question: 'faq-2.question',
    answer: 'faq-2.answer',
    images: [],
  },
  {
    question: 'faq-3.question',
    answer: 'faq-3.answer',
    images: [],
  },
  {
    question: 'faq-4.question',
    answer: 'faq-4.answer',
    images: [],
  },
  {
    question: 'faq-5.question',
    answer: 'faq-5.answer',
    images: [],
  },
  {
    question: 'faq-6.question',
    answer: 'faq-6.answer',
    images: [],
  },
  {
    question: 'faq-7.question',
    answer: 'faq-7.answer',
    images: ['CompassFaq/dashboard-preview'],
  },
  {
    question: 'faq-8.question',
    answer: 'faq-8.answer',
    images: ['CompassFaq/action-1', 'CompassFaq/action-2'],
  },
  {
    question: 'faq-9.question',
    answer: 'faq-9.answer',
    images: [],
  },
  {
    question: 'faq-10.question',
    answer: 'faq-10.answer',
    images: [],
  },
  {
    question: 'faq-11.question',
    answer: 'faq-11.answer',
    images: [],
  },
  {
    question: 'faq-12.question',
    answer: 'faq-12.answer',
    images: [],
  },
  {
    question: 'faq-13.question',
    answer: 'faq-13.answer',
    images: [],
  },
] as const;

type FAQKey =
  | typeof faqKeys[number]['question']
  | typeof faqKeys[number]['answer'];

export const CompassFAQs = () => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');
  const imagesToTranslate = faqKeys
    .filter(({ images }) => images.length > 0)
    .map(({ images }) => images)
    .flat();

  const imageSrcs = useTranslatedAssets(imagesToTranslate, 'png');

  const items = faqKeys.map(({ question, answer, images }) => ({
    header: (
      <FAQHeader>
        {translate(`onboarding.faqs.${question as FAQKey}`)}
      </FAQHeader>
    ),
    content: (
      <FAQContent>
        <Trans
          t={translate}
          i18nKey={`onboarding.faqs.${answer as FAQKey}`}
          components={{
            value_of_compass_link: <StyledLink to={RoutePath.ValueOfCompass} />,
            ordered_list: <ol />,
            unordered_list: <ul />,
            list_item: <li />,
            faq_images: (
              <div>
                {images.map(imageName => (
                  <FaqImage
                    $widthPerc={100 / images.length}
                    src={imageSrcs[imageName]}
                    key={uuid()}
                    aria-hidden={true}
                  />
                ))}
              </div>
            ),
          }}
        />
      </FAQContent>
    ),
  }));

  return (
    <FaqContainer id={LEARN_MORE_ANCHOR}>
      <Title>{translate('onboarding.faqs.title')}</Title>
      <Accordion items={items} />
    </FaqContainer>
  );
};
