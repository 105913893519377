import { hideVisually, rem } from 'polished';
import React, { InputHTMLAttributes } from 'react';
import { styled, ThemeInterface } from 'styles';
import { Tick } from 'icons';
import { BodyText } from '../../Shared/Typography';
import getFocusState from '../../Shared/Button/getFocusState';

export const CheckboxLabel = styled(BodyText).attrs(
  ({
    theme,
    smallTextSize,
  }: {
    theme: ThemeInterface;
    smallTextSize: boolean;
  }) => ({
    color: theme.colors.text.secondary,
    sizes: smallTextSize
      ? [theme.typography.fontSizes.fontSize14]
      : [theme.typography.fontSizes.fontSize16],
  }),
)<{ smallTextSize: boolean }>`
  flex: 1;
  text-align: left;
  margin-left: ${rem(12)};
  font-weight: 500;
`;

const CheckboxButton = styled.button<{ rounded?: boolean }>`
  width: ${rem('24px')};
  height: ${rem('24px')};
  border-width: ${rem('1px')};
  border-color: ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: ${props => (props.rounded ? rem('4px') : rem('2px'))};
  border: solid 1px ${({ theme }) => theme.colors.border.secondary};
  padding: 0;
  margin: 0;

  &:hover {
    border-color: ${({ theme }) => theme.colors.border.secondary};
  }

  :focus {
    ${getFocusState()}
  }
`;

const CheckboxInput = styled.input`
  ${hideVisually()};
`;

const TickIcon = styled(Tick).attrs(({ theme }: { theme: ThemeInterface }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem('22px')};
  height: ${rem('22px')};
  border-radius: ${rem('1px')};
  background-color: ${({ theme }) => theme.colors.brand.primary};
  padding: ${rem(3)};
`;

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  setFieldValue?: Function;
  rounded?: boolean;
}

export const Checkbox = (props: CheckboxProps) => {
  const { setFieldValue, value, checked, name } = props;
  const { id, rounded, ...inputProps } = props;

  return (
    <React.Fragment>
      <CheckboxButton
        rounded={rounded}
        data-testid="faux-checkbox"
        onClick={() => setFieldValue && setFieldValue(name, !value)}
        aria-labelledby={id}
        role="checkbox"
        aria-checked={Boolean(checked)}
        type="button"
      >
        {Boolean(checked) && <TickIcon />}
      </CheckboxButton>
      <CheckboxInput type="checkbox" {...inputProps} />
    </React.Fragment>
  );
};
