import { Formik } from 'formik';
import { rem } from 'polished';
import queryString from 'query-string';
import * as moment from 'moment-timezone';
import React, { ReactNode, useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import type { ApolloClient } from '@apollo/client';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { isNil } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Wand } from 'icons';
import { Helmet } from 'react-helmet-async';
import { isMSTeams } from 'utils/MSTeams';
import {
  TextInput,
  SecureTextInput,
  Loader,
  Error,
  small,
  BodyText,
} from '@unmind/design-system-components-web';
import { setStoredValue } from 'utils/storageHelpers';
import { StorageKey, useStorage } from 'utils/useStorage';
import { useRedirectToSubdomain } from 'utils/useRedirectToSubdomain';
import { encodeQueryParams, UrlQueryParams } from 'utils/urlHelpers';
import { LocalStorageKey } from 'utils/localStorage';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { validateEmail } from '../../Shared/Form/Formik';
import {
  NewAuthWrapperBlock,
  AuthWrapper,
  AuthWrapperTitle,
} from '../AuthWrapper';
import {
  StyledForm,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { configureSentryUserScope } from '../../App/logging';
import withFeatureFlagUserContext from '../../flags/withFeatureFlagUserContext';
import { WithFeatureFlagUserContextProps } from '../../flags/types';
import withUpdateUser, {
  WithUpdateUserProps,
} from '../../Account/withUpdateUser';
import {
  AuthPayload,
  AuthStatus,
  LoginAction,
  authStatusMap,
} from '../../App/Auth';
import { Errors } from '../../Shared/Errors';
import {
  withConfirmAndAuthUser,
  WithConfirmAndAuthUserProps,
} from '../withConfirmAndAuthUser';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import { identifyBrazeUserAndMergeProfiles } from '../../App/braze/identifyBrazeUser';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from '../../utils/getSubdomainFromUrl';
import SecondaryButton from '../../Shared/SecondaryButton';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import {
  withLoginWithToken,
  WithLoginWithTokenProps,
} from './withLoginWithToken';
import { LoginVariables, useLogin } from './useLogin';

const WandIcon = styled(Wand)`
  width: ${rem(17)};
  height: ${rem(17)};
  margin-right: ${rem(8)};
  flex-shrink: 0;
`;

const MagicLinkButton = styled(SecondaryButton)`
  width: 100%;
`;

const StyledSSOButton = styled(SecondaryButton)`
  width: 100%;
  margin-top: ${rem(24)};
`;

const InputContainer = styled.div`
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};

  ${small(css`
    margin-top: ${rem(24)};
    margin-bottom: ${rem(16)};
  `)}
`;

const ForgotPasswordLink = styled(Link)`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(48)};
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const PasswordLinkContainer = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(24)};

  ${small(css`
    margin-bottom: ${rem(48)};
  `)}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MagicLinkButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FindYourOrgText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-top: ${rem(24)};
`;

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

interface HandleAuthResponseProps {
  response: AuthPayload;
  isFirstLogin: boolean;
  loginAction: LoginAction;
}

interface FormValues {
  emailAddress: string;
  password: string;
}

export interface LoginViaUsernamePasswordProps
  extends RouteComponentProps,
    WithFeatureFlagUserContextProps,
    WithLoginWithTokenProps,
    WithConfirmAndAuthUserProps,
    WithUpdateUserProps {
  client: ApolloClient<any>;
}

export function LoginViaUsernamePassword(props: LoginViaUsernamePasswordProps) {
  const subdomainFromUrl = getSubdomainFromUrl();
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const isClientSubdomainLogin = isClientSubdomain(subdomainFromUrl);

  const {
    group,
    ssoProviderName,
    loading: subdomainInfoLoading,
    groupId,
  } = useSubdomainInfo({
    subdomain: subdomainFromUrl,
    skip: !isClientSubdomainLogin,
  });

  const [loginRedirect, setLoginRedirect] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const emailCookie = useStorage(StorageKey.EMAIL);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [tokenOrRidLoginLoading, setTokenOrRidLoginLoading] = useState(false);
  const userTimezone = moment.tz.guess();
  const history = useHistory();
  const { redirectToSubdomain } = useRedirectToSubdomain();

  const partner = group?.isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null;

  const trackLoginFailed = () =>
    tracking.track('login-failed', { subdomain: subdomainFromUrl });

  const handleMagicLoginError = (error?: Errors) => {
    trackLoginFailed();
    switch (error) {
      case Errors.InvalidCredentialsError:
      case Errors.TokenExpiredError:
        setLoginError(translate('login.errors.magic_link_invalid'));
        break;
      case Errors.NotFoundError:
        setLoginError(translate('login.errors.user_not_found'));
        break;
      default:
        setLoginError(translate('login.errors.default_login_error'));
    }
  };

  const handleLoginError = (error?: string) => {
    trackLoginFailed();
    switch (error) {
      case Errors.InvalidCredentialsError:
        setLoginError(translate('login.errors.invalid_credentials'));
        break;

      case Errors.AccountLockedError:
        setLoginError(translate('login.errors.account_locked'));
        break;

      default:
        setLoginError(translate('login.errors.default_login_error'));
    }
  };
  const clientSubdomain = isClientSubdomain(subdomainFromUrl);
  const { login, loading } = useLogin(handleLoginError, !clientSubdomain);

  const identifyLoggedInUser = (
    response: AuthPayload,
    callback: () => void,
  ) => {
    if (response.status === AuthStatus.ERROR) {
      return;
    }

    const { userType, permissions, isLineManager } = response;
    const traits = {
      activationDate: response.activationDate,
      createdAt: response.createdAt,
      uuid: response.id,
      groupId: response.groupId,
      client: response.groupName,
      clientTier: response.clientTier,
      subdomain: response.subdomain,
      praiseDirectoryEnabled: Boolean(Number(response.praiseDirectory?.value)),
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      department: response.departmentName,
      departmentId: response.departmentId,
      location: response.locationName,
      locationId: response.locationId,
      userType: userType && userType.name ? userType.name : undefined,
      accessType:
        permissions && permissions.value
          ? permissions.value.toLowerCase()
          : undefined,
      timezone: userTimezone,
      partner,
      identifiedAsLineManager: isLineManager,
    };

    configureSentryUserScope({
      id: response.id,
      subdomain: response.subdomain,
    });

    const userIdForTracking = response.id;

    tracking.identifyUser({
      userId: userIdForTracking,
      traits,
      callback,
    });
  };

  /** When logging in on web from search page
   * we need to pass auth tokens in query string
   * because local storage is not shared between subdomains
   */
  const constructAuthTokenQueryString = (token: string, irisToken?: string) =>
    encodeQueryParams({
      [UrlQueryParams.TOKEN]: token,
      [UrlQueryParams.IRIS_TOKEN]: irisToken,
      [UrlQueryParams.SEARCH_REDIRECT]: 'true',
    });

  const redirectAuthenticatedUser = async (
    {
      subdomain,
      token,
      irisToken,
    }: {
      subdomain: string;
      token: string;
      irisToken?: string;
    },
    isFirstLogin?: boolean,
  ) => {
    if (clientSubdomain) {
      if (!isNil(loginRedirect)) {
        props.history.push(loginRedirect);
      } else {
        props.history.push(RoutePath.Home, { isFirstLogin });
      }
    } else {
      setRedirectLoading(true);
      redirectToSubdomain({
        subdomain,
        routePath: RoutePath.Home,
        queryString: constructAuthTokenQueryString(token, irisToken),
      });
    }
  };

  /** We store user auth tokens in local storage
   * unless we are logging in on web from search page
   * without subdomain (i.e. subdomainless login)
   */
  const storeUserAuthTokens = (token: string, irisToken?: string) => {
    if (clientSubdomain || isMSTeams()) {
      localStorage.setItem(LocalStorageKey.TOKEN, token);

      if (irisToken) {
        localStorage.setItem(LocalStorageKey.IRIS_TOKEN, irisToken);
      }
    }
  };

  const handleAuthSuccess = async ({
    response,
    isFirstLogin,
    loginAction,
  }: HandleAuthResponseProps) => {
    if (
      response.status !== AuthStatus.DEGRADED &&
      response.status !== AuthStatus.SUCCESS
    ) {
      return;
    }

    const { status, token, id, userTraits, subdomain } = response;

    const irisToken =
      status === AuthStatus.SUCCESS ? response.iris_token : undefined;

    storeUserAuthTokens(token, irisToken);

    identifyLoggedInUser(response, () => {
      tracking.track('login-successful', {
        subdomain,
        isFirstLogin,
        loginAction,
        type: authStatusMap[AuthStatus.SUCCESS],
      });
    });

    void props.identifyFeatureFlagUser?.({
      userId: id,
      subdomain,
      userTraits,
    });

    await identifyBrazeUserAndMergeProfiles(props.client, id);

    await props.updateUser({
      timezone: userTimezone,
    });

    await redirectAuthenticatedUser(
      { subdomain, token, irisToken },
      isFirstLogin,
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const { location, loginWithToken, confirmAndAuthUser } = props;
      const { search } = location;
      const query = queryString.parse(search);
      const { token, redirect, rid } = query;

      if (!(isNil(redirect) || Array.isArray(redirect))) {
        setLoginRedirect(redirect);
      }

      if (Array.isArray(token) || Array.isArray(rid)) {
        return;
      }

      if (!isNil(token) || !isNil(rid)) {
        let response: AuthPayload;

        if (!isNil(token) && token.length > 0) {
          setTokenOrRidLoginLoading(true);
          response = await loginWithToken({ token });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);
            handleMagicLoginError(response.error);

            return;
          }

          await handleAuthSuccess({
            response,
            isFirstLogin: false,
            loginAction: LoginAction.MAGIC_LINK,
          });

          return;
        }

        if (!isNil(rid) && rid.length > 0) {
          setTokenOrRidLoginLoading(true);

          response = await confirmAndAuthUser({ rid });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);
            handleMagicLoginError(response.error);

            return;
          }

          await handleAuthSuccess({
            response,
            isFirstLogin: true,
            loginAction: LoginAction.EMAIL_CONFIRMATION,
          });

          return;
        }

        setLoginError(translate('login.errors.default_login_error'));
      }
    })();
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { emailAddress, password } = values;

    if (!emailAddress || !password) {
      return;
    }

    setLoginError(null);
    setStoredValue({
      key: StorageKey.EMAIL,
      value: emailAddress,
    });

    const loginVariables: LoginVariables = {
      emailAddress,
      password,
      ...(clientSubdomain && { groupId }),
    };

    const response = await login(loginVariables);
    if (response) {
      await handleAuthSuccess({
        response,
        isFirstLogin: false,
        loginAction: LoginAction.DIRECT_LOGIN,
      });
    }
  };

  const onMagicLinkClicked = () => {
    tracking.track('magic-link-clicked', { subdomain: subdomainFromUrl });
    history.push(RoutePath.MagicLogin);
  };

  const magicLinkButtonContents = (
    <MagicLinkButtonContents>
      <WandIcon />
      <div>{translate('login.magic_link')}</div>
    </MagicLinkButtonContents>
  );

  const initialValues = {
    emailAddress: emailCookie || '',
    password: '',
  };

  return (
    <>
      <Helmet
        meta={[
          {
            name: `robots`,
            content: 'noindex',
          },
        ]}
      />
      <AuthWrapper newDesign={true}>
        <NewAuthWrapperBlock>
          {tokenOrRidLoginLoading || subdomainInfoLoading || redirectLoading ? (
            <Container data-testid="loading-indicator">
              <Loader data-testid="loading-indicator" />
            </Container>
          ) : (
            <>
              <AuthWrapperTitle>{translate('login.title')}</AuthWrapperTitle>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={async ({ emailAddress, password }) => {
                  tracking.track('login-button-clicked', {
                    subdomain: subdomainFromUrl,
                  });

                  return onSubmit({
                    emailAddress,
                    password,
                  });
                }}
                validate={({ emailAddress, password }) => {
                  const emailAddressError = validateEmail(emailAddress);
                  if (emailAddressError) {
                    return { emailAddress: emailAddressError };
                  }

                  if (!password) {
                    return { password: true };
                  }

                  return {};
                }}
              >
                {({
                  isValid,
                  touched,
                  setTouched,
                  setFieldValue,
                  errors,
                  values,
                }) => (
                  <StyledForm>
                    <InputContainer>
                      <TextInput
                        autoComplete="email"
                        additionalText={{
                          label: translate('login.email_field.label'),
                        }}
                        value={values.emailAddress}
                        id="emailAddress"
                        name="emailAddress"
                        type="email"
                        placeholder={''}
                        aria-label={translate(
                          'login.email_field.input_field_label',
                        )}
                        errorText={
                          errors.emailAddress && touched.emailAddress
                            ? errors.emailAddress
                            : undefined
                        }
                        data-testid="email-input"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('emailAddress', e.target.value);
                        }}
                        onBlur={() => {
                          setTouched({ emailAddress: true });
                          if (!validateEmail(values.emailAddress)) {
                            setStoredValue({
                              key: StorageKey.EMAIL,
                              value: values.emailAddress,
                            });
                          }
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setTouched({ emailAddress: true });
                          }
                        }}
                      />
                    </InputContainer>
                    <InputContainer>
                      <SecureTextInput
                        additionalText={{
                          label: translate('login.password_field.label'),
                        }}
                        autoComplete="current-password"
                        id="password-input"
                        name="password"
                        type="password"
                        data-testid="password-input"
                        onChange={e => {
                          setFieldValue('password', e.target.value);
                        }}
                        a11yLabels={{
                          contentsVisibleAlert: translate(
                            'login.password_field.contents_visible_alert',
                          ),
                          contentsHiddenAlert: translate(
                            'login.password_field.contents_hidden_alert',
                          ),
                          toggleButton: translate(
                            'login.password_field.toggle_button',
                          ),
                        }}
                        validityRequirements={[]}
                      />
                    </InputContainer>
                    <PasswordLinkContainer>
                      <ForgotPasswordLink
                        to={RoutePath.ForgotPassword}
                        onClick={() => {
                          tracking.track('forgot-password-clicked', {
                            subdomain: subdomainFromUrl,
                          });
                        }}
                        data-testid="forgotten-password-link"
                      >
                        {translate('login.password_field.forgot_password')}
                      </ForgotPasswordLink>
                    </PasswordLinkContainer>
                    {!isMSTeams() ? (
                      <MagicLinkButton
                        type="button"
                        onClick={onMagicLinkClicked}
                        label={magicLinkButtonContents as ReactNode}
                      />
                    ) : null}
                    {group?.authType === AuthTypeEnum.MIXED_MODE && (
                      <StyledSSOButton
                        type="button"
                        onClick={() => {
                          history.push(RoutePath.LoginWithSSO);
                        }}
                        label={
                          ssoProviderName
                            ? translate(
                                'sso.continue_button.sso_provider_label',
                                {
                                  sso_provider: ssoProviderName,
                                },
                              )
                            : translate('sso.continue_button.default_label')
                        }
                      />
                    )}
                    <StyledSubmitButton
                      data-testid="sign-in-button"
                      label={translate('login.submit_button.label')}
                      disabled={!isValid}
                      loading={loading}
                      type="submit"
                    />
                    {Boolean(loginError) && (
                      <Error errorText={loginError} showIcon />
                    )}
                  </StyledForm>
                )}
              </Formik>
              {!isClientSubdomainLogin && (
                <FindYourOrgText>
                  {Trans({
                    t: translate,
                    i18nKey: 'login.help_link',
                    defaults:
                      'Having trouble signing in? <help_link>Find your organisation.</help_link>',
                    components: {
                      help_link: (
                        <BoldLink
                          to={{ pathname: RoutePath.SignInToYourOrganisation }}
                          onClick={() => {
                            tracking.track('search-organisation');
                          }}
                        />
                      ),
                    },
                  })}
                </FindYourOrgText>
              )}
            </>
          )}
        </NewAuthWrapperBlock>
      </AuthWrapper>
    </>
  );
}

export default compose<LoginViaUsernamePasswordProps, RouteComponentProps>(
  withApollo,
  withFeatureFlagUserContext,
  withLoginWithToken,
  withConfirmAndAuthUser,
  withUpdateUser,
)(LoginViaUsernamePassword);
