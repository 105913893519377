import gql from 'graphql-tag';

export const TOP_LEVEL_EXPLORE_CONTENT_QUERY = gql`
  query exploreContent {
    exploreTopLevelCategories {
      id
      title
      slug
      internalName
    }
    exploreFeaturedHero {
      __typename
      ... on Series {
        id
        title
        subtitle
        slug
        cloudinaryThumbnail {
          path
        }
        totalDays
        daysCompleted
        exploreCategory {
          id
          slug
          colourTheme
          internalName
        }
      }
      ... on Tool {
        id
        title
        summary
        slug
        estimatedMins
        cloudinaryThumbnail {
          path
        }
        mediaType {
          value
        }
        isFavourite
        exploreCategory {
          id
          slug
          colourTheme
          internalName
        }
      }
      ... on ExploreCategory {
        id
        exploreCategoryTitle: title
        exploreCategorySlug: slug
        summary
        cloudinaryThumbnail {
          path
        }
        internalName
        colourTheme
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;

export const EXPLORE_TOOL_FRAGMENT = gql`
  fragment ExploreToolFields on Tool {
    id
    title
    slug
    summary
    estimatedMins
    cloudinaryThumbnail {
      path
    }
    isFavourite
    mediaType {
      value
    }
    mediaSrc {
      value
    }
    mediaAsset {
      path
    }
    exploreCategory {
      id
      title
      slug
      colourTheme
      internalName
    }
  }
`;

export const FEATURED_CONTENT_QUERY = gql`
  query exploreFeaturedContent {
    exploreFeaturedContent {
      ... on Series {
        id
        title
        slug
        subtitle
        cloudinaryThumbnail {
          path
        }
        totalDays
        daysCompleted
        exploreCategory {
          id
          title
          slug
          colourTheme
          internalName
        }
      }
      ...ExploreToolFields
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
  ${EXPLORE_TOOL_FRAGMENT}
`;

export const NEW_RELEASES_EXPLORE_CONTENT_QUERY = gql`
  query exploreNewReleases {
    exploreNewReleases {
      ... on Series {
        id
        title
        slug
        subtitle
        cloudinaryThumbnail {
          path
        }
        totalDays
        daysCompleted
        exploreCategory {
          id
          title
          slug
          colourTheme
          internalName
        }
      }
      ...ExploreToolFields
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
  ${EXPLORE_TOOL_FRAGMENT}
`;
