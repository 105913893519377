import React from 'react';

const SearchRemove: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="98" height="98" viewBox="0 0 98 98">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    >
      <path
        fill="#FED325"
        d="M77.646 39.883a37.763 37.763 0 1 1-75.525.009 37.763 37.763 0 0 1 75.525-.009z"
      />
      <path d="M26.367 26.367l26.516 26.516M26.367 52.883l26.516-26.516M66.588 66.583l29.287 29.292" />
    </g>
  </svg>
);

export default SearchRemove;
