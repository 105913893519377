import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import {
  DurationLabelContainer,
  DurationLabelText,
  VideoCameraIcon,
} from './styles';

interface DurationLabelProps {
  duration?: number | null;
  className?: string;
}

export const DurationLabel = ({ duration, className }: DurationLabelProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const formattedDuration = t('booking_flow.duration_label', {
    duration: duration ?? 0,
  });

  return (
    <DurationLabelContainer className={className}>
      <VideoCameraIcon />
      <DurationLabelText>{formattedDuration}</DurationLabelText>
    </DurationLabelContainer>
  );
};
