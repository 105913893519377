import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsUplift = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 18C2 9.163 9.163 2 18 2s16 7.163 16 16-7.163 16-16 16S2 26.837 2 18zM18 4C10.268 4 4 10.268 4 18s6.268 14 14 14 14-6.268 14-14S25.732 4 18 4z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.318 21.269a1 1 0 0 1 1.413.049C14.461 23.172 16.28 24 18 24c1.72 0 3.538-.828 5.269-2.682a1 1 0 0 1 1.462 1.364C22.728 24.828 20.413 26 18 26c-2.413 0-4.728-1.172-6.731-3.318a1 1 0 0 1 .049-1.413z"
      fill={primaryColor}
    />
    <path
      d="M15 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM24 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsUplift;
