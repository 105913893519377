import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { InviteColleague as InviteColleagueIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SendInviteInformationPanel } from '../SendInviteInformationPanel';
import { BodyText, HeadingText } from '../../Shared/Typography';
import { Grid, GridItem } from '../../Shared/Grid';
import { medium } from '../../utils';
import { CopyLink } from './CopyLink';

const InviteAColleagueTitle = styled(HeadingText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.medium,
  level: 3,
}))`
  margin-bottom: 8px;
`;

const InviteAColleagueText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  margin-bottom: 32px;
`;

const InviteColleagueGrid = styled(Grid)`
  width: 100%;
  display: flex;
  padding: 0;
`;

export const InviteColleagueImage = styled(InviteColleagueIcon).attrs({
  role: 'presentation',
})`
  display: block;
`;

const FormGridItem = styled(GridItem)`
  position: relative;
  padding-top: 0;

  ${medium(css`
    background-color: ${({ theme }) => theme.colors.background.primary};
    padding: ${rem(0)} ${rem(52)} ${rem(0)} ${rem(12)};
    z-index: 1;
  `)}
`;

const InfoPanelGridItem = styled(GridItem)`
  display: none;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.boxShadow};
  border-radius: ${rem(4)};
  padding: ${rem(24)};

  ${medium(css`
    display: flex;
    margin-left: auto;
    margin-bottom: ${rem(52)};
  `)}
`;

export const InviteColleague = () => {
  const { t: translate } = useTranslation<Namespace<'account'>>('account');

  const informationPanelBulletPoints = [
    translate('invite_colleague.information_panel.bullet_point_1'),
    translate('invite_colleague.information_panel.bullet_point_2'),
  ];

  return (
    <InviteColleagueGrid>
      <FormGridItem sm={4} md={8} lg={7}>
        <InviteAColleagueTitle>
          {translate('invite_colleague.title')}
        </InviteAColleagueTitle>
        <InviteAColleagueText>
          {translate('invite_colleague.instruction')}
        </InviteAColleagueText>
        <CopyLink />
      </FormGridItem>
      <InfoPanelGridItem sm={4} md={8} lg={4}>
        <SendInviteInformationPanel
          image={
            <InviteColleagueImage
              width={rem(180)}
              height={rem(210)}
              aria-hidden="true"
              focusable="false"
            />
          }
          title={translate('invite_colleague.title')}
          bulletPoints={informationPanelBulletPoints}
        />
      </InfoPanelGridItem>
    </InviteColleagueGrid>
  );
};
