import { NetworkStatus } from '@apollo/client';

export type Status = 'loading' | 'loadingMore' | 'error' | 'ready';

export default function getStatus(networkStatus: NetworkStatus): Status {
  switch (networkStatus) {
    case NetworkStatus.loading:
      return 'loading';
    case NetworkStatus.fetchMore:
    case NetworkStatus.refetch:
    case NetworkStatus.setVariables:
      return 'loadingMore';
    // TODO: Figure out why this isn't set when there is an error
    case NetworkStatus.error:
      return 'error';
    case NetworkStatus.ready:
      return 'ready';
    default:
      return 'error';
  }
}
