import { useMutation } from '@apollo/client';
import { logException } from '../../logging';
import { LOG_EVENT_MUTATION } from './withLogEvent';
import {
  getSentAtTimestamp,
  ServersideTrackingEvent,
  updateInteractionSession,
} from '.';

export interface TrackServersideInput {
  eventName: ServersideTrackingEvent;
  eventProperties?: Record<string, unknown>;
  brazeProperties?: Record<string, unknown>;
}

/**
 * Convenience hook for calling the logEvent mutation
 *
 * Typically used to track Braze attributes, in which case
 * properties must be duplicated to the brazeProperties field
 *
 * Will also write to the datalake (via Retina)
 */
export const useTrackServerside = () => {
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);

  const trackServerside = async ({
    eventName,
    eventProperties,
    brazeProperties,
  }: TrackServersideInput) => {
    try {
      updateInteractionSession();

      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties: {
              clientSentAtUtcTimestamp: getSentAtTimestamp(),
              ...eventProperties,
            },
            brazeProperties,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  return trackServerside;
};
