import React from 'react';
import { IconProps } from '../IconProps';

const SvgSearch = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#Search_svg__clip0_2027_20238)">
      <g clipPath="url(#Search_svg__clip1_2027_20238)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 6.837a5.337 5.337 0 1 1 9.152 3.732.498.498 0 0 0-.083.083A5.337 5.337 0 0 1 1.5 6.837zm9.45 4.82a6.337 6.337 0 1 1 .707-.707l3.697 3.697a.5.5 0 0 1-.707.707l-3.697-3.696z"
          fill={primaryColor}
          fillOpacity={0.45}
        />
      </g>
    </g>
    <defs>
      <clipPath id="Search_svg__clip0_2027_20238">
        <path fill={secondaryColor} d="M0 0h16v16H0z" />
      </clipPath>
      <clipPath id="Search_svg__clip1_2027_20238">
        <path fill={secondaryColor} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSearch;
