import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves12 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M0 174.139V192h340v-61.033c-19.859-9.443-41.667-17.427-64.936-23.472-56.145-14.583-77.485 3.444-102.475 24.555-26.862 22.693-57.941 48.949-141.006 42.086-12.93-1.068-23.373-1.014-31.583.003z"
      fill={primaryColor}
    />
    <path
      d="M0 94V0h170c-2.327 6.2-4.284 13.08-6.276 20.085-5.434 19.108-11.133 39.15-25.343 48.855-17.279 11.802-36.157 3.604-54.638-4.422-18.623-8.087-36.843-16-52.617-3.094C20.953 69.746 11.62 80.55 2.756 90.813 1.833 91.883.914 92.946 0 94z"
      fill={secondaryColor}
    />
  </svg>
);

export default SvgWaves12;
