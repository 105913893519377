import { isNull } from 'util';
import { rem } from 'polished';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import { useTranslation } from 'react-i18next';
import { css, MoodColors, styled, ThemeConsumer, ThemeInterface } from 'styles';
import { ScreenReaderContent } from '../../../Shared/Accessibility';
import { small } from '../../../utils';
import { LineChart, LineChartWithScroll } from '../../../Shared/Charts';
import mapYearToEveryMonthExceptThisYear from '../../../Shared/Charts/mapYearToEveryMonthExceptThisYear';
import { getCheckInScores_lastMonthCheckins } from '../__generated__/getCheckInScores';
import { Moods, Mood } from '../types';
import { DeprecatedMood } from '../../../Shared/Mood';
import { ParagraphSmall } from '../../Typography';
import { CheckInScores } from '../__generated__/CheckInScores';
import MoodScoreEntries from './MoodScoreEntries';
import MoodScoresByMood, { MoodData } from './MoodScoresByMood';
import MoodScoresLastMonth from './MoodScoresLastMonth';
import { CheckInByMoodWithTopFeelings } from './helpers/getGroupedCheckInsByMood';
import { AverageMoodByMonth } from './helpers/getAverageMoodsByMonth';

export const MoodsScoreContainer = styled.div`
  margin-bottom: ${rem(12)};
  margin-top: ${rem(24)};

  ${small(css`
    margin-top: ${rem(36)};
    padding-top: ${rem(24)};
  `)};
`;

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AverageMoodScoreContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${small(css`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const ChartContainer = styled.div`
  flex: 1;
  overflow: hidden;
  margin-right: ${rem(-48)};
  margin-left: ${rem(-48)};

  ${small(css`
    margin-top: ${rem(-49)};
    margin-right: ${rem(-32)};
    margin-left: ${rem(-16)};
  `)}
`;

const Chart = styled.div`
  width: 100%;
`;

const MoodScoresByMoodContainer = styled.div`
  margin-top: ${rem(40)};
`;

export const Cta = CenteredContent;
export const TotalCheckins = styled(ParagraphSmall)``;

export function getMoodColor({ colors }: ThemeInterface, mood: string) {
  const moodId = (Object.keys(Moods) as (keyof typeof Moods)[]).find(
    key => Moods[key] === mood,
  );

  return colors.mood[moodId?.toLowerCase() as keyof MoodColors];
}

function getLargestMood(checkInsByMood: MoodData[]) {
  const moodCounts = checkInsByMood.map(mood => mood.count);
  const largestCount = Math.max(...moodCounts);

  return checkInsByMood.find(mood => mood.count === largestCount);
}

export interface MoodScoresProps {
  allCheckins: CheckInScores[];
  lastMonthCheckins: getCheckInScores_lastMonthCheckins[];
  averageMoodByMonth: AverageMoodByMonth[];
  allCheckinsByMood: CheckInByMoodWithTopFeelings[];
}

const MoodScores = ({
  averageMoodByMonth,
  allCheckins,
  allCheckinsByMood,
  lastMonthCheckins,
}: MoodScoresProps) => {
  const { t: translate } = useTranslation('insights');

  const hasCheckins = allCheckins.length > 0;
  const showLastMonthMood = lastMonthCheckins.length > 0;

  const baseGraphData = averageMoodByMonth.map(
    ({ month, year, averageMood }) => ({
      x: mapYearToEveryMonthExceptThisYear(month, year),
      y: averageMood,
    }),
  );

  const visualGraphData = baseGraphData.map(entry => ({
    ...entry,
    x: entry.x.toUpperCase(),
  }));

  /**
   * Filters out incomplete data and reverses the array so that
   * the most recent entries come first.
   * Used when rendering screen reader version of graph,
   * to provide quicker access to recent data.
   */
  const screenReaderGraphData = baseGraphData
    .filter(entry => entry.y > 0)
    .reverse();

  const chartViewportSize = 7;
  const moodsCount = Object.keys(Mood).length;

  return (
    <ThemeConsumer>
      {theme => {
        let groupedCheckIns;
        let largestMood: MoodData | undefined;

        if (hasCheckins) {
          groupedCheckIns = allCheckinsByMood.map(mood => ({
            ...mood,
            fill: getMoodColor(theme, mood.mood),
          }));

          largestMood = getLargestMood(groupedCheckIns);

          if (largestMood) {
            largestMood.isActive = true;
          }
        }

        return (
          <MoodsScoreContainer>
            <AverageMoodScoreContainer>
              {showLastMonthMood && (
                <MoodScoresLastMonth lastMonthCheckins={lastMonthCheckins} />
              )}
              <SizeMe monitorWidth={true}>
                {({ size: { width } }) => (
                  <ChartContainer
                    aria-hidden="true"
                    data-testid="checkin-chart"
                  >
                    <Chart>
                      {visualGraphData.length > chartViewportSize ? (
                        <LineChartWithScroll
                          data={visualGraphData}
                          hideYAxis={true}
                          pointDisplayCount={chartViewportSize}
                          width={!isNull(width) ? width : undefined}
                          height={240}
                          yMax={moodsCount}
                        />
                      ) : (
                        <LineChart
                          data={visualGraphData}
                          hideYAxis={true}
                          displayUpToIndex={chartViewportSize}
                          pointDisplayCount={chartViewportSize}
                          width={!isNull(width) ? width : undefined}
                          height={240}
                          yMax={moodsCount}
                        />
                      )}
                    </Chart>
                  </ChartContainer>
                )}
              </SizeMe>
              <ScreenReaderContent as="table" data-testid="checkin-sr-table">
                <caption>
                  {translate('moods.screen_reader_content.caption')}
                </caption>
                <tbody>
                  <tr>
                    <th scope="col">
                      {translate(
                        'moods.screen_reader_content.month_table_header',
                      )}
                    </th>
                    <th scope="col">
                      {translate(
                        'moods.screen_reader_content.mood_table_header',
                      )}
                    </th>
                  </tr>
                  {screenReaderGraphData.map(({ x, y }, i) => (
                    <tr key={i}>
                      <td>{x}</td>
                      <td>{DeprecatedMood[y]}</td>
                    </tr>
                  ))}
                </tbody>
              </ScreenReaderContent>
            </AverageMoodScoreContainer>
            {groupedCheckIns && largestMood && (
              <MoodScoresByMoodContainer>
                <MoodScoresByMood
                  checkIns={groupedCheckIns}
                  initialMood={largestMood}
                  totalCheckIns={allCheckins.length}
                />
              </MoodScoresByMoodContainer>
            )}
            <MoodScoreEntries />
          </MoodsScoreContainer>
        );
      }}
    </ThemeConsumer>
  );
};

export default MoodScores;
