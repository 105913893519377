import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@unmind/design-system-components-web';
import { BodyText } from 'Shared/Typography';
import { Clear } from 'icons';
import { rem } from 'polished';
import { useTheme } from 'styled-components';
import { styled } from 'styles';

const Form = styled.form<{ isChecked: boolean }>`
  width: ${rem(390)};
  height: ${rem(370)};
  border-radius: ${rem(24)};
  box-shadow: 0px 8px 16px -4px ${({ theme }) => theme.colors.background.skeleton},
    0px 16px 32px -8px ${({ theme }) => theme.colors.background.skeleton};
  padding: ${rem(24)};
  background-color: ${({ theme }) => theme.colors.background.inverse};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // Checkbox styles
  > label {
    color: ${({ theme }) => theme.colors.background.primary};
  }
  label:hover span {
    background-color: ${({ theme, isChecked }) =>
      isChecked
        ? theme.colors.brand.primary
        : theme.colors.background.primary} !important;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseIcon = styled(Clear).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.card,
}))`
  width: ${rem(32)};
  height: ${rem(32)};
  cursor: pointer;
`;

const TextArea = styled.textarea`
  width: 100%;
  outline: 0;
  background-color: ${({ theme }) => theme.colors.border.primary};
  border: solid 1px ${({ theme }) => theme.colors.background.primary};
  border-radius: ${rem(8)};
  color: ${({ theme }) => theme.colors.background.primary};
  resize: none;
  padding: ${rem(16)};
  min-height: ${rem(120)};
  height: ${rem(140)};
  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.colors.background.primary};
  }
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.toast.overlay : theme.colors.brand.primary};
`;

const ArrowDownWrapper = styled.div`
  position: absolute;
  bottom: ${rem(-6)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: ${rem(20)} solid transparent;
  border-right: ${rem(20)} solid transparent;
  border-top: ${rem(20)} solid ${({ theme }) => theme.colors.background.inverse};
`;

interface FeedbackUserInput {
  feedbackText: string;
  contactConsent: boolean;
}

interface FreeTextFeedbackProps {
  handleCloseClick(): void;
  handleSubmitClick(userInput: FeedbackUserInput): void;
}

const FreeTextFeedback = ({
  handleCloseClick,
  handleSubmitClick,
}: FreeTextFeedbackProps) => {
  const { t: translate } = useTranslation('shared');
  const { colors, typography } = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitClick({
      feedbackText: textareaValue,
      contactConsent: isChecked,
    });
  };

  return (
    <Form onSubmit={handleSubmit} isChecked={isChecked}>
      <TitleWrapper>
        <BodyText
          weight={typography.fontWeights.bold}
          color={colors.background.card}
          sizes={[typography.fontSizes.fontSize18]}
        >
          {translate('free_text_feedback.title')}
        </BodyText>
        <CloseIcon
          role="button"
          tabIndex={0}
          aria-label={translate('default_close_button.a11y_label')}
          onClick={handleCloseClick}
          data-testid="close-button"
        />
      </TitleWrapper>
      <TextArea
        onChange={e => setTextareaValue(e.target.value)}
        name="feedback"
        placeholder={translate('free_text_feedback.textarea.placeholder_text')}
        value={textareaValue}
      />
      <Checkbox
        onChange={() => setIsChecked(!isChecked)}
        labelText={translate('free_text_feedback.checkbox.label')}
        data-testid="contact-consent"
        checked={isChecked}
      />
      <SubmitButton
        disabled={!textareaValue}
        aria-disabled={!textareaValue}
        label={translate('free_text_feedback.submit_button.text')}
        variant="primary"
        type="submit"
      />
      <ArrowDownWrapper>
        <ArrowDown />
      </ArrowDownWrapper>
    </Form>
  );
};

export default FreeTextFeedback;
