import { ApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { ApolloCacheShape } from '../apollo';
import { CheckAuthenticationProps } from '../Router/RedirectIfUnauthenticated';
import { AUTHENTICATED_QUERY } from './authenticatedQuery';
import useGetUserAuthMethod from './useGetUserAuthMethod';

type AuthState = {
  state: 'LOADING' | 'READY';
  isAuthenticated?: boolean;
  isUsingSSO?: boolean;
};

interface LocalAuthStatusProps {
  client?: ApolloClient<ApolloCacheShape>;
  children(authState: AuthState): React.ReactElement;
}

const LocalAuthStatus = ({ client, children }: LocalAuthStatusProps) => {
  const [authState, setAuthState] = useState<AuthState>({ state: 'LOADING' });

  const { userAuthMethod, userAuthMethodLoading } = useGetUserAuthMethod();

  useEffect(() => {
    if (!client || userAuthMethodLoading) {
      return;
    }

    const querySubscription = client
      .watchQuery<CheckAuthenticationProps>({
        query: AUTHENTICATED_QUERY,
        fetchPolicy: 'cache-only',
      })
      .subscribe(({ data }) => {
        if (data.authenticated !== undefined) {
          setAuthState({
            state: 'READY',
            isAuthenticated: data.authenticated,
            isUsingSSO: userAuthMethod === 'SSO',
          });
        }
      });

    return () => {
      querySubscription.unsubscribe();
    };
  }, [client, userAuthMethod, userAuthMethodLoading]);

  return children(authState);
};

export default withApollo<LocalAuthStatusProps>(LocalAuthStatus);
