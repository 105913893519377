import React from 'react';
import { IconProps } from '../IconProps';

const SvgCategoryHeaderWave = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 900 48"
    fill="none"
    preserveAspectRatio="none"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M328.131 47.897C530.856 45.52 724.042 45.623 900 0H0v24.189c118.354 16.305 229.88 24.86 328.131 23.708z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgCategoryHeaderWave;
