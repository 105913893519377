import { Colors } from '@unmind/design-system-theme';
import { isNil } from 'lodash/fp';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from 'styles';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { AdminGroupedBarChart } from '../../../../Shared/Charts';
import { useFilterState } from '../../FilterState';
import Panel, { PanelSection } from '../../Panel';
import { filterNonNull } from '../../../../typescript/helpers';
import { stripUnusedQueryVariables } from '../../FilterState/helpers';
import { AnonymityWarningMessage, renderUtcDate } from '../../helpers';
import { PanelAnonymityWarning } from '../../AnonymityWarning';
import Metric, { MetricTitle } from '../../Metric';
import { BodyText } from '../../../../Shared/Typography';
import {
  GetOrganisationWellbeingScores,
  GetOrganisationWellbeingScoresVariables,
  GetOrganisationWellbeingScores_currentPeriod,
  GetOrganisationWellbeingScores_currentPeriod_categories,
} from './__generated__/GetOrganisationWellbeingScores';
import IndexCategoryList from './IndexCategoryList';

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    margin-right: ${rem(48)};
  }
`;

const ListHeader = styled(MetricTitle)`
  margin-bottom: ${rem(12)};
`;

export const ADMIN_WELLBEING_SCORES_QUERY = gql`
  query GetOrganisationWellbeingScores(
    $currentPeriod: OrganisationWellbeingInput!
    $previousPeriod: OrganisationWellbeingInput!
  ) {
    currentPeriod: organisationWellbeing(input: $currentPeriod) {
      totalCompletedCount
      overallAverageScore
      categories {
        name
        averageScore
        description
      }
      shouldBeAnonymised
    }
    previousPeriod: organisationWellbeing(input: $previousPeriod) {
      overallAverageScore
      categories {
        name
        averageScore
        description
      }
      shouldBeAnonymised
    }
    wellbeingTracker {
      bands {
        name
        lower
        upper
      }
    }
  }
`;

interface GetOrganisationWellbeingScores_currentPeriod_categories_with_descriptions
  extends Omit<
    GetOrganisationWellbeingScores_currentPeriod_categories,
    'description'
  > {
  description: string;
}

const cleanCategoriesData = (
  categories: (GetOrganisationWellbeingScores_currentPeriod_categories | null)[],
): GetOrganisationWellbeingScores_currentPeriod_categories_with_descriptions[] => {
  const nonNullCategories = categories.filter(filterNonNull);
  const categoriesWithDescriptions = nonNullCategories.filter(
    (
      cat,
    ): cat is GetOrganisationWellbeingScores_currentPeriod_categories_with_descriptions =>
      !!cat?.description,
  );

  return categoriesWithDescriptions;
};

const getDataWithLabels = (
  categories: GetOrganisationWellbeingScores_currentPeriod_categories_with_descriptions[],
  labelPrefix: string,
  overallAverage: number | null,
) => {
  const overallCategory = {
    name: 'Overall',
    averageScore: overallAverage,
  };

  return [overallCategory, ...categories].map(({ averageScore, name }) => ({
    name,
    value: averageScore,
    label: [name, labelPrefix, `Avg. score: ${Math.round(averageScore || 0)}`],
  }));
};

const formatDateLabel = (dateString: string) =>
  renderUtcDate(dateString, 'yyyy-MM-dd', 'do MMM yy');

interface IndexPanelContentsProps {
  currentPeriod: GetOrganisationWellbeingScores_currentPeriod;
  previousPeriod: GetOrganisationWellbeingScores['previousPeriod'];
  panelColor: Colors;
  boundaries: [number, number];
}

const IndexPanelContents = ({
  panelColor,
  currentPeriod,
  previousPeriod,
  boundaries,
}: IndexPanelContentsProps) => {
  const { colors, typography } = useTheme();

  const { t: translate } = useTranslation('admin');
  const { timeFilterState, timeFilterPreviousPeriodState } = useFilterState();

  const currentStartDateLabel = formatDateLabel(timeFilterState.startDate);
  const currentEndDateLabel = formatDateLabel(timeFilterState.endDate);

  const previousStartDateLabel = formatDateLabel(
    timeFilterPreviousPeriodState.startDate,
  );
  const previousEndDateLabel = formatDateLabel(
    timeFilterPreviousPeriodState.endDate,
  );

  const { totalCompletedCount, overallAverageScore = null } = currentPeriod;
  const categories = currentPeriod.categories || [];
  const nonNullCategories = categories?.filter(filterNonNull) ?? [];
  const seriesOneLegend = `${currentStartDateLabel} - ${currentEndDateLabel}`;
  const seriesTwoLegend = `${previousStartDateLabel} - ${previousEndDateLabel}`;
  const currentDataWithLabels = currentPeriod?.categories
    ? getDataWithLabels(
        cleanCategoriesData(currentPeriod.categories),
        seriesOneLegend,
        overallAverageScore,
      )
    : [];
  const previousDataWithLabels = previousPeriod?.categories
    ? getDataWithLabels(
        cleanCategoriesData(previousPeriod.categories),
        seriesTwoLegend,
        previousPeriod.overallAverageScore,
      )
    : [];

  const groupedIndexChartData = !previousPeriod?.shouldBeAnonymised
    ? {
        seriesOne: {
          data: currentDataWithLabels,
          legend: seriesOneLegend,
        },
        seriesTwo: {
          data: previousDataWithLabels,
          legend: seriesTwoLegend,
        },
      }
    : {
        seriesOne: {
          data: currentDataWithLabels,
          legend: seriesOneLegend,
        },
      };

  return (
    <>
      <PanelSection>
        <MetricContainer>
          <Metric
            data-testid="index-insights-completed"
            color={panelColor}
            title={translate('panels.index.metrics.completed')}
            value={totalCompletedCount ?? 0}
          />
          {!isNil(overallAverageScore) ? (
            <Metric
              data-testid="index-insights-average-score"
              color={panelColor}
              title={translate('panels.index.metrics.average_score')}
              value={overallAverageScore}
            />
          ) : null}
        </MetricContainer>
      </PanelSection>
      <PanelSection>
        <AdminGroupedBarChart
          caption={translate('panels.index.chart.title')}
          categoriesHeader={translate('panels.index.chart.category_header')}
          colorScale={[
            colors.staticPalette.plum,
            colors.staticPalette.purple.purple7,
          ]}
          data={groupedIndexChartData}
          height={396}
          title={translate('panels.index.chart.title')}
          description={translate('panels.index.chart.description')}
          boundaries={boundaries}
        />
      </PanelSection>
      {nonNullCategories.every(({ averageScore }) => !isNil(averageScore)) ? (
        <PanelSection data-test-id="category-scores">
          <ListHeader>
            {translate('panels.index.category_scores.title')}
            <BodyText sizes={[typography.fontSizes.fontSize12]}>
              {currentStartDateLabel} - {currentEndDateLabel}
            </BodyText>
          </ListHeader>
          <IndexCategoryList
            //@ts-ignore
            items={[...categories].reverse()}
          />
        </PanelSection>
      ) : null}
    </>
  );
};

export const UnmindIndexPanel = () => {
  const {
    timeFilterState,
    timeFilterPreviousPeriodState,
    userGroupFilterState,
  } = useFilterState();

  const nullFreeUserGroupFilters = userGroupFilterState
    ? stripUnusedQueryVariables(userGroupFilterState)
    : {};

  const { data, loading, error } = useQuery<
    GetOrganisationWellbeingScores,
    GetOrganisationWellbeingScoresVariables
  >(ADMIN_WELLBEING_SCORES_QUERY, {
    variables: {
      currentPeriod: {
        ...timeFilterState,
        ...nullFreeUserGroupFilters,
      },
      previousPeriod: {
        ...timeFilterPreviousPeriodState,
        ...nullFreeUserGroupFilters,
      },
    },
  });

  const { t: translate } = useTranslation('admin');

  const { colors } = useTheme();

  const panelProps = {
    color: colors.staticPalette.plum,
    title: translate('panels.index.title'),
  };

  if (loading || !isNil(error) || !data || !data.currentPeriod) {
    const hasError = !isNil(error) || !data || !data.currentPeriod;

    return <Panel {...panelProps} isLoading={loading} hasError={hasError} />;
  }

  const { shouldBeAnonymised } = data.currentPeriod;
  if (shouldBeAnonymised) {
    return (
      <PanelAnonymityWarning
        {...panelProps}
        message={AnonymityWarningMessage.INDEX_PANEL}
      />
    );
  }

  const boundaries: IndexPanelContentsProps['boundaries'] = [
    data.wellbeingTracker?.bands[0].lower || 40,
    data.wellbeingTracker?.bands[data.wellbeingTracker.bands.length - 1]
      .upper || 160,
  ];

  return (
    <Panel {...panelProps}>
      <IndexPanelContents
        panelColor={panelProps.color}
        currentPeriod={data.currentPeriod}
        previousPeriod={data.previousPeriod}
        boundaries={boundaries}
      />
    </Panel>
  );
};

export default UnmindIndexPanel;
