import { styled, css } from 'styles';
import { medium } from '../../../utils';

export const Panel = styled.div`
  ${medium(css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `)}
`;

export const RadioContainer = styled.div`
  margin-right: 3rem;
`;
