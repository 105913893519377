import React from 'react';
import { IconProps } from '../IconProps';

const SvgRefresh = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.747.72c-.188.01-.363.022-.5.036-2.28.229-4.664 1.54-6.465 3.556-.375.42-.796.973-1.088 1.432l-.041.064-.13-.516a9.75 9.75 0 0 0-.155-.578.67.67 0 0 0-.166-.23.684.684 0 0 0-.483-.194.702.702 0 0 0-.656.42.536.536 0 0 0-.06.294l.001.124.366 1.46c.201.803.372 1.478.379 1.5.02.066.08.173.126.23.154.187.417.29.644.253a182.58 182.58 0 0 0 3.046-.766.722.722 0 0 0 .422-.516.716.716 0 0 0-.77-.854c-.045.006-.35.078-.778.185l-.709.176c-.01 0 .175-.296.308-.492C4.056 4.808 5.525 3.55 7.095 2.829a7.248 7.248 0 0 1 2.364-.657c.284-.027.67-.03 1.012-.008a7.861 7.861 0 0 1 6.848 5.054c.075.201.094.246.128.303A.715.715 0 0 0 18.756 7a6.432 6.432 0 0 0-.224-.603 9.288 9.288 0 0 0-7.985-5.66 13.454 13.454 0 0 0-.8-.017zm8.712 10.72c-.12.02-2.97.74-3.025.763a.72.72 0 0 0-.422.515.808.808 0 0 0 .02.36.716.716 0 0 0 .752.494c.042-.005.366-.082.803-.191.403-.101.732-.182.732-.18a8.985 8.985 0 0 1-2.616 2.976 8.944 8.944 0 0 1-4.224 1.638 4.53 4.53 0 0 1-.972.028 7.865 7.865 0 0 1-6.15-3.643 8.278 8.278 0 0 1-.696-1.392c-.073-.19-.081-.208-.13-.281a.716.716 0 1 0-1.288.557c.008.033.048.146.088.252.254.66.59 1.304.987 1.888.24.353.589.793.853 1.076a9.288 9.288 0 0 0 6.32 2.975c.466.023.652.017 1.128-.036a10.376 10.376 0 0 0 7.665-4.847l.079-.127.12.483c.073.288.132.509.148.545a.67.67 0 0 0 .166.23.687.687 0 0 0 .478.194.704.704 0 0 0 .66-.42.537.537 0 0 0 .06-.293v-.124l-.366-1.46a242.84 242.84 0 0 0-.379-1.5.857.857 0 0 0-.126-.23.722.722 0 0 0-.665-.25z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgRefresh;
