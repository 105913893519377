import { styled } from 'styles';
import getFocusState from '../Button/getFocusState';

const WrappingButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  &:focus {
    ${getFocusState()}
  }
`;

export default WrappingButton;
