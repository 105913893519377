import gql from 'graphql-tag';
import client from '../App/apollo';

const USER_LOCALE_QUERY = gql`
  query userLocaleQuery {
    user {
      id
      userLocale {
        value
      }
    }
  }
`;

type UserLocaleQueryResponse = {
  user: {
    __typename: string;
    id: string;
    userLocale: {
      value: string;
      __typename: string;
    } | null;
  };
};

export const getDbUserLocale = async () => {
  const response = await client.query<UserLocaleQueryResponse>({
    query: USER_LOCALE_QUERY,
    fetchPolicy: 'network-only',
  });

  return response;
};
