import React from 'react';
import { Matcher } from 'react-day-picker';
import { TimeSlot, getTimes } from 'Talk/lib/availability';
import { formatTime } from 'Talk/lib/dates';
import { TimeSelectorContainer, TimeSlotButton } from './styles';

interface TimeSelectorProps {
  day?: Matcher | Matcher[];
  selectedSlot?: TimeSlot;
  practitionerSlots?: TimeSlot[];
  onSelect(slot: TimeSlot): void;
}

export const TimeSelector = ({
  day,
  selectedSlot,
  practitionerSlots = [],
  onSelect,
}: TimeSelectorProps) => {
  const slotsForDay = day ? getTimes(practitionerSlots, day as Date) : [];

  return (
    <TimeSelectorContainer data-cy="practitioner-time-slot-list">
      {slotsForDay.map(slot => (
        <TimeSlotButton
          type="button"
          key={`${slot.start}${slot.end}`}
          selected={
            selectedSlot?.start === slot.start && selectedSlot?.end === slot.end
          }
          onClick={() => onSelect(slot)}
        >
          {formatTime(slot.start)}
        </TimeSlotButton>
      ))}
    </TimeSelectorContainer>
  );
};
