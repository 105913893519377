import i18n from '../../i18n/config';

const localisedMonths = [
  i18n.t('insights:shared.months.jan'),
  i18n.t('insights:shared.months.feb'),
  i18n.t('insights:shared.months.mar'),
  i18n.t('insights:shared.months.apr'),
  i18n.t('insights:shared.months.may'),
  i18n.t('insights:shared.months.jun'),
  i18n.t('insights:shared.months.jul'),
  i18n.t('insights:shared.months.aug'),
  i18n.t('insights:shared.months.sep'),
  i18n.t('insights:shared.months.oct'),
  i18n.t('insights:shared.months.nov'),
  i18n.t('insights:shared.months.dec'),
];

const mapYearToEveryMonthExceptThisYear = (
  month: number,
  year: number,
): string =>
  year !== new Date().getFullYear()
    ? `${localisedMonths[month]} '${year.toString().slice(2, 4)}`
    : localisedMonths[month];

export default mapYearToEveryMonthExceptThisYear;
