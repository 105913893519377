import { meanBy } from 'lodash';
import moment from 'moment';
import { getCheckInScores_allCheckins } from '../../__generated__/getCheckInScores';

type MoodByMonth = {
  year: number;
  month: number;
  moods: getCheckInScores_allCheckins[];
};

export type AverageMoodByMonth = {
  year: number;
  month: number;
  averageMood: number;
};

const sortCheckinsByDateAscending = (
  checkins: getCheckInScores_allCheckins[],
) =>
  checkins
    .concat()
    .sort(
      ({ dateCreated: dateA }, { dateCreated: dateB }) =>
        new Date(dateA).getTime() - new Date(dateB).getTime(),
    );

const getEdgeDates = (checkins: getCheckInScores_allCheckins[]) => {
  const orderedCheckins = sortCheckinsByDateAscending(checkins);
  const { dateCreated: startDate } = orderedCheckins[0];
  const { dateCreated: endDate } = orderedCheckins[orderedCheckins.length - 1];

  return {
    startDate: moment(startDate).startOf('month'),
    endDate: moment(endDate).startOf('month'),
  };
};

const getMoodsForDate = (
  checkins: getCheckInScores_allCheckins[],
  month: number,
  year: number,
) =>
  checkins.filter(({ dateCreated }) => {
    const date = new Date(dateCreated);

    return month === date.getMonth() && year === date.getFullYear();
  });

const distributeMoodsByMonth = (
  checkins: getCheckInScores_allCheckins[],
): MoodByMonth[] => {
  if (!checkins.length) {
    return [];
  }

  const { startDate } = getEdgeDates(checkins);
  const monthsDiff = moment().startOf('month').diff(moment(startDate), 'month');
  const dataSpread = monthsDiff + 1;
  const monthsToAdd = dataSpread >= 7 ? dataSpread : 7;
  const moodsByMonth: MoodByMonth[] = [];

  for (let i = 0; i < monthsToAdd; i += 1) {
    const date = moment().subtract(i, 'months');
    const month = date.get('month');
    const year = date.get('year');
    const moodsForMonth = getMoodsForDate(checkins, month, year);

    moodsByMonth.unshift({
      month,
      year,
      moods: moodsForMonth,
    });
  }

  return moodsByMonth;
};

const getAverageMood = (moods: getCheckInScores_allCheckins[]): number => {
  const averageMood = meanBy(moods, 'moodId');

  return !isNaN(averageMood) ? Math.round(meanBy(moods, 'moodId')) : 0;
};

export const getAverageMoodsByMonth: (
  checkins: getCheckInScores_allCheckins[],
) => AverageMoodByMonth[] = checkins =>
  distributeMoodsByMonth(checkins).map(({ month, year, moods }) => ({
    month,
    year,
    averageMood: getAverageMood(moods),
  }));
