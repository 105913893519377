import { BrowseFilterPanel } from 'Talk/components/BrowseFilterPanel';
import { FiltersCollection } from 'Talk/components/BrowseFilterPanel/types';
import { PractitionerCardList } from 'Talk/components/PractitionerCardList';
import { TalkHeader } from 'Talk/components/TalkHeader';
import { useLocationData } from 'Talk/hooks/useLocationData';
import { Namespace } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tracking } from '../../../App/Tracking';
import Modal from '../../../Shared/Modal';
import { PractitionerMatches, useMatches } from '../../components/Matching';
import { SetLocationForm } from '../../components/SetLocation';
import {
  LocationFormCopy,
  LocationFormTitle,
} from '../../components/SetLocation/styles';
import { HeaderWrapper, SubHeading, UpdateLocationFormWrapper } from './styles';

interface TalkBrowsePageProps {
  selectedFilters: FiltersCollection;
  onFilterChange(filter: FiltersCollection): void;
}

export const TalkBrowsePage = ({
  selectedFilters,
  onFilterChange,
}: TalkBrowsePageProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { userLocationData, hasValidLocationData } = useLocationData();
  const { userHasPractitionerMatches, practitionerMatches } = useMatches();

  return (
    <>
      <HeaderWrapper increasedSpacing={!userHasPractitionerMatches}>
        <TalkHeader />
      </HeaderWrapper>
      {userHasPractitionerMatches ? (
        <PractitionerMatches
          matches={practitionerMatches}
          cardOrientation="horizontal"
          trackingSource="browse_match"
        />
      ) : null}

      {userHasPractitionerMatches ? (
        <SubHeading>
          {t('browse_practitioners.page_subtitle_when_matches_present')}
        </SubHeading>
      ) : null}
      <BrowseFilterPanel
        selectedFilters={selectedFilters}
        onFiltersChange={onFilterChange}
        renderFilterLabel={!userHasPractitionerMatches}
        userLocation={userLocationData?.user ?? null}
      />
      <PractitionerCardList
        filters={selectedFilters}
        userLocation={userLocationData?.user ?? null}
        userHasValidLocationData={hasValidLocationData}
      />

      <Modal
        open={!hasValidLocationData}
        modalContent={({ close }) => (
          <UpdateLocationFormWrapper>
            <LocationFormTitle>
              {t('location_modal.update_location_title')}
            </LocationFormTitle>
            <LocationFormCopy>{t('location_modal.body')}</LocationFormCopy>
            <SetLocationForm
              onSubmitSuccess={(country, state) => {
                tracking.track('place-of-residence-updated', {
                  country: country.value,
                  state: state.value,
                  source: 'talk',
                });

                close();
              }}
            />
          </UpdateLocationFormWrapper>
        )}
      />
    </>
  );
};
