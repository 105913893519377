import { rem } from 'polished';
import { css, keyframes } from 'styled-components';
import { styled } from 'styles';

export const SINGLE_BUBBLE_DIAMETER = 2.5;

const loading = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 ${SINGLE_BUBBLE_DIAMETER}em 0 -1.3em;
  }
  40% {
    box-shadow: 0 ${SINGLE_BUBBLE_DIAMETER}em 0 0;
  }
`;

const animation = () => css`
  ${loading} 1.8s infinite ease-in-out;
`;

const BubbleLoader = styled.div<{ color?: string; size: number }>`
  animation: ${animation};
  animation-delay: 1.64s;
  animation-fill-mode: both;
  border-radius: 50%;
  color: ${props => props.color || props.theme.colors.primary};
  font-size: ${props => `${rem(props.size)}`};
  margin: 0;
  position: relative;
  text-indent: -9999em;
  transform: translateX(3.25em) translateY(-${SINGLE_BUBBLE_DIAMETER}em)
    translateZ(0);
  height: ${SINGLE_BUBBLE_DIAMETER}em;
  width: ${SINGLE_BUBBLE_DIAMETER}em;

  &:before {
    animation: ${animation};
    animation-delay: 1.48s;
    animation-fill-mode: both;
    border-radius: 50%;
    content: '';
    height: ${SINGLE_BUBBLE_DIAMETER}em;
    left: -3.25em;
    position: absolute;
    top: 0;
    width: ${SINGLE_BUBBLE_DIAMETER}em;
  }

  &:after {
    animation: ${animation};
    animation-fill-mode: both;
    border-radius: 50%;
    content: '';
    height: ${SINGLE_BUBBLE_DIAMETER}em;
    left: 3.25em;
    position: absolute;
    top: 0;
    width: ${SINGLE_BUBBLE_DIAMETER}em;
  }
`;

export default BubbleLoader;
