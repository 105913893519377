import RoutePath from '../RoutePath';

export const getLocationInstance = () => {
  if (window?.location) {
    return window.location;
  }

  return false;
};

const getIgnoreFromPath = (location: false | Location): boolean => {
  const routePathsToIgnore = [
    RoutePath.PlusOneSignUp,
    RoutePath.VerifyAccountOwnership,
    RoutePath.SSOSignUp,
    RoutePath.ConfirmSSOAccount,
  ];
  const pathsToIgnore = `(${routePathsToIgnore.join('|')})`;

  if (location) {
    const currentPath = location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const isLovedOneSignup = !!queryParams.get('invite_token');

    if (isLovedOneSignup) {
      return true;
    }

    return new RegExp(pathsToIgnore, 'i').test(currentPath);
  }

  return false;
};

export default getIgnoreFromPath;
