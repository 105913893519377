import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { Feeling, Heading } from '../../Typography';

const FeelingsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-top: ${rem(4)};
`;

const FeelingsHeading = styled(Heading)`
  display: block;
`;

const FeelingItem = styled(Feeling)`
  display: block;

  & + & {
    margin-left: ${rem(12)};
  }
`;

type TopFeelingsProps = {
  className?: string;
  feelings: string[];
};

export default ({ className, feelings }: TopFeelingsProps) => {
  const { t: translate } = useTranslation('insights');

  return (
    <div className={className}>
      <FeelingsHeading>{translate('moods.top_feelings.title')}</FeelingsHeading>
      <FeelingsContainer>
        {feelings.map(feeling => (
          <FeelingItem key={feeling}>{feeling}</FeelingItem>
        ))}
      </FeelingsContainer>
    </div>
  );
};
