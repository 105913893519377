import React from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import { Info } from 'icons';
import { BodyText } from '../Typography';

const GraphDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${rem(0)} ${rem(16)};
  margin-top: ${rem(4)};
`;

const GraphDescriptionIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  height: 14,
  width: 14,
}))`
  margin-top: ${rem(2)};
`;

const GraphDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  flex: 1;
  margin-left: ${rem(3)};
`;

interface ChartDescriptionProps {
  description: string;
}

const ChartDescription = ({ description }: ChartDescriptionProps) => (
  <GraphDescriptionContainer>
    <GraphDescriptionIcon />
    <GraphDescription>{description}</GraphDescription>
  </GraphDescriptionContainer>
);

export default ChartDescription;
