import React, { ReactNode } from 'react';
import { useUnmindTalk } from '../../hooks';

interface Props {
  children: ReactNode;
}

export const AuthTalk = ({ children }: Props) => {
  const { loading, unmindTalkIsAvailableForUser } = useUnmindTalk();

  if (loading || !unmindTalkIsAvailableForUser) {
    return null;
  }

  return <>{children}</>;
};
