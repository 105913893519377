import { mapUserLocaleToShorthand } from 'i18n/mapUserLocaleToShorthand';
import React from 'react';
import { Chevron } from 'icons';
import { styled } from 'styles';
import { StyledDayPicker } from './styles';

interface DisabledDateRangeBefore {
  before: Date;
  after?: never;
}

interface DisabledDateRangeAfter {
  before?: never;
  after: Date;
}

interface DisabledDateRangeBoth {
  before: Date;
  after: Date;
}

export type DisabledDateRange =
  | DisabledDateRangeBefore
  | DisabledDateRangeAfter
  | DisabledDateRangeBoth;

interface DayPickerProps {
  value: Date;
  onSelect(value: Date): void;
  disabledDateRange?: DisabledDateRange;
}

const ChevronIconRight = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))``;

const ChevronIconLeft = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  transform: rotate(180deg);
`;

const DayPicker = ({ value, onSelect, disabledDateRange }: DayPickerProps) => (
  <StyledDayPicker
    components={{
      IconRight: () => <ChevronIconRight />,
      IconLeft: () => <ChevronIconLeft />,
    }}
    selected={value}
    onSelect={(selectedDate: Date | undefined) => {
      if (selectedDate) {
        onSelect(selectedDate);
      }
    }}
    mode="single"
    locale={mapUserLocaleToShorthand()}
    disabled={disabledDateRange}
  ></StyledDayPicker>
);

export default DayPicker;
