import gql from 'graphql-tag';
import isUndefined from 'lodash/isUndefined';
import { graphql } from '@apollo/client/react/hoc';

interface InputProps {
  subdomain: string;
}

export interface GroupIdResponse {
  subDomain: Subdomain[];
}

interface Subdomain {
  groupId: string;
}

export interface WithGroupIdProps {
  groupId?: string;
  groupIdLoading: boolean;
}

export const GROUPID_QUERY = gql`
  query subDomain($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      groupId
    }
  }
`;

export default graphql<
  InputProps,
  GroupIdResponse,
  InputProps,
  WithGroupIdProps
>(GROUPID_QUERY, {
  options: ({ subdomain }) => ({
    fetchPolicy: 'cache-first',
    variables: {
      subdomain,
    },
  }),
  props: ({ data }) =>
    // error handling to come.
    ({
      groupId:
        !isUndefined(data) &&
        !isUndefined(data.subDomain) &&
        data.subDomain.length > 0
          ? data.subDomain[0].groupId
          : undefined,
      groupIdLoading: Boolean(data && data.loading),
    }),
});
