import React, { useRef } from 'react';
import { rem } from 'polished';
import { Form, Formik, FormikProps } from 'formik';
import { styled, useTheme } from 'styles';
import { useTranslation } from 'react-i18next';
import { useAssignFocus } from '../../../Shared/Accessibility';
import {
  FormikInput,
  FormikSelect,
  FormikValidatedPasswordInput,
} from '../../../Shared/Form/Formik';
import { SelectSize } from '../../../Shared/Form/Select';
import PrimaryButton from '../../../Shared/PrimaryButton';

import FieldError from '../../../Shared/Form/FieldError';
import BodyText from '../../../Shared/Typography/BodyText';
import { TsAndCsStatement } from '../TsAndCsStatement';
import { createValidator } from './validateVoucherForm';
import { VoucherCodeFormProps, VoucherFormFields } from './types';

const FieldErrorWrapper = styled.div`
  margin-top: ${rem(24)};
`;

const StyledTsAndCsStatement = styled(TsAndCsStatement)`
  margin-bottom: ${rem(16)};
`;

const SelectWrapper = styled.div`
  margin: ${rem(24)} 0;
`;

const SignUpButton = styled(PrimaryButton)`
  margin: ${rem(24)} 0 ${rem(16)} 0;
  width: 100%;
`;

const StyledInput = styled(FormikInput)`
  &:first-child {
    margin-top: 0;
  }
  margin: ${rem(24)} 0;
`;

const StyledPasswordInput = styled(FormikValidatedPasswordInput)`
  margin: ${rem(24)} 0;
`;

const VoucherCodeForm = (props: VoucherCodeFormProps) => {
  const { typography } = useTheme();
  const headerRef = useRef<HTMLHeadingElement>(null);
  const { t: translate } = useTranslation('logged_out');
  useAssignFocus(headerRef);

  const { departments, locations } = props;

  const validate = createValidator({ departments, locations });

  return (
    <Formik
      ref={props.formikRef}
      initialValues={{
        department: departments.length === 1 ? departments[0].value : '',
        email: '',
        firstName: '',
        lastName: '',
        location: locations.length === 1 ? locations[0].value : '',
        password: '',
        voucher: '',
      }}
      onSubmit={values => props.onSubmit(values)}
      validate={validate}
    >
      {({ isValid }: FormikProps<VoucherFormFields>) => (
        <Form>
          <StyledInput
            name="voucher"
            placeholder={translate(
              'sign_up.voucher_code.voucher_code_field.placeholder',
            )}
          />
          <StyledInput
            name="firstName"
            placeholder={translate(
              'sign_up.voucher_code.first_name_field.placeholder',
            )}
          />
          <StyledInput
            name="lastName"
            placeholder={translate(
              'sign_up.voucher_code.last_name_field.placeholder',
            )}
          />
          <StyledInput
            name="email"
            placeholder={translate(
              'sign_up.voucher_code.email_field.placeholder',
            )}
            type="email"
          />
          {locations.length > 1 && (
            <SelectWrapper>
              <FormikSelect
                name="location"
                placeholder={translate(
                  'sign_up.voucher_code.location_field.placeholder',
                )}
                options={locations}
                size={SelectSize.Big}
              />
            </SelectWrapper>
          )}
          {departments.length > 1 && (
            <SelectWrapper>
              <FormikSelect
                name="department"
                placeholder={translate(
                  'sign_up.voucher_code.department_field.placeholder',
                )}
                options={departments}
                size={SelectSize.Big}
              />
            </SelectWrapper>
          )}
          <StyledPasswordInput
            name="password"
            placeholder={translate(
              'sign_up.voucher_code.password_field.placeholder',
            )}
            autoComplete="new-password"
          />
          {props.error ? (
            <FieldErrorWrapper>
              <FieldError message={props.error} />
            </FieldErrorWrapper>
          ) : null}
          <SignUpButton
            data-testid="submit-button"
            type="submit"
            label={translate('sign_up.voucher_code.submit_button.label')}
            disabled={props.loading || !isValid}
            loading={props.loading}
          />
          <StyledTsAndCsStatement partner={props.partner} />
          <BodyText sizes={[typography.fontSizes.fontSize12]}>
            {translate('sign_up.voucher_code.body')}
          </BodyText>
        </Form>
      )}
    </Formik>
  );
};

export default VoucherCodeForm;
