import React from 'react';
import { styled } from 'styles';
import { StyledFormFieldLabel } from '../../../LoggedOut/SignUp/Forms/CommonFormStyledComponents';

const FieldLabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

type AdditionalInformationNeededLabelProps = {
  htmlFor: string;
  id: string;
  labelText: string;
  testId: string;
};

export const AdditionalInformationNeededLabel = ({
  htmlFor,
  id,
  labelText,
  testId,
}: AdditionalInformationNeededLabelProps) => (
  <FieldLabelWrapper>
    <StyledFormFieldLabel id={id} data-testid={testId} htmlFor={htmlFor}>
      {labelText}
    </StyledFormFieldLabel>
  </FieldLabelWrapper>
);
