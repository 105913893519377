import React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { logException } from '../../App/logging';
import { AUTH0_AUDIENCE, AUTH0_LOGIN_SCOPE } from './useUniversalLogin';

// This matches refresh_token.token_lifetime configured in auth0
const SSO_SESSION_EXPIRY_DAYS = 14;

const Auth0SDKReady = ({ children }: { children: React.ReactElement }) => {
  const { isLoading, error } = useAuth0();
  if (isLoading) {
    return null;
  }
  if (error) {
    logException(error);
    /* returning children as i'm unclear on the nature or frequency of these 
    auth0 sdk errors and would like to observe, shouldn't affect non-sso users */
  }

  return <>{children}</>;
};

/**
 * Local wrapper around the Auth0Context.
 * Conditionally renders if on a secure origin, or testing locally.
 */
const UniversalLoginProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => (
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    sessionCheckExpiryDays={SSO_SESSION_EXPIRY_DAYS}
    authorizationParams={{
      redirect_uri: window.location.origin,
      scope: AUTH0_LOGIN_SCOPE,
      audience: AUTH0_AUDIENCE,
    }}
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
    onRedirectCallback={appState => {
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}${
          appState?.returnTo
            ? `?returnto=${encodeURIComponent(appState?.returnTo)}`
            : ''
        }`,
      );
    }}
  >
    <Auth0SDKReady children={children}></Auth0SDKReady>
  </Auth0Provider>
);

export default UniversalLoginProvider;
