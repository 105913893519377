import React from 'react';
import { IconProps } from '../IconProps';
const SvgCheckEmpty = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx={10} cy={10} r={9.5} stroke="#0D0300" strokeOpacity={0.85} />
  </svg>
);
export default SvgCheckEmpty;
