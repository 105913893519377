import { BodyText } from '@unmind/design-system-components-web';
import { ThemeInterface } from '@unmind/design-system-theme';
import Label from 'Shared/Form/Label';
import { Pencil, Text, Clock, Repeat, Link, Close } from 'icons';
import { rem } from 'polished';
import styled from 'styled-components';
import { css } from 'styles';
import { small } from 'utils/breakpoints';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${rem(500)};
  overflow: scroll;

  ${small(css`
    max-height: 100%;
    min-width: ${rem(400)};
  `)}
`;

export const AccessibilityLabel = styled(Label)`
  display: inline-flex;
`;

export const Title = styled(BodyText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  padding: ${rem(16)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(16)};

  > button {
    margin-left: ${rem(16)};
  }
`;

export const PencilIcon = styled(Pencil).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const ClockIcon = styled(Clock).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-top: ${rem(15)};
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const LinkIcon = styled(Link).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-top: ${rem(15)};
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const TextIcon = styled(Text).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const RepeatIcon = styled(Repeat).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(20)};
  height: ${rem(20)};
`;

export const ContentContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  padding: 0 ${rem(16)};
  width: 100%;
`;

export const AddToCalendarField = styled.div<{ multipleFields?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: ${rem(16)} 0;

  align-items: ${({ multipleFields }) =>
    multipleFields ? 'flex-start' : 'center'};

  background-color: transparent;
  border: none;
  width: 100%;

  & > svg {
    margin-right: ${rem(10)};
  }
`;

export const ModalWrapper = styled.div`
  padding: 0;
  border: none;
  z-index: 10;
  margin: ${rem(20)};
  max-height: 90vh;
  overflow: scroll;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  ${small(css`
    position: absolute;
    width: ${rem(400)};
    overflow: hidden;
    max-height: 100vh;
  `)}
`;

export const CloseIcon = styled(Close).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.text.primary,
  }),
)`
  height: ${rem('12px')};
  width: ${rem('12px')};
`;

export const CloseIconButton = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background.card};
  z-index: ${({ theme }) => theme.zIndex.overModal};

  ${small(css`
    position: relative;
  `)}
`;
