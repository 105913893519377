import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesWork = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M20.04 29.886c-3.901 4.86-9.17 3.375-11.293 2.016-10.578-6.707-5.718-23.731 3.736-27.7 8.537-3.568 20.748-.818 20.748 12.14 0 9.317-8.332 7.45-13.192 13.543z"
      fill="#FFD099"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.365 12.68c-.856-.857-1.433-1.777-1.696-2.545-.28-.82-.136-1.22 0-1.356.137-.137.537-.28 1.357 0 .768.262 1.688.84 2.544 1.696.857.856 1.434 1.776 1.697 2.544.28.82.136 1.22 0 1.357-.137.136-.537.28-1.357 0-.768-.262-1.688-.84-2.545-1.696zm-3.214-2.383c.03.11.063.217.099.323.145.424.352.862.615 1.299l-.01.01-9.176 9.158a.673.673 0 0 1-.027-.009c-.169-.055-.41-.195-.65-.436-.244-.244-.388-.486-.445-.654a.625.625 0 0 1-.018-.063.776.776 0 0 0 .044-.076l9.568-9.552zm-7.37 11.81l8.976-8.959a10.382 10.382 0 0 0 1.14 1.14l-9.027 9.01a1.809 1.809 0 0 1-.65-.437c-.243-.244-.387-.487-.444-.655a.633.633 0 0 1-.018-.062.88.88 0 0 0 .023-.037zm2.255 2.146a.754.754 0 0 1-.06.11c.004.016.01.037.019.062.057.168.2.41.444.655.24.24.481.38.65.436l.013.004 9.63-9.63a4.998 4.998 0 0 1-.307-.095 6.819 6.819 0 0 1-1.299-.615.645.645 0 0 1-.011.012l-9.079 9.06zm-4.95-2.412l-.796 3.746 1.163 1.163 3.742-.806a3.16 3.16 0 0 1-.621-1.038 2.04 2.04 0 0 1-.044-.146 2.11 2.11 0 0 1-.13-.039c-.432-.142-.867-.426-1.24-.8-.37-.369-.659-.802-.805-1.234a2.092 2.092 0 0 1-.043-.146 2.11 2.11 0 0 1-.131-.038 3.16 3.16 0 0 1-1.094-.662zm6.138 5.2l-4.508.971.011.012-3.53.746-1.172.252.243-1.141.753-3.563.004.004 1.091-5.128 11.089-11.07a.752.752 0 0 1 .174-.13 1.85 1.85 0 0 1 .23-.276c.765-.765 1.926-.693 2.902-.359 1.028.351 2.138 1.073 3.12 2.055.982.982 1.704 2.092 2.055 3.12.334.976.407 2.137-.359 2.902a1.947 1.947 0 0 1-.305.25.752.752 0 0 1-.119.155L17.24 26.504a.751.751 0 0 1-.158.211c-.068.065-.14.118-.214.16l-.034.035-.052.011a1.31 1.31 0 0 1-.558.12zm9.448-14.37c.246-.246-.052-.944-.667-1.558-.614-.615-1.312-.913-1.559-.667-.246.247.052.945.667 1.56.614.614 1.312.912 1.559.666z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesWork;
