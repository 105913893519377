import styled from 'styled-components';
import { Location } from 'icons';
import { HeaderSlice } from 'Talk/pages/common/styles';

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LocationPin = styled(Location).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 16,
  ['aria-hidden']: true,
}))`
  margin-right: 6px;
`;

export const LocationHeaderSlice = styled(HeaderSlice)`
  justify-content: end;
`;
