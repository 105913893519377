import { large, medium } from '@unmind/design-system-components-web';
import { styled, css } from 'styles';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
  margin-top: 24px;

  ${medium(css`
    flex-wrap: nowrap;
  `)}

  ${large(css`
    gap: 80px;
  `)}
`;

export const Inner = styled.div<{ showTimelineView?: boolean }>`
  flex-direction: column;

  ${large(css`
    flex-direction: row;
    flex: 1;
    min-width: 56%;

    ${({ showTimelineView }: { showTimelineView?: boolean }) =>
      showTimelineView &&
      css`
        // equal to the left-offset of the timeline, plus half the width of the timeline icon
        padding-left: 60px;
      `})}
  `)}
`;

export const UpcomingSessionTimeline = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  ${large(css`
    &:before {
      content: '';
      width: 1px;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.border.secondary};
      height: 100%;
      top: 0;
      left: -40px;
    }
  `)}
`;

export const UpcomingSessionTimelineItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 48px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:not(:last-of-type):before {
    content: '';
    width: 1px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.border.secondary};
    left: 50%;
    top: 100%;
    height: 48px;
  }

  ${large(css`
    margin-bottom: 24px;

    // Hide the top-most and bottom-most timeline lines by overlaying a background-colored line over them
    &:first-of-type:after,
    &:last-of-type:after {
      content: '';
      width: 1px;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.background.primary};
      height: 50%;
      left: -40px;
    }

    &:first-of-type:after {
      top: 0;
    }

    &:last-of-type:after {
      bottom: 0;
    }

    // hide the timeline line which runs through the middle of each item on small screens
    &:not(:last-of-type):before {
      display: none;
    }
  `)}
`;

export const UpcomingSessionTimelineItemIcon = styled(DynamicIcon).attrs(
  ({ theme }) => ({
    primaryColor: theme.colors.text.secondary,
    width: 40,
    height: 40,
    ['aria-hidden']: true,
  }),
)`
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  ${large(css`
    top: auto;
    left: -40px;
    transform: translateX(-50%);
  `)}
`;
