import { transparentize, rem } from 'polished';
import React, { ButtonHTMLAttributes } from 'react';
import { css, styled } from 'styles';
import { Chevron } from 'icons';
import { small } from '../../utils';
import { ScreenReaderContent } from '../Accessibility';
import { buttonReset } from '../Button';

const Button = styled.button<{ isAudioPlayer?: boolean }>`
  ${buttonReset}
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  height: ${rem(36)};
  width: ${rem(36)};
  border: ${rem(2)} solid transparent;
  border-radius: 50%;

  &:hover {
    background-color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.backButton.hover
        : transparentize(0.85, 'white')};
  }

  &:focus {
    outline: none;
    border-color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.borderFocus
        : props.theme.button.borderFocusColor};
  }

  ${small(css`
    height: ${rem(52)};
    width: ${rem(52)};
  `)}
`;

const BackArrow = styled(Chevron).attrs(props => ({
  primaryColor: props.isAudioPlayer
    ? props.theme.colors.mediaPlayer.audio.icon.default
    : props.theme.colors.staticPalette.white,
}))`
  transform: rotate(180deg);
  height: ${rem(16)};
  width: ${rem(10)};

  ${small(css`
    height: ${rem(23)};
    width: ${rem(14)};
  `)}
`;

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: never;
  label: string;
  isAudioPlayer?: boolean;
}

export const BackButton = ({
  className,
  onClick,
  label,
  isAudioPlayer,
  ...props
}: BackButtonProps) => (
  <Button
    className={className}
    onClick={onClick}
    isAudioPlayer={isAudioPlayer}
    {...props}
  >
    <BackArrow isAudioPlayer={isAudioPlayer} />
    <ScreenReaderContent as="span">{label}</ScreenReaderContent>
  </Button>
);
