import RoutePath from 'App/RoutePath';
import { MediaTypes, NavigateToPlayerProps } from './navigateToPlayer';

export const usePlayerParams = (): NavigateToPlayerProps => {
  const searchParams = new URLSearchParams(location.search);
  const mediaSrc = searchParams.get('mediaSrc') || '';
  const title = searchParams.get('title') || '';
  const mediaType = searchParams.get('mediaType') as MediaTypes;

  if (
    !mediaSrc ||
    !mediaType ||
    !Object.values(MediaTypes).includes(mediaType)
  ) {
    location.assign(RoutePath.StartSplitScreen);
  }

  return {
    mediaSrc,
    title,
    mediaType: mediaType,
  };
};
