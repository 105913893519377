import React from 'react';
import { IconProps } from '../IconProps';

const SvgClear = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <g clipPath="url(#Clear_svg__clip0_2248_65387)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.854 10.853a.5.5 0 1 0-.707-.707L16 15.293l-5.147-5.147a.5.5 0 1 0-.707.707L15.293 16l-5.147 5.147a.5.5 0 1 0 .707.707L16 16.707l5.147 5.147a.5.5 0 1 0 .707-.707L16.707 16l5.147-5.147z"
        fill={primaryColor}
        fillOpacity={0.6}
      />
    </g>
    <defs>
      <clipPath id="Clear_svg__clip0_2248_65387">
        <path fill="#fff" transform="translate(10 10)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgClear;
