import React from 'react';
import { IconProps } from '../IconProps';
const SvgProfile = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <g clipPath="url(#Profile_svg__clip0_1909_34336)">
      <path d="M0 14a14 14 0 1028 0 14 14 0 00-28 0z" fill={primaryColor} />
      <path
        d="M9 10a5 5 0 1010 0 5 5 0 00-10 0zM22.486 21c-2.018-2.443-5.07-4-8.486-4a10.978 10.978 0 00-8.486 4c2.018 2.443 5.07 4 8.486 4 3.416 0 6.468-1.557 8.486-4z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <clipPath id="Profile_svg__clip0_1909_34336">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgProfile;
