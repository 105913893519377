import { css, ThemeInterface } from 'styles';
import { InputSize } from '../Form/Input';
import { createFontStyle } from './Base';

export const inputFontStyles = (
  theme: ThemeInterface,
  size: InputSize = InputSize.large,
) => {
  let fontStyle;
  if (size === InputSize.regular) {
    fontStyle = css`
      ${createFontStyle({
        size: theme.typography.fontSizes.fontSize18,
      })}
    `;
  } else {
    fontStyle = css`
      ${createFontStyle({
        size: theme.typography.fontSizes.fontSize18,
      })}
    `;
  }

  return css`
    font-family: inherit;
    ${fontStyle}
    color: ${theme.colors.text.primary};
  `;
};
