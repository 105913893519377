import { BodyText } from '@unmind/design-system-components-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rem } from 'polished';
import { FeatureText } from 'Shared/Typography';
import { small } from 'utils';
import { css } from 'styles';
import { AlternativeHomePageSection } from './Shared/AlternativeHomePageSection/AlternativeHomePageSection';
import UnmindOverview from './Assets/unmind_overview.png';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${small(css`
    padding-right: ${rem(30)};
  `)};
`;

const Title = styled(FeatureText)`
  ${small(css`
    padding-bottom: ${rem(20)};
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${rem(20)};
  width: 100%;

  ${small(css`
    flex-direction: row;
    width: ${rem(1000)};
  `)};
`;

const UnmindImage = styled.img`
  width: 100%;
  margin-top: ${rem(10)};

  ${small(css`
    margin-top: 0;
    width: ${rem(500)};
  `)};
`;

export const Overview = () => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <AlternativeHomePageSection>
      <Container>
        <TextContainer>
          <Title>
            {translate('ms_teams_alternative_start.fourth_section.header')}
          </Title>
          <BodyText>
            {translate('ms_teams_alternative_start.fourth_section.description')}
          </BodyText>
        </TextContainer>
        <UnmindImage src={UnmindOverview} aria-hidden />
      </Container>
    </AlternativeHomePageSection>
  );
};
