import { talkEventRequestsQuery_unmindEventRequests_edges_node as EventRequest } from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQuery';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { AssessmentStatus } from 'Services/BeGateway/__generated__/beGatewayTypes';
import { CircularCheck, CircularClipboard, CircularVideo } from 'icons';
import React, { useState } from 'react';
import { OnboardingContent, UpcomingEvent } from '../../components';
import {
  CompletedCard,
  PromptCard,
  QuestionnaireTakeover,
} from '../../components/OutcomeMeasures';
import { EventType } from '../../components/UpcomingEvent/types';
import {
  Container,
  Inner,
  UpcomingSessionTimeline,
  UpcomingSessionTimelineItem,
  UpcomingSessionTimelineItemIcon,
} from './styles';

interface Props {
  eventType: EventType;
  event?: Event | null;
  eventRequest?: EventRequest | null;
  userID?: string;
  numEvents: number;
}

export const UpcomingSession = ({
  eventType,
  event,
  eventRequest,
  userID,
  numEvents,
}: Props) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const currentUserEventGuest = event?.eventGuests?.find(
    guest => guest?.contact?.unmindUserId === userID,
  );
  const outcomeMeasureRequired =
    currentUserEventGuest?.assessmentStatus &&
    currentUserEventGuest.assessmentStatus !== AssessmentStatus.not_needed;
  const outcomeMeasureCompleted =
    currentUserEventGuest?.assessmentStatus === AssessmentStatus.complete;
  const showOutcomeMeasure = outcomeMeasureRequired || outcomeMeasureCompleted;

  return (
    <Container>
      <Inner showTimelineView={showOutcomeMeasure}>
        {showOutcomeMeasure ? (
          <>
            <UpcomingSessionTimeline>
              {outcomeMeasureCompleted ? (
                <UpcomingSessionTimelineItem>
                  <UpcomingSessionTimelineItemIcon Icon={CircularCheck} />
                  <CompletedCard />
                </UpcomingSessionTimelineItem>
              ) : (
                <UpcomingSessionTimelineItem>
                  <UpcomingSessionTimelineItemIcon Icon={CircularClipboard} />
                  <PromptCard
                    onQuestionnaireStart={() => setShowQuestionnaire(true)}
                  />
                </UpcomingSessionTimelineItem>
              )}

              <UpcomingSessionTimelineItem>
                <UpcomingSessionTimelineItemIcon Icon={CircularVideo} />
                <UpcomingEvent
                  numEvents={numEvents}
                  eventType={eventType}
                  event={event}
                  eventRequest={eventRequest}
                  defocused={!outcomeMeasureCompleted}
                />
              </UpcomingSessionTimelineItem>
            </UpcomingSessionTimeline>

            {event ? (
              <QuestionnaireTakeover
                event={event}
                showQuestionnaire={showQuestionnaire}
                onClose={() => setShowQuestionnaire(false)}
              />
            ) : null}
          </>
        ) : (
          <UpcomingEvent
            numEvents={numEvents}
            eventType={eventType}
            event={event}
            eventRequest={eventRequest}
          />
        )}
      </Inner>
      <OnboardingContent />
    </Container>
  );
};
