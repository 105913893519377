import { Colors, FontSizes } from '@unmind/design-system-theme';
import React, { forwardRef } from 'react';
import { styled, useTheme } from 'styles';
import Base, { Props as BaseTextProps } from './Base';

export interface Props extends Omit<BaseTextProps, 'sizes' | 'color'> {
  sizes?: FontSizes[];
  color?: Colors;
  /**
   * Not for manual use - this is used internally by Styled Components.
   * If you're trying to change the rendered type of this component, use `forwardedAs` instead.
   */
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

const StyledNationalText = styled(Base)`
  font-family: ${({ theme }) => theme.typography.nationalText.fontFamily};
  line-height: 75%;
`;

const NationalText = forwardRef<HTMLElement, Props>(
  ({ sizes, color, className, children, as, ...props }, ref) => {
    const {
      colors,
      typography: { fontSizes },
    } = useTheme();

    return (
      <StyledNationalText
        sizes={
          sizes?.length
            ? sizes
            : [
                fontSizes.fontSize96,
                fontSizes.fontSize96,
                fontSizes.fontSize112,
                fontSizes.fontSize140,
              ]
        }
        color={color ?? colors.text.primary}
        ref={ref}
        className={className}
        forwardedAs={as}
        {...props}
      >
        {children}
      </StyledNationalText>
    );
  },
);

export default NationalText;
