import { Colors } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { styled } from 'styles';
import { createFontStyle } from '../../../Shared/Typography/Base';
import BodyText from '../../../Shared/Typography/BodyText';

interface NumberedListItemProps {
  numberColor: Colors;
}
export const NumberedListItem = styled(BodyText).attrs(() => ({
  forwardedAs: 'li',
}))<NumberedListItemProps>`
  counter-increment: counter;
  display: flex;
  align-items: center;

  &::before {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize12,
        weight: theme.typography.fontWeights.medium,
      })};
    background: ${props => props.numberColor};
    border-radius: 50%;
    color: ${props => props.theme.colors.background.primary};
    content: counter(counter);
    flex-shrink: 0;
    height: ${rem(20)};
    margin-right: ${rem(8)};
    text-align: center;
    min-width: ${rem(20)};

    /* visually center the number vertically */
    line-height: 1.2;
    padding: ${rem(3)} 0 ${rem(4)};
  }

  & + & {
    margin-top: ${rem(4)};
  }
`;

export const NumberedList = styled.ol`
  counter-reset: counter;
  list-style: none;
  margin: 0;
  padding: 0;
`;
