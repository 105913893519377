/**
 * This is the clarity tracking code.
 * It adds a script to the head.
 * It comes from https://clarity.microsoft.com/projects/view/hxl6qv907t/settings/trackingCode
 */

export default function (id) {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.id = 'clarity-script';
    t.async = 1;
    // eslint-disable-next-line prefer-template, @typescript-eslint/restrict-plus-operands
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', id);
}
