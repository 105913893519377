import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled, useTheme } from 'styles';
import { ExternalLink, BodyText } from '../Shared/Typography';

const DisclaimerText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
  ],
}))`
  padding: ${rem(16)};
  border: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  border-radius: ${({ theme }) => rem(theme.layout.borderRadii.radius4)};
`;

export default function HelpDisclaimer() {
  const { t: translate } = useTranslation<Namespace<'help'>>('help');
  const theme = useTheme();

  return (
    <DisclaimerText>
      {Trans({
        t: translate,
        i18nKey: 'help_disclaimer.disclaimer_text',
        components: {
          link_text: (
            <ExternalLink
              href={translate('help_disclaimer.support_page_link.url')}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={translate(
                'help_disclaimer.support_page_link.a11y_label',
              )}
              sizes={[
                theme.typography.fontSizes.fontSize12,
                theme.typography.fontSizes.fontSize12,
                theme.typography.fontSizes.fontSize12,
                theme.typography.fontSizes.fontSize14,
              ]}
            />
          ),
        },
        defaults:
          'While we regularly check the links and information provided on these pages, we can’t always ensure their accuracy. Please <link_text>submit a request</link_text> to our Support Team if something seems wrong or a link is broken.',
      })}
    </DisclaimerText>
  );
}
