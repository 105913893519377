import React from 'react';
import { IconProps } from '../IconProps';
const SvgLegal = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.481h2.51v1.186H0V9.48zM1.88 6.273L3.708 8l-.887.838L.992 7.111l.888-.838z"
      fill="#0D0300"
    />
    <path
      d="M14.743 10.472L9.93 5.926l1.255-1.185 4.813 4.546-1.255 1.185z"
      fill="#0D0300"
    />
    <path
      d="M15.22 3.771L11.227 0l-.963.91a1.153 1.153 0 00-.368.838v.102a.576.576 0 01-.184.419L7.418 4.436a.647.647 0 01-.444.173h-.108c-.332 0-.652.125-.887.347l-.963.91 3.993 3.772.963-.91c.236-.223.368-.524.368-.838v-.102c0-.157.066-.308.184-.42l1.147-1.083 1.147-1.083a.646.646 0 01.444-.174h.107c.333 0 .652-.125.888-.347l.963-.91zM1.882 11.852v1.185c-1.04 0-1.882.796-1.882 1.778V16h13.804v-1.185c0-.982-.843-1.778-1.882-1.778v-1.185H1.882z"
      fill="#0D0300"
    />
  </svg>
);
export default SvgLegal;
