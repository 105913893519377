import { rem, transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Repeat } from 'icons';
import { isDesktop } from 'react-device-detect';
import { large, small } from '../../utils';
import { ScreenReaderContent } from '../Accessibility';
import { buttonReset } from '../Button';
import MediaButtonTooltip from './MediaButtonTooltip';

const Button = styled.button<{ loop: boolean; isAudioPlayer?: boolean }>`
  ${buttonReset}
  background-color: ${({ loop, theme }) =>
    loop
      ? transparentize(0.2, theme.button.primary.default.active.backgroundColor)
      : 'transparent'};
  border-radius: 100%;
  color: ${({ loop, theme, isAudioPlayer }) =>
    loop
      ? isAudioPlayer
        ? theme.colors.mediaPlayer.audio.icon.default
        : transparentize(0.2, theme.colors.text.primary)
      : isAudioPlayer
      ? theme.colors.mediaPlayer.audio.icon.default
      : transparentize(0.2, theme.colors.staticPalette.white)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  position: relative;

  ${small(css`
    height: ${rem(20)};
    width: ${rem(20)};
  `)}

  &:focus,
  &:hover {
    background-color: ${({ loop, theme }) =>
      loop
        ? transparentize(
            0.2,
            theme.button.primary.default.active.backgroundColor,
          )
        : 'transparent'};

    color: ${({ loop, theme, isAudioPlayer }) =>
      loop
        ? isAudioPlayer
          ? theme.colors.mediaPlayer.audio.icon.hover
          : transparentize(0.2, theme.colors.text.primary)
        : isAudioPlayer
        ? theme.colors.mediaPlayer.audio.icon.hover
        : transparentize(0.2, theme.colors.staticPalette.white)};
  }

  &:focus {
    outline: ${({ theme, isAudioPlayer }) =>
      css`
        ${rem(2)} solid ${isAudioPlayer
          ? theme.colors.mediaPlayer.audio.borderFocus
          : theme.button.borderFocusColor}
      `};
  }
`;

const RepeatIcon = styled(Repeat).attrs({
  primaryColor: 'currentColor',
})`
  height: 70%;
  width: 70%;

  ${small(css`
    height: 85%;
    width: 85%;
  `)}

  ${large(css`
    height: 100%;
    width: 100%;
  `)}
`;

interface LoopButtonProps {
  loop: boolean;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  isAudioPlayer?: boolean;
}

export function LoopButton({ loop, onClick, isAudioPlayer }: LoopButtonProps) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const { t: translate } = useTranslation('media_player');

  return (
    <>
      <Button
        onMouseEnter={() => (isDesktop ? setTooltipVisible(true) : null)}
        onMouseLeave={() => setTooltipVisible(false)}
        loop={loop}
        onClick={onClick}
        isAudioPlayer={isAudioPlayer}
      >
        <RepeatIcon />
        <ScreenReaderContent as="span">
          {loop
            ? translate('controls.loop_button.accessibility_label.disable')
            : translate('controls.loop_button.accessibility_label.enable')}
        </ScreenReaderContent>
        {isTooltipVisible && (
          <MediaButtonTooltip
            label={
              loop
                ? translate('controls.loop_button.tooltip.is_enabled')
                : translate('controls.loop_button.tooltip.is_disabled')
            }
            minWidth={116}
            isLeftOffset={true}
          />
        )}
      </Button>
    </>
  );
}
