import { useQuery } from '@apollo/client';
import { rem } from 'polished';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import BodyText from '../../../Shared/Typography/BodyText';
import { IndexScore } from '../types/types';
import { UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands } from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import { getUserIndexInsights_userIndexInsights_categories } from './__generated__/getUserIndexInsights';
import WellbeingTrackerLineChartWrapper from './WellbeingTrackerLineChartWrapper';
import CrisisHelpDisclaimer from './CrisisHelpDisclaimer';

const ContentParagraph = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
  ],
  forwardedAs: 'div',
}))`
  p,
  li {
    overflow-wrap: break-word;
  }
  li:not(:last-child):before {
    border-bottom: none;
  }
  margin-bottom: ${rem(24)};
`;

const ErrorText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  color: ${({ theme }) => theme.colors.text.error};
  text-align: center;
`;

const CRISIS_MESSAGING_CATEGORIES = ['coping', 'happiness'];

export const INDEX_CATEGORY_CONTENT_QUERY = gql`
  query IndexRecommendation($input: IndexRecommendationInput!) {
    indexRecommendation(input: $input) {
      id
      content
    }
  }
`;

export interface CategoryContentProps
  extends Pick<
    getUserIndexInsights_userIndexInsights_categories,
    'name' | 'band'
  > {
  bands: TrackerBands[];
  categoryNameKey: string;
  scoreOverTime: IndexScore[];
  handleContentClick(event: React.MouseEvent<HTMLSpanElement>): void;
}

const CategoryContent = ({
  handleContentClick,
  scoreOverTime,
  name,
  categoryNameKey,
  band,
  bands,
}: CategoryContentProps) => {
  const { t: translate } = useTranslation('track');

  const { loading, data, error } = useQuery(INDEX_CATEGORY_CONTENT_QUERY, {
    variables: {
      input: {
        band: band,
        category: categoryNameKey,
      },
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <ErrorText>
        {translate('wellbeing.category_item.error_message')}
      </ErrorText>
    );
  }

  return (
    <>
      <ContentParagraph
        onClick={handleContentClick}
        dangerouslySetInnerHTML={{
          __html: data.indexRecommendation.content,
        }}
      />
      {scoreOverTime[0].value <= 65 &&
      CRISIS_MESSAGING_CATEGORIES.includes(categoryNameKey) ? (
        <CrisisHelpDisclaimer />
      ) : null}
      <WellbeingTrackerLineChartWrapper
        data={scoreOverTime}
        category={name}
        bands={bands}
      />
    </>
  );
};

export default CategoryContent;
