import { rem } from 'polished';
import React from 'react';
import { useHistory } from 'react-router';
import { animated, Spring } from 'react-spring/renderprops';
import { styled } from 'styles';
import { tracking } from '../../../App/Tracking';
import BodyText from '../../../Shared/Typography/BodyText';
import urlToFeature from '../../../Shared/urlToFeature';
import { IndexScore } from '../types/types';
import { UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands } from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import CategoryContent from './CategoryContent';
import CategoryHeader from './CategoryHeader';
import { Category, SetOpenCategoryName } from './CategoryTypes';

const CategoryContentContainer = styled(animated.div)`
  padding: 28px 24px 40px 24px;
`;

const CategorySubtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: theme.typography.fontWeights.bold,
  forwardedAs: 'p',
}))`
  margin: ${rem(12)} 0 ${rem(8)};
  flex-basis: 100%;
  text-align: justify;
`;

const ListItem = styled.li``;

const showContent = { opacity: 1, transform: 'translate3d(0, 0, 0)' };
const hideContent = { opacity: 0, transform: 'translate3d(0, 12px, 0)' };

export interface CategoryItemProps extends Category {
  band: string;
  categoryNameKey: string;
  shortDescription: string;
  isOpen: boolean;
  setOpenCategoryName: SetOpenCategoryName;
  scoreOverTime: IndexScore[];
  bands: TrackerBands[];
}

const CategoryItem = ({
  name,
  band,
  categoryNameKey,
  isOpen,
  score,
  shortDescription,
  setOpenCategoryName,
  scoreOverTime,
  bands,
}: CategoryItemProps) => {
  const history = useHistory();

  const handleContentClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    // From the element we clicked on, walk up the DOM tree looking for a
    // relative link.
    let target: HTMLElement | null = event.target as HTMLElement;
    while (target && target !== event.currentTarget) {
      const url = target.getAttribute('href');
      if (url) {
        tracking.track('index-results-recommendation-clicked', {
          subscale: name,
          url,
          feature: urlToFeature(url),
        });

        // For relative links navigate without a full page reload.
        if (url.startsWith('/')) {
          history.push(url);
          event.preventDefault();
        }

        return;
      }

      target = target.parentElement;
    }
  };

  return (
    <ListItem>
      <CategoryHeader
        name={name}
        categoryNameKey={categoryNameKey}
        score={score}
        isOpen={isOpen}
        onClick={() => {
          if (!isOpen) {
            setOpenCategoryName(name);
            tracking.track('index-results-subscale-view-clicked', {
              subscale: name,
            });
          } else {
            setOpenCategoryName(undefined);
          }
        }}
        bands={bands}
      />
      <Spring to={isOpen ? showContent : hideContent}>
        {contentTransition =>
          isOpen && (
            <CategoryContentContainer
              style={contentTransition}
              data-testid="index-category-content"
            >
              <CategorySubtitle>{shortDescription}</CategorySubtitle>
              <CategoryContent
                handleContentClick={handleContentClick}
                scoreOverTime={scoreOverTime}
                name={name}
                band={band}
                bands={bands}
                categoryNameKey={categoryNameKey}
              />
            </CategoryContentContainer>
          )
        }
      </Spring>
    </ListItem>
  );
};

export default CategoryItem;
