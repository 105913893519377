import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { BodyText, medium } from '@unmind/design-system-components-web';
import { Link } from 'react-router-dom';
import { RIGHT_CONTAINER_DESKTOP_PX_WIDTH } from 'Compass/Components/CompassDashboard/styles';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.card};
  transition: background-color 0.5s;
  border-radius: ${rem(20)};
  margin: ${rem(0)} ${rem(16)} ${rem(40)} ${rem(16)};
  position: fixed;
  overflow-y: auto;
  max-height: 80vh;

  ${medium(
    css`
      /* Hide scrollbar but keep functionality */

      ::-webkit-scrollbar {
        /* Chrome, Safari and Opera  */
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      width: ${rem(RIGHT_CONTAINER_DESKTOP_PX_WIDTH)};
    `,
  )}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(24)};

  & > div:not(:last-of-type) {
    border-bottom: ${({ theme }) =>
      `${rem(2)} solid ${theme.colors.background.secondary}`};
  }

  & > div {
    padding-top: ${rem(32)};
    padding-bottom: ${rem(60)};
  }
`;

const sharedContainerStyles = `
  padding: ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  ${sharedContainerStyles};
  border-bottom: ${({ theme }) =>
    `${rem(2)} solid ${theme.colors.background.secondary}`};
`;

export const Footer = styled.div`
  ${sharedContainerStyles};
  border-top: ${({ theme }) =>
    `${rem(2)} solid ${theme.colors.background.secondary}`};
`;

export const StatusBadge = styled.div<{ color: string }>`
  width: ${rem(13)};
  height: ${rem(13)};
  min-width: ${rem(13)};
  min-height: ${rem(13)};
  background-color: ${({ color }) => color};
  border-radius: ${rem(100)};
  align-self: center;
  margin-right: ${rem(12)};
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & > div:not(:first-of-type) {
    padding-top: ${rem(64)};
  }
`;

export const CardText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.secondary,
}))`
  white-space: pre-line;
  text-wrap: wrap;
  margin-top: ${rem(16)};
`;

export const CardDistributionContainer = styled.div`
  width: 100%;
`;

export const CardTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))``;

const BaseStyledComponent = `
  text-decoration: underline;
  margin-right: ${rem(4)};
`;

export const CardLink = styled(Link)`
  ${BaseStyledComponent};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const ChangeSalaryButton = styled.div`
  ${BaseStyledComponent};
  display: inline-block;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.text.secondary};
  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;
