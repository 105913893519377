import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves3 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M340 110.953V0H0v107.439c84.74-73.109 142.323-34.117 191.386-.894 19.718 13.351 38.059 25.771 56.234 29.61 41.652 8.796 67.254-7.122 92.38-25.202z"
      fill={primaryColor}
      style={{
        mixBlendMode: 'multiply',
      }}
    />
  </svg>
);

export default SvgWaves3;
