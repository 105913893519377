import { UrlQueryParams } from 'utils/urlHelpers';
import { LocalStorageKey } from 'utils/localStorage';

/**
 * Used for preserving auth tokens during subdomainless login.
 * i.e. when redirecting from search to org subdomains.
 * This is necessary because of limitations with accessing
 * local storage for different subdomains. e.g. local storage set on
 * search.unmind.com not accessible from uat.unmind.com
 */
export const storeRedirectTokensAndCleanUrl = () => {
  const url = new URL(window.location.href);
  const isSearchRedirect = url.searchParams.get(UrlQueryParams.SEARCH_REDIRECT);

  if (isSearchRedirect) {
    url.searchParams.delete(UrlQueryParams.SEARCH_REDIRECT);

    const token = url.searchParams.get(UrlQueryParams.TOKEN);
    const irisToken = url.searchParams.get(UrlQueryParams.IRIS_TOKEN);
    if (token) {
      localStorage.setItem(LocalStorageKey.TOKEN, token);
      url.searchParams.delete(UrlQueryParams.TOKEN);
    }

    if (irisToken) {
      localStorage.setItem(LocalStorageKey.IRIS_TOKEN, irisToken);
      url.searchParams.delete(UrlQueryParams.IRIS_TOKEN);
    }

    if (token || irisToken) {
      window.history.replaceState({}, document.title, url.toString());
    }
  }
};
