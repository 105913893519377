import { rem, rgba } from 'polished';
import React, { HTMLAttributes, ReactElement, useMemo, useState } from 'react';
import { Manager as LayoutManager, Popper, Reference } from 'react-popper';
import { css, styled } from 'styles';
import { small } from '../../utils';
import useHandleEscapeKeyPress from '../../Shared/MediaPlayerComponents/useHandleEscapeKeyPress';
import BodyText from '../Typography/BodyText';

const OPACITY = 0.9;
const ARROW_HEIGHT = 9;

const Container = styled.div`
  position: relative;
`;

const Popover = styled.div<{ visible: boolean; placement: TooltipPlacement }>`
  ${({ visible, placement }) => {
    const isRightAligned = placement === 'right';

    return css`
      display: ${visible ? 'block' : 'none'};
      padding: ${isRightAligned ? '0' : `${rem(ARROW_HEIGHT)} 0`};
      z-index: ${({ theme }) => theme.zIndex.modal};
      ${isRightAligned &&
      css`
        margin-top: ${rem(60)};

        ${small(css`
          margin-left: ${rem(10)};
          margin-top: ${rem(35)};
        `)}
      `};
    `;
  }}
`;

const Arrow = styled.div`
  position: absolute;
  width: 2em;
  height: 1em;
  font-size: ${rem(ARROW_HEIGHT)};

  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
    margin-top: -0.9em;

    &::before {
      border-width: 1em 1em 0 1em;
      border-top-color: ${({ theme }) =>
        rgba(theme.colors.border.primary, OPACITY)};
    }
  }

  &[data-placement*='bottom'] {
    top: 0;
    left: 0;
    margin-bottom: -0.9em;

    &::before {
      border-width: 0 1em 1em 1em;
      border-bottom-color: ${({ theme }) =>
        rgba(theme.colors.border.primary, OPACITY)};
    }
  }

  &[data-placement*='right'] {
    top: 0;
    left: 0;
    margin-left: -1.5em;
    margin-top: -${rem(5)};

    &::before {
      border-width: 1em 1em 1em 0;
      border-right-color: ${({ theme }) =>
        rgba(theme.colors.border.primary, OPACITY)};
    }
  }

  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }
`;

const Label = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.staticPalette.white,
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'div',
}))`
  max-width: calc(100vw - ${rem(12)});
  padding: ${rem(16)};
  border-radius: 3px;
  background: ${({ theme }) => rgba(theme.colors.background.inverse, OPACITY)};
`;

export const TooltipLabel = styled.div`
  width: ${rem(320)};
  max-width: 100%;
`;

/**
 * Available placement directions for `Tooltip`. This is a subset of placements
 * supported by react-popper.
 */
export type TooltipPlacement = 'top' | 'bottom' | 'right';

export type TooltipProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Whether the tooltip popover should be open. If set, the component will be
   * fully-controlled.
   */
  open?: boolean;

  /**
   * The base placement direction for the tooltip popover, which may be inverted
   * if near the viewport edge. Defaults to 'top'.
   */
  placement?: TooltipPlacement;

  /**
   * A unique ID used to accessibly identify the tooltip label element.
   */
  accessibilityId?: string;

  /**
   * String displayed in tooltip popup
   */
  labelCopy: string;

  /**
   * An Element reference in which the tooltip will stay bounded within.
   */
  overflowBoundaryRef?: Element;

  children: ReactElement;
};

/**
 * A hook providing `open` state for a `Tooltip` and a default set of event
 * handlers to assign to the containing element that should control its
 * visibility.
 */
export const useTooltipState = (
  /**
   * Whether the tooltip is initially open.
   */
  initialState = false,
) => {
  const [open, setOpen] = useState(initialState);

  return {
    open,
    setOpen,
    handlers: useMemo(
      () => ({
        onMouseEnter: () => {
          setOpen(true);
        },
        onMouseLeave: () => {
          setOpen(false);
        },
        onFocus: () => {
          setOpen(true);
        },
        onBlur: () => {
          setOpen(false);
        },
      }),
      [setOpen],
    ),
  };
};

/**
 * A component that reveals a popout message when a user interacts with or
 * focuses on an element.
 */
const Tooltip = ({
  placement: requestedPlacement = 'top',
  overflowBoundaryRef,
  accessibilityId,
  children,
  labelCopy,
  ...props
}: TooltipProps) => {
  const { open, setOpen, handlers } = useTooltipState();
  const accessibilityDescribedById = `${accessibilityId}-described-by`;

  useHandleEscapeKeyPress(() => {
    setOpen(false);
  });

  return (
    <LayoutManager>
      <Reference>
        {({ ref }) => (
          <Container
            {...(typeof props.open === 'boolean' ? null : handlers)}
            {...props}
            ref={ref}
            data-testid={`${accessibilityId}-popper-activator`}
          >
            {React.cloneElement(children, {
              'aria-labelledby': accessibilityId,
              'aria-describedby': accessibilityDescribedById,
              tabIndex: 0,
              role: 'button',
            })}
          </Container>
        )}
      </Reference>
      <Popper
        placement={requestedPlacement}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: overflowBoundaryRef,
          },
        }}
      >
        {({ ref, style, placement, arrowProps }) => (
          <Popover
            ref={ref}
            style={style}
            data-placement={placement}
            visible={typeof props.open === 'boolean' ? props.open : open}
            placement={requestedPlacement}
            data-testid={`${accessibilityId}-popover`}
          >
            <Label aria-hidden="true" role="tooltip" id={accessibilityId}>
              <TooltipLabel id={accessibilityDescribedById}>
                {labelCopy}
              </TooltipLabel>
            </Label>
            <Arrow {...arrowProps} data-placement={placement} />
          </Popover>
        )}
      </Popper>
    </LayoutManager>
  );
};

export default Tooltip;
