import React from 'react';
import { IconProps } from '../IconProps';
const SvgPhone = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 12 12" fill="none" width="1em" height="1em" {...props}>
    <g clipPath="url(#Phone_svg__a)">
      <path
        d="M4.248 7.751a8.615 8.615 0 004.902 2.443.585.585 0 00.646-.431l.385-1.485a.582.582 0 00-.327-.678l-1.132-.503a.581.581 0 00-.69.168L6.797 8.673a8.634 8.634 0 01-3.47-3.47l1.407-1.236a.581.581 0 00.168-.69L4.4 2.146a.582.582 0 00-.678-.327l-1.485.385a.585.585 0 00-.432.646A8.614 8.614 0 004.25 7.751z"
        fill={primaryColor}
        stroke={primaryColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Phone_svg__a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPhone;
