import React, { ReactNode } from 'react';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import * as S from './styles';

interface CompassFurtherInfoPageProps {
  children: ReactNode;
  title: string;
}

export const CompassFurtherInfoPage = ({
  children,
  title,
}: CompassFurtherInfoPageProps) => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');

  return (
    <S.PageContainer>
      <S.LayoutContainer>
        <S.TitleContainer>
          <S.LinkWrapper
            to={RoutePath.Compass}
            $a11yLabel={translate('compass_info_page.back_link.a11y_label')}
          >
            <S.BackArrow />
          </S.LinkWrapper>
          <S.HeadingOne>{title}</S.HeadingOne>
        </S.TitleContainer>
        <S.ContentsContainer>{children}</S.ContentsContainer>
      </S.LayoutContainer>
    </S.PageContainer>
  );
};
