import React, { useRef } from 'react';
import { Button, DayProps, useDayRender } from 'react-day-picker';
import { formatDate } from 'Talk/lib/dates';

export const DayPickerDay = (props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

  if (dayRender.isHidden) {
    return <div role="button"></div>;
  }

  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <Button
      ref={buttonRef}
      {...dayRender.buttonProps}
      // We have to explicitly unset `role` here otherwise `react-day-picker` will give it `role="griditem"` which leads to poor screen reader usage
      role={undefined}
      aria-label={formatDate(props.date, 'EEEE, PP') ?? undefined}
      aria-disabled={dayRender.buttonProps.disabled ? 'true' : 'false'}
    />
  );
};
