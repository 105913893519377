import { useCallback, useEffect, useRef } from 'react';

export const useTimeout = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(
    () => () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const internalTimeout = useCallback((cb: () => unknown, interval: number) => {
    timeoutRef.current = setTimeout(cb, interval);
  }, []);

  return {
    timeout: internalTimeout,
  };
};
