import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';
import { styled } from 'styles';
import { isMSTeams } from 'utils/MSTeams';
import { isEmbeddedTeamsMobileFrame } from 'utils/MSTeams/MSTeamsHelpers';
import {
  ANDROID_APP_STORE_BADGE_PREFIX,
  IOS_APP_STORE_BADGE_PREFIX,
} from 'i18n/assetPrefixes';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';
import { useIsVirginPulseGroup } from '../../LoggedOut/VirginPulse/useIsVirginPulseGroup';
import { FullScreenTakeover } from '../../Shared/FullScreenTakeover';
import { SecondaryButton } from '../../Shared/SecondaryButton';
import { HeadingText, BodyText } from '../../Shared/Typography';
import { tracking } from '../Tracking';
import AppIcon from './assets/AppIcon';
import getIgnoreFromPath, { getLocationInstance } from './getIgnoreFromPath';
import getPlatform, { getNavigatorInstance, Platform } from './getPlatform';
import OverlayBackground from './OverlayBackground';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const CentralisedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: center;
  margin-bottom: ${rem(24)};
  max-width: ${rem(154)};
`;

const Body = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: center;
  margin-bottom: ${rem(28)};
  max-width: ${rem(190)};
`;

const ContinueButton = styled(SecondaryButton)`
  border-color: transparent;
`;

export const Link = styled.a`
  text-decoration: none;
  margin-bottom: ${rem(5)};
`;

const AppleBadgeWrapper = styled.img`
  width: ${rem(144)};
  height: auto;
`;

const GoogleBadgeWrapper = styled.img`
  width: ${rem(160)};
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

export interface StoreOverlayProps {
  platform: Platform;
  isIgnoredPath: boolean;
}

export const STORE_OVERLAY_DISMISSED_KEY = 'store-overlay-dismissed';

const trackGoToStore = (store: string) => {
  tracking.track('mobile-web-overlay-clicked', {
    action: 'go-to-store',
    store,
  });
};

export const StoreLink = ({
  platform,
  downloadLinkRef,
}: {
  platform: Platform;
  downloadLinkRef: React.RefObject<HTMLAnchorElement>;
}) => {
  const { t: translate } = useTranslation('shared');
  const {
    [ANDROID_APP_STORE_BADGE_PREFIX]: androidStoreBadge,
    [IOS_APP_STORE_BADGE_PREFIX]: iosStoreBadge,
  } = useTranslatedAssets(
    [IOS_APP_STORE_BADGE_PREFIX, ANDROID_APP_STORE_BADGE_PREFIX],
    'svg',
  );

  if (androidStoreBadge === undefined || iosStoreBadge === undefined) {
    return null;
  }

  switch (platform) {
    case 'apple': {
      return (
        <Link
          ref={downloadLinkRef}
          href={translate('store_link.apple.link')}
          onClick={() => {
            trackGoToStore(platform);
          }}
          aria-label={translate('store_link.apple.a11y_label')}
          target="_blank"
        >
          <AppleBadgeWrapper
            src={iosStoreBadge}
            alt={translate('store_link.apple.image_alt')}
          />
        </Link>
      );
    }
    case 'android': {
      return (
        <Link
          ref={downloadLinkRef}
          href={translate('store_link.google.link')}
          onClick={() => {
            trackGoToStore(platform);
          }}
          aria-label={translate('store_link.google.a11y_label')}
          target="_blank"
        >
          <GoogleBadgeWrapper
            src={androidStoreBadge}
            alt={translate('store_link.google.image_alt')}
          />
        </Link>
      );
    }
    default:
      return null;
  }
};

export const StoreOverlay = ({
  isIgnoredPath,
  platform,
}: StoreOverlayProps) => {
  let dismissedBefore = false;
  try {
    dismissedBefore =
      localStorage.getItem(STORE_OVERLAY_DISMISSED_KEY) !== null ? true : false;
  } catch (e) {
    console.error(e);
  }

  const [dismissed, setDismissed] = useState(dismissedBefore);

  const persistDismiss = () => {
    try {
      setDismissed(true);
      localStorage.setItem(STORE_OVERLAY_DISMISSED_KEY, 'true');
    } catch (e) {
      console.error(e);
    }
  };

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const { t: translate } = useTranslation('shared');

  const isDesktop = platform === 'desktop';

  const { isVirginPulseGroup, loading: loadingIsVirginPulseGroup } =
    useIsVirginPulseGroup(isDesktop);

  const isWithinTeams = isEmbeddedTeamsMobileFrame() || isMSTeams();

  const showOverlay =
    !isDesktop &&
    !dismissed &&
    !isIgnoredPath &&
    !isVirginPulseGroup &&
    !isWithinTeams;

  const closeHandler = () => {
    persistDismiss();
    tracking.track('mobile-web-overlay-clicked', { action: 'dismissed' });
  };

  const focusDownloadLink = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.focus();
    }
  };

  if (loadingIsVirginPulseGroup) {
    return null;
  }

  focusDownloadLink();

  return (
    <FullScreenTakeover shown={showOverlay} showCloseButton={false}>
      <OverlayBackground />
      <Container>
        <CentralisedContent>
          <AppIcon />
          <Title>{translate('store_overlay.title')}</Title>
          <Body>{translate('store_overlay.body')}</Body>
          <StoreLink platform={platform} downloadLinkRef={downloadLinkRef} />
          <ContinueButton
            data-testid="continue-button"
            label={translate('store_overlay.continue_button.label')}
            onClick={closeHandler}
          />
        </CentralisedContent>
      </Container>
    </FullScreenTakeover>
  );
};

export default compose<StoreOverlayProps, Record<string, unknown>>(
  withProps(() => ({
    platform: getPlatform(getNavigatorInstance()),
    isIgnoredPath: getIgnoreFromPath(getLocationInstance()),
  })),
)(StoreOverlay);
