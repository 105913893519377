import { rem } from 'polished';
import styled, { css } from 'styled-components';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';
import { BodyText } from '@unmind/design-system-components-web';
import { Form } from 'formik';

const textOverflow = css`
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BookingFlowContainer = styled(Form)`
  display: grid;
  height: 100%;
  grid-template-rows: ${rem(64)} 1fr;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  padding: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(16)};
  height: ${rem(64)};
  padding: 0 ${rem(16)};
  margin-bottom: ${rem(24)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;

export const PractitionerTitleText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
  tabIndex: -1,
}))`
  ${textOverflow}
`;

export const StyledIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: rem(18),
  height: rem(18),
}))``;

export const BookingAlertContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;
