import React from 'react';
import { IconProps } from '../IconProps';

const SvgSleepyMoon = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 52 52" width="1em" height="1em" {...props}>
    <path
      fill={primaryColor}
      d="M1.5 26C1.5 12.47 12.47 1.5 26 1.5h.091a1.5 1.5 0 0 1 0 3c-.426 0-1.378.255-2.68 1.003-1.248.716-2.664 1.798-3.996 3.225-2.66 2.85-4.915 7-4.915 12.272 0 9.113 7.387 16.5 16.5 16.5 5.273 0 9.422-2.255 12.272-4.915 1.427-1.332 2.509-2.748 3.225-3.997.748-1.301 1.003-2.253 1.003-2.679a1.5 1.5 0 1 1 3 .056V26c0 13.531-10.969 24.5-24.5 24.5C12.47 50.5 1.5 39.531 1.5 26zm44.356 8.26c-.174.174-.353.347-.537.518C42.033 37.846 37.182 40.5 31 40.5c-10.77 0-19.5-8.73-19.5-19.5 0-6.182 2.655-11.033 5.722-14.319.171-.184.344-.363.518-.537C9.966 9.382 4.5 17.053 4.5 26c0 11.874 9.626 21.5 21.5 21.5 8.947 0 16.618-5.466 19.856-13.24zM40 4.5a1.5 1.5 0 0 1 0-3h9a1.5 1.5 0 0 1 1.06 2.56l-6.439 6.44H49a1.5 1.5 0 0 1 0 3h-9a1.5 1.5 0 0 1-1.06-2.56l6.439-6.44zm-5 15a1.5 1.5 0 0 1 0-3h6a1.5 1.5 0 0 1 1.06 2.56l-3.439 3.44H41a1.5 1.5 0 0 1 0 3h-6a1.5 1.5 0 0 1-1.06-2.56l3.439-3.44zm-11 6a1.5 1.5 0 0 1 0-3h5a1.5 1.5 0 0 1 1.06 2.56l-2.439 2.44H29a1.5 1.5 0 0 1 0 3h-5a1.5 1.5 0 0 1-1.06-2.56l2.439-2.44z"
    />
  </svg>
);

export default SvgSleepyMoon;
