import Cookies from 'js-cookie';
import { isMSTeams } from './MSTeams';

const inFifteenMinutes = new Date(Date.now() + 15 * 60 * 1000);

const cookieAPI = Cookies.withAttributes({
  path: '/',
  domain: process.env.REACT_APP_DOMAIN,
  expires: inFifteenMinutes,
});

interface SetStoredValueProps {
  key: string;
  value: string;
}

export const setStoredValue = ({ key, value }: SetStoredValueProps) => {
  // teams cookies are stored in local storage due to the iframe restrictions.
  if (isMSTeams()) {
    localStorage.setItem(key, value);

    return;
  }

  cookieAPI.remove(key);
  cookieAPI.set(key, value);
};

export const getCookie = (cookieName: string) => cookieAPI.get(cookieName);
