import { Breakpoints } from '@unmind/design-system-theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { styled } from 'styles';
import { PageTitle } from '../Shared/PageTitle';
import Tabs from '../Shared/Tabs';
import { css } from '../styles';
import useViewportWidth from '../utils/useViewportWidth';
import { small, extraSmall } from '../utils';
import Wellbeing from './Wellbeing/Wellbeing';
import MoodsScoreScreen from './Moods/MoodsScoreScreen';

export const WELLBEING_TAB_HASH = '#wellbeing';
export const MOODS_TAB_HASH = '#moods';

export const TrackEmptyStateContainer = styled.div`
  margin-top: 43px;
`;

const TitleContainer = styled.div`
  display: flex;

  ${small(css`
    display: block;
  `)}
  ${extraSmall(css`
    margin-top: 40px;
    margin-bottom: 24px;
  `)}
`;

interface TabsProps {
  title: string;
  hash: string;
  testId?: string;
  onClick?(): void;
}

const Track = () => {
  const { t: translate } = useTranslation('track');
  const viewportWidth = useViewportWidth();
  const isSmallScreen = viewportWidth < Breakpoints.XS;

  const history = useHistory();
  const updateHistory = history.push;
  const activeTab = history.location.hash || WELLBEING_TAB_HASH;

  const trackTabs: TabsProps[] = [
    {
      title: translate('tab_menu.wellbeing_tab_button.text'),
      hash: WELLBEING_TAB_HASH,
      testId: 'track-wellbeing-tab',
    },
    {
      title: translate('tab_menu.moods_tab_button.text'),
      hash: MOODS_TAB_HASH,
      testId: 'track-moods-tab',
    },
  ];

  return (
    <>
      {!isSmallScreen && (
        <TitleContainer>
          <PageTitle>{translate('title')}</PageTitle>
        </TitleContainer>
      )}
      <Tabs
        activeTab={activeTab}
        tabs={trackTabs}
        updateHistory={updateHistory}
        align="left"
      >
        <Wellbeing />
        <div data-testid="moods-screen">
          <MoodsScoreScreen />
        </div>
      </Tabs>
    </>
  );
};

export default Track;
