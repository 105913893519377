import {
  BodyText,
  HeadingText,
  extraSmall,
  medium,
} from '@unmind/design-system-components-web';
import { Chevron } from 'icons';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  ${medium(
    css`
      padding: ${rem(25)} 0 ${rem(20)};
    `,
  )}

  ${extraSmall(
    css`
      padding: ${rem(25)} ${rem(24)} ${rem(20)};
    `,
  )}

  padding: ${rem(25)} ${rem(24)} ${rem(100)};
  display: flex;
  justify-content: center;
`;

export const LayoutContainer = styled.div`
  width: 100%;
  max-width: ${rem(1024)};
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${rem(16)};
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: ${rem(19)};
`;

export const HeadingOne = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize28],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
  level: 1,
}))`
  margin-left: ${rem(18)};
`;

export const HeadingTwo = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
  level: 2,
}))`
  margin: ${rem(48)} 0 ${rem(16)};
`;

export const HeadingThree = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
  level: 3,
}))`
  margin: ${rem(16)} 0;
`;

export const HeadingFour = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
  level: 4,
}))`
  margin: ${rem(16)} 0;
`;

export const Paragraph = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.primary,
}))`
  margin: ${rem(16)} 0;
`;

export const InfoPanel = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.card};
    border: 1px solid ${theme.colors.border.secondary};
  `};

  border-radius: ${rem(8)};
  padding: ${rem(48)} 12.5%;
`;

export const LinkWrapper = styled(Link).attrs<{
  readonly $a11yLabel: string;
}>(({ $a11yLabel }) => ({
  'aria-label': $a11yLabel,
}))<{
  readonly $a11yLabel: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackArrow = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  width: rem(16),
  height: rem(16),
}))`
  transform: rotate(180deg);
  & path {
    stroke-width: 3;
  }
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const listStyles = `
  line-height: 150%;
  overflow-wrap: break-word;
`;

export const OrderedList = styled.ol`
  ${listStyles}
`;

export const UnorderedList = styled.ul`
  ${listStyles}
`;
