import { rem } from 'polished';
import { styled } from 'styles';

const PanelSection = styled.section`
  & + & {
    margin-top: ${rem(32)};
  }
`;

export default PanelSection;
