import { Colors, FontSizes } from '@unmind/design-system-theme';
import React, { forwardRef } from 'react';
import { styled, useTheme } from 'styles';
import Base, { Props as BaseTextProps } from './Base';

export interface Props extends Omit<BaseTextProps, 'sizes' | 'color'> {
  sizes?: FontSizes[];
  color?: Colors;
  /**
   * Not for manual use - this is used internally by Styled Components.
   * If you're trying to change the rendered type of this component, use `forwardedAs` instead.
   */
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

const StyledBodyText = styled(Base)`
  font-family: ${({ theme }) => theme.typography.bodyText.fontFamily};
  line-height: 150%;
`;

const BodyText = forwardRef<HTMLElement, Props>(
  (
    {
      sizes = [
        FontSizes.fontSize16,
        FontSizes.fontSize16,
        FontSizes.fontSize20,
        FontSizes.fontSize22,
      ],
      color,
      className,
      children,
      as,
      ...props
    },
    ref,
  ) => {
    const { colors } = useTheme();

    return (
      <StyledBodyText
        sizes={sizes}
        color={color ?? colors.text.secondary}
        ref={ref}
        className={className}
        forwardedAs={as}
        {...props}
      >
        {children}
      </StyledBodyText>
    );
  },
);

export default BodyText;
