import { BodyText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import styled from 'styled-components';
import DynamicIcon from '../../../Shared/DynamicIcon/DynamicIcon';

export const Chip = styled.div<{ $type: 'primary' | 'secondary' }>`
  position: absolute;
  top: ${rem(12)};
  z-index: 1;
  left: ${rem(12)};
  padding: ${rem(4)} ${rem(8)};
  background-color: ${props =>
    props.$type === 'primary'
      ? props.theme.colors.background.primary
      : props.theme.colors.staticPalette.blue.blue200};
  border-radius: ${rem(16)};
  margin-bottom: ${rem(8)};
  display: inline-flex;
  align-items: center;
`;

export const Label = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize12],
  color: theme.colors.text.primary,
}))`
  line-height: 1;
`;

export const ChipIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  height: 12,
  width: 12,
  primaryColor: theme.colors.text.primary,
  'aria-hidden': true,
}))`
  margin-right: 4px;
`;
