import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Alert, Button } from '@unmind/design-system-components-web';
import { ChatbotMessageRole } from '__generated__/globalTypes';
import { tracking } from 'App/Tracking';
import { CONVERSATION_SUGGESTIONS_QUERY } from 'Assistant/Chatbot/graphql';
import uuid from 'uuid';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { Suggestions } from '../../graphql/__generated__/Suggestions';
import { OnSubmit } from '../../types';
import {
  ChatbotLoadingMessage,
  ChatbotMessage,
  ChatbotOptionsMessage,
} from '../ChatbotMessage';

const DisneyDemoStarters = [
  { label: 'Leverage your strengths', topic: 'Mangers' },
  { label: 'Boost team engagement', topic: 'Mangers' },
  { label: 'Setting clear goals', topic: 'Mangers' },
];

const date = new Date().toISOString();

interface ChatbotStarterMessageProps {
  handleSuggestionSelect: OnSubmit;
}

const ChatbotStarterSuggestionsOptions = ({
  handleSuggestionSelect,
}: ChatbotStarterMessageProps) => {
  const { t: translate } = useTranslation('shared');
  const { loading, error, data } = useQuery<Suggestions>(
    CONVERSATION_SUGGESTIONS_QUERY,
  );
  const isDisneyDemo = useFeatureFlag(FEATURE_FLAGS.DISNEY_CHATBOT);

  if (loading) {
    return <ChatbotLoadingMessage />;
  }

  if (error) {
    return (
      <Alert
        variant="error"
        size="small"
        title={translate('errors.messages.failed')}
      ></Alert>
    );
  }

  /**
   * NOTE:
   * This ternary operator is temporary logic for demo purposes, if flag is true
   * serves static demo starters, otherwise it serves content fetched from the API.
   */
  const suggestions = isDisneyDemo
    ? DisneyDemoStarters
    : data?.companionSuggestions?.slice(0, 4);

  return (
    <>
      {suggestions?.map(({ label, topic }) => (
        <Button
          key={label}
          label={label}
          onClick={async () => {
            tracking.track('ai-coach-chatbot-starter-suggestion-clicked', {
              source: 'ai-coach-chatbot-ui',
              message: label,
            });

            await handleSuggestionSelect({
              id: uuid.v4(),
              createdAt: new Date().toISOString(),
              content: label,
              topic: topic,
              starterId: null,
            });
          }}
          variant="secondary"
        />
      ))}
    </>
  );
};

export const ChatbotStarterMessage = ({
  handleSuggestionSelect,
}: ChatbotStarterMessageProps) => {
  const { t: translate } = useTranslation('chatbot');
  const isDisneyDemo = useFeatureFlag(FEATURE_FLAGS.DISNEY_CHATBOT);

  useEffect(() => {
    tracking.track('ai-coach-chatbot-starter-suggestions-displayed', {
      source: 'ai-coach-chatbot-ui',
    });
  }, []);

  /**
   * NOTE:
   * This ternary operator is temporary logic for demo purposes, if flag is true
   * it serves up some static demo starters and intro messages, otherwise it serves
   * content fetched from the API.
   */
  return isDisneyDemo ? (
    <>
      <ChatbotOptionsMessage
        message={{
          id: 'chatbot-starter-get-started',
          createdAt: date,
          role: ChatbotMessageRole.assistant,
          source: translate('conversation.chatbot_starter.get_started'),
        }}
        options={
          <ChatbotStarterSuggestionsOptions
            handleSuggestionSelect={handleSuggestionSelect}
          />
        }
      />
    </>
  ) : (
    <>
      <ChatbotMessage
        message={{
          id: 'chatbot-starter-introduction',
          createdAt: date,
          role: ChatbotMessageRole.assistant,
          source: translate('conversation.chatbot_starter.introduction'),
        }}
      />
      <ChatbotOptionsMessage
        message={{
          id: 'chatbot-starter-get-started',
          createdAt: date,
          role: ChatbotMessageRole.assistant,
          source: translate('conversation.chatbot_starter.get_started'),
        }}
        options={
          <ChatbotStarterSuggestionsOptions
            handleSuggestionSelect={handleSuggestionSelect}
          />
        }
      />
    </>
  );
};
