import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  RecordCourseImpactQuestionnaireResponses,
  RecordCourseImpactQuestionnaireResponsesVariables,
} from 'Series/SingleSeries/SeriesDay/SeriesSegment/Questionnaire/__generated__/RecordCourseImpactQuestionnaireResponses';

export const RECORD_IMPACT_QUESTIONNAIRE_RESPONSES_MUTATION = gql`
  mutation RecordCourseImpactQuestionnaireResponses(
    $input: RecordCourseImpactQuestionnaireInput!
  ) {
    recordCourseImpactQuestionnaireResponses(input: $input) {
      code
      success
    }
  }
`;

export default function useRecordCourseImpactQuestionnaireResponses(
  variables: RecordCourseImpactQuestionnaireResponsesVariables,
) {
  return useMutation<RecordCourseImpactQuestionnaireResponses>(
    RECORD_IMPACT_QUESTIONNAIRE_RESPONSES_MUTATION,
    {
      variables,
    },
  );
}
