import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

interface WithUserNameResponse {
  user: {
    id: string;
    firstName: string;
  };
}

export interface WithUserNameChildProps {
  userId?: string;
  firstName?: string;
}

export const withUserNameQuery = gql`
  query withUserNameQuery {
    user {
      id
      firstName
    }
  }
`;

export const withUserName = graphql<
  Record<string, unknown>,
  WithUserNameResponse,
  Record<string, unknown>,
  WithUserNameChildProps
>(withUserNameQuery, {
  props: ({ data }) => ({
    userId: data && data.user ? data.user.id : undefined,
    firstName: data && data.user ? data.user.firstName : undefined,
  }),
});
