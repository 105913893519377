import { useQuery } from '@apollo/client';
import {
  TALK_EVENTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN,
  TALK_EVENT_REQUESTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN,
} from 'Services/BeGateway/Talk/talk.services';
import { talkEventRequestsQueryForPractitionersOtherThan } from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQueryForPractitionersOtherThan';
import { talkEventsQueryForPractitionersOtherThan } from 'Services/BeGateway/Talk/__generated__/talkEventsQueryForPractitionersOtherThan';
import { USER_QUERY } from 'Services/User/user.services';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { userQuery } from '../../Services/User/__generated__/userQuery';
import { useToday } from './useToday';

/**
 * Checks whether the current user has an upcoming session or an active booking request
 * with a practitioner other than the `practitionerId` provided.
 */
export const useHasUpcomingBookingOrRequestWithPractitionerOtherThan = (
  practitionerId: string,
) => {
  const {
    data: userData,
    loading: userQueryLoading,
    error: userQueryError,
  } = useQuery<userQuery>(USER_QUERY);
  const now = useToday();
  const { id: userID } = userData?.user || {};
  const commonVariables = {
    first: 1,
    after: null,
    unmindUserId: userID ?? '',
  };

  const {
    data: events,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery<talkEventsQueryForPractitionersOtherThan>(
    TALK_EVENTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN,
    {
      variables: {
        ...commonVariables,
        filter: {
          where: {
            endsAt: {
              moreThan: now.toISOString(),
            },
            userId: {
              notEqualTo: practitionerId,
            },
          },
        },
      },
      skip: !userID,
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
    },
  );

  const {
    data: eventRequests,
    loading: eventRequestsLoading,
    error: eventRequestsError,
  } = useQuery<talkEventRequestsQueryForPractitionersOtherThan>(
    TALK_EVENT_REQUESTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN,
    {
      variables: {
        ...commonVariables,
        filter: {
          where: {
            preferredUserId: {
              notEqualTo: practitionerId,
            },
          },
        },
      },
      skip: !userID,
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
    },
  );

  const hasBooking = !!events?.unmindEvents?.edges?.length;
  const hasRequest = !!eventRequests?.unmindEventRequests?.edges?.length;

  return {
    loading: eventsLoading || eventRequestsLoading || userQueryLoading,
    hasBookingOrRequest: hasBooking || hasRequest,
    errors: [eventRequestsError, eventsError, userQueryError],
  };
};
