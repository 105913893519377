import { rem } from 'polished';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { css, styled, useTheme } from 'styles';
import { CourseCompleteTick } from 'icons';
import { Maybe } from 'graphql/jsutils/Maybe';
import DynamicIcon from '../Shared/DynamicIcon/DynamicIcon';
import { BodyText } from '../Shared/Typography';
import { small, medium } from '../utils';

export const CourseProgressPill = styled.div<{ grouped?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${rem(3)} ${rem(6)};
  background-color: ${({ theme }) => theme.colors.background.card};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: ${rem(20)};
  margin-left: ${rem(6)};

  ${({ grouped }) => css`
    position: ${grouped ? 'relative' : 'absolute'};
    right: ${grouped ? rem(0) : rem(6)};
    bottom: ${grouped ? rem(0) : rem(6)};
  `};

  ${({ grouped }) =>
    small(css`
      position: ${grouped ? 'relative' : 'absolute'};
      right: ${grouped ? rem(0) : '24px'};
      bottom: ${grouped ? rem(0) : '24px'};
    `)};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  height: ${rem(12)};
  width: ${rem(12)};
`;

const CourseCompleteIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.staticPalette.white,
  secondaryColor: theme.colors.background.success,
  width: rem(12),
  height: rem(12),
}))``;

const CourseProgressText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  margin-left: ${rem(4)};
  white-space: nowrap;

  ${medium(css`
    line-height: 1.2;
  `)};
`;

interface CourseProgressProps {
  daysCompleted?: Maybe<number>;
  totalDays?: Maybe<number>;
  grouped?: boolean;
}

const CLOCKWISE_ROTATION_TO_OFFSET_ROUNDED_EDGES = 0.02;
const NEGATIVE_PROGRESS_TO_OFFSET_ROUNDED_EDGES = 5;

const CourseProgress = ({
  daysCompleted,
  totalDays,
  grouped = false,
}: CourseProgressProps) => {
  const { t: translate } = useTranslation('courses');
  const { colors } = useTheme();

  let courseProgress: number | undefined;

  if (daysCompleted && totalDays) {
    courseProgress = Math.round((daysCompleted / totalDays) * 100);
  }

  return courseProgress ? (
    <CourseProgressPill grouped={grouped}>
      {courseProgress === 100 ? (
        <>
          <CourseCompleteIcon Icon={CourseCompleteTick} />
          <CourseProgressText>
            {translate('course_card.course_complete_pill.text')}
          </CourseProgressText>
        </>
      ) : (
        <>
          <ProgressBarContainer>
            <CircularProgressbar
              value={courseProgress - NEGATIVE_PROGRESS_TO_OFFSET_ROUNDED_EDGES}
              strokeWidth={21}
              styles={buildStyles({
                pathColor: colors.staticPalette.mint,
                trailColor: colors.staticPalette.magicMint,
                strokeLinecap: 'round',
                rotation: CLOCKWISE_ROTATION_TO_OFFSET_ROUNDED_EDGES,
              })}
            />
          </ProgressBarContainer>
          <CourseProgressText>
            {translate('course_card.progress_indicator.text', {
              courseProgress,
            })}
          </CourseProgressText>
        </>
      )}
    </CourseProgressPill>
  ) : null;
};

export default CourseProgress;
