import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavExploreActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <g clipPath="url(#NavExploreActive_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .169c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.274 6.03a.995.995 0 0 1 .465.264 1 1 0 0 1 .22 1.04l-2.721 6.544a2.442 2.442 0 0 1-1.32 1.32L7.372 18.09a1 1 0 0 1-.38.08 1.001 1.001 0 0 1-.95-1.3l2.43-6.704a2.411 2.411 0 0 1 1.522-1.521l6.744-2.432a1 1 0 0 1 .535-.013zm-6.229 7.39c.282.188.613.288.951.288a1.702 1.702 0 0 0 1.711-1.711 1.711 1.711 0 1 0-2.662 1.423z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <clipPath id="NavExploreActive_svg__a">
        <path fill="#fff" transform="translate(0 .162)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNavExploreActive;
