import React from 'react';
import { IconProps } from '../IconProps';

const SvgCalmness = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Calmness_svg__clip0_1095_24960)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M15.42 8.91c0 1.71-1.36 3.1-3.04 3.1s-3.04-1.39-3.04-3.1c0-1.52 1.77-3.31 2.72-4.25.18-.17.45-.17.63 0 1.09 1.07 2.72 2.69 2.72 4.25h.01z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.82 20.04h-9.1c-2.02 0-3.66-1.64-3.66-3.66v-3.99c0-.49.39-.88.88-.88H19.6c.49 0 .88.39.88.88v3.99c0 2.02-1.64 3.66-3.66 3.66z"
        fill="#FFF9EF"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Calmness_svg__clip0_1095_24960">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCalmness;
