import React from 'react';
import { IconProps } from '../IconProps';
const SvgAudio = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#Audio_svg__clip0_206_971)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.681 4.521C6.833 3.303 8.387 2.625 10 2.625s3.167.678 4.319 1.896c1.153 1.22 1.806 2.882 1.806 4.622v2.232h-.34c-1.013 0-1.91.777-1.91 1.825v3.6c0 1.048.897 1.825 1.91 1.825h1.93c1.013 0 1.91-.777 1.91-1.825v-3.6c0-1.048-.897-1.825-1.91-1.825h-.34V9.143c0-2.05-.769-4.021-2.148-5.48-1.38-1.46-3.26-2.288-5.227-2.288-1.968 0-3.847.828-5.227 2.288-1.38 1.459-2.148 3.43-2.148 5.48v2.232h-.34c-1.013 0-1.91.777-1.91 1.825v3.6c0 1.048.897 1.825 1.91 1.825h1.93c1.013 0 1.91-.777 1.91-1.825v-3.6c0-1.048-.897-1.825-1.91-1.825h-.34V9.143c0-1.74.653-3.402 1.806-4.622zM3.25 12.625h-.964c-.407 0-.661.297-.661.575v3.6c0 .278.254.575.66.575h1.93c.405 0 .66-.297.66-.575v-3.6c0-.278-.255-.575-.66-.575H3.25zm13.5 0h-.964c-.406 0-.661.297-.661.575v3.6c0 .278.255.575.66.575h1.93c.405 0 .66-.297.66-.575v-3.6c0-.278-.255-.575-.66-.575h-.965z"
        fill={primaryColor}
        fillOpacity={0.95}
      />
    </g>
    <defs>
      <clipPath id="Audio_svg__clip0_206_971">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAudio;
