import React from 'react';
import { IconProps } from '../IconProps';

const SvgChevron = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 12 20" fill="none" {...props}>
    <path
      d="M1.498 19.29l8.785-8.787a.685.685 0 0 0 0-1L1.498.718"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgChevron;
