import React from 'react';
import { IconProps } from '../IconProps';

const SvgFlourish = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 39 34" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.682 2.7H5.318C3.85 2.7 2.66 3.909 2.66 5.4v16.2c0 1.491 1.19 2.7 2.66 2.7h28.363c1.468 0 2.659-1.209 2.659-2.7V5.4c0-1.491-1.19-2.7-2.66-2.7zM5.318 0C2.381 0 0 2.418 0 5.4v16.2C0 24.582 2.381 27 5.318 27h28.364C36.619 27 39 24.582 39 21.6V5.4C39 2.418 36.619 0 33.682 0H5.318zM7 32.25c0-.69.62-1.25 1.385-1.25h21.23c.765 0 1.385.56 1.385 1.25s-.62 1.25-1.385 1.25H8.385C7.62 33.5 7 32.94 7 32.25z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 7.055c-4.871 0-8.82 3.722-8.82 8.313v.002a7.964 7.964 0 0 0 1.763 4.985.99.99 0 0 1-.216 1.439c-.48.34-1.164.25-1.526-.204-1.432-1.793-2.204-3.978-2.201-6.223C8.5 9.641 13.425 5 19.5 5s11 4.642 11 10.368h-1.09l1.09-.001v.001c.003 2.244-.77 4.429-2.201 6.222a1.134 1.134 0 0 1-1.526.204.99.99 0 0 1-.216-1.44 7.964 7.964 0 0 0 1.763-4.984v-.002c0-4.591-3.949-8.313-8.82-8.313zm-11 8.312l1.09.001H8.5z"
      fill={primaryColor}
    />
    <path
      d="M8.5 15.367c-.003 2.245.77 4.43 2.201 6.223a1.134 1.134 0 0 0 1.526.204.99.99 0 0 0 .216-1.44 7.964 7.964 0 0 1-1.763-4.984v-.002c0-4.591 3.949-8.313 8.82-8.313s8.82 3.722 8.82 8.313v.002a7.964 7.964 0 0 1-1.763 4.985.99.99 0 0 0 .216 1.439c.48.34 1.164.25 1.526-.204 1.431-1.793 2.204-3.978 2.201-6.222m-22 0C8.5 9.64 13.425 5 19.5 5s11 4.642 11 10.368m-22 0v-.001l1.09.001H8.5s0 0 0 0zm22 0h-1.09l1.09-.001v.001z"
      stroke={primaryColor}
      strokeWidth={0.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.515 10.825A5.832 5.832 0 0 1 19.501 10c1.043 0 2.073.283 2.986.826a6.112 6.112 0 0 1 2.217 2.304c.537.972.815 2.084.795 3.221a6.421 6.421 0 0 1-.907 3.186c-.293.49-.872.61-1.293.27-.422-.34-.526-1.012-.233-1.501a4.02 4.02 0 0 0 .073-4.013 4.124 4.124 0 0 0-1.502-1.548 4.178 4.178 0 0 0-4.273-.001 4.123 4.123 0 0 0-1.502 1.547 4.019 4.019 0 0 0 .071 4.014c.293.489.189 1.16-.233 1.5-.421.34-1 .22-1.294-.27a6.423 6.423 0 0 1-.905-3.186 6.44 6.44 0 0 1 .796-3.221 6.111 6.111 0 0 1 2.218-2.303z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth={0.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.086 15.649A1.907 1.907 0 0 1 19.5 15c.53 0 1.04.233 1.414.649.375.415.586.978.586 1.565 0 .434-.318.786-.71.786-.392 0-.71-.352-.71-.786a.68.68 0 0 0-.17-.454.554.554 0 0 0-.41-.189.554.554 0 0 0-.41.189.68.68 0 0 0-.17.454c0 .434-.318.786-.71.786-.392 0-.71-.352-.71-.786 0-.587.21-1.15.586-1.565z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth={0.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFlourish;
