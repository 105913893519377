import React from 'react';
import { CloseIcon, BasePill, BasePillButton, Label } from './styles';

interface PillProps {
  label: string;
}

interface BrowseFilterPillProps extends PillProps {
  onSelect(): void;
}

export const BrowseFilterPill = ({
  label,
  onSelect,
}: BrowseFilterPillProps) => (
  <BasePillButton onClick={onSelect}>
    <Label>{label}</Label>
    <CloseIcon />
  </BasePillButton>
);

export const DisplayPill = ({ label }: PillProps) => (
  <BasePill>
    <Label>{label}</Label>
  </BasePill>
);
