import { rem, rgba } from 'polished';
import React from 'react';

import { css, styled, ThemeInterface } from 'styles';
import { Chevron } from 'icons';
import { small } from '@unmind/design-system-components-web';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const ControlWrapper = styled.div<{ visible: boolean }>`
  font-size: ${rem('30px')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  padding: 0;

  ${small(css`
    padding: ${rem('10px')};
  `)}
`;

const ArrowLeftIcon = styled<any>(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
    width: 24,
    height: 24,
  }),
)`
  cursor: pointer;
  transform: rotate(180deg);
`;

const ArrowRightIcon = styled<any>(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
    width: 24,
    height: 24,
  }),
)`
  cursor: pointer;
`;

const SlideViewport = styled.div`
  overflow-x: hidden;
  position: relative;
  width: 100%;

  &::after {
    box-sizing: content-box;
    background: ${({ theme }) => `linear-gradient(
      to right,
      ${rgba(theme.colors.background.primary, 1)} 0%,
      5%,
      ${rgba(theme.colors.background.primary, 0)} 10%,
      95%,
      ${rgba(theme.colors.background.primary, 1)}
    )`};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const SlideTrack = styled.div<{
  activeSlide: number;
  height: number;
  overlap: boolean;
  slideMargin: number;
  slideScale: number;
  slideWidth: number;
}>`
  height: ${({ height, slideScale }) =>
    rem(height * (1 / slideScale) - slideScale * 80)};
  line-height: ${({ height, slideScale }) =>
    rem(height * (1 / slideScale) - slideScale * 80)};
  transition: transform 300ms ease-in-out;
  white-space: nowrap;

  ${({ activeSlide, slideScale, overlap, slideMargin, slideWidth }) =>
    // prettier-ignore
    overlap ? small(css`
      transform:
        translateX(50%)
        translateX(${rem(-slideWidth * slideScale * 0.5)})
        translateX(${rem(
          -activeSlide * (slideMargin * 2 + slideWidth * slideScale),
        )})
        translateX(${rem(-slideMargin)})
        ;
    `) : small(css`
      transform:
        translateX(50%)
        translateX(${rem(-slideWidth * 0.5)})
        translateX(${rem(-activeSlide * (slideMargin * 2 + slideWidth))})
        translateX(${rem(-slideMargin)})
      ;
    `)}

  > * {
    display: inline-block;
    position: absolute;
    vertical-align: middle;
  }
`;

const Slide = styled.div<{
  active: boolean;
  height: number;
  slideScale: number;
  margin: number;
  overlap: boolean;
  width: number;
}>`
  border-radius: ${rem('4px')};
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  margin: ${({ margin }) => rem(margin)};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  overflow: hidden;
  position: relative;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  z-index: ${({ active }) => (active ? 1 : 0)};
  display: ${({ active }) => (active ? 'block' : 'none')};

  ${small(css`
    display: inline-block;
  `)}

  ${({ active, height, slideScale, overlap, width }) =>
    overlap
      ? small(css`
          transform: ${active ? `scale(${1 / slideScale})` : undefined};
          height: ${rem(height * slideScale)};
          width: ${rem(width * slideScale)};
        `)
      : small(css`
          transform: ${active ? undefined : `scale(${slideScale})`};
          height: ${rem(height)};
          width: ${rem(width)};
        `)};
`;

interface SliderProps {
  initialSlide: number;
  overlap: boolean;
  showControls: boolean;
  showUnusableControls: boolean;
  slideHeight: number;
  slideMargin: number;
  slideScale: number;
  slideWidth: number;
}

interface SliderState {
  activeSlide: number;
  canSlideNext: boolean;
  canSlidePrevious: boolean;
}

export default class Slider extends React.Component<SliderProps, SliderState> {
  static defaultProps = {
    initialSlide: 0,
    overlap: true,
    slideHeight: 400,
    slideMargin: 8,
    slideScale: 0.8,
    slideWidth: 410,
  };

  constructor(props: SliderProps) {
    super(props);

    this.state = {
      activeSlide: this.props.initialSlide,
      canSlideNext: this.props.initialSlide < this.getChildrenLength() - 1,
      canSlidePrevious: this.props.initialSlide > 0,
    };
  }

  getChildrenLength = () => React.Children.toArray(this.props.children).length;

  isActiveSlide = (idx: number) => idx === this.state.activeSlide;

  slidePrevious = () => {
    if (this.state.canSlidePrevious) {
      this.setState(({ activeSlide }) => ({
        activeSlide: activeSlide - 1,
        canSlideNext: activeSlide - 1 < this.getChildrenLength() - 1,
        canSlidePrevious: activeSlide - 1 > 0,
      }));
    }
  };

  slideNext = () => {
    if (this.state.canSlideNext) {
      this.setState(({ activeSlide }) => ({
        activeSlide: activeSlide + 1,
        canSlideNext: activeSlide + 1 < this.getChildrenLength() - 1,
        canSlidePrevious: activeSlide + 1 > 0,
      }));
    }
  };

  componentDidUpdate(prevProps: SliderProps & { children: React.ReactNode }) {
    const hasNewChildren =
      React.Children.toArray(prevProps.children).length !==
      this.getChildrenLength();

    if (hasNewChildren) {
      this.setState(({ activeSlide }) => ({
        canSlideNext: activeSlide < this.getChildrenLength() - 1,
        canSlidePrevious: activeSlide > 1,
      }));
    }
  }

  render() {
    const { activeSlide, canSlideNext, canSlidePrevious } = this.state;
    const {
      children,
      showControls,
      showUnusableControls,
      slideHeight,
      slideScale,
      slideMargin,
      overlap,
      slideWidth,
    } = this.props;

    return (
      <Wrapper>
        <ControlWrapper
          visible={showControls && (showUnusableControls || canSlidePrevious)}
        >
          <ArrowLeftIcon onClick={this.slidePrevious} />
        </ControlWrapper>
        <SlideViewport>
          <SlideTrack
            activeSlide={activeSlide}
            height={slideHeight + slideMargin * 2}
            slideScale={slideScale}
            overlap={overlap}
            slideMargin={slideMargin}
            slideWidth={slideWidth}
          >
            {React.Children.map(children, (child, idx) => (
              <Slide
                active={this.isActiveSlide(idx)}
                height={slideHeight}
                slideScale={slideScale}
                margin={slideMargin}
                overlap={overlap}
                width={slideWidth}
              >
                {child}
              </Slide>
            ))}
          </SlideTrack>
        </SlideViewport>
        <ControlWrapper
          visible={showControls && (showUnusableControls || canSlideNext)}
        >
          <ArrowRightIcon onClick={this.slideNext} />
        </ControlWrapper>
      </Wrapper>
    );
  }
}
