import { Maybe } from 'graphql/jsutils/Maybe';

export type SeriesAttributes = {
  contentId?: Maybe<string>;
  label?: Maybe<string>;
  stringValue?: Maybe<string>;
  numericValue?: Maybe<number>;
  dateValue?: Maybe<string>;
  unknownValue?: Maybe<string>;
  longValue?: Maybe<string>;
};

export type SeriesSegment = {
  id: string;
  title?: Maybe<string>;
  summary?: Maybe<string>;
  html?: Maybe<string>;
  orderNo?: Maybe<number>;
  icon?: Maybe<SeriesAttributes>;
  transcript?: Maybe<SeriesAttributes>;
  mediaType?: Maybe<SeriesAttributes>;
  mediaSrc?: Maybe<SeriesAttributes>;
  durationSeconds?: Maybe<SeriesAttributes>;
  dialogWindowAction?: Maybe<SeriesAttributes>;
  lockSegment?: Maybe<SeriesAttributes>;
  feedback?: Maybe<SeriesAttributes>;
};

export type SeriesDay = {
  id: string;
  title?: Maybe<string>;
  orderNo?: Maybe<number>;
  segments?: Maybe<SeriesSegment[]>;
  dayHighlight1?: Maybe<string>;
  dayHighlight2?: Maybe<string>;
  dayHighlight3?: Maybe<string>;
  daysLocked?: Maybe<SeriesAttributes>;
  hasReminder?: Maybe<boolean>;
};

export type SeriesCategory = {
  id: string;
  name: Maybe<string>;
};

export interface SeriesDaysCompleted {
  seriesId: string;
  daysCompleted: number;
}

export type Series = {
  id: string;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  slug?: Maybe<string>;
  categories?: SeriesCategory[] | null;
  releaseDate?: Maybe<string>;
  released?: Maybe<boolean>;
  totalDays?: Maybe<number>;
  commitment?: Maybe<SeriesAttributes>;
  description?: Maybe<SeriesAttributes>;
  benefit1?: Maybe<SeriesAttributes>;
  benefit2?: Maybe<SeriesAttributes>;
  benefit3?: Maybe<SeriesAttributes>;
  minsPerDay?: Maybe<SeriesAttributes>;
  seriesDays?: Maybe<SeriesDay[]>;
};

export interface SeriesTwo {
  id: string;
  hint?: string;
  released: boolean;
  releaseDate: string;
  slug: string;
  subtitle: string;
  title: string;
  totalDays: number;
}

export interface Highlight {
  text: string;
}

export enum SeriesStates {
  completed = 'completed',
  inProgress = 'inProgress',
  notReleased = 'notReleased',
  notStarted = 'notStarted',
}

export const SeriesCategories: readonly string[] = [
  'foundations',
  'health',
  'performance',
  'relationships',
  'wellbeing',
];

export enum SeriesSegmentType {
  audio = 'audio',
  image = 'image',
  questionnaire = 'questionnaire',
  video = 'video',
  text = 'text',
}
