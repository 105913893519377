import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { UNLINK_SLACK_USER_MUTATION } from '../../../Services/slack/slack.services';
import {
  UnlinkSlackUserToUnmind,
  UnlinkSlackUserToUnmindVariables,
} from '../../../Services/slack/__generated__/UnlinkSlackUserToUnmind';
import RoutePath from '../../../App/RoutePath';
import { SLACK_USER_NOT_FOUND } from '../constants';

export const useUnlinkSlackUser = () => {
  const history = useHistory();

  return useMutation<UnlinkSlackUserToUnmind, UnlinkSlackUserToUnmindVariables>(
    UNLINK_SLACK_USER_MUTATION,
    {
      errorPolicy: 'all',
      onError: error => {
        if (error.message.toLowerCase().includes('not found')) {
          history.replace(
            `${RoutePath.Account}?error=${SLACK_USER_NOT_FOUND}#integrations`,
          );
        }
      },
      refetchQueries: ['SlackIntegration'],
    },
  );
};
