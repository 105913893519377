import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React, { useCallback, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { ChevronDown } from 'icons';
import { useTranslation } from 'react-i18next';
import { Namespace, ParseKeys } from 'i18next';

const CountryPickerButtonContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.button.primary.disabled.backgroundColor};
  gap: ${rem(4)};
  padding: ${rem(6)} ${rem(8)};
  border-radius: ${rem(1000)};
  width: fit-content;
  cursor: pointer;
  max-height: ${rem(32)};

  &:hover {
    background: ${({ theme }) => theme.button.primary.disabled.borderColor};
  }

  padding-left: ${rem(12)};
`;

const CountryPickerLabel = styled(BodyText).attrs({
  id: 'country-label',
})`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14}px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const FlagWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: ${rem(20)};
  width: ${rem(20)};
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
`;

const Down = styled(ChevronDown).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  width: `${rem(theme.typography.fontSizes.fontSize12)}`,
  height: `${rem(theme.typography.fontSizes.fontSize12)}`,
}))`
  align-self: center;
  padding-left: ${rem(2)};
`;

type HelpCountryButtonProps = {
  countryCode: string;
  onClick(): void;
};

const HelpCountryButton = ({
  countryCode,
  onClick,
}: HelpCountryButtonProps) => {
  const { t: translateCountry } =
    useTranslation<Namespace<'country'>>('country');

  const getCountryLabel = useCallback(
    (country: string) =>
      translateCountry(
        country.toUpperCase() as ParseKeys<Namespace<'country'>>,
      ),
    [translateCountry],
  );

  const [showFlag, setShowFlag] = useState(true);

  return (
    <CountryPickerButtonContainer onClick={onClick} role="button" tabIndex={0}>
      {showFlag && (
        <FlagWrapper>
          <ReactCountryFlag
            data-testid="flag-wrapper"
            countryCode={countryCode}
            style={{
              width: 'none',
              height: '100%',
            }}
            svg
            onError={() => setShowFlag(false)}
            aria-label={`Button to open current location picker, you have currently selected ${getCountryLabel(
              countryCode,
            )}`}
          ></ReactCountryFlag>
        </FlagWrapper>
      )}
      <CountryPickerLabel>{getCountryLabel(countryCode)}</CountryPickerLabel>
      <Down />
    </CountryPickerButtonContainer>
  );
};

export default HelpCountryButton;
