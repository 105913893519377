import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesSleep = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M2.46 15.021c-.411-4.2 2.314-8.71 5.482-10.176 2.236-1.034 4.928-1.16 7.381 1.78 5.621 6.689 16.918 2.863 19.803 12.452 2.885 9.588-10.316 17.84-23.333 10.588-7.645-4.277-8.92-10.443-9.332-14.644z"
      fill="#E9DFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 7.25C12.063 7.25 7.25 12.063 7.25 18S12.063 28.75 18 28.75 28.75 23.937 28.75 18c0-.018 0-.036-.002-.053.002-.028.002-.056.002-.083a.75.75 0 0 0-1.5 0c0 .156-.099.546-.418 1.101a7.462 7.462 0 0 1-1.369 1.697c-1.212 1.131-2.974 2.088-5.213 2.088a7 7 0 0 1-7-7c0-2.239.957-4 2.088-5.213a7.463 7.463 0 0 1 1.697-1.37c.555-.318.945-.417 1.102-.417a.75.75 0 0 0 0-1.5c-.028 0-.055 0-.083.002A.764.764 0 0 0 18 7.25zm-3.808 2.318a9.25 9.25 0 1 0 12.24 12.24c-1.429 1.314-3.522 2.442-6.182 2.442a8.5 8.5 0 0 1-8.5-8.5c0-2.66 1.128-4.753 2.442-6.182zM17 16.75a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 .53 1.28l-1.719 1.72h1.19a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.53-1.28l1.719-1.72H17zM21.25 9c0 .414.336.75.75.75h3.19l-3.72 3.72a.75.75 0 0 0 .53 1.28h5a.75.75 0 0 0 0-1.5h-3.189l3.72-3.72A.75.75 0 0 0 27 8.25h-5a.75.75 0 0 0-.75.75z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesSleep;
