import { gql } from '@apollo/client';

export const SUBMIT_MESSAGES_MUTATION = gql`
  mutation SubmitChatbotMessages($input: SubmitChatbotMessagesInput!) {
    submitChatbotMessages(input: $input) {
      response {
        content {
          ... on ChatbotMessageText {
            id
            body
          }
        }
        role
        conversationId
      }
    }
  }
`;
