import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { CheckInScores } from '../__generated__/CheckInScores';
import MoodScoreEntry from './MoodScoreEntry';

const List = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  padding: ${rem(20)} 0;

  & + & {
    border-top: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  }
`;

interface MoodScoreEntryListProps {
  entries: CheckInScores[];
}

const MoodScoreEntryList = ({ entries }: MoodScoreEntryListProps) => (
  <List>
    {entries.map(entry => (
      <ListItem data-testid="check-in-entry" key={entry.dateCreated}>
        <MoodScoreEntry {...entry} />
      </ListItem>
    ))}
  </List>
);

export default MoodScoreEntryList;
