import * as braze from '@braze/web-sdk';
import type { ApolloClient } from '@apollo/client';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { logDevOnlyInfo, logDevOnlyWarning, logException } from '../logging';
import brazeSdkAuthentication from './brazeSdkAuthentication';
import {
  BrazeSdkAuthErrorEvent,
  SET_SDK_AUTH_ERROR_MESSAGE,
  SDK_AUTH_ERROR_MESSAGE,
  MISSING_SDK_API_KEY,
  captureBrazeException,
  BrazeIdentityError,
  BrazeSdkError,
} from './brazeSdkErrors';

export type BrazeProps = WithApolloClient<{
  children: React.ReactElement;
}>;

const BRAZE_SDK_BASE_URL = 'sdk.fra-01.braze.eu';

const handleAuthException = (errorEvent: BrazeSdkAuthErrorEvent) => {
  switch (errorEvent.errorCode) {
    case 22:
      logDevOnlyWarning(
        'Braze authentication token expired, attempting to retrieve a new braze auth token',
      );
      break;
    default:
      captureBrazeException(
        new BrazeIdentityError(
          SDK_AUTH_ERROR_MESSAGE(errorEvent.errorCode),
          errorEvent,
        ),
      );
  }
};

export const sdkAuthenticationErrorCallback =
  (client: ApolloClient<unknown>) =>
  async (errorEvent: BrazeSdkAuthErrorEvent) => {
    logDevOnlyWarning(`Braze sdkAuthenticationError triggered`);
    try {
      handleAuthException(errorEvent);
      logDevOnlyInfo('Retry to retrieve a fresh Braze Auth JWT');
      const response = await brazeSdkAuthentication(client);
      const updatedBrazeJWT = response.data.brazeSdkAuthentication.brazeJWT;
      logDevOnlyInfo(`Updated Braze Auth JWT: ${updatedBrazeJWT}`);
      braze.setSdkAuthenticationSignature(updatedBrazeJWT);
    } catch (error) {
      captureBrazeException(
        new BrazeIdentityError(SET_SDK_AUTH_ERROR_MESSAGE, error as Error),
      );
    }
  };

const initializeBrazeSDK = (client: ApolloClient<unknown>) => {
  const initializationOptions: braze.InitializationOptions = {
    baseUrl: BRAZE_SDK_BASE_URL,
    enableSdkAuthentication: true,
    noCookies: true,
    allowUserSuppliedJavascript: true,
    ...(process.env.REACT_APP_ENVIRONMENT !== 'production' && {
      enableLogging: true,
    }),
  };
  let brazeInitialized = false;
  try {
    if (process.env.REACT_APP_BRAZE_SDK_API_KEY) {
      brazeInitialized = braze.initialize(
        process.env.REACT_APP_BRAZE_SDK_API_KEY,
        initializationOptions,
      );

      braze.subscribeToSdkAuthenticationFailures(
        sdkAuthenticationErrorCallback(client),
      );
    } else {
      throw new BrazeSdkError(MISSING_SDK_API_KEY);
    }
  } catch (error) {
    captureBrazeException(error as Error | BrazeSdkError);
    brazeInitialized = false;
  }

  return brazeInitialized;
};

function Braze({ client, children }: BrazeProps) {
  if (process.env.REACT_APP_BRAZE_ENABLED === 'true') {
    if (client) {
      const brazeInitialized = initializeBrazeSDK(client);
      logDevOnlyInfo(`braze initialized: ${brazeInitialized}`);
    } else {
      logException(
        'Attempted to initialise braze SDK but apollo client not intialised.' +
          ' Braze not enabled',
      );
    }
  } else {
    logDevOnlyInfo('braze not enabled');
  }

  return children;
}

export default withApollo<BrazeProps>(Braze);
