import React from 'react';
import { useCollapse } from 'react-collapsed';
import { TalkTranslate } from 'Talk/lib/talkTranslate';
import { practitionersQuery_practitioners_edges_node_details_profileQuestionResponses as QuestionResponse } from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import {
  AnswerText,
  Container,
  ExpandableHeader,
  QuestionText,
  ArrowIcon,
} from './styles';

type CollapsibleAnswerProps = {
  questionAnswer: QuestionResponse;
};

export const CollapsibleAnswer = ({
  questionAnswer,
}: CollapsibleAnswerProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  const onKeyDown: React.KeyboardEventHandler<HTMLElement> = ({
    key,
    keyCode,
  }) => {
    const pressedSpacebar = keyCode === 32 || key === ' ';
    const pressedEnter = keyCode === 13 || key === 'Enter';

    if (pressedSpacebar || pressedEnter) {
      setExpanded(!isExpanded);
    }
  };

  return (
    <Container>
      <ExpandableHeader
        role="button"
        {...getToggleProps()}
        onKeyDown={onKeyDown}
      >
        <QuestionText>
          {TalkTranslate(
            'questions',
            questionAnswer.profileQuestion.translationKey,
            questionAnswer.profileQuestion.question,
          )}
        </QuestionText>
        <ArrowIcon $isOpen={isExpanded} />
      </ExpandableHeader>
      <div {...getCollapseProps()}>
        <AnswerText>{questionAnswer.answer}</AnswerText>
      </div>
    </Container>
  );
};
