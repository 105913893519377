import React, { useEffect, useRef, useState } from 'react';
import { Arrow } from 'icons';
import { IconButton } from '@unmind/design-system-components-web';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import { OnSubmit } from '../../types';
import { InputBar, SparklesIcon, TextFieldWrapper } from '../styles';
import { ChatBotTextInput } from './styles';

interface ChatbotInputProps {
  /**
   * Boolean to indicate whether submit button is disabled.
   */
  canSubmit?: boolean;
  /**
   * Callback function to handle submission of form.
   */
  onSubmit: OnSubmit;
}

export const ChatbotInput = ({ canSubmit, onSubmit }: ChatbotInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentInput, setCurrentInput] = useState('');
  const { t: translate } = useTranslation('chatbot');

  const handleOnSubmit = () => {
    void onSubmit({
      id: uuid.v4(),
      createdAt: new Date().toISOString(),
      content: currentInput,
      topic: null,
      starterId: null,
    });

    setCurrentInput('');

    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }
  };

  useEffect(() => {
    // Focus the input element if it exists
    inputRef.current?.focus();
  }, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLElement> = event => {
    const pressedEnter = event.key === 'Enter';

    if (pressedEnter && currentInput === '') {
      event.preventDefault();

      return;
    }

    if (pressedEnter && !event.shiftKey && canSubmit) {
      handleOnSubmit();
      event.preventDefault();
    }
  };

  return (
    <InputBar>
      <SparklesIcon aria-hidden={true} />
      <TextFieldWrapper>
        <ChatBotTextInput
          name="message"
          type="conversation"
          id="chat-input"
          placeholder={translate(
            'conversation.conversation_input.placeholder_text',
          )}
          border={false}
          aria-label={translate('conversation.conversation_input.a11y_label')}
          autoComplete="off"
          ref={inputRef}
          value={currentInput}
          onChange={e => setCurrentInput(e.target.value)}
          multiline={true}
          onKeyDown={onKeyDown}
        />
      </TextFieldWrapper>
      <IconButton
        type="submit"
        icon={Arrow}
        size="medium"
        variant="primary"
        disabled={currentInput === '' || !canSubmit}
        aria-label={translate('conversation.submit_button.label')}
        onClick={handleOnSubmit}
      />
    </InputBar>
  );
};
