import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { compose } from 'recompose';
import { tracking } from '../../App/Tracking';
import Modal, {
  ModalActions,
  ModalContent,
  ModalCopy,
  ModalTitle,
} from '../../Shared/Modal';
import PrimaryButton from '../../Shared/PrimaryButton';
import { ButtonSize } from '../../Shared/Button';
import TextButton from '../../Shared/TextButton';
import Tooltip, { useTooltipState } from '../../Shared/Tooltip';
import { PlusOneConfirmationModalType } from './PlusOneConfirmationModal';
import {
  ActionButton,
  ActionButtonWrapper,
  BaseText,
  InfoIcon,
  InviteDate,
  InviteeDetails,
  InviteeDetailsInner,
  InviteName,
  PlusOne,
  Subtitle,
  SubtitleContainer,
  TooltipContainer,
  tooltipPosition,
  trackTooltip,
} from './PlusOneStatus';
import withRevokePlusOneInvite, {
  RevokePlusOneInviteProps,
} from './withRevokePlusOneInvite';

interface InvitedProps {
  plusOne: PlusOne;
  plusOneConfirmationModalCallback(
    confType: PlusOneConfirmationModalType,
  ): void;
}

interface Props extends RevokePlusOneInviteProps, InvitedProps {}

const PlusOneStatusInvited = ({
  plusOne,
  revokePlusOneInvite,
  plusOneConfirmationModalCallback,
}: Props) => {
  const { t: translate } = useTranslation<Namespace<'plus_one'>>('plus_one');
  const { open: tooltipOpen, handlers: tooltipHandlers } = useTooltipState();
  const { createdAt, emailAddress, firstName } = plusOne;
  const invitedDate = moment(createdAt).format('MMM Do, YYYY');

  const RevokePlusOneModal = () => {
    const [loading, setLoading] = useState(false);

    const revokeSubmit = async () => {
      try {
        setLoading(true);
        await revokePlusOneInvite();
        tracking.track('settings-plus-one-uninvite-confirmed');
        plusOneConfirmationModalCallback('uninvited');
      } catch (e) {
        // TODO: Handle Error State.
        setLoading(false);
      }
    };

    return (
      <Modal
        closeHeader={true}
        onCloseCallback={(trackingData: { [key: string]: string }) => {
          tracking.track('settings-plus-one-uninvite-cancelled', {
            ...trackingData,
          });
        }}
        openModalButton={({ open }) => (
          <ActionButton
            data-testid="uninvite-plusone-button"
            label={translate('invite_flow.invited.uninvite_button.label')}
            onClick={() => {
              tracking.track('settings-plus-one-uninvite-clicked');
              open();
            }}
          />
        )}
        modalContent={({ close }) => (
          <Fragment>
            <ModalContent>
              <ModalTitle>
                {translate('invite_flow.invited.uninvite_modal.title')}
              </ModalTitle>
              <ModalCopy>
                {translate('invite_flow.invited.uninvite_modal.body')}
              </ModalCopy>
            </ModalContent>
            <ModalActions>
              <PrimaryButton
                data-testid="plusone-uninvite-modal-cancel-button"
                label={translate(
                  'invite_flow.invited.uninvite_modal.cancel_button.label',
                )}
                type="submit"
                onClick={() => {
                  close({
                    actionMethod: 'cancelButton',
                  });
                }}
                size={ButtonSize.medium}
              />
              <TextButton
                data-testid="plusone-uninvite-modal-confirm-button"
                variant="destructive"
                label={translate(
                  'invite_flow.invited.uninvite_modal.continue_button.label',
                )}
                loading={loading}
                onClick={revokeSubmit}
                size={ButtonSize.medium}
              />
            </ModalActions>
          </Fragment>
        )}
      />
    );
  };

  const labelCopy = `${translate('invite_flow.tool_tip.body_1')} ${translate(
    'invite_flow.tool_tip.body_2',
  )}`;

  return (
    <>
      <SubtitleContainer>
        <Subtitle>{translate('invite_flow.invited.title')}</Subtitle>
        <TooltipContainer {...tooltipHandlers}>
          <Tooltip
            labelCopy={labelCopy}
            placement={tooltipPosition('right')}
            open={tooltipOpen}
            accessibilityId="plusone-invited-tooltip"
            onMouseEnter={() => {
              trackTooltip();
              tooltipHandlers.onMouseEnter();
            }}
          >
            <InfoIcon />
          </Tooltip>
        </TooltipContainer>
      </SubtitleContainer>
      <BaseText>{translate('invite_flow.invited.body')}</BaseText>
      <InviteeDetails>
        <InviteeDetailsInner>
          {Trans({
            t: translate,
            i18nKey: 'invite_flow.invited.invitee',
            defaults:
              '<bold>{{first_name}}</bold> <regular>on {{date}}</regular>',
            components: {
              bold: <InviteName data-testid="invited-plusone-name" />,
              regular: <InviteDate />,
            },
            values: {
              first_name: firstName,
              date: invitedDate,
            },
          })}
          <div>
            <BaseText data-testid="invited-plusone-email">
              {emailAddress}
            </BaseText>
          </div>
        </InviteeDetailsInner>
        <ActionButtonWrapper>
          <RevokePlusOneModal />
        </ActionButtonWrapper>
      </InviteeDetails>
    </>
  );
};

export default compose<Props, InvitedProps>(withRevokePlusOneInvite)(
  PlusOneStatusInvited,
);
