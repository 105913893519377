import React from 'react';
import { Accordion, BodyText } from '@unmind/design-system-components-web';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';
import SegmentHeader from 'Series/SingleSeries/SeriesDay/SeriesSegment/SegmentHeader';
import { Check, CheckEmpty, Lock } from 'icons';
import RoutePath from 'App/RoutePath';
import { useHistory } from 'react-router';
import { SingleSeriesWithDays } from '../useSingleSeriesWithSegments';

interface CourseContentProps {
  course: SingleSeriesWithDays;
  activeDayTitle: string;
  slug: string;
}

const CheckIcon = styled(Check).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  height: ${rem(21)};
  flex-shrink: 0;
  margin-right: ${rem(9)};
`;

const CheckEmptyIcon = styled(CheckEmpty).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  flex-shrink: 0;
  height: ${rem(21)};
  margin-right: ${rem(9)};
`;

const CourseContentTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin-top: ${rem(24)};
`;

const SessionInformation = styled.div`
  display: flex;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(24)};
`;

const SegmentContent = styled.div`
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: flex-start;
  margin-left: ${rem(8)};
`;

const SegmentTitle = styled.li<{ clickable: boolean }>`
  flex-grow: 1;
  margin-left: ${rem(16)};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const AccordionContent = styled.div`
  padding: ${rem(16)};
`;

const LockedIcon = styled(Lock).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  height: ${rem(21)};
  flex-shrink: 0;
  margin-right: ${rem(9)};
`;

export const CourseContent = ({
  course,
  activeDayTitle,
  slug,
}: CourseContentProps) => {
  const { t: translate } = useTranslation('courses');
  const history = useHistory();

  const completedSegmentIds = course.seriesDays?.reduce(
    (acc, day) => [...acc, ...day.completedSegments],
    [] as string[],
  );

  const getNextUnlockedSegmentId = () => {
    const activeDay = course.seriesDays.find(
      day => day.title === activeDayTitle,
    );

    const segmentsCompleted = activeDay?.completedSegments || [];

    if (segmentsCompleted.length === 0) {
      return activeDay?.segments[0].id;
    }

    return (
      activeDay?.segments.find(
        segment => segment.id && !segmentsCompleted.includes(segment.id),
      )?.id || ''
    );
  };

  const courseStarted = completedSegmentIds.length > 0;

  const nextUnlockedSegmentId = getNextUnlockedSegmentId();

  const items = course.seriesDays?.map((day, index) => ({
    header: day.title,
    value: day.title,
    content: (
      <AccordionContent>
        {day.segments.map(segment => {
          const isNextSegment = nextUnlockedSegmentId === segment.id;
          const isSegmentUnlocked =
            (segment.id && completedSegmentIds.includes(segment.id)) ||
            isNextSegment;

          return (
            <SegmentContent key={segment.id}>
              {segment.icon?.stringValue && (
                <SegmentHeader
                  title={''}
                  idx={0}
                  isComplete={false}
                  isExpanded={false}
                  segmentsLeft={0}
                  icon={segment.icon.stringValue}
                />
              )}
              <SegmentTitle
                clickable={isSegmentUnlocked}
                onClick={() => {
                  if (isSegmentUnlocked) {
                    history.push(
                      `${RoutePath.Series}/${slug}/day/${index + 1}`,
                    );
                  }
                }}
              >
                {segment.title}
              </SegmentTitle>
              {segment.id && day.completedSegments.includes(segment.id) ? (
                <CheckIcon />
              ) : isNextSegment ? (
                <CheckEmptyIcon />
              ) : (
                <LockedIcon />
              )}
            </SegmentContent>
          );
        })}
      </AccordionContent>
    ),
  }));

  return (
    <>
      <CourseContentTitle>
        {translate('course_homepage.course_content.title')}
      </CourseContentTitle>
      <SessionInformation>
        {translate('course_card.course_information', {
          numberOfSessions: course.seriesDays.length,
          avgLengthSession: course.minsPerDay?.numericValue,
        })}
      </SessionInformation>
      <Accordion
        items={items}
        defaultValue={courseStarted ? [activeDayTitle] : []}
      />
    </>
  );
};
