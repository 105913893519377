import styled from 'styled-components';
import {
  HeadingText,
  BodyText,
  Container,
  small,
  extraSmall,
  medium,
} from '@unmind/design-system-components-web';
import { Phone, OpenExternalLink } from 'icons';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { css } from 'styles';
import SecondaryButton from 'Shared/SecondaryButton';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HelpContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const HelpLocationSelectContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  margin-top: ${rem(36)};
  margin-bottom: ${rem(40)};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: ${rem(6)};
`;

export const HeaderText = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize32],
  weight: theme.typography.fontWeights.semiBold,
  accessibilityAutoFocus: true,
}))`
  line-height: 1;
  text-align: center;
`;

export const PhoneIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
  height: 20,
  width: 20,
  ['aria-hidden']: true,
}))``;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(4)};
  padding: ${rem(4)} 0px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(8)};
`;

export const HelpBodyText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.primary,
}))`
  text-align: center;
  line-height: ${rem(20)};
`;

export const PhoneLink = styled.a`
  color: ${({ theme }) => theme.colors.text.link};
  font-size: ${rem(20)};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  line-height: ${rem(20)};
  text-align: center;
`;

export const EapPrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border.secondary};
  width: 100%;
  min-height: ${rem(95)};
  padding: ${rem(20)} ${rem(24)} ${rem(32)};
  margin-left: 0;
  margin-right: 0;
  margin-bottom: ${rem(73)};
  gap: ${rem(8)};

  ${extraSmall(css`
    padding: ${rem(20)} ${rem(16)};
    margin-bottom: 0;
  `)};

  ${small(css`
    padding: ${rem(20)} ${rem(65)};
  `)};

  ${medium(css`
    padding: ${rem(20)} ${rem(78)};
  `)};
`;

export const PrivacyLink = styled.a`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  font-size: ${rem(12)};
  text-decoration: underline;
  text-align: center;
  display: inline-flex;
  margin-left: ${rem(4)};
`;

export const EapPrivacyText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize12],
  color: theme.colors.text.secondary,
}))`
  display: inline-block;
  text-align: center;
  line-height: ${rem(16)};
  gap: ${rem(4)};
`;

export const ExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
}))`
  height: ${rem(14)};
  width: ${rem(14)};
  padding: ${rem(1)};
  stroke-width: 1.5;
`;

export const LinkContainer = styled.span`
  display: inline-block;
  text-align: center;
  align-items: baseline;
  gap: ${rem(4)};
`;

export const AlertContainer = styled.div`
  max-width: ${rem(342)};
  margin-top: ${rem(12)};
  align-self: center;
`;

export const RetryOnErrorButton = styled(SecondaryButton)`
  margin-top: ${rem(24)};
  background: ${({ theme }) => theme.colors.background.card};
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.background.cardHover};
  }
`;
