import { useQuery } from '@apollo/client';

import Permissions from '../../../App/Auth/permissions';
import { SlackIntegration } from '../../../Services/slack/__generated__/SlackIntegration';
import { SLACK_INTEGRATION_QUERY } from '../../../Services/slack/slack.services';

const getSlackIntegrationData = (data?: SlackIntegration) => {
  const currentUserPermission = data?.user?.permissions?.value?.toLowerCase();
  const isAdminUser =
    currentUserPermission === Permissions.admin ||
    currentUserPermission === Permissions.superAdmin;
  const slackIntegration = data?.organisation?.slackIntegration;
  const isSlackUser = Boolean(
    data?.user?.connectedSlackUsers && data.user.connectedSlackUsers.length > 0,
  );
  const slackUserId = data?.user?.connectedSlackUsers?.[0]?.id;

  const slackIntegrationData = {
    isAdminUser,
    isSlackUser,
    slackIntegration,
    slackUserId,
  };

  return data ? slackIntegrationData : undefined;
};

export const useSlackIntegration = () => {
  const { data, loading, error } = useQuery<SlackIntegration>(
    SLACK_INTEGRATION_QUERY,
    { errorPolicy: 'all', returnPartialData: true },
  );

  const slackIntegrationData = getSlackIntegrationData(data);

  return { data: slackIntegrationData, loading, error };
};
