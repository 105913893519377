import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategoryBody = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 26" fill="none" {...props}>
    <path
      d="M8.27 11.77H10a58.58 58.58 0 0 0 1.285-3.238c.284-.754 1.36-.754 1.619 0a192.283 192.283 0 0 0 2.484 6.822c.284.741 1.36.73 1.606-.037l1.211-3.535h8.058l.321.248c1.459-4.548-1.026-9.986-5.783-10.579a8.448 8.448 0 0 0-.977-.062c-2.237 0-4.276.94-5.796 2.472h-.05c-1.531-1.533-3.558-2.472-5.795-2.472-.334 0-.655.025-.976.062-4.696.58-7.168 5.87-5.858 10.368l.272-.074h6.648v.025z"
      fill="#FFC6E5"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.916 24.178c.655.494 1.483.655 2.163 0 1.977-1.842 9.417-8.503 10.652-10.381a7.203 7.203 0 0 0 .853-1.755l-.321-.247h-8.058l-1.21 3.534c-.26.754-1.323.779-1.62.025a169.874 169.874 0 0 1-2.484-6.81c-.26-.766-1.334-.778-1.618-.024-.408 1.1-.791 2.064-1.286 3.238H1.621l-.272.074c.123.432.26.865.482 1.26 2.175 3.868 9.948 10.072 10.875 10.9.074.062.136.124.21.173v.013z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategoryBody;
