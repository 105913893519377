import gql from 'graphql-tag';

/**
 * Queries
 */
export const SLACK_INTEGRATION_QUERY = gql`
  query SlackIntegration {
    organisation {
      id
      slackIntegration {
        teams {
          id
          name
        }
        appId
      }
    }
    user {
      id
      connectedSlackUsers {
        id
      }
      permissions {
        value
      }
    }
  }
`;

/**
 * Mutations
 */
export const LINK_SLACK_USER_MUTATION = gql`
  mutation LinkSlackUserToUnmind(
    $clientSentAtUtcTimestamp: String
    $slackUserId: String!
  ) {
    linkSlackUserToUnmind(
      input: {
        clientSentAtUtcTimestamp: $clientSentAtUtcTimestamp
        slackUserId: $slackUserId
      }
    ) {
      success
    }
  }
`;

export const UNLINK_SLACK_USER_MUTATION = gql`
  mutation UnlinkSlackUserToUnmind(
    $clientSentAtUtcTimestamp: String
    $slackUserId: String!
  ) {
    unlinkSlackUserToUnmind(
      input: {
        clientSentAtUtcTimestamp: $clientSentAtUtcTimestamp
        slackUserId: $slackUserId
      }
    ) {
      success
    }
  }
`;
