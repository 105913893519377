import { showToast } from '@unmind/design-system-components-web';
import { noop } from 'lodash';
import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const HAS_ALREADY_TAKEN_COMPASS_ACTION = 'hasAlreadyTakenCompassAction';

export const ActionTakenContext = createContext({
  showActionTakenModal: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowActionTakenModal: (show: React.SetStateAction<boolean>) => noop(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markActionTaken: noop,
});

export const ActionTakenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showActionTakenModal, setShowActionTakenModal] = useState(false);
  const { t: translate } = useTranslation('compass');

  const markActionTaken = () => {
    const hasAlreadyTakenCompassAction =
      localStorage.getItem(HAS_ALREADY_TAKEN_COMPASS_ACTION) || 'false';

    if (hasAlreadyTakenCompassAction === 'true') {
      // an action has been taken before, so show toast instead of modal
      showToast(translate('action_taken_toast.message'));

      return;
    }

    // action has not been taken before, so show modal
    setShowActionTakenModal(true);

    localStorage.setItem(HAS_ALREADY_TAKEN_COMPASS_ACTION, 'true');
  };

  const contextValue = useMemo(
    () => ({
      showActionTakenModal,
      setShowActionTakenModal,
      markActionTaken,
    }),
    [showActionTakenModal],
  );

  return (
    <ActionTakenContext.Provider value={contextValue}>
      {children}
    </ActionTakenContext.Provider>
  );
};
