import React from 'react';
import { useActionDetail } from 'Compass/api/useActionDetail';
import {
  mapThemeIdsToThemeName,
  mapThemeIdsToThemeType,
} from 'Compass/Components/CompassDashboard/constants';
import { TakeActionCheckbox } from '../TakeActionCheckbox';
import { ActionsMarkdownContainer } from '../ActionsMarkdownContainer';
import { SelectedCard } from './SelectedCard';
import { CardTitle } from './styles';
import { LoadingSelectedCard } from './LoadingSelectedCard';

interface RecommendedActionsSelectedCardProps {
  title: string;
  closeCard(): void;
  actionId: string;
  scheduleId: string;
}

export const RecommendedActionsSelectedCard = ({
  title,
  closeCard,
  actionId,
  scheduleId,
}: RecommendedActionsSelectedCardProps) => {
  const { data: actionDetailData, loading: actionDetailDataLoading } =
    useActionDetail({ scheduleId, actionId });

  if (actionDetailDataLoading) {
    return <LoadingSelectedCard closeCard={closeCard} />;
  }

  const { recommendedActionDetail } = actionDetailData || {};

  return (
    <SelectedCard
      header={<CardTitle>{title}</CardTitle>}
      content={
        recommendedActionDetail?.description && (
          <ActionsMarkdownContainer
            htmlContent={recommendedActionDetail.description}
            actionTrackingData={{
              ...recommendedActionDetail,
              score: recommendedActionDetail.score,
              scheduleId,
              themeId: recommendedActionDetail.themeId || '',
              themeType:
                mapThemeIdsToThemeType[
                  recommendedActionDetail.themeId as keyof typeof mapThemeIdsToThemeType
                ],
              theme:
                mapThemeIdsToThemeName[
                  recommendedActionDetail.themeId as keyof typeof mapThemeIdsToThemeName
                ],
            }}
          ></ActionsMarkdownContainer>
        )
      }
      closeCard={closeCard}
      footer={
        <TakeActionCheckbox
          ariaLabel={title}
          actionId={actionDetailData?.recommendedActionDetail?.actionId || ''}
          scheduleId={scheduleId}
        />
      }
    ></SelectedCard>
  );
};
