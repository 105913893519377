import { useQuery } from '@apollo/client';
import { userQuery } from 'Services/User/__generated__/userQuery';
import { USER_QUERY } from 'Services/User/user.services';
import { useOrganisationEntitlements } from 'Shared/Entitlement';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';

const useIsManagerExperienceActive = () => {
  const isManagerExperienceFeatureFlagActive = useFeatureFlag(
    FEATURE_FLAGS.SHOULD_SHOW_MANAGER_EXPERIENCE_WEB,
  );
  const { entitlements } = useOrganisationEntitlements([
    { entitlement: 'lineManagerFeatures', default: false },
  ]);
  const { data } = useQuery<userQuery>(USER_QUERY);

  return Boolean(
    isManagerExperienceFeatureFlagActive &&
      entitlements.lineManagerFeatures &&
      data?.user?.isLineManager,
  );
};

export default useIsManagerExperienceActive;
