import { useApolloClient } from '@apollo/client';
import useUniversalLogin from '../../LoggedOut/UniversalLogin/useUniversalLogin';
import useFeatureFlagUserContext from '../../flags/useFeatureFlagUserContext';
import { configureSentryUserScope } from '../logging';
import { tracking } from '../Tracking';
import { LogoutReason } from './LogoutReason';

const useLogoutWithUniversalLogin = () => {
  const client = useApolloClient();
  const { anonymizeFeatureFlagUser } = useFeatureFlagUserContext();
  const { logoutWithUniversalLogin } = useUniversalLogin();

  const logout = async () => {
    localStorage.removeItem('irisToken');
    if (window.analytics) {
      window.analytics.reset();
    }
    configureSentryUserScope(null);
    client.clearStore().catch(e => {
      if (e.message === 'Unauthorized') {
        // we're expecting an error here because we're no longer authenticated on the current route
      } else {
        throw e;
      }
    });
    await anonymizeFeatureFlagUser?.();
    tracking.track('logout-successful', {
      logOutReason: LogoutReason.DROPDOWN_CLICKED,
      logOutForced: false,
    });

    return logoutWithUniversalLogin();
  };

  return {
    logoutWithUniversalLogin: logout,
  };
};

export default useLogoutWithUniversalLogin;
