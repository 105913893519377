import React from 'react';
import { IconProps } from '../IconProps';

const SvgExclamation = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#Exclamation_svg__clip0_1601_27549)">
      <g clipPath="url(#Exclamation_svg__clip1_1601_27549)">
        <path
          d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1zM8 8.5V4.73"
          stroke="#0D0300"
          strokeOpacity={0.95}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1z"
          fill="#0D0300"
          fillOpacity={0.95}
        />
      </g>
    </g>
    <defs>
      <clipPath id="Exclamation_svg__clip0_1601_27549">
        <path fill="#fff" transform="matrix(1 0 0 -1 0 16)" d="M0 0h16v16H0z" />
      </clipPath>
      <clipPath id="Exclamation_svg__clip1_1601_27549">
        <path fill="#fff" transform="matrix(1 0 0 -1 0 16)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgExclamation;
