import React from 'react';
import { Breakpoints } from '@unmind/design-system-theme';
import useViewportWidth from 'utils/useViewportWidth';
import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import { CloseIcon } from '../../../../CompassSurveyModal/styles';
import { Header, Footer, CardContent, CardContainer } from './styles';

interface SelectedCardProps {
  header: React.ReactNode;
  content: React.ReactNode;
  closeCard(): void;
  footer?: React.ReactNode;
}

export const SelectedCard = ({
  header,
  content,
  closeCard,
  footer,
}: SelectedCardProps) => {
  const viewportWidth = useViewportWidth();
  const isLargeScreen = viewportWidth < Breakpoints.L;

  const cardContent = (
    <>
      <Header>
        {header}
        {isLargeScreen && <CloseIcon onClick={closeCard} />}
      </Header>
      <CardContent>{content}</CardContent>
      {footer && <Footer>{footer}</Footer>}
    </>
  );

  return isLargeScreen ? (
    <ModalWithDrawerOnMobile children={cardContent} onClose={closeCard} />
  ) : (
    <CardContainer>{cardContent}</CardContainer>
  );
};
