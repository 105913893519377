import React from 'react';
import { Redirect } from 'react-router';
import RoutePath from '../../../App/RoutePath';

export interface WithSeriesDayAccessProps {
  seriesDayProgressLoading?: boolean;
  seriesDayIdLoading?: boolean;
  seriesDayLoading?: boolean;
  seriesDaysCompletedIds: string[];
  completedSegmentIds: string[];
  dayNumber?: number;
  segment?: number;
  seriesCompleted: boolean;
  seriesDayCompleted: boolean;
  slug: string;
}

function isTrueOrUndefined(item: boolean | undefined): boolean {
  return item === undefined || item;
}

export const withSeriesAccess =
  (WrappedComponent: React.ComponentType<WithSeriesDayAccessProps>) =>
  (props: WithSeriesDayAccessProps) => {
    // there are cases where apollo won't provide loading flag
    // so we should treat undefined as loading state
    const loading = [
      props.seriesDayProgressLoading,
      props.seriesDayIdLoading,
      props.seriesDayLoading,
    ].some(isTrueOrUndefined);

    if (!loading && props.dayNumber !== undefined) {
      const lastAccessibleDay = props.seriesCompleted
        ? props.seriesDaysCompletedIds.length
        : props.seriesDaysCompletedIds.length + 1;

      if (lastAccessibleDay < props.dayNumber) {
        return (
          <Redirect
            to={`${RoutePath.Series}/${props.slug}/day/${lastAccessibleDay}`}
          />
        );
      }

      const lastAccessibleSegment = props.completedSegmentIds.length + 1;

      if (
        props.segment !== undefined &&
        lastAccessibleSegment < props.segment
      ) {
        return (
          <Redirect
            to={`${RoutePath.Series}/${props.slug}/day/${props.dayNumber}/${lastAccessibleSegment}`}
          />
        );
      }
    }

    return <WrappedComponent {...props} />;
  };
