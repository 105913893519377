import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsFiresides = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.438 20.002a1 1 0 0 1 1.06.933v.008l.005.047a8.042 8.042 0 0 0 .186 1.017c.17.679.479 1.575 1.034 2.463C11.801 26.195 13.864 28 18 28s6.199-1.805 7.277-3.53a8.647 8.647 0 0 0 1.22-3.48 2.23 2.23 0 0 0 .005-.047v-.006-.002a1 1 0 0 1 1.996.127L27.5 21c.998.062.998.063.998.064v.004l-.001.01-.002.025-.008.086a10.079 10.079 0 0 1-.236 1.303 10.642 10.642 0 0 1-1.278 3.038C25.551 27.805 22.864 30 18 30c-4.864 0-7.55-2.195-8.973-4.47a10.64 10.64 0 0 1-1.514-4.34 4.54 4.54 0 0 1-.008-.087l-.002-.026v-.012l-.001-.001c0-.001 0-.002.998-.064l-.998.062a1 1 0 0 1 .936-1.06zm18.064.933z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 28a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 33a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1zM12 8a6 6 0 0 1 12 0v12a6 6 0 0 1-12 0V8zm6-4a4 4 0 0 0-4 4v12a4 4 0 0 0 8 0V8a4 4 0 0 0-4-4z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsFiresides;
