import { getMSTeamsUrlInfo } from 'utils/MSTeams';
import {
  RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY,
  createLinkToExternalPlayer,
} from './MSTeamsHelpers';

interface HandleMSTeamsDeepLinkProps {
  userIsAuthenticated: boolean;
}

const handleReturnFromPreviousDeepLink = () => {
  /**
   * Users can navigate back to the web app,
   * having previously navigated to the Workplace Index app via a deep link.
   *
   * The previous survey deep link will still be in the MSTeams context.
   *
   * To ensure that the previous survey deep link isn't immediately re-triggered,
   * we check for a query param that indicates the user has returned from a previous deep link.
   */
  const hasReturnedFromPreviousDeepLink =
    new URLSearchParams(window.location.search).get(
      'returned_from_previous_deep_link',
    ) === 'true';

  if (hasReturnedFromPreviousDeepLink) {
    localStorage.setItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY, 'true');
  }
};

export const resolveMSTeamsDeepLink = ({
  userIsAuthenticated,
}: HandleMSTeamsDeepLinkProps): string | undefined => {
  const {
    externalLink,
    externalLinkTitle,
    internalLink,
    externalLinkContentType,
  } = getMSTeamsUrlInfo() || {};

  handleReturnFromPreviousDeepLink();

  const deepLinkHasBeenViewed =
    localStorage.getItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY) === 'true';

  if (deepLinkHasBeenViewed) return;

  if (userIsAuthenticated && internalLink) return internalLink;

  if (!externalLink) return;

  return externalLinkContentType === 'link'
    ? externalLink
    : createLinkToExternalPlayer(
        externalLink,
        externalLinkContentType,
        externalLinkTitle,
      );
};
