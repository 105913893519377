import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsHealthy = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      d="M30.811 18.275c2.269-2.185 2.802-5.527 1.468-8.226-1.868-3.6-6.539-5.013-10.276-3.214-.667.386-1.334.772-1.868 1.286L18 9.92l-2.135-1.8c-2.936-2.828-7.74-2.828-10.81 0-.4.643-.934 1.157-1.334 1.928-1.334 2.7-.8 6.041 1.468 8.226L9 22.071m4.2 4.167L18 31l9-8.333"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgToolsHealthy;
