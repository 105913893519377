import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import React from 'react';
import { CompassOnboardingPage } from 'Compass/CompassOnboardingPage';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { CloseIcon, CloseIconButton } from './CompassSurveyModal/styles';

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const ModalBody = styled.div`
  padding: ${rem(0)} ${rem(24)};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

type CompassFAQModalProps = {
  closeModal(): void;
};

export const CompassFAQModal = ({ closeModal }: CompassFAQModalProps) => {
  const { t: translate } = useTranslation('compass');

  return (
    <ModalWithDrawerOnMobile onClose={closeModal}>
      <ModalHeader>
        <CloseIconButton
          role="button"
          data-testid="modal-close-button"
          tabIndex={0}
          onClick={closeModal}
          aria-label={translate(
            'dashboard.latest_survey_status.about.modal.close_label',
          )}
        >
          <CloseIcon />
        </CloseIconButton>
      </ModalHeader>
      <ModalBody>
        <CompassOnboardingPage showCreateCompassSurveyButton={false} />
      </ModalBody>
    </ModalWithDrawerOnMobile>
  );
};
