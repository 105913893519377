import React from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import {
  IOS_APP_STORE_BADGE_PREFIX,
  ANDROID_APP_STORE_BADGE_PREFIX,
} from 'i18n/assetPrefixes';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';
import { tracking } from '../../App/Tracking';
import { BodyText } from '../../Shared/Typography';

const StyledText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.primary,
}))``;

const AppleBadgeWrapper = styled.img`
  width: ${rem(118)};
  height: auto;
  margin-right: ${rem(16)};
`;

const GoogleBadgeWrapper = styled.img`
  width: ${rem(126)};
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${rem(10)};
`;

interface AppStoreProps {
  helpText?: string;
}

export const AppStore = ({ helpText }: AppStoreProps) => {
  const subdomain = getSubdomainFromUrl();
  const { t: translate } = useTranslation(['logged_out', 'shared']);
  const {
    [ANDROID_APP_STORE_BADGE_PREFIX]: androidStoreBadge,
    [IOS_APP_STORE_BADGE_PREFIX]: iosStoreBadge,
  } = useTranslatedAssets(
    [IOS_APP_STORE_BADGE_PREFIX, ANDROID_APP_STORE_BADGE_PREFIX],
    'svg',
  );

  if (androidStoreBadge === undefined || iosStoreBadge === undefined) {
    return null;
  }

  return (
    <>
      <StyledText>{helpText}</StyledText>
      <BadgesWrapper>
        <a
          onClick={() => {
            tracking.track('appstore-prompt-clicked', {
              subdomain,
              platform: 'iOS',
            });
          }}
          href={translate('shared:store_link.apple.link')}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={translate('shared:store_link.apple.a11y_label')}
        >
          <AppleBadgeWrapper
            data-testid="apple-app-store-badge"
            src={iosStoreBadge}
            alt={translate('shared:store_link.apple.image_alt')}
          />
        </a>
        <a
          href={translate('shared:store_link.google.link')}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={translate('shared:store_link.google.a11y_label')}
        >
          <GoogleBadgeWrapper
            data-testid="google-play-store-badge"
            onClick={() => {
              tracking.track('appstore-prompt-clicked', {
                subdomain,
                platform: 'android',
              });
            }}
            src={androidStoreBadge}
            alt={translate('shared:store_link.google.image_alt')}
          />
        </a>
      </BadgesWrapper>
    </>
  );
};
