export const stripUnusedQueryVariables = (
  object: Record<string, unknown[] | undefined | null>,
) =>
  Object.keys(object).reduce((out, key) => {
    const value = object[key];
    if (value && value.length) {
      return {
        ...out,
        [key]: value,
      };
    }

    return out;
  }, {});
