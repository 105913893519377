import { lighten, rem } from 'polished';
import React, { useContext, useMemo, useState } from 'react';
import {
  ActionMeta,
  default as Select,
  GroupTypeBase,
  OptionProps,
  OptionsType,
  StylesConfig,
  Props as SelectProps,
} from 'react-select';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import { ThemeContext } from 'styled-components';
import { css, styled, ThemeInterface } from 'styles';
import getFocusState from '../Button/getFocusState';
import { FormElementDisplay } from '../Layout';
import BodyText from '../Typography/BodyText';
import { InteractiveContainer } from './InteractiveContainer';

export enum MultiSelectSize {
  Compact = 'Compact',
  Regular = 'Regular',
  Big = 'Big',
}

// The types in the library for react-select are not complete,
// we've had to augment them below to reflect what actually
// comes through.
type IsMulti = true;

interface MultiValueRemoveExtendedProps<T extends BaseOptionValue>
  extends MultiValueRemoveProps<
    MultiSelectOption<T>,
    GroupTypeBase<MultiSelectOption<T>>
  > {
  isFocused: boolean;
}

interface ClearIndicatorExtendedProps<T extends BaseOptionValue>
  extends IndicatorProps<MultiSelectOption<T>, IsMulti> {
  selectProps: any;
}

export const getMultiSelectStyles = <T extends BaseOptionValue>(
  theme: ThemeInterface,
  size?: MultiSelectSize,
): StylesConfig<MultiSelectOption<T>, IsMulti> => {
  const {
    colors: { multiSelect: multiSelectTheme },
  } = theme;

  return {
    menu: provided => ({
      ...provided,
      zIndex: +2,
    }),
    placeholder: provided => ({
      ...provided,
      opacity: 1,
      color: theme.colors.text.secondary,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.colors.text.primary,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    clearIndicator: (provided, state: ClearIndicatorExtendedProps<T>) => ({
      ...provided,
      color: theme.colors.text.primary,
      display: state.selectProps.value.length > 1 ? 'flex' : 'none',
      ':hover': {
        cursor: 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: state.isFocused
        ? `0 0 0 1px ${theme.colors.primary}`
        : 'transparent',
    }),
    input: provided => ({
      ...provided,
      marginTop: '4px',
      marginBottom: '4px',
    }),
    multiValueLabel: provided => ({
      ...provided,
      textTransform: 'uppercase',
      color: theme.colors.text.primary,
      fontSize: theme.typography.fontSizes.fontSize14,
      paddingRight: '8px',
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: multiSelectTheme.multiValue.backgroundColor,
      margin: size === MultiSelectSize.Compact ? '3px' : '2px',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: size === MultiSelectSize.Compact ? '5px' : '8px',
    }),
    multiValueRemove: (provided, state: MultiValueRemoveExtendedProps<T>) => ({
      ...provided,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: lighten(0.4, theme.colors.staticPalette.red.red6),
      },
      backgroundColor: state.isFocused
        ? multiSelectTheme.multiValueRemove.focused.backgroundColor
        : multiSelectTheme.multiValueRemove.backgroundColor,
    }),
    option: (provided, state) => ({
      ...provided,
      ':hover': {
        backgroundColor: multiSelectTheme.option.hover.backgroundColor,
      },
      ':active': {
        backgroundColor: multiSelectTheme.option.active.backgroundColor,
      },
      backgroundColor: state.isFocused
        ? multiSelectTheme.option.backgroundColor
        : 'transparent',
      color: state.isDisabled
        ? theme.colors.text.disabled
        : theme.colors.text.primary,
    }),
  };
};

const Container = styled(InteractiveContainer)<{
  containerType?: FormElementDisplay;
  open: boolean;
}>`
  position: relative;
  flex-direction: column;
  display: inline-block;
  width: 100%;
  border: ${rem('1px')} solid ${({ theme }) => theme.colors.text.secondary};

  ${({ containerType }) =>
    containerType === FormElementDisplay.Inline &&
    css`
      display: flex;
      width: auto;
      border: none;
    `};
  ${({ open }) => {
    if (open) {
      return css`
        border: ${rem('1px')} solid ${({ theme }) => theme.colors.primary};
        ${getFocusState()}
      `;
    }
  }};
`;

export type BaseOptionValue = string | number;

export interface MultiSelectOption<T extends BaseOptionValue> {
  label: string;
  value: T;
  isDisabled?: boolean;
}

interface MultiSelectProps<T extends BaseOptionValue> {
  onChange?(
    values: OptionsType<MultiSelectOption<T>>,
    action?: ActionMeta<MultiSelectOption<T>>,
  ): void;
  error?: string;
  options?: MultiSelectOption<T>[];
  defaultSelectedValues?: MultiSelectOption<T>[];
  placeholder: string;
  /**
   * Changes component's behaviour between Inline and Block
   */
  display?: FormElementDisplay;
  inputId?: string;
  size?: MultiSelectSize;
  customOption?(
    props: OptionProps<MultiSelectOption<T>, true>,
  ): JSX.Element | null;
  value?: MultiSelectOption<T>[];
  /**
   * Escape hatch to directly configure react-select props
   */
  selectProps?: SelectProps<MultiSelectOption<T>, IsMulti>;
}

const Error = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.error,
}))`
  margin: ${rem('4px')} 0 ${rem('10px')};
  text-align: left;
`;

export const MultiSelect = <T extends BaseOptionValue>({
  onChange,
  placeholder = 'Select',
  options = [],
  display,
  error,
  defaultSelectedValues = [],
  inputId,
  size,
  customOption,
  value,
  selectProps,
}: MultiSelectProps<T>) => {
  const themeContext: ThemeInterface = useContext(ThemeContext);
  const multiSelectStyles = getMultiSelectStyles<T>(themeContext, size);

  const [isMenuOpen, setisMenuOpen] = useState(false);

  const hasError = Boolean(error);

  const memoOption = useMemo(
    () => (customOption ? { Option: customOption } : undefined),
    [customOption],
  );

  return (
    <>
      <Container containerType={display} hasError={hasError} open={isMenuOpen}>
        <Select
          isMulti
          options={options}
          isOptionDisabled={option => !!option.isDisabled}
          placeholder={placeholder}
          styles={{ ...multiSelectStyles, ...(selectProps?.styles || {}) }}
          defaultValue={defaultSelectedValues}
          onMenuClose={() => setisMenuOpen(false)}
          onMenuOpen={() => setisMenuOpen(true)}
          onChange={onChange}
          inputId={inputId}
          components={memoOption}
          value={value}
          {...selectProps}
        />
      </Container>
      {hasError && <Error>{error}</Error>}{' '}
    </>
  );
};
