import { BodyText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';

const CardDecorationStyle = css`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  border-radius: ${rem(16)};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.background.card};
`;

export const PractitionerCardContainer = styled(Link)`
  ${CardDecorationStyle}
  box-shadow: 0px 6px 12px -3px rgba(13, 3, 0, 0.05),
    0px 3px 6px -2px rgba(13, 3, 0, 0.05);

  transition: transform 200ms ease-in-out, transform 200ms ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.98;
  }

  &:hover {
    transition: transform 200ms ease-in-out, transform 200ms ease-in-out;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
    transform: scale(1.01);
  }
`;

export const ImageWrapper = styled.img<{ src: string }>`
  position: relative;
  flex-shrink: 0;
  height: 342px;
  max-height: 100%;
  object-fit: cover;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(12)} ${rem(16)} ${rem(16)};
  height: 100%;
  justify-content: space-between;
`;

export const SummaryWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: ${rem(4)};
  margin-top: ${rem(8)};
`;

export const PractitionerTypeText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.primary,
}))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PractitionerNameText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize24],
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.semiBold,
}))`
  margin-bottom: ${rem(8)};
  line-height: 1;
`;

export const PractitionerLocation = styled.div`
  margin-top: ${rem(8)};
  margin-bottom: ${rem(16)};
  display: flex;
  align-items: center;
`;

export const LocationText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-left: ${rem(8)};
`;

export const SummaryText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  lineHeight: theme.typography.lineHeights.lineHeight20,
  color: theme.colors.text.primary,
}))`
  height: ${rem(90)};
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  mask-image: linear-gradient(180deg, black 80%, transparent);
`;

export const FocusAreas = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FocusAreaPills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(4)} ${rem(12)};
`;

export const FocusAreaPill = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize12],
}))`
  display: inline-block;
  padding: ${rem(2)} ${rem(6)};
  border-radius: ${rem(18)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

export const LocationIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: 16,
  height: 16,
}))``;
