import React from 'react';
import { IconProps } from '../IconProps';

const SvgLockShield = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#LockShield_svg__clip0_1124_26755)">
      <g
        clipPath="url(#LockShield_svg__clip1_1124_26755)"
        stroke="#0D0300"
        strokeOpacity={0.95}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.499 18.907c-.321.124-.677.124-.998 0A13.392 13.392 0 0 1 .998 6.443V2.385A1.385 1.385 0 0 1 2.383 1h15.234a1.385 1.385 0 0 1 1.385 1.385v4.044a13.392 13.392 0 0 1-8.503 12.478z" />
        <path d="M12.077 7.925H7.923c-.765 0-1.385.62-1.385 1.385v2.77c0 .764.62 1.384 1.385 1.384h4.154c.765 0 1.385-.62 1.385-1.385V9.31c0-.764-.62-1.384-1.385-1.384zM12.077 7.925V6.54a2.077 2.077 0 1 0-4.154 0v1.385" />
      </g>
    </g>
    <defs>
      <clipPath id="LockShield_svg__clip0_1124_26755">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
      <clipPath id="LockShield_svg__clip1_1124_26755">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLockShield;
