import { Calendar, Lock } from 'icons';
import get from 'lodash/get';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withProps } from 'recompose';
import { styled, ThemeInterface } from 'styles';
import RoutePath from '../../App/RoutePath';
import PrimaryButton from '../../Shared/PrimaryButton';
import { BodyText } from '../../Shared/Typography';
import { PageContainer } from '../SeriesShared';
import ProgressIndicator from './ProgressIndicator';
import { SeriesDayCardHighlights } from './SeriesDayCardHighlights';
import {
  EventSource,
  TrackEventDayOptions,
} from './SeriesHelpers/makeTrackSeriesEvent';
import Slider from './Slider';
import {
  ExtendedSeriesDay,
  withSeriesDays,
  WithSeriesDaysChildProps,
} from './withSeriesDays';

// Size relationship between active and inactive slides
const SLIDE_SCALE = 0.8;

const SeriesDaySlide = styled.div`
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: normal;
  width: 100%;

  > * {
    flex-grow: 1;
  }
`;

const SlideHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${rem(SLIDE_SCALE * 28)};
  padding-top: ${rem(SLIDE_SCALE * 23)};
`;

const SlideFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: 0 ${rem(SLIDE_SCALE * 28)} ${rem(SLIDE_SCALE * 28)};
  position: relative;

  &::before {
    content: '';
    backdrop-filter: blur(32px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: inherit;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  white-space: normal;
  width: 100%;
`;

export const Day = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
}))`
  white-space: normal;
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
  margin-top: ${rem(5)};
`;

const CalendarIcon = styled<{ disabled: boolean } & any>(Calendar).attrs(
  ({ disabled, theme }: { disabled: boolean; theme: ThemeInterface }) => ({
    primaryColor: disabled ? theme.colors.text.disabled : theme.colors.primary,
    secondaryColor: 'transparent',
  }),
)`
  width: ${rem(24)};
  height: ${rem(24)};
  padding-right: ${rem(8)};
  margin-top: 6px;
  vertical-align: top;
  overflow: visible;
`;

const LockIcon = styled<any>(Lock).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.button.primary.disabled.textColor,
    secondaryColor: 'transparent',
  }),
)`
  font-size: ${rem(18)};
  vertical-align: top;
`;

const CallToActionButton = styled(PrimaryButton)`
  margin-top: ${rem(16)};
`;

interface SeriesSliderProps extends WithSeriesDaysChildProps {
  initialDay?: number;
  trackSeriesEvents(
    source: EventSource,
    dayOptions: TrackEventDayOptions,
  ): void;
}

export const SeriesSlider = ({
  seriesDays,
  slug,
  initialDay,
  trackSeriesEvents,
}: SeriesSliderProps) => {
  const { t: translate } = useTranslation('courses');
  const initialSlide = initialDay
    ? initialDay - 1
    : seriesDays &&
      (seriesDays.every(({ complete }) => complete)
        ? seriesDays.length - 1
        : seriesDays.findIndex(({ complete }) => !complete));

  const getButtonLabel = (day: ExtendedSeriesDay) => {
    const baseText =
      day.day === 1 && !day.started
        ? translate('course_day_slider.cta_button.course')
        : translate('course_day_slider.cta_button.session', {
            session_number: day.day,
          });

    if (day.locked) {
      return (
        <>
          <LockIcon /> {baseText}
        </>
      );
    }

    const statusText =
      day.started && !day.complete
        ? translate('course_day_slider.cta_button.continue_variant.text')
        : day.complete
        ? translate('course_day_slider.cta_button.review_variant.text')
        : !day.started && !day.locked
        ? translate('course_day_slider.cta_button.begin_variant.text')
        : '';

    return `${statusText} ${baseText}`;
  };

  return (
    <PageContainer>
      <Slider
        initialSlide={initialSlide}
        key={initialSlide}
        showControls={true}
        showUnusableControls={false}
        slideScale={SLIDE_SCALE}
      >
        {slug !== undefined &&
          seriesDays !== undefined &&
          seriesDays.map((day, index) => (
            <SeriesDaySlide key={index}>
              <SlideHeader>
                <TitleWrapper>
                  <CalendarIcon disabled={day.locked} />
                  <DayWrapper>
                    <Day>
                      {translate('course_day_slider.session_number', {
                        session_number: day.day,
                      })}
                    </Day>
                    <Title>{day.title}</Title>
                  </DayWrapper>
                </TitleWrapper>
                <CallToActionButton
                  label={getButtonLabel(day)}
                  disabled={day.locked}
                  to={{
                    pathname: `${RoutePath.Series}/${slug}/day/${day.day}`,
                  }}
                  onClick={() => {
                    trackSeriesEvents('series-day-slider', {
                      dayId: day.id,
                      dayNumber: day.day,
                      dayStarted: day.started,
                      dayComplete: day.complete,
                    });
                  }}
                />
              </SlideHeader>
              <SeriesDayCardHighlights
                sliderView
                dayHighlights={day.highlights}
              />
              <SlideFooter>
                <ProgressIndicator
                  completedItems={day.segmentsComplete}
                  totalItems={day.segmentsTotal}
                />
              </SlideFooter>
            </SeriesDaySlide>
          ))}
      </Slider>
    </PageContainer>
  );
};

export default compose<
  SeriesSliderProps,
  Pick<SeriesSliderProps, 'trackSeriesEvents'>
>(
  withRouter,
  withProps((props: RouteComponentProps) => ({
    slug: get(props.match.params, 'slug'),
    initialDay: get(props.location.state, 'initialDay'),
  })),
  withSeriesDays,
)(SeriesSlider);
