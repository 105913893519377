import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { Transcript } from 'icons';

import SegmentActionButton from './SegmentActionButton';

const Icon = styled<any>(Transcript).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.primary,
  }),
)`
  width: ${rem('76px')};
  height: ${rem('76px')};
`;

const ReadButton = () => {
  const { t: translate } = useTranslation('courses');

  return (
    <SegmentActionButton text={translate('segment.read_button.text')}>
      <Icon />
    </SegmentActionButton>
  );
};

export default ReadButton;
