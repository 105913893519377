import { useQuery } from '@apollo/client';
import { Colors } from '@unmind/design-system-theme';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { styled, ThemeInterface, useTheme } from 'styles';
import Card from '../../../Shared/Card';
import { BodyText, HeadingText } from '../../../Shared/Typography';

import {
  WellbeingTrackerBandingsForAboutScreen as WellbeingTrackerBandings,
  WellbeingTrackerBandingsForAboutScreen_wellbeingTracker_bands as WellbeingTrackerBands,
} from './__generated__/WellbeingTrackerBandingsForAboutScreen';

const ScreenHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.bold,
}))`
  margin-bottom: 16px;
  line-height: 20px;
`;

const Body = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  line-height: 20px;
`;

const LineBreak = styled.span`
  margin-top: 8px;
`;

const RangeHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
  flex-wrap: nowrap;
`;

const RangeIndicatorPill = styled.div<{ color: Colors }>`
  height: 6px;
  width: 24px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;

const RangeHeading = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
}))`
  line-height: 100%;
`;

const RangeDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  line-height: 140%;
`;

const Container = styled(Card)`
  height: fit-content;
  padding: 24px 20px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface BandingInfoProps {
  heading: string;
  description: string;
  color: Colors;
}

const BandingInfo = ({ heading, description, color }: BandingInfoProps) => (
  <>
    <RangeHeadingWrapper>
      <RangeIndicatorPill color={color} />
      <RangeHeading>{heading}</RangeHeading>
    </RangeHeadingWrapper>
    <RangeDescription>{description}</RangeDescription>
  </>
);

function generateBandingInfo(
  band: WellbeingTrackerBands,
  colors: ThemeInterface['colors'],
  translate: TFunction<'track'>,
) {
  switch (band.name) {
    case 'Mid':
      return (
        <BandingInfo
          heading={translate('wellbeing.bands.mid.title', {
            lower_boundary: band.lower,
            upper_boundary: band.upper,
          })}
          description={translate('wellbeing.bands.mid.description')}
          color={colors.index.bands.mid}
        />
      );
    case 'High':
      return (
        <BandingInfo
          heading={translate('wellbeing.bands.high.title', {
            lower_boundary: band.lower,
          })}
          description={translate('wellbeing.bands.high.description')}
          color={colors.index.bands.high}
        />
      );

    default:
      return (
        <BandingInfo
          heading={`${translate('wellbeing.bands.low.title')} (<${band.upper})`}
          description={translate('wellbeing.bands.low.description')}
          color={colors.index.bands.low}
        />
      );
  }
}

export const WELLBEING_TRACKER_BANDINGS_QUERY = gql`
  query WellbeingTrackerBandingsForAboutScreen {
    wellbeingTracker {
      bands {
        name
        lower
        upper
      }
    }
  }
`;

const ScienceBehindScores = ({ bordered = true }: { bordered?: boolean }) => {
  const { t: translate } = useTranslation('track');
  const { colors } = useTheme();
  const { data } = useQuery<WellbeingTrackerBandings>(
    WELLBEING_TRACKER_BANDINGS_QUERY,
  );

  const trackerBands = data?.wellbeingTracker?.bands;

  return (
    <Container bordered={bordered}>
      <ScreenHeading>{translate('wellbeing.about_screen.title')}</ScreenHeading>
      <Body>{translate('wellbeing.about_screen.description_part_one')}</Body>
      <LineBreak />
      <Body>{translate('wellbeing.about_screen.description_part_two')}</Body>

      {trackerBands?.length ? (
        <>
          {trackerBands.map(
            (band: WellbeingTrackerBands, i: React.Key | undefined) => (
              <React.Fragment key={i}>
                {generateBandingInfo(band, colors, translate)}
              </React.Fragment>
            ),
          )}
        </>
      ) : null}
    </Container>
  );
};

export default ScienceBehindScores;
