import {
  ExploreCategoryBody,
  ExploreCategoryGrow,
  ExploreCategoryOvercome,
  ExploreCategoryRelax,
  ExploreCategorySleep,
  ExploreCategoryWork,
} from 'icons';

export const CategoryIcons = {
  sleep: ExploreCategorySleep,
  work: ExploreCategoryWork,
  body: ExploreCategoryBody,
  grow: ExploreCategoryGrow,
  relax: ExploreCategoryRelax,
  overcome: ExploreCategoryOvercome,
};
