import { addDays, addWeeks, endOfDay } from 'date-fns';

// Max window within which users can book with a practitioner
const weeksToView = 9;

// In calendar days: notice required for booking
const bookingLeadIn = 1;

export const getDefaultDateRange = (today: Date) => ({
  start: endOfDay(addDays(today, bookingLeadIn)),
  end: addWeeks(today, weeksToView),
});
