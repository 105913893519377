/**
 * @deprecated We want to move away from mapping errors frontend
 * as it's usually more helpful to expose the actual error
 * and/or improve the way errors are returned from backend operations
 */
export enum Errors {
  InternalServerError = 'InternalServerError',
  ServerError = 'ServerError',
  UnrecognisedSubdomain = 'UnrecognisedSubdomain',
  InvalidCredentialsError = 'InvalidCredentialsError',
  AccountLockedError = 'AccountLockedError',
  NotFoundError = 'NotFoundError',
  Error = 'Error',
  InvalidCurrentPassword = 'InvalidCurrentPassword',
  InvalidCustomerIdentifier = 'InvalidCustomerIdentifier',
  InvalidArgumentError = 'InvalidArgumentError',
  RateLimitError = 'RateLimitError',
  TokenExpiredError = 'TokenExpiredError',
  UnauthorizedError = 'UnauthorizedError',
  FileExceedsMaxSize = 'FileExceedsMaxSize',
}

export function getError(error: string): Errors | undefined {
  return Object.values(Errors).find(errorName => error === errorName);
}

export type GraphQLError = {
  name: string;
  message?: string;
};
