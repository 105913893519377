import React from 'react';
import { IconProps } from '../IconProps';

const SvgIndicatorSuccess = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipRule="evenodd"
    >
      <path
        d="M56 28a28 28 0 1 1-56 0 28 28 0 0 1 56 0z"
        fill={secondaryColor}
      />
      <path
        d="M40.6 14.7a3.5 3.5 0 0 1 .7 4.9l-15.75 21a3.5 3.5 0 0 1-5.67-.095l-5.25-7.497a3.502 3.502 0 1 1 5.74-4.014l2.478 3.542L35.7 15.4a3.5 3.5 0 0 1 4.9-.7z"
        fill={primaryColor}
      />
    </g>
  </svg>
);

export default SvgIndicatorSuccess;
