import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { styled } from 'styles';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { FullWidthContainer } from '../../../Shared/Layout';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import BodyText from '../../../Shared/Typography/BodyText';

const Container = styled.div`
  margin: ${rem(80)} auto 0;
  text-align: center;
`;

const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))``;

const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))`
  margin-top: ${rem(20)};
`;

const ProgressButton = styled(PrimaryButton)`
  display: inline-block;
  margin-top: ${rem(32)};
`;

interface SeriesDayReminderProps extends RouteComponentProps {
  seriesId: string;
  slug: string;
  error: boolean;
}

const SeriesDayReminder = (props: SeriesDayReminderProps) => {
  const { t: translate } = useTranslation(['courses', 'shared']);

  const navigateToProgress = (): void => {
    const { slug, seriesId } = props;

    tracking.track('series-reminder-view-progress-clicked', { seriesId });

    props.history.push(`${RoutePath.Series}/${slug}/#progress`);
  };

  return (
    <FullWidthContainer>
      <Container>
        {props.error ? (
          <Subtitle>
            {translate('shared:errors.messages.oops_something_wrong')}
          </Subtitle>
        ) : (
          <>
            <Title>
              {translate('courses:course_day.complete.reminder.title')}
            </Title>
            <Subtitle>
              {translate('courses:course_day.complete.reminder.subtitle')}
            </Subtitle>
          </>
        )}
        <ProgressButton
          onClick={navigateToProgress}
          label={translate(
            'courses:course_day.complete.view_progress_button.label',
          )}
          size={ButtonSize.medium}
        />
      </Container>
    </FullWidthContainer>
  );
};

interface EnhancedComponentProps {
  slug: string;
  error: boolean;
  seriesId: string;
}
export default compose<SeriesDayReminderProps, Record<string, unknown>>(
  withRouter,
  (Component: React.ComponentClass<EnhancedComponentProps>) =>
    (props: RouteComponentProps<{ slug: string }>) =>
      (
        <Component
          {...props}
          slug={props.match.params.slug}
          error={props.location.state.error}
          seriesId={props.location.state.seriesId}
        />
      ),
)(SeriesDayReminder);
