import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { Audio } from 'icons';

import SegmentActionButton from './SegmentActionButton';

export const Icon = styled<any>(Audio).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.primary,
  }),
)`
  width: ${rem('92px')};
  height: ${rem('92px')};
  margin-bottom: -10px;
  *[stroke] {
    stroke-width: 0.7;
  }
`;

const ListenButton = () => {
  const { t: translate } = useTranslation('courses');

  return (
    <SegmentActionButton text={translate('segment.listen_button.text')}>
      <Icon />
    </SegmentActionButton>
  );
};

export default ListenButton;
