import gql from 'graphql-tag';

export const UPDATE_INDEX_SCHEDULE_DATES = gql`
  mutation updateIndexScheduleDates($input: UpdateIndexScheduleDatesInput!) {
    updateIndexScheduleDates(input: $input) {
      indexSchedule {
        id
        startDate
        endDate
      }
    }
  }
`;
