import { useAuth0 } from '@auth0/auth0-react';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from '../../App/RoutePath';

export type UseUniversalLoginReturn = {
  universalLoginError: Error | undefined;
  isUniversalLoginLoading: boolean;
  loginWithUniversalLogin(): Promise<void>;
  logoutWithUniversalLogin(): void;
  isAuthenticatedViaUniversalLogin: boolean;
};

type loginWithUniversalLoginParams = {
  authProviderOrganisationId?: string | null;
  returnTo?: string;
};

export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_LOGIN_SCOPE =
  'read:current_user email openid offline_access';

const useUniversalLogin = () => {
  const { loginWithRedirect, logout, loginWithPopup } = useAuth0();

  const loginWithUniversalLogin = async ({
    authProviderOrganisationId,
    returnTo,
  }: loginWithUniversalLoginParams) => {
    const redirect_uri = `${window.location.origin}${RoutePath.SSOLoginHome}`;

    return loginWithRedirect({
      appState: { returnTo },
      authorizationParams: {
        organization: authProviderOrganisationId ?? undefined,
        redirect_uri,
        audience: AUTH0_AUDIENCE,
        scope: AUTH0_LOGIN_SCOPE,
      },
    });
  };

  const logoutWithUniversalLogin = async () =>
    logout({
      logoutParams: {
        returnTo: isMSTeams()
          ? window.location.origin + RoutePath.StartSplitScreen
          : window.location.origin,
      },
    });

  const loginWithPopupWindow = async ({
    authProviderOrganisationId,
  }: loginWithUniversalLoginParams) =>
    loginWithPopup({
      authorizationParams: {
        organization: authProviderOrganisationId ?? undefined,
        audience: AUTH0_AUDIENCE,
        scope: AUTH0_LOGIN_SCOPE,
      },
    });

  return {
    loginWithUniversalLogin,
    logoutWithUniversalLogin,
    loginWithPopupWindow,
  };
};

export default useUniversalLogin;
