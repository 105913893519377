import gql from 'graphql-tag';
import React, { useState } from 'react';

import { SeriesQuestionResponse } from '../../../../../__generated__/globalTypes';
import RadioQuestion from '../../../../../Shared/Radio/Question';
import {
  SingleSeries_singleSeries,
  SingleSeries_singleSeries_questions,
} from '../../../__generated__/SingleSeries';

export interface QuestionnaireQuestionsProps {
  series: SingleSeries_singleSeries;
  impactQuestionnaireQuestionsCount?: number;
  answers?: SeriesQuestionResponse[];
  currentQuestionIndex?: number;
  setFinalAnswers(answers: SeriesQuestionResponse[]): void;
  nextSection(): void;
}

export const SERIES_QUESTIONS_QUERY = gql`
  query seriesQuestions($seriesId: String!) {
    seriesQuestions(seriesId: $seriesId) {
      id
      orderId
      question
      isReversed
    }
  }
`;

export function QuestionnaireQuestions({
  series,
  impactQuestionnaireQuestionsCount = 0,
  answers: finalAnswers = [],
  currentQuestionIndex = 0,
  setFinalAnswers,
  nextSection,
}: QuestionnaireQuestionsProps) {
  const [answers, setAnswers] =
    useState<SeriesQuestionResponse[]>(finalAnswers);
  const [currentQuestion, setCurrentQuestion] = useState(currentQuestionIndex);
  const nextStepDelay = 500;

  const seriesQuestionsData =
    series.questions?.filter(
      (q): q is SingleSeries_singleSeries_questions => !!q,
    ) || [];
  const scale = seriesQuestionsData[currentQuestion]?.scale;

  if (seriesQuestionsData.length === 0 || !scale) {
    return null;
  }

  function onBackButtonClick() {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  }

  function getUpdatedAnswers(answer: number) {
    const newAnswers = [...answers];

    if (answers[currentQuestion] === undefined) {
      return [
        ...answers,
        { seriesQuestionId: seriesQuestionsData[currentQuestion].id, answer },
      ];
    }
    const newAnswer = {
      seriesQuestionId: answers[currentQuestion].seriesQuestionId,
      answer,
    };

    newAnswers.splice(currentQuestion, 1, newAnswer);

    return newAnswers;
  }

  function onAnswerSelected(answer: number) {
    const newAnswers = getUpdatedAnswers(answer);
    setAnswers(newAnswers);

    // next step/section
    setTimeout(() => {
      if (currentQuestion < seriesQuestionsData.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setFinalAnswers(newAnswers);
        nextSection();
      }
    }, nextStepDelay);
  }

  return (
    <RadioQuestion
      questionText={`“${(
        seriesQuestionsData[currentQuestion].questionText || ''
      ).trim()}”`}
      minLabel={scale.minimumValue}
      maxLabel={scale.maximumValue}
      questionNumber={currentQuestion + 1}
      totalQuestions={
        (seriesQuestionsData.length || 0) + impactQuestionnaireQuestionsCount
      }
      onBackButtonClick={onBackButtonClick}
      onAnswerSelected={onAnswerSelected}
      selectedIndex={
        answers[currentQuestion] !== undefined
          ? answers[currentQuestion].answer
          : undefined
      }
    />
  );
}

export default QuestionnaireQuestions;
