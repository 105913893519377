import React, { ReactNode, useEffect, useRef } from 'react';

import { css, styled } from 'styles';

import DefaultCloseButton from './DefaultCloseButton';
import Modal from './Modal';

type TakeoverContainerProps = {
  scrollable: boolean;
};

const TakeoverContainer = styled.div<TakeoverContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  background-color: ${({ theme }) => theme.colors.background.primary};
  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: auto;
    `}
`;

export interface FullScreenModalProps {
  className?: string;
  shown: boolean;
  children: ReactNode;
  showCloseButton?: boolean;
  onClose?(): void;
  hasWhiteBackground?: boolean;
  closeButtonLabel?: string;
  scrollable?: boolean;
  initFocus?: boolean;
  reclaimFocus?: boolean;
  allowForOverlay?: boolean;
}

export const FullScreenTakeover = ({
  className,
  children,
  closeButtonLabel,
  hasWhiteBackground,
  onClose,
  showCloseButton,
  shown,
  scrollable = false,
  initFocus = true,
  reclaimFocus = false,
  allowForOverlay = false,
}: FullScreenModalProps) => {
  const modalDiv = useRef<HTMLDivElement>(null);
  const previousFocus = useRef<HTMLElement | null>(null);

  const getAllElements = () => {
    if (modalDiv.current) {
      return modalDiv.current.querySelectorAll<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
    }

    return [];
  };

  const handleFocusChange = (e: React.FocusEvent<HTMLElement>) => {
    // Keep track of currently focused modal element in case we lose focus to another modal and come back
    previousFocus.current = e.target;
  };

  const handleTab = (e: React.KeyboardEvent) => {
    if (e.key !== 'Tab') {
      return;
    }

    if (modalDiv.current !== null) {
      const modalElements = getAllElements();

      if (modalElements.length > 0) {
        const firstElement = modalElements[0];
        const lastElement = modalElements[modalElements.length - 1];

        if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          e.preventDefault();

          return;
        }

        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();

          return;
        }
      }
    }
  };

  useEffect(() => {
    const modalClassName = allowForOverlay
      ? 'modal-overlay-open'
      : 'modal-open';
    document.body.classList[shown ? 'add' : 'remove'](modalClassName);
    if (shown && initFocus) {
      // initFocus is true by default, meaning we will "steal" focus from
      // wherever it is and give it to the first modal element.
      // However, initFocus can be set to false if focus decisions should be left up to the modal contents (like we do in media player)
      const allFocusable = getAllElements();
      if (allFocusable.length > 0) {
        allFocusable[0].focus();
      }
    }

    return () => {
      document.body.classList.remove(modalClassName);
    };
  }, [initFocus, allowForOverlay, shown]);

  useEffect(() => {
    // If we receive a signal to reclaim focus from a previously overriding modal, we can take it back now
    if (reclaimFocus && previousFocus.current) {
      previousFocus.current.focus();
    }
  }, [reclaimFocus]);

  return shown ? (
    <Modal>
      <TakeoverContainer
        className={className}
        onKeyDown={handleTab}
        ref={modalDiv}
        onFocus={handleFocusChange}
        scrollable={scrollable}
      >
        {children}
        {showCloseButton === true && (
          <DefaultCloseButton
            hasWhiteBackground={hasWhiteBackground}
            accessibilityLabel={closeButtonLabel}
            onClose={onClose}
            showCloseButton={showCloseButton}
          />
        )}
      </TakeoverContainer>
    </Modal>
  ) : null;
};
