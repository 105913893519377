import { ApolloError } from '@apollo/client';
import { rem } from 'polished';
import { useLocation } from 'react-router-dom';
import React, { forwardRef } from 'react';
import SectionError from 'Shared/SectionError';
import { css, styled } from 'styles';
import { useTranslation } from 'react-i18next';
import { Alert, ContentTile } from '@unmind/design-system-components-web';
import { SkeletonContentTile } from 'Shared/Skeleton';
import { ContentType } from '../../../Explore/types';
import { extraSmall, small } from '../../../utils';
import NoResultsView from '../NoResultsView/NoResultsView';
import {
  exploreSearch_exploreSearch_edges_node_Series as Series,
  exploreSearch_exploreSearch_edges_node_Tool as Tool,
  exploreSearch_exploreSearch_edges_node_HelpSearchText as HelpSearchText,
} from '../__generated__/exploreSearch';

const RESULT_CARD_HEIGHT = 95;
const MAX_NUMBER_OF_RESULTS_FOR_TRANSITION = 6;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ResultsContainer = styled.div<{ numberOfResults: number }>`
  position: relative;
  margin-top: ${rem(20)};
  border-top: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  overflow-y: hidden;
  max-height: -webkit-fill-available;
  margin-bottom: ${rem(30)};
  min-height: ${props => props.numberOfResults * RESULT_CARD_HEIGHT}px;

  &.search-results-enter-active {
    animation: fadeIn 0.2s ease-in forwards;
  }

  &.search-results-enter-done {
    opacity: 1;
  }

  &.search-results-exit-active {
    animation: fadeOut 0.2s ease-in forwards;
  }

  &.search-results-exit-done {
    opacity: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${extraSmall(css`
    background-color: ${({ theme }) => theme.colors.background.card};
    margin-top: ${rem(8)};
    border-top: none;
    border-radius: ${rem(32)};
    padding-left: ${rem(24)};
  `)};

  ${({ numberOfResults }) =>
    small(css`
      max-width: ${rem(545)};
      min-height: min(${rem(500)}, ${numberOfResults * RESULT_CARD_HEIGHT}px);
      max-height: ${rem(500)};
      margin: ${rem(8)} auto;
    `)};
`;

const List = styled.ol`
  margin: 0;
  list-style-type: none;
  overflow-y: scroll;
  padding-bottom: 175px;
  padding-left: 0;
  height: 100vh;

  ${small(css`
    min-height: min(31.25rem, 570px);
    max-height: 31.25rem;
    padding: ${rem(24)} 0;

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => `${theme.colors.background.skeleton}`};
      border: 4px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar {
      width: 13px;
    }
  `)};
`;

const ListItem = styled.li`
  margin-bottom: 12px;
  padding: 12px 0px;

  :last-child {
    margin-bottom: 0px;
  }

  ${small(css`
    padding: 5px 0px;
  `)};
`;

const AlertItem = styled(Alert)`
  a {
    text-decoration: underline;
  }
`;

export interface ResultsListProps {
  searchTerm: string;
  results: (Series | Tool | HelpSearchText)[];
  runSearchQuery(text: string): void;
  isLoading: boolean;
  hasErrored?: ApolloError;
  trackResultSelected(
    contentType: ContentType,
    contentSlug: string | null,
    contentId: string,
    resultsPosition: number,
  ): void;
  assetToken: string | null;
}

const ResultsList = forwardRef<HTMLDivElement, ResultsListProps>(
  (
    {
      results,
      searchTerm,
      isLoading,
      hasErrored,
      runSearchQuery,
      trackResultSelected,
      assetToken,
    },
    ref,
  ) => {
    const location = useLocation();
    const { t: translate } = useTranslation(['courses', 'shorts']);

    const getNumberOfResultsForHeight = () => {
      if (results?.length) {
        return results.length > MAX_NUMBER_OF_RESULTS_FOR_TRANSITION
          ? MAX_NUMBER_OF_RESULTS_FOR_TRANSITION
          : results.length;
      }
    };
    const numberOfResultsForHeight = getNumberOfResultsForHeight();

    if (searchTerm === '') {
      return null;
    }

    if (hasErrored) {
      return (
        <ResultsContainer
          className="search-results"
          numberOfResults={MAX_NUMBER_OF_RESULTS_FOR_TRANSITION}
          ref={ref}
        >
          <ContentWrapper>
            <SectionError onRetry={() => runSearchQuery(searchTerm)} />
          </ContentWrapper>
        </ResultsContainer>
      );
    }

    if (isLoading || !results) {
      return (
        <ResultsContainer
          className="search-results"
          numberOfResults={MAX_NUMBER_OF_RESULTS_FOR_TRANSITION}
          ref={ref}
        >
          <List>
            {[...Array(MAX_NUMBER_OF_RESULTS_FOR_TRANSITION)].map((_, i) => (
              <ListItem key={i}>
                <SkeletonContentTile />
              </ListItem>
            ))}
          </List>
        </ResultsContainer>
      );
    }

    if (results && results.length === 0) {
      return (
        <ResultsContainer
          className="search-results"
          numberOfResults={MAX_NUMBER_OF_RESULTS_FOR_TRANSITION}
          ref={ref}
        >
          <ContentWrapper>
            <NoResultsView
              searchTerm={searchTerm}
              runSearchQuery={runSearchQuery}
            />
          </ContentWrapper>
        </ResultsContainer>
      );
    }

    return results ? (
      <ResultsContainer
        className="search-results"
        numberOfResults={numberOfResultsForHeight ?? 0}
        ref={ref}
      >
        <List id="matches" role="listbox">
          {results?.map((result, index) => {
            if (
              result?.__typename === 'HelpSearchText' &&
              result?.title &&
              result?.text
            ) {
              return (
                <ListItem key={result.id} style={{ marginRight: '8px' }}>
                  <AlertItem
                    title={result.title}
                    body={result.text}
                    bodyContainsMarkup={true}
                    variant="info"
                    size="small"
                    orientation="vertical"
                  />
                </ListItem>
              );
            } else if (result?.__typename === 'Tool') {
              return (
                <ListItem key={result.id}>
                  <ContentTile
                    item={{
                      itemType: result.mediaType?.value as 'audio' | 'video',
                      title: result.title as string,
                      thumbnailUrl: `${result.cloudinaryThumbnail?.path}?${assetToken}`,
                      tags: [
                        result.estimatedMins
                          ? translate('shorts:short_card.duration_text', {
                              duration: result.estimatedMins.toString(),
                            })
                          : '',
                        result.exploreCategory?.topLevelCategory
                          ?.title as string,
                      ],
                    }}
                    to={{
                      pathname: `/shorts/${result.slug}`,
                      state: {
                        ...location.state,
                        onToolPlayerClose: {
                          location: {
                            pathname: location.pathname,
                            search: `?q=${searchTerm}`,
                            hash: location.hash,
                          },
                        },
                      },
                    }}
                    onClick={() =>
                      trackResultSelected(
                        ContentType.short,
                        result.slug,
                        result.id,
                        index,
                      )
                    }
                    variant="horizontal"
                  />
                </ListItem>
              );
            } else if (result?.__typename === 'Series') {
              if (result.totalDays === 1) {
                return null;
              }

              return (
                <ListItem key={result.id}>
                  <ContentTile
                    item={{
                      itemType: 'course',
                      title: result.title as string,
                      thumbnailUrl: `${result.cloudinaryThumbnail?.path}?${assetToken}`,
                      tags: [
                        result.totalDays
                          ? translate(
                              'courses:course_card.course_duration_text',
                              {
                                total_days: result.totalDays,
                              },
                            )
                          : '',
                      ],
                    }}
                    to={`/courses/${result.slug}`}
                    variant="horizontal"
                    onClick={() =>
                      trackResultSelected(
                        ContentType.course,
                        result.slug,
                        result.id,
                        index,
                      )
                    }
                  />
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </List>
      </ResultsContainer>
    ) : null;
  },
);

export default ResultsList;
