import { rem } from 'polished';
import { css, styled } from 'styles';
import { small } from '../utils';

export const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;

  ${small(css`
    max-width: ${rem(960)};
  `)}
`;

export interface Highlight {
  text: string;
}
