import { rem } from 'polished';
import {
  Alert,
  Card,
  HeadingText,
  Loader,
  small,
} from '@unmind/design-system-components-web';
import { css, styled } from 'styles';
import { InputBar } from '../styles';

export const Container = styled(Card)`
  border: none;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: ${rem(16)};

  ${small(css`
    padding: ${rem(24)};
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(6)};
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

export const ErrorAlert = styled(Alert)`
  margin-top: ${rem(20)};
`;

export const LoadingIndicator = styled(Loader).attrs(({ theme }) => ({
  color: theme.colors.text.disabled.replace('0.6', '0.5'),
}))`
  display: flex;
  width: 100%;
  height: ${rem(500)};
  justify-content: center;
  align-items: center;
  position: relative;
  right: ${rem(20)};
`;

export const SuggestionItem = styled(InputBar)`
  margin-top: ${rem(10)};
  margin-bottom: ${rem(10)};
  cursor: pointer;
  justify-content: space-between;
`;

export const ItemIconContainer = styled.div`
  margin-left: ${rem(6)};
`;
