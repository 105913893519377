/**
 *
 * UpcomingEvent
 *
 */
import { tracking } from 'App/Tracking';
import { talkEventRequestsQuery_unmindEventRequests_edges_node as EventRequest } from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQuery';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { ExternalLinkIcon } from 'Shared/Typography/ExternalLink';
import { formatDate } from 'Talk/lib/dates';
import { getHelpLink } from 'Talk/lib/getHelpLink';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { Tooltip } from '@unmind/design-system-components-web';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { Namespace, TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoutePath from '../../../App/RoutePath';
import { CalendarLinks } from './components/CalendarLinks';
import { EditEvent } from './components/EditEvent';
import {
  Anchor,
  BrowseLink,
  BrowseLinkArrow,
  CardBody,
  CardBodyRow,
  CardContentContainer,
  CardHeading,
  CardHeadingWrapper,
  CardWrapper,
  MeetingHeaderText,
  EditWrapper,
  HelpCentreLinkWrapper,
  Image,
  ImageWrapper,
  MeetingText,
  MetaActionsContainer,
  SessionInfo,
  UpcomingEventWrapper,
  Wrapper,
} from './styles';
import { EventData, EventType } from './types';

export interface UpcomingEventProps {
  eventType: EventType;
  event?: Event | null;
  eventRequest?: EventRequest | null;
  defocused?: boolean;
  numEvents: number;
}

const getDuration = (
  t: TFunction<Namespace<'talk'>>,
  beginsAt: string,
  endsAt: string,
): string => {
  const startTime = new Date(beginsAt);
  const endTime = new Date(endsAt);

  const durationInMinutes = differenceInMinutes(endTime, startTime);
  const durationInHours = differenceInHours(endTime, startTime);

  if (durationInMinutes <= 60) {
    return t('upcoming_events.duration_mins', {
      mins: durationInMinutes,
    });
  }

  return t('upcoming_events.duration_hrs', {
    hours: durationInHours,
    mins: durationInMinutes,
    count: durationInHours,
  });
};

const getEventData = (t: TFunction<Namespace<'talk'>>, event: Event) => {
  if (!event) {
    return null;
  }

  const userProfile = event.userProfile;
  const firstName = userProfile?.firstName;
  const lastName = userProfile?.lastName;
  const eventTime = formatDate(
    event?.beginsAt as string,
    'ccc, d LLL, h:mmaaa',
  );

  const duration = getDuration(
    t,
    event.beginsAt as string,
    event.endsAt as string,
  );
  const meetURL = event.zoomMeetingDetails
    ? event.zoomMeetingDetails.url
    : `${process.env.REACT_APP_FRANKIE_MEET_URL}/unmind/s/lobby/${event.session?.externalRef}/${event.session?.password}`;
  const photo = userProfile?.photoUrls[0]?.url ?? '';

  return {
    firstName,
    lastName,
    eventTime,
    duration,
    photo,
    meetURL,
    event,
  };
};

const getEventRequestData = (event: EventRequest) => {
  if (!event) {
    return null;
  }

  const photo = event?.preferredPractitioner?.photoUrls[0]?.url ?? '';
  const firstName = event?.preferredPractitioner?.firstName;
  const lastName = event?.preferredPractitioner?.lastName;

  return { firstName, lastName, photo };
};

export const UpcomingEvent = ({
  numEvents,
  event,
  eventRequest,
  eventType,
  defocused = false,
}: UpcomingEventProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const cancelEventEnabled = useFeatureFlag(
    FEATURE_FLAGS.TALK_ENABLE_CANCEL_EVENT,
  );
  const outcomeMeasuresFlagEnabled = useFeatureFlag(
    FEATURE_FLAGS.TALK_ENABLE_OUTCOME_MEASURES,
  );

  let eventData: EventData | null;

  if (eventType === EventType.EVENT) {
    eventData = getEventData(t, event as Event);

    const eventDate = new Date(eventData?.event?.beginsAt);
    const now = new Date();
    const twentyFourHours = 24 * 60 * 60 * 1000;

    if (eventData) {
      eventData.isWithin24Hours =
        eventDate.getTime() - now.getTime() <= twentyFourHours;
    }
  } else if (eventType === EventType.EVENT_REQUEST) {
    eventData = getEventRequestData(eventRequest as EventRequest);
  } else {
    return null;
  }

  return (
    <UpcomingEventWrapper>
      <Wrapper fullWidth={outcomeMeasuresFlagEnabled}>
        <CardWrapper defocused={defocused}>
          <CardContentContainer>
            <CardHeadingWrapper>
              <CardHeading>
                <ImageWrapper>
                  <Image src={eventData?.photo} />
                </ImageWrapper>
                <SessionInfo>
                  {eventType === EventType.EVENT ? (
                    <MeetingHeaderText>
                      {eventData?.eventTime}
                    </MeetingHeaderText>
                  ) : (
                    <MeetingHeaderText>
                      {t('upcoming_events.pending_request_title')}
                    </MeetingHeaderText>
                  )}
                  {t('upcoming_events.title', {
                    firstName: eventData?.firstName,
                    lastName: eventData?.lastName,
                  })}
                </SessionInfo>
              </CardHeading>
              {event && eventType === EventType.EVENT && cancelEventEnabled ? (
                <EditWrapper>
                  {eventData?.isWithin24Hours ? (
                    <Tooltip
                      triggerElement={
                        <EditEvent
                          event={event}
                          isDisabled
                          numEvents={numEvents}
                        />
                      }
                      tooltipContents={
                        <span>{t('upcoming_events.edit_event.tooltip')}</span>
                      }
                      placement="bottom"
                      accessibilityId="bottom-tooltip"
                    />
                  ) : (
                    <EditEvent event={event} numEvents={numEvents} />
                  )}
                </EditWrapper>
              ) : null}
            </CardHeadingWrapper>
            {eventType === EventType.EVENT && eventData?.meetURL && (
              <CardBody>
                <CardBodyRow>
                  <MeetingText
                    href={eventData.meetURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      tracking.track('talk-event-meeting-link-selected', {
                        linkType: eventData?.event?.zoomMeetingDetails
                          ? 'zoom'
                          : 'meet',
                      })
                    }
                  >
                    <ExternalLinkIcon />
                    {t('upcoming_events.join_meeting')}
                  </MeetingText>
                </CardBodyRow>

                {eventData?.event && (
                  <CalendarLinks
                    callURL={eventData.meetURL}
                    event={eventData.event}
                  />
                )}
              </CardBody>
            )}
          </CardContentContainer>
        </CardWrapper>

        <MetaActionsContainer>
          <HelpCentreLinkWrapper>
            {t('common.help_cta.need_help')}{' '}
            <Anchor forwardedAs="a" href={getHelpLink()} target="_blank">
              {`${t('common.help_cta.visit_help')}`}
            </Anchor>
          </HelpCentreLinkWrapper>

          <BrowseLink
            to={RoutePath.TalkBrowse}
            data-cy="talk-homescreen-browse-cta"
          >
            {t('upcoming_events.browse_cta.label')} <BrowseLinkArrow />
          </BrowseLink>
        </MetaActionsContainer>
      </Wrapper>
    </UpcomingEventWrapper>
  );
};
