import React from 'react';
import { IconProps } from '../IconProps';

const SvgArrow = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 11" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.385.93a.554.554 0 0 0-.374.183.505.505 0 0 0-.103.148.483.483 0 0 0-.05.242.47.47 0 0 0 .014.144.61.61 0 0 0 .088.185c.015.021.715.726 1.554 1.565l1.527 1.527-6.776.003L.49 4.93l-.062.017a.586.586 0 0 0-.42.456.77.77 0 0 0 0 .2.586.586 0 0 0 .42.455l.062.018 6.776.003 6.776.003-1.527 1.526c-.84.84-1.54 1.544-1.554 1.566a.61.61 0 0 0-.088.185.47.47 0 0 0-.014.144c0 .105.012.16.05.241.029.06.055.098.103.148a.54.54 0 0 0 .426.181c.116 0 .21-.029.313-.095a571.37 571.37 0 0 0 4.127-4.12c.06-.075.116-.2.116-.26 0-.01.003-.02.007-.022a.268.268 0 0 0 .006-.08c0-.05-.002-.076-.006-.073-.004.002-.007-.004-.007-.014a.549.549 0 0 0-.116-.261 551.689 551.689 0 0 0-4.127-4.12.572.572 0 0 0-.365-.097zM.002 5.504c0 .044.001.062.002.04a.85.85 0 0 0 0-.08c0-.022-.002-.004-.002.04z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgArrow;
