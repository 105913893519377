import { Colors } from '@unmind/design-system-theme';
import moment from 'moment';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { styled, ThemeInterface } from 'styles';
import { Chevron } from 'icons';

import { css } from 'styled-components';
import { extraSmall, small } from '../../utils';
import IndexLineChart from './IndexLineChart';
import { IndexScore } from './types';

const SEEK_INTERVAL = 850;

const ScrollChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 ${rem(8)};
  margin-top: ${rem(20)};

  ${extraSmall(css`
    padding: 0 ${rem(24)};
  `)};

  ${small(css`
    margin-top: 0;
  `)};
`;

const ControlWrapper = styled.div<{ visible: boolean }>`
  align-items: center;
  display: flex;
  justify-items: center;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const ChartWrapper = styled.div`
  flex: 1;
  max-width: 100%;
`;

const ArrowIcon = styled(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
  }),
)`
  &:hover {
    *[stroke] {
      stroke: #555;
    }

    *[fill]:not([fill='none']) {
      fill: #eee;
    }
  }
  transform: rotate(90deg);
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 ${rem(6)} rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: ${rem(32)};
  padding: ${rem(9)};
  position: absolute;
  top: 50%;
  width: ${rem(32)};
  z-index: 1;
`;

const ArrowLeftIcon = styled(ArrowIcon)`
  left: 0;
  transform: translateY(-50%) translateY(-12px) rotate(180deg);
`;

const ArrowRightIcon = styled(ArrowIcon)`
  right: 0;
  transform: translateY(-50%) translateY(-12px);
`;

interface IndexLineChartScrollWrapperProps {
  caption: string;
  data?: IndexScore[];
  height?: number;
  plotColor?: Colors;
}

const IndexLineChartScrollWrapper = ({
  caption,
  data,
  height,
  plotColor,
}: IndexLineChartScrollWrapperProps) => {
  const initialStartDate = moment().startOf('month').subtract(2, 'month');
  const initialEndDate = moment().endOf('month');

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [canMoveEarlier, setEarlierMovement] = useState(false);
  const [canMoveLater, setLaterMovement] = useState(false);

  if (!data) {
    return <div />;
  }

  const moveEarlier = () => {
    if (
      canMoveEarlier &&
      startDate > moment(data[data.length - 1].date).startOf('month')
    ) {
      setStartDate(moment(startDate).subtract(1, 'month'));
      setEndDate(moment(endDate).subtract(1, 'month'));
    }
  };

  const moveLater = () => {
    if (canMoveLater && endDate < moment(data[0].date).endOf('month')) {
      setStartDate(moment(startDate).add(1, 'month'));
      setEndDate(moment(endDate).add(1, 'month'));
    }
  };

  let earlierHandle: number | undefined = window.setInterval(
    moveEarlier,
    SEEK_INTERVAL,
  );
  let laterHandle: number | undefined = window.setInterval(
    moveLater,
    SEEK_INTERVAL,
  );

  const clearTimers = () => {
    if (earlierHandle !== undefined) {
      clearInterval(earlierHandle);
    }

    if (laterHandle !== undefined) {
      clearInterval(laterHandle);
    }
  };

  const enableEarlierMovement = () => {
    if (earlierHandle === undefined) {
      earlierHandle = window.setInterval(moveEarlier, SEEK_INTERVAL);
    }
    setEarlierMovement(true);
    setLaterMovement(false);
  };

  const enableLaterMovement = () => {
    if (laterHandle === undefined) {
      laterHandle = window.setInterval(moveLater, SEEK_INTERVAL);
    }
    setEarlierMovement(false);
    setLaterMovement(true);
  };

  const disableMovement = () => {
    setEarlierMovement(false);
    setLaterMovement(false);
    clearTimers();
  };

  const goToEarliestPoint = () => {
    disableMovement();
    const newStartDate = moment(data[data.length - 1].date).startOf('month');
    setStartDate(newStartDate);
    setEndDate(moment(newStartDate).endOf('month').add(2, 'month'));
  };

  const goToLatestPoint = () => {
    disableMovement();
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  };

  // FIXME
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => () => {
    clearTimers();
  });

  return (
    <ScrollChartWrapper>
      <ControlWrapper
        visible={
          startDate > moment(data[data.length - 1].date).startOf('month')
        }
      >
        <ArrowLeftIcon
          onClick={goToEarliestPoint}
          onMouseEnter={enableEarlierMovement}
          onMouseLeave={disableMovement}
        />
      </ControlWrapper>
      <ChartWrapper>
        <IndexLineChart
          caption={caption}
          data={data}
          endDate={endDate}
          height={height}
          plotColor={plotColor}
          startDate={startDate}
        />
      </ChartWrapper>
      <ControlWrapper visible={endDate < moment(data[0].date).startOf('month')}>
        <ArrowRightIcon
          onMouseEnter={enableLaterMovement}
          onMouseLeave={disableMovement}
          onClick={goToLatestPoint}
        />
      </ControlWrapper>
    </ScrollChartWrapper>
  );
};

export default IndexLineChartScrollWrapper;
