import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'Shared/Loader';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { showToast } from '@unmind/design-system-components-web';
import { isMSTeams } from 'utils/MSTeams';
import {
  NotificationPreferenceTypes,
  NotificationTypes,
  SubscriptionType,
  UpdateNotificationType,
} from '../types';
import {
  USER_NOTIFICATION_PREFERENCES,
  useUserNotificationPreferences,
} from '../hooks/useUserNotificationPreferences';
import { useOrganisationTeamsTenantIds } from '../hooks/useOrganisationTeamsTenantIds';
import { NotificationPreferenceType } from './NotificationPreferenceType';
import { QuickNotificationPreferences } from './QuickNotificationPreferences';
import { PrivacyPolicy } from './PrivacyPolicy';
import { EditNotificationPreferenceModal } from './EditNotificationPreferenceModal';
import {
  UpdateNotificationPreferences,
  UpdateNotificationPreferencesVariables,
} from './__generated__/updateNotificationPreferences';

interface ModalState {
  open: boolean;
  editingPreference: string | null;
}

export const UPDATE_USER_NOTIFICATION_PREFERENCES_MUTATION = gql`
  mutation UpdateNotificationPreferences(
    $input: UpdateNotificationPreferencesInput!
  ) {
    updateNotificationPreferences(input: $input) {
      teamsNotificationPrefs {
        communityParticipation
        productUpdates
        upcomingEvents
      }
      emailNotificationPrefs {
        communityParticipation
        productUpdates
        upcomingEvents
      }
    }
  }
`;

export const NotificationsPreferenceCentreTab = () => {
  const { t: translate } = useTranslation('account', {
    keyPrefix: 'notification_preference_centre',
  });
  const client = useApolloClient();
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    editingPreference: null,
  });
  const {
    data: organisationTeamsTenantIdData,
    loading: isOrgTenantIdsLoading,
  } = useOrganisationTeamsTenantIds();

  const { data, loading: loadingUserNotificationPrefsQuery } =
    useUserNotificationPreferences();
  const [
    updateNotificationPreferences,
    { loading: loadingUserNotificationPrefsMutation },
  ] = useMutation<
    UpdateNotificationPreferences,
    UpdateNotificationPreferencesVariables
  >(UPDATE_USER_NOTIFICATION_PREFERENCES_MUTATION);

  if (loadingUserNotificationPrefsQuery || !data?.user) {
    return <Loader />;
  }

  const { teamsNotificationPrefs: teams, emailNotificationPrefs: email } =
    data.user.notificationPreferences;

  const hideTeamsPreferences =
    !(
      !isOrgTenantIdsLoading &&
      organisationTeamsTenantIdData?.organisation?.msTenantIds &&
      organisationTeamsTenantIdData?.organisation?.msTenantIds?.length > 0
    ) && !isMSTeams();

  const userPreferences: NotificationPreferenceTypes[] = [
    {
      title: translate('community_updates.title'),
      description: translate('community_updates.description'),
      subscriptions: {
        isTeamsEnabled: teams.communityParticipation,
        isEmailEnabled: email.communityParticipation,
      },
      key: NotificationTypes.communityParticipation,
    },
    {
      title: translate('upcoming_events.title'),
      description: translate('upcoming_events.description'),
      subscriptions: {
        isTeamsEnabled: teams.upcomingEvents,
        isEmailEnabled: email.upcomingEvents,
      },
      key: NotificationTypes.upcomingEvents,
    },
    {
      title: translate('product_updates.title'),
      description: translate('product_updates.description'),
      subscriptions: {
        isTeamsEnabled: teams.productUpdates,
        isEmailEnabled: email.productUpdates,
      },
      key: NotificationTypes.productUpdates,
    },
  ];

  const getUserSubscriptionPreferencesByKey = (
    key: NotificationTypes,
    subscriptionType: SubscriptionType,
  ) =>
    userPreferences.find(preference => preference.key === key)?.subscriptions[
      subscriptionType
    ];

  const toggleScroll = (isModalOpen: boolean) => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
  };

  const handleOpenEditModal = (selectedPreference: string) => {
    toggleScroll(true);

    setModalState({
      open: true,
      editingPreference: selectedPreference,
    });
  };

  const updatePreferences = async (
    preferenceUpdate: UpdateNotificationType,
  ) => {
    // Store the original state before the optimistic update
    const prevQueryData = cloneDeep(data);

    client.writeQuery({
      query: USER_NOTIFICATION_PREFERENCES,
      data: {
        user: {
          notificationPreferences: {
            teamsNotificationPrefs: {
              productUpdates: getUserSubscriptionPreferencesByKey(
                NotificationTypes.productUpdates,
                SubscriptionType.isTeamsEnabled,
              ),
              upcomingEvents: getUserSubscriptionPreferencesByKey(
                NotificationTypes.upcomingEvents,
                SubscriptionType.isTeamsEnabled,
              ),
              communityParticipation: getUserSubscriptionPreferencesByKey(
                NotificationTypes.communityParticipation,
                SubscriptionType.isTeamsEnabled,
              ),
              ...preferenceUpdate.teamsNotificationPrefs,
            },
            emailNotificationPrefs: {
              productUpdates: getUserSubscriptionPreferencesByKey(
                NotificationTypes.productUpdates,
                SubscriptionType.isEmailEnabled,
              ),
              upcomingEvents: getUserSubscriptionPreferencesByKey(
                NotificationTypes.upcomingEvents,
                SubscriptionType.isEmailEnabled,
              ),
              communityParticipation: getUserSubscriptionPreferencesByKey(
                NotificationTypes.communityParticipation,
                SubscriptionType.isEmailEnabled,
              ),
              ...preferenceUpdate.emailNotificationPrefs,
            },
          },
        },
      },
    });

    await updateNotificationPreferences({
      variables: { input: preferenceUpdate },
      onError: () => {
        // Rollback the optimistic update
        client.writeQuery({
          query: USER_NOTIFICATION_PREFERENCES,
          data: prevQueryData,
        });

        showToast(translate('update_preferences.error_message'));
      },
    });
  };

  const allSubscriptions = userPreferences.map(preference => ({
    ...preference.subscriptions,
  }));

  return (
    <>
      <QuickNotificationPreferences
        subscriptions={allSubscriptions}
        hideTeamsPreferences={hideTeamsPreferences}
        updatePreferences={updatePreferences}
        disableToggles={loadingUserNotificationPrefsMutation}
      />
      {userPreferences.map(({ title, description, subscriptions, key }) => (
        <NotificationPreferenceType
          title={title}
          description={description}
          subscriptions={subscriptions}
          hideTeamsPreferences={hideTeamsPreferences}
          testId={key}
          key={key}
          onClick={() => handleOpenEditModal(title)}
        />
      ))}
      {modalState.open && modalState.editingPreference !== null && (
        <EditNotificationPreferenceModal
          onClose={() => {
            toggleScroll(false);
            setModalState({ open: false, editingPreference: null });
          }}
          hideTeamsPreferences={hideTeamsPreferences}
          updatePreferences={updatePreferences}
          disableToggles={loadingUserNotificationPrefsMutation}
          preference={userPreferences.find(
            preference => preference.title === modalState.editingPreference,
          )}
        />
      )}
      <PrivacyPolicy />
    </>
  );
};
