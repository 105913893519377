import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { Errors } from '../Shared/Errors';

export type WithUserResponse = {
  user: {
    __typename: string;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    praiseDirectory: {
      value: string;
      __typename: string;
    } | null;
    permissions: {
      value: string;
      __typename: string;
    };
    userType: {
      id: number;
      __typename: string;
    };
    departmentData?: {
      id: string;
      name: string;
      __typename: string;
    };
    locationData?: {
      id: string;
      name: string;
      __typename: string;
    };
  };
};

export interface WithUserChildProps {
  error?: Errors;
  firstName?: string;
  lastName?: string;
  email?: string;
  loading: boolean;
  praiseDirectory?: boolean;
  permissions?: string[];
  userType?: number;
  departmentId?: string;
  locationId?: string;
}

export const USER_QUERY = gql`
  query withUser {
    user {
      id
      firstName
      lastName
      email
      praiseDirectory {
        value
      }
      permissions {
        value
      }
      userType {
        id
      }
      departmentData {
        id
        name
      }
      locationData {
        id
        name
      }
    }
  }
`;

const withUser = graphql<
  Record<string, never>,
  WithUserResponse,
  Record<string, never>,
  WithUserChildProps
>(USER_QUERY, {
  props: ({ data }) => {
    if (data && data.loading) {
      return {
        loading: true,
      };
    }

    if (data && data.user) {
      return {
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        praiseDirectory:
          data.user.praiseDirectory !== null
            ? data.user.praiseDirectory.value === '1'
            : true,
        permissions:
          data.user.permissions !== undefined
            ? [data.user.permissions.value.toLowerCase()]
            : [],
        userType: data.user.userType.id,
        locationId: data.user.locationData
          ? data.user.locationData.id
          : undefined,
        departmentId: data.user.departmentData
          ? data.user.departmentData.id
          : undefined,
        loading: false,
      };
    }

    return {
      error: Errors.ServerError,
      loading: false,
    };
  },
  options: {
    update: (cache, result) => {
      cache.writeQuery({
        data: result.data,
        query: USER_QUERY,
      });
    },
    fetchPolicy: 'network-only',
  },
});

export default withUser;
