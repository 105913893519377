import { useEffect } from 'react';

/**
 * Adds a keydown event listener which triggers the provided `onKeyDown` callback
 * if the pressed key code is in the provided `keyCodes` array
 */
export function useKeyDown(
  keyCodes: KeyboardEvent['code'][],
  onKeyDown: () => void,
) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (keyCodes.includes(event.code)) {
        onKeyDown();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
