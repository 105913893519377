import React, { useState } from 'react';
import { CountryPicker } from 'Shared/CountryPicker';
import styled from 'styled-components';
import { css } from 'styles';
import { rem } from 'polished';
import { Alert, small } from '@unmind/design-system-components-web';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { ApolloQueryResult, gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import { useCountries } from './hooks/useGetCountries';
import {
  SetUserSelectedLocation,
  SetUserSelectedLocationVariables,
} from './__generated__/SetUserSelectedLocation';
import { getUserCurrentLocation } from './hooks/__generated__/getUserCurrentLocation';
import { getCountryContact } from './hooks/__generated__/getCountryContact';
import { getEapServices } from './hooks/__generated__/getEapServices';
import { getCountries_countries } from './hooks/__generated__/getCountries';

const CountryPickerContainer = styled.div<{ inModal?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${rem(428)};
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px ${rem(24)} ${rem(24)};
  gap: ${rem(16)};
  > div:first-child {
    padding: ${({ inModal }) => (inModal ? `${rem(24)} 0px 0px` : rem(24))};
    background: ${({ inModal, theme }) =>
      inModal ? theme.colors.background.primary : theme.colors.background.card};
  }

  ${({ inModal }) =>
    inModal &&
    small(css`
      margin-top: ${rem(18)};
    `)}
`;

const CountryPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: ${rem(20)};
  width: fit-content;
  height: fit-content;
`;

const ErrorContainer = styled.div`
  display: flex;
`;

export const SET_USER_SELECTED_LOCATION = gql`
  mutation SetUserSelectedLocation($input: SetUserSelectedLocationInput!) {
    setUserSelectedLocation(input: $input) {
      countryCode
      success
    }
  }
`;

type HelpLocationSelectionProps = {
  refetchUserLocation(): Promise<ApolloQueryResult<getUserCurrentLocation>>;
  refetchCountryContact(): Promise<ApolloQueryResult<getCountryContact>>;
  refetchEapServices(): Promise<ApolloQueryResult<getEapServices>>;
  inModal?: boolean;
  onClose?(): void;
};

const ALL_OTHER_COUNTRIES_CODE = 'zz';
const mapAndFilterCountries = (
  countries: getCountries_countries[],
  countryCodesToIgnore: string[],
) =>
  countries
    .filter(({ id }) => !countryCodesToIgnore.includes(id.toLocaleLowerCase()))
    .map(({ name, id }) => ({
      value: id,
      label: name,
      isDisabled: false,
    }));

const HelpLocationSelect = ({
  refetchUserLocation,
  refetchCountryContact,
  refetchEapServices,
  inModal,
  onClose: handleClose,
}: HelpLocationSelectionProps) => {
  const [error, setError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { data, loading: isLoadingCountries } = useCountries();

  const { t: translate } = useTranslation('help', {
    keyPrefix: 'country_picker',
  });

  const [
    setUserSelectedLocation,
    { loading: isLoadingSetUserSelectedLocation },
  ] = useMutation<SetUserSelectedLocation, SetUserSelectedLocationVariables>(
    SET_USER_SELECTED_LOCATION,
  );

  if (isLoadingCountries || isLoadingSetUserSelectedLocation) {
    return <LoadingIndicator />;
  }

  const countryOptions = mapAndFilterCountries(data?.countries || [], [
    ALL_OTHER_COUNTRIES_CODE,
  ]);

  const submitCountry = async (countryCode: string) => {
    setDisableSubmit(true);
    await setUserSelectedLocation({
      variables: {
        input: {
          countryCode,
        },
      },
      onCompleted: async () => {
        const country = countryOptions.find(
          option => option.value === countryCode,
        );
        tracking.track('user_selected_location_updated', {
          countryName: country?.label,
          countryCode: country?.value,
          source: 'help',
        });
        await Promise.all([
          refetchUserLocation(),
          refetchCountryContact(),
          refetchEapServices(),
        ]);
        if (handleClose) {
          handleClose();
        }
      },
      onError: updateError => {
        setDisableSubmit(false);
        setError(`There was an error updating your location: ${updateError}`);
      },
    });
  };

  return (
    <CountryPickerContainer inModal={inModal}>
      <CountryPickerWrapper>
        <CountryPicker
          countryOptions={countryOptions}
          disabled={disableSubmit || isLoadingSetUserSelectedLocation}
          onSubmit={submitCountry}
          inModal={inModal}
          displayCloseButton={false}
        />
      </CountryPickerWrapper>

      {error && (
        <ErrorContainer>
          <Alert
            title={translate('error.title')}
            body={translate('error.body')}
            variant="error"
            size="small"
          />
        </ErrorContainer>
      )}
    </CountryPickerContainer>
  );
};

export default HelpLocationSelect;
