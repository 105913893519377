import { styled, ThemeInterface } from 'styles';
import {
  HappierFaceWithBorder,
  HappiestFaceWithBorder,
  HappyFaceWithBorder,
  NeutralFaceWithBorder,
  SadderFaceWithBorder,
  SaddestFaceWithBorder,
  SadFaceWithBorder,
} from 'icons';

import { Moods } from '../../Track/Moods/types';

export const SaddestIcon = styled<any>(SaddestFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.saddest,
    secondaryColor: theme.colors.mood.saddest,
    role: 'img',
    'aria-label': 'Saddest',
  }),
)``;

export const SadderIcon = styled<any>(SadderFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.sadder,
    secondaryColor: theme.colors.mood.sadder,
    role: 'img',
    'aria-label': 'Sadder',
  }),
)``;

export const SadIcon = styled<any>(SadFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.sad,
    secondaryColor: theme.colors.mood.sad,
    role: 'img',
    'aria-label': 'Sad',
  }),
)``;

export const NeutralIcon = styled<any>(NeutralFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.neutral,
    secondaryColor: theme.colors.mood.neutral,
    role: 'img',
    'aria-label': 'Neutral',
  }),
)``;

export const HappyIcon = styled<any>(HappyFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.happy,
    secondaryColor: theme.colors.mood.happy,
    role: 'img',
    'aria-label': 'Happy',
  }),
)``;

export const HappierIcon = styled<any>(HappierFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.happier,
    secondaryColor: theme.colors.mood.happier,
    role: 'img',
    'aria-label': 'Happier',
  }),
)``;

export const HappiestIcon = styled<any>(HappiestFaceWithBorder).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.mood.happiest,
    secondaryColor: theme.colors.mood.happiest,
    role: 'img',
    'aria-label': 'Happiest',
  }),
)``;

export const moodIconMap = {
  [Moods.Saddest]: SaddestIcon,
  [Moods.Sadder]: SadderIcon,
  [Moods.Sad]: SadIcon,
  [Moods.Neutral]: NeutralIcon,
  [Moods.Happy]: HappyIcon,
  [Moods.Happier]: HappierIcon,
  [Moods.Happiest]: HappiestIcon,
};
