import { Namespace } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CompassFurtherInfoPage } from './CompassFurtherInfoPage';
import * as S from './styles';

export const ValueOfCompassPage = () => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');

  return (
    <CompassFurtherInfoPage title={translate('value_of_compass_page.title')}>
      <S.InfoPanel>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'value_of_compass_page.intro.para_1'}
            components={{
              styled_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.intro.accidental_manager_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.Paragraph>
          {translate('value_of_compass_page.intro.para_2')}
        </S.Paragraph>
        <S.HeadingTwo>
          {translate('value_of_compass_page.compass_performance_model.title')}
        </S.HeadingTwo>
        <S.Paragraph>
          {translate(
            'value_of_compass_page.compass_performance_model.intro_para',
          )}
        </S.Paragraph>
        <S.HeadingThree>
          {translate(
            'value_of_compass_page.compass_performance_model.empowerment.title',
          )}
        </S.HeadingThree>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={
              'value_of_compass_page.compass_performance_model.empowerment.para'
            }
            components={{
              autonomy_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.empowerment.autonomy_link',
                  )}
                  target="_blank"
                />
              ),
              invested_in_work_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.empowerment.invested_in_work_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.HeadingThree>
          {translate(
            'value_of_compass_page.compass_performance_model.connection.title',
          )}
        </S.HeadingThree>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={
              'value_of_compass_page.compass_performance_model.connection.para'
            }
            components={{
              team_cohesion_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.connection.team_cohesion_link',
                  )}
                  target="_blank"
                />
              ),
              psych_safety_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.connection.psych_safety_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.HeadingThree>
          {translate(
            'value_of_compass_page.compass_performance_model.clarity.title',
          )}
        </S.HeadingThree>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={
              'value_of_compass_page.compass_performance_model.clarity.para'
            }
            components={{
              focus_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.clarity.focus_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.HeadingThree>
          {translate(
            'value_of_compass_page.compass_performance_model.balance.title',
          )}
        </S.HeadingThree>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={
              'value_of_compass_page.compass_performance_model.balance.para'
            }
            components={{
              balance_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.compass_performance_model.balance.balance_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.HeadingTwo>
          {translate('value_of_compass_page.how_does_it_work.title')}
        </S.HeadingTwo>
        <S.Paragraph>
          {translate('value_of_compass_page.how_does_it_work.para_1')}
        </S.Paragraph>
        <Trans
          t={translate}
          i18nKey={'value_of_compass_page.how_does_it_work.four_steps'}
          components={{
            ordered_list: <S.OrderedList />,
            list_item: <li />,
            strong: <strong />,
          }}
        />
        <S.Paragraph>
          {translate('value_of_compass_page.how_does_it_work.para_2')}
        </S.Paragraph>
        <S.Paragraph>
          {translate('value_of_compass_page.how_does_it_work.para_3')}
        </S.Paragraph>
        <S.Paragraph>
          {translate('value_of_compass_page.how_does_it_work.para_4')}
        </S.Paragraph>
        <S.HeadingTwo>
          {translate('value_of_compass_page.measuring_success.title')}
        </S.HeadingTwo>
        <S.Paragraph>
          {translate('value_of_compass_page.measuring_success.para_1')}
        </S.Paragraph>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'value_of_compass_page.measuring_success.para_2'}
            components={{
              presenteeism_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.measuring_success.presenteeism_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'value_of_compass_page.measuring_success.para_3'}
            components={{
              attrition_link: (
                <S.Anchor
                  href={translate(
                    'value_of_compass_page.measuring_success.attrition_link',
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </S.Paragraph>
        <S.Paragraph>
          {translate('value_of_compass_page.measuring_success.para_4')}
        </S.Paragraph>
        <S.HeadingThree>
          {translate('value_of_compass_page.measuring_success.future.title')}
        </S.HeadingThree>
        <S.Paragraph>
          {translate('value_of_compass_page.measuring_success.future.para')}
        </S.Paragraph>
      </S.InfoPanel>
    </CompassFurtherInfoPage>
  );
};
