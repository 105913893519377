import React from 'react';
import { IconProps } from '../IconProps';
const SvgWarningDiamond = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      clipPath="url(#WarningDiamond_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M13.8.7c-1-1-2.6-1-3.6 0L.7 10.2c-1 1-1 2.6 0 3.6l9.5 9.5c1 1 2.6 1 3.6 0l9.5-9.5c1-1 1-2.6 0-3.6z"
        fill="#CCC7BF"
      />
      <path
        d="M13.3 6.6a1.3 1.3 0 10-2.6 0v5.6a1.3 1.3 0 102.6 0zm-1.3 12a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4"
        fill="#0D0300"
      />
    </g>
    <defs>
      <clipPath id="WarningDiamond_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWarningDiamond;
