import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { Errors } from '../Shared/Errors';

export type Response = {
  user: {
    __typename: string;
    id: string;
    userType: {
      id: number;
      __typename: string;
    };
  };
};

export interface WithUserTypeProps {
  userType?: number;
  userTypeLoading?: boolean;
}

export const USERTYPE_QUERY = gql`
  query withUserType {
    user {
      id
      userType {
        id
      }
    }
  }
`;

export const withUserType = graphql<
  Record<string, never>,
  Response,
  Record<string, never>,
  WithUserTypeProps
>(USERTYPE_QUERY, {
  props: ({ data }) => {
    if (!data?.user) {
      return {
        userTypeLoading: true,
      };
    }

    if (data && data.user && data.user.userType) {
      return {
        id: data.user.id,
        userType: data.user.userType.id,
        userTypeLoading: false,
      };
    }

    return {
      error: Errors.ServerError,
      userTypeLoading: false,
    };
  },
});
