import { styled } from 'styles';

export const RangeWrapper = styled.div`
  position: relative;
  padding-top: 16px;

  input[type='range'] {
    width: 100%;
  }
`;

export const Range = styled.input.attrs({
  type: 'range',
})`
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.background.input};
    border: ${({ theme }) => `2px solid ${theme.colors.border.primary}`};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
`;

export const Output = styled.output`
  background-color: ${({ theme }) => theme.colors.background.inverse};
  color: ${({ theme }) => theme.colors.text.inverse};
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  opacity: 0;
  will-change: opacity;

  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: inherit;
    bottom: -1px;
    left: 50%;
  }
`;
