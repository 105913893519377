import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { tracking } from 'App/Tracking';
import RoutePath from '../../../App/RoutePath';
import { HelpContainer, HelpIcon, HelpLabel } from './styles';

const HelpLink = () => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');

  return (
    <HelpContainer
      to={RoutePath.Help}
      onClick={() => {
        tracking.track('talk-need-immediate-help-clicked');
      }}
    >
      <HelpIcon />
      <HelpLabel>{t('browse_practitioners.help_link')}</HelpLabel>
    </HelpContainer>
  );
};

export default HelpLink;
