import React from 'react';
import { rem } from 'polished';
import { styled, useTheme } from 'styles';
import BodyText from '../Typography/BodyText';

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  margin: ${rem(0)} auto;
  padding: ${rem(8)};
  text-align: center;
  width: 100%;
`;

interface InfoBannerProps {
  text: string;
}

const InfoBanner = (props: InfoBannerProps) => {
  const { colors, typography } = useTheme();

  return (
    <Container>
      <BodyText
        color={colors.staticPalette.white}
        sizes={[typography.fontSizes.fontSize16]}
        weight={typography.fontWeights.medium}
      >
        {props.text}
      </BodyText>
    </Container>
  );
};

export default InfoBanner;
