import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { small } from 'utils';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';
import {
  BodyText,
  FeatureText,
  HeadingText,
} from '@unmind/design-system-components-web';

const textOverflow = css`
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: ${rem(60)};

  ${small(css`
    margin-top: 0;
  `)};
`;

export const PractitionerTitleText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.primary,
}))`
  ${textOverflow}
`;

export const PractitionerNameText = styled(FeatureText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize32],
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.regular,
}))`
  margin-top: ${rem(16)};
  ${textOverflow}
`;

export const ItemRow = styled.div`
  margin-top: ${rem(4)};
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: 16,
  height: 16,
  'aria-hidden': true,
}))``;

export const DimmedText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-left: ${rem(8)};
`;

export const SummaryText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  lineHeight: theme.typography.lineHeights.lineHeight20,
  color: theme.colors.text.primary,
}))`
  margin-top: ${rem(24)};
  overflow: hidden;
  white-space: pre-wrap;
`;

export const Subheader = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  lineHeight: theme.typography.lineHeights.lineHeight16,
  color: theme.colors.text.primary,
  level: 3,
}))`
  margin-top: ${rem(24)};
`;

export const AboutMeContainer = styled.div`
  margin: ${rem(16)} 0;
`;
