import { hideVisually, lighten, rem } from 'polished';
import React, { ChangeEvent } from 'react';
import { styled } from 'styles';
import getFocusState from '../../../../Shared/Button/getFocusState';
import { BodyText } from '../../../../Shared/Typography';

const CheckBox = styled.input`
  ${hideVisually()}
`;

const Option = styled.label`
  padding: ${rem(15)};
  border-radius: ${rem(34)};
  text-align: center;
  cursor: pointer;
  display: block;
  user-select: none;
  width: 100%;

  ${CheckBox} + & {
    border: solid ${rem(2)} ${({ theme }) => theme.colors.border.secondary};
    background-color: ${({ theme }) => theme.colors.background.input};
  }

  ${CheckBox}:checked + & {
    border: solid ${rem(2)} ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => lighten(0.5, theme.colors.primary)};
  }

  ${CheckBox}:focus + & {
    ${getFocusState()}
  }
`;

export default class FeelingOption extends React.Component<
  {
    id: string;
    label: string;
    onSelectChange?(selected: boolean, id: string): void;
    className?: string;
  },
  { selected: boolean }
> {
  state = {
    selected: false,
  };

  onOptionClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { onSelectChange } = this.props;

    this.setState({ selected: event.target.checked });
    if (onSelectChange !== undefined) {
      onSelectChange(event.target.checked, event.target.id);
    }
  };

  render() {
    return (
      <div className={this.props.className}>
        <CheckBox
          name={this.props.label}
          checked={this.state.selected}
          onChange={this.onOptionClick}
          id={this.props.id}
          type="checkbox"
        />
        <Option data-testid="feeling" htmlFor={this.props.id}>
          <BodyText align="center">{this.props.label}</BodyText>
        </Option>
      </div>
    );
  }
}
