import React from 'react';
import { IconProps } from '../IconProps';

const SvgInviteColleague = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 130 179" width="1em" height="1em" {...props}>
    <path
      d="M8.368 19.32L74.264.802 86.35 43.805 20.453 62.323 8.368 19.32z"
      fill="#FFA600"
    />
    <path
      d="M8.37 19.322L74.266.804l.855 3.043L9.225 22.365l-.855-3.043z"
      fill="#FFD099"
    />
    <path
      d="M129 141.107H1"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.767}
    />
    <path d="M101.78 96.316H33.332v44.669h68.448V96.316z" fill="#9992F9" />
    <path
      d="M32.248 127.22a3.188 3.188 0 0 0 1.682-.485 3.274 3.274 0 0 0 1.189-1.309 3.354 3.354 0 0 0-.25-3.426 46.995 46.995 0 0 0-8.738-9.349 3.214 3.214 0 0 0-1.127-.603 3.18 3.18 0 0 0-2.478.283c-.374.212-.703.499-.968.842s-.46.737-.574 1.158-.146.861-.093 1.295c.054.433.191.852.405 1.23.213.379.498.711.838.977a40.228 40.228 0 0 1 7.486 8.013c.3.425.694.772 1.151 1.011.457.239.964.363 1.477.363z"
      fill="#FFA600"
    />
    <path
      d="M21.562 113.31s14.239 9.566 17.044 26.087M28.171 114.7l-.87 3.478M29.04 120.27l-2.783 1.391M32.345 124.27l1.391-3.652M31.649 123.4l.696-4.521"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.34}
    />
    <path
      d="M17.794 134.88c.35 0 .699-.056 1.03-.163 4.176-1.358 7.815-1.466 10.813-.321a3.337 3.337 0 0 0 2.474.023c.393-.153.75-.38 1.05-.666.298-.286.534-.626.693-1a2.874 2.874 0 0 0-.044-2.345 3.02 3.02 0 0 0-.73-.977 3.211 3.211 0 0 0-1.072-.63c-4.456-1.701-9.584-1.631-15.252.209-.713.231-1.316.695-1.702 1.308s-.53 1.337-.406 2.041a2.98 2.98 0 0 0 1.082 1.806 3.286 3.286 0 0 0 2.056.715h.008z"
      fill="#FFA600"
    />
    <path
      d="M39.301 141.14s-3.478-16.347-24.522-8.347M28.692 130.88l-1.565-2.957M22.605 130.88l-.696 2.957M19.127 131.4l-1.74 3.479"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.34}
    />
    <path
      d="M40.392 119.22a3.16 3.16 0 0 0 1.967-.686 3.19 3.19 0 0 0 1.12-1.762c1.509-6.338 3.482-10.313 3.5-10.352a3.203 3.203 0 0 0 .156-2.419 3.188 3.188 0 0 0-1.581-1.831 3.163 3.163 0 0 0-2.407-.186 3.18 3.18 0 0 0-1.841 1.567c-.095.188-2.328 4.684-4.006 11.738a3.203 3.203 0 0 0 .598 2.713 3.176 3.176 0 0 0 2.489 1.214l.005.004z"
      fill="#FFA600"
    />
    <path
      d="M45.214 102.01s-9.466 18.16-5.74 38.783M40.519 115.05l-2.26-2.782M41.562 111.57l-2.26-3.13M42.953 107.4l3.304.696"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.34}
    />
    <path d="M101.78 96.316H33.332v3.825h68.448v-3.825z" fill="#D4D1FE" />
    <path
      d="M111.09 140.79a9.217 9.217 0 0 0 9.217-9.217 9.217 9.217 0 1 0-18.434 0 9.217 9.217 0 0 0 9.217 9.217zM70.87 67.05l.808 3.616 6.27-1.401 1.452 6.501 3.715-.83-1.453-6.501 6.303-1.409-.808-3.615-6.303 1.408-1.445-6.469-3.715.83 1.445 6.47-6.269 1.4z"
      fill="#FFA600"
    />
    <circle
      transform="rotate(-12.596 77.54 58.647)"
      cx={77.54}
      cy={58.647}
      r={8}
      stroke="#FFA600"
      strokeWidth={3.399}
    />
    <path
      d="M49.282 64.663l.965 3.817.391-.277-.064-5.392c-.007-.504.321-.95.802-1.096l.062-.018.548 5.972.306-.128.357-5.46c.04-.6.514-1.082 1.115-1.13h.008l.027 6.316.512.096.553-5.009a1.07 1.07 0 0 1 1.06-.955l-.294 7.762a.596.596 0 0 0 .957.498l1.094-.832a.897.897 0 0 1 1.24.15l-2.762 3.898.834 18.02 9.313 16.877c-3.1 1.183-5.13 2.36-7.184 4.946-5.717-6.943-9.446-20.004-9.446-20.004-.668-7.07-.017-18.003-.017-18.003-.798-2.298-.365-5.1-.365-5.1l-.616-3.564a1.369 1.369 0 0 1 .604-1.382v-.002z"
      fill="#AF564A"
    />
    <path
      d="M56.344 76.91l.647 13.985 9.312 16.876c-3.1 1.184-5.13 2.362-7.184 4.947-5.717-6.944-9.445-20.005-9.445-20.005-.483-5.102-.278-12.211-.126-15.777 2.265-.118 4.53-.151 6.796-.027z"
      fill="#FFA600"
    />
    <path
      d="M54.733 67.368s-2.599-.508-4.682 1.434M53.534 71.861s-1.416.81-.834 2.643M55.995 91.469s-.708 1.496-.607 2.774"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.357}
    />
    <path
      d="M100.63 141.01l-10.339-8.6-8.613-17.245c-2.733 1.881-4.854 2.884-8.149 3.109 2.474 8.647 11.113 19.129 11.113 19.129 2 1.894 6.283 3.607 6.283 3.607h9.705z"
      fill="#FFA600"
    />
    <path
      d="M89.251 132.9s-1.633-.266-2.629-1.074"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.357}
    />
    <path
      d="M64.454 142.52c-10.113-1.116-17.42-10.108-16.319-20.084s10.192-17.158 20.305-16.042 17.42 10.107 16.319 20.083-10.192 17.159-20.305 16.043z"
      fill="#FF8900"
    />
    <path
      d="M82.392 133.64s-8.82 15.924-25.822 6.445c-8.02-5.613-9.373-14.518-7.187-21.187 17.306.936 33.008 14.742 33.008 14.742z"
      fill="#1B1A29"
    />
    <path
      d="M70.088 110.7s7.035-1.849 10.458 2.164c0 0 3.722-1.327.56-5.082-3.16-3.754-10.475-4.492-13.077-2.982-2.603 1.511 2.059 5.9 2.059 5.9z"
      fill="#FFA600"
    />
    <path
      d="M76.286 108.07l-8.296-3.343s.843-5.016 2.25-7.26c1.407-2.245 6.439-.268 6.439-.268l1.693 2.787-2.089 8.086.003-.002z"
      fill="#1B1A29"
    />
    <path
      d="M74.706 106.74a5.732 5.732 0 1 1 1.885-11.31 5.733 5.733 0 0 1-1.885 11.31z"
      fill="#AF564A"
    />
    <path
      d="M74.829 103.28l-1.644-2.302c.135-.076.285-.131.443-.16.53.735 1.058 1.47 1.583 2.207-.107.107-.24.192-.382.257v-.002zM72.803 101.3l1.509 2.113a1.771 1.771 0 0 1-.635-.073c-.798-.246-1.272-1.002-1.06-1.685.04-.131.106-.248.186-.352v-.003zM69.857 100.43a.393.393 0 0 1 .016-.071c.057-.18.146-.339.255-.473l1.238 1.733a1.048 1.048 0 0 1-.505.217l-1.002-1.403-.002-.003zM71.697 101.22c-.393-.55-.785-1.097-1.176-1.647a.908.908 0 0 1 .675-.059c.535.165.81.814.614 1.446-.03.093-.069.18-.113.262v-.002z"
      fill="#fff"
      opacity={0.3}
    />
    <path
      d="M73.675 103.34c-.798-.247-1.272-1.002-1.06-1.686.212-.683 1.03-1.037 1.828-.79s1.272 1.002 1.06 1.686c-.212.684-1.03 1.038-1.828.79zM70.485 101.81c-.535-.165-.81-.813-.614-1.446.196-.632.79-1.01 1.324-.845s.81.813.614 1.446c-.196.633-.789 1.011-1.324.845zM72.614 101.65s-.188-.399-.92-.426M75.454 102.7l2.75.956M71.943 101.68l-.782 1.28.655.377M74.619 104.42s-.473.805-1.72.218"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.357}
    />
    <path
      d="M75.72 99.846l.296-1.776-.964 1.486-2.184-.95.172-1.032-.657.819-2.142-.933s2.629-4.838 8.722-1.944c7.336 3.482 3.243 15.522 3.243 15.522l-6.977-2.702 2.644-7.551-2.153-.94z"
      fill="#1B1A29"
    />
    <path
      d="M73.068 108.66c-1.28.072-2.132.434-2.132.434s-.974-.367-1.403-2.088l3.537 1.654h-.002z"
      fill="#AF564A"
    />
    <path
      d="M77.827 110.03c-3.43-2.407-6.89-.934-6.89-.934s-1.183-.44-1.516-2.644"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.357}
    />
    <path
      d="M51.293 151.93s1.332 5.375-1.25 9.313c-2.582 3.937-.956 5.07-.956 5.07l8.62-10.318-1.031-6.706-5.381 2.641h-.002z"
      fill="#1B1A29"
    />
    <path
      d="M48.99 153.04l-5.75-29.074a6.035 6.035 0 0 1 4.277-6.976c3.531-.997 7.127 1.363 7.617 5.001l3.733 27.744s-4.797 3.202-9.878 3.305h.001zM74.921 161.64s-1.332 5.374 1.25 9.312c2.582 3.938.956 5.07.956 5.07l-8.62-10.318 1.031-6.706 5.381 2.641.002.001z"
      fill="#1B1A29"
    />
    <path
      d="M77.223 162.75l5.75-29.074a6.035 6.035 0 0 0-4.276-6.976c-3.532-.997-7.128 1.363-7.618 5l-3.733 27.745s4.797 3.201 9.879 3.305h-.002zM28.768 60.026a21.403 21.403 0 0 1 19.758-5.545l-19.757 5.545zM33.798 34.964l16.55-1.65-9.41-8.191-7.14 9.841z"
      fill="#1B1A29"
    />
    <path
      d="M42.106 32.776a7.16 7.16 0 1 1-1.893-14.194 7.16 7.16 0 0 1 1.893 14.194z"
      fill="#FFB4B9"
    />
    <path
      d="M35.69 26.773s.252 1.554 2.136 2.019c1.884.465 2.467-1.317 4.334.168 0 0 1.873-1.223 3.287-.697.62.23 1.196.822 1.648 1.414a7.16 7.16 0 0 1-11.936-.078l.531-2.826z"
      fill="#fff"
    />
    <path
      d="M44.202 24.465c.674-2.831-2.317-3.814-3.382-3.904-.533-.05-1.069.07-1.53.343-.613.374-1.255 1.141-.91 2.653.605 2.655-3.654 2.423-2.046 5.927 1.608 3.503-.135 3.912 1.1 5.125L26.11 35.785s-.135-2.053 1.411-3.579c1.546-1.526-.576-2.723 1.728-5.346s-.342-3.613 2.734-5.917c3.077-2.305 2.098-4.31 5.503-4.474 3.405-.165 5.48-2.115 7.421.006 1.941 2.121 3.61.075 4.892 3.145 1.281 3.07 2.808 2.04 3.716 5.029s1.86 1.69 2.378 4.273c.518 2.583 1.237 1.706 1.695 3.668l-11.605 1.157s-.727-1.074-.022-2.72c.706-1.644-2.564-3.175-1.758-6.562z"
      fill="#fff"
    />
    <path
      d="M41.975 26.147l.723 1.81-1.01.034M40.529 29.538s.23.905 1.32.711M40.236 26.208a1.092 1.092 0 0 0-1.277.074M44.076 25.65a1.103 1.103 0 0 0-1.092.388M38.877 24.441l4.694-.367M40.171 23.198l2.958-.233"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
    <path
      d="M45.985 33.749s-.727-1.074-.022-2.72c.706-1.644-2.567-3.177-1.761-6.564.674-2.832-2.317-3.814-3.382-3.904-.533-.05-1.07.07-1.53.343-.614.374-1.256 1.14-.91 2.653.605 2.655-3.654 2.423-2.046 5.927 1.607 3.503-.135 3.912 1.1 5.124"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
    <path
      d="M24.322 35.816l33.529-3.343c.05-.006.096-.013.138-.01l.062-.001c.109-.007.217-.01.324-.01l18.487-.543 3.874-7.284 2.806 9.917c-.537 2.119-1.769 3.622-1.769 3.622a5.454 5.454 0 0 1-3.254 2.08c-3.26.687-9.97 1.924-16.482 2.024a19.844 19.844 0 0 1-37.72-6.45l.006-.002z"
      fill="#6C61F7"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
    <path
      d="M47.854 33.468l.194 2.707-4.475-2.28-3.182 3.189-3.057-2.566M43.572 33.896s3.34 9.31 2.464 19.701M47.33 49.799a.346.346 0 1 1-.091-.686.346.346 0 0 1 .091.686zM46.17 41.572a.346.346 0 1 1-.092-.686.346.346 0 0 1 .092.686z"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
    <path
      d="M58.897 75.737l.046-.038-4.344-3.522.257-.172 4.417 2.557a1.135 1.135 0 0 0 1.452-.277l-4.873-3.306.19-.452 4.17 2.17a1 1 0 0 0 1.29-.326l-6.169-3.798a.568.568 0 0 1-.263-.436.554.554 0 0 1 .374-.563l1.211-.418a.837.837 0 0 0 .524-1.038l-4.453.126L50.11 64.9c-2.633.737-3.07 2.62-5.727 3.259l3.553 2.178c1.367 1.811 3.764 2.928 3.764 2.928l2.443 2.327a1.274 1.274 0 0 0 1.383.247l-2.457-2.729.419-.159 4.14 2.847a1.055 1.055 0 0 0 1.269-.06z"
      fill="#FFB4B9"
    />
    <path
      d="M30.608 42.589l3.068 10.354 16.807 12.004-3.992 4.99-18.036-10.398a5.537 5.537 0 0 1-3.545-3.56l-4.504-14.054a4.702 4.702 0 0 1 3.915-6.106"
      fill="#6C61F7"
    />
    <path
      d="M30.608 42.589l3.068 10.354 16.807 12.004-3.992 4.99-18.036-10.398a5.537 5.537 0 0 1-3.545-3.56l-4.504-14.054a4.702 4.702 0 0 1 3.915-6.106"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
    <path
      d="M30.127 53.935l3.55-.992-3.068-10.354M76.667 32.068s-1 1.477-2.242 2.085M63.3 38.743a19.852 19.852 0 0 1-17.265 14.85"
      stroke="#1B1A29"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.307}
    />
  </svg>
);

export default SvgInviteColleague;
