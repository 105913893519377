import { TimerState } from './TimerState';

export enum TimerActions {
  START,
  STOP,
  RESET,
}

export interface StartAction {
  // tslint:disable-next-line: no-reserved-keywords
  type: typeof TimerActions.START;
  payload: {
    capped: boolean;
  };
}

export interface StopAction {
  // tslint:disable-next-line: no-reserved-keywords
  type: typeof TimerActions.STOP;
  payload?: {
    callback(state: TimerState): void;
  };
}

export interface ResetAction {
  // tslint:disable-next-line: no-reserved-keywords
  type: typeof TimerActions.RESET;
  payload?: {
    hasSecondarySegment: boolean;
  };
}
