import gql from 'graphql-tag';
import { LINE_MANAGERS_CATEGORY } from '../Managers/constants';

const PERSONALISED_TODAY_TOOL_FRAGMENT = gql`
  fragment TodayToolFields on Tool {
    id
    title
    slug
    isFavourite
    estimatedMins
    html
    summary
    cloudinaryThumbnail {
      path
    }
    mediaType {
      value
    }
    mediaSrc {
      value
    }
    mediaAsset {
      path
    }
    exploreCategory {
      id
      colourTheme
      slug
      topLevelCategory {
        id
        title
      }
    }
  }
`;

export const PERSONALISED_TODAY_CONTENT_QUERY = gql`
  ${PERSONALISED_TODAY_TOOL_FRAGMENT}
  query PersonalisedTodayContent($userLocalDateTime: DateTime!) {
    user {
      id
      firstName
      isLineManager
      dailySelection(userLocalDateTime: $userLocalDateTime) {
        beforeWork {
          ...TodayToolFields
        }
        duringWork {
          ...TodayToolFields
        }
        afterWork {
          ...TodayToolFields
        }
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
    exploreCategory(slug: "${LINE_MANAGERS_CATEGORY}") {
      id
      slug
      thumbnailMediaPath
      cloudinaryThumbnail {
        path
      }
    }
    organisationEntitlements {
      lineManagerFeatures
      id
    }
    homeAssembly {
      id
      featuredCategory {
        id
        slug
      }
      featuredContent {
        ... on Tool {
          ...TodayToolFields
        }
        ... on Series {
          id
          title
          subtitle
          slug
          cloudinaryThumbnail {
            path
          }
          totalDays
          daysCompleted
          exploreCategory {
            id
            colourTheme
            slug
            topLevelCategory {
              id
              title
            }
          }
        }
      }
    }
  }
`;
