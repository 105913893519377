enum ZIndex {
  overMobileNav = 7000,
  overModal = 6000,
  modal = 5000,
  overlay = 4000,
  dropdown = 3000,
  header = 2000,
  footer = 1000,
}

export default ZIndex;
