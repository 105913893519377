import React from 'react';
import { IconProps } from '../IconProps';

const SvgHappyFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M83.2 164.3c44.945 0 81.38-36.435 81.38-81.38 0-44.945-36.435-81.38-81.38-81.38-44.945 0-81.38 36.435-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#D4E26D"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.12 70.81a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.61 70.81a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M108.11 104.82c-7.6 6-17.2 9.59-27.64 9.59-10.44 0-19.75-3.48-27.3-9.32M105.23 102.1c1.63 1.56 3.37 3 5.22 4.3M56.46 102.1c-1.63 1.56-3.37 3-5.22 4.3"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHappyFaceWithBorder;
