import { useEffect } from 'react';
import { AdminTabType } from '../../../types';
import { DashboardFilterOptions } from '../../FilterPanel/types';
import { useFilterState } from '../../FilterState';
import { UserGroupFilterStateKey } from '../../FilterState/types';
import { trackFilterTypeChange } from './trackFilterTypeChange';

export const useFilterTypeTracking = ({
  dashboardFilterOptions,
  adminTabType,
  userGroupFilterType,
  userGroupFilterCount,
  isReady,
}: {
  dashboardFilterOptions: DashboardFilterOptions;
  adminTabType: AdminTabType;
  userGroupFilterType: UserGroupFilterStateKey | undefined;
  userGroupFilterCount: number;
  isReady: boolean;
}) => {
  const { filterSelectionMethod, timeFilterPreset } = useFilterState();

  useEffect(() => {
    if (!isReady || userGroupFilterType === undefined) {
      return;
    }

    trackFilterTypeChange(
      dashboardFilterOptions[userGroupFilterType],
      adminTabType,
      userGroupFilterType,
      filterSelectionMethod,
      userGroupFilterCount,
      timeFilterPreset,
    );
  }, [
    isReady,
    adminTabType,
    filterSelectionMethod,
    userGroupFilterType,
    timeFilterPreset,
  ]);
};
