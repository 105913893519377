import { StylelessButton } from 'Shared/Button';
import PrimaryButton from 'Shared/PrimaryButton';
import { BodyText } from 'Shared/Typography';
import { ChevronDown, ChevronUp, Tick } from 'icons';
import { rem } from 'polished';
import styled, { DefaultTheme, css } from 'styled-components';
import { extraSmall, small } from 'utils';

export const DropdownContainer = styled.div.attrs({
  tabIndex: -1,
})`
  flex: 1;
  max-width: ${rem(245)};
  flex-basis: 100%;
  margin-top: ${rem(16)};
  cursor: pointer;

  ${extraSmall(css`
    margin-top: ${rem(24)};
  `)};

  ${small(css`
    width: auto;
    flex-basis: auto;
    margin-top: 0;

    &:not(:last-child) {
      margin-right: ${rem(24)};
    }
  `)}
`;

export const OptionLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

export const OptionContents = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(12)};
`;

export const OptionCheckbox = styled.div<{ isSelected: boolean }>`
  flex-shrink: 0;
  width: ${rem(20)};
  height: ${rem(20)};
  border: 1px solid ${({ theme }) => theme.button.primary.default.borderColor};
  border-radius: 4px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.button.primary.default.backgroundColor : 'initial'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionCheck = styled(Tick).attrs(({ theme }) => ({
  primaryColor: theme.colors.staticPalette.white,
}))`
  width: ${rem(12)};
  height: ${rem(12)};
`;

export const Count = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.staticPalette.black};
  color: ${({ theme }) => theme.colors.staticPalette.white};
  border-radius: 50%;
  width: ${rem(16)};
  height: ${rem(16)};
  margin-left: ${rem(8)};
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlaceholderLabel = styled(BodyText).attrs<{ count: number }>(
  ({ theme, count }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
    weight:
      count > 0
        ? theme.typography.fontWeights.bold
        : theme.typography.fontWeights.regular,
  }),
)<{ count: number }>`
  white-space: nowrap;
`;

const indicatorAttrs = (theme: DefaultTheme) => ({
  primaryColor: theme.colors.text.primary,
  width: theme.typography.fontSizes.fontSize16,
  height: theme.typography.fontSizes.fontSize16,
});

const indicatorStyles = css`
  padding-right: 0;
  pointer-events: inherit;
`;

export const Down = styled(ChevronDown).attrs(({ theme }) =>
  indicatorAttrs(theme),
)`
  ${indicatorStyles}
`;
export const Up = styled(ChevronUp).attrs(({ theme }) => indicatorAttrs(theme))`
  ${indicatorStyles}
`;

export const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  padding: ${rem(12)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)};
`;

export const ClearButton = styled(StylelessButton).attrs({
  tabIndex: -1,
  'aria-hidden': true,
})`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.disabled : theme.colors.text.primary};

  padding: ${rem(8)};
  text-decoration: underline;
  text-underline-position: from-font;
  -ms-text-underline-position: from-font;
`;

export const DoneButton = styled(PrimaryButton).attrs({
  tabIndex: -1,
  'aria-hidden': true,
})`
  padding: ${rem(10)} ${rem(16)};
`;
