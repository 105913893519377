import {
  BodyText,
  Button,
  HeadingText,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { Field } from 'formik';
import { hideVisually } from 'polished';
import { styled } from 'styles';

export const Container = styled.div`
  padding: 0 24px 24px;
  overflow-y: scroll;
  max-height: 90vh;
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
}))`
  margin-bottom: 16px;
`;

export const VisuallyHiddenRadioInput = styled(Field).attrs({
  type: 'radio',
  tabIndex: 0,
})`
  ${hideVisually()};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CancellationReason = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.secondary,
  forwardedAs: 'label',
}))`
  padding: 16px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.border.secondary};
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.background.input};
  cursor: pointer;

  &:has(${VisuallyHiddenRadioInput}:checked) {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const TextBoxLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.primary,
  forwardedAs: 'label',
}))`
  margin-bottom: 12px;
`;

export const TextBox = styled(Field).attrs({
  type: 'textarea',
  rows: 4,
  maxLength: 1000,
})`
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.border.secondary};

  &:focus {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const SubmitButton = styled(Button).attrs({
  variant: 'primary',
  type: 'submit',
})`
  align-self: flex-end;
`;

export const FormStatusContainer = styled.div`
  margin-bottom: 16px;
`;
