import React from 'react';
import { CheckboxProps, CheckboxLabel, Checkbox } from 'Shared/Form/Checkbox';
import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export interface LabelledCheckboxProps extends CheckboxProps {
  setFieldValue(field: string, value: unknown): void;
  smallTextSize?: boolean;
  children?: React.ReactChild;
}

const LabelledCheckbox = ({
  smallTextSize = false,
  children,
  ...props
}: LabelledCheckboxProps) => (
  <CheckBoxWrapper>
    <Checkbox {...props} />
    <CheckboxLabel id={props.id} smallTextSize={smallTextSize}>
      {children}
    </CheckboxLabel>
  </CheckBoxWrapper>
);

export default LabelledCheckbox;
