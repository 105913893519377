import { rem } from 'polished';
import { styled } from 'styles';
import { InsufficientData } from 'icons';

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(36)};
  justify-content: center;
  align-items: center;
  padding: 0 ${rem(16)};
  text-align: center;
`;

export const WarningIcon = styled(InsufficientData).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
  secondaryColor: theme.colors.staticPalette.purple.purple1,
  height: 40,
  width: 40,
}))`
  margin-bottom: ${rem(12)};
`;
