import { Colors } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { styled } from 'styles';

export interface BottomBarProps {
  backgroundColor?: Colors;
  testID?: string;
  progressAmount?: number;
  progressColor?: string;
}

// TODO: Consolidate with ProgressBar?
export const BottomBar = styled.div<BottomBarProps>`
  display: flex;
  height: ${({ progressColor }) =>
    Boolean(progressColor) ? rem(38) : rem(40)};
  margin-top: ${({ progressColor }) =>
    Boolean(progressColor) ? rem(18) : rem(16)};
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  &::before {
    display: ${({ progressAmount }) =>
      progressAmount !== undefined ? 'block' : 'none'};
    width: 100%;
    height: ${rem(6)};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor !== undefined
        ? backgroundColor
        : theme.colors.background.secondary};
  }

  &::after {
    display: ${({ progressColor }) =>
      Boolean(progressColor) ? 'block' : 'none'};
    width: ${({ progressAmount }) =>
      progressAmount !== undefined ? `${progressAmount}%` : `100%`};
    height: ${({ progressAmount }) =>
      progressAmount !== undefined ? rem(6) : '100%'};
    background: ${({ progressColor }) => progressColor};
  }
`;
