import { Field, FieldProps } from 'formik';
import React from 'react';
import ValidatedPasswordInput, {
  ValidatedPasswordInputProps,
} from '../ValidatedPasswordInput';

const FormikValidatedPasswordInput = ({
  name,
  ...props
}: Omit<ValidatedPasswordInputProps, 'value'> & {
  name: string;
  validate?(value: any): string | boolean | Promise<void> | undefined;
}) => (
  <Field name={name} validate={props.validate}>
    {({ field, form }: FieldProps) => (
      <ValidatedPasswordInput
        {...field}
        {...props}
        onChange={e => {
          form.setFieldTouched(name);
          form.handleChange(e);
        }}
      />
    )}
  </Field>
);

export default FormikValidatedPasswordInput;
