import React from 'react';
import { IconProps } from '../IconProps';

const SvgCourse = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g
      clipPath="url(#Course_svg__clip0_661_318)"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.434 7.907a1.09 1.09 0 0 1-.868 0L2.304 5.469a.517.517 0 0 1 0-.923L7.566 2.09a1.09 1.09 0 0 1 .868 0l5.262 2.437a.517.517 0 0 1 0 .924L8.434 7.907z" />
      <path d="M8.434 7.907a1.09 1.09 0 0 1-.868 0L2.304 5.469a.517.517 0 0 1 0-.923L7.566 2.09a1.09 1.09 0 0 1 .868 0l5.262 2.437a.517.517 0 0 1 0 .924L8.434 7.907zM14.001 8.322l-5.632 2.594a.923.923 0 0 1-.766 0L1.999 8.322" />
      <path d="M14.001 8.322l-5.632 2.594a.923.923 0 0 1-.766 0L1.999 8.322M14.001 11.322l-5.632 2.595a.924.924 0 0 1-.766 0l-5.604-2.595" />
      <path d="M14.001 11.322l-5.632 2.595a.924.924 0 0 1-.766 0l-5.604-2.595" />
    </g>
  </svg>
);

export default SvgCourse;
