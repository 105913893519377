import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContentTile,
  BodyText,
  FeatureText,
} from '@unmind/design-system-components-web';
import { css, styled } from 'styles';
import { AddToCalendar } from 'Shared/AddToCalendar';
import RoutePath from '../App/RoutePath';
import { ContentType, Series } from '../Explore/types';
import DynamicIcon from '../Shared/DynamicIcon/DynamicIcon';
import { medium } from '../utils';
import { tracking } from '../App/Tracking';

export const CardDecorationStyle = css`
  border-radius: 32px;
`;

export const CourseCardStyle = css`
  text-decoration: none;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;

export const Card = styled(Link)`
  ${CardDecorationStyle}
  ${CourseCardStyle}

  transition: transform 100ms ease-in-out, transform 100ms ease-in-out;
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }

  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
  }
`;

export const CardImageContainerStyle = css`
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
`;

export const CardImageContainer = styled.div`
  ${CardImageContainerStyle}
`;

export const Image = styled.img`
  resize-mode: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const CardContentContainer = styled.div<{ horizontalVariant?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-bottom-left-radius: ${({ horizontalVariant }) =>
    !horizontalVariant ? '32px' : '19px'};
  border-bottom-right-radius: ${({ horizontalVariant }) =>
    !horizontalVariant ? '32px' : '8px'};
  border-top-left-radius: ${({ horizontalVariant }) =>
    horizontalVariant ? '19px' : '0px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  flex-grow: 1;
  width: ${({ horizontalVariant }) => (horizontalVariant ? '70%' : '100%')};
  padding: 16px;

  ${medium(css`
    padding: 24px;
  `)};
`;

export const CardDetailsTop = styled.div``;

export const CardTitleStyle = css`
  margin: ${rem(12)} ${rem(12)} 0 ${rem(0)};

  ${medium(css`
    margin: ${rem(16)} ${rem(16)} 0 ${rem(16)};
  `)};
`;

export const CardTitle = styled(FeatureText).attrs(({ theme }) => ({
  forwardedAs: 'h3',
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))``;

export const CardDescription = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  margin: ${rem(6)} 0 ${rem(12)} 0;
  line-height: 1.5;
`;

export const CardMetadata = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(12)};
`;

export const CourseIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: 16,
  height: 16,
}))`
  margin-right: ${rem(4)};
`;

export const CourseText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  ${medium(css`
    line-height: 1.2;
  `)};
`;

export const DurationText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  margin-left: ${rem(5)};

  ${medium(css`
    line-height: 1.2;
  `)};
`;

export const DotSeparator = styled(DurationText)`
  margin-left: ${rem(5)};
`;

const ActionWrapper = styled.div`
  display: flex;
  position: relative;
`;

interface CourseCardProps {
  series: Series;
  showDescription?: boolean;
  source: string;
  assetToken?: string | null;
}

const CourseCard = ({
  series,
  showDescription = false,
  source,
  assetToken,
}: CourseCardProps) => {
  const { t: translate } = useTranslation('courses');

  const {
    id,
    title,
    slug,
    subtitle,
    cloudinaryThumbnail,
    daysCompleted,
    totalDays,
    exploreCategory,
  } = series;

  const trackCourseClick = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: totalDays,
      contentType: ContentType.course,
      source,
      contentCategoryPath: exploreCategory?.internalName,
      isFavourite: null,
    });
  };

  const calendarAction =
    title && subtitle
      ? [
          <ActionWrapper>
            <AddToCalendar
              title={title}
              description={subtitle}
              displayBorder={false}
              useSmallButton={true}
              buttonTextKey={'add_to_calendar.text'}
              contentPath={`${RoutePath.Courses}/${slug}`}
            ></AddToCalendar>
          </ActionWrapper>,
        ]
      : [];

  return (
    <ContentTile
      to={`${RoutePath.Courses}/${series.slug}`}
      onClick={trackCourseClick}
      hoverLabel={translate('course_card.hover_label')}
      item={{
        itemType: 'course',
        title: title as string,
        description: subtitle as string | undefined,
        thumbnailUrl:
          cloudinaryThumbnail?.path && assetToken
            ? `${cloudinaryThumbnail?.path}?${assetToken}`
            : undefined,
        tags: [
          translate('course_card.course_pill.text'),
          translate('course_card.course_duration_text', {
            total_days: totalDays,
          }),
        ],
      }}
      showItemDescription={showDescription}
      courseProgress={{
        daysCompleted: daysCompleted ?? undefined,
        totalDays: totalDays ?? undefined,
        completedText: translate('course_card.course_complete_pill.text'),
        getInProgressText: percentComplete =>
          translate('course_card.progress_indicator.text', {
            courseProgress: percentComplete,
          }),
      }}
      actions={[...calendarAction]}
    />
  );
};

export default CourseCard;
