import gql from 'graphql-tag';

/**
 * Fragments
 */
export const PRACTITIONER_DETAILS_PROFILE_QUESTION_RESPONSES_FRAGMENT = gql`
  fragment practitionerDetailsProfileQuestionResponsesFragment on UserProfileQuestion {
    id
    answer
    profileQuestion {
      id
      question
      translationKey
    }
  }
`;

export const PRACTITIONER_DETAILS_FRAGMENT = gql`
  ${PRACTITIONER_DETAILS_PROFILE_QUESTION_RESPONSES_FRAGMENT}
  fragment practitionerDetailsFragment on UserProfileDetailConnect {
    id
    type
    profileInformation
    specialities {
      approaches {
        id
        name
        translationKey
      }
      areasOfExpertise {
        id
        name
        translationKey
      }
      clientGroups {
        id
        name
        translationKey
      }
    }
    profileQuestionResponses {
      ...practitionerDetailsProfileQuestionResponsesFragment
    }
  }
`;

export const PRACTITIONER_AVAILABILITY_FRAGMENT = gql`
  fragment availabilityFragment on Practitioner {
    availability(input: { start: $start, end: $end }) {
      availabilityConfig @include(if: $includeTimes) {
        duration
      }
      hasAvailability
      times @include(if: $includeTimes) {
        start
        end
      }
    }
  }
`;

export const PRACTITIONER_FRAGMENT = gql`
  ${PRACTITIONER_DETAILS_FRAGMENT}
  ${PRACTITIONER_AVAILABILITY_FRAGMENT}
  fragment practitionerFragment on Practitioner {
    id
    firstName
    lastName
    photoUrls
    languages
    timeZone
    city
    state
    account {
      id
    }
    country {
      id
      name
    }
    details {
      ...practitionerDetailsFragment
    }
    ...availabilityFragment
  }
`;

export const PRACTITIONER_FILTER_VALUE_FRAGMENT = gql`
  fragment practitionerFilterValueFragment on PractitionerFilterValue {
    id
    name
  }
`;

// TODO does this need IDs
export const PRACTITIONERS_PAGINATION_FRAGMENT = gql`
  fragment practitionersPaginationFragment on Query {
    practitioners(
      first: $first
      after: $after
      filter: $filter
      ordering: $ordering
    ) @connection(key: "PractitionersPaginationFragment_practitioners") {
      count
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...practitionerFragment
        }
      }
    }
  }
`;

/**
 * Queries
 */
export const PRACTITIONER_COUNTRIES_QUERY = gql`
  query practitionerCountriesQuery {
    practitionerCountries {
      id
      name
      isoCodeTwo
    }
  }
`;

export const PRACTITIONER_QUERY = gql`
  ${PRACTITIONER_FRAGMENT}

  query practitionerQuery(
    $id: ID!
    $start: Time!
    $end: Time!
    $country: String
    $state: String
    $includeTimes: Boolean = true
  ) {
    practitioner(id: $id, country: $country, state: $state) {
      ...practitionerFragment
    }
  }
`;

export const PRACTITIONERS_QUERY = gql`
  ${PRACTITIONER_FRAGMENT}
  ${PRACTITIONERS_PAGINATION_FRAGMENT}

  query practitionersQuery(
    $first: Int! = 20
    $after: String
    $filter: PractitionerFilter
    $ordering: [UserProfileOrdering!]
    $start: Time!
    $end: Time!
    $includeTimes: Boolean = false
  ) {
    ...practitionersPaginationFragment
  }
`;

export const PRACTITIONER_FILTERS_QUERY = gql`
  ${PRACTITIONER_FILTER_VALUE_FRAGMENT}

  query practitionerFiltersQuery(
    $types: [PractitionerFilterType!]!
    $country: String
    $state: String
  ) {
    practitionerFilters(types: $types, country: $country, state: $state) {
      languages {
        ...practitionerFilterValueFragment
      }
      approaches {
        ...practitionerFilterValueFragment
      }
      areasOfExpertise {
        ...practitionerFilterValueFragment
      }
    }
  }
`;

export const PRACTITIONER_AVAILABILITY_QUERY = gql`
  ${PRACTITIONER_AVAILABILITY_FRAGMENT}
  query practitionerAvailabilityQuery(
    $id: ID!
    $country: String
    $state: String
    $start: Time!
    $end: Time!
    $includeTimes: Boolean = true
  ) {
    practitioner(id: $id, country: $country, state: $state) {
      id
      ...availabilityFragment
    }
  }
`;

export const PRACTITIONER_MATCHES_QUERY = gql`
  query practitionerMatchesQuery {
    practitionerMatches {
      id
    }
  }
`;

/**
 * Mutations
 */
export const PRACTITIONER_REQUEST_MUTATION = gql`
  mutation practitionerRequestMutation($input: PractitionerRequestInput!) {
    practitionerRequest(input: $input)
  }
`;

export const MATCH_TO_PRACTITIONERS_MUTATION = gql`
  mutation matchToPractitionersMutation($input: MatchToPractitionersInput!) {
    matchToPractitioners(input: $input) {
      id
    }
  }
`;
