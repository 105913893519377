import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { css, styled, useTheme } from 'styles';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { getSentAtTimestamp } from '../../../App/Tracking/serverside';
import { AlertBox } from '../../../Shared/Form';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import { HeadingText } from '../../../Shared/Typography';
import { medium, small } from '../../../utils';
import { MOODS_TAB_HASH } from '../../Track';
import MoodTrackerForm, { MoodTrackerFormValues } from './MoodTrackerForm';
import { MoodType } from './types';
import { withCheckInMoods, WithCheckInMoodsProps } from './withCheckInMoods';
import { CreateCheckIn } from './__generated__/CreateCheckIn';

const Container = styled.div`
  padding-bottom: ${rem(1)};
  text-align: center;
  margin: 0 ${rem(20)} ${rem(100)};

  ${small(css`
    margin: 0 ${rem(44)} ${rem(100)} ${rem(44)};
  `)}

  ${medium(css`
    margin: 0 auto ${rem(100)} auto;
    max-width: ${rem(672)};
  `)}
`;

const PageTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  align: 'center',
}))`
  margin: ${rem(36)} 0 ${rem(20)};
`;

const ErrorAlertBox = styled(AlertBox)`
  margin-top: ${rem(36)};
`;

export const CHECK_IN_FEELINGS_QUERY = gql`
  query GetCheckInFeelings {
    checkinFeelings {
      id
      name
    }
  }
`;

export const CREATE_CHECK_IN_MUTATION = gql`
  mutation CreateCheckIn($input: CreateCheckInInput!) {
    createCheckIn(input: $input) {
      checkIn {
        id
        feelings {
          name
        }
        mood
        note
      }
    }
  }
`;

export interface MoodTrackerProps
  extends WithCheckInMoodsProps,
    Pick<RouteComponentProps, 'history'> {}

export const MoodTracker = (props: MoodTrackerProps) => {
  const { t: translate } = useTranslation<Namespace<'check_in' | 'shared'>>([
    'check_in',
    'shared',
  ]);
  const [createCheckInError, setCreateCheckInError] = useState(false);
  const theme = useTheme();

  const {
    loading: feelingsLoading,
    data: feelingsData,
    error: feelingsError,
  } = useQuery(CHECK_IN_FEELINGS_QUERY);

  const [createCheckIn, { loading }] = useMutation(CREATE_CHECK_IN_MUTATION, {
    onCompleted: (data: CreateCheckIn) => {
      if (data.createCheckIn) {
        const { checkIn } = data.createCheckIn;

        if (checkIn) {
          const checkInNote = checkIn.note;

          tracking.track('checkin-completed', {
            checkInId: checkIn.id,
            mood: checkIn.mood as MoodType,
            feelings: (checkIn.feelings || []).map(feeling => feeling?.name),
            commentLength: checkInNote ? checkInNote.length : 0,
          });

          props.history.push(`${RoutePath.Track}${MOODS_TAB_HASH}`);
        }
      }
    },
    onError: () => {
      setCreateCheckInError(true);
    },
  });

  const submitCheckIn = async (checkInFormValues: MoodTrackerFormValues) => {
    setCreateCheckInError(false);
    await createCheckIn({
      variables: {
        input: {
          ...checkInFormValues,
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        },
      },
    });
  };

  return (
    <>
      <Container>
        <PageTitle>{translate('check_in:title')}</PageTitle>
        <HeadingText
          level={2}
          sizes={[theme.typography.fontSizes.fontSize16]}
          id="mood"
          align="center"
        >
          {translate('check_in:feeling_prompt')}
        </HeadingText>
        {feelingsLoading || props.moodsLoading ? (
          <LoadingIndicator />
        ) : feelingsError || props.hasMoodsError ? (
          <ErrorAlertBox
            alertType="failed"
            message={translate('shared:errors.messages.unexpected')}
          />
        ) : (
          <MoodTrackerForm
            checkInMoods={props.checkInMoods}
            checkinFeelings={feelingsData?.checkinFeelings}
            onSubmit={submitCheckIn}
            loading={loading}
            error={createCheckInError}
          />
        )}
      </Container>
    </>
  );
};

export default compose<MoodTrackerProps, Pick<RouteComponentProps, 'history'>>(
  withCheckInMoods,
)(MoodTracker);
