import { useMutation } from '@apollo/client';
import { Back } from 'icons';
import { rem } from 'polished';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { logException } from '../../../App/logging';
import { tracking } from '../../../App/Tracking';
import { ServersideTrackingEvent } from '../../../App/Tracking/serverside';
import { LOG_EVENT_MUTATION } from '../../../App/Tracking/serverside/withLogEvent';
import { ButtonSize } from '../../../Shared/Button';
import Container from '../../../Shared/Container';
import PrimaryButton from '../../../Shared/PrimaryButton';
import BodyText from '../../../Shared/Typography/BodyText';
import { medium, small } from '../../../utils';
import QuestionnaireHeader from './QuestionnaireHeader';
import { getIndexQuestionnaireQuestions_getIndexQuestionnaireQuestions as WellbeingQuestionnaire } from './__generated__/getIndexQuestionnaireQuestions';

const QuestionnaireIntroductionContainer = styled(Container)``;

const InnerContainer = styled.div`
  ${medium(css`
    max-width: 66%;
  `)}
`;

const QuestionnaireIntroductionParagraph = styled(BodyText).attrs(
  ({ theme }) => ({
    color: theme.colors.text.primary,
  }),
)`
  margin-bottom: ${rem(24)};
`;

const StartQuestionnaireButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: ${rem(36)};

  ${small(css`
    width: auto;
  `)};
`;

export interface QuestionnaireIntroductionProps
  extends Pick<WellbeingQuestionnaire, 'version'> {
  hideQuestionnaire(indexComplete?: boolean): void;
  startQuestions(): void;
  sessionId: string;
}
export const QuestionnaireIntroduction = (
  props: QuestionnaireIntroductionProps,
) => {
  useEffect(() => {
    tracking.track('index-started', {
      sessionId: props.sessionId,
      indexVersion: props.version,
    });
  }, [props.sessionId, props.version]);

  const { t: translate } =
    useTranslation<Namespace<'unmind_index'>>('unmind_index');

  const [logEvent] = useMutation(LOG_EVENT_MUTATION);

  const logServerside = async (eventName: ServersideTrackingEvent) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  return (
    <QuestionnaireIntroductionContainer>
      <QuestionnaireHeader
        buttonAriaLabel={translate(
          'index_introduction.index_header.aria_label',
        )}
        buttonIcon={Back}
        onButtonClick={() => props.hideQuestionnaire()}
        heading={translate('index_introduction.index_header.heading')}
        subHeading={translate('index_introduction.index_header.subheading')}
      />
      <InnerContainer>
        {Trans({
          t: translate,
          i18nKey: 'index_introduction.paragraph_text',
          components: {
            intro_paragraph: <QuestionnaireIntroductionParagraph />,
          },
        })}
        <StartQuestionnaireButton
          size={ButtonSize.medium}
          label={translate(
            'index_introduction.start_questionnaire_button.label',
          )}
          onClick={() => {
            void logServerside('WELLBEING_TRACKER_STARTED');
            props.startQuestions();
          }}
          data-testid="start-index-button"
        />
      </InnerContainer>
    </QuestionnaireIntroductionContainer>
  );
};
