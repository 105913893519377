import React from 'react';
import { IconProps } from '../IconProps';

const SvgDownload = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 12 12" width="1em" height="1em" {...props}>
    <g
      clipPath="url(#Download_svg__a)"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeWidth={0.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.429 6L6 9l2.572-3M6 9V3" />
      <path d="M6 11.572A5.571 5.571 0 1 0 6 .429a5.571 5.571 0 0 0 0 11.143z" />
    </g>
    <defs>
      <clipPath id="Download_svg__a">
        <path d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDownload;
