import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { small } from 'utils';
import RoutePath from '../../../../App/RoutePath';
import { tracking } from '../../../../App/Tracking';
import { PrimaryButton } from '../../../../Shared/PrimaryButton/PrimaryButton';
import { ButtonSize } from '../../../../Shared/Button';
import { SecondaryButton } from '../../../../Shared/SecondaryButton';
import {
  withSeriesTrackingIds,
  withSeriesTrackingIdsChildProps,
} from '../withSeriesTrackingIds';
import {
  withSendSeriesEndOfDayReminder,
  WithSendSeriesEndOfDayReminderChildProps,
} from '../withSendSeriesEndOfDayReminder';
import { BodyText } from '../../../../Shared/Typography';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-top: ${rem(32)};
`;

const Message = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))`
  margin-top: ${rem(10)};
  margin-bottom: ${rem(36)};
`;

const ButtonsContainer = styled.div`
  margin-bottom: ${rem(36)};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  ${small(css`
    margin-bottom: ${rem(12)};
  `)}
`;

const PrimaryProgressButton = styled(PrimaryButton)`
  ${small(css`
    min-width: ${rem(190)};
  `)};
`;

const SecondaryProgressButton = styled(SecondaryButton)`
  ${small(css`
    min-width: ${rem(190)};
  `)}
`;

const RemindMeButton = styled(PrimaryButton)`
  margin-left: ${rem(20)};
  ${small(css`
    min-width: ${rem(190)};
  `)}
`;

interface OuterCongratulationsDayProps {
  slug: string;
  dayNumber: number;
  hasReminder: boolean;
}

export interface CongratulationsDayProps
  extends RouteComponentProps,
    OuterCongratulationsDayProps,
    WithSendSeriesEndOfDayReminderChildProps,
    withSeriesTrackingIdsChildProps {}

export const CongratulationsDay = (props: CongratulationsDayProps) => {
  const { t: translate } = useTranslation('courses');

  const { dayNumber, slug, seriesId, hasReminder } = props;
  const dayId = props.id;

  const navigateToProgress = (): void => {
    tracking.track('series-end-of-day-view-progress-clicked', {
      seriesId,
      dayId,
    });

    props.history.push(`${RoutePath.Series}/${slug}/#progress`, {
      initialDay: dayNumber,
    });
  };

  const sendReminder = async () => {
    const { sendSeriesEndOfDayReminder } = props;
    const response = await sendSeriesEndOfDayReminder({
      seriesLink: `${RoutePath.Series}/${slug}/#progress`,
      templateVars: [],
    });

    tracking.track('series-end-of-day-reminder-clicked', { seriesId, dayId });

    props.history.push({
      pathname: `${RoutePath.Series}/${slug}/reminder`,
      state: { seriesId, error: !response.success },
    });
  };

  return (
    <Container>
      <Header>
        {translate('course_day.complete.title', { session_number: dayNumber })}
      </Header>
      <Message>{translate('course_day.complete.subtitle')}</Message>
      <ButtonsContainer>
        {hasReminder ? (
          <>
            <SecondaryProgressButton
              data-testid="congratulations-day-cta"
              onClick={navigateToProgress}
              label={translate(
                'course_day.complete.view_progress_button.label',
              )}
              size={ButtonSize.medium}
            />
            <RemindMeButton
              data-testid="remind-me-day-cta"
              onClick={sendReminder}
              label={translate(
                'course_day.complete.send_reminder_button.label',
              )}
              size={ButtonSize.medium}
            />
          </>
        ) : (
          <PrimaryProgressButton
            data-testid="congratulations-day-cta"
            onClick={navigateToProgress}
            label={translate('course_day.complete.view_progress_button.label')}
            size={ButtonSize.medium}
          />
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default compose<CongratulationsDayProps, OuterCongratulationsDayProps>(
  withRouter,
  withSendSeriesEndOfDayReminder,
  withSeriesTrackingIds,
)(CongratulationsDay);
