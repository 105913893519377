import React from 'react';
import { IconProps } from '../IconProps';

const SvgSpeechBubbleError = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 70 60" fill="none" {...props}>
    <path
      d="M58.616 7.392C51.433 1.553 40.859.008 32.07 1.573c-1.043.18-2.046.441-3.01.742C14.194 7.011 6.89 25.59 12.83 40.518c.12.301.1.642-.06.923-.903 1.505-4.274 6.661-11.437 12.139-.823.622-.401 1.946.642 1.946 3.43 0 6.862-.24 10.172-1.043 2.228-.542 5.86-2.448 7.344-3.25.401-.221.903-.141 1.244.18 7.986 7.544 18.138 8.929 27.468 6.48 11.056-2.889 19.503-12.92 20.747-24.739 1.003-9.59-2.97-19.783-10.313-25.742l-.02-.02z"
      fill={primaryColor}
      stroke={secondaryColor}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.777 22.861c.822-1.625 2.167-3.17 3.812-3.912 3.531-1.585 8.969 2.227 6.662 6.36-.542.963-1.325 1.746-2.067 2.548-1.986 2.227-3.531 5.036-3.21 8.066M47.72 17.745c-.02 5.237.101 12.018.302 17.255"
      stroke={secondaryColor}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={32.983}
      cy={42.143}
      r={0.654}
      fill={secondaryColor}
      stroke={secondaryColor}
      strokeWidth={1.25}
    />
    <circle
      cx={48.022}
      cy={42.143}
      r={0.654}
      fill={secondaryColor}
      stroke={secondaryColor}
      strokeWidth={1.25}
    />
  </svg>
);

export default SvgSpeechBubbleError;
