import React from 'react';
import { IconProps } from '../IconProps';

const SvgRemove = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={2.222}
    >
      <path d="M47.13 28c0 10.563-8.567 19.13-19.13 19.13-10.567 0-19.13-8.567-19.13-19.13C8.87 17.433 17.433 8.87 28 8.87c10.563 0 19.13 8.563 19.13 19.13zM35.378 20.622L20.62 35.38M35.378 35.38L20.62 20.622" />
    </g>
  </svg>
);

export default SvgRemove;
