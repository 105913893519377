import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsBoost = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 2a1 1 0 0 1 1 1v.176c0 5.123 0 9.467-2.54 13.313-2.567 3.883-7.593 7.088-17.127 10.454a1 1 0 0 1-.666-1.886c9.465-3.342 13.94-6.367 16.124-9.67C31.97 12.087 32 8.341 32 3a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.106 21.08a1 1 0 0 1 1.313.526c.875 2.041.704 4.053.099 6.003-.514 1.655-1.372 3.355-2.227 5.05l-.397.788a1 1 0 0 1-1.875-.25c-.487-2.438-.955-3.558-1.492-4.417-.277-.443-.584-.835-.975-1.337l-.012-.015c-.393-.503-.85-1.09-1.372-1.873a1 1 0 1 1 1.664-1.11 27.058 27.058 0 0 0 1.324 1.803c.37.473.734.939 1.067 1.472.414.663.767 1.406 1.09 2.414.529-1.089.983-2.114 1.295-3.118.52-1.675.598-3.163-.027-4.622a1 1 0 0 1 .525-1.313zM32.86 2H33a1 1 0 1 1 0 2c-4.847 0-8.613.026-12.04 2.217-3.425 2.192-6.685 6.677-10.017 16.116a1 1 0 0 1-1.886-.666c3.376-9.561 6.825-14.576 10.826-17.134C23.843 2 28.177 2 32.86 2z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293 22.707l1.414-1.414 4 4-1.414 1.414-4-4zM2.553 14.106c3.999-2 7.339-3.196 10.763-2.055a1 1 0 1 1-.633 1.898c-1.99-.664-4.032-.334-6.705.774.952.313 1.664.655 2.302 1.054.533.333.998.697 1.472 1.067l.05.04c.498.388 1.036.806 1.752 1.284a1 1 0 1 1-1.109 1.664c-.783-.522-1.37-.98-1.873-1.372l-.016-.012c-.5-.392-.893-.698-1.336-.975-.859-.537-1.98-1.005-4.416-1.492a1 1 0 0 1-.251-1.875zM25 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0zM8.426 24.22a1 1 0 0 1 .323 1.096c-.32.96-.096 1.523.158 1.777s.818.478 1.777.158a1 1 0 0 1 1.174 1.463C9.238 33.08 5.667 34 3 34a1 1 0 0 1-1-1c0-2.669.92-6.238 5.285-8.858a1 1 0 0 1 1.14.078zM4.06 31.94c1.552-.183 3.321-.831 4.904-2.627a3.021 3.021 0 0 1-1.471-.806 3.021 3.021 0 0 1-.806-1.47c-1.796 1.582-2.444 3.35-2.627 4.903z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsBoost;
