import { CaptureContext } from '@sentry/types';
import { logException } from '../logging';

export const IDENTIFICATION_ERROR_MESSAGE = 'Failed to Identify Braze User';
export const SET_SDK_AUTH_ERROR_MESSAGE =
  'Failed to set a Braze SDK authentication Signature';
export const MISSING_SDK_API_KEY = 'Missing braze sdk api key';
export const SDK_AUTH_ERROR_MESSAGE = (code: number | string) =>
  `Failed to authenticate Braze SDK request. Error Code: ${code}`;

export type BrazeSdkAuthErrorEvent = {
  errorCode: string | number;
  reason?: string;
  userId?: string;
  signature?: string;
};

export class BrazeSdkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BrazeSdkError';
  }
}

export class BrazeIdentityError extends BrazeSdkError {
  originalError: Error | BrazeSdkAuthErrorEvent;
  constructor(message: string, originalError: Error | BrazeSdkAuthErrorEvent) {
    super(message);
    this.name = 'BrazeIdentityError';
    this.originalError = originalError;
  }
}

export const captureBrazeException = (error: BrazeSdkError | Error) => {
  let captureContext: CaptureContext = {
    tags: { feature: 'braze' },
  };
  if (error instanceof BrazeIdentityError) {
    captureContext = {
      ...captureContext,
      extra: { 'original error': error.originalError },
    };
  }
  logException(error, captureContext);
};
