import React from 'react';
import { rem } from 'polished';
import { medium } from '@unmind/design-system-components-web';
import { css, styled } from 'styles';
import SkeletonPulse from './SkeletonPulse';

const SKELETON_TEXT_HEIGHT = rem(11);

const TileContentContainer = styled.article`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TileImageContainerSkeleton = styled(SkeletonPulse)`
  align-self: center;
  height: 64px;
  width: 64px;
  margin-right: 8px;
  border-radius: 16px;

  ${medium(css`
    height: 100px;
    width: 100px;
  `)}
`;

const TileTitleSkeleton = styled(SkeletonPulse)`
  border-radius: 5px;
  width: 80%;
  margin-bottom: 16px;
  height: ${SKELETON_TEXT_HEIGHT};
`;

const TileMetadataSkeleton = styled(SkeletonPulse)`
  border-radius: 5px;
  width: 30%;
  margin-bottom: 8px;
  height: ${SKELETON_TEXT_HEIGHT};
`;

const TileContentsSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0px 8px;
`;

const SkeletonContentTile = () => (
  <TileContentContainer>
    <TileImageContainerSkeleton />
    <TileContentsSkeleton>
      <TileTitleSkeleton />
      <TileMetadataSkeleton />
    </TileContentsSkeleton>
  </TileContentContainer>
);

export default SkeletonContentTile;
