import { useQuery } from '@apollo/client';
import { ACCOUNT_UNMIND_ACCOUNT_QUERY } from 'Services/BeGateway/Account/account.services';
import { accountUnmindAccountQuery } from 'Services/BeGateway/Account/__generated__/accountUnmindAccountQuery';
import { BEGatewayQueryContext } from 'utils/apollo';
import { USER_QUERY } from '../../Services/User/user.services';
import { userQuery } from '../../Services/User/__generated__/userQuery';

export type TalkUserDetails = ReturnType<typeof useTalkUserDetails>['user'];

export const useTalkUserDetails = () => {
  const { loading: userLoading, data } = useQuery<userQuery>(USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading: accountLoading, data: accountData } =
    useQuery<accountUnmindAccountQuery>(ACCOUNT_UNMIND_ACCOUNT_QUERY, {
      fetchPolicy: 'cache-and-network',
      ...BEGatewayQueryContext,
    });

  const user = {
    id: data?.user?.id,
    firstName: data?.user?.firstName,
    lastName: data?.user?.lastName,
    email: data?.user?.email,
    userType: data?.user?.userType?.id || undefined,
    hasUnmindTalkUserLevelAccess: data?.user?.hasUnmindTalkUserLevelAccess,
    isDemo: !!accountData?.unmindAccount.isDemo,
    organisationSessionLimit:
      accountData?.unmindAccount.unmindAccountSettings?.sessionLimitAmount || 0,
  };

  return { loading: userLoading || accountLoading, user };
};
