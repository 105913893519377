import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import RoutePath from '../../../App/RoutePath';
import { getSentAtTimestamp } from '../../../App/Tracking/serverside';
import {
  LinkSlackUserToUnmind,
  LinkSlackUserToUnmindVariables,
} from '../../../Services/slack/__generated__/LinkSlackUserToUnmind';
import { LINK_SLACK_USER_MUTATION } from '../../../Services/slack/slack.services';

export const useLinkSlackUserEffect = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integrationType = searchParams.get('integrationType');
  const slackUserId = searchParams.get('id');

  const [linkSlackUserToUnmind, { loading: isConnecting }] = useMutation<
    LinkSlackUserToUnmind,
    LinkSlackUserToUnmindVariables
  >(LINK_SLACK_USER_MUTATION, { refetchQueries: ['SlackIntegration'] });

  useEffect(() => {
    if (integrationType === 'slack' && slackUserId) {
      void linkSlackUserToUnmind({
        variables: {
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
          slackUserId,
        },
      }).then(({ data }) => {
        if (data?.linkSlackUserToUnmind?.success) {
          history.replace(`${RoutePath.Account}#integrations`);
        }
      });
    }
  }, [history, integrationType, linkSlackUserToUnmind, slackUserId]);

  return { isConnecting };
};
