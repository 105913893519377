import { tracking } from '../../../../App/Tracking';
import { AdminTabType } from '../../../types';
import { UserGroupFilterStateKey } from '../../FilterState/types';
import { DropdownOptions } from '../../FilterPanel/types';
import { TimePeriod } from '../../../../__generated__/globalTypes';
import { FilterSelectionMethod, trackingFilterTypes } from './types';

export const trackFilterTypeChange = (
  options: DropdownOptions,
  adminTabType: AdminTabType,
  filterType: UserGroupFilterStateKey,
  filterSelectionMethod: FilterSelectionMethod,
  numOfFiltersAvailable: number,
  timeFilterPreset: TimePeriod,
) => {
  const restrictedOptionCount = options.filter(
    option => option.isDisabled,
  ).length;

  const eventProperties = {
    dashboardType: adminTabType,
    filterType: trackingFilterTypes[filterType],
    filterSelectionMethod,
    numOfFiltersAvailable,
    numOfOptionsAvailable: options.length,
    numOfOptionsRestricted: restrictedOptionCount,
    timePeriodSelected: timeFilterPreset,
  };

  tracking.track('admin-dashboard-filter-initialised', eventProperties);
};
