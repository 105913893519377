import { rem } from 'polished';
import { styled } from 'styles';
import { createFontStyle } from '../../../../../Shared/Typography/Base';

const HTMLContent = styled.div`
  ${({ theme }) =>
    createFontStyle({ size: theme.typography.fontSizes.fontSize16 })};
  color: ${({ theme }) => theme.colors.text.secondary};

  p {
    margin: 0;

    & + p {
      margin-top: ${rem(16)};
    }
  }

  h1 {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize18,
        weight: theme.typography.fontWeights.medium,
      })};
  }

  h2 {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize16,
        weight: theme.typography.fontWeights.medium,
      })};
  }

  h3,
  h4,
  h5,
  h6 {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize14,
        weight: theme.typography.fontWeights.medium,
      })}
  }

  ul {
    padding-left: ${rem(24)};
  }

  ol {
    padding-left: ${rem(36)};
  }

  img {
    max-width: 100%;
    box-shadow: 0 0 ${rem(10)} 0 rgba(0, 0, 0, 0.15);
    border-radius: ${rem(12)};
    border: solid ${rem(6)} ${props => props.theme.colors.staticPalette.white};
    margin-top: ${rem(8)};
    margin-left: ${rem(2)};
  }

  blockquote {
    color: ${props => props.theme.colors.text.primary};
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize18,
      })}
    font-style: italic;
    border-left: ${rem(4)} solid ${props => props.theme.colors.border.secondary};
    margin: ${rem(16)} 0;
    padding: ${rem(8)} ${rem(16)};
  }

  hr {
    border: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
    margin: ${rem(24)} 0;
  }

  table,
  th,
  td {
    margin: ${rem(16)} 0;
    border-collapse: collapse;
    text-align: left;
    padding-right: ${rem(12)};
  }
`;

export default HTMLContent;
