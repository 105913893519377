import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavTodayInactive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.753 2.034a1.292 1.292 0 0 1 2.584-.022l.015 1.714a1.292 1.292 0 1 1-2.584.023l-.015-1.715zm-7.256 2.23c-.5.51-.494 1.327.015 1.828l1.223 1.202A1.292 1.292 0 0 0 6.547 5.45L5.324 4.248a1.292 1.292 0 0 0-1.827.016zm16.99 1.828a1.292 1.292 0 1 0-1.81-1.844l-1.224 1.203a1.292 1.292 0 0 0 1.812 1.843l1.223-1.202zm2.21 7.931a1.292 1.292 0 0 0 .022-2.584l-1.715-.015a1.292 1.292 0 1 0-.022 2.584l1.715.015zM4.299 12.742a1.292 1.292 0 0 1-1.303 1.281l-1.715-.015a1.292 1.292 0 1 1 .022-2.584l1.715.015c.714.006 1.287.59 1.281 1.303zm2.248 7.27a1.292 1.292 0 1 0-1.812-1.843L3.512 19.37a1.292 1.292 0 1 0 1.812 1.843l1.223-1.202zm10.89-1.828c-.5.51-.493 1.328.016 1.828l1.223 1.202a1.292 1.292 0 1 0 1.812-1.843l-1.223-1.202a1.292 1.292 0 0 0-1.827.015zm-6.67 3.55a1.292 1.292 0 0 1 2.585-.022l.015 1.715a1.292 1.292 0 1 1-2.585.022l-.014-1.714zM12.06 8.506a4.225 4.225 0 1 0 0 8.45 4.225 4.225 0 0 0 0-8.45zm0-1.714a5.939 5.939 0 1 0 0 11.878 5.939 5.939 0 0 0 0-11.878z"
      fill="#747380"
    />
  </svg>
);

export default SvgNavTodayInactive;
