import { format } from 'date-fns';
import { round } from 'lodash';
import i18n from '../../../../i18n/config';
import { IndexScore as WellbeingScoreByType } from '../../types/types';

export const getChartDataPointWithLabel = (
  entry: WellbeingScoreByType,
  index: number,
  data: WellbeingScoreByType[],
): {
  date: Date;
  value: number;
  label: string;
  shouldShowDifference: boolean;
  index: number;
} => {
  const { date, value } = entry;
  const dateLabel = format(date, 'dd MMM yyyy');
  const roundedValue = round(value, 1);

  let label = i18n.t('track:wellbeing.scores.chart.data_point.label', {
    date: dateLabel,
    score: roundedValue,
  });
  let shouldShowDifference = false;

  if (data.length > 1 && index < data.length - 1) {
    const previousEntry = data[index + 1];
    const difference = round(roundedValue - round(previousEntry.value, 1), 1);
    shouldShowDifference = difference !== 0;

    if (shouldShowDifference) {
      label += `\n${difference > 0 ? `\u2197` : `\u2198`} ${i18n.t(
        'track:wellbeing.scores.chart.data_point.score_change',
        {
          difference,
        },
      )}`;
    }
  }

  return {
    date,
    value: roundedValue,
    label,
    shouldShowDifference,
    index,
  };
};
