import React, { useEffect, useRef } from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import { BodyText } from '../Shared/Typography';
import { tracking } from '../App/Tracking';

const Body = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'div',
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: left;

  p {
    margin: 0 0 ${rem(12)};
  }
  a {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
  padding-bottom: ${rem('12px')};
`;

const trackLinkClick = (e: Event) => {
  const target = e.target as HTMLLinkElement;

  tracking.track('help-resource-link-clicked', {
    resourceLinkUrl: target.href,
  });
};

const HelpBody = ({ body }: { body: string }) => {
  const bodyContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyContent?.current) {
      const anchorLinks = bodyContent.current.querySelectorAll('[href]');

      anchorLinks.forEach(link => {
        link.addEventListener('click', trackLinkClick);
      });

      return () => {
        anchorLinks.forEach(link => {
          link.removeEventListener('click', trackLinkClick);
        });
      };
    }
  }, []);

  return (
    <Body
      ref={bodyContent}
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  );
};

export default HelpBody;
