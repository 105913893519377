import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { UserAuthMethod } from '../../__generated__/globalTypes';

const USER_AUTH_METHOD_QUERY = gql`
  query GetUserAuthMethod {
    user {
      id
      authenticationMethod
    }
  }
`;

type UserAuthMethodResponse = {
  userAuthMethod: UserAuthMethod | null;
  userAuthMethodLoading: boolean;
  userAuthMethodError: Error | undefined;
};

const useGetUserAuthMethod = (): UserAuthMethodResponse => {
  const { data, loading, error } = useQuery(USER_AUTH_METHOD_QUERY);
  const userAuthMethod = data?.user?.authenticationMethod;

  return {
    userAuthMethod,
    userAuthMethodLoading: loading,
    userAuthMethodError: error,
  };
};

export default useGetUserAuthMethod;
