import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaveLight = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 370 820" width="1em" height="1em" {...props}>
    <path
      d="M47.47 234.092C47.47 113.936 109.513 81 169.584 69 267.913 49.358 370 0 370 0v820H0V536.941c0-140.885 47.47-182.693 47.47-302.849z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgWaveLight;
