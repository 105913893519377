import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesRelationships = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.468 3.53c4.1 1.782 5.672 6.238 6.798 10.411 1.126 4.132 1.678 8.548-.7 12.174-2.614 3.99-7.011 7.272-11.94 7.11-4.695-.162-7.52-4.436-10.537-7.88-2.974-3.383-6.99-6.765-6.225-11.14.786-4.437 5.524-6.827 9.751-8.832 4.08-1.924 8.71-3.646 12.853-1.843z"
      fill="#FFCAEA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.25 12.25a3.5 3.5 0 1 1 5.717 2.708 2.538 2.538 0 0 0-.211-.161c-.437-.298-1.086-.547-2.006-.547-.92 0-1.57.25-2.006.547a2.536 2.536 0 0 0-.212.161 3.493 3.493 0 0 1-1.282-2.708zm3.5-5a5 5 0 0 0-4.792 6.432l-2.688 1.344a5 5 0 1 0 0 5.947l2.688 1.345a5 5 0 1 0 .641-1.356l-2.627-1.314c.18-.516.278-1.07.278-1.648 0-.577-.098-1.132-.278-1.648L19.6 15.04a5 5 0 1 0 4.15-7.789zm0 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0 11.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0-1.5a3.5 3.5 0 0 0-2.218 6.208c.063-.053.134-.107.212-.161.437-.298 1.086-.547 2.006-.547.92 0 1.569.25 2.006.547.078.053.148.108.211.161a3.5 3.5 0 0 0-2.218-6.208zM12.25 16a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-3.5 2c0 1.091.5 2.066 1.282 2.708.063-.053.134-.107.212-.161.437-.298 1.086-.547 2.006-.547.92 0 1.569.25 2.006.547.078.053.149.108.211.161A3.5 3.5 0 1 0 8.75 18z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesRelationships;
