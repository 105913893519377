import React from 'react';
import { IconProps } from '../IconProps';

const SvgTick = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <path
      stroke={primaryColor}
      strokeWidth={4.3}
      d="M11 29.085L21.485 40.2 45.478 15"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTick;
