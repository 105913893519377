import React from 'react';
import { IconProps } from '../IconProps';

const SvgChatBubbleSquare = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M7.021 19.755L.654 21.347l1.593-4.776V2.245A1.593 1.593 0 0 1 3.839.652h15.92a1.592 1.592 0 0 1 1.592 1.593v15.919a1.593 1.593 0 0 1-1.593 1.592H7.023l-.002-.001z"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.543 11.382c.795 2.864 3.979 4.616 6.845 3.821 1.75-.636 3.184-2.07 3.662-3.821m-8.836-3.98a.398.398 0 0 1 0-.796m0 .796a.398.398 0 1 0 0-.796m7.164.796a.397.397 0 1 1 0-.796m0 .796a.398.398 0 1 0 0-.796"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgChatBubbleSquare;
