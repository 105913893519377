import { gql } from '@apollo/client';

export const CONVERSATION_SUGGESTIONS_QUERY = gql`
  query Suggestions {
    companionSuggestions {
      label
      topic
    }
  }
`;
