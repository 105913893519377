import { rem } from 'polished';
import { styled, ThemeInterface } from 'styles';
import BodyText from '../Typography/BodyText';

const Pill = styled(BodyText).attrs(({ theme }: { theme: ThemeInterface }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.medium,
}))`
  border-color: ${props => props.theme.colors.border.secondary};
  border-radius: ${rem(34)};
  border-style: solid;
  border-width: ${rem(2)};
  display: inline-block;
  padding: ${rem(2)} ${rem(6)};
`;

export default Pill;
