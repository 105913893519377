import { styled } from 'styles';
import getFocusState from './getFocusState';

const StylelessButton = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
  &:focus {
    ${getFocusState()}
  }
`;

export default StylelessButton;
