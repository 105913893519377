import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { pluralize } from 'utils/pluralize';
import {
  DistributionType,
  ResponseDistribution,
} from './Sections/Components/SelectedCards/RelatedQuestionsSection';

const COLUMN_HEIGHT = 80;
const MIN_HEIGHT = 5;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${rem(120)};
  padding: ${rem(16)} 0;
  width: 100%;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: ${rem(88)};
  margin-top: ${rem(24)};
  width: ${rem(324.5)};
`;

export const Chart = styled.div<{
  distributionType: DistributionType;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: ${({ distributionType }) =>
    distributionType === DistributionType.RATING ? rem(60) : rem(29.5)};
`;

const Column = styled.div<{
  height: number;
  distributionType: DistributionType;
}>`
  background-color: ${({ theme }) => theme.colors.primary};
  width: ${({ distributionType }) =>
    distributionType === DistributionType.RATING ? rem(55) : rem(20)};

  height: ${({ height }) => rem(height)};
  margin:: ${({ distributionType }) =>
    distributionType === DistributionType.RATING
      ? `0 ${rem(4)}`
      : `0 ${rem(1)}`};
  position: relative;
  transition: height 0.3s ease;

  ::after {
    content: '';
    position: absolute;
    left: -${rem(5)};
    right: -${rem(5)};
    bottom: -${rem(1)};
    height: ${rem(1)};
    background-color: ${({ theme }) => theme.colors.border.secondary};
  }
`;

const Label = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.text.secondary,
}))`
  text-align: center;
  margin-top: 5px;
  height: ${rem(16)};
`;

const AccessibileChartDescription = styled.aside`
  position: absolute;
  width: 1;
  height: 1;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
`;

interface ChartProps {
  height: number;
  label: string;
  hideLabel: boolean;
  distributionType: DistributionType;
}

const StyledChart = React.memo(
  ({ height, label, hideLabel, distributionType }: ChartProps) => (
    <Chart distributionType={distributionType}>
      <Column
        height={height}
        role="presentation"
        distributionType={distributionType}
      />
      <Label>{!hideLabel ? label : ''}</Label>
    </Chart>
  ),
);

interface DistributionProps {
  responseDistributions: ResponseDistribution[];
  distributionType: DistributionType;
}

export const Distribution = ({
  responseDistributions,
  distributionType,
}: DistributionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const responseOrResponses = (count: number) =>
    pluralize(count, 'singular', 'plural');

  const totalResponses = responseDistributions.length;

  const getColumnHeight = (columnResponses: number) => {
    if (totalResponses === 0 || columnResponses === 0) {
      return MIN_HEIGHT;
    }
    const maxCount = Math.max(
      ...responseDistributions.map(({ responseCount }) => responseCount),
    );
    const scaledHeight =
      (columnResponses / maxCount) * (COLUMN_HEIGHT - MIN_HEIGHT) + MIN_HEIGHT;

    return scaledHeight;
  };

  const hideLabels = distributionType === DistributionType.RATING ? [2, 4] : [];

  const accessibilityDescription = responseDistributions
    .map(
      ({ label, responseCount }) =>
        `${label}: ${responseCount} ${responseOrResponses(responseCount)}`,
    )
    .join(', ');

  return (
    <Container>
      <ChartContainer ref={containerRef}>
        {responseDistributions.map(({ label, responseCount }, index) => (
          <StyledChart
            height={getColumnHeight(responseCount)}
            distributionType={distributionType}
            label={label}
            hideLabel={hideLabels.includes(index + 1)}
          />
        ))}
      </ChartContainer>
      <AccessibileChartDescription>
        {'question'} {accessibilityDescription}
      </AccessibileChartDescription>
    </Container>
  );
};
