import { BodyText, frost } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import {
  BlankCalendar,
  Certificate,
  ChatBubbleSquare,
  IconType,
  PadlockShield,
} from 'icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled } from 'styles';
import DynamicIcon from '../../../Shared/DynamicIcon/DynamicIcon';

const Card = styled.div`
  padding: 24px 16px;
  border-radius: 12px;
  ${frost()};
`;

const BenefitsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const BenefitLineItem = styled.li`
  display: flex;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const BenefitLineItemCopyContainer = styled.div<{ hasBody: boolean }>`
  align-self: ${({ hasBody }) => (hasBody ? 'baseline' : 'center')};
  margin-left: 8px;
`;

const BenefitHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.inverse,
}))`
  line-height: 1;
`;

const BenefitBody = styled(BenefitHeader).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.regular,
}))`
  margin-top: 10px;
`;

const BenefitIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  height: 20,
  width: 20,
  primaryColor: theme.colors.text.inverse,
}))`
  flex-shrink: 0;
`;

interface BenefitLineItemParams {
  header: string;
  body?: string;
  icon: IconType;
}

function createBenefitLineItem({ header, body, icon }: BenefitLineItemParams) {
  return (
    <BenefitLineItem>
      <BenefitIcon Icon={icon} aria-hidden="true" />
      <BenefitLineItemCopyContainer hasBody={Boolean(body)}>
        <BenefitHeader> {header}</BenefitHeader>
        {body ? <BenefitBody>{body}</BenefitBody> : null}
      </BenefitLineItemCopyContainer>
    </BenefitLineItem>
  );
}

export function BenefitsCard() {
  const { t: translate } = useTranslation<Namespace<'talk'>>('talk');

  return (
    <Card>
      <BenefitsList>
        {createBenefitLineItem({
          header: translate(
            'onboarding_notification.benefits.confidential.title',
          ),
          icon: PadlockShield,
        })}
        {createBenefitLineItem({
          header: translate('onboarding_notification.benefits.booking.title'),
          icon: BlankCalendar,
        })}
        {createBenefitLineItem({
          header: translate('onboarding_notification.benefits.reason.title'),
          body: translate('onboarding_notification.benefits.reason.body'),
          icon: ChatBubbleSquare,
        })}
        {createBenefitLineItem({
          header: translate(
            'onboarding_notification.benefits.practitioners.title',
          ),
          body: translate(
            'onboarding_notification.benefits.practitioners.body',
          ),
          icon: Certificate,
        })}
      </BenefitsList>
    </Card>
  );
}
