/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssessmentStatus {
  complete = "complete",
  incomplete = "incomplete",
  not_needed = "not_needed",
}

export enum CancellationReasonType {
  cancelled_early = "cancelled_early",
  cancelled_late = "cancelled_late",
  found_another_practitioner = "found_another_practitioner",
  no_longer_need_support = "no_longer_need_support",
  no_show = "no_show",
  other = "other",
  practitioner_cancelled = "practitioner_cancelled",
  session_not_needed = "session_not_needed",
  unavailable_at_time = "unavailable_at_time",
}

export enum CancellationType {
  community = "community",
  guest = "guest",
  practitioner = "practitioner",
}

export enum EventSort {
  ACCOUNT_ID = "ACCOUNT_ID",
  BEGINS_AT = "BEGINS_AT",
  CANCELLATION_DATETIME = "CANCELLATION_DATETIME",
  CANCELLATION_REASON = "CANCELLATION_REASON",
  CANCELLATION_REASON_TYPE = "CANCELLATION_REASON_TYPE",
  CANCELLATION_TYPE = "CANCELLATION_TYPE",
  COMMENT = "COMMENT",
  COMPLETED_DATETIME = "COMPLETED_DATETIME",
  CREATED_AT = "CREATED_AT",
  CREATED_BY = "CREATED_BY",
  DELETED_AT = "DELETED_AT",
  DESCRIPTION = "DESCRIPTION",
  ENDS_AT = "ENDS_AT",
  EXTERNAL_REF = "EXTERNAL_REF",
  FLAG = "FLAG",
  GOOGLE_MEET_DETAILS = "GOOGLE_MEET_DETAILS",
  ICALENDAR = "ICALENDAR",
  ID = "ID",
  INCOMPLETE_REASON = "INCOMPLETE_REASON",
  INCOMPLETE_TYPE = "INCOMPLETE_TYPE",
  LOCATION = "LOCATION",
  MEET_COMPLETION_DETAIL = "MEET_COMPLETION_DETAIL",
  NAME = "NAME",
  PRACTITIONER_CONFIRMATION_DATETIME = "PRACTITIONER_CONFIRMATION_DATETIME",
  PRACTITIONER_CONFIRMATION_SOURCE = "PRACTITIONER_CONFIRMATION_SOURCE",
  RANDOM = "RANDOM",
  RECURRING = "RECURRING",
  RECURRING_BEGIN = "RECURRING_BEGIN",
  RECURRING_END = "RECURRING_END",
  SESSION_TYPE = "SESSION_TYPE",
  SOURCE = "SOURCE",
  STATUS = "STATUS",
  TAGS = "TAGS",
  TIME_ZONE = "TIME_ZONE",
  TWILIO_COMPLETION_DETAIL = "TWILIO_COMPLETION_DETAIL",
  TYPE = "TYPE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_BY = "UPDATED_BY",
  USER_ID = "USER_ID",
  ZOOM_COMPLETION_DETAIL = "ZOOM_COMPLETION_DETAIL",
  ZOOM_MEETING_DETAILS = "ZOOM_MEETING_DETAILS",
}

export enum PractitionerFilterType {
  COACH = "COACH",
  DIETITIAN = "DIETITIAN",
  THERAPIST = "THERAPIST",
}

export enum PractitionerTypeEnum {
  COACH = "COACH",
  DIETITIAN = "DIETITIAN",
  OPERATIONS = "OPERATIONS",
  THERAPIST = "THERAPIST",
}

export enum SessionLimitTypeEnum {
  ACCOUNT = "ACCOUNT",
  USER = "USER",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserProfileSort {
  AGE_GROUP = "AGE_GROUP",
  CITY = "CITY",
  COUNTRY_ID = "COUNTRY_ID",
  CREATED_AT = "CREATED_AT",
  DEFAULT_LANGUAGE = "DEFAULT_LANGUAGE",
  DELETED_AT = "DELETED_AT",
  DOB = "DOB",
  EMAIL = "EMAIL",
  ETHNICITY = "ETHNICITY",
  EXTERNAL_REF = "EXTERNAL_REF",
  FIRST_NAME = "FIRST_NAME",
  GENDER = "GENDER",
  GOOGLE_CREDENTIALS = "GOOGLE_CREDENTIALS",
  HUBSPOT_ID = "HUBSPOT_ID",
  ID = "ID",
  IS_AU_ORIGIN = "IS_AU_ORIGIN",
  I_P = "I_P",
  LANGUAGES = "LANGUAGES",
  LAST_NAME = "LAST_NAME",
  PHONE = "PHONE",
  PHOTO_URLS = "PHOTO_URLS",
  RANDOM = "RANDOM",
  SETUP_COMPLETE = "SETUP_COMPLETE",
  STATE = "STATE",
  TIME_ZONE = "TIME_ZONE",
  TIME_ZONE_OFFSET = "TIME_ZONE_OFFSET",
  UPDATED_AT = "UPDATED_AT",
  WORKOS_DIR_USER_REF = "WORKOS_DIR_USER_REF",
}

export interface AccountBillingInfoWhere {
  id?: IDFilter | null;
  cardBrand?: StringFilter | null;
  cardLastFour?: StringFilter | null;
  cardCountryId?: IDFilter | null;
  billingAddressLineOne?: StringFilter | null;
  billingAddressLineTwo?: StringFilter | null;
  billingCity?: StringFilter | null;
  billingState?: StringFilter | null;
  billingCountryId?: IDFilter | null;
  billingZip?: StringFilter | null;
  extraBillingInformation?: StringFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  stripePaymentMethodRef?: StringFilter | null;
  default?: TimeFilter | null;
  cardExpMonth?: IntFilter | null;
  cardExpYear?: IntFilter | null;
  account?: AccountWhere | null;
  or?: AccountBillingInfoWhere | null;
  and?: AccountBillingInfoWhere | null;
}

export interface AccountContactWhere {
  id?: IDFilter | null;
  accountId?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  or?: AccountContactWhere | null;
  and?: AccountContactWhere | null;
}

export interface AccountUserProfileInvitationWhere {
  id?: IDFilter | null;
  email?: StringFilter | null;
  externalRef?: StringFilter | null;
  token?: StringFilter | null;
  expires?: TimeFilter | null;
  accepted?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  account?: AccountWhere | null;
  createdByUserProfile?: UserProfileWhere | null;
  relatedUser?: UserProfileWhere | null;
  or?: AccountUserProfileInvitationWhere | null;
  and?: AccountUserProfileInvitationWhere | null;
}

export interface AccountWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  name?: StringFilter | null;
  addressLineOne?: StringFilter | null;
  addressLineTwo?: StringFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  countryId?: IDFilter | null;
  zip?: StringFilter | null;
  website?: StringFilter | null;
  urls?: any | null;
  description?: StringFilter | null;
  phone?: StringFilter | null;
  businessLogoUrls?: any | null;
  coverPhotoUrls?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  platformAccess?: any | null;
  paypalDetails?: any | null;
  stripeDetails?: any | null;
  stripeRef?: StringFilter | null;
  inviteLinkToken?: StringFilter | null;
  inviteLinkActive?: TimeFilter | null;
  hubspotId?: IDFilter | null;
  authAllowEmail?: BooleanFilter | null;
  authPreferredType?: StringFilter | null;
  authName?: StringFilter | null;
  authDomain?: StringFilter | null;
  unmindOrganisationId?: IDFilter | null;
  isDemo?: TimeFilter | null;
  accountBillingInfos?: AccountBillingInfoWhere | null;
  accountUserProfileInvitations?: AccountUserProfileInvitationWhere | null;
  userProfiles?: UserProfileWhere | null;
  or?: AccountWhere | null;
  and?: AccountWhere | null;
}

export interface AssessmentQuestionResponseType {
  questionId: string;
  answer: number;
}

export interface AssessmentResponseType {
  version: string;
  responses?: AssessmentQuestionResponseType[] | null;
}

export interface BooleanFilter {
  isNull?: boolean | null;
  notNull?: boolean | null;
  equalTo?: boolean | null;
  notEqualTo?: boolean | null;
}

export interface CancelEventInput {
  eventID: string;
  cancellationType: CancellationType;
  cancellationReasonType: CancellationReasonType;
  cancellationReason?: string | null;
}

export interface ChannelMemberProductWhere {
  id?: IDFilter | null;
  userProfileId?: IDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  internalPrice?: FloatFilter | null;
  internalCurrency?: StringFilter | null;
  externalPrice?: FloatFilter | null;
  externalCurrency?: StringFilter | null;
  meta?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  channel?: ChannelWhere | null;
  or?: ChannelMemberProductWhere | null;
  and?: ChannelMemberProductWhere | null;
}

export interface ChannelMemberWhere {
  id?: IDFilter | null;
  useAvailability?: BooleanFilter | null;
  allowCustomProduct?: BooleanFilter | null;
  productMeta?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  status?: StringFilter | null;
  isVisible?: TimeFilter | null;
  channel?: ChannelWhere | null;
  teamMember?: TeamMemberWhere | null;
  or?: ChannelMemberWhere | null;
  and?: ChannelMemberWhere | null;
}

export interface ChannelWhere {
  id?: IDFilter | null;
  name?: StringFilter | null;
  logo?: StringFilter | null;
  accountId?: IDFilter | null;
  public?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  channelMembers?: ChannelMemberWhere | null;
  channelMemberProducts?: ChannelMemberProductWhere | null;
  or?: ChannelWhere | null;
  and?: ChannelWhere | null;
}

export interface ContactActivityHistoryWhere {
  id?: IDFilter | null;
  type?: StringFilter | null;
  description?: StringFilter | null;
  meta?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  or?: ContactActivityHistoryWhere | null;
  and?: ContactActivityHistoryWhere | null;
}

export interface ContactNoteWhere {
  id?: IDFilter | null;
  message?: StringFilter | null;
  attachments?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  or?: ContactNoteWhere | null;
  and?: ContactNoteWhere | null;
}

export interface ContactTagWhere {
  id?: IDFilter | null;
  accountId?: IDFilter | null;
  tag?: StringFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  or?: ContactTagWhere | null;
  and?: ContactTagWhere | null;
}

export interface ContactTeamMemberWhere {
  id?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  fee?: FeeWhere | null;
  teamMember?: TeamMemberWhere | null;
  or?: ContactTeamMemberWhere | null;
  and?: ContactTeamMemberWhere | null;
}

export interface ContactWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  email?: StringFilter | null;
  salutation?: StringFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  preferredName?: StringFilter | null;
  dob?: TimeFilter | null;
  gender?: StringFilter | null;
  mobilePhone?: StringFilter | null;
  workPhone?: StringFilter | null;
  homePhone?: StringFilter | null;
  addressLineOne?: StringFilter | null;
  addressLineTwo?: StringFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  countryId?: IDFilter | null;
  zip?: StringFilter | null;
  status?: StringFilter | null;
  type?: StringFilter | null;
  businessName?: StringFilter | null;
  occupation?: StringFilter | null;
  source?: StringFilter | null;
  meta?: any | null;
  workflowStatus?: any | null;
  photoUrls?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  userId?: IDFilter | null;
  unmindUserId?: StringFilter | null;
  timezone?: StringFilter | null;
  locale?: StringFilter | null;
  contactAccountId?: IDFilter | null;
  sessionLimitOverride?: IntFilter | null;
  accountContacts?: AccountContactWhere | null;
  contactActivityHistories?: ContactActivityHistoryWhere | null;
  contactNotes?: ContactNoteWhere | null;
  contactTags?: ContactTagWhere | null;
  contactTeamMembers?: ContactTeamMemberWhere | null;
  eventGuests?: EventGuestWhere | null;
  eventRequests?: EventRequestWhere | null;
  formResponses?: FormResponseWhere | null;
  salesOrders?: SalesOrderWhere | null;
  or?: ContactWhere | null;
  and?: ContactWhere | null;
}

export interface EventFilter {
  search?: string | null;
  where?: EventWhere | null;
  awaitingConfirmationOnly?: boolean | null;
}

export interface EventGuestWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  status?: StringFilter | null;
  recurringSendSalesOrder?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  event?: EventWhere | null;
  salesOrders?: SalesOrderWhere | null;
  or?: EventGuestWhere | null;
  and?: EventGuestWhere | null;
}

export interface EventOrdering {
  sort: EventSort;
  direction: SortDirection;
}

export interface EventRequestFilter {
  search?: string | null;
  where?: EventRequestWhere | null;
}

export interface EventRequestWhere {
  id?: IDFilter | null;
  accountId?: IDFilter | null;
  preferredUserId?: IDFilter | null;
  contactMethod?: StringFilter | null;
  comment?: StringFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  accountInvoiceId?: IDFilter | null;
  declinationReason?: StringFilter | null;
  contact?: ContactWhere | null;
  event?: EventWhere | null;
  salesOrders?: SalesOrderWhere | null;
  or?: EventRequestWhere | null;
  and?: EventRequestWhere | null;
}

export interface EventWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  userId?: IDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  location?: StringFilter | null;
  beginsAt?: TimeFilter | null;
  endsAt?: TimeFilter | null;
  recurring?: BooleanFilter | null;
  recurringBegin?: TimeFilter | null;
  recurringEnd?: TimeFilter | null;
  icalendar?: StringFilter | null;
  timeZone?: StringFilter | null;
  comment?: StringFilter | null;
  flag?: StringFilter | null;
  type?: StringFilter | null;
  status?: StringFilter | null;
  zoomMeetingDetails?: any | null;
  tags?: StringFilter | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  source?: StringFilter | null;
  sessionType?: StringFilter | null;
  googleMeetDetails?: any | null;
  incompleteType?: StringFilter | null;
  incompleteReason?: StringFilter | null;
  cancellationType?: StringFilter | null;
  cancellationReason?: StringFilter | null;
  cancellationDatetime?: TimeFilter | null;
  practitionerConfirmationDatetime?: TimeFilter | null;
  practitionerConfirmationSource?: StringFilter | null;
  meetCompletionDetail?: any | null;
  zoomCompletionDetail?: any | null;
  twilioCompletionDetail?: any | null;
  completedDatetime?: TimeFilter | null;
  cancellationReasonType?: StringFilter | null;
  updatedBy?: StringFilter | null;
  session?: SessionWhere | null;
  eventGuests?: EventGuestWhere | null;
  eventRequests?: EventRequestWhere | null;
  or?: EventWhere | null;
  and?: EventWhere | null;
  practitionerCountry?: StringFilter | null;
  practitionerCountryID?: IDFilter | null;
  practitionerEmail?: StringFilter | null;
  practitionerFirstName?: StringFilter | null;
  practitionerLastName?: StringFilter | null;
  clientOrganisation?: StringFilter | null;
}

export interface FeeWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  amount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  meta?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  type?: StringFilter | null;
  contactTeamMembers?: ContactTeamMemberWhere | null;
  teamMembers?: TeamMemberWhere | null;
  or?: FeeWhere | null;
  and?: FeeWhere | null;
}

export interface FloatFilter {
  isNullOrZero?: boolean | null;
  isNull?: boolean | null;
  notNullOrZero?: boolean | null;
  notNull?: boolean | null;
  equalTo?: number | null;
  notEqualTo?: number | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
}

export interface FormResponseWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  schema?: any | null;
  uISchema?: any | null;
  formData?: any | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  contact?: ContactWhere | null;
  form?: FormWhere | null;
  or?: FormResponseWhere | null;
  and?: FormResponseWhere | null;
}

export interface FormTypeWhere {
  id?: IDFilter | null;
  description?: StringFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  typeForms?: FormWhere | null;
  or?: FormTypeWhere | null;
  and?: FormTypeWhere | null;
}

export interface FormWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  name?: StringFilter | null;
  schema?: any | null;
  uISchema?: any | null;
  submitText?: StringFilter | null;
  thankYouMessage?: StringFilter | null;
  captchaEnabled?: BooleanFilter | null;
  isPublished?: BooleanFilter | null;
  noBranding?: BooleanFilter | null;
  notificationRecipients?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  type?: FormTypeWhere | null;
  formResponses?: FormResponseWhere | null;
  or?: FormWhere | null;
  and?: FormWhere | null;
}

export interface IDFilter {
  isNull?: boolean | null;
  notNull?: boolean | null;
  equalTo?: string | null;
  notEqualTo?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface IntFilter {
  isNullOrZero?: boolean | null;
  isNull?: boolean | null;
  notNullOrZero?: boolean | null;
  notNull?: boolean | null;
  equalTo?: number | null;
  notEqualTo?: number | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  in?: number[] | null;
  notIn?: number[] | null;
}

export interface MatchToPractitionersInput {
  sessionContext?: string | null;
  sessionPreferences?: string[] | null;
  location?: MatchToPractitionersLocationInput | null;
  languages?: string[] | null;
}

export interface MatchToPractitionersLocationInput {
  country?: string | null;
  region?: string | null;
}

export interface PaymentInWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  ref?: StringFilter | null;
  amount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  payerEmail?: StringFilter | null;
  meta?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  salesOrder?: SalesOrderWhere | null;
  paymentOuts?: PaymentOutWhere | null;
  or?: PaymentInWhere | null;
  and?: PaymentInWhere | null;
}

export interface PaymentOutWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  ref?: StringFilter | null;
  accountId?: IDFilter | null;
  amount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  meta?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  status?: StringFilter | null;
  source?: StringFilter | null;
  paymentIn?: PaymentInWhere | null;
  salesOrder?: SalesOrderWhere | null;
  or?: PaymentOutWhere | null;
  and?: PaymentOutWhere | null;
}

export interface PaymentWhere {
  id?: IDFilter | null;
  paidAt?: TimeFilter | null;
  amount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  accountId?: IDFilter | null;
  createdAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  salesOrders?: SalesOrderWhere | null;
  or?: PaymentWhere | null;
  and?: PaymentWhere | null;
}

export interface PractitionerFilter {
  search?: string | null;
  where?: PractitionerWhere | null;
  country?: string | null;
  state?: string | null;
}

export interface PractitionerRequestInput {
  practitionerId: string;
  practitionerAccountId: string;
  practitionerFirstName: string;
  practitionerLastName: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  accountName?: string | null;
  userId?: string | null;
  message?: string | null;
  start?: any | null;
  end?: any | null;
  paymentMethodId?: string | null;
  savePhone?: boolean | null;
}

export interface PractitionerUserProfileDetailWhere {
  id?: IDFilter | null;
  roleId?: IDFilter | null;
  type?: StringFilter | null;
  profileInformation?: any | null;
  specialties?: StringFilter | null;
  specialityKeys?: StringFilter | null;
  onboardingTourCompletion?: any | null;
  pricing?: any | null;
  profileSettings?: any | null;
  defaultSessionPassword?: StringFilter | null;
  activeForCustomer?: TimeFilter | null;
  activeForEmployer?: TimeFilter | null;
  zoomDetails?: any | null;
  zoomCredentials?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  or?: PractitionerUserProfileDetailWhere | null;
  and?: PractitionerUserProfileDetailWhere | null;
}

export interface PractitionerWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  countryId?: IDFilter | null;
  timeZone?: StringFilter | null;
  languages?: StringFilter | null;
  gender?: StringFilter | null;
  phone?: StringFilter | null;
  ageGroup?: StringFilter | null;
  dob?: StringFilter | null;
  photoUrls?: any | null;
  iP?: StringFilter | null;
  setupComplete?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  email?: StringFilter | null;
  account?: AccountWhere | null;
  createdByAccountUserProfileInvitations?: AccountUserProfileInvitationWhere | null;
  userUserAuthenticationProviders?: UserAuthenticationProviderWhere | null;
  userUserProfileDetailConnects?: PractitionerUserProfileDetailWhere | null;
  or?: PractitionerWhere | null;
  and?: PractitionerWhere | null;
}

export interface ProductWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  type?: StringFilter | null;
  price?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  meta?: any | null;
  createdBy?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  salesOrderItems?: SalesOrderItemWhere | null;
  or?: ProductWhere | null;
  and?: ProductWhere | null;
}

export interface SalesOrderItemWhere {
  id?: IDFilter | null;
  name?: StringFilter | null;
  description?: StringFilter | null;
  quantity?: IntFilter | null;
  price?: FloatFilter | null;
  discount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  meta?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  product?: ProductWhere | null;
  salesOrder?: SalesOrderWhere | null;
  or?: SalesOrderItemWhere | null;
  and?: SalesOrderItemWhere | null;
}

export interface SalesOrderWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  accountId?: IDFilter | null;
  userId?: IDFilter | null;
  customerEmail?: StringFilter | null;
  customerFirstName?: StringFilter | null;
  customerLastName?: StringFilter | null;
  subAmount?: FloatFilter | null;
  taxAmount?: FloatFilter | null;
  totalAmount?: FloatFilter | null;
  discountAmount?: FloatFilter | null;
  grandAmount?: FloatFilter | null;
  currencyCode?: StringFilter | null;
  status?: StringFilter | null;
  meta?: any | null;
  paymentTerms?: any | null;
  eventRecurringId?: IDFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  accountInvoiceId?: IDFilter | null;
  type?: StringFilter | null;
  updatedBy?: StringFilter | null;
  contact?: ContactWhere | null;
  eventGuest?: EventGuestWhere | null;
  eventRequest?: EventRequestWhere | null;
  payment?: PaymentWhere | null;
  paymentIns?: PaymentInWhere | null;
  paymentOuts?: PaymentOutWhere | null;
  salesOrderItems?: SalesOrderItemWhere | null;
  or?: SalesOrderWhere | null;
  and?: SalesOrderWhere | null;
}

export interface SessionHistoryWhere {
  id?: IDFilter | null;
  duration?: FloatFilter | null;
  userId?: IDFilter | null;
  startAt?: TimeFilter | null;
  endAt?: TimeFilter | null;
  iP?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  session?: SessionWhere | null;
  or?: SessionHistoryWhere | null;
  and?: SessionHistoryWhere | null;
}

export interface SessionWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  createdBy?: IDFilter | null;
  password?: StringFilter | null;
  status?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  events?: EventWhere | null;
  sessionHistories?: SessionHistoryWhere | null;
  or?: SessionWhere | null;
  and?: SessionWhere | null;
}

export interface StringFilter {
  isNullOrEmpty?: boolean | null;
  isEmpty?: boolean | null;
  isNull?: boolean | null;
  notNullOrEmpty?: boolean | null;
  notEmpty?: boolean | null;
  notNull?: boolean | null;
  equalTo?: string | null;
  notEqualTo?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  startWith?: string | null;
  notStartWith?: string | null;
  endWith?: string | null;
  notEndWith?: string | null;
  contain?: string | null;
  notContain?: string | null;
  startWithStrict?: string | null;
  notStartWithStrict?: string | null;
  endWithStrict?: string | null;
  notEndWithStrict?: string | null;
  containStrict?: string | null;
  notContainStrict?: string | null;
}

export interface SubmitAssessmentInput {
  eventId: string;
  GAD: AssessmentResponseType;
  PHQ: AssessmentResponseType;
  Workplace: AssessmentResponseType;
}

export interface TeamMemberWhere {
  id?: IDFilter | null;
  accountId?: IDFilter | null;
  memberUserId?: IDFilter | null;
  permissions?: any | null;
  showOnCatalogue?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  fee?: FeeWhere | null;
  channelMembers?: ChannelMemberWhere | null;
  contactTeamMembers?: ContactTeamMemberWhere | null;
  or?: TeamMemberWhere | null;
  and?: TeamMemberWhere | null;
}

export interface TimeFilter {
  isNull?: boolean | null;
  notNull?: boolean | null;
  equalTo?: any | null;
  notEqualTo?: any | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  moreThan?: any | null;
  moreThanOrEqualTo?: any | null;
}

export interface UserAuthenticationProviderWhere {
  id?: IDFilter | null;
  providerType?: StringFilter | null;
  providerUsername?: StringFilter | null;
  providerPassword?: StringFilter | null;
  password?: StringFilter | null;
  tokens?: any | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  providerIdpId?: IDFilter | null;
  user?: UserProfileWhere | null;
  or?: UserAuthenticationProviderWhere | null;
  and?: UserAuthenticationProviderWhere | null;
}

export interface UserProfileOrdering {
  sort: UserProfileSort;
  direction: SortDirection;
}

export interface UserProfileWhere {
  id?: IDFilter | null;
  externalRef?: StringFilter | null;
  firstName?: StringFilter | null;
  lastName?: StringFilter | null;
  countryId?: IDFilter | null;
  timeZone?: StringFilter | null;
  languages?: any | null;
  gender?: StringFilter | null;
  phone?: StringFilter | null;
  ageGroup?: StringFilter | null;
  dob?: StringFilter | null;
  photoUrls?: any | null;
  iP?: StringFilter | null;
  setupComplete?: BooleanFilter | null;
  deletedAt?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  email?: StringFilter | null;
  hubspotId?: IDFilter | null;
  city?: StringFilter | null;
  state?: StringFilter | null;
  isAuOrigin?: BooleanFilter | null;
  workosDirUserRef?: StringFilter | null;
  timeZoneOffset?: IntFilter | null;
  googleCredentials?: any | null;
  defaultLanguage?: StringFilter | null;
  ethnicity?: any | null;
  account?: AccountWhere | null;
  createdByAccountUserProfileInvitations?: AccountUserProfileInvitationWhere | null;
  relatedUserAccountUserProfileInvitations?: AccountUserProfileInvitationWhere | null;
  userUserAuthenticationProviders?: UserAuthenticationProviderWhere | null;
  or?: UserProfileWhere | null;
  and?: UserProfileWhere | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
