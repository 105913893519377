import { rem } from 'polished';
import { styled } from 'styles';
import i18n from '../../../i18n/config';
// eslint-disable-next-line @typescript-eslint/tslint/config
import logo from '../../../Shared/Assets/unmind_logo.svg';

const AppIcon = styled.img.attrs({
  height: 54,
  width: 59,
  src: logo,
  alt: i18n.t('shared:store_overlay.app_icon.alt_text'),
})`
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: ${rem(20)};
  box-shadow: 0 0 ${rem(30)} rgba(0, 0, 0, 0.15);
  padding: ${rem(20)} ${rem(20)} ${rem(15)} ${rem(20)};
  height: ${rem(100)};
  width: ${rem(100)};
  margin-bottom: ${rem(36)};

  // required for Safari
  min-height: ${rem(100)};
`;

export default AppIcon;
