import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelledCheckbox, { LabelledCheckboxProps } from './LabelledCheckbox';

const StoringDataInEEACheckbox = (props: LabelledCheckboxProps) => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <LabelledCheckbox {...props} data-testid="storing-data-eea-checkbox">
      {translate('sign_up.storing_data_eea_checkbox.label')}
    </LabelledCheckbox>
  );
};

export default StoringDataInEEACheckbox;
