import { useAuth0 } from '@auth0/auth0-react';
import { logException } from 'App/logging';
import { useEffect, useState } from 'react';

export const useAuth0Token = () => {
  const [authToken, setAuthToken] = useState<string | null>();
  const { getAccessTokenSilently, isAuthenticated: isAuthenticatedViaAuth0 } =
    useAuth0();

  useEffect(() => {
    const getAuthToken = async () => {
      if (isAuthenticatedViaAuth0) {
        try {
          const token = await getAccessTokenSilently();
          setAuthToken(token);

          return;
        } catch (e) {
          logException(e);
        }
      }

      setAuthToken(null);
    };

    void getAuthToken();
  }, [getAccessTokenSilently, isAuthenticatedViaAuth0]);

  return authToken;
};
