import React, { ReactNode } from 'react';
import { Namespace, TFunction } from 'i18next';
import { useLocation } from 'react-router';
import { Breadcrumb, BreadcrumbContainer, Separator } from './styles';

interface BreadcrumbConfig {
  label: string | ReactNode;
  to: string;
}

export interface BreadcrumbsProps {
  labels: Record<string, string | ReactNode>;
  className?: string;
}

export const getTalkBreadcrumbLabels = ({
  t,
  practitionerName,
}: {
  t: TFunction<Namespace<'talk'>>;
  practitionerName?: string | ReactNode;
}) => ({
  talk: t('common.breadcrumbs.talk'),
  browse: t('common.breadcrumbs.browse'),
  2: practitionerName ?? '',
});

export const Breadcrumbs = ({ labels, className }: BreadcrumbsProps) => {
  const { pathname } = useLocation();
  const pathParts = pathname
    .slice(1)
    .split('/')
    .filter(part => part !== '');

  const breadcrumbs: BreadcrumbConfig[] = pathParts.map((pathPart, i) => ({
    label: labels[pathPart] || labels[i],
    to: `/${pathParts.slice(0, i + 1).join('/')}`,
  }));

  return (
    <BreadcrumbContainer className={className}>
      {breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={breadcrumb.to}>
          <Breadcrumb
            to={breadcrumb.to}
            key={i}
            disabled={i === breadcrumbs.length - 1}
          >
            {breadcrumb.label}
          </Breadcrumb>
          {i < breadcrumbs.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};
