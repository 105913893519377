import { rem } from 'polished';
import { styled } from 'styles';
import getFocusState from '../Button/getFocusState';
import { inputFontStyles } from '../Typography/Forms';

export const TextArea = styled.textarea`
  width: 100%;
  outline: 0;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: ${rem(4)};
  border: solid 1px ${({ theme }) => theme.colors.border.secondary};
  color: ${({ theme }) => theme.colors.text.primary};
  &:focus {
    ${getFocusState()}
  }
  resize: none;
  padding: ${rem(20)};
  min-height: ${rem(120)};

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  ${({ theme }) => inputFontStyles(theme)};
`;
