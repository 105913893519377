import { rem } from 'polished';
import React from 'react';

import { css, styled } from 'styles';
import { small } from '../../utils';

const ErrorSection = styled.section`
  padding: ${rem(40)} 0;
  text-align: center;

  ${small(css`
    padding: ${rem(80)} 0;
  `)}

  &::before {
    content: '';
    background: ${({ theme }) => theme.colors.background.secondary};
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const ErrorHeading = styled.h1`
  font-size: ${rem(24)};
  font-weight: 400;
  line-height: ${rem(32)};
  margin: 0;

  ${small(css`
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  `)}
`;

const ErrorMessage = styled.p`
  font-size: ${rem(15)};
  font-weight: 400;
  line-height: ${rem(24)};
  margin: ${rem(20)} 0 0;

  ${small(css`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `)}
`;

interface FullPageAlertViewProps {
  heading: string;
  message: string;
}

export const FullPageAlertView = ({
  heading,
  message,
}: FullPageAlertViewProps) => (
  <ErrorSection>
    <ErrorHeading>{heading}</ErrorHeading>
    <ErrorMessage>{message}</ErrorMessage>
  </ErrorSection>
);
