import { rem, rgba } from 'polished';
import React from 'react';

import { styled, ThemeInterface } from 'styles';
import { Back } from 'icons';
import { WrappingButton } from '../../../Shared/Accessibility';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const ControlWrapper = styled.div`
  width: ${rem('80px')};
`;

const ArrowLeftIcon = styled(Back).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
  }),
)`
  width: ${rem('50px')};
  height: ${rem('50px')};
`;

const ArrowRightIcon = styled(Back).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
  }),
)`
  width: ${rem('50px')};
  height: ${rem('50px')};
  transform: rotate(-180deg);
`;

const SlideViewport = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    box-sizing: content-box;
    background: ${({ theme }) => `linear-gradient(
      to right,
      ${rgba(theme.colors.background.primary, 1)} 0%,
      5%,
      ${rgba(theme.colors.background.primary, 0)} 10%,
      95%,
      ${rgba(theme.colors.background.primary, 1)}
    )`};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const SlideTrack = styled.div<{
  activeSlide: number;
  height: number;
  overlap: boolean;
  slideMargin: number;
  slideScale: number;
  slideWidth: number;
}>`
  height: ${({ height, slideScale }) =>
    rem(height * (1 / slideScale) - slideScale * 80)};
  line-height: ${({ height, slideScale }) =>
    rem(height * (1 / slideScale) - slideScale * 80)};
  transition: transform 300ms ease-in-out;
  white-space: nowrap;

  ${({ activeSlide, slideScale, overlap, slideMargin, slideWidth }) =>
    // prettier-ignore
    overlap ? `
      transform:
        translateX(50%)
        translateX(${rem(-slideWidth * slideScale * 0.5)})
        translateX(${rem(
          -activeSlide * (slideMargin * 2 + slideWidth * slideScale),
        )})
        translateX(${rem(-slideMargin)})
        ;
    ` : `
      transform:
        translateX(50%)
        translateX(${rem(-slideWidth * 0.5)})
        translateX(${rem(-activeSlide * (slideMargin * 2 + slideWidth))})
        translateX(${rem(-slideMargin)})
      ;
    `}

  > * {
    display: inline-block;
    position: absolute;
    vertical-align: middle;
  }
`;

export const Slide = styled.div<{
  active: boolean;
  height: number;
  slideScale: number;
  margin: number;
  overlap: boolean;
  width: number;
}>`
  margin: ${({ margin }) => rem(margin)};
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  overflow: hidden;
  position: relative;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  z-index: ${({ active }) => (active ? 1 : 0)};

  ${({ active, height, slideScale, overlap, width }) =>
    overlap
      ? `
          transform: ${active ? `scale(${1 / slideScale})` : undefined};
          height: ${rem(height * slideScale)};
          width: ${rem(width * slideScale)};
        `
      : `
          transform: ${active ? undefined : `scale(${slideScale})`};
          height: ${rem(height)};
          width: ${rem(width)};
        `};
`;

export interface SliderProps {
  ariaLabelledBy?: string;
  ariaValueText?: string[];
  initialSlide: number;
  overlap: boolean;
  slideHeight: number;
  slideMargin: number;
  slideScale: number;
  slideWidth: number;
  onSlideChange?(index: number): void;
}

interface SliderState {
  activeSlide: number;
  canSlideNext: boolean;
  canSlidePrevious: boolean;
}

export enum Direction {
  Next,
  Previous,
}

export default class Slider extends React.Component<SliderProps, SliderState> {
  static defaultProps = {
    initialSlide: 0,
    overlap: true,
    slideHeight: 366,
    slideMargin: 8,
    slideScale: 0.8,
    slideWidth: 410,
  };

  slideInterval?: number;

  constructor(props: SliderProps) {
    super(props);

    this.state = {
      activeSlide: this.props.initialSlide,
      canSlideNext: this.props.initialSlide < this.getChildrenLength() - 1,
      canSlidePrevious: this.props.initialSlide > 0,
    };
  }

  getChildrenLength = () => React.Children.toArray(this.props.children).length;

  isActiveSlide = (idx: number) => idx === this.state.activeSlide;

  handleSlideChange = () => {
    const { onSlideChange } = this.props;
    if (onSlideChange !== undefined) {
      onSlideChange(this.state.activeSlide);
    }
    if (
      (!this.state.canSlidePrevious || !this.state.canSlideNext) &&
      this.slideInterval !== undefined
    ) {
      clearInterval(this.slideInterval);
    }
  };

  autoSlideStart = (direction: Direction) => {
    if (direction === Direction.Previous) {
      this.slidePrevious();
    } else {
      this.slideNext();
    }

    this.slideInterval = window.setInterval(
      direction === Direction.Previous ? this.slidePrevious : this.slideNext,
      500,
    );
  };

  autoSlideFinish = () => {
    if (this.slideInterval !== undefined) {
      clearInterval(this.slideInterval);
    }
  };

  slidePrevious = () => {
    if (this.state.canSlidePrevious) {
      this.setState(
        ({ activeSlide }) => ({
          activeSlide: activeSlide - 1,
          canSlideNext: activeSlide - 1 < this.getChildrenLength() - 1,
          canSlidePrevious: activeSlide - 1 > 0,
        }),
        this.handleSlideChange,
      );
    }
  };

  slideNext = () => {
    if (this.state.canSlideNext) {
      this.setState(
        ({ activeSlide }) => ({
          activeSlide: activeSlide + 1,
          canSlideNext: activeSlide + 1 < this.getChildrenLength() - 1,
          canSlidePrevious: activeSlide + 1 > 0,
        }),
        this.handleSlideChange,
      );
    }
  };

  keyboardSlide = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
      e.preventDefault();
      this.slideNext();
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
      e.preventDefault();
      this.slidePrevious();
    }
  };

  autoSlidePrevious = () => {
    this.autoSlideStart(Direction.Previous);
  };

  autoSlideNext = () => {
    this.autoSlideStart(Direction.Next);
  };

  keyboardAutoSlide =
    (fn: () => void) => (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        fn();
        this.autoSlideFinish();
      }
    };

  componentDidUpdate(prevProps: SliderProps & { children: React.ReactNode }) {
    const hasNewChildren =
      React.Children.toArray(prevProps.children).length !==
      this.getChildrenLength();

    if (hasNewChildren) {
      this.setState(({ activeSlide }) => ({
        canSlideNext: activeSlide < this.getChildrenLength() - 1,
        canSlidePrevious: activeSlide > 1,
      }));
    }
  }

  render() {
    const { activeSlide, canSlideNext, canSlidePrevious } = this.state;
    const {
      ariaLabelledBy,
      ariaValueText,
      children,
      slideHeight,
      slideScale,
      slideMargin,
      overlap,
      slideWidth,
    } = this.props;

    return (
      <Wrapper
        role="slider"
        aria-valuemin={0}
        aria-valuemax={this.getChildrenLength() - 1}
        aria-valuenow={activeSlide}
        aria-valuetext={ariaValueText ? ariaValueText[activeSlide] : undefined}
        aria-labelledby={ariaLabelledBy}
        aria-orientation="horizontal"
      >
        <ControlWrapper>
          {canSlidePrevious && (
            <WrappingButton
              aria-label="previous"
              onMouseDown={this.autoSlidePrevious}
              onMouseUp={this.autoSlideFinish}
              onKeyDown={this.keyboardAutoSlide(this.autoSlidePrevious)}
            >
              <ArrowLeftIcon />
            </WrappingButton>
          )}
        </ControlWrapper>
        <SlideViewport
          data-testid="slide-track-container"
          tabIndex={0}
          onKeyDown={this.keyboardSlide}
        >
          <SlideTrack
            activeSlide={activeSlide}
            height={slideHeight + slideMargin * 2}
            slideScale={slideScale}
            overlap={overlap}
            slideMargin={slideMargin}
            slideWidth={slideWidth}
          >
            {React.Children.map(children, (child, idx) => (
              <Slide
                active={this.isActiveSlide(idx)}
                height={slideHeight}
                slideScale={slideScale}
                margin={slideMargin}
                overlap={overlap}
                width={slideWidth}
              >
                {child}
              </Slide>
            ))}
          </SlideTrack>
        </SlideViewport>
        <ControlWrapper>
          {canSlideNext && (
            <WrappingButton
              onMouseDown={this.autoSlideNext}
              onMouseUp={this.autoSlideFinish}
              onKeyDown={this.keyboardAutoSlide(this.autoSlideNext)}
              aria-label="next"
            >
              <ArrowRightIcon />
            </WrappingButton>
          )}
        </ControlWrapper>
      </Wrapper>
    );
  }
}
