import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled } from 'styles';
import { ExternalLinkIcon } from '../../ExternalLinkIcon';
import { tracking } from '../../../App/Tracking';
import { Events } from '../../../App/Tracking/Events';
import { ExternalLink } from '../../../Shared/Typography';
import { getPrivacyPolicyLink } from '../../../utils';
import { getTermsOfUseLink } from '../../../utils/getTermsOfUseLink';
import LabelledCheckbox, { LabelledCheckboxProps } from './LabelledCheckbox';

const StyledLink = styled(ExternalLink)`
  white-space: nowrap;
`;

interface TermsCheckboxProps extends LabelledCheckboxProps {
  subdomain?: string;
  termsTrackingEvent?: Events;
  partner?: string | null;
}

const trackTerms = (
  subdomain: string | undefined,
  trackingEvent: Events | undefined,
  partner: string | null,
) => {
  const trackingEventName = trackingEvent
    ? trackingEvent
    : 'terms-conditions-clicked';

  tracking.track(trackingEventName, { subdomain, partner });
};

const trackPrivacyPolicy = (
  subdomain: string | undefined,
  partner: string | null,
) => {
  tracking.track('privacy-policy-clicked', { subdomain, partner });
};

const TermsCheckbox = ({
  partner = null,
  subdomain,
  termsTrackingEvent,
  ...props
}: TermsCheckboxProps) => {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  return (
    <LabelledCheckbox {...props} data-testid="sign-up-terms">
      {Trans({
        t: translate,
        i18nKey: 'sign_up.terms_checkbox.label',
        defaults:
          'I agree to Unmind’s <bold_link_terms>Terms & Conditions</bold_link_terms> and <bold_link_policy>Privacy Policy</bold_link_policy>.',
        components: {
          bold_link_terms: (
            <StyledLink
              onClick={() => trackTerms(subdomain, termsTrackingEvent, partner)}
              href={getTermsOfUseLink(subdomain)}
              target="_blank"
              data-testid="terms-conditions-link"
            />
          ),
          bold_link_policy: (
            <StyledLink
              onClick={() => trackPrivacyPolicy(subdomain, partner)}
              href={getPrivacyPolicyLink(subdomain)}
              target="_blank"
              data-testid="privacy-policy-link"
            />
          ),
          external_link_icon: <ExternalLinkIcon />,
        },
      })}
    </LabelledCheckbox>
  );
};

export default TermsCheckbox;
