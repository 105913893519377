import { small } from '@unmind/design-system-components-web';
import Portal from 'Shared/Portal';
import { rem, rgba } from 'polished';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

interface ModalWithDrawerOnMobileProps {
  children: ReactNode | null;
  onClose(): void;
  largeModal?: boolean;
}

const ModalContent = styled.div`
  padding: 0;
  border: none;
  z-index: 10;
  width: 100%;
  max-height: 90vh;
  overflow: scroll;
  background-color: white;
  border-radius: ${rem(20)};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;

  ${small(css`
    width: auto;
    max-height: auto;
    height: auto;
  `)};
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10040;
  background: ${({ theme }) => rgba(theme.colors.background.inverse, 0.7)};
  visibility: visible;

  ${small(css`
    align-items: center;
  `)};
`;

export const ModalWrapper = styled.div`
  padding: 0;
  border: none;
  z-index: 10;
  max-height: 90vh;
  overflow: scroll;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ModalWithDrawerOnMobile = ({
  children,
  onClose,
}: ModalWithDrawerOnMobileProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        document.activeElement &&
        (document.activeElement as HTMLElement).tagName.toLowerCase() ===
          'input' &&
        e.key !== 'Tab' &&
        e.key !== 'Enter'
      ) {
        // Don't prevent default for input elements
        return;
      }

      // Close the modal on 'Escape' key press
      if (e.key === 'Escape') {
        onClose();

        return;
      }

      if (e.key === ' ' && document.activeElement) {
        const elementType = (
          document.activeElement as HTMLElement
        ).tagName.toLowerCase();
        if (elementType === 'button' || elementType === 'input') {
          (document.activeElement as HTMLButtonElement).click();
        }
      }

      // Trap focus inside the modal
      if (e.key === 'Tab' && modalRef.current) {
        const tabbingForwards = !e.shiftKey;

        const focusableElements: NodeListOf<HTMLElement> =
          modalRef.current.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]),div[role="button"]',
          );

        const focusableVisibleElements: HTMLElement[] = Array.from(
          focusableElements,
        ).filter(element => {
          const isAriaHidden =
            (element.ariaDisabled &&
              element.getAttribute('aria-disabled') === 'true') ||
            element.hasAttribute('disabled');

          const style = window.getComputedStyle(element);

          return (
            style.display !== 'none' &&
            style.visibility !== 'hidden' &&
            !isAriaHidden
          );
        });

        const elementIndex = Array.from(focusableVisibleElements).indexOf(
          document.activeElement as HTMLElement,
        );

        let nextElement;

        if (tabbingForwards) {
          nextElement =
            elementIndex === focusableVisibleElements.length - 1
              ? focusableVisibleElements[0]
              : focusableVisibleElements[elementIndex + 1];
        } else {
          nextElement =
            elementIndex === 0
              ? focusableVisibleElements[focusableVisibleElements.length - 1]
              : focusableVisibleElements[elementIndex - 1];
        }

        nextElement.focus();
        e.preventDefault();
      }
    },
    [onClose],
  );

  const listenForClickOutside = useCallback(
    (e: MouseEvent) => {
      // because the select closes straight away the target wont be in the modal by the time this gets called so we want to ignore these clicks
      const clickIsInsideSelect =
        e.target instanceof Node &&
        (e.target as Element).getAttribute('role') === 'option';

      if (
        modalRef.current &&
        e.target &&
        !modalRef.current.contains(e.target as Node) &&
        !clickIsInsideSelect
      ) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', listenForClickOutside);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', listenForClickOutside);
      document.body.style.overflow = 'visible';
    };
  }, [handleKeyDown, listenForClickOutside]);

  return (
    <Portal prependToRootComponent={true}>
      <ModalWrapper>
        <ModalContainer>
          <ModalContent role="dialog" ref={modalRef}>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalWrapper>
    </Portal>
  );
};
