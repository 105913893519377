import { TimeSlot } from 'Talk/lib/availability';

export enum BookingStage {
  DateSelect = 'dateSelect',
  Confirm = 'confirm',
}

export enum FieldValues {
  BookingType = 'bookingType',
  SelectedDate = 'selectedDate',
  SelectedSlot = 'selectedSlot',
  Stage = 'bookingStage',
  Range = 'range',
  Message = 'message',
}

export type Range = {
  from: Date;
  to: Date;
};

export enum BookingType {
  DirectBooking = 'directBooking',
  BookingRequest = 'bookingRequest',
}

export interface FormValues {
  [FieldValues.BookingType]: BookingType;
  [FieldValues.SelectedDate]?: Date;
  [FieldValues.SelectedSlot]?: TimeSlot;
  [FieldValues.Stage]: BookingStage;
  [FieldValues.Range]: Range;
  [FieldValues.Message]?: string;
}

export interface SubmissionError {
  error: boolean;
  message: string;
}

export interface FormikStatus {
  submissionError?: SubmissionError;
  submissionComplete?: boolean;
}
