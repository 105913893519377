import defaultTo from 'lodash/defaultTo';
import { rem } from 'polished';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { styled } from 'styles';
import ImpactQuestionnaireQuestions from 'Series/SingleSeries/SeriesDay/SeriesSegment/Questionnaire/ImpactQuestionnaireQuestions';
import { UseSingleSeries_singleSeries } from 'Series/SingleSeries/__generated__/UseSingleSeries';
import { SingleSeries_singleSeries_questions } from 'Series/SingleSeries/__generated__/SingleSeries';
import { tracking } from '../../../../../App/Tracking';
import { ButtonSize } from '../../../../../Shared/Button';
import { FullScreenTakeover } from '../../../../../Shared/FullScreenTakeover';
import { PrimaryButton } from '../../../../../Shared/PrimaryButton/PrimaryButton';
import { TimerDispatch, withTimerDispatch } from '../../../../../Shared/Timer';
import { BodyText } from '../../../../../Shared/Typography';
import {
  CourseImpactQuestionnaireResponse,
  SeriesQuestionResponse,
} from '../../../../../__generated__/globalTypes';
import { withSingleSeries } from '../../../useSingleSeries';
import {
  withSeriesTrackingIds,
  withSeriesTrackingIdsChildProps,
} from '../../withSeriesTrackingIds';
import { withUserName, WithUserNameChildProps } from '../withUserName';
import QuestionnaireHeader from './QuestionnaireHeader';
import QuestionnaireIntro from './QuestionnaireIntro';
import QuestionnaireOutro from './QuestionnaireOutro';
import QuestionnaireQuestions from './QuestionnaireQuestions';

interface OuterQuestionnaireSegmentProps {
  seriesSegmentId: string;
  segmentTitle: string;
  isSegmentExpanded: boolean;
  isLastSegmentInSeries: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
}

interface EnhancedComponentProps {
  slug: string;
  dayNumber: number;
  isInitialQuestionnaire: boolean;
  segmentNumber: number;
}

export interface QuestionnaireSegmentProps
  extends OuterQuestionnaireSegmentProps,
    WithTranslation,
    withSeriesTrackingIdsChildProps,
    WithUserNameChildProps,
    EnhancedComponentProps,
    Pick<RouteComponentProps, 'history'> {
  series: UseSingleSeries_singleSeries;
  singleSeriesLoading: boolean;
  timerDispatch: TimerDispatch;
}

interface QuestionnaireSegmentState {
  isTakeoverShown: boolean;
  answers: SeriesQuestionResponse[];
  impactQuestionnaireAnswers: CourseImpactQuestionnaireResponse[];
  currentStep: QuestionnaireStep;
}

export enum QuestionnaireStep {
  intro,
  question,
  impactQuestionnaire,
  outro,
}

const Container = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  forwardedAs: 'div',
}))`
  margin: 0 0 ${rem(18)};

  p {
    margin-block-start: 0;
  }
`;

const TakeoverContainer = styled.div`
  max-width: ${rem(710)};
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${rem(25)};
`;

export class QuestionnaireSegment extends React.Component<
  QuestionnaireSegmentProps,
  QuestionnaireSegmentState
> {
  state = {
    isTakeoverShown: false,
    answers: [],
    impactQuestionnaireAnswers: [],
    currentStep: QuestionnaireStep.intro,
  };

  componentDidMount() {
    if (this.props.isSegmentExpanded) {
      this.props.timerDispatch.reset();
      this.props.timerDispatch.start(true);
    }
  }

  setAnswers = (answers: SeriesQuestionResponse[]) => {
    this.setState({ answers });
  };

  nextSection = () => {
    const {
      series,
      isLastSegmentInDay,
      isLastSegmentInSeries,
      isInitialQuestionnaire,
    } = this.props;

    const isLastQuestionnaire = !isInitialQuestionnaire;
    const hasImpactQuestionnaire = series.impactQuestionnaire;
    const hasCompletedImpactQuestionnaire =
      this.state.impactQuestionnaireAnswers?.length > 0;

    const shouldDisplayCourseImpactQuestionnaire =
      hasImpactQuestionnaire &&
      isLastQuestionnaire &&
      isLastSegmentInDay &&
      isLastSegmentInSeries &&
      !hasCompletedImpactQuestionnaire;

    const nextStep = shouldDisplayCourseImpactQuestionnaire
      ? QuestionnaireStep.impactQuestionnaire
      : QuestionnaireStep.outro;

    this.setState({ currentStep: nextStep });
  };

  prevSection = () => {
    this.setState({ currentStep: QuestionnaireStep.question });
  };

  beginQuestionnaire = () => {
    this.setState({
      currentStep: QuestionnaireStep.question,
      isTakeoverShown: true,
    });
  };

  closeTakeover = () => {
    this.setState({
      isTakeoverShown: false,
    });
  };

  trackSeriesSegmentClosed = () => {
    const {
      dayNumber,
      seriesId,
      slug,
      id,
      segmentTitle,
      seriesSegmentId,
      segmentNumber,
      isComplete,
    } = this.props;

    tracking.track('series-segment-closed', {
      seriesId,
      seriesSlug: slug,
      segmentId: seriesSegmentId,
      segmentTitle: segmentTitle,
      segmentType: 'questionnaire',
      segmentNumber,
      dayId: id,
      dayNumber: dayNumber,
      isInitial: !isComplete,
    });
  };

  trackSeriesSegmentStarted = () => {
    const {
      seriesSegmentId,
      dayNumber,
      segmentTitle,
      segmentNumber,
      seriesId,
      id,
      slug,
      isComplete,
    } = this.props;

    tracking.track('series-segment-started', {
      seriesId,
      seriesSlug: slug,
      segmentId: seriesSegmentId,
      segmentTitle,
      segmentNumber,
      dayId: id,
      dayNumber: dayNumber,
      isInitial: !isComplete,
    });
  };

  render() {
    const {
      answers,
      impactQuestionnaireAnswers,
      currentStep,
      isTakeoverShown,
    } = this.state;
    const {
      dayNumber,
      firstName,
      history,
      id,
      isInitialQuestionnaire,
      series,
      seriesSegmentId,
      singleSeriesLoading,
      slug,
      segmentNumber,
      segmentTitle,
      isLastSegmentInSeries,
      isLastSegmentInDay,
      isComplete,
      t: translate,
      exploreCategoryId,
    } = this.props;

    if (id === undefined || !series || singleSeriesLoading) {
      return <div />;
    }

    const impactQuestionnaire = series?.impactQuestionnaire;

    // todo: Possibly remove duplicate code
    const seriesQuestionsData =
      series.questions?.filter(
        (q): q is SingleSeries_singleSeries_questions => !!q,
      ) || [];

    return (
      <Container>
        <QuestionnaireIntro
          isInitialQuestionnaire={isInitialQuestionnaire}
          firstName={firstName}
          seriesTitle={series.title || ''}
        />
        <ButtonsWrapper>
          <PrimaryButton
            disabled={false}
            label={translate('segment.questionnaire.intro.begin_button.label')}
            onClick={() => {
              this.beginQuestionnaire();
              this.trackSeriesSegmentStarted();
            }}
            size={ButtonSize.medium}
            type="submit"
          />
        </ButtonsWrapper>
        <FullScreenTakeover
          shown={isTakeoverShown}
          hasWhiteBackground={true}
          scrollable={true}
        >
          <TakeoverContainer>
            <QuestionnaireHeader
              seriesTitle={series.title || ''}
              dayNumber={dayNumber}
              handleClose={this.closeTakeover}
              trackOnCloseButtonClick={this.trackSeriesSegmentClosed}
            />
            <Container>
              {currentStep === QuestionnaireStep.question && series.id && (
                <QuestionnaireQuestions
                  series={series}
                  answers={answers}
                  currentQuestionIndex={
                    answers.length > 0 ? answers.length - 1 : 0
                  }
                  impactQuestionnaireQuestionsCount={
                    impactQuestionnaire?.questions?.length || 0
                  }
                  setFinalAnswers={this.setAnswers}
                  nextSection={this.nextSection}
                />
              )}
              {currentStep === QuestionnaireStep.impactQuestionnaire &&
                series.id && (
                  <ImpactQuestionnaireQuestions
                    impactQuestionnaire={impactQuestionnaire}
                    setImpactQuestionnaireAnswers={impactAnswers => {
                      this.setState({
                        impactQuestionnaireAnswers: impactAnswers,
                      });
                    }}
                    nextSection={this.nextSection}
                    prevSection={this.prevSection}
                    seriesQuestionsCount={seriesQuestionsData.length}
                  />
                )}
              {currentStep === QuestionnaireStep.outro && series.id && (
                <QuestionnaireOutro
                  answers={answers}
                  impactQuestionnaireAnswers={impactQuestionnaireAnswers}
                  impactQuestionnaireVersion={
                    series?.impactQuestionnaire?.version
                  }
                  dayNumber={dayNumber}
                  history={history}
                  seriesTitle={series.title || ''}
                  seriesId={series.id}
                  seriesSegmentId={seriesSegmentId}
                  segmentTitle={segmentTitle}
                  seriesDayId={id}
                  slug={slug}
                  handleClose={this.closeTakeover}
                  isInitialQuestionnaire={isInitialQuestionnaire}
                  segmentNumber={segmentNumber}
                  isLastSegmentInSeries={isLastSegmentInSeries}
                  isLastSegmentInDay={isLastSegmentInDay}
                  isComplete={isComplete}
                  exploreCategoryId={exploreCategoryId}
                />
              )}
            </Container>
          </TakeoverContainer>
        </FullScreenTakeover>
      </Container>
    );
  }
}

export default withTranslation('courses')(
  compose<QuestionnaireSegmentProps, OuterQuestionnaireSegmentProps>(
    withRouter,
    (Component: React.ComponentClass<EnhancedComponentProps>) =>
      (
        props: RouteComponentProps<{
          slug: string;
          day: string;
          segment: string;
        }>,
      ) => {
        const dayNumber = defaultTo(Number(props.match.params.day), 1);
        const segmentNumber = defaultTo(Number(props.match.params.segment), 1);

        return (
          <Component
            slug={props.match.params.slug}
            dayNumber={dayNumber}
            isInitialQuestionnaire={dayNumber === 1}
            segmentNumber={segmentNumber}
            {...props}
          />
        );
      },
    withSingleSeries,
    withSeriesTrackingIds,
    withTimerDispatch,
    withUserName,
  )(QuestionnaireSegment),
);
