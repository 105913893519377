import React from 'react';
import { IconProps } from '../IconProps';

const SvgCards = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M14.962 2.959a1.085 1.085 0 0 1 1.33-.768l13.76 3.687a1.086 1.086 0 0 1 .769 1.328l-5.28 19.696a1.087 1.087 0 0 1-1.33.768l-13.76-3.685a1.087 1.087 0 0 1-.769-1.33l5.28-19.696z"
      fill={primaryColor}
    />
    <path
      d="M14.818 5L2.805 8.221a1.086 1.086 0 0 0-.768 1.328l5.275 19.698a1.086 1.086 0 0 0 1.33.766l15.215-4.807"
      fill={secondaryColor}
    />
    <path
      d="M14.818 5L2.805 8.221a1.086 1.086 0 0 0-.768 1.328l5.275 19.698a1.086 1.086 0 0 0 1.33.766l15.215-4.807"
      stroke={tertiaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.646 3.104a1.086 1.086 0 0 1 1.309-.8L29.808 5.62a1.086 1.086 0 0 1 .803 1.307L25.858 26.76a1.086 1.086 0 0 1-1.31.801l-13.853-3.317a1.086 1.086 0 0 1-.803-1.307l4.754-19.832z"
      fill={primaryColor}
      stroke={tertiaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCards;
