import { rgba, rem } from 'polished';
import React from 'react';
import { css } from 'styled-components';
import { styled } from 'styles';
import { small, medium } from '../../utils';
import BodyText from '../Typography/BodyText';

const tooltipWidth = 66;

const TooltipContainer = styled.div<{ left: number }>`
  background-color: ${({ theme }) => rgba(theme.colors.background.inverse, 1)};
  border-radius: ${rem(4)};
  height: ${rem(55)};
  width: ${rem(tooltipWidth)};
  position: absolute;
  left: calc(${props => props.left}px - ${rem(tooltipWidth)} / 2);
  bottom: ${rem(42)};
  ${small(css`
    bottom: ${rem(62)};
  `)}
  ${medium(css`
    bottom: ${rem(80)};
  `)}
`;

const ToolTipText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.staticPalette.white,
}))`
  text-align: center;
  padding-top: ${rem(17)};
  position: relative;
`;

const TooltipArrow = styled.div`
  position: absolute;
  top: ${rem(45)};
  width: ${rem(16)};
  height: ${rem(16)};
  left: ${rem(26)};
  background-color: ${({ theme }) => rgba(theme.colors.background.inverse, 1)};
  transform: rotate(45deg);
`;
interface ProgressBarTooltipProps {
  xPosition: number;
  seekTime: string;
}

const ProgressBarTooltip = ({
  xPosition,
  seekTime,
}: ProgressBarTooltipProps) => (
  <TooltipContainer data-testid="progress-bar-tooltip" left={xPosition}>
    <ToolTipText role="tooltip">{seekTime}</ToolTipText>
    <TooltipArrow />
  </TooltipContainer>
);

export default ProgressBarTooltip;
