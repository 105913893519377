import { useClickOutside } from '@unmind/design-system-components-web';
import { ThemeInterface } from '@unmind/design-system-theme';
import React, { MouseEventHandler, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { IndicatorProps, MenuProps } from 'react-select';
import { MultiSelect } from 'Shared/Form/MultiSelect';
import { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { ScreenReaderContent } from '../../../Shared/Accessibility';
import { useKeyDown } from '../../../utils/useKeyDown';
import {
  FilterActionMeta,
  FilterOptionCollection,
  FilterOptionType,
} from '../BrowseFilterPanel/types';
import {
  TalkFilterMenu,
  TalkFilterOption,
  TalkFilterPlaceholder,
  TalkFilterDropdownIndicator,
} from './BrowseFilterComponents';
import { DropdownContainer } from './styles';

interface TalkFilterDropdownProps {
  options: FilterOptionType[];
  placeholder: string;
  value: FilterOptionType[];
  description?: string;
  onChange(value: FilterOptionCollection, actionMeta?: FilterActionMeta): void;
  onClear(): void;
}

export const FilterDropdown = React.memo(
  ({
    options,
    placeholder,
    value,
    description,
    onChange,
    onClear,
  }: TalkFilterDropdownProps) => {
    const { t } = useTranslation<Namespace<'talk'>>('talk');
    const theme: ThemeInterface = useContext(ThemeContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openMenu = () => {
      setIsOpen(true);
    };

    const closeMenu = () => {
      setIsOpen(false);
    };

    useClickOutside(containerRef, () => {
      if (isOpen) {
        closeMenu();
      }
    });

    useKeyDown(['Tab', 'Escape'], () => {
      closeMenu();
    });

    /**
     * Quick fix to allow for menu close on click on control
     * Needed so long as menuIsOpen is controlled by focus
     * TabIndex is set so this is ignored by keyboard nav
     */
    const handleContainerClick: MouseEventHandler<HTMLDivElement> = () => {
      if (isOpen) {
        closeMenu();
        containerRef.current?.focus();
      } else {
        openMenu();
      }
    };

    // memoise the select components to prevent re-renders on every change
    const memoisedSelectComponents = React.useMemo(
      () => ({
        Option: TalkFilterOption,
        Placeholder: TalkFilterPlaceholder,
        Menu: (props: MenuProps<FilterOptionType, true>) => (
          <TalkFilterMenu
            {...props}
            description={description}
            onDone={closeMenu}
          />
        ),
        DropdownIndicator: (props: IndicatorProps<FilterOptionType, true>) => (
          <TalkFilterDropdownIndicator {...props} isOpen={isOpen} />
        ),
        IndicatorSeparator: null,
      }),
      [description, isOpen],
    );

    return (
      <DropdownContainer ref={containerRef} onMouseDown={handleContainerClick}>
        <MultiSelect
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          selectProps={{
            closeMenuOnSelect: false,
            hideSelectedOptions: false,
            controlShouldRenderValue: false,
            tabSelectsValue: false,
            isClearable: false,
            isSearchable: false,
            menuIsOpen: isOpen,
            onFocus: openMenu,
            components: memoisedSelectComponents,
            styles: {
              option: (provided, state) => ({
                ...provided,
                ':hover': {
                  backgroundColor: theme.colors.background.secondary,
                },
                ':active': {
                  backgroundColor: theme.colors.background.card,
                },
                backgroundColor: state.isFocused
                  ? theme.colors.background.secondary
                  : 'transparent',
              }),
              valueContainer: provided => ({
                ...provided,
                'padding-left': 0,
              }),
              indicatorsContainer: provided => ({
                ...provided,
                'padding-right': 0,
                div: {
                  padding: 0,
                },
              }),
              control: provided => ({
                ...provided,
                'padding-left': rem(16),
                'padding-right': rem(16),
              }),
              dropdownIndicator: provided => ({
                ...provided,
                'pointer-events': 'none',
              }),
            },
            'aria-label': description,
          }}
        />
        {/* Our custom `TalkFilterMenu` component includes buttons for clearing and closing the menu,
        but since they are rendered within the combobox the currently active document element is not moved away from the options
        when navigating to the buttons. The result is that the screen reader announces focus on the button,
        but activating it actually activates the most recently selected option instead.
        To get round this, the buttons within the menu are hidden from screen readers and these visually-hidden buttons are rendered
        to provide the same functionality. */}
        <ScreenReaderContent as="div">
          <button onClick={onClear} disabled={!value.length}>
            {t('browse_practitioners.filter_panel.clear')}
          </button>
          <button onClick={closeMenu} tabIndex={isOpen ? 0 : -1}>
            {t('browse_practitioners.filter_panel.done')}
          </button>
        </ScreenReaderContent>
      </DropdownContainer>
    );
  },
);
