import { PlayCircular } from 'icons';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { css } from 'styles';
import { small } from 'utils';

const PlayButton = styled(PlayCircular).attrs(({ theme }) => ({
  width: 44,
  height: 44,
  primaryColor: theme.button.primary.default.backgroundColor,
  secondaryColor: theme.button.primary.default.textColor,
}))`
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TileContainer = styled.div<{ hasChildren: boolean }>`
  overflow: hidden;
  opacity: 1;
  position: relative;
  cursor: pointer;
  border-radius: ${({ hasChildren }) =>
    hasChildren ? '30px 30px 0 0' : '30px'};

  &:hover ${PlayButton} {
    visibility: visible;
  }
  flex: ${({ hasChildren }) => (hasChildren ? '2' : '0')};
`;

const TileImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.4s linear;
  scale: 1.03;

  ${TileContainer}:hover & {
    transform: scale(1.05);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${rem(30)};
  margin-bottom: ${rem(10)};

  ${small(css`
    margin-right: ${rem(25)};
  `)};
`;

const ChildrenContainer = styled.div`
  flex: 1;
  padding: ${rem(25)};
`;
interface ContentTileProps {
  src: string;
  onClick(): void;
  children?: ReactNode;
  title?: string;
}
export const ContentTile = ({
  src,
  onClick,
  children,
  title,
}: ContentTileProps) => {
  const { t: translate } = useTranslation('logged_out');

  const accessibilityLabel: string = title
    ? translate('ms_teams_alternative_start.player_accessibility_label', {
        title: title,
      })
    : translate('ms_teams_alternative_start.player_accessibility_label');

  return (
    <Container>
      <TileContainer
        aria-label={accessibilityLabel}
        role="button"
        tabIndex={0}
        onClick={onClick}
        hasChildren={children ? true : false}
      >
        <TileImage src={src} aria-hidden />
        <PlayButton />
      </TileContainer>
      {children ? <ChildrenContainer>{children}</ChildrenContainer> : null}
    </Container>
  );
};
