import React from 'react';
import { IconProps } from '../IconProps';
const SvgCircularClipboard = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z"
      fill="#F8E5C0"
    />
    <path
      d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z"
      stroke="#EA9F0E"
    />
    <path
      d="M22.296 10.05h-4.592a1.53 1.53 0 00-1.53 1.531v.766c0 .845.685 1.53 1.53 1.53h4.592a1.53 1.53 0 001.53-1.53v-.766a1.53 1.53 0 00-1.53-1.53z"
      stroke="#EA9F0E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.21 11.581h2.295a1.53 1.53 0 011.53 1.53v15.307a1.53 1.53 0 01-1.53 1.53h-13.01a1.53 1.53 0 01-1.53-1.53V13.112a1.53 1.53 0 011.53-1.53h2.296m.382 9.948h7.653m-7.653 3.826h7.653"
      stroke="#EA9F0E"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCircularClipboard;
