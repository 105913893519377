import React from 'react';
import {
  EmptyContainer,
  EmptySubtitle,
  EmptyTitle,
  EmptyTitleContainer,
  MagIcon,
} from './styles';

type EmptyStateCardProps = {
  title: string;
  subtitle: string;
};

const EmptyStateCard = ({ title, subtitle }: EmptyStateCardProps) => (
  <EmptyContainer>
    <EmptyTitleContainer>
      <MagIcon />
      <EmptyTitle> {title} </EmptyTitle>
    </EmptyTitleContainer>
    <EmptySubtitle> {subtitle} </EmptySubtitle>
  </EmptyContainer>
);

export default EmptyStateCard;
