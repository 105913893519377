import React, { useState } from 'react';
import Modal from 'Shared/Modal';
import { ChatBubbleTypingSquare, ShieldCheck, Visible } from 'icons';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ModalContentWrapper,
  ModalTitle,
  TipDescription,
  TipIcon,
  TipTitle,
  TipTitleWrapper,
} from './styles';

export const TIPS_LOCAL_STORAGE_KEY = 'Assistant.hasSeenChatbotTips';

export const TipsModal = () => {
  const { t: translate } = useTranslation('chatbot');
  const [shouldShowTipsModal, setShouldShowTipsModal] = useState(
    !localStorage.getItem(TIPS_LOCAL_STORAGE_KEY),
  );

  const tips = [
    {
      icon: ChatBubbleTypingSquare,
      title: translate('tips_modal.tip_1.title'),
      description: translate('tips_modal.tip_1.description'),
    },
    {
      icon: ShieldCheck,
      title: translate('tips_modal.tip_2.title'),
      description: translate('tips_modal.tip_2.description'),
    },
    {
      icon: Visible,
      title: translate('tips_modal.tip_3.title'),
      description: translate('tips_modal.tip_3.description'),
    },
  ];

  const handleModalClose = () => {
    localStorage.setItem(TIPS_LOCAL_STORAGE_KEY, 'true');
    setShouldShowTipsModal(false);
  };

  return (
    <Modal
      variant="overnavigation"
      open={shouldShowTipsModal}
      size="small"
      onCloseCallback={handleModalClose}
      modalContent={({ close }) => (
        <ModalContentWrapper>
          <ModalTitle>{translate('tips_modal.title')}</ModalTitle>
          {tips.map((tip, index) => (
            <div key={index}>
              <TipTitleWrapper>
                <TipIcon Icon={tip.icon} />
                <TipTitle>{tip.title}</TipTitle>
              </TipTitleWrapper>
              <TipDescription>{tip.description}</TipDescription>
            </div>
          ))}
          <ActionButton
            label={translate('tips_modal.action_button.label')}
            onClick={() => {
              handleModalClose();
              close();
            }}
          />
        </ModalContentWrapper>
      )}
    />
  );
};
