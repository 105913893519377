import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { compose, flattenProp, mapProps } from 'recompose';
import uuid from 'uuid';
import { getToolSummaryFromHtml } from 'utils/getToolSummaryFromHtml';
import RoutePath from '../App/RoutePath';
import { tracking } from '../App/Tracking';
import { getSentAtTimestamp } from '../App/Tracking/serverside';
import {
  getBreadcrumbStateInfo,
  getToolPlayerOnCloseInfo,
} from '../App/routeHelpers';
import withLoadingIndicator from '../Shared/LoadingIndicator/withLoadingIndicator';
import { TranscriptTakeover } from '../Shared/MediaPlayerComponents/TranscriptTakeover';
import useHandleEscapeKeyPress from '../Shared/MediaPlayerComponents/useHandleEscapeKeyPress';
import {
  TimerDispatch,
  TimerProvider,
  withTimerDispatch,
} from '../Shared/Timer';
import Transcript from '../Shared/Transcript';
import { DAILY_BOOST_CATEGORY_SLUG } from '../Shorts/constants';
import { getMediaSrc } from '../utils';
import { redirectHelper } from '../utils/redirectHelper';
import ToolMediaPlayer from './ToolMediaPlayer';
import { LogToolActivity, withLogToolActivity } from './withLogToolActivity';
import { ToolWithMedia, withToolScreen } from './withToolScreen';
import { LogToolActivityVariables } from './__generated__/LogToolActivity';
import isAudioTool from './isAudioTool';

export interface ToolTrackingProps {
  toolId: string;
  toolActivityEvent?: LogToolActivityVariables['toolActivityEvent'];
  toolSlug: string;
  exploreCategoryId?: string | null;
  exploreCategorySlug?: string | null;
  exploreCategoryPath?: string | null;
  toolLength?: number | null;
  isFavourite: boolean | null;
  source?: string;
}

export interface ToolProps {
  tool: Required<ToolWithMedia>;
  timerDispatch: TimerDispatch;
  logToolActivity: LogToolActivity;
  getAssetToken: { assetToken: string };
}

export const Tool = ({
  tool,
  timerDispatch,
  logToolActivity,
  getAssetToken: { assetToken },
}: ToolProps) => {
  const { id, mediaSrc, mediaAsset, exploreCategory, estimatedMins, html } =
    tool;

  const { t: translate } = useTranslation('media_player');
  const { tool: toolSlug } = useParams<{
    tool: string;
  }>();
  const [mediaUrl, setMediaUrl] = useState<string>(
    getMediaSrc({
      cloudinaryMedia: mediaAsset,
      assetToken,
      contentId: id,
      legacyMediaUrl: mediaSrc.value,
    }),
  );
  const [showTranscript, setShowTranscript] = useState(false);
  // eslint-disable-next-line @typescript-eslint/tslint/config
  const [mediaSessionId] = useState(() => uuid());

  const location = useLocation<Location>();
  const pathname = location.pathname;
  const history = useHistory();

  // we use a ref here so the up to date value is always passed into a callback,
  // instead of stale state
  const numberOfReplaysRef = useRef(0);

  useEffect(() => {
    if (tool) {
      setMediaUrl(
        getMediaSrc({
          cloudinaryMedia: mediaAsset,
          assetToken,
          contentId: id,
          legacyMediaUrl: mediaSrc.value,
        }),
      );
    }
  }, [tool, assetToken, mediaAsset, id, mediaSrc.value]);

  const isDailyBoost =
    location.pathname.split('/')[2] === DAILY_BOOST_CATEGORY_SLUG;

  const customCloseLocation = getToolPlayerOnCloseInfo(location);
  const toolTrackingProps: ToolTrackingProps = {
    toolId: id,
    toolSlug,
    exploreCategoryId: exploreCategory?.id,
    exploreCategorySlug: exploreCategory?.slug,
    exploreCategoryPath: exploreCategory?.internalName,
    toolLength: estimatedMins,
    isFavourite: tool.isFavourite,
  };

  const closeTranscript = () => {
    setShowTranscript(false);

    tracking.track('media-transcript-closed', {
      feature: 'tool',
      medium: tool.mediaType.value,
      mediaSessionId,
      source: 'media-player',
    });
  };

  const onCloseButtonClick = () => {
    tracking.track('tool-closed', toolTrackingProps);
    const stateBreadcrumbs = getBreadcrumbStateInfo(location);
    const closeLocation = customCloseLocation
      ? {
          ...customCloseLocation.location,
          ...(stateBreadcrumbs ? { state: stateBreadcrumbs } : {}),
        }
      : {
          pathname: `${RoutePath.Explore}`,
          state: stateBreadcrumbs,
        };
    history.push(closeLocation);
  };

  useHandleEscapeKeyPress(() => {
    if (showTranscript) {
      closeTranscript();
    }
  });

  const logToolActivityOnStop = async ({
    total,
    end,
  }: {
    total: number;
    end?: boolean;
  }) => {
    if (total > 0) {
      await logToolActivity({
        variables: {
          seconds: total,
          toolId: tool.id,
          categoryId: null,
          toolActivityEvent: end
            ? ('TOOL_COMPLETED' as LogToolActivityVariables['toolActivityEvent'])
            : ('TOOL_PLAYED' as LogToolActivityVariables['toolActivityEvent']),
          exploreCategoryId:
            !isDailyBoost && exploreCategory ? exploreCategory.id : null,
          isDailyBoost,
          toolSessionId: mediaSessionId,
          replays: numberOfReplaysRef.current,
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        },
      });
    }
  };

  const handleWindowUnload = () => {
    timerDispatch.stop(logToolActivityOnStop);
  };

  // Save media player interaction time on unmount / leave page
  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowUnload);

    return () => {
      handleWindowUnload();
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  });

  if (pathname.includes(RoutePath.Tools)) {
    return redirectHelper(`${RoutePath.Shorts}/${toolSlug}`, isDailyBoost);
  }

  const onEnded = () => {
    tracking.track('tool-track-end-reached', toolTrackingProps);
    timerDispatch.stop(({ total }) => {
      logToolActivityOnStop({ total, end: true });
    });
  };

  const onClickPlay = () => {
    tracking.track('tool-play-clicked', toolTrackingProps);
  };

  const onClickPause = () => {
    tracking.track('tool-pause-clicked', toolTrackingProps);
  };

  const incrementNumberOfReplays = () => {
    numberOfReplaysRef.current = numberOfReplaysRef.current + 1;
  };

  const onClickReplay = () => {
    incrementNumberOfReplays();
    tracking.track('tool-replay-clicked', toolTrackingProps);
  };

  const onMediaHasLooped = () => {
    incrementNumberOfReplays();
  };

  const onTranscriptClick = ({
    totalLength,
    position,
  }: {
    totalLength: number;
    position: number;
  }): void => {
    tracking.track('media-transcript-opened', {
      feature: 'tool',
      medium: tool.mediaType.value,
      position,
      totalLength,
      mediaSessionId,
      source: 'media-player',
    });
    tracking.track('tool-transcript-switch-clicked', toolTrackingProps);
    setShowTranscript(true);
  };

  const closeButtonLabel = customCloseLocation
    ? customCloseLocation.ariaLabel
    : translate('back_button.label');

  return (
    <>
      <ToolMediaPlayer
        closeButtonLabel={closeButtonLabel}
        isOpen
        onClose={onCloseButtonClick}
        mediaSrc={mediaUrl}
        mediaType={isAudioTool(tool) ? 'audio' : 'video'}
        timerDispatch={timerDispatch}
        title={tool.title as string}
        summary={getToolSummaryFromHtml(html)}
        slug={toolSlug}
        hasTranscript={!!tool.transcription && !!tool.transcription.value}
        mediaSessionId={mediaSessionId}
        onClickPlay={onClickPlay}
        onClickPause={onClickPause}
        onClickReplay={onClickReplay}
        onTranscriptClick={onTranscriptClick}
        onEnded={onEnded}
        onMediaHasLooped={onMediaHasLooped}
        reclaimFocus={!showTranscript}
        isTranscriptShown={showTranscript}
      />
      {!!tool.transcription && !!tool.transcription.value && (
        <TranscriptTakeover show={showTranscript}>
          <Transcript
            title={tool.title as string}
            transcript={tool.transcription.value}
            timerDispatch={timerDispatch}
            onCloseButtonClick={closeTranscript}
          />
        </TranscriptTakeover>
      )}
    </>
  );
};

export default compose<ToolProps, RouteComponentProps>(
  mapProps(
    ({
      match: {
        params: { tool },
      },
    }: RouteComponentProps<{ tool: string }>) => ({
      toolSlug: tool,
    }),
  ),
  withToolScreen,
  flattenProp('data'),
  withLoadingIndicator,
  (Component: React.ComponentClass<ToolProps>) => (props: ToolProps) =>
    (
      <TimerProvider>
        <Component {...props} />
      </TimerProvider>
    ),
  withLogToolActivity,
  withTimerDispatch,
)(Tool);
