// A safety net in case the parent screen unmounts and remounts while
// the condition for rendering TalkOnboardingModal is still truthy,
// as we would not want to show the modal again in this scenario
let userHasSeenTalkOnboardingModal = false;

export function setUserHasSeenTalkOnboardingModal(value: boolean) {
  userHasSeenTalkOnboardingModal = value;
}

export function getUserHasSeenTalkOnboardingModal() {
  return userHasSeenTalkOnboardingModal;
}
