import React from 'react';
import { IconProps } from '../IconProps';
const SvgRepeat = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g
      clipPath="url(#Repeat_svg__clip0_928_128469)"
      stroke="#19100D"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.333 7.667v-4A.667.667 0 0013.667 3H.333" />
      <path d="M3 5.666L.333 2.999 3 .333M1.667 8.334v4a.667.667 0 00.666.666h13.334" />
      <path d="M13 10.332L15.667 13 13 15.666" />
    </g>
    <defs>
      <clipPath id="Repeat_svg__clip0_928_128469">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRepeat;
