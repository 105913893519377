import { Field, FieldProps } from 'formik';
import React from 'react';
import { Select, SelectProps } from '../Select';
import getError from './getError';

const FormikSelect = ({
  name,
  onChange,
  ...props
}: { name: string; onChange?(value: string | Date): void } & SelectProps) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <Select
        {...field}
        {...props}
        error={getError(name, form)}
        onChange={value => {
          if (onChange) {
            return onChange(value);
          }
          form.setFieldValue(name, value);
        }}
      />
    )}
  </Field>
);

export default FormikSelect;
