import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'Shared/Form/Formik';
import { AccessibilityLabel, AddToCalendarField, RepeatIcon } from './styles';
import { SelectContainer } from './TimePickerDropdown';

export enum Cadence {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Once = 'ONCE',
  Daily = 'DAILY',
}

interface CadenceDropdownProps {
  value: Cadence;
  onChange(field: string, value: Cadence): void;
}

const getDisplayText = (cadence: Cadence): string =>
  `add_to_calendar.cadence_dropdown.display_text.${cadence.toLowerCase()}`;

export const CadenceDropdown = ({ value, onChange }: CadenceDropdownProps) => {
  const { t: translate } = useTranslation('shared');
  const options = [
    Cadence.Once,
    Cadence.Daily,
    Cadence.Weekly,
    Cadence.Monthly,
  ];

  return (
    <AddToCalendarField>
      <RepeatIcon />
      <SelectContainer>
        <FormikSelect
          // there is no label as there is always a default value however we need this to add the accessibility information
          LabelComponent={a11yProps => (
            <AccessibilityLabel {...a11yProps}>{''}</AccessibilityLabel>
          )}
          aria-label={translate(
            'add_to_calendar.cadence_dropdown.accessibility_label',
            { value },
          )}
          placeholder={translate(
            'add_to_calendar.cadence_dropdown.placeholder',
          )}
          options={options.map(option => ({
            // @ts-ignore
            label: translate(getDisplayText(option)),
            value: option,
          }))}
          value={value}
          onChange={selection => onChange('cadence', selection as Cadence)}
          name={'cadence'}
        />
      </SelectContainer>
    </AddToCalendarField>
  );
};
