import React from 'react';
import { useTheme } from 'styles';
import { ButtonThemeStyleProps } from 'styles/types/buttons';
import BaseButton, { BaseButtonProps } from '../Button';

type Variant = 'onLightBg' | 'onDarkBg';

export interface PrimaryButtonProps extends BaseButtonProps {
  variant?: Variant;
}

export const PrimaryButton = ({
  'aria-disabled': ariaDisabled,
  disabled,
  variant = 'onLightBg',
  ...props
}: PrimaryButtonProps) => {
  const theme = useTheme();
  let styleProps: ButtonThemeStyleProps = theme.button.primary.default;

  if (variant === 'onDarkBg') {
    const darkStyles = theme.button.primary.onDark;
    styleProps = disabled ? darkStyles.disabled : darkStyles.default;
  } else if (disabled || ariaDisabled) {
    styleProps = theme.button.primary.disabled;
  }

  return (
    <BaseButton
      disabled={disabled}
      aria-disabled={ariaDisabled}
      {...props}
      styleProps={styleProps}
    />
  );
};

export default PrimaryButton;
