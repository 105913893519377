import { isApolloError } from '@apollo/client';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Errors, getError } from '../../Shared/Errors';

export interface WithConfirmEmailUpdateResponse {
  success: boolean;
  error: Errors;
}

export interface EmailVariables {
  emailToken: string;
}

export interface WithConfirmEmailUpdateProps {
  confirmEmailUpdate(
    variables: EmailVariables,
  ): Promise<Partial<WithConfirmEmailUpdateResponse>>;
}

export const withConfirmEmailUpdateMutation = gql`
  mutation confirmEmailUpdate($emailToken: String!) {
    confirmEmailUpdate(emailToken: $emailToken)
  }
`;

export const withConfirmEmailUpdate = graphql<
  Record<string, never>,
  Record<string, never>,
  EmailVariables,
  WithConfirmEmailUpdateProps
>(withConfirmEmailUpdateMutation, {
  props: ({ mutate }) => ({
    confirmEmailUpdate: async variables => {
      try {
        if (mutate) {
          const response = await mutate({ variables });

          if (typeof response === 'object' && response.data !== undefined) {
            return {
              success: true,
            };
          }
        }
      } catch (error) {
        if (error instanceof Error && isApolloError(error)) {
          if (error.graphQLErrors.length > 0) {
            const [{ name: graphQLErrorName }] = error.graphQLErrors;
            const mappedError = getError(graphQLErrorName);

            return {
              error: Boolean(mappedError) ? mappedError : Errors.ServerError,
            };
          }
        }
      }

      return {
        error: Errors.ServerError,
      };
    },
  }),
});
