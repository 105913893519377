import { css, styled, ThemeInterface } from 'styles';
import { rem } from 'polished';

import { FullHeightContent } from 'Navigation/AuthenticatedPage';
import { small } from 'utils';

import { default as BaseContainer } from 'Shared/Container';
import { Accordion, HeadingText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { HeaderSlice } from '../common/styles';

export const Page = styled(FullHeightContent)`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.background.primary};
  display: flex;
  flex-direction: column;
`;

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;

  margin-top: ${rem(40)};
  margin-bottom: ${rem(32)};

  ${small(css`
    margin-bottom: ${rem(40)};
    margin-top: ${rem(48)};
  `)};
`;

export const LandingPageBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LocationFormContainer = styled.div`
  max-width: ${rem(412)};
  margin: 32px auto 32px;
`;

export const MoreAboutTalkHeader = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.primary,
  level: 3,
}))`
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  margin-bottom: ${rem(16)};
  text-align: center;
`;

export const CollapsingAccordionContainer = styled.div`
  max-width: ${rem(412)};
  width: inherit;
  margin: ${rem(8)} auto;
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(32)};
`;

export const AccordionItemHeader = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.primary,
  level: 4,
}))`
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

export const StyledAccordion = styled(Accordion)`
  border: none;
  background-color: transparent;

  div {
    background-color: transparent;
  }

  & > div > button > svg {
    margin-left: ${rem(16)};
    margin-right: 0;
  }

  div > span {
    background-color: transparent;
    padding: 0 ${rem(16)};
  }

  div > button {
    border: none;
    background-color: transparent;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: ${rem(12)} ${rem(16)};
    cursor: pointer;
  }

  & > div[data-state='open'] {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border-bottom-right-radius: ${rem(8)};
    border-bottom-left-radius: ${rem(8)};

    button {
      padding-bottom: 0;
    }

    span:last-child {
      padding-bottom: ${rem(12)};
    }
  }

  div[data-state='open']:first-child,
  div[data-state='closed'] + div[data-state='open'] {
    border-top-right-radius: ${rem(8)};
    border-top-left-radius: ${rem(8)};
  }

  div[data-state='open']:has(+ div[data-state='open']) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const HeaderTitleSlice = styled(HeaderSlice)`
  justify-content: start;
`;
