import moment from 'moment';
import { rem } from 'polished';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface, useTheme } from 'styles';
import Pill from '../../../Shared/Pill';
import { CheckInScores } from '../__generated__/CheckInScores';
import BodyText from '../../../Shared/Typography/BodyText';
import MoodScoreMoodFace from './MoodScoreMoodFace';
import { filterCheckInFeelingsData } from './helpers/filterCheckInData';

const MoodScoreDate = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [theme.typography.fontSizes.fontSize12],
  }),
)`
  margin-left: ${rem(8)};
`;

export const Note = styled(BodyText).attrs(({ theme }) => ({
  'data-testid': 'check-in-note',
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-top: ${rem(12)};
  overflow-wrap: break-word;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const PillWrapper = styled.div`
  display: inline-block;
  margin-top: ${rem(8)};
  margin-right: ${rem(8)};
`;

const MoodScoreEntry = ({
  moodId,
  mood,
  dateCreated,
  note,
  feelings,
}: CheckInScores) => {
  const { colors } = useTheme();
  const { t: translate } = useTranslation('insights');

  const checkinDate = useMemo(
    () => (
      <MoodScoreDate>
        {translate('moods.checkin_entry.datetime', {
          date: moment(dateCreated).format('D MMM YYYY'),
          time: moment(dateCreated).format('HH:mm'),
        })}
      </MoodScoreDate>
    ),
    [dateCreated, translate],
  );

  return (
    <div>
      <Header>
        {mood ? (
          <MoodScoreMoodFace
            data-testid={`mood-${moodId}`}
            mood={mood}
            height={32}
            width={32}
          />
        ) : null}
        {checkinDate}
      </Header>
      {Boolean(note) ? (
        <Note color={colors.text.primary}>{note}</Note>
      ) : (
        <Note color={colors.text.secondary}>
          {translate('moods.checkin_entry.no_note_text')}
        </Note>
      )}
      {filterCheckInFeelingsData(feelings)
        .sort((a, b) => a.name?.localeCompare(b.name as string) as number)
        .map(feeling => (
          <PillWrapper data-testid="check-in-feeling" key={feeling.id}>
            <Pill color={colors.text.secondary}>{feeling.name}</Pill>
          </PillWrapper>
        ))}
    </div>
  );
};

export default MoodScoreEntry;
