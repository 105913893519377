import { Colors } from '@unmind/design-system-theme';
import { hex, hsv } from 'color-convert';
import curry from 'lodash/curry';
import { darken } from 'polished';

type HSV = [number, number, number];

export const toHSV = curry(hex.hsv);

export const toHex = curry(hsv.hex);

export const getDarkAccessoryColor = (color: Colors) => darken(0.2, color);

export const adjustBrightness = curry(
  (delta: number, [hue, saturation, value]: HSV): HSV => [
    hue,
    saturation,
    value + delta * 100,
  ],
);

export const adjustSaturation = curry(
  (delta: number, [hue, saturation, value]: HSV): HSV => [
    hue,
    saturation + delta * 100,
    value,
  ],
);

export const setSaturation = curry(
  (newSaturation: number, [hue, , value]: HSV): HSV => [
    hue,
    newSaturation * 100,
    value,
  ],
);

export const setBrightness = curry(
  (newBrightness: number, [hue, saturation]: HSV): HSV => [
    hue,
    saturation,
    newBrightness * 100,
  ],
);

export { default as getFocusColor } from './getFocusColor';
