import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { IsVirginPulseQuery } from './__generated__/IsVirginPulseQuery';

export const IS_VIRGIN_PULSE_QUERY: DocumentNode = gql`
  query IsVirginPulseQuery($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      group {
        isVirginPulseGroup
      }
    }
  }
`;

export interface UseIsVirginPulseResponse {
  isVirginPulseGroup?: boolean;
  loading: boolean;
}

export function useIsVirginPulseGroup(
  skip: boolean = false,
): UseIsVirginPulseResponse {
  const subdomain = getSubdomainFromUrl();

  const { data, loading } = useQuery<IsVirginPulseQuery>(
    IS_VIRGIN_PULSE_QUERY,
    {
      variables: {
        subdomain,
      },
      skip,
      fetchPolicy: 'cache-first',
    },
  );

  const isVirginPulseGroup = data?.subDomain?.[0]?.group?.isVirginPulseGroup;

  return {
    isVirginPulseGroup:
      isVirginPulseGroup === null ? undefined : isVirginPulseGroup,
    loading,
  };
}
