import gql from 'graphql-tag';

/*
 *  Fragments
 */
export const CONTAINER_SHORT_FRAGMENT = gql`
  fragment containerShortFragment on Tool {
    id
    title
    slug
    isFavourite
    summary
    html
    estimatedMins
    cloudinaryThumbnail {
      path
    }
    mediaType {
      value
    }
    mediaSrc {
      value
    }
    mediaAsset {
      path
    }
    exploreCategory {
      id
      internalName
    }
  }
`;

/*
 * Queries
 */
export const SHORTS_CONTAINER_QUERY = gql`
  ${CONTAINER_SHORT_FRAGMENT}
  query shortsContainerQuery($slug: String!) {
    getShortsByContainerSlug(slug: $slug) {
      ...containerShortFragment
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;
