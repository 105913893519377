export const INITIAL_FORM_VALUES = {
  gad: [],
  phq: [],
  workplace: [],
};

export enum QuestionnaireStateType {
  gad = 'GAD_QUESTION_RESPONSE',
  phq = 'PHQ_QUESTION_RESPONSE',
  workplace = 'WORKPLACE_QUESTION_RESPONSE',
  resetQuestionnaire = 'RESET_QUESTIONNAIRE',
}

export enum QuestionnaireType {
  gad = 'gad',
  phq = 'phq',
  workplace = 'workplace',
}

type QuestionResponsePayload = {
  questionId: string;
  answer: number;
};

type QuestionnaireAction =
  | { type: QuestionnaireStateType.gad; payload: QuestionResponsePayload }
  | { type: QuestionnaireStateType.phq; payload: QuestionResponsePayload }
  | {
      type: QuestionnaireStateType.workplace;
      payload: QuestionResponsePayload;
    }
  | {
      type: QuestionnaireStateType.resetQuestionnaire;
    };

type QuestionnaireState = {
  [key in QuestionnaireType]: QuestionResponsePayload[];
};

const setQuestionResponse = (
  state: QuestionnaireState,
  questionnaireType: QuestionnaireType,
  payload: QuestionResponsePayload,
) => {
  const responses = state[questionnaireType];
  const responseExistsForQuestion = responses.some(
    response => response.questionId === payload.questionId,
  );

  if (responseExistsForQuestion) {
    const updatedResponses = responses.map(response => {
      if (response.questionId === payload.questionId) {
        return {
          ...response,
          answer: payload.answer,
        };
      }

      return response;
    });

    return {
      ...state,
      [questionnaireType]: updatedResponses,
    };
  }

  const updatedResponses = [
    ...responses,
    {
      questionId: payload.questionId,
      answer: payload.answer,
    },
  ];

  return {
    ...state,
    [questionnaireType]: updatedResponses,
  };
};

export const responseReducer = (
  state: QuestionnaireState,
  action: QuestionnaireAction,
) => {
  switch (action.type) {
    case QuestionnaireStateType[QuestionnaireType.gad]:
      return setQuestionResponse(state, QuestionnaireType.gad, action.payload);
    case QuestionnaireStateType[QuestionnaireType.phq]:
      return setQuestionResponse(state, QuestionnaireType.phq, action.payload);
    case QuestionnaireStateType[QuestionnaireType.workplace]:
      return setQuestionResponse(
        state,
        QuestionnaireType.workplace,
        action.payload,
      );
    case QuestionnaireStateType.resetQuestionnaire:
      return {
        ...state,
        ...INITIAL_FORM_VALUES,
      };
    default:
      return state;
  }
};
