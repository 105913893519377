import { useState, useEffect } from 'react';
import { getCookie } from './storageHelpers';
import { isMSTeams } from './MSTeams';

export enum StorageKey {
  EMAIL = 'email',
}

export const useStorage = (key: StorageKey) => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    let storedValue = null;
    // teams cookies are stored in local storage due to the iframe restrictions.
    if (isMSTeams()) {
      storedValue = localStorage.getItem(key);
    } else {
      storedValue = getCookie(key);
    }
    if (storedValue) {
      setValue(storedValue);
    }
  }, [key]);

  return value;
};
