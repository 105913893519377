import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { BodyText, HeadingText } from '../../../Shared/Typography';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0px 30px;
`;

const TextContainer = styled.div`
  max-width: 450px;
`;

const NoResultsText = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
  level: 2,
}))`
  text-align: center;
  padding: 0px 10px;
  line-height: 28px;
  margin-bottom: 12px;
`;

const NoResultsSubText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  text-align: center;
`;

const LinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.link,
  forwardedAs: 'a',
}))`
  display: contents;
  cursor: pointer;
`;

interface NoResultsViewProps {
  searchTerm: string;
  runSearchQuery(text: string): void;
}

const NoResultsView = ({ searchTerm, runSearchQuery }: NoResultsViewProps) => {
  const { t: translate } = useTranslation('explore');

  return (
    <ViewContainer>
      <TextContainer>
        <NoResultsText>
          {translate('search.no_results_view.title')} "{searchTerm}"
        </NoResultsText>
        <NoResultsSubText>
          {translate('search.no_results_view.subtitle')} "
          <LinkText
            onClick={() =>
              runSearchQuery(translate('search.no_results_view.anxiety'))
            }
          >
            {translate('search.no_results_view.anxiety')}
          </LinkText>
          " {translate('search.no_results_view.and')} “
          <LinkText
            onClick={() =>
              runSearchQuery(translate('search.no_results_view.sleep'))
            }
          >
            {translate('search.no_results_view.sleep')}
          </LinkText>
          ”
        </NoResultsSubText>
      </TextContainer>
    </ViewContainer>
  );
};

export default NoResultsView;
