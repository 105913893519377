import times from 'lodash/times';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { Success } from 'icons';

export interface ProgressIndicatorProps {
  totalItems: number;
  completedItems: number;
}

const Container = styled.div`
  position: relative;
  height: ${rem(33)};
`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: ${rem(33)};
`;

export const SingleIndicator = styled.div`
  width: ${rem(23.8)};
  height: ${rem(23.4)};
  border-radius: 50%;
  border: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  background: ${({ theme }) => theme.colors.background.primary};
  margin: ${rem(5)};
  top: 50%;
  position: relative;
  margin-top: ${rem(-12.6)};
`;

const SingleIndicatorCenter = styled.div`
  width: ${rem(9.8)};
  height: ${rem(9.8)};
  border-radius: 50%;
  margin: auto;
  top: 50%;
  margin-top: ${rem(-4.9)};
  position: relative;
  background: ${({ theme }) => theme.colors.background.secondary};
`;

const Line = styled.div`
  position: relative;
  top: calc(50% + ${rem(1)});
  height: ${rem(2)};
  margin: ${rem(-1)} auto 0 auto;
  width: 95%;
  background: ${({ theme }) => theme.colors.background.secondary};
`;

export const Checkmark = styled(Success).attrs(({ theme }) => ({
  primaryColor: theme.colors.border.success,
  secondaryColor: theme.colors.staticPalette.white,
}))`
  width: ${rem(20)};
  height: ${rem(20)};
  position: relative;
  top: 50%;
  margin-top: ${rem(-10)};
`;

export default class ProgressIndicator extends React.Component<ProgressIndicatorProps> {
  render() {
    return (
      <Container>
        <Line />
        <DotContainer>
          {times(this.props.totalItems, idx =>
            idx < this.props.completedItems ? (
              <Checkmark key={idx} data-testid="progress-indicator-checkmark" />
            ) : (
              <SingleIndicator
                key={idx}
                data-testid="progress-indicator-single-indicator"
              >
                <SingleIndicatorCenter />
              </SingleIndicator>
            ),
          )}
        </DotContainer>
      </Container>
    );
  }
}
