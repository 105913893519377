import { useQuery } from '@apollo/client';
import {
  BodyText,
  Button,
  HeadingText,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { practitionerMatchesQuery_practitionerMatches } from 'Services/BeGateway/Practitioner/__generated__/practitionerMatchesQuery';
import {
  practitionersQuery_practitioners_edges_node as Practitioner,
  practitionersQuery,
  practitionersQueryVariables,
} from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import { PRACTITIONERS_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import SectionError from 'Shared/SectionError';
import { SkeletonContentTile } from 'Shared/Skeleton';
import { useLocationData, useToday } from 'Talk/hooks';
import { Namespace } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { extraSmall, small } from 'utils';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { getDefaultDateRange } from '../../PractitionerDrawer/helpers/getDefaultDateRange';
import { MatchCard } from './MatchCard';
import { MatchingExplainer } from './Explainer';

const MatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 48px;
`;

const MatchesHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MatchesHeader = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
  align: 'left',
}))`
  margin-bottom: 16px;
`;

const MatchesExplainerContainer = styled.div`
  margin-bottom: 16px;
`;

const MatchesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 40px;

  > a,
  > div {
    width: 100%;

    ${extraSmall(css`
      flex: 1 0 calc(50% - 32px);
      max-width: calc(50% - 16px);
    `)}

    ${small(css`
      flex: 1 0 calc(33.3% - 32px);
    `)}
  }
`;

const BrowseCTAContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 32px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 12px;
`;

const BrowseCTAHeader = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize18],
}))``;

interface Props {
  matches: practitionerMatchesQuery_practitionerMatches[];
  cardOrientation?: 'horizontal' | 'vertical';
  trackingSource: 'home_match' | 'browse_match';
}

export function PractitionerMatches({
  matches,
  cardOrientation = 'vertical',
  trackingSource,
}: Props) {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const {
    userLocationData,
    hasValidLocationData,
    loading: userLocationDataLoading,
  } = useLocationData();
  const today = useToday();
  const defaultDateRange = getDefaultDateRange(today);
  const matchIds = matches.map(match => match?.id);
  const practitionerMatchesQueryVariables: practitionersQueryVariables = {
    first: 3,
    ...defaultDateRange,
    filter: {
      country: userLocationData?.user?.country?.value,
      state: userLocationData?.user?.state?.value ?? null,
      where: {
        id: {
          in: matchIds,
        },
      },
    },
  };

  const {
    data,
    loading: practitionerMatchesLoading,
    error,
    refetch,
  } = useQuery<practitionersQuery>(PRACTITIONERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      ...practitionerMatchesQueryVariables,
    },
    skip: userLocationDataLoading || !hasValidLocationData,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const handleBrowseClick = () =>
    tracking.track('talk-home-matching-browse-clicked', {
      userHasMatches: true,
    });

  if (practitionerMatchesLoading || userLocationDataLoading) {
    return (
      <MatchesContainer>
        <MatchesHeader>{t('matching.matches.title')}</MatchesHeader>
        <MatchesGrid>
          {[...Array(3)].map((_, i) => (
            <div key={i}>
              <SkeletonContentTile />
            </div>
          ))}
        </MatchesGrid>
      </MatchesContainer>
    );
  }

  if (error) {
    return (
      <MatchesContainer>
        <MatchesHeader>{t('matching.matches.title')}</MatchesHeader>
        <SectionError
          onRetry={async () =>
            refetch({ ...practitionerMatchesQueryVariables })
          }
        />
      </MatchesContainer>
    );
  }

  const practitionerEdges = (data?.practitioners?.edges ?? []).filter(
    (edge): edge is NonNullable<typeof edge> => edge !== null,
  );

  return (
    <MatchesContainer>
      <MatchesHeaderContainer>
        <MatchesHeader>{t('matching.matches.title')}</MatchesHeader>
        {cardOrientation === 'vertical' ? (
          <MatchesExplainerContainer>
            <MatchingExplainer />
          </MatchesExplainerContainer>
        ) : null}
      </MatchesHeaderContainer>

      {practitionerEdges?.length ? (
        <MatchesGrid>
          {practitionerEdges.map(edge => (
            <MatchCard
              match={edge.node as Practitioner}
              key={edge?.node?.id}
              orientation={cardOrientation}
              trackingSource={trackingSource}
            />
          ))}
        </MatchesGrid>
      ) : null}

      {cardOrientation === 'vertical' ? (
        <BrowseCTAContainer>
          <BrowseCTAHeader>
            {t('matching.matches.browse_cta.header')}
          </BrowseCTAHeader>
          <Button
            label={t('matching.matches.browse_cta.button.label')}
            to={RoutePath.TalkBrowse}
            onClick={handleBrowseClick}
          />
        </BrowseCTAContainer>
      ) : null}
    </MatchesContainer>
  );
}
