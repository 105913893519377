import { Distribution } from 'Compass/Components/CompassDashboard/Distribution';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { CardHeader } from '../styles';
import { CardDistributionContainer, CardText, Container } from './styles';

export interface ResponseDistribution {
  label: string;
  responseCount: number;
  responseOptionId: string;
}

export enum DistributionType {
  PERCENT = 'percent',
  RATING = 'rating',
}

export interface RelatedQuestion {
  questionText: string;
  responseDistributions: ResponseDistribution[];
  distributionType: DistributionType;
}

interface RelatedQuestionsSectionProps {
  relatedQuestions: RelatedQuestion[];
  dataTestId?: string;
}

export const RelatedQuestionsSection = ({
  relatedQuestions,
  dataTestId,
}: RelatedQuestionsSectionProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: `dashboard.cards.wellbeing`,
  });

  if (relatedQuestions.length === 0) {
    return null;
  }

  return (
    <Container data-testid={dataTestId}>
      <CardHeader>{translate('related_question_header')}</CardHeader>
      {relatedQuestions.map(question => (
        <CardDistributionContainer key={uuid()}>
          <CardText>{question.questionText}</CardText>
          <Distribution
            responseDistributions={question.responseDistributions}
            distributionType={question.distributionType}
          />
        </CardDistributionContainer>
      ))}
    </Container>
  );
};
