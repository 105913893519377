import { useEffect, useState } from 'react';

export const ASSISTANT_SUBMIT_INITIAL_MESSAGE =
  'Assistant.assistantSubmitInitialMessage';

export function useSubmitInitialMessage(): [
  boolean | null | undefined,
  (newState: boolean) => Promise<void>,
] {
  const [shouldSubmitInitialMessage, setLocalState] = useState<
    boolean | null
  >();

  useEffect(() => {
    const getSubmitInitialMessage = async () => {
      const stored = localStorage.getItem(ASSISTANT_SUBMIT_INITIAL_MESSAGE);

      if (!stored) {
        setLocalState(null);

        return;
      }

      setLocalState(stored === 'true');
    };

    void getSubmitInitialMessage();
  }, []);

  const setSubmitInitialMessage = async (newState: boolean) => {
    if (!newState) {
      localStorage.removeItem(ASSISTANT_SUBMIT_INITIAL_MESSAGE);
      setLocalState(false);
    } else {
      localStorage.setItem(
        ASSISTANT_SUBMIT_INITIAL_MESSAGE,
        newState ? 'true' : 'false',
      );
    }
  };

  return [shouldSubmitInitialMessage, setSubmitInitialMessage];
}
