import { MutationFunction, isApolloError } from '@apollo/client';
import graphqlTag from 'graphql-tag';
import isNil from 'lodash/isNil';
import { graphql } from '@apollo/client/react/hoc';
import { AuthStatus as Status } from '../../App/Auth';
import { handleCreateUserErrors } from '../../LoggedOut/SignUp/withCreateUser';
import { Errors } from '../../Shared/Errors';

export const CREATE_PLUS_ONE_USER_MUTATION = graphqlTag`
  mutation CreatePlusOneUser($input: CreatePlusOneUserInput!) {
    createPlusOneUser(input: $input) {
      id
      token
      iris_token
      email
      firstName
      lastName
      userType {
        id
        name
      }
      createdAt
      groupId
      groupName
      clientTier
      subdomain
      timezone
      praiseDirectory {
        value
      }
      departmentId
      departmentName
      locationId
      locationName
      permissions {
        value
      }
    }
  }
`;

interface CreatePlusOneUserInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  subdomain: string;
  inviteToken: string;
  timezone: string;
  explicitPrivacyConsentGiven: boolean;
  explicitEEADataStorageConsentGiven: boolean;
  marketingOptIn?: boolean;
}

export interface CreatePlusOneUserVariables {
  input: CreatePlusOneUserInput;
}

export interface CreatePlusOneUserResponse {
  activationDate: string | null;
  id: string;
  token: string;
  iris_token: string;
  email: string;
  firstName: string;
  lastName: string;
  userType: {
    id: number;
    name: string;
  };
  permissions: {
    value: string;
  };
  departmentId: string;
  departmentName: string;
  locationId: string;
  locationName: string;
  createdAt: string;
  groupId: string;
  groupName: string;
  clientTier: string;
  subdomain: string;
  praiseDirectory: {
    value: string;
  };
  timezone: string | null;
  isLineManager: boolean;
}

export interface CreatePlusOneUserError {
  formError?: {
    code: Errors;
    message?: string;
  };
  fieldErrors?: string[];
  status: Status.ERROR;
}

export interface CreatePlusOneUserSuccess extends CreatePlusOneUserResponse {
  status: Status.SUCCESS;
}

interface CreatePlusOneUserApiResponse {
  createPlusOneUser: CreatePlusOneUserResponse;
}

export type CreatePlusOnePayload =
  | CreatePlusOneUserError
  | CreatePlusOneUserSuccess;

export interface CreatePlusOneUserProps {
  createPlusOneUser(
    variables: CreatePlusOneUserVariables,
  ): Promise<CreatePlusOnePayload>;
}

const createPlusOneUser = async (
  variables: CreatePlusOneUserVariables,
  mutate?: MutationFunction<
    CreatePlusOneUserApiResponse,
    CreatePlusOneUserVariables
  >,
): Promise<CreatePlusOnePayload> => {
  try {
    if (mutate) {
      const response = await mutate({
        variables,
      });

      if (response) {
        if (response.data && !isNil(response.data.createPlusOneUser)) {
          return {
            ...response.data.createPlusOneUser,
            status: Status.SUCCESS,
          };
        }
      }
    }
  } catch (error) {
    if (error instanceof Error && isApolloError(error)) {
      const userErrors = handleCreateUserErrors(error);

      return {
        ...userErrors,
        status: Status.ERROR,
      };
    }

    return {
      status: Status.ERROR,
    };
  }

  return {
    formError: {
      code: Errors.ServerError,
    },
    status: Status.ERROR,
  };
};

const withCreatePlusOneUser = graphql<
  Record<string, unknown>,
  CreatePlusOneUserApiResponse,
  CreatePlusOneUserVariables,
  CreatePlusOneUserProps
>(CREATE_PLUS_ONE_USER_MUTATION, {
  options: {
    context: {
      timeout: 15000,
    },
  },
  props: ({ mutate }) => ({
    createPlusOneUser: async (variables: CreatePlusOneUserVariables) =>
      createPlusOneUser(variables, mutate),
  }),
});

export default withCreatePlusOneUser;
