import { Colors } from '@unmind/design-system-theme';
import { endOfWeek, format } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLine,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory';

import { range } from 'lodash';
import { styled, ThemeInterface, useTheme } from 'styles';
import { ScreenReaderContent } from '../../Shared/Accessibility';
import { useFilterState } from '../../Admin/Dashboard/FilterState';
import { timeFilterBreakdownPeriod } from '../../Admin/Dashboard/constants';
import BodyText from '../Typography/BodyText';
import { TimePeriod } from '../../__generated__/globalTypes';
import ChartTooltip from './ChartTooltip';

// These wrappers are needed to make sizeme work correctly when placed within a flexbox container
const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const WarningContainer = styled.div`
  position: absolute;
  top: calc(50% - ${rem(40)} / 2);
  left: calc(50% - ${rem(144)} / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TICK_WIDTH = 20;
const LIGHT_STROKE_WIDTH = 0.4;
export const ID_INDEX_LINE_GRAPH = 'index-line-graph';
export const ID_RANDOM = 'random';

const getDataPointLabels = (
  date: Date,
  count: number,
  dataLabelUnit: string,
) => {
  const dateLabel = format(date, 'MMMM do');
  const dailyLabel = `${dateLabel}\n${dataLabelUnit}: ${count}`;

  const weekStartLabel = format(date, 'do MMM');
  const weekEndLabel = format(endOfWeek(date, { weekStartsOn: 1 }), 'do MMM');
  const weeklyLabel = `${weekStartLabel} - ${weekEndLabel}\n${dataLabelUnit}: ${count}`;

  const monthLabel = format(date, 'MMMM yyyy');
  const monthlyLabel = `${monthLabel}\n${dataLabelUnit}: ${count}`;

  return {
    [TimePeriod.LAST_30_DAYS]: dailyLabel,
    [TimePeriod.LAST_12_WEEKS]: weeklyLabel,
    [TimePeriod.LAST_6_MONTHS]: monthlyLabel,
    [TimePeriod.ALL_TIME]: monthlyLabel,
  };
};

const getChartTheme = (
  { colors }: ThemeInterface,
  plotColor: Colors,
  yAxisUpperValue: number,
) => ({
  axis: {
    style: {
      axis: {
        stroke: 'none',
      },
      grid: {
        stroke: (t: number) => (t % 1 === 0 ? colors.border.secondary : 'none'),
        strokeWidth: LIGHT_STROKE_WIDTH,
      },
      ticks: {
        stroke: 'none',
        size: TICK_WIDTH,
        strokeWidth: LIGHT_STROKE_WIDTH,
      },
      tickLabels: {
        fill: colors.text.secondary,
      },
    },
  },
  dependentAxis: {
    style: {
      axis: {
        stroke: 'none',
      },
      grid: {
        stroke: colors.border.secondary,
        strokeWidth: (stroke: number) =>
          stroke === 0 || stroke === yAxisUpperValue ? 1 : LIGHT_STROKE_WIDTH,
      },
    },
  },
  line: {
    style: {
      data: { stroke: colors.text.secondary, strokeWidth: 1 },
    },
  },
  scatter: {
    style: {
      data: { fill: plotColor },
    },
  },
});

type RegistrationCountByDate = {
  date: Date;
  count: number;
};

export interface AdminLineChartProps {
  caption: string;
  data?: RegistrationCountByDate[];
  height?: number;
  plotColor?: Colors;
  isDataHidden?: boolean;
  dataPointsToDisplay: number;
  dataLabelUnit: string;
  title: string;
  description: string;
}

const AdminLineChart = ({
  caption,
  data,
  height,
  plotColor,
  isDataHidden,
  dataPointsToDisplay,
  dataLabelUnit,
  title,
  description,
}: AdminLineChartProps) => {
  const theme = useTheme();
  const { timeFilterPreset } = useFilterState();

  const breakdownPeriod = timeFilterBreakdownPeriod[timeFilterPreset];

  if (!data) {
    return null;
  }

  const dataWithLabels = data.map(
    (entry: RegistrationCountByDate, index: number) => {
      const { date, count } = entry;
      const labelOptions = getDataPointLabels(date, count, dataLabelUnit);
      const label = labelOptions[timeFilterPreset];

      return {
        date,
        count,
        label,
        index,
      };
    },
  );

  const tickLabels = range(dataPointsToDisplay).map((tickNumber: number) => {
    const date = data[tickNumber]?.date;

    const isFirstLastOrMiddlePoint = () =>
      tickNumber === 0 ||
      tickNumber === dataPointsToDisplay - 1 ||
      tickNumber === Math.round(dataPointsToDisplay / 2) - 1;

    if (date && breakdownPeriod === 'MONTH') {
      if (dataPointsToDisplay > 12) {
        if (!isFirstLastOrMiddlePoint()) {
          return '';
        }
      }

      return format(date, 'MMM yy');
    } else if (date && isFirstLastOrMiddlePoint()) {
      return format(date, 'do MMM');
    }
  });

  const highestCount = data.reduce(
    (largestCount, dataPoint) =>
      dataPoint.count > largestCount ? dataPoint.count : largestCount,
    0,
  );

  const yAxisUpperValue =
    highestCount < 100
      ? Math.ceil(highestCount / 10) * 10
      : Math.ceil(highestCount / 100) * 100;

  const chartTheme = getChartTheme(
    theme,
    plotColor !== undefined ? plotColor : theme.colors.staticPalette.topaz,
    yAxisUpperValue,
  );

  const a11yTableData = data
    // remove entries without value
    .filter(({ count }) => typeof count === 'number' && !isNaN(count))
    .map(({ date, count }) => ({
      x: format(date, 'MMMM do'),
      y: `${count}`,
    }));

  const renderInsufficientDataText = () => (
    <WarningContainer>
      <BodyText sizes={[theme.typography.fontSizes.fontSize12]}>
        Insufficient data.
        <br />
        Please check again later
      </BodyText>
    </WarningContainer>
  );

  const DynamicAxisLabel = (props: VictoryLabelProps) => {
    const labels = tickLabels.filter(label => !!label);
    const textAnchor =
      props.text === labels[0]
        ? 'start'
        : props.text === labels[labels.length - 1]
        ? 'end'
        : 'middle';

    return (
      <VictoryLabel
        {...props}
        textAnchor={breakdownPeriod === 'MONTH' ? 'middle' : textAnchor}
      />
    );
  };

  return (
    <SizeMe monitorWidth={true}>
      {({ size: { width } }) => (
        <>
          <Wrapper style={{ height }}>
            <InnerWrapper aria-hidden={true} data-testid={ID_INDEX_LINE_GRAPH}>
              <VictoryChart
                containerComponent={
                  <VictoryZoomContainer
                    allowPan={false}
                    allowZoom={false}
                    height={height}
                    responsive={false}
                    width={width !== null ? width : undefined}
                    title={title}
                    desc={description}
                  />
                }
                height={height}
                padding={{
                  left: yAxisUpperValue.toString().length * 12,
                  right: 0,
                  top: 0,
                  bottom: 45,
                }}
                singleQuadrantDomainPadding={{ x: false, y: false }}
                domainPadding={{
                  x: breakdownPeriod === 'MONTH' ? [10, 20] : 10,
                  y: [0, 20],
                }}
                // @ts-ignore
                theme={chartTheme}
                width={width !== null ? width : undefined}
              >
                <VictoryAxis
                  data-testid={ID_RANDOM}
                  tickValues={range(dataPointsToDisplay)}
                  tickLabelComponent={<DynamicAxisLabel />}
                  tickFormat={tickLabels}
                />
                <VictoryAxis
                  orientation="top"
                  tickValues={range(dataPointsToDisplay)}
                  tickFormat={() => ''}
                />
                <VictoryAxis
                  dependentAxis
                  tickValues={range(
                    0,
                    yAxisUpperValue + 1,
                    yAxisUpperValue / 2,
                  )}
                  tickFormat={t => {
                    if (
                      t === 0 ||
                      t === yAxisUpperValue ||
                      t === Math.round(yAxisUpperValue / 2)
                    ) {
                      return t;
                    }

                    return '';
                  }}
                  standalone={false}
                />
                <VictoryAxis
                  dependentAxis
                  tickValues={range(
                    0,
                    yAxisUpperValue + 1,
                    yAxisUpperValue / 2,
                  )}
                  tickFormat={() => ''}
                  standalone={false}
                />
                {isDataHidden ? null : (
                  <VictoryGroup>
                    <VictoryLine data={data} x="index" y="count" />
                    <VictoryScatter
                      data={dataWithLabels}
                      size={4}
                      x="index"
                      y="count"
                      events={[
                        {
                          target: 'data',
                          eventHandlers: {
                            onMouseOver: () => [
                              {
                                target: 'data',
                                mutation: () => ({
                                  style: {
                                    fill: chartTheme.scatter.style.data.fill,
                                    stroke: chartTheme.scatter.style.data.fill,
                                    strokeWidth: 4,
                                  },
                                }),
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: true }),
                              },
                            ],
                            onMouseOut: () => [
                              {
                                target: 'data',
                                mutation: () => ({}),
                              },
                              {
                                target: 'labels',
                                mutation: () => ({ active: false }),
                              },
                            ],
                          },
                        },
                      ]}
                      labelComponent={<ChartTooltip />}
                    />
                  </VictoryGroup>
                )}
              </VictoryChart>

              {isDataHidden ? renderInsufficientDataText() : null}
            </InnerWrapper>
            <ScreenReaderContent as="table">
              <caption>{caption}</caption>
              <tbody>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Count</th>
                </tr>

                {a11yTableData.map(({ x, y }, idx) => (
                  <tr key={`A11yTable-${caption}-${x},${y}-${idx}`}>
                    <td>{x}</td>
                    <td>{y}</td>
                  </tr>
                ))}
              </tbody>
            </ScreenReaderContent>
          </Wrapper>
        </>
      )}
    </SizeMe>
  );
};

export default AdminLineChart;
