import { css, styled } from 'styles';
import { rem } from 'polished';
import { extraSmall, small } from '../../utils';

export const Page = styled.div`
  &::after {
    background: ${props => props.theme.colors.background.secondary};
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
`;

export const Main = styled.div`
  background: ${props => props.theme.colors.background.primary};
`;

export const TitleContainer = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: ${rem(1280)};

  ${extraSmall(css`
    flex-direction: row;
  `)}

  ${small(css`
    margin: 0 auto;
  `)}
`;
