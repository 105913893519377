import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { small } from 'utils';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import PrimaryButton from 'Shared/PrimaryButton';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import TalkPractitionerImage from './Assets/talk.png';

const OuterSectionContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.staticPalette.white};
  align-self: center;
  justify-content: center;
  width: 100%;
  padding-bottom: ${rem(20)};

  ${small(css`
    padding-bottom: ${rem(0)};
  `)};
`;

const InnerSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.staticPalette.white};
  align-items: center;
  justify-content: space-between;
  padding: ${rem(40)} ${rem(20)} ${rem(10)} ${rem(20)};
  gap: ${rem(40)};
  align-self: center;
  max-width: ${rem(1252)};

  ${small(css`
    padding: ${rem(60)} ${rem(60)} ${rem(100)} ${rem(60)};
    flex-direction: row;
    gap: ${rem(24)};
  `)};
`;

const ImageSection = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: ${rem(20)};
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(400)};
  gap: ${rem(16)};
`;

const TalkImage = styled.img`
  width: 100%;
  margin-top: ${rem(10)};
  scale: 1.2;

  ${small(css`
    margin-top: 0;
    width: ${rem(620)};
    scale: 1;
  `)};
`;

const TalkTitle = styled(HeadingText)`
  font-size: ${rem(24)};
  font-weight: 600;

  ${small(css`
    font-size: ${rem(30)};
  `)};
`;

const TalkText = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize20}px;
`;

const TalkCTAButton = styled(PrimaryButton)`
  max-width: ${rem(240)};
`;

export const TalkBody = ({
  ctaNavigationPath,
}: {
  ctaNavigationPath: string;
}) => (
  <OuterSectionContainer>
    <InnerSectionContainer>
      <DescriptionSection>
        <TalkTitle level={2}>Talk therapy and coaches</TalkTitle>
        <TalkText>
          Browse and find support from a licensed therapist or coach and get the
          support you deserve.
          <br />
          <br />
          Access to hundreds of fully accredited therapists and coaches
          representing a diverse cross-section of society, covering 50+
          languages and all major time zones.
        </TalkText>
        <TalkCTAButton
          label="Browse practitioners ->"
          to={ctaNavigationPath}
          onClick={() => {
            tracking.track('teams-start-page-cta-clicked', {
              abTestGroup: 'talk-focussed-start-page-sept-2024',
              tenantId: getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
              ctaLocation: 'start-page-body',
            });
          }}
        ></TalkCTAButton>
      </DescriptionSection>
      <ImageSection>
        <TalkImage src={TalkPractitionerImage} aria-hidden />
      </ImageSection>
    </InnerSectionContainer>
  </OuterSectionContainer>
);
