import React from 'react';
import { IconProps } from '../IconProps';
const SvgCircularVideo = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20z"
      fill="#f2ece4"
    />
    <path
      d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z"
      stroke="#cdc8c0"
    />
    <g clipPath="url(#CircularVideo_svg__a)">
      <path
        d="M28.314 15.357l-4.028 1.786V15a1.429 1.429 0 00-1.429-1.429H12.143A1.428 1.428 0 0010.714 15v10a1.429 1.429 0 001.429 1.428h10.714A1.429 1.429 0 0024.286 25v-2.143l4.028 1.786a.714.714 0 00.972-.672v-7.943a.714.714 0 00-.972-.671v0z"
        stroke="#cdc8c0"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="CircularVideo_svg__a">
        <path fill="#fff" d="M10 10h20v20H10z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircularVideo;
