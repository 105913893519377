import { rem } from 'polished';
import React, { HTMLAttributes, ReactNode } from 'react';
import { css, styled } from 'styles';
import { small } from '../../utils';

const ModalActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 ${rem('16px')} ${rem('12px')};

  ${small(css`
    padding: 0 ${rem('24px')} ${rem('8px')};
  `)}
`;

const ModalAction = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem('10px')};

  ${small(css`
    padding-bottom: ${rem('16px')};
  `)}
`;

type ModalActionsProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

const ModalActions = ({ children }: ModalActionsProps) => (
  <ModalActionsContainer>
    {React.Children.map(children, (child: ReactNode) => (
      <ModalAction>{child}</ModalAction>
    ))}
  </ModalActionsContainer>
);

export default ModalActions;
