import { differenceInCalendarDays } from 'date-fns';
import { useEffect } from 'react';
import { tracking } from '../../../../App/Tracking';
import { useFilterState } from '../../FilterState';

interface UseAnonymityThresholdTrackingProps {
  hideUserActivity?: boolean | null;
  hideCurrentRegistrations?: boolean | null;
  hideRegistrations?: boolean | null;
  loading: boolean;
  adminTabType: 'activity' | 'wellbeing' | 'managerReporting';
}

const isSet = (flag?: boolean | null) => typeof flag === 'boolean';

export const useAnonymityThresholdTracking = ({
  hideUserActivity,
  hideCurrentRegistrations,
  hideRegistrations,
  loading,
  adminTabType,
}: UseAnonymityThresholdTrackingProps) => {
  const { timeFilterState } = useFilterState();

  useEffect(() => {
    const anonymisationFlags = {
      hideUserActivity,
      ...(adminTabType === 'activity' && { hideCurrentRegistrations }),
      ...(adminTabType === 'activity' && { hideRegistrations }),
    };

    const hasDataPointFailedAnonymityThresholdCheck = Object.values(
      anonymisationFlags,
    ).find(flag => flag === true);
    const areAnonymityFlagsExplicitlySet =
      Object.values(anonymisationFlags).filter(flag => !isSet(flag)).length ===
      0;

    if (
      !loading &&
      areAnonymityFlagsExplicitlySet &&
      hasDataPointFailedAnonymityThresholdCheck
    ) {
      const endDate = new Date(timeFilterState.endDate);
      const startDate = new Date(timeFilterState.startDate);
      // Difference in calendar days (midnight to midnight), plus one to include the last day
      const currentTimeframe = differenceInCalendarDays(endDate, startDate) + 1;

      tracking.track('admin-dashboard-anonymity-threshold-not-met', {
        currentTimeframe,
        ...(isSet(hideUserActivity) && {
          currentActivityThreshold: !hideUserActivity,
        }),
        ...(isSet(hideCurrentRegistrations) && {
          currentRegistrationThreshold: !hideCurrentRegistrations,
        }),
        ...(isSet(hideRegistrations) && {
          allTimeRegistrationThreshold: !hideRegistrations,
        }),
        adminTabType,
      });
    }
  }, [
    hideUserActivity,
    hideCurrentRegistrations,
    hideRegistrations,
    timeFilterState.startDate,
    timeFilterState.endDate,
    loading,
    adminTabType,
  ]);
};
