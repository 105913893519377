import { DayPicker, DayPickerProps } from 'react-day-picker';
import { rem } from 'polished';
import styled from 'styled-components';
import { BodyText } from '@unmind/design-system-components-web';

export const StyledDayPicker = styled(DayPicker)<DayPickerProps>`
  .rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${rem(24)};
  }

  .rdp-button {
    background: none;
    border: none;
    box-shadow: none;
    box-sizing: border-box;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .rdp-nav {
    display: inline-flex;
  }

  .rdp-table {
    width: 100%;
    text-align: center;
    font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize12)};
  }

  .rdp-head_cell {
    font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize16)};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    line-height: ${rem(30)};
  }

  .rdp-day {
    height: ${rem(32)};
    width: ${rem(32)};
    border-radius: 50%;
    padding: 0;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .rdp-head_row,
  .rdp-row {
    margin-top: ${rem(30)};
  }

  .rdp-day_today {
    border: 1px solid
      ${({ theme }) => theme.button.primary.disabled.borderColor};
  }

  .rdp-day_selected:not([disabled]),
  .rdp-day_selected:focus:not([disabled]),
  .rdp-day_selected:active:not([disabled]),
  .rdp-day_selected:hover:not([disabled]) {
    border: 1px solid ${({ theme }) => theme.button.primary.default.borderColor};
    background-color: ${({ theme }) =>
      theme.button.primary.default.backgroundColor};
    color: ${({ theme }) => theme.button.primary.default.textColor};
  }

  .rdp-day:hover:not([disabled], .rdp-day_selected),
  .rdp-day:focus:not([disabled], .rdp-day_selected) {
    background-color: ${({ theme }) =>
      theme.button.primary.default.backgroundColor};
    color: ${({ theme }) => theme.button.primary.default.textColor};
  }
`;

export const DateLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.bold,
}))`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
`;
