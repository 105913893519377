import React from 'react';
import { IconProps } from '../IconProps';

const SvgIndicatorError = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    aria-hidden="true"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipRule="evenodd"
    >
      <path
        d="M56 28a28 28 0 1 1-56 0 28 28 0 0 1 56 0z"
        fill={secondaryColor}
      />
      <path
        d="M28 10.5a3.5 3.5 0 0 0-3.5 3.5v17.5a3.5 3.5 0 1 0 7 0V14a3.5 3.5 0 0 0-3.5-3.5zm0 28a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"
        fill={primaryColor}
      />
    </g>
  </svg>
);

export default SvgIndicatorError;
