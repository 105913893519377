import React from 'react';
import { IconProps } from '../IconProps';

const SvgPlay = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 37 42" width="1em" height="1em" {...props}>
    <path
      d="M35.779 22.296L2.54 41.684a1.5 1.5 0 0 1-2.255-1.296V1.612A1.5 1.5 0 0 1 2.54.316L35.78 19.704a1.5 1.5 0 0 1 0 2.592z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgPlay;
