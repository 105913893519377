import { rem } from 'polished';
import React, { useLayoutEffect, useRef } from 'react';

import { styled, ThemeInterface } from 'styles';
import { WrappingButton } from '../../../Shared/Accessibility';
import { HeadingText } from '../../../Shared/Typography';

const ButtonIcon = ({ icon }: { icon: any }) => {
  const StyledIcon = styled<any>(icon).attrs(
    ({ theme }: { theme: ThemeInterface }) => ({
      primaryColor: theme.colors.text.secondary,
      secondaryColor: theme.colors.text.secondary,
      viewBox: '8 8 40 40',
    }),
  )`
    display: block;
    height: ${rem(32)};
    width: ${rem(32)};
  `;

  return <StyledIcon />;
};

const ButtonContainer = styled(WrappingButton)`
  height: 100%;
  padding-right: ${rem(20)};
  transform: translateY(${rem(4)});
`;

const StyledHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
}))`
  margin: 0;

  &:focus {
    outline: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  padding-top: ${rem(36)};
  padding-bottom: ${rem(24)};
  margin-bottom: ${rem(32)};
  ${({ fullScreenMode }: { fullScreenMode: boolean }) => {
    if (fullScreenMode) {
      return `
        margin-top: ${rem(60)};
        padding-top: ${rem(0)};
        padding-bottom: ${rem(30)};
      `;
    }
  }}
`;

const SubHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.secondary,
}))`
  padding-top: ${rem(8)};
  text-transform: uppercase;
`;

interface HeaderButtonProps {
  ariaLabel?: string;
  icon: any;
  onClick(): void;
}

const HeaderButton = ({ ariaLabel, icon, onClick }: HeaderButtonProps) => (
  <ButtonContainer
    data-testid="close-button"
    aria-label={ariaLabel}
    onClick={onClick}
  >
    <ButtonIcon icon={icon} />
  </ButtonContainer>
);

interface QuestionnaireHeaderProps {
  heading: string;
  subHeading?: string;
  onButtonClick(): void;
  buttonAriaLabel?: string;
  buttonIcon: any;
  headingSize?: string;
  fullScreenMode?: boolean;
}

export default ({
  heading,
  subHeading = '',
  onButtonClick,
  buttonAriaLabel,
  buttonIcon,
  fullScreenMode = false,
}: QuestionnaireHeaderProps) => {
  const headerRef = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    headerRef?.current?.focus();
  }, [heading, headerRef]);

  return (
    <HeaderContainer fullScreenMode={fullScreenMode}>
      <HeaderButton
        ariaLabel={buttonAriaLabel}
        icon={buttonIcon}
        onClick={onButtonClick}
      />
      <TitleContainer>
        <StyledHeading ref={headerRef} tabIndex={0}>
          {heading}
        </StyledHeading>
        {subHeading && <SubHeading>{subHeading}</SubHeading>}
      </TitleContainer>
    </HeaderContainer>
  );
};
