import { BodyText, Button } from '@unmind/design-system-components-web';
import { ThemeInterface } from '@unmind/design-system-theme';
import { Chevron } from 'icons';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(16)};
`;

export const AddToCalendarButton = styled(Button).attrs({
  variant: 'secondary',
  size: 'small',
})`
  width: ${rem(185)};
  min-height: ${rem(40)};
`;

export const AddToCalendarButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddToCalendarButtonLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))``;

export const ArrowIcon = styled(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    width: 14,
    height: 14,
  }),
)<{ $isOpen: boolean }>`
  margin-left: 8px;
  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
  transform: ${props => (props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;
