import React from 'react';
import { IconProps, IconType } from 'icons';

interface DynamicIconProps extends IconProps {
  Icon: IconType;
}

const DynamicIcon = ({ Icon, ...props }: DynamicIconProps) => (
  <Icon {...props} />
);

export default DynamicIcon;
