import React, { ReactElement } from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import { HeadingText, BodyText } from '../Shared/Typography';

export const InfoPanelContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const InfoPanelTextContainer = styled.div``;

export const InfoPanelTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.medium,
  level: 4,
}))`
  text-transform: uppercase;
`;

export const InfoPanelText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  display: inline;
`;

export const InfoPanelList = styled.ul`
  padding: 0 0 0 ${rem(16)};
  margin: 0 0 ${rem(24)} 0;
  list-style: none;
`;

export const InfoPanelListItem = styled.li`
  margin-top: ${rem(16)};

  &:first-of-type {
    margin-top: 0;
  }

  &::before {
    content: '\\2022';
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${rem(12)};
    font-weight: bold;
    display: inline-block;
    width: ${rem(16)};
    margin-left: -${rem(16)};
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin: ${rem(16)} 0;
  display: flex;
  justify-content: center;
`;

interface SendInviteInformationPanelProps {
  title: string;
  bulletPoints: (ReactElement | string)[];
  image: ReactElement;
  link?: ReactElement;
}

export const SendInviteInformationPanel = ({
  title,
  bulletPoints,
  image,
  link,
}: SendInviteInformationPanelProps) => (
  <InfoPanelContainer>
    <InfoPanelTitle>{title}</InfoPanelTitle>
    <ImageContainer>{image}</ImageContainer>
    <InfoPanelTextContainer>
      <InfoPanelList>
        {bulletPoints.map(bulletPoint => (
          <InfoPanelListItem>
            <InfoPanelText>{bulletPoint}</InfoPanelText>
          </InfoPanelListItem>
        ))}
      </InfoPanelList>
      {link}
    </InfoPanelTextContainer>
  </InfoPanelContainer>
);
