import defaultTo from 'lodash/defaultTo';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import RoutePath from '../../../App/RoutePath';
import Route404 from '../../../App/Router/Route404';
import { TimerProvider } from '../../../Shared/Timer';
import { PageContainer } from '../../SeriesShared';
import CongratulationsDay from './CongratulationsDay';
import { SeriesDayHeader } from './SeriesDayHeader';
import { UserSeriesSegment } from './SeriesSegment/SeriesSegment';
import { SeriesSegmentsList } from './SeriesSegmentsList';
import { withSeriesDay, WithSeriesDayChildProps } from './withSeriesDay';
import { withSeriesAccess } from './withSeriesDayAccess';
import {
  withSeriesTrackingIds,
  withSeriesTrackingIdsChildProps,
} from './withSeriesTrackingIds';
import {
  withSeriesDayProgress,
  WithSeriesDayProgressChildProps,
} from './withSeriesDayProgress';

interface EnhancedRoutingComponentProps {
  dayNumber: number;
  routeProps: RouteComponentProps;
  segment?: number;
  slug: string;
  onToggle(segmentIdx: number): void;
  updateHistory(segmentIdx: number, expanded: boolean): void;
}
export interface SeriesDayProps
  extends EnhancedRoutingComponentProps,
    WithSeriesDayChildProps,
    withSeriesTrackingIdsChildProps,
    WithSeriesDayProgressChildProps {}

export interface SegmentTrackingProps {
  dayId?: string;
  dayNumber: number;
  seriesId?: string;
  seriesSlug: string;
}

export const SeriesDay = (props: SeriesDayProps) => {
  const {
    dayNumber,
    segment,
    completedSegmentIds,
    seriesDayLoading,
    seriesDayProgressLoading,
    seriesDay,
    seriesDayIdLoading,
    seriesId,
    seriesCompleted,
    seriesDayCompleted,
    lastDayInSeries,
  } = props;
  const isSeriesDayIdLoading =
    seriesDayIdLoading === undefined || seriesDayIdLoading;
  const isSeriesDayLoading =
    seriesDayLoading === true || seriesDayProgressLoading === true;

  if (isSeriesDayIdLoading || isSeriesDayLoading) {
    return <React.Fragment />;
  }

  if (!seriesDay) {
    return <Route404 {...props.routeProps} />;
  }

  const totalNoOfSegments = seriesDay.segments.length;

  const sortedSegments: UserSeriesSegment[] = seriesDay.segments
    .sort((a, b) => a.orderNo - b.orderNo)
    .map(seriesSegment => {
      const isComplete = Boolean(
        completedSegmentIds.find(
          completedSegment => seriesSegment.id === completedSegment,
        ),
      );

      return { ...seriesSegment, isComplete };
    });

  const completedSegments: UserSeriesSegment[] = sortedSegments.filter(
    seriesSegment => seriesSegment.isComplete,
  );

  /**
   * Expand the relevant section based on redirect state for direct link or on
   * the next segment to be completed.
   * For the URL we count from 1 but the segments count from 0.
   * This also handles an invalid segment and just expands the next incomplete
   * one if appropriate.
   */
  const expandedSegment = () =>
    segment !== undefined && segment <= completedSegments.length
      ? segment - 1
      : completedSegments.length < sortedSegments.length
      ? completedSegments.length
      : undefined;

  const segmentsToShow =
    expandedSegment !== undefined &&
    sortedSegments.length > completedSegments.length
      ? [...completedSegments, sortedSegments[completedSegments.length]]
      : completedSegments;

  const segmentsLeft = totalNoOfSegments - segmentsToShow.length + 1;

  const showDayCompleted =
    seriesDayCompleted && (!seriesCompleted || !lastDayInSeries);

  const showSeriesCompleted = seriesCompleted && lastDayInSeries;

  // FIXME
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const expanded = expandedSegment();
    if (segment !== undefined && completedSegments !== undefined) {
      // we need a check for a questionnaire segment which can't be re-done [expanded]
      if (
        segment - 1 < completedSegments.length &&
        completedSegments[segment - 1].mediaType === 'questionnaire'
      ) {
        const nextSegment =
          completedSegments.length < totalNoOfSegments
            ? completedSegments.length + 1
            : 0;
        props.updateHistory(nextSegment, nextSegment > 0);
      }
    }

    if (segment === undefined && expanded !== undefined) {
      props.updateHistory(expanded, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isExpanded = expandedSegment();
  const showCongratulationsSection =
    completedSegments.length === totalNoOfSegments || isExpanded === undefined;

  return (
    <PageContainer>
      <SeriesDayHeader
        day={dayNumber}
        name={seriesDay.title}
        slug={props.slug}
      />
      {segmentsToShow.length && (
        <TimerProvider>
          <SeriesSegmentsList
            segmentTrackingProps={{
              dayId: seriesDay.id,
              dayNumber,
              seriesId,
              seriesSlug: props.slug,
            }}
            expandedSegmentOnLoad={isExpanded}
            segmentsToShow={segmentsToShow}
            segmentsLeft={segmentsLeft}
            totalNoOfSegments={totalNoOfSegments}
            onToggle={props.onToggle}
            dayCompleted={showDayCompleted || showSeriesCompleted}
            lastDayInSeries={lastDayInSeries}
          />
        </TimerProvider>
      )}
      {showCongratulationsSection && (
        <CongratulationsDay
          slug={props.slug}
          dayNumber={dayNumber}
          hasReminder={seriesDay.hasReminder}
        />
      )}
    </PageContainer>
  );
};

export default compose<SeriesDayProps, Record<string, unknown>>(
  withRouter,
  (Component: React.ComponentType<EnhancedRoutingComponentProps>) =>
    (
      props: RouteComponentProps<{
        slug: string;
        day: string;
        segment: string;
      }>,
    ) => {
      const dayNumber = defaultTo(Number(props.match.params.day), 1);
      const segment = defaultTo(Number(props.match.params.segment), undefined);

      const updateHistory = (segmentIdx: number, expanded: boolean) => {
        if (expanded) {
          props.history.replace(
            `${RoutePath.Series}/${props.match.params.slug}/day/${dayNumber}/${
              segmentIdx + 1
            }`,
            { ...props.location.state },
          );
        } else {
          props.history.replace(
            `${RoutePath.Series}/${props.match.params.slug}/day/${dayNumber}`,
            { ...props.location.state },
          );
        }
      };

      const onSegmentToggle = (segmentIdx: number) => {
        const sameSegment = segmentIdx + 1 === segment;
        updateHistory(segmentIdx, !sameSegment);
      };

      return (
        <Component
          dayNumber={dayNumber}
          routeProps={props}
          segment={segment}
          slug={props.match.params.slug}
          updateHistory={updateHistory}
          onToggle={onSegmentToggle}
        />
      );
    },
  withSeriesTrackingIds,
  withSeriesDay,
  withSeriesDayProgress,
  withSeriesAccess,
)(SeriesDay);
