import React from 'react';
import { IconProps } from '../IconProps';

const SvgPerformance = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={1.667}
    >
      <path d="M28 15.344a18.78 18.78 0 0 0-13.318 5.706A19.255 19.255 0 0 0 9.25 34.62v3.505c0 1.398 1.12 2.531 2.5 2.531h32.5c1.38 0 2.5-1.133 2.5-2.531v-3.51c.078-10.564-8.317-19.191-18.75-19.271z" />
      <path d="M23 40.656v-1.265c0-2.796 2.239-5.063 5-5.063s5 2.267 5 5.063v1.265M31.75 22.943L28 34.333M16.125 33.063a.629.629 0 0 0-.625.632c0 .35.28.633.625.633a.629.629 0 0 0 .625-.633c0-.35-.28-.633-.625-.633M18.625 26.74a.629.629 0 0 0-.625.632c0 .35.28.633.625.633a.629.629 0 0 0 .625-.633c0-.35-.28-.633-.625-.633M39.875 33.063a.629.629 0 0 0-.625.632c0 .35.28.633.625.633a.629.629 0 0 0 .625-.633c0-.35-.28-.633-.625-.633M37.375 26.74a.629.629 0 0 0-.625.632c0 .35.28.633.625.633a.629.629 0 0 0 .625-.633c0-.35-.28-.633-.625-.633M23.625 21.677a.629.629 0 0 0-.625.633c0 .35.28.633.625.633a.629.629 0 0 0 .625-.633c0-.35-.28-.633-.625-.633" />
    </g>
  </svg>
);

export default SvgPerformance;
