import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { logException } from 'App/logging';
import { isMSTeams } from 'utils/MSTeams';
import { authenticationErrorStorageCode } from 'LoggedOut/Login/AuthError';
import { ApolloCacheShape } from '../../App/apollo';
import RoutePath from '../../App/RoutePath';
import { AUTHENTICATED_QUERY } from '../../App/Auth/authenticatedQuery';

interface SSOLoginHomeProps {
  client?: ApolloClient<ApolloCacheShape>;
}

const SSOLoginHome = ({ client }: SSOLoginHomeProps) => {
  /**
   * Component that loads after unsuccessful authentication with
   * Auth0 SSO used to handle any required actions or redirects
   */
  const urlSearchParams = new URLSearchParams(location.search);
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    if (client) {
      client.writeQuery({
        query: AUTHENTICATED_QUERY,
        data: {
          authenticated: true,
        },
      });

      const returnTo = urlSearchParams.get('returnto');
      const qs = returnTo ? `?returnto=${encodeURIComponent(returnTo)}` : '';

      return <Redirect to={`${RoutePath.SSOLoginSuccess}${qs}`} />;
    } else {
      logException(
        'attempted to persist authenticated state but apollo client not initialised',
      );
    }
  }

  const errorDescription = urlSearchParams.get('error_description');
  const authErrorParam = errorDescription
    ? `?error_code=${errorDescription}`
    : '';

  if (isMSTeams()) {
    const errorParams = {
      errorDescription,
      authErrorParam,
    };
    localStorage.setItem(
      authenticationErrorStorageCode,
      JSON.stringify(errorParams),
    );
    window.close();

    return <Redirect to={RoutePath.MSTeamsSSOCallback} />;
  }

  if (errorDescription === 'EMAIL_VERIFICATION_REQUIRED') {
    return <Redirect to={RoutePath.VerifyAccountOwnershipEmailConfirmation} />;
  }

  return <Redirect to={`${RoutePath.Login}${authErrorParam}`} />;
};

export default withApollo<SSOLoginHomeProps>(SSOLoginHome);
