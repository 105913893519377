import { StylelessButton } from 'Shared/Button';
import { BodyText } from '@unmind/design-system-components-web';
import { Close } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const BasePillStyles = css`
  display: inline-flex;
  align-items: center;
  height: ${rem(24)};
  padding: ${rem(5)} ${rem(12)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${rem(16)};
`;
export const BasePillButton = styled(StylelessButton)`
  ${BasePillStyles}
`;

export const BasePill = styled.div`
  ${BasePillStyles}
`;

export const Label = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

export const CloseIcon = styled(Close).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  height: ${rem(12)};
  width: ${rem(12)};
  margin-left: ${rem(8)};
`;
