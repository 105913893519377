import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getWellbeingThemeDetail } from './__generated__/getWellbeingThemeDetail';

export const WELLBEING_THEME_DETAIL_QUERY = gql`
  query getWellbeingThemeDetail($indexScheduleId: String!, $themeId: String!) {
    wellbeingThemeDetail(indexScheduleId: $indexScheduleId, themeId: $themeId) {
      id
      title
      description
      meanScore
      riskCategory
      definition
      questions {
        id
        questionText
        responseDistributions {
          id
          label
          responses
        }
      }
      recommendedActions {
        actionId
        themeId
        title
        description
        markedAsTaken
        score
        riskCategory
      }
    }
  }
`;

export const useWellbeingThemeDetail = ({
  indexScheduleId,
  themeId,
}: {
  indexScheduleId: string;
  themeId: string;
}) =>
  useQuery<getWellbeingThemeDetail>(WELLBEING_THEME_DETAIL_QUERY, {
    variables: {
      indexScheduleId,
      themeId,
    },
    fetchPolicy: 'no-cache',
  });
