import { useTranslation } from 'react-i18next';
import { ANONYMITY_THRESHOLD } from '../constants';
import { useFilterState } from '../FilterState';

export enum AnonymityWarningMessage {
  ACTIVE_USERS = 'active_users',
  USER_REGISTRATIONS = 'user_registrations',
  CHECK_IN_PANEL = 'check_in_panel',
  INDEX_PANEL = 'index_panel',
}

type AnonymityWarningI18n = 'last_30_days' | 'last_12_weeks' | 'last_6_months';

export const useAnonymityCopy = (message: AnonymityWarningMessage) => {
  const { t: translate } = useTranslation('admin');
  const { timeFilterPreset } = useFilterState();

  return translate(
    `anonymity_warning.${message}.${
      timeFilterPreset.toLowerCase() as AnonymityWarningI18n
    }`,
    {
      ANONYMITY_THRESHOLD,
    },
  );
};
