import React from 'react';
import gql from 'graphql-tag';
import { styled } from 'styles';
import { rem } from 'polished';
import { Trans, useTranslation } from 'react-i18next';
import { AlertBox } from '../../Shared/Form';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { BodyText } from '../../Shared/Typography';
import CheckInsPanel from './panels/CheckInsPanel';
import UnmindIndexPanel from './panels/UnmindIndexPanel';
import {
  PanelColumn,
  PanelContainer,
  PanelWrapper,
} from './DashboardContent.styled';
import { useFilteredQuery, useFilterState } from './FilterState';
import { WellbeingDataAvailability } from './__generated__/WellbeingDataAvailability';
import FilterPanel from './FilterPanel';
import { useAnonymityThresholdTracking } from './helpers/tracking';
import { AnonymityWarningMessage } from './helpers/useAnonymityCopy';
import AnonymityWarning from './AnonymityWarning';
import ErrorMessage from './ErrorMessage';

const AlertBoxWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

const AlertBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlertBoxText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))<{ isLastChild?: boolean }>`
  padding-bottom: ${({ isLastChild }) => (isLastChild ? 0 : rem(10))};
`;

const StudyLink = styled.a``;

export const WELLBEING_DATA_AVAILABILITY_QUERY = gql`
  query WellbeingDataAvailability($input: OrganisationInsightsQueryInput!) {
    organisationActiveUsers(input: $input) {
      shouldBeAnonymised
    }
  }
`;

export const WellbeingContent = () => {
  const { t: translate } = useTranslation('admin');
  const { timeFilterPreset } = useFilterState();
  const { data: { organisationActiveUsers } = {}, loading } =
    useFilteredQuery<WellbeingDataAvailability>(
      WELLBEING_DATA_AVAILABILITY_QUERY,
      {
        variables: {
          input: {
            timePeriod: timeFilterPreset,
          },
        },
        fetchPolicy: 'cache-and-network',
      },
    );

  const hideUserActivity = organisationActiveUsers?.shouldBeAnonymised;
  const areAnonymityFlagsExplicitlySet = typeof hideUserActivity === 'boolean';

  useAnonymityThresholdTracking({
    loading,
    adminTabType: 'wellbeing',
    hideUserActivity,
  });

  if (loading) {
    return (
      <>
        <FilterPanel key="filterPanel" />
        <LoadingIndicator />
      </>
    );
  }

  if (!organisationActiveUsers || !areAnonymityFlagsExplicitlySet) {
    return <ErrorMessage />;
  }

  if (!!hideUserActivity) {
    return <AnonymityWarning message={AnonymityWarningMessage.ACTIVE_USERS} />;
  }

  return (
    <>
      <AlertBoxWrapper>
        <AlertBox alertType="warning">
          <AlertBoxTextWrapper>
            <AlertBoxText>
              {translate('wellbeing_content.banner.paragraph_one')}
            </AlertBoxText>
            <AlertBoxText>
              {translate('wellbeing_content.banner.paragraph_two')}
            </AlertBoxText>
            <AlertBoxText isLastChild={true}>
              {Trans({
                t: translate,
                i18nKey: 'wellbeing_content.banner.paragraph_three',
                components: {
                  study_link: (
                    <StudyLink
                      href="https://mental.jmir.org/2022/1/e34103"
                      target="_blank"
                    />
                  ),
                },
              })}
            </AlertBoxText>
          </AlertBoxTextWrapper>
        </AlertBox>
      </AlertBoxWrapper>
      <FilterPanel key="filterPanel" />
      <PanelContainer>
        <PanelColumn>
          <PanelWrapper>
            <UnmindIndexPanel />
          </PanelWrapper>
        </PanelColumn>

        <PanelColumn>
          <PanelWrapper>
            <CheckInsPanel />
          </PanelWrapper>
        </PanelColumn>
      </PanelContainer>
    </>
  );
};

export default WellbeingContent;
