import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { medium, small } from '../../utils';
import { Series, Tool } from '../types';
import CourseCard from '../../Courses/CourseCard';
import ShortCard from '../../Shorts/ShortCard';
import useViewportWidth from '../../utils/useViewportWidth';

export const ContentList = styled.div`
  display: flex;
`;

const ContentCardWrapper = styled.div`
  width: calc((100% - ${rem(16)}) / 2);
  margin-right: 16px;

  @media (max-width: ${rem(600)}) {
    &:nth-of-type(2n) {
      margin-right: 0px;
    }
  }

  ${small(css`
    width: calc((100% - ${rem(32)}) / 3);
    &:nth-of-type(3n) {
      margin-right: 0px;
    }
  `)}

  ${medium(css`
    margin-right: 32px;
  `)}
`;

export interface GenericExploreContentProps {
  content: (Series | Tool)[];
  assetToken?: string | null;
  source: string;
}

const GenericExploreContent = ({
  content,
  assetToken,
  source,
}: GenericExploreContentProps) => {
  const width = useViewportWidth();
  // limiting the amount of content we want to show depending on viewport
  const limitContent = content.slice(0, width <= 600 ? 2 : 3);

  return content?.length ? (
    <ContentList data-testid="generic-explore-content">
      {limitContent.map(item => {
        if (item.__typename === 'Tool') {
          return (
            <ContentCardWrapper key={item.id}>
              <ShortCard
                tool={item}
                assetToken={assetToken}
                showDescription={false}
                source={source}
              />
            </ContentCardWrapper>
          );
        } else {
          return (
            <ContentCardWrapper key={item.id}>
              <CourseCard
                series={item}
                assetToken={assetToken}
                showDescription={false}
                source={source}
              />
            </ContentCardWrapper>
          );
        }
      })}
    </ContentList>
  ) : null;
};

export default GenericExploreContent;
