import { Department, Location } from '../useSubdomainInfo';

export const isCollectingWorkDetails = (
  locations?: (Location | null)[] | null | undefined,
  departments?: (Department | null)[] | null | undefined,
  isInvitedUser?: boolean,
) =>
  !!(
    ((locations && locations.length > 0) ||
      (departments && departments.length > 0)) &&
    !isInvitedUser
  );
