import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { SkeletonPulse } from 'Shared/Skeleton';
import { ShortCardVariants } from '../Shorts/constants';
import {
  CardContentContainer,
  CardDecorationStyle,
  CardDetailsTop,
  CardImageContainerStyle,
  CardMetadata,
  CardTitleStyle,
  CourseCardStyle,
} from './CourseCard';

const SKELETON_TEXT_HEIGHT = rem(11);
const SKELETON_HORIZONTAL_IMAGE_HEIGHT = rem(82);

export const CardSkeleton = styled.div`
  ${CardDecorationStyle}
  ${CourseCardStyle}
`;

export const CardImageContainerSkeleton = styled(SkeletonPulse)<{
  horizontalVariant?: boolean;
}>`
  ${CardImageContainerStyle}
  max-height: ${({ horizontalVariant }) =>
    horizontalVariant ? SKELETON_HORIZONTAL_IMAGE_HEIGHT : 'initial'};
`;

export const CardTitleSkeleton = styled(SkeletonPulse)`
  border-radius: 5px;
  width: 80%;
  height: ${SKELETON_TEXT_HEIGHT};

  ${CardTitleStyle}
`;

const CardMetadataSkeleton = styled(SkeletonPulse)`
  border-radius: 5px;
  width: 60%;
  height: ${SKELETON_TEXT_HEIGHT};
`;

const SkeletonCard = ({
  variant = ShortCardVariants.VERTICAL,
}: {
  variant?: ShortCardVariants;
}) => {
  const horizontalVariant = variant === ShortCardVariants.HORIZONTAL;

  return (
    <CardSkeleton data-testid="skeleton-card">
      <CardImageContainerSkeleton horizontalVariant={horizontalVariant} />
      {!horizontalVariant ? (
        <div>
          <CardContentContainer>
            <CardDetailsTop>
              <CardTitleSkeleton />
            </CardDetailsTop>
            <CardMetadata>
              <CardMetadataSkeleton />
            </CardMetadata>
          </CardContentContainer>
        </div>
      ) : null}
    </CardSkeleton>
  );
};

export default SkeletonCard;
