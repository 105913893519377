import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getActions } from './__generated__/getActions';

export const ACTIONS_QUERY = gql`
  query getActions($indexScheduleId: String!) {
    recommendedActions(indexScheduleId: $indexScheduleId) {
      actionId
      title
      themeId
      markedAsTaken
      score
      orderNumber
      riskCategory
    }
  }
`;

export const useActions = ({
  indexScheduleId,
  skip,
}: {
  indexScheduleId: string;
  skip: boolean;
}) =>
  useQuery<getActions>(ACTIONS_QUERY, {
    variables: {
      indexScheduleId,
    },
    skip,
    fetchPolicy: 'cache-and-network',
  });
