import { Severity } from '@sentry/browser';
import { getUserLocale } from '../i18n/getUserLocale';
import { logException } from '../App/logging';
import { CloudinaryMedia } from '../Series/SingleSeries/SeriesDay/SeriesSegment/withSeriesSegmentMedia';

/**
 * @deprecated Unmind assets are now stored in Cloudinary
 * therefore shouldn't be a use case for the base CDN for media paths
 * If you need to use this, have you got the correct media fields from the API?
 * Get the full URL to a media source on the Unmind CDN, unless full media URL is from Cloudinary/other sources
 */
export const getMediaUrl = (mediaSrc: string, fullMediaUrl = false) =>
  fullMediaUrl ? mediaSrc : `${process.env.REACT_APP_CDN_BASE_URL}${mediaSrc}`;

/**
 * Get cloudinary media URL with assetToken
 * Use s3 media as a fallback and trigger a warning
 */
export const getMediaSrc = ({
  cloudinaryMedia,
  assetToken,
  contentId,
  legacyMediaUrl = '',
}: {
  cloudinaryMedia?: CloudinaryMedia | null;
  assetToken?: string;
  contentId?: string;
  legacyMediaUrl?: string;
}) => {
  if (cloudinaryMedia && cloudinaryMedia.path && assetToken) {
    return `${cloudinaryMedia.path}?${assetToken}`;
  }

  const locale = getUserLocale();

  if (!cloudinaryMedia) {
    logException(new Error('Missing Cloudinary asset'), {
      level: Severity.Warning,
      tags: {
        owner: 'content-team',
        contentId: contentId || 'missing-id',
        locale,
      },
    });
  }

  return getMediaUrl(legacyMediaUrl);
};
