import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled from 'styled-components';

export const CardHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))``;

export const Score = styled.div<{ large?: boolean }>`
  font-size: ${({ theme, large }) =>
    large
      ? rem(theme.typography.fontSizes.fontSize32)
      : rem(theme.typography.fontSizes.fontSize14)};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.text.primary};
`;
