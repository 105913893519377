import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LatestCompassIndex } from './__generated__/LatestCompassIndex';

export const LATEST_COMPASS_INDEX_QUERY = gql`
  query LatestCompassIndex($ownerId: ID!) {
    latestCompassIndex(ownerId: $ownerId) {
      latestSchedule {
        id
        cohortSize
        numResponses
        startDate
        endDate
      }
    }
  }
`;

export const TEMPORARY_OWNER_ID = 'to-be-replaced';

export const useLatestCompassIndex = () => {
  const { data, loading, refetch } = useQuery<LatestCompassIndex>(
    LATEST_COMPASS_INDEX_QUERY,
    {
      variables: {
        ownerId: TEMPORARY_OWNER_ID, //set in core-api see here for more details https://github.com/unmind/core-api/pull/1453
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  return { data, loading, refetch };
};
