import React from 'react';
import { IconProps } from '../IconProps';

const SvgTranscript = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.01 1.299a.718.718 0 0 0-.506.206.69.69 0 0 0-.205.49v13.46a.69.69 0 0 0 .205.49.718.718 0 0 0 .505.206h.978a.65.65 0 0 1 0 1.299h-.978c-.53 0-1.04-.209-1.418-.582A1.988 1.988 0 0 1 0 15.455V1.995C0 1.464.213.955.591.582A2.017 2.017 0 0 1 2.009 0H12.89c.531 0 1.041.209 1.419.582.377.374.59.882.59 1.413v.78a.65.65 0 0 1-1.298 0v-.78a.69.69 0 0 0-.206-.49.718.718 0 0 0-.505-.206H2.01z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.56 3.846a.718.718 0 0 0-.505.206.69.69 0 0 0-.206.49v13.46a.69.69 0 0 0 .206.49.718.718 0 0 0 .505.206h10.881c.19 0 .372-.075.505-.206a.69.69 0 0 0 .206-.49V4.541a.69.69 0 0 0-.206-.49.718.718 0 0 0-.505-.206H4.561zm-1.418-.717a2.017 2.017 0 0 1 1.418-.582h10.881c.53 0 1.041.208 1.418.582.378.374.591.882.591 1.413v13.46c0 .53-.213 1.04-.59 1.413a2.017 2.017 0 0 1-1.419.582H4.561c-.532 0-1.042-.209-1.42-.582A1.988 1.988 0 0 1 2.552 18V4.542c0-.531.213-1.04.59-1.413z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.375 8.087c0-.359.29-.65.65-.65h5.95a.65.65 0 0 1 0 1.3h-5.95a.65.65 0 0 1-.65-.65zM6.375 11.063c0-.358.29-.649.65-.649h5.95a.65.65 0 1 1 0 1.299h-5.95a.65.65 0 0 1-.65-.65zM6.375 14.462c0-.359.29-.65.65-.65h5.95a.65.65 0 0 1 0 1.3h-5.95a.65.65 0 0 1-.65-.65z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
  </svg>
);

export default SvgTranscript;
