import { useState } from 'react';
import { isSameDay } from 'date-fns';
import { useLastViewedDisclaimer } from '.';

const DAY_MILLISECONDS = 86400000;

function shouldShowDisclaimer(lastViewedDisclaimer: Date | null) {
  if (lastViewedDisclaimer === null) {
    return true;
  }

  const current = new Date();
  const difference = current.getTime() - lastViewedDisclaimer.getTime();
  const days = difference / DAY_MILLISECONDS;

  // Want to also show disclaimer if it's the same day as today.
  const isSameDayAsToday = isSameDay(current, lastViewedDisclaimer);

  return isSameDayAsToday || days >= 7;
}

export function useShowDisclaimer(): boolean {
  const [lastViewedDisclaimer, setLastViewedDisclaimer] =
    useLastViewedDisclaimer();
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);

  if (lastViewedDisclaimer === undefined) {
    return false;
  }

  if (!disclaimerVisible) {
    if (!shouldShowDisclaimer(lastViewedDisclaimer)) {
      return false;
    }

    void setLastViewedDisclaimer(new Date(Date.now()));
    setDisclaimerVisible(true);
  }

  return disclaimerVisible;
}
