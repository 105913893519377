import isNil from 'lodash/isNil';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from 'styles';

const Container = styled.article`
  position: relative;
`;

const BlockLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
`;

const Card = ({ children, to, className, ...props }: Partial<LinkProps>) => (
  <Container className={className}>
    {children}
    {!isNil(to) ? <BlockLink to={to} {...props} /> : null}
  </Container>
);

export default Card;
