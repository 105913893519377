import React from 'react';
import { IconProps } from '../IconProps';
const SvgCircularCheck = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z"
      fill="#D0F2CF"
    />
    <path
      d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z"
      stroke="#31B62F"
    />
    <g clipPath="url(#CircularCheck_svg__clip0_1586_4742)">
      <path
        d="M13 21.67l2.94 3.78a1.077 1.077 0 001.68.032L27 14.13"
        stroke="#31B62F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="CircularCheck_svg__clip0_1586_4742">
        <path fill="#fff" transform="translate(12 12)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCircularCheck;
