import { Search } from 'icons';
import { rem } from 'polished';
import styled from 'styled-components';
import { BodyText } from '@unmind/design-system-components-web';

export const EmptyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${rem(8)};
  padding: ${rem(24)};
  margin-top: ${rem(24)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  color: ${({ theme }) => theme.colors.background.inverse};
  border-radius: 8px;
`;

export const MagIcon = styled(Search).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.text.primary,
}))`
  height: ${rem(20)};
  width: ${rem(20)};
  margin-right: ${rem(8)};
`;

export const EmptyTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const EmptyTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${rem(4)};
`;

export const EmptySubtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
  ],
}))`
  color: ${({ theme }) => theme.colors.text.primary};
`;
