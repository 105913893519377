import SecondaryButton from 'Shared/SecondaryButton';
import { FieldArray } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Plus } from 'icons';
import { BodyText } from '@unmind/design-system-components-web';
import { validateEmail } from 'Shared/Form/Formik';
import { rem } from 'polished';
import { Input } from 'Shared/Form';
import FieldError from 'Shared/Form/FieldError';
import { SectionHeader } from './CompassSurveyModal/styles';

const InviteesList = styled.ul`
  list-style: none;
  padding: 0;
  padding-right: ${rem(8)};
  overflow-y: scroll;
  max-height: ${rem(240)};
`;

const AddMemberButton = styled(SecondaryButton)`
  width: 100%;
  height: ${rem(40)};
  padding: 0;
  margin-top: ${rem(20)};
`;

const AddMemberButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const InviteeItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${rem(20)} 0;

  &:first-child {
    margin-top: 0;
  }
`;

const AddMemberButtonText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(8)};
`;

const StyledInput = styled(Input)`
  border-radius: ${rem(12)};
`;

const PlusIcon = styled(Plus).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.background.card,
}))`
  margin-left: ${rem(8)};
  width: ${rem(20)};
  height: ${rem(20)};
  transform: rotate(45deg);
`;

const RemoveIcon = styled(Plus)`
  cursor: pointer;
  width: ${rem(24)};
  height: ${rem(24)};
  margin-left: ${rem(8)};
  flex-shrink: 0;
  &:hover {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
`;

interface InviteesSectionProps {
  invitees: string[];
  error?: string;
}

const InviteesSection = ({ invitees, error }: InviteesSectionProps) => {
  const [newInvitee, setNewInvitee] = useState('');
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);

  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'create_compass_modal.invitees',
  });

  const handleAddInvitee = (push: (obj: unknown) => void) => {
    const invalidEmail = validateEmail(newInvitee);

    if (invitees.includes(newInvitee)) {
      setDuplicateEmailError(true);

      return;
    }

    if (newInvitee && !invalidEmail) {
      setDuplicateEmailError(false);
      push(newInvitee);
      setNewInvitee('');
    }
  };

  const handleInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    push: (obj: unknown) => void,
  ) => {
    if (e.key === 'Enter') {
      handleAddInvitee(push);
      e.preventDefault();
    }
  };

  return (
    <>
      <SectionHeader>{translate('title')}</SectionHeader>
      <FieldArray name="invitees">
        {({ push, remove }) => (
          <>
            <InviteesList>
              {invitees.map((invitee, index) => (
                <InviteeItem key={invitee}>
                  <AddMemberButtonText>{invitee}</AddMemberButtonText>
                  <RemoveIcon
                    aria-label={translate('remove_invitee_button.aria_label', {
                      name: invitee,
                    })}
                    role="button"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </InviteeItem>
              ))}
              {error && <FieldError message={error} />}
            </InviteesList>
            <InputLabel>{translate('input.label')}</InputLabel>
            {duplicateEmailError && (
              <FieldError message={translate('duplicate_email_error')} />
            )}
            <StyledInput
              placeholder={translate('input.placeholder')}
              onChange={e => {
                setDuplicateEmailError(false);
                setNewInvitee(e.target.value);
              }}
              onKeyDown={e => handleInputKeyDown(e, push)}
              aria-label={translate('title')}
              value={newInvitee}
            />
            <AddMemberButton
              onClick={() => {
                handleAddInvitee(push);
              }}
              type="button"
              disabled={!newInvitee}
              label={
                <AddMemberButtonLabel>
                  <AddMemberButtonText>
                    {translate('add_invitees_button.label')}
                  </AddMemberButtonText>
                  <PlusIcon />
                </AddMemberButtonLabel>
              }
            />
          </>
        )}
      </FieldArray>
    </>
  );
};

export default InviteesSection;
