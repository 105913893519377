import React from 'react';
import { useTranslation } from 'react-i18next';
import { tracking } from '../../App/Tracking';
import { useIsVirginPulseGroup } from '../VirginPulse/useIsVirginPulseGroup';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import {
  ExternalLinkIcon,
  FAQLink,
  HelpCentreContainer,
  HelpCentreTitle,
  LinkArea,
} from './AuthWrapperHelpCentre.styled';

export const AuthWrapperHelpCentre = () => {
  const { t: translate } = useTranslation('shared');
  const { isVirginPulseGroup, loading: loadingIsVirginPulseGroup } =
    useIsVirginPulseGroup();
  const subdomain = getSubdomainFromUrl();

  if (loadingIsVirginPulseGroup) {
    return <LoadingIndicator />;
  }

  const faqUrl = isVirginPulseGroup
    ? 'auth_wrapper_help_centre.faq_link.virgin_pulse_url'
    : 'auth_wrapper_help_centre.faq_link.url';

  return (
    <HelpCentreContainer>
      <HelpCentreTitle>
        {translate('auth_wrapper_help_centre.need_help.text')}
      </HelpCentreTitle>
      <LinkArea>
        <FAQLink
          href={translate(faqUrl)}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="help-centre-link"
          onClick={() => {
            tracking.track('faq-clicked', {
              subdomain,
              partner: isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null,
            });
          }}
          aria-label={translate('auth_wrapper_help_centre.faq_link.a11y_label')}
        >
          {translate('auth_wrapper_help_centre.faq_link.text')}
          <ExternalLinkIcon aria-hidden />
        </FAQLink>
      </LinkArea>
    </HelpCentreContainer>
  );
};
