import { useState } from 'react';
import { ChatbotMessageRole } from '__generated__/globalTypes';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AssistantStreamMessage,
  Message,
  UserMessage,
  ConversationUpdateType,
  ChatbotHTTPStreamingInput,
} from '../types';

export type StreamOutput = {
  messages: Message[];
  isSubmittingMessage: boolean;
  isStreamResponseComplete: boolean;
  submitMessage(message: UserMessage): Promise<void>;
};

type UserConversationHistoryParams = {
  onConversationUpdated?(updateType: ConversationUpdateType): void;
};

const { REACT_APP_CHAT_API_URL } = process.env;
/**
 * NOTE:
 * This hook is purely demo-ware and should only be used behind demo feature flag in production
 * Its purpose is to demonstrate how to use the http streaming endpoint to send and receive messages, it
 * has yet to be unit tested and does not handle errors or edge cases very well.
 */

export const useHttpStream = ({
  onConversationUpdated,
}: UserConversationHistoryParams): StreamOutput => {
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const [isStreamResponseComplete, setIsStreamResponseComplete] =
    useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const { getAccessTokenSilently, isAuthenticated: isAuthenticatedViaAuth0 } =
    useAuth0();

  const getAuthToken = async (): Promise<Record<string, string>> => {
    if (isAuthenticatedViaAuth0) {
      try {
        const token = await getAccessTokenSilently();

        return { ['x-api-authorization']: `Bearer ${token}` };
      } catch (e) {
        // TODO: log auth0 token errors to somewhere useful (e.g. sentry)
        console.error(e);
      }
    }

    // otherwise, send the usual string token to the server
    return { ['x-api-key']: localStorage.getItem('token') || '' };
  };

  const handleChunk = (chunk: AssistantStreamMessage) => {
    const mappedChunk: Message = {
      id: chunk.id,
      source: chunk.content[0].value,
      role: ChatbotMessageRole.assistant,
      createdAt: new Date().toISOString(),
    };

    // Append chunk to the last message in state or create a new message in state
    setMessages(prevMessages => {
      const lastMessageIndex = prevMessages.length - 1;
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[lastMessageIndex];

      if (
        lastMessage.role === ChatbotMessageRole.assistant &&
        lastMessage.id === mappedChunk.id
      ) {
        lastMessage.source += mappedChunk.source;

        return updatedMessages;
      } else {
        return [...prevMessages, mappedChunk];
      }
    });
  };

  const mapMessageMessagesToInput = (
    message: Message,
  ): ChatbotHTTPStreamingInput => {
    if (message.role === ChatbotMessageRole.assistant) {
      return {
        content: message.source,
        ...message,
      };
    }

    return { ...message };
  };

  const submitMessage = async (message: UserMessage) => {
    try {
      setIsSubmittingMessage(true);
      setIsStreamResponseComplete(false);
      onConversationUpdated?.('NEW_MESSAGE');

      const newMessage: Message = {
        ...message,
        role: ChatbotMessageRole.user,
      };

      setMessages(prevMessages => [...prevMessages, newMessage]);

      const authHeaders = await getAuthToken();
      const response = await fetch(
        `${REACT_APP_CHAT_API_URL}/chat_stream?chat_model_id=disney_demo`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            ...authHeaders,
          },
          body: JSON.stringify({
            messages: [...messages, newMessage].map(mapMessageMessagesToInput),
            /**
             * Removing need to pass metadata. As auth middleware will fetch this
             * information for us when calling 'chat_stream' endpoint
             */
          }),
        },
      );

      if (response && response.body) {
        setIsSubmittingMessage(false);
        const reader = response.body.getReader();
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const decoded = new TextDecoder().decode(value, { stream: true });
          const decodedChunks = decoded.split('\n');

          for (const decodedChunk of decodedChunks) {
            if (decodedChunk && decodedChunk.trim().length > 0) {
              try {
                const chunk: AssistantStreamMessage = JSON.parse(decodedChunk);
                handleChunk(chunk);
              } catch (error) {
                console.error(error);
              }
            }
          }
        }
        setIsStreamResponseComplete(true);
      }
    } catch (error) {
      console.error('Error fetching http stream:', error);
    }
  };

  return {
    messages,
    isSubmittingMessage,
    isStreamResponseComplete,
    submitMessage,
  };
};
