import React from 'react';
import { IconProps } from '../IconProps';
const SvgCheck = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx={10} cy={10} r={10} fill="#0D0300" fillOpacity={0.85} />
    <path
      d="M5 11.385l2.1 2.7a.77.77 0 001.2.023L15 6"
      stroke="#fff"
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCheck;
