/**
 *
 * The downshift select dropdown has a buggy behaviour on IE
 * around the onchange event (see ENG-963 JIRA ticket).
 * This native select is a solution to tackle a11y for IE
 */

import { ellipsis, rem } from 'polished';
import React, { FormEvent, useMemo } from 'react';

import { css, styled, ThemeInterface } from 'styles';

import { FormElementDisplay } from '../Layout';
import {
  selectPaddingCss,
  SelectProps,
  selectTypographyCss,
  ToggleArrow,
} from './Select';

export enum SelectSize {
  Big,
  Regular,
  Compact,
}

interface SelectElementProps {
  containerDisplay?: FormElementDisplay;
  containerSize?: SelectSize;
  theme: ThemeInterface;
  hasError?: boolean;
  selected: boolean;
}

const Container = styled.div`
  position: relative;

  ${ToggleArrow} {
    margin-right: ${rem('4px')};
  }
`;

const Select = styled.select<SelectElementProps>`
  width: 100%;
  text-align: left;

  border: ${rem('1px')} solid
    ${({ hasError, theme }) =>
      hasError ? theme.colors.background.error : theme.colors.text.secondary};
  border-radius: ${rem(4)};

  appearance: none;

  ${({ containerSize }) => selectTypographyCss({ containerSize })}

  ${({ selected }) =>
    selected
      ? css`
          color: ${({ theme }) => theme.colors.text.primary};
          ${ellipsis()}
        `
      : css`
          color: ${({ theme }) => theme.colors.text.secondary};
        `}

  /* hides the default arrow */
  &::-ms-expand {
    display: none;
  }
  &:focus::-ms-value {
    background: transparent;
    color: inherit;
    outline-style: dotted;
    outline-width: thin;
  }
  &::-ms-value {
    ${({ containerDisplay, containerSize }) =>
      selectPaddingCss({ containerDisplay, containerSize })}
  }
`;

const SelectNative = ({
  display,
  size,
  error,
  options = [],
  onChange,
  placeholder,
  value,
}: SelectProps) => {
  const selectedItem = useMemo(
    () => options.find(o => o.value === value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );
  const hasError = Boolean(error);
  const hasSelectedItem = Boolean(selectedItem);
  const handleOnChange = (e: FormEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  return (
    <Container>
      <Select
        containerDisplay={display}
        containerSize={size}
        selected={hasSelectedItem}
        hasError={hasError}
        onChange={handleOnChange}
        value={value || undefined}
      >
        <option
          value={undefined}
          disabled={hasSelectedItem}
          aria-selected={!selectedItem}
        >
          {placeholder}
        </option>
        {options.map(({ label, value: optionValue }) => (
          <option
            key={optionValue}
            aria-selected={hasSelectedItem && optionValue === value}
            value={optionValue}
          >
            {label}
          </option>
        ))}
      </Select>
      <ToggleArrow />
    </Container>
  );
};

export default SelectNative;
