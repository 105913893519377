import React from 'react';
import { IconProps } from '../IconProps';

const SvgVideoPlayer = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 33" fill="none" {...props}>
    <rect
      x={6.28}
      y={3.938}
      width={19.44}
      height={27}
      rx={1}
      fill={primaryColor}
      stroke={secondaryColor}
      strokeOpacity={0.95}
    />
    <path
      d="M13 21.174v-7.516c0-.559.536-.894.956-.626l5.717 3.758a.792.792 0 0 1 .24.276.756.756 0 0 1 0 .7.793.793 0 0 1-.24.277l-5.717 3.847c-.42.179-.956-.157-.956-.716z"
      fill={tertiaryColor}
      stroke={secondaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.12 8.258H1.88a1 1 0 0 0-1 1v17.44a1 1 0 0 0 1 1h2.24M27.88 8.258h2.24a1 1 0 0 1 1 1v17.44a1 1 0 0 1-1 1h-2.24"
      stroke={secondaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgVideoPlayer;
