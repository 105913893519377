import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from 'utils/getSubdomainFromUrl';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { EmailOnlyFormFields } from '../../Shared/Form/Formik';
import {
  NewAuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapper,
} from '../AuthWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import EmailInputForm from '../SignUp/Forms/EmailInputForm';
import { useMagicLogin } from './useMagicLogin';

type FormFields = EmailOnlyFormFields;

const MagicLogin = () => {
  const subdomain = getSubdomainFromUrl();
  const { groupId } = useSubdomainInfo({
    subdomain: subdomain,
    skip: !isClientSubdomain(subdomain),
  });

  const { requestMagicLogin, loading } = useMagicLogin();
  const { t: sharedTranslate } = useTranslation('shared');
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'login.forms.magic_login',
  });
  const formikRef = useRef<Formik<FormFields>>(null);

  const history = useHistory();
  const [error, setError] = useState<string | undefined>();

  const errorMessage = sharedTranslate('errors.messages.something_wrong');

  async function onSubmit({ emailInput }: FormFields) {
    if (!emailInput) {
      setError(errorMessage);

      return;
    }

    setError(undefined);

    const { error } = await requestMagicLogin({
      email: emailInput,
      groupId,
    });

    if (error) {
      return setError(errorMessage);
    }

    tracking.track('magic-link-sent', { Subdomain: subdomain });
    history.push(RoutePath.MagicLoginConfirmation, { email: emailInput });
  }

  const emailLabel = translate('email_label');
  const submitLabel = translate('submit_button.label');

  return (
    <AuthWrapper newDesign showCreateAccountButton={false}>
      <NewAuthWrapperBlock>
        <AuthWrapperTitle data-testid="title">
          {translate('heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="subtitle">
          {translate('subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailInputForm
            formikRef={formikRef}
            inputLabel={emailLabel}
            submitLabel={submitLabel}
            onSubmit={onSubmit}
            loading={loading}
            setError={setError}
            error={error}
          ></EmailInputForm>
        </AuthWrapperContent>
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
};

export default MagicLogin;
