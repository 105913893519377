import React from 'react';
import { IconProps } from '../IconProps';

const SvgVolumeMuted = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.607 2a.75.75 0 0 0-1.225-.58l-7.65 6.259H2a.75.75 0 0 0-.75.75v7.142c0 .415.336.75.75.75h4.732l7.65 6.26A.75.75 0 0 0 15.607 22V2zM7.475 9.01l6.632-5.427v16.834l-6.632-5.426a.75.75 0 0 0-.475-.17H2.75V9.18H7a.75.75 0 0 0 .475-.17zm11.055.46a.75.75 0 1 0-1.06 1.06L18.94 12l-1.47 1.47a.75.75 0 1 0 1.06 1.06L20 13.06l1.47 1.47a.75.75 0 1 0 1.06-1.06L21.06 12l1.47-1.47a.75.75 0 1 0-1.06-1.06L20 10.94l-1.47-1.47z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgVolumeMuted;
