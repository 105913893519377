import React from 'react';
import { IconProps } from '../IconProps';
const SvgApple = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 22" fill="none" {...props}>
    <g clipPath="url(#Apple_svg__clip0_2380_35599)">
      <path
        d="M18.372 8.082c-.12.093-2.248 1.292-2.248 3.957 0 3.083 2.707 4.174 2.788 4.2-.012.067-.43 1.494-1.427 2.949-.89 1.28-1.818 2.557-3.23 2.557-1.413 0-1.777-.82-3.407-.82-1.59 0-2.155.847-3.447.847-1.292 0-2.194-1.184-3.23-2.638C2.97 17.426 2 14.774 2 12.255c0-4.038 2.626-6.18 5.21-6.18 1.373 0 2.518.902 3.38.902.82 0 2.1-.956 3.662-.956.592 0 2.72.054 4.12 2.06zm-4.861-3.77c.646-.767 1.103-1.83 1.103-2.894 0-.148-.013-.298-.04-.418-1.05.04-2.301.7-3.055 1.575-.592.673-1.145 1.736-1.145 2.815 0 .162.027.324.04.376.066.012.174.027.282.027.943 0 2.13-.632 2.815-1.482z"
        fill="#606060"
      />
    </g>
    <defs>
      <clipPath id="Apple_svg__clip0_2380_35599">
        <path fill="#fff" transform="translate(2 1)" d="M0 0h16.91v20.774H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgApple;
