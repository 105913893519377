import React from 'react';
import { IconProps } from '../IconProps';

const SvgCalm = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#F0FAFF" d="M0 0h340v192H0z" />
    <g filter="url(#Calm_svg__filter0_b_1466:10960)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.849 162.142c37.542-17.827 80.977-38.453 104.32-118.87 6.641-22.878 14.63-36.706 23.4-43.272H340v192H137.903c12.413-13.46 29.007-21.34 46.946-29.858z"
        fill="#0024E0"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.386 17.696c-32.524 13.37-70.154 28.84-102.37 105.709-.34.809-.678 1.606-1.016 2.39V0h137.819c-10.344 7.793-22.016 12.591-34.433 17.696z"
      fill="#011165"
    />
    <defs>
      <filter
        id="Calm_svg__filter0_b_1466:10960"
        x={117.903}
        y={-20}
        width={242.097}
        height={232}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={10} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1466:10960"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1466:10960"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgCalm;
