import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoodFacePlus } from 'icons';
import RoutePath from '../../App/RoutePath';
import { FullPageGeneralError } from '../../Shared/AlertView';
import EmptyState from '../../Shared/EmptyState';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { TrackEmptyStateContainer } from '../Track';
import { CHECK_IN_SCORES_QUERY } from './getCheckInScores';
import {
  filterAllCheckInData,
  filterLastMonthCheckinsData,
} from './MoodScores/helpers/filterCheckInData';
import { getAverageMoodsByMonth } from './MoodScores/helpers/getAverageMoodsByMonth';
import { getGroupedCheckInsByMood } from './MoodScores/helpers/getGroupedCheckInsByMood';
import MoodScores from './MoodScores/MoodScores';
import { getCheckInScores } from './__generated__/getCheckInScores';
import MoodTrackerCTA from './MoodTracker/MoodTrackerCTA';

const MoodsScoreScreen = () => {
  const { t: translate } = useTranslation('track');

  const { data, loading, error } = useQuery<getCheckInScores>(
    CHECK_IN_SCORES_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  if (Boolean(error)) {
    return <FullPageGeneralError />;
  }

  if (
    data &&
    data.allCheckins &&
    data.allCheckins.length > 0 &&
    data.lastMonthCheckins
  ) {
    const filterAllCheckins = filterAllCheckInData(data.allCheckins);
    const filteredLastMonthCheckins = filterLastMonthCheckinsData(
      data.lastMonthCheckins,
    );

    return (
      <>
        <MoodTrackerCTA source="track-moods" />
        <MoodScores
          allCheckins={filterAllCheckins}
          lastMonthCheckins={filteredLastMonthCheckins}
          averageMoodByMonth={getAverageMoodsByMonth(filterAllCheckins)}
          allCheckinsByMood={getGroupedCheckInsByMood(filterAllCheckins)}
        />
      </>
    );
  }

  return (
    <>
      <TrackEmptyStateContainer>
        <EmptyState
          emptyStateIcon={MoodFacePlus}
          buttonTitle={translate('moods.empty_state.record_button.title')}
          title={translate('moods.empty_state.title')}
          description={translate('moods.empty_state.description')}
          to={{
            pathname: RoutePath.CheckIn,
          }}
        />
      </TrackEmptyStateContainer>
    </>
  );
};

export default MoodsScoreScreen;
