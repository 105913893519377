import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { small } from '../../utils';
import { CardDecorationStyle } from '../../Courses/CourseCard';
import useViewportWidth from '../../utils/useViewportWidth';
import SkeletonCard from '../../Courses/SkeletonCard';

const ContentList = styled.div`
  display: flex;
`;

const ContentCardWrapper = styled.div`
  ${CardDecorationStyle}
  width: calc((100% - ${rem(16)}) / 2);
  margin-right: 16px;

  @media (max-width: ${rem(600)}) {
    &:nth-of-type(2n) {
      margin-right: 0px;
    }
  }

  ${small(css`
    width: calc((100% - ${rem(32)}) / 3);
    &:nth-of-type(3n) {
      margin-right: 0px;
    }
  `)}
`;

const GenericExploreContentSkeleton = () => {
  const width = useViewportWidth();

  const Card = () => (
    <ContentCardWrapper>
      <SkeletonCard />
    </ContentCardWrapper>
  );

  return (
    <ContentList data-testid="skeleton-cards">
      {width <= 600 ? (
        <>
          <Card />
          <Card />
        </>
      ) : (
        <>
          <Card />
          <Card />
          <Card />
        </>
      )}
    </ContentList>
  );
};

export default GenericExploreContentSkeleton;
