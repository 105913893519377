import {
  formatISO,
  lastDayOfMonth,
  parse,
  startOfMonth,
  sub,
  subDays,
  subMonths,
} from 'date-fns';
import { TimePeriod } from '../../../__generated__/globalTypes';
import { DateRange } from './types';

const toISODate = (date: Date) => formatISO(date, { representation: 'date' });

export const get30DaysDateRange = (): DateRange => {
  const endDate = subDays(new Date(), 1);
  const startDate = subDays(endDate, 29);

  return {
    startDate: toISODate(startDate),
    endDate: toISODate(endDate),
  };
};

export const getWeeksEndDate = () => {
  const date = new Date();
  const daysToSubtract = date.getDay() || 7;
  const newDate = subDays(date, daysToSubtract);

  return newDate;
};

const getDateMinusWeeks = (endDate: Date, weeksToSubtract: number) =>
  sub(endDate, { weeks: weeksToSubtract - 1, days: 6 });

/**
 * Get the 12 week date range props for Admin Insights queries.
 */
export const get12WeekDateRange = (): DateRange => {
  const endDate = getWeeksEndDate();

  return {
    startDate: toISODate(getDateMinusWeeks(endDate, 12)),
    endDate: toISODate(endDate),
  };
};

export const getMonthsEndDate = () => {
  const date = new Date();
  const lastMonth = subMonths(date, 1);

  return lastDayOfMonth(lastMonth);
};

const getDateMinusMonths = (endDate: Date, monthsToSubtract: number) => {
  const endOfStartMonth = subMonths(endDate, monthsToSubtract - 1);

  return startOfMonth(endOfStartMonth);
};

/**
 * Get the 6 month date range props for Admin Insights queries.
 */
export const get6MonthDateRange = (): DateRange => {
  const endDate = getMonthsEndDate();

  return {
    startDate: toISODate(getDateMinusMonths(endDate, 6)),
    endDate: toISODate(endDate),
  };
};

/**
 * Get the All Time date range props for Admin Insights queries.
 */
export const getAllTimeDateRange = (): DateRange => {
  const endDate = subDays(new Date(), 1);

  return {
    // startDate is the year we start with the time_dimension table in the backend
    startDate: '2019-01-01',
    endDate: toISODate(endDate),
  };
};

const datePresetToPreviousRange: Record<
  TimePeriod,
  (prevRangeEndDate: Date) => Date
> = {
  [TimePeriod.LAST_30_DAYS]: prevRangeEndDate => subDays(prevRangeEndDate, 29),
  [TimePeriod.LAST_12_WEEKS]: prevRangeEndDate =>
    getDateMinusWeeks(prevRangeEndDate, 12),
  [TimePeriod.LAST_6_MONTHS]: prevRangeEndDate =>
    getDateMinusMonths(prevRangeEndDate, 6),
  // Not really applicable, but a value is required
  [TimePeriod.ALL_TIME]: prevRangeEndDate =>
    getDateMinusMonths(prevRangeEndDate, 6),
};

/**
 * Get the previous date range props for Admin Insights queries.
 */
export const getPreviousPeriodDateRange = (
  dateRange: DateRange,
  datePreset: TimePeriod,
): DateRange => {
  const start = parse(dateRange.startDate, 'yyyy-MM-dd', new Date());
  const prevRangeEndDate = subDays(start, 1);
  const prevRangeStartDate =
    datePresetToPreviousRange[datePreset](prevRangeEndDate);

  return {
    startDate: toISODate(prevRangeStartDate),
    endDate: toISODate(prevRangeEndDate),
  };
};

const datePresetToRange: Record<TimePeriod, () => DateRange> = {
  [TimePeriod.LAST_30_DAYS]: get30DaysDateRange,
  [TimePeriod.LAST_12_WEEKS]: get12WeekDateRange,
  [TimePeriod.LAST_6_MONTHS]: get6MonthDateRange,
  [TimePeriod.ALL_TIME]: getAllTimeDateRange,
};

export const getDateRange = (datePreset: TimePeriod): DateRange =>
  datePresetToRange[datePreset]();
