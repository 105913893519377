import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import Modal from 'Shared/Modal';
import { useLocationData } from 'Talk/hooks';
import { Namespace } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MatchingQuestionnaireTakeover } from '..';
import { SetLocationForm } from '../../SetLocation';
import {
  LocationFormCopy,
  LocationFormTitle,
  UpdateLocationFormWrapper,
} from '../../SetLocation/styles';
import CTAHero from '../assets/cta_hero.png';
import {
  CTAWrapper,
  CTAActionsContainer,
  CTABodyText,
  CTAHeaderText,
  CTAHeroImage,
  CTAPrimaryActionButton,
  CTASecondaryActionButton,
} from './styles';

export function MatchingCTA() {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { hasValidLocationData } = useLocationData();
  const history = useHistory();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [matchingQuestionnaireOpen, setMatchingQuestionnaireOpen] =
    useState(false);

  const handleBrowseButtonClick = () => {
    tracking.track('talk-home-matching-browse-clicked', {
      userHasMatches: false,
    });

    if (hasValidLocationData) {
      history.push(RoutePath.TalkBrowse);
    } else {
      setLocationModalOpen(true);
    }
  };

  const handleStartMatchingButtonClick = () => {
    tracking.track('talk-home-matching-start-clicked');
    setMatchingQuestionnaireOpen(true);
  };

  return (
    <>
      <CTAWrapper>
        <CTAHeroImage src={CTAHero} />
        <CTAHeaderText>{t('matching.cta.header')}</CTAHeaderText>
        <CTABodyText>{t('matching.cta.body')}</CTABodyText>
        <CTAActionsContainer>
          <CTASecondaryActionButton
            label={t('matching.cta.browse_button.label')}
            onClick={handleBrowseButtonClick}
            data-cy="talk-homescreen-browse-cta"
          />
          <CTAPrimaryActionButton
            variant="primary"
            label={t('matching.cta.start_button.label')}
            onClick={handleStartMatchingButtonClick}
          />
        </CTAActionsContainer>
      </CTAWrapper>

      <Modal
        open={locationModalOpen}
        modalContent={({ close }) => (
          <UpdateLocationFormWrapper>
            <LocationFormTitle>
              {t('location_modal.update_location_title')}
            </LocationFormTitle>
            <LocationFormCopy>{t('location_modal.body')}</LocationFormCopy>
            <SetLocationForm
              onSubmitSuccess={(country, state) => {
                tracking.track('place-of-residence-updated', {
                  country: country.value,
                  state: state.value,
                  source: 'talk',
                });

                close();
                history.push(RoutePath.TalkBrowse);
              }}
            />
          </UpdateLocationFormWrapper>
        )}
      />

      <MatchingQuestionnaireTakeover
        showTakeover={matchingQuestionnaireOpen}
        onClose={() => {
          setMatchingQuestionnaireOpen(false);
        }}
      />
    </>
  );
}
