import { Colors } from '@unmind/design-system-theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { InsufficientData } from 'icons';
import Panel from '../Panel';
import ErrorMessage from '../ErrorMessage';
import { AnonymityWarningMessage, useAnonymityCopy } from '../helpers';

const InsufficientDataIcon = styled(InsufficientData).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
  secondaryColor: theme.colors.staticPalette.purple.purple1,
  width: 24,
  height: 24,
}))``;

interface PanelAnonymityWarningProps {
  title: string;
  color: Colors;
  message: AnonymityWarningMessage;
}

export const PanelAnonymityWarning = ({
  title,
  color,
  message,
}: PanelAnonymityWarningProps) => {
  const { t: translate } = useTranslation('admin');
  const anonymityCopy = useAnonymityCopy(message);

  return (
    <Panel color={color} title={title}>
      <ErrorMessage
        icon={<InsufficientDataIcon />}
        headline={translate('anonymity_warning.not_enough_data')}
        description={anonymityCopy}
      />
    </Panel>
  );
};
