import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import {
  RecordSeriesQuestionnaireResponsesVariables,
  RecordSeriesQuestionnaireResponses,
} from './__generated__/RecordSeriesQuestionnaireResponses';

export const RECORD_SERIES_QUESTIONNAIRE_RESPONSES_MUTATION = gql`
  mutation RecordSeriesQuestionnaireResponses(
    $input: RecordSeriesQuestionnaireResponsesInput!
  ) {
    recordSeriesQuestionnaireResponses(input: $input) {
      code
      success
    }
  }
`;

export default function useRecordSeriesQuestionnaireResponses(
  variables: RecordSeriesQuestionnaireResponsesVariables,
) {
  return useMutation<RecordSeriesQuestionnaireResponses>(
    RECORD_SERIES_QUESTIONNAIRE_RESPONSES_MUTATION,
    {
      variables,
    },
  );
}
