import gql from 'graphql-tag';

/**
 * Queries
 */
export const ACCOUNT_UNMIND_ACCOUNT_QUERY = gql`
  query accountUnmindAccountQuery {
    unmindAccount {
      id
      name
      externalRef
      isDemo
      unmindAccountSettings {
        sessionLimitAmount
      }
    }
  }
`;
