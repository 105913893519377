import { Colors } from '@unmind/design-system-theme';
import { get } from 'lodash';
import {
  Audio,
  Eye,
  Mindfulness,
  Question,
  Timer,
  Trophy,
  Video,
  Foundations,
  Health,
  Performance,
  Relationships,
  SeriesBodyHealth,
  SeriesFoundations,
  MadeForManagers,
  SeriesPersonalGrowth,
  SeriesRelationships,
  SeriesTargetedHelp,
  SeriesSleep,
  SeriesWork,
  Wellbeing,
  IconType,
  SeriesDefault,
  Pencil,
  Cloud,
  Wave,
  NavTalk,
} from 'icons';

// SEGMENTS
export const SeriesSegmentIcons: {
  [key: string]: React.ComponentType<{
    primaryColor: string;
    secondaryColor: string;
    height?: number;
    width?: number;
  }>;
} = {
  'chat-square': NavTalk,
  eye: Eye,
  headphones: Audio,
  journal: Pencil,
  mindfulness: Mindfulness,
  questions: Question,
  reflection: Cloud,
  smile: Wave,
  pencil: Pencil,
  thinking: Cloud,
  timer: Timer,
  trophy: Trophy,
  video: Video,
};

export const OldSeriesCategorySlugs: { [key: string]: string } = {
  foundations: 'foundations',
  health: 'health',
  performance: 'performance',
  relationships: 'relationships',
  wellbeing: 'wellbeing',
  madeForManagers: 'made-for-managers',
};

export const SeriesCategorySlugs: { [key: string]: string } = {
  foundationsSeries: 'foundations-series',
  relationshipsSeries: 'relationships-series',
  bodyHealthSeries: 'body-health-series',
  targetedHelpSeries: 'targeted-help-series',
  personalGrowthSeries: 'personal-growth-series',
  sleepSeries: 'sleep-series',
  workSeries: 'work-series',
};

type CategorySlugs = typeof SeriesCategorySlugs & typeof OldSeriesCategorySlugs;

type ValueOf<T> = T[keyof T];
type SeriesCategoryTheme = {
  icon: IconType;
  colors: {
    primary: Colors;
    secondary?: 'transparent';
  };
};

export const seriesCategories: {
  [key in ValueOf<CategorySlugs> | 'default']: SeriesCategoryTheme;
} = {
  default: {
    icon: SeriesDefault,
    colors: { primary: Colors.purple8 },
  },
  [SeriesCategorySlugs.foundationsSeries]: {
    icon: SeriesFoundations,
    colors: {
      primary: Colors.blue8,
    },
  },
  [SeriesCategorySlugs.relationshipsSeries]: {
    icon: SeriesRelationships,
    colors: {
      primary: Colors.pink8,
    },
  },
  [SeriesCategorySlugs.bodyHealthSeries]: {
    icon: SeriesBodyHealth,
    colors: {
      primary: Colors.green8,
    },
  },
  [SeriesCategorySlugs.targetedHelpSeries]: {
    icon: SeriesTargetedHelp,
    colors: {
      primary: Colors.orange8,
    },
  },
  [SeriesCategorySlugs.personalGrowthSeries]: {
    icon: SeriesPersonalGrowth,
    colors: {
      primary: Colors.aqua8,
    },
  },
  [SeriesCategorySlugs.sleepSeries]: {
    icon: SeriesSleep,
    colors: {
      primary: Colors.indigo9,
    },
  },
  [SeriesCategorySlugs.workSeries]: {
    icon: SeriesWork,
    colors: {
      primary: Colors.orange8,
    },
  },
  [OldSeriesCategorySlugs.foundations]: {
    icon: Foundations,
    colors: {
      primary: Colors.bluish,
      secondary: 'transparent',
    },
  },
  [OldSeriesCategorySlugs.health]: {
    icon: Health,
    colors: {
      primary: Colors.leather,
      secondary: 'transparent',
    },
  },
  [OldSeriesCategorySlugs.performance]: {
    icon: Performance,
    colors: {
      primary: Colors.salmon,
      secondary: 'transparent',
    },
  },
  [OldSeriesCategorySlugs.relationships]: {
    icon: Relationships,
    colors: {
      primary: Colors.redish,
      secondary: 'transparent',
    },
  },
  [OldSeriesCategorySlugs.wellbeing]: {
    icon: Wellbeing,
    colors: {
      primary: Colors.topaz,
      secondary: 'transparent',
    },
  },
  [OldSeriesCategorySlugs.madeForManagers]: {
    icon: MadeForManagers,
    colors: {
      primary: Colors.cornflower,
    },
  },
};

export const getSeriesCategoryTheme = (
  categorySlug: string,
): SeriesCategoryTheme =>
  get(seriesCategories, categorySlug, seriesCategories.default);
