import { useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { memo, useMemo } from 'react';
import { PRACTITIONER_AVAILABILITY_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import {
  practitionerAvailabilityQuery,
  practitionerAvailabilityQueryVariables,
} from 'Services/BeGateway/Practitioner/__generated__/practitionerAvailabilityQuery';
import { DateLabel, StyledDayPicker } from 'Shared/DayPicker';
import LoadingIndicator from 'Shared/LoadingIndicator';
import {
  FieldValues,
  FormValues,
  Range,
} from 'Talk/components/PractitionerDrawer/types';
import { useLocationData, useToday } from 'Talk/hooks';
import { TimeSlot } from 'Talk/lib/availability';
import { formatDate, localDateFormat } from 'Talk/lib/dates';
// eslint-disable-next-line import/no-unassigned-import
import 'react-day-picker/dist/style.css';
import { useParams } from 'react-router-dom';
import { Maybe } from 'graphql/jsutils/Maybe';
import { mapUserLocaleToShorthand } from '../../../../../i18n/mapUserLocaleToShorthand';
import { BEGatewayQueryContext } from '../../../../../utils/apollo';
import { getDefaultDateRange } from '../../helpers/getDefaultDateRange';
import { DayPickerCaptionLabel } from './DayPickerCaptionLabel';
import { DayPickerDay } from './DayPickerDay';
import { DayPickerRow } from './DayPickerRow';

function getFirstAndLastDayOfMonth(month: Date): [Date, Date] {
  const firstDayOfMonth = startOfMonth(month);
  const lastDayOfMonth = endOfMonth(month);

  return [firstDayOfMonth, lastDayOfMonth];
}

function getAllDaysOfMonth(startDate: Date, endDate: Date): Date[] {
  const firstDayOfMonth: Date = startOfMonth(startDate);
  const lastDayOfMonth: Date = endOfMonth(endDate);
  const daysArray: Date[] = [];

  for (
    let day: Date = firstDayOfMonth;
    day <= lastDayOfMonth;
    day.setDate(day.getDate() + 1)
  ) {
    daysArray.push(new Date(day));
  }

  return daysArray;
}

function getUnavailableDates(
  timeSlots: Maybe<(TimeSlot | null)[]>,
  range: Range,
): Date[] {
  const endDate = range.to;
  const allDates = getAllDaysOfMonth(range.from, endDate);

  // If we have no time slots we return all dates in the range.
  if (!timeSlots?.length) return allDates;

  const slotDates: Date[] = timeSlots
    .filter((slot): slot is TimeSlot => slot !== null)
    .map(slot => new Date(slot?.start));
  const uniqueDates: string[] = Array.from(
    new Set(slotDates.map(date => date.toDateString())),
  );
  const availableDates: Date[] = uniqueDates.map(
    dateString => new Date(dateString),
  );

  const unavailableDates = allDates.filter(
    date =>
      !availableDates.some(
        available => available.toDateString() === date.toDateString(),
      ),
  );

  return unavailableDates;
}

export interface DayPickerProps {
  setValues(value: FormValues): void;
  formValues: FormValues;
}

const DayPicker = ({ formValues, setValues }: DayPickerProps) => {
  const { practitionerId } = useParams<{ practitionerId: string }>();
  const {
    userLocationData,
    hasValidLocationData,
    loading: userLocationDataLoading,
  } = useLocationData();

  const today = useToday();
  const initialMonth = formValues[FieldValues.SelectedDate] ?? today;
  const defaultDateRange = getDefaultDateRange(today);

  const practitionerAvailabilityVariables = {
    id: practitionerId,
    country: userLocationData?.user?.country?.value,
    state: userLocationData?.user?.state?.value,
    ...defaultDateRange,
  };

  const {
    data: practitionerAvailabilityData,
    refetch: practitionerAvailabilityRefetch,
    loading: practitionerAvailabilityLoading,
  } = useQuery<
    practitionerAvailabilityQuery,
    practitionerAvailabilityQueryVariables
  >(PRACTITIONER_AVAILABILITY_QUERY, {
    variables: {
      ...practitionerAvailabilityVariables,
    },
    skip: !practitionerId || !hasValidLocationData,
    ...BEGatewayQueryContext,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const timeSlots =
    practitionerAvailabilityData?.practitioner?.availability?.times;

  const disabled = useMemo(
    () => getUnavailableDates(timeSlots, formValues.range),
    [timeSlots, formValues.range],
  );

  if (practitionerAvailabilityLoading || userLocationDataLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledDayPicker
      components={{
        Day: props => <DayPickerDay {...props} />,
        CaptionLabel: props => <DayPickerCaptionLabel {...props} />,
        Row: props => <DayPickerRow {...props} />,
      }}
      onMonthChange={async (month: Date) => {
        const [firstDay, lastDay] = getFirstAndLastDayOfMonth(month);
        setValues({
          ...formValues,
          [FieldValues.Range]: { from: firstDay, to: lastDay },
        });

        await practitionerAvailabilityRefetch({
          ...practitionerAvailabilityVariables,
        });
      }}
      locale={mapUserLocaleToShorthand()}
      defaultMonth={initialMonth}
      required={true}
      fromMonth={today}
      toDate={defaultDateRange.end}
      mode="single"
      selected={formValues[FieldValues.SelectedDate] as Date}
      onSelect={(selectedDate: Date | undefined) => {
        if (selectedDate !== formValues[FieldValues.SelectedDate]) {
          setValues({
            ...formValues,
            [FieldValues.SelectedDate]: selectedDate,
            [FieldValues.SelectedSlot]: undefined,
          });
        }
      }}
      formatters={{
        formatWeekdayName: day => localDateFormat(day, 'EEE'),
      }}
      disabled={disabled}
      footer={
        <DateLabel>
          {formValues[FieldValues.SelectedDate] &&
            formatDate(formValues[FieldValues.SelectedDate], 'EEEE, PPP')}
        </DateLabel>
      }
    />
  );
};

export default memo(DayPicker);
