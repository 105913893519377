import i18next from 'i18next';
import { format, isValid, parseISO } from 'date-fns';
import { utcToZonedTime, format as formatTz } from 'date-fns-tz';
import { mapUserLocaleToShorthand } from '../../i18n/mapUserLocaleToShorthand';

export const localDateFormat = (
  date: number | Date,
  formattingString: string,
) => format(date, formattingString, { locale: mapUserLocaleToShorthand() });

export const formatDate = (
  inputDate: string | Date,
  formattingString?: string,
): string | null => {
  const date = typeof inputDate === 'string' ? parseISO(inputDate) : inputDate;

  if (!isValid(date)) {
    return null;
  }

  let localeDefaultFormattingString;
  switch (i18next.language) {
    case 'en-GB':
      localeDefaultFormattingString = 'h:mm aaa, PPPP';
      break;

    default:
      localeDefaultFormattingString = 'PPPp';
  }

  return format(date, formattingString || localeDefaultFormattingString, {
    locale: mapUserLocaleToShorthand(),
  });
};

export const formatTime = (timeStr: string) => {
  const date = parseISO(timeStr);

  if (!isValid(date)) {
    return null;
  }

  let formattingString: string;

  switch (i18next.language) {
    case 'en-GB':
      formattingString = 'h:mm aaa';
      break;

    default:
      formattingString = 'p';
  }

  return format(date, formattingString, {
    locale: mapUserLocaleToShorthand(),
  }).toLowerCase();
};

export const convertToZonedTime = (timeZone: string): string => {
  const date = new Date();
  const tz = utcToZonedTime(date, timeZone);
  const locale = mapUserLocaleToShorthand();

  return `${timeZone.split('/').reverse().join(', ')} ${formatTz(tz, '(zzz)', {
    timeZone,
    locale,
  })}`;
};
