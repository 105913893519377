import gql from 'graphql-tag';

export const FEATURED_CHEAT_SHEETS_QUERY = gql`
  query FeaturedCheatSheets {
    featuredCheatSheets {
      id
      title
      slug
      cloudinaryThumbnail {
        path
      }
    }
  }
`;

const CHEAT_SHEET_IMAGE_FRAGMENT = gql`
  fragment ImageFields on Image {
    id
    altText
    cloudinaryMedia {
      path
    }
  }
`;

export const CHEAT_SHEET_ALBUM_QUERY = gql`
  ${CHEAT_SHEET_IMAGE_FRAGMENT}
  query cheatSheetAlbumBySlug($slug: String!) {
    cheatSheetAlbum(slug: $slug) {
      id
      slug
      download {
        path
      }
      items {
        ...ImageFields
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;
