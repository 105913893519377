import React, { useCallback, useContext, useReducer } from 'react';

import timerReducer, { Dispatch } from './reducers/TimerReducer';

import { TimerActions, TimerState } from './types/index';

interface TimerProviderProps {
  children: React.ReactNode;
}

const TimerStateContext = React.createContext<TimerState | undefined>(
  undefined,
);
const TimerDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

export const TimerProvider = (props: TimerProviderProps) => {
  const [state, setTimer] = useReducer(timerReducer, {
    timeSplits: [],
    total: 0,
  });

  return (
    <TimerStateContext.Provider value={state}>
      <TimerDispatchContext.Provider value={setTimer}>
        {props.children}
      </TimerDispatchContext.Provider>
    </TimerStateContext.Provider>
  );
};

// Use this in functional components in place of useContext()
export const useTimerState = () =>
  // TODO: Uncomment this check once all segment types are wrapped in the provider
  // const context = useContext(TimerStateContext);
  // if (context === undefined) {
  //   throw new Error('useTimerState must be used within a TimerProvider');
  // }

  useContext(TimerStateContext);

// Use this in functional components in place of useContext()
export const useTimerDispatch = () => {
  // TODO: Uncomment this check once all segment types are wrapped in the provider
  // const context =  useContext(TimerDispatchContext);
  // if (context === undefined) {
  //   throw new Error('useTimerDispatch must be used within a TimerProvider');
  // }

  const context = useContext(TimerDispatchContext);

  const start = useCallback(
    (capped: boolean) => {
      if (context !== undefined) {
        context({ type: TimerActions.START, payload: { capped: capped } });
      }
    },
    [context],
  );

  const stop = useCallback(
    (callback?: (state: TimerState) => void) => {
      if (context !== undefined) {
        if (callback !== undefined) {
          context({
            type: TimerActions.STOP,
            payload: {
              callback,
            },
          });
        } else {
          context({
            type: TimerActions.STOP,
          });
        }
      }
    },
    [context],
  );

  const reset = useCallback(
    (secondarySegmentType?: string) => {
      if (context !== undefined) {
        if (secondarySegmentType !== undefined) {
          context({
            type: TimerActions.RESET,
            payload: {
              hasSecondarySegment: true,
            },
          });
        } else {
          context({ type: TimerActions.RESET });
        }
      }
    },
    [context],
  );

  return {
    start: start,
    stop: stop,
    reset: reset,
  };
};

export interface TimerDispatch {
  start(capped: boolean): void;
  stop(callback?: (state: TimerState) => void): void;
  reset(secondarySegmentType?: string): void;
}

// These are only needed to wrap around class components
// for passing in context via the renderProp pattern
export const TimerDispatchConsumer = TimerDispatchContext.Consumer;
export const TimerStateConsumer = TimerStateContext.Consumer;

// For use in tests
export const TimerStateProvider = TimerStateContext.Provider;
export const TimerDispatchProvider = TimerDispatchContext.Provider;
