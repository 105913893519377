import gql from 'graphql-tag';

export const EXPLORE_CATEGORY_QUERY = gql`
  query categoryContent($slug: String!) {
    exploreCategory(slug: $slug) {
      id
      title
      slug
      internalName
      colourTheme
      summary
      featuredContent {
        __typename
        ... on Series {
          id
          title
          subtitle
          slug
          cloudinaryThumbnail {
            path
          }
          totalDays
          daysCompleted
          exploreCategory {
            id
            title
            slug
            internalName
            colourTheme
          }
        }
        ... on Tool {
          id
          title
          summary
          slug
          cloudinaryThumbnail {
            path
          }
          estimatedMins
          mediaType {
            value
          }
          mediaAsset {
            path
          }
          isFavourite
          exploreCategory {
            id
            title
            slug
            internalName
            colourTheme
          }
        }
        ... on ExploreCategory {
          id
          exploreCategoryTitle: title
          exploreCategorySlug: slug
          summary
          cloudinaryThumbnail {
            path
          }
          internalName
          colourTheme
        }
      }
      subCategories {
        id
        internalName
        title
        slug
        summary
      }
      content {
        ... on Series {
          id
          title
          slug
          subtitle
          cloudinaryThumbnail {
            path
          }
          totalDays
          daysCompleted
          exploreCategory {
            id
            title
            slug
            internalName
            colourTheme
          }
        }
        ... on Tool {
          id
          title
          summary
          slug
          estimatedMins
          cloudinaryThumbnail {
            path
          }
          mediaType {
            value
          }
          isFavourite
          exploreCategory {
            id
            slug
            colourTheme
            internalName
          }
        }
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;
