import React from 'react';
import { IconProps } from '../IconProps';

const SvgLocation = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g
      clipPath="url(#Location_svg__clip0_1089_21271)"
      stroke="#0D0300"
      strokeOpacity={0.7}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.847 5.846C12.847 8.528 8 15 8 15S3.154 8.528 3.154 5.846a4.846 4.846 0 1 1 9.693 0z" />
      <path d="M8 7.461a1.615 1.615 0 1 0 0-3.23 1.615 1.615 0 0 0 0 3.23z" />
    </g>
    <defs>
      <clipPath id="Location_svg__clip0_1089_21271">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLocation;
