import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Risk_Category } from '__generated__/globalTypes';
import { IMPACT_QUESTONS_THEME_ID } from '../../constants';

const ScoreText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize32],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))`
  margin-right: ${rem(12)};
  display: flex;
  align-items: center;
`;

const DenominatorText = styled(ScoreText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
}))``;

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledStatusBar = styled.div<{
  backgroundColor: string;
  textColor: string;
}>`
  border-radius: ${rem(6)};
  padding: ${rem(10)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  width: auto;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

type riskCategoryTranslationKeys = 'high' | 'medium' | 'low';

const mapRiskCategoryToTranslationKey = (
  riskCategory: string,
): riskCategoryTranslationKeys | null => {
  const riskCategoryToTranslationKeyMap: Record<
    string,
    riskCategoryTranslationKeys
  > = {
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low',
  };

  return riskCategoryToTranslationKeyMap[riskCategory] || null;
};

interface StatusBarProps {
  riskCategory: string;
}

export const StatusBar = ({ riskCategory }: StatusBarProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.risks.risk_categories',
  });
  const theme = useTheme();

  const riskStatusColor =
    riskCategory === 'HIGH'
      ? theme.colors.staticPalette.red.red6
      : riskCategory === 'MEDIUM'
      ? theme.colors.staticPalette.orange.orange600
      : theme.colors.staticPalette.green.green7;

  const textColor =
    riskCategory === 'MEDIUM'
      ? theme.colors.text.primary
      : theme.colors.staticPalette.white;

  const translationKey = mapRiskCategoryToTranslationKey(riskCategory);

  return (
    translationKey && (
      <StyledStatusBar backgroundColor={riskStatusColor} textColor={textColor}>
        {translate(translationKey)}
      </StyledStatusBar>
    )
  );
};

export const StatusBadge = styled.div<{
  color: string;
}>`
  width: ${rem(13)};
  height: ${rem(13)};
  min-width: ${rem(13)};
  min-height: ${rem(13)};
  background-color: ${({ color }) => color};
  border-radius: ${rem(100)};
  align-self: center;
  margin-right: ${rem(12)};
`;

interface ScoreWithColourStatusProps {
  score: number;
  themeId?: string;
  riskCategory: Risk_Category;
}

export enum ScoreType {
  PERCENTAGE = 'PERCENTAGE',
  FIVE_POINT = 'FIVE_POINT',
}

export const getDisplayValue = (
  score: number,
  scoreType: ScoreType,
): string => {
  if (scoreType === ScoreType.PERCENTAGE) {
    return `${score}%`;
  }

  return `${score}/5`;
};

export const getStatusColor = (
  designTheme: DefaultTheme,
  riskCategory: Risk_Category,
) => {
  if (riskCategory === Risk_Category.HIGH) {
    return designTheme.colors.staticPalette.red.red6;
  } else if (riskCategory === Risk_Category.MEDIUM) {
    return designTheme.colors.staticPalette.orange.orange600;
  } else if (riskCategory === Risk_Category.LOW) {
    return designTheme.colors.staticPalette.green.green7;
  }
};

export const ScoreWithColourStatus = ({
  score,
  themeId,
  riskCategory,
}: ScoreWithColourStatusProps) => {
  const designTheme = useTheme();
  const statusBadgeColor = getStatusColor(designTheme, riskCategory);

  return (
    <ScoreContainer>
      {statusBadgeColor && (
        <StatusBadge data-testid={'status-badge'} color={statusBadgeColor} />
      )}
      <ScoreText>
        {score}
        <DenominatorText>
          {themeId === IMPACT_QUESTONS_THEME_ID ? '' : '/5'}
        </DenominatorText>
      </ScoreText>
    </ScoreContainer>
  );
};
