import { Colors } from '@unmind/design-system-theme';
import get from 'lodash/get';
import {
  ToolsLearn,
  ToolsCalm,
  ToolsBoost,
  ToolsEmpower,
  ToolsFiresides,
  ToolsFocus,
  ToolsHealthy,
  ToolsInterludes,
  ToolsMindfulness,
  ToolsMindSnacks,
  ToolsMove,
  ToolsNightwaves,
  SleepyMoon,
  ToolsSoundscapes,
  ToolsTransform,
  ToolsUplift,
  IconType,
  Heartbeat,
  Calm,
  DailyBoost,
  Firesides,
  Uplift,
  Interludes,
  Mindsnacks,
  Move,
  Nightwaves,
  Soundscapes,
  Transform,
  Workouts,
  Empower,
  Sleeptales,
  Flourish,
  FocusTool as Focus,
  MindfulnessTool as Mindfulness,
  MadeForManagersTool as MadeForManagers,
} from 'icons';

export const ToolCategorySlugs = {
  calm: 'calm',
  empower: 'empower',
  focus: 'focus',
  mindfulness: 'mindfulness',
  move: 'move',
  nightwaves: 'nightwaves',
  sleepTales: 'sleep-tales',
  soundscapes: 'soundscapes',
  transform: 'transform',
  uplift: 'uplift',
  dailyBoost: 'daily-boost',
  interludes: 'interludes',
  firesides: 'firesides',
  mindSnacks: 'mindsnacks',
  healthy: 'healthy',
  /**
   * @deprecated Please use "nightwaves" instead
   */
  sleep: 'sleep',
  /**
   * @deprecated Please use "soundscapes" instead
   */
  soundwaves: 'soundwaves',
  workouts: 'workouts',
  flourish: 'flourish',
  madeForManagers: 'made-for-managers',
} as const;

type ValueOf<T> = T[keyof T];
type ToolCategoryTheme = {
  color: Colors;
  icon: IconType;
  image: IconType;
};

export const toolCategories: {
  [key in ValueOf<typeof ToolCategorySlugs> | 'default']: ToolCategoryTheme;
} = {
  default: { color: Colors.burnt, icon: ToolsLearn, image: Calm },
  [ToolCategorySlugs.calm]: {
    color: Colors.bluish,
    icon: ToolsCalm,
    image: Calm,
  },
  [ToolCategorySlugs.dailyBoost]: {
    color: Colors.amber,
    icon: ToolsBoost,
    image: DailyBoost,
  },
  [ToolCategorySlugs.empower]: {
    color: Colors.salmon,
    icon: ToolsEmpower,
    image: Empower,
  },
  [ToolCategorySlugs.firesides]: {
    color: Colors.plum,
    icon: ToolsFiresides,
    image: Firesides,
  },
  [ToolCategorySlugs.focus]: {
    color: Colors.leather,
    icon: ToolsFocus,
    image: Focus,
  },
  [ToolCategorySlugs.healthy]: {
    color: Colors.water,
    icon: ToolsHealthy,
    image: Uplift,
  },
  [ToolCategorySlugs.interludes]: {
    color: Colors.burnt,
    icon: ToolsInterludes,
    image: Interludes,
  },
  [ToolCategorySlugs.mindfulness]: {
    color: Colors.grass,
    icon: ToolsMindfulness,
    image: Mindfulness,
  },
  [ToolCategorySlugs.mindSnacks]: {
    color: Colors.water,
    icon: ToolsMindSnacks,
    image: Mindsnacks,
  },
  [ToolCategorySlugs.move]: {
    color: Colors.topaz,
    icon: ToolsMove,
    image: Move,
  },
  [ToolCategorySlugs.nightwaves]: {
    color: Colors.plum,
    icon: ToolsNightwaves,
    image: Nightwaves,
  },
  [ToolCategorySlugs.sleepTales]: {
    color: Colors.plum,
    icon: SleepyMoon,
    image: Sleeptales,
  },
  [ToolCategorySlugs.soundscapes]: {
    color: Colors.burnt,
    icon: ToolsSoundscapes,
    image: Soundscapes,
  },
  [ToolCategorySlugs.transform]: {
    color: Colors.cornflower,
    icon: ToolsTransform,
    image: Transform,
  },
  [ToolCategorySlugs.uplift]: {
    color: Colors.redish,
    icon: ToolsUplift,
    image: Uplift,
  },
  [ToolCategorySlugs.sleep]: {
    color: Colors.plum,
    icon: ToolsNightwaves,
    image: Nightwaves,
  },
  [ToolCategorySlugs.soundwaves]: {
    color: Colors.burnt,
    icon: ToolsSoundscapes,
    image: Soundscapes,
  },
  [ToolCategorySlugs.workouts]: {
    color: Colors.redish,
    icon: Heartbeat,
    image: Workouts,
  },
  [ToolCategorySlugs.flourish]: {
    color: Colors.burnt,
    icon: Flourish,
    image: Calm,
  },
  [ToolCategorySlugs.madeForManagers]: {
    color: Colors.cornflower,
    icon: MadeForManagers,
    image: Calm,
  },
};

export const getToolCategoryTheme = (categorySlug: string): ToolCategoryTheme =>
  get(toolCategories, categorySlug, toolCategories.default);

export const getToolCategoryColor = (categorySlug: string) =>
  getToolCategoryTheme(categorySlug).color;
export const getToolCategoryIcon = (categorySlug: string) =>
  getToolCategoryTheme(categorySlug).icon;
export const getToolCategoryImage = (categorySlug: string) =>
  getToolCategoryTheme(categorySlug).image;
