import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';
import { FullPageAlertView } from './FullPageAlertView';

export const LABEL_ERROR_HEADING = i18n.t(
  'shared:errors.messages.oops_something_wrong',
);
export const LABEL_ERROR_MESSAGE = i18n.t('shared:errors.messages.unexpected');

export const FullPageGeneralError = () => {
  const { t: translate } = useTranslation('shared');

  return (
    <FullPageAlertView
      heading={translate('errors.messages.oops_something_wrong')}
      message={translate('errors.messages.unexpected')}
    />
  );
};
