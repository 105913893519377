import React from 'react';
import { IconProps } from '../IconProps';
const SvgLock = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#Lock_svg__clip0_430_478)">
      <path
        d="M15.714 7.857H4.286c-.79 0-1.429.64-1.429 1.429v8.571c0 .79.64 1.429 1.429 1.429h11.428c.79 0 1.429-.64 1.429-1.429V9.286c0-.79-.64-1.429-1.429-1.429z"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.857V5.714a5 5 0 00-10 0v2.143"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.107a.625.625 0 100-1.25.625.625 0 000 1.25z"
        fill={primaryColor}
        fillOpacity={0.95}
      />
    </g>
    <defs>
      <clipPath id="Lock_svg__clip0_430_478">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLock;
