import { useQuery } from '@apollo/client';
import { userCountryStateQuery } from 'Services/User/__generated__/userCountryStateQuery';
import { USER_COUNTRY_STATE_QUERY } from 'Services/User/user.services';

export const CANADA = 'Canada';
const USA = 'United States of America';
const UAE = 'United Arab Emirates';
export const COUNTRIES_REQUIRING_STATES = [CANADA, USA, UAE];

type CountriesRequiringStatesType = typeof CANADA | typeof USA | typeof UAE;

// A type guard for narrowing the provided country to the CountriesRequiringStatesType where appropriate
export function countryRequiresState(
  country: string,
): country is CountriesRequiringStatesType {
  return COUNTRIES_REQUIRING_STATES.includes(country);
}

/**
 * Provides the user's place of residence data.
 * For convenience, also provides booleans indicating whether their location data is valid
 * and whether their country of residence requires a state.
 */
export function useLocationData() {
  const {
    data: userData,
    loading,
    error,
  } = useQuery<userCountryStateQuery>(USER_COUNTRY_STATE_QUERY);

  const requiresState = Boolean(
    userData?.user?.country?.value &&
      countryRequiresState(userData.user.country.value),
  );

  const userHasValidLocationData = () => {
    if (
      !userData?.user?.country?.value ||
      userData?.user?.country?.value === '0'
    )
      return false;

    if (requiresState) {
      return Boolean(userData?.user?.state?.value);
    }

    return Boolean(userData.user.country.value);
  };

  return {
    userLocationData: userData,
    hasValidLocationData: userHasValidLocationData(),
    requiresState,
    loading,
    error,
  };
}
