import { BodyText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';

import { NavHelp } from 'icons';
import { Link } from 'react-router-dom';
import { styled } from 'styles';

export const HelpContainer = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  margin-left: auto;
  padding: ${rem(12)};
  border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};
`;

export const HelpIcon = styled(NavHelp).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 14,
  width: 14,
  ['aria-hidden']: true,
}))`
  margin-right: 6px;
`;

export const HelpLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`
  line-height: 1;
`;
