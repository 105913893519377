import React from 'react';
import { IconProps } from '../IconProps';

const SvgCertificate = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M14.25 17.518a3.822 3.822 0 1 0 0-7.645 3.822 3.822 0 0 0 0 7.645zM5.429 5.468h9.75m-9.75 3.25h2.785"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.2 17.518H2.375a1.592 1.592 0 0 1-1.593-1.593V2.891A1.593 1.593 0 0 1 2.376 1.3h17.31a1.592 1.592 0 0 1 1.594 1.59v13.034a1.592 1.592 0 0 1-1.592 1.593h-.348"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.411 17.064v4.234l1.84-1.096 1.839 1.096v-4.234m-1.84-2.972a.398.398 0 1 1 0-.795m0 .795a.398.398 0 1 0 0-.795"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCertificate;
