import React from 'react';
import { IconProps } from '../IconProps';

const SvgInviteALovedOne = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 256 206" fill="none" {...props}>
    <path
      d="M64.388 205c-2.258-14.625-1.012-31.571-10.473-42.822a705.97 705.97 0 0 0-31.927-35.49c-2.94-3.062-5.995-6.201-7.533-10.16-1.538-3.958-1.188-8.989 2.005-11.797 2.453-2.165 6.093-2.555 9.266-1.775 3.173.78 5.996 2.574 8.683 4.446a154.533 154.533 0 0 1 34.048 32.136c1.304 1.677 2.648 3.042 4.205 4.466.74.682 1.888 1.404 2.667.76.292-.234.448-.624.565-.975 1.46-4.66-.701-9.594-2.415-13.845a432.964 432.964 0 0 1-6.093-16.087A395.273 395.273 0 0 1 57.302 80.98c-1.869-7.196-3.562-14.586-2.881-21.977.467-5.109 2.823-10.861 7.767-12.188 3.33-.896 6.95.566 9.442 2.945s4.05 5.558 5.412 8.736c2.083 4.856 3.816 9.848 5.548 14.84 3.173 9.126 16.684 48.243 23.497 57.564.039-1.619-4.283-35.256-6.482-49.51a700.089 700.089 0 0 1-5.393-44.598c-.584-6.513-.409-14.41 5.16-17.842 4.963-3.062 11.835-.449 15.398 4.173 3.562 4.621 4.672 10.628 5.665 16.36 1.168 6.709 2.316 13.417 3.484 20.105 1.772 10.238 11.252 61.308 12.829 66.632.857-2.379 10.123-84.201 13.803-89.915 3.192-4.973 10.317-8.872 16.002-5.518 7.981 4.718 7.514 16.945 7.242 24.92-.429 12.773-1.246 25.448-2.609 38.182-1.032 9.633-2.044 19.266-3.076 28.899-1.382 13.026 1.363 27.183 6.736 39.682M199.16 204.688c2.531-4.778 4.887-9.653 7.106-14.567 3.543-7.8 6.502-16.029 11.758-22.795 4.497-5.792 10.493-10.257 15.302-15.776 4.711-5.421 8.234-13.65 4.127-19.539-2.823-4.036-8.449-5.343-13.277-4.329-4.828 1.014-9.013 3.9-12.965 6.864-9.403 7.059-17.424 15.581-26.495 23.03-3.212 2.632-6.483 4.309-9.87 5.538"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.562 59.081c-.701-12.675-2.92-31.785-.312-42.276C88.917 6.06 97.19-1.291 108.696 1.653c19.195 4.934 17.248 37.265 21.219 52.007-2.433 4.017-7.144 6.396-11.661 7.703a58.93 58.93 0 0 1-22.056 2.105c-3.485-.331-9.442-.897-9.636-4.387z"
      fill="#FFF964"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.571 90.457c-3.524-12.188-9.967-30.303-9.792-41.126.195-11.076 6.6-20.105 18.474-19.812 19.799.487 26.165 31.434 32.316 45.903-1.48 4.466-5.529 7.84-9.636 10.14a59.451 59.451 0 0 1-21.005 7.02c-3.466.468-9.403 1.248-10.377-2.106l.02-.02zM4 133.045c-1.83 1.521-3.348 4.192-1.537 6.337 3.348 3.959 9.11 2.145 13.043.41.39-.176 4.516-2.321 4.71-2.145l-.077-.059c3.66 3.92 7.3 7.683 10.279 10.959 2.375 2.594 7.32-.76 10.24-2.73a59.076 59.076 0 0 0 15.71-15.639c2.647-3.88 4.789-8.716 4.127-13.357-12.401-8.873-31.362-35.12-49.311-26.735-10.765 4.992-12.498 15.932-7.767 25.935 1.693 3.588 4.652 7.664 8.117 11.856-2.005 2.243-5.217 3.237-7.533 5.168z"
      fill="#FFF964"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.188 64.717c1.188 1.58 3.213 2.262 5.16 2.613 1.946.35 3.97.526 5.703 1.462 1.733.936 3.135 2.906 2.628 4.817-.389 1.423-1.732 2.398-3.114 2.886-1.383.487-2.882.546-4.342.819"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.718 55.746c.233 2.321-.623 4.642-1.81 6.67-.662 1.092-1.422 2.145-1.908 3.315-.487 1.17-.701 2.554-.195 3.724.74 1.716 2.803 2.535 4.672 2.38 1.869-.157 3.563-1.054 5.217-1.931"
      fill="#FFF964"
    />
    <path
      d="M75.718 55.746c.233 2.321-.623 4.642-1.81 6.67-.662 1.092-1.422 2.145-1.908 3.315-.487 1.17-.701 2.554-.195 3.724.74 1.716 2.803 2.535 4.672 2.38 1.869-.157 3.563-1.054 5.217-1.931"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M124.854 23.045c2.2-1.014 3.485-3.88 6.074-4.114 1.402-.117 2.551.76 3.115 2.028.467 1.033.526 2.242.312 3.373-.351 1.99-1.266 3.803-2.512 5.382-1.304 1.658-2.881 3.062-4.497 4.407"
      fill="#FFF964"
    />
    <path
      d="M124.854 23.045c2.2-1.014 3.485-3.88 6.074-4.114 1.402-.117 2.551.76 3.115 2.028.467 1.033.526 2.242.312 3.373-.351 1.99-1.266 3.803-2.512 5.382-1.304 1.658-2.881 3.062-4.497 4.407"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M181.952 51.886c3.192.097 11.291-3.004 9.928-7.508-1.09-3.608-8.001-.605-9.598-3.49-.467-5.207-1.791-10.609-4.01-15.347-3.017-6.435-9.519-10.764-16.703-10.374-7.65.41-13.491 5.596-16.508 12.402-1.188 2.69-2.141 6.084-2.92 9.828l.078-.078c-1.519 1.267-3.407 2.047-5.354 2.379-1.771.312-3.815.487-4.361 2.574-.953 3.724 4.828 6.026 7.632 5.947h.253c-.954 7.352-1.597 14.957-2.317 21.041-.467 3.978 10.104 6.142 12.634 6.845a47.052 47.052 0 0 0 16.567 1.56c4.4-.37 10.727-.975 13.841-4.525.526-5.537.662-13.65.896-20.864"
      fill="#FFF964"
    />
    <path
      d="M181.952 51.886c3.192.097 11.291-3.004 9.928-7.508-1.09-3.608-8.001-.605-9.598-3.49-.467-5.207-1.791-10.609-4.01-15.347-3.017-6.435-9.519-10.764-16.703-10.374-7.65.41-13.491 5.596-16.508 12.402-1.188 2.69-2.141 6.084-2.92 9.828l.078-.078c-1.519 1.267-3.407 2.047-5.354 2.379-1.771.312-3.815.487-4.361 2.574-.953 3.724 4.828 6.026 7.632 5.947h.253c-.954 7.352-1.597 14.957-2.317 21.041-.467 3.978 10.104 6.142 12.634 6.845a47.052 47.052 0 0 0 16.567 1.56c4.4-.37 10.727-.975 13.841-4.525.526-5.537.662-13.65.896-20.864M93.355 33.458c1.771 2.866 5.14 4.192 7.923 6.103.915.624 1.791 1.365 2.336 2.34.545.975.701 2.223.136 3.179-.389.682-1.09 1.15-1.81 1.482-3.232 1.56-7.32 1.111-10.143-1.092"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M207.298 126.22c-1.304-3.939-.564-8.327 1.091-12.149.584-1.345 1.323-2.671 2.53-3.49 1.207-.819 2.998-.936 4.03.097 1.869 1.853.059 5.48 1.811 7.449l.584.429c2.978-2.554 5.879-4.699 8.526-6.123 9.734-5.226 20.733-4.017 26.281 6.494 4.77 9.067.331 18.876-6.21 27.982v.098a8.353 8.353 0 0 0 3.971 1.014c.915 0 1.83-.137 2.726 0 .895.136 1.83.604 2.219 1.521.37.819.214 1.852-.194 2.671-.409.819-1.091 1.424-1.791 1.931-3.836 2.866-9.033 3.256-13.102.994l-.195-.117c-4.516 5.402-8.994 10.355-11.68 14.547-4.614.878-9.539-1.014-13.53-3.471-6.327-3.9-11.914-8.989-16.352-14.956-2.103-2.808-5.685-7.605-3.213-10.101 3.836-3.9 8.371-8.795 13.063-13.533l-.545-1.287h-.02z"
      fill="#FFF964"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.382 103.658l3.134 3.9M22.474 99.193l3.037 3.646M16.012 115.378c.876.936 2.141 1.404 3.407 1.618 3.445.585 7.144-.507 9.714-2.886 2.57-2.379 3.971-5.967 3.679-9.457M15.72 112.745a14.003 14.003 0 0 1-2.219 5.85M30.203 102.683c1.791.449 3.563.878 5.354 1.326M54.44 41.882l.858 3.53M62.286 39.483l1.11 3.978M53.078 52.373c5.276 2.145 11.797.74 15.73-3.393M53.233 50.209c-.409 1.637-1.226 3.158-2.316 4.445M67.699 47.206a20.152 20.152 0 0 0 4.049 2.398M104.16 12.866c.292 1.56.486 3.14.623 4.738M112.162 11.267l1.051 4.738M158.61 27.14a33.647 33.647 0 0 0-.526 4.836M166.806 28.271c-.117 1.56-.214 3.12-.331 4.68M152.907 37.982c1.888 2.496 4.964 3.88 8.079 4.173 3.114.292 6.229-.41 9.149-1.502M154.171 35.856a10.816 10.816 0 0 1-4.672 2.984M169.005 37.826a9.406 9.406 0 0 0 3.348 3.841M236.402 121.306a26.476 26.476 0 0 1-3.134 2.769M242.865 126.727a16.19 16.19 0 0 1-3.329 3.198M226.336 126.941a11.916 11.916 0 0 0 4.303 6.903 11.93 11.93 0 0 0 7.748 2.477M227.661 125.264a18.897 18.897 0 0 1-5.159 1.209M238.951 134.351l-.136 5.831M175.489 162.802c-10.24 3.568-19.098 10.978-24.432 20.455M103.906 25.014c3.816 1.268 8.157.82 11.642-1.17M103.751 22.616a9.553 9.553 0 0 1-1.947 4.31M114.848 21.407a17.78 17.78 0 0 0 2.667 3.373"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.966 110.561c-2.141 2.613-6.073 3.12-8.332 5.616a3.342 3.342 0 0 0-.759 1.229c-.253.819 0 1.735.506 2.418.507.682 1.246 1.17 2.025 1.54 2.122 1.014 4.555 1.268 6.891 1.053 2.336-.214 4.614-.897 6.853-1.638"
      fill="#FFF964"
    />
    <path
      d="M43.966 110.561c-2.141 2.613-6.073 3.12-8.332 5.616a3.342 3.342 0 0 0-.759 1.229c-.253.819 0 1.735.506 2.418.507.682 1.246 1.17 2.025 1.54 2.122 1.014 4.555 1.268 6.891 1.053 2.336-.214 4.614-.897 6.853-1.638"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M242.97 57.396c-4.388-2.302-9.54-.332-12.556 3.308.154-4.736-2.4-9.622-7.185-10.85-3.846-.975-8.274 1.134-9.054 5.264-.44 2.353.208 4.806 1.142 7.006 1.891 4.515 5.468 12.586 7.076 16.23.184.433.535.747.92.933l.026.006.025.007a1.83 1.83 0 0 0 1.31-.116c3.621-1.638 11.65-5.308 16.053-7.424 2.156-1.042 4.261-2.4 5.499-4.485 2.161-3.595.251-8.143-3.26-9.962l.004.083z"
      fill="#391F17"
      stroke="#391F17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgInviteALovedOne;
