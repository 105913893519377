import { useEffect } from 'react';
import { logException } from '../logging';
import withRefreshAuthToken, {
  WithRefreshAuthTokenChildProps,
} from './withRefreshAuthToken';

const TEN_MINUTES = 10 * 60 * 1000;

export const RefreshAuthToken = ({
  refreshAuthToken,
}: WithRefreshAuthTokenChildProps): null => {
  useEffect(() => {
    refreshAuthToken().catch(e => {
      logException(e);
    });

    const intervalId = setInterval(() => {
      refreshAuthToken().catch(e => {
        logException(e);
      });
    }, TEN_MINUTES);

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshAuthToken]);

  return null;
};

export default withRefreshAuthToken(RefreshAuthToken);
