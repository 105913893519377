import React from 'react';
import { IconProps } from '../IconProps';

const SvgHappierFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M83.2 164.3c44.945 0 81.38-36.435 81.38-81.38 0-44.945-36.435-81.38-81.38-81.38-44.945 0-81.38 36.435-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#C0D23D"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.12 66.31a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.61 66.31a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M121.12 95.54c-7.4 14.41-22.42 24.27-39.73 24.27-17.31 0-33.06-10.35-40.26-25.33M43.72 91.1c-2.08 2-4.36 3.8-6.8 5.36M117.56 91.1c2.08 2 4.36 3.8 6.8 5.36"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHappierFaceWithBorder;
