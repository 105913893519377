export const getNavigatorInstance = () => {
  if (window !== undefined) {
    if (window.navigator || navigator) {
      return window.navigator || navigator;
    }
  }

  return false;
};

export type Platform = 'apple' | 'android' | 'desktop';

const getPlatform = (navigator: false | Navigator): Platform => {
  const applePattern = `(iPod|iPad|iPhone)`;
  const androidPattern = `Android`;

  if (navigator) {
    const ua = navigator.userAgent;

    if (new RegExp(applePattern, 'i').test(ua)) {
      return 'apple';
    }

    if (new RegExp(androidPattern, 'i').test(ua)) {
      return 'android';
    }
  }

  return 'desktop';
};

export default getPlatform;
