import styled from 'styled-components';
import React from 'react';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { BodyText } from '@unmind/design-system-components-web';
import { Risk_Category } from '__generated__/globalTypes';
import { useWellbeingThemeDetail } from 'Compass/api';
import { CardHeader } from '../styles';
import { ScoreWithColourStatus } from '../ScoreWithColourStatus';
import { RecommendedActionsAccordion } from '../RecommendedActionsAccordion';
import { SelectedCard } from './SelectedCard';
import {
  DistributionType,
  RelatedQuestion,
  RelatedQuestionsSection,
} from './RelatedQuestionsSection';
import { LoadingSelectedCard } from './LoadingSelectedCard';

const CardTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))``;

export interface WellbeingCardProps {
  indexScheduleId: string;
  themeId: string;
  closeCard(): void;
}

export const StatusBadge = styled.div<{ color: string }>`
  width: ${rem(13)};
  height: ${rem(13)};
  min-width: ${rem(13)};
  min-height: ${rem(13)};
  background-color: ${({ color }) => color};
  border-radius: ${rem(100)};
  align-self: center;
  margin-right: ${rem(12)};
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const CardText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(16)};
`;

const Score = ({
  score,
  riskCategory,
  themeId,
  description,
}: {
  score?: number;
  riskCategory?: Risk_Category;
  themeId?: string;
  description?: string;
}) => {
  if (!score || !themeId || !riskCategory || !description) {
    return null;
  }

  return (
    <Container data-testid={'wellbeing-selected-card-score'}>
      <ScoreWithColourStatus
        score={score}
        themeId={themeId}
        riskCategory={riskCategory}
      />
      <CardText>{description}</CardText>
    </Container>
  );
};

const Definition = ({ definitionHtml }: { definitionHtml?: string }) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: `dashboard.cards.wellbeing`,
  });

  if (!definitionHtml) {
    return null;
  }

  return (
    <div data-testid={'wellbeing-selected-card-definition'}>
      <CardHeader>{translate('definition_header')}</CardHeader>
      <CardText dangerouslySetInnerHTML={{ __html: definitionHtml }} />
    </div>
  );
};

export const WellbeingSelectedCard = ({
  closeCard,
  indexScheduleId,
  themeId,
}: WellbeingCardProps) => {
  const {
    data: wellbeingThemeDetailData,
    loading: wellbeingThemeDetailLoading,
  } = useWellbeingThemeDetail({ indexScheduleId, themeId });

  if (wellbeingThemeDetailLoading) {
    return <LoadingSelectedCard closeCard={closeCard} />;
  }

  const {
    title,
    meanScore,
    description,
    definition,
    id: wellbeingThemeId,
    riskCategory,
    questions,
  } = wellbeingThemeDetailData?.wellbeingThemeDetail || {};

  const relatedQuestions: RelatedQuestion[] = (questions || []).map(
    ({ questionText, responseDistributions }) => ({
      questionText,
      distributionType: DistributionType.RATING,
      responseDistributions: (responseDistributions || []).map(
        ({ label, responses, id }) => ({
          label,
          responseCount: responses,
          responseOptionId: id,
        }),
      ),
    }),
  );

  const actions =
    wellbeingThemeDetailData?.wellbeingThemeDetail?.recommendedActions;

  return (
    <SelectedCard
      header={
        <CardTitle data-testid={'wellbeing-selected-card-title'}>
          {title}
        </CardTitle>
      }
      closeCard={closeCard}
      content={
        <>
          <Score
            score={meanScore}
            description={description}
            riskCategory={riskCategory}
            themeId={wellbeingThemeId}
          />
          <RelatedQuestionsSection
            relatedQuestions={relatedQuestions}
            dataTestId={'wellbeing-selected-card-related-questions'}
          />
          <Definition definitionHtml={definition} />
          {actions && (
            <RecommendedActionsAccordion
              recommendedActions={actions}
              scheduleId={indexScheduleId}
            />
          )}
        </>
      }
    />
  );
};
