import { rem, transparentize } from 'polished';
import React, { MouseEvent, ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { Hamburger } from 'icons';
import { isMSTeams } from 'utils/MSTeams';

const MenuIcon = styled(Hamburger).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: 'transparent',
  width: 26,
  height: 17,
}))``;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
`;

const DropdownButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const DropdownMenuPopover = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.card};
  position: absolute;
  transform: translateY(${({ isOpen }) => (isOpen ? '100%' : '98%')});
  bottom: -8px;
  right: 0px;
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  min-width: ${isMSTeams() ? rem(270) : rem(240)};
  box-shadow: 0px 2px 10px
    ${({ theme }) => transparentize(0.9, theme.colors.boxShadow)};
  border: ${({ theme }) => `1px solid ${theme.colors.border.primary}`};
  border-radius: 16px;
  padding: 8px 16px;
`;

const DropdownMenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  a {
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};

    &:hover {
      color: inherit;
    }
  }
`;

const MenuItemWrapper = styled.button`
  display: block;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  background: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100%;
`;

export interface MenuItemProps {
  parentMenuIsOpen?: boolean;
  label: React.ReactNode;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

export const MenuItem = ({
  label,
  parentMenuIsOpen,
  ...props
}: MenuItemProps) => (
  <MenuItemWrapper
    tabIndex={parentMenuIsOpen ? 0 : -1}
    role="menuitem"
    {...props}
  >
    {label}
  </MenuItemWrapper>
);

export interface DropdownMenuProps {
  children: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
  open: boolean;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  onRequestClose(): void;
}

export const DropdownMenu = ({
  children,
  onRequestClose,
  open,
  ...props
}: DropdownMenuProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation('navigation');

  const handleClickOutside = (event: Event) => {
    const target = event.target as HTMLDivElement;
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      onRequestClose();
    }
  };

  const handleKeyboardClose = (event: KeyboardEvent): void => {
    const escKeyPressed = event.key === 'Esc' || event.key === 'Escape';
    if (open && escKeyPressed) {
      onRequestClose();
    }

    return;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardClose, {
      capture: true,
    });

    return () => {
      document.removeEventListener('keydown', handleKeyboardClose, {
        capture: true,
      });
    };
  }, [open]);

  return (
    /**
     *  `wrapperRef` is cast to any as a workaround to a Typescript & Styled-Components issue:
     *  https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
     *  https://spectrum.chat/styled-components/general/typescript-refs~5857d917-966e-4a71-940f-524206896f43
     */
    <Wrapper ref={wrapperRef}>
      <DropdownButton
        aria-label={translate('dropdown_menu.dropdown_button.a11y_label')}
        aria-expanded={open}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        id="dropdown-menu-trigger"
        {...props}
      >
        <MenuIcon />
      </DropdownButton>
      <DropdownMenuPopover isOpen={open} aria-hidden={!open} id="dropdown-menu">
        <DropdownMenuList role="menu" aria-labelledby="dropdown-menu-trigger">
          {React.Children.map(children, child => (
            <li role="presentation">
              {React.cloneElement(child, {
                ...child.props,
                parentMenuIsOpen: open,
                onClick: (event: MouseEvent<HTMLButtonElement>) => {
                  onRequestClose();
                  child.props.onClick(event);
                },
              })}
            </li>
          ))}
        </DropdownMenuList>
      </DropdownMenuPopover>
    </Wrapper>
  );
};
