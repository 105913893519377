import React from 'react';
import { IconProps } from '../IconProps';

const SvgFunnel = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M19 1H1a9 9 0 0 0 7 9v9l4-3v-6a9 9 0 0 0 7-9z"
      stroke={primaryColor}
      strokeOpacity={0.9}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFunnel;
