import {
  BodyText,
  FeatureText,
  MonoText,
} from '@unmind/design-system-components-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';
import { Volume } from 'icons';
import { css } from 'styles';
import { small } from 'utils';
import { AlternativeHomePageSection } from './Shared/AlternativeHomePageSection/AlternativeHomePageSection';
import { ContentTile } from './Shared/ContentTile';
import { MediaTypes, navigateToPlayer } from './Shared/navigateToPlayer';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${rem(450)}
  justify-content: space-between;
  
  ${small(css`
    width: 80%;
    flex-direction: row;
  `)};

`;

const CardHeader = styled(FeatureText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
}))``;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VolumeIcon = styled(Volume).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  height: ${rem(16)};
  width: ${rem(16)};
`;

const DurationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DurationText = styled(MonoText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-left: ${rem(5)};
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${rem(160)};
`;

export const Tools = () => {
  const { t: translate } = useTranslation('logged_out');
  const toolsPrefix = 'ms_teams_alternative_start.second_section.tools';

  const alternativeStartPageTools = [
    {
      description: translate(`${toolsPrefix}.tool_1.description`),
      mediaSrc:
        'https://assets.unmind.com/video/upload/v1659463358/Personal%20Wellbeing/Live/Transform-Work_Conflicts-en-GB.mp3',
      duration: 8,
      title: translate(`${toolsPrefix}.tool_1.title`),
      thumbnailUrl:
        'https://assets.unmind.com/image/upload/v1679310580/Personal%20Wellbeing/Thumbnails/Work_Work-Conflicts_thumb.jpg',
    },
    {
      description: translate(`${toolsPrefix}.tool_2.description`),
      mediaSrc:
        'https://assets.unmind.com/video/upload/v1659565463/Personal%20Wellbeing/Live/Empower-Refocusing_Attention-en-GB.mp3',
      duration: 3,
      title: translate(`${toolsPrefix}.tool_2.title`),
      thumbnailUrl:
        'https://assets.unmind.com/image/upload/v1679310599/Personal%20Wellbeing/Thumbnails/Work_Refocusing-Attention_thumb.jpg',
    },
    {
      description: translate(`${toolsPrefix}.tool_3.description`),
      mediaSrc:
        'https://assets.unmind.com/video/upload/v1659471177/Personal%20Wellbeing/Live/Soundscapes-Space_in_the_Office-en-GB.mp3',
      duration: 30,
      title: translate(`${toolsPrefix}.tool_3.title`),
      thumbnailUrl:
        'https://assets.unmind.com/image/upload/v1680087595/Personal%20Wellbeing/Thumbnails/Work_Space-in-the-Office_thumb.jpg',
    },
  ];

  return (
    <AlternativeHomePageSection
      description={translate(
        'ms_teams_alternative_start.second_section.description',
      )}
      header={translate('ms_teams_alternative_start.second_section.header')}
    >
      <CardsContainer>
        {alternativeStartPageTools.map((tool, index) => (
          <ContentTile
            key={tool.title}
            src={tool.thumbnailUrl}
            onClick={() => {
              navigateToPlayer({
                mediaSrc: tool.mediaSrc,
                mediaType: MediaTypes.Audio,
                title: tool.title,
              });
            }}
          >
            <CardContent>
              <CardInformation id={`card-header-${index}`}>
                <CardHeader>{tool.title}</CardHeader>
                <Text>{tool.description}</Text>
              </CardInformation>
              <DurationContainer>
                <VolumeIcon />
                <DurationText>{tool.duration} min</DurationText>
              </DurationContainer>
            </CardContent>
          </ContentTile>
        ))}
      </CardsContainer>
    </AlternativeHomePageSection>
  );
};
