import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { getHelpLink } from 'Talk/lib/getHelpLink';
import { ExternalLinkIcon } from 'LoggedOut/ExternalLinkIcon';
import {
  BookingStage,
  FieldValues,
  FormValues,
  FormikStatus,
} from '../../types';
import {
  HelpLinkContainer,
  HelpText,
  Anchor,
  ConfirmButton,
  ConfirmContainer,
  ErrorText,
  ErrorContainer,
  ErrorIcon,
} from './styles';

interface ConfirmFooterProps {
  formValues: FormValues;
  setFieldValue(field: FieldValues, value: unknown): void;
  isSubmitting: boolean;
  isValid: boolean;
  status?: FormikStatus;
}

export const ConfirmFooter = ({
  setFieldValue,
  formValues,
  isSubmitting,
  isValid,
  status,
}: ConfirmFooterProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const bookingStage = formValues[FieldValues.Stage];

  const buttonType =
    formValues[FieldValues.Stage] === BookingStage.DateSelect
      ? 'button'
      : 'submit';

  const onClick = useCallback(() => {
    if (bookingStage === BookingStage.DateSelect) {
      setFieldValue(FieldValues.Stage, BookingStage.Confirm);
    }
  }, [bookingStage, setFieldValue]);

  return (
    <ConfirmContainer>
      {status?.submissionError?.error && (
        <ErrorContainer>
          <ErrorIcon />
          <ErrorText>{status.submissionError.message}</ErrorText>
        </ErrorContainer>
      )}

      <HelpLinkContainer>
        <HelpText>{t('common.help_cta.need_help')}</HelpText>{' '}
        <Anchor forwardedAs="a" href={getHelpLink()} target="_blank">
          {t('common.help_cta.help_link')}
          <ExternalLinkIcon />
        </Anchor>
      </HelpLinkContainer>

      <ConfirmButton
        key={formValues[FieldValues.Stage]}
        $success={!!status?.submissionComplete}
        loading={isSubmitting}
        disabled={isSubmitting || !isValid || status?.submissionComplete}
        label={
          status?.submissionComplete
            ? t('booking_flow.booking_confirmed')
            : t('booking_flow.confirm')
        }
        type={buttonType}
        onClick={onClick}
        data-cy="practitioner-booking-confirm-button"
      />
    </ConfirmContainer>
  );
};
