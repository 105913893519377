export const formatTime = (timeInSeconds: number): string => {
  let formattedTime = '';

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor((timeInSeconds % 3600) % 60);

  if (hours > 0) {
    formattedTime = `${hours}:`;
  }

  formattedTime = hours
    ? minutes >= 10
      ? `${formattedTime}${minutes}:`
      : `${formattedTime}0${minutes}:`
    : `${minutes}:`;

  formattedTime = seconds
    ? seconds >= 10
      ? `${formattedTime}${seconds}`
      : `${formattedTime}0${seconds}`
    : `${formattedTime}00`;

  return formattedTime;
};

export const remainingTime = (
  durationTime: number,
  currentTime: number,
): string => {
  switch (durationTime - currentTime) {
    case 0:
    case durationTime:
      return formatTime(durationTime);
    default:
      return `-${formatTime(durationTime - currentTime)}`;
  }
};

export const calculateSeekTime = (
  lengthOfProgressBarHoveredPx: number,
  progressBarWidthPx: number,
  duration: number,
) => {
  if (lengthOfProgressBarHoveredPx <= 0) {
    return 0;
  }

  return (lengthOfProgressBarHoveredPx / progressBarWidthPx) * duration;
};
