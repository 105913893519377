import { startCase } from 'lodash';
import { rem } from 'polished';
import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled, ThemeInterface } from 'styles';
import {
  Calmness as CalmnessIcon,
  Chevron,
  Connection as ConnectionIcon,
  Coping as CopingIcon,
  Fulfilment as FulfilmentIcon,
  Happiness as HappinessIcon,
  Health as HealthIcon,
  IconProps,
  Sleep as SleepIcon,
} from 'icons';

import { CategoryName } from '../types/types';
import clearHeaderOnScroll from '../../../Navigation/clearHeaderOnScroll';
import { WrappingButton } from '../../../Shared/Accessibility';
import { small } from '../../../utils';
import { BodyText } from '../../../Shared/Typography';
import { UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands } from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import { Category } from './CategoryTypes';
import ScoreGraphic from './ScoreGraphic';

const ClickableHeader = styled(WrappingButton)<{ isOpen: boolean }>`
  width: 100%;
  transition: box-shadow 0.3s;
  padding: 47px 24px 24px 24px;
  ${clearHeaderOnScroll(5)};
`;

const CategoryIcon = ({ icon }: { icon: any }) => {
  const StyledIcon = styled(icon).attrs({
    primaryColor: 'transparent',
  })`
    display: none;

    ${small(css`
      display: block;
      width: ${rem(24)};
      height: ${rem(24)};
      margin-right: ${rem(8)};
    `)}
  `;

  return <StyledIcon />;
};

const CategorySection = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryHeading = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: [theme.typography.fontWeights.medium],
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 24px;
  text-transform: capitalize;
  max-width: 50%;
`;

const Arrow = styled(
  ({ isOpen, ...props }: IconProps & { isOpen: boolean }) => (
    <Chevron {...props} />
  ),
).attrs(({ theme }: { theme: ThemeInterface }) => ({
  primaryColor: theme.colors.text.secondary,
  secondaryColor: theme.colors.text.secondary,
}))`
  margin-left: ${rem(12)};
  ${props => props.isOpen && 'transform: rotate(90deg);'}
  transition: transform 200ms ease-in-out;

  ${small(css`
    font-size: ${rem(20)};
    line-height: ${rem(40)};
    margin-left: ${rem(40)};
  `)}
`;

const HeadingWrapper = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
`;

const CategoryHeaderBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;

  ${small(css`
    width: ${rem(330)};
    flex-wrap: nowrap;
  `)}
`;

const ScoreGraphicContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  width: ${rem(115)};

  ${small(css`
    width: ${rem(300)};
  `)}
`;

const categoryIcons: { [key in CategoryName]: ReactNode } = {
  happiness: HappinessIcon,
  calmness: CalmnessIcon,
  coping: CopingIcon,
  sleep: SleepIcon,
  health: HealthIcon,
  fulfilment: FulfilmentIcon,
  connection: ConnectionIcon,
};

interface CategoryHeaderProps extends Category {
  categoryNameKey: string;
  isOpen: boolean;
  onClick(): void;
  bands: TrackerBands[];
}

const CategoryHeader = ({
  name,
  categoryNameKey,
  score,
  isOpen,
  onClick,
  bands,
}: CategoryHeaderProps) => {
  const { t: translate } = useTranslation('track');

  const headerRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    // setTimeout needed to allow scrollIntoView with "smooth" behaviour
    setTimeout(() => {
      if (isOpen && headerRef.current) {
        headerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }, 100);
  }, [isOpen]);

  return (
    <ClickableHeader
      ref={headerRef}
      onClick={onClick}
      aria-expanded={isOpen}
      aria-label={`${translate('wellbeing.category_item.header.a11y_label', {
        category_name: startCase(name),
        score,
      })}%`}
      isOpen={isOpen}
    >
      <Wrapper aria-hidden={true}>
        <CategoryIcon icon={categoryIcons[categoryNameKey as CategoryName]} />
        <HeadingWrapper>
          <CategorySection>
            <CategoryHeaderBody>
              <CategoryHeading>{name}</CategoryHeading>
              <ScoreGraphicContainer>
                <ScoreGraphic
                  score={score}
                  boundaries={bands}
                  segmentLabels={[
                    translate('wellbeing.bands.low.tag'),
                    translate('wellbeing.bands.mid.tag'),
                    translate('wellbeing.bands.high.tag'),
                  ]}
                />
              </ScoreGraphicContainer>
            </CategoryHeaderBody>
            <Arrow isOpen={isOpen} />
          </CategorySection>
        </HeadingWrapper>
      </Wrapper>
    </ClickableHeader>
  );
};

export default CategoryHeader;
