import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React from 'react';
import { styled, ThemeInterface, css } from 'styles';
import { Arrow } from 'icons';
import { Link } from 'react-router-dom';
import ArrowLink, { ArrowLinkProps } from '../ArrowLink';
import BodyText from '../Typography/BodyText';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  height: inherit;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};
  cursor: pointer;
  outline: none;
  padding: ${rem(12)} ${rem(20)};
  text-decoration: none;

  ${({ theme }) => css`
    background-color: ${theme.button.primary.default.backgroundColor};
    border: ${rem(theme.button.borderWidth)} solid
      ${theme.button.primary.default.borderColor};

    &:hover {
      background-color: ${theme.button.primary.default.hover.backgroundColor};
    }

    &:active {
      background-color: ${theme.button.primary.default.active.backgroundColor};
    }

    &:focus {
      border-color: ${theme.button.borderFocusColor};
    }
  `}
`;

const LinkText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.button.primary.default.textColor,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  line-height: 1;
`;

const ArrowIcon = styled(Arrow).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.button.primary.default.textColor,
    width: 16,
    height: 12,
  }),
)`
  margin-left: 6px;
`;

interface ArrowButtonProps extends ArrowLinkProps {
  enableMobileWebLink?: boolean;
}

const ArrowButton = ({
  text,
  to,
  ariaLabel,
  onClick,
  enableMobileWebLink = false,
  className,
}: ArrowButtonProps) => {
  if (enableMobileWebLink && window.innerWidth <= Breakpoints.S) {
    return (
      <ArrowLink
        className={className}
        text={text}
        to={to}
        ariaLabel={ariaLabel}
        onClick={onClick}
      />
    );
  }

  return (
    <StyledLink className={className} onClick={onClick} to={to}>
      <LinkText>{text}</LinkText>
      <ArrowIcon />
    </StyledLink>
  );
};

export default ArrowButton;
