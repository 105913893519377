import { ArrowRightEncircled } from 'icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'styles';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import Card from '../../../Shared/Card';
import { BodyText } from '../../../Shared/Typography';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Wrapper = styled(Card)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 8px;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  height: 100%;
  transition: background-color 150ms ease-out;
`;

export const TextWrapper = styled.div``;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  display: inline-block;
`;

const IconContainer = styled.div`
  display: inherit;
  margin-left: auto;
`;

const Icon = styled(ArrowRightEncircled).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.textColor,
  secondaryColor: theme.button.primary.default.backgroundColor,
  height: 28,
  width: 28,
}))`
  margin-left: 10px;
  transition: fill 150ms ease-out;

  ${Wrapper}:hover & {
    rect:first-child {
      fill: ${({ theme }) =>
        theme.button.primary.default.hover.backgroundColor};
    }
  }
`;

type Props = {
  source: string;
};

const MoodTrackerCTA = ({ source }: Props) => {
  const { t: translate } = useTranslation('track');

  const onCTAClick = () =>
    tracking.track('mood-tracker-cta-clicked', {
      source,
    });

  return (
    <StyledLink onClick={onCTAClick} to={RoutePath.CheckIn}>
      <Wrapper>
        <Text>{translate('moods.checkin_cta.text')}</Text>
        <IconContainer aria-hidden="true">
          <Icon />
        </IconContainer>
      </Wrapper>
    </StyledLink>
  );
};

export default MoodTrackerCTA;
