import utf8 from 'utf8';

export const textDecoder = (buf: ArrayBuffer) => {
  const view = new Uint8Array(buf);
  let encodedStr = '';

  for (const byte of view) {
    encodedStr += String.fromCharCode(byte);
  }

  return utf8.decode(encodedStr);
};

export const textEncoder = (text: string) => {
  const encodedStr = utf8.encode(text);
  const utf8Array = new Uint8Array(encodedStr.length);

  for (const [index, char] of Array.from(encodedStr).entries()) {
    utf8Array[index] = char.charCodeAt(0);
  }

  return utf8Array;
};
