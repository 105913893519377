import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logException } from '../logging';
import { TrackingConfig } from '../Tracking';
import {
  getSentAtTimestamp,
  ServersideTrackingEvent,
  updateInteractionSession,
} from '../Tracking/serverside';
import { LOG_EVENT_MUTATION } from '../Tracking/serverside/withLogEvent';
import { LastLocationContext } from './LastLocationProvider';

/**
 * A hook which configures page load analytics events. This should be called in
 * a single top-level component.
 */
const usePageTracking = (
  pageName: string,
  trackingConfig?: TrackingConfig,
): void => {
  const location = useLocation();
  const lastLocation = useContext(LastLocationContext);

  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const logServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  useEffect(() => {
    if (window.analytics) {
      if (lastLocation === '') {
        window.analytics.page(pageName);
      } else {
        window.analytics.page(pageName, undefined, { referrer: lastLocation });
      }
    }
    if (trackingConfig) {
      const { alwaysUpdateInteractionSession, trackServerside } =
        trackingConfig;

      if (alwaysUpdateInteractionSession) {
        updateInteractionSession();
      }
      if (trackServerside) {
        void logServerside('NAVIGATED_TO_PAGE', {
          pageName,
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        });
      }
    }
  }, [location.pathname]);
};

export default usePageTracking;
