import { isNullOrUndefined } from 'util';
import { ThemeInterface, useTheme } from 'styles';

import React from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory';
import { VictoryClipContainer } from 'victory-core';

import { getChartEdges, getDataWithoutEmptyValues } from './chartHelpers';

export interface ChartData {
  x: number | string;
  y: number;
}

export interface LineChartProps {
  data: ChartData[];
  hideYAxis: boolean;
  /**
   * Defines amount of points displayed on the graph, thus left edge of the chart viewport
   */
  pointDisplayCount: number;
  /**
   * Defines right edge of the chart viewport
   * If passed -1, will default to last element in the data collection
   */
  displayUpToIndex?: number;
  width?: number;
  height?: number;
  yMax?: number;
}

const getChartTheme = (theme: ThemeInterface) => ({
  lineStyle: {
    data: { stroke: theme.colors.staticPalette.bluish, strokeWidth: 3 },
  },
  scatterStyle: {
    data: { fill: theme.colors.staticPalette.bluish },
  },
  emptyLineStyle: {
    data: { stroke: 'transparent' },
  },
  emptyScatterStyle: {
    data: { fill: 'transparent' },
  },
  chartTheme: {
    axis: {
      style: {
        grid: {
          stroke: theme.colors.border.secondary,
        },
        tickLabels: {
          fontFamily: theme.typography.bodyText.fontFamily,
          fontSize: theme.typography.fontSizes.fontSize12,
          padding: theme.typography.fontSizes.fontSize12,
          color: theme.colors.text.secondary,
          fill: theme.colors.text.secondary,
        },
      },
    },
    line: {
      style: {
        labels: { fontSize: theme.typography.fontSizes.fontSize8 },
      },
    },
  },
});

export const LineChart = ({
  hideYAxis,
  data,
  pointDisplayCount = 7,
  displayUpToIndex = 7,
  width,
  height,
  yMax,
}: LineChartProps) => {
  const theme = useTheme();
  const splittedData = getDataWithoutEmptyValues(data);
  const { start, end } = getChartEdges(
    splittedData,
    displayUpToIndex,
    pointDisplayCount,
  );
  const maxValue = !isNullOrUndefined(yMax)
    ? yMax
    : Math.max(...data.map(value => value.y));
  const {
    chartTheme,
    emptyLineStyle,
    emptyScatterStyle,
    lineStyle,
    scatterStyle,
  } = getChartTheme(theme);

  return (
    <VictoryChart
      height={height}
      width={width}
      animate={{
        duration: 850,
        easing: 'linear',
      }}
      // @ts-ignore
      theme={chartTheme}
      containerComponent={
        <VictoryZoomContainer
          height={height}
          width={width}
          allowZoom={false}
          allowPan={false}
          zoomDimension={'x'}
          zoomDomain={{
            x: [start, end],
            y: [0, maxValue + 1],
          }}
          responsive={false}
          clipContainerComponent={
            <VictoryClipContainer
              clipPadding={{
                left: 6,
                right: 10,
              }}
            />
          }
        />
      }
    >
      <VictoryAxis
        crossAxis
        standalone={false}
        style={{
          axis: {
            stroke: theme.colors.border.secondary,
            fill: theme.colors.border.secondary,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        crossAxis
        offsetX={hideYAxis ? -99 : undefined}
        standalone={false}
      />
      {splittedData.map(
        ({ hideScatter, data: chunkData }, index) =>
          chunkData.length && (
            <VictoryScatter
              key={index}
              style={hideScatter ? emptyScatterStyle : scatterStyle}
              data={chunkData}
              size={6}
            />
          ),
      )}
      {splittedData.map(
        ({ hideLine, data: chunkData }, index) =>
          chunkData.length && (
            <VictoryLine
              key={index}
              style={hideLine ? emptyLineStyle : lineStyle}
              interpolation="natural"
              data={chunkData}
            />
          ),
      )}
    </VictoryChart>
  );
};
