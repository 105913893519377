import { BodyText } from '@unmind/design-system-components-web';
import { Office365, Apple, Yahoo, Google, Outlook } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styles/styled';

export const sharedIconStyle = css`
  width: ${rem(20)};
  margin-right: ${rem(10)};
  height: ${rem(20)};
`;

export const Office365Icon = styled(Office365).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  ${sharedIconStyle}
`;

export const OutlookIcon = styled(Outlook).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  ${sharedIconStyle}
`;

export const AppleIcon = styled(Apple).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  ${sharedIconStyle}
`;

export const GoogleIcon = styled(Google).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  ${sharedIconStyle}
`;

export const YahooIcon = styled(Yahoo).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  ${sharedIconStyle}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconLabel = styled.span`
  font-size: ${rem(12)};
  margin-top: ${rem(5)};
  display: flex;
  align-items: center;
  padding: ${rem(10)} 0;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    cursor: pointer;
  }
`;

export const CalendarLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

export const RadioOption = styled.input`
  margin-right: ${rem(10)};
  width: ${rem(20)};
  height: ${rem(20)};
  accent-color: ${({ theme }) => theme.colors.text.link};
`;
