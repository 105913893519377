import React from 'react';
import { LinkProps } from 'react-router-dom';
import CompletedState from './CompletedState';
import IncompleteState from './IncompleteState';

export interface SeriesReviewProps {
  started: boolean;
  seriesCompleted: boolean;
  seriesId: string;
  seriesLink: LinkProps['to'];
  seriesTitle: string;
}

const SeriesReview = ({
  started,
  seriesCompleted,
  seriesTitle,
  seriesLink,
  seriesId,
}: SeriesReviewProps) => {
  if (!seriesCompleted) {
    return <IncompleteState started={started} seriesLink={seriesLink} />;
  }

  return <CompletedState seriesId={seriesId} seriesTitle={seriesTitle} />;
};

export default SeriesReview;
