import { Colors } from '@unmind/design-system-theme';
import moment from 'moment';
import { rem } from 'polished';
import React from 'react';
import { styled, useTheme } from 'styles';
import { Info } from 'icons';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { formatISO, subDays } from 'date-fns';
import { AdminLineChart } from '../../../../Shared/Charts';
import formatPreciseNumber from '../../../../utils/formatNumber/formatPreciseNumber';
import {
  ANONYMITY_THRESHOLD,
  timeFilterBreakdownPeriod,
} from '../../constants';
import Panel, { PanelSection } from '../../Panel';
import { useFilterState } from '../../FilterState/FilterState';
import { stripUnusedQueryVariables } from '../../FilterState/helpers';
import { TimePeriod } from '../../../../__generated__/globalTypes';
import Metric from '../../Metric';
import { BodyText } from '../../../../Shared/Typography';
import {
  GetOrganisationSignUps,
  GetOrganisationSignUps_allTime,
  GetOrganisationSignUps_currentPeriod,
} from './__generated__/GetOrganisationSignUps';

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > *:first-child {
    margin-right: ${rem(48)};
  }
`;

const CaptionContainer = styled.div`
  text-align: center;
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
}))`
  display: flex;
  width: ${rem(20)};
  height: ${rem(20)};
  margin-left: ${rem(5)};
  margin-top: ${rem(4)};
`;

interface PanelContentsProps {
  currentPeriod: GetOrganisationSignUps_currentPeriod;
  allTime: GetOrganisationSignUps_allTime;
  panelColor: Colors;
}

const captionTemplates = {
  [TimePeriod.LAST_30_DAYS]: 'Daily registrations in the last 30 days',
  [TimePeriod.LAST_12_WEEKS]: 'Weekly registrations in the last 12 weeks',
  [TimePeriod.LAST_6_MONTHS]: 'Monthly registrations in the last 6 months',
  [TimePeriod.ALL_TIME]: 'All time registrations',
};

const UserRegistrationPanelContents = ({
  currentPeriod,
  allTime,
  panelColor,
}: PanelContentsProps) => {
  const { typography } = useTheme();
  const { timeFilterPreset } = useFilterState();

  const hideCurrentPeriodData = !!currentPeriod.shouldBeAnonymised;

  const userData =
    !hideCurrentPeriodData && currentPeriod.userCountByPeriod
      ? currentPeriod.userCountByPeriod.map(({ periodStart, count }) => ({
          date: moment(periodStart).toDate(),
          count,
        }))
      : [];

  const currentPeriodCountValue = currentPeriod.totalUserCount || 0;
  const currentPeriodCountDisplay = hideCurrentPeriodData
    ? `<${ANONYMITY_THRESHOLD}`
    : formatPreciseNumber(currentPeriodCountValue);
  const allTimeCountValue = allTime.totalUserCount || 0;
  const allTimeCountDisplay = formatPreciseNumber(allTimeCountValue);

  return (
    <>
      <PanelSection>
        <MetricContainer>
          <Metric
            color={panelColor}
            title="Current time period"
            value={currentPeriodCountDisplay}
          />
          <Metric
            color={panelColor}
            title="All time"
            value={allTimeCountDisplay}
          />
        </MetricContainer>
      </PanelSection>
      <PanelSection>
        <AdminLineChart
          caption={captionTemplates[timeFilterPreset]}
          data={userData}
          isDataHidden={hideCurrentPeriodData}
          height={216}
          title="New users"
          description="The number of new users who have signed up to Unmind over the selected period."
          dataPointsToDisplay={userData.length}
          dataLabelUnit="Registrations"
        />
        <CaptionContainer>
          <BodyText sizes={[typography.fontSizes.fontSize12]}>
            {captionTemplates[timeFilterPreset]}
          </BodyText>
        </CaptionContainer>
      </PanelSection>
    </>
  );
};

export const USER_REGISTRATIONS_QUERY = gql`
  query GetOrganisationSignUps(
    $allTimeInput: OrganisationSignUpsInput!
    $currentPeriodInput: OrganisationSignUpsInput!
    $periodInput: BreakdownPeriod!
  ) {
    allTime: organisationSignUps(input: $allTimeInput) {
      totalUserCount
      shouldBeAnonymised
    }
    currentPeriod: organisationSignUps(input: $currentPeriodInput) {
      totalUserCount
      userCountByPeriod(period: $periodInput) {
        periodStart
        count
      }
      shouldBeAnonymised
    }
  }
`;

export const RegistrationPanel = () => {
  const { colors } = useTheme();
  const { userGroupFilterState, timeFilterState, timeFilterPreset } =
    useFilterState();
  const panelColor = colors.staticPalette.topaz;

  const nonEmptyUserGroupFilterState = stripUnusedQueryVariables(
    userGroupFilterState || {},
  );

  const breakdownPeriod = timeFilterBreakdownPeriod[timeFilterPreset];

  const allTimeRegistrationsDateRange = {
    endDate: formatISO(subDays(new Date(), 1), { representation: 'date' }),
  };

  const { error, loading, data } = useQuery<GetOrganisationSignUps>(
    USER_REGISTRATIONS_QUERY,
    {
      variables: {
        currentPeriodInput: {
          ...timeFilterState,
          ...nonEmptyUserGroupFilterState,
          timePeriod: timeFilterPreset,
        },
        allTimeInput: {
          ...allTimeRegistrationsDateRange,
          ...nonEmptyUserGroupFilterState,
          startDate: undefined,
        },
        periodInput: breakdownPeriod,
      },
      context: {
        timeout: 15000,
      },
    },
  );

  const { currentPeriod, allTime } = data || {};

  return (
    <Panel
      color={panelColor}
      title="Registration"
      isLoading={loading}
      hasError={!!error || !currentPeriod || !allTime}
      tooltip={{
        children: <InfoIcon />,
        labelCopy: 'Showing registered users that have not been deleted',
        placement: 'right',
        accessibilityId: 'user-registration-tooltip',
      }}
    >
      {!error && currentPeriod && allTime && !loading ? (
        <UserRegistrationPanelContents
          panelColor={panelColor}
          currentPeriod={currentPeriod}
          allTime={allTime}
        />
      ) : null}
    </Panel>
  );
};

export default RegistrationPanel;
