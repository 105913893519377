import React, { useEffect, useState } from 'react';
import useFeatureFlag from '../../../flags/useFeatureFlag';
import { useFilterState } from '../FilterState';
import { UserGroupFilterStateKey } from '../FilterState/types';
import FilterDropdown from './FilterDropdown';
import RadioButtons from './RadioButtons/RadioButtons';
import TimeFilterDropdown from './TimeFilterDropdown';
import useLoadFilterOptions from './useLoadFilterOptions';
import { RadioContainer, Panel } from './FilterPanel.styled';

const radioOptions: { label: string; value: UserGroupFilterStateKey }[] = [
  {
    label: 'Locations',
    value: 'locationIds',
  },
  {
    label: 'Departments',
    value: 'departmentIds',
  },
];

const FilterPanel = () => {
  const {
    timeFilterState,
    setUserGroupFilterType,
    userGroupFilterType,
    adminTabType,
    timeFilterPreset,
  } = useFilterState();

  const { availableFilterTypes, dashboardFilterOptions, loading } =
    useLoadFilterOptions({
      userGroupFilterType,
      timeFilterState,
      adminTabType,
      timeFilterPreset,
    });
  const [isRadioVisible, setRadioVisible] = useState<boolean>(false);

  const isFiltersFeatureEnabled = useFeatureFlag(
    'admin-dashboards-show-filters',
  );

  const isTimeFilterFeatureEnabled = useFeatureFlag(
    'admin-dashboards-enable-time-filtering',
  );

  useEffect(() => {
    if (!isFiltersFeatureEnabled) {
      return setRadioVisible(false);
    }

    if (loading) {
      return;
    }

    if (availableFilterTypes.length === 1) {
      if (userGroupFilterType) {
        return;
      }

      const [validFilterType] = availableFilterTypes;
      setUserGroupFilterType(validFilterType, true);

      return setRadioVisible(false);
    }

    if (availableFilterTypes.length > 1) {
      return setRadioVisible(true);
    }
  }, [
    dashboardFilterOptions,
    isFiltersFeatureEnabled,
    loading,
    setUserGroupFilterType,
    userGroupFilterType,
    availableFilterTypes,
  ]);

  if (loading) {
    return null;
  }

  const handleRadioChange = (newFilterType: UserGroupFilterStateKey) => {
    setUserGroupFilterType(newFilterType);
  };

  return (
    <Panel>
      {isRadioVisible ? (
        <RadioContainer>
          <RadioButtons
            onChange={handleRadioChange}
            radioOptions={radioOptions}
            value={userGroupFilterType}
            title="Filter"
          />
        </RadioContainer>
      ) : null}
      {userGroupFilterType ? (
        <FilterDropdown
          options={dashboardFilterOptions[userGroupFilterType]}
          filterType={userGroupFilterType}
          adminTabType={adminTabType}
        />
      ) : null}
      {isTimeFilterFeatureEnabled ? <TimeFilterDropdown /> : null}
    </Panel>
  );
};

export default FilterPanel;
