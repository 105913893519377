import { app as MSTeamsApp } from '@microsoft/teams-js';
import RoutePath from 'App/RoutePath';
import {
  PlatformType,
  trackUtmParamsIfAvailable,
  tracking,
} from 'App/Tracking';

export const RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY = 'resolvedDeepLink';
/*
 ** Returns MS Teams user context (extra information about the user)
 */
export const getMSTeamsUserContext = (): MSTeamsApp.Context | undefined => {
  const context = localStorage.getItem('MSTeamsContext');
  if (context) {
    return JSON.parse(context);
  }
};

type DeepLinkContentType = 'audio' | 'video' | 'link';

interface MSTeamsURLInformation {
  internalLink?: string;
  externalLink?: string;
  utmLink?: string;
  appId?: string;
  isWelcomeNotification?: boolean;
  externalLinkTitle?: string;
  externalLinkContentType?: DeepLinkContentType;
}

/*
 ** Returns URL information passed to context from chatbot
 */
export const getMSTeamsUrlInfo = (): MSTeamsURLInformation | undefined => {
  const context = getMSTeamsUserContext();

  if (context) {
    const encodedSubPageId = context?.page?.subPageId;
    if (encodedSubPageId) {
      const decodedSubPageId = JSON.parse(
        decodeURIComponent(atob(encodedSubPageId)),
      );
      if (decodedSubPageId.urlInformation) {
        return decodedSubPageId.urlInformation;
      }

      return decodedSubPageId;
    }
  }
};

/*
 ** Returns whether the web-app is rendered in MS Teams or not
 */
export const isMSTeams = () => localStorage.getItem('isMSTeams') === 'true';

export const trackMSTeamsMessageClicked = (): void => {
  if (!isMSTeams()) {
    return;
  }

  const { internalLink, externalLink, utmLink, isWelcomeNotification } =
    getMSTeamsUrlInfo() || {};

  const noDeeplinkInformationInContext =
    !internalLink && !externalLink && !utmLink;

  if (noDeeplinkInformationInContext) {
    return;
  }

  const getContentUrl = (): string | undefined => {
    const bothLinksProvided = internalLink && externalLink;
    if (bothLinksProvided) {
      //  todo here check if the user is authenticated

      return internalLink;
    }

    return internalLink || externalLink;
  };

  const { user } = getMSTeamsUserContext() || {};

  return tracking.track('ms-teams-message-clicked', {
    contentUrl: getContentUrl(),
    tenantId: user?.tenant?.id,
    MSTeamsUserId: user?.id,
    isWelcomeNotification,
  });
};

// Call this to initialise the MS Teams SDK
export const initialiseMSTeamsApp = async () => {
  if (!MSTeamsApp.isInitialized()) {
    try {
      await MSTeamsApp.initialize();

      const context = await MSTeamsApp.getContext();

      localStorage.setItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY, 'false');
      localStorage.setItem('isMSTeams', 'true');
      localStorage.setItem('MSTeamsContext', JSON.stringify(context));

      const { utmLink } = getMSTeamsUrlInfo() || {};

      if (utmLink) {
        trackUtmParamsIfAvailable({
          search: utmLink,
          pathname: window.location.pathname,
        });
      }

      trackMSTeamsMessageClicked();

      MSTeamsApp.notifyAppLoaded();
      MSTeamsApp.notifySuccess();
    } catch (e) {
      // This logic is triggered when accessing web-app outside of MS Teams
      localStorage.removeItem('isMSTeams');
      localStorage.removeItem('MSTeamsContext');
    }
  }
};

export const isEmbeddedTeamsMobileFrame = (): boolean => {
  const userAgent = window.navigator.userAgent;

  return userAgent.toLowerCase().includes('teamsmobile');
};

/*
 ** Returns the MS Teams device that the user is using - e.g. web, desktop, ios, android
 */
export const getMSTeamsDevice = (): PlatformType | 'unknown' => {
  const context = getMSTeamsUserContext();
  if (isMSTeams() && context) {
    return context.app.host.clientType as unknown as PlatformType;
  }

  return 'unknown';
};

export const getMSTeamsLocale = (): string | null => {
  const context = getMSTeamsUserContext();
  if (isMSTeams() && context) {
    return context.app.locale;
  }

  return null;
};

export const createLinkToExternalPlayer = (
  link: string,
  mediaType: DeepLinkContentType = 'video',
  title?: string,
): string =>
  `${
    RoutePath.AlternativeStartPagePlayer
  }?mediaSrc=${link}&mediaType=${mediaType}${title ? `&title=${title}` : ''}`;
