import { styled } from 'styles';
import React from 'react';
import RoutePath from 'App/RoutePath';
import { rem } from 'polished';
import { small } from 'utils';
import { css } from 'styled-components';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { StickyFooter } from '../Shared/StickyFooter';
import { NovaStartPageHeader } from './NovaStartPageHeader';
import { NovaBody } from './NovaBody';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(75)};

  ${small(css`
    padding-bottom: 0;
  `)};
`;

export const NovaStartPage = () => {
  const subdomain = getSubdomainFromUrl();
  const ctaNavigationPath =
    !subdomain || subdomain === 'search'
      ? RoutePath.SignInToYourOrganisation
      : RoutePath.Chatbot;

  return (
    <Container data-testid="alternative-start">
      <NovaStartPageHeader ctaNavigationPath={ctaNavigationPath} />
      <NovaBody ctaNavigationPath={ctaNavigationPath} />
      <StickyFooter
        actionButtonLabel="Get started with Nova ->"
        path={ctaNavigationPath}
        onCtaClick={() => {
          tracking.track('teams-start-page-cta-clicked', {
            abTestGroup: 'nova-focussed-start-page-sept-2024',
            tenantId: getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
            ctaLocation: 'sticky-footer',
          });
        }}
      />
    </Container>
  );
};
