import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styles';
import CourseCard from '../../Courses/CourseCard';
import ShortCard from '../../Shorts/ShortCard';
import { medium, small } from '../../utils';
import { categoryContent_exploreCategory_content as CategoryContent } from '../Category/__generated__/categoryContent';

const ContentList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${small(css`
    display: grid;
    flex-wrap: none;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 24px;
  `)}

  ${medium(css`
    grid-gap: 32px;
  `)}
`;

const ContentCardWrapper = styled.div`
  width: calc((100% - ${rem(16)}) / 2);
  margin-right: 16px;
  margin-bottom: 16px;

  &:nth-of-type(2n) {
    margin-right: 0px;
  }

  ${small(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)}
`;

interface ContentProps {
  content: CategoryContent[];
  assetToken?: string | null;
  source: string;
}

function ContentCardGrid({ content, assetToken, source }: ContentProps) {
  return (
    <ContentList data-testid="content-list">
      {content.map(item => {
        if (item.__typename === 'Tool') {
          return (
            <ContentCardWrapper key={item.id}>
              <ShortCard
                tool={item}
                assetToken={assetToken}
                showDescription
                source={source}
              />
            </ContentCardWrapper>
          );
        } else {
          return (
            <ContentCardWrapper key={item.id}>
              <CourseCard
                series={item}
                assetToken={assetToken}
                showDescription
                source={source}
              />
            </ContentCardWrapper>
          );
        }
      })}
    </ContentList>
  );
}

export default ContentCardGrid;
