import RoutePath from 'App/RoutePath';
import useIsManagerExperienceActive from 'Home/Managers/useIsManagerExperienceActive';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import React from 'react';
import { Redirect } from 'react-router';
import LoadingIndicator from 'Shared/LoadingIndicator/LoadingIndicator';
import { useLatestCompassIndex } from './api';
import { CompassOnboardingPage } from './CompassOnboardingPage';
import { CompassDashboard } from './Components/CompassDashboard/CompassDashboard';

export const Compass = () => {
  const isManagerExperienceActive = useIsManagerExperienceActive();
  const compassEnabled = useFeatureFlag(FEATURE_FLAGS.COMPASS);
  const {
    data,
    loading,
    refetch: refetchLatestSchedule,
  } = useLatestCompassIndex();

  if (!isManagerExperienceActive || !compassEnabled) {
    return <Redirect to={RoutePath.Home} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return data?.latestCompassIndex?.latestSchedule ? (
    <CompassDashboard
      latestSchedule={data?.latestCompassIndex?.latestSchedule}
      refetchLatestSchedule={refetchLatestSchedule}
    />
  ) : (
    <CompassOnboardingPage showCreateCompassSurveyButton={true} />
  );
};
