import { Namespace } from 'i18next';
import { CalendarAdd } from 'icons';
import React, { useCallback, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MediaButtonTooltip from 'Shared/MediaPlayerComponents/MediaButtonTooltip';
import { keyboardHandlers } from 'Shared/MediaPlayerComponents/keyboardHandlers';
import { tracking } from 'App/Tracking';
import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import {
  AddToCalendarButton,
  AddToCalendarIcon,
  AddToCalendarIconButton,
  AddToCalendarText,
} from './styles';
import { AddToCalendarModal } from './AddToCalendarModal/AddToCalendarModal';

type AddToCalendarProps = {
  buttonTextKey?:
    | 'add_to_calendar.text'
    | 'add_to_calendar.course_reminder_text';
  displayBorder?: boolean;
  isVideoPlayer?: boolean;
  useSmallButton?: boolean;
  description?: string;
  title: string;
  onAddToCalendarModalToggled?(open: boolean): void;
  contentPath: string;
  isKeyDownListenerEnabled?: boolean;
};

const AddToCalendar = ({
  buttonTextKey,
  title,
  displayBorder = true,
  isVideoPlayer = false,
  useSmallButton = false,
  description,
  contentPath,
  isKeyDownListenerEnabled,
  onAddToCalendarModalToggled,
}: AddToCalendarProps) => {
  const { t: translate } = useTranslation<Namespace<'shared'>>('shared');
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onClick = useCallback(() => {
    if (!modalIsOpen) {
      tracking.track('add-to-calendar-clicked');
    }

    if (onAddToCalendarModalToggled) {
      onAddToCalendarModalToggled(!modalIsOpen);
    }

    setModalIsOpen(!modalIsOpen);
  }, [modalIsOpen, onAddToCalendarModalToggled]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (
        isKeyDownListenerEnabled &&
        keyboardHandlers.isCKey(e) &&
        !modalIsOpen
      ) {
        onClick();
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [
    isKeyDownListenerEnabled,
    onClick,
    onAddToCalendarModalToggled,
    modalIsOpen,
  ]);

  return (
    <>
      {!buttonTextKey ? (
        <AddToCalendarIconButton isVideoPlayer={isVideoPlayer}>
          <AddToCalendarIcon
            Icon={CalendarAdd}
            onClick={onClick}
            onMouseEnter={() => (isDesktop ? setTooltipVisible(true) : null)}
            onMouseLeave={() => setTooltipVisible(false)}
            iconOnly={true}
            isVideoPlayer={isVideoPlayer}
            useSmallButton={useSmallButton}
          />
          {isTooltipVisible && (
            <MediaButtonTooltip
              label={translate('add_to_calendar.tooltip_label')}
              minWidth={132}
              isLeftOffset={false}
            />
          )}
        </AddToCalendarIconButton>
      ) : (
        <AddToCalendarButton
          bordered={displayBorder}
          onClick={onClick}
          role="button"
          tabIndex={0}
          aria-label={translate(buttonTextKey)}
        >
          <AddToCalendarIcon
            Icon={CalendarAdd}
            isVideoPlayer={isVideoPlayer}
            useSmallButton={useSmallButton}
          />
          {buttonTextKey && (
            <AddToCalendarText useSmallButton={useSmallButton}>
              {translate(buttonTextKey)}
            </AddToCalendarText>
          )}
        </AddToCalendarButton>
      )}
      {modalIsOpen && (
        <ModalWithDrawerOnMobile onClose={() => setModalIsOpen(false)}>
          <AddToCalendarModal
            onClose={onClick}
            title={title}
            description={description}
            contentPath={contentPath}
          />
        </ModalWithDrawerOnMobile>
      )}
    </>
  );
};

export default AddToCalendar;
