import { BodyText, medium } from '@unmind/design-system-components-web';
import { getUserLocale } from 'i18n/getUserLocale';
import { Namespace } from 'i18next';
import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useUserId } from './useUserId';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
`;

const Banner = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border.info};
  height: ${rem(26)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BannerText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.inverse,
  sizes: [theme.typography.fontSizes.fontSize10],
}))``;

const BannerLink = styled.a`
  color: ${({ theme }) => theme.colors.text.inverse};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.text.inverse};

  &:hover {
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;

export const HeaderBar = styled.div<{ blurBackground?: boolean }>`
  height: ${rem(50)};
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  background: ${({ theme, blurBackground }) =>
    blurBackground
      ? `linear-gradient(180deg, ${
          theme.colors.background.primary
        } 50%, ${theme.colors.toast.overlay.replace('0.2', '0')} 100%)`
      : 'none'};

  ${medium(css`
    height: ${rem(50)};
  `)}
`;

export interface FeedbackBannerProps {
  i18nNamespace: Namespace;
  i18nKey: string;
  surveyLink: string;
  supportedFeedbackLocales: string[];
  blurBackground?: boolean;
  includeUserId?: boolean;
}

const FeedbackBanner = ({
  i18nNamespace,
  i18nKey,
  surveyLink,
  supportedFeedbackLocales,
  blurBackground = true,
  includeUserId = true,
}: FeedbackBannerProps) => {
  const { t: translate } = useTranslation(i18nNamespace);
  const userLocale = getUserLocale();

  // Include the user ID in the survey link
  // https://www.typeform.com/help/a/using-hidden-fields-360052676612/
  const { data } = useUserId();
  const userId = data?.user?.id;
  surveyLink =
    includeUserId && userId ? `${surveyLink}#user_id=${userId}` : surveyLink;

  return (
    <HeaderWrapper>
      {supportedFeedbackLocales.includes(userLocale) ? (
        <Banner>
          <BannerText>
            <Trans
              t={translate}
              i18nKey={i18nKey as any} // eslint-disable-line
              components={{
                survey_link: <BannerLink href={surveyLink} target="_blank" />,
              }}
            />
          </BannerText>
        </Banner>
      ) : null}
      <HeaderBar blurBackground={blurBackground} />
    </HeaderWrapper>
  );
};

export default FeedbackBanner;
