import meanBy from 'lodash/meanBy';
import { rem } from 'polished';
import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';

import { Mood, moods } from '../types';
import { small } from '../../../utils';
import { Heading, ParagraphSmall } from '../../Typography';
import { getCheckInScores_lastMonthCheckins } from '../__generated__/getCheckInScores';
import MoodScoreMoodFace from './MoodScoreMoodFace';

const AvgMoodHeadingContainer = styled.div`
  flex-shrink: 0;
  margin-bottom: ${rem(20)};

  ${small(css`
    max-width: ${rem(140)};
    margin-right: ${rem(24)};
  `)}
`;

const AvgMoodFaceContainer = styled.div`
  margin: 0 auto;

  @media (min-width: ${rem(440)}) {
    margin-left: 0;
    margin-right: ${rem(24)};
  }
`;

const LastMonthAvgMood = styled.div`
  ${small(css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 40%;
  `)}
`;

export const TotalCheckins = styled(ParagraphSmall)``;

const MoodScoresLastMonth: React.FunctionComponent<{
  lastMonthCheckins: getCheckInScores_lastMonthCheckins[];
}> = ({ lastMonthCheckins }) => {
  const { t: translate } = useTranslation<Namespace<'insights'>>('insights');

  if (lastMonthCheckins.length === 0) {
    return null;
  }

  const averageMood: Mood =
    moods[Math.round(meanBy(lastMonthCheckins, 'moodId')) - 1];

  return (
    <LastMonthAvgMood data-testid="mood-scores-last-month">
      <AvgMoodHeadingContainer>
        <Heading>{translate('moods.checkins_last_month.heading')}</Heading>
        <TotalCheckins>
          {Trans({
            t: translate,
            i18nKey: 'moods.checkins_last_month.label',
            values: { count: lastMonthCheckins.length },
            components: { bold: <strong /> },
          })}
        </TotalCheckins>
      </AvgMoodHeadingContainer>
      <AvgMoodFaceContainer>
        <MoodScoreMoodFace mood={averageMood} width={132} height={132} />
      </AvgMoodFaceContainer>
    </LastMonthAvgMood>
  );
};

export default MoodScoresLastMonth;
