export const parseBeGatewayId = (id?: string) => {
  if (!id) {
    return null;
  }

  if (id.indexOf('-') > -1) {
    return parseInt(id.split('-')[1], 10);
  }

  return parseInt(id, 10);
};
