import {
  LINK_SLACK_USER_MUTATION,
  UNLINK_SLACK_USER_MUTATION,
} from '../../../Services/slack/slack.services';

interface GetSlackIntegrationDataArgs {
  isSlackUser?: boolean;
  permission?: 'admin' | 'user';
  slackIntegration?: {
    teams: { id: string; name: string; __typename: 'SlackTeam' }[];
    appId: string;
    __typename: 'SlackIntegration';
  } | null;
}

const createSlackIntegrationData = ({
  isSlackUser = false,
  permission = 'user',
  slackIntegration = {
    teams: [
      {
        id: 'T052NMMRMEU',
        name: 'Dev Space',
        __typename: 'SlackTeam',
      },
    ],
    appId: 'A051W4PS1RB',
    __typename: 'SlackIntegration',
  },
}: GetSlackIntegrationDataArgs = {}) => ({
  organisation: {
    id: 'test-organisation-id',
    slackIntegration,
    __typename: 'Organisation',
  },
  user: {
    id: 'test-user-id',
    connectedSlackUsers: isSlackUser
      ? [{ id: 'U012A345B678', __typename: 'ConnectedSlackUser' }]
      : [],
    permissions: {
      value: permission,
      __typename: 'UserAttribute',
    },
    __typename: 'User',
  },
});

export const userInOrgWithSlackInstalled = createSlackIntegrationData();

export const userInOrgWithoutSlackInstalled = createSlackIntegrationData({
  slackIntegration: null,
});

export const connectedRegularUser = createSlackIntegrationData({
  isSlackUser: true,
});

export const adminUserInOrgWithSlackInstalled = createSlackIntegrationData({
  permission: 'admin',
});

export const adminUserInOrgWithoutSlackInstalled = createSlackIntegrationData({
  permission: 'admin',
  slackIntegration: null,
});

export const connectedAdminUser = createSlackIntegrationData({
  permission: 'admin',
  isSlackUser: true,
});

export const createLinkSlackUserResponse = ({
  success,
  clientSentAtUtcTimestamp = '2023-01-01 00:00:01.000',
  slackUserId,
}: {
  success: boolean;
  clientSentAtUtcTimestamp?: string;
  slackUserId: string;
}) => ({
  request: {
    query: LINK_SLACK_USER_MUTATION,
    variables: { clientSentAtUtcTimestamp, slackUserId },
  },
  result: jest.fn(() => ({
    data: {
      linkSlackUserToUnmind: {
        success,
        __typename: 'LinkSlackUserToUnmindResponse',
      },
    },
  })),
});

export const createUnlinkSlackUserResponse = ({
  success,
  clientSentAtUtcTimestamp = '2023-01-01 00:00:01.000',
  slackUserId,
}: {
  success: boolean;
  clientSentAtUtcTimestamp?: string;
  slackUserId: string;
}) => ({
  request: {
    query: UNLINK_SLACK_USER_MUTATION,
    variables: { clientSentAtUtcTimestamp, slackUserId },
  },
  result: jest.fn(() => ({
    data: {
      unlinkSlackUserToUnmind: {
        success,
        __typename: 'UnlinkSlackUserToUnmindResponse',
      },
    },
  })),
});
