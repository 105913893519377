import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsEmpower = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.194 8.626c1.468-1.147 3.237-.424 4.115.787a.95.95 0 0 1 .024.034c.396.596.676 1.272.605 2.003-.072.753-.493 1.347-1.042 1.813l-3.75 3.175a1 1 0 0 1-1.292-1.527l3.748-3.174c.302-.256.34-.412.346-.478.008-.088-.012-.296-.269-.687-.21-.283-.466-.441-.683-.494a.629.629 0 0 0-.557.113l-3.288 2.818-.002.002c-.368.313-.834.517-1.267.63-.43.111-.937.16-1.409.064a1 1 0 0 1-.114-.031l-9.673-3.224c-1.267-.418-2.349-.177-3.648.647l-.002.001-3.493 2.242a1.82 1.82 0 0 0-.668.862c-.134.36-.102.634-.005.805.09.159.282.302.617.346.34.045.712-.034.98-.199l3.5-2.25c.878-.554 1.843-.374 2.472-.168l.014.005 2.371.813a1 1 0 0 1 .616 1.288l-2 5.5a1 1 0 0 1-.454.532l-4.5 2.5-.019.01c-.42.222-.991.366-1.467.366H5.002C4.507 23.75 4 24.2 4 24.88c0 .673.502 1.12 1.002 1.12h4.059a4.757 4.757 0 0 0 2.214-.555m0 0l4.875-2.58a1 1 0 0 1 .318-.105l5.169-.781h.003a2.884 2.884 0 0 1 2.123.506l.003.001 4.728 3.339a.91.91 0 0 0 .96.067c.314-.165.55-.515.576-.942a1.167 1.167 0 0 0-.467-1.025l-4.733-3.342a4.762 4.762 0 0 0-3.46-.833l-1.963.296a1 1 0 0 1-1.083-1.345l1.242-3.252a1 1 0 1 1 1.868.714l-.63 1.65.27-.041a6.764 6.764 0 0 1 4.908 1.176l4.733 3.343c.893.629 1.377 1.693 1.312 2.78-.066 1.086-.675 2.083-1.644 2.592a2.909 2.909 0 0 1-3.041-.203l-4.734-3.343-.004-.003a.885.885 0 0 0-.656-.16l-.006.001-5.007.757-4.724 2.5A6.757 6.757 0 0 1 9.065 28H5.003C3.29 28 2 26.548 2 24.88c0-1.664 1.286-3.13 3.002-3.13H9c.143 0 .388-.06.526-.13l4.162-2.313 1.524-4.191-1.401-.481c-.454-.148-.67-.105-.772-.041L9.54 16.84l-.013.008a3.554 3.554 0 0 1-2.302.487c-.787-.104-1.62-.505-2.096-1.343-.468-.826-.404-1.748-.13-2.486a3.816 3.816 0 0 1 1.455-1.845l.005-.004L9.964 9.41c1.638-1.039 3.347-1.52 5.35-.86l.002.001 9.603 3.201c.097.011.261.005.46-.047.23-.06.395-.15.472-.215l3.308-2.835a.95.95 0 0 1 .035-.029"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 27a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 33a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsEmpower;
