import React from 'react';
import { LinkProps } from 'react-router-dom';
import { styled } from 'styles';
import { IconType } from 'icons';
import PrimaryButton from '../PrimaryButton';
import DynamicIcon from '../DynamicIcon/DynamicIcon';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 14px;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-bottom: 14px;
  text-align: center;
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-bottom: 20px;
  text-align: center;
`;

const EmptyStateIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.staticPalette.indigo,
  secondaryColor: theme.colors.background.primary,
  width: 32,
  height: 32,
}))`
  align-self: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  align-self: center;
`;

interface EmptyStateProps {
  emptyStateIcon?: IconType;
  buttonTitle: string;
  onButtonClick?(): void;
  description: string;
  title: string;
  to?: LinkProps['to'];
}

const EmptyState = ({
  emptyStateIcon,
  title,
  to,
  description,
  onButtonClick,
  buttonTitle,
  ...props
}: EmptyStateProps) => (
  <Wrapper {...props} data-testid="empty-state">
    {emptyStateIcon ? (
      <EmptyStateIcon aria-hidden="true" Icon={emptyStateIcon} />
    ) : null}
    <Title>{title}</Title>
    <Description>{description}</Description>
    {to || onButtonClick ? (
      <ButtonWrapper>
        <PrimaryButton onClick={onButtonClick} label={buttonTitle} to={to} />
      </ButtonWrapper>
    ) : null}
  </Wrapper>
);

export default EmptyState;
