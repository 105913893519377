import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { DateRange, UserGroupFilterStateKey } from '../FilterState/types';
import { dashboardFilterContext } from '../constants';
import { AdminTabType } from '../../types';
import { FilterContext, TimePeriod } from '../../../__generated__/globalTypes';
import { filterNonNull } from '../../../typescript/helpers';
import { useFilterTypeTracking } from '../helpers/tracking';
import {
  AdminDashboardFilters,
  AdminDashboardFiltersVariables,
} from './__generated__/AdminDashboardFilters';
import { DashboardFilterOptions } from './types';

interface Option {
  id: string;
  name: string;
  isRestricted: boolean | null;
}

export const DASHBOARD_FILTER_QUERY = gql`
  query AdminDashboardFilters($input: OrganisationInsightsFiltersInput!) {
    organisationInsightsFilters(input: $input) {
      locations {
        id
        name
        isRestricted
      }
      departments {
        id
        name
        isRestricted
      }
    }
  }
`;

const mapFilterOptions = (filterOptions?: (Option | null)[] | null) => {
  if (!filterOptions) {
    return [];
  }

  return filterOptions
    .filter(filterNonNull)
    .filter(({ id, name }) => id && name)
    .map(({ id, name, isRestricted }) => ({
      label: name,
      value: id,
      isDisabled: !!isRestricted,
    }));
};

export default ({
  timeFilterState,
  adminTabType,
  userGroupFilterType,
  timeFilterPreset,
}: {
  timeFilterState: DateRange;
  adminTabType: AdminTabType;
  userGroupFilterType?: UserGroupFilterStateKey;
  timeFilterPreset: TimePeriod;
}) => {
  //TODO: ts/webpack complains if this cast is removed
  const context = dashboardFilterContext[
    adminTabType
  ] as unknown as FilterContext;

  const { data: { organisationInsightsFilters } = {}, loading } = useQuery<
    AdminDashboardFilters,
    AdminDashboardFiltersVariables
  >(DASHBOARD_FILTER_QUERY, {
    variables: {
      input: {
        ...timeFilterState,
        context,
        timePeriod: timeFilterPreset,
      },
    },
  });

  const dashboardFilterOptions: DashboardFilterOptions = useMemo(() => {
    if (loading) {
      return {
        locationIds: [],
        departmentIds: [],
      };
    }

    return {
      locationIds: mapFilterOptions(organisationInsightsFilters?.locations),
      departmentIds: mapFilterOptions(organisationInsightsFilters?.departments),
    };
  }, [loading, organisationInsightsFilters]);

  const hasMultipleOptions = (filterType: UserGroupFilterStateKey) =>
    dashboardFilterOptions[filterType].length > 1;
  const availableFilterTypes = (
    Object.keys(dashboardFilterOptions) as UserGroupFilterStateKey[]
  ).filter(hasMultipleOptions);

  const userGroupFilterCount = availableFilterTypes.length;
  useFilterTypeTracking({
    isReady: !loading && !!userGroupFilterType && !!organisationInsightsFilters,
    adminTabType,
    userGroupFilterType,
    userGroupFilterCount,
    dashboardFilterOptions,
  });

  return {
    dashboardFilterOptions,
    availableFilterTypes,
    loading,
  };
};
