import React from 'react';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { css, styled, ThemeInterface } from 'styles';
import { Question } from 'icons';
import { HeadingText } from '../../../Shared/Typography';
import Modal, { useModalState } from '../../../Shared/Modal';
import { medium, small } from '../../../utils';
import {
  UserWellbeingTrackerScoresForScoreScreen_user_wellbeingTrackerScores_edges_node as TrackerEntryNodes,
  UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands,
} from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import { hasCompletedIndexRecently } from '../utils/timeBetweenIndex';
import WellbeingTrackerCTA from '../WellbeingTrackerCTA';
import PrimaryButton from '../../../Shared/PrimaryButton';
import getScoresByType from './helpers/getScoresByType';
import ExpandableCategories from './ExpandableCategories';
import NextQuestionnaireCountdown from './NextQuestionnaireCountdown';
import ScienceBehindScores from './ScienceBehindScores';
import ScienceBehindScoresButton from './ScienceBehindScoresButton';
import WellbeingTrackerLineChartWrapper from './WellbeingTrackerLineChartWrapper';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: ${rem(40)};

  ${medium(css`
    grid-template-columns: 2fr 1fr;
    column-gap: ${rem(39)};
    grid-auto-flow: row;
  `)};
`;

export const OverallScoreContainer = styled.div`
  margin-bottom: ${rem(12)};
  margin-top: ${rem(24)};

  ${small(css`
    margin-top: ${rem(32)};
  `)};
`;

const Heading = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
  level: 2,
}))`
  margin-top: ${rem(12)};

  ${medium(css`
    margin-top: ${rem(0)};
  `)};
`;

export const ScoreContainer = styled.div`
  margin-right: 0;
  ${small(css`
    margin-right: ${rem(36)};
  `)};
`;

const ScienceBehindScoresContainer = styled.div`
  margin-top: ${rem(24)};
  display: none;

  ${medium(css`
    display: block;
    grid-column: 2;
    grid-row: 4;
  `)};
`;

export const ExpandableCategoriesContainer = styled.section`
  grid-column: 1;
  grid-row: 3 span;
  margin-top: ${rem(14)};
  margin-bottom: ${rem(40)};
  padding: ${rem(0)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(14)};

  ${small(css`
    margin-top: ${rem(18)};
  `)};

  ${medium(css`
    grid-column: 1;
    grid-row: 3 span;
  `)};
`;

export const OverallScoreChartContainer = styled.section`
  grid-column: 1;
  grid-row: 3 span;
  margin-top: ${rem(14)};
  margin-bottom: ${rem(40)};
  padding: ${rem(32)} ${rem(20)} ${rem(32)} ${rem(20)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(14)};

  ${medium(css`
    grid-column: 1;
    grid-row: 3 span;
  `)};
`;

export const QuestionIcon = styled(Question).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    width: 18,
    height: 18,
    primaryColor: theme.colors.text.secondary,
  }),
)`
  margin-top: ${rem(5)};
  margin-left: ${rem(6)};
`;

export const TooltipLabel = styled.div`
  width: ${rem(320)};
  max-width: 100%;
`;

const CountdownContainer = styled.div`
  grid-column: 1;
  grid-row: 6;
  margin-bottom: ${rem(22)};

  ${medium(css`
    margin-top: ${rem(18)};
    margin-bottom: ${rem(0)};
    grid-column: 2;
    grid-row: 3;
  `)};
`;

const CTAContainer = styled.div`
  margin: ${rem(14)} ${rem(0)} ${rem(20)} ${rem(0)};
  grid-row: 1;
  grid-column: 1;

  ${small(css`
    margin-top: ${rem(0)};
  `)};

  ${medium(css`
    grid-column: 2;
    grid-row: 3;
    margin-top: ${rem(18)};
    margin-bottom: ${rem(0)};
  `)};
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-row: 2;
`;

const OverallScoreHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-column: 1;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: ${rem(350)};

  ${small(css`
    max-height: none;
  `)};
`;

const DismissButton = styled(PrimaryButton)`
  margin: ${rem(0)} ${rem(20)} ${rem(30)} ${rem(20)};
`;

interface Props {
  wellbeingTrackerEntryNodes: TrackerEntryNodes[];
  bands: TrackerBands[];
  retakeFrequency: number;
}

export const WellbeingScoreInsights = ({
  wellbeingTrackerEntryNodes,
  bands,
  retakeFrequency,
}: Props) => {
  const { t: translate } = useTranslation('track');

  const latestScore = wellbeingTrackerEntryNodes?.[0];
  const scoresByType = getScoresByType(wellbeingTrackerEntryNodes);
  const latestScoreDate = latestScore?.date;
  const hasCompletedTrackerRecently = hasCompletedIndexRecently(
    retakeFrequency,
    latestScoreDate,
  );
  const { show, openModal, closeModal, setShow } = useModalState();

  const ScienceBehindScoresModal = () => (
    <Modal
      open={show}
      onCloseCallback={closeModal}
      modalContent={() => (
        <Wrapper>
          <ScienceBehindScores bordered={false} />
          <DismissButton
            label={translate('wellbeing.about_screen.dismiss_button.text')}
            ariaLabel={translate(
              'wellbeing.about_screen.dismiss_button.a11y_hint',
            )}
            onClick={() => setShow(false)}
          />
        </Wrapper>
      )}
    />
  );

  return latestScore && scoresByType ? (
    <Container>
      <>
        {!hasCompletedTrackerRecently ? (
          <CTAContainer>
            <WellbeingTrackerCTA
              retakeFrequency={retakeFrequency}
              source="track-wellbeing"
            />
          </CTAContainer>
        ) : null}
        <HeadingContainer>
          <Heading accessibilityAutoFocus={true}>
            {translate('wellbeing.scores.category_scores_title')}
          </Heading>
          <ScienceBehindScoresButton onButtonClick={openModal} />
        </HeadingContainer>
        <ExpandableCategoriesContainer>
          <ExpandableCategories
            categories={latestScore.categories}
            scoresByType={scoresByType}
            bands={bands}
          />
        </ExpandableCategoriesContainer>
        <OverallScoreHeadingContainer>
          <Heading>
            {translate('wellbeing.scores.overall_score.chart.title')}
          </Heading>
        </OverallScoreHeadingContainer>
        <OverallScoreChartContainer>
          <WellbeingTrackerLineChartWrapper
            data={scoresByType.total}
            category={translate(
              'wellbeing.scores.overall_score.chart.category_name',
            )}
            bands={bands}
            showEmptyState={true}
          />
        </OverallScoreChartContainer>
      </>
      {hasCompletedTrackerRecently ? (
        <CountdownContainer>
          <NextQuestionnaireCountdown
            latestScoreDate={latestScoreDate}
            retakeFrequency={retakeFrequency}
          />
        </CountdownContainer>
      ) : null}
      <ScienceBehindScoresContainer>
        <ScienceBehindScores />
      </ScienceBehindScoresContainer>
      <ScienceBehindScoresModal />
    </Container>
  ) : null;
};

export default WellbeingScoreInsights;
