import React from 'react';
import { IconProps } from '../IconProps';

const SvgBlankCalendar = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M2.964 3.786A1.643 1.643 0 0 0 1.321 5.43v15.607a1.643 1.643 0 0 0 1.643 1.643h18.071a1.643 1.643 0 0 0 1.643-1.643V5.43a1.643 1.643 0 0 0-1.643-1.643H17.75M1.321 9.536h21.357M6.25 1.321v4.93m11.5-4.93v4.93M6.25 3.786h8.214"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgBlankCalendar;
