import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { NAMESPACE } from '../customClaimNamespace';

const useIsFirstLoginClaim = () => {
  const [loading, setLoading] = useState(true);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [unmindUserId, setUnmindUserId] = useState<string>('');
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    void (async () => {
      try {
        const claims = await getIdTokenClaims();
        if (claims) {
          setIsFirstLogin(claims[`${NAMESPACE}/is_first_login`] === true);
          setUnmindUserId(claims[`${NAMESPACE}/unmind_user_id`]);
        }
      } catch (e) {
        console.error('Failed to get idToken');
      } finally {
        setLoading(false);
      }
    })();
  }, [getIdTokenClaims]);

  return { loading, isFirstLogin, unmindUserId };
};

export default useIsFirstLoginClaim;
