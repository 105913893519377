import { useMutation, useQuery } from '@apollo/client';
import { Alert, Radio, showToast } from '@unmind/design-system-components-web';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { logException } from 'App/logging';
import {
  submitOutcomeMeasuresAssessment_submitAssessment as submitAssessmentPayload,
  submitOutcomeMeasuresAssessmentVariables as submitAssessmentVariables,
} from 'Services/BeGateway/Talk/__generated__/submitOutcomeMeasuresAssessment';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import {
  SUBMIT_ASSESSMENT_MUTATION,
  TALK_EVENTS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import { FieldSet } from 'Shared/Form/FieldSet';
import { FullScreenTakeover } from 'Shared/FullScreenTakeover';
import Modal from 'Shared/Modal';
import outcomeMeasuresQuestions from 'Talk/lib/constants/outcomeMeasuresQuestions.json';
import { HELP_FOLDER_QUERY } from 'Track/Wellbeing/Score/CrisisHelpDisclaimer';
import { HelpFolderQuery } from 'Track/Wellbeing/Score/__generated__/HelpFolderQuery';
import { Namespace, ParseKeys } from 'i18next';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import React, { SyntheticEvent, useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BEGatewayQueryContext } from 'utils/apollo';
import { Slider } from '../../../../Shared/Slider';
import {
  INITIAL_FORM_VALUES,
  QuestionnaireStateType,
  QuestionnaireType,
  responseReducer,
} from './state';
import {
  AlertContainer,
  BackButton,
  BackIcon,
  BackText,
  CloseButton,
  CloseIcon,
  ExitModalCTA,
  ExitModalCopy,
  ExitModalHeading,
  ExitModalInner,
  QuestionCTAButton,
  QuestionCard,
  QuestionContextText,
  QuestionCounter,
  QuestionHelpLink,
  QuestionHelpText,
  QuestionResponseContainer,
  QuestionScaleBadge,
  QuestionScaleBadgeContainer,
  QuestionText,
  QuestionTextContainer,
  QuestionnaireContainer,
  QuestionnaireForm,
  QuestionnaireHeader,
  QuestionnaireMetaContainer,
} from './styles';

// Replace with schema-generated type following Contentful integration
type Question = {
  id: string;
  questionnaireType: QuestionnaireType;
  contextText: string;
  questionText: string;
  uiType: string;
  scaleMinimumLabel?: string;
  scaleMaximumLabel?: string;
  responseOptions: {
    id: string;
    label: string;
    value: number;
  }[];
};

export interface Props {
  event: Event;
  showQuestionnaire: boolean;
  onClose(): void;
}

export const QuestionnaireTakeover = ({
  event,
  showQuestionnaire = false,
  onClose,
}: Props) => {
  const { t } = useTranslation<Namespace<'talk' | 'shared'>>([
    'talk',
    'shared',
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const [finishQuestionnaireModalOpen, setFinishQuestionnaireModalOpen] =
    useState(false);

  const { data: helpFolderData } = useQuery<HelpFolderQuery>(HELP_FOLDER_QUERY);
  const [responses, setResponse] = useReducer(
    responseReducer,
    INITIAL_FORM_VALUES,
  );
  const [submitQuestionnaire, { loading: mutationInFlight }] = useMutation<
    submitAssessmentPayload,
    submitAssessmentVariables
  >(SUBMIT_ASSESSMENT_MUTATION, {
    refetchQueries: [TALK_EVENTS_QUERY],
    ...BEGatewayQueryContext,
  });

  const urgentHelpCategory = helpFolderData?.helpContentRoot?.items?.find(
    item => item?.folderType === 'Urgent',
  );

  const { gad, phq, workplace } = outcomeMeasuresQuestions;
  const allQuestions = [
    ...gad.questions.map(question => ({
      ...question,
      questionnaireType: QuestionnaireType.gad,
    })),
    ...phq.questions.map(question => ({
      ...question,
      questionnaireType: QuestionnaireType.phq,
    })),
    ...workplace.questions.map(question => ({
      ...question,
      questionnaireType: QuestionnaireType.workplace,
    })),
  ];
  const questionnaireIsIncomplete =
    gad.questions.length !== responses.gad.length ||
    phq.questions.length !== responses.phq.length ||
    workplace.questions.length !== responses.workplace.length;

  const closeQuestionnaire = () => {
    setCurrentQuestion(0);
    setResponse({
      type: QuestionnaireStateType.resetQuestionnaire,
    });

    onClose();
  };

  const trackQuestionResponse = (question: Question) =>
    tracking.track('talk-session-prep-question-responded', {
      eventID: event?.id,
      questionID: question.id,
      questionNumber: currentQuestion + 1, // account for 0-indexing
    });

  const handleSubmit = async (formEvent: SyntheticEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    try {
      const input = {
        eventId: event.id,
        GAD: {
          version: outcomeMeasuresQuestions.gad.version,
          responses: responses.gad,
        },
        PHQ: {
          version: outcomeMeasuresQuestions.phq.version,
          responses: responses.phq,
        },
        Workplace: {
          version: outcomeMeasuresQuestions.workplace.version,
          responses: responses.workplace,
        },
      };

      await submitQuestionnaire({
        variables: { input },
      });

      tracking.track('talk-session-prep-completed', {
        eventID: event?.id,
      });

      setFormSubmissionError(false);
      closeQuestionnaire();
      showToast(
        t('talk:outcome_measures.questionnaire.submission_success.label'),
      );
    } catch (e) {
      setFormSubmissionError(true);
      logException(e, {
        tags: {
          talkEventId: event.id,
        },
      });
    }
  };

  const Question = ({ question }: { question: Question }) => (
    <>
      <QuestionCounter>
        {currentQuestion + 1}/{allQuestions.length}
      </QuestionCounter>
      <FieldSet id={question.id}>
        <QuestionTextContainer
          questionBeforeContext={question.questionnaireType === 'workplace'}
        >
          <QuestionContextText>
            {t(
              `talk:outcome_measures.${question.questionnaireType}.${question.contextText}` as ParseKeys<
                Namespace<'talk'>
              >,
            )}
          </QuestionContextText>
          <QuestionText>
            {t(
              `talk:outcome_measures.${question.questionnaireType}.${question.questionText}` as ParseKeys<
                Namespace<'talk'>
              >,
            )}
          </QuestionText>
        </QuestionTextContainer>
        <QuestionResponseContainer>
          {question.uiType === 'Scale' ? (
            <>
              <Slider
                name={question.id}
                id={question.id}
                min={question.responseOptions[0].value}
                max={
                  question.responseOptions[question.responseOptions.length - 1]
                    .value
                }
                step={1}
                value={
                  responses[question.questionnaireType].find(
                    r => r.questionId === question.id,
                  )?.answer
                }
                onSliderChange={e => {
                  setResponse({
                    type: QuestionnaireStateType[question.questionnaireType],
                    payload: {
                      questionId: question.id,
                      answer: parseInt(e.target.value, 10),
                    },
                  });
                }}
                remeasureTooltipPlacement={currentQuestion}
                requiresInteraction
              />

              <QuestionScaleBadgeContainer>
                {question.scaleMinimumLabel ? (
                  <QuestionScaleBadge>
                    {t(
                      `talk:outcome_measures.${question.questionnaireType}.${question.scaleMinimumLabel}` as ParseKeys<
                        Namespace<'talk'>
                      >,
                    )}
                  </QuestionScaleBadge>
                ) : null}

                {question.scaleMaximumLabel ? (
                  <QuestionScaleBadge>
                    {t(
                      `talk:outcome_measures.${question.questionnaireType}.${question.scaleMaximumLabel}` as ParseKeys<
                        Namespace<'talk'>
                      >,
                    )}
                  </QuestionScaleBadge>
                ) : null}
              </QuestionScaleBadgeContainer>

              {/* Show the 'next question' button if we are not on the last question */}
              {currentQuestion !== allQuestions.length - 1 ? (
                <QuestionCTAButton
                  label={t(
                    'talk:outcome_measures.questionnaire.next_question_button.label',
                  )}
                  type="button"
                  disabled={isUndefined(
                    responses[question.questionnaireType].find(
                      r => r.questionId === question.id,
                    )?.answer,
                  )}
                  onClick={() => {
                    trackQuestionResponse(question);
                    setCurrentQuestion(currentQuestion + 1);
                  }}
                />
              ) : null}
            </>
          ) : (
            question.responseOptions.map(response => {
              const responseIsSelected =
                responses[question.questionnaireType].find(
                  r => r.questionId === question.id,
                )?.answer === response.value ?? false;

              return (
                <Radio
                  key={response.id}
                  name={question.id}
                  value={response.value}
                  checked={responseIsSelected}
                  aria-checked={responseIsSelected}
                  labelText={t(
                    `talk:outcome_measures.responseOptions.${response.label}` as ParseKeys<
                      Namespace<'talk'>
                    >,
                  )}
                  onChange={() => noop}
                  onClick={() => {
                    setResponse({
                      type: QuestionnaireStateType[question.questionnaireType],
                      payload: {
                        questionId: question.id,
                        answer: response.value,
                      },
                    });

                    trackQuestionResponse(question);

                    // Allow time for user to see their selected answer before moving on
                    setTimeout(() => {
                      setCurrentQuestion(currentQuestion + 1);
                    }, 250);
                  }}
                  size="medium"
                  bordered
                />
              );
            })
          )}
          {question.questionText === 'thoughts_of_hurting_yourself' && (
            <QuestionHelpText>
              <Trans
                t={t}
                i18nKey="talk:outcome_measures.questionHelp.get_help"
                defaults="If you are in crisis or need urgent support <help_link>click here</help_link> for more information"
                components={{
                  help_link: (
                    <QuestionHelpLink
                      to={{
                        pathname: `${RoutePath.Help}/${urgentHelpCategory?.id}`,
                      }}
                      target="_blank"
                      aria-label={t(
                        'talk:outcome_measures.questionHelp.get_help',
                      )}
                    />
                  ),
                }}
              />
            </QuestionHelpText>
          )}
        </QuestionResponseContainer>
      </FieldSet>
    </>
  );

  return (
    <FullScreenTakeover
      shown={showQuestionnaire}
      scrollable
      allowForOverlay={true}
      reclaimFocus={!finishQuestionnaireModalOpen}
    >
      <QuestionnaireForm onSubmit={handleSubmit}>
        <QuestionnaireContainer>
          <QuestionnaireHeader>
            {currentQuestion >= 1 ? (
              <BackButton
                onClick={() => {
                  setCurrentQuestion(currentQuestion - 1);
                }}
              >
                <BackIcon />
                <BackText>
                  {t(
                    'talk:outcome_measures.questionnaire.prev_question_button.label',
                  )}
                </BackText>
              </BackButton>
            ) : null}

            <CloseButton
              onClick={() => {
                setFinishQuestionnaireModalOpen(true);
              }}
              aria-label={t(
                'talk:outcome_measures.questionnaire.close_questionnaire_button.a11y_label',
              )}
            >
              <CloseIcon />
            </CloseButton>
          </QuestionnaireHeader>

          <QuestionCard>
            <Question question={allQuestions[currentQuestion]} />
            {currentQuestion === allQuestions.length - 1 ? (
              <QuestionnaireMetaContainer>
                <QuestionCTAButton
                  label={t(
                    'talk:outcome_measures.questionnaire.submit_button.label',
                  )}
                  type="submit"
                  disabled={mutationInFlight || questionnaireIsIncomplete}
                  onClick={() => {
                    const finalQuestion = allQuestions[allQuestions.length - 1];
                    trackQuestionResponse(finalQuestion);
                  }}
                />
              </QuestionnaireMetaContainer>
            ) : null}

            {formSubmissionError ? (
              <AlertContainer>
                <Alert
                  variant="error"
                  size="small"
                  title={t('shared:errors.messages.something_wrong')}
                />
              </AlertContainer>
            ) : null}
          </QuestionCard>
        </QuestionnaireContainer>
      </QuestionnaireForm>
      {finishQuestionnaireModalOpen && (
        <Modal
          closeHeader
          variant="overlayed"
          open={finishQuestionnaireModalOpen}
          onCloseCallback={() => setFinishQuestionnaireModalOpen(false)}
          modalContent={({ close }) => (
            <ExitModalInner>
              <ExitModalHeading>
                {t(
                  'talk:outcome_measures.questionnaire.finish_prompt_modal.title',
                )}
              </ExitModalHeading>
              <ExitModalCopy>
                {t(
                  'talk:outcome_measures.questionnaire.finish_prompt_modal.body_1',
                )}
              </ExitModalCopy>
              <ExitModalCopy>
                {t(
                  'talk:outcome_measures.questionnaire.finish_prompt_modal.body_2',
                )}
              </ExitModalCopy>
              <ExitModalCTA
                onClick={() => {
                  setFinishQuestionnaireModalOpen(false);
                  close();
                  tracking.track('talk-session-prep-abandoned', {
                    eventID: event?.id,
                    currentQuestionId: allQuestions[currentQuestion].id,
                    currentQuestionNumber: currentQuestion + 1, // account for 0-indexing
                  });
                  closeQuestionnaire();
                }}
                label={t(
                  'talk:outcome_measures.questionnaire.finish_prompt_modal.exit_button.label',
                )}
              />
            </ExitModalInner>
          )}
        />
      )}
    </FullScreenTakeover>
  );
};
