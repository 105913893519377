import { BodyText } from '@unmind/design-system-components-web';
import { ThemeInterface } from '@unmind/design-system-theme';
import { VideoCamera } from 'icons';
import styled from 'styled-components';

export const DurationLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const DurationLabelText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

export const VideoCameraIcon = styled(VideoCamera).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    width: 16,
    height: 16,
    ['aria-hidden']: true,
  }),
)`
  margin-right: 8px;
`;
