import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';
import { Status } from '.';

export interface MediaPlayerControlVisibilityHandlers {
  handleMouseMove?(): void;
  onControlMouseOver?(): void;
  onControlMouseOut?(): void;
  onActionKeyPress(e: React.KeyboardEvent): void;
  isInterfaceVisible: boolean;
}

export const IGNORE_MOUSE_PERIOD = 1000;
export const INTERACTION_INACTIVITY_PERIOD = 3000;

export const KEYBOARD_CONTROL_KEYS = ['Tab', 'ArrowLeft', 'ArrowRight'];

/**
 * Handles the visibility of the player interface when media is playing,
 * based on the movement or position of the mouse.
 */
const useMediaPlayerControlVisibility = (
  status: Status,
): MediaPlayerControlVisibilityHandlers => {
  // When clicking play for the first time (or replaying after end), ignore mouse
  // movements for a short time. This allows the user to click play, then move
  // their mouse off to the side without the interface covering the video
  const [ignoreMouse, setIgnoreMouse] = useState(true);
  const [areControlsHovered, setAreControlsHovered] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);

  // Prevent memory leaks from timers firing off after unmount
  const mounted = useRef(false);

  const ignoreMouseTimeout = useRef(0);
  const inactivityTimeout = useRef(0);
  const previousStatus = useRef<Status>(status);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      clearTimeout(ignoreMouseTimeout.current);
      clearTimeout(inactivityTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (status === 'playing' && ignoreMouse) {
      clearTimeout(ignoreMouseTimeout.current);
      ignoreMouseTimeout.current = window.setTimeout(() => {
        if (mounted.current) {
          setIgnoreMouse(false);
        }
      }, IGNORE_MOUSE_PERIOD);
    } else if (status === 'ended') {
      setIgnoreMouse(true);
    }
  }, [ignoreMouse, status]);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = window.setTimeout(() => {
      if (mounted.current) {
        setIsUserActive(false);
      }
    }, INTERACTION_INACTIVITY_PERIOD);
  };

  const handleMouseMove = throttle(() => {
    if (!isUserActive && !ignoreMouse) {
      if (mounted.current) {
        setIsUserActive(true);
      }

      resetInactivityTimeout();
    }
  }, 10);

  const onControlMouseOver = () => {
    if (!areControlsHovered && !ignoreMouse) {
      setAreControlsHovered(true);
    }
  };

  const onControlMouseOut = () => {
    if (areControlsHovered) {
      setAreControlsHovered(false);
    }
  };

  const onActionKeyPress = (e: React.KeyboardEvent) => {
    if (KEYBOARD_CONTROL_KEYS.includes(e.key) && status === 'playing') {
      setIsUserActive(true);

      resetInactivityTimeout();
    }
  };

  if (
    previousStatus.current === 'paused' &&
    status === 'playing' &&
    !ignoreMouse // ignoreMouse implies this is the "first" play where we immediately hide controls no matter what
  ) {
    // We've just started playing, either via keyboard or clickable area
    setIsUserActive(true);

    resetInactivityTimeout();
  }
  previousStatus.current = status;

  if (status === 'playing') {
    return {
      isInterfaceVisible: areControlsHovered || isUserActive,
      handleMouseMove,
      onControlMouseOver,
      onActionKeyPress,
      onControlMouseOut,
    };
  }

  return {
    isInterfaceVisible: true,
    handleMouseMove,
    onControlMouseOver,
    onActionKeyPress,
    onControlMouseOut,
  };
};

export default useMediaPlayerControlVisibility;
