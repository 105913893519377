import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import RoutePath from '../App/RoutePath';
import {
  ServersideTrackingEvent,
  withLogEvent,
  WithLogEventProps,
} from '../App/Tracking/serverside';
import LoadingIndicator from '../Shared/LoadingIndicator';
import useFeatureFlag, { FEATURE_FLAGS } from '../flags/useFeatureFlag';
import { useOrganisationEntitlements } from '../Shared/Entitlement';
import ContentFolderView from './ContentFolderView';
import ContentTile from './ContentTile';
import { HelpLanding } from './HelpLanding';
import {
  withHelpContentRoot,
  WithHelpContentRootProps,
} from './withHelpContentRoot';

export interface HelpRootProps
  extends WithLogEventProps,
    WithHelpContentRootProps {}

export const HelpRoot = ({
  helpContentRoot,
  loading,
  error,
  logEvent,
}: HelpRootProps) => {
  const { t: translate } = useTranslation(['help', 'shared']);
  const helpLandingFlagEnabled = useFeatureFlag(
    FEATURE_FLAGS.TALK_ENABLE_IMPROVE_HELP_ACCESS_WEB,
  );
  const { entitlements, loading: loadingEntitlements } =
    useOrganisationEntitlements([{ entitlement: 'unmindHelp' }]);

  if (
    loading ||
    loadingEntitlements ||
    (helpLandingFlagEnabled === undefined && entitlements.unmindHelp) // stops the page defaulting to old help when loading ff
  ) {
    return <LoadingIndicator />;
  }

  const showNewHelpLanding = helpLandingFlagEnabled && entitlements.unmindHelp;
  if (showNewHelpLanding) {
    return <HelpLanding data-testid="help-landing" />;
  }

  if (!helpContentRoot || error) {
    return (
      <p data-testid={'error-handling'}>
        {translate('shared:errors.messages.something_wrong')}
      </p>
    );
  }

  const { items } = helpContentRoot;

  const trackServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ContentFolderView
      data-testid={'content-folder-view'}
      title={translate('help:title')}
    >
      {items.map(({ title, summary, id: itemId }, i) => (
        <ContentTile
          title={title}
          id={itemId}
          description={summary}
          linkTo={`${RoutePath.Help}/${itemId}/`}
          visible={true}
          key={`${title}-${i}`}
          trackServerside={trackServerside}
        />
      ))}
    </ContentFolderView>
  );
};

export default compose<HelpRootProps, HelpRootProps>(
  withHelpContentRoot,
  withRouter,
  withLogEvent,
)(HelpRoot);
