import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { small } from 'utils';
import DefaultCloseButton from '../../../../../Shared/FullScreenTakeover/DefaultCloseButton';
import { SeriesDay, SeriesName } from '../../SeriesDayHeader';

const Header = styled.div`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  margin-bottom: ${rem(20)};
  ${small(css`
    padding-bottom: ${rem(30)};
    margin: ${rem(60)} auto ${rem(32)};
    flex-direction: row;
  `)};
`;

const ButtonContainer = styled.div`
  position: relative;
  ${small(css`
    position: absolute;
    top: ${rem(48)};
    left: -${rem(80)};
  `)}

  width: 0;
`;

export interface QuestionnaireHeaderProps {
  seriesTitle: string;
  dayNumber: number;
  handleClose(): void;
  trackOnCloseButtonClick(): void;
}

const QuestionnaireHeader = (props: QuestionnaireHeaderProps) => {
  const { t: translate } = useTranslation('courses');

  const { seriesTitle, dayNumber, handleClose, trackOnCloseButtonClick } =
    props;

  const onCloseButtonPress = () => {
    handleClose();
    trackOnCloseButtonClick();
  };

  return (
    <React.Fragment>
      <Header>
        <ButtonContainer>
          <DefaultCloseButton
            onClose={onCloseButtonPress}
            hasWhiteBackground={true}
          />
        </ButtonContainer>
        <SeriesName>{seriesTitle}</SeriesName>
        <SeriesDay>
          {translate('course_day.session_number', {
            session_number: dayNumber,
          })}
        </SeriesDay>
      </Header>
    </React.Fragment>
  );
};

export default QuestionnaireHeader;
