import React from 'react';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useQuery } from '@apollo/client';
import RoutePath from '../App/RoutePath';
import { Category } from '../Tools/types';
import { ToolWithCategories as Tool } from '../Tools/Cards/ToolCard';
import { DAILY_BOOST_CATEGORY_SLUG } from '../Shorts/constants';
import LoadingIndicator from '../Shared/LoadingIndicator';

interface DailyBoostCategoryData extends Category {
  tools: Maybe<Tool[]>;
}

const DAILY_BOOST_QUERY = gql`
  query dailyBoost {
    dailyBoost {
      tools {
        slug
      }
    }
  }
`;

interface DailyBoostData {
  dailyBoost: Partial<DailyBoostCategoryData>;
}

export const DailyBoostRedirect = () => {
  const { data, loading } = useQuery<DailyBoostData>(DAILY_BOOST_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const tools = data?.dailyBoost?.tools || [];
  const toolSlug = tools.length ? tools[0].slug : null;

  return toolSlug ? (
    <Redirect
      to={`${RoutePath.Shorts}/${DAILY_BOOST_CATEGORY_SLUG}/${toolSlug}`}
    />
  ) : (
    <Redirect to={`${RoutePath.WelcomeToUnmind}`} />
  );
};
