import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsMindfulness = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 15c0-7.632-6.296-13-14-13-3.095 0-6.312.91-8.78 2.735C7.733 6.575 6.01 9.347 6 12.97c-.003.012-.016.064-.057.168-.062.155-.16.355-.293.605-.183.34-.401.716-.643 1.131-.12.206-.245.42-.374.646-.378.659-.776 1.382-1.08 2.08C3.256 18.275 3 19.037 3 19.75a1 1 0 0 0 .003.078c.032.4.118.818.437 1.258.133.184.316.388.547.557.229.167.56.338.97.356h.004c.334.013.698.017 1.025.016.003.455.018 1.021.06 1.618.052.704.144 1.479.314 2.172.158.65.425 1.413.946 1.915 1.139 1.1 2.656 1.254 4.676 1.278l.01.002a.022.022 0 0 1 .007.004l.001.002V32a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-4.84c0-.337.19-.714.573-1.006C32.206 23.383 33 18.706 33 15zM6.97 20h-.011l-.036.002a23.446 23.446 0 0 1-.607.01c-.354.005-.79.005-1.178-.008a.591.591 0 0 1-.077-.089l-.002-.003a.21.21 0 0 1-.036-.066.677.677 0 0 1-.022-.133c.009-.288.128-.726.384-1.315.258-.592.61-1.235.982-1.883l.344-.594c.253-.434.503-.862.702-1.235.147-.276.284-.551.387-.807.092-.231.2-.55.2-.879 0-2.926 1.36-5.142 3.409-6.657C13.482 4.81 16.266 4 19 4c6.787 0 12 4.652 12 11 0 3.558-.788 7.388-3.64 9.564-.758.579-1.36 1.498-1.36 2.596V32H14v-2.995a2.013 2.013 0 0 0-1.994-2.007c-2.076-.024-2.806-.23-3.311-.717l-.001-.001c-.087-.084-.249-.363-.391-.949-.133-.54-.214-1.192-.261-1.843a23.39 23.39 0 0 1-.045-2.398L8 21.054v-.01A1 1 0 0 0 6.969 20z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9.554a4.23 4.23 0 0 1 1.076-1.046c.981-.654 2.116-.77 3.153-.5C25.27 8.54 27 10.566 27 13.188c0 1.871-1.292 3.607-2.58 4.91-1.336 1.352-2.908 2.475-3.894 3.126a2.758 2.758 0 0 1-3.052 0c-.986-.65-2.558-1.774-3.894-3.126-1.288-1.303-2.58-3.039-2.58-4.91 0-2.622 1.73-4.647 3.77-5.178 1.038-.27 2.173-.155 3.154.499A4.23 4.23 0 0 1 19 9.554zm3.725.39c1.142.297 2.275 1.513 2.275 3.242 0 1.016-.759 2.247-2.002 3.505-1.195 1.21-2.636 2.244-3.574 2.863a.758.758 0 0 1-.848 0c-.938-.62-2.379-1.653-3.574-2.863C13.76 15.433 13 14.202 13 13.187c0-1.73 1.133-2.946 2.275-3.243.553-.144 1.09-.072 1.54.228.455.303.937.916 1.213 2.062a1 1 0 0 0 1.944 0c.276-1.146.758-1.758 1.213-2.062.45-.3.987-.372 1.54-.228z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsMindfulness;
