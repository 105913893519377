import { rem } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { useTranslation } from 'react-i18next';
import logoWithVirginPulse from '../Shared/Assets/unmind_logo_with_virgin_pulse.svg';
import { small, medium } from '../utils';
import logoWithName from '../Shared/Assets/unmind_logo_with_name.svg';
import { useIsVirginPulseGroup } from '../LoggedOut/VirginPulse/useIsVirginPulseGroup';

const Logo = styled.img`
  flex-shrink: 0;
  z-index: 1;
  position: absolute;
  top: 40px;
  height: 20px;

  ${small(css`
    height: 30px;
  `)}

  // Target IE11 to make sure logo takes up 100% height.
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    height: 100%;
  }
`;

const VirginPulseLogo = styled.img`
  top: 40px;
  ${small(css`
    height: 30px;
  `)}
  ${medium(css`
    height: 40px;
  `)};
`;

const LoadingPlaceholder = styled.div`
  height: ${rem('32px')};
  ${small(css`
    height: ${rem('45px')};
  `)}
  ${medium(css`
    height: ${rem('46px')};
  `)}
`;

type UnauthenticatedHeaderLogoProps = {
  shouldDisplay: boolean;
};

const UnauthenticatedHeaderLogo = (props: UnauthenticatedHeaderLogoProps) => {
  const { t: translate } = useTranslation('shared');
  const { loading, isVirginPulseGroup } = useIsVirginPulseGroup();

  if (loading) {
    return <LoadingPlaceholder />;
  }

  if (isVirginPulseGroup) {
    return (
      <VirginPulseLogo
        src={logoWithVirginPulse}
        alt="Unmind and Virgin Pulse logos"
      />
    );
  }

  if (!props.shouldDisplay) {
    return <></>;
  }

  return (
    <Logo
      src={logoWithName}
      alt={translate('navigation.unauthenticated_header_logo.alt_text')}
    />
  );
};

export default UnauthenticatedHeaderLogo;
