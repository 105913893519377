import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import { LocationDescriptorObject } from 'history';
import { rem } from 'polished';
import React from 'react';
import { CustomTypeOptions, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import styled, { css } from 'styled-components';
import { FullHeightContent } from '../../Navigation/AuthenticatedPage';
import { default as OuterContainer } from '../../Shared/Container';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import Tabs from '../../Shared/Tabs';
import { BodyText, HeadingText } from '../../Shared/Typography';
import { small } from '../../utils';
import BreadcrumbNavigation from '../BreadcrumbNavigation/BreadcrumbNavigation';
import {
  categoryContent_exploreCategory_content,
  categoryContent_exploreCategory_content_Series,
} from '../Category/__generated__/categoryContent';
import ContentSection from '../Content/ContentSection';
import GenericError from '../GenericError/GenericError';
import {
  getUserCourses,
  getUserCourses_getUserCourses_edges,
} from './__generated__/getUserCourses';
import { transformYourCourses } from './transformYourCourses';
import { USER_COURSES_QUERY } from './userCoursesQuery';

const YourCoursesFullHeightContent = styled(FullHeightContent)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
`;

const YourCoursesWrapper = styled(OuterContainer)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  align-self: center;
  width: 100%;
  align-items: center;
  margin: 0 auto;
`;

const YourCoursesInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const YourCoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(32)};
`;

export const Container = styled.div`
  margin-bottom: ${rem(32)};

  ${small(css`
    margin-bottom: ${rem(40)};
  `)};
`;

const PageTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  accessibilityAutoFocus: true,
}))`
  text-align: left;
  width: 100%;
  margin: ${rem(4)} 0 ${rem(16)};
`;

const TabContainer = styled.div`
  display: block;
`;

export const TemporaryStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const YourCoursesEmpty = styled.div`
  padding-top: ${rem(92)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: center;
  width: 100%;
  margin: ${rem(4)} 0 ${rem(16)};
`;

const EmptyBody = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'p',
}))`
  text-align: center;
  width: 100%;
  margin: ${rem(4)} 0 ${rem(16)};
`;

type ExploreYourCoursesNavigationTranslationOptions =
  CustomTypeOptions['resources']['explore']['your_courses']['navigation'];

type ValidTranslationKey = keyof ExploreYourCoursesNavigationTranslationOptions;

interface TabsPropsPreTranslation {
  title: ValidTranslationKey;
  hash: string;
}

const defaultTabs: TabsPropsPreTranslation[] = [
  {
    title: 'in_progress_title',
    hash: '#in-progress',
  },
  {
    title: 'completed_title',
    hash: '#completed',
  },
];

interface YourCoursesPageProps {
  activeTab: string;
  updateHistory(location: LocationDescriptorObject): void;
}

export const YourCoursesPage: React.FC<YourCoursesPageProps> = ({
  activeTab = '#in-progress',
  updateHistory,
}) => {
  const { t: translate } = useTranslation('explore');
  const yourCoursesTabs = defaultTabs.map(tab => ({
    ...tab,
    title: translate(`your_courses.navigation.${tab.title}`).toString(),
  }));

  const filterCollectionsFromCourses = (
    courses: categoryContent_exploreCategory_content[],
  ) =>
    courses?.filter(
      course =>
        !(
          (course as categoryContent_exploreCategory_content_Series)
            ?.totalDays === 1
        ),
    );

  const {
    data: inProgressData,
    loading: inProgressLoading,
    error: inProgressError,
    refetch: inProgressRefetch,
  } = useQuery<getUserCourses>(USER_COURSES_QUERY, {
    variables: {
      input: {
        status: 'IN_PROGRESS',
      },
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: completedData,
    loading: completedLoading,
    error: completedError,
    refetch: completedRefetch,
  } = useQuery<getUserCourses>(USER_COURSES_QUERY, {
    variables: {
      input: {
        status: 'COMPLETED',
      },
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const inProgressCourses = transformYourCourses(
    inProgressData?.getUserCourses
      ?.edges as (getUserCourses_getUserCourses_edges | null)[],
  );

  const completedCourses = transformYourCourses(
    completedData?.getUserCourses
      ?.edges as (getUserCourses_getUserCourses_edges | null)[],
  );

  const TabRender = ({
    content,
    assetToken,
    source,
    error,
    loading,
    refetch,
    translationId,
  }: {
    content: categoryContent_exploreCategory_content[];
    assetToken?: string | null;
    source: string;
    error: ApolloError | undefined;
    loading: boolean;
    refetch(
      variables?: OperationVariables | undefined,
    ): Promise<ApolloQueryResult<getUserCourses>>;
    translationId: 'in_progress' | 'completed';
  }) => {
    if (loading) {
      return (
        <TemporaryStateContainer>
          <LoadingIndicator />
        </TemporaryStateContainer>
      );
    }

    if (error) {
      return (
        <TemporaryStateContainer>
          <GenericError retry={true} onRetry={refetch} />
        </TemporaryStateContainer>
      );
    }

    if (content.length <= 0) {
      return (
        <YourCoursesInner>
          <YourCoursesEmpty>
            <EmptyTitle>
              {translate(`your_courses.${translationId}.empty_state.title`)}
            </EmptyTitle>
            <EmptyBody>
              {translate(`your_courses.${translationId}.empty_state.body`)}
            </EmptyBody>
          </YourCoursesEmpty>
        </YourCoursesInner>
      );
    }

    return (
      <YourCoursesInner>
        <ContentSection
          content={content}
          assetToken={assetToken}
          source={source}
        />
      </YourCoursesInner>
    );
  };

  return (
    <YourCoursesFullHeightContent>
      <YourCoursesContainer>
        <YourCoursesWrapper>
          <YourCoursesInner>
            <BreadcrumbNavigation />
            <PageTitle>{translate('your_courses.navigation.title')}</PageTitle>
          </YourCoursesInner>
        </YourCoursesWrapper>
        <Tabs
          activeTab={activeTab}
          tabs={yourCoursesTabs}
          updateHistory={updateHistory}
          tabsClassName="your-courses-tabs"
          data-testid="your-courses-tabs"
          align={'left'}
        >
          <TabContainer>
            <TabRender
              content={filterCollectionsFromCourses(
                inProgressCourses as categoryContent_exploreCategory_content[],
              )}
              assetToken={inProgressData?.getAssetToken?.assetToken}
              source="your-courses-in-progress"
              error={inProgressError}
              loading={inProgressLoading}
              refetch={inProgressRefetch}
              translationId="in_progress"
            />
          </TabContainer>
          <TabContainer>
            <TabRender
              content={filterCollectionsFromCourses(
                completedCourses as categoryContent_exploreCategory_content[],
              )}
              assetToken={completedData?.getAssetToken?.assetToken}
              source="your-courses-completed"
              error={completedError}
              loading={completedLoading}
              refetch={completedRefetch}
              translationId="completed"
            />
          </TabContainer>
        </Tabs>
      </YourCoursesContainer>
    </YourCoursesFullHeightContent>
  );
};

export default compose<YourCoursesPageProps, YourCoursesPageProps>(
  withRouter,
  mapProps((props: RouteComponentProps) => ({
    ...props,
    activeTab: props.location.hash,
    updateHistory: props.history.push,
  })),
)(YourCoursesPage);
