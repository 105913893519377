import styled from 'styled-components';
import { rem } from 'polished';
import { FeatureText, HeadingText } from 'Shared/Typography';
import { ThemeInterface } from 'styles';
import { Location } from 'icons';
import { FontSizes } from '@unmind/design-system-theme';

export const HeaderWrapper = styled.div<{ increasedSpacing?: boolean }>`
  margin-bottom: ${({ increasedSpacing }) =>
    increasedSpacing ? '48px' : '24px'};
`;

export const PageTitle = styled(FeatureText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 1,
    sizes: [theme.typography.fontSizes.fontSize32],
    weight: theme.typography.fontWeights.medium,
    accessibilityAutoFocus: true,
  }),
)`
  margin: 0;
  padding-bottom: ${rem(24)};
`;

export const SubHeading = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
  align: 'left',
}))`
  margin-bottom: 16px;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdateLocationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 24px;
`;

export const LocationPin = styled(Location).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 16,
  ['aria-hidden']: true,
}))`
  margin-right: 6px;
`;
