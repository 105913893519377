import { Field, FieldProps } from 'formik';
import React from 'react';
import { Input, InputProps } from '../Input';
import getError from './getError';

const FormikInput = ({
  name,
  ...props
}: {
  name: string;
  as?: string | React.ComponentType<FieldProps['field']>;
  validate?(value: any): string | boolean | Promise<void> | undefined;
} & InputProps) => (
  <Field name={name} as={props.as} validate={props.validate}>
    {({ field, form }: FieldProps) => (
      <Input
        aria-describedby={`${name}-error`}
        aria-invalid={!!getError(name, form)}
        {...field}
        {...props}
        error={getError(name, form)}
      />
    )}
  </Field>
);

export default FormikInput;
