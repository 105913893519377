import React, { useRef, useState } from 'react';
import { css, styled } from 'styles';
import { Info } from 'icons';
import { rem } from 'polished';
import { Formik, FormikProps } from 'formik';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation, Trans } from 'react-i18next';
import { Namespace } from 'i18next';
import {
  FormikInput,
  validateFieldNotEmpty,
} from '../../../Shared/Form/Formik';
import FieldError from '../../../Shared/Form/FieldError';
import PrimaryButton from '../../../Shared/PrimaryButton';
import Tooltip from '../../../Shared/Tooltip';
import { ExternalLink } from '../../../Shared/Typography';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { GroupFileField } from '../useSubdomainInfo';
import { medium } from '../../../utils';
import { CustomIdentifier, UserDetails } from '../SignUp';
import { tracking } from '../../../App/Tracking';
import i18n from '../../../i18n/config';
import { SignUpWithSSOButton } from '../SignUpWithSSOButton';
import { StyledForm, StyledFormFieldLabel } from './CommonFormStyledComponents';

export const ResponseMessageTypes = {
  'validate-custom-identifier-not-valid': i18n.t(
    'logged_out:sign_up.forms.employee_id.errors.unrecognised_id',
  ),
  fallback: i18n.t('shared:errors.messages.failed'),
} as const;

const ContinueButton = styled(PrimaryButton)`
  align-self: bottom;
  margin: auto 0 ${rem(16)} 0;
  width: 100%;

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

const FieldLabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
}))`
  display: flex;
  width: ${rem(16)};
  height: ${rem(16)};
`;

const TooltipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${rem(6)};
`;

export const TooltipLabel = styled.div`
  max-width: ${rem(270)};
`;

export interface EmployeeIdFormValues {
  [key: string]: string;
}

export interface EmployeeIdFormProps {
  businessName: string;
  groupFileFields: GroupFileField[];
  subdomain: string;
  onSubmit(details: UserDetails): void;
  progressIndicator: React.ReactElement;
  isInitialValid?: boolean;
  initialValues?: EmployeeIdFormValues;
  ssoProviderName?: string | null;
  shouldShowSSOButton?: boolean;
}

export function mapCustomIdentifiers(
  groupFileFields: GroupFileField[],
  values: { [key: string]: string },
): CustomIdentifier[] {
  return groupFileFields.map(fileField => ({
    fieldKey: fileField.fileMappingField || '',
    fieldValue: fileField.fileMappingField
      ? values[fileField.fileMappingField]
      : '',
  }));
}

export const VALIDATE_IDENTIFIERS_QUERY = gql`
  query validateCustomIdentifier($input: ValidateCustomIdentifierInput!) {
    validateCustomIdentifier(input: $input) {
      ... on ValidateCustomIdentifierSuccess {
        isValid
      }
      ... on ValidateCustomIdentifierNotFound {
        isValid
        message
      }
    }
  }
`;

const EmployeeIdForm = ({
  isInitialValid = false,
  initialValues = {},
  ssoProviderName,
  shouldShowSSOButton = false,
  ...props
}: EmployeeIdFormProps) => {
  const { groupFileFields, subdomain, onSubmit } = props;
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const containerRef = useRef<HTMLDivElement>(null);
  const formikRef = useRef<Formik<EmployeeIdFormValues>>(null);
  const [error, setError] = useState('');
  const [validateIdentifiers, { loading }] = useLazyQuery(
    VALIDATE_IDENTIFIERS_QUERY,
    {
      onCompleted: data => {
        const {
          validateCustomIdentifier: { isValid, message },
        } = data;

        if (isValid) {
          handleValidateIdentifiersSuccess();
        } else {
          handleValidateIdentifiersFailure(message);
        }
      },
      onError: () => {
        setError(ResponseMessageTypes.fallback);
      },
    },
  );

  groupFileFields.forEach(({ fileMappingField }) => {
    if (fileMappingField) {
      initialValues[fileMappingField] = initialValues
        ? initialValues[fileMappingField]
        : '';
    }
  });

  const handleValidateIdentifiersFailure = (
    message: keyof typeof ResponseMessageTypes,
  ) => {
    const errorMessage = ResponseMessageTypes[message]
      ? ResponseMessageTypes[message]
      : translate('sign_up.forms.employee_id.errors.unrecognised_id');

    setError(errorMessage);

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'employee-id',
      verificationValid: false,
      partner: null,
    });
  };

  const handleValidateIdentifiersSuccess = () => {
    setError('');

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'employee-id',
      verificationValid: true,
      partner: null,
    });

    const identifiers = formikRef?.current?.state
      .values as EmployeeIdFormValues;

    const mappedIdentifiers = mapCustomIdentifiers(
      groupFileFields,
      identifiers,
    );

    onSubmit({
      customIdentifiers: mappedIdentifiers,
    });
  };

  const validateInputs = (values: EmployeeIdFormValues) => {
    const mappedIdentifiers = mapCustomIdentifiers(groupFileFields, values);

    validateIdentifiers({
      variables: {
        input: {
          customIdentifiers: mappedIdentifiers,
          subdomain,
        },
      },
    });
  };

  // There are currently no orgs in prod using more than one groupFileField, so we're safe to use the first one for the title
  const firstCustomIdHeader = groupFileFields[0].fileHeader;

  const shouldUseDefaultFormHeading = () =>
    !firstCustomIdHeader || firstCustomIdHeader === 'Employee ID';

  const getFormHeading = () => {
    if (shouldUseDefaultFormHeading()) {
      return translate('sign_up.forms.employee_id.heading');
    } else {
      return `${translate(
        'sign_up.forms.employee_id.custom_heading',
      )} ${firstCustomIdHeader}`;
    }
  };

  return (
    <AuthWrapper
      groupName={props.businessName}
      progressBar={props.progressIndicator}
    >
      <AuthWrapperBlock data-testid="signup-id-form">
        <AuthWrapperTitle data-testid="form-title">
          {getFormHeading()}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('sign_up.forms.employee_id.subtitle')}{' '}
          {Trans({
            t: translate,
            i18nKey: 'sign_up.forms.employee_id.support_link.text',
            components: {
              link_text: (
                <ExternalLink
                  data-testid="support-team-link"
                  href={translate('sign_up.forms.employee_id.support_link.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={translate(
                    'sign_up.forms.employee_id.support_link.a11y_label',
                  )}
                />
              ),
            },
            defaults:
              '<link_text>Support Team<external_link_icon></external_link_icon></link_text>',
          })}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <Formik
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            onSubmit={validateInputs}
            ref={formikRef}
          >
            {({ isValid }: FormikProps<EmployeeIdFormValues>) => (
              <StyledForm>
                {groupFileFields.map(field =>
                  field.fileMappingField && field.fileHeader ? (
                    <>
                      <FieldLabelWrapper data-testid="custom-id-input-label">
                        <StyledFormFieldLabel htmlFor={field.fileMappingField}>
                          {field.fileHeader}
                        </StyledFormFieldLabel>
                        {shouldUseDefaultFormHeading() && (
                          <TooltipContainer>
                            <Tooltip
                              labelCopy={translate(
                                'sign_up.forms.employee_id.tool_tip_text',
                              )}
                              placement="top"
                              overflowBoundaryRef={
                                containerRef.current || undefined
                              }
                              accessibilityId="disabled-employee-id-tooltip"
                              data-testid="custom-id-label-tooltip"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </TooltipContainer>
                        )}
                      </FieldLabelWrapper>
                      <FormikInput
                        data-testid="custom-id-input"
                        key={field.fileMappingField}
                        name={field.fileMappingField}
                        placeholder={translate(
                          'sign_up.forms.employee_id.custom_id_field.placeholder',
                        )}
                        aria-label={field.fileHeader}
                        validate={e =>
                          validateFieldNotEmpty(
                            e,
                            translate(
                              'sign_up.forms.employee_id.errors.field_empty',
                              {
                                file_header: field.fileHeader,
                              },
                            ),
                          )
                        }
                        expandHeight={false}
                        onFocus={() => setError('')}
                      />
                    </>
                  ) : null,
                )}
                {error && <FieldError message={error} />}

                <ContinueButton
                  data-testid="continue-button"
                  type="submit"
                  label={translate(
                    'sign_up.forms.employee_id.submit_button.label',
                  )}
                  disabled={!isValid}
                  loading={loading}
                />
              </StyledForm>
            )}
          </Formik>
          {shouldShowSSOButton && (
            <SignUpWithSSOButton ssoProviderName={ssoProviderName} />
          )}
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default EmployeeIdForm;
