import React from 'react';
import { IconProps } from '../IconProps';

const SvgQuoteQuestionAndExclamationMark = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 163 139" fill="none" {...props}>
    <path
      d="M136.353 17.113C119.676 3.581 95.126 0 74.722 3.627c-2.423.419-4.752 1.023-6.988 1.72-34.52 10.883-51.476 53.945-37.687 88.544.28.698.233 1.488-.14 2.14-2.096 3.487-9.922 15.439-26.553 28.134-1.91 1.442-.932 4.511 1.49 4.511 7.967 0 15.933-.558 23.62-2.418 5.17-1.256 13.602-5.674 17.05-7.534.93-.511 2.095-.325 2.887.419 18.541 17.485 42.113 20.694 63.775 15.021 25.668-6.697 45.28-29.949 48.169-57.34 2.329-22.228-6.895-45.852-23.945-59.664l-.047-.047z"
      fill="#FFF964"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.753 52.968c1.91-3.767 5.031-7.348 8.851-9.069 8.199-3.674 20.823 5.162 15.466 14.742-1.258 2.232-3.074 4.046-4.798 5.906-4.612 5.162-8.199 11.672-7.454 18.695M111.058 41.11c-.047 12.137.233 27.855.699 39.993"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx={76.84} cy={97.658} rx={1.543} ry={1.54} fill="#391F17" />
    <ellipse cx={112.595} cy={97.658} rx={1.543} ry={1.54} fill="#391F17" />
  </svg>
);

export default SvgQuoteQuestionAndExclamationMark;
