import React from 'react';
import styled from 'styled-components';
import { categoryContent_exploreCategory_content as CategoryContent } from '../Category/__generated__/categoryContent';
import ContentCardGrid from './ContentCardGrid';

const SectionContainer = styled.div``;

export interface CategoryContentProps {
  content: CategoryContent[];
  assetToken?: string | null;
  source: string;
}

function ContentSection({ content, assetToken, source }: CategoryContentProps) {
  return (
    <SectionContainer data-testid="content-section">
      {/* Filtering goes here */}
      <ContentCardGrid
        content={content}
        assetToken={assetToken}
        source={source}
      />
    </SectionContainer>
  );
}

export default ContentSection;
