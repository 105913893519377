import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';

import { Mood } from '../../Track/Moods/types';
import { moodIconMap } from './MoodFaceIcons';

const Container = styled.div<{ size?: number; active: boolean }>`
  width: ${({ size }) => (!isNil(size) ? rem(size) : rem(256))};
  height: ${({ size }) => (!isNil(size) ? rem(size) : rem(256))};
  position: relative;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  /**
   * Select face border and background which is made up of the first two and fourth paths for
   * transition to transparent
   */
  & > svg g path:nth-child(-n + 4):not(:nth-child(3)) {
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ active }) => (active ? undefined : `0`)};
  }
`;

export const MoodFace: React.FC<{
  mood: Mood;
  active: boolean;
  size?: number;
  shadow?: boolean;
  onClick?(): void;
}> = ({ mood, size, active, onClick: onClickProps }) => {
  const onClick = () => {
    if (onClickProps) {
      onClickProps();
    }
  };

  const MoodFaceIcon = moodIconMap[mood];

  return (
    <Container
      aria-hidden={!active}
      size={size}
      onClick={onClick}
      active={active}
    >
      <MoodFaceIcon />
    </Container>
  );
};
