import { Field, FieldProps, Formik } from 'formik';
import React, { useRef } from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FormikSelect } from '../../../Shared/Form/Formik';
import { SelectSize } from '../../../Shared/Form/Select';
import PrimaryButton from '../../../Shared/PrimaryButton';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { small, medium } from '../../../utils';
import { UserDetails } from '../SignUp';
import { Department, Location } from '../useSubdomainInfo';
import { filterNonNull } from '../../../typescript/helpers';
import {
  useSubDomainInfo_subDomain_departments,
  useSubDomainInfo_subDomain_locations,
} from '../__generated__/useSubDomainInfo';
import { MarketingCheckbox } from '../Checkboxes';
import BodyText from '../../../Shared/Typography/BodyText';
import { StyledForm, StyledFormFieldLabel } from './CommonFormStyledComponents';

const SubmitButton = styled(PrimaryButton)`
  margin: auto 0 ${rem('16px')} 0;
  width: 100%;

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

type FormValues = {
  location: string;
  department: string;
  marketingOptIn?: boolean;
};

interface EnterWorkDetailsProps {
  name?: string;
  locations?: Location[] | null;
  departments?: Department[] | null;
  onSubmit(details: UserDetails): void;
  progressIndicator: React.ReactElement;
  isInitialValid?: boolean;
  initialValues?: FormValues;
  showMarketingCheckbox?: boolean;
}

type NonNullLocations = useSubDomainInfo_subDomain_locations[];
type NonNullDepartments = useSubDomainInfo_subDomain_departments[];

const FieldWrapper = styled.div`
  margin-bottom: ${rem('16px')};

  ${small(css`
    margin-bottom: ${rem('32px')};
  `)}

  ${medium(css`
    margin-bottom: ${rem('24px')};
  `)}
`;

const FieldLabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const SubmitWrapper = styled.div`
  margin-top: ${rem(20)};
`;

const Smallprint = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(20)};
`;

const formValidation = ({
  location,
  department,
  translate,
  filteredDepartments,
  filteredLocations,
}: FormValues & {
  translate: TFunction<'logged_out'>;
  filteredDepartments: NonNullDepartments;
  filteredLocations: NonNullLocations;
}) => {
  const errors: {
    department?: string;
    location?: string;
  } = {};
  if (filteredDepartments.length > 0 && !department) {
    errors.department = translate(
      'sign_up.forms.work_details.errors.department_required',
    );
  }

  if (filteredLocations.length > 0 && !location) {
    errors.location = translate(
      'sign_up.forms.work_details.errors.location_required',
    );
  }

  return errors;
};

const EnterWorkDetailsForm = ({
  locations,
  departments,
  name,
  onSubmit,
  progressIndicator,
  isInitialValid = false,
  initialValues = {
    location: '',
    department: '',
    marketingOptIn: false,
  },
  showMarketingCheckbox = false,
}: EnterWorkDetailsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation('logged_out');

  const filteredLocations = locations ? locations.filter(filterNonNull) : [];
  const filteredDepartments = departments
    ? departments.filter(filterNonNull)
    : [];

  return (
    <AuthWrapper groupName={name} progressBar={progressIndicator}>
      <AuthWrapperBlock data-testid="work-details-page" ref={containerRef}>
        <AuthWrapperTitle data-testid="form-title">
          {translate('sign_up.forms.work_details.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('sign_up.forms.work_details.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <Formik
            initialValues={initialValues}
            isInitialValid={isInitialValid}
            onSubmit={async values => {
              if (showMarketingCheckbox) {
                onSubmit({
                  location: locations ? values.location : undefined,
                  department: departments ? values.department : undefined,
                  marketingOptIn: values.marketingOptIn,
                });
              } else {
                onSubmit({
                  location: locations ? values.location : undefined,
                  department: departments ? values.department : undefined,
                });
              }
            }}
            validate={values =>
              formValidation({
                ...values,
                translate,
                filteredLocations,
                filteredDepartments,
              })
            }
          >
            {({ isValid, setFieldValue }) => (
              <StyledForm>
                {filteredLocations.length > 0 ? (
                  <FieldWrapper>
                    <FormikSelect
                      LabelComponent={({ id, htmlFor }) => (
                        <FieldLabelWrapper>
                          <StyledFormFieldLabel
                            id={id}
                            data-testid="location-select-label"
                            htmlFor={htmlFor}
                          >
                            {translate(
                              'sign_up.forms.work_details.work_location_field.label',
                            )}
                          </StyledFormFieldLabel>
                        </FieldLabelWrapper>
                      )}
                      arrowSize={{ width: 16, height: 16 }}
                      size={SelectSize.Big}
                      data-testid="location-select"
                      name="location"
                      placeholder={translate(
                        'sign_up.forms.work_details.work_location_field.placeholder',
                      )}
                      aria-label={translate(
                        'sign_up.forms.work_details.work_location_field.label',
                      )}
                      options={filteredLocations.map(location => ({
                        value: location.id || '',
                        label: location.name || '',
                      }))}
                    />
                  </FieldWrapper>
                ) : null}
                {filteredDepartments.length > 0 ? (
                  <FieldWrapper>
                    <FormikSelect
                      LabelComponent={({ id, htmlFor }) => (
                        <FieldLabelWrapper>
                          <StyledFormFieldLabel
                            id={id}
                            htmlFor={htmlFor}
                            data-testid="department-select-label"
                          >
                            {translate(
                              'sign_up.forms.work_details.department_field.label',
                            )}
                          </StyledFormFieldLabel>
                        </FieldLabelWrapper>
                      )}
                      arrowSize={{ width: 16, height: 16 }}
                      size={SelectSize.Big}
                      data-testid="department-select"
                      name="department"
                      placeholder={translate(
                        'sign_up.forms.work_details.department_field.placeholder',
                      )}
                      aria-label={translate(
                        'sign_up.forms.work_details.department_field.a11y_label',
                      )}
                      options={filteredDepartments.map(department => ({
                        value: department.id || '',
                        label: department.name || '',
                      }))}
                    />
                  </FieldWrapper>
                ) : null}

                <Smallprint>
                  {translate('sign_up.forms.work_details.body')}
                </Smallprint>

                {showMarketingCheckbox ? (
                  <Field name="marketingOptIn">
                    {({ field }: FieldProps) => (
                      <MarketingCheckbox
                        {...field}
                        id="sign-up-marketing"
                        setFieldValue={setFieldValue}
                        checked={field.value as boolean}
                      />
                    )}
                  </Field>
                ) : null}

                <SubmitWrapper>
                  <SubmitButton
                    data-testid="submit-button"
                    type="submit"
                    label={translate(
                      'sign_up.forms.work_details.submit_button.label',
                    )}
                    disabled={!isValid}
                  />
                </SubmitWrapper>
              </StyledForm>
            )}
          </Formik>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default EnterWorkDetailsForm;
