import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useSubdomainInfo } from '../../LoggedOut/SignUp/useSubdomainInfo';
import { LocalGeneralError } from '../../Shared/AlertView';
import { AuthWrapperBlock, AuthWrapper } from '../AuthWrapper';
import useUniversalLogin from '../UniversalLogin/useUniversalLogin';
import LoadingIndicator from '../../Shared/LoadingIndicator';

export type LoginViaIDPInitiatedSSOProps = RouteComponentProps;

function LoginViaIDPInitiatedSSO({}: LoginViaIDPInitiatedSSOProps) {
  const subdomain = getSubdomainFromUrl();

  const {
    groupName,
    authProviderOrganisationId,
    loading: subdomainInfoLoading,
    error: subdomainInfoError,
  } = useSubdomainInfo({
    subdomain,
  });

  const { loginWithUniversalLogin } = useUniversalLogin();

  useEffect(() => {
    if (authProviderOrganisationId) {
      void loginWithUniversalLogin({
        authProviderOrganisationId,
      });
    }
  }, [authProviderOrganisationId, loginWithUniversalLogin]);

  if (subdomainInfoLoading) return <LoadingIndicator />;

  return (
    <>
      <AuthWrapper groupName={groupName}>
        <AuthWrapperBlock>
          {subdomainInfoError ? <LocalGeneralError /> : <LoadingIndicator />}
        </AuthWrapperBlock>
      </AuthWrapper>
    </>
  );
}

export default LoginViaIDPInitiatedSSO;
