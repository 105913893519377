import React from 'react';
import { IconProps } from '../IconProps';
const SvgMindfulness = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g
      clipPath="url(#Mindfulness_svg__clip0_431_421)"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeWidth={1.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.681 16.84C13.521 19 10 19 10 19s0-3.52 2.16-5.681c2.16-2.16 5.682-2.16 5.682-2.16s0 3.52-2.16 5.68zM4.319 16.84C6.479 19 10 19 10 19s0-3.52-2.16-5.681c-2.16-2.16-5.682-2.16-5.682-2.16s0 3.52 2.16 5.68zM10 11.078V19M10 11.078l-4.881-5.12a2.924 2.924 0 01-.56-3.361c.88-1.76 3.2-2.16 4.64-.72l.801.8.8-.8c1.36-1.36 3.76-1.04 4.641.72.56 1.12.32 2.48-.56 3.36L10 11.078z" />
    </g>
    <defs>
      <clipPath id="Mindfulness_svg__clip0_431_421">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMindfulness;
