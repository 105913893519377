import { useTranslation } from 'react-i18next';
import { HeartRateClipboard } from 'icons';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { BodyText } from '@unmind/design-system-components-web';
import { v4 as uuid } from 'uuid';
import { useRiskQuestions } from 'Compass/api';
import { getRiskQuestions_riskQuestions as RiskQuestions } from 'Compass/api/__generated__/getRiskQuestions';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { Risk_Category } from '__generated__/globalTypes';
import { SalaryContext } from '../SalaryProvider';
import {
  ATTRITION_QUESTION_ID,
  BURNOUT_QUESTION_ID,
  PRESENTEEISM_QUESTION_ID,
  ThemeType,
} from '../constants';
import { SectionHeader } from './Components/SectionHeader';
import { Mosaic } from './Components/Mosaic';
import { RiskSelectedCard } from './Components/SelectedCards/RiskSelectedCard';
import { StatusBar } from './Components/ScoreWithColourStatus';
import { Score } from './Components/styles';
import { getOverallCost } from './utils/getOverallCost';

const HeartRateClipboardIcon = styled(HeartRateClipboard).attrs(
  ({ theme }) => ({
    primaryColor: theme.colors.text.primary,
  }),
)`
  width: ${rem(24)};
  height: ${rem(24)};
`;

interface RiskProps {
  title: string;
  risk?: RiskQuestions | null;
  dataTestId?: string;
}

const RiskHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
  height: 100%;
  width: 100%;
  align-items: flex-start;
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(16)};
`;

const LargeSkeleton = styled.div`
  width: ${rem(77)};
  height: ${rem(20)};
  margin-bottom: ${rem(58)};
  margin-top: ${rem(16)};
  border-radius: ${rem(12)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const SmallSkeleton = styled.div`
  width: ${rem(77)};
  height: ${rem(14)};
  border-radius: ${rem(12)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const RiskSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize14)};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: ${rem(8)} 0;
  white-space: pre-line;
  margin-top: ${rem(24)};

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `${rem(2)} solid ${theme.colors.background.secondary}`};
  }
`;

const Risk = ({ title, risk, dataTestId }: RiskProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.risks',
  });

  const { currency, averageSalary } = useContext(SalaryContext);

  const shouldShowOverallCost =
    risk?.id !== BURNOUT_QUESTION_ID && typeof risk?.overallCost === 'number';

  return (
    <Container data-testid={dataTestId}>
      <RiskHeader>
        <Title>{title}</Title>
      </RiskHeader>
      {risk ? (
        <>
          <StatusBar riskCategory={risk?.riskCategory} />
          <RiskSection>
            {translate('employees_impacted_text')}
            <Score large>{risk.bottom2Percentage}%</Score>
          </RiskSection>
          {risk.bottom2PercentageBenchmark && (
            <RiskSection>
              {translate('benchmark_text')}
              <Score>
                {risk.bottom2PercentageBenchmark.difference > 0 ? '+' : ''}
                {risk.bottom2PercentageBenchmark.difference}%
              </Score>
            </RiskSection>
          )}
          {shouldShowOverallCost && (
            <RiskSection>
              {translate('potential_cost_text')}
              <Score>
                {getOverallCost(
                  risk.overallCost as number,
                  currency,
                  averageSalary,
                )}
              </Score>
            </RiskSection>
          )}
        </>
      ) : (
        <>
          <LargeSkeleton />
          <SmallSkeleton />
          <Description>{translate('no_data')}</Description>
        </>
      )}
    </Container>
  );
};

interface RisksProps {
  scheduleId: string;
  readyToShowData: boolean;
  setSelectedCard(card: React.ReactNode): void;
  openAverageSalarySelectedCard(): void;
  trackOpenSelectedCard(
    title: string,
    riskCategory: Risk_Category,
    score: number,
    themeType: ThemeType,
  ): void;
}

export const Risks = ({
  setSelectedCard,
  openAverageSalarySelectedCard,
  scheduleId,
  readyToShowData,
  trackOpenSelectedCard,
}: RisksProps) => {
  const { t: translate } = useTranslation('compass');

  const { data: riskQuestionsData, loading: riskQuestionsLoading } =
    useRiskQuestions({ indexScheduleId: scheduleId, skip: !readyToShowData });

  if (riskQuestionsLoading) {
    return <LoadingIndicator />;
  }

  const { riskQuestions } = riskQuestionsData || { riskQuestions: [] };

  const setRiskSelectedCard = (
    indexScheduleId: string,
    riskQuestionId?: string,
  ) => {
    if (riskQuestionId) {
      setSelectedCard(
        <RiskSelectedCard
          indexScheduleId={indexScheduleId}
          riskQuestionId={riskQuestionId}
          closeCard={() => setSelectedCard(null)}
          openAverageSalarySelectedCard={openAverageSalarySelectedCard}
        />,
      );
    }
  };

  const burnoutData = (riskQuestions ?? []).find(
    risk => risk.id === BURNOUT_QUESTION_ID,
  );
  const lostProductivityData = (riskQuestions ?? []).find(
    risk => risk.id === PRESENTEEISM_QUESTION_ID,
  );
  const attritionData = (riskQuestions ?? []).find(
    risk => risk.id === ATTRITION_QUESTION_ID,
  );

  return (
    <>
      <SectionHeader
        text={translate('dashboard.risks.title')}
        icon={<HeartRateClipboardIcon />}
        description={translate('dashboard.risks.description')}
      />
      <Mosaic
        rowEntries={[
          {
            id: uuid(),
            data: [
              {
                id: uuid(),
                cell: (
                  <Risk
                    title={translate('dashboard.risks.burnout.title')}
                    risk={readyToShowData ? burnoutData : null}
                    dataTestId="burnout-risk-panel"
                  />
                ),
                clickable: readyToShowData,
                onClick: () => {
                  setRiskSelectedCard(scheduleId, burnoutData?.id);

                  const title = burnoutData?.title;
                  const riskCategory = burnoutData?.riskCategory;
                  const riskScore = burnoutData?.bottom2Percentage;

                  if (!title || !riskCategory || !riskScore) {
                    return;
                  }

                  trackOpenSelectedCard(
                    title,
                    riskCategory,
                    riskScore,
                    ThemeType.IMPACT,
                  );
                },
              },
              {
                id: uuid(),
                cell: (
                  <Risk
                    title={translate('dashboard.risks.lost_productivity.title')}
                    risk={readyToShowData ? lostProductivityData : null}
                    dataTestId="lost-productivity-risk-panel"
                  />
                ),
                clickable: readyToShowData,
                onClick: () => {
                  setRiskSelectedCard(scheduleId, lostProductivityData?.id);

                  const title = lostProductivityData?.title;
                  const riskCategory = lostProductivityData?.riskCategory;
                  const riskScore = lostProductivityData?.bottom2Percentage;

                  if (!title || !riskCategory || !riskScore) {
                    return;
                  }

                  trackOpenSelectedCard(
                    title,
                    riskCategory,
                    riskScore,
                    ThemeType.IMPACT,
                  );
                },
              },
              {
                id: uuid(),
                cell: (
                  <Risk
                    title={translate('dashboard.risks.attrition.title')}
                    risk={readyToShowData ? attritionData : null}
                    dataTestId="attrition-risk-panel"
                  />
                ),
                clickable: readyToShowData,
                onClick: () => {
                  setRiskSelectedCard(scheduleId, attritionData?.id);

                  const title = attritionData?.title;
                  const riskCategory = attritionData?.riskCategory;
                  const riskScore = attritionData?.bottom2Percentage;

                  if (!title || !riskCategory || !riskScore) {
                    return;
                  }

                  trackOpenSelectedCard(
                    title,
                    riskCategory,
                    riskScore,
                    ThemeType.IMPACT,
                  );
                },
              },
            ],
            flexible: false,
          },
        ]}
      />
    </>
  );
};
