import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Namespace, ParseKeys, TFunction } from 'i18next';
import { PRACTITIONER_FILTERS_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { userCountryStateQuery_user as UserLocation } from 'Services/User/__generated__/userCountryStateQuery';
import { practitionerFiltersQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerFiltersQuery';
import { getISOLanguageFromCode } from 'utils/getISOLanguageFromCode';
import { tracking } from 'App/Tracking';
import { BrowseFilterPill } from '../Pill';
import { FilterDropdown } from '../BrowseFilterDropdown';
import {
  ClearButton,
  FilterPanelContainer,
  FunnelIcon,
  Label,
  LabelContainer,
  PillsContainer,
} from './styles';
import {
  FilterActionMeta,
  FilterCategory,
  FilterOptionType,
  FiltersCollection,
} from './types';

export interface BrowseFilterPanelProps {
  selectedFilters: FiltersCollection;
  onFiltersChange(filters: FiltersCollection): void;
  renderFilterLabel?: boolean;
  userLocation: UserLocation | null;
}

const queryResponseToFilterOption = (
  t: TFunction<Namespace<'talk'>>,
  queryResponse: { name: string },
) => ({
  value: queryResponse.name,
  label: t(
    `specialities.${queryResponse.name}` as ParseKeys<Namespace<'talk'>>,
  ),
});

export const BrowseFilterPanel = React.memo(
  ({
    selectedFilters,
    onFiltersChange,
    renderFilterLabel = true,
    userLocation,
  }: BrowseFilterPanelProps) => {
    const { t } = useTranslation<Namespace<'talk'>>('talk');
    const { t: translateLanguageName } =
      useTranslation<Namespace<'languages'>>('languages');

    const { data } = useQuery<practitionerFiltersQuery>(
      PRACTITIONER_FILTERS_QUERY,
      {
        variables: {
          types: ['THERAPIST', 'COACH'],
          country: userLocation?.country?.value,
          state: userLocation?.state?.value,
        },
        ...defaultQueryConfig,
        ...BEGatewayQueryContext,
        skip: !userLocation,
      },
    );

    const {
      languages: selectedLanguages,
      approaches: selectedApproaches,
      areasOfExpertise: selectedAreasOfExpertise,
    } = selectedFilters;

    const allSelectedFiltersWithCategory: (FilterOptionType & {
      category: FilterCategory;
    })[] = selectedLanguages
      .map(lang => ({ ...lang, category: 'language' as FilterCategory }))
      .concat(
        selectedApproaches.map(approach => ({
          ...approach,
          category: 'approach' as FilterCategory,
        })),
      )
      .concat(
        selectedAreasOfExpertise.map(areaOfExpertise => ({
          ...areaOfExpertise,
          category: 'areaOfExpertise' as FilterCategory,
        })),
      );

    // memoisation required to preserve select options
    const availableLanguages = useMemo(
      () =>
        data?.practitionerFilters?.languages?.map(lang => {
          const languageLabels = getISOLanguageFromCode(lang.name);

          return {
            value: lang.name,
            label: `${translateLanguageName(
              languageLabels.en as ParseKeys<Namespace<'languages'>>,
            )} (${languageLabels.native})`,
          };
        }) || [],
      [data, translateLanguageName],
    );

    const availableApproaches = useMemo(
      () =>
        data?.practitionerFilters?.approaches?.map(item =>
          queryResponseToFilterOption(t, item),
        ) || [],
      [t, data],
    );

    const availableAreasOfExpertise = useMemo(
      () =>
        data?.practitionerFilters?.areasOfExpertise?.map(item =>
          queryResponseToFilterOption(t, item),
        ) || [],
      [t, data],
    );

    /**
     * Handlers
     */
    const trackFilterCategoryClear = (
      filterCategory: 'all' | FilterCategory,
      uiElement: 'dropdown' | 'pill',
    ) => {
      tracking.track('talk-browse-practitioner-filter-option-selected', {
        filterOption: 'all',
        filterCategory,
        action: 'deselect',
        uiElement,
      });
    };

    const trackFilterValueClear = (
      filterOption: string | undefined,
      filterCategory: 'all' | FilterCategory,
      uiElement: 'dropdown' | 'pill',
    ) => {
      tracking.track('talk-browse-practitioner-filter-option-selected', {
        filterOption,
        filterCategory,
        action: 'deselect',
        uiElement,
      });
    };

    const trackFilterOptionChange = (
      actionMeta: FilterActionMeta | undefined,
      filterCategory: FilterCategory,
    ) => {
      if (actionMeta?.action === 'select-option') {
        tracking.track('talk-browse-practitioner-filter-option-selected', {
          filterOption: actionMeta?.option?.value,
          filterCategory,
          action: 'select',
          uiElement: 'dropdown',
        });
      }

      if (actionMeta?.action === 'deselect-option') {
        trackFilterValueClear(
          actionMeta?.option?.value,
          filterCategory,
          'dropdown',
        );
      }

      if (actionMeta?.action === 'clear') {
        trackFilterCategoryClear(filterCategory, 'dropdown');
      }
    };

    const clearValue = (value: string) => {
      onFiltersChange({
        languages: selectedLanguages.filter(s => s.value !== value),
        approaches: selectedApproaches.filter(s => s.value !== value),
        areasOfExpertise: selectedAreasOfExpertise.filter(
          s => s.value !== value,
        ),
      });
    };

    const clearAllValues = () => {
      trackFilterCategoryClear('all', 'pill');
      onFiltersChange({
        languages: [],
        approaches: [],
        areasOfExpertise: [],
      });
    };

    return (
      <>
        <FilterPanelContainer>
          {renderFilterLabel ? (
            <LabelContainer>
              <FunnelIcon />
              <Label>{t('browse_practitioners.filter_panel.title')}</Label>
            </LabelContainer>
          ) : null}
          {!!data && (
            <>
              <FilterDropdown
                placeholder={t(
                  'browse_practitioners.filter_panel.dropdown_language.title',
                )}
                description={t(
                  'browse_practitioners.filter_panel.dropdown_language.description',
                )}
                options={availableLanguages}
                value={selectedLanguages}
                onChange={(value, actionMeta) => {
                  trackFilterOptionChange(actionMeta, 'language');
                  onFiltersChange({
                    ...selectedFilters,
                    languages: [...value],
                  });
                }}
                onClear={() => {
                  trackFilterCategoryClear('language', 'dropdown');
                  onFiltersChange({
                    ...selectedFilters,
                    languages: [],
                  });
                }}
              />
              <FilterDropdown
                placeholder={t(
                  'browse_practitioners.filter_panel.dropdown_focus_area.title',
                )}
                description={t(
                  'browse_practitioners.filter_panel.dropdown_focus_area.description',
                )}
                options={availableAreasOfExpertise}
                value={selectedAreasOfExpertise}
                onChange={(value, actionMeta) => {
                  trackFilterOptionChange(actionMeta, 'areaOfExpertise');
                  onFiltersChange({
                    ...selectedFilters,
                    areasOfExpertise: [...value],
                  });
                }}
                onClear={() => {
                  trackFilterCategoryClear('areaOfExpertise', 'dropdown');
                  onFiltersChange({
                    ...selectedFilters,
                    areasOfExpertise: [],
                  });
                }}
              />
              <FilterDropdown
                placeholder={t(
                  'browse_practitioners.filter_panel.dropdown_approach.title',
                )}
                description={t(
                  'browse_practitioners.filter_panel.dropdown_approach.description',
                )}
                options={availableApproaches}
                value={selectedApproaches}
                onChange={(value, actionMeta) => {
                  trackFilterOptionChange(actionMeta, 'approach');
                  onFiltersChange({
                    ...selectedFilters,
                    approaches: [...value],
                  });
                }}
                onClear={() => {
                  trackFilterCategoryClear('approach', 'dropdown');
                  onFiltersChange({
                    ...selectedFilters,
                    approaches: [],
                  });
                }}
              />
            </>
          )}
        </FilterPanelContainer>
        {allSelectedFiltersWithCategory.length ? (
          <PillsContainer>
            {allSelectedFiltersWithCategory.map(selectedFilter => (
              <BrowseFilterPill
                key={selectedFilter.value}
                label={selectedFilter.label}
                onSelect={() => {
                  trackFilterValueClear(
                    selectedFilter.value,
                    selectedFilter.category,
                    'pill',
                  );
                  clearValue(selectedFilter.value);
                }}
              />
            ))}
            <ClearButton onClick={clearAllValues}>
              {t('browse_practitioners.filter_panel.clear')}
            </ClearButton>
          </PillsContainer>
        ) : null}
      </>
    );
  },
);
