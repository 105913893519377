import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { compose } from 'recompose';
import { styled } from 'styles';
import { tracking } from '../../App/Tracking';
import Modal, {
  ModalActions,
  ModalContent,
  ModalCopy,
  ModalTitle,
} from '../../Shared/Modal';
import PrimaryButton from '../../Shared/PrimaryButton';
import { ButtonSize } from '../../Shared/Button';
import TextButton from '../../Shared/TextButton';
import Tooltip, { useTooltipState } from '../../Shared/Tooltip';
import { BodyText } from '../../Shared/Typography';
import { PlusOneConfirmationModalType } from './PlusOneConfirmationModal';
import {
  ActionButton,
  ActionButtonWrapper,
  InfoIcon,
  InviteDate,
  InviteeDetails,
  InviteeDetailsInner,
  InviteName,
  PlusOne,
  Subtitle,
  SubtitleContainer,
  TooltipContainer,
  tooltipPosition,
  trackTooltip,
} from './PlusOneStatus';
import withRemovePlusOneUser, {
  RemovePlusOneUserProps,
} from './withRemovePlusOneUser';

interface AcceptedProps {
  plusOne: PlusOne;
  plusOneConfirmationModalCallback(
    confType: PlusOneConfirmationModalType,
  ): void;
}

interface Props extends RemovePlusOneUserProps, AcceptedProps {}

const EmailText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

const PlusOneStatusAccepted = ({
  plusOne,
  removePlusOneUser,
  plusOneConfirmationModalCallback,
}: Props) => {
  const { t: translate } = useTranslation<Namespace<'plus_one'>>('plus_one');
  const { open: tooltipOpen, handlers: tooltipHandlers } = useTooltipState();
  const { joinedOn, emailAddress, firstName } = plusOne;
  const joinedDate = moment(joinedOn).format('MMM Do, YYYY');

  const RemovePlusOneModal = () => {
    const [loading, setLoading] = useState(false);

    const removeSubmit = async () => {
      try {
        setLoading(true);
        await removePlusOneUser();
        setLoading(false);
        tracking.track('settings-plus-one-remove-confirmed');
        plusOneConfirmationModalCallback('removed');
      } catch (e) {
        // TODO: Handle Error State.
        setLoading(false);
      }
    };

    return (
      <Modal
        closeHeader={true}
        onCloseCallback={(trackingData: { [key: string]: string }) => {
          tracking.track('settings-plus-one-remove-cancelled', {
            ...trackingData,
          });
        }}
        openModalButton={({ open }) => (
          <ActionButton
            label={translate('invite_flow.accepted.removal_button.label')}
            onClick={() => {
              tracking.track('settings-plus-one-remove-clicked');
              open();
            }}
          />
        )}
        modalContent={({ close }) => (
          <Fragment>
            <ModalContent>
              <ModalTitle>
                {translate('invite_flow.accepted.removal_modal.title')}
              </ModalTitle>
              <ModalCopy>
                {translate('invite_flow.accepted.removal_modal.body')}
              </ModalCopy>
            </ModalContent>
            <ModalActions>
              <PrimaryButton
                label={translate(
                  'invite_flow.accepted.removal_modal.cancel_button.label',
                )}
                type="submit"
                onClick={() => {
                  close({
                    actionMethod: 'cancelButton',
                  });
                }}
                size={ButtonSize.medium}
              />
              <TextButton
                variant="destructive"
                label={translate(
                  'invite_flow.accepted.removal_modal.continue_button.label',
                )}
                loading={loading}
                onClick={removeSubmit}
                size={ButtonSize.medium}
              />
            </ModalActions>
          </Fragment>
        )}
      />
    );
  };

  const labelCopy = `${translate('invite_flow.tool_tip.body_1')} ${translate(
    'invite_flow.tool_tip.body_2',
  )}`;

  return (
    <>
      <SubtitleContainer>
        <Subtitle>{translate('invite_flow.accepted.title')}</Subtitle>
        <TooltipContainer {...tooltipHandlers}>
          <Tooltip
            labelCopy={labelCopy}
            placement={tooltipPosition('right')}
            open={tooltipOpen}
            accessibilityId="plusone-tooltip"
            onMouseEnter={() => {
              trackTooltip();
              tooltipHandlers.onMouseEnter();
            }}
          >
            <InfoIcon />
          </Tooltip>
        </TooltipContainer>
      </SubtitleContainer>
      <InviteeDetails>
        <InviteeDetailsInner>
          {Trans({
            t: translate,
            i18nKey: 'invite_flow.accepted.invitee',
            defaults:
              '<bold>{{first_name}}</bold> <regular>joined us on {{date}}</regular>',
            components: {
              bold: <InviteName data-testid="accepted-plusone-name" />,
              regular: <InviteDate />,
            },
            values: {
              first_name: firstName,
              date: joinedDate,
            },
          })}
          <EmailText>{emailAddress}</EmailText>
        </InviteeDetailsInner>
        <ActionButtonWrapper>
          <RemovePlusOneModal />
        </ActionButtonWrapper>
      </InviteeDetails>
    </>
  );
};

export default compose<Props, AcceptedProps>(withRemovePlusOneUser)(
  PlusOneStatusAccepted,
);
