import { Namespace } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body, CompletionContainer } from './styles';

export const CompletedCard = () => {
  const { t: translate } = useTranslation<Namespace<'talk'>>('talk');

  return (
    <CompletionContainer>
      <Body>
        {translate('outcome_measures.questionnaire_state.completed.body')}
      </Body>
    </CompletionContainer>
  );
};
