import { rem, transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { TextBlock } from 'icons';
import { isDesktop } from 'react-device-detect';
import { ScreenReaderContent } from '../Accessibility';
import { small } from '../../utils';
import MediaButtonTooltip from './MediaButtonTooltip';

const Button = styled.button<{ isAudioPlayer?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${props =>
    props.isAudioPlayer
      ? transparentize(0.2, props.theme.colors.mediaPlayer.audio.icon.default)
      : transparentize(0.2, props.theme.colors.staticPalette.white)};
  cursor: pointer;
  padding: 0;
  height: ${rem(24)};
  width: ${rem(24)};
  position: relative;

  ${small(css`
    height: ${rem(32)};
    width: ${rem(32)};
  `)}

  &:hover {
    color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.icon.hover
        : props.theme.colors.staticPalette.white};
  }

  &:active,
  &:focus {
    outline: ${props =>
      css`
        ${rem(2)} solid ${props.theme.button.borderFocusColor}
      `};
  }
`;

const TranscriptIcon = styled(TextBlock).attrs({
  primaryColor: 'currentColor',
})`
  height: 100%;
  width: 100%;
`;

interface TranscriptButtonProps {
  className?: string;
  onClick?(): void;
  isAudioPlayer?: boolean;
}

export const TranscriptButton = ({
  className,
  onClick,
  isAudioPlayer,
}: TranscriptButtonProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const { t: translate } = useTranslation('media_player');

  return (
    <>
      <Button
        className={className}
        onClick={onClick}
        onMouseEnter={() => (isDesktop ? setTooltipVisible(true) : null)}
        onMouseLeave={() => setTooltipVisible(false)}
        isAudioPlayer={isAudioPlayer}
      >
        <TranscriptIcon />
        <ScreenReaderContent as="span">
          {translate('controls.transcript_button.accessibility_label')}
        </ScreenReaderContent>
        {isTooltipVisible && (
          <MediaButtonTooltip
            label={translate('controls.transcript_button.tooltip')}
            minWidth={92}
            isLeftOffset={true}
          />
        )}
      </Button>
    </>
  );
};
