export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
const DEFAULT_TZ = 'UTC';

export const getTimeZone = (): string => {
  if (Intl) {
    if (Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (timeZone) return timeZone;
    }

    return DEFAULT_TZ;
  }

  console.warn('Time zone information is unavailable.');

  return DEFAULT_TZ;
};
