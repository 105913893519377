import moment from 'moment';
import { TFunction } from 'i18next';

const floorDateToMidnight = (date: number | string): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const hasCompletedIndexRecently = (
  indexFrequency: number,
  lastIndexDate?: string,
  currentDate = Date.now(),
): boolean =>
  lastIndexDate
    ? moment(floorDateToMidnight(lastIndexDate)) >
      moment(floorDateToMidnight(currentDate)).subtract(indexFrequency, 'days')
    : false;

export const daysRemaining = (
  lastIndexDate: string,
  indexFrequency: number,
  currentDate = Date.now(),
): number => {
  const twoWeeksAgo = moment(floorDateToMidnight(currentDate)).subtract(
    indexFrequency,
    'days',
  );

  return moment(floorDateToMidnight(lastIndexDate)).diff(twoWeeksAgo, 'days');
};

export const getIndexFrequencyText = (
  indexFrequency: number,
  translate: TFunction,
): string => {
  switch (indexFrequency) {
    case 1:
      return translate('insights:index.frequency_gaps.one_day');
    case 7:
      return translate('insights:index.frequency_gaps.seven_days');
    default:
      return translate('insights:index.frequency_gaps.two_weeks');
  }
};

export const MINIMUM_INDEX_GAP = '2 weeks';
