import { logException } from '../../App/logging';

export interface FormFields {
  file?: unknown;
}
export interface ManageUsersAlert {
  success: boolean;
  message: string;
}

export async function postFile(
  url: string,
  file: File,
  irisToken: string | undefined,
  auth0JWT: string | undefined,
) {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  if (irisToken) {
    formData.append('iris_token', irisToken);
  } else if (auth0JWT) {
    // iris will not be able to parse the form if you don't give 2 fields, it
    // doesn't matter what the 2nd field is, but it has to be there.
    formData.append('make_iris_happy', '1');
  }

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      ...(auth0JWT ? { 'x-api-authorization': `Bearer ${auth0JWT}` } : {}),
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData,
  })
    .then(async response => response.json())
    .catch(logException);
}
