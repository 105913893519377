import { components, OptionProps } from 'react-select';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import {
  BaseOptionValue,
  MultiSelectOption,
} from '../../../Shared/Form/MultiSelect';

const AnonymityMessage = styled.div`
  font-size: ${rem('13px')};
  padding-top: 3px;
`;

const FilterOption = <T extends BaseOptionValue>(
  props: OptionProps<MultiSelectOption<T>, true>,
) => (
  <components.Option {...props}>
    <label>{props.label}</label>
    {props.isDisabled ? (
      <AnonymityMessage data-testid={`anon-warning-${props.label}`}>
        Not enough data to show
      </AnonymityMessage>
    ) : null}
  </components.Option>
);

export default FilterOption;
