import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rem } from 'polished';
import { SingleSelect } from 'Shared/Form/SingleSelect';
import { SalaryContext } from 'Compass/Components/CompassDashboard/SalaryProvider';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import debounce from 'lodash/debounce';
import { SalaryInput } from '../SalaryInput';
import { CardHeader } from '../styles';
import { CURRENCY_SYMBOLS } from '../../utils/averageSalaryHelpers';
import { CardTitle, CardText, CardLink } from './styles';
import { SelectedCard } from './SelectedCard';

const Bolded = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  display: contents;
`;

const AverageSalaryContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(120)};
`;

const Headers = styled(CardText)`
  margin-bottom: ${rem(12)};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: ${rem(24)};
`;

const StyledSelect = styled(SingleSelect)`
  border-radius: ${rem(12)};
  border-bottom: ${rem(24)};
  height: ${rem(48)};
  margin-bottom: ${rem(24)};
`;

const StyledSelectWrapper = styled.span`
  & > div {
    border-radius: ${rem(12)};
  }

  &:onhover > div {
    border-color: ${({ theme }) => theme.colors.border.secondary};
  }

  & > div > div > div {
    border-radius: ${rem(12)};
  }
`;

interface AverageSalarySelectedCardProps {
  closeCard(): void;
  scheduleId: string;
}

export const AverageSalarySelectedCard = ({
  closeCard,
  scheduleId,
}: AverageSalarySelectedCardProps) => {
  const { averageSalary, currency, setAverageSalaryAndCurrency } =
    useContext(SalaryContext);

  const { t: translate } = useTranslation('compass', {
    keyPrefix: `dashboard.cards.average_salary`,
  });

  const debouncedUpdateAverageSalaryAndCurrency = debounce(
    (updatedAverageSalary, updatedCurrencyCode: string) => {
      setAverageSalaryAndCurrency({
        averageSalary: updatedAverageSalary,
        currency: updatedCurrencyCode,
      });
      tracking.track('compass-average-salary-updated', {
        currencyCode: updatedCurrencyCode,
        averageSalary: updatedAverageSalary,
        scheduleId,
      });
    },
    1000,
  );

  return (
    <SelectedCard
      header={<CardTitle>{translate('title')}</CardTitle>}
      closeCard={closeCard}
      content={
        <AverageSalaryContainer data-testid="average-salary-selected-card">
          <Headers>{translate('currency_header')}</Headers>
          <StyledSelectWrapper>
            <StyledSelect
              placeholder={''}
              isInModal={false}
              defaultSelectedValue={{
                value: currency,
                label: currency,
              }}
              options={Object.keys(CURRENCY_SYMBOLS).map(currencySymbol => ({
                value: currencySymbol,
                label: currencySymbol,
              }))}
              onChange={selection => {
                if (selection?.value && selection.value !== currency) {
                  debouncedUpdateAverageSalaryAndCurrency(
                    averageSalary,
                    selection.value as string,
                  );
                }
              }}
            />
          </StyledSelectWrapper>
          <Headers>{translate('salary_header')}</Headers>
          <SalaryInput
            value={averageSalary}
            currency={CURRENCY_SYMBOLS[currency]}
            onValueChange={value => {
              if (value && value !== averageSalary) {
                debouncedUpdateAverageSalaryAndCurrency(value, currency);
              }
            }}
            ariaLabel={translate('title')}
            name={'average-salary-input'}
          />
          <CardHeader>{translate('description_header')}</CardHeader>
          <Trans
            t={translate}
            i18nKey="description"
            components={{
              container: <CardText />,
              learn_more_link: (
                <CardLink
                  to={RoutePath.CompassCalculator}
                  onClick={() =>
                    tracking.track('compass-schedule-link-clicked', {
                      path: RoutePath.CompassCalculator,
                      scheduleId,
                    })
                  }
                />
              ),
              bolded: <Bolded />,
            }}
          />
        </AverageSalaryContainer>
      }
    />
  );
};
