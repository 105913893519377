import React from 'react';
import { IconProps } from '../IconProps';

const SvgThumb = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 14"
    fill={secondaryColor}
    fillOpacity={0.05}
    {...props}
  >
    <path
      d="M1.538 5.746H4.09v6.795H1.538A.539.539 0 0 1 1 12.002V6.285a.538.538 0 0 1 .538-.539zM4.09 5.746l2.735-4.372a1.174 1.174 0 0 1 1.012-.56A1.195 1.195 0 0 1 9.075 2v3.133h4.727a1.239 1.239 0 0 1 1.184 1.421l-.861 5.557a1.228 1.228 0 0 1-1.217 1.076H5.845a2.152 2.152 0 0 1-.97-.226l-.774-.388"
      stroke={primaryColor}
      fillOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.09 5.746v6.794"
      stroke={tertiaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgThumb;
