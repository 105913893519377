import {
  HTTPValidationError,
  ValidationError,
} from 'Assistant/__generated__/client.schemas';

function isValidationError(item: unknown): item is ValidationError {
  if (typeof item !== 'object' || item === null) {
    return false;
  }

  const partial = item as { loc: unknown; msg: unknown; type: unknown };

  if (
    !Array.isArray(partial.loc) ||
    !partial.loc.every(
      (locItem: unknown) =>
        typeof locItem === 'string' || typeof locItem === 'number',
    )
  ) {
    return false;
  }

  if (typeof partial.msg !== 'string' || typeof partial.type !== 'string') {
    return false;
  }

  return true;
}

export function isHTTPValidationError(
  error?: unknown,
): error is Required<HTTPValidationError> {
  if (typeof error !== 'object' || error === null || !('detail' in error)) {
    return false;
  }

  const partial = error as { detail: unknown };
  if (!Array.isArray(partial.detail)) {
    return false;
  }

  return partial.detail.every(isValidationError);
}
