import React from 'react';
import { IconProps } from '../IconProps';

const SvgDailyBoost = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#FFF8F0" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 90.211V192h340v-47.381c-12.676-7.374-23.748-17.008-34.678-26.519-24.242-21.094-47.783-41.579-86.572-35.435-15.759 2.496-31.382 13.382-48.177 25.085C128.187 137.286 78.336 172.023 0 90.211z"
      fill="#F4C000"
    />
  </svg>
);

export default SvgDailyBoost;
