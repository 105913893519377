import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Maybe } from 'graphql/jsutils/Maybe';

export interface LineManagerEntitlementsData {
  organisationEntitlements: Maybe<{
    lineManagerFeatures: boolean;
  }>;
}
export const LINE_MANAGER_ENTITLEMENTS_QUERY = gql`
  query GetLineManagerEntitlements {
    organisationEntitlements {
      lineManagerFeatures
    }
  }
`;

export const useLineManagerEntitlements = () => {
  const { data, loading } = useQuery<LineManagerEntitlementsData>(
    LINE_MANAGER_ENTITLEMENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  return !loading && data?.organisationEntitlements?.lineManagerFeatures;
};
