import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { HeadingText, BodyText } from '../../Shared/Typography';
import { small } from '../../utils';

const Container = styled.div`
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    background: ${({ theme }) => theme.colors.background.secondary};
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const ErrorSection = styled.section`
  padding: ${rem(36)} ${rem(36)} ${rem(0)} ${rem(36)};
  text-align: center;
  max-width: ${rem(300)};

  ${small(css`
    max-width: ${rem(470)};
    padding: ${rem(52)} ${rem(36)} ${rem(0)} ${rem(36)};
  `)}
`;

const ErrorHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  padding-bottom: ${rem(16)};

  ${small(css`
    padding-bottom: ${rem(36)};
  `)}
`;

const ErrorMessage = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  role: 'message',
}))`
  padding-bottom: ${rem(12)};

  ${small(css`
    padding-bottom: ${rem(24)};
  `)}
`;

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.text.link};
  font-weight: 500;
  text-decoration: none;
`;

export const InvalidPlusOneInviteError = () => {
  const { t: translate } = useTranslation<Namespace<'plus_one'>>('plus_one');

  return (
    <Container>
      <ErrorSection>
        <ErrorHeading>
          {translate('invalid_plus_one_invite.heading')}
        </ErrorHeading>
        <ErrorMessage>
          {translate('invalid_plus_one_invite.subheading')}
        </ErrorMessage>
        <ErrorMessage>
          {Trans({
            t: translate,
            i18nKey: 'invalid_plus_one_invite.check_invite_message',
            components: {
              contact_link: <ContactLink href="mailto:support@unmind.com" />,
            },
          })}
        </ErrorMessage>
      </ErrorSection>
    </Container>
  );
};
