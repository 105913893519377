import { gql, useQuery } from '@apollo/client';
import { getUserCurrentLocation } from './__generated__/getUserCurrentLocation';

export const GET_USER_CURRENT_LOCATION = gql`
  query getUserCurrentLocation {
    user {
      selectedCurrentLocation {
        value
      }
    }
  }
`;

export const useUserCurrentLocation = () => {
  const { data, loading, error, refetch } = useQuery<getUserCurrentLocation>(
    GET_USER_CURRENT_LOCATION,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error, refetch };
};
