import intersection from 'lodash/fp/intersection';
import React from 'react';

import LoadingIndicator from '../../Shared/LoadingIndicator';
import { withAuthenticationViaGraphQL } from '../Auth';
import Permissions from '../Auth/permissions';
import { RouteConfig } from '../routes';
import RouteWithSubRoute from './RouteWithSubRoute';

interface RouteWithPermissionsProps extends RouteConfig, React.Attributes {
  authenticating: boolean;
  currentUserPermissions: Permissions[];
  permissions: Permissions[];

  renderIfUnauthorized: React.ComponentType<RouteConfig>;
}

export const RouteWithPermissions: React.FC<RouteWithPermissionsProps> = ({
  authenticating,
  currentUserPermissions,
  permissions,
  renderIfUnauthorized: Unauthorized,
  ...props
}) => {
  if (authenticating) {
    return <LoadingIndicator />;
  }

  if (intersection(currentUserPermissions, permissions).length > 0) {
    return <RouteWithSubRoute {...props} />;
  }

  return <Unauthorized {...props} />;
};

export default withAuthenticationViaGraphQL(RouteWithPermissions);
