import React from 'react';
import { IconProps } from '../IconProps';

const SvgFoundations = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={1.905}
    >
      <path d="M24.833 30.782H9.792v8.73" />
      <path d="M9.792 31.575v-8.73h19.791M9.792 22.845h17.416V14.91H9.792zM16.125 14.909v7.936M22.458 22.845v7.937M16.125 30.782v7.936M35.125 39.512v7.143" />
      <path
        d="M35.923 19.9a2.368 2.368 0 0 0 3.352.211l6.111-5.565c.472-.418.76-1.005.798-1.637a2.376 2.376 0 0 0-3.939-1.933l-6.112 5.564a2.38 2.38 0 0 0-.798 1.636c-.04.63.171 1.25.588 1.724z"
        strokeLinecap="round"
      />
      <path strokeLinecap="round" d="M35.941 19.918l5.072 5.86-5.938 5.252" />
      <path
        d="M31.216 25.813c.467-1.77 2.665-2.088 3.959-.793l5.541 5.555c1.305 1.31.988 3.52-.791 3.969l-14.25 5.555 5.541-14.286z"
        strokeLinecap="round"
      />
      <path d="M38.316 38.718h7.892v7.937H9.79v-7.937h12.667v7.937" />
    </g>
  </svg>
);

export default SvgFoundations;
