import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves6 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <g clipPath="url(#Waves6_svg__a)">
      <path fill={tertiaryColor} d="M0 0h340v192H0z" />
      <path
        d="M311.748 0c-3.843 39.236-29.723 68.548-53.169 95.103-15.761 17.85-30.422 34.455-36.55 51.991-5.913 16.918-7.118 31.574-5.433 44.906H340V0h-28.252z"
        fill={secondaryColor}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <path
        d="M39.885-33.168c106.09-48.837 264.008-50.56 238.053-.446C263.205-5.167 209.386-11.17 163.085.713c-46.301 11.882-49.051 71.906-97.167 87.323-40.69 13.038-75.034-23.847-108.661-5.366-33.628 18.481-50.623 100.437-77.157 95.833-53.349-9.256 53.696-162.833 159.785-211.67z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <clipPath id="Waves6_svg__a">
        <path fill="#fff" d="M0 0h340v192H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgWaves6;
