import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { css, styled } from 'styles';
import { small, medium } from '../../utils';

const GridOuter = styled.div`
  padding: ${({ theme }) => `0 ${rem(theme.grid.margins.mobile)}`};
  max-width: ${rem(Breakpoints.L)};
  margin: 0 auto;

  ${small(css`
    padding: ${({ theme }) => `0 ${rem(theme.grid.margins.tablet)}`};
  `)}

  ${medium(css`
    padding: ${({ theme }) => `0 ${rem(theme.grid.margins.desktop)}`};
  `)}
`;

const GridInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `0 -${rem(theme.grid.gutters.mobile / 2)}`};
  width: ${({ theme }) => `calc(100% + ${rem(theme.grid.gutters.mobile)})`};

  ${small(css`
    margin: ${({ theme }) => `0 -${rem(theme.grid.gutters.tablet / 2)}`};
    width: ${({ theme }) => `calc(100% + ${rem(theme.grid.gutters.tablet)})`};
  `)}
`;

interface StyledGridItemProps {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  fullWidth?: boolean;
  className?: string;
}

export const StyledGridItem = styled.div<StyledGridItemProps>`
  ${({ theme, sm = theme.grid.columns.mobile, fullWidth }) =>
    `padding: 0 ${rem(theme.grid.gutters.mobile / 2)}};
    max-width: ${fullWidth ? 100 : (sm / theme.grid.columns.mobile) * 100}%;
    flex-basis: ${fullWidth ? 100 : (sm / theme.grid.columns.mobile) * 100}%`};

  ${({ theme, md = theme.grid.gutters.tablet, fullWidth }) =>
    css`
      ${small(css`
        padding: 0 ${rem(theme.grid.gutters.tablet / 2)};
        max-width: ${fullWidth ? 100 : (md / theme.grid.columns.tablet) * 100}%;
        flex-basis: ${
          fullWidth ? 100 : (md / theme.grid.columns.tablet) * 100
        }%};
      `)}
    `};

  ${({ theme, lg = theme.grid.columns.desktop, fullWidth }) =>
    css`
      ${medium(css`
        max-width: ${
          fullWidth ? 100 : (lg / theme.grid.columns.desktop) * 100
        }%;
        flex-basis: ${
          fullWidth ? 100 : (lg / theme.grid.columns.desktop) * 100
        }%};
      `)}
    `};

  ${({ theme, xl = theme.grid.columns.desktop, fullWidth }) =>
    css`
      ${medium(css`
        max-width: ${
          fullWidth ? 100 : (xl / theme.grid.columns.desktop) * 100
        }%;
        flex-basis: ${
          fullWidth ? 100 : (xl / theme.grid.columns.desktop) * 100
        }%};
      `)}
    `};
`;

interface GridItemProps extends StyledGridItemProps {
  children: ReactNode;
}

export const GridItem = ({
  sm,
  md = sm,
  lg = md,
  xl = lg,
  fullWidth = false,
  children,
  className,
}: GridItemProps) => (
  <StyledGridItem
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    fullWidth={fullWidth}
    className={className}
  >
    {children}
  </StyledGridItem>
);

interface GridProps {
  children: ReactNode;
  className?: string;
}

export const Grid = ({ children, className }: GridProps) => (
  <GridOuter className={className}>
    <GridInner>{children}</GridInner>
  </GridOuter>
);
