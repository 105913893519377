import React, { ReactNode } from 'react';
import { styled, useTheme } from 'styles';
import { VictoryPie } from 'victory';

/**
 * A number is required by the library to set the svg viewbox etc
 * This can be changed without affecting the size of the chart
 * as the other values are calculated relative to this number
 */
const DEFAULT_CHART_SIZE = 100;
const STROKE_WIDTH = 1;

/**
 * This calculates the ratios of the various parts of the chart:
 *  sliceRadius: Always half of the chart, needed to reset a slice back to inactive
 *  innerRadius: Gap in the middle of the chart, thus making it a donut instead of a pie
 *  radiusIncreaseAmount: Controls how much the slice 'pops' out when active
 *  dimension: Adds space around the base chart to allow for the popped slice and the stroke width
 */
function getSizes(chartSize: number) {
  const sliceRadius = chartSize / 2;
  const innerRadius = sliceRadius - chartSize / 5;
  const radiusIncreaseAmount = chartSize / 25;
  const dimension = radiusIncreaseAmount * 2 + chartSize + STROKE_WIDTH;

  return {
    sliceRadius,
    innerRadius,
    radiusIncreaseAmount,
    dimension,
  };
}

function getInnerDiameterPercentage() {
  // any number can be used here, all values are relative to each other
  const { innerRadius, dimension } = getSizes(100);

  return ((innerRadius * 2) / dimension) * 100;
}

const {
  sliceRadius: SLICE_RADIUS,
  innerRadius: INNER_RADIUS,
  radiusIncreaseAmount: RADIUS_INCREASE_AMOUNT,
  dimension: DIMENSION,
} = getSizes(DEFAULT_CHART_SIZE);

type DonutChartProps = {
  children?: ReactNode;
  data: SliceData[];
  onSliceClick?(event: MouseEvent, sliceData: any): void;
};

export interface SliceData {
  count: number;
  fill: string;
  isActive?: boolean;
}

const Wrapper = styled.div`
  position: relative;
`;

const ChartWrapper = styled.div`
  /* prevent extra space around chart */
  line-height: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${getInnerDiameterPercentage()}%;
  height: ${getInnerDiameterPercentage()}%;

  > svg {
    display: block;
    width: 100%;
  }
`;

function toggleActiveSlice(event: MouseEvent, datum: { index: number }) {
  return [
    {
      eventKey: 'all',
      mutation: () => ({
        radius: SLICE_RADIUS,
      }),
    },
    {
      eventKey: datum.index,
      mutation: () => ({
        radius: SLICE_RADIUS + RADIUS_INCREASE_AMOUNT,
      }),
    },
  ];
}

export const DonutChart = ({
  children,
  data,
  onSliceClick,
}: DonutChartProps) => {
  const theme = useTheme();
  const events = [];

  if (onSliceClick) {
    events.push({
      target: 'data',
      eventHandlers: {
        onClick: (event: MouseEvent, datum: { index: number }) => {
          onSliceClick(event, datum);

          return toggleActiveSlice(event, datum);
        },
      },
    });
  }

  return (
    <Wrapper>
      <ChartWrapper>
        {/* @ts-ignore typing is outdated */}
        <VictoryPie
          data={data}
          // @ts-ignore // typing is outdated
          events={events}
          height={DIMENSION}
          innerRadius={INNER_RADIUS}
          labels={() => ''}
          padding={0}
          // @ts-ignore // typing is outdated
          radius={(datum: { isActive: boolean }) =>
            datum.isActive
              ? SLICE_RADIUS + RADIUS_INCREASE_AMOUNT
              : SLICE_RADIUS
          }
          style={{
            data: {
              fill: d => d.datum.fill,
              stroke: theme.colors.text.primary,
              strokeWidth: STROKE_WIDTH,
              cursor: 'pointer',
            },
          }}
          width={DIMENSION}
          y="count"
        />
      </ChartWrapper>
      {Boolean(children) && <IconWrapper>{children}</IconWrapper>}
    </Wrapper>
  );
};
