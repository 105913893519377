import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import { Formik, Form, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import SecondaryButton from 'Shared/SecondaryButton';
import { format } from 'date-fns';
import { useScheduleCompassIndex } from 'Compass/api';
import FieldError from 'Shared/Form/FieldError';
import { tracking } from 'App/Tracking';
import { COMPASS_ANONYMITY_THRESHOLD } from 'Admin/Dashboard/constants';
import { Deadline } from '../Deadline';
import InviteesSection from '../InviteesSection';
import { TeamsMessagePreview } from './TeamsMessagePreview';
import {
  ModalHeader,
  CompassSurveyTitle,
  CloseIconButton,
  CloseIcon,
  ModalContent,
  Section,
  SectionHeader,
  SectionDescription,
  ModalFooter,
  SubmitButton,
  Container,
} from './styles';

//set in core-api see here for more details https://github.com/unmind/core-api/pull/1453
export const VALUE_REPLACED_IN_CORE_API = 'to-be-replaced';

export interface CreateCompassSurveyFields {
  invitees: string[];
  date: Date;
}

interface ScheduleCompassIndexModalProps {
  closeModal(): void;
}

export const USER_FIRST_NAME_QUERY = gql`
  query userFirstName {
    user {
      firstName
    }
  }
`;

export const ScheduleCompassIndexModal = ({
  closeModal,
}: ScheduleCompassIndexModalProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'create_compass_modal',
  });
  const { data: userQueryData } = useQuery(USER_FIRST_NAME_QUERY);
  const {
    scheduleCompassIndexWithAdditionalFields: scheduleCompassIndex,
    loading: scheduleCompassIndexLoading,
    data: scheduleCompassIndexResponse,
    error: scheduleCompassIndexError,
  } = useScheduleCompassIndex();

  const today = new Date();

  const firstAvailableEndDate = new Date(today);
  firstAvailableEndDate.setDate(firstAvailableEndDate.getDate() + 1);

  const validate = (values: CreateCompassSurveyFields) => {
    const errors: FormikErrors<CreateCompassSurveyFields> = {};

    if (values.invitees.length < COMPASS_ANONYMITY_THRESHOLD) {
      errors.invitees = [translate('invitees.minimum_threshold_error')];
    }

    return errors;
  };

  const hasResponse = Boolean(scheduleCompassIndexResponse);
  const isResponseSuccessful =
    scheduleCompassIndexResponse?.scheduleCompassIndex?.success;
  const hasError = Boolean(scheduleCompassIndexError);

  const isApiErrorPresent =
    hasResponse &&
    (!isResponseSuccessful || hasError) &&
    !scheduleCompassIndexLoading;

  const handleFormSubmit = async (values: CreateCompassSurveyFields) => {
    // set date to end of day
    const clonedEndDate = structuredClone(values.date);
    clonedEndDate.setUTCHours(23, 59, 59, 999);

    const { data } = await scheduleCompassIndex({
      date: clonedEndDate,
      invitees: values.invitees,
      startDate: today.toISOString(),
    });

    const scheduleId = data?.scheduleCompassIndex?.scheduleId;

    if (scheduleId) {
      tracking.track('compass-schedule-created', {
        startDate: today.toISOString(),
        endDate: clonedEndDate.toISOString(),
        numberofInvitees: values.invitees.length,
        scheduleId,
      });
    }
    closeModal();
  };

  return (
    <ModalWithDrawerOnMobile onClose={closeModal}>
      <ModalHeader>
        <CompassSurveyTitle>{translate('title')}</CompassSurveyTitle>
        <CloseIconButton
          role="button"
          data-testid="modal-close-button"
          tabIndex={0}
          onClick={closeModal}
          aria-label={translate('close_label')}
        >
          <CloseIcon />
        </CloseIconButton>
      </ModalHeader>
      <Formik
        validate={validate}
        isInitialValid={false}
        initialValues={
          {
            invitees: [],
            date: firstAvailableEndDate,
          } as CreateCompassSurveyFields
        }
        onSubmit={handleFormSubmit}
        render={({
          values,
          setFieldValue,
          errors,
          isInitialValid,
          isValid,
        }) => (
          <Form>
            <ModalContent>
              <Section>
                <InviteesSection
                  invitees={values.invitees}
                  error={errors?.invitees?.[0] || ''}
                />
              </Section>
              <Section>
                <SectionHeader>{translate('deadline.title')}</SectionHeader>
                <SectionDescription>
                  {translate('deadline.description')}
                </SectionDescription>
                <Deadline value={values.date} onSelect={setFieldValue} />
              </Section>
              <Section isLast={true}>
                <SectionHeader>{translate('message.title')}</SectionHeader>
                <SectionDescription>
                  {translate('message.description')}
                </SectionDescription>
                <TeamsMessagePreview
                  managerFname={userQueryData?.user?.firstName || ''}
                  surveyEndDate={format(values.date, 'MMMM do')}
                />
              </Section>
            </ModalContent>
            <ModalFooter>
              {isApiErrorPresent && (
                <FieldError message={translate('api_error')} />
              )}
              <Container>
                <SecondaryButton
                  onClick={closeModal}
                  label={translate('cancel_button.label')}
                />
                <SubmitButton
                  type="submit"
                  disabled={
                    (Object.keys(errors).length > 0 || !isInitialValid) &&
                    !isValid
                  }
                  loading={scheduleCompassIndexLoading}
                  label={translate('submit_button.label')}
                />
              </Container>
            </ModalFooter>
          </Form>
        )}
      />
    </ModalWithDrawerOnMobile>
  );
};
