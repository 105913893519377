import isNil from 'lodash/isNil';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import RoutePath from '../App/RoutePath';
import {
  ServersideTrackingEvent,
  withLogEvent,
  WithLogEventProps,
} from '../App/Tracking/serverside';
import LoadingIndicator from '../Shared/LoadingIndicator';
import { HelpContent } from './HelpContent';
import HelpFolder from './HelpFolder';
import {
  ContentFolder,
  ContentItem,
  withHelpContentFolder,
  WithHelpContentFolderProps,
} from './withHelpContentFolder';

export interface HelpViewProps
  extends WithLogEventProps,
    WithHelpContentFolderProps,
    Pick<
      RouteComponentProps<
        {},
        Record<string, unknown>,
        { expandedItemId?: string }
      >,
      'history' | 'location'
    > {
  id: string;
  rootId?: string;
}

export const HelpView = ({
  error,
  loading,
  helpContent,
  history,
  location,
  logEvent,
  assetToken,
}: HelpViewProps) => {
  const { t: translate } = useTranslation('shared');

  if (loading) {
    return <LoadingIndicator />;
  }

  if (isNil(helpContent) || error) {
    return (
      <p data-testid={'error-handling'}>
        {translate('errors.messages.something_wrong')}
      </p>
    );
  }

  if (!('items' in helpContent) || helpContent.items.length === 0) {
    return (
      <Redirect
        data-testid={'help-item-redirect'}
        to={{
          pathname: `${RoutePath.Help}/${helpContent.parentId}`,
          state: { expandedItemId: helpContent.id },
        }}
      />
    );
  }

  const { title, summary, items, description } = helpContent;

  const trackServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onBackPressed = () => {
    history.goBack();
  };

  if ('body' in items[0]) {
    const { state } = location;

    return (
      <HelpContent
        data-testid={'content-list'}
        title={title}
        summary={summary}
        description={description}
        onBack={onBackPressed}
        items={items as ContentItem[]}
        expandedItemId={
          !isNil(state) && !isNil(state.expandedItemId)
            ? state.expandedItemId
            : undefined
        }
        trackServerside={trackServerside}
        assetToken={assetToken}
      />
    );
  }

  return (
    <HelpFolder
      data-testid={'help-folder'}
      title={title}
      onBack={onBackPressed}
      items={items as ContentFolder[]}
      trackServerside={trackServerside}
    />
  );
};

interface InitialHelpViewProps
  extends WithLogEventProps,
    WithHelpContentFolderProps,
    RouteComponentProps<{ id: string }> {}

export default compose<HelpViewProps, HelpViewProps>(
  mapProps<HelpViewProps, InitialHelpViewProps>(
    ({
      match: {
        params: { id },
      },
      ...rest
    }) => ({
      id,
      ...rest,
    }),
  ),
  withHelpContentFolder,
  withRouter,
  withLogEvent,
)(HelpView);
