import React, { useState } from 'react';
import { compose } from 'recompose';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { styled } from 'styles';
import { Severity } from '@sentry/browser';
import { logException } from '../../App/logging';
import Loader from '../../Shared/Loader';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import { ProgressIndicator } from '../ProgressIndicator';
import Route404 from '../../App/Router/Route404';
import RoutePath from '../../App/RoutePath';
import { useIsVirginPulseGroup } from '../VirginPulse/useIsVirginPulseGroup';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import EnterWorkEmailForm from './Forms/EnterWorkEmailForm';
import EmployeeIdForm from './Forms/EmployeeIdForm';
import NameForm from './Forms/NameForm';
import {
  Department,
  Location,
  GroupFileField,
  useSubdomainInfo,
} from './useSubdomainInfo';
import EnterCustomIDEmailForm from './Forms/EnterCustomIDEmailForm';
import EnterWorkDetailsForm from './Forms/EnterWorkDetailsForm';
import PrivacyConsentForm from './Forms/PrivacyConsentForm';
import EnterPasswordForm from './Forms/EnterPasswordForm';
import useCreateUser from './hooks/useCreateUser';
import SignUpEmailConfirmation from './SignUpEmailConfirmation';
import VirginPulsePartnerEligibilityForm from './Forms/VirginPulsePartnerEligibilityForm';
import calculateCurrentStepInFlow from './calculateCurrentSignUpStep';
import { useShowPrivacyConsentStepOnSignup } from './hooks/useShowPrivacyConsentStepOnSignup';
import { isCollectingWorkDetails } from './utils/isCollectingWorkDetails';
import { useCreateInvitedUser } from './hooks/useCreateInvitedUser';
import { useValidateInvitationToken } from './hooks/useValidateInvitationToken';

export interface SignUpProps
  extends RouteComponentProps,
    SubdomainFromUrlProps {
  skipRedirectForTest?: boolean;
}

export interface CustomIdentifier {
  fieldKey: string;
  fieldValue: string;
}

export interface UserDetails {
  email?: string;
  customIdentifiers?: CustomIdentifier[];
  firstName?: string;
  lastName?: string;
  location?: string;
  department?: string;
  password?: string;
  explicitPrivacyConsentGiven?: boolean;
  explicitEEADataStorageConsentGiven?: boolean;
  explicitHealthDataConsentGiven?: boolean;
  marketingOptIn?: boolean;
  // we pass in date of birth if a user is a virgin pulse user
  // so we can validate that they are eligible
  dateOfBirth?: string;
  isInvitedUser?: boolean;
  invitationToken?: string;
}

export enum FormType {
  CustomIdentifiers,
  CompanyEmail,
  VirginPulsePartner,
  InvitedUser,
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SIGNUP_BASE_PATH = '/signup';

interface ValidatedRouteProps extends RouteProps {
  userDetails: UserDetails;
  skipRedirectForTest?: boolean;
  isVirginPulseUser?: boolean;
  hasConfirmedVirginPulseEligibility?: boolean;
}

/**
 * ValidatedRoute checks to make sure that they have completed the first stage
 * of the sign up process. In our case, entered their email or the employee ID.
 * As we are using a routing system for each part of the SignUp form, we need to
 * protect against users who attempt to navigate to routes before completing previous
 * steps.
 */
export function ValidatedRoute(props: ValidatedRouteProps) {
  const {
    userDetails,
    skipRedirectForTest,
    isVirginPulseUser,
    hasConfirmedVirginPulseEligibility,
  } = props;

  const { email, customIdentifiers } = userDetails;

  const customIdentifiersHaveBeenCollected = Boolean(
    customIdentifiers && customIdentifiers.length > 0,
  );

  const emailHasBeenCollected = Boolean(email && email.length > 0);

  const isConfirmedVirginPulseUser = Boolean(
    isVirginPulseUser && hasConfirmedVirginPulseEligibility,
  );

  const canProceedThroughSignupFlow =
    Boolean(skipRedirectForTest) ||
    emailHasBeenCollected ||
    customIdentifiersHaveBeenCollected ||
    isConfirmedVirginPulseUser;

  if (canProceedThroughSignupFlow) {
    return <Route {...props} />;
  }

  return <Redirect to={SIGNUP_BASE_PATH} />;
}

function isUsingCustomIdentifier(formType: FormType) {
  return formType === FormType.CustomIdentifiers;
}

function isVirginPulsePartnerGroup(formType: FormType) {
  return formType === FormType.VirginPulsePartner;
}
/**
 * Calculate the total number of steps that the user must take to get to the end
 * of the sign up flow.
 */
export function calculateNumberOfSteps(
  formType: FormType,
  locations?: (Location | null)[] | null,
  departments?: (Department | null)[] | null,
  showExplicitPrivacyCheckbox?: boolean,
  isInvitedUser?: boolean,
) {
  let numberOfSteps = 4;

  // If the user is authenticating with a custom identifier, there is an extra step
  // to gather their personal email.
  if (isUsingCustomIdentifier(formType)) {
    numberOfSteps++;
  }
  // If the locations or departments have items in the array, the work details page
  // will be rendered, which adds another step to the process.
  if (isCollectingWorkDetails(locations, departments, isInvitedUser)) {
    numberOfSteps++;
  }

  // if it's a virgin pulse partner group
  // we've already collected their name, so we don't need to ask for it again
  if (isVirginPulsePartnerGroup(formType)) {
    numberOfSteps--;
  }

  // If we should show the separate privacy consent page, that adds a step
  // Remove this if statement once the feature flag `show-explicit-privacy-checkbox` is gone
  // and we have a permanent solution for all this consent stuff
  if (showExplicitPrivacyCheckbox) {
    numberOfSteps++;
  }

  return numberOfSteps;
}

const determineFormType = ({
  isValidInvitedUser,
  customIds,
  isVirginPulseGroup,
}: {
  isValidInvitedUser: boolean;
  customIds: GroupFileField[];
  isVirginPulseGroup?: boolean;
}) => {
  if (isValidInvitedUser) {
    return FormType.InvitedUser;
  }

  if (isVirginPulseGroup) {
    return FormType.VirginPulsePartner;
  }

  if (customIds.length) {
    return FormType.CustomIdentifiers;
  }

  return FormType.CompanyEmail;
};

export function SignUp({ subdomain, skipRedirectForTest }: SignUpProps) {
  const {
    error: subdomainError,
    groupFileFields: fetchedGroupFileFields,
    loading: loadingSubdomainInfo,
    groupName: fetchedGroupName,
    departments,
    locations,
    group,
    ssoProviderName,
  } = useSubdomainInfo({
    subdomain,
  });

  const [userDetails, setUserDetails] = useState<UserDetails>({});
  const [confirmedVirginPulseEligibility, setConfirmedVirginPulseEligibility] =
    useState<boolean | undefined>(undefined);
  const history = useHistory();
  const { search } = useLocation();

  const { loading: loadingVirginPulseQuery, isVirginPulseGroup } =
    useIsVirginPulseGroup();

  function updateUserDetails(details: UserDetails) {
    setUserDetails({
      ...userDetails,
      ...details,
    });
  }

  const { tokenIsValid: isValidInvitedUser, hasLovedOneInvitationCode } =
    useValidateInvitationToken((invitationToken: string) =>
      setUserDetails({ ...userDetails, invitationToken }),
    );

  const { createInvitedUser } = useCreateInvitedUser();

  const groupFileFields = !fetchedGroupFileFields ? [] : fetchedGroupFileFields;
  const groupName = fetchedGroupName || '';

  const { authType } = group ?? { authType: null };
  const isUsingSSO = authType === AuthTypeEnum.AUTH0_SSO;

  const filteredLocations = locations
    ? locations.filter((location): location is Location => location !== null)
    : null;

  const filteredDepartments = departments
    ? departments.filter(
        (department): department is Department => department !== null,
      )
    : null;

  const filteredCustomIds = groupFileFields.filter(
    (groupFileField): groupFileField is GroupFileField =>
      !!groupFileField && groupFileField.fileMappingField !== 'email',
  );

  const formType = determineFormType({
    isValidInvitedUser,
    isVirginPulseGroup,
    customIds: filteredCustomIds,
  });

  const {
    loading: loadingPrivacyConsentStep,
    showPrivacyConsentStep,
    showEEAExplicitDataStorageConsent,
  } = useShowPrivacyConsentStepOnSignup(subdomain);

  const totalSteps = calculateNumberOfSteps(
    formType,
    locations,
    departments,
    showPrivacyConsentStep,
    isValidInvitedUser,
  );

  const {
    createUser,
    error: createUserError,
    loading: createUserLoading,
  } = useCreateUser({
    subdomain,
    onCompleted: () => {
      history.push(`${SIGNUP_BASE_PATH}/confirmation${search}`);
    },
    isVirginPulseGroup,
  });

  function moveToNextPage(page: string) {
    history.push(`${SIGNUP_BASE_PATH}/${page}${search}`);
  }

  if (subdomainError) {
    logException(subdomainError, {
      level: Severity.Warning,
      tags: {
        subdomain,
      },
    });
  }

  if (
    loadingSubdomainInfo ||
    loadingVirginPulseQuery ||
    loadingPrivacyConsentStep
  ) {
    return (
      <Wrapper data-testid="signup-v2">
        <Loader />
      </Wrapper>
    );
  }

  if (isUsingSSO && !hasLovedOneInvitationCode) {
    return <Redirect to={RoutePath.LoginWithSSO} />;
  }

  const progressIndicator = (currentStep: number) => (
    <ProgressIndicator totalSteps={totalSteps} currentStep={currentStep} />
  );

  const isVirginPulseUser = Boolean(isVirginPulseGroup);

  const {
    customIdentifiersStepCount,
    emailStepCount,
    employeeIdEmailStepCount,
    detailsStepCount,
    workDetailsStepCount,
    privacyConsentStepCount,
    setPasswordStepCount,
    confirmationStepCount,
  } = calculateCurrentStepInFlow({
    usingCustomIdentifier: isUsingCustomIdentifier(formType),
    isVirginPulseGroup,
    collectingWorkDetails: isCollectingWorkDetails(
      locations,
      departments,
      isValidInvitedUser,
    ),
    showPrivacyConsentStep,
  });

  const shouldShowSSOButton = authType !== AuthTypeEnum.LEGACY_CREDENTIALS;

  return (
    <Switch>
      <Route
        path={SIGNUP_BASE_PATH}
        exact={true}
        render={() => {
          switch (formType) {
            case FormType.InvitedUser:
              return (
                <EnterCustomIDEmailForm
                  businessName={groupName}
                  progressIndicator={progressIndicator(emailStepCount)}
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('details');
                  }}
                />
              );
            case FormType.CustomIdentifiers:
              return (
                <EmployeeIdForm
                  businessName={groupName}
                  isInitialValid={
                    userDetails.customIdentifiers &&
                    userDetails.customIdentifiers.length > 0
                      ? true
                      : false
                  }
                  initialValues={
                    userDetails.customIdentifiers &&
                    userDetails.customIdentifiers.length > 0
                      ? {
                          [userDetails.customIdentifiers[0].fieldKey]:
                            userDetails.customIdentifiers[0].fieldValue,
                        }
                      : undefined
                  }
                  groupFileFields={filteredCustomIds}
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('employee-id-email');
                  }}
                  subdomain={subdomain}
                  progressIndicator={progressIndicator(
                    customIdentifiersStepCount,
                  )}
                  ssoProviderName={ssoProviderName}
                  shouldShowSSOButton={shouldShowSSOButton}
                />
              );
            case FormType.VirginPulsePartner:
              return (
                <VirginPulsePartnerEligibilityForm
                  onConfirmedEligibility={(values: UserDetails) => {
                    updateUserDetails(values);
                    setConfirmedVirginPulseEligibility(true);
                    moveToNextPage('employee-id-email');
                  }}
                  subdomain={subdomain}
                />
              );
            default:
              return (
                <EnterWorkEmailForm
                  businessName={groupName}
                  canUseVoucher={Boolean(group && group.vouchers)}
                  isInitialValid={userDetails.email ? true : false}
                  initialValues={
                    userDetails.email
                      ? { emailInput: userDetails.email }
                      : undefined
                  }
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('details');
                  }}
                  subdomain={subdomain}
                  ssoProviderName={ssoProviderName}
                  shouldShowSSOButton={shouldShowSSOButton}
                  progressIndicator={progressIndicator(emailStepCount)}
                />
              );
          }
        }}
      />

      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/employee-id-email`}
        exact={true}
        userDetails={userDetails}
        isVirginPulseUser={isVirginPulseUser}
        hasConfirmedVirginPulseEligibility={confirmedVirginPulseEligibility}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterCustomIDEmailForm
            isInitialValid={userDetails.email ? true : false}
            initialValues={
              userDetails.email ? { emailInput: userDetails.email } : undefined
            }
            businessName={groupName}
            onSubmit={(details: UserDetails) => {
              updateUserDetails(details);

              const collectingWorkDetails = isCollectingWorkDetails(
                locations,
                departments,
              );

              const virginPulseNextPage = collectingWorkDetails
                ? 'work-details'
                : showPrivacyConsentStep
                ? 'privacy-consent'
                : 'set-password';
              moveToNextPage(
                isVirginPulseUser ? virginPulseNextPage : 'details',
              );
            }}
            progressIndicator={progressIndicator(employeeIdEmailStepCount)}
          />
        )}
      />

      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/details`}
        exact={true}
        userDetails={userDetails}
        isVirginPulseUser={isVirginPulseUser}
        hasConfirmedVirginPulseEligibility={confirmedVirginPulseEligibility}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <NameForm
            isInitialValid={
              userDetails.firstName && userDetails.lastName ? true : false
            }
            initialValues={
              userDetails.firstName && userDetails.lastName
                ? {
                    firstName: userDetails.firstName || '',
                    lastName: userDetails.lastName || '',
                  }
                : undefined
            }
            businessName={groupName}
            onSubmit={(values: UserDetails) => {
              const hasWorkDetails =
                ((departments && departments.length) ||
                  (locations && locations.length)) &&
                !isValidInvitedUser;
              updateUserDetails(values);
              moveToNextPage(
                hasWorkDetails
                  ? 'work-details'
                  : showPrivacyConsentStep
                  ? 'privacy-consent'
                  : 'set-password',
              );
            }}
            progressIndicator={progressIndicator(detailsStepCount)}
          />
        )}
      />

      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/work-details`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterWorkDetailsForm
            isInitialValid={
              userDetails.location || userDetails.department ? true : false
            }
            initialValues={{
              location: userDetails.location || '',
              department: userDetails.department || '',
            }}
            locations={filteredLocations}
            departments={filteredDepartments}
            name={groupName}
            onSubmit={(values: UserDetails) => {
              updateUserDetails(values);
              moveToNextPage(
                showPrivacyConsentStep ? 'privacy-consent' : 'set-password',
              );
            }}
            progressIndicator={progressIndicator(workDetailsStepCount)}
          />
        )}
      />

      {showPrivacyConsentStep ? (
        <ValidatedRoute
          path={`${SIGNUP_BASE_PATH}/privacy-consent`}
          exact={true}
          userDetails={userDetails}
          skipRedirectForTest={skipRedirectForTest}
          render={() => (
            <PrivacyConsentForm
              initialValues={{
                explicitPrivacyConsentGiven:
                  userDetails.explicitPrivacyConsentGiven || false,
                marketingOptIn: userDetails.marketingOptIn || false,
              }}
              name={groupName}
              onSubmit={(values: UserDetails) => {
                updateUserDetails(values);
                moveToNextPage('set-password');
              }}
              progressIndicator={progressIndicator(privacyConsentStepCount)}
              showEEAExplicitDataStorageConsent={
                showEEAExplicitDataStorageConsent
              }
            />
          )}
        />
      ) : null}

      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/set-password`}
        exact={true}
        userDetails={userDetails}
        isVirginPulseUser={isVirginPulseUser}
        hasConfirmedVirginPulseEligibility={confirmedVirginPulseEligibility}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterPasswordForm
            name={groupName}
            loading={createUserLoading}
            error={createUserError}
            onSubmit={async (password: string, marketingConsent?: boolean) => {
              const { marketingOptIn: marketingOptInFromPrivacyConsentPage } =
                userDetails;

              const marketingOptIn =
                // if we're on a LATAM subdomain, the user has already seen the privacy page
                // and indicated their marketing preference
                marketingOptInFromPrivacyConsentPage !== undefined
                  ? marketingOptInFromPrivacyConsentPage
                  : // else they've instead indicated this preference on the password page
                    // so use the passed in value
                    marketingConsent;

              if (userDetails.invitationToken) {
                await createInvitedUser({
                  firstName: userDetails.firstName || '',
                  lastName: userDetails.lastName || '',
                  email: userDetails.email || '',
                  password: password || '',
                  subdomain,
                  timezone: 'Europe',
                  invitationToken: userDetails.invitationToken,
                  explicitPrivacyConsentGiven:
                    userDetails.explicitPrivacyConsentGiven || false,
                  explicitEEADataStorageConsentGiven:
                    userDetails.explicitEEADataStorageConsentGiven || false,
                  explicitHealthDataConsentGiven: true, // user can only submit if they've given health data consent
                  marketingOptIn: marketingOptIn || false,
                });
              } else {
                await createUser({
                  ...userDetails,
                  subdomain,
                  password,
                  marketingOptIn,
                  explicitHealthDataConsentGiven: true, // user can only submit if they've given health data consent
                });
              }
            }}
            subdomain={subdomain}
            progressIndicator={progressIndicator(setPasswordStepCount)}
            partner={isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null}
            showCheckboxes={!showPrivacyConsentStep}
          />
        )}
      />

      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/confirmation`}
        exact={true}
        userDetails={userDetails}
        isVirginPulseUser={isVirginPulseUser}
        hasConfirmedVirginPulseEligibility={confirmedVirginPulseEligibility}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <SignUpEmailConfirmation
            name={groupName}
            email={userDetails.email}
            progressIndicator={progressIndicator(confirmationStepCount)}
            redirectTo={
              formType === FormType.CustomIdentifiers
                ? `${SIGNUP_BASE_PATH}/employee-id-email`
                : RoutePath.SignUp
            }
          />
        )}
      />

      <Route path={`${SIGNUP_BASE_PATH}/*`} component={Route404} />
    </Switch>
  );
}

export default compose<SignUpProps, RouteComponentProps>(withSubdomainFromUrl)(
  SignUp,
);
