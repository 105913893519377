import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Highlight } from 'Series/SeriesShared';
import { useEffect, useState } from 'react';
import { GetSeriesSegmentsCompleted_seriesSegmentsCompleted_edges as seriesSegmentsCompleted } from './__generated__/GetSeriesSegmentsCompleted';
import { GetSingleSeries_singleSeries_seriesDays as singleSeriesDays } from './__generated__/GetSingleSeries';
import { GetSeriesSegments_seriesDay_segments as seriesSegments } from './__generated__/GetSeriesSegments';

const getSingleSeriesQuery = gql`
  query GetSingleSeries($slug: String!) {
    singleSeries(slug: $slug) {
      id
      title
      subtitle
      totalDays
      benefit1 {
        stringValue
      }
      benefit2 {
        stringValue
      }
      benefit3 {
        stringValue
      }
      description {
        longValue
      }
      minsPerDay {
        numericValue
      }
      seriesDays {
        title
        id # Unused but needed to avoid caching bug (see ENG-1285)
      }
      hasCompletedPreviousVersion
      isNotAnonymised
    }
  }
`;

const getSeriesSegmentsQuery = gql`
  query GetSeriesSegments($id: String!) {
    seriesDay(id: $id) {
      segments {
        id
        title
        icon {
          stringValue
        }
      }
    }
  }
`;

const getSeriesSegmentsCompletedQuery = gql`
  query GetSeriesSegmentsCompleted($id: String) {
    seriesSegmentsCompleted(by: DATE_CREATED, order: ASC, seriesDayId: $id) {
      edges {
        node {
          segmentId
        }
      }
    }
  }
`;

export const getSeriesProgressQuery = gql`
  query GetSeriesProgress($seriesId: String) {
    seriesDaysCompleted(seriesId: $seriesId) {
      daysCompleted
    }
  }
`;

export interface SeriesDay {
  completedSegments: string[];
  title: string;
  orderNo: number;
  highlights: Highlight[];
  id: string;
  segments: seriesSegments[];
}
export interface SingleSeriesWithDays {
  id: string;
  title: string;
  subtitle: string;
  totalDays: number;
  minsPerDay?: {
    numericValue: number;
  };
  benefit1: string;
  benefit2: string;
  benefit3: string;
  description: string;
  seriesDays: SeriesDay[];
  daysCompleted?: number;
  hasCompletedPreviousVersion: boolean;
  isNotAnonymised?: boolean;
}

export const useSingleSeriesWithSegments = (slug: string) => {
  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
  } = useQuery(getSingleSeriesQuery, {
    variables: { slug },
    fetchPolicy: 'cache-and-network',
  });

  const [completedSegments, setCompletedSegments] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);
  const [seriesWithSegments, setSeriesWithSegments] =
    useState<SingleSeriesWithDays | null>(null);
  const [getSegments] = useLazyQuery(getSeriesSegmentsQuery);
  const [getSegmentsCompleted] = useLazyQuery(getSeriesSegmentsCompletedQuery);
  const [getSeriesProgress] = useLazyQuery(getSeriesProgressQuery);

  useEffect(() => {
    const fetchSeriesDetails = async () => {
      if (seriesData?.singleSeries) {
        const seriesDays = seriesData.singleSeries.seriesDays;

        const seriesDaysWithSegmentsPromises = seriesDays.map(
          async (day: singleSeriesDays) => {
            const { data } = await getSegments({ variables: { id: day.id } });
            const segments = data?.seriesDay?.segments || [];

            const { data: completedSegmentsData } = await getSegmentsCompleted({
              variables: { id: day.id },
            });

            setCompletedSegments(
              completedSegmentsData?.seriesSegmentsCompleted.edges.map(
                (edge: seriesSegmentsCompleted) => edge.node?.segmentId,
              ) || [],
            );

            return {
              ...day,
              segments,
              completedSegments:
                completedSegmentsData?.seriesSegmentsCompleted.edges.map(
                  (edge: seriesSegmentsCompleted) => edge.node?.segmentId,
                ),
            };
          },
        );

        const seriesDaysWithSegments = await Promise.all(
          seriesDaysWithSegmentsPromises,
        );

        const { data } = await getSeriesProgress({
          variables: { seriesId: seriesData.singleSeries.id },
        });

        setSeriesWithSegments({
          ...seriesData.singleSeries,
          seriesDays: seriesDaysWithSegments,
          daysCompleted: data?.seriesDaysCompleted[0]?.daysCompleted,
        });

        setFetching(false);
      }
    };

    if (seriesData) {
      setFetching(true);
      fetchSeriesDetails().catch(console.error);
    }
  }, [seriesData, getSegments, getSegmentsCompleted, getSeriesProgress]);

  return {
    seriesData: seriesWithSegments,
    seriesLoading: seriesLoading || fetching,
    seriesError,
    completedSegments,
  };
};
