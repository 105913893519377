import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import BubbleLoader, { SINGLE_BUBBLE_DIAMETER } from './BubbleLoader';

/**
 * Give Bubbles dimensions to match space taken up in document flow
 */
const BubblesWrapper = styled.div<{ size: number }>`
  /* 1px bigger than BubbleLoader to avoid chopping off the bottom */
  font-size: ${props => `${rem(props.size + 1)}`};
  height: 2.5em;
  width: ${SINGLE_BUBBLE_DIAMETER * 3}em;
`;

interface LoaderProps {
  className?: string;
  color?: string;
  size?: number;
  isAudioPlayer?: boolean;
}

const Loader = ({ className, color, size = 6 }: LoaderProps) => (
  <BubblesWrapper className={className} size={size}>
    <BubbleLoader color={color} size={size} />
  </BubblesWrapper>
);

export default Loader;
