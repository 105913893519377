import RoutePath from 'App/RoutePath';
import PrimaryButton from 'Shared/PrimaryButton';
import React from 'react';

type ActionButtonProps = {
  label: string;
  path: RoutePath;
  onClick(): void;
};

export const ActionButton = ({ label, path, onClick }: ActionButtonProps) => (
  <PrimaryButton role="button" label={label} to={`${path}`} onClick={onClick} />
);
