import React from 'react';
import { IconProps } from '../IconProps';
const SvgLaptopUser = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#LaptopUser_svg__clip0_3159_961)" fill="#0D0300">
      <path d="M15.667 10h-7l-1.75 4h-2.25v1.333h8.667L15.667 10z" />
      <path d="M1.333 14A6.007 6.007 0 018 8.037l-1.955 4.63H4.667c-.737 0-1.333.597-1.333 1.333v.969c-.5-.09-.998-.198-1.496-.322l-.505-.126V14zM7.333 6.667a3 3 0 100-6 3 3 0 000 6z" />
    </g>
    <defs>
      <clipPath id="LaptopUser_svg__clip0_3159_961">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLaptopUser;
