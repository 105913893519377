import React, { useState } from 'react';
import { styled } from 'styles';
import { CategoryName, IndexScore } from '../types/types';
import {
  UserWellbeingTrackerScoresForScoreScreen_user_wellbeingTrackerScores_edges_node_categories as TrackerCategories,
  UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands,
} from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import CategoryItem from './CategoryItem';

type CategoryScoresByType = { [key in CategoryName]: IndexScore[] };
export type ScoresByType = { total: IndexScore[] } & CategoryScoresByType;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li:not(:last-child) {
    position: relative;

    :before {
      content: '';
      position: absolute;
      left: 3%;
      bottom: 0;
      height: 1px;
      width: 94%;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
    }
  }
`;

interface ExpandableCategoriesProps {
  categories: TrackerCategories[];
  scoresByType: ScoresByType;
  bands: TrackerBands[];
}

const ExpandableCategories = ({
  categories,
  scoresByType,
  bands,
}: ExpandableCategoriesProps) => {
  const [openCategoryName, setOpenCategoryName] = useState<CategoryName>();

  return (
    <List>
      {Object.values(categories).map(
        ({ name, key, score, band, shortDescription }) => {
          const categoryName = name.toLowerCase() as CategoryName;
          const isOpen = openCategoryName === categoryName;

          return (
            <CategoryItem
              key={key}
              band={band}
              name={categoryName}
              shortDescription={shortDescription ?? ''}
              categoryNameKey={key}
              score={score}
              isOpen={isOpen}
              setOpenCategoryName={setOpenCategoryName}
              scoreOverTime={scoresByType[key as CategoryName]}
              bands={bands}
            />
          );
        },
      )}
    </List>
  );
};

export default ExpandableCategories;
