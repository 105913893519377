import { useQuery } from '@apollo/client';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { ThemeInterface } from 'styles';
import { default as OuterContainer } from 'Shared/Container';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { SkeletonPulse } from 'Shared/Skeleton';
import {
  FullHeightContent,
  HiddenOnMobile,
} from '../Navigation/AuthenticatedPage';
import HeadingText, { HeadingLevels } from '../Shared/Typography/HeadingText';
import useFeatureFlag from '../flags/useFeatureFlag';
import { getUserLocale } from '../i18n/getUserLocale';
import { extraSmall, small } from '../utils';
import CategoryButton from './CategoryButton/CategoryButton';
import { filterOutCollectionsFromContent } from './Collections/collectionsHelpers';
import FeaturedContent from './FeaturedContent/FeaturedContent';
import GenericError from './GenericError/GenericError';
import HeroBannerSection from './HeroBanner/HeroBannerSection';
import NewReleases from './NewReleases/NewReleases';
import { LOCATION_HASH, Search } from './Search';
import YourCourses from './YourCourses/YourCourses';
import { getUserCourses } from './YourCourses/__generated__/getUserCourses';
import { USER_COURSES_QUERY } from './YourCourses/userCoursesQuery';
import {
  exploreContent_exploreTopLevelCategories as TopLevelCategory,
  exploreContent,
} from './__generated__/exploreContent';
import { exploreFeaturedContent } from './__generated__/exploreFeaturedContent';
import { exploreNewReleases } from './__generated__/exploreNewReleases';
import { CategoryIcons } from './categoryIcons';
import {
  FEATURED_CONTENT_QUERY,
  NEW_RELEASES_EXPLORE_CONTENT_QUERY,
  TOP_LEVEL_EXPLORE_CONTENT_QUERY,
} from './explorePageQueries';
import { Series, Tool } from './types';

const isSearchSupportedInLocale = (locale: string) => {
  const searchLocales = ['en-GB', 'en-US', 'en-AU'];

  return searchLocales.includes(locale);
};

const ExploreFullHeightContent = styled(FullHeightContent)<{
  isSearchActive: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  position: ${({ isSearchActive }) => (isSearchActive ? 'fixed' : 'static')};
  z-index: ${({ isSearchActive, theme }) =>
    isSearchActive ? theme.zIndex.modal : 0};
  padding-bottom: ${rem(54)};

  ${small(css`
    margin-top: 0;
    padding-bottom: 0;
  `)}
`;

export const Container = styled.div`
  margin-bottom: ${rem(32)};

  ${small(css`
    margin-bottom: ${rem(40)};
  `)};
`;

export const PageTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: 'left';
  width: 100%;
  margin: ${rem(48)} 0 ${rem(32)};
`;

export const SectionHeadingTitleAttrs = ({
  theme,
  level,
}: {
  theme: ThemeInterface;
  level?: HeadingLevels;
}) => ({
  level: level ?? 2,
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
  ],
  weight: theme.typography.fontWeights.medium,
});

export const SectionHeadingTitleStyle = css`
  margin-bottom: ${rem(16)};

  &:focus {
    outline: none;
  }

  ${small(css`
    line-height: 1.4;
  `)}
`;

export const SectionHeadingTitle = styled(HeadingText).attrs(
  SectionHeadingTitleAttrs,
)`
  ${SectionHeadingTitleStyle}
`;

export const SectionHeadingTitleSkeletonPlaceholder = styled(
  SkeletonPulse,
).attrs(SectionHeadingTitleAttrs)`
  user-select: none;
  color: transparent;

  border-radius: 5px;

  ${SectionHeadingTitleStyle}
`;

export const SectionTitleSkeletonPlaceholder = ({
  title,
}: {
  title: string;
}) => (
  <SectionHeadingTitleSkeletonPlaceholder>
    {title}
  </SectionHeadingTitleSkeletonPlaceholder>
);

const ExploreTopCategories = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
  ${extraSmall(css`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 0;
    margin-bottom: ${rem(40)};
  `)};
`;

export const TemporaryStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const ExplorePage = () => {
  const { t: translate } = useTranslation('explore');
  const shouldShowSearch = useFeatureFlag('should-show-search-web');
  const { hash } = useLocation();
  const userLocale = getUserLocale();

  const {
    data,
    loading,
    error,
    refetch: topLevelContentRefetch,
  } = useQuery<exploreContent>(TOP_LEVEL_EXPLORE_CONTENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: featuredContentData,
    loading: featuredContentLoading,
    error: featuredContentError,
    refetch: featuredContentRefetch,
  } = useQuery<exploreFeaturedContent>(FEATURED_CONTENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: newReleasesData,
    loading: newReleasesLoading,
    error: newReleasesError,
    refetch: newReleasesRefetch,
  } = useQuery<exploreNewReleases>(NEW_RELEASES_EXPLORE_CONTENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: yourCoursesData,
    loading: yourCoursesLoading,
    error: yourCoursesError,
    refetch: yourCoursesRefetch,
  } = useQuery<getUserCourses>(USER_COURSES_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const exploreCategories = data?.exploreTopLevelCategories;
  const heroBanner = data?.exploreFeaturedHero;

  if (loading) {
    return (
      <TemporaryStateContainer>
        <LoadingIndicator />
      </TemporaryStateContainer>
    );
  }

  const hasSectionErrored = (section: string): boolean =>
    error?.graphQLErrors
      .map(err => err?.path?.length && err?.path[0])
      .includes(section) ?? false;

  if (
    hasSectionErrored('exploreTopLevelCategories') ||
    error?.graphQLErrors.length === 0
  ) {
    return (
      <TemporaryStateContainer>
        <GenericError retry={true} onRetry={topLevelContentRefetch} />
      </TemporaryStateContainer>
    );
  }

  return (
    <ExploreFullHeightContent isSearchActive={hash === LOCATION_HASH}>
      <OuterContainer>
        {shouldShowSearch && isSearchSupportedInLocale(userLocale) ? (
          <Search />
        ) : (
          <HiddenOnMobile>
            <PageTitle>{translate('title')}</PageTitle>
          </HiddenOnMobile>
        )}
        <ExploreTopCategories>
          {(exploreCategories as TopLevelCategory[] | undefined)?.map(
            (category, i) => (
              <CategoryButton
                key={`explore-category-button-${i}`}
                category={category}
                Icon={
                  CategoryIcons[category.slug as keyof typeof CategoryIcons]
                }
              />
            ),
          )}
        </ExploreTopCategories>
        {heroBanner && (
          <HeroBannerSection
            heroBannerContent={heroBanner}
            assetToken={data?.getAssetToken?.assetToken}
            source={'explore-hero-banner'}
          />
        )}
        <FeaturedContent
          isLoading={featuredContentLoading}
          content={filterOutCollectionsFromContent(
            featuredContentData?.exploreFeaturedContent as (Series | Tool)[],
          )}
          assetToken={featuredContentData?.getAssetToken?.assetToken}
          hasErrored={featuredContentError?.graphQLErrors.length ? true : false}
          refetch={featuredContentRefetch}
        />
        <NewReleases
          isLoading={newReleasesLoading}
          content={filterOutCollectionsFromContent(
            newReleasesData?.exploreNewReleases as (Series | Tool)[],
          )}
          assetToken={newReleasesData?.getAssetToken?.assetToken}
          hasErrored={newReleasesError?.graphQLErrors.length ? true : false}
          refetch={newReleasesRefetch}
        />
        <YourCourses
          courses={yourCoursesData?.getUserCourses?.edges?.filter(
            edge => edge?.node.totalDays !== 1,
          )}
          assetToken={yourCoursesData?.getAssetToken?.assetToken}
          isLoading={yourCoursesLoading}
          hasErrored={yourCoursesError?.graphQLErrors.length ? true : false}
          refetch={yourCoursesRefetch}
        />
      </OuterContainer>
    </ExploreFullHeightContent>
  );
};

export default ExplorePage;
