import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { Video } from 'icons';

import SegmentActionButton from './SegmentActionButton';

const Icon = styled<any>(Video).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.primary,
    width: 80,
    height: 66,
  }),
)``;

const WatchButton = () => {
  const { t: translate } = useTranslation('courses');

  return (
    <SegmentActionButton text={translate('segment.watch_button.text')}>
      <Icon />
    </SegmentActionButton>
  );
};

export default WatchButton;
