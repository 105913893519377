import times from 'lodash/times';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled, ThemeInterface, useTheme } from 'styles';
import { small } from 'utils';
import BodyText from '../../../Shared/Typography/BodyText';

export const NUMBER_OF_OPTIONS = 6;

const Container = styled.div<{ before: boolean }>`
  width: 50%;
  margin-right: ${({ before }) => (before ? rem(10) : 0)};
  margin-left: ${({ before }) => (!before ? rem(10) : 0)};
`;

const Box = styled.div`
  width: 100%;
  height: ${rem(60)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${rem(4)};
  padding-left: ${rem(2)};
  padding-right: ${rem(2)};
  margin-top: ${rem(9)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${small(css`
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  `)}
`;

export const Dot = styled.div<{ marked: boolean; before: boolean }>`
  height: ${rem(20)};
  width: ${rem(20)};
  background-color: ${({ theme, marked, before }) =>
    marked
      ? before
        ? theme.colors.background.secondary
        : theme.colors.primary
      : 'none'};
  border: ${({ marked, theme }) =>
    !marked ? `${rem(1)} solid ${theme.colors.border.secondary}` : ''};
  border-radius: 50%;
  display: inline-block;
`;

const LabelsContainer = styled.div`
  display: flex;

  justify-content: space-between;
  overflow-wrap: break-word;

  ${small(css`
    margin-left: ${rem(20)};
    margin-right: ${rem(20)};
  `)}
`;

const MinLabel = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    weight: theme.typography.fontWeights.medium,
  }),
)`
  text-transform: uppercase;
  margin-top: ${rem(8)};
  text-align: left;
  width: 50%;
  padding-right: 0.5rem;
  font-size: ${({ theme }) => `${rem(theme.typography.fontSizes.fontSize8)}`};

  ${small(css`
    font-size: ${({ theme }) =>
      `${rem(theme.typography.fontSizes.fontSize12)}`};
  `)}
`;

const MaxLabel = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    weight: theme.typography.fontWeights.medium,
  }),
)`
  text-transform: uppercase;
  margin-top: ${rem(8)};
  text-align: right;
  width: 50%;
  padding-left: 0.5rem;
  font-size: ${({ theme }) => `${rem(theme.typography.fontSizes.fontSize8)}`};

  ${small(css`
    font-size: ${({ theme }) =>
      `${rem(theme.typography.fontSizes.fontSize12)}`};
  `)}
`;

export interface QuestionOutcomeProps {
  before: boolean;
  value: number | null;
  minLabel: string;
  maxLabel: string;
}

export const QuestionOutcome = (props: QuestionOutcomeProps) => {
  const theme = useTheme();
  const { t: translate } = useTranslation('courses');
  const { before, minLabel, maxLabel, value } = props;

  return (
    <Container before={before}>
      <BodyText
        sizes={[theme.typography.fontSizes.fontSize16]}
        weight={theme.typography.fontWeights.medium}
      >
        {before
          ? translate('review.question_outcome.before')
          : translate('review.question_outcome.after')}
      </BodyText>
      <Box data-testid="question-item-box">
        {times(NUMBER_OF_OPTIONS, i => (
          // times counts from zero whereas scores are 1 to 6
          <Dot key={i} marked={value === i + 1} before={before} />
        ))}
      </Box>
      <LabelsContainer>
        <MinLabel>{minLabel}</MinLabel>
        <MaxLabel>{maxLabel}</MaxLabel>
      </LabelsContainer>
    </Container>
  );
};
