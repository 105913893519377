/**
 * Calculate the current step through the sign-up flow
 */
const calculateCurrentStepInFlow = ({
  usingCustomIdentifier,
  isVirginPulseGroup,
  collectingWorkDetails,
  showPrivacyConsentStep,
}: {
  usingCustomIdentifier?: boolean;
  isVirginPulseGroup?: boolean;
  collectingWorkDetails?: boolean;
  showPrivacyConsentStep?: boolean;
}) => {
  const customIdentifierStepCountModifier = usingCustomIdentifier ? 1 : 0;
  const workDetailsStepCountModifier = collectingWorkDetails ? 1 : 0;
  const virginPulseStepCountModifier = isVirginPulseGroup ? -1 : 0;

  const customIdentifiersStepCount = 1;
  const emailStepCount = 1;
  const employeeIdEmailStepCount = 2 + virginPulseStepCountModifier;
  const detailsStepCount =
    2 + customIdentifierStepCountModifier + virginPulseStepCountModifier;
  const workDetailsStepCount =
    3 + customIdentifierStepCountModifier + virginPulseStepCountModifier;
  const privacyConsentStepCount =
    3 +
    customIdentifierStepCountModifier +
    workDetailsStepCountModifier +
    virginPulseStepCountModifier;
  const setPasswordStepCount =
    3 +
    customIdentifierStepCountModifier +
    workDetailsStepCountModifier +
    virginPulseStepCountModifier +
    (showPrivacyConsentStep ? 1 : 0);
  const confirmationStepCount =
    4 +
    customIdentifierStepCountModifier +
    workDetailsStepCountModifier +
    virginPulseStepCountModifier +
    (showPrivacyConsentStep ? 1 : 0);

  return {
    customIdentifiersStepCount,
    emailStepCount,
    employeeIdEmailStepCount,
    detailsStepCount,
    workDetailsStepCount,
    privacyConsentStepCount,
    setPasswordStepCount,
    confirmationStepCount,
  };
};

export default calculateCurrentStepInFlow;
