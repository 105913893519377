import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getRiskQuestions } from './__generated__/getRiskQuestions';

export const RISK_QUESTIONS_QUERY = gql`
  query getRiskQuestions($indexScheduleId: String!) {
    riskQuestions(indexScheduleId: $indexScheduleId) {
      id
      bottom2Percentage(decimalPlaces: 1)
      bottom2PercentageBenchmark {
        difference(decimalPlaces: 1)
      }
      title
      riskCategory
      overallCost
    }
  }
`;

export const useRiskQuestions = ({
  indexScheduleId,
  skip,
}: {
  indexScheduleId: string;
  skip: boolean;
}) =>
  useQuery<getRiskQuestions>(RISK_QUESTIONS_QUERY, {
    variables: {
      indexScheduleId,
    },
    skip,
  });
