import React from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import { Tick } from 'icons';
import { BodyText } from '../../Shared/Typography';

const ValidationItemLayout = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationItemText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))<{ isValid?: boolean }>`
  margin-left: ${rem(4)};
  ${props => (props.isValid ? `text-decoration: line-through;` : ``)}
  color: ${props =>
    props.isValid
      ? props.theme.colors.text.primary
      : props.theme.colors.text.secondary}
`;

const TickIcon = styled(Tick).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(14)};
  height: ${rem(14)};
`;

const ValidationItemCircle = styled.div<{ isValid?: boolean }>`
  width: ${rem(14)};
  height: ${rem(14)};
  border-radius: 100%;
  opacity: ${props => (props.isValid ? 1 : 0.4)};
  background-color: ${props =>
    props.isValid
      ? props.theme.colors.staticPalette.green.green6
      : props.theme.colors.border.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props =>
    props.isValid
      ? `solid 1px ${props.theme.colors.text.primary}`
      : `solid 1px ${props.theme.colors.border.primary}`};
`;

interface Props {
  label: string;
  isValid?: boolean;
  'data-testid'?: string;
}

const PasswordValidationItem = ({
  label,
  isValid,
  'data-testid': testID,
}: Props) => (
  <ValidationItemLayout data-testid={testID}>
    <ValidationItemCircle isValid={isValid}>
      <TickIcon
        data-testid="password-validation-tick"
        style={{ display: isValid ? 'block' : ' none' }}
      />
    </ValidationItemCircle>
    <ValidationItemText
      data-testid="password-validation-text"
      isValid={isValid}
    >
      {label}
    </ValidationItemText>
  </ValidationItemLayout>
);

export default PasswordValidationItem;
