import React, { useState } from 'react';
import { small } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { tracking } from 'App/Tracking';
import { CompassFAQs } from './Components/CompassFAQs';
import { CompassHeader } from './Components/CompassHeader';
import { CompassOverview } from './Components/CompassOverview';
import { ScheduleCompassIndexModal } from './Components/CompassSurveyModal/ScheduleCompassIndexModal';

const CompassPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${rem(40)};
  margin: ${rem(20)};

  ${small(css`
    margin: 0;
  `)};
`;

type CompassOnboardingPageProps = {
  showCreateCompassSurveyButton: boolean;
};

export const CompassOnboardingPage = ({
  showCreateCompassSurveyButton,
}: CompassOnboardingPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);

    if (!modalIsOpen) {
      tracking.track('compass-schedule-modal-opened', {
        method: 'survey-onboarding',
      });
    }
  };

  return (
    <CompassPage>
      <CompassHeader
        toggleModal={toggleModal}
        showGetStartedButton={showCreateCompassSurveyButton}
      />
      <CompassOverview />
      <CompassFAQs />
      {modalIsOpen && <ScheduleCompassIndexModal closeModal={toggleModal} />}
    </CompassPage>
  );
};
