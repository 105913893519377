import React from 'react';
import { IconProps } from '../IconProps';
const SvgChevronUp = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <g clipPath="url(#ChevronUp_svg__clip0_820_17322)">
      <path
        d="M15.428 11.6L8.399 4.572a.548.548 0 00-.8 0L.571 11.6"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="ChevronUp_svg__clip0_820_17322">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChevronUp;
