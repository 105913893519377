import {
  BodyText,
  Button,
  Card,
  Container,
  TextInput,
  Fieldset,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { styled } from 'styles';
import { Arrow, Close } from 'icons';
import { StylelessButton } from 'Shared/Button';

export const QuestionTextInput = styled(TextInput)`
  min-height: ${rem(167)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 12px;
`;

export const QuestionnaireContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const QuestionnaireHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: ${rem(16)};
`;

export const QuestionnaireForm = styled.div.attrs({
  role: 'form',
})`
  height: 100%;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: ${rem(24)};
`;

export const CloseButton = styled(StylelessButton).attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const CloseIcon = styled(Close).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  ['aria-hidden']: true,
}))`
  width: ${rem(14)};
  height: ${rem(14)};
`;

export const AddIcon = styled(Close).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  ['aria-hidden']: true,
}))`
  transform: rotate(45deg);
  width: ${rem(12)};
  height: ${rem(12)};
`;

export const BackButton = styled(StylelessButton).attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
`;

export const AddLanguageButton = styled(StylelessButton).attrs({
  type: 'button',
})`
  display: flex;
  padding: 10px 0px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const AddLanguageText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})``;

export const LanguageSelectionContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;
`;

export const BackIcon = styled(Arrow).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  ['aria-hidden']: true,
}))`
  width: ${rem(12)};
  height: ${rem(12)};
  transform: rotate(180deg);
  margin-right: 4px;
`;

export const BackText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})``;

export const QuestionCard = styled(Card)`
  max-width: ${rem(560)};
  margin: 72px auto 16px;
  padding: 28px;
`;

export const QuestionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;
`;

export const QuestionBodyText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize18],
})``;

export const QuestionTitleTextLabel = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.bold,
  forwardedAs: 'label',
}))``;

export const InputLegend = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  color: theme.colors.text.primary,
  align: 'center',
  forwardedAs: 'legend',
}))``;

export const QuestionCounter = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
  align: 'center',
})`
  margin-bottom: 40px;
`;

export const QuestionResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ResponseFieldset = styled(Fieldset)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > label {
    justify-content: space-between;
  }
`;

export const QuestionCTAButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  margin-left: auto;
  width: ${rem(118)};
`;

export const DisclaimerTextContainer = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize14],
  align: 'center',
})`
  align-self: stretch;
`;

export const AlertContainer = styled.div`
  margin-top: 20px;
`;
