import React from 'react';
import { useTheme } from 'styles';
import { VictoryLabel, VictoryTooltip, VictoryTooltipProps } from 'victory';

const ChartTooltip = (props: VictoryTooltipProps) => {
  const { colors, typography } = useTheme();
  const fontSize = typography.fontSizes.fontSize16;
  const lineHeight = 24;
  const paddingVertical = 12;

  return (
    <VictoryTooltip
      cornerRadius={4}
      labelComponent={
        <VictoryLabel
          lineHeight={lineHeight / fontSize}
          style={{
            fill: colors.staticPalette.white,
            fontSize,
            fontFamily: typography.bodyText.fontFamily,
          }}
        />
      }
      flyoutStyle={{
        fill: colors.background.inverse,
        stroke: colors.background.inverse,
      }}
      // @ts-ignore the type signature on this property, because it's wrong
      height={(datum: { label?: string[] }) => {
        const numberOfLines = datum.label ? datum.label.length : 0;

        return numberOfLines * lineHeight + paddingVertical * 2;
      }}
      orientation={'top'}
      pointerLength={12}
      pointerWidth={20}
      {...props}
      style={{
        fontSize,
        padding: 15,
      }}
    />
  );
};

export default ChartTooltip;
