import React from 'react';
import { IconProps } from '../IconProps';

const SvgMindfulnessTool = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#F1EBFF" d="M0 0h340v192H0z" />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      filter="url(#MindfulnessTool_svg__filter0_b_1466:10965)"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340 102.894c-23.537-32.887-60.533-36.789-93.747-40.292-20.358-2.147-39.295-4.144-52.842-12.573-21.393-13.311-30.867-30.8-36.103-50.029H340v102.894z"
        fill="#1B0074"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.739 192H0V99.375a422.72 422.72 0 0 1 39.18-1.503c64.992.498 82.86 26.197 103.782 56.29 8.588 12.352 17.69 25.444 30.777 37.838z"
      fill="#7600CC"
    />
    <defs>
      <filter
        id="MindfulnessTool_svg__filter0_b_1466:10965"
        x={153.308}
        y={-4}
        width={190.692}
        height={110.894}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={2} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1466:10965"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1466:10965"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgMindfulnessTool;
