import { useQuery } from '@apollo/client';
import {
  talkEventRequestsQuery_unmindEventRequests_edges_node as EventRequest,
  talkEventRequestsQuery,
} from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQuery';
import {
  talkEventsQuery_unmindEvents_edges_node as Event,
  talkEventsQuery,
} from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import {
  TALK_EVENTS_QUERY,
  TALK_EVENT_REQUESTS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import { userCountryStateQuery } from 'Services/User/__generated__/userCountryStateQuery';
import { USER_COUNTRY_STATE_QUERY } from 'Services/User/user.services';
import Loader from 'Shared/Loader';
import SectionError from 'Shared/SectionError/SectionError';
import { EventType } from 'Talk/components/UpcomingEvent/types';
import { useTalkUserDetails } from 'Talk/hooks';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import useFeatureFlag, { FEATURE_FLAGS } from '../../../flags/useFeatureFlag';
import { AuthTalk, OnboardingContent } from '../../components';
import { TalkHeader } from '../../components/TalkHeader';
import { UpcomingEvent } from '../../components/UpcomingEvent';
import { UpcomingSession } from '../UpcomingSession';
import { LandingPage } from './LandingPage';
import * as S from './styles';

function getDemoData(isDemo: boolean, state: any) {
  const demoEvent: Event | null = isDemo && !!state?.event ? state.event : null;

  const demoEventRequest: EventRequest | null =
    isDemo && !!state?.eventRequest ? state.eventRequest : null;

  return { demoEvent, demoEventRequest };
}

export const Talk = () => {
  /**
   * State
   */
  const { state } = useLocation();
  const [staticDate] = useState(new Date().toISOString());

  /**
   * Services
   */
  const outcomeMeasuresFlagEnabled = useFeatureFlag(
    FEATURE_FLAGS.TALK_ENABLE_OUTCOME_MEASURES,
  );
  const { data: userData } = useQuery<userCountryStateQuery>(
    USER_COUNTRY_STATE_QUERY,
  );

  const userID = userData?.user?.id;
  const { user } = useTalkUserDetails();

  const eventRequestVariables = {
    first: 10,
    after: null,
    unmindUserId: userID ?? '',
    ordering: [
      {
        sort: 'BEGINS_AT',
        direction: 'ASC',
      },
    ],
    filter: {
      where: {
        endsAt: {
          moreThan: staticDate,
        },
      },
    },
  };
  const {
    data: events,
    loading: eventsLoading,
    error: eventsError,
    refetch: refetchEvents,
  } = useQuery<talkEventsQuery>(TALK_EVENTS_QUERY, {
    variables: {
      ...eventRequestVariables,
    },
    skip: !userID,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const eventRequestQueryVariables = {
    first: 10,
    after: null,
    unmindUserId: userID ?? '',
  };
  const {
    data: eventRequests,
    loading: eventRequestsLoading,
    error: eventRequestError,
    refetch: refetchEventRequests,
  } = useQuery<talkEventRequestsQuery>(TALK_EVENT_REQUESTS_QUERY, {
    variables: { ...eventRequestQueryVariables },
    skip: !userID,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const {
    demoEvent,
    demoEventRequest,
  }: {
    demoEvent: Event | null;
    demoEventRequest: EventRequest | null;
  } = getDemoData(!!user.isDemo, state);

  const hasDemoBooking = !!demoEvent || !!demoEventRequest;
  const hasBooking = !!events?.unmindEvents?.edges?.length;
  const hasRequest = !!eventRequests?.unmindEventRequests?.edges?.length;
  const showLandingPage = !(hasBooking || hasRequest || hasDemoBooking);

  const isLoading = eventsLoading || eventRequestsLoading;
  if (isLoading) {
    return (
      <AuthTalk>
        <S.Page>
          <S.Container>
            <Loader />
          </S.Container>
        </S.Page>
      </AuthTalk>
    );
  }

  if ((eventRequestError && !eventRequests) || (eventsError && !events)) {
    return (
      <SectionError
        onRetry={() => {
          void refetchEventRequests({ ...eventRequestQueryVariables });
          void refetchEvents({ ...eventRequestVariables });
        }}
      />
    );
  }

  if (showLandingPage) {
    return (
      <AuthTalk>
        <S.Page>
          <S.Container>
            <LandingPage />
          </S.Container>
        </S.Page>
      </AuthTalk>
    );
  }

  const numberOfEvents =
    events?.unmindEvents.count ?? events?.unmindEvents?.edges?.length ?? 0;
  const bookingType =
    hasBooking || !!demoEvent ? EventType.EVENT : EventType.EVENT_REQUEST;
  const upcomingEvent = !!demoEvent
    ? demoEvent
    : events?.unmindEvents.edges?.map(edge => edge?.node)?.[0];
  const upcomingEventRequest = !!demoEventRequest
    ? demoEventRequest
    : eventRequests?.unmindEventRequests.edges?.map(edge => edge?.node)?.[0];

  return (
    <AuthTalk>
      <S.Page>
        <S.Container>
          <>
            <TalkHeader />
            {outcomeMeasuresFlagEnabled ? (
              <UpcomingSession
                numEvents={numberOfEvents}
                eventType={bookingType}
                event={upcomingEvent}
                eventRequest={upcomingEventRequest}
                userID={userID}
              />
            ) : (
              <>
                <UpcomingEvent
                  numEvents={numberOfEvents}
                  eventType={bookingType}
                  event={upcomingEvent}
                  eventRequest={upcomingEventRequest}
                />

                <OnboardingContent />
              </>
            )}
          </>
        </S.Container>
      </S.Page>
    </AuthTalk>
  );
};
