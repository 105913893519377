import { Namespace } from 'i18next';
import isUndefined from 'lodash/isUndefined';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Output, Range, RangeWrapper } from './styles';

interface Props {
  name: string;
  id: string;
  min: number;
  max: number;
  step: number;
  value: number | undefined;
  // Called on both the `onMouseUp` and `onBlur` events on the Slider.
  // This is needed because React patches over the native HTML `onchange` event, and the patch means `onChange` fires more often than desired -
  // in this case, `onChange` causes the Slider to lose focus every time the value changes
  onSliderChange(e: ChangeEvent<HTMLInputElement>): void;
  // An optional value which, on change, will cause the Slider to re-measure its tooltip placement.
  // May be useful if rendering multiple consecutive Sliders.
  remeasureTooltipPlacement?: boolean | number;
  // If true and the Slider has an undefined `value`, the Slider will display a tooltip instructing the user to interact with it.
  // Useful for Sliders which require interaction before setting any value.
  requiresInteraction?: boolean;
}

export const Slider = ({
  name,
  id,
  min,
  max,
  step,
  value,
  onSliderChange,
  remeasureTooltipPlacement,
  requiresInteraction,
}: Props) => {
  const { t } = useTranslation<Namespace<'shared'>>('shared');
  const rangeContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rangeWrapper = rangeContainer.current;
    const range =
      rangeWrapper?.querySelector<HTMLInputElement>('.range-slider');
    const outputTooltip =
      rangeWrapper?.querySelector<HTMLOutputElement>('.output');

    if (requiresInteraction && isUndefined(value) && outputTooltip) {
      outputTooltip.textContent = t('forms.slider.tooltip_interaction_label');
      outputTooltip.style.opacity = '1';
    }

    const placeTooltip = () => {
      if (range && outputTooltip) {
        const val = range.value;
        const newVal = Number(((Number(val) - min) * 100) / (max - min));

        outputTooltip.textContent = val;
        outputTooltip.style.opacity = '1';
        outputTooltip.style.left = `calc(${newVal}% + (${
          8 - newVal * 0.15
        }px))`;
      }
    };

    range?.addEventListener('input', placeTooltip);

    if (!isUndefined(value)) {
      placeTooltip();
    }

    return () => range?.removeEventListener('input', placeTooltip);
  }, [
    rangeContainer,
    requiresInteraction,
    remeasureTooltipPlacement,
    value,
    min,
    max,
    t,
  ]);

  return (
    <RangeWrapper ref={rangeContainer}>
      <Range
        className="range-slider"
        name={name}
        id={id}
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        onMouseUp={e =>
          onSliderChange(e as unknown as ChangeEvent<HTMLInputElement>)
        }
        onBlur={e =>
          onSliderChange(e as unknown as ChangeEvent<HTMLInputElement>)
        }
      />
      <Output className="output"></Output>
    </RangeWrapper>
  );
};
