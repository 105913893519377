import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled, ThemeInterface, useTheme } from 'styles';
import { Back } from 'icons';
import HelpBody from 'Help/HelpBody';
import { large } from '../../utils';
import { WrappingButton } from '../Accessibility';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const Wrapper = styled.div`
  display: flex;
  padding: ${rem('36px')} 0 ${rem('32px')} ${rem('5px')};
  border-bottom: solid ${rem('1px')}
    ${({ theme }) => theme.colors.border.secondary};
`;

// istanbul ignore next
const BackButton = styled(WrappingButton)`
  height: 100%;
  position: relative;
  top: ${rem('4px')};
  right: ${rem('13px')};
  ${large(css`
    right: ${rem('63px')};
  `)}
`;

const BackIcon = styled(Back).attrs(({ theme }: { theme: ThemeInterface }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  width: ${rem('46px')};
  height: ${rem('46px')};
`;

// istanbul ignore next
const TextWrapper = styled.div`
  position: relative;
  ${large(css`
    right: ${rem('51px')};
  `)}
`;

export interface ContentHeaderProps {
  title: string;
  summary: string;
  description?: string;
  onBackButtonClick(): void;
}

const ContentHeader = ({
  title,
  summary,
  description,
  onBackButtonClick,
}: ContentHeaderProps) => {
  const theme = useTheme();
  const { t: translate } = useTranslation('shared');

  return (
    <Wrapper>
      <BackButton
        onClick={onBackButtonClick}
        aria-label={translate('content_header.back_button.label')}
      >
        <BackIcon />
      </BackButton>
      <TextWrapper>
        <HeadingText
          level={1}
          sizes={[
            theme.typography.fontSizes.fontSize24,
            theme.typography.fontSizes.fontSize28,
            theme.typography.fontSizes.fontSize32,
            theme.typography.fontSizes.fontSize40,
          ]}
          weight={theme.typography.fontWeights.bold}
        >
          {title}
        </HeadingText>
        {!description ? (
          <BodyText sizes={[theme.typography.fontSizes.fontSize16]}>
            {summary}
          </BodyText>
        ) : (
          <HelpBody body={description} />
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default ContentHeader;
