/* eslint-disable complexity */
import React from 'react';
import { Message, OnSubmit } from 'Assistant/Chatbot/types';
import { ChatbotMessageRole } from '__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { format, formatISO, isToday } from 'date-fns';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { ChatbotStarterMessage } from '../ChatbotStarterMessage';
import { ChatbotLoadingMessage, ChatbotMessage } from '../ChatbotMessage';
import { Error } from '../Error';
import {
  ChatbotSessionDate,
  ChatbotSessionMessagesWrapper,
  ChatbotSessionWrapper,
} from './styles';

interface ChatbotSessionProps {
  isCurrentOldestLoadedSession?: boolean;
  date: string;
  messages: Message[];
  conversationId?: string;
  hasInitialMessage?: boolean;
  isDisclaimerShown?: boolean;
  loading: boolean;
  error?: boolean;
  onSubmit: OnSubmit;
  onReSubmit(): Promise<void>;
  userFirstName?: string;
}

/**
 * Component representing a chatbot session. A session is a collection of messages
 * that are grouped by date.
 *
 * @param conversationId - id of the conversation
 * @param hasInitialMessage - boolean indicating if the session has an initial message
 * @param isCurrentOldestLoadedSession - boolean indicating if the session is the oldest loaded session
 * @param date - date of the session
 * @param isDisclaimerShown - boolean indicating if the disclaimer is to been shown
 * @param onSubmit - callback function to handle submission of form
 * @param onReSubmit - callback function to handle resubmission of form
 * @param messages - array of messages to display in session
 * @param loading - boolean indicating if the session is loading
 * @param error - boolean indicating if the session has an error
 * @param userFirstName - first name of the user
 */
export const ChatbotSession = ({
  date,
  hasInitialMessage,
  isCurrentOldestLoadedSession,
  isDisclaimerShown,
  onSubmit,
  onReSubmit,
  userFirstName,
  messages,
  conversationId,
  loading,
  error,
}: ChatbotSessionProps) => {
  const { t: translate } = useTranslation('chatbot');
  const formattedDate = date ? new Date(date) : null;
  const isDisneyDemo = useFeatureFlag(FEATURE_FLAGS.DISNEY_CHATBOT);

  const hasMessages = messages.length > 0;

  const showStarterMessage =
    !hasMessages &&
    !hasInitialMessage &&
    isDisclaimerShown &&
    formattedDate &&
    isToday(formattedDate) &&
    !isDisneyDemo;

  /**
   * NOTE:
   * This ternary operator is temporary logic for demo purposes.
   * disneyHasMessages is set to be greater than 1 due to fact we
   * want to allow intro message to be permanent for demo purposes.
   */
  const showStarterMessageForDisneyDemo = !hasMessages && isDisneyDemo;

  /**
   * Add starter disclaimer message to session if meets criteria
   */
  if (
    !showStarterMessage &&
    isDisclaimerShown &&
    formattedDate &&
    isToday(formattedDate) &&
    !isDisneyDemo
  ) {
    const messagesWithDisclaimer = messages.slice();

    messagesWithDisclaimer.unshift({
      createdAt: formatISO(formattedDate),
      role: ChatbotMessageRole.assistant,
      source: translate('conversation.chatbot_starter.introduction'),
      id: 'chatbot_starter_introduction',
    });

    messages = messagesWithDisclaimer;
  }

  return hasMessages || isDisclaimerShown || isDisneyDemo ? (
    <ChatbotSessionWrapper>
      {!isCurrentOldestLoadedSession && formattedDate && (
        <ChatbotSessionDate>
          {isToday(formattedDate)
            ? translate('conversation.conversation_today_session_header')
            : format(formattedDate, 'MMMM d, yyyy')}
        </ChatbotSessionDate>
      )}

      <ChatbotSessionMessagesWrapper>
        {showStarterMessage ? (
          <ChatbotStarterMessage handleSuggestionSelect={onSubmit} />
        ) : null}

        {isDisneyDemo ? (
          <ChatbotMessage
            message={{
              id: 'chatbot-starter-introduction',
              createdAt: new Date().toISOString(),
              role: ChatbotMessageRole.assistant,
              source:
                'Hi, I’m Marcus Buckingham, your personal leadership and performance coach.',
            }}
          />
        ) : null}

        {messages.map((message, index) => (
          <ChatbotMessage
            message={message}
            conversationId={conversationId}
            key={message.id || index}
            userFirstName={userFirstName}
          />
        ))}

        {showStarterMessageForDisneyDemo ? (
          <ChatbotStarterMessage handleSuggestionSelect={onSubmit} />
        ) : null}

        {
          /** Loading state for assistant messages */
          loading ? <ChatbotLoadingMessage /> : null
        }

        {
          /** Error state for chat message mutation allows
           * re submit of previous messages.
           */
          error ? (
            <Error
              title={translate(
                'conversation.conversation_errors.submit_message_error.title',
              )}
              body={translate(
                'conversation.conversation_errors.submit_message_error.body',
              )}
              onTryAgainPress={onReSubmit}
            />
          ) : null
        }
      </ChatbotSessionMessagesWrapper>
    </ChatbotSessionWrapper>
  ) : null;
};
