import React from 'react';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import { useUserNeedsAdditionalWorkDetails } from '../Auth/userNeedsAdditionalWorkDetails/useUserNeedsAdditionalWorkDetails';
import RoutePath from '../RoutePath';

export const RedirectIfAdditionalDetailsNeeded = () => {
  const {
    userNeedsAdditionalWorkDetails,
    userNeedsExplicitPrivacyConsent,
    loading,
  } = useUserNeedsAdditionalWorkDetails();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (userNeedsAdditionalWorkDetails) {
    window.location.replace(
      `${window.location.origin}${RoutePath.AdditionalInformationNeeded}${
        userNeedsExplicitPrivacyConsent
          ? '?showprivacyconsent=true'
          : '?showprivacyconsent=false'
      }`,
    );
  } else if (userNeedsExplicitPrivacyConsent) {
    window.location.replace(
      `${window.location.origin}${RoutePath.ExplicitPrivacyConsentNeeded}`,
    );
  }

  return null;
};

export default RedirectIfAdditionalDetailsNeeded;
