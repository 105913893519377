import React from 'react';
import { IconProps } from '../IconProps';
const SvgHeartRateClipboard = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3 1.7a2.6 2.6 0 00-2.6 2.6v17.1C1.7 22.8 3 24 4.3 24h15.4c1.4 0 2.6-1.2 2.6-2.6V4.3c0-1.4-1.2-2.6-2.6-2.6z"
      fill="#CCC7BF"
    />
    <path
      d="M14.6 5.1H9.4a2.6 2.6 0 010-5.1h5.2a2.6 2.6 0 110 5.1"
      fill="#0D0300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7 13.4h-3a1 1 0 00-.8.5L14 16.6 11 10a1 1 0 00-1.9-.2L7 13.4H4.3v2.2h3.1a1 1 0 001-.5l1.5-2.5 3 6.7a1 1 0 001.9.1l2.5-3.8h2.4z"
      fill="#0D0300"
    />
  </svg>
);
export default SvgHeartRateClipboard;
