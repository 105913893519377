import { rem } from 'polished';
import React, { MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { Back } from 'icons';
import { WrappingButton } from '../Shared/Accessibility';
import { HeadingText } from '../Shared/Typography';
import Container from '../Shared/Container';
import { ContentTileProps } from './ContentTile';

const ContentHeader = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  margin: ${rem('36px')} 0;
  text-align: left;
  align-items: center;
`;

const BackButton = styled(WrappingButton)`
  cursor: pointer;
  justify-content: flex-start;
`;

// istanbul ignore next
const BackIcon = styled(Back).attrs(({ theme }: { theme: ThemeInterface }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  width: ${rem('46px')};
  height: ${rem('46px')};
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.bold,
}))<{ backButtonDisplayed: boolean }>`
  flex: 1 1 100%;
  text-align: center;
  margin: 0;
  margin-right: ${({ backButtonDisplayed }) =>
    backButtonDisplayed ? rem('46px') : 0};
`;

const ContentTileListing = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(244px, 1fr));
  grid-gap: 1rem;
  margin-bottom: ${rem(16)};
`;

export interface ContentFolderViewProps {
  children: ReactElement<ContentTileProps> | ReactElement<ContentTileProps>[];
  onBackButtonClick?(event: MouseEvent<HTMLButtonElement>): void;
  title: string;
}

export const ContentFolderView = ({
  children,
  onBackButtonClick,
  title,
}: ContentFolderViewProps) => {
  const { t: translate } = useTranslation('shared');

  return (
    <Container>
      <ContentHeader aria-live="polite">
        {onBackButtonClick ? (
          <BackButton
            onClick={onBackButtonClick}
            aria-label={translate('content_header.back_button.label')}
          >
            <BackIcon />
          </BackButton>
        ) : null}
        <Title backButtonDisplayed={!!onBackButtonClick}>{title}</Title>
      </ContentHeader>
      <ContentTileListing>{children}</ContentTileListing>
    </Container>
  );
};

export default ContentFolderView;
