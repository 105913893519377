import React from 'react';
import { IconProps } from '../IconProps';

const SvgWellbeing = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={1.818}
    >
      <path d="M41.219 47.522H14.692c-4.932 0-5.055-7.656 0-7.656H41.22c5.064 0 4.92 7.656 0 7.656zM39.306 39.866c0-5.378.589-15.311-4.737-15.311H19.88c-1.435 0-2.746.82-3.388 2.113l-3.695 7.456 4.737 5.742h21.77z" />
      <path d="M22.271 39.866l-4.737-5.742 4.737-4.784zM22.271 32.976L37.18 27.29M25.946 13.356c-.591-.438-.832-1.001-.832-1.719 0-1.322 1.272-2.392 2.842-2.392s2.843 1.07 2.843 2.392c0 .71-.275 1.273-.86 1.711" />
      <path d="M32.692 17.856c0 3.172-2.842 4.785-4.737 4.785-1.894 0-4.736-1.613-4.736-4.785 0-3.17 1.599-4.784 4.736-4.784 3.138 0 4.737 1.613 4.737 4.784zM22.271 38.91l16.952-6.028M38.377 39.866s-13.264 7.656-19.895 7.656" />
    </g>
  </svg>
);

export default SvgWellbeing;
