import { rem } from 'polished';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { styled, ThemeInterface } from 'styles';
import { Remove } from 'icons';
import getFocusState from '../Button/getFocusState';

interface IconProps extends React.ComponentProps<typeof Remove> {
  hasWhiteBackground?: boolean;
}

export const Icon = styled(({ hasWhiteBackground, ...rest }: IconProps) => (
  <Remove {...rest} />
)).attrs(
  ({
    theme,
    hasWhiteBackground,
  }: {
    theme: ThemeInterface;
    hasWhiteBackground: boolean;
  }) => ({
    primaryColor: hasWhiteBackground
      ? theme.colors.text.secondary
      : theme.colors.staticPalette.white,
  }),
)`
  width: ${rem('47px')};
  height: ${rem('47px')};
`;

const CloseButton = styled.button<{ positionButton: boolean }>`
  z-index: 10000;
  ${({ positionButton }) =>
    positionButton
      ? `
    position: fixed;
    left: ${rem('54px')};
    top: ${rem('49px')};
    `
      : ``};
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    ${getFocusState()}
  }
`;

interface DefaultCloseButtonProps {
  onClose?(): void;
  hasWhiteBackground?: boolean;
  showCloseButton?: boolean;
  accessibilityLabel?: string;
}

const DefaultCloseButton = ({
  accessibilityLabel,
  hasWhiteBackground,
  onClose,
  showCloseButton,
}: DefaultCloseButtonProps) => {
  const { t: translate } = useTranslation('shared');
  const onCloseButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (onClose) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <CloseButton
      aria-label={
        accessibilityLabel ?? translate('default_close_button.a11y_label')
      }
      onClick={onCloseButtonClick}
      positionButton={!!showCloseButton}
    >
      <Icon hasWhiteBackground={hasWhiteBackground} />
    </CloseButton>
  );
};

export default DefaultCloseButton;
