import gql from 'graphql-tag';
import { ChildDataProps, graphql } from '@apollo/client/react/hoc';
import {
  ToolCategoriesFragment,
  ToolMediaFragment,
  ToolTranscriptFragment,
} from './types';
import { ToolScreen_tool as Tool } from './__generated__/ToolScreen';

export interface ToolWithMedia
  extends Pick<
      Tool,
      | 'id'
      | 'title'
      | 'exploreCategory'
      | 'estimatedMins'
      | 'isFavourite'
      | 'html'
    >,
    ToolMediaFragment,
    ToolTranscriptFragment,
    ToolCategoriesFragment {}

interface InputProps {
  categorySlug: string;
  toolSlug: string;
}

interface Variables {
  slug: string;
}

interface Result {
  tool: ToolWithMedia;
  getAssetToken: {
    assetToken: string;
  };
}

export type WithToolScreenProps = ChildDataProps<InputProps, Result>;

export const TOOL_QUERY = gql`
  query ToolScreen($slug: String!) {
    tool(slug: $slug) {
      id
      title
      html
      mediaType {
        value
      }
      mediaSrc {
        value
      }
      mediaAsset {
        path
        width
        height
        duration
      }
      transcription {
        value
      }
      categories {
        id
        slug
        name
      }
      exploreCategory {
        id
        slug
        internalName
      }
      estimatedMins
      isFavourite
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;

export const withToolScreen = graphql<InputProps, Result, Variables>(
  TOOL_QUERY,
  {
    options: ({ categorySlug, toolSlug }) => ({
      variables: {
        slug: `${categorySlug}/${toolSlug}`,
      },
      fetchPolicy: 'no-cache',
    }),
  },
);
