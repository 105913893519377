export enum NotificationTypes {
  productUpdates = 'productUpdates',
  communityParticipation = 'communityParticipation',
  upcomingEvents = 'upcomingEvents',
}

export enum SubscriptionType {
  isTeamsEnabled = 'isTeamsEnabled',
  isEmailEnabled = 'isEmailEnabled',
}

export type NotificationPreferenceTypes = {
  title: string;
  description: string;
  subscriptions: {
    [SubscriptionType.isTeamsEnabled]: boolean;
    [SubscriptionType.isEmailEnabled]: boolean;
  };
  key: NotificationTypes;
};

export enum NotificationChannel {
  Teams = 'Teams',
  Email = 'Email',
}

export const SUBSCRIPTION_TYPE_TO_CHANNEL: Record<
  SubscriptionType,
  NotificationChannel
> = {
  [SubscriptionType.isTeamsEnabled]: NotificationChannel.Teams,
  [SubscriptionType.isEmailEnabled]: NotificationChannel.Email,
} as const;

export enum NotificationPreferenceMutationFields {
  teamsNotificationPrefs = 'teamsNotificationPrefs',
  emailNotificationPrefs = 'emailNotificationPrefs',
}

export const NOTIFICATION_CHANNEL_TO_PREFERENCE: Record<
  NotificationChannel,
  NotificationPreferenceMutationFields
> = {
  [NotificationChannel.Teams]:
    NotificationPreferenceMutationFields.teamsNotificationPrefs,
  [NotificationChannel.Email]:
    NotificationPreferenceMutationFields.emailNotificationPrefs,
} as const;

export type UpdateNotificationType = {
  [value in NotificationPreferenceMutationFields]?: {
    [key in NotificationTypes]?: boolean;
  };
};
