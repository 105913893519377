import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavTalk = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M7.7 22L1 23.5l1.7-5.1V3.1a1.7 1.7 0 0 1 1.7-1.7h17.1A1.7 1.7 0 0 1 23.1 3v17.1a1.7 1.7 0 0 1-1.7 1.7H7.7zm0-13H18M7.7 14.2h6.9"
      stroke={primaryColor}
      strokeWidth={1.7}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgNavTalk;
