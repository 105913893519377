import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import { BodyText, large } from '@unmind/design-system-components-web';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';

const StepContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${rem(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${rem(400)};
  justify-content: space-between;
  margin-top: ${rem(20)};

  ${large(css`
    margin-top: 0;
  `)}
`;

const StepInformation = styled.div`
  margin: ${rem(24)};
  max-width: ${rem(339)};
`;

const StepDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(8)};
`;

const StepTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))``;

const StepOverview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: ${rem(64)};

  ${large(css`
    flex-direction: row;
    align-items: flex-start;
    width: ${rem(1200)};
  `)}
`;

const Image = styled.img`
  max-width: ${rem(300)};
  max-height: ${rem(220)};
`;

interface StepProps {
  keyPrefix: 'overview.step_1' | 'overview.step_2' | 'overview.step_3';
  imageSrc: string;
}

const Step = ({ keyPrefix, imageSrc }: StepProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: keyPrefix,
  });

  return (
    <StepContainer>
      <StepInformation>
        <BodyText>{translate('step')}</BodyText>
        <StepTitle>{translate('title')}</StepTitle>
        <StepDescription>{translate('description')}</StepDescription>
      </StepInformation>
      <Image src={imageSrc} aria-hidden={true} alt="" />
    </StepContainer>
  );
};

export const CompassOverview = () => {
  const sendCheckinImage = 'CompassOverview/SendCheckin';
  const reviewInsightsImage = 'CompassOverview/ReviewInsights';
  const takeActionImage = 'CompassOverview/TakeAction';

  const imageSrcs = useTranslatedAssets(
    [sendCheckinImage, reviewInsightsImage, takeActionImage],
    'png',
  );

  return (
    <StepOverview>
      <Step
        keyPrefix="overview.step_1"
        imageSrc={imageSrcs[sendCheckinImage]}
      />
      <Step
        keyPrefix="overview.step_2"
        imageSrc={imageSrcs[reviewInsightsImage]}
      />
      <Step keyPrefix="overview.step_3" imageSrc={imageSrcs[takeActionImage]} />
    </StepOverview>
  );
};
