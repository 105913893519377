export enum LineManagerErrorKey {
  INVALID_EMAIL = 'INVALID_EMAIL',
  EXCEEDS_ONE_COLUMN = 'EXCEEDS_ONE_COLUMN',
  EMPTY_CSV = 'EMPTY_CSV',
}

export const INVALID_EMAIL_MSG =
  'This file contains an invalid email address. Please check that all email addresses are in joebloggs@yourcompany.com format and try again.';
export const EXCEEDS_ONE_COLUMN_MSG =
  'This file contains more than one column. Please check that all data is contained within one column.';
export const EMPTY_CSV_MSG =
  'This file appears to be empty. Please check that the file contains at least one user.';
export const DEFAULT_ERROR_MSG =
  'An unexpected error has occurred. No users were added. Please try again, or contact support if the problem persists.';

export const getLineManagerErrorMsg = (
  messageKey: LineManagerErrorKey | string,
) => {
  switch (messageKey) {
    case LineManagerErrorKey.INVALID_EMAIL:
      return INVALID_EMAIL_MSG;
    case LineManagerErrorKey.EXCEEDS_ONE_COLUMN:
      return EXCEEDS_ONE_COLUMN_MSG;
    case LineManagerErrorKey.EMPTY_CSV:
      return EMPTY_CSV_MSG;
    default:
      return DEFAULT_ERROR_MSG;
  }
};
