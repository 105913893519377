export enum UserType {
  employee = 1,
  plusOne = 2,
}

export const isEmployee = (userType?: UserType) =>
  Boolean(userType) && userType === UserType.employee;

export const isPlusOne = (userType?: UserType) =>
  Boolean(userType) && userType === UserType.plusOne;
