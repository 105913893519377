import { isApolloError } from '@apollo/client';
import gql from 'graphql-tag';
import isNil from 'lodash/isNil';
import { graphql } from '@apollo/client/react/hoc';

import { Errors } from '../Shared/Errors';

interface WithUpdateUserResponse {
  updateUser?: string;
}

interface Response {
  error?: Errors;
}

export type UpdateUserInput = {
  currentPassword?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  lastAgreedPrivacyPolicyVersion?: string;
  lastAgreedTermsVersion?: string;
  password?: string;
  praiseDirectory?: boolean;
  locationId?: string;
  departmentId?: string;
  plusOneCTADismissed?: boolean;
  createPlusOneVisited?: boolean;
  timezone?: string;
  country?: string; // user selected country
  state?: string; // user selected state
};

interface Variables {
  userInput: Partial<UpdateUserInput>;
}

export interface WithUpdateUserProps {
  updateUser(userInput: Partial<UpdateUserInput>): Promise<Response>;
}

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput)
  }
`;

export default graphql<
  Record<string, unknown>,
  WithUpdateUserResponse,
  Variables,
  WithUpdateUserProps
>(UPDATE_USER_MUTATION, {
  props: ({ mutate }) => ({
    updateUser: async userInput => {
      try {
        if (mutate) {
          const variables = {
            userInput,
          };
          const response = await mutate({ variables });

          if (response && !isNil(response.data)) {
            return {};
          }
        }
      } catch (error) {
        if (error instanceof Error && isApolloError(error)) {
          if (error.graphQLErrors.length > 0) {
            const [{ message: graphQLErrorMessage }] = error.graphQLErrors;

            return {
              error:
                Boolean(graphQLErrorMessage) &&
                graphQLErrorMessage === 'Invalid current password'
                  ? Errors.InvalidCurrentPassword
                  : Errors.ServerError,
            };
          }
        }
      }

      return {
        error: Errors.ServerError,
      };
    },
  }),
  options: {
    refetchQueries: ['withUser'],
  },
});
