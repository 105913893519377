export enum Mood {
  HAPPY = 'HAPPY',
  OK = 'OK',
  SAD = 'SAD',
  SLIGHTLY_HAPPY = 'SLIGHTLY_HAPPY',
  SLIGHTLY_SAD = 'SLIGHTLY_SAD',
  VERY_HAPPY = 'VERY_HAPPY',
  VERY_SAD = 'VERY_SAD',
}

export const Moods = {
  Saddest: Mood.VERY_SAD,
  Sadder: Mood.SAD,
  Sad: Mood.SLIGHTLY_SAD,
  Neutral: Mood.OK,
  Happy: Mood.SLIGHTLY_HAPPY,
  Happier: Mood.HAPPY,
  Happiest: Mood.VERY_HAPPY,
} as const;

export type Moods = typeof Moods[keyof typeof Moods];

export const moods = Object.values(Moods);
