import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import PrimaryButton from 'Shared/PrimaryButton';
import SecondaryButton from 'Shared/SecondaryButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';

export const CreateAccountButton = () => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <PrimaryButton
      role="button"
      data-testid={'create-an-account'}
      label={translate('getting_started.create_account_button.label')}
      to={`${RoutePath.SignUpPrivacy}`}
      onClick={() => {
        tracking.track('create-account-clicked', {
          partner: null,
        });
      }}
    />
  );
};

export const SignInButton = () => {
  const { t: translate } = useTranslation('logged_out');
  const subdomain = getSubdomainFromUrl();

  const isSearch = subdomain === 'search';

  return (
    <SecondaryButton
      role="button"
      data-testid={'sign-in'}
      label={translate('getting_started.sign_in_button.label')}
      to={isSearch ? RoutePath.SignInToYourOrganisation : RoutePath.Login}
      onClick={() => {
        tracking.track('signin-selected', {
          partner: null,
        });
      }}
    />
  );
};
