import { rem } from 'polished';
import React from 'react';
import { css, styled, ThemeInterface } from 'styles';
import { Arrow } from 'icons';
import { medium } from '@unmind/design-system-components-web';
import { buttonReset } from '../../../Shared/Button';
import BodyText from '../../../Shared/Typography/BodyText';
import { categoryContent_exploreCategory_subCategories as SubCategory } from '../../Category/__generated__/categoryContent';
import { tracking } from '../../../App/Tracking';
import { ContentType } from '../../types';

export const Card = styled.button`
  ${buttonReset}
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${rem(12)} 0;
  width: calc(100% / 2 - 12px);
  text-align: left;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};

  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
`;

const CardContentContainer = styled.div`
  padding: ${rem(16)};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;

  ${medium(css`
    padding: ${rem(32)};
  `)}
`;

const CardContentText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-right: ${rem(18)};
`;

const CardTitle = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [
      theme.typography.fontSizes.fontSize16,
      theme.typography.fontSizes.fontSize16,
      theme.typography.fontSizes.fontSize16,
      theme.typography.fontSizes.fontSize20,
    ],
    weight: theme.typography.fontWeights.medium,
    color: theme.colors.text.primary,
  }),
)`
  ${medium(css`
    padding-bottom: ${rem(4)};
  `)}
`;

const CardSummary = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [
      theme.typography.fontSizes.fontSize14,
      theme.typography.fontSizes.fontSize14,
      theme.typography.fontSizes.fontSize14,
      theme.typography.fontSizes.fontSize18,
    ],
    color: theme.colors.text.secondary,
  }),
)``;

const ArrowIcon = styled(Arrow).attrs(({ theme }) => ({
  primaryColor: theme.colors.border.primary,
  width: 16,
  height: 12,
}))``;

export interface SubCategoryCardProps {
  category: SubCategory;
  onNavigateExploreCategory(slug: string, title: string): void;
}

const SubCategoryCard = ({
  category,
  onNavigateExploreCategory,
}: SubCategoryCardProps) => {
  const { id, title, summary, slug, internalName } = category;

  const trackCategoryClicked = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: null,
      contentType: ContentType.category,
      source: 'explore-category-subcategory-button',
      contentCategoryPath: internalName,
      isFavourite: null,
    });
  };

  const handleSelect = () => {
    onNavigateExploreCategory(slug, title);
    trackCategoryClicked();
  };

  return (
    <Card onClick={handleSelect} data-testid="sub-category-card">
      <CardContentContainer aria-roledescription="text">
        <CardContentText>
          <CardTitle>{title}</CardTitle>
          <CardSummary>{summary}</CardSummary>
        </CardContentText>
        <ArrowIcon />
      </CardContentContainer>
    </Card>
  );
};

export default SubCategoryCard;
