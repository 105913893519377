import React from 'react';
import { IconProps } from '../IconProps';

const SvgOutlook = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <g clipPath="url(#Outlook_svg__clip0_102_60298)">
      <path
        d="M32 16.8a.782.782 0 0 0-.34-.66h-.005l-.013-.008-11.088-7.056a1.397 1.397 0 0 0-1.662 0l-11.09 7.056-.013.008c-.339.227-.442.706-.232 1.07a.75.75 0 0 0 .25.26l11.088 7.056a1.396 1.396 0 0 0 1.663 0l11.088-7.056A.78.78 0 0 0 32 16.8z"
        fill="#0A2767"
      />
      <path
        d="M9.095 12.089h7.277v7.17H9.095v-7.17zM30.511 4.8V1.52C30.53.7 29.925.02 29.162 0H10.28C9.516.02 8.913.7 8.93 1.52V4.8L20.093 8 30.51 4.8z"
        fill="#0364B8"
      />
      <path d="M8.93 4.8h7.442V12H8.931V4.8z" fill="#0078D4" />
      <path
        d="M23.814 4.8h-7.442V12l7.442 7.2h6.698V12l-6.698-7.2z"
        fill="#28A8EA"
      />
      <path d="M16.372 12h7.442v7.2h-7.442V12z" fill="#0078D4" />
      <path d="M16.372 19.2h7.442v7.2h-7.442v-7.2z" fill="#0364B8" />
      <path d="M9.096 19.26h7.276v6.518H9.096v-6.519z" fill="#14447D" />
      <path d="M23.814 19.2h6.697v7.2h-6.697v-7.2z" fill="#0078D4" />
      <path
        d="M31.659 17.427l-.014.008-11.089 6.703a1.456 1.456 0 0 1-.75.25l-.606-.38a1.487 1.487 0 0 1-.149-.093L7.814 17.021H7.81l-.368-.221v13.571c.006.906.693 1.635 1.536 1.629h21.511c.013 0 .024-.006.037-.006.178-.013.354-.052.521-.117.073-.033.143-.072.21-.117.05-.03.135-.097.135-.097.381-.303.607-.782.609-1.292V16.8a.73.73 0 0 1-.341.627z"
        fill="url(#Outlook_svg__paint0_linear_102_60298)"
      />
      <path
        opacity={0.5}
        d="M31.404 16.747v.831L19.81 26.16 7.806 17.026c0-.004-.003-.008-.007-.008l-1.102-.712v-.6l.454-.008.96.593.023.008.081.056s11.282 6.92 11.312 6.936l.432.271c.037-.015.074-.032.119-.047.022-.017 11.2-6.777 11.2-6.777l.126.009z"
        fill="#0A2767"
      />
      <path
        d="M31.659 17.427l-.014.008-11.089 6.704a1.457 1.457 0 0 1-1.663 0L7.806 17.435l-.013-.008a.73.73 0 0 1-.35-.627v13.571c.005.905.692 1.635 1.534 1.629h21.489c.842.006 1.53-.723 1.535-1.629V16.8c0 .26-.131.499-.341.627z"
        fill="#1490DF"
      />
      <path
        opacity={0.1}
        d="M20.718 24.04l-.166.1a1.456 1.456 0 0 1-.734.257l4.22 5.363 7.358 1.906c.202-.163.363-.379.467-.626l-11.145-7z"
        fill="#000"
      />
      <path
        opacity={0.05}
        d="M21.47 23.586l-.918.554a1.458 1.458 0 0 1-.734.257l1.977 5.858 9.604 1.41c.378-.306.6-.785.6-1.294v-.175l-10.53-6.61z"
        fill="#000"
      />
      <path
        d="M8.997 32h21.466c.33.002.652-.11.919-.32l-12.183-7.671a1.512 1.512 0 0 1-.148-.092L7.813 17.022h-.005l-.367-.222v13.525c0 .924.696 1.674 1.556 1.675z"
        fill="#28A8EA"
      />
      <path
        opacity={0.1}
        d="M17.86 7.866V24.93c-.001.599-.34 1.136-.856 1.36-.16.074-.332.112-.506.112H7.441V7.2H8.93v-.8h7.568c.752.003 1.36.658 1.362 1.466z"
        fill="#000"
      />
      <path
        opacity={0.2}
        d="M17.116 8.666V25.73c.002.194-.036.385-.112.56-.207.548-.7.907-1.25.91H7.441v-20h8.313a1.18 1.18 0 0 1 .618.168c.456.247.744.75.744 1.298z"
        fill="#000"
      />
      <path
        opacity={0.2}
        d="M17.116 8.666V24.13c-.004.808-.61 1.463-1.362 1.47H7.441V7.2h8.313a1.18 1.18 0 0 1 .618.168c.456.247.744.75.744 1.298z"
        fill="#000"
      />
      <path
        opacity={0.2}
        d="M16.372 8.666V24.13c-.001.81-.61 1.466-1.362 1.47H7.44V7.2h7.569c.752 0 1.362.657 1.362 1.466z"
        fill="#000"
      />
      <path
        d="M1.364 7.2h13.644c.753 0 1.364.656 1.364 1.466v14.668c0 .81-.61 1.466-1.364 1.466H1.364C.611 24.8 0 24.143 0 23.334V8.666C0 7.856.61 7.2 1.364 7.2z"
        fill="url(#Outlook_svg__paint1_linear_102_60298)"
      />
      <path
        d="M4.263 13.35a4.173 4.173 0 0 1 1.559-1.85 4.454 4.454 0 0 1 2.476-.667c.803-.019 1.596.2 2.291.633a4.128 4.128 0 0 1 1.516 1.768c.365.81.547 1.7.531 2.6.018.94-.17 1.871-.547 2.72a4.228 4.228 0 0 1-1.563 1.83 4.312 4.312 0 0 1-2.376.649c-.82.021-1.631-.2-2.342-.64a4.186 4.186 0 0 1-1.536-1.771 5.92 5.92 0 0 1-.538-2.57 6.583 6.583 0 0 1 .53-2.701zm1.661 4.345c.18.486.484.909.877 1.217.4.301.88.457 1.37.443a2.28 2.28 0 0 0 1.461-.457c.388-.308.684-.731.851-1.218.187-.543.279-1.12.272-1.698a5.524 5.524 0 0 0-.256-1.72c-.155-.5-.441-.94-.825-1.27a2.157 2.157 0 0 0-1.449-.48c-.499-.013-.99.143-1.4.448-.4.31-.71.736-.894 1.228a5.222 5.222 0 0 0-.006 3.508v-.002z"
        fill="#fff"
      />
      <path d="M23.814 4.8h6.697V12h-6.697V4.8z" fill="#50D9FF" />
    </g>
    <defs>
      <linearGradient
        id="Outlook_svg__paint0_linear_102_60298"
        x1={19.721}
        y1={16.8}
        x2={19.721}
        y2={32}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35B8F1" />
        <stop offset={1} stopColor="#28A8EA" />
      </linearGradient>
      <linearGradient
        id="Outlook_svg__paint1_linear_102_60298"
        x1={2.844}
        y1={6.054}
        x2={14.728}
        y2={25.201}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop offset={0.5} stopColor="#107AD5" />
        <stop offset={1} stopColor="#0A63C9" />
      </linearGradient>
      <clipPath id="Outlook_svg__clip0_102_60298">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgOutlook;
