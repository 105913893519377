import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import { logException } from 'App/logging';
import {
  CreateCompassSurveyFields,
  VALUE_REPLACED_IN_CORE_API,
} from '../Components/CompassSurveyModal/ScheduleCompassIndexModal';
import {
  scheduleCompassIndex as ScheduleCompassIndex,
  scheduleCompassIndex,
  scheduleCompassIndex_scheduleCompassIndex_userErrors as UserError,
} from './__generated__/scheduleCompassIndex';
import { LATEST_COMPASS_INDEX_QUERY } from './useLatestCompassIndex';

interface ScheduleCompassSurveyFields extends CreateCompassSurveyFields {
  startDate: string;
}

export const SCHEDULE_COMPASS_INDEX_MUTATION = gql`
  mutation scheduleCompassIndex($input: ScheduleCompassIndexInput!) {
    scheduleCompassIndex(input: $input) {
      success
      scheduleId
      userErrors {
        message
        __typename
      }
    }
  }
`;

export const useScheduleCompassIndex = (): {
  data: ScheduleCompassIndex | null | undefined;
  scheduleCompassIndexWithAdditionalFields(
    scheduleCompassIndexFields: ScheduleCompassSurveyFields,
  ): Promise<FetchResult<scheduleCompassIndex>>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [scheduleIndex, { data, loading, error }] =
    useMutation<ScheduleCompassIndex>(SCHEDULE_COMPASS_INDEX_MUTATION, {
      onCompleted: () => {
        if (data?.scheduleCompassIndex?.success) {
          return data;
        }

        data?.scheduleCompassIndex?.userErrors.forEach(
          (userError: UserError) => {
            logException(userError.message, {
              tags: {
                source: 'scheduleCompassIndex',
                scheduleId: data?.scheduleCompassIndex?.scheduleId,
              },
            });
          },
        );
      },
      refetchQueries: [
        {
          query: LATEST_COMPASS_INDEX_QUERY,
          variables: {
            ownerId: VALUE_REPLACED_IN_CORE_API,
          },
          fetchPolicy: 'network-only',
        },
      ],
    });

  const scheduleCompassIndexWithAdditionalFields = async (
    scheduleCompassIndexFields: ScheduleCompassSurveyFields,
  ) =>
    scheduleIndex({
      variables: {
        input: {
          clientId: VALUE_REPLACED_IN_CORE_API,
          cohortSize: scheduleCompassIndexFields.invitees.length,
          startDate: scheduleCompassIndexFields.startDate,
          endDate: scheduleCompassIndexFields.date.toISOString(),
          questionnaireId: VALUE_REPLACED_IN_CORE_API,
          departmentOptions: [],
          locationOptions: [],
          financialImpactCalculatorInput: {
            averageSalary: 0, // this will be replaced with default value in the core api
            currency: VALUE_REPLACED_IN_CORE_API,
            financialImpactParamsId: 1, // this will be replaced with default value in the core api
          },
          compassIndex: {
            invitedEmails: scheduleCompassIndexFields.invitees,
            ownerId: VALUE_REPLACED_IN_CORE_API,
            subdomain: VALUE_REPLACED_IN_CORE_API,
            managerFname: VALUE_REPLACED_IN_CORE_API,
            managerSignupEmail: VALUE_REPLACED_IN_CORE_API,
          },
        },
      },
    });

  return {
    data,
    scheduleCompassIndexWithAdditionalFields,
    loading,
    error,
  };
};
