import { Colors } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React from 'react';
import { defaultTheme, styled, useTheme } from 'styles';
import { IconType, IndicatorError, IndicatorSuccess } from 'icons';
import BodyText from '../Typography/BodyText';

type AlertType = 'failed' | 'success' | 'warning';

export interface AlertBoxProps {
  alertType: AlertType;
  message?: string;
}

interface IconProps {
  icon: IconType;
  color: Colors;
}

const Icon = (props: IconProps) => {
  const StyledIcon = styled(props.icon).attrs(({ theme }) => ({
    primaryColor: theme.colors.staticPalette.white,
    secondaryColor: props.color,
  }))`
    margin-top: ${rem(4)};
    min-height: ${rem(16)};
    margin-right: ${rem(16)};
    min-width: ${rem(16)};
  `;

  return <StyledIcon />;
};

const Container = styled.div<{ alertType: string }>`
  background-color: ${({ alertType, theme }) =>
    `${
      alertType === 'success'
        ? theme.colors.background.success
        : alertType === 'failed'
        ? theme.colors.background.error
        : theme.colors.background.warning
    }`};
  border: ${({ alertType, theme }) =>
    `solid ${rem(1)} ${
      alertType === 'success'
        ? theme.colors.border.success
        : alertType === 'failed'
        ? theme.colors.border.error
        : theme.colors.border.warning
    }}`};
  border-radius: ${rem(4)};
  display: flex;
  padding: ${rem(17)} ${rem(20)};
`;

const AlertBox: React.FC<AlertBoxProps> = ({
  alertType,
  message,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Container role="alert" alertType={alertType} {...props}>
      {alertType === 'success' ? (
        <Icon
          icon={IndicatorSuccess}
          color={defaultTheme.colors.border.success}
        />
      ) : alertType === 'failed' ? (
        <Icon icon={IndicatorError} color={defaultTheme.colors.border.error} />
      ) : (
        <Icon
          icon={IndicatorError}
          color={defaultTheme.colors.border.warning}
        />
      )}
      {message ? (
        <BodyText sizes={[theme.typography.fontSizes.fontSize16]}>
          {message}
        </BodyText>
      ) : null}
      {children}
    </Container>
  );
};

export default AlertBox;
