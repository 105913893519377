import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesTargetedHelp = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M5.765 24.308c-7.575-14.94.657-20.255 8.731-21.236 8.097-1.004 14.152.068 17.735 9.717 6.055 16.217-16.986 30.2-26.466 11.519z"
      fill="#FFD099"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 7.616C12.265 7.616 7.615 12.265 7.615 18S12.265 28.385 18 28.385c5.735 0 10.385-4.65 10.385-10.385 0-5.735-4.65-10.384-10.385-10.384zM6.23 18C6.23 11.5 11.5 6.231 18 6.231s11.77 5.27 11.77 11.77S24.5 29.768 18 29.768 6.23 24.5 6.23 18zM18 12.325a5.675 5.675 0 1 0 0 11.35 5.675 5.675 0 0 0 0-11.35zM10.94 18a7.06 7.06 0 1 1 14.12 0 7.06 7.06 0 0 1-14.12 0zm5.831 0a1.228 1.228 0 1 1 2.457 0 1.228 1.228 0 0 1-2.457 0zM18 15.387a2.613 2.613 0 1 0 0 5.226 2.613 2.613 0 0 0 0-5.226z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesTargetedHelp;
