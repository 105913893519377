import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { logException } from '../logging';
import { getSentAtTimestamp, getTimezoneOffset } from './serverside';
import { LOG_EVENT_MUTATION } from './serverside/withLogEvent';

export default function useActiveUserTracking() {
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);

  const trackServerSide = async (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      const clientSentAtUtcTimestamp = getSentAtTimestamp();
      const timezoneOffset = getTimezoneOffset();

      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties: {
              ...eventProperties,
              clientSentAtUtcTimestamp,
              timezoneOffset,
            },
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  const handleVisibiltyChange = () => {
    if (document.visibilityState === 'visible') {
      void trackServerSide('APP_LOGGED_IN_FOCUSED');
    }
  };

  useEffect(() => {
    void trackServerSide('APP_LOGGED_IN_FOCUSED');

    document.addEventListener('visibilitychange', handleVisibiltyChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibiltyChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
