import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavTalkActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3.5a2.6 2.6 0 0 0-2.6 2.6v15.3l-1.7 5a.9.9 0 0 0 1 1l6.8-1.6h13.6a2.6 2.6 0 0 0 2.6-2.6V3.1A2.6 2.6 0 0 0 21.4.5H4.3zm2.3 8.3c0-.6.5-1 1.1-1H18a1 1 0 1 1 0 2H7.7a1 1 0 0 1-1-1zm1.1 4.6a1 1 0 0 0 0 2.2h6.9a1 1 0 1 0 0-2.2H7.7z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgNavTalkActive;
