export interface ValidatorPayload {
  validator: string;
  message?: string;
}

interface InputValidatorCreatorOptions {
  [key: string]: any;
  message?: string;
}

type InputValidatorCreator = (
  options?: InputValidatorCreatorOptions,
) => InputValidator;

export type InputValidator = (value: string) => ValidatorPayload;

export const isNonEmptyString = (value?: string) =>
  Boolean(value) && value !== undefined ? value.trim().length > 0 : false;

export const isRequired: InputValidatorCreator = options => value => {
  const { message = 'This field is required' } = options || {};
  const isValid = isNonEmptyString(value);

  return {
    message: isValid ? undefined : message,
    validator: 'is_required',
  };
};

export const isValidEmail = (email: string) => {
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

  return new RegExp(pattern).test(email);
};

export const isEmail: InputValidatorCreator = options => value => {
  const { message = 'Invalid email address' } = options || {};

  if (value === undefined || value.length > 254) {
    return {
      message,
      validator: 'is_email',
    };
  }

  const isValid = isValidEmail(value);

  return {
    message: isValid ? undefined : message,
    validator: 'is_email',
  };
};
