import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { medium, small } from 'utils';

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(8)};
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  margin-bottom: ${rem(28)};

  ${small(css`
    max-width: 280px;
    max-height: 280px;
  `)};

  ${medium(css`
    max-width: 350px;
    max-height: 350px;
  `)};
`;

export const AvatarImage = styled.img<{
  src: string;
  alt: string;
  ['aria-label']?: string;
}>`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
`;
