import { Button } from '@unmind/design-system-components-web';
import Modal from 'Shared/Modal';
import { Namespace } from 'i18next';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Body,
  CTAContainer,
  Heading,
  ModalCTA,
  ModalCopy,
  ModalHeading,
  ModalHelpLink,
  ModalInner,
  ModalSubText,
  PromptContainer,
} from './styles';

interface Props {
  onQuestionnaireStart(): void;
}

export const PromptCard = ({ onQuestionnaireStart }: Props) => {
  const { t: translate } = useTranslation<Namespace<'talk'>>('talk');
  const modalHeaderRef = useRef<HTMLElement>(null);
  const cardHeaderRef = useRef<HTMLElement>(null);

  return (
    <PromptContainer>
      <Heading ref={cardHeaderRef}>
        {translate('outcome_measures.questionnaire_state.prompt.title')}
      </Heading>
      <Body>
        {translate('outcome_measures.questionnaire_state.prompt.body')}
      </Body>

      <CTAContainer>
        <Modal
          closeHeader
          onCloseCallback={() => {
            cardHeaderRef?.current?.focus();
          }}
          openModalButton={({ open }) => (
            <Button
              variant="secondary"
              label={translate(
                'outcome_measures.questionnaire_state.prompt.cta_button.label',
              )}
              onClick={() => {
                open();

                setTimeout(() => {
                  modalHeaderRef?.current?.focus();
                }, 300);
              }}
            />
          )}
          modalContent={({ close }) => (
            <ModalInner>
              <ModalHeading ref={modalHeaderRef}>
                {translate(
                  'outcome_measures.questionnaire.pre_session_modal.title',
                )}
              </ModalHeading>
              <ModalCopy>
                {translate(
                  'outcome_measures.questionnaire.pre_session_modal.body_1',
                )}
              </ModalCopy>
              <ModalCopy>
                {translate(
                  'outcome_measures.questionnaire.pre_session_modal.body_2',
                )}
              </ModalCopy>
              <ModalSubText>
                <Trans
                  t={translate}
                  i18nKey="outcome_measures.questionnaire.pre_session_modal.subtext"
                  defaults="For more information on the questions and how data is used please click <help_link>here</help_link>"
                  components={{
                    help_link: (
                      <ModalHelpLink
                        href={translate(
                          'outcome_measures.questionnaire.pre_session_modal.help_link',
                        )}
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </ModalSubText>
              <ModalCTA
                label={translate(
                  'outcome_measures.questionnaire.pre_session_modal.start_button.label',
                )}
                onClick={() => {
                  close();
                  onQuestionnaireStart();
                }}
              />
            </ModalInner>
          )}
        />
      </CTAContainer>
    </PromptContainer>
  );
};
