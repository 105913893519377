import { useOrganisationEntitlements } from '../../Shared/Entitlement';
import { isEmployee, isPlusOne } from '../../utils';
import { TalkUserDetails, useTalkUserDetails } from './useTalkUserDetails';

interface TalkEntitlements {
  unmindTalk: boolean;
  unmindTalkPlusOne: boolean;
}

const isUnmindTalkAvailableForUser = (
  entitlements: TalkEntitlements,
  user?: TalkUserDetails,
): boolean => {
  const isTalkEnabledForOrganisation = entitlements.unmindTalk;

  if (user && isTalkEnabledForOrganisation) {
    if (isEmployee(user.userType)) {
      return true;
    } else if (isPlusOne(user.userType)) {
      return entitlements.unmindTalkPlusOne;
    }
  } else if (user?.hasUnmindTalkUserLevelAccess) {
    // Feature flag used for circumventing entitlements for indvidiual users
    // Caveat: this will ignore entitlements - for Talk to work the organisation will still
    // need to be populated in Frankie db, regardless of entitlement status
    return true;
  }

  return false;
};

export const useUnmindTalk = () => {
  const { loading: entitlementsLoading, entitlements } =
    useOrganisationEntitlements([
      { entitlement: 'unmindTalk' },
      { entitlement: 'unmindTalkPlusOne' },
    ]);

  const { loading: userLoading, user } = useTalkUserDetails();

  const unmindTalkIsAvailableForUser = isUnmindTalkAvailableForUser(
    entitlements,
    user,
  );

  return {
    loading: entitlementsLoading || userLoading,
    unmindTalkIsAvailableForUser,
  };
};
