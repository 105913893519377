import { MultiSelectOption } from '../../../../Shared/Form/MultiSelect';
import { UserGroupFilterState } from '../../FilterState/types';

const isLocationFilter = (
  userGroupFilterState: UserGroupFilterState,
): userGroupFilterState is { locationIds: string[] } =>
  !!userGroupFilterState && 'locationIds' in userGroupFilterState;

const isDepartmentFilter = (
  userGroupFilterState: UserGroupFilterState,
): userGroupFilterState is { departmentIds: string[] } =>
  !!userGroupFilterState && 'departmentIds' in userGroupFilterState;

const exhaustiveTypesGuard = (x: never) => x;

const getValuesFromFilterState = (
  userGroupFilterState: UserGroupFilterState,
): string[] => {
  if (!userGroupFilterState) {
    return [];
  }

  if (isLocationFilter(userGroupFilterState)) {
    return userGroupFilterState.locationIds;
  }

  if (isDepartmentFilter(userGroupFilterState)) {
    return userGroupFilterState.departmentIds;
  }

  exhaustiveTypesGuard(userGroupFilterState);
  throw new Error('filter type not supported');
};

export const getSelectedFilterValues = (
  userGroupFilterState: UserGroupFilterState,
  filterOptions: MultiSelectOption<string>[],
): MultiSelectOption<string>[] => {
  const selectedValues = getValuesFromFilterState(userGroupFilterState);
  const selectedFilterOptions = filterOptions.filter(option =>
    selectedValues.find(value => value === option.value),
  );

  return selectedFilterOptions;
};
