import {
  FeatureText,
  MonoText,
  BodyText,
  Button,
} from '@unmind/design-system-components-web';
import baseButtonStyles from 'Shared/Button/BaseStyles';
import SecondaryButton from 'Shared/SecondaryButton';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  Slack as SlackIcon,
  MSTeams as MSTeamsIcon,
  Outlook as OutlookIcon,
  GoogleCalendar as GoogleCalendarIcon,
  Thumb,
} from '../../../icons';

export const Card = styled.div`
  display: grid;
  border: 1px solid ${props => props.theme.colors.border.secondary};
  border-radius: ${rem(32)};
  padding: ${rem(24)};
  grid-gap: ${rem(12)};
  background: ${props => props.theme.colors.background.card};
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${rem(12)};
`;

const LogoStyling = ` 
  width: ${rem(32)};
  height: ${rem(32)};
  margin-bottom: ${rem(8)};
`;

export const SlackLogo = styled(SlackIcon)`
  ${LogoStyling}
  flex-shrink:0;
`;

export const MSTeamsLogo = styled(MSTeamsIcon)`
  ${LogoStyling}
`;

export const OutlookLogo = styled(OutlookIcon)`
  ${LogoStyling}
`;

export const GoogleCalendarLogo = styled(GoogleCalendarIcon)`
  ${LogoStyling}
`;

export const ButtonContentContainer = styled.div.attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  display: flex;
  align-items: center;
  height: ${rem(32)};
`;

export const InDevelopmentTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
  ],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))``;

export const ThumbIcon = styled(Thumb).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.textColor,
  secondaryColor: theme.colors.background.secondary,
  tertiaryColor: theme.colors.background.secondary,
}))`
  margin-left: ${rem(4)};
`;

export const ModalContent = styled.div`
  margin: ${rem(32)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ModalTitle = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  width: 100%;
`;

export const ModalDescription = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  padding: ${rem(32)} 0;
  width: 100%;
`;

export const IntegrationName = styled(FeatureText).attrs(({ theme }) => ({
  forwardedAs: 'h2',
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))``;

export const IntegrationType = styled(MonoText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))``;

export const IntegrationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntegrationBanner = styled(MonoText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  text-align: right;
  max-height: 100px;
  overflow: scroll;
  text-wrap: balance;
`;

export const IntegrationDesc = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  max-width: ${rem(374)};
  margin-bottom: ${rem(15)};
`;

export const IntegrationLink = styled.a`
  ${baseButtonStyles}
  flex-grow: 1;
`;

export const IntegrationButton = styled(Button)`
  ${baseButtonStyles}
  flex-grow: 1;
`;

export const IntegrationButtonText = styled(BodyText)`
  color: ${({ theme }) => theme.button.primary.default.textColor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IntegrationActionsContainer = styled.div<{ isSlackUser: boolean }>`
  display: flex;
  justify-content: ${({ isSlackUser }) => (isSlackUser ? 'center' : 'start')};
  flex-flow: wrap;
  gap: ${rem(8)};
`;

export const DisconnectButton = styled(SecondaryButton)`
  border: 0;

  &:hover,
  &:active {
    background-color: transparent;
    text-decoration: underline;
  }
`;
