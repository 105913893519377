import React from 'react';
import { IconProps } from '../IconProps';
const SvgCloud = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#Cloud_svg__clip0_430_825)">
      <path
        d="M15.16 8.209a3.242 3.242 0 00-.622 0 4.646 4.646 0 00-9.076 0 3.242 3.242 0 00-.621 0 3.62 3.62 0 100 7.225h10.318A3.617 3.617 0 0019 11.822a3.62 3.62 0 00-3.84-3.613z"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Cloud_svg__clip0_430_825">
        <path fill={primaryColor} d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCloud;
