import {
  BodyText,
  ToggleSwitch,
  small,
} from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import { ThemeInterface, css } from 'styles';
import { Close } from 'icons';
import { tracking } from 'App/Tracking';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import {
  NOTIFICATION_CHANNEL_TO_PREFERENCE,
  NotificationChannel,
  NotificationPreferenceTypes,
  NotificationTypes,
  SUBSCRIPTION_TYPE_TO_CHANNEL,
  SubscriptionType,
  UpdateNotificationType,
} from '../types';

const Container = styled.div`
  padding: ${rem(28)};
  padding-bottom: ${rem(80)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${small(css`
    width: ${rem(340)};
    padding: ${rem(28)};
  `)};
`;

const ToggleText = styled.div`
  margin-right: ${rem(16)};
`;

const ModalContentTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(16)};
  display: flex;
  justify-content: space-between;
`;

const ModalContentDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  margin-bottom: ${rem(16)};
`;

const ModalToggleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    margin-bottom: ${rem(24)};
  }
`;
const CloseIcon = styled(Close).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.text.primary,
  }),
)`
  height: ${rem('12px')};
  width: ${rem('12px')};
`;

const CloseIconButton = styled.div`
  top: ${rem(20)};
  right: ${rem(20)};
  cursor: pointer;
`;

interface EditNotificationPreferenceModalProps {
  onClose(): void;
  preference?: NotificationPreferenceTypes;
  disableToggles: boolean;
  updatePreferences(preference: UpdateNotificationType): void;
  hideTeamsPreferences?: boolean;
}
export const EditNotificationPreferenceModal = ({
  onClose,
  preference,
  disableToggles,
  updatePreferences,
  hideTeamsPreferences,
}: EditNotificationPreferenceModalProps) => {
  const { t: translate } = useTranslation('account', {
    keyPrefix: 'notification_preference_centre',
  });
  const emailPreferenceCentreIsEnabled = useFeatureFlag(
    FEATURE_FLAGS.SHOW_EMAIL_NOTIFICATIONS,
  );

  if (!preference) return null;

  const trackPreferenceUpdate = (
    notificationType: NotificationTypes,
    notificationValue: string,
    notificationChannel: NotificationChannel,
  ) => {
    tracking.track('notification-preference-updated', {
      notificationType,
      notificationValue,
      notificationChannel,
    });
  };

  const subscriptionChannels = Object.keys(preference.subscriptions);

  return (
    <ModalWithDrawerOnMobile onClose={onClose}>
      <Container data-testid="edit-notification-preferences-modal">
        <ModalContentTitle>
          {preference.title}
          <CloseIconButton
            role="button"
            aria-describedby={translate('edit_modal.close_a11y_label', {
              preferenceType: preference.title,
            })}
            tabIndex={0}
            onClick={onClose}
          >
            <CloseIcon />
          </CloseIconButton>
        </ModalContentTitle>

        <ModalContentDescription>
          {preference.description}
        </ModalContentDescription>
        {subscriptionChannels.map(subscription => {
          const channel =
            SUBSCRIPTION_TYPE_TO_CHANNEL[subscription as SubscriptionType];

          if (
            !emailPreferenceCentreIsEnabled &&
            channel === NotificationChannel.Email
          )
            return null;

          if (hideTeamsPreferences && channel === NotificationChannel.Teams)
            return null;

          const isChecked =
            preference.subscriptions[subscription as SubscriptionType];

          return (
            <ModalToggleSection key={subscription}>
              <ToggleText>
                {channel === NotificationChannel.Teams &&
                  translate('edit_modal.teams_toggle_text')}
                {channel === NotificationChannel.Email &&
                  translate('edit_modal.email_toggle_text')}
              </ToggleText>
              <ToggleSwitch
                isChecked={isChecked}
                disabled={disableToggles}
                onChange={() => {
                  const notificationValue = !isChecked ? 'on' : 'off';
                  trackPreferenceUpdate(
                    preference.key,
                    notificationValue,
                    channel,
                  );
                  updatePreferences({
                    [NOTIFICATION_CHANNEL_TO_PREFERENCE[channel]]: {
                      [preference.key]: !isChecked,
                    },
                  });
                }}
                label={channel}
                aria-label={translate('edit_modal.toggle_switch_a11y_label', {
                  preferenceType: preference.title,
                })}
              />
            </ModalToggleSection>
          );
        })}
      </Container>
    </ModalWithDrawerOnMobile>
  );
};
