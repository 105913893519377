import React from 'react';
import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { BodyText, small } from '@unmind/design-system-components-web';
import { useTranslation } from 'react-i18next';
import SecondaryButton from 'Shared/SecondaryButton';
import PrimaryButton from 'Shared/PrimaryButton';

interface CompassSurveyExtendModalProps {
  closeModal(): void;
  extendSurvey(): Promise<void>;
}

const SurveyExtendModalContiner = styled.div`
  padding: ${rem(20)};
  max-width: 100%;
  ${small(css`
    max-width: ${rem(370)};
  `)};
`;

const SurveyExtendModalTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(8)};
`;

const SurveyExtendModalDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-bottom: ${rem(16)};
`;

const SurveyExtendModalButtonContiner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SurveyExtendModalSubmitButton = styled(PrimaryButton)`
  margin-left: ${rem(8)};
`;

const SurveyExtendModalCancelButton = styled(SecondaryButton)``;

export const CompassSurveyExtendModal = ({
  closeModal,
  extendSurvey,
}: CompassSurveyExtendModalProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'extend_compass_modal',
  });

  return (
    <ModalWithDrawerOnMobile onClose={closeModal}>
      <SurveyExtendModalContiner>
        <SurveyExtendModalTitle>{translate('title')}</SurveyExtendModalTitle>
        <SurveyExtendModalDescription>
          {translate('description')}
        </SurveyExtendModalDescription>
        <SurveyExtendModalButtonContiner>
          <SurveyExtendModalCancelButton
            onClick={closeModal}
            label={translate('cancel_button.label')}
          />
          <SurveyExtendModalSubmitButton
            type="submit"
            onClick={extendSurvey}
            label={translate('submit_button.label')}
          />
        </SurveyExtendModalButtonContiner>
      </SurveyExtendModalContiner>
    </ModalWithDrawerOnMobile>
  );
};
