import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { graphql, MutateProps } from '@apollo/client/react/hoc';
import { MutationFunction } from '@apollo/client';
import {
  LogToolActivityVariables,
  LogToolActivity as LogToolActivityResponse,
} from './__generated__/LogToolActivity';

export type LogToolActivity = MutationFunction<
  LogToolActivityResponse,
  LogToolActivityVariables
>;

export interface WithLogToolActivityProps
  extends Omit<
    MutateProps<LogToolActivityResponse, LogToolActivityVariables>,
    'mutate'
  > {
  logToolActivity: LogToolActivity;
}

export const LOG_TOOL_ACTIVITY_MUTATION: DocumentNode = gql`
  mutation LogToolActivity(
    $toolId: String!
    $toolActivityEvent: ToolActivityEvent
    $seconds: Int!
    $categoryId: String
    $exploreCategoryId: String
    $isDailyBoost: Boolean
    $toolSessionId: String
    $replays: Int
    $clientSentAtUtcTimestamp: String
  ) {
    logToolActivity(
      toolId: $toolId
      toolActivityEvent: $toolActivityEvent
      seconds: $seconds
      categoryId: $categoryId
      exploreCategoryId: $exploreCategoryId
      isDailyBoost: $isDailyBoost
      toolSessionId: $toolSessionId
      replays: $replays
      clientSentAtUtcTimestamp: $clientSentAtUtcTimestamp
    )
  }
`;

export const withLogToolActivity = graphql<
  Record<string, unknown>,
  LogToolActivityResponse,
  LogToolActivityVariables
>(LOG_TOOL_ACTIVITY_MUTATION, {
  name: 'logToolActivity',
});
