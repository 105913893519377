import React, { useEffect, useState } from 'react';
import { Wrapper, Mask, DrawerWrapper, DrawerContent } from './styles';

interface DrawerProps {
  isOpen: boolean;
  setIsOpen(state: boolean): void;
  side?: 'left' | 'right';
  children: React.ReactElement;
}

export function Drawer({
  isOpen,
  setIsOpen,
  side = 'right',
  children,
}: DrawerProps) {
  // This flag is needed to set visibility property at transition end
  const [isClosing, setIsClosing] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setIsClosing(true);
    }
  }, [isOpen]);

  return (
    <Wrapper
      side={side}
      isOpen={isOpen}
      isContentVisible={isOpen || isClosing}
      onTransitionEnd={() => {
        if (!isOpen) {
          setIsClosing(false);
        }
      }}
    >
      <Mask isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <DrawerWrapper side={side} isOpen={isOpen}>
        <DrawerContent isOpen={isOpen}>{children}</DrawerContent>
      </DrawerWrapper>
    </Wrapper>
  );
}
