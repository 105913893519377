import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { LinkProps } from 'react-router-dom';
import { small } from 'utils';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import { HeadingText } from '../../../Shared/Typography';
import BodyText from '../../../Shared/Typography/BodyText';

interface IncompleteStateProps {
  started: boolean;
  seriesLink: LinkProps['to'];
}

const NotCompletedContainer = styled.div`
  width: 100%;
  ${small(css`
    width: ${rem(640)};
  `)}
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const Header = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  align: 'center',
}))`
  ${small(css`
    margin-top: ${rem(20)};
    padding-left: ${rem(150)};
    padding-right: ${rem(150)};
  `)}
`;

const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
  ],
  align: 'center',
}))`
  ${small(css`
    margin-top: ${rem(30)};
    margin-bottom: ${rem(35)};
    padding-left: ${rem(105)};
    padding-right: ${rem(105)};
  `)}
`;

const SeriesButton = styled(PrimaryButton)`
  margin: auto;
  padding: ${rem(12)} ${rem(14)};
  width: ${rem(160)};
`;

const IncompleteState = ({ started, seriesLink }: IncompleteStateProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <NotCompletedContainer>
      <Header>{translate('review.incomplete_state.heading')}</Header>
      <Subtitle>{translate('review.incomplete_state.description')}</Subtitle>
      <SeriesButton
        to={seriesLink}
        label={
          started
            ? translate(
                'review.incomplete_state.cta_button.continue_variant.text',
              )
            : translate('review.incomplete_state.cta_button.begin_variant.text')
        }
        size={ButtonSize.medium}
      />
    </NotCompletedContainer>
  );
};

export default IncompleteState;
