import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import { createLinkToExternalPlayer } from 'utils/MSTeams/MSTeamsHelpers';

export enum MediaTypes {
  Audio = 'audio',
  Video = 'video',
}

export interface NavigateToPlayerProps {
  mediaSrc: string;
  title: string;
  mediaType: MediaTypes;
}

export const navigateToPlayer = ({
  mediaSrc,
  title,
  mediaType,
}: NavigateToPlayerProps) => {
  const context = getMSTeamsUserContext();

  localStorage.setItem('scrollPosition', window.scrollY.toString());

  tracking.track('teams-home-page-tool-played', {
    mediaSrc,
    tenantId: context?.user?.tenant?.id,
    MSTeamsUserId: context?.user?.id,
  });

  location.assign(createLinkToExternalPlayer(mediaSrc, mediaType, title));
};
