import { rem } from 'polished';
import { css, styled, ThemeInterface } from 'styles';
import { FeatureText, BodyText } from '@unmind/design-system-components-web';
import { small, medium } from '../../../utils';

import ArrowButton from '../../../Shared/ArrowButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)} ${rem(20)};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border: ${({ theme }) => `1px solid  ${theme.colors.border.secondary}`};
  border-radius: ${rem(32)};

  ${medium(css`
    padding: ${rem(24)} ${rem(56)};
    flex-direction: row;
    align-items: center;
  `)}
`;

export const CardContainer = styled.div`
  width: 100%;
  max-width: ${rem(1280)};
  padding: ${rem(16)} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${medium(css`
    align-items: flex-start;
    padding: ${rem(16)} ${rem(32)} ${rem(16)} 0;
  `)}
`;

export const CardButton = styled(ArrowButton)`
  width: 100%;
  max-width: ${rem(340)};

  ${medium(css`
    width: auto;
  `)}
`;

export const HeroImage = styled.img.attrs({
  alt: '',
})`
  display: none;
  max-width: 40%;
  max-height: ${rem(320)};

  ${medium(css`
    display: block;
  `)}
`;

export const HeroImageNarrow = styled.img.attrs({
  alt: '',
})`
  display: block;
  max-width: ${rem(300)};
  padding-bottom: ${rem(16)};

  ${small(css`
    max-width: ${rem(400)};
  `)}

  ${medium(css`
    display: none;
  `)}
`;

export const CardHeading = styled(FeatureText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 1,
    sizes: [theme.typography.fontSizes.fontSize32],
    lineHeight: theme.typography.lineHeights.lineHeight40,
    weight: theme.typography.fontWeights.regular,
    accessibilityAutoFocus: true,
  }),
)`
  margin: 0;
  padding-bottom: ${rem(16)};

  ${medium(css`
    padding-bottom: ${rem(24)};
  `)}
`;

export const CardDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  margin: 0;
  padding-bottom: ${rem(16)};

  ${medium(css`
    padding-bottom: ${rem(48)};
  `)}
`;
