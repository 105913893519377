import React, { createContext, useState, useEffect, useMemo } from 'react';
import {
  AVG_SALARY_STORAGE_KEY,
  CURRENCY_STORAGE_KEY,
  DEFAULT_AVG_SALARY,
  DEFAULT_AVG_SALARY_CURRENCY,
} from './Sections/utils/averageSalaryHelpers';

export const SalaryContext = createContext({
  averageSalary: DEFAULT_AVG_SALARY,
  currency: DEFAULT_AVG_SALARY_CURRENCY,
  setAverageSalaryAndCurrency: ({
    averageSalary,
    currency,
  }: {
    averageSalary: string;
    currency: string;
  }) => {
    localStorage.setItem(AVG_SALARY_STORAGE_KEY, averageSalary);
    localStorage.setItem(CURRENCY_STORAGE_KEY, currency);
  },
});

export const SalaryProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ averageSalary, currency }, setAverageSalaryAndCurrencyState] =
    useState(() => {
      const savedSalary = localStorage.getItem(AVG_SALARY_STORAGE_KEY);
      const savedCurrency = localStorage.getItem(CURRENCY_STORAGE_KEY);

      return {
        averageSalary: savedSalary ?? DEFAULT_AVG_SALARY,
        currency: savedCurrency ? savedCurrency : DEFAULT_AVG_SALARY_CURRENCY,
      };
    });

  useEffect(() => {
    localStorage.setItem(AVG_SALARY_STORAGE_KEY, averageSalary);
    localStorage.setItem(CURRENCY_STORAGE_KEY, currency);
  }, [averageSalary, currency]);

  const setAverageSalaryAndCurrency = (averageSalaryAndCurrency: {
    averageSalary: string;
    currency: string;
  }) => {
    setAverageSalaryAndCurrencyState(averageSalaryAndCurrency);
  };

  const contextValue = useMemo(
    () => ({
      averageSalary,
      currency,
      setAverageSalaryAndCurrency,
    }),
    [averageSalary, currency],
  );

  return (
    <SalaryContext.Provider value={contextValue}>
      {children}
    </SalaryContext.Provider>
  );
};
