import React from 'react';
import { getManagerReporting_organisationManagerReporting_userProgress } from '../__generated__/getManagerReporting';
import {
  Container,
  Title,
  BaseCell,
  Table,
  TableRow,
  TitleRow,
  TitleText,
} from './ManagerReporting.styled';

type LineManagerProgressTableProps = {
  userProgress: getManagerReporting_organisationManagerReporting_userProgress[];
};

export const LineManagerProgressTable = ({
  userProgress,
}: LineManagerProgressTableProps) => (
  <Container>
    <Title>Manager Course Progress</Title>
    <Table>
      <TitleRow>
        <TitleText>User</TitleText>
        <TitleText>Email</TitleText>
        <TitleText>Course</TitleText>
        <TitleText>Status</TitleText>
      </TitleRow>
      {userProgress.map(
        ({
          id,
          user: { firstName, lastName, email },
          content: { title },
          contentStatus,
          contentPercentage,
        }) => (
          <TableRow key={id}>
            <BaseCell>
              {firstName} {lastName}
            </BaseCell>
            <BaseCell>{email}</BaseCell>
            <BaseCell>{title}</BaseCell>
            <BaseCell>
              {contentStatus === 'IN_PROGRESS' ? 'In-Progress' : 'Complete'} (
              {contentPercentage}%)
            </BaseCell>
          </TableRow>
        ),
      )}
    </Table>
  </Container>
);
