import {
  BodyText,
  Tooltip,
  useClickOutside,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { Namespace } from 'i18next';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { Sparkles } from 'icons';
import { rem } from 'polished';
import { StylelessButton } from 'Shared/Button';

const TriggerButton = styled(StylelessButton)`
  display: flex;
  align-items: center;
`;

const ExplainerTrigger = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.primary,
}))``;

export const SparklesIcon = styled(Sparkles).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-right: 6px;
`;

export const ExplainerContents = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.inverse,
}))`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 12px;
  }
`;

export const ExplainerLink = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'a',
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.inverse,
}))`
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  border-style: solid;
  border-color: ${({ theme }) => `${theme.colors.text.inverse}`};
  border-width: ${({ theme }) => rem(theme.button.borderWidth)};
  border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};
  padding: ${rem(8)} ${rem(10)};

  &:hover {
    color: ${({ theme }) => theme.colors.text.inverse};
  }
`;

export function MatchingExplainer() {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    const handleKeyboardClose = (event: KeyboardEvent): void => {
      const escKeyPressed = event.key === 'Esc' || event.key === 'Escape';
      if (open && escKeyPressed) {
        setOpen(false);
      }

      return;
    };

    document.addEventListener('keydown', handleKeyboardClose);

    return () => {
      document.removeEventListener('keydown', handleKeyboardClose);
    };
  }, [open]);

  return (
    <div ref={containerRef}>
      <Tooltip
        overflowBoundaryRef={containerRef?.current ?? undefined}
        triggerElement={
          <TriggerButton onClick={() => setOpen(!open)}>
            <SparklesIcon aria-hidden="true" />
            <ExplainerTrigger>
              {t('matching.matches.explainer.header')}
            </ExplainerTrigger>
          </TriggerButton>
        }
        open={open}
        tooltipContents={
          <>
            <ExplainerContents>
              {t('matching.matches.explainer.body_1')}
            </ExplainerContents>
            <ExplainerContents>
              {t('matching.matches.explainer.body_2')}
            </ExplainerContents>
            <ExplainerContents>
              {t('matching.matches.explainer.body_3')}
            </ExplainerContents>
            <ExplainerLink
              href={t('matching.matches.explainer.link.url')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('matching.matches.explainer.link.label')}
            </ExplainerLink>
          </>
        }
        placement="bottom"
        accessibilityId="matching-explainer"
      />
    </div>
  );
}
