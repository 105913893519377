import React, { HTMLAttributes } from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import { BodyText } from '../../Shared/Typography';

const Pill = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.medium,
}))`
  max-width: 100%;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.03em;
`;

const PillWrapper = styled.div`
  border-color: ${props => props.theme.colors.border.secondary};
  border-radius: ${rem(34)};
  border-style: solid;
  border-width: 1px;
  padding: ${rem(4)} ${rem(16)} ${rem(3)} ${rem(16)};
`;

export default ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <PillWrapper {...props}>
    <Pill>{children}</Pill>
  </PillWrapper>
);
