import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { useHistory, useLocation } from 'react-router-dom';
import { SectionHeadingTitle } from '../ExplorePage';
import {
  categoryContent_exploreCategory as CategoryContent,
  categoryContent_exploreCategory_subCategories as ExploreSubCategory,
} from '../Category/__generated__/categoryContent';
import RoutePath from '../../App/RoutePath';
import { setBreadcrumbState } from '../BreadcrumbNavigation/setBreadcrumbState';
import SubCategoryList from './SubCategoryList/SubCategoryList';

export interface CategoryParamsRouteParams {
  category: string;
}

const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  flex: 1;
  align-items: center;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const SubCategoryListContainer = styled.div`
  display: flex;
  width: 100%;
`;

export interface SubCategoriesProps {
  title?: string | null;
  subCategories: (ExploreSubCategory | null)[];
  currentCategory?: CategoryContent;
}

function SubCategorySection({
  title,
  subCategories,
  currentCategory,
}: SubCategoriesProps) {
  const { t: translate } = useTranslation('explore');
  const { state } = useLocation();
  const history = useHistory();

  const onNavigateExploreCategory = (slug: string, selectedTitle: string) => {
    const newState = setBreadcrumbState({
      currentState: state,
      currentCategory: currentCategory,
      currentTitle: selectedTitle,
    });
    history.push(`${RoutePath.Explore}/${slug}`, newState);
  };

  return subCategories.length ? (
    <SectionContainer>
      <TitleContainer>
        <SectionHeadingTitle>
          {translate('category_page.content_title', { title })}
        </SectionHeadingTitle>
      </TitleContainer>
      <SubCategoryListContainer data-testid="sub-category-list">
        <SubCategoryList
          categories={subCategories as ExploreSubCategory[]}
          onNavigateExploreCategory={onNavigateExploreCategory}
        />
      </SubCategoryListContainer>
    </SectionContainer>
  ) : null;
}

export default SubCategorySection;
