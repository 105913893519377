import { getMSTeamsLocale, isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import {
  DEFAULT_FALLBACK_LOCALE,
  DEFAULT_SUPPORTED_LOCALES,
  LOCALES_IN_TESTING,
  LOCALE_MAP,
  TEST_ORGANISATION_SUBDOMAINS,
} from './defaultLocaleConfig';
import formatLocale from './formatLocale';

const mapToSupportedLocale = (locale: string) => {
  const subdomain = getSubdomainFromUrl();

  if (
    subdomain &&
    TEST_ORGANISATION_SUBDOMAINS.includes(subdomain) &&
    LOCALES_IN_TESTING.includes(locale)
  ) {
    return locale;
  }

  // The locale exactly matches a supported locale (fr-FR, en-GB, es-419, etc.)
  if (DEFAULT_SUPPORTED_LOCALES.includes(locale)) {
    return locale;
  }

  // A locale that needs to be mapped to another locale (E.g. es-MX to es-419)
  if (
    LOCALE_MAP.hasOwnProperty(locale) &&
    DEFAULT_SUPPORTED_LOCALES.includes(LOCALE_MAP[locale])
  ) {
    return LOCALE_MAP[locale];
  }

  // The locale isn't one we support - revert to default
  return DEFAULT_FALLBACK_LOCALE;
};

export const IN_APP_SELECTED_LOCALE_KEY = 'inAppSelectedLanguage';

export const getUserLocale = () => {
  const detectedLocale: string = isMSTeams()
    ? getMSTeamsLocale() ?? DEFAULT_FALLBACK_LOCALE
    : navigator.language;

  const locale =
    localStorage.getItem(IN_APP_SELECTED_LOCALE_KEY) ?? detectedLocale;

  const formattedLocale = formatLocale(locale);

  return mapToSupportedLocale(formattedLocale);
};

/**
 * Should we resolve the locale every time we need to check it or keep it as the
 * value from app initialisation?
 *
 * We don't maintain the locale from initialisation to cover the case where a
 * user may update their locale and not refresh the browser. See notion page:
 * https://tinyurl.com/47nhvwf8
 *
 * We make an exception if current organisation is a production testing
 * organisation, because we need to switch it on for testing organisations, but
 * we don't know what org we're in on app initialisation (i.e. pre-signin). So
 * we accept the mixed-language experience.
 */
export const shouldResetLocale = () => {
  const subdomain = getSubdomainFromUrl();

  if (subdomain && TEST_ORGANISATION_SUBDOMAINS.includes(subdomain)) {
    return true;
  }

  return false;
};
