import { BodyText } from '@unmind/design-system-components-web';
import { SingleSelect as Dropdown } from 'Shared/Form/SingleSelect';
import { SingleSelectOption } from '@unmind/design-system-components-web/dist/components/Form/SingleSelect/SingleSelect';
import { ThemeInterface } from '@unmind/design-system-theme';
import { Close } from 'icons';
import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'Shared/PrimaryButton';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(380)};
  border-radius: ${rem(20)}; ;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem(4)};
`;

const CloseIconButton = styled.div`
  cursor: pointer;
  align-self: center;
  width: ${rem(16)};
  height: ${rem(16)};
`;

const CloseIcon = styled(Close).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.text.primary,
  }),
)``;

const Title = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize18}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const Description = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${rem(20)};
`;

const ContinueButton = styled(PrimaryButton)`
  margin-top: ${rem(24)};
`;

type CountryPickerProps = {
  displayCloseButton: boolean;
  countryOptions: SingleSelectOption<string>[];
  disabled?: boolean;
  onSubmit(countryCode: string): void;
  onClose?(): void;
  inModal?: boolean;
};

const CountryPicker = ({
  displayCloseButton,
  countryOptions,
  disabled,
  onSubmit,
  onClose,
  inModal,
}: CountryPickerProps) => {
  const { t: translate } = useTranslation('shared', {
    keyPrefix: 'country_picker',
  });
  const [country, setCountry] = useState<string | undefined>(undefined);

  return (
    <Container>
      <Header>
        <Title id="modal-title">{translate('title')}</Title>
        {displayCloseButton && onClose && (
          <CloseIconButton
            onClick={onClose}
            role="button"
            aria-label={translate('close_button.accessibility_label')}
            data-testid="close-button"
          >
            <CloseIcon />
          </CloseIconButton>
        )}
      </Header>
      <Description id="modal-copy">{translate('description')}</Description>
      <Dropdown
        placeholder={translate('dropdown.placeholder')}
        onChange={option => setCountry(option?.value as string)}
        options={countryOptions}
        isInModal={inModal}
        ariaLabel={translate('dropdown.a11y_label')}
      />
      <ContinueButton
        label={translate('continue_button.label')}
        onClick={() => onSubmit(country as string)}
        disabled={disabled || !country}
      />
    </Container>
  );
};

export default CountryPicker;
