import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
// eslint-disable-next-line @typescript-eslint/tslint/config
import uuid from 'uuid/v4';
import { styled } from 'styles';
import { Remove } from 'icons';

import { FullScreenTakeover } from '../../../Shared/FullScreenTakeover';
import { getSentAtTimestamp } from '../../../App/Tracking/serverside';

import { tracking } from '../../../App/Tracking';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import BodyText from '../../../Shared/Typography/BodyText';
import QuestionnaireHeader from './QuestionnaireHeader';
import { QuestionnaireIntroduction } from './QuestionnaireIntroduction';
import QuestionnaireOutro from './QuestionnaireOutro';
import Questions, { Answer } from './Questions';
import { getIndexQuestionnaireQuestions } from './__generated__/getIndexQuestionnaireQuestions';
import { saveUnmindIndexResponses } from './__generated__/saveUnmindIndexResponses';

const QuestionsContainer = styled.div`
  margin: 0;
`;

enum QuestionnaireStage {
  intro,
  questions,
  outro,
}

const ContentContainer = styled.div`
  margin: 0 ${rem(20)};
`;

const TakeoverContainer = styled.div`
  max-width: ${rem(710)};
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  position: relative;
`;

const ErrorText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.error};
  text-align: center;
  margin: ${rem(20)};
`;

export const INDEX_QUESTIONNAIRE_QUERY = gql`
  query getIndexQuestionnaireQuestions {
    getIndexQuestionnaireQuestions {
      version
      questions {
        id
        preQuestionText
        questionText
        scale {
          minimum
          maximum
          responseOptions {
            id
            responseLabel
          }
        }
      }
    }
  }
`;

export const SUBMIT_INDEX_QUESTIONNAIRE_MUTATION = gql`
  mutation saveUnmindIndexResponses($input: SaveUnmindIndexResponsesInput!) {
    saveUnmindIndexResponses(input: $input) {
      userIndexScore {
        id
        overallScore: overall
        categories {
          key
          name
          averageScore: score
          band
        }
      }
    }
  }
`;

export interface QuestionnaireProps {
  hideQuestionnaire(indexComplete?: boolean): void;
}

export const initialAnswers: Answer[] = [];
export const initialQuestionNumber = 0;

export const Questionnaire = ({ hideQuestionnaire }: QuestionnaireProps) => {
  const [stage, setStage] = useState(QuestionnaireStage.intro);
  const [answers, setAnswers] = useState(initialAnswers);
  const [submitError, setSubmitError] = useState('');
  const [sessionId] = useState(uuid());
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionNumber);

  const clientSentAtUtcTimestamp = getSentAtTimestamp();

  const { t: translate } = useTranslation(['unmind_index', 'shared']);

  const { data, loading, error } = useQuery<getIndexQuestionnaireQuestions>(
    INDEX_QUESTIONNAIRE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [submitIndexResponses] = useMutation<saveUnmindIndexResponses>(
    SUBMIT_INDEX_QUESTIONNAIRE_MUTATION,
    {
      onCompleted: () => {
        tracking.track('index-completed', { sessionId });
        hideQuestionnaire(true);
      },
      onError: () => {
        setSubmitError(translate('shared:errors.messages.unexpected'));
      },
      refetchQueries: ['getUserIndexInsights'],
      awaitRefetchQueries: true,
    },
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || !data?.getIndexQuestionnaireQuestions?.questions?.length) {
    return (
      <ErrorText>{translate('shared:errors.messages.unexpected')}</ErrorText>
    );
  }

  const indexQuestions = data.getIndexQuestionnaireQuestions.questions;
  const questionnaireVersion = data.getIndexQuestionnaireQuestions?.version;

  const resetQuestions = () => {
    setAnswers(initialAnswers);
    setCurrentQuestion(initialQuestionNumber);
  };

  const submitAnswers = async () => {
    setSubmitError('');

    await submitIndexResponses({
      variables: {
        input: {
          responses: answers,
          questionnaireVersion: questionnaireVersion,
          clientSentAtUtcTimestamp,
        },
      },
    });
  };

  const closeIndex = () => {
    const position = currentQuestion + 1;
    tracking.track('index-closed', {
      sessionId,
      title: indexQuestions[currentQuestion].questionText,
      position,
      remaining: indexQuestions.length - position,
    });
  };

  if (submitError) {
    return <ErrorText>{submitError}</ErrorText>;
  }

  if (stage === QuestionnaireStage.questions) {
    return (
      <>
        <FullScreenTakeover shown={true} hasWhiteBackground={true} scrollable>
          <TakeoverContainer>
            <ContentContainer>
              <QuestionnaireHeader
                buttonAriaLabel={translate(
                  'unmind_index:questionnaire.index_header.aria_label',
                )}
                buttonIcon={Remove}
                onButtonClick={() => {
                  closeIndex();
                  resetQuestions();
                  setStage(QuestionnaireStage.intro);
                }}
                heading={translate(
                  'unmind_index:questionnaire.index_header.heading',
                )}
                headingSize="medium"
                fullScreenMode={true}
              />
              <QuestionsContainer>
                <Questions
                  onComplete={() => {
                    setStage(QuestionnaireStage.outro);
                  }}
                  indexQuestions={indexQuestions}
                  answers={answers}
                  setAnswers={setAnswers}
                  currentQuestion={currentQuestion}
                  setCurrentQuestion={setCurrentQuestion}
                  sessionId={sessionId}
                />
              </QuestionsContainer>
            </ContentContainer>
          </TakeoverContainer>
        </FullScreenTakeover>
      </>
    );
  }

  if (stage === QuestionnaireStage.outro) {
    return (
      <QuestionnaireOutro
        goBackToQuestions={() => {
          setStage(QuestionnaireStage.questions);
        }}
        submitAnswers={submitAnswers}
      />
    );
  }

  return (
    <QuestionnaireIntroduction
      hideQuestionnaire={hideQuestionnaire}
      startQuestions={() => {
        setStage(QuestionnaireStage.questions);
      }}
      sessionId={sessionId}
      version={questionnaireVersion}
    />
  );
};

export default Questionnaire;
