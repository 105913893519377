import React from 'react';
import { IconProps } from '../IconProps';

const SvgTalk = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.538 17.615L1 19l1.385-4.154V2.385A1.385 1.385 0 0 1 3.769 1h13.846A1.385 1.385 0 0 1 19 2.385V16.23a1.385 1.385 0 0 1-1.385 1.384H6.538zM6.538 7.23h8.308M6.538 11.385h5.539"
      stroke={primaryColor}
      strokeOpacity={0.7}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTalk;
