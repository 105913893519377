import React from 'react';
import { ModalWithDrawerOnMobile } from 'ModalWithDrawerOnMobile/ModalWithDrawerOnMobile';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { BodyText, small } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import PrimaryButton from 'Shared/PrimaryButton';

type ActionTakenModalProps = {
  closeModal(): void;
};

const ActionTakenModalContainer = styled.div`
  padding: ${rem(20)};
  max-width: 100%;
  ${small(css`
    max-width: ${rem(370)};
  `)};
`;

const ActionTakenModalParagraph = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  line-height: 140%;
  margin-bottom: ${rem(8)};
`;

const ActionTakenModalButtonContiner = styled.div`
  margin-top: ${rem(24)};
  display: flex;
  justify-content: flex-end;
`;

const ActionTakenModalSubmitButton = styled(PrimaryButton)`
  margin-left: ${rem(8)};
`;

const ActionTakenModalTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(16)};
`;

export const ActionTakenModal = ({ closeModal }: ActionTakenModalProps) => {
  const { t: translate } = useTranslation('compass');

  return (
    <ModalWithDrawerOnMobile onClose={closeModal}>
      <ActionTakenModalContainer>
        <ActionTakenModalTitle>
          {translate('action_taken_modal.title')}
        </ActionTakenModalTitle>
        <ActionTakenModalParagraph>
          {translate('action_taken_modal.para_1')}
        </ActionTakenModalParagraph>
        <ActionTakenModalParagraph>
          {translate('action_taken_modal.para_2')}
        </ActionTakenModalParagraph>
        <ActionTakenModalButtonContiner>
          <ActionTakenModalSubmitButton
            type="submit"
            onClick={closeModal}
            label={translate('action_taken_modal.button_label')}
          />
        </ActionTakenModalButtonContiner>
      </ActionTakenModalContainer>
    </ModalWithDrawerOnMobile>
  );
};
