import { useSelect, UseSelectState } from 'downshift';
import { ellipsis, rem } from 'polished';
import React, { useCallback, useMemo } from 'react';

import { css, styled, ThemeInterface } from 'styles';
import { Chevron, IconProps } from 'icons';

import { isBrowserIE } from '../../utils/browser';
import { StylelessButton } from '../Button';
import getFocusState from '../Button/getFocusState';
import { FormElementDisplay } from '../Layout';
import BodyText from '../Typography/BodyText';
import { createFontStyle } from '../Typography/Base';
import { InteractiveContainer } from './InteractiveContainer';
import SelectNative from './SelectNative';

export enum SelectSize {
  Big,
  Regular,
  Compact,
}

const arrowWidth = 24;
const arrowHeight = 12;

export const ToggleArrow = styled(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface; open: boolean }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.staticPalette.white,
  }),
)<IconProps & { open?: boolean; arrowheight?: number; arrowwidth?: number }>`
  width: ${props =>
    props.arrowwidth ? rem(`${props.arrowwidth}px`) : rem(`${arrowWidth}px`)};
  height: ${props =>
    props.arrowheight
      ? rem(`${props.arrowheight}px`)
      : rem(`${arrowHeight}px`)};
  padding: 0;
  margin-left: ${rem('8px')};
  transform: ${({ open }) => (open ? 'rotate(270deg)' : 'rotate(90deg)')};

  position: absolute;
  right: ${props => (props.arrowheight ? `${props.arrowheight}px` : `3px`)};
  top: ${(props: any) =>
    props.arrowheight
      ? `calc(50% - ${props.arrowheight / 2}px)`
      : `calc(50% - ${arrowHeight / 2}px)`};
`;

// re-used in native select
export const selectPaddingCss = ({
  containerDisplay,
  containerSize,
}: Pick<ToggleButtonProps, 'containerDisplay' | 'containerSize'>) => css`
  padding: ${rem('8px')};
  padding-left: ${rem('14px')};

  ${containerSize === SelectSize.Big &&
  css`
    padding: ${rem('16px')};
  `}

  ${containerSize === SelectSize.Regular &&
  css`
    padding: ${rem('13px')};
  `}

  ${containerDisplay === FormElementDisplay.Inline &&
  css`
    padding-left: 0;
  `}

  padding-right: ${rem(`${arrowWidth + 3}px`)};
`;

// re-used in native select
export const selectTypographyCss = ({
  containerSize,
}: {
  containerSize?: SelectSize;
}) => css`
  ${({ theme }) =>
    createFontStyle({
      size: theme.typography.fontSizes.fontSize16,
    })}
  color: ${({ theme }) => theme.colors.text.primary};

  ${() =>
    containerSize === SelectSize.Big &&
    css`
      ${({ theme }) =>
        createFontStyle({
          size: theme.typography.fontSizes.fontSize18,
        })}
    `}
`;

const Container = styled(InteractiveContainer)<{
  containerSize?: SelectSize;
  containerType?: FormElementDisplay;
  hasError: boolean;
  open: boolean;
}>`
  ${({ containerSize }) => selectTypographyCss({ containerSize })}
  position: relative;
  border-bottom-left-radius: ${props => (props.open ? 0 : rem(4))};
  border-bottom-right-radius: ${props => (props.open ? 0 : rem(4))};
  flex-direction: column;
  display: inline-block;
  width: 100%;
  ${({ containerType }) =>
    containerType === FormElementDisplay.Inline &&
    css`
      display: flex;
      width: auto;
      border: none;
    `}
`;

interface ToggleButtonProps {
  selected: boolean;
  containerDisplay?: FormElementDisplay;
  containerSize?: SelectSize;
}

const ToggleButton = styled(StylelessButton)<ToggleButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.background.input};
  border-radius: ${rem(4)};

  ${selectPaddingCss}

  ${({ selected }) =>
    selected
      ? css`
          color: ${({ theme }) => theme.colors.text.primary};
          ${ellipsis()}
        `
      : css`
          color: ${({ theme }) => theme.colors.text.secondary};
        `}
`;

const MenuList = styled.ul<{ open: boolean }>`
  padding: 0;
  margin: 0;

  position: absolute;
  top: 100%;
  left: ${rem('-1px')};
  right: ${rem('-1px')};
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.input};
  z-index: 10;

  ${({ open }) =>
    open
      ? css`
          border: ${rem('1px')} solid ${({ theme }) => theme.colors.primary};
          border-bottom-left-radius: ${rem(4)};
          border-bottom-right-radius: ${rem(4)};
          ${getFocusState()}
          max-height: 45vh;
        `
      : css`
          height: 0;
        `}
`;

const MenuListItem = styled.li<{ active: boolean }>`
  list-style-type: none;

  display: block;
  border-bottom: ${rem('1px')} solid
    ${({ theme }) => theme.colors.border.secondary};
  padding: ${rem('11px')} ${rem('14px')} ${rem('9px')};
  background: ${({ theme, active }) =>
    active ? theme.colors.background.secondary : `none`};
  cursor: pointer;
  word-break: break-word;

  &:last-of-type {
    border: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
  }
`;

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface SelectProps {
  onChange?(value: string | number, name?: string): void;

  /**
   * Optional name prop, passed back through onChange handler for convenience
   */
  name?: string;
  error?: string;
  options?: SelectOption[];
  value?: string | number;
  placeholder: string;
  disabled?: boolean;
  LabelComponent?: React.ComponentType<{
    id: string;
    htmlFor: string;
  }>;

  /**
   * Changes component's base styling between Big and Compact
   */
  size?: SelectSize;

  /**
   * Changes component's behaviour between Inline and Block
   * Block acts like a normal div element
   * Inline acts like an inline element
   */
  display?: FormElementDisplay;

  arrowSize?: { height: number; width: number };
}

const itemToString = (option: SelectOption): string => option.label;

export const Error = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.error,
}))`
  margin: ${rem('4px')} 0 ${rem('10px')};
  text-align: left;
`;

export const Select = ({
  onChange,
  placeholder = 'Select',
  options = [],
  size,
  display,
  error,
  value,
  disabled,
  LabelComponent,
  arrowSize,
  ...props
}: SelectProps) => {
  const onSelectedItemChange = useCallback(
    (changes: Partial<UseSelectState<SelectOption>>) => {
      if (onChange && changes.selectedItem) {
        onChange(changes.selectedItem.value);
      }
    },
    [onChange],
  );
  const selectedItem = useMemo(
    () => options.find(o => o.value === value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );
  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items: options,
    selectedItem,
    itemToString,
    onSelectedItemChange,
  });
  const selectedItemLabel = selectedItem && itemToString(selectedItem);
  const selected = Boolean(selectedItemLabel);
  const hasError = Boolean(error);
  const useNativeSelect = isBrowserIE();

  return (
    <>
      {LabelComponent && <LabelComponent {...getLabelProps()} />}

      {useNativeSelect ? (
        <SelectNative
          display={display}
          size={size}
          error={error}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
        />
      ) : (
        <Container
          containerSize={size}
          containerType={display}
          hasError={hasError}
          open={isOpen}
        >
          <ToggleButton
            // without type="button" formik auto-submits form on spacebar key down
            type="button"
            {...props}
            containerDisplay={display}
            containerSize={size}
            {...getToggleButtonProps()}
            selected={selected}
          >
            {selectedItemLabel || placeholder}
            <ToggleArrow
              open={isOpen}
              // these props are intentionally not camelCased
              // this is to avoid a console error which doesn't allow camelCase props on DOM element
              // See ENG-5356 for more info
              arrowheight={arrowSize?.height}
              arrowwidth={arrowSize?.width}
            />
          </ToggleButton>

          {/* for accessibility reasons the list must always be screen-readable (even when closed) */}
          <MenuList open={isOpen} {...getMenuProps()}>
            {isOpen &&
              options.map((item, index) => (
                <MenuListItem
                  {...getItemProps({ item, index })}
                  key={`${item.value}${index}`}
                  active={index === highlightedIndex}
                >
                  {itemToString(item)}
                </MenuListItem>
              ))}
          </MenuList>
        </Container>
      )}

      {hasError && <Error>{error}</Error>}
    </>
  );
};
