import { ContentType } from 'Explore/types';
import FeedbackButtons from 'Shared/FeedbackButtons/FeedbackButtons';
import FreeTextFeedback from 'Shared/FreeTextFeedback';
import {
  FeedbackContainer,
  FeedbackCta,
  FreeTextFeedbackOverlay,
  FreeTextFeedbackWrapper,
  InnerFeedbackSubmittedConfirmation,
  OuterFeedbackSubmittedConfirmation,
  SuccessIcon,
} from 'StaticContent/Feedback.styled';
import { useSendFeedback } from 'StaticContent/hooks';
import { EventName, FeedbackCategory } from '__generated__/globalTypes';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FeedbackProps {
  contentId: string;
  contentSlug: string;
}

type FeedbackContentTrackingEventProperties = {
  contentType: ContentType;
  contentSlug: string;
  contentId: string;
};

const Feedback = ({ contentId, contentSlug }: FeedbackProps) => {
  const { t: translate } = useTranslation('cheat_sheets');
  const { sendFeedback } = useSendFeedback();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [feedbackState, setFeedbackState] = useState<FeedbackCategory | null>(
    null,
  );
  const isFreeTextFeedbackActive = feedbackState !== null;
  const freeTextFeedbackRef = useRef<HTMLDivElement>(null);

  const feedbackEventProperties: FeedbackContentTrackingEventProperties = {
    contentType: ContentType.cheatSheet,
    contentId,
    contentSlug,
  };

  useEffect(() => {
    if (isFreeTextFeedbackActive) {
      freeTextFeedbackRef?.current?.focus();
    }
  }, [isFreeTextFeedbackActive]);

  if (feedbackSubmitted) {
    return (
      <OuterFeedbackSubmittedConfirmation>
        <InnerFeedbackSubmittedConfirmation>
          <SuccessIcon />
          {translate('static_content_gallery.feedback.submitted_confirmation')}
        </InnerFeedbackSubmittedConfirmation>
      </OuterFeedbackSubmittedConfirmation>
    );
  }

  return (
    <FeedbackContainer data-testid="feedback-area">
      <FeedbackCta tabIndex={0}>
        {translate('static_content_gallery.feedback.request_feedback_cta')}
      </FeedbackCta>
      {isFreeTextFeedbackActive ? (
        <>
          <FreeTextFeedbackWrapper
            ref={freeTextFeedbackRef}
            role="dialog"
            tabIndex={-1}
            isPositiveFreeTextFeedbackActive={
              feedbackState === FeedbackCategory.POSITIVE
            }
          >
            <FreeTextFeedback
              handleCloseClick={() => {
                setFeedbackState(null);
              }}
              handleSubmitClick={async userInput => {
                setFeedbackState(null);
                setFeedbackSubmitted(true);

                void sendFeedback({
                  source: 'personalised-today-screen',
                  category: feedbackState,
                  feedback: userInput.feedbackText,
                  consentForContact: userInput.contactConsent,
                  contentId,
                  contentSlug,
                });
              }}
            />
          </FreeTextFeedbackWrapper>
          <FreeTextFeedbackOverlay
            data-testid="free-text-feedback-overlay"
            onClick={() => {
              setFeedbackState(null);
            }}
          />
        </>
      ) : null}
      <FeedbackButtons
        onPositiveClick={() => {
          setFeedbackState(FeedbackCategory.POSITIVE);
        }}
        onNegativeClick={() => {
          setFeedbackState(FeedbackCategory.NEGATIVE);
        }}
        eventProperties={feedbackEventProperties}
        clientSideEventNames={{
          positive: 'liked-content-clicked',
          negative: 'disliked-content-clicked',
        }}
        serverSideEventNames={{
          positive: EventName.CONTENT_LIKED,
          negative: EventName.CONTENT_DISLIKED,
        }}
      />
    </FeedbackContainer>
  );
};

export default Feedback;
