import gql from 'graphql-tag';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { graphql } from '@apollo/client/react/hoc';

export interface InputProps {
  slug: string;
  dayNumber: number;
}

export interface Variables {
  slug: string;
}

interface SeriesDay {
  id: string;
  orderNo: number;
}

interface ExploreCategory {
  id: string;
}

interface withSeriesTrackingIdsResponse {
  singleSeries?: {
    id: string;
    seriesDays: SeriesDay[];
    exploreCategory: ExploreCategory;
  };
}

export interface withSeriesTrackingIdsChildProps {
  seriesId?: string;
  id?: string;
  seriesDayIdLoading?: boolean;
  exploreCategoryId?: string;
}

export const withSeriesTrackingIdsQuery = gql`
  query SeriesDayId($slug: String!) {
    singleSeries(slug: $slug) {
      id
      seriesDays {
        id
        orderNo
      }
      exploreCategory {
        id
      }
    }
  }
`;

export const withSeriesTrackingIds = graphql<
  InputProps,
  withSeriesTrackingIdsResponse,
  Variables,
  withSeriesTrackingIdsChildProps
>(withSeriesTrackingIdsQuery, {
  options: ({ slug }) => ({
    variables: { slug },
  }),
  props: ({ data, ownProps }) => {
    if (data && data.singleSeries !== undefined) {
      const days = sortBy(get(data, 'singleSeries.seriesDays', []), 'orderNo');

      const seriesId = get(data, 'singleSeries.id', undefined);
      const exploreCategoryId = get(
        data,
        'singleSeries.exploreCategory.id',
        undefined,
      );

      if (days.length === 0) {
        return {
          seriesId,
          seriesDayIdLoading: false,
          exploreCategoryId,
        };
      }

      const dayIdx = Math.max(0, ownProps.dayNumber - 1);
      if (dayIdx >= days.length) {
        return {
          seriesId,
          id: days[0].id,
          seriesDayIdLoading: false,
          exploreCategoryId,
        };
      }

      return {
        seriesId,
        id: days[dayIdx].id,
        seriesDayIdLoading: false,
        exploreCategoryId,
      };
    }

    return {
      seriesDayIdLoading: Boolean(data && data.loading),
    };
  },
  skip: props => !props.slug || !props.dayNumber,
});
