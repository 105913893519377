import PrimaryButton from 'Shared/PrimaryButton';
import { BodyText, ExternalLink } from 'Shared/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';
import { app } from '@microsoft/teams-js';
import { small } from 'utils';
import { css } from 'styles';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import { AlternativeHomePageSection } from './Shared/AlternativeHomePageSection/AlternativeHomePageSection';
import { ContentTile } from './Shared/ContentTile';
import { MediaTypes, navigateToPlayer } from './Shared/navigateToPlayer';

const ChampionsTrainingContainer = styled.div`
  flex: 1;
  margin-bottom: ${rem(10)};

  ${small(css`
    margin-right: ${rem(20)};
    margin-bottom: 0;
  `)};
`;

const ChampionsTrainingCard = styled.div`
  width: 100%;
  margin-top: ${rem(5)};

  ${small(css`
    flex: 2;
  `)};
`;

const ChampionsTrainingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChampionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  ${small(css`
    flex-direction: row;
    width: ${rem(945)};
  `)};
`;

const BoldedText = styled(BodyText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.bold,
}))``;

export const ChampionsTraining = () => {
  const { t: translate } = useTranslation('logged_out');
  const context = getMSTeamsUserContext();

  const thumbnailUrl =
    'https://assets.unmind.com/image/upload/v1679927997/Personal%20Wellbeing/Thumbnails/Course_Champions_Thumbnail.jpg';
  const trainingSrc =
    'https://assets.unmind.com/video/upload/v1680765203/Personal%20Wellbeing/Live/002_Wellbeing_Champions_Training_0822_Wrapper_UK.mp4';

  return (
    <AlternativeHomePageSection
      description={translate(
        'ms_teams_alternative_start.third_section.description',
      )}
      header={translate('ms_teams_alternative_start.third_section.header')}
    >
      <ChampionsContainer>
        <ChampionsTrainingContainer>
          <BoldedText>
            {translate(
              'ms_teams_alternative_start.third_section.training.header',
            )}
          </BoldedText>
          <BodyText>
            <ul>
              <li>
                {translate(
                  'ms_teams_alternative_start.third_section.training.bullet_point_1',
                )}
              </li>
              <li>
                {translate(
                  'ms_teams_alternative_start.third_section.training.bullet_point_2',
                )}
              </li>
              <li>
                {translate(
                  'ms_teams_alternative_start.third_section.training.bullet_point_3',
                )}
              </li>
            </ul>
          </BodyText>
          <PrimaryButton
            role="button"
            data-testid={'champions-training-course'}
            label={
              <ChampionsTrainingButton>
                {translate(
                  'ms_teams_alternative_start.third_section.training.button',
                )}
                <ExternalLink />
              </ChampionsTrainingButton>
            }
            onClick={async () => {
              tracking.track(
                'teams-home-page-champions-course-button-clicked',
                {
                  tenantId: context?.user?.tenant?.id,
                  MSTeamsUserId: context?.user?.id,
                },
              );

              return app.openLink(
                'https://unmind.com/wellbeing-champions-form2',
              );
            }}
          />
        </ChampionsTrainingContainer>
        <ChampionsTrainingCard>
          <ContentTile
            src={thumbnailUrl}
            onClick={() =>
              navigateToPlayer({
                mediaSrc: trainingSrc,
                mediaType: MediaTypes.Video,
                title: translate(
                  'ms_teams_alternative_start.third_section.training.title',
                ),
              })
            }
          />
        </ChampionsTrainingCard>
      </ChampionsContainer>
    </AlternativeHomePageSection>
  );
};
