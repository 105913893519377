import { rem } from 'polished';
import {
  BodyText,
  Button,
  HeadingText,
} from '@unmind/design-system-components-web';
import { styled } from 'styles';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(32)};
  overflow-y: scroll;
`;

export const ModalTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  level: 1,
}))`
  margin-bottom: ${rem(40)};
`;

export const TipIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  height: 20,
  width: 20,
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.text.inverse,
}))`
  margin-right: ${rem(12)};
`;

export const TipTitleWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(8)};
`;

export const TipTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
}))``;

export const TipDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  margin-bottom: ${rem(30)};
`;

export const ActionButton = styled(Button).attrs({ variant: 'primary' })`
  align-self: flex-end;
`;
