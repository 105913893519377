import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTransition } from 'react-spring';
import { compose, mapProps } from 'recompose';
import { Breakpoints } from '@unmind/design-system-theme';
import { withUserType, WithUserTypeProps } from '../../../Account/withUserType';
import withPlusOne, {
  WithPlusOneProps,
} from '../../../Account/PlusOne/withPlusOne';
import withUpdateUser, {
  WithUpdateUserProps,
} from '../../../Account/withUpdateUser';

import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { Events } from '../../../App/Tracking/Events';
import useFeatureFlag from '../../../flags/useFeatureFlag';
import { isPlusOne } from '../../../utils';
import { ButtonSize } from '../../Button';
import { SecondaryButton } from '../../SecondaryButton/SecondaryButton';

import { PlusOneHeroImage } from '../PlusOneImages';
import {
  AbsoluteContent,
  ButtonContainer,
  Container,
  DismissButton,
  DismissedHeader,
  FormHeader,
  Message,
  PlusOneBackground,
  PlusOneImageContainer,
  RelativeContent,
  StyledLink,
  TextContainer,
} from './styles';

const useSetupTransitions = (items: boolean) =>
  useTransition(items, null, {
    initial: { opacity: 1 },
    update: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
    config: { duration: 250 },
  });

type OuterProps = {
  parentFeature?: 'praise' | 'series';
};

interface PlusOneCTAProps
  extends OuterProps,
    WithPlusOneProps,
    WithUpdateUserProps,
    WithUserTypeProps {
  updateHistory(location: string): void;
}

export const PlusOneCTA = ({
  plusOne: plusOneDetails,
  plusOneCTADismissed: ctaAlreadyDismissed = true,
  updateHistory,
  updateUser,
  userType,
  parentFeature,
}: PlusOneCTAProps) => {
  const plusOneEnabled = useFeatureFlag('plus-one');
  const [dismissedCTA, setDismissed] = useState(false);
  const { t: translate } = useTranslation<Namespace<'plus_one'>>('plus_one');
  const showPlusOneCTA =
    !plusOneDetails && !ctaAlreadyDismissed && !isPlusOne(userType);
  const transitions = useSetupTransitions(dismissedCTA);
  const isSmall = window.innerWidth < Breakpoints.XS;
  const buttonSize = isSmall ? ButtonSize.small : ButtonSize.medium;

  const dimissPlusOneCTA = async () => {
    if (parentFeature) {
      const trackingEventName =
        `${parentFeature}-plus-one-dismiss-clicked` as Events;

      tracking.track(trackingEventName);
    }

    await updateUser({
      plusOneCTADismissed: true,
    });
    setDismissed(true);
  };

  const navigateToInviteFriend = (): void => {
    if (parentFeature) {
      const trackingEventName =
        `${parentFeature}-plus-one-invite-clicked` as Events;

      tracking.track(trackingEventName);
    }

    updateHistory(RoutePath.InviteFriend);
  };

  const trackAccountClicked = () => {
    if (parentFeature) {
      const trackingEventName =
        `${parentFeature}-plus-one-account-clicked` as Events;

      tracking.track(trackingEventName);
    }

    return;
  };

  return plusOneEnabled && showPlusOneCTA ? (
    <Container>
      <PlusOneBackground />
      {transitions.map(({ item: isDismissed, key, props }) =>
        isDismissed ? (
          <AbsoluteContent key={key} style={props}>
            <TextContainer>
              <DismissedHeader>
                {translate('plus_one_cta.dismissed_state.header')}
              </DismissedHeader>
              <Message>
                {Trans({
                  t: translate,
                  i18nKey: 'plus_one_cta.dismissed_state.message',
                  components: {
                    plus_one_link: (
                      <StyledLink
                        to={RoutePath.InviteFriend}
                        onClick={trackAccountClicked}
                        data-testid="plusone-cta-account-link"
                      />
                    ),
                  },
                })}
              </Message>
            </TextContainer>
          </AbsoluteContent>
        ) : (
          <RelativeContent key={key} style={props}>
            <PlusOneImageContainer>
              <PlusOneHeroImage height={'auto'} />
            </PlusOneImageContainer>
            <TextContainer>
              <FormHeader>
                {translate('plus_one_cta.active_state.header')}
              </FormHeader>
              <Message>
                {translate('plus_one_cta.active_state.message')}
              </Message>
              <ButtonContainer>
                <SecondaryButton
                  label={translate('plus_one_cta.invite_button.label')}
                  onClick={navigateToInviteFriend}
                  size={buttonSize}
                />
                <DismissButton
                  label={translate('plus_one_cta.dismiss_button.label')}
                  onClick={dimissPlusOneCTA}
                  size={buttonSize}
                />
              </ButtonContainer>
            </TextContainer>
          </RelativeContent>
        ),
      )}
    </Container>
  ) : null;
};

export default compose<PlusOneCTAProps, OuterProps>(
  withPlusOne,
  withUserType,
  withRouter,
  withUpdateUser,
  mapProps((props: RouteComponentProps) => ({
    ...props,
    updateHistory: props.history.push,
  })),
)(PlusOneCTA);
