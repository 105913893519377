import React from 'react';
import { formatDate } from 'Talk/lib/dates';
import { practitionerQuery_practitioner_availability as Availability } from 'Services/BeGateway/Practitioner/__generated__/practitionerQuery';
import { Field } from 'formik';
import {
  FieldValues,
  FormValues,
} from 'Talk/components/PractitionerDrawer/types';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import {
  BackButton,
  BackIcon,
  BackText,
  DateLabel,
  DurationLabel,
  Label,
  MessageCaption,
  MessageLengthCounter,
  TextArea,
} from './styles';

const MAX_MESSAGE_LENGTH = 1000;

interface BookingStageConfirmProps {
  formValues: FormValues;
  setFieldValue(field: string, value: unknown): void;
  practitionerName: string;
  availableTimes: Availability;
  onBackSelected(): void;
  isDirectBookingEnabled: boolean;
  isSubmitting: boolean;
}

export const BookingStageConfirm = ({
  formValues,
  setFieldValue,
  availableTimes,
  practitionerName,
  onBackSelected,
  isDirectBookingEnabled,
  isSubmitting,
}: BookingStageConfirmProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const selectedSlot = formValues[FieldValues.SelectedSlot];
  const message = formValues[FieldValues.Message];
  const messageLength = message?.length ?? 0;
  const isMessageAtLimit = messageLength >= MAX_MESSAGE_LENGTH;
  const labelId = 'text-field-label-id';
  const inputId = 'text-field-id';

  return (
    <>
      {isDirectBookingEnabled && (
        <>
          <DurationLabel
            duration={availableTimes.availabilityConfig?.duration}
          />
          <BackButton disabled={isSubmitting} onClick={onBackSelected}>
            <BackIcon />
            <BackText>{t('booking_flow.back')}</BackText>
          </BackButton>
        </>
      )}
      {selectedSlot && <DateLabel>{formatDate(selectedSlot.start)}</DateLabel>}
      <Label id={labelId} htmlFor={inputId}>
        {t('booking_flow.issues_to_address')}
      </Label>
      <Field
        name={FieldValues.Message}
        render={() => (
          <TextArea
            id={inputId}
            name={FieldValues.Message}
            disabled={isSubmitting}
            aria-labelledby={labelId}
            placeholder={t('booking_flow.issues_to_address_placeholder')}
            value={formValues[FieldValues.Message]}
            onChange={e => {
              const newMessage = e.target.value;

              if (newMessage.length > MAX_MESSAGE_LENGTH) {
                return;
              }

              setFieldValue(FieldValues.Message, e.target.value);
            }}
          />
        )}
      />
      <MessageLengthCounter isMessageAtLimit={isMessageAtLimit}>
        {message
          ? t('booking_flow.max_char_warning', {
              messageLength,
              maxCharacters: MAX_MESSAGE_LENGTH,
            })
          : t('booking_flow.max_char_warning_placeholder', {
              maxCharacters: MAX_MESSAGE_LENGTH,
            })}
      </MessageLengthCounter>
      <MessageCaption>
        {t('booking_flow.message_caption', { practitionerName })}
      </MessageCaption>
    </>
  );
};
