import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesDefault = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M5.76 24.3C-1.8 9.38 6.42 4.06 14.5 3.08c8.1-1 14.15.07 17.73 9.72C38.3 29 15.24 42.99 5.76 24.3z"
      fill="#F7DBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 16c0-5.09-4.2-8.67-9.33-8.67-2.07 0-4.21.61-5.86 1.83A6.67 6.67 0 0 0 10 14.65l-.04.1c-.04.11-.1.25-.2.41l-.42.76-.25.43c-.25.44-.52.92-.72 1.38A3.76 3.76 0 0 0 8 19.22a1.68 1.68 0 0 0 .66 1.21c.15.11.37.22.64.24h.7c0 .31 0 .69.03 1.09.04.46.1.98.21 1.44.1.44.28.95.63 1.28.76.73 1.77.84 3.12.85H14v2c0 .74.6 1.34 1.33 1.34h8c.74 0 1.34-.6 1.34-1.34v-3.22c0-.23.12-.48.38-.67C27.47 21.59 28 18.47 28 16zm-17.35 3.33h-.13a26.6 26.6 0 0 1-1.1 0 .44.44 0 0 1-.05-.05.14.14 0 0 1-.02-.05l-.02-.09c.01-.19.09-.48.26-.87.17-.4.4-.83.65-1.26l.23-.4c.17-.29.34-.57.47-.82.1-.18.2-.37.26-.54.06-.15.13-.36.13-.58 0-1.95.91-3.43 2.28-4.44a8.65 8.65 0 0 1 5.06-1.56c4.52 0 8 3.1 8 7.33 0 2.37-.53 4.93-2.43 6.38-.5.38-.9 1-.9 1.73v3.22h-8v-2c0-.74-.61-1.32-1.34-1.33-1.38-.02-1.87-.15-2.2-.48-.06-.06-.17-.24-.27-.63-.08-.36-.14-.8-.17-1.23a15.59 15.59 0 0 1-.03-1.6v-.03a.67.67 0 0 0-.68-.7z"
      fill="#1B1A29"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.67 12.37a2.57 2.57 0 0 1 2.82-1.03A3.52 3.52 0 0 1 24 14.79c0 1.25-.86 2.4-1.72 3.27-.9.9-1.94 1.65-2.6 2.09-.62.4-1.41.4-2.03 0-.66-.44-1.7-1.18-2.6-2.09-.86-.86-1.72-2.02-1.72-3.27 0-1.75 1.16-3.1 2.52-3.45a2.57 2.57 0 0 1 2.82 1.03zm2.48.26c.76.2 1.52 1 1.52 2.16 0 .68-.5 1.5-1.34 2.34-.8.8-1.76 1.5-2.38 1.9a.5.5 0 0 1-.57 0c-.62-.4-1.58-1.1-2.38-1.9-.83-.84-1.33-1.66-1.33-2.34 0-1.15.75-1.96 1.51-2.16.37-.1.73-.05 1.03.15.3.2.62.61.8 1.38a.67.67 0 0 0 1.3 0c.19-.77.51-1.18.81-1.38.3-.2.66-.25 1.03-.15z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesDefault;
