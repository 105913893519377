import { useEffect } from 'react';
import * as braze from '@braze/web-sdk';
import getDeviceLanguageCode from '../../i18n/getDeviceLanguageCode';

export const useSetBrazeLanguage = () => {
  const deviceLanguageCode = getDeviceLanguageCode();

  useEffect(() => {
    if (deviceLanguageCode && process.env.REACT_APP_BRAZE_ENABLED === 'true') {
      braze.getUser()?.setLanguage(deviceLanguageCode);
    }
  }, [deviceLanguageCode]);
};
