import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { Department, Location, useSubdomainInfo } from '../useSubdomainInfo';
import getSubdomainFromUrl from '../../../utils/getSubdomainFromUrl';
import useCreateUser from '../hooks/useCreateUser';
import RoutePath from '../../../App/RoutePath';
import { RecursiveNonNullable } from '../../../typescript/helpers';
import { VIRGIN_PULSE_PARTNER_NAME } from '../../VirginPulse/consts';
import VoucherCodeForm from './VoucherCodeForm';
import { VoucherFormFields } from './types';

const VoucherCodeFormPage = () => {
  const formikRef = useRef<Formik<VoucherFormFields>>(null);
  const history = useHistory();
  const [error, setError] = useState('');
  const { t: translate } = useTranslation(['logged_out', 'shared']);
  const subdomain = getSubdomainFromUrl();
  const {
    group,
    groupName,
    departments: rawDepartments,
    locations: rawLocations,
  } = useSubdomainInfo({
    subdomain,
  });

  const filteredDepartments = rawDepartments
    ? rawDepartments.filter(
        (department): department is RecursiveNonNullable<Department> =>
          department !== null &&
          department.id !== null &&
          department.name !== null,
      )
    : [];

  const filteredLocations = rawLocations
    ? rawLocations.filter(
        (location): location is RecursiveNonNullable<Location> =>
          location !== null && location.id !== null && location.name !== null,
      )
    : [];

  const departments = filteredDepartments.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const locations = filteredLocations.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const { createUser, loading } = useCreateUser({
    subdomain,
    onCompleted: () => {
      history.push(RoutePath.VoucherCodeSignUpConfirmation, {
        email: formikRef?.current?.state.values.email,
      });
    },
    onError: err => {
      setError(
        err && err.message.toLowerCase().indexOf('invalid voucher code') >= 0
          ? translate(
              'logged_out:sign_up.voucher_code.errors.voucher_not_recognised',
            )
          : translate('shared:errors.messages.failed'),
      );
    },
    isVirginPulseGroup: false,
  });

  const onSubmit = async (formValues: VoucherFormFields) => {
    await createUser({
      ...formValues,
      subdomain,
    });
  };

  return (
    <AuthWrapper groupName={groupName}>
      <AuthWrapperBlock>
        <AuthWrapperTitle>
          {translate('logged_out:sign_up.voucher_code.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle>
          {translate('logged_out:sign_up.voucher_code.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <VoucherCodeForm
            formikRef={formikRef}
            departments={departments}
            locations={locations}
            loading={loading}
            error={error}
            onSubmit={onSubmit}
            partner={
              group?.isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null
            }
          />
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default VoucherCodeFormPage;
