import { Formik } from 'formik';
import React from 'react';
import { styled } from 'styles';
import { useTranslation } from 'react-i18next';
import {
  FormikInput,
  validateFieldNotEmpty,
} from '../../../Shared/Form/Formik';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { UserDetails } from '../SignUp';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from './CommonFormStyledComponents';

const FirstNameInputWrapper = styled.div`
  flex: 0;
`;

export interface NameFormProps {
  businessName: string;
  onSubmit(details: UserDetails): void;
  progressIndicator: React.ReactElement;
  isInitialValid?: boolean;
  initialValues?: { firstName: string; lastName: string };
}

const NameForm = ({
  isInitialValid = false,
  initialValues = { firstName: '', lastName: '' },
  ...props
}: NameFormProps) => {
  const { t: translate } = useTranslation(['logged_out', 'shared']);

  return (
    <AuthWrapper
      groupName={props.businessName}
      progressBar={props.progressIndicator}
    >
      <AuthWrapperBlock data-testid="details-page">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:sign_up.forms.name_form.heading')}
        </AuthWrapperTitle>
        <AuthWrapperContent>
          <Formik
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            onSubmit={values => {
              props.onSubmit({
                firstName: values.firstName,
                lastName: values.lastName,
              });
            }}
          >
            {({ isValid }) => (
              <StyledForm>
                <FirstNameInputWrapper>
                  <StyledFormFieldLabel
                    data-testid="first-name-input-label"
                    htmlFor="firstName"
                  >
                    {translate(
                      'logged_out:sign_up.forms.name_form.first_name_field.label',
                    )}
                  </StyledFormFieldLabel>
                  <FormikInput
                    data-testid="first-name-input"
                    name="firstName"
                    placeholder={translate(
                      'logged_out:sign_up.forms.name_form.first_name_field.label',
                    )}
                    aria-label={translate(
                      'logged_out:sign_up.forms.name_form.first_name_field.label',
                    )}
                    type="firstName"
                    validate={value =>
                      validateFieldNotEmpty(
                        value,
                        translate(
                          'shared:forms.validate_fields.first_name.text',
                        ),
                      )
                    }
                  />
                </FirstNameInputWrapper>
                <StyledFormFieldLabel
                  data-testid="last-name-input-label"
                  htmlFor="lastName"
                >
                  {translate(
                    'logged_out:sign_up.forms.name_form.last_name_field.label',
                  )}
                </StyledFormFieldLabel>
                <FormikInput
                  data-testid="last-name-input"
                  name="lastName"
                  placeholder={translate(
                    'logged_out:sign_up.forms.name_form.last_name_field.label',
                  )}
                  aria-label={translate(
                    'logged_out:sign_up.forms.name_form.last_name_field.label',
                  )}
                  type="lastName"
                  validate={value =>
                    validateFieldNotEmpty(
                      value,
                      translate('shared:forms.validate_fields.last_name.text'),
                    )
                  }
                />
                <StyledSubmitButton
                  data-testid="submit-button"
                  type="submit"
                  label={translate(
                    'logged_out:sign_up.forms.name_form.submit_button.label',
                  )}
                  disabled={!isValid}
                />
              </StyledForm>
            )}
          </Formik>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default NameForm;
