import React from 'react';
import { IconProps } from '../IconProps';
const SvgOffice365 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 22" fill="none" {...props}>
    <g clipPath="url(#Office365_svg__clip0_2391_5830)">
      <path
        d="M8.042.513L7.93.581a4.004 4.004 0 00-.504.361L7.75.714h2.75L11 4.5 8.5 7 6 8.738v2.004a4 4 0 001.929 3.422l2.631 1.593L5 19H3.928l-2-1.21A4 4 0 010 14.37V7.63a4 4 0 011.93-3.423l6-3.63.112-.065z"
        fill="url(#Office365_svg__paint0_radial_2391_5830)"
      />
      <path
        d="M8.042.513L7.93.581a4.004 4.004 0 00-.504.361L7.75.714h2.75L11 4.5 8.5 7 6 8.738v2.004a4 4 0 001.929 3.422l2.631 1.593L5 19H3.928l-2-1.21A4 4 0 010 14.37V7.63a4 4 0 011.93-3.423l6-3.63.112-.065z"
        fill="url(#Office365_svg__paint1_linear_2391_5830)"
      />
      <path
        d="M14 8.5v2.24a4 4 0 01-1.929 3.422l-6 3.632a4 4 0 01-4.003.08l5.86 3.548a4 4 0 004.143 0l6-3.632A4 4 0 0020 14.368V12.75l-.5-.75L14 8.5z"
        fill="url(#Office365_svg__paint2_radial_2391_5830)"
      />
      <path
        d="M14 8.5v2.24a4 4 0 01-1.929 3.422l-6 3.632a4 4 0 01-4.003.08l5.86 3.548a4 4 0 004.143 0l6-3.632A4 4 0 0020 14.368V12.75l-.5-.75L14 8.5z"
        fill="url(#Office365_svg__paint3_linear_2391_5830)"
      />
      <path
        d="M18.07 4.208l-6-3.63A4 4 0 008.05.51l-.12.072A4 4 0 006 4.004v4.742l1.93-1.168a4 4 0 014.14 0l6 3.63a4 4 0 011.928 3.291l.002-.13V7.63a4 4 0 00-1.93-3.422z"
        fill="url(#Office365_svg__paint4_radial_2391_5830)"
      />
      <path
        d="M18.07 4.208l-6-3.63A4 4 0 008.05.51l-.12.072A4 4 0 006 4.004v4.742l1.93-1.168a4 4 0 014.14 0l6 3.63a4 4 0 011.928 3.291l.002-.13V7.63a4 4 0 00-1.93-3.422z"
        fill="url(#Office365_svg__paint5_linear_2391_5830)"
      />
    </g>
    <defs>
      <radialGradient
        id="Office365_svg__paint0_radial_2391_5830"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(110.528 1.857 4.486) scale(16.6829 29.0983)"
      >
        <stop offset={0.064} stopColor="#AE7FE2" />
        <stop offset={1} stopColor="#0078D4" />
      </radialGradient>
      <radialGradient
        id="Office365_svg__paint2_radial_2391_5830"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-8.367 119.012 -13.388) scale(15.5252 10.2554)"
      >
        <stop offset={0.134} stopColor="#D59DFF" />
        <stop offset={1} stopColor="#5E438F" />
      </radialGradient>
      <radialGradient
        id="Office365_svg__paint4_radial_2391_5830"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-12.07915 -3.06278 5.1559 -20.33412 18.528 12.252)"
      >
        <stop offset={0.058} stopColor="#50E6FF" />
        <stop offset={1} stopColor="#436DCD" />
      </radialGradient>
      <linearGradient
        id="Office365_svg__paint1_linear_2391_5830"
        x1={6.756}
        y1={17.934}
        x2={4.376}
        y2={13.818}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#114A8B" />
        <stop offset={1} stopColor="#0078D4" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="Office365_svg__paint3_linear_2391_5830"
        x1={18.178}
        y1={11.688}
        x2={15.628}
        y2={15.346}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#493474" />
        <stop offset={1} stopColor="#8C66BA" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="Office365_svg__paint5_linear_2391_5830"
        x1={6.488}
        y1={0.528}
        x2={10.243}
        y2={0.528}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D3F80" />
        <stop offset={1} stopColor="#436DCD" stopOpacity={0} />
      </linearGradient>
      <clipPath id="Office365_svg__clip0_2391_5830">
        <path fill="#fff" d="M0 0h20v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgOffice365;
