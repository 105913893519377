import { Link } from 'react-router-dom';
import { css, styled, ThemeInterface } from 'styles';
import { rem } from 'polished';

import {
  FeatureText,
  BodyText,
  MonoText,
} from '@unmind/design-system-components-web';
import DynamicIcon from '../../../Shared/DynamicIcon/DynamicIcon';
import PlayButton from '../../../Shorts/ShortCardButtons/PlayButton';
import { medium, small } from '../../../utils';

export const CardContainer = styled(Link)`
  width: 100%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.background.card};
  box-shadow: 0px 4px 8px -2px rgba(13, 3, 0, 0.08),
    0px 2px 4px -1px rgba(13, 3, 0, 0.08);
  border-radius: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  /* on click effect */
  transition: transform 100ms ease-in-out, transform 100ms ease-in-out;
  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }

  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
  }

  /* play button effect */
  :hover ${PlayButton} {
    transform: scale(1.1);
    transition: transform 200ms ease-in-out;
  }

  :hover {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: max-content;
  max-height: ${rem(140)};
  position: relative;
  overflow: hidden;

  ${small(css`
    max-height: ${rem(220)};
    flex: 3;
  `)}
`;

export const Image = styled.img.attrs({
  alt: '',
})`
  object-fit: cover;
  width: 100%;
  max-height: ${rem(220)};
`;

export const CardActionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${rem(24)};
  flex: 1;

  ${small(css`
    flex: 2;
  `)}
`;

export const CardTitle = styled(FeatureText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 2,
    sizes: [theme.typography.fontSizes.fontSize18],
    weight: theme.typography.fontWeights.regular,
  }),
)`
  margin-bottom: ${rem(8)};
  line-height: 1.4;

  &:focus {
    outline: none;
  }

  ${small(css`
    margin-bottom: ${rem(20)};
  `)}
`;

export const CardBody = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 2,
    sizes: [theme.typography.fontSizes.fontSize16],
    weight: theme.typography.fontWeights.regular,
  }),
)`
  line-height: 1.4;
`;

export const CardMetadata = styled.div`
  display: flex;
  align-items: center;
`;

export const DurationText = styled(MonoText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  margin-left: ${rem(5)};

  ${medium(css`
    line-height: 1.2;
  `)};
`;

export const CardMediaTypeIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  height: ${rem(16)};
  width: ${rem(16)};
`;
