import graphqlTag from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Errors } from '../../Shared/Errors';

export const REVOKE_PLUS_ONE_INVITE = graphqlTag`
  mutation RevokePlusOneInvite {
    revokePlusOneInvite {
      user {
        id
        plusOne {
          id
        }
      }
    }
  }
`;

type RevokePlusOneInviteOutput = {
  user: {
    id: string;
    plusOne: null;
  };
};

interface Response {
  error?: Errors;
  revokePlusOneInvite: RevokePlusOneInviteOutput | null;
}
export interface RevokePlusOneInviteProps {
  error?: Errors;
  revokePlusOneInvite(): Promise<Response>;
}

const withRevokePlusOneInvite = graphql<
  Record<string, unknown>,
  Response,
  Record<string, unknown>,
  RevokePlusOneInviteProps
>(REVOKE_PLUS_ONE_INVITE, {
  name: 'revokePlusOneInvite',
});

export default withRevokePlusOneInvite;
