import React from 'react';
import { IconProps } from '../IconProps';

const SvgRelationships = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={1.538}
    >
      <path d="M41.817 32.015l-8.6 1.638M32.027 22.277l-5.78 1.904a1.92 1.92 0 0 1-2.342-1.015 1.93 1.93 0 0 1 .882-2.541l5.598-2.806a3.917 3.917 0 0 1 3.102-.18l8.288 3.022" />
      <path d="M14.142 32.028h4.075l5.853 6.538c.61.705 1.596.958 2.472.633A2.23 2.23 0 0 0 28 37.111V36.09l.292.117c.85.339 1.813.235 2.572-.276a2.748 2.748 0 0 0 1.213-2.277h.815c.926-.001 1.772-.523 2.186-1.348a2.432 2.432 0 0 0-.23-2.553l-5.231-6.68M25.638 20.196l-.596-.49a3.508 3.508 0 0 0-3.374-.429l-7.418 2.957" />
      <path d="M9.25 19.407h3.333a1.6 1.6 0 0 1 1.667 1.522v10.659a1.601 1.601 0 0 1-1.667 1.523H9.25M46.75 33.111h-3.333a1.601 1.601 0 0 1-1.667-1.523v-10.66a1.6 1.6 0 0 1 1.667-1.521h3.333M28 36.09l-1.63-1.625M32.077 33.653l-2.447-2.439" />
    </g>
  </svg>
);

export default SvgRelationships;
