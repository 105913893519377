import account from './account.json';
import admin from './admin.json';
import chatbot from './chatbot.json';
import cheat_sheets from './cheat_sheets.json';
import check_in from './check_in.json';
import courses from './courses.json';
import country from './country.json';
import explore from './explore.json';
import help from './help.json';
import homepage from './homepage.json';
import insights from './insights.json';
import invite_a_loved_one from './invite_a_loved_one.json';
import languages from './languages.json';
import logged_out from './logged_out.json';
import media_player from './media_player.json';
import navigation from './navigation.json';
import password_validation from './password_validation.json';
import plus_one from './plus_one.json';
import shared from './shared.json';
import shorts from './shorts.json';
import talk from './talk.json';
import tools from './tools.json';
import track from './track.json';
import unmind_index from './unmind_index.json';

const bnBDResources = {
  account,
  admin,
  chatbot,
  cheat_sheets,
  check_in,
  country,
  courses,
  explore,
  help,
  homepage,
  insights,
  invite_a_loved_one,
  languages,
  logged_out,
  media_player,
  navigation,
  password_validation,
  plus_one,
  shared,
  shorts,
  talk,
  tools,
  track,
  unmind_index,
};

export default bnBDResources;
