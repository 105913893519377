import React from 'react';
import { useTranslation } from 'react-i18next';
import { Graph } from 'icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { hasCompletedIndexRecently } from '../utils/timeBetweenIndex';
import RoutePath from '../../../App/RoutePath';
import PrimaryButton from '../../../Shared/PrimaryButton';
import DynamicIcon from '../../../Shared/DynamicIcon/DynamicIcon';
import HeadingText from '../../../Shared/Typography/HeadingText';
import BodyText from '../../../Shared/Typography/BodyText';
import withIndexRetakeFrequency, {
  MINIMUM_DAYS_BETWEEN_INDEXES,
  WithIndexRetakeFrequencyProps,
} from '../Questionnaire/withIndexRetakeFrequency';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(4)} ${rem(14)};
  margin: 0 auto;
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-bottom: ${rem(14)};
  text-align: center;
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-bottom: ${rem(20)};
  text-align: center;
`;

const EmptyStateIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: 20,
  height: 20,
}))`
  align-self: center;
  margin-bottom: ${rem(16)};
`;

const ButtonWrapper = styled.div`
  align-self: center;
`;

const Button = styled(PrimaryButton)`
  margin: ${rem(0)} ${rem(20)} ${rem(0)} ${rem(20)};
`;

interface ScoreChartEmptyStateProps extends WithIndexRetakeFrequencyProps {
  latestScoreDate: Date;
  category: string;
}

const ScoreChartEmptyState = ({
  latestScoreDate,
  indexFrequency = MINIMUM_DAYS_BETWEEN_INDEXES,
  category,
}: ScoreChartEmptyStateProps) => {
  const { t: translate } = useTranslation('track');

  const showWellbeingTrackerCTA = !hasCompletedIndexRecently(
    indexFrequency,
    latestScoreDate.toString(),
  );

  return (
    <Wrapper data-testid="score-chart-empty-state">
      <EmptyStateIcon aria-hidden="true" Icon={Graph} />
      <Title>{translate('wellbeing.scores.chart_empty_state.title')}</Title>
      <Description>
        {translate('wellbeing.scores.chart_empty_state.description', {
          category: category.toLowerCase(),
        })}
      </Description>
      {showWellbeingTrackerCTA ? (
        <ButtonWrapper>
          <Button
            label={translate(
              'wellbeing.scores.chart_empty_state.cta_button.text',
            )}
            to={{ pathname: RoutePath.UnmindIndex }}
          />
        </ButtonWrapper>
      ) : null}
    </Wrapper>
  );
};

export default withIndexRetakeFrequency(ScoreChartEmptyState);
