import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalAlertView } from './LocalAlertView';

export const LocalGeneralError = () => {
  const { t: translate } = useTranslation('shared');

  return (
    <LocalAlertView
      heading={translate('errors.messages.oops_something_wrong')}
      message={translate('errors.messages.unexpected')}
    />
  );
};
