import { useEffect } from 'react';

export default function useHandleEscapeKeyPress(onEscapeKeyDown: () => void) {
  useEffect(() => {
    const handleEscapeKeyDown = (event: KeyboardEvent) => {
      if (
        event.code === 'Escape' ||
        event.key === 'Esc' // For IE11
      ) {
        onEscapeKeyDown();
      }
    };
    document.addEventListener('keydown', handleEscapeKeyDown);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  });
}
