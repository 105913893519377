import { useQuery } from '@apollo/client';
import { Checkbox, Loader } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { practitionerCountriesQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerCountriesQuery';
import { PRACTITIONER_COUNTRIES_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import { SingleSelect } from 'Shared/Form/SingleSelect';
import useAvailableLanguages, {
  formatCountryOptions,
  formatStateOptions,
} from 'Talk/hooks/useAvailableLanguages';
import {
  COUNTRIES_REQUIRING_STATES,
  useLocationData,
} from 'Talk/hooks/useLocationData';
import { Namespace } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BEGatewayQueryContext } from 'utils/apollo';
import {
  MatchingAction,
  MatchingQuestionKeys,
  MatchingStateResponse,
  MatchingStateType,
  SessionsImportantOptions,
} from './state';
import {
  AddIcon,
  AddLanguageButton,
  AddLanguageText,
  CloseButton,
  CloseButtonContainer,
  CloseIcon,
  InputLegend,
  LanguageSelectionContainer,
  QuestionBodyText,
  QuestionCTAButton,
  QuestionCounter,
  QuestionResponseContainer,
  QuestionTextContainer,
  QuestionTextInput,
  QuestionTitleTextLabel,
  QuestionnaireForm,
  ResponseFieldset,
} from './styles';

function questionnaireLocationIsValid(
  country: string,
  region: string | undefined,
  hasValidLocationData: boolean,
) {
  const isValidNewLocation = COUNTRIES_REQUIRING_STATES.includes(country)
    ? !!region && !!country
    : !!country;

  return country ? isValidNewLocation : hasValidLocationData;
}

interface Props {
  currentQuestion: number;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>;
  questionResponses: MatchingStateType['questionResponses'];
  addedLanguagesCount: number;
  setLanguageCount: React.Dispatch<React.SetStateAction<number>>;
  setMatchingQuestionState(s: MatchingAction): void;
  onQuestionnaireSubmit(): void;
  questionnaireSubmissionInFlight: boolean;
}

export function createMatchingQuestionKeys() {
  return Object.keys(MatchingQuestionKeys) as MatchingQuestionKeys[];
}

export function MatchingQuestionnaire({
  currentQuestion,
  setCurrentQuestion,
  questionResponses,
  addedLanguagesCount,
  setLanguageCount,
  setMatchingQuestionState,
  onQuestionnaireSubmit,
  questionnaireSubmissionInFlight,
}: Readonly<Props>) {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { userLocationData, hasValidLocationData } = useLocationData();
  const {
    data: countriesQueryData,
    loading: practitionerCountriesDataLoading,
  } = useQuery<practitionerCountriesQuery>(PRACTITIONER_COUNTRIES_QUERY, {
    ...BEGatewayQueryContext,
  });
  const { country, region } =
    questionResponses[MatchingQuestionKeys.where_location];
  const regionOptions = formatStateOptions(country?.value);
  const { availableLanguages } = useAvailableLanguages({
    country,
    region,
  });
  const questionKeys = createMatchingQuestionKeys();
  const questionKey = questionKeys[currentQuestion];
  const questionsCount = questionKeys.length;
  const locationIsValid = questionnaireLocationIsValid(
    country?.value,
    region?.value,
    hasValidLocationData,
  );
  const remainingLanguages = availableLanguages.filter(
    language =>
      !questionResponses[MatchingQuestionKeys.what_language].some(
        selectedLanguage => selectedLanguage?.value === language.value,
      ),
  );
  const countryData = countriesQueryData?.practitionerCountries
    ? formatCountryOptions(countriesQueryData.practitionerCountries)
    : [];
  const nextButtonDisabled =
    (questionKey === MatchingQuestionKeys.where_location && !locationIsValid) ||
    (questionKey === MatchingQuestionKeys.sessions_for &&
      !questionResponses[MatchingQuestionKeys.sessions_for]) ||
    (questionKey === MatchingQuestionKeys.sessions_important &&
      questionResponses[MatchingQuestionKeys.sessions_important].length === 0);

  const handleLanguageSelect = (
    index: number,
    language: { value: string; label: string },
  ) => {
    const newSelectedLanguages = [
      ...questionResponses[MatchingQuestionKeys.what_language],
    ];
    newSelectedLanguages[index] = language;
    setMatchingQuestionState({
      type: MatchingStateResponse[MatchingQuestionKeys.what_language],
      payload: newSelectedLanguages,
    });
  };

  const handleCheckboxSelect = (option: SessionsImportantOptions) => {
    const optionExists =
      questionResponses[MatchingQuestionKeys.sessions_important].includes(
        option,
      );
    const updatedResponse = optionExists
      ? questionResponses[MatchingQuestionKeys.sessions_important].filter(
          response => response !== option,
        )
      : [...questionResponses[MatchingQuestionKeys.sessions_important], option];

    return setMatchingQuestionState({
      type: MatchingStateResponse[MatchingQuestionKeys.sessions_important],
      payload: updatedResponse,
    });
  };

  const handleNextQuestionClick = () => {
    tracking.track('talk-matching-question-responded', {
      questionKey,
    });
    setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <QuestionnaireForm>
      <QuestionCounter>
        {currentQuestion + 1}/{questionsCount}
      </QuestionCounter>
      <QuestionTextContainer>
        <QuestionTitleTextLabel
          aria-hidden={
            questionKey ===
            (MatchingQuestionKeys.sessions_important ||
              MatchingQuestionKeys.what_language)
          }
          htmlFor={`question-${questionKey}`}
        >
          {t(`matching.questionnaire.questions.${questionKey}.title`)}
        </QuestionTitleTextLabel>
        <QuestionBodyText>
          {t(`matching.questionnaire.questions.${questionKey}.body`)}
        </QuestionBodyText>
      </QuestionTextContainer>
      <QuestionResponseContainer>
        {questionKey === MatchingQuestionKeys.sessions_for && (
          <QuestionTextInput
            id={`question-${MatchingQuestionKeys.sessions_for}`}
            name="matching-text-input"
            size="medium"
            border={false}
            multiline
            autoComplete="off"
            value={questionResponses[MatchingQuestionKeys.sessions_for]}
            onChange={e => {
              setMatchingQuestionState({
                type: MatchingStateResponse[MatchingQuestionKeys.sessions_for],
                payload: e.target.value,
              });
            }}
          />
        )}
        {questionKey === MatchingQuestionKeys.sessions_important && (
          <ResponseFieldset>
            <InputLegend hidden>
              {t(`matching.questionnaire.questions.${questionKey}.title`)}{' '}
              {t(`matching.questionnaire.questions.${questionKey}.body`)}
            </InputLegend>
            {Object.keys(SessionsImportantOptions).map(option => {
              const checkboxKey =
                SessionsImportantOptions[
                  option as keyof typeof SessionsImportantOptions
                ];

              const responses =
                questionResponses[MatchingQuestionKeys.sessions_important];
              const optionSelected =
                Array.isArray(responses) && responses.includes(checkboxKey);

              return (
                <Checkbox
                  key={checkboxKey}
                  name={`${MatchingQuestionKeys.sessions_important}-${checkboxKey}`}
                  value={checkboxKey}
                  checked={optionSelected}
                  labelPosition="left"
                  labelText={t(
                    `matching.questionnaire.questions.${questionKey}.options.${checkboxKey}`,
                  )}
                  onChange={() =>
                    handleCheckboxSelect(
                      checkboxKey as SessionsImportantOptions,
                    )
                  }
                  size="medium"
                  bordered
                  disabled={responses.length === 3 && !optionSelected}
                />
              );
            })}
          </ResponseFieldset>
        )}
        {questionKey === MatchingQuestionKeys.where_location &&
          (practitionerCountriesDataLoading ? (
            <Loader />
          ) : (
            <ResponseFieldset>
              <InputLegend hidden>
                {t('matching.questionnaire.location_select.label')}
              </InputLegend>
              <SingleSelect
                inputId="country_select"
                options={countryData}
                placeholder={t('location_modal.select_country')}
                onChange={(e: { value: string; label: string }) => {
                  setMatchingQuestionState({
                    type: MatchingStateResponse[
                      MatchingQuestionKeys.where_location
                    ],
                    payload: {
                      country: e,
                      region: {
                        value: '',
                        label: t('location_modal.select_region'),
                      },
                    },
                  });
                }}
                value={
                  !!country.value
                    ? country
                    : countryData.find(
                        c => c.value === userLocationData?.user?.country?.value,
                      )
                }
              />
              {COUNTRIES_REQUIRING_STATES.includes(country?.value) && (
                <SingleSelect
                  inputId="region_select"
                  options={regionOptions}
                  placeholder={t('location_modal.select_region')}
                  onChange={(e: { value: string; label: string }) => {
                    setMatchingQuestionState({
                      type: MatchingStateResponse[
                        MatchingQuestionKeys.where_location
                      ],
                      payload: {
                        country:
                          questionResponses[MatchingQuestionKeys.where_location]
                            .country,
                        region: e,
                      },
                    });
                  }}
                  value={regionOptions.find(s => s.value === region?.value)}
                />
              )}
            </ResponseFieldset>
          ))}

        {questionKey === MatchingQuestionKeys.what_language &&
          addedLanguagesCount > 0 && (
            <>
              <ResponseFieldset>
                <InputLegend hidden>
                  {t(`matching.questionnaire.questions.${questionKey}.title`)}{' '}
                  {t(`matching.questionnaire.questions.${questionKey}.body`)}
                </InputLegend>
                {[...Array(addedLanguagesCount)].map((_, index) => (
                  <LanguageSelectionContainer key={`lang-${index}`}>
                    <SingleSelect
                      placeholder={t(
                        'matching.questionnaire.language_select.label',
                      )}
                      inputId={`selected-language-${index}`}
                      options={remainingLanguages}
                      onChange={(e: { value: string; label: string }) =>
                        handleLanguageSelect(index, e)
                      }
                      value={
                        questionResponses[MatchingQuestionKeys.what_language][
                          index
                        ]
                      }
                    />
                    <CloseButtonContainer>
                      {addedLanguagesCount > 1 && index >= 1 ? (
                        <CloseButton
                          onClick={() => {
                            const newSelectedLanguages = questionResponses[
                              MatchingQuestionKeys.what_language
                            ].filter((_l, i) => i !== index);

                            setMatchingQuestionState({
                              type: MatchingStateResponse[
                                MatchingQuestionKeys.what_language
                              ],
                              payload: newSelectedLanguages,
                            });
                            setLanguageCount(addedLanguagesCount - 1);
                          }}
                          aria-label={t(
                            'matching.questionnaire.close_button.a11y_label',
                          )}
                          disabled={addedLanguagesCount === 1}
                        >
                          <CloseIcon />
                        </CloseButton>
                      ) : null}
                    </CloseButtonContainer>
                  </LanguageSelectionContainer>
                ))}
              </ResponseFieldset>

              <AddLanguageButton
                onClick={() => setLanguageCount(addedLanguagesCount + 1)}
                disabled={
                  addedLanguagesCount === remainingLanguages.length ||
                  addedLanguagesCount === 3 ||
                  !Boolean(
                    questionResponses[MatchingQuestionKeys.what_language][
                      addedLanguagesCount - 1
                    ]?.value,
                  )
                }
                aria-label={t(
                  'matching.questionnaire.add_language_button.label',
                )}
              >
                <AddLanguageText>
                  {t('matching.questionnaire.add_language_button.label')}
                </AddLanguageText>
                <AddIcon />
              </AddLanguageButton>
            </>
          )}
        {currentQuestion !== questionsCount - 1 ? (
          <QuestionCTAButton
            label={t('matching.questionnaire.next_button.label')}
            type="button"
            onClick={handleNextQuestionClick}
            disabled={nextButtonDisabled}
          />
        ) : (
          <QuestionCTAButton
            label={t('matching.questionnaire.submit_button.label')}
            type="submit"
            disabled={
              questionnaireSubmissionInFlight ||
              questionResponses[MatchingQuestionKeys.what_language].length !==
                addedLanguagesCount
            }
            onClick={onQuestionnaireSubmit}
          />
        )}
      </QuestionResponseContainer>
    </QuestionnaireForm>
  );
}
