import React from 'react';
import { tracking } from 'App/Tracking';
import { Events } from 'App/Tracking/Events';
import { ExternalLink } from 'Shared/Typography';
import { Namespace } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExternalLinkIcon } from 'LoggedOut/ExternalLinkIcon';
import { getPrivacyPolicyLink, getTermsOfUseLink } from 'utils';

const StyledLink = styled(ExternalLink)`
  white-space: nowrap;
`;

const TsAndCsWrapper = styled.div`
  text-align: center;
`;

const trackTerms = (
  subdomain: string | undefined,
  trackingEvent: Events | undefined,
  partner: string | null,
) => {
  const trackingEventName = trackingEvent
    ? trackingEvent
    : 'terms-conditions-clicked';

  tracking.track(trackingEventName, { subdomain, partner });
};

const trackPrivacyPolicy = (
  subdomain: string | undefined,
  partner: string | null,
) => {
  tracking.track('privacy-policy-clicked', { subdomain, partner });
};

interface TsAndCsStatementProps {
  subdomain?: string;
  termsTrackingEvent?: Events;
  partner?: string | null;
  className?: string;
}

export const TsAndCsStatement = ({
  partner = null,
  subdomain,
  termsTrackingEvent,
  className,
}: TsAndCsStatementProps) => {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  return (
    <TsAndCsWrapper className={className}>
      <Trans
        t={translate}
        i18nKey="sign_up.ts_and_cs_statement"
        defaults="By continuing, I agree to Unmind’s <bold_link_terms>Terms & Conditions</bold_link_terms> and <bold_link_policy>Privacy Policy</bold_link_policy>."
        components={{
          bold_link_terms: (
            <StyledLink
              onClick={() => trackTerms(subdomain, termsTrackingEvent, partner)}
              href={getTermsOfUseLink(subdomain)}
              target="_blank"
              data-testid="terms-conditions-link"
            />
          ),
          bold_link_policy: (
            <StyledLink
              onClick={() => trackPrivacyPolicy(subdomain, partner)}
              href={getPrivacyPolicyLink(subdomain)}
              target="_blank"
              data-testid="privacy-policy-link"
            />
          ),
          external_link_icon: <ExternalLinkIcon />,
        }}
      />
    </TsAndCsWrapper>
  );
};
