import { hideVisually } from 'polished';
import { styled } from 'styles';

/**
 * Used for wrapping content which we want visually hidden
 * but accessible to screen readers.
 * See https://a11yproject.com/posts/how-to-hide-content/
 */
const ScreenReaderContent = styled.div<{ as: string }>`
  ${hideVisually()};
  white-space: ${({ as }) => (as === 'table' ? 'initial' : 'nowrap')};
`;

export default ScreenReaderContent;
