import React from 'react';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import Route404 from 'App/Router/Route404';
import { UserMessage } from 'Assistant/Chatbot/types';
import { useLocation } from 'react-router';
import { ChatbotHttpStream } from 'Assistant/Chatbot/components/ChatbotHttpStream';
import FeedbackBanner from 'Shared/FeedbackBanner';
import uuid from 'uuid';
import { isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { Chatbot } from '../../components/Chatbot';
import { PageContainer } from './styles';

const FEEDBACK_LOCALES = ['en-GB', 'en-US', 'en-AU'];

export const ChatbotPage = () => {
  const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);
  const isDisneyDemo = useFeatureFlag(FEATURE_FLAGS.DISNEY_CHATBOT);
  const { state: locationState } = useLocation<UserMessage>();
  const teamsURLParams = new URLSearchParams(location.search);
  let userMessage: UserMessage | undefined;
  const subdomain = getSubdomainFromUrl();

  if (isMSTeams()) {
    const content = teamsURLParams.get('content');

    userMessage = content
      ? {
          content,
          topic: teamsURLParams.get('topic'),
          starterId: teamsURLParams.get('starterId'),
          id: uuid.v4(),
          createdAt: new Date().toISOString(),
        }
      : undefined;
  } else {
    userMessage = locationState;
  }

  if (subdomain === 'kearney' || subdomain === 'prod-testing') {
    console.log(`Nova flag is set to: ${isChatBotEnabled}`);
  }

  if (!isChatBotEnabled) {
    return <Route404 />;
  }

  /**
   * NOTE:
   * This ternary operator is temporary logic for demo purposes, if flag is true
   * will serve the chatbot with the HTTP stream for demo, otherwise it will serve the chatbot
   * in current production form.
   */
  return (
    <>
      <FeedbackBanner
        i18nNamespace={'chatbot'}
        i18nKey={'banner.text'}
        surveyLink={'https://unmind.typeform.com/to/ISVJNakZ'}
        supportedFeedbackLocales={FEEDBACK_LOCALES}
      />
      <PageContainer>
        {isDisneyDemo ? (
          <ChatbotHttpStream initialMessage={userMessage} />
        ) : (
          <Chatbot initialMessage={userMessage} />
        )}
      </PageContainer>
    </>
  );
};
