import React from 'react';
import { rem } from 'polished';
import { startOfMonth, sub, endOfMonth, isWithinInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { startCase } from 'lodash';
import WellbeingTrackerLineChart, {
  PADDING_DAYS,
} from '../../../Shared/Charts/WellbeingTrackerLineChart';
import { UserWellbeingTrackerScoresForScoreScreen_wellbeingTracker_bands as TrackerBands } from '../__generated__/UserWellbeingTrackerScoresForScoreScreen';
import { IndexScore as WellbeingScoreByType } from '../types/types';
import ChartDescription from '../../../Shared/Charts/ChartDescription';
import ScoreChartEmptyState from './ScoreChartEmptyState';

export const GraphContainer = styled.div``;

export const ChartOuter = styled.div`
  margin-bottom: ${rem(24)};
`;

interface WellbeingTrackerLineChartWrapperProps {
  bands: TrackerBands[];
  category: string;
  data: WellbeingScoreByType[];
  showEmptyState?: boolean;
}

const WellbeingTrackerLineChartWrapper = ({
  data,
  bands,
  category,
  showEmptyState,
}: WellbeingTrackerLineChartWrapperProps) => {
  const { t: translate } = useTranslation('track');
  const now = new Date();
  const startDate = sub(startOfMonth(now), {
    months: 2,
    days: PADDING_DAYS,
  });
  const endDate = endOfMonth(now);

  const numberOfCompletionsWithinRange = data?.filter(d =>
    isWithinInterval(d.date, {
      start: startDate,
      end: endDate,
    }),
  ).length;
  const valuesToDisplay = numberOfCompletionsWithinRange ?? 0;

  /**
   * We add an extra value to create a connection between
   * the data point outside the left edge and the data point
   * farthest to the left within the range. Also required to
   * display the change between the scores.
   */
  const recentData = data?.slice(0, valuesToDisplay + 1);
  const latestScoreDate = data?.[0]?.date ?? now;
  const description = translate(
    'wellbeing.scores.chart.category_chart_description',
    {
      category: category.toLowerCase(),
    },
  );

  return valuesToDisplay > 1 ? (
    <GraphContainer>
      <ChartOuter data-testid="wellbeing-score-graph">
        <WellbeingTrackerLineChart
          caption={translate('wellbeing.category_item.chart.caption', {
            category_name: startCase(category),
          })}
          data={recentData}
          height={280}
          yAxisBottom={bands[0].lower ?? 40}
          yAxisTop={bands[bands.length - 1].upper ?? 160}
          startDate={startDate}
          endDate={endDate}
        />
      </ChartOuter>
      <ChartDescription description={description} />
    </GraphContainer>
  ) : showEmptyState ? (
    <ScoreChartEmptyState
      latestScoreDate={latestScoreDate}
      category={category}
    />
  ) : null;
};

export default WellbeingTrackerLineChartWrapper;
