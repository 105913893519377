/* eslint-disable import/order */
import React, { useState } from 'react';
import LoadingIndicator from 'Shared/LoadingIndicator';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/client';
import FilterPanel from '../FilterPanel/FilterPanel';
import { useFilterState } from '../FilterState';
import { stripUnusedQueryVariables } from '../FilterState/helpers';
import { getManagerReporting } from '../__generated__/getManagerReporting';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useAnonymityThresholdTracking } from '../helpers/tracking';
import { CourseProgressTable } from './CourseProgressTable';
import { LineManagerProgressTable } from './LineManagerProgressTable';
import { getManagerReportingExport } from './__generated__/getManagerReportingExport';
import { Button } from '@unmind/design-system-components-web';
import { downloadBase64File } from '../helpers';
import { Panel } from './ManagerReporting.styled';
import { ExportModal } from '../Export';

const MANAGER_REPORTING_EXPORT_QUERY = gql`
  query getManagerReportingExport(
    $startDate: Date!
    $endDate: Date!
    $locationIds: [String]
    $departmentIds: [String]
  ) {
    organisationManagerReportingExport(
      input: {
        startDate: $startDate
        endDate: $endDate
        locationIds: $locationIds
        departmentIds: $departmentIds
      }
    ) {
      fileContent
      filePassword
    }
  }
`;

const MANAGER_REPORTING_QUERY = gql`
  query getManagerReporting(
    $startDate: Date!
    $endDate: Date!
    $locationIds: [String]
    $departmentIds: [String]
  ) {
    organisationManagerReporting(
      input: {
        startDate: $startDate
        endDate: $endDate
        locationIds: $locationIds
        departmentIds: $departmentIds
      }
    ) {
      lineManagers
      userProgress {
        id
        user {
          id
          firstName
          lastName
          email
        }
        contentStatus
        contentPercentage
        department {
          name
        }
        location {
          name
        }
        content {
          id
          slug
          title
        }
      }
      courseProgress {
        id
        content {
          id
          title
          slug
        }
        numberOfUsersInProgress
        numberOfUsersCompleted
        percentageOfUsersInProgress
        percentageOfUsersCompleted
      }
    }
  }
`;

export const ManagerReporting = () => {
  const { userGroupFilterState, timeFilterState, timeFilterPreset } =
    useFilterState();
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [downloadPassword, setDownloadPassword] = useState<string | null>(null);

  const nonEmptyUserGroupFilterState = stripUnusedQueryVariables(
    userGroupFilterState || {},
  );

  const { data, loading } = useQuery<getManagerReporting>(
    MANAGER_REPORTING_QUERY,
    {
      variables: {
        ...timeFilterState,
        ...nonEmptyUserGroupFilterState,
        timePeriod: timeFilterPreset,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getExport] = useLazyQuery<getManagerReportingExport>(
    MANAGER_REPORTING_EXPORT_QUERY,
    {
      onCompleted: ({
        organisationManagerReportingExport: { fileContent, filePassword },
      }) => {
        if (fileContent !== null) {
          const fileName = `unmind-manager-reporting-export-${new Date().toISOString()}.zip`;
          setDownloadPassword(filePassword);
          downloadBase64File(fileContent, fileName, 'application/zip');
          setShowPasswordModal(true);
        }
      },
    },
  );

  const onSubmitExport = async () =>
    getExport({
      variables: {
        ...timeFilterState,
        ...nonEmptyUserGroupFilterState,
        timePeriod: timeFilterPreset,
      },
    });

  useAnonymityThresholdTracking({
    loading,
    adminTabType: 'managerReporting',
    hideUserActivity: false,
  });

  if (loading) {
    return (
      <>
        <FilterPanel key="filterPanel" />
        <LoadingIndicator />
      </>
    );
  }

  if (!data?.organisationManagerReporting) {
    return (
      <>
        <ErrorMessage />
      </>
    );
  }

  const {
    organisationManagerReporting: {
      courseProgress,
      lineManagers,
      userProgress,
    },
  } = data;

  return (
    <>
      <FilterPanel key="filterPanel" />
      <Panel>
        <Button onClick={onSubmitExport} label="Export" variant="secondary" />
      </Panel>
      <ExportModal
        open={showPasswordModal}
        onCloseCallback={() => setShowPasswordModal(false)}
        downloadPassword={downloadPassword}
      />
      <>
        {courseProgress === null || courseProgress.length === 0 ? (
          <ErrorMessage description="No course progress to show" />
        ) : (
          <CourseProgressTable
            courseProgress={courseProgress}
            lineManagers={lineManagers}
          />
        )}
      </>
      <>
        {userProgress === null || userProgress.length === 0 ? (
          <></>
        ) : (
          <LineManagerProgressTable userProgress={userProgress} />
        )}
      </>
    </>
  );
};
