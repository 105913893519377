import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategoryGrow = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 22 26" fill="none" {...props}>
    <path
      d="M10.994 14.794c.667.81 1.178 2.387.845 3.654-.556 2.11-2.71 4.264-.867 6.552"
      fill="#FFFFFC"
    />
    <path
      d="M10.994 14.794c.667.81 1.178 2.387.845 3.654-.556 2.11-2.71 4.264-.867 6.552"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.961 20.347a4.217 4.217 0 0 0-1.11-1.111c-1.033-.71-2.233-1.177-3.443-1.521-1.589-.456-3.243-.678-4.887-.867-.178-.022-.389-.022-.489.122-.077.111-.055.256-.022.39.489 2.176 1.888 4.108 3.698 5.408 1.81 1.3 4.02 2.021 6.242 2.21.144.01.3.022.433-.056a.653.653 0 0 0 .245-.3c.277-.544.266-1.221.188-1.81-.122-.855-.366-1.755-.844-2.477l-.01.012z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.817 14.338c4.642 0 8.385-3.765 8.373-8.407v-1.8C18.957 2.367 17.459 1 15.636 1c-.888 0-1.688.333-2.32.866A3.562 3.562 0 0 0 10.993 1h-.133c-.889 0-1.688.333-2.321.866A3.562 3.562 0 0 0 6.219 1a3.575 3.575 0 0 0-3.543 3.132v1.8c-.033 4.641 3.72 8.406 8.363 8.406"
      fill="#FF9699"
    />
    <path
      d="M10.817 14.338c4.642 0 8.385-3.765 8.373-8.407v-1.8C18.957 2.367 17.459 1 15.636 1c-.888 0-1.688.333-2.32.866A3.562 3.562 0 0 0 10.993 1h-.133c-.889 0-1.688.333-2.321.866A3.562 3.562 0 0 0 6.219 1a3.575 3.575 0 0 0-3.543 3.132v1.8c-.033 4.641 3.72 8.406 8.363 8.406"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.039 20.347a4.22 4.22 0 0 1 1.11-1.111c1.033-.71 2.233-1.177 3.443-1.521 1.588-.456 3.243-.678 4.887-.867.178-.022.389-.022.489.122.077.111.055.256.022.39-.489 2.176-1.888 4.108-3.698 5.408-1.81 1.31-4.02 2.021-6.242 2.21-.144.01-.3.022-.433-.056a.653.653 0 0 1-.245-.3c-.277-.544-.266-1.221-.188-1.81.122-.855.366-1.755.844-2.477l.01.012z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategoryGrow;
