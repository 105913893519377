import { rem } from 'polished';
import { NavLink } from 'react-router-dom';
import { css, styled } from 'styles';
import { extraSmall } from '../utils';

const MenuLink = styled(NavLink)<{
  $isInFooter?: boolean;
  $isSelected?: boolean;
}>`
  text-decoration: none;
  display: flex;
  flex-direction: ${({ $isInFooter }) => ($isInFooter ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: max-content;
  position: relative;

  ${extraSmall(css`
    margin-left: ${rem('12px')};
    margin-right: ${rem('12px')};
  `)}

  color: ${({ theme }) => theme.colors.text.primary};

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &:hover:after {
    border-top: ${({ $isInFooter, $isSelected }) =>
      $isInFooter
        ? 'none'
        : css`
            ${rem('2px')} solid ${({ theme }) =>
              $isSelected
                ? theme.colors.text.link
                : theme.colors.text.secondary}
          `};
    transition: border-top 100ms ease-in;
  }

  ${props =>
    props.$isSelected &&
    css`
      color: ${({ theme }) => theme.colors.text.link};

      span {
        color: ${({ theme }) => theme.colors.text.link};
      }
    `}

  ${props =>
    props.$isSelected &&
    extraSmall(css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        border-top: ${rem('2px')} solid ${({ theme }) => theme.colors.text.link};
      }
    `)}
`;

export default MenuLink;
