import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves9 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M310.689 192H0V90.389c5.5 3.52 11.1 7.222 16.799 10.989 17.36 11.475 35.64 23.558 54.78 32.983 29.677 14.617 58.766 6.774 88.499-1.243 29.505-7.955 59.645-16.082 91.624-2.6 26.297 11.086 40.517 33.032 54.075 53.954a920.67 920.67 0 0 0 4.912 7.528z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgWaves9;
