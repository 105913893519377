import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { Errors } from '../../../Shared/Errors';

export interface SeriesDayInputProps {
  id: string;
  dayNumber: number;
  seriesId: string;
  slug: string;
}

export interface SeriesDayVariables {
  slug: string;
  seriesId: string;
  id: string;
}

interface WithSeriesDayProgressResponse {
  singleSeries: {
    id: string;
    totalDays: number;
  };
  seriesDaysCompletedIds: SeriesDaysCompletedIds[];
  seriesSegmentsCompleted: {
    edges: {
      node: {
        segmentId: string;
      };
    }[];
  };
}

interface SeriesDaysCompletedIds {
  seriesId: string;
  dayId: string;
  seriesDay: {
    orderNo: number;
  };
}

type RefetchFunction = () => void;
type RefetchProp = RefetchFunction | null;

export interface WithSeriesDayProgressChildProps {
  completedSegmentIds: string[];
  seriesDaysCompletedIds: SeriesDaysCompletedIds[];
  seriesDayProgressLoading?: boolean;
  seriesDayProgressError?: Errors;
  seriesDayProgressRefetch: RefetchProp;
  seriesCompleted: boolean;
  seriesDayCompleted: boolean;
  lastDayInSeries: boolean;
}

export const withSeriesDayProgressQuery = gql`
  query SeriesDayProgress($id: String!, $slug: String!, $seriesId: String) {
    seriesSegmentsCompleted(by: DATE_CREATED, order: ASC, seriesDayId: $id) {
      edges {
        node {
          segmentId
        }
      }
    }
    singleSeries(slug: $slug) {
      id
      totalDays
    }
    seriesDaysCompletedIds(seriesId: $seriesId) {
      seriesId
      dayId
      seriesDay {
        orderNo
      }
    }
  }
`;

export const withSeriesDayProgress = graphql<
  SeriesDayInputProps,
  WithSeriesDayProgressResponse,
  SeriesDayVariables,
  WithSeriesDayProgressChildProps
>(withSeriesDayProgressQuery, {
  options: ({ slug, id, seriesId }) => ({
    variables: { slug, id, seriesId },
  }),
  props: ({ data, ownProps }) => {
    const loading = get(data, 'loading', true);

    if (data && data.loading) {
      return {
        seriesDayProgressLoading: loading,
        seriesDayProgressRefetch: null,
        completedSegmentIds: [],
        seriesDaysCompletedIds: [],
        seriesCompleted: false,
        seriesDayCompleted: false,
        lastDayInSeries: false,
      };
    }

    if (
      data !== undefined &&
      data.singleSeries !== undefined &&
      data.seriesSegmentsCompleted !== undefined &&
      data.seriesDaysCompletedIds !== undefined
    ) {
      const {
        seriesSegmentsCompleted: { edges: completedSegments = [] },
        refetch,
        seriesDaysCompletedIds,
        singleSeries,
      } = data;

      return {
        seriesDayProgressLoading: false,
        seriesDayProgressRefetch: refetch,
        completedSegmentIds: completedSegments.map(
          ({ node: { segmentId } }) => segmentId,
        ),
        seriesDaysCompletedIds: seriesDaysCompletedIds,
        seriesDayCompleted:
          seriesDaysCompletedIds.findIndex(day => day.dayId === ownProps.id) >
          -1,
        seriesCompleted:
          seriesDaysCompletedIds.length === singleSeries.totalDays,
        lastDayInSeries: ownProps.dayNumber === singleSeries.totalDays,
      };
    }

    return {
      seriesDayProgressError: Errors.ServerError,
      seriesDayProgressLoading: loading,
      seriesDayProgressRefetch: null,
      completedSegmentIds: [],
      seriesDaysCompletedIds: [],
      seriesCompleted: false,
      seriesDayCompleted: false,
      lastDayInSeries: false,
    };
  },
  skip: props =>
    !props.id || !props.slug || !props.dayNumber || !props.seriesId,
});
