import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import PageWithNavigation from '../../Navigation/PageWithNavigation';
import GlobalErrorBoundary from '../GlobalErrorBoundary';
// eslint-disable-next-line @typescript-eslint/tslint/config
import routeConfig, { RouteConfig } from '../routes';
import LastLocationProvider from './LastLocationProvider';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import RedirectToSearch from './RedirectToSearch';
import Route404 from './Route404';
import RouteWithAuthentication from './RouteWithAuthentication';
import RouteWithSubRoute from './RouteWithSubRoute';

interface RouterProps {
  routes?: RouteConfig[];
  renderIfAuthenticating: React.ComponentType<RouteConfig>;
  renderIfUnauthorized: React.ComponentType;
}

const Router = ({
  routes = routeConfig,
  renderIfAuthenticating,
  renderIfUnauthorized,
}: RouterProps) => (
  <GlobalErrorBoundary>
    <LastLocationProvider>
      <PageWithNavigation>
        <Switch>
          {routes.map((route, key) => {
            if (route.redirectIfAuthenticated !== undefined) {
              return (
                <RedirectIfAuthenticated
                  key={key}
                  renderIfAuthenticating={renderIfAuthenticating}
                  {...route}
                />
              );
            }

            if (route.permissions && route.permissions.length > 0) {
              return (
                <RouteWithAuthentication
                  key={key}
                  renderIfAuthenticating={renderIfAuthenticating}
                  renderIfUnauthorized={renderIfUnauthorized}
                  {...route}
                />
              );
            }

            return <RouteWithSubRoute key={key} {...route} />;
          })}
          <Route component={Route404} />
        </Switch>
        <RedirectToSearch />
      </PageWithNavigation>
    </LastLocationProvider>
  </GlobalErrorBoundary>
);

export default compose<RouterProps, Omit<RouterProps, 'client'>>(withApollo)(
  Router,
);
