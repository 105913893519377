import { Select } from 'Shared/Form/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccessibilityLabel } from './styles';

interface DurationDropdownProps {
  duration: number;
  onSelect(field: string, value: number): void;
}

export const DurationDropdown = ({
  duration,
  onSelect,
}: DurationDropdownProps) => {
  const { t: translate } = useTranslation('shared');

  const durationOptions = () => {
    const durations = [10, 15, 30, 60];

    return durations.map(length => ({
      label: translate('add_to_calendar.duration_dropdown.options', {
        length: length.toString(),
      }),
      value: length,
    }));
  };

  return (
    <Select
      aria-label={translate(
        'add_to_calendar.duration_dropdown.accessibility_label',
        { value: duration },
      )}
      LabelComponent={a11yProps => (
        <AccessibilityLabel {...a11yProps}>{''}</AccessibilityLabel>
      )}
      placeholder={'Duration'}
      options={durationOptions()}
      value={duration}
      onChange={(value: number) => onSelect('duration', value)}
    />
  );
};
