import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isMSTeams } from 'utils/MSTeams';
import { OpenExternalLink } from 'icons';
import { rem } from 'polished';

const PrivacyPolicyLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ExternalLinkIcon = styled(OpenExternalLink).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  cursor: pointer;
  width: ${rem(20)};
  height: ${rem(20)};
  padding-top: ${rem(4)};
`;

export const PrivacyPolicy = () => {
  const { t: translate } = useTranslation('account');

  const linkOnClick = () =>
    window.open(
      translate('notification_preference_centre.privacy_policy.link'),
      '_blank',
    );

  return (
    <Trans
      t={translate}
      i18nKey="notification_preference_centre.privacy_policy.text"
      defaults="By selecting your preferences above you are consenting to receive communications from Unmind. Learn about our <styled_link>Privacy Policy</styled_link><external_link_icon></external_link_icon>"
      components={{
        styled_link: (
          <PrivacyPolicyLink onClick={linkOnClick}></PrivacyPolicyLink>
        ),
        external_link_icon: isMSTeams() ? (
          <ExternalLinkIcon onClick={linkOnClick} />
        ) : (
          <>.</>
        ),
      }}
    />
  );
};
