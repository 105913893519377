import React from 'react';
import { IconProps } from '../IconProps';
const SvgSendEnvelope = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#SendEnvelope_svg__clip0_4899_8121)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.946 1.578a2.04 2.04 0 01-.051 1.117l-3.97 11.908A2.03 2.03 0 019.99 16a2.063 2.063 0 01-1.451-.603l-1.92-1.928L4.48 15.04a.8.8 0 01-1.28-.64v-3.456l8.364-6.192a.8.8 0 10-.953-1.286l-7.995 5.92-2.003-1.91a2.043 2.043 0 01.787-3.4L13.307.105a2.045 2.045 0 012.64 1.472h-.001z"
        fill="#FFF9EF"
      />
    </g>
    <defs>
      <clipPath id="SendEnvelope_svg__clip0_4899_8121">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSendEnvelope;
