import { Maybe } from 'graphql/jsutils/Maybe';
import { Namespace } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TWO_MINUTES_IN_MS = 120000;

type TimeOfDayTitleKey =
  | 'personalised_today.header.title_morning'
  | 'personalised_today.header.title_afternoon'
  | 'personalised_today.header.title_evening';

export const useTitle = (name: Maybe<string>) => {
  const { t: translate } = useTranslation<Namespace<'homepage'>>('homepage');

  const getTitleKey = (): TimeOfDayTitleKey => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 0 && hours < 12) {
      return 'personalised_today.header.title_morning';
    }

    if (hours >= 12 && hours < 18) {
      return 'personalised_today.header.title_afternoon';
    }

    return 'personalised_today.header.title_evening';
  };

  const [titleKey, setTitleKey] = useState(getTitleKey());

  useEffect(() => {
    const interval = setInterval(() => {
      setTitleKey(getTitleKey());
    }, TWO_MINUTES_IN_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return translate(titleKey, {
    context: name ? 'personal' : 'generic',
    name: name,
  });
};
