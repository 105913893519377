import { ApolloQueryResult } from '@apollo/client';
import gql from 'graphql-tag';
import { ChildDataProps, graphql } from '@apollo/client/react/hoc';
import { compose, mapProps } from 'recompose';

import getStatus, { Status } from '../../../Shared/getStatus';
import { CheckInScoresFragment } from '../getCheckInScores';
import { CheckInScores } from '../__generated__/CheckInScores';
import { paginatedCheckInScores_paginatedCheckIns_edges_node } from './__generated__/paginatedCheckInScores';

interface CheckInConnection {
  edges: { node: paginatedCheckInScores_paginatedCheckIns_edges_node }[];
  pageInfo: {
    hasNextPage: boolean;
    endCursor: string | null;
  };
  __typename: string;
}

interface Response {
  paginatedCheckIns: CheckInConnection;
}

interface BaseProps {
  status: Status;
}

interface PaginatedProps extends BaseProps {
  paginatedCheckIns: CheckInScores[];
  loadMoreCheckIns(): Promise<ApolloQueryResult<Response> | void>;
  hasNextPage: boolean;
}

export interface CheckinScoresError extends BaseProps {
  status: 'error';
}
export interface CheckinScoresLoading extends BaseProps {
  status: 'loading';
}
export interface CheckinScoresReady extends PaginatedProps {
  status: 'ready';
}
export interface CheckinScoresLoadingMore extends PaginatedProps {
  hasNextPage: true;
  status: 'loadingMore';
}

export type WithPaginatedCheckInsProps =
  | CheckinScoresLoading
  | CheckinScoresReady
  | CheckinScoresLoadingMore
  | CheckinScoresError;

export const PAGINATED_CHECK_IN_SCORES_QUERY = gql`
  query paginatedCheckInScores($first: Int, $after: String) {
    paginatedCheckIns: checkIns(first: $first, after: $after) {
      edges {
        node {
          ...CheckInScores
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CheckInScoresFragment}
`;

export const INITIAL_NUM_CHECK_INS = 3;
export const NUM_LOAD_MORE_CHECK_INS = 10;

type QueryProps = ChildDataProps<Record<string, unknown>, Response>;

export const withPaginatedCheckIns = compose<
  Record<string, unknown>,
  WithPaginatedCheckInsProps
>(
  graphql<
    { first?: number | null },
    Response,
    Record<string, unknown>,
    QueryProps
  >(PAGINATED_CHECK_IN_SCORES_QUERY, {
    options: props => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        first: props.first !== undefined ? props.first : INITIAL_NUM_CHECK_INS,
      },
    }),
  }),
  mapProps(
    ({
      data: { error, fetchMore, networkStatus, paginatedCheckIns },
      ...rest
    }: QueryProps) => {
      const status = error ? 'error' : getStatus(networkStatus);
      const props = { ...rest, status };

      if (paginatedCheckIns) {
        const { edges, pageInfo } = paginatedCheckIns;
        const endCursor = pageInfo.endCursor;

        return {
          ...props,
          paginatedCheckIns: edges.map(edge => edge.node),
          hasNextPage: pageInfo.hasNextPage,
          loadMoreCheckIns: async () =>
            fetchMore({
              variables: { first: NUM_LOAD_MORE_CHECK_INS, after: endCursor },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newEdges = fetchMoreResult.paginatedCheckIns.edges;

                return newEdges.length
                  ? {
                      ...prev,
                      paginatedCheckIns: {
                        __typename: prev.paginatedCheckIns.__typename,
                        edges: [...prev.paginatedCheckIns.edges, ...newEdges],
                        pageInfo: fetchMoreResult.paginatedCheckIns.pageInfo,
                      },
                    }
                  : prev;
              },
              // tslint:disable-next-line: promise-function-async
            }).catch(async e => {
              // TODO: Figure out how to do Sentry logging from unmind-apollo-helpers
              console.error(e);

              return Promise.reject(e);
            }),
        };
      }

      return props;
    },
  ),
);
