import { gql, useQuery } from '@apollo/client';
import { getCountries } from './__generated__/getCountries';

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
    }
  }
`;

export const useCountries = () => {
  const { data, loading, error } = useQuery<getCountries>(GET_COUNTRIES, {
    errorPolicy: 'all',
    returnPartialData: false,
  });

  return { data, loading, error };
};
