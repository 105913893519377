import { gql, useQuery } from '@apollo/client';
import { getEapServices } from './__generated__/getEapServices';

export const GET_EAP_SERVICES = gql`
  query getEapServices {
    helpEAPServices {
      id
      serviceName
      description
      icon
    }
  }
`;

export const useGetEapServices = () =>
  useQuery<getEapServices>(GET_EAP_SERVICES, {
    errorPolicy: 'all',
    returnPartialData: false,
  });
