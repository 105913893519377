import { SingleSeries } from 'Series/SingleSeries/withSingleSeries';
import { AddToCalendar } from 'Shared/AddToCalendar';
import React from 'react';
import BackButton from 'Shared/Button/BackButton';
import {
  HeaderContainer,
  TitleAndSubtitle,
  Title,
  Description,
  AddToCalendarContainer,
} from './styles';

interface CourseHeaderProps {
  course: SingleSeries;
}

export const CourseHeader = ({ course }: CourseHeaderProps) => (
  <HeaderContainer>
    <TitleAndSubtitle>
      <Title>
        <BackButton />
        {course.title}
      </Title>
      <Description>{course.subtitle}</Description>
    </TitleAndSubtitle>
    <AddToCalendarContainer>
      <AddToCalendar
        title={course.title}
        useSmallButton={true}
        description={course.subtitle}
        buttonTextKey={'add_to_calendar.course_reminder_text'}
        contentPath={window.location.pathname}
      />
    </AddToCalendarContainer>
  </HeaderContainer>
);
