import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { IconButton } from '@unmind/design-system-components-web';
import { Arrow } from 'icons';
import { CONVERSATION_SUGGESTIONS_QUERY } from 'Assistant/Chatbot/graphql';
import uuid from 'uuid';
import { Suggestions } from '../../graphql/__generated__/Suggestions';
import { UserMessage } from '../../types';
import { SparklesIcon } from '../styles';
import {
  Container,
  TitleContainer,
  Title,
  LoadingIndicator,
  ErrorAlert,
  ItemIconContainer,
  SuggestionItem,
} from './styles';

const ContentContainer = ({ children }: { children: React.ReactNode }) => {
  const { t: translate } = useTranslation('chatbot');

  return (
    <Container>
      <TitleContainer>
        <SparklesIcon />
        <Title>{translate('suggestions_list.title')}</Title>
      </TitleContainer>
      {children}
    </Container>
  );
};

interface SuggestionsListProps {
  handleSuggestionClick(message: UserMessage): void;
}

/**
 * A Component to display a list of dynamic suggestion collected from the backend
 * to be displayed within the chatbot landing page and not the chat lui itself.
 *
 * @param handleSuggestionClick - Function to handle the click of a suggestion
 * @returns A list of suggestions to be displayed on the chatbot landing page
 */
export const SuggestionsList = ({
  handleSuggestionClick,
}: SuggestionsListProps) => {
  const { t: translate } = useTranslation('shared');
  const { loading, error, data } = useQuery<Suggestions>(
    CONVERSATION_SUGGESTIONS_QUERY,
  );

  if (loading) {
    return (
      <ContentContainer>
        <LoadingIndicator />
      </ContentContainer>
    );
  }

  if (error) {
    return (
      <ContentContainer>
        <ErrorAlert
          variant="error"
          title={translate('errors.messages.failed')}
          size="medium"
        />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <>
        {data?.companionSuggestions?.map(({ label, topic }) => (
          <SuggestionItem
            aria-label={label}
            key={label}
            onClick={() =>
              handleSuggestionClick({
                id: uuid.v4(),
                createdAt: new Date().toISOString(),
                content: label,
                topic: topic,
                starterId: null,
              })
            }
          >
            {label}
            <ItemIconContainer>
              <IconButton icon={Arrow} size="medium" variant="primary" />
            </ItemIconContainer>
          </SuggestionItem>
        ))}
      </>
    </ContentContainer>
  );
};
