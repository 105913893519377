import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { MagicLinkWithoutOrgId } from './__generated__/MagicLinkWithoutOrgId';
import { MagicLogin } from './__generated__/MagicLogin';

export interface UseMagicLoginParams {
  email: string;
  groupId?: string;
}

interface RequestMagicLoginReturnParams {
  success: boolean;
  error?: Error;
}

export const MAGIC_LOGIN_MUTATION = gql`
  mutation MagicLogin($email: String!, $groupId: String!) {
    magicLogin(email: $email, groupId: $groupId)
  }
`;

export const MAGIC_LOGIN_WITHOUT_GROUP_ID_MUTATION = gql`
  mutation MagicLinkWithoutOrgId($email: String!) {
    sendMagicLinkWithoutOrgId(email: $email) {
      success
    }
  }
`;

interface MagicLoginResponse {
  requestMagicLogin(
    params: UseMagicLoginParams,
  ): Promise<RequestMagicLoginReturnParams>;
  loading: boolean;
}

export function useMagicLogin(): MagicLoginResponse {
  const [magicLoginWithOrgId, { loading: magicLoginLoading }] =
    useMutation<MagicLogin>(MAGIC_LOGIN_MUTATION);
  const [magicLoginWithoutOrgId, { loading: magicLoginWithoutOrgIdLoading }] =
    useMutation<MagicLinkWithoutOrgId>(MAGIC_LOGIN_WITHOUT_GROUP_ID_MUTATION);

  const handleError = (e: unknown) => {
    if (e instanceof Error) {
      return {
        success: false,
        error: e,
      };
    }

    return {
      success: false,
    };
  };

  async function requestMagicLoginWithOrgId(
    params: UseMagicLoginParams,
  ): Promise<RequestMagicLoginReturnParams> {
    try {
      const { data } = await magicLoginWithOrgId({
        variables: params,
      });

      return {
        success: Boolean(data && data.magicLogin),
      };
    } catch (e) {
      return handleError(e);
    }
  }

  async function requestMagicLoginWithoutOrgId({
    email,
  }: UseMagicLoginParams): Promise<RequestMagicLoginReturnParams> {
    try {
      const { data } = await magicLoginWithoutOrgId({
        variables: {
          email,
        },
      });

      return {
        success: Boolean(data && data.sendMagicLinkWithoutOrgId?.success),
      };
    } catch (e) {
      return handleError(e);
    }
  }

  const requestMagicLogin = async ({
    groupId = undefined,
    email,
  }: UseMagicLoginParams) => {
    if (groupId) {
      return requestMagicLoginWithOrgId({ email, groupId });
    }

    return requestMagicLoginWithoutOrgId({ email });
  };

  return {
    requestMagicLogin,
    loading: magicLoginLoading || magicLoginWithoutOrgIdLoading,
  };
}
