import { gql, useQuery } from '@apollo/client';
import { getHelpEAPServices } from './__generated__/getHelpEAPServices';

export const GET_EAP_SERVICES = gql`
  query getHelpEAPServices {
    helpEAPServices {
      id
      serviceName
      icon
    }
  }
`;

export const useGetEAPServices = () => {
  const { data, loading, error, refetch } = useQuery<getHelpEAPServices>(
    GET_EAP_SERVICES,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error, refetch };
};
