import React from 'react';
import Lottie from 'react-lottie';
import animation from './audio-animation.json';
interface AudioAnimationProps {
  isPaused: boolean;
}

const AudioAnimation = ({ isPaused }: AudioAnimationProps) => (
  <Lottie
    style={{
      position: 'absolute',
    }}
    options={{
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
    isClickToPauseDisabled={true}
    isPaused={isPaused}
  />
);

export default AudioAnimation;
