import React from 'react';
import { IconProps } from '../IconProps';

const SvgSparkles = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M0 0h16v16H0z" />
    <path d="M-251-509a2 2 0 0 1 2-2h9013c1.1 0 2 .895 2 2v2069c0 1.1-.9 2-2 2H-249c-1.105 0-2-.9-2-2V-509z" />
    <path d="M-62-165h693v343H-62z" />
    <g filter="url(#Sparkles_svg__filter0_d_52_1335)">
      <path
        d="M-16 8c0-14.36 11.64-26 26-26h529c14.359 0 26 11.64 26 26s-11.641 26-26 26H10C-4.36 34-16 22.36-16 8z"
        fill={secondaryColor}
        shapeRendering="crispEdges"
      />
      <g clipPath="url(#Sparkles_svg__clip0_52_1335)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.862.778C3.067-.158 4.398-.164 4.611.77l.01.042.018.083a2.546 2.546 0 0 0 2.044 1.927c.976.17.976 1.57 0 1.74a2.546 2.546 0 0 0-2.046 1.942l-.026.11c-.213.934-1.544.928-1.749-.007l-.02-.095A2.53 2.53 0 0 0 .801 4.56c-.973-.169-.973-1.568 0-1.737A2.53 2.53 0 0 0 2.84.882l.015-.069.008-.035zm6.24 4.737c.255-1.16 1.906-1.168 2.171-.01l.015.068.033.144a4.617 4.617 0 0 0 3.703 3.494c1.206.209 1.206 1.938 0 2.148a4.616 4.616 0 0 0-3.71 3.521l-.042.183c-.263 1.158-1.915 1.151-2.169-.01l-.034-.157a4.591 4.591 0 0 0-3.698-3.541c-1.203-.21-1.203-1.934 0-2.143A4.59 4.59 0 0 0 9.063 5.69l.026-.12.012-.056h.001z"
          fill={primaryColor}
        />
      </g>
      <path
        d="M-15.5 8c0-14.083 11.417-25.5 25.5-25.5h529c14.083 0 25.5 11.417 25.5 25.5S553.083 33.5 539 33.5H10C-4.083 33.5-15.5 22.083-15.5 8z"
        stroke="#0D0300"
        strokeOpacity={0.2}
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M-249-510h9013v-2H-249v2zm9014 1v2069h2V-509h-2zm-1 2070H-249v2h9013v-2zm-9014-1V-509h-2v2069h2zm1 1c-.552 0-1-.45-1-1h-2c0 1.66 1.343 3 3 3v-2zm9014-1c0 .55-.45 1-1 1v2c1.66 0 3-1.34 3-3h-2zm-1-2070c.55 0 1 .448 1 1h2c0-1.657-1.34-3-3-3v2zm-9013-2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2z"
      fill={secondaryColor}
      fillOpacity={0.1}
    />
    <defs>
      <filter
        id="Sparkles_svg__filter0_d_52_1335"
        x={-28}
        y={-22}
        width={605}
        height={76}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={4}
          in="SourceAlpha"
          result="effect1_dropShadow_52_1335"
        />
        <feOffset dy={8} />
        <feGaussianBlur stdDeviation={8} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0509804 0 0 0 0 0.0118954 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_52_1335" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_52_1335"
          result="shape"
        />
      </filter>
      <clipPath id="Sparkles_svg__clip0_52_1335">
        <path fill={secondaryColor} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSparkles;
