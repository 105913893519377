import React from 'react';
import { IconProps } from '../IconProps';

const SvgPause = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 33 44" width="1em" height="1em" {...props}>
    <path
      d="M20.5 1.571C20.5.786 21.281 0 22.063 0h9.375C32.218 0 33 .786 33 1.571V42.43c0 .785-.781 1.571-1.563 1.571h-9.375c-.78 0-1.562-.786-1.562-1.571V1.57zm-20.5 0C0 .786.781 0 1.563 0h9.375c.78 0 1.562.786 1.562 1.571V42.43c0 .785-.781 1.571-1.563 1.571H1.563C.782 44 0 43.214 0 42.429V1.57z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgPause;
