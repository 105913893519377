import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { FullPageGeneralError } from '../Shared/AlertView';

interface ErrorProps {
  children: ReactNode;
  location: RouteComponentProps['location'];
}

interface ErrorState {
  hasError: boolean;
}

class GlobalErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps: ErrorProps) {
    if (
      this.state.hasError &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <FullPageGeneralError />;
    }

    return this.props.children;
  }
}

export default compose<ErrorProps, Record<string, unknown>>(withRouter)(
  GlobalErrorBoundary,
);
