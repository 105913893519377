import React, { Props } from 'react';
import getSubdomainFromUrl, { Subdomain } from '../utils/getSubdomainFromUrl';

export interface ChildProps {
  subdomain: Subdomain;
}

export const withSubdomainFromUrl =
  (Component: React.ComponentType<ChildProps>) =>
  (props: Props<Record<string, unknown>>) =>
    <Component subdomain={getSubdomainFromUrl()} {...props} />;
