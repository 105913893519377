import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaveDark = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 317 732" width="1em" height="1em" {...props}>
    <path
      fill={primaryColor}
      d="M-21.939 965.877C-.901 1189.06 257.726 1417.82 438.119 1390.2s266.255-311.04 191.778-633.034C555.421 435.17 516.86-29.859 336.467-2.24 258.437 9.706 29.137 15.409 31.989 398.503 34.84 781.598-42.977 742.691-21.94 965.877z"
    />
  </svg>
);

export default SvgWaveDark;
