import React from 'react';
import { IconProps } from '../IconProps';
const SvgUserCheck = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#UserCheck_svg__clip0_4880_8135)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 3.2a3.2 3.2 0 116.4 0 3.2 3.2 0 01-6.4 0zm3.2 4.797c-3.06 0-5.603 2.547-5.6 5.603v1.6a.8.8 0 00.8.8h7.043l-1.203-.9a2.6 2.6 0 013.12-4.16l.563.42.243-.325C9.626 9.242 7.738 7.997 5.6 7.997zm10.008 2.317a1 1 0 00-1.6-1.2L10.643 13.6 8.8 12.218a1 1 0 00-1.2 1.6l2.643 1.982a1 1 0 001.4-.2l3.965-5.286z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <clipPath id="UserCheck_svg__clip0_4880_8135">
        <path fill={primaryColor} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUserCheck;
