import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { flattenDictionary } from '../../utils/flattenDictionary';
import { logException } from '../logging';
import { isIrisTokenValid } from './isIrisTokenValid';
import { UserID } from './__generated__/UserID';

export const USER_ID_QUERY = gql`
  query UserID {
    user {
      id
    }
  }
`;

type IrisAuthenticateResponse = { iris_token?: string } | undefined;

function useIrisToken() {
  const { data } = useQuery<UserID>(USER_ID_QUERY);

  const [hasError, setHasError] = useState(false);
  const [irisToken, setIrisToken] = useState<string | undefined>();
  const { isAuthenticated: isAuthenticatedViaAuth0 } = useAuth0();

  useEffect(() => {
    let isEffectMounted = true;

    async function getIrisToken() {
      const userId = data?.user?.id;

      if (userId) {
        const currentIrisToken = irisToken || localStorage.getItem('irisToken');

        if (currentIrisToken && isIrisTokenValid(currentIrisToken, userId)) {
          setIrisToken(currentIrisToken);
          setHasError(false);
        } else {
          const accessToken = localStorage.getItem('token');
          if (accessToken) {
            const irisAuthenticateResponse: IrisAuthenticateResponse =
              await fetch(
                `${process.env.REACT_APP_IRIS_BASE_URL}authenticate`,
                {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    access_token: accessToken,
                    user_id: userId,
                  }),
                  mode: 'cors',
                },
              )
                .then(async res => {
                  const response = await res.json();
                  if (res.ok) {
                    return response;
                  }
                  throw response;
                })
                .catch(error => {
                  if (error instanceof Error) {
                    logException(error);
                  } else {
                    logException(new Error('Iris token fetch error'), {
                      tags: flattenDictionary(error),
                    });
                  }

                  if (isEffectMounted) {
                    setHasError(true);
                  }
                });

            if (isEffectMounted) {
              if (
                irisAuthenticateResponse &&
                irisAuthenticateResponse.iris_token
              ) {
                setIrisToken(irisAuthenticateResponse.iris_token);
                localStorage.setItem(
                  'irisToken',
                  irisAuthenticateResponse.iris_token,
                );
              } else {
                // This *shouldn't* happen... but Iris is unreliable so if it does happen then
                // we better know about it
                setHasError(true);
                logException(new Error('Missing iris token response'));
              }
            }
          }
        }
      }
    }

    if (!isAuthenticatedViaAuth0) {
      void getIrisToken();
    }

    return () => {
      isEffectMounted = false;
    };
  });

  return { hasError, irisToken };
}

export default useIrisToken;
