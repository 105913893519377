import { Dispatch, RefObject, useEffect, useState } from 'react';

type Callback = () => void;

export enum Direction {
  UP = 'up',
  DOWN = 'down',
}

export default function useInfiniteScroll(
  callback: Callback,
  currentElement: RefObject<HTMLElement>,
  direction: Direction = Direction.DOWN,
  offsetHeight?: number,
): { setIsFetching: Dispatch<React.SetStateAction<boolean>> } {
  const [isFetching, setIsFetching] = useState(false);

  const isScrolling = () => {
    const offset = offsetHeight ?? 0;

    if (direction === Direction.DOWN) {
      if (
        currentElement.current &&
        currentElement.current.getBoundingClientRect().bottom >
          window.innerHeight + offset
      ) {
        return;
      }
      setIsFetching(true);
    } else {
      if (
        window.scrollY <= offset // Check if the scroll position is within the offset from the top
      ) {
        setIsFetching(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', isScrolling, true);

    return () => window.removeEventListener('scroll', isScrolling, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching) {
      callback();
      setIsFetching(false);
    }
  }, [callback, isFetching]);

  return { setIsFetching };
}
