import React from 'react';
import { IconProps } from '../IconProps';
const SvgPencil = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#Pencil_svg__clip0_874_102758)">
      <g clipPath="url(#Pencil_svg__clip1_874_102758)">
        <path
          d="M5.848 13.643L1 15l1.357-4.846 8.877-8.83a1.077 1.077 0 011.54 0l1.907 1.917a1.075 1.075 0 010 1.529l-8.833 8.873z"
          stroke={primaryColor}
          strokeOpacity={0.95}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="Pencil_svg__clip0_874_102758">
        <path fill={primaryColor} d="M0 0h16v16H0z" />
      </clipPath>
      <clipPath id="Pencil_svg__clip1_874_102758">
        <path fill={primaryColor} d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPencil;
