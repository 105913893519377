import React from 'react';
import { IconProps } from '../IconProps';
const SvgClock = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64.002a9.997 9.997 0 00-4.924 1.502A10.015 10.015 0 00.512 6.828a9.807 9.807 0 00-.508 3.17c0 .475.012.71.057 1.115a9.986 9.986 0 004.06 6.977c.806.589 1.74 1.07 2.707 1.394a9.797 9.797 0 003.172.51c.476 0 .713-.013 1.116-.057a9.986 9.986 0 005.572-2.503c.14-.124.625-.609.75-.749a9.839 9.839 0 001.522-2.244 9.865 9.865 0 001.032-4.058c0-.049.003-.082.008-.082.006 0 .008-.108.008-.308 0-.204-.002-.307-.008-.304-.005.004-.008-.024-.008-.077 0-.135-.024-.475-.052-.73a9.988 9.988 0 00-1.446-4.169 9.832 9.832 0 00-1.26-1.62 9.832 9.832 0 00-2.79-2.055A9.845 9.845 0 0010.65.017c-.161-.011-.86-.022-1.01-.015zm-.08 1.436a8.486 8.486 0 00-3.384.889 8.393 8.393 0 00-1.832 1.231c-.249.218-.674.651-.9.92a8.559 8.559 0 00-1.96 4.54c-.07.629-.07 1.33 0 1.96a8.544 8.544 0 002.234 4.848c.13.14.427.432.542.536a8.531 8.531 0 005.016 2.175 8.537 8.537 0 006.562-2.267 8.47 8.47 0 001.795-2.373 8.46 8.46 0 00.935-3.9 8.432 8.432 0 00-.916-3.86 8.248 8.248 0 00-1.123-1.692 9.164 9.164 0 00-1.185-1.149 8.567 8.567 0 00-2.14-1.25 8.432 8.432 0 00-2.787-.608c-.185-.01-.67-.01-.857 0zm.332 4.28a.732.732 0 00-.577.495l-.023.068-.002 1.896c-.002 1.611 0 1.905.01 1.957.022.12.077.233.163.336l1.849 2.155c1.278 1.49 1.828 2.124 1.868 2.158a.79.79 0 00.268.136c.056.014.09.018.188.017a.504.504 0 00.19-.021.716.716 0 00.406-1.07 217.45 217.45 0 00-1.779-2.088l-1.74-2.028V8.036c0-1.818 0-1.74-.04-1.858a.708.708 0 00-.186-.273.676.676 0 00-.307-.172.931.931 0 00-.288-.015zM.004 10c0 .172.001.241.002.154a8.112 8.112 0 000-.312C.005 9.758.004 9.83.004 10z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgClock;
