import React from 'react';
import { SkeletonPulse } from 'Shared/Skeleton';
import { rem } from 'polished';
import { styled } from 'styles';

const SkeletonStaticContentGallerySlide = styled(SkeletonPulse)`
  border-radius: ${rem(28)};
  width: 100%;
  aspect-ratio: 2 / 1;
`;

const SkeletonStaticContentGalleryOptions = styled(SkeletonPulse)`
  border-radius: ${rem(10)};
  width: 100%;
  aspect-ratio: 40 / 1;
  margin-bottom: ${rem(24)};
`;

const SkeletonStaticContentGallery = () => (
  <>
    <SkeletonStaticContentGalleryOptions />
    <SkeletonStaticContentGallerySlide />
  </>
);

export default SkeletonStaticContentGallery;
