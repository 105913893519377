import { Maybe } from 'graphql/jsutils/Maybe';
import { rem } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { styled, ThemeInterface } from 'styles';
import { Chevron } from 'icons';
import { tracking } from '../App/Tracking';
import { HeadingText, BodyText } from '../Shared/Typography';

export const TRANSITION_DURATION = 300;

// istanbul ignore next
const Wrapper = styled.div`
  cursor: pointer;
  padding: 0 ${rem(68)} 0 ${rem(16)};
  min-height: ${rem(96)};
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.colors.border.secondary};
`;

const Header = styled.div`
  position: relative;
  padding: ${rem(22)} 0;
  display: flex;
`;

// istanbul ignore next
const Icon = styled<any>(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
  }),
)<{ active: number }>`
  width: ${rem(14)};
  height: ${rem(14)};
  transform: ${({ active }) => (active === 1 ? 'rotate(90deg)' : 'none')};
  transition: transform ${TRANSITION_DURATION}ms ease-in-out;
  position: absolute;
  top: ${rem(38)};
  right: ${rem(-30)};
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin: 0
  text-align: left;
`;

const Summary = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin: 0;
  text-align: left;
`;

// istanbul ignore next
const TransitionWrapper = styled.div<{ active: boolean }>`
  overflow: hidden;
  max-height: ${({ active }) => (active ? rem(3000) : 0)};
  transition: max-height ${TRANSITION_DURATION}ms ease-in-out;
`;

const Body = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'div',
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: left;

  p {
    margin: 0 0 ${rem(8)};
  }
  a {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
  padding-bottom: ${rem('12px')};
`;

const ListItemIcon = styled.img`
  width: ${rem(56)};
  height: ${rem(56)};
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.border.secondary};
`;

// istanbul ignore next
const ListItemIconContainer = styled.div`
  margin-right: ${rem(16)};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const trackLinkClick = (e: Event) => {
  const target = e.target as HTMLLinkElement;

  tracking.track('help-resource-link-clicked', {
    resourceLinkUrl: target.href,
  });
};

export interface ContentListItemProps {
  id: string;
  title: string;
  summary: string;
  body: string;
  active?: boolean;
  icon: string | null;
  iconAsset: Maybe<{
    path: string | null;
  }>;
  assetToken: Maybe<string>;
}

const getIconPath = ({
  icon,
  iconAsset,
  assetToken,
}: Pick<ContentListItemProps, 'icon' | 'iconAsset' | 'assetToken'>) => {
  if (iconAsset?.path && assetToken) {
    return `${iconAsset?.path}?${assetToken}`;
  }

  return icon;
};

const ContentListItem = ({
  title,
  summary,
  body,
  active = false,
  icon,
  iconAsset,
  assetToken,
}: ContentListItemProps) => {
  const bodyContent = useRef<HTMLDivElement>(null);
  const [iconPath, setIconPath] = useState<Maybe<string>>();

  useEffect(() => {
    if (bodyContent && bodyContent.current) {
      const anchorLinks = bodyContent.current.querySelectorAll('[href]');

      anchorLinks.forEach(link => {
        link.addEventListener('click', trackLinkClick);
      });

      return () => {
        anchorLinks.forEach(link => {
          link.removeEventListener('click', trackLinkClick);
        });
      };
    }
  }, []);

  useEffect(() => {
    setIconPath(getIconPath({ icon, iconAsset, assetToken }));
  }, [icon, iconAsset, assetToken]);

  return (
    <Wrapper>
      <Header>
        <ListItemIconContainer>
          <ListItemIcon
            src={iconPath || require('./assets/default_icon.png')}
            alt={title}
            data-testid="content-list-item-media"
          />
        </ListItemIconContainer>
        <ContentContainer>
          <Title data-testid="content-list-item-title">{title}</Title>
          <Summary data-testid="content-list-item-summary">{summary}</Summary>
          {/* Workaround for styled components error: https://github.com/styled-components/styled-components/issues/1198 */}
          <Icon data-testid="content-list-item-icon" active={active ? 1 : 0} />
        </ContentContainer>
      </Header>

      <TransitionWrapper
        active={active}
        data-testid="content-list-item-body"
        aria-hidden={!active}
      >
        <Body
          ref={bodyContent}
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </TransitionWrapper>
    </Wrapper>
  );
};

export default ContentListItem;
