import { UPDATE_USER_MUTATION } from '../Account/withUpdateUser';
import client from '../App/apollo';

export const updateDbUserLocale = async (
  userLocale: string,
  userLocaleOriginal: string,
) => {
  await client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: {
      userInput: { userLocale, userLocaleOriginal },
    },
  });
};
