import React from 'react';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled } from 'styles';
import { PlayShort } from 'icons';
import { useQuery } from '@apollo/client';
import SectionError from 'Shared/SectionError/SectionError';
import SkeletonCard from 'Courses/SkeletonCard';
import HeroBannerShort from 'Explore/HeroBanner/HeroBannerShort/HeroBannerShort';
import SectionHeading from '../SectionHeading';
import { HomeScreenSection } from '../../PersonalisedToday/PersonalisedTodayScreen';
import { getTodayFeaturedDailyBoost } from './__generated__/getTodayFeaturedDailyBoost';
import { Container, FeaturedContentWrapper } from './FeaturedContent';

export const TODAY_FEATURED_DAILY_BOOST_QUERY = gql`
  query getTodayFeaturedDailyBoost {
    dailyBoost {
      tools {
        id
        title
        summary
        slug
        estimatedMins
        cloudinaryThumbnail {
          path
        }
        mediaType {
          value
        }
        exploreCategory {
          id
          internalName
          colourTheme
        }
        isFavourite
      }
    }
  }
`;

const PlayShortIcon = styled(PlayShort).attrs(({ theme }) => ({
  primaryColor: theme.colors.border.primary,
  secondaryColor: theme.colors.brand.primary,
  tertiaryColor: theme.colors.background.input,
  height: 32,
  width: 32,
  'aria-hidden': true,
}))``;

type FeaturedDailyBoostProps = {
  assetToken: string | null | undefined;
};

function isNotNull<T>(element: T | null): element is T {
  return element !== null;
}

const FeaturedDailyBoost = ({ assetToken }: FeaturedDailyBoostProps) => {
  const { t: translate } = useTranslation<Namespace<'homepage'>>('homepage');

  const { data, loading, error, refetch } =
    useQuery<getTodayFeaturedDailyBoost>(TODAY_FEATURED_DAILY_BOOST_QUERY, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
    });

  if (loading) {
    return (
      <Container>
        <SkeletonCard />
      </Container>
    );
  }

  if (error) {
    return <SectionError onRetry={async () => refetch()} />;
  }

  const dailyBoost = data?.dailyBoost?.tools
    ? data?.dailyBoost?.tools.filter(isNotNull)
    : [];

  return dailyBoost.length ? (
    <HomeScreenSection>
      <SectionHeading
        title={translate('managers_today.featured_banner.daily_boost.title')}
        iconComponent={<PlayShortIcon />}
      />

      <FeaturedContentWrapper>
        <HeroBannerShort
          tool={dailyBoost[0]}
          assetToken={assetToken}
          source="today-manager-hero-banner"
        />
      </FeaturedContentWrapper>
    </HomeScreenSection>
  ) : null;
};

export default FeaturedDailyBoost;
