import { rem, rgba } from 'polished';
import React, { ReactNode } from 'react';
import { css, styled, useTheme } from 'styles';
import { extraSmall, small, medium } from '../../utils';
import BodyText from '../Typography/BodyText';

export interface TabProps {
  children: ReactNode;
  active?: boolean;
  locked?: boolean;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  tabWidth?: number;
  fixedWidth?: boolean;
  tabsFillSpace?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  uppercaseLabels: boolean;
  disabled?: boolean;
  hasStatus?: boolean;
  horizontalPadding?: boolean;
  testId?: string;
}

type TabTitleProps = {
  active: boolean;
  tabWidth: number;
  fixedWidth: boolean;
  tabsFillSpace: boolean;
  activeColor: string;
  inactiveColor: string;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
};

export const TAB_WIDTH = 274;
const ANIM_SETTINGS = '100ms ease-in';

const baseBarTransformVisible = 'translateY(0)';
const baseBarTransformHidden = 'translateY(4px)';

const TabTitle = styled.button<TabTitleProps>`
  display: inline-block;
  position: relative;
  padding: 0;
  border: none;
  background: inherit;
  z-index: 0;
  outline: none;
  cursor: pointer;
  flex: 1 0;
  height: 100%;
  white-space: nowrap;

  ${({ tabWidth, fixedWidth, tabsFillSpace }) => css`
    &:first-of-type {
      ${small(css`
        margin-left: ${tabsFillSpace ? `${rem(5)}` : `${rem(16)}`};
      `)}
    }

    &:last-of-type {
      margin-right: ${rem(10)};
      ${small(css`
        margin-right: ${rem(16)};
      `)}
      ${extraSmall(css`
        margin-right: ${rem(16)};
      `)}
    }

    ${!fixedWidth && `flex: auto;`}
    margin: ${fixedWidth
      ? `0`
      : !tabsFillSpace
      ? `0 ${rem(8)} 0 ${rem(8)}`
      : `0 ${rem(16)}`};

    ${medium(css`
      flex: 1 0 ${fixedWidth ? rem(tabWidth) : 'auto'};
    `)}
  `}

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: ${rem(2)};
    background-color: ${({ inactiveColor }) => inactiveColor};
    transform: ${baseBarTransformHidden};
    transition: transform ${ANIM_SETTINGS};

    ${({ active, activeColor }) =>
      active &&
      `
      transform: ${baseBarTransformVisible};
      background-color: ${activeColor};
  `}
  }

  &:hover:after {
    transform: ${baseBarTransformVisible};
  }

  &:focus {
    box-shadow: ${({ activeColor }) =>
      `0 0 0 ${rem(2)} ${rgba(activeColor, 0.5)}`};
    border-radius: ${rem(4)};
    z-index: 1;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const TabLabel = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'label',
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))<{
  active: boolean;
  fixedWidth: boolean;
  activeColor: string;
  inactiveColor: string;
  uppercase: boolean;
  hasStatus: boolean;
  horizontalPadding: boolean;
}>`
  padding: ${({ horizontalPadding }) =>
    horizontalPadding ? `${rem(14)} ${rem(16)}` : `${rem(14)} 0`};
  display: inline-block;
  color: ${({ active, activeColor, inactiveColor }) =>
    active ? activeColor : inactiveColor};
  cursor: pointer;
  text-align: center;
  position: relative;

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${({ horizontalPadding }) => css`
    ${small(css`
      padding: ${horizontalPadding
        ? `${rem(12)} ${rem(18)} ${rem(13)}`
        : `${rem(12)} 0 ${rem(13)} 0`};
    `)}
  `};

  ${({ fixedWidth, hasStatus, horizontalPadding }) => css`
    ${medium(css`
      padding: ${fixedWidth
        ? `${rem(12)} ${rem(24)} ${hasStatus ? rem(9) : rem(13)}`
        : `${hasStatus ? rem(9) : rem(12)} ${horizontalPadding ? rem(24) : 0} ${
            hasStatus ? rem(9) : rem(13)
          }`};
    `)}
  `};
`;

const Tab = ({
  active = false,
  children,
  onClick,
  tabWidth = TAB_WIDTH,
  fixedWidth = true,
  tabsFillSpace = true,
  activeColor,
  inactiveColor,
  uppercaseLabels,
  disabled = false,
  hasStatus = false,
  horizontalPadding = false,
  testId,
}: TabProps) => {
  const theme = useTheme();
  const tabActiveAccent = activeColor ?? theme.colors.text.primary;
  const tabInactiveAccent = inactiveColor ?? theme.colors.text.secondary;

  return (
    <TabTitle
      active={active}
      onClick={onClick}
      tabWidth={tabWidth}
      fixedWidth={fixedWidth}
      tabsFillSpace={tabsFillSpace}
      activeColor={tabActiveAccent}
      inactiveColor={tabInactiveAccent}
      disabled={disabled}
      data-testid={testId}
      role="tab"
      aria-selected={active}
    >
      <TabLabel
        active={active}
        fixedWidth={fixedWidth}
        activeColor={tabActiveAccent}
        inactiveColor={tabInactiveAccent}
        uppercase={uppercaseLabels}
        hasStatus={hasStatus}
        horizontalPadding={horizontalPadding}
      >
        {children}
      </TabLabel>
    </TabTitle>
  );
};

export default Tab;
