import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ContentTile } from '@unmind/design-system-components-web';
import { styled } from 'styles';
import { AddToCalendar } from 'Shared/AddToCalendar';
import { getToolSummaryFromHtml } from 'utils/getToolSummaryFromHtml';
import RoutePath from '../App/RoutePath';
import { tracking } from '../App/Tracking';
import { ContentType, Tool } from '../Explore/types';
import DynamicIcon from '../Shared/DynamicIcon/DynamicIcon';
import { DAILY_BOOST_CATEGORY_SLUG, ShortCardVariants } from './constants';

export const CardActionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardMediaTypeIcon = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  height: ${rem(16)};
  width: ${rem(16)};
`;

const ActionWrapper = styled.div`
  display: flex;
  position: relative;
`;

export interface ShortCardProps {
  tool: Tool;
  showDescription?: boolean;
  showCategory?: boolean;
  assetToken?: string | null;
  source: string;
  variant?: ShortCardVariants;
}

const ShortCard = ({
  tool,
  showDescription = false,
  showCategory = false,
  assetToken,
  source,
  variant = ShortCardVariants.VERTICAL,
}: ShortCardProps) => {
  const { t: translate } = useTranslation('shorts');
  const { state } = useLocation();

  const {
    id,
    title,
    slug,
    estimatedMins,
    cloudinaryThumbnail,
    summary,
    html,
    exploreCategory,
    isFavourite,
  } = tool;

  const location = useLocation<Location>();
  const isDailyBoost = exploreCategory?.slug === DAILY_BOOST_CATEGORY_SLUG;
  const toolMediaPlayerPath = isDailyBoost
    ? `${RoutePath.Shorts}/${DAILY_BOOST_CATEGORY_SLUG}/${tool.slug}`
    : `${RoutePath.Shorts}/${tool.slug}`;

  const trackShortClick = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: estimatedMins,
      contentType: ContentType.short,
      source,
      contentCategoryPath: exploreCategory?.internalName,
      isFavourite,
    });
  };

  const tags = [
    translate('short_card.duration_text', {
      duration: estimatedMins?.toString(),
    }),
  ];

  const addToCalendarSummary =
    summary || (html && getToolSummaryFromHtml(html));

  const informationRequiredForAddToCalendar = title && addToCalendarSummary;

  const calendarContentPath = isDailyBoost
    ? `${RoutePath.Shorts}/${DAILY_BOOST_CATEGORY_SLUG}`
    : `${RoutePath.Shorts}/${tool.slug}`;

  const calendarAction = informationRequiredForAddToCalendar
    ? [
        <ActionWrapper>
          <AddToCalendar
            title={title}
            useSmallButton={true}
            contentPath={calendarContentPath}
            description={addToCalendarSummary}
            displayBorder={false}
            buttonTextKey={'add_to_calendar.text'}
          ></AddToCalendar>
        </ActionWrapper>,
      ]
    : [];

  return (
    <ContentTile
      to={{
        pathname: toolMediaPlayerPath,
        state: {
          ...state,
          onToolPlayerClose: {
            location: {
              pathname: location.pathname,
            },
          },
        },
      }}
      onClick={trackShortClick}
      item={{
        itemType: tool?.mediaType?.value as 'audio' | 'video',
        title: title as string,
        description: summary as string | undefined,
        thumbnailUrl:
          cloudinaryThumbnail?.path && assetToken
            ? `${cloudinaryThumbnail?.path}?${assetToken}`
            : undefined,
        tags:
          showCategory && exploreCategory?.topLevelCategory?.title
            ? [...tags, exploreCategory.topLevelCategory.title]
            : tags,
      }}
      showItemDescription={showDescription}
      variant={variant}
      actions={[...calendarAction]}
    />
  );
};

export default ShortCard;
