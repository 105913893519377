import { OpenExternalLink } from 'icons';
import { rem } from 'polished';
import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import { css, styled } from 'styles';
import BodyText, { Props as BodyTextProps } from './BodyText';

const StyledLink = styled(BodyText).attrs(({ sizes, theme }) => ({
  forwardedAs: 'a',
  weight: theme.typography.fontWeights.bold,
  sizes,
  color: theme.colors.text.link as string,
}))<AnchorHTMLAttributes<HTMLAnchorElement>>`
  ${({ sizes }) =>
    !sizes?.length &&
    css`
      font-size: inherit;
    `}
  display: inline-flex;
  align-items: center;
`;

export const ExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
}))`
  margin-left: ${rem(4)};
  height: ${`${4 / 3}em`};
  width: ${`${4 / 3}em`};
`;

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  sizes?: BodyTextProps['sizes'];
}

const ExternalLink = ({ children, ...props }: ExternalLinkProps) => (
  <StyledLink {...props}>
    {children}
    <ExternalLinkIcon />
  </StyledLink>
);

export default ExternalLink;
