import React from 'react';
import { moodIconMap } from '../../../Shared/MoodFace/MoodFaceIcons';
import { Mood } from '../types';

interface MoodFaceProps {
  mood: Mood;
  height?: number;
  width?: number;
}

const MoodScoreMoodFace = ({ mood, ...props }: MoodFaceProps) => {
  const Face = moodIconMap[mood];

  return <Face {...props} />;
};

export default MoodScoreMoodFace;
