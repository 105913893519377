export enum GridColumns {
  mobile = 4,
  tablet = 8,
  desktop = 12,
}

export enum GridGutters {
  mobile = 16,
  tablet = 24,
}

export enum GridMargins {
  mobile = 16,
  tablet = 36,
  desktop = 52,
}
