import React from 'react';
import { rem } from 'polished';
import { styled } from 'styles';

import { FormikInput } from '../../Shared/Form/Formik';
import { StyledFormFieldLabel } from '../SignUp/Forms/CommonFormStyledComponents';
import FieldError from '../../Shared/Form/FieldError';
import { BodyText } from '../../Shared/Typography';
import { createFontStyle } from '../../Shared/Typography/Base';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DayOrMonthField = styled.div`
  label {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize12,
        weight: theme.typography.fontWeights.regular,
      })};
  }
  max-width: ${rem('72px')};
  margin-right: ${rem('16px')};
  width: 100%;
`;

const YearField = styled.div`
  label {
    ${({ theme }) =>
      createFontStyle({
        size: theme.typography.fontSizes.fontSize12,
        weight: theme.typography.fontWeights.regular,
      })};
  }
  max-width: ${rem('94px')};
  width: 100%;
`;

const StyledLegend = styled(BodyText).attrs(({ color, theme }) => ({
  color: color ? color : theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'legend',
}))``;

const StyledFieldSet = styled.fieldset`
  margin-top ${rem('8px')};
  padding: 0;
  border: none;
  width: 100%;
`;

interface Props {
  label: string;
  error?: string;
  // we use this so we can enable autocomplete on the fields
  // to meet AA a11y standards
  isDateOfBirth?: boolean;
}

const DateInput = ({ label, error, isDateOfBirth }: Props) => (
  <StyledFieldSet>
    <StyledLegend>{label}</StyledLegend>
    <Wrapper id="date">
      <DayOrMonthField>
        <StyledFormFieldLabel htmlFor="dateMonth">
          {'Month'}
        </StyledFormFieldLabel>
        <FormikInput
          name="dateMonth"
          id="dateMonth"
          placeholder={'MM'}
          aria-label="month"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          hideErrorText={true}
          autoComplete={isDateOfBirth ? 'bday-month' : undefined}
        />
      </DayOrMonthField>
      <DayOrMonthField>
        <StyledFormFieldLabel htmlFor="dateDay">{'Day'}</StyledFormFieldLabel>
        <FormikInput
          name="dateDay"
          id="dateDay"
          placeholder={'DD'}
          aria-label="day"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          hideErrorText={true}
          autoComplete={isDateOfBirth ? 'bday-day' : undefined}
        />
      </DayOrMonthField>
      <YearField>
        <StyledFormFieldLabel htmlFor="dateYear">{'Year'}</StyledFormFieldLabel>
        <FormikInput
          name="dateYear"
          id="dateYear"
          placeholder={'YYYY'}
          aria-label="year"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          hideErrorText={true}
          autoComplete={isDateOfBirth ? 'bday-year' : undefined}
        />
      </YearField>
    </Wrapper>
    {error && <FieldError message={error} hiddenA11yText="error" />}
  </StyledFieldSet>
);

export default DateInput;
