/* eslint-disable import/order */
import { Button, HeadingText } from '@unmind/design-system-components-web';
import React from 'react';
import Modal, {
  ModalContent,
  ModalCopy,
  ModalProps,
  ModalActions,
} from 'Shared/Modal';
import { CopyLink } from './CopyLink';
import { styled } from 'styles';
import { rem } from 'polished';

export interface ExportModalProps
  extends Pick<ModalProps, 'open' | 'onCloseCallback'> {
  downloadPassword: string | null;
}

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
}))`
  display: flex;
  padding: 0 0 ${rem(8)} 0;
  margin-top: ${rem(8)};
`;

export const ExportModal = ({
  open,
  onCloseCallback,
  downloadPassword,
}: ExportModalProps) => (
  <Modal
    open={open}
    closeHeader={true}
    onCloseCallback={onCloseCallback}
    modalContent={({ close }) => (
      <>
        <ModalContent>
          <Title>Export Password</Title>
          <ModalCopy>
            A password-protected zip file has been downloaded. The password is:
            <CopyLink value={downloadPassword} />
          </ModalCopy>
        </ModalContent>
        <ModalActions>
          <Button
            variant="secondary"
            size="small"
            label="Done"
            onClick={close}
          />
        </ModalActions>
      </>
    )}
  ></Modal>
);
