import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { isNil } from 'lodash';
import {
  ShowPrivacyConsentStepOnSignup,
  ShowPrivacyConsentStepOnSignupVariables,
} from './__generated__/ShowPrivacyConsentStepOnSignup';

export const USE_SHOW_PRIVACY_CONSENT_STEP_QUERY = gql`
  query ShowPrivacyConsentStepOnSignup($subDomain: String!) {
    showPrivacyConsentStepOnSignup(subDomain: $subDomain) {
      showPrivacyConsentStepOnSignup
      showEEAExplicitDataStorageConsent
    }
  }
`;

interface useShowPrivacyConsentStepOnSignupOutput {
  showPrivacyConsentStep?: boolean;
  showEEAExplicitDataStorageConsent?: boolean;
  loading: boolean;
}

export const useShowPrivacyConsentStepOnSignup = (
  subDomain: string,
): useShowPrivacyConsentStepOnSignupOutput => {
  const { data, loading } = useQuery<
    ShowPrivacyConsentStepOnSignup,
    ShowPrivacyConsentStepOnSignupVariables
  >(USE_SHOW_PRIVACY_CONSENT_STEP_QUERY, { variables: { subDomain } });

  const showPrivacyConsentStepOnSignup =
    data?.showPrivacyConsentStepOnSignup?.showPrivacyConsentStepOnSignup;
  const showEEAExplicitDataStorageConsent =
    data?.showPrivacyConsentStepOnSignup?.showEEAExplicitDataStorageConsent;
  const show = isNil(showPrivacyConsentStepOnSignup)
    ? true
    : showPrivacyConsentStepOnSignup;

  const showEEAExplicitDataStorageConsentEvenIfNull = isNil(
    showEEAExplicitDataStorageConsent,
  )
    ? true
    : showEEAExplicitDataStorageConsent;

  return {
    showPrivacyConsentStep: loading ? undefined : show,
    showEEAExplicitDataStorageConsent: loading
      ? undefined
      : showEEAExplicitDataStorageConsentEvenIfNull,
    loading,
  };
};
