import React from 'react';
import { IconProps } from '../IconProps';

const SvgEyeFormInput = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.152 8c.513 1.046 1.59 2.409 3.176 3.602C7.195 13.008 9.533 14 12 14c2.467 0 4.805-.992 6.672-2.398 1.585-1.194 2.663-2.556 3.176-3.602-.513-1.046-1.59-2.409-3.176-3.602C16.805 2.992 14.467 2 12 2c-2.467 0-4.805.992-6.672 2.398C3.743 5.59 2.665 6.954 2.152 8zM24 8c-1 3.2-6 8-12 8S1 11.2 0 8c1-3.2 6-8 12-8s11 4.8 12 8z"
      fill={secondaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
      fill={secondaryColor}
    />
  </svg>
);

export default SvgEyeFormInput;
