import React from 'react';
import { IconProps } from '../IconProps';
const SvgGoogle = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 22" fill="none" {...props}>
    <path
      d="M18.8 11.209c0-.65-.058-1.275-.167-1.875H10v3.55h4.933a4.226 4.226 0 01-1.841 2.758v2.308h2.975C17.8 16.35 18.8 14 18.8 11.21z"
      fill="#4285F4"
    />
    <path
      d="M10 20.167c2.475 0 4.55-.817 6.067-2.217l-2.975-2.308c-.817.55-1.859.883-3.092.883-2.383 0-4.408-1.608-5.133-3.775h-3.05v2.367c1.508 2.991 4.6 5.05 8.183 5.05z"
      fill="#34A853"
    />
    <path
      d="M4.867 12.742A5.493 5.493 0 014.575 11c0-.608.108-1.192.292-1.742V6.892h-3.05A9.053 9.053 0 00.833 11c0 1.483.359 2.875.984 4.108l2.375-1.85.675-.516z"
      fill="#FBBC05"
    />
    <path
      d="M10 5.484c1.35 0 2.55.466 3.508 1.366l2.625-2.625C14.542 2.742 12.475 1.834 10 1.834c-3.583 0-6.675 2.058-8.183 5.058l3.05 2.366C5.592 7.092 7.617 5.484 10 5.484z"
      fill="#EA4335"
    />
  </svg>
);
export default SvgGoogle;
