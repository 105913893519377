import { useMutation, useQuery } from '@apollo/client';
import { Loader } from '@unmind/design-system-components-web';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace, ParseKeys, TFunction } from 'i18next';
import { PRACTITIONER_COUNTRIES_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import {
  practitionerCountriesQuery,
  practitionerCountriesQuery_practitionerCountries,
} from 'Services/BeGateway/Practitioner/__generated__/practitionerCountriesQuery';
import { USER_COUNTRY_STATE_QUERY } from 'Services/User/user.services';
import {
  COUNTRIES_REQUIRING_STATES,
  useLocationData,
  countryRequiresState,
  CANADA,
} from 'Talk/hooks/useLocationData';
import { UPDATE_USER_MUTATION } from '../../../Account/withUpdateUser';
import { ButtonSize } from '../../../Shared/Button';
import { SingleSelect } from '../../../Shared/Form/SingleSelect';
import { BEGatewayQueryContext } from '../../../utils/apollo';
import states from '../../lib/constants/states.json';
import { FormFields, SubmitButton, LoadingContainer } from './styles';

export interface UserLocationInput {
  country: string;
  state: string | null;
}

interface Opts {
  value: string;
  label: string;
}

interface Props {
  onSubmitSuccess?(country: Opts, state: Opts): void;
  OverrideSubmitButton?: React.ReactFragment;
}

function getStateSelectionCopy(
  countryName: string,
  t: TFunction<Namespace<'talk'>>,
): string {
  switch (countryName) {
    case CANADA:
      return t(`location_modal.select_province`);
      break;
    default:
      return t(`location_modal.select_state`);
  }
}

export const SetLocationForm = ({
  onSubmitSuccess,
  OverrideSubmitButton,
}: Props) => {
  const { t: translate } = useTranslation<Namespace<'talk'>>('talk');
  const { t: translateCountry } =
    useTranslation<Namespace<'country'>>('country');

  const [mutateUserCountryAndState, { loading }] =
    useMutation(UPDATE_USER_MUTATION);

  const { userLocationData, loading: userLocationDataLoading } =
    useLocationData();
  const {
    data: countriesQueryData,
    loading: practitionerCountriesDataLoading,
  } = useQuery<practitionerCountriesQuery>(PRACTITIONER_COUNTRIES_QUERY, {
    ...BEGatewayQueryContext,
  });
  const countries = countriesQueryData?.practitionerCountries;

  const getCountryLabel = useCallback(
    (country: practitionerCountriesQuery_practitionerCountries) => {
      if (country?.isoCodeTwo) {
        return translateCountry(
          country?.isoCodeTwo as ParseKeys<Namespace<'country'>>,
        );
      }

      if (country?.name) {
        return country?.name;
      }

      return '';
    },
    [translateCountry],
  );

  const countryData = useMemo(() => {
    const countryOptions =
      countries && countries?.length > 0
        ? countries?.map(country => ({
            value: country?.name ?? '',
            label: getCountryLabel(country),
          }))
        : [];
    countryOptions.sort((a, b) => a.label.localeCompare(b.label));

    return countryOptions;
  }, [countries, getCountryLabel]);

  const getStateData = (country: string) => {
    if (countryRequiresState(country)) {
      return states[country] && states[country].length > 0
        ? states[country].map(state => ({
            label: state.label,
            value: state.value,
          }))
        : [];
    }

    return [];
  };

  const initialValues = {
    country: {
      value: '',
      label: translate('location_modal.select_country'),
    },
    state: {
      value: '',
      label: translate('location_modal.select_state'),
    },
  };

  const getInitialValues = () => {
    const state = userLocationData?.user?.state?.value;
    const country = userLocationData?.user?.country?.value;
    const selectedCountry = countryData.find(
      option => option.value === country,
    );

    if (selectedCountry && state) {
      return {
        country: selectedCountry,
        state: {
          value: state,
          label: state,
        },
      };
    } else if (selectedCountry) {
      return {
        ...initialValues,
        country: selectedCountry,
      };
    } else {
      return initialValues;
    }
  };

  const saveLocationData = async (country: Opts, state: Opts) => {
    let userInput: UserLocationInput = { country: country.value, state: null };
    if (COUNTRIES_REQUIRING_STATES.includes(country.value)) {
      userInput = {
        ...userInput,
        state: state.value,
      };
    }
    await mutateUserCountryAndState({
      variables: {
        userInput,
      },
      update: cache => {
        cache.writeQuery({
          query: USER_COUNTRY_STATE_QUERY,
          data: {
            user: {
              __typename: 'User',
              id: userLocationData?.user?.id,
              country: {
                __typename: 'UserAttribute',
                value: userInput.country,
              },
              state: {
                __typename: 'UserAttribute',
                value: userInput.state,
              },
            },
          },
        });
      },
    });
  };

  if (userLocationDataLoading || practitionerCountriesDataLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={async (values, actions) => {
        /**
         * Save location country and state
         */
        await saveLocationData(values.country, values.state);

        actions.setSubmitting(false);

        /**
         * Perform any callbacks provided on submit success
         */
        onSubmitSuccess?.(values.country, values.state);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const isDisabled =
          values.country.value === '' ||
          (COUNTRIES_REQUIRING_STATES.includes(values.country.value) &&
            !values.state.value);

        return (
          <form onSubmit={handleSubmit}>
            <FormFields>
              <SingleSelect
                inputId="country"
                options={countryData}
                placeholder={translate('location_modal.select_country')}
                onChange={e => {
                  setFieldValue('country', e);
                  setFieldValue('state', '');
                }}
                value={values.country}
                isInModal={true}
              />
              {COUNTRIES_REQUIRING_STATES.includes(values.country.value) && (
                <SingleSelect
                  inputId="state"
                  options={getStateData(values.country.value)}
                  placeholder={getStateSelectionCopy(
                    values.country.value,
                    translate,
                  )}
                  onChange={e => {
                    setFieldValue('state', e);
                  }}
                  value={values.state}
                  isInModal={true}
                />
              )}
              {!!OverrideSubmitButton ? (
                OverrideSubmitButton
              ) : (
                <SubmitButton
                  label={translate('location_modal.button')}
                  type="submit"
                  size={ButtonSize.medium}
                  disabled={isDisabled || loading}
                  loading={loading}
                />
              )}
            </FormFields>
          </form>
        );
      }}
    </Formik>
  );
};
