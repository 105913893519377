import { Chevron } from 'icons';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { small } from 'utils';

export const BreadcrumbContainer = styled.div`
  display: none;
  align-items: center;
  gap: ${rem(12)};

  ${small(css`
    display: flex;
  `)}
`;

export const Breadcrumb = styled(Link).attrs<{ disabled: boolean }>(
  ({ disabled }) => ({
    tabIndex: disabled ? -1 : 0,
    'aria-hidden': disabled,
  }),
)<{ disabled: boolean }>`
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14}px;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: none;
          pointer-events: none;
        `
      : ''}
`;

export const Separator = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
  secondaryColor: theme.colors.text.link,
  tabIndex: -1,
  'aria-hidden': true,
  'data-testid': 'separator',
}))`
  height: ${rem(12)};
`;
