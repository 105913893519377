import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import { Download, Forward } from 'icons';
import { WrappingButton } from 'Shared/Accessibility';
import {
  BodyText,
  ButtonSize,
  Container,
} from '@unmind/design-system-components-web';
import useResizeObserver from '@react-hook/resize-observer';
import { cheatSheetAlbumBySlug_cheatSheetAlbum as CheatSheetAlbumContent } from 'Services/CheatSheets/__generated__/cheatSheetAlbumBySlug';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { extraSmall } from 'utils';
import baseButtonStyles from 'Shared/Button/BaseStyles';
import { tracking } from 'App/Tracking';
import { ContentType } from 'Explore/types';
import { LOG_EVENT_MUTATION } from 'App/Tracking/serverside/withLogEvent';
import { useMutation } from '@apollo/client';
import { EventName } from '__generated__/globalTypes';
import Feedback from './Feedback';

const ArrowForwardIcon = styled(
  ({ canGoForward, ...props }: { canGoForward: boolean }) => (
    <Forward {...props} />
  ),
).attrs(({ theme, canGoForward }) => ({
  primaryColor: theme.button.primary.default.textColor,
  secondaryColor: canGoForward
    ? theme.button.primary.default.backgroundColor
    : theme.button.primary.disabled.backgroundColor,
  height: 28,
  width: 28,
}))<{ canGoForward: boolean }>`
  transition: fill 150ms ease-out;

  &:hover {
    circle:first-child {
      fill: ${({ theme, canGoForward }) =>
        canGoForward
          ? theme.button.primary.default.hover.backgroundColor
          : theme.button.primary.disabled.hover.backgroundColor};
    }
  }
`;

const ArrowBackIcon = styled(
  ({ canGoBack, ...props }: { canGoBack: boolean }) => <Forward {...props} />,
).attrs(({ theme, canGoBack }) => ({
  primaryColor: theme.button.primary.default.textColor,
  secondaryColor: canGoBack
    ? theme.button.primary.default.backgroundColor
    : theme.button.primary.disabled.backgroundColor,
  height: 28,
  width: 28,
}))<{ canGoBack: boolean }>`
  transition: fill 150ms ease-out;
  transform: rotate(-180deg);

  &:hover {
    circle:first-child {
      fill: ${({ theme, canGoBack }) =>
        canGoBack
          ? theme.button.primary.default.hover.backgroundColor
          : theme.button.primary.disabled.hover.backgroundColor};
    }
  }
`;

const GalleryImage = styled.img`
  border-radius: ${rem(16)};
  object-fit: fill;
  width: inherit;
  height: inherit;
`;

const GalleryViewport = styled.div`
  overflow-x: hidden;
  position: relative;
  width: 100%;

  &::after {
    box-sizing: content-box;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const SlideTrack = styled.ol<{
  activeSlide: number;
  slideWidth: number;
}>`
  transition: transform 300ms ease-in-out;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  ${({ activeSlide, slideWidth }) =>
    `
      transform:
        translateX(50%)
        translateX(${rem(-slideWidth * 0.5)})
        /* This moves the slide displayed based upon which is active*/
        translateX(${rem(-activeSlide * (16 * 2 + slideWidth))})
        /* This is to allow next and previous slide to be partially in view */
        translateX(${rem(-16)})
      ;
    `};

  > * {
    display: inline-block;
    position: absolute;
    vertical-align: middle;
  }
`;

const Slide = styled.li<{
  active: boolean;
  width: number;
}>`
  margin: ${rem(12)} ${rem(16)};
  overflow: hidden;
  position: relative;
  transition: transform 300ms ease-in-out;
  z-index: ${({ active }) => (active ? 1 : 0)};
  width: ${({ width }) => rem(width)};
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 2;
`;

const ControlText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  display: inline-block;
  margin: 0px 10px;
`;

const OptionsWrapper = styled(Container)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 12px;

  ${extraSmall(css`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

const ControlButton = styled(WrappingButton)`
  display: flex;
  border-radius: 50%;

  &:focus {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.button.borderFocusColor};
  }
`;

interface StaticContentGalleryProps {
  content: CheatSheetAlbumContent;
  assetToken?: string | null;
  leftMenuOption?: JSX.Element;
  onItemChange?({
    contentId,
    currentPosition,
    remainingItems,
  }: {
    contentId: string;
    currentPosition: number;
    remainingItems: number;
  }): void;
}

const VisuallyHiddenLiveRegion = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const DownloadLink = styled.a.attrs(({ theme }) => ({
  styleProps: theme.button.primary.default,
  size: ButtonSize.small,
}))`
  ${baseButtonStyles}
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-self: end;
  justify-content: center;
`;

const DownloadIcon = styled(Download).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.textColor,
  height: 12,
  width: 12,
}))`
  margin-right: 6px;
`;

const DownloadLinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.regular,
  color: theme.button.primary.default.textColor,
}))``;

const StaticContentGallery = ({
  content,
  assetToken,
  leftMenuOption,
  onItemChange,
}: StaticContentGalleryProps) => {
  const images = content.items;
  const downloadPath = content.download?.path;
  const { t: translate } = useTranslation('cheat_sheets');
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const isMultiSlideGallery = images.length > 1;
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const canSlidePrevious = activeSlide > 0;
  const canSlideNext = activeSlide < images.length - 1;

  const [galleryWidth, setGalleryWidth] = useState<number>(0);
  const galleryWrapperRef = useRef<HTMLDivElement>(null);

  const updateSizes = () => {
    if (galleryWrapperRef.current) {
      setGalleryWidth(galleryWrapperRef.current.clientWidth);
    }
  };

  useLayoutEffect(() => {
    updateSizes();
  }, []);

  useEffect(() => {
    const currentSlidePosition = activeSlide + 1;
    const remainingSlides = images.length - currentSlidePosition;

    onItemChange?.({
      contentId: images[activeSlide].id,
      currentPosition: currentSlidePosition,
      remainingItems: remainingSlides,
    });
  }, [activeSlide, images, onItemChange]);

  useResizeObserver(galleryWrapperRef, () => {
    updateSizes();
  });

  // Set width of a frame to be 80% of the parent viewport no matter screen size.
  const slideWidth = (galleryWidth / 10) * 6;

  const isActiveSlide = (idx: number) => idx === activeSlide;

  const slidePrevious = () => {
    if (canSlidePrevious) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const slideNext = () => {
    if (canSlideNext) {
      setActiveSlide(activeSlide + 1);
    }
  };

  const keyboardControl = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
      e.preventDefault();
      slideNext();
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
      e.preventDefault();
      slidePrevious();
    }
  };

  const tabControl = (e: React.FocusEvent<HTMLElement>, idx: number) => {
    if (idx > activeSlide) {
      slideNext();
    } else if (idx < activeSlide) {
      slidePrevious();
    }
  };

  return (
    <GalleryWrapper ref={galleryWrapperRef}>
      <OptionsWrapper>
        {leftMenuOption}
        {isMultiSlideGallery ? (
          <ControlsWrapper
            onKeyDown={keyboardControl}
            data-testid="static-gallery-controls"
          >
            <ControlButton
              aria-label={translate(
                'static_content_gallery.controls.previous_button.a11y_label',
              )}
              onClick={slidePrevious}
              aria-disabled={!canSlidePrevious}
            >
              <ArrowBackIcon canGoBack={canSlidePrevious} />
            </ControlButton>
            <ControlText aria-hidden="true">{`${activeSlide + 1} / ${
              images.length
            }`}</ControlText>
            <ControlButton
              aria-label={translate(
                'static_content_gallery.controls.next_button.a11y_label',
              )}
              onClick={slideNext}
              aria-disabled={!canSlideNext}
            >
              <ArrowForwardIcon canGoForward={canSlideNext} />
            </ControlButton>
          </ControlsWrapper>
        ) : null}

        {downloadPath ? (
          <DownloadLink
            href={`${downloadPath}?${assetToken}`}
            download
            target="_blank"
            onClick={async () => {
              const properties = {
                contentId: content.id,
                contentSlug: content.slug,
                contentType: ContentType.cheatSheet,
              };

              tracking.track('content-download-clicked', {
                contentDownloadAction: 'downloaded',
                ...properties,
              });

              await logEvent({
                variables: {
                  input: {
                    eventName: EventName.CONTENT_DOWNLOADED,
                    brazeProperties: properties,
                    eventProperties: properties,
                  },
                },
              });
            }}
          >
            <DownloadIcon />
            <DownloadLinkText>
              {translate('static_content_gallery.download_button.label')}
            </DownloadLinkText>
          </DownloadLink>
        ) : null}
      </OptionsWrapper>

      <GalleryViewport>
        <SlideTrack
          role="listbox"
          aria-orientation="horizontal"
          activeSlide={activeSlide}
          slideWidth={slideWidth}
        >
          {images.map((image, idx) => (
            <Slide
              role="listitem"
              active={isActiveSlide(idx)}
              width={slideWidth}
              key={image.id}
              tabIndex={0}
              onFocus={e => tabControl(e, idx)}
            >
              <GalleryImage
                src={`${image.cloudinaryMedia.path}?${assetToken}`}
                alt={image.altText ?? ''}
                aria-hidden={!isActiveSlide(idx)}
                aria-selected={isActiveSlide(idx)}
              />
            </Slide>
          ))}
        </SlideTrack>
        <Feedback
          contentId={images[activeSlide].id}
          contentSlug={content.slug}
        />
        <VisuallyHiddenLiveRegion aria-live="polite" aria-atomic="true">
          {translate('static_content_gallery.screen_reader_content', {
            active: activeSlide + 1,
            length: images.length,
          })}
        </VisuallyHiddenLiveRegion>
      </GalleryViewport>
    </GalleryWrapper>
  );
};

export default StaticContentGallery;
