import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Breadcrumbs } from 'Talk/components/Breadcrumbs';
import Container from '../../../Shared/Container';
import { extraSmall, medium, small } from '../../../utils';

export const PageWrapper = styled(Container)`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(32)};

  ${small(css`
    margin-bottom: ${rem(40)};
    margin-top: ${rem(48)};
  `)};
`;

export const Page = styled.div`
  background: ${props => props.theme.colors.background.primary};
  display: flex;
  flex-direction: column;
`;

export const PractitionerBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: ${rem(32)};
`;

export const BookingAlertContainer = styled.div`
  margin-bottom: 40px;
`;

export const LocationAlertContainer = styled.div`
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  ${extraSmall(css`
    place-items: flex-start;
  `)};

  ${small(css`
    place-items: flex-start;
    grid-template-columns: minmax(0, 280px) 1fr;
    grid-gap: 40px;
  `)};

  ${medium(css`
    place-items: flex-start;
    grid-template-columns: minmax(0, 350px) 1fr;
    grid-gap: 40px;
  `)};
`;

export const StickyColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${small(css`
    position: sticky;
    top: calc(59px + ${rem(40)}); // Top Nav + Page padding
  `)}
`;
