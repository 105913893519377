import {
  IconProps,
  NavCompanion,
  NavCompanionActive,
  NavExplore,
  NavExploreActive,
  NavTalk,
  NavTalkActive,
  NavTodayActive,
  NavTodayInactive,
  NavTrack,
  NavTrackActive,
} from 'icons';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { BorderRadii } from '@unmind/design-system-theme';
import { css, styled } from 'styles';
import { BodyText, extraSmall } from '@unmind/design-system-components-web';
import {
  useUpcomingBookingsAndRequests,
  useLocationData,
  useUnmindTalk,
} from 'Talk/hooks';
import useIsManagerExperienceActive from 'Home/Managers/useIsManagerExperienceActive';
import { isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { useOrganisationEntitlements } from 'Shared/Entitlement';
import RoutePath from '../App/RoutePath';
import useFeatureFlag, { FEATURE_FLAGS } from '../flags/useFeatureFlag';
import { small } from '../utils';
import MenuLink from './MenuLink';

const Wrapper = styled.nav<{ isInFooter: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${p => (p.isInFooter ? 'space-evenly' : 'flex-start')};
  flex-wrap: wrap;
  padding-top: ${p => (p.isInFooter ? rem(12) : rem(0))};
  padding-bottom: ${p => (p.isInFooter ? rem(12) : rem(0))};
  height: ${p => (p.isInFooter ? 'auto' : rem(48))};

  ${extraSmall(css`
    height: auto;
    justify-content: 'center';
    flex-wrap: nowrap;
  `)}
`;

const LinkTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
}))`
  position: relative;
  padding-top: ${rem(7)};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  ${small(css`
    bottom: ${rem(3)};
  `)}
`;

const MenuItem = styled.div<{ displayedInMSTeams: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin-right: ${({ displayedInMSTeams }) =>
    displayedInMSTeams ? rem(10) : rem(0)};
  width: max-content;
  min-width: ${({ displayedInMSTeams }) =>
    displayedInMSTeams ? rem(0) : rem(60)};
  justify-content: center;

  &.active span {
    color: ${({ theme }) => theme.colors.text.link};
  }
`;

const Label = styled.div`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.background.skeleton};
  padding: ${rem(2)} ${rem(5)};
  border-radius: ${BorderRadii.radius16}px;
  margin-left: ${rem(3)};
  margin-top: ${rem(3.2)};
  ${small(css`
    margin-top: 0;
  `)}
`;

const LabelText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize9],
}))`
  color: ${({ theme }) => theme.colors.text.primary} !important;
`;

export enum MainMenuFeatures {
  TODAY = 'today',
  EXPLORE = 'explore',
  TRACK = 'track',
  TALK = 'talk',
  CHATBOT = 'chatbot',
}

export interface MainMenuProps {
  isInFooter: boolean;
}

interface LinkConfig {
  name: string;
  to: string;
  Icon: React.FC<IconProps>;
  shouldHide?: boolean;
  feature?: MainMenuFeatures;
  label?: string;
}

const useActiveFeature = () => {
  const location = useLocation();
  const featurePath = `/${location.pathname.split('/')[1]}`;
  const [featureHistory, setFeatureHistory] = useState<string[]>([]);
  const [activeFeature, setActiveFeature] = useState<
    MainMenuFeatures | undefined
  >(undefined);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (featurePath !== featureHistory[featureHistory.length - 1]) {
      setFeatureHistory([...featureHistory, featurePath]);
    }

    switch (featurePath) {
      case RoutePath.Home:
      case RoutePath.CheatSheet:
        setActiveFeature(MainMenuFeatures.TODAY);
        break;
      case RoutePath.Explore:
      case RoutePath.YourCourses:
        setActiveFeature(MainMenuFeatures.EXPLORE);
        break;
      case RoutePath.Track:
      case RoutePath.UnmindIndex:
      case RoutePath.CheckIn:
        setActiveFeature(MainMenuFeatures.TRACK);
        break;
      case RoutePath.Talk:
        setActiveFeature(MainMenuFeatures.TALK);
        break;
      case RoutePath.ChatbotLanding:
        setActiveFeature(MainMenuFeatures.CHATBOT);
        break;
      case RoutePath.Courses:
      case RoutePath.Series:
        const trackFeatureIndex = featureHistory.lastIndexOf(RoutePath.Track);
        const exploreFeatureIndex = featureHistory.lastIndexOf(
          RoutePath.Explore,
        );
        const todayFeatureIndex = featureHistory.lastIndexOf(RoutePath.Home);
        const didRouteFromTrack =
          trackFeatureIndex > -1 &&
          trackFeatureIndex > exploreFeatureIndex &&
          trackFeatureIndex > todayFeatureIndex;
        const didRouteFromExplore =
          exploreFeatureIndex > -1 &&
          exploreFeatureIndex > trackFeatureIndex &&
          exploreFeatureIndex > todayFeatureIndex;
        const didRouteFromToday =
          todayFeatureIndex > -1 &&
          todayFeatureIndex > exploreFeatureIndex &&
          todayFeatureIndex > trackFeatureIndex;

        if (didRouteFromTrack) {
          setActiveFeature(MainMenuFeatures.TRACK);
        }

        if (didRouteFromExplore) {
          setActiveFeature(MainMenuFeatures.EXPLORE);
        }

        if (didRouteFromToday) {
          setActiveFeature(MainMenuFeatures.TODAY);
        }

        break;
      default:
        setActiveFeature(undefined);
    }
  }, [featurePath, featureHistory]);

  return { activeFeature, setActiveFeature };
};

const MainMenu = ({ isInFooter }: MainMenuProps) => {
  const { unmindTalkIsAvailableForUser } = useUnmindTalk();
  const { t: translate } = useTranslation('navigation');
  const { activeFeature, setActiveFeature } = useActiveFeature();
  const { hasBookingOrRequest } = useUpcomingBookingsAndRequests();
  const { hasValidLocationData } = useLocationData();

  const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);
  const { entitlements } = useOrganisationEntitlements([
    { entitlement: 'aiEnabled', default: false },
  ]);
  const isTalkMatchingEnabled = entitlements.aiEnabled;
  const isDisneyDemo = useFeatureFlag(FEATURE_FLAGS.DISNEY_CHATBOT);

  const isManagerExperienceActive = useIsManagerExperienceActive();

  // TODO: Remove this once matching is deployed and feature flag is removed
  const showTalkBrowse = !hasBookingOrRequest && hasValidLocationData;
  const subdomain = getSubdomainFromUrl();

  const homeLink = {
    name: isManagerExperienceActive
      ? translate('main_menu.tabs.for_managers')
      : translate('main_menu.tabs.today'),
    to: RoutePath.Home,
    feature: MainMenuFeatures.TODAY,
    Icon:
      activeFeature === MainMenuFeatures.TODAY
        ? NavTodayActive
        : NavTodayInactive,
  };

  const exploreLink = {
    name: translate('main_menu.tabs.explore'),
    to: RoutePath.Explore,
    feature: MainMenuFeatures.EXPLORE,
    Icon:
      activeFeature === MainMenuFeatures.EXPLORE
        ? NavExploreActive
        : NavExplore,
  };

  const trackLink = {
    name: translate('main_menu.tabs.track'),
    to: RoutePath.Track,
    feature: MainMenuFeatures.TRACK,
    Icon: activeFeature === MainMenuFeatures.TRACK ? NavTrackActive : NavTrack,
  };

  const talkLink = {
    name: translate('main_menu.tabs.talk'),
    to:
      isTalkMatchingEnabled || !showTalkBrowse
        ? RoutePath.Talk
        : RoutePath.TalkBrowse,
    feature: MainMenuFeatures.TALK,
    Icon: activeFeature === MainMenuFeatures.TALK ? NavTalkActive : NavTalk,
    shouldHide: !unmindTalkIsAvailableForUser,
  };

  /**
   * NOTE:
   * If demo flag is true, we skip landing page and go straight to chatbot
   */
  const chatbotLink = {
    name: translate('main_menu.tabs.chatbot'),
    to: isDisneyDemo ? RoutePath.Chatbot : RoutePath.ChatbotLanding,
    feature: MainMenuFeatures.CHATBOT,
    Icon:
      activeFeature === MainMenuFeatures.CHATBOT
        ? NavCompanionActive
        : NavCompanion,
    shouldHide: !isChatBotEnabled,
  };

  const links: LinkConfig[] = [
    homeLink,
    exploreLink,
    trackLink,
    talkLink,
    chatbotLink,
  ];

  if (subdomain === 'kearney' || subdomain === 'prod-testing') {
    console.log(`Nova flag is set to: ${isChatBotEnabled}`);
  }

  return (
    <Wrapper isInFooter={isInFooter}>
      {links
        .filter(({ shouldHide }) => !shouldHide)
        .map(({ name, to, Icon, feature, label }) => (
          <MenuItem key={name} displayedInMSTeams={isMSTeams()}>
            <MenuLink
              to={to}
              data-testid={`${feature}-menu-link`}
              $isInFooter={isInFooter}
              onClick={() => setActiveFeature(feature)}
              $isSelected={feature === activeFeature}
            >
              {isInFooter ? (
                <Icon
                  data-testid={`${feature}-icon`}
                  primaryColor="currentColor"
                  secondaryColor="currentColor"
                  width={24}
                  height={24}
                />
              ) : null}
              <LinkTitle>{name}</LinkTitle>
              {label && !isInFooter ? (
                <Label>
                  <LabelText>{label}</LabelText>
                </Label>
              ) : null}
            </MenuLink>
          </MenuItem>
        ))}
    </Wrapper>
  );
};

export default MainMenu;
