import { BodyText, medium, small } from '@unmind/design-system-components-web';
import { ThemeInterface } from '@unmind/design-system-theme';
import PrimaryButton from 'Shared/PrimaryButton';
import { Close } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  min-width: auto;

  ${small(css`
    max-width: ${rem(1100)};
    width: 100%;
    flex-direction: row;
    min-width: ${rem(700)};
  `)}

  ${medium(css`
    width: ${rem(1100)};
  `)}
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(24)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;

export const CloseIcon = styled(Close).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.text.primary,
  }),
)`
  height: ${rem('12px')};
  width: ${rem('12px')};
`;

export const CloseIconButton = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
`;

export const Section = styled.div<{ isLast?: boolean }>`
  border-right: 1px solid ${({ theme }) => theme.colors.border.secondary};
  flex-grow: 1;
  padding: ${rem(24)};

  ${({ isLast }) =>
    isLast &&
    css`
      border-right: none;
    `}
  ${small(css`
    width: 0;
  `)}
`;

export const SubmitButton = styled(PrimaryButton)`
  margin-left: ${rem(16)};
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.secondary};
  display: flex;
  flex-shrink: 0;
  padding: ${rem(24)};
  align-items: flex-end;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
`;

export const SectionHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(8)};
`;

export const SectionDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-bottom: ${rem(16)};
`;

export const CompassSurveyTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(8)};
`;
