import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { AuthTypeEnum } from '../../__generated__/globalTypes';

export const useIsUsingSSO = () => {
  const { group, loading } = useSubdomainInfo({
    subdomain: getSubdomainFromUrl(),
  });

  const authType = group?.authType || null;

  const isUsingSSO = authType === AuthTypeEnum.AUTH0_SSO;

  return {
    isUsingSSO,
    loadingIsUsingSSO: loading,
  };
};

export default useIsUsingSSO;
