import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import { styled } from 'styles';
import { ButtonSize } from '../../../../Shared/Button';
import { PlusOneCTA } from '../../../../Shared/PlusOne';
import { SecondaryButton } from '../../../../Shared/SecondaryButton/SecondaryButton';
import { SuccessState } from '../../../../Shared/SuccessState';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${rem('960px')};
  margin: 0 auto;
`;

interface CongratulationsSeriesProps {
  updateHistory(location: string): void;
  reviewDayUrl: string;
}

export const CongratulationsSeries = ({
  updateHistory,
  reviewDayUrl,
}: CongratulationsSeriesProps) => {
  const { t: translate } = useTranslation('courses');

  const navigateToReviewLastDay = (): void => {
    updateHistory(reviewDayUrl);
  };

  const reviewButton = (
    <SecondaryButton
      label={translate('course_completed.review_button.label')}
      onClick={navigateToReviewLastDay}
      size={ButtonSize.medium}
    />
  );

  const messages = [
    translate('course_completed.message_one'),
    translate('course_completed.message_two'),
  ];

  return (
    <Wrapper>
      <Container>
        <SuccessState
          title={translate('course_completed.title')}
          messages={messages}
          buttons={[reviewButton]}
          aria-live="polite"
        >
          <PlusOneCTA parentFeature="series" />
        </SuccessState>
      </Container>
    </Wrapper>
  );
};

export default compose<CongratulationsSeriesProps, Record<string, unknown>>(
  withRouter,
  mapProps((props: RouteComponentProps) => ({
    ...props,
    updateHistory: props.history.push,
    reviewDayUrl: props.location.state && props.location.state.reviewDay,
  })),
)(CongratulationsSeries);
