import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import { isClientSubdomain } from 'utils/getSubdomainFromUrl';
import RoutePath from '../App/RoutePath';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../LoggedOut/withSubdomainFromUrl';
import { small } from '../utils';
import InfoBanner from '../Shared/InfoBanner';
import UnauthenticatedHeaderLogo from './UnauthenticatedHeaderLogo';

export const MOBILE_HEIGHT = 68;
export const TABLET_HEIGHT = 86;

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem(24)} ${rem(16)};
  cursor: ${isMSTeams() ? 'default' : 'pointer'};

  ${small(css`
    margin: ${rem(24)} ${rem(16)};
  `)}
`;

const Header = styled('header')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

const IE11OnlyWrapper = styled.div`
  display: none;

  // Target ie browsers
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }
`;

export const UnauthenticatedHeader = compose<
  SubdomainFromUrlProps,
  Record<string, never>
>(withSubdomainFromUrl)(({ subdomain }: SubdomainFromUrlProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation('shared');
  const clientSubdomain = isClientSubdomain(subdomain);
  const isInSignUpFlow = window.location.pathname.split('/')[1] === 'signup';
  const isOnSignInPage = window.location.pathname.split('/')[1] === 'signin';
  const shouldDirectToStartScreen = !clientSubdomain || isInSignUpFlow;
  const startURL = `http://search.${process.env.REACT_APP_DOMAIN}${RoutePath.StartSplitScreen}`;
  useLocation(); // ensures component rerenders on location state change, for Alternative homepage logo

  /**
   * If users are in the sign up flow or on the search subdomain, they are directed to /start. Otherwise:
   * a) If they are on /signin we use replace to avoid duplicate entries in browser history
   * b) Otherwise we use push to insert into browser history as normal
   */
  const handleClick = () => {
    if (!isMSTeams()) {
      shouldDirectToStartScreen
        ? window.location.assign(startURL)
        : isOnSignInPage
        ? history.replace(RoutePath.SignInToYourOrganisation)
        : history.push(RoutePath.SignInToYourOrganisation);
    }
  };

  return (
    <Header id="unauthenticated-header">
      <StyledLink
        role="link"
        onClick={handleClick}
        tabIndex={-1}
        aria-label={translate(
          'navigation.unauthenticated_page.header.link.a11y_label',
        )}
      >
        <UnauthenticatedHeaderLogo
          shouldDisplay={
            !(isMSTeams() && location.pathname === RoutePath.StartSplitScreen)
          }
        />
      </StyledLink>
    </Header>
  );
});

const UnauthenticatedContent = styled.main`
  display: block;
  height: calc(100% - ${rem(MOBILE_HEIGHT)});
  width: 100%;

  ${small(css`
    height: calc(100% - ${rem(TABLET_HEIGHT)});
  `)}
`;

const UnauthenticatedPage = ({ children }: { children?: ReactNode }) => (
  <>
    <IE11OnlyWrapper>
      <InfoBanner
        text="We will no longer be supporting Internet Explorer 11 from 15th June.
      Please upgrade to a newer browser to ensure a great Unmind experience."
      />
    </IE11OnlyWrapper>
    <UnauthenticatedHeader />
    <UnauthenticatedContent>{children}</UnauthenticatedContent>
  </>
);

export default UnauthenticatedPage;
