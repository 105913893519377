import { SpeechBubbleError } from 'icons';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, HeadingText } from 'Shared/Typography';
import { styled, ThemeInterface } from 'styles';

const Container = styled.div`
  padding: ${rem(20)} ${rem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  border-radius: 8px;
  width: 100%;
`;

const ErrorIcon = styled(SpeechBubbleError).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.brand.primary,
    secondaryColor: theme.colors.text.primary,
    height: 52,
    width: 68,
  }),
)`
  margin-bottom: ${rem(24)};
`;

const StyledHeading = styled(HeadingText).attrs(() => ({
  level: 3,
}))`
  margin-bottom: ${rem(32)};
  text-align: center;
`;

const RetryButton = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.button.primary.default.textColor,
  forwardedAs: 'button',
}))`
  display: flex;
  flex-direction: row;
  height: inherit;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};
  cursor: pointer;
  outline: none;
  padding: ${rem(10)} ${rem(25)};
  text-decoration: none;
  border: ${({ theme }) => rem(theme.button.borderWidth)} solid
    ${({ theme }) => theme.button.primary.default.borderColor};

  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};

  &:focus {
    border-color: ${({ theme }) => theme.button.borderFocusColor};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.default.hover.backgroundColor};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.button.primary.default.active.backgroundColor};
  }
`;

interface SectionErrorProps {
  onRetry(): void;
}

const SectionError = ({ onRetry }: SectionErrorProps) => {
  const { t: translate } = useTranslation('shared');

  return (
    <Container>
      <ErrorIcon aria-hidden="true" />
      <StyledHeading>{translate('errors.section_error.title')}</StyledHeading>
      <RetryButton
        onClick={() => {
          onRetry();
        }}
        aria-label={translate('errors.section_error.retry_button.aria_label')}
      >
        {translate('errors.section_error.retry_button.text')}
      </RetryButton>
    </Container>
  );
};

export default SectionError;
