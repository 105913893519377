import { styled } from 'styles';
import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
`;

export const DropdownMenuPopover = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.card};
  position: absolute;
  left: 0;
  transform: translateY(${({ isOpen }) => (isOpen ? '100%' : '98%')});
  bottom: -8px;
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  box-shadow: 0px 3px 6px -2px ${({ theme }) => theme.colors.boxShadow},
    0px 6px 12px -3px ${({ theme }) => theme.colors.boxShadow};
  border-radius: 16px;
  padding: 8px 16px;
  width: ${rem(185)};
`;

export const DropdownMenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  a:hover {
    color: inherit;
  }
`;

export const MenuItemText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  padding: 8px 0;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
`;
