import React from 'react';
import { IconProps } from '../IconProps';

const SvgFulfilment = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Fulfilment_svg__clip0_1095_25812)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M12.41 12.08c-4.35 0-7.87 3.48-7.87 7.78 0 .2.02.4.03.61H9.6s-.01-.07-.01-.1a2.78 2.78 0 0 1 2.78-2.78c1.53 0 2.78 1.25 2.78 2.78 0 .03 0 .07-.01.1h5.11c.02-.2.03-.4.03-.61 0-4.3-3.52-7.78-7.87-7.78z"
        fill="#FFF9EF"
        stroke="#391F17"
        strokeMiterlimit={10}
      />
      <path
        d="M15.19 20.44c0-1.55-1.26-2.81-2.81-2.81s-2.81 1.26-2.81 2.81H6.71c0-3.13 2.54-5.67 5.67-5.67s5.67 2.54 5.67 5.67h-2.86z"
        fill="#FFF964"
        stroke="#391F17"
        strokeMiterlimit={10}
      />
      <path
        d="M10.51 7.95c-.48-.44-.99-.94-1.08-1.58-.11-.75.47-1.52 1.21-1.69.67-.15 1.4.16 1.81.71l.08.12.09-.12c.41-.55 1.14-.86 1.81-.71.74.17 1.31.94 1.21 1.69-.09.65-.59 1.15-1.08 1.58-.56.5-2.03 1.79-2.03 1.79s-1.45-1.28-2-1.79h-.02z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Fulfilment_svg__clip0_1095_25812">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgFulfilment;
