import React from 'react';
import { IconProps } from '../IconProps';

const SvgMove = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#FFF0F1" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 96.919V0H218.063l.081.147c30.648 55.812 52.26 64.046 77.568 73.687 13.165 5.015 27.33 10.412 44.288 23.085z"
      fill="#CC000D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.383 192c15.265-22.891 30.318-46.305 18.554-72.308-9.868-21.81-36.039-34.498-60.99-46.595-26.566-12.879-51.75-25.088-54.401-46.923C51.583 18.243 54.576 9.35 59.858 0H0v192h149.383z"
      fill="#660007"
    />
  </svg>
);

export default SvgMove;
