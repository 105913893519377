import React from 'react';
import { IconProps } from '../IconProps';
const SvgChatBubbleTypingSquare = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g
      clipPath="url(#ChatBubbleTypingSquare_svg__clip0_1537_4389)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M4.066.77a1.978 1.978 0 00-1.978 1.978v11.76L.803 18.364a.66.66 0 00.785.849l5.196-1.3h10.469a1.978 1.978 0 001.978-1.977V2.748A1.978 1.978 0 0017.253.77H4.066z"
        fill={primaryColor}
      />
      <path
        d="M5.765 9.287a1.164 1.164 0 112.329 0 1.164 1.164 0 01-2.329 0zm3.785 0a1.164 1.164 0 112.328 0 1.164 1.164 0 01-2.328 0zm4.948-1.165a1.164 1.164 0 100 2.329 1.164 1.164 0 000-2.329z"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <clipPath id="ChatBubbleTypingSquare_svg__clip0_1537_4389">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChatBubbleTypingSquare;
