import { talkEventsQuery_unmindEvents_edges_node } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';

export enum EventType {
  EVENT = 'EVENT',
  EVENT_REQUEST = 'EVENT_REQUEST',
}

export type EventData = {
  firstName?: string;
  lastName?: string;
  photo?: string;
  meetURL?: string;
  timeZone?: string | null;
  eventTime?: string | null;
  duration?: string | null;
  googleCalendarLink?: string | null;
  outlookCalendarLink?: string | null;
  event?: talkEventsQuery_unmindEvents_edges_node | null;
  isWithin24Hours?: boolean;
};
