import React from 'react';
import { IconProps } from '../IconProps';

const SvgTabHelp = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.35 5.974l-.941-.337a6.977 6.977 0 014.228-4.228l.337.941A5.981 5.981 0 002.35 5.974zM5.637 14.591a6.978 6.978 0 01-4.228-4.228l.941-.338a5.98 5.98 0 003.624 3.624l-.337.942zM10.363 14.591l-.338-.941a5.98 5.98 0 003.624-3.625l.942.338a6.978 6.978 0 01-4.228 4.228zM13.65 5.974a5.981 5.981 0 00-3.625-3.624l.338-.941a6.977 6.977 0 014.228 4.228l-.941.338z"
      fill="#200C05"
    />
    <path
      d="M15.07 5.514l-.153-.434-3.969 1.04a3.519 3.519 0 00-1.069-1.068l1.042-3.969-.435-.153a7.425 7.425 0 00-4.971 0l-.435.153 1.041 3.969c-.429.275-.794.64-1.069 1.069L1.083 5.079l-.153.436A7.473 7.473 0 00.5 8c0 .838.145 1.674.43 2.486l.153.434 3.97-1.04c.274.429.639.793 1.068 1.068L5.08 14.917l.435.153c.811.285 1.647.43 2.485.43.838 0 1.674-.145 2.486-.43l.435-.153-1.041-3.969c.429-.274.794-.64 1.068-1.069l3.97 1.042.152-.435c.286-.812.43-1.648.43-2.486 0-.838-.145-1.674-.43-2.486zM8 10.5A2.503 2.503 0 015.5 8c0-1.378 1.121-2.5 2.5-2.5 1.378 0 2.5 1.122 2.5 2.5 0 1.379-1.122 2.5-2.5 2.5z"
      fill="#200C05"
    />
  </svg>
);

export default SvgTabHelp;
