import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { Cards } from 'icons';
import { rem } from 'polished';
import { ContentTile } from '@unmind/design-system-components-web';
import { useQuery } from '@apollo/client';
import { FEATURED_CHEAT_SHEETS_QUERY } from 'Services/CheatSheets/cheatsheet.services';
import { FeaturedCheatSheets as FeaturedCheatSheetsQuery } from 'Services/CheatSheets/__generated__/FeaturedCheatSheets';
import { filterNonNull } from 'typescript/helpers';
import RoutePath from 'App/RoutePath';
import { SkeletonContentTile } from 'Shared/Skeleton';
import SectionError from 'Shared/SectionError/SectionError';
import { tracking } from 'App/Tracking';
import { ContentType } from 'Explore/types';
import {
  ContentCardWrapper,
  HomeScreenSection,
} from '../../PersonalisedToday/PersonalisedTodayScreen';
import { medium, small } from '../../../utils';
import SectionHeading from '../SectionHeading';

const CheatSheetIcon = styled(Cards).attrs(({ theme }) => ({
  primaryColor: theme.colors.brand.primary,
  secondaryColor: theme.colors.background.card,
  tertiaryColor: theme.colors.border.primary,
  height: 32,
  width: 32,
  'aria-hidden': true,
}))``;

const CheatSheetsWrapper = styled.div<{ numItems: number }>`
  margin-top: ${rem(40)};
  display: grid;
  grid-gap: 16px;

  ${({ numItems }) =>
    small(css`
      grid-template-columns: repeat(${numItems > 2 ? 4 : 2}, 1fr);
    `)}

  ${medium(css`
    grid-gap: 32px;
  `)}
`;

type FeaturedCheatSheetsProps = {
  assetToken: string | null | undefined;
};

const FeaturedCheatSheets = ({ assetToken }: FeaturedCheatSheetsProps) => {
  const { t: translate } =
    useTranslation<Namespace<'cheat_sheets'>>('cheat_sheets');

  const { data, loading, error, refetch } = useQuery<FeaturedCheatSheetsQuery>(
    FEATURED_CHEAT_SHEETS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
    },
  );

  if (loading && !data) {
    const numberOfLoadingTiles = 4;

    return (
      <CheatSheetsWrapper numItems={numberOfLoadingTiles}>
        {[...Array(numberOfLoadingTiles)].map((_, i) => (
          <SkeletonContentTile key={i} />
        ))}
      </CheatSheetsWrapper>
    );
  }

  if (error) {
    return <SectionError onRetry={async () => refetch()} />;
  }

  const featuredCheatSheets = data?.featuredCheatSheets;

  return featuredCheatSheets?.length ? (
    <HomeScreenSection>
      <SectionHeading
        title={translate('cheat_sheets_section.title')}
        subtitle={translate('cheat_sheets_section.subtitle')}
        iconComponent={<CheatSheetIcon />}
      />
      <CheatSheetsWrapper numItems={featuredCheatSheets.length}>
        {featuredCheatSheets.filter(filterNonNull).map(item => {
          const contentItem = {
            itemType: 'cheatsheet' as 'cheatsheet',
            title: item.title,
            thumbnailUrl: `${item?.cloudinaryThumbnail?.path}?${assetToken}`,
            tags: [translate('cheat_sheets_section.content_tile.tags')],
          };

          return (
            <ContentCardWrapper key={item.id}>
              <ContentTile
                item={contentItem}
                to={`${RoutePath.CheatSheet}/${item.slug}`}
                hoverLabel={translate(
                  'cheat_sheets:cheat_sheets_section.content_tile.hover_label',
                )}
                onClick={() =>
                  tracking.track('content-selected', {
                    contentId: item.id,
                    contentSlug: item.slug,
                    contentType: ContentType.cheatSheet,
                    source: 'today-featured-cheat-sheets',
                  })
                }
              />
            </ContentCardWrapper>
          );
        })}
      </CheatSheetsWrapper>
    </HomeScreenSection>
  ) : null;
};

export default FeaturedCheatSheets;
