import { Colors } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { HeadingText } from '../../../Shared/Typography';
import BodyText from '../../../Shared/Typography/BodyText';
import formatRoundedNumber from '../../../utils/formatNumber/formatRoundedNumber';

const ValueContainer = styled.span`
  display: flex;
  align-items: baseline;
`;

export const Value = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))``;

const Unit = styled(BodyText)`
  margin-left: ${rem(4)};
`;

export const MetricTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  &::after {
    content: '';
    display: block;
    border-bottom: ${rem(1)} solid
      ${({ theme }) => theme.colors.border.secondary};
    margin-top: ${rem(4)};
  }
`;

export interface MetricProps {
  color: Colors;
  title: string;
  // If you want to override formatting, pass through formatted string
  value?: string | number;
  unit?: string;
}

const Metric = ({ title, value = '0', color, unit }: MetricProps) => (
  <div>
    <MetricTitle data-testid="metric-title">{title}</MetricTitle>
    <ValueContainer data-testid="metric-value">
      <Value color={color}>
        {typeof value === 'number' ? formatRoundedNumber(value) : value}
      </Value>
      {unit ? <Unit color={color}>{unit}</Unit> : null}
    </ValueContainer>
  </div>
);

export default Metric;
