import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadingText } from '../../../Shared/Typography';
import BodyText from '../../../Shared/Typography/BodyText';
import { useTheme } from '../../../styles';
import {
  AnonymityWarningMessage,
  useAnonymityCopy,
} from '../helpers/useAnonymityCopy';
import { WarningContainer, WarningIcon } from './AnonymityWarning.styled';

interface AnonymityWarningProps {
  message: AnonymityWarningMessage;
}

export const AnonymityWarning = ({ message }: AnonymityWarningProps) => {
  const { t: translate } = useTranslation('admin');
  const theme = useTheme();
  const anonymityCopy = useAnonymityCopy(message);

  return (
    <WarningContainer>
      <WarningIcon />
      <HeadingText
        level={3}
        sizes={[
          theme.typography.fontSizes.fontSize14,
          theme.typography.fontSizes.fontSize16,
          theme.typography.fontSizes.fontSize18,
          theme.typography.fontSizes.fontSize20,
        ]}
      >
        {translate('anonymity_warning.not_enough_data')}
      </HeadingText>
      <BodyText sizes={[theme.typography.fontSizes.fontSize16]}>
        {anonymityCopy}
      </BodyText>
    </WarningContainer>
  );
};
