import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsMove = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.727 11.114A4.271 4.271 0 0 1 18 6.844a4.271 4.271 0 0 1 4.273 4.27A4.271 4.271 0 0 1 18 15.384a4.271 4.271 0 0 1-4.273-4.27zM18 8.844a2.271 2.271 0 0 0-2.273 2.27A2.271 2.271 0 0 0 18 13.384a2.271 2.271 0 0 0 2.273-2.27A2.271 2.271 0 0 0 18 8.844z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.809 1.93a3.184 3.184 0 0 1 4.5.002 3.178 3.178 0 0 1-.002 4.497 6.62 6.62 0 0 0-.008 9.363 6.822 6.822 0 0 0 9.402 0 6.62 6.62 0 0 0-.008-9.363 3.178 3.178 0 0 1-.001-4.497 3.184 3.184 0 0 1 4.499-.002 12.977 12.977 0 0 1 0 18.367l-.01.01a12.662 12.662 0 0 1-2.726 2.012v6.004l4.965 4.96a1 1 0 1 1-1.414 1.416l-5.258-5.255a1 1 0 0 1-.293-.707V21.71a1 1 0 0 1 .563-.9 10.664 10.664 0 0 0 2.765-1.933 10.977 10.977 0 0 0-.005-15.531 1.184 1.184 0 0 0-1.672 0 1.178 1.178 0 0 0 0 1.668 8.62 8.62 0 0 1 0 12.201l-.016.016a8.822 8.822 0 0 1-12.18 0l-.016-.016a8.62 8.62 0 0 1 0-12.201M8.81 1.93a12.977 12.977 0 0 0 0 18.367l.01.01a12.66 12.66 0 0 0 2.726 2.012V34a1 1 0 0 0 2 0V21.71a1 1 0 0 0-.563-.9 10.665 10.665 0 0 1-2.765-1.933 10.978 10.978 0 0 1 .005-15.531 1.184 1.184 0 0 1 1.673 0c.46.46.46 1.207 0 1.668M17.88 30.688a3.183 3.183 0 0 1 3.469.69v.001l1.892 1.898a1 1 0 1 1-1.417 1.412l-1.89-1.897a1.185 1.185 0 0 0-1.289-.256 1.18 1.18 0 0 0-.729 1.09V34a1 1 0 1 1-2 0v-.373a3.18 3.18 0 0 1 1.965-2.939z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsMove;
