import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import MoodTrackerCTA from '../../../Track/Moods/MoodTracker/MoodTrackerCTA';
import { SectionHeadingTitle } from '../../../Explore/ExplorePage';
import withIndexRetakeFrequency, {
  MINIMUM_DAYS_BETWEEN_INDEXES,
  WithIndexRetakeFrequencyProps,
} from '../../../Track/Wellbeing/Questionnaire/withIndexRetakeFrequency';
import { hasCompletedIndexRecently } from '../../../Track/Wellbeing/utils/timeBetweenIndex';
import WellbeingTrackerCTA from '../../../Track/Wellbeing/WellbeingTrackerCTA';
import { small } from '../../../utils';
import { HomeScreenSection } from '../PersonalisedTodayScreen';
import { getLatestWellbeingTrackerCompletionForToday } from './__generated__/getLatestWellbeingTrackerCompletionForToday';

const ActionsContainer = styled.div<{ showWellbeingTrackerCTA: boolean }>`
  display: grid;
  grid-gap: 16px;

  ${css`
    ${small(css`
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    `)}
  `}
`;

export const WELLBEING_TRACKER_COMPLETION_QUERY = gql`
  query getLatestWellbeingTrackerCompletionForToday {
    userIndexInsights {
      id
      date
    }
  }
`;

type Props = WithIndexRetakeFrequencyProps;

export const Actions = ({
  indexFrequency = MINIMUM_DAYS_BETWEEN_INDEXES,
}: Props) => {
  const { t: translate } = useTranslation<Namespace<'homepage'>>(['homepage']);
  const { error, loading, data } =
    useQuery<getLatestWellbeingTrackerCompletionForToday>(
      WELLBEING_TRACKER_COMPLETION_QUERY,
    );

  const completedIndexRecently = hasCompletedIndexRecently(
    indexFrequency,
    data?.userIndexInsights?.[0]?.date,
  );

  const showWellbeingTrackerCTA = !loading && !error && !completedIndexRecently;

  return (
    <HomeScreenSection>
      <SectionHeadingTitle>
        {translate('personalised_today.actions_section.title')}
      </SectionHeadingTitle>
      <ActionsContainer showWellbeingTrackerCTA={showWellbeingTrackerCTA}>
        {showWellbeingTrackerCTA ? (
          <WellbeingTrackerCTA
            retakeFrequency={indexFrequency}
            source="today-wellbeing"
          />
        ) : null}
        <MoodTrackerCTA source="today-moods" />
      </ActionsContainer>
    </HomeScreenSection>
  );
};

export default withIndexRetakeFrequency(Actions);
