import React from 'react';
import { IconProps } from '../IconProps';

const SvgPlayShort = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.936 28.836a2.388 2.388 0 0 1-1.979-2.167c-.035-.5-.073-1.002-.11-1.508-.205-2.75-.417-5.6-.417-8.522 0-2.922.212-5.772.417-8.522.037-.506.075-1.009.11-1.508a2.388 2.388 0 0 1 1.98-2.167l.516-.09c1.792-.314 3.647-.638 5.547-.638s3.755.324 5.547.637l.516.09a2.388 2.388 0 0 1 1.98 2.168c.035.5.073 1.002.11 1.508.205 2.75.417 5.6.417 8.522 0 2.922-.212 5.772-.417 8.522-.037.506-.075 1.009-.11 1.508a2.388 2.388 0 0 1-1.98 2.167l-.516.09c-1.792.314-3.647.638-5.547.638s-3.755-.324-5.547-.638l-.517-.09zm3.378-9.383c-.132-.987-.286-2.336-.286-3.246 0-.91.154-2.26.287-3.247.09-.679.767-1.112 1.407-.867 1.854.713 3.587 1.84 4.728 3.577a.977.977 0 0 1 0 1.073c-1.14 1.737-2.873 2.865-4.728 3.577-.64.246-1.317-.188-1.408-.867z"
      fill={tertiaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.07 26.288l1.436-.113c.736-.058 1.465-.116 2.187-.17 1.09-.08 1.981-.91 2.156-1.988.04-.253.083-.508.125-.764.35-2.134.714-4.347.714-6.614 0-2.267-.363-4.48-.714-6.614l-.125-.764a2.371 2.371 0 0 0-2.156-1.988c-.722-.054-1.451-.112-2.187-.17a733.01 733.01 0 0 0-1.436-.113l.083 1.127c.205 2.75.417 5.6.417 8.522 0 2.922-.212 5.772-.417 8.522l-.083 1.127zM7.93 6.99l-.083 1.127c-.205 2.75-.417 5.6-.417 8.522 0 2.922.212 5.772.417 8.522l.083 1.127-1.437-.113a423.83 423.83 0 0 0-2.187-.17 2.371 2.371 0 0 1-2.155-1.988c-.04-.253-.083-.508-.125-.764-.35-2.134-.714-4.347-.714-6.614 0-2.267.363-4.48.714-6.614l.125-.764a2.371 2.371 0 0 1 2.155-1.988c.723-.054 1.452-.112 2.187-.17L7.93 6.99z"
      fill={secondaryColor}
    />
    <path
      d="M4.94 7.225l-.633.048c-1.09.08-1.981.91-2.156 1.988-.383 2.368-.84 4.839-.84 7.378 0 2.54.457 5.01.84 7.378a2.371 2.371 0 0 0 2.156 1.988l.633.048M27.06 7.225l.633.048c1.09.08 1.981.91 2.156 1.988.383 2.368.84 4.839.84 7.378 0 2.54-.457 5.01-.84 7.378a2.371 2.371 0 0 1-2.156 1.988l-.633.048M7.957 26.669a2.388 2.388 0 0 0 1.98 2.167c1.95.338 3.98.728 6.063.728 2.084 0 4.113-.39 6.063-.728a2.388 2.388 0 0 0 1.98-2.167c.23-3.21.527-6.57.527-10.03 0-3.46-.297-6.82-.527-10.03a2.388 2.388 0 0 0-1.98-2.167c-1.95-.338-3.563-.728-6.063-.728-2.5 0-4.113.39-6.064.728a2.388 2.388 0 0 0-1.979 2.167c-.23 3.21-.527 6.57-.527 10.03 0 3.46.297 6.82.527 10.03z"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.028 16.206c0 .91.155 2.26.287 3.247.09.68.767 1.113 1.407.867 1.855-.713 3.587-1.84 4.728-3.577a.977.977 0 0 0 0-1.073c-1.14-1.737-2.873-2.865-4.728-3.577-.64-.246-1.316.188-1.407.867-.132.987-.287 2.336-.287 3.246z"
      fill={secondaryColor}
    />
    <path
      d="M13.028 16.206c0 .91.155 2.26.287 3.247.09.68.767 1.113 1.407.867 1.855-.713 3.587-1.84 4.728-3.577a.977.977 0 0 0 0-1.073c-1.14-1.737-2.873-2.865-4.728-3.577-.64-.246-1.316.188-1.407.867-.132.987-.287 2.336-.287 3.246z"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgPlayShort;
