import { Colors } from '@unmind/design-system-theme';
import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React, { SyntheticEvent, useLayoutEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import { styled, useTheme } from 'styles';
import {
  EventPropTypeInterface,
  // @ts-ignore Selection *is* exported from victory/src/index.js
  Selection,
  VictoryAxis,
  VictoryBar,
  VictoryBarProps,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryTooltipProps,
  VictoryZoomContainer,
} from 'victory';
import { ScreenReaderContent } from '../../Shared/Accessibility';
import BodyText from '../Typography/BodyText';
import ChartTooltip from './ChartTooltip';

const SPACE_BETWEEN_LABEL_AND_AXIS = 8;
const LEFT_AXIS_GROUP_CLASS = 'unmind-left-axis';
const BAR_WIDTH = 12;
const SPACE_BETWEEN_BARS_IN_GROUP = 4;

export const ID_GROUPED_BAR_CHART = 'grouped-bar-chart';

// These wrappers are needed to make sizeme work correctly when placed within a flexbox container
const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const WarningContainer = styled.div<{ chartLeftOffset: number }>`
  color: ${props => props.theme.colors.text.secondary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: ${props => rem(props.chartLeftOffset / 2)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type SeriesData = {
  value: number | null;
  name: string;
  label?: string[];
};

type GroupedChartData = {
  seriesOne: {
    data: SeriesData[] | null;
    legend: string;
  };
  seriesTwo?: {
    data: SeriesData[] | null;
    legend: string;
  };
};

export interface AdminGroupedBarChartProps {
  caption: string;
  colorScale: [Colors, Colors];
  data: GroupedChartData;
  height?: number;
  categoriesHeader: string;
  title: string;
  description: string;
  boundaries?: [number, number];
}

const barEventHandlers: EventPropTypeInterface<'data' | 'labels', 'event'> = {
  target: 'data',
  eventHandlers: {
    onMouseOver: () => [
      {
        target: 'labels',
        mutation: () => ({ active: true }),
      },
    ],
    onMouseOut: () => [
      {
        target: 'labels',
        mutation: () => ({ active: false }),
      },
    ],
  },
};

// A tooltip which follows the mouse on hover
const MouseFollowTooltip = (props: VictoryTooltipProps) => (
  <ChartTooltip dy={BAR_WIDTH / 2} {...props} />
);

MouseFollowTooltip.defaultEvents = [
  {
    target: 'data',
    eventHandlers: {
      onMouseOver: (evt: SyntheticEvent) => {
        const { x } = Selection.getSVGEventCoordinates(evt);

        return {
          target: 'labels',
          mutation: () => ({
            x,
            active: true,
          }),
        };
      },
      onMouseMove: (evt: SyntheticEvent) => {
        const { x } = Selection.getSVGEventCoordinates(evt);

        return {
          target: 'labels',
          mutation: () => ({
            x,
            active: true,
          }),
        };
      },
    },
  },
];

const InsufficientDataText = ({
  chartLeftOffset,
}: {
  chartLeftOffset: number;
}) => {
  const { typography } = useTheme();

  return (
    <WarningContainer chartLeftOffset={chartLeftOffset}>
      <BodyText sizes={[typography.fontSizes.fontSize12]}>
        No data available.
        <br />
        Please check again later
      </BodyText>
    </WarningContainer>
  );
};

const Caption = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  text-align: center;
`;

const AdminGroupedBarChart = ({
  caption,
  colorScale,
  data,
  height,
  categoriesHeader,
  title,
  description,
  boundaries,
}: AdminGroupedBarChartProps) => {
  const { seriesOne, seriesTwo } = data;
  const seriesOneData = seriesOne.data || [];

  const seriesTwoAnonymised = isNil(seriesTwo);
  const seriesTwoData = seriesTwo?.data || [];
  const theme = useTheme();

  const shouldHideData =
    (!seriesOneData.length && !seriesTwoData.length) ||
    (seriesOneData.every(seriesData => isNil(seriesData.value)) &&
      seriesTwoData.every(seriesData => isNil(seriesData.value)));

  // used to identify the rows of the a11y table
  const a11yCaptionKey = caption.replace(/\s/g, '');

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [maxLabelWidth, setMaxLabelWidth] = useState(0);

  useLayoutEffect(() => {
    let labelWidth = maxLabelWidth;

    if (containerRef) {
      containerRef
        .querySelectorAll(`.${LEFT_AXIS_GROUP_CLASS} text`)
        .forEach(text => {
          labelWidth = Math.max(labelWidth, text.getBoundingClientRect().width);
        });

      setMaxLabelWidth(labelWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, data]);

  const commonBarProps: VictoryBarProps = {
    barWidth: BAR_WIDTH,
    cornerRadius: {
      topLeft: theme.layout.borderRadii.radius4,
      topRight: theme.layout.borderRadii.radius4,
    },
    events: [{ ...barEventHandlers }],
    labelComponent: <MouseFollowTooltip />,
    x: 'name',
    y: 'value',
  };

  const legendData = seriesTwoAnonymised
    ? [
        {
          name: seriesOne.legend,
          symbol: {
            type: 'square',
          },
        },
      ]
    : [
        {
          name: seriesOne.legend,
          symbol: {
            type: 'square',
          },
        },
        {
          name: seriesTwo?.legend,
          symbol: {
            type: 'square',
          },
        },
      ];

  return (
    <>
      <SizeMe monitorWidth={true}>
        {({ size: { width } }) => (
          <Wrapper style={{ height }}>
            <InnerWrapper aria-hidden={true} data-testid={ID_GROUPED_BAR_CHART}>
              <VictoryChart
                domain={boundaries ? { y: boundaries } : undefined}
                containerComponent={
                  <VictoryZoomContainer
                    containerRef={setContainerRef}
                    allowPan={false}
                    allowZoom={false}
                    height={height}
                    responsive={false}
                    width={width !== null ? width : undefined}
                    title={title}
                    desc={description}
                  />
                }
                height={height}
                horizontal
                padding={{
                  left: maxLabelWidth + SPACE_BETWEEN_LABEL_AND_AXIS + 1, // + axis width,
                  right: 16,
                  top: 40,
                  bottom: 32,
                }}
                width={width !== null ? width : undefined}
              >
                <VictoryLegend
                  colorScale={colorScale}
                  gutter={30}
                  data={legendData}
                  orientation="horizontal"
                  style={{
                    labels: {
                      fill: theme.colors.text.secondary,
                      fontFamily: theme.typography.bodyText.fontFamily,
                      fontSize: theme.typography.fontSizes.fontSize14,
                    },
                  }}
                  width={width || 400}
                  x={-10}
                  y={0}
                />
                {/* x axis */}
                <VictoryAxis
                  dependentAxis
                  orientation="bottom"
                  style={{
                    axis: {
                      stroke: 'none',
                    },
                    grid: {
                      stroke: theme.colors.border.secondary,
                    },
                    tickLabels: {
                      fill: theme.colors.text.secondary,
                      fontFamily: theme.typography.bodyText.fontFamily,
                      fontSize: theme.typography.fontSizes.fontSize14,
                    },
                  }}
                  tickFormat={boundaries ? undefined : t => `${t}%`}
                  tickValues={
                    boundaries
                      ? [
                          boundaries[0],
                          (boundaries[0] + boundaries[1]) / 2,
                          boundaries[1],
                        ]
                      : [0, 50, 100]
                  }
                />
                {/* y axis - left */}
                <VictoryAxis
                  groupComponent={
                    <g
                      className={LEFT_AXIS_GROUP_CLASS}
                      data-testid={LEFT_AXIS_GROUP_CLASS}
                    />
                  }
                  orientation="left"
                  style={{
                    axis: {
                      stroke: theme.colors.border.secondary,
                    },
                    tickLabels: {
                      fill: theme.colors.text.secondary,
                      fontFamily: theme.typography.bodyText.fontFamily,
                      fontSize: theme.typography.fontSizes.fontSize14,
                      padding: SPACE_BETWEEN_LABEL_AND_AXIS,
                    },
                  }}
                  tickFormat={t =>
                    !seriesOneData.length && !seriesTwoData.length ? `` : t
                  }
                />
                {/* y axis - right */}
                <VictoryAxis
                  offsetX={16}
                  orientation="right"
                  style={{
                    axis: {
                      stroke: theme.colors.border.secondary,
                    },
                  }}
                  tickFormat={() => ``}
                />
                <VictoryGroup
                  // reversed as the rotated chart is drawn from bottom to top
                  colorScale={
                    !seriesTwoAnonymised
                      ? Array.from(colorScale).reverse()
                      : Array.from(colorScale)
                  }
                  offset={BAR_WIDTH + SPACE_BETWEEN_BARS_IN_GROUP}
                >
                  {!seriesTwoAnonymised ? (
                    <VictoryBar
                      {...commonBarProps}
                      // reversed as the rotated chart is drawn from bottom to top
                      data={[...seriesTwoData].reverse()}
                    />
                  ) : null}
                  <VictoryBar
                    {...commonBarProps}
                    // reversed as the rotated chart is drawn from bottom to top
                    data={[...seriesOneData].reverse()}
                  />
                </VictoryGroup>
              </VictoryChart>

              {shouldHideData ? (
                <InsufficientDataText chartLeftOffset={maxLabelWidth} />
              ) : null}
            </InnerWrapper>
            {!shouldHideData ? (
              <ScreenReaderContent as="table">
                <caption>{caption}</caption>
                <tbody>
                  <tr>
                    <th scope="col">{categoriesHeader}</th>
                    <th scope="col">{seriesOne.legend}</th>
                    {!seriesTwoAnonymised ? (
                      <th scope="col">{seriesTwo?.legend}</th>
                    ) : null}
                  </tr>
                  {seriesOne.data &&
                    seriesOne.data.map(({ name, value }, index) => (
                      <tr key={`a11yTable-${a11yCaptionKey}-${index}`}>
                        <td>{name}</td>
                        <td>{Math.round(value || 0)}%</td>
                        {!seriesTwoAnonymised ? (
                          <td>
                            {seriesTwoData &&
                              Math.round(seriesTwoData[index].value || 0)}
                            %
                          </td>
                        ) : null}
                      </tr>
                    ))}
                </tbody>
              </ScreenReaderContent>
            ) : null}
          </Wrapper>
        )}
      </SizeMe>
      <Caption>{caption}</Caption>
    </>
  );
};

export default AdminGroupedBarChart;
