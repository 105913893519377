import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Label from '../../../../Shared/Form/Label';
import RadioOption from './RadioOption';

const Container = styled.div``;

const OptionContainer = styled.div`
  display: flex;
  label + label {
    margin-left: ${rem(24)};
  }
`;

const FieldLabel = styled(Label)`
  display: inline-flex;
  margin-bottom: ${rem(16)};
`;

interface RadioOption<T extends string | number> {
  label: string;
  value: T;
}

interface Props<T extends string | number> {
  value?: T;
  onChange(value: T): void;
  radioOptions: RadioOption<T>[];
  title?: string;
}

const RadioButtons = <T extends string | number>({
  value,
  onChange,
  radioOptions,
  title = '',
}: Props<T>) => (
  <Container>
    <FieldLabel>{title}</FieldLabel>
    <OptionContainer role="radiogroup">
      {radioOptions.map(({ label, value: currentValue }) => (
        <RadioOption
          key={currentValue}
          label={label}
          handleChange={() => onChange(currentValue)}
          isSelected={value === currentValue}
        />
      ))}
    </OptionContainer>
  </Container>
);

export default RadioButtons;
