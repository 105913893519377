import { isApolloError, MutationFunction } from '@apollo/client';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import {
  AuthApiResponse,
  AuthPayload,
  getAuthPayload,
  AuthStatus,
} from '../App/Auth';
import { Errors, getError } from '../Shared/Errors';

export const CONFIRM_AND_AUTH_USER_MUTATION = gql`
  mutation confirmAndAuthUser($rid: String!) {
    confirmNewUserAndLogin(rid: $rid) {
      id
      token
      iris_token
      email
      firstName
      lastName
      createdAt
      groupId
      subdomain
      praiseDirectory {
        value
      }
      departmentId
      departmentName
      locationId
      locationName
      userType {
        name
      }
      permissions {
        value
      }
      abTestCohorts
    }
  }
`;

interface ApiResponse {
  confirmNewUserAndLogin: AuthApiResponse;
}

interface Variables {
  rid: string;
}

export interface WithConfirmAndAuthUserProps {
  confirmAndAuthUser(variables: Variables): Promise<AuthPayload>;
}

export const confirmAndAuthUser = async (
  variables: Variables,
  mutate?: MutationFunction<ApiResponse, Variables>,
): Promise<AuthPayload> => {
  try {
    if (mutate) {
      const response = await mutate({
        variables: variables,
      });

      if (response) {
        if (
          response.data &&
          response.data.confirmNewUserAndLogin !== undefined
        ) {
          return getAuthPayload(response.data.confirmNewUserAndLogin);
        }
      }
    }
  } catch (error) {
    if (error instanceof Error && isApolloError(error)) {
      if (error.graphQLErrors.length > 0) {
        const [{ name: graphQLErrorName }] = error.graphQLErrors;
        const mappedError = getError(graphQLErrorName);

        return {
          status: AuthStatus.ERROR,
          error: mappedError !== undefined ? mappedError : Errors.ServerError,
        };
      }
    }
  }

  return {
    error: Errors.ServerError,
    status: AuthStatus.ERROR,
  };
};

export const withConfirmAndAuthUser = graphql<
  Record<string, unknown>,
  ApiResponse,
  Variables,
  WithConfirmAndAuthUserProps
>(CONFIRM_AND_AUTH_USER_MUTATION, {
  options: {
    context: {
      timeout: 15000,
    },
  },
  props: ({ mutate }) => ({
    confirmAndAuthUser: async (variables: Variables) =>
      confirmAndAuthUser(variables, mutate),
  }),
});
