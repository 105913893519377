import React from 'react';
import { IconProps } from '../IconProps';
const SvgChevronDown = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <g clipPath="url(#ChevronDown_svg__clip0_820_17327)">
      <path
        d="M.572 4.4l7.029 7.028a.548.548 0 00.8 0L15.429 4.4"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="ChevronDown_svg__clip0_820_17327">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChevronDown;
