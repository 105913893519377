import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from 'App/RoutePath';
import BackButton from 'Shared/Button/BackButton';
import SecondaryButton from 'Shared/SecondaryButton';
import { useHistory } from 'react-router-dom';
import { tracking } from 'App/Tracking';
import { getPrivacyPolicyLink } from 'utils';
import { getHelpCenterLink } from 'utils/getHelpCenterLink';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from 'utils/getSubdomainFromUrl';
import {
  AuthContent,
  AuthWrapperFooter,
  AuthWrapperGrid,
  SubdomainHeader,
  Container,
  LinksContainer,
  LockIcon,
  HelpIcon,
  Link,
  LinkText,
  NewAuthContent,
} from './AuthWrapper.styled';
import { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

export { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

interface AuthWrapperProps {
  children: ReactNode;
  groupName?: string;
  progressBar?: ReactNode;
  showFooter?: boolean;
  newDesign?: boolean;
  showCreateAccountButton?: boolean;
}

export const AuthWrapper = ({
  children,
  groupName,
  progressBar,
  showFooter = true,
  newDesign = false,
  showCreateAccountButton = true,
}: AuthWrapperProps) => {
  const { t: translate } = useTranslation('logged_out');
  const history = useHistory();
  const subdomain = getSubdomainFromUrl();
  const clientSubdomain = isClientSubdomain(subdomain);

  const { group } = useSubdomainInfo({
    subdomain: subdomain,
    skip: !clientSubdomain,
  }) || { group: null };

  const getSignUpScreen = (authType?: AuthTypeEnum) => {
    if (!clientSubdomain) {
      return `${RoutePath.SignInToYourOrganisation}?redirect=/signup`;
    }
    if (authType === AuthTypeEnum.AUTH0_SSO) {
      return RoutePath.LoginWithSSO;
    }

    return RoutePath.SignUp;
  };

  return (
    <AuthWrapperGrid>
      {newDesign ? (
        <NewAuthContent showFooter={showFooter}>
          <Container>
            {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
            {isMSTeams() && (
              <BackButton linkToDefault={RoutePath.StartSplitScreen} />
            )}
          </Container>
          {progressBar}
          {children}
          {showFooter && (
            <AuthWrapperFooter>
              {showCreateAccountButton && (
                <SecondaryButton
                  label={translate('footer.create_account_button.label')}
                  onClick={() => {
                    history.push(getSignUpScreen(group?.authType));
                    tracking.track('create-account-clicked', {
                      subdomain,
                    });
                  }}
                />
              )}
              <LinksContainer>
                <Link data-testid="help-link" href={getHelpCenterLink()}>
                  <HelpIcon />
                  <LinkText>{translate('footer.help.label')}</LinkText>
                </Link>
                <Link data-testid="privacy-link" href={getPrivacyPolicyLink()}>
                  <LockIcon />
                  <LinkText>
                    {translate('footer.privacy_policy.label')}
                  </LinkText>
                </Link>
              </LinksContainer>
            </AuthWrapperFooter>
          )}
        </NewAuthContent>
      ) : (
        <AuthContent>
          <Container>
            {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
            {isMSTeams() && (
              <BackButton linkToDefault={RoutePath.StartSplitScreen} />
            )}
            {groupName && (
              <SubdomainHeader data-testid="business-pill">
                {groupName}
              </SubdomainHeader>
            )}
          </Container>
          {progressBar}
          {children}
          {showFooter && (
            <AuthWrapperFooter>
              <AuthWrapperHelpCentre />
            </AuthWrapperFooter>
          )}
        </AuthContent>
      )}
    </AuthWrapperGrid>
  );
};
