import { darken, normalize } from 'polished';
import { createGlobalStyle } from 'styles';
import { isMSTeams } from 'utils/MSTeams';

const GlobalStyle = createGlobalStyle`
  ${normalize()};
  @font-face {
    font-display: block;
    font-family: 'Inter';
    src: url('/fonts/Inter-Bold.ttf');
    src: local('Inter-Bold'),
        url('/fonts/Inter-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-display: block;
    font-family: 'Inter';
    src: url('/fonts/Inter-SemiBold.ttf');
    src: local('Inter-SemiBold'),
        url('/fonts/Inter-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-display: block;
    font-family: 'Inter';
    src: url('/fonts/Inter-Medium.ttf');
    src: local('Inter-Medium'),
        url('/fonts/Inter-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-display: block;
    font-family: 'Inter';
    src: url('/fonts/Inter-Regular.ttf');
    src: local('Inter-Regular'),
        url('/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-display: block;
    font-family: 'Feature';
    src: url('/fonts/Feature-RegularItalic.ttf');
    src: local('Feature-RegularItalic'),
         url('/fonts/Feature-RegularItalic.woff2') format('woff2'),
         url('/fonts/Feature-RegularItalic.woff') format('woff'),
         url('/fonts/Feature-RegularItalic.ttf') format('truetype'),
         url('/fonts/Feature-RegularItalic.otf') format('otf'),
    font-weight: 400 800;
    font-style: italic;
  }

  @font-face {
    font-display: block;
    font-family: 'Feature';
    src: url('/fonts/Feature-Regular.ttf');
    src: local('Feature-Regular'),
         url('/fonts/Feature-Regular.woff2') format('woff2'),
         url('/fonts/Feature-Regular.woff') format('woff'),
         url('/fonts/Feature-Regular.ttf') format('truetype'),
         url('/fonts/Feature-Regular.otf') format('otf'),
    font-weight: 400 800;
    font-style: normal;
  }

  @font-face {
    font-display: block;
    font-family: 'National';
    src: url('/fonts/National2Compressed-Extrabold.ttf');
    src: local('National2Compressed-Extrabold'),
         url('/fonts/National2Compressed-Extrabold.woff2') format('woff2'),
         url('/fonts/National2Compressed-Extrabold.ttf') format('truetype'),
         url('/fonts/National2Compressed-Extrabold.otf') format('otf'),
    font-style: normal;
    font-weight: 400 800;
  }

  @font-face {
    font-display: block;
    font-family: 'IBMPlexMono';
    src: url('/fonts/IBMPlexMono-Regular.ttf');
    src: local('IBMPlexMono-Regular'),
        url('/fonts/IBMPlexMono-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-display: block;
    font-family: 'QuincyCF';
    src: local('QuincyCF-Bold'),
         url('/fonts/QuincyCF-Bold.woff2') format('woff2'),
         url('/fonts/QuincyCF-Bold.woff') format('woff'),
         url('/fonts/QuincyCF-Bold.eot') format('eot');
    font-style: normal;
    font-weight: 700;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body, #root {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  html {
    overflow-y: overlay;
    scrollbar-gutter: stable;
    overflow-x: ${isMSTeams() ? 'hidden' : 'visible'};
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Inter';
    background-color: ${({ theme }) => theme.colors.background.primary};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  button {
    font-family: inherit;
  }

  a {
    color: ${({ theme }) => theme.colors.text.link};
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    text-decoration: none;
    transition: color 0.15s linear;

    &:hover {
      color: ${({ theme }) => darken(0.1, theme.colors.text.link)};
    }
  }
`;

export default GlobalStyle;
