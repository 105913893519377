import { rem } from 'polished';
import React, { MouseEvent } from 'react';
import { styled } from 'styles';
import {
  ServersideTrackingEvent,
  updateInteractionSession,
  getSentAtTimestamp,
} from '../App/Tracking/serverside';
import { WrappingButton } from '../Shared/Accessibility';
import ContentListItem, {
  ContentListItemProps,
  TRANSITION_DURATION,
} from './ContentListItem';

// istanbul ignore next
const List = styled.ul`
  margin: 0;
  padding: ${rem('2px')} 0 0;
`;

const ListItem = styled.li`
  list-style-type: none;
`;

const ContentItemTrigger = styled(WrappingButton)`
  width: 100%;
`;

export type ContentListItem = Omit<ContentListItemProps, 'active'>;

export interface ContentListProps {
  contentListItems: ContentListItem[];
  expandedItemId?: string;
  trackServerside?(
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ): Promise<void>;
}

interface ContentListState {
  expandedItemIndex: number | null;
}

class ContentList extends React.Component<ContentListProps, ContentListState> {
  state = {
    expandedItemIndex: null,
  };

  listItemsRefs: { [key: number]: HTMLButtonElement } = {};

  componentDidMount = () => {
    this.expandListItemOnDeepLink();
  };

  expandListItemOnDeepLink = () => {
    const { expandedItemId, contentListItems } = this.props;

    if (Boolean(expandedItemId)) {
      const expandedItemIndex = contentListItems.findIndex(
        item => item.id === expandedItemId,
      );

      this.setState({ expandedItemIndex });
      setTimeout(() => {
        this.listItemsRefs[expandedItemIndex].scrollIntoView({
          behavior: 'smooth',
        });
      }, TRANSITION_DURATION);
    }
  };

  onListItemClick = (
    e: MouseEvent<HTMLElement>,
    itemIndex: number,
    title: string,
    id: string,
  ) => {
    if ((e.target as HTMLElement).tagName === 'A') {
      return;
    }

    if (this.state.expandedItemIndex === itemIndex) {
      this.setState({ expandedItemIndex: null });
    } else {
      this.setState({ expandedItemIndex: itemIndex });
    }

    const { trackServerside } = this.props;

    if (trackServerside) {
      updateInteractionSession();
      void trackServerside('HELP_RESOURCE_CLICKED', {
        title,
        contentId: id,
        clientSentAtUtcTimestamp: getSentAtTimestamp(),
      });
    }
  };

  render = () => {
    const { contentListItems } = this.props;

    return (
      <List>
        {contentListItems.map((contentListItem: ContentListItem, i: number) => (
          <ListItem key={contentListItem.id}>
            <ContentItemTrigger
              onClick={e => {
                this.onListItemClick(
                  e,
                  i,
                  contentListItem.title,
                  contentListItem.id,
                );
              }}
              key={`${contentListItem.title}-${i}`}
              data-testid="content-list-item"
              ref={(instance: HTMLButtonElement) => {
                this.listItemsRefs[i] = instance;
              }}
            >
              <ContentListItem
                {...contentListItem}
                active={this.state.expandedItemIndex === i}
              />
            </ContentItemTrigger>
          </ListItem>
        ))}
      </List>
    );
  };
}

export default ContentList;
