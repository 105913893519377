import { Accordion, BodyText } from '@unmind/design-system-components-web';
import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { CheckSquare } from 'icons';
import { Colors } from '@unmind/design-system-theme';
import { useTranslation } from 'react-i18next';
import { getWellbeingThemeDetail_wellbeingThemeDetail_recommendedActions as RecommendedWellbeingActions } from 'Compass/api/__generated__/getWellbeingThemeDetail';
import { getRiskQuestionDetail_riskQuestionDetail_recommendedActions as RecommendedRiskActions } from 'Compass/api/__generated__/getRiskQuestionDetail';
import { Risk_Category } from '__generated__/globalTypes';
import { tracking } from 'App/Tracking';
import {
  mapThemeIdsToThemeName,
  mapThemeIdsToThemeType,
} from '../../constants';
import { TakeActionCheckbox } from './TakeActionCheckbox';
import { CardHeader } from './styles';
import { ActionsMarkdownContainer } from './ActionsMarkdownContainer';

interface RecommendedActionsAccordionProps {
  title?: string;
  recommendedActions: RecommendedWellbeingActions[] | RecommendedRiskActions[];
  scheduleId: string;
}

const CheckSquareIcon = styled(CheckSquare).attrs(({ theme }) => ({
  primaryColor: Colors.sentimentGreen100,
  secondaryColor: theme.colors.staticPalette.green.green7,
}))`
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(8)};
`;

const RecommendedActionsAccordionContainer = styled.span`
  padding: ${rem(24)} 0;

  & > div > div > button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.background.card};
  }

  & > div > div > div {
    background-color: ${({ theme }) => theme.colors.background.card};
    padding: 0 ${rem(24)};
    border-bottom: ${({ theme }) =>
      `${rem(2)} solid ${theme.colors.background.secondary}`};
  }
`;

const RecommendedActionsAccordionTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(32)};
`;

const Container = styled.div``;

const Header = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))``;

const RecommendedActionsContent = styled.div`
  padding-bottom: ${rem(24)};
`;

const RecommendedActionsAccordionContent = ({
  actionId,
  scheduleId,
  description,
  title,
  themeId,
  riskCategory,
  score,
}: {
  actionId: string;
  scheduleId: string;
  description: string;
  title: string;
  themeId: string;
  riskCategory: Risk_Category;
  score: number;
}) => (
  <RecommendedActionsContent>
    <ActionsMarkdownContainer
      htmlContent={description}
      actionTrackingData={{
        actionId,
        scheduleId,
        themeId,
        riskCategory,
        score: score,
        themeType:
          mapThemeIdsToThemeType[
            themeId as keyof typeof mapThemeIdsToThemeType
          ],
        theme:
          mapThemeIdsToThemeName[
            themeId as keyof typeof mapThemeIdsToThemeName
          ],
      }}
    ></ActionsMarkdownContainer>
    <TakeActionCheckbox
      scheduleId={scheduleId}
      ariaLabel={title}
      actionId={actionId}
    />
  </RecommendedActionsContent>
);

export const RecommendedActionsAccordion = ({
  title,
  recommendedActions,
  scheduleId,
}: RecommendedActionsAccordionProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.cards.recommended_actions_accordion',
  });

  const trackActionOpened = (actionId: string) => {
    const action = recommendedActions.find(
      recommendedAction => recommendedAction.actionId === actionId,
    );

    if (!action) {
      return;
    }

    tracking.track('compass-action-opened', {
      actionId: action.actionId,
      scheduleId,
      themeType:
        mapThemeIdsToThemeType[
          action.themeId as keyof typeof mapThemeIdsToThemeType
        ],
      theme:
        mapThemeIdsToThemeName[
          action.themeId as keyof typeof mapThemeIdsToThemeName
        ],
      riskCategory: action.riskCategory,
      score: action.score,
      actionTitle: action.title,
      markedAsTaken: action.markedAsTaken,
      location: 'SELECTED_CARD',
    });
  };

  const items = recommendedActions.map(recommendedAction => ({
    header: (
      <Header data-testid={'selected-card-actions'}>
        {recommendedAction.title}
      </Header>
    ),
    content: (
      <RecommendedActionsAccordionContent
        scheduleId={scheduleId}
        actionId={recommendedAction.actionId}
        description={recommendedAction.description}
        title={recommendedAction.title || ''}
        themeId={recommendedAction.themeId || ''}
        riskCategory={recommendedAction.riskCategory}
        score={recommendedAction.score}
      />
    ),
    value: recommendedAction.actionId || '',
  }));

  return (
    <Container>
      <RecommendedActionsAccordionTitle>
        <CheckSquareIcon />
        <CardHeader>{title ? title : translate('title')}</CardHeader>
      </RecommendedActionsAccordionTitle>
      <RecommendedActionsAccordionContainer>
        <Accordion items={items} onOpenItem={trackActionOpened} />
      </RecommendedActionsAccordionContainer>
    </Container>
  );
};
