import { rem } from 'polished';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { styled } from 'styles';
import RoutePath from '../../../../../App/RoutePath';
import { tracking } from '../../../../../App/Tracking';
import { getSentAtTimestamp } from '../../../../../App/Tracking/serverside';
import { ButtonSize } from '../../../../../Shared/Button';
import { PrimaryButton } from '../../../../../Shared/PrimaryButton/PrimaryButton';
import {
  TimerDispatch,
  withTimerDispatch,
  withTimerState,
} from '../../../../../Shared/Timer';
import { TimerState } from '../../../../../Shared/Timer/types';
import FormError from '../../../../../Shared/Typography/FormError';
import {
  CourseImpactQuestionnaireResponse,
  SeriesQuestionResponse,
} from '../../../../../__generated__/globalTypes';
import {
  WithLogSeriesActivityResponsesChildProps,
  withLogSeriesActivity,
} from '../../withLogSeriesActivity';
import useRecordSeriesQuestionnaireResponses from './useRecordSeriesQuestionnaireResponses';
import useRecordCourseImpactQuestionnaireResponses from './useRecordCourseImpactQuestionnaireResponses';

interface OuterQuestionnaireOutroProps
  extends Pick<RouteComponentProps, 'history'> {
  answers: SeriesQuestionResponse[];
  impactQuestionnaireAnswers?: CourseImpactQuestionnaireResponse[];
  impactQuestionnaireVersion?: string;
  dayNumber: number;
  seriesTitle: string;
  seriesId: string;
  seriesSegmentId: string;
  segmentTitle: string;
  seriesDayId: string;
  slug: string;
  handleClose(): void;
  isInitialQuestionnaire: boolean;
  segmentNumber: number;
  isLastSegmentInSeries: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
  exploreCategoryId?: string;
}

export interface QuestionnaireOutroProps
  extends OuterQuestionnaireOutroProps,
    WithLogSeriesActivityResponsesChildProps,
    Pick<RouteComponentProps, 'history'> {
  timerDispatch: TimerDispatch;
  timerState: TimerState;
}

export interface QuestionnaireOutroState {
  submissionError?: string;
  submitting: boolean;
}

export const Form = styled.form`
  text-align: left;
`;

const StyledFormError = styled(FormError).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))``;

export const SubmitButton = styled(PrimaryButton)`
  margin-top: ${rem('25px')};
`;

export function QuestionnaireOutro({
  slug,
  seriesId,
  seriesTitle,
  seriesDayId,
  seriesSegmentId,
  segmentTitle,
  segmentNumber,
  handleClose,
  logSeriesActivity,
  isLastSegmentInSeries,
  isLastSegmentInDay,
  isComplete,
  isInitialQuestionnaire,
  dayNumber,
  answers,
  impactQuestionnaireAnswers = [],
  impactQuestionnaireVersion,
  history,
  timerState,
  timerDispatch,
  exploreCategoryId,
}: QuestionnaireOutroProps) {
  const { t: translate } = useTranslation('courses');

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<null | string>(null);

  const [recordSeriesQuestionnaireResponses, { error }] =
    useRecordSeriesQuestionnaireResponses({
      input: {
        responses: answers,
        isInitialQuestionnaire,
        seriesId,
        clientSentAtUtcTimestamp: getSentAtTimestamp(),
      },
    });
  const [recordCourseImpactQuestionnaireResponses] =
    useRecordCourseImpactQuestionnaireResponses({
      input: {
        responses: impactQuestionnaireAnswers,
        courseId: seriesId,
        version: impactQuestionnaireVersion as string,
        clientSentAtUtcTimestamp: getSentAtTimestamp(),
      },
    });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitting(true);
    timerDispatch.stop();

    const { data } = await recordSeriesQuestionnaireResponses();

    if (impactQuestionnaireAnswers?.length) {
      await recordCourseImpactQuestionnaireResponses();
    }

    if (
      error !== undefined ||
      !data?.recordSeriesQuestionnaireResponses?.success
    ) {
      setSubmitting(false);
      setSubmissionError(
        translate('segment.questionnaire.outro.submission_error'),
      );
    } else {
      const res = await logSeriesActivity({
        segmentId: seriesSegmentId,
        dayId: seriesDayId,
        seriesId,
        seconds: timerState.total,
        slug,
        clientSentAtUtcTimestamp: getSentAtTimestamp(),
        exploreCategoryId,
      });

      if (res.success) {
        if (isLastSegmentInSeries) {
          tracking.track('series-completed', {
            seriesId,
            seriesSlug: slug,
          });
        }

        tracking.track('series-segment-completed', {
          segmentId: seriesSegmentId,
          segmentTitle,
          segmentNumber,
          dayId: seriesDayId,
          dayNumber: dayNumber,
          seriesId,
          seriesSlug: slug,
          isInitial: !isComplete,
          exploreCategoryId,
        });

        if (isLastSegmentInDay) {
          tracking.track('series-day-completed', {
            seriesId,
            dayId: seriesDayId,
            seriesSlug: slug,
            dayNumber,
          });
        }
      }

      setSubmitting(false);
      handleClose();

      // Go to the second segment if this is the first questionnaire
      // Else go to the next segment
      const newSegmentNumber = isInitialQuestionnaire ? 2 : segmentNumber + 1;

      /**
       * Using replace here instead of push means that user pressing browser back
       * button will not be cycled through the individual day content and instead return to
       * overview screen or screen that they came from before starting day segments
       */
      history.replace(
        `${RoutePath.Series}/${slug}/day/${dayNumber}/${newSegmentNumber}`,
      );
    }
  };

  return (
    <React.Fragment>
      {isInitialQuestionnaire ? (
        <p data-testid="initial">
          {translate('segment.questionnaire.outro.body.course_start', {
            course_title: seriesTitle,
          })}
        </p>
      ) : (
        <p data-testid="final">
          {translate('segment.questionnaire.outro.body.course_end')}
        </p>
      )}
      <Form onSubmit={onSubmit}>
        <StyledFormError>{submissionError}</StyledFormError>
        <SubmitButton
          disabled={submitting}
          label={
            isInitialQuestionnaire
              ? translate(
                  'segment.questionnaire.outro.next_step_button.label_start',
                )
              : translate(
                  'segment.questionnaire.outro.next_step_button.label_end',
                )
          }
          loading={submitting}
          size={ButtonSize.medium}
          type="submit"
        />
      </Form>
    </React.Fragment>
  );
}

export default compose<QuestionnaireOutroProps, OuterQuestionnaireOutroProps>(
  withLogSeriesActivity,
  withTimerDispatch,
  withTimerState,
)(QuestionnaireOutro);
