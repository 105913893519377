import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategoryWork = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 30 28" fill="none" {...props}>
    <path
      d="M5.607 1.226L1.109 9.438l8.717.159-4.219-8.371z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.16 4.29L5.62 1.226 3.962 4.264a2.805 2.805 0 0 0 3.21.026H7.16z"
      fill="#391F17"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.8 26.473h-.04a18.107 18.107 0 0 1-8.677 0V9.544h8.716v16.929z"
      fill="#CEFF78"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.733 9.517L7.146 4.29a2.758 2.758 0 0 1-1.632.491 2.772 2.772 0 0 1-1.579-.517L1.096 9.438l.531.12a17.38 17.38 0 0 0 8.093-.054l.013.013z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.559 1h5.44c1.605 0 2.918 1.42 2.918 3.17 0 1.752-1.3 3.172-2.919 3.172H20.56M12.294 1h3.98"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.065 13.643H19.67c-1.605 0-2.919-1.42-2.919-3.17 0-1.752 1.3-3.171 2.92-3.171h6.394"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.55 19.959h6.395c1.606 0 2.92-1.42 2.92-3.171 0-1.752-1.301-3.171-2.92-3.171h-6.394M26.118 19.959h-6.395c-1.605 0-2.919 1.42-2.919 3.17 0 1.752 1.3 3.171 2.92 3.171h9.047"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategoryWork;
