import defaultTo from 'lodash/defaultTo';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { SegmentTrackingProps } from '..';
import { tracking } from '../../../../App/Tracking';
import { SeriesSegmentType } from '../../../types';
import NextButton from '../Buttons/NextButton';
import HTMLSegment from './HTML';
import ImageSegment from './Image';
import MediaSegment from './Media/MediaSegment';
import SeriesQuestionnaire from './Questionnaire';
import SeriesSegmentUI from './SeriesSegmentUI';

export interface SeriesSegmentProps extends UserSeriesSegment {
  idx: number;
  segmentsLeft: number;
  isLast?: boolean;
  isComplete: boolean;
  isExpanded: boolean;
  onToggle?(idx: number): void;
  lastDayInSeries?: boolean;
  segmentTrackingProps: SegmentTrackingProps;
}

export type SeriesSegmentWithUserProps = SeriesSegmentProps;

interface Segment {
  durationSeconds?: number;
  icon: string;
  id: string;
  mediaType: string;
  orderNo: number;
  title: string;
  html?: string;
  introHTML: string | null;
  bodyHTML?: string | null;
  primarySegmentType: SeriesSegmentType;
}

export interface UserSeriesSegment extends Segment {
  isComplete: boolean;
}

export interface SeriesSegmentState {
  animating: boolean;
}

const SegmentDetails = styled.div`
  height: ${rem('200px')};
`;

export default class SeriesSegment extends React.Component<
  SeriesSegmentWithUserProps,
  SeriesSegmentState
> {
  constructor(props: SeriesSegmentProps) {
    super(props);

    this.state = {
      animating: false,
    };
  }

  trackSegmentStarted = () => {
    tracking.track('series-segment-started', {
      seriesId: this.props.segmentTrackingProps.seriesId,
      seriesSlug: this.props.segmentTrackingProps.seriesSlug,
      segmentId: this.props.id,
      segmentTitle: this.props.title,
      segmentNumber: this.props.orderNo,
      dayId: this.props.segmentTrackingProps.dayId,
      dayNumber: this.props.segmentTrackingProps.dayNumber,
      isInitial: !this.props.isComplete,
    });
  };

  renderSegment(isSegmentExpanded: boolean, isComplete: boolean) {
    const {
      title,
      primarySegmentType,
      id,
      isLast = false,
      lastDayInSeries = false,
      segmentTrackingProps,
      bodyHTML,
    } = this.props;

    const isLastSegmentInSeries = isLast && lastDayInSeries;

    switch (defaultTo(primarySegmentType, '')) {
      case SeriesSegmentType.text:
        return (
          <HTMLSegment
            html={bodyHTML}
            isExpanded={isSegmentExpanded}
            onSegmentStart={this.trackSegmentStarted}
            renderNextStepButton={
              <NextButton
                segmentId={id}
                segmentTitle={title}
                isLastSegmentInSeries={isLastSegmentInSeries}
                isLastSegmentInDay={isLast}
                isComplete={isComplete}
              />
            }
          />
        );
      case SeriesSegmentType.audio:
        return (
          <MediaSegment
            segmentTrackingProps={segmentTrackingProps}
            segmentTitle={title}
            segmentId={id}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLast}
            isComplete={isComplete}
            mediaType={SeriesSegmentType.audio}
          />
        );
      case SeriesSegmentType.questionnaire:
        return (
          <SeriesQuestionnaire
            segmentTitle={title}
            seriesSegmentId={id}
            isSegmentExpanded={isSegmentExpanded}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLast}
            isComplete={isComplete}
          />
        );
      case SeriesSegmentType.image:
        return (
          <ImageSegment
            segmentId={id}
            segmentTitle={title}
            segmentTrackingProps={segmentTrackingProps}
            isSegmentExpanded={isSegmentExpanded}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLast}
            isComplete={isComplete}
          />
        );
      case SeriesSegmentType.video:
        return (
          <MediaSegment
            segmentTrackingProps={segmentTrackingProps}
            segmentTitle={title}
            segmentId={id}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLast}
            isComplete={isComplete}
            mediaType={SeriesSegmentType.video}
          />
        );
      default:
        return <SegmentDetails>{primarySegmentType}</SegmentDetails>;
    }
  }

  render() {
    const {
      durationSeconds,
      icon,
      idx,
      isComplete,
      isExpanded,
      onToggle,
      segmentsLeft,
      title,
      introHTML,
      isLast = false,
    } = this.props;

    return (
      <SeriesSegmentUI
        idx={idx}
        icon={icon}
        introHTML={introHTML}
        onToggle={onToggle}
        isComplete={isComplete}
        isExpanded={isExpanded}
        isLast={isLast}
        segmentsLeft={segmentsLeft}
        title={title}
        durationSeconds={durationSeconds}
      >
        {this.renderSegment(isExpanded, isComplete)}
      </SeriesSegmentUI>
    );
  }
}
