import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { styled } from 'styles';

export const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const HeaderSlice = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PageTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize32],
  weight: theme.typography.fontWeights.semiBold,
  accessibilityAutoFocus: true,
}))`
  line-height: 1;
`;

export const PageSubTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize16],
}))``;

export const BodyCopy = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize16],
}))``;

export const BodyCopySmall = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize14],
}))`
  & + & {
    margin-top: ${rem(12)};
  }
`;
