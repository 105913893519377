import { BodyText, small } from '@unmind/design-system-components-web';
import { Calendar } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: flex-start;
  margin-top: ${rem(19)};
  justify-content: flex-start;

  ${small(css`
    flex-direction: row;
  `)}
`;

export const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize32],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  flex-direction: row;
  display: flex;
  align-items: center;

  ${small(css`
    align-items: flex-start;
  `)}
`;

export const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(8)};
  ${small(css`
    margin-top: ${rem(12)};
    margin-left: ${rem(64)};
  `)}
`;

export const TitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-bottom: 0;

  ${small(css`
    margin-left: ${rem(16)};
    margin-top: ${rem(16)};
    margin-bottom: ${rem(32)};
  `)}
`;

export const AddToCalendarContainer = styled.div`
  margin-left: 0;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(24)};

  ${small(css`
    margin-left: auto;
    margin-bottom: 0;
  `)}
`;

export const SessionLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${rem(20)};
  padding: ${rem(24)};
  align-items: flex-start;
  margin-top: ${rem(32)};
  margin-bottom: ${rem(24)};
`;

export const CalendarIcon = styled(Calendar).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: '20px',
  width: '20px',
}))`
  margin-right: ${rem(8)};
`;

export const Session = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  display: flex;
  align-items: center;
`;

export const SessionHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SessionDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.secondary,
}))``;

export const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${rem(872)};
  padding: 0;

  ${small(css`
    padding: 0 ${rem(44)} ${rem(40)};
  `)}
`;

export const CourseDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`
  margin-bottom: ${rem(16)};
`;

export const CourseDescriptionBenefits = styled.li`
  margin-top: ${rem(8)};
`;

export const CourseDescriptionBenefitsList = styled.ul`
  margin: 0;
`;
