import { logException } from '../App/logging';

export function isRecord(value: unknown): value is Record<string, unknown> {
  return !!(value && typeof value === 'object' && Object.keys(value).length);
}

export const flattenDictionary = (
  obj: Record<string, unknown> | ArrayLike<unknown>,
  parentProperty?: string,
  propertyMap: Record<string, string> = {},
) => {
  try {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}.${key}` : key;
      if (isRecord(value)) {
        flattenDictionary(value, property, propertyMap);
      } else {
        propertyMap[property] = value as string;
      }
    }
  } catch (e) {
    logException(e);
  }

  return propertyMap;
};
