import { rem } from 'polished';
import { css, ThemeInterface } from 'styles';

type CssReturnType = ReturnType<typeof css>;

const greaterThan =
  (
    breakpoint: keyof ThemeInterface['layout']['breakpoints'],
    styles: CssReturnType,
  ) =>
  ({ theme }: { theme: ThemeInterface }) =>
    css`
      @media (min-width: ${rem(theme.layout.breakpoints[breakpoint])}) {
        ${styles};
      }
    `;

export const extraSmall = (styles: CssReturnType) => greaterThan('XS', styles);

export const small = (styles: CssReturnType) => greaterThan('S', styles);

export const medium = (styles: CssReturnType) => greaterThan('M', styles);

export const large = (styles: CssReturnType) => greaterThan('L', styles);
