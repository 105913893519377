import gql from 'graphql-tag';

/*
 *  Fragments
 */
export const USER_COUNTRY_STATE_FRAGMENT = gql`
  fragment userCountryStateFragment on User {
    country {
      value
    }
    state {
      value
    }
  }
`;

export const USER_BASE_DETAILS_FRAGMENT = gql`
  fragment userBaseDetailsFragment on User {
    firstName
    lastName
    email
    userType {
      id
    }
  }
`;

export const USER_ATTRIBUTES_FRAGMENT = gql`
  fragment userAttributesFragmenet on User {
    hasUnmindTalkUserLevelAccess
    isLineManager
  }
`;

/*
 * Queries
 */
export const USER_COUNTRY_STATE_QUERY = gql`
  ${USER_COUNTRY_STATE_FRAGMENT}
  query userCountryStateQuery {
    user {
      id
      ...userCountryStateFragment
    }
  }
`;

export const USER_QUERY = gql`
  ${USER_BASE_DETAILS_FRAGMENT}
  ${USER_ATTRIBUTES_FRAGMENT}

  query userQuery {
    user {
      id
      ...userBaseDetailsFragment
      ...userAttributesFragmenet
    }
  }
`;
