import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { COMPASS_ANONYMITY_THRESHOLD } from 'Admin/Dashboard/constants';
import { isWithinInterval, differenceInDays, isAfter, addDays } from 'date-fns';
import FeedbackBanner from 'Shared/FeedbackBanner';
import {
  LatestCompassIndex,
  LatestCompassIndex_latestCompassIndex_latestSchedule as LatestSchedule,
} from 'Compass/api/__generated__/LatestCompassIndex';
import { Risk_Category } from '__generated__/globalTypes';
import { tracking } from 'App/Tracking';
import { CompassDashboardHeader } from '../CompassDashboardHeader/CompassDashboardHeader';
import { LeftContainer, RightContainer, StyledPageContainer } from './styles';
import { RecommendedActions } from './Sections/RecommendedActions';
import { Risks } from './Sections/Risks';
import { Wellbeing } from './Sections/Wellbeing';
import { AverageSalarySelectedCard } from './Sections/Components/SelectedCards/AverageSalarySelectedCard';
import { SalaryProvider } from './SalaryProvider';
import { ThemeType } from './constants';
import { ActionTakenProvider } from './ActionTakenProvider';

export type CompassDashboardProps = {
  latestSchedule: LatestSchedule;
  refetchLatestSchedule(): Promise<ApolloQueryResult<LatestCompassIndex>>;
};

type LatestSurveyStatusInput = {
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  isAboveAnonymityThreshold: boolean;
};

enum SurveyStatus {
  RUNNING = 'Running',
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
  READY = 'Ready',
}

const getLatestSurveyStatus = ({
  currentDate,
  startDate,
  endDate,
  isAboveAnonymityThreshold,
}: LatestSurveyStatusInput): SurveyStatus => {
  const isSurveyRunning = isWithinInterval(currentDate, {
    start: startDate,
    end: endDate,
  });

  if (isSurveyRunning) {
    return SurveyStatus.RUNNING;
  }

  const surveyHasFinished = isAfter(currentDate, endDate);
  const earliestNewSurveyStartDate = addDays(endDate, 14);
  const isFourteenOrMoreDaysAfterEndDate = isAfter(
    currentDate,
    earliestNewSurveyStartDate,
  );

  const isSurveyComplete = surveyHasFinished && isAboveAnonymityThreshold;
  const numberOfDaysUntilNewSurvey = differenceInDays(
    earliestNewSurveyStartDate,
    currentDate,
  );

  // We want to show that the survey is complete and a countdown of days until the new survey
  if (isSurveyComplete && numberOfDaysUntilNewSurvey > 0) {
    return SurveyStatus.COMPLETE;
  }

  const isSurveyIncomplete = surveyHasFinished && !isAboveAnonymityThreshold;

  // We want to show that the survey has finished but we didn't receive enough responses
  if (isSurveyIncomplete) {
    return SurveyStatus.INCOMPLETE;
  }

  const isNextSurveyReady =
    surveyHasFinished && isFourteenOrMoreDaysAfterEndDate;

  // We want to show that the previous survey has finished and that 14 days have passed, meaning a new survey can start
  if (isNextSurveyReady) {
    return SurveyStatus.READY;
  }

  // If none of the above conditions are met, we default to the survey being ready
  return SurveyStatus.READY;
};

export const CompassDashboard = ({
  latestSchedule,
  refetchLatestSchedule,
}: CompassDashboardProps) => {
  const currentDate = new Date();
  const { startDate, endDate, numResponses, id: scheduleId } = latestSchedule;
  const isAboveAnonymityThreshold = numResponses >= COMPASS_ANONYMITY_THRESHOLD;
  const surveyStatus = getLatestSurveyStatus({
    currentDate,
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    isAboveAnonymityThreshold,
  });
  const readyToShowData =
    surveyStatus === SurveyStatus.COMPLETE ||
    surveyStatus === SurveyStatus.READY;
  const [selectedCard, setSelectedCard] = useState<React.ReactNode | null>(
    null,
  );

  const FEEDBACK_LOCALES = ['en-GB', 'en-US', 'en-AU'];

  const trackOpenAverageSalarySelectedCard = () => {
    tracking.track('compass-average-salary-editor-opened', {
      scheduleId,
    });
  };

  const openAverageSalarySelectedCard = () => {
    trackOpenAverageSalarySelectedCard();
    setSelectedCard(
      <AverageSalarySelectedCard
        closeCard={() => setSelectedCard(null)}
        scheduleId={scheduleId}
      />,
    );
  };

  const trackOpenSelectedCard = (
    title: string,
    riskCategory: Risk_Category,
    score: number,
    themeType: ThemeType,
  ) =>
    tracking.track('compass-card-selected', {
      surveyStatus,
      scheduleId,
      riskCategory,
      title,
      score,
      themeType,
    });

  return (
    <SalaryProvider>
      <ActionTakenProvider>
        <FeedbackBanner
          i18nNamespace={'compass'}
          blurBackground={false}
          i18nKey={'dashboard.feedback_banner'}
          surveyLink={'https://unmind.typeform.com/to/rjajM05c'}
          supportedFeedbackLocales={FEEDBACK_LOCALES}
        />
        <StyledPageContainer>
          <LeftContainer>
            <CompassDashboardHeader
              latestSchedule={{ ...latestSchedule, endDate: new Date(endDate) }}
              surveyStatus={surveyStatus}
              isAboveAnonymityThreshold={isAboveAnonymityThreshold}
              currentDate={currentDate}
              refetchLatestSchedule={refetchLatestSchedule}
              openAverageSalarySelectedCard={openAverageSalarySelectedCard}
            ></CompassDashboardHeader>
            <Risks
              scheduleId={scheduleId}
              readyToShowData={readyToShowData}
              setSelectedCard={setSelectedCard}
              openAverageSalarySelectedCard={openAverageSalarySelectedCard}
              trackOpenSelectedCard={trackOpenSelectedCard}
            />
            <RecommendedActions
              endDate={new Date(endDate)}
              surveyStatus={surveyStatus}
              setSelectedCard={setSelectedCard}
              scheduleId={scheduleId}
              readyToShowData={readyToShowData}
            />
            <Wellbeing
              setSelectedCard={setSelectedCard}
              readyToShowData={readyToShowData}
              scheduleId={scheduleId}
              trackOpenSelectedCard={trackOpenSelectedCard}
            />
          </LeftContainer>
          <RightContainer>{selectedCard}</RightContainer>
        </StyledPageContainer>
      </ActionTakenProvider>
    </SalaryProvider>
  );
};
