import { Form, Formik, FormikActions } from 'formik';
import { rem } from 'polished';
import queryString from 'query-string';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { styled } from 'styles';
import RoutePath from '../../App/RoutePath';
import { Input } from '../../Shared/Form/Input';
import { PrimaryButton } from '../../Shared/PrimaryButton/PrimaryButton';
import FormError from '../../Shared/Typography/FormError';
import {
  getValidationResult,
  isPasswordValid,
  PasswordValidator,
} from '../../Shared/ValidatorMatch/PasswordValidator';
import withGroupId from '../Login/withGroupId';
import {
  AuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapper,
} from '../AuthWrapper';
import { withSubdomainFromUrl } from '../withSubdomainFromUrl';
import { withResetPassword, WithResetPasswordProps } from './withResetPassword';

const Submit = styled(PrimaryButton)`
  width: 100%;
  margin: ${rem('16px')} 0 0 0;
`;

export interface ResetPasswordProps
  extends WithResetPasswordProps,
    WithTranslation,
    Pick<RouteComponentProps, 'history'>,
    Pick<RouteComponentProps, 'location'> {
  groupId: string;
}

export interface ResetPasswordState {
  passwordError?: string;
  confirmationPasswordError?: string;
  formError?: string;
  formValid: boolean;
  loading: boolean;
}

export interface FormFields {
  newPassword: string;
  confirmPassword: string;
}

type FormErrors = Partial<FormFields>;

export class ResetPassword extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  state = {
    formError: undefined,
    formValid: false,
    loading: false,
  };

  translate = this.props.t;

  public readonly onSubmit = async (
    values: FormFields,
    actions: Pick<FormikActions<FormFields>, 'setSubmitting'>,
  ) => {
    const { groupId, history, resetPassword } = this.props;
    const params = queryString.parse(this.props.location.search);
    const variables = {
      groupId,
      passwordToken: params.passwordToken as string,
      password: values.newPassword,
    };

    this.setState({ formError: undefined, loading: true });
    const result = await resetPassword(variables);
    this.setState({ loading: false });

    if (result.error !== undefined) {
      this.setState({
        formError: this.translate('shared:errors.messages.failed'),
      });
    } else {
      const confirmationMessage = this.translate(
        'login.forms.reset_password.confirmation_message',
      );
      history.push(RoutePath.Login, {
        confirmationMessage,
      });
    }
    actions.setSubmitting(false);
  };

  readonly handleFormErrors = (values: FormFields): FormErrors => {
    const errors: FormErrors = {};

    if (!values.newPassword) {
      errors.newPassword = this.translate(
        'login.forms.reset_password.errors.password_required',
      );
    } else if (!isPasswordValid(values.newPassword)) {
      errors.newPassword = this.translate(
        'login.forms.reset_password.errors.invalid_password',
      );
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = this.translate(
        'login.forms.reset_password.errors.password_required',
      );
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = this.translate(
        'login.forms.reset_password.errors.passwords_not_matching',
      );
    }

    return errors;
  };

  readonly passwordTouched =
    (
      passwordField: string,
      setFieldTouched: (field: string) => void,
      handleChange: (e: React.ChangeEvent<any>) => void,
    ) =>
    (e: React.ChangeEvent<any>) => {
      setFieldTouched(passwordField);
      handleChange(e);
    };

  render() {
    const { formError } = this.state;

    const shouldShowFormError = !(
      formError === null || formError === undefined
    );

    const initialValues = {
      newPassword: '',
      confirmPassword: '',
    };

    return (
      <AuthWrapper>
        <AuthWrapperBlock>
          <AuthWrapperTitle data-testid="title">
            {this.translate('login.forms.reset_password.heading')}
          </AuthWrapperTitle>
          <AuthWrapperSubtitle>
            {this.translate('login.forms.reset_password.subtitle')}
          </AuthWrapperSubtitle>
          <AuthWrapperContent>
            {shouldShowFormError && <FormError>{formError}</FormError>}
            <Formik
              initialValues={initialValues}
              validate={this.handleFormErrors}
              onSubmit={this.onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldTouched,
                isValid,
              }) => (
                <Form>
                  <Input
                    name="newPassword"
                    autoComplete="new-password"
                    placeholder={this.translate(
                      'login.forms.reset_password.new_password_placeholder',
                    )}
                    value={values.newPassword}
                    error={
                      Boolean(touched.newPassword)
                        ? errors.newPassword
                        : undefined
                    }
                    onChange={this.passwordTouched(
                      'newPassword',
                      setFieldTouched,
                      handleChange,
                    )}
                    onBlur={handleBlur}
                    secure={true}
                  />
                  <Input
                    name="confirmPassword"
                    autoComplete="new-password"
                    placeholder={this.translate(
                      'login.forms.reset_password.confirm_password_placeholder',
                    )}
                    value={values.confirmPassword}
                    error={
                      Boolean(touched.confirmPassword)
                        ? errors.confirmPassword
                        : undefined
                    }
                    onChange={this.passwordTouched(
                      'confirmPassword',
                      setFieldTouched,
                      handleChange,
                    )}
                    onBlur={handleBlur}
                    secure={true}
                  />
                  <PasswordValidator
                    {...getValidationResult(
                      Boolean(touched.newPassword)
                        ? values.newPassword
                        : undefined,
                    )}
                  />
                  <Submit
                    type="submit"
                    label={this.translate(
                      'login.forms.reset_password.submit_button.label',
                    )}
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </AuthWrapperContent>
        </AuthWrapperBlock>
      </AuthWrapper>
    );
  }
}

export default withTranslation(['logged_out', 'shared'])(
  compose<ResetPasswordProps, WithResetPasswordProps>(
    withSubdomainFromUrl,
    withGroupId,
    withResetPassword,
  )(ResetPassword),
);
