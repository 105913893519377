import React, { useContext, useState } from 'react';
import { addDays } from 'date-fns';
import { Loader, showToast } from '@unmind/design-system-components-web';
import { Colors } from '@unmind/design-system-theme';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import { v4 as uuid } from 'uuid';
import { pluralize } from 'utils/pluralize';
import {
  LatestCompassIndex,
  LatestCompassIndex_latestCompassIndex_latestSchedule as LatestSchedule,
} from 'Compass/api/__generated__/LatestCompassIndex';
import { UPDATE_INDEX_SCHEDULE_DATES } from 'Compass/api';
import {
  updateIndexScheduleDates,
  updateIndexScheduleDatesVariables,
} from 'Compass/api/__generated__/updateIndexScheduleDates';
import { CompassFAQModal } from '../CompassFAQModal';
import { ScheduleCompassIndexModal } from '../CompassSurveyModal/ScheduleCompassIndexModal';
import { CompassSurveyExtendModal } from '../CompassSurveyExtendModal';
import {
  Mosaic,
  MosaicProps,
} from '../CompassDashboard/Sections/Components/Mosaic';
import {
  getLatestSurveyInfo,
  surveyMessageKey,
} from '../CompassDashboard/getLatestSurveyInfo';
import { SalaryContext } from '../CompassDashboard/SalaryProvider';
import { getAverageSalaryDisplay } from '../CompassDashboard/Sections/utils/averageSalaryHelpers';
import {
  AboutCompass,
  AboutIcon,
  Container,
  StatusBarButton,
  RefreshIcon,
  SendSurveyIcon,
  StatusInfoText,
  StatusText,
  SurveyStatusBadge,
  Title,
  UserCheckIcon,
  Description,
  PencilIcon,
  HeaderContainer,
} from './styles';

export type CompassDashboardHeaderProps = {
  latestSchedule: LatestSchedule;
  surveyStatus: SurveyStatus;
  isAboveAnonymityThreshold: boolean;
  currentDate: Date;
  refetchLatestSchedule(): Promise<ApolloQueryResult<LatestCompassIndex>>;
  openAverageSalarySelectedCard(): void;
};

export enum SurveyStatus {
  RUNNING = 'Running',
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
  READY = 'Ready',
}

type SurveyStatusKeys =
  | 'dashboard.latest_survey_status.survey_statuses.running'
  | 'dashboard.latest_survey_status.survey_statuses.complete'
  | 'dashboard.latest_survey_status.survey_statuses.incomplete'
  | 'dashboard.latest_survey_status.survey_statuses.ready';

type SurveyMessageKeyType = typeof surveyMessageKey;

type StatusInfoKeys =
  | `${SurveyMessageKeyType}.running.plural`
  | `${SurveyMessageKeyType}.complete.plural`
  | `${SurveyMessageKeyType}.incomplete`
  | `${SurveyMessageKeyType}.ready`
  | `${SurveyMessageKeyType}.running.singular`
  | `${SurveyMessageKeyType}.complete.singular`;

const SurveyStatusKeyMap: Record<SurveyStatus, SurveyStatusKeys> = {
  [SurveyStatus.RUNNING]:
    'dashboard.latest_survey_status.survey_statuses.running',
  [SurveyStatus.COMPLETE]:
    'dashboard.latest_survey_status.survey_statuses.complete',
  [SurveyStatus.INCOMPLETE]:
    'dashboard.latest_survey_status.survey_statuses.incomplete',
  [SurveyStatus.READY]: 'dashboard.latest_survey_status.survey_statuses.ready',
};

type StatusColour = {
  innerColour: string;
  outerColour: string;
};

const statusColours: Record<SurveyStatus, StatusColour> = {
  [SurveyStatus.RUNNING]: {
    innerColour: Colors.blue8,
    outerColour: Colors.blue400,
  },
  [SurveyStatus.INCOMPLETE]: {
    innerColour: Colors.red6,
    outerColour: Colors.red2,
  },
  [SurveyStatus.READY]: {
    innerColour: Colors.orange600,
    outerColour: Colors.orange200,
  },
  [SurveyStatus.COMPLETE]: {
    innerColour: Colors.green7,
    outerColour: Colors.sentimentGreen100,
  },
};

export const CompassDashboardHeader = ({
  latestSchedule: { id, startDate, endDate, numResponses, cohortSize },
  surveyStatus,
  isAboveAnonymityThreshold,
  currentDate,
  refetchLatestSchedule,
  openAverageSalarySelectedCard,
}: CompassDashboardHeaderProps) => {
  const [surveyCreationModalIsOpen, setSurveyCreationModalIsOpen] =
    useState(false);
  const [compassFAQModalIsOpen, setCompassFAQModalIsOpen] = useState(false);
  const [surveyExtendModalIsOpen, setSurveyExtendModalIsOpen] = useState(false);
  const { t: translate } = useTranslation('compass');
  const { innerColour, outerColour } = statusColours[surveyStatus];
  const { textKey, days } = getLatestSurveyInfo(
    surveyStatus,
    currentDate,
    endDate,
  );
  const { averageSalary, currency } = useContext(SalaryContext);
  const dayOrDays = pluralize(days || 0, 'singular', 'plural');
  const [
    updateIndexScheduleDatesMutation,
    { loading: loadingUpdateIndexScheduleDatesMutation },
  ] = useMutation<updateIndexScheduleDates, updateIndexScheduleDatesVariables>(
    UPDATE_INDEX_SCHEDULE_DATES,
  );

  const toggleSurveyCreationModal = (
    method?: 'survey-ready' | 'survey-restart',
  ) => {
    setSurveyCreationModalIsOpen(!surveyCreationModalIsOpen);

    if (!surveyCreationModalIsOpen) {
      tracking.track('compass-schedule-modal-opened', {
        method,
        scheduleId: id,
        surveyStatus,
      });
    }
  };

  const toggleCompassFAQModal = () => {
    if (!compassFAQModalIsOpen) {
      tracking.track('compass-faq-modal-opened', {
        scheduleId: id,
        surveyStatus,
      });
    }

    setCompassFAQModalIsOpen(!compassFAQModalIsOpen);
  };

  const toggleSurveyExtendModal = () =>
    setSurveyExtendModalIsOpen(!surveyExtendModalIsOpen);

  if (loadingUpdateIndexScheduleDatesMutation) {
    return <Loader />;
  }

  const surveyStatusCell = {
    id: uuid(),
    cell: (
      <HeaderContainer>
        <SurveyStatusBadge
          innerColour={innerColour}
          outerColour={outerColour}
        />
        <StatusText>{translate(SurveyStatusKeyMap[surveyStatus])}</StatusText>
      </HeaderContainer>
    ),
  };

  const participationRateCell = {
    id: uuid(),
    cell: (
      <HeaderContainer>
        <UserCheckIcon aria-hidden={true} />
        <StatusInfoText
          aria-label={`${translate(
            'dashboard.latest_survey_status.completion_rate.a11_label',
          )}: ${numResponses}/${cohortSize}`}
          displayAsError={surveyStatus === SurveyStatus.INCOMPLETE}
        >
          {numResponses}/{cohortSize}{' '}
          {isAboveAnonymityThreshold &&
            translate('dashboard.latest_survey_status.completion_rate.text')}
        </StatusInfoText>
      </HeaderContainer>
    ),
  };

  const aboutCompassCell = {
    id: uuid(),
    onClick: toggleCompassFAQModal,
    cell: (
      <HeaderContainer>
        <AboutCompass>
          <AboutIcon aria-hidden={true} />
          <StatusInfoText
            aria-label={translate('dashboard.latest_survey_status.about.text')}
          >
            {translate('dashboard.latest_survey_status.about.text')}
          </StatusInfoText>
        </AboutCompass>
      </HeaderContainer>
    ),
    clickable: true,
  };

  const averageSalaryCell = {
    id: uuid(),
    onClick: openAverageSalarySelectedCard,
    cell: (
      <HeaderContainer data-testid="average-salary-header-cell">
        <PencilIcon />
        {translate('dashboard.average_salary', {
          salary: getAverageSalaryDisplay(averageSalary.toString(), currency),
        })}
      </HeaderContainer>
    ),
    clickable: true,
  };

  const possiblePluralTextKey = [
    `${surveyMessageKey}.running`,
    `${surveyMessageKey}.complete`,
  ];

  const getSurveyStatusText = () => ({
    id: uuid(),
    cell: (
      <HeaderContainer>
        {translate(
          `${
            possiblePluralTextKey.includes(textKey)
              ? `${textKey}.${dayOrDays}`
              : textKey
          }` as StatusInfoKeys,
          {
            numOfDays: days,
          },
        )}
      </HeaderContainer>
    ),
  });

  const startAgainCell = {
    id: uuid(),
    darkVariant: true,
    onClick: () => toggleSurveyCreationModal('survey-restart'),
    cell: (
      <StatusBarButton>
        {translate('dashboard.latest_survey_status.restart_survey')}
      </StatusBarButton>
    ),
    clickable: true,
  };

  const extendDeadlineCell = {
    id: uuid(),
    darkVariant: true,
    onClick: toggleSurveyExtendModal,
    cell: (
      <StatusBarButton>
        {translate('dashboard.latest_survey_status.extend_survey.button')}
        <RefreshIcon aria-hidden={true} />
      </StatusBarButton>
    ),
    clickable: true,
  };

  const sendNewCheckinCell = {
    id: uuid(),
    darkVariant: true,
    onClick: () => toggleSurveyCreationModal('survey-ready'),
    cell: (
      <StatusBarButton>
        {translate('dashboard.latest_survey_status.send_new_checkin')}
        <SendSurveyIcon aria-hidden={true} />
      </StatusBarButton>
    ),
    clickable: true,
  };

  const defaultStatusBarContent: MosaicProps = {
    rowEntries: [
      {
        id: uuid(),
        data: [surveyStatusCell, getSurveyStatusText(), participationRateCell],
        flexible: true,
      },
      {
        id: uuid(),
        data: [aboutCompassCell, averageSalaryCell],
        flexible: true,
        shortRow: true,
      },
    ],
    mobileLayoutRowEntries: [
      { id: uuid(), data: [surveyStatusCell, participationRateCell] },
      { id: uuid(), data: [getSurveyStatusText()] },
      { id: uuid(), data: [aboutCompassCell] },
      { id: uuid(), data: [averageSalaryCell] },
    ],
  };

  const anonymityThresholdNotMetStatusBarContent: MosaicProps = {
    rowEntries: [
      {
        id: uuid(),
        data: [surveyStatusCell, participationRateCell, aboutCompassCell],
        flexible: true,
      },
      { id: uuid(), data: [getSurveyStatusText()] },
      {
        id: uuid(),
        data: [averageSalaryCell, startAgainCell, extendDeadlineCell],
        flexible: true,
      },
    ],
    mobileLayoutRowEntries: [
      { id: uuid(), data: [surveyStatusCell, participationRateCell] },
      { id: uuid(), data: [getSurveyStatusText()] },
      { id: uuid(), data: [averageSalaryCell] },
      { id: uuid(), data: [aboutCompassCell] },
      { id: uuid(), data: [startAgainCell, extendDeadlineCell] },
    ],
  };

  const surveyDueStatusBarContent: MosaicProps = {
    mobileLayoutRowEntries: [
      { id: uuid(), data: [surveyStatusCell, averageSalaryCell] },
      { id: uuid(), data: [getSurveyStatusText()] },
      { id: uuid(), data: [aboutCompassCell, sendNewCheckinCell] },
    ],
    rowEntries: [
      {
        id: uuid(),
        data: [surveyStatusCell, getSurveyStatusText(), averageSalaryCell],
        flexible: true,
      },
      {
        id: uuid(),
        data: [aboutCompassCell, sendNewCheckinCell],
        flexible: true,
        shortRow: true,
      },
    ],
  };

  const getStatusBarContent = (): MosaicProps => {
    if (surveyStatus === SurveyStatus.INCOMPLETE) {
      return anonymityThresholdNotMetStatusBarContent;
    }

    if (surveyStatus === SurveyStatus.READY) {
      return surveyDueStatusBarContent;
    }

    return defaultStatusBarContent;
  };

  return (
    <Container>
      <Title>{translate('dashboard.title')}</Title>
      <Description>{translate('dashboard.description')}</Description>
      <Mosaic {...getStatusBarContent()} />
      {surveyCreationModalIsOpen && (
        <ScheduleCompassIndexModal closeModal={toggleSurveyCreationModal} />
      )}
      {compassFAQModalIsOpen && (
        <CompassFAQModal closeModal={toggleCompassFAQModal} />
      )}
      {surveyExtendModalIsOpen && (
        <CompassSurveyExtendModal
          closeModal={toggleSurveyExtendModal}
          extendSurvey={async () => {
            const newEndDate = new Date(
              addDays(currentDate, 7).setUTCHours(23, 59, 59, 999),
            ).toISOString();

            await updateIndexScheduleDatesMutation({
              variables: {
                input: {
                  endDate: newEndDate,
                  scheduleId: id,
                  startDate,
                },
              },
              onCompleted: async () => {
                await refetchLatestSchedule();
                tracking.track('compass-schedule-extended', {
                  scheduleId: id,
                  startDate,
                  oldEndDate: endDate,
                  newEndDate,
                  scheduleStatus: surveyStatus,
                  currentCompletionRate: `${numResponses}/${cohortSize}`,
                  numberOfInvitees: cohortSize,
                });
              },
              onError: () => {
                showToast(
                  translate(
                    'dashboard.latest_survey_status.extend_survey.error',
                  ),
                );
              },
            });
          }}
        />
      )}
    </Container>
  );
};
