import React from 'react';
import { IconProps } from '../IconProps';

const SvgTextBlock = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M4 9.25a.75.75 0 0 1 .75-.75h22.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 9.25zM4 13.75a.75.75 0 0 1 .75-.75h18.5a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1-.75-.75zM4 18.25a.75.75 0 0 1 .75-.75h22.5a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1-.75-.75zM4 22.75a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5h-14a.75.75 0 0 1-.75-.75z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgTextBlock;
