import React from 'react';
import { useTranslation } from 'react-i18next';

export interface QuestionnaireIntroProps {
  firstName?: string;
  seriesTitle: string;
  isInitialQuestionnaire: boolean;
}

const QuestionnaireIntro = (props: QuestionnaireIntroProps) => {
  const { t: translate } = useTranslation('courses');

  const { firstName, seriesTitle, isInitialQuestionnaire } = props;

  return (
    <React.Fragment>
      {isInitialQuestionnaire ? (
        <React.Fragment>
          <p data-testid="initial">
            {translate('segment.questionnaire.intro.body_1.course_start', {
              first_name:
                firstName ??
                translate('segment.questionnaire.intro.fallback_first_name'),
              course_title: seriesTitle,
            })}
          </p>
          <p>{translate('segment.questionnaire.intro.body_2.course_start')}</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p data-testid="final">
            {translate('segment.questionnaire.intro.body_1.course_end', {
              first_name:
                firstName ??
                translate('segment.questionnaire.intro.fallback_first_name'),
              course_title: seriesTitle,
            })}
          </p>
          <p>{translate('segment.questionnaire.intro.body_2.course_end')}</p>
        </React.Fragment>
      )}
      <p>{translate('segment.questionnaire.intro.body_3')}</p>
      <p>{translate('segment.questionnaire.intro.body_4')}</p>
    </React.Fragment>
  );
};

export default QuestionnaireIntro;
