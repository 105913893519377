import { Form, Formik, FormikActions, FormikErrors } from 'formik';
import { rem } from 'polished';
import React, { useState } from 'react';

import gql from 'graphql-tag';
import { css, styled } from 'styles';

import { animated, Transition } from 'react-spring/renderprops';
import { CustomTypeOptions, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { AlertBox, InputSize } from '../Shared/Form';
import { AlertBoxProps } from '../Shared/Form/AlertBox';
import {
  FormikInput,
  FormikValidatedPasswordInput,
} from '../Shared/Form/Formik';
import Label from '../Shared/Form/Label';
import { GridItem } from '../Shared/Grid';
import PrimaryButton from '../Shared/PrimaryButton';
import { ButtonSize } from '../Shared/Button';
import { isPasswordValid } from '../Shared/ValidatorMatch/PasswordValidator';
import { small } from '../utils';
import {
  UpdateUserPassword,
  UpdateUserPasswordVariables,
} from './__generated__/UpdateUserPassword';

const AlertContainer = styled(AlertBox)`
  margin-bottom: ${rem(24)};
`;

const FieldLabel = styled(Label)`
  display: block;
  margin-bottom: ${rem(8)};
`;

const FormField = styled.div`
  margin-bottom: ${rem(24)};
`;

export const AnimationContainer = styled(animated.div)`
  width: 100%;
  overflow: hidden;
  ${small(css`
    width: 50%;
  `)}
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: ${rem(24)};
  ${small(css`
    width: auto;
  `)}
`;
export interface ChangePasswordProps {
  toggleChangingPassword(): void;
  changingPassword: boolean;
}

export type ChangePasswordOutProps = Pick<
  ChangePasswordProps,
  'toggleChangingPassword' | 'changingPassword'
>;

export interface FormFields {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

type PasswordValidationTranslationOptions =
  CustomTypeOptions['resources']['account']['security']['change_password']['error_states'];

type ValidTranslationKey = keyof PasswordValidationTranslationOptions;

interface StateMessagesKeys {
  [key: string]: ValidTranslationKey;
}

export const StateMessages: StateMessagesKeys = {
  CURRENT_PASSWORD_INVALID: 'current_password_invalid',
  FAILED: 'failed',
  INVALID_PASSWORD: 'invalid_password',
  SUCCESS: 'success',
  NOT_MATCHING: 'not_matching',
};

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      token
    }
  }
`;

const showForm = { height: 'auto' };
const hideForm = { height: 0 };

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  changingPassword,
  toggleChangingPassword,
}) => {
  let formErrorMessage = '';
  const [animating, setAnimating] = useState(false);
  const { t: translate } = useTranslation('account');

  const [updateUserPasswordMutation, { data, error }] = useMutation<
    UpdateUserPassword,
    UpdateUserPasswordVariables
  >(UPDATE_USER_PASSWORD_MUTATION);
  const getTranslatedErrorMessage = (errorKey: ValidTranslationKey) =>
    translate(`security.change_password.error_states.${errorKey}`);

  const initialValues: FormFields = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  if (error) {
    if (error.message === 'Incorrect current password') {
      formErrorMessage = getTranslatedErrorMessage(
        StateMessages.CURRENT_PASSWORD_INVALID,
      );
    } else {
      formErrorMessage = getTranslatedErrorMessage(StateMessages.FAILED);
    }
  }

  const createAlert = () => {
    let alert: AlertBoxProps | undefined;
    if (data && !formErrorMessage) {
      alert = {
        alertType: 'success',
        message: getTranslatedErrorMessage(StateMessages.SUCCESS),
      };
    } else if (formErrorMessage && changingPassword) {
      alert = { alertType: 'failed', message: formErrorMessage };
    }
    if (alert) {
      return (
        <GridItem>
          <AlertContainer alertType={alert.alertType} message={alert.message} />
        </GridItem>
      );
    }
  };

  const handleFormErrors = (values: FormFields): FormikErrors<FormFields> => {
    const formErrors: FormikErrors<FormFields> = Object.keys(values).reduce(
      (errors, key) => {
        // This is used to silently set all the fields as required
        // so the form stays invalid unless everything is filled in
        if (!Boolean(values[key as keyof FormFields])) {
          return {
            [key]: '',
            ...errors,
          };
        }

        const value = values[key as keyof FormFields];

        if (key === 'newPassword') {
          if (!isPasswordValid(value)) {
            return {
              [key]: getTranslatedErrorMessage(StateMessages.INVALID_PASSWORD),
              ...errors,
            };
          }
        }

        return errors;
      },
      {},
    );

    if (values.newPassword !== values.confirmNewPassword) {
      formErrors.confirmNewPassword = getTranslatedErrorMessage(
        StateMessages.NOT_MATCHING,
      );
    }

    return formErrors;
  };

  const onSubmit = async (
    values: FormFields,
    actions: Pick<
      FormikActions<FormFields>,
      'setStatus' | 'setSubmitting' | 'resetForm'
    >,
  ) => {
    const { currentPassword, newPassword } = values;
    let postMutateData = null;
    try {
      const response = await updateUserPasswordMutation({
        variables: { input: { currentPassword, newPassword } },
      });
      postMutateData = response.data;
    } catch (e) {
      console.error(e);
    }

    const newToken = postMutateData?.updateUserPassword?.token;

    if (newToken) {
      localStorage.setItem('token', newToken);
      actions.resetForm(initialValues);
      toggleChangingPassword();
    }

    actions.setSubmitting(false);
  };

  return (
    <>
      <Transition
        native
        items={changingPassword}
        from={hideForm}
        enter={showForm}
        leave={hideForm}
        onStart={() => {
          setAnimating(true);
        }}
        onDestroyed={() => {
          setAnimating(false);
        }}
      >
        {show =>
          show &&
          (transition => (
            <AnimationContainer style={transition}>
              {changingPassword || animating ? (
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validate={handleFormErrors}
                >
                  {({
                    errors,
                    handleChange,
                    isSubmitting,
                    isValid,
                    setFieldTouched,
                    touched,
                  }) => (
                    <Form>
                      <GridItem>
                        <FormField>
                          <FieldLabel htmlFor="currentPassword">
                            {translate(
                              'security.change_password.current_password_label',
                            )}
                          </FieldLabel>
                          <FormikInput
                            id="currentPassword"
                            inputSize={InputSize.regular}
                            name="currentPassword"
                            secure
                          />
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField>
                          <FieldLabel htmlFor="newPassword">
                            {translate(
                              'security.change_password.new_password_label',
                            )}
                          </FieldLabel>
                          <FormikValidatedPasswordInput
                            id="newPassword"
                            inputSize={InputSize.regular}
                            name="newPassword"
                            placeholder=""
                            showValidator={
                              Boolean(touched.newPassword) &&
                              errors.newPassword !== undefined
                            }
                          />
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField>
                          <FieldLabel htmlFor="confirmNewPassword">
                            {translate(
                              'security.change_password.confirm_password_label',
                            )}
                          </FieldLabel>
                          <FormikInput
                            id="confirmNewPassword"
                            inputSize={InputSize.regular}
                            name="confirmNewPassword"
                            onChange={e => {
                              setFieldTouched('confirmNewPassword');
                              handleChange(e);
                            }}
                            secure
                          />
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <SubmitButton
                          disabled={isSubmitting || !isValid}
                          label={translate(
                            'security.change_password.submit_password_label',
                          )}
                          ariaLabel={translate(
                            'security.change_password.submit_password_label',
                          )}
                          loading={isSubmitting}
                          size={ButtonSize.medium}
                          type="submit"
                        />
                      </GridItem>
                    </Form>
                  )}
                </Formik>
              ) : null}
            </AnimationContainer>
          ))
        }
      </Transition>
      {createAlert()}
    </>
  );
};

export default ChangePassword;
