import { useQuery } from '@apollo/client';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import { exploreFeaturedContent } from 'Explore/__generated__/exploreFeaturedContent';
import { filterOutCollectionsFromContent } from 'Explore/Collections/collectionsHelpers';
import { FEATURED_CONTENT_QUERY } from 'Explore/explorePageQueries';
import FeaturedContent from 'Explore/FeaturedContent/FeaturedContent';
import { Series, Tool } from 'Explore/types';
import { getUserCourses } from 'Explore/YourCourses/__generated__/getUserCourses';
import { USER_COURSES_QUERY } from 'Explore/YourCourses/userCoursesQuery';
import YourCourses from 'Explore/YourCourses/YourCourses';
import React from 'react';
import styled, { css } from 'styled-components';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { Arrow } from 'icons';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import RoutePath from 'App/RoutePath';
import { extraSmall, medium } from 'utils';
import { HelpIcon } from 'Help/HelpIcons';
import SectionError from 'Shared/SectionError';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import HelpBanner from './HelpBanner';
import { useGetEAPServices } from './hooks/useGetEAPServices';

const HomepageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const HomepageBanner = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const HelplineTitle = styled(HeadingText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize20}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${rem(16)};
`;

const EAPServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(16)};
  padding-bottom: ${rem(64)};

  ${extraSmall(css`
    gap: ${rem(24)};
  `)}
`;

const EAPServiceCard = styled(Link)`
  display: flex;
  background: ${({ theme }) => theme.colors.background.card};
  padding: ${rem(20)};
  border-radius: ${rem(12)};
  gap: ${rem(8)};
  align-items: center;
  flex: 0 0 100%;
  cursor: pointer;

  ${extraSmall(css`
    flex: 0 0 calc(50% - ${rem(16)}); // Adjusted to account for the gap
  `)}

  ${medium(css`
    flex: 0 0 calc(33.333% - ${rem(16)}); // Adjusted to account for the gap
  `)}
`;

const EAPServiceName = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const EAPServiceArrow = styled(Arrow).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const ErrorWrapper = styled.div`
  margin-top: 56px;
`;

export const CRISIS_SUPPORT_SERVICE_ID = '7HFpIlFvksx9GXNhEvuzzY';

export const HelpHomepage = () => {
  const { t: translate } = useTranslation('help');

  const {
    data: featuredContentData,
    loading: featuredContentLoading,
    error: featuredContentError,
    refetch: featuredContentRefetch,
  } = useQuery<exploreFeaturedContent>(FEATURED_CONTENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: yourCoursesData,
    loading: yourCoursesLoading,
    error: yourCoursesError,
    refetch: yourCoursesRefetch,
  } = useQuery<getUserCourses>(USER_COURSES_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: helpEAPServicesData,
    loading: helpEAPServicesLoading,
    error: helpEAPservicesError,
    refetch: helpEAPServicesRefetch,
  } = useGetEAPServices();

  if (featuredContentLoading || yourCoursesLoading || helpEAPServicesLoading) {
    return <LoadingIndicator />;
  }

  if (helpEAPservicesError) {
    return (
      <ErrorWrapper>
        <SectionError onRetry={async () => helpEAPServicesRefetch()} />
      </ErrorWrapper>
    );
  }

  const helpServicesToDisplay = helpEAPServicesData?.helpEAPServices?.filter(
    service => service.id !== CRISIS_SUPPORT_SERVICE_ID,
  ); // Filter out crisis support as we're statically displaying it in the banner

  return (
    <HomepageContainer>
      <HomepageBanner>
        <HelpBanner
          assetToken={featuredContentData?.getAssetToken?.assetToken ?? ''}
        />
      </HomepageBanner>
      <HelplineTitle level={2}>
        {translate('homepage.helpline.title')}
      </HelplineTitle>
      <EAPServicesContainer>
        {helpServicesToDisplay?.map(service => (
          <EAPServiceCard
            key={service.id}
            to={{ pathname: `${RoutePath.Help}`, hash: `${service.icon}` }}
            aria-label={translate('homepage.services.a11y_label', {
              serviceName: service.serviceName,
            })}
            onClick={() => {
              tracking.track('help_homepage_service_clicked', {
                serviceId: service.id,
                serviceName: service.serviceName,
              });
            }}
          >
            <HelpIcon icon={service.icon} />
            <EAPServiceName>{service.serviceName}</EAPServiceName>
            <EAPServiceArrow>{'->'}</EAPServiceArrow>
          </EAPServiceCard>
        ))}
      </EAPServicesContainer>
      <FeaturedContent
        isLoading={featuredContentLoading}
        content={filterOutCollectionsFromContent(
          featuredContentData?.exploreFeaturedContent as (Series | Tool)[],
        )}
        assetToken={featuredContentData?.getAssetToken?.assetToken}
        hasErrored={featuredContentError?.graphQLErrors.length ? true : false}
        refetch={featuredContentRefetch}
      />
      <YourCourses
        courses={yourCoursesData?.getUserCourses?.edges?.filter(
          edge => edge?.node.totalDays !== 1,
        )}
        assetToken={yourCoursesData?.getAssetToken?.assetToken}
        isLoading={yourCoursesLoading}
        hasErrored={yourCoursesError?.graphQLErrors.length ? true : false}
        refetch={yourCoursesRefetch}
      />
    </HomepageContainer>
  );
};
