import React from 'react';
import { Info } from 'icons';
import { styled } from 'styles';
import { BodyText } from '../Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.staticPalette.blue.blue100};
  padding: 10px 14px;
  margin: 16px 0px;
  border: 1px solid ${props => props.theme.colors.staticPalette.blue.blue300};
  border-radius: 13px;
`;

const InformationIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 16,
  width: 16,
}))`
  margin-top: 2px;
`;

const DisclaimerText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  line-height: 18px;
  flex: 1;
  margin-left: 8px;

  & strong {
    font-weight: ${props => props.theme.typography.fontWeights.bold};
  }
`;

export interface AlertDisclaimer {
  disclaimerText: JSX.Element | string;
}

export default function AlertDisclaimer({ disclaimerText }: AlertDisclaimer) {
  return (
    <Wrapper data-testid="alert-disclaimer">
      <InformationIcon />
      <DisclaimerText>{disclaimerText}</DisclaimerText>
    </Wrapper>
  );
}
