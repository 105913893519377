import { gql, useQuery } from '@apollo/client';
import { UserId } from './__generated__/UserId';

export const USERID_QUERY = gql`
  query UserId {
    user {
      id
    }
  }
`;

export const useUserId = () => {
  const { data, loading, error } = useQuery<UserId>(USERID_QUERY, {
    errorPolicy: 'all',
    returnPartialData: false,
  });

  return { data, loading, error };
};
