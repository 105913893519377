import { small } from '@unmind/design-system-components-web';
import { DayPicker } from 'Shared/DayPicker';
import { DisabledDateRange } from 'Shared/DayPicker/DayPicker';
import { format } from 'date-fns';
import { mapUserLocaleToShorthand } from 'i18n/mapUserLocaleToShorthand';
import { Chevron } from 'icons';
import { rem } from 'polished';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const DropdownContent = styled.div<{ isOpen: boolean }>`
  z-index: 1;
  width: ${rem(350)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.colors.background.card};
  padding: ${rem(16)};
  border-radius: ${rem(16)};
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.2);

  ${small(css`
    position: absolute;
    overflow: scroll;
  `)}
`;

export const ChevronIcon = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  transform: rotate(90deg);
`;

export const DayDropdownButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${rem(12)};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: ${rem(16)};
  margin-bottom: ${rem(16)};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
  }
`;

interface DayDropdownDropdownProps {
  date: Date;
  onSelect(field: string, value: Date | string | number): void;
  disabledDateRange?: DisabledDateRange;
}

export const DayDropdownDropdown = ({
  date,
  onSelect,
  disabledDateRange,
}: DayDropdownDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: translate } = useTranslation('shared');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const formatDate = (dateToFormat: Date) => {
    const formattedDate = format(dateToFormat, 'eeee do MMMM', {
      locale: mapUserLocaleToShorthand(),
    });

    return formattedDate;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  return (
    <>
      <DayDropdownButton
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        ref={buttonRef}
        aria-label={translate(
          'add_to_calendar.day_dropdown.accessibility_label',
          { value: formatDate(date) },
        )}
      >
        {formatDate(date)}
        <ChevronIcon />
      </DayDropdownButton>
      <DropdownContent ref={wrapperRef} isOpen={isOpen}>
        <DayPicker
          value={date}
          disabledDateRange={disabledDateRange}
          onSelect={(selection: Date) => {
            onSelect('date', selection);
            setIsOpen(false);
          }}
        />
      </DropdownContent>
    </>
  );
};
