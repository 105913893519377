import { useLazyQuery } from '@apollo/client';
import { Search as MagnifyingGlass } from 'icons';
import debounce from 'lodash/debounce';
import { rem } from 'polished';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { css, styled, useTheme } from 'styles';
import uuid from 'uuid';
import { tracking } from '../../App/Tracking';
import RoutePath from '../../App/RoutePath';
import { getSentAtTimestamp } from '../../App/Tracking/serverside';
import { BodyText } from '../../Shared/Typography';
import { extraSmall, small } from '../../utils';
import Modal from './Modal';
import {
  exploreSearch as ExploreSearch,
  exploreSearch_exploreSearch as ExploreSearchResponse,
  exploreSearchVariables as ExploreSearchVariables,
} from './__generated__/exploreSearch';
import { LOCATION_HASH } from './constants';
import { SEARCH_QUERY } from './searchQuery';

const DEBOUNCE_WAIT = 450;
export const NUMBER_OF_RESULTS = 20;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SearchBar = styled(Link)`
  margin-top: ${rem(20)};
  background-color: ${({ theme }) => theme.colors.background.card};
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  border-radius: ${rem(32)};
  padding: ${rem(10)} ${rem(16)};
  display: flex;
  flex: 1;
  align-items: center;
  cursor: text;

  ${extraSmall(css`
    margin-bottom: ${rem(24)};
  `)}

  ${small(css`
    margin-top: ${rem(48)};
    margin-bottom: ${rem(34)};
    max-width: ${rem(545)};
  `)}
`;

const SearchIcon = styled(MagnifyingGlass).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-right: ${rem(10)};
  cursor: default;
`;

const Overlay = styled.div`
  height: 100%;
  ${extraSmall(css`
    background-color: ${({ theme }) => theme.colors.background.secondary};
    position: fixed;
    z-index: ${({ theme }) => theme.zIndex.modal};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `)}
`;

const Search = () => {
  const { hash, search } = useLocation();
  const { typography } = useTheme();
  const { t: translate } = useTranslation('explore');
  const [searchSessionId, setSearchSessionId] = useState('');
  const currentSearchTerm = new URLSearchParams(search).get('q');
  const [searchTerm, setSearchTerm] = useState(currentSearchTerm ?? '');
  const [results, setResults] = useState<ExploreSearchResponse | null>(null);
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [getInitialSearchResults, { data, error }] =
    useLazyQuery<ExploreSearch>(SEARCH_QUERY, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      onCompleted: resultsData => {
        setIsLoading(false);
        setResults(resultsData.exploreSearch || null);
      },
    });

  const getSearchResults = useCallback(
    (args: { variables: ExploreSearchVariables }) => {
      const {
        variables: {
          input: { searchTerm: term },
        },
      } = args;
      if (term !== '') {
        getInitialSearchResults(args);
      } else {
        setResults(null);
        setSearchSessionId('');
      }
    },
    [getInitialSearchResults],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(
        getSearchResults,
        isFirstSearch && currentSearchTerm ? 0 : DEBOUNCE_WAIT,
      ),
    [currentSearchTerm, getSearchResults, isFirstSearch],
  );

  const runSearchQuery = (term: string) => {
    const sessionId = uuid();
    const sentAt = getSentAtTimestamp();

    setIsLoading(true);
    setSearchTerm(term);
    setSearchSessionId(sessionId);

    // Only need to provide sessionId and timestamp on first run
    void debouncedSearch({
      variables: {
        input: {
          searchTerm: term,
          searchSessionId: sessionId,
          first: NUMBER_OF_RESULTS,
          clientSentAtUtcTimestamp: sentAt,
        },
      },
    });
  };

  return (
    <Wrapper>
      <SearchBar
        to={`${RoutePath.Explore}${LOCATION_HASH}`}
        aria-label={translate('search.search_bar.a11y_label')}
        onClick={() => {
          tracking.track('search-clicked');
          if (error) {
            runSearchQuery(searchTerm);
          }
        }}
      >
        <SearchIcon aria-hidden={true} />
        <BodyText sizes={[typography.fontSizes.fontSize16]}>
          {translate('search.search_input.placeholder_text')}
        </BodyText>
      </SearchBar>
      {hash.includes(LOCATION_HASH) ? (
        <>
          <Overlay />
          <Modal
            searchTerm={searchTerm}
            searchSessionId={searchSessionId}
            setSearchTerm={setSearchTerm}
            runSearchQuery={runSearchQuery}
            setIsFirstSearch={setIsFirstSearch}
            results={results}
            assetToken={data?.getAssetToken?.assetToken || null}
            isLoading={isLoading}
            hasErrored={error}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Search;
