import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getRiskQuestionDetail } from './__generated__/getRiskQuestionDetail';

export const RISK_QUESTION_DETAIL_QUERY = gql`
  query getRiskQuestionDetail($indexScheduleId: String!, $questionId: String!) {
    riskQuestionDetail(
      indexScheduleId: $indexScheduleId
      questionId: $questionId
    ) {
      id
      bottom2Percentage(decimalPlaces: 1)
      bottom2PercentageBenchmark {
        difference(decimalPlaces: 1)
      }
      meanScore(decimalPlaces: 1)
      questionText
      title
      description
      definition
      riskCategory
      overallCost
      responseDistributions {
        responseOptionId
        responseNumValue
        label
        responses
      }
      recommendedActions {
        actionId
        themeId
        title
        description
        markedAsTaken
        score
        riskCategory
      }
    }
  }
`;

export const useRiskQuestionDetail = ({
  indexScheduleId,
  questionId,
}: {
  indexScheduleId: string;
  questionId: string;
}) =>
  useQuery<getRiskQuestionDetail>(RISK_QUESTION_DETAIL_QUERY, {
    variables: {
      indexScheduleId,
      questionId,
    },
    fetchPolicy: 'no-cache',
  });
