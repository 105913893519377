function parseJWT(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  } catch {
    // in the case that the user changes the token manually or something
    return null;
  }
}

function hasTokenExpired(expiry: number) {
  return expiry <= Date.now() / 1000;
}

export function isIrisTokenValid(token: string | undefined, userId: string) {
  // the token not being defined yet does not mean it is invalid
  if (token === undefined) {
    return true;
  }

  const parsedToken = parseJWT(token);
  // a token that can't be parsed is invalid
  if (parsedToken === null) {
    return false;
  }

  return (
    parsedToken &&
    !hasTokenExpired(parsedToken.exp) &&
    parsedToken.auth.user_id === userId
  );
}
