import {
  ResetAction,
  StartAction,
  StopAction,
  TimerActions,
  TimeRecord,
  TimerState,
} from '../types';

type ActionType = StartAction | StopAction | ResetAction;

export type Dispatch = (action: ActionType) => void;

export const MAX_SECONDS = 1200;

export const generateTotal = (timeSplits: TimeRecord[]): number =>
  Math.round(
    timeSplits.reduce((total: number, record: TimeRecord) => {
      if (record.end !== undefined) {
        const timeDifferenceSeconds =
          (record.end.getTime() - record.start.getTime()) / 1000;

        const applyCap = record.capped && timeDifferenceSeconds > MAX_SECONDS;

        return total + (applyCap ? MAX_SECONDS : timeDifferenceSeconds);
      }

      return total;
    }, 0),
  );

export const timerReducer = (
  state: TimerState,
  action: ActionType,
): TimerState => {
  const { timeSplits: times } = state;

  const getUnfinishedIndex = (timers: TimeRecord[]): number =>
    timers.findIndex(record => record.end === undefined);

  const endTimer = (timers: TimeRecord[], indexToEnd: number): TimeRecord[] =>
    timers.map((record: TimeRecord, idx: number) => {
      if (idx === indexToEnd) {
        return { ...record, end: new Date() };
      }

      return record;
    });

  switch (action.type) {
    case TimerActions.START: {
      const addTime = (currentTimes: TimeRecord[]): TimeRecord[] =>
        currentTimes.concat([
          { start: new Date(), capped: action.payload.capped },
        ]);

      const unfinishedTimerIndex = getUnfinishedIndex(times);

      // If present, end previously started timers as soon as we start another
      if (unfinishedTimerIndex > -1) {
        const finishedTimes = endTimer(times, unfinishedTimerIndex);

        return {
          timeSplits: addTime(finishedTimes),
          total: generateTotal(addTime(finishedTimes)),
        };
      }

      // Otherwise, just add a new timer
      return {
        timeSplits: addTime(times),
        total: generateTotal(addTime(times)),
      };
    }

    case TimerActions.STOP: {
      const unfinishedTimerIndex = getUnfinishedIndex(times);

      const finishedTimes = endTimer(times, unfinishedTimerIndex);

      const newState = {
        timeSplits: finishedTimes,
        total: generateTotal(finishedTimes),
      };

      if (
        action.payload !== undefined &&
        action.payload.callback !== undefined
      ) {
        action.payload.callback(newState);
      }

      return newState;
    }

    case TimerActions.RESET: {
      if (action.payload !== undefined && action.payload.hasSecondarySegment) {
        return state;
      }

      return {
        timeSplits: [],
        total: 0,
      };
    }

    default: {
      return state;
    }
  }
};

export default timerReducer;
