import React from 'react';
import { IconProps } from '../IconProps';

const SvgMadeForManagers = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <g
      clipPath="url(#MadeForManagers_svg__a)"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22.5 27h-13l-1.69-6.76a1 1 0 0 1 .536-1.139A1 1 0 0 1 8.78 19h14.44a1 1 0 0 1 .79.38 1 1 0 0 1 .18.86L22.5 27zM5 27h22M16 16v1.5M13 6.73a5.81 5.81 0 0 0 4.17 1.77 5.91 5.91 0 0 0 2.3-.5" />
      <path d="M16 12a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm0 12a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zm4.75-7a5 5 0 0 0-9.5 0" />
    </g>
    <defs>
      <clipPath id="MadeForManagers_svg__a">
        <path
          fill={primaryColor}
          transform="translate(4 4)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMadeForManagers;
