export const ANONYMITY_THRESHOLD = 6;
export const COMPASS_ANONYMITY_THRESHOLD = 4;

export enum dashboardFilterContext {
  'activity' = 'ACTIVE_USERS',
  'wellbeing' = 'WELLBEING_DATA',
  'managerReporting' = 'MANAGER_REPORTING',
  'manageUsers' = '', //noop as filters don't get used here
}

export enum timeFilterBreakdownPeriod {
  'LAST_30_DAYS' = 'DAY',
  'LAST_12_WEEKS' = 'WEEK',
  'LAST_6_MONTHS' = 'MONTH',
  'ALL_TIME' = 'MONTH',
}
