import React from 'react';
import { IconProps } from '../IconProps';

const SvgCourseComputer = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <rect
      x={2}
      y={3}
      width={28}
      height={21}
      rx={1}
      fill={secondaryColor}
      stroke={primaryColor}
      strokeOpacity={0.95}
    />
    <path
      d="M15.855 7.864l8.577 3.718-8.577 3.717-8.582-3.717 8.582-3.718z"
      fill={tertiaryColor}
    />
    <path
      d="M11.174 17.324s1.418 1.874 4.679 1.874 4.685-1.876 4.685-1.876v-4.044h-9.372l.008 4.046z"
      fill={tertiaryColor}
    />
    <path
      d="M11.165 13.53l.011 4.046s1.415 1.876 4.677 1.876c3.261 0 4.687-1.876 4.687-1.876v-4.044"
      fill="#fff"
    />
    <path
      d="M11.165 13.53l.011 4.046s1.415 1.876 4.677 1.876c3.261 0 4.687-1.876 4.687-1.876v-4.044"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.855 8.118l8.58 3.718-8.58 3.72-8.58-3.72 8.58-3.718z"
      fill="#fff"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.275 18.375v-6.54M13.724 24.315l-2.132 5.33M17.987 24.315l2.131 5.33M9.46 29.645h12.79"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCourseComputer;
