import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import {
  Card,
  IntegrationName,
  IntegrationType,
  IntegrationDesc,
  IntegrationBanner,
  IntegrationHeader,
} from './styles';

interface IntegrationCardProps {
  name: string;
  description: string;
  type: string;
  button: React.ReactNode;
  logo: React.ReactNode;
  live?: boolean;
}

export const ButtonContainer = styled.div`
  display: flex;
`;

export const IntegrationCard = ({
  name,
  logo,
  description,
  type,
  button,
  live,
}: IntegrationCardProps) => {
  const { t: translate } = useTranslation('account');

  return (
    <Card>
      <IntegrationHeader>
        {logo}
        {live && (
          <IntegrationBanner>
            {translate('integrations.in_development.banner')}
          </IntegrationBanner>
        )}
      </IntegrationHeader>
      <IntegrationName>{name}</IntegrationName>
      <IntegrationType>{type}</IntegrationType>
      <IntegrationDesc>{description}</IntegrationDesc>
      <ButtonContainer>{button}</ButtonContainer>
    </Card>
  );
};
