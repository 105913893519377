import { rem } from 'polished';
import React, { InputHTMLAttributes, PureComponent } from 'react';
import { styled } from 'styles';
import { EyeFormInput } from 'icons';

import { FormElementDisplay } from '../Layout';
import { inputFontStyles } from '../Typography/Forms';
import BodyText from '../Typography/BodyText';
import {
  InputValidator,
  isRequired,
  ValidatorPayload,
} from './inputValidators';
import { InteractiveContainer } from './InteractiveContainer';

const Container = styled.div<{ expandHeight: boolean }>`
  width: 100%;
  flex: ${({ expandHeight }) => (expandHeight ? 1 : 'none')};
  flex-direction: column;
`;

const InputContainer = styled(InteractiveContainer)<{
  hasError?: boolean;
  display?: FormElementDisplay;
  disabled?: boolean;
  focused: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${rem('14px')};
  margin-bottom: ${rem('8px')};
  background: ${({ disabled, theme }) =>
    Boolean(disabled)
      ? theme.colors.background.secondary
      : theme.colors.background.input};

  ${({ display }) =>
    display === FormElementDisplay.Inline &&
    `
    border: 0;
    margin: 0;
    padding: 0;
  `}
`;

export enum InputAlign {
  left = 'left',
  right = 'right',
}

export enum InputSize {
  regular = 'regular',
  large = 'large',
}

const TextInput = styled.input<{
  align?: InputAlign;
  disabled?: boolean;
  inputSize: InputSize;
}>`
  ${({ inputSize, theme }) => inputFontStyles(theme, inputSize)};
  border: 0;
  outline: 0;
  flex: 1;
  max-width: 100%;
  ${({ inputSize }) =>
    `padding: ${rem(inputSize === InputSize.large ? 16 : 13)} 0`};
  text-align: ${({ align }) => (align === InputAlign.right ? 'right' : 'left')};
  background: transparent;
  color: ${({ theme }) => theme.colors.text.primary};

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.secondary};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text.secondary};
    cursor: not-allowed;
  }
`;

const ShowSecureTextButton = styled.a<{ active?: boolean }>`
  text-decoration: none;

  & path {
    fill: ${({ active, theme }) =>
      Boolean(active) ? theme.colors.primary : theme.colors.text.secondary};
  }
`;

const EyeIcon = styled(EyeFormInput).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  secondaryColor: theme.colors.staticPalette.white,
}))`
  width: ${rem('30px')};
  height: ${rem('30px')};
  margin-top: ${rem('5px')};
`;

export const Error = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.error};
  margin: ${rem('-6px')} 0 ${rem('10px')};
  text-align: right;
`;

export const SuffixLabel = styled(BodyText)`
  margin-left: ${rem(4)};
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name?: string;
  error?: string;
  onToggleSecureText?(displayed: boolean): void;

  /**
   * Act as type="password" input and show "Eye" button
   */
  secure?: boolean;

  /**
   * Align input's content to left, or right
   */
  align?: InputAlign;

  /**
   * InputValidator collection, which are called everytime user finishes editing input
   */
  validators?: InputValidator[];

  inputSize?: InputSize;

  /**
   * Called on validation, despite the result
   *
   * @param payload Contains validation results for all validators, despite result
   * @param name Optional input's name specified by name prop
   */
  onValidation?(payload: ValidatorPayload[], name?: string): void;

  /**
   * Called when all fields are valid
   *
   * @param name Optional input's name specified by name prop
   */
  onValidationPass?(name?: string): void;

  /**
   * Called when any of validators have failed
   *
   * @param payload Contains validation result
   * @param name Optional input's name specified by name prop
   */
  onValidationFail?(payload: ValidatorPayload, name?: string): void;

  /**
   * Changes component's behaviour between Inline and Block
   * Block acts like a normal div element
   * Inline acts like an inline element
   */
  display?: FormElementDisplay;

  /**
   * Dictates whether the input container should expand to fill the height of the container.
   * Defaults to true.
   */
  expandHeight?: boolean;

  /**
   * Changes component behaviour to not allow user input values
   * Will also grey out background of input
   */
  disabled?: boolean;

  /**
   * Hide the integrated error text component
   * Note, the field will still outline in red with an error
   */
  hideErrorText?: boolean;

  suffixLabel?: string;
}

export interface InputState {
  shouldShowSecuredText: boolean;
  focused: boolean;
}

export class Input extends PureComponent<InputProps, InputState> {
  validationTimer?: number;

  constructor(props: InputProps) {
    super(props);

    this.state = {
      shouldShowSecuredText: false,
      focused: false,
    };
  }

  componentDidUpdate(prevProps: InputProps) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      // skip timeout to speed up tests
      if (process.env.NODE_ENV === 'test') {
        this.validate();

        return;
      }

      if (this.validationTimer !== undefined) {
        clearTimeout(this.validationTimer);
      }

      // run validation after certain time after finished editing
      this.validationTimer = window.setTimeout(() => {
        this.validate();
      }, 500);
    }
  }

  readonly onTextInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;

    this.validate();

    if (onBlur) {
      onBlur(event);
    }
  };

  readonly onContainerBlur = () => {
    this.setState({ focused: false });
  };

  readonly onContainerFocus = () => {
    this.setState({ focused: true });
  };

  readonly handleSecureTextToggle = (event: React.MouseEvent): void => {
    event.preventDefault();

    const { onToggleSecureText } = this.props;

    this.setState(
      ({ shouldShowSecuredText }) => ({
        shouldShowSecuredText: !shouldShowSecuredText,
      }),
      () => {
        if (onToggleSecureText !== undefined) {
          onToggleSecureText(this.state.shouldShowSecuredText);
        }
      },
    );
  };

  readonly validate = (): void => {
    const {
      name,
      value,
      validators,
      onValidation,
      onValidationFail,
      onValidationPass,
    } = this.props;

    if (!validators) {
      return;
    }

    const result = validators.map(validator => validator(value as string));

    if (onValidation) {
      onValidation(result, name);
    }

    const errors = result.filter(({ message }) => Boolean(message));

    if (errors.length && onValidationFail) {
      onValidationFail(errors[0], name);

      return;
    }

    if (onValidationPass) {
      onValidationPass(name);
    }
  };

  render() {
    const {
      className,
      inputSize = InputSize.large,
      error,
      secure,
      suffixLabel,
      align,
      display,
      expandHeight = true,
      disabled,
      onValidation,
      onValidationFail,
      onValidationPass,
      onToggleSecureText,
      hideErrorText,
      name,
      ...props
    } = this.props;
    const { shouldShowSecuredText, focused } = this.state;
    const inputType =
      Boolean(secure) && !shouldShowSecuredText ? 'password' : 'text';
    const hasError = Boolean(error);
    const shouldShowEyeIcon = Boolean(secure);

    return (
      <Container expandHeight={expandHeight}>
        <InputContainer
          focused={focused}
          display={display}
          disabled={disabled}
          onBlur={this.onContainerBlur}
          onFocus={this.onContainerFocus}
          hasError={hasError}
          className={className}
        >
          <TextInput
            type={inputType}
            align={align}
            onBlur={this.onTextInputBlur}
            inputSize={inputSize}
            disabled={disabled}
            name={name}
            {...props}
          />
          {Boolean(suffixLabel) && <SuffixLabel>{suffixLabel}</SuffixLabel>}
          {shouldShowEyeIcon && (
            <ShowSecureTextButton
              href="#"
              onClick={this.handleSecureTextToggle}
              active={shouldShowSecuredText}
              aria-label="Toggle secure text"
            >
              <EyeIcon />
            </ShowSecureTextButton>
          )}
        </InputContainer>
        {!hideErrorText && hasError && (
          <Error aria-live="polite" id={`${name}-error`}>
            {error}
          </Error>
        )}
      </Container>
    );
  }
}

export default Input;

export interface RequiredInputProps extends InputProps {
  requiredMessage: string;
}

export const RequiredInput = ({
  requiredMessage,
  validators = [],
  ...props
}: RequiredInputProps) => (
  <Input
    validators={[isRequired({ message: requiredMessage }), ...validators]}
    {...props}
  />
);
