import React from 'react';
import { IconProps } from '../IconProps';
const SvgYahoo = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 22" fill="none" {...props}>
    <path
      d="M6.646 20.163H2.759l1.567-3.7L0 6.12h3.95l2.32 5.956L8.59 6.12h3.886m3.637 4.765h-4.326l3.887-9.279H20M12.915 16.527a2.382 2.382 0 100-4.765 2.382 2.382 0 000 4.765z"
      fill="#5F01D1"
    />
  </svg>
);
export default SvgYahoo;
