import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsNightwaves = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.508 5.134a13.998 13.998 0 1 0 7.152 6.9 1 1 0 1 1 1.81-.853 15.998 15.998 0 1 1-8.175-7.886 1 1 0 0 1-.788 1.839z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18.805c-1.029 0-2.077-.383-2.75-1.142l1.499-1.326c.217.246.67.468 1.251.468.58 0 1.034-.222 1.25-.468l1.499 1.326c-.672.76-1.72 1.142-2.749 1.142zM24 18.805c-1.029 0-2.077-.383-2.75-1.142l1.499-1.326c.217.246.67.468 1.251.468.58 0 1.034-.222 1.25-.468l1.499 1.326c-.672.76-1.72 1.142-2.749 1.142zM15 23.5a2.5 2.5 0 0 1 2.5-2.5h1a2.5 2.5 0 0 1 2.5 2.5v3a2.5 2.5 0 0 1-2.5 2.5h-1a2.5 2.5 0 0 1-2.5-2.5v-3zm2.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-1zM27.995 5a1 1 0 1 1 0-2h4.003a1 1 0 0 1 .707 1.707L30.41 7.003h1.589a1 1 0 1 1 0 2h-4.003a1 1 0 0 1-.707-1.707L29.584 5h-1.59zM20.988 10.005a1 1 0 0 1 0-2h4.003a1 1 0 0 1 .707 1.707l-2.296 2.296h1.59a1 1 0 1 1 0 2h-4.004a1 1 0 0 1-.707-1.707l2.296-2.296h-1.59z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsNightwaves;
