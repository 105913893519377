import { Breakpoints } from '@unmind/design-system-theme';
import { LocationDescriptorObject } from 'history';
import { rem, rgba } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import { css, styled } from 'styles';
import { extraSmall, medium, small } from '../../utils';
import useViewportWidth from '../../utils/useViewportWidth';
import Container from '../Container';
import Tab from './Tab';

export interface TabProps {
  activeTab: string;
  tabs: {
    title: string;
    hash: string;
    redirect?: string | string[];
    status?: React.ReactElement;
    testId?: string;
    onClick?(): void;
    visible?: boolean;
  }[];
  children: React.ReactNode[];
  updateHistory(location: LocationDescriptorObject): void;

  /**
   * Dictates whether the tab buttons are aligned to the left or center
   */
  align?: 'center' | 'left';

  /**
   * Dictates whether tab buttons have uppercase labels. Defaults to true
   */
  uppercaseLabels?: boolean;

  /**
   * Dictates whether tab buttons expand to fill available space, or remain the width of the label
   */
  tabsFillSpace?: boolean;

  /**
   * A pixel value dictating the width of each tab button. Used for more granular layout control in cases where
   * `tabsFillSpace` is not specific enough for your needs.
   * If both this and `tabsFillSpace` are used, then the greater value of the two is applied.
   */
  tabWidth?: number;

  /**
   * Adding a class to the grid to customise styles
   */
  tabsClassName?: string;

  /**
   * The background color of the tab contents
   */
  bgColor?: string;

  /**
   * The accent color used on the tab buttons when active. Defaults to the primary theme color inside the tab component
   */
  tabActiveColor?: string;

  /**
   * The accent color used on the tab buttons when inactive. Defaults to gray7 inside the tab component
   */
  tabInactiveColor?: string;

  /**
   * Dictates whether a divider line is added underneath the tab buttons, between the tabs and their contents. Defaults to true
   */
  dividerLine?: boolean;

  /**
   * Dictates whether horizontal padding is applied to the tab label
   */
  tabHorizontalPadding?: boolean;
}

export interface TabState {
  activeTabIdx: number;
}

type PageContainerProps = {
  dividerLine: boolean;
  tabsFixedPosition?: boolean;
};

const ComponentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  margin-top: ${rem(16)};

  ${small(css`
    margin-top: ${rem(0)};
  `)};
`;

const PageContainer = styled.div<PageContainerProps>`
  position: relative;

  ${({ dividerLine, theme }) =>
    dividerLine &&
    `
      border-bottom: 1px solid ${theme.colors.border.secondary}};
  `}
`;

const PillContainer = styled.span`
  padding: 0 0 0 ${rem('6px')};

  ${small(css`
    padding: 0 0 0 ${rem('10px')};
  `)};
`;

type TabTitleContainerType = {
  align?: 'center' | 'left';
  tabsFillSpace?: boolean;
};

const TabTitleContainer = styled.div<TabTitleContainerType>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;

  ${small(css`
    padding: 0.5rem 1rem 0 1rem;
  `)};

  ::before {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: 2;
    height: 100%;
    left: 0;
    bottom: 0;
    width: 1rem;
    ${small(css`
      background: ${({ theme }) => `linear-gradient(
      90deg,
      ${rgba(theme.colors.background.card, 1)} 0%,
      ${rgba(theme.colors.background.card, 0)} 100%
    )`};
    `)};
  }

  ::after {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: 2;
    height: 100%;
    right: 0;
    bottom: 0;
    width: 1rem;
    ${small(css`
      background: ${({ theme }) => `linear-gradient(
      270deg,
      ${rgba(theme.colors.background.card, 1)} 0%,
      ${rgba(theme.colors.background.card, 0)} 100%
    )`};
    `)};
  }

  ${small(css`
    ::before {
      background: none;
    }

    ::after {
      background: none;
    }
  `)};

  ${({ align, tabsFillSpace }) => {
    const centered = align === 'center';

    return css`
      margin: 0 auto;
      ${!tabsFillSpace
        ? css`
            ::before {
              background: none;
            }

            ::after {
              background: none;
            }
          `
        : ''}

      ${!tabsFillSpace
        ? css`
            justify-content: center;
            & > button {
              flex: auto;
              flex-grow: 0;

              &:first-of-type label {
                text-align: left;
              }
            }
          `
        : ''}

      ${small(css`
        justify-content: ${centered ? 'center' : 'flex-start'};

        padding: 4px 0 0 0;
      `)};

      ${extraSmall(css`
        justify-content: ${centered ? 'center' : 'flex-start'};

        padding: 4px 0 0 0;
      `)};
    `;
  }}
`;

const TabContainer = styled(Container)<{
  bgColor: string;
}>`
  display: flex;
  flex: 1 0 auto;
  background-color: ${({ bgColor }) => bgColor};
  padding-top: 26px;
  padding-bottom: 26px;

  ${small(css`
    padding-top: 48px;
    padding-bottom: 48px;
  `)}

  ${medium(css`
    padding-top: 56px;
    padding-bottom: 56px;
  `)}
`;

const TabBody = styled.div`
  width: 100%;
`;

const Tabs = ({
  align = 'center',
  tabsClassName = 'default-tabs',
  tabWidth,
  tabsFillSpace,
  uppercaseLabels = false,
  dividerLine = true,
  tabs,
  activeTab,
  children,
  updateHistory,
  bgColor = 'transparent',
  tabActiveColor,
  tabInactiveColor,
  tabHorizontalPadding,
}: TabProps) => {
  const tabsGrow = tabsFillSpace ?? align === 'center';
  const activeTabIdx = Math.max(
    0,
    tabs.findIndex(tab => {
      if (tab.redirect) {
        const redirect = Array.isArray(tab.redirect)
          ? tab.redirect
          : [tab.redirect];

        return (
          tab.hash.toLowerCase() === activeTab ||
          redirect.some(item => item.toLowerCase() === activeTab)
        );
      }

      return tab.hash.toLowerCase() === activeTab;
    }),
  );
  const [state, setState] = useState({ activeTabIdx });

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;

      return;
    }
    updateHistory({ hash: tabs[state.activeTabIdx].hash });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeTabIdx]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;

      return;
    }
    setState({ activeTabIdx });
  }, [activeTab, activeTabIdx, tabs]);

  const viewportWidth = useViewportWidth();

  return (
    <ComponentContainer>
      <PageContainer dividerLine={dividerLine}>
        {viewportWidth < Breakpoints.S ? (
          <TabTitleContainer
            data-testid="tab-title-container"
            align={align}
            tabsFillSpace={true}
            role="tablist"
          >
            {tabs.map((tab, idx) => {
              if (tab.visible === false) {
                return null;
              }

              return (
                <Tab
                  key={idx}
                  active={idx === state.activeTabIdx}
                  onClick={() => {
                    setState({ activeTabIdx: idx });
                    if (tab.onClick) {
                      tab.onClick();
                    }
                  }}
                  tabWidth={viewportWidth / 2}
                  tabsFillSpace={true}
                  fixedWidth={Boolean(tabWidth)}
                  activeColor={tabActiveColor}
                  inactiveColor={tabInactiveColor}
                  uppercaseLabels={uppercaseLabels}
                  hasStatus={Boolean(tab.status)}
                  testId={tab.testId}
                  horizontalPadding={tabHorizontalPadding}
                >
                  {tab.title}
                  {tab.status ? (
                    <PillContainer>{tab.status}</PillContainer>
                  ) : null}
                </Tab>
              );
            })}
          </TabTitleContainer>
        ) : (
          <Container className={tabsClassName}>
            <TabTitleContainer
              data-testid="tab-title-container"
              align={align}
              tabsFillSpace={tabsGrow}
              role="tablist"
            >
              {tabs.map((tab, idx) => {
                if (tab.visible === false) {
                  return null;
                }

                return (
                  <Tab
                    key={idx}
                    active={idx === state.activeTabIdx}
                    onClick={() => {
                      setState({ activeTabIdx: idx });
                      if (tab.onClick) {
                        tab.onClick();
                      }
                    }}
                    tabWidth={tabWidth}
                    tabsFillSpace={tabsFillSpace}
                    fixedWidth={Boolean(tabWidth)}
                    activeColor={tabActiveColor}
                    inactiveColor={tabInactiveColor}
                    uppercaseLabels={uppercaseLabels}
                    hasStatus={Boolean(tab.status)}
                    testId={tab.testId}
                    horizontalPadding={tabHorizontalPadding}
                  >
                    {tab.title}
                    {tab.status ? (
                      <PillContainer>{tab.status}</PillContainer>
                    ) : null}
                  </Tab>
                );
              })}
            </TabTitleContainer>
          </Container>
        )}
      </PageContainer>
      <TabContainer bgColor={bgColor}>
        <TabBody>{children[state.activeTabIdx]}</TabBody>
      </TabContainer>
    </ComponentContainer>
  );
};

export default Tabs;
