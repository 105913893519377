import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SizeMe } from 'react-sizeme';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import {
  addWeeks,
  subWeeks,
  isWithinInterval,
  toDate,
  getMonth,
  getYear,
} from 'date-fns';
import { styled } from 'styles';

import { useTheme } from 'styled-components';
import { ScreenReaderContent } from '../../Shared/Accessibility';
import { IndexScore as WellbeingScoreByType } from '../../Track/Wellbeing/types/types';
import { getChartDataPointWithLabel } from '../../Track/Wellbeing/Score/helpers/getChartDataPointWithLabels';
import mapYearToEveryMonthExceptThisYear from './mapYearToEveryMonthExceptThisYear';

export const PADDING_DAYS = 3;

// These wrappers are needed to make sizeme work correctly when placed within a flexbox container
const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ID_WELLBEING_TRACKER_LINE_GRAPH = 'wellbeing-tracker-line-graph';
const chartPadding = { top: 0, bottom: 25, left: 35, right: 45 };

const BandingSections = () => {
  const { colors } = useTheme();

  return (
    <defs>
      {/* `x2` and `y2` convert this into a bottom-to-top SVG rather than left-to-right */}
      <linearGradient id="bandingSections" x2="0%" y2="100%">
        <stop offset="0%" stopColor={colors.staticPalette.kiwi.kiwi200} />
        <stop offset="33%" stopColor={colors.staticPalette.kiwi.kiwi200} />
        <stop offset="33%" stopColor={colors.staticPalette.blue.blue200} />
        <stop offset="66%" stopColor={colors.staticPalette.blue.blue200} />
        <stop offset="66%" stopColor={colors.staticPalette.orange.orange200} />
        <stop offset="100%" stopColor={colors.staticPalette.orange.orange200} />
      </linearGradient>
    </defs>
  );
};

export interface WellbeingTrackerLineChartProps {
  yAxisBottom: number;
  yAxisTop: number;
  data: WellbeingScoreByType[];
  height?: number;
  caption: string;
  endDate: Date;
  startDate: Date;
}

const WellbeingTrackerLineChart = ({
  caption,
  data,
  height,
  yAxisBottom,
  yAxisTop,
  startDate,
  endDate,
}: WellbeingTrackerLineChartProps) => {
  const { t: translate } = useTranslation('insights');
  const theme = useTheme();

  const dataWithLabels = data.map((entry, index) =>
    getChartDataPointWithLabel(entry, index, data),
  );

  const a11yTableData = dataWithLabels
    // remove entries without value
    .filter(({ value }) => typeof value === 'number' && !isNaN(value))
    .map(({ date, value }) => ({
      x: moment(date).format('DD-MMMM-YY'),
      y: `${value}`,
    }))
    // most-recent first
    .reverse();

  return (
    <SizeMe monitorWidth={true}>
      {({ size: { width } }) => {
        const monthLabelPosition = width !== null ? width * 0.11 : undefined;

        return (
          <Wrapper style={{ height }}>
            <InnerWrapper
              aria-hidden={true}
              data-testid={ID_WELLBEING_TRACKER_LINE_GRAPH}
            >
              <VictoryChart
                style={{
                  // @ts-ignore victory has incorrectly typed that the `background` style prop does not exist
                  background: {
                    fill: "url('#bandingSections')",
                  },
                }}
                domain={{
                  x: [toDate(startDate), toDate(endDate)],
                  y: [yAxisBottom, yAxisTop],
                }}
                height={height}
                padding={{
                  top: chartPadding.top,
                  bottom: chartPadding.bottom,
                  left: chartPadding.left,
                  right: chartPadding.right,
                }}
                scale={{ x: 'time' }}
                width={width !== null ? width : undefined}
              >
                <BandingSections />

                {/* The x-axis */}
                <VictoryAxis
                  style={{
                    axis: {
                      stroke: 'transparent',
                    },
                    tickLabels: {
                      fill: theme.colors.text.primary,
                      fontFamily: theme.typography.monoText.fontFamily,
                      fontSize: theme.typography.fontSizes.fontSize12,
                      fontWeight: theme.typography.fontWeights.medium,
                      stroke: 'transparent',
                    },
                  }}
                  standalone={false}
                  tickLabelComponent={<VictoryLabel dx={monthLabelPosition} />}
                  tickFormat={(date: Date) => {
                    if (date <= endDate) {
                      return mapYearToEveryMonthExceptThisYear(
                        getMonth(date),
                        getYear(date),
                      ).toUpperCase();
                    }

                    return '';
                  }}
                />

                {/* The y-axis */}
                <VictoryAxis
                  dependentAxis
                  standalone={false}
                  style={{
                    axis: {
                      stroke: 'none',
                    },
                    // this creates a dotted line at the values defined in `tickValues`
                    grid: {
                      stroke: theme.colors.border.primary,
                      strokeDasharray: 3,
                    },
                    tickLabels: {
                      fill: theme.colors.text.primary,
                      fontFamily: theme.typography.monoText.fontFamily,
                      fontSize: theme.typography.fontSizes.fontSize12,
                      fontWeight: theme.typography.fontWeights.medium,
                      stroke: 'transparent',
                    },
                  }}
                  tickFormat={(tick: number) => (tick === 100 ? '100' : '')}
                  tickLabelComponent={<VictoryLabel />}
                  tickValues={[100]}
                />

                {/* This is exclusively to add a border to the top and bottom of the chart */}
                <VictoryAxis
                  dependentAxis
                  tickValues={[yAxisBottom, yAxisTop - 0.5]}
                  tickFormat={() => ''}
                  style={{
                    axis: {
                      stroke: 'none',
                    },
                    grid: {
                      stroke: theme.colors.border.secondary,
                      strokeWidth: 2,
                      strokeDasharray: 0,
                    },
                  }}
                />

                {/* The line which connects data points */}
                <VictoryLine
                  data={data}
                  interpolation="monotoneX"
                  x="date"
                  y="value"
                />

                {/* The data points themselves */}
                <VictoryScatter
                  data={dataWithLabels}
                  x="date"
                  y="value"
                  size={7}
                  style={{
                    data: {
                      fill: theme.colors.brand.primary,
                      stroke: theme.colors.text.primary,
                      strokeWidth: 1,
                    },
                  }}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => [
                          {
                            target: 'labels',
                            mutation: () => ({ active: true }),
                          },
                        ],
                        onMouseOut: () => [
                          {
                            target: 'labels',
                            mutation: () => ({ active: false }),
                          },
                        ],
                      },
                    },
                  ]}
                  labelComponent={
                    <VictoryTooltip
                      cornerRadius={8}
                      //@ts-ignore
                      orientation={({
                        datum,
                      }: {
                        datum: ReturnType<typeof getChartDataPointWithLabel>;
                      }) => {
                        const isWithinWeekOfLeftEdge = isWithinInterval(
                          datum?.date,
                          {
                            start: startDate,
                            end: addWeeks(startDate, 1),
                          },
                        );

                        const isWithinWeekOfRightEdge = isWithinInterval(
                          datum?.date,
                          {
                            start: subWeeks(endDate, 1),
                            end: endDate,
                          },
                        );

                        if (isWithinWeekOfLeftEdge) {
                          return 'right';
                        }

                        if (isWithinWeekOfRightEdge) {
                          return 'left';
                        }

                        const orientation =
                          datum?.value < yAxisTop / 2 ? 'top' : 'bottom';

                        return orientation;
                      }}
                      flyoutStyle={{
                        fill: theme.colors.text.primary,
                        stroke: theme.colors.text.primary,
                      }}
                      //@ts-ignore
                      flyoutPadding={{
                        left: 15,
                        right: 15,
                        bottom: 5,
                        top: 5,
                      }}
                      renderInPortal={false}
                      pointerLength={7}
                      pointerWidth={10}
                      labelComponent={
                        <VictoryLabel
                          lineHeight={12 / 12}
                          style={{
                            fill: theme.colors.staticPalette.white,
                            fontFamily: theme.typography.bodyText.fontFamily,
                            fontSize: theme.typography.fontSizes.fontSize12,
                            fontWeight: theme.typography.fontWeights.medium,
                          }}
                        />
                      }
                    />
                  }
                />
              </VictoryChart>
            </InnerWrapper>

            <ScreenReaderContent as="table">
              <caption>{caption}</caption>
              <tbody>
                <tr>
                  <th scope="col">
                    {translate(
                      'charts.index_line_chart.screen_reader_table.column_1.header',
                    )}
                  </th>
                  <th scope="col">
                    {translate(
                      'charts.index_line_chart.screen_reader_table.column_2.header',
                    )}
                  </th>
                </tr>

                {a11yTableData.map(({ x, y }, idx) => (
                  <tr key={`A11yTable-${caption}-${x},${y}-${idx}`}>
                    <td>{x}</td>
                    <td>{y}</td>
                  </tr>
                ))}
              </tbody>
            </ScreenReaderContent>
          </Wrapper>
        );
      }}
    </SizeMe>
  );
};

export default WellbeingTrackerLineChart;
