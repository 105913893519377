import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import {
  PrimaryButton,
  PrimaryButtonProps,
} from '../../../../Shared/PrimaryButton/PrimaryButton';
import {
  TimerDispatch,
  useTimerState,
  withTimerDispatch,
} from '../../../../Shared/Timer';
import {
  WithLogSeriesActivityResponsesChildProps,
  withLogSeriesActivity,
} from '../withLogSeriesActivity';
import {
  withSeriesTrackingIds,
  withSeriesTrackingIdsChildProps,
} from '../withSeriesTrackingIds';
import { handleNext, onNextStep } from './ButtonsShared';

interface OuterNextButtonProps extends Omit<PrimaryButtonProps, 'label'> {
  segmentId: string;
  segmentTitle: string;
  handleTakeOvers?: onNextStep;
  disabled?: boolean;
  isLastSegmentInSeries?: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
}

interface EnhancedComponentProps {
  slug: string;
  dayNumber: number;
  segmentNumber: number;
}

export interface NextButtonProps
  extends OuterNextButtonProps,
    EnhancedComponentProps,
    withSeriesTrackingIdsChildProps,
    WithLogSeriesActivityResponsesChildProps,
    Pick<RouteComponentProps, 'history'> {
  className?: string;
  timerDispatch: TimerDispatch;
}

export const NextButton = (props: NextButtonProps) => {
  const {
    size,
    className,
    timerDispatch,
    disabled,
    isLastSegmentInSeries = false,
    isComplete = false,
  } = props;

  const { t: translate } = useTranslation('courses');
  const seriesTimerSeconds = get(useTimerState(), 'total', 0);

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    void (async () => {
      if (clicked) {
        setClicked(false);
        await handleNext({
          ...props,
          seconds: seriesTimerSeconds,
          isLastSegmentInSeries,
          isComplete,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  return (
    <PrimaryButton
      disabled={disabled}
      label={translate('next_step_button')}
      onClick={() => {
        timerDispatch.stop();
        setClicked(true);
      }}
      size={size}
      className={className}
      variant={props.variant}
      data-testid="next-step-button"
    />
  );
};

export default compose<NextButtonProps, OuterNextButtonProps>(
  withRouter,
  withTimerDispatch,
  (Component: React.ComponentClass<EnhancedComponentProps>) =>
    (
      props: RouteComponentProps<{
        slug: string;
        day: string;
        segment: string;
      }>,
    ) =>
      (
        <Component
          slug={props.match.params.slug}
          dayNumber={defaultTo(Number(props.match.params.day), 1)}
          segmentNumber={defaultTo(Number(props.match.params.segment), 1)}
          {...props}
        />
      ),
  withSeriesTrackingIds,
  withLogSeriesActivity,
)(NextButton);
