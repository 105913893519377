import React from 'react';
import { getManagerReporting_organisationManagerReporting_courseProgress } from '../__generated__/getManagerReporting';
import {
  Container,
  Title,
  BaseCell,
  Table,
  TableRow,
  TitleRow,
  TitleText,
} from './ManagerReporting.styled';

type CourseProgressTableProps = {
  courseProgress: getManagerReporting_organisationManagerReporting_courseProgress[];
  lineManagers: number;
};

export const CourseProgressTable = ({
  courseProgress,
  lineManagers,
}: CourseProgressTableProps) => (
  <Container>
    <Title>Overall Course Progress</Title>
    <Table>
      <TitleRow>
        <TitleText>Course</TitleText>
        <TitleText>Users In-Progress</TitleText>
        <TitleText>Users Completed</TitleText>
      </TitleRow>
      {courseProgress.map(
        ({
          id,
          content: { title },
          percentageOfUsersInProgress,
          numberOfUsersInProgress,
          percentageOfUsersCompleted,
          numberOfUsersCompleted,
        }) => (
          <TableRow key={id}>
            <BaseCell>{title}</BaseCell>
            <BaseCell>
              {percentageOfUsersInProgress} % ({numberOfUsersInProgress} /{' '}
              {lineManagers})
            </BaseCell>
            <BaseCell>
              {percentageOfUsersCompleted} % ({numberOfUsersCompleted} /{' '}
              {lineManagers})
            </BaseCell>
          </TableRow>
        ),
      )}
    </Table>
  </Container>
);
