import * as Sentry from '@sentry/browser';
import { CaptureContext } from '@sentry/types';
import { noop } from 'lodash';
import { logDataDogException } from '../Datadog';

const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';
const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';
const datadogEnabled = process.env.REACT_APP_DATADOG_ENABLED === 'true';
const sentryEnvironment =
  process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development';

if (isDevEnvironment && sentryEnvironment === 'production') {
  throw new Error(
    'sentry environment is pointing to production when running in development mode',
  );
}

export const initializeLogging = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: sentryEnabled,
    environment: sentryEnvironment,
    release: process.env.SENTRY_RELEASE,
    ignoreErrors: [
      'LaunchDarklyFlagFetchError',
      'network error',
      /Object Not Found Matching Id/i,
    ],
  });
};

interface SentryUserParams {
  id: string;
  subdomain: string;
}

export const configureSentryUserScope = (
  userParams: SentryUserParams | null,
) => {
  const sentryParams = userParams ? { ...userParams } : null;

  Sentry.configureScope(scope => {
    scope.setUser(sentryParams);
  });
};

export const logException = (...args: [unknown, CaptureContext?]) => {
  if (isDevEnvironment) {
    console.error(...args);
  }
  if (sentryEnabled) {
    Sentry.captureException(...args);
  }
  if (datadogEnabled) {
    logDataDogException(...args);
  }
};

/**
 * Log a warning to the console only when in development mode.
 */
export const logDevOnlyWarning = isDevEnvironment ? console.warn : noop;
export const logDevOnlyInfo = isDevEnvironment ? console.log : noop;
