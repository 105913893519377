import React from 'react';
import { IconProps } from '../IconProps';

const SvgSaddestFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M82.9 164.74c44.945 0 81.38-36.435 81.38-81.38 0-44.944-36.435-81.38-81.38-81.38-44.945 0-81.38 36.436-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#EF9358"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.21 92.7c-4.3 3.21-10.62 3.39-15.1.43M46.08 92.7c4.3 3.21 10.62 3.39 15.1.43M96.94 75.55c5.07 5.29 13 8.58 20.35 8.09M98.31 127.88c-.03-2.49-1.53-4.75-3.34-6.47-7.04-6.67-19.47-6.66-26.5 0-1.81 1.71-3.31 3.98-3.34 6.47M67.15 75.55c-5.07 5.29-13 8.58-20.35 8.09"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59 118.04c0 4.7-3.16 8.5-7.05 8.5-3.89 0-7.05-3.81-7.05-8.5 0-4.69 4.38-10.61 7.05-13.83 2.83 3.41 7.05 9.14 7.05 13.83z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSaddestFaceWithBorder;
