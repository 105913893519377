import React, { MutableRefObject, useRef } from 'react';
import {
  practitionersQuery,
  practitionersQuery_practitioners_edges as PractitionersEdges,
  practitionersQuery_practitioners_edges_node as PractitionersEdgeNode,
} from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import { PRACTITIONERS_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import { useQuery } from '@apollo/client';
import { userCountryStateQuery_user as UserLocation } from 'Services/User/__generated__/userCountryStateQuery';
import { BEGatewayQueryContext } from 'utils/apollo';
import useInfiniteScroll, { Direction } from 'hooks/useInfiniteScroll';
import { mergeRelayPageResult } from 'utils/relay';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import SectionError from 'Shared/SectionError/SectionError';
import { useToday } from 'Talk/hooks';
import { FiltersCollection } from '../BrowseFilterPanel/types';
import { PractitionerCard } from '../PractitionerCard';
import EmptyStateCard from '../EmptyStateCard';
import { getDefaultDateRange } from '../PractitionerDrawer/helpers/getDefaultDateRange';
import { ErrorContainer, PractitionerCardsContainer } from './styles';

const INITIAL_PRACTITIONER_LOAD_COUNT = 200; // HACK: Set to this as pagination currently broken.  Revert when fixed!

export interface PractitionerCardListProps {
  filters: FiltersCollection;
  userLocation: UserLocation | null;
  userHasValidLocationData: boolean;
}

export const PractitionerCardList = ({
  filters,
  userLocation,
  userHasValidLocationData,
}: PractitionerCardListProps) => {
  const pageRef: MutableRefObject<null> = useRef(null);
  const { t } = useTranslation<Namespace<'talk'>>('talk');

  const noFiltersApplied = Object.values(filters).every(
    filter => filter.length === 0,
  );
  const today = useToday();

  const defaultDateRange = getDefaultDateRange(today);

  const practitionersQueryVariables = {
    first: INITIAL_PRACTITIONER_LOAD_COUNT,
    ...defaultDateRange,
    filter: {
      country: userLocation?.country?.value,
      state: userLocation?.state?.value,

      where: {
        languages: {
          in: [...filters?.languages?.map(s => s.value)],
        },
        userUserProfileDetailConnects: {
          ...(filters.approaches.length && {
            specialityKeys: {
              in: [...filters?.approaches?.map(s => s.value)],
            },
          }),
          ...(filters.areasOfExpertise.length && {
            and: {
              specialityKeys: {
                in: [...filters?.areasOfExpertise?.map(s => s.value)],
              },
            },
          }),
        },
      },
    },
  };

  const { data, fetchMore, loading, error, refetch } =
    useQuery<practitionersQuery>(PRACTITIONERS_QUERY, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...practitionersQueryVariables,
      },
      skip: !userHasValidLocationData,
      ...BEGatewayQueryContext,
    });

  const fetchMorePractitioners = () => {
    void fetchMore({
      variables: {
        offset: data?.practitioners?.edges?.length,
        after: data?.practitioners?.pageInfo?.endCursor,
        first: 6,
        ...defaultDateRange,
      },
      ...BEGatewayQueryContext,
      updateQuery: mergeRelayPageResult<practitionersQuery, 'practitioners'>(
        'practitioners',
      ),
    });
  };

  useInfiniteScroll(
    () => {
      if (data?.practitioners?.pageInfo?.hasNextPage) {
        fetchMorePractitioners();
      }
    },
    pageRef,
    Direction.DOWN,
    500,
  );

  const noPractitionersFound =
    !loading && !data?.practitioners?.edges?.find(edge => edge?.node);

  if (noPractitionersFound) {
    if (error) {
      return (
        <ErrorContainer>
          <SectionError
            onRetry={() => {
              void refetch({ ...practitionersQueryVariables });
            }}
          />
        </ErrorContainer>
      );
    }

    return (
      <EmptyStateCard
        title={
          noFiltersApplied
            ? t('browse_practitioners.empty_state.title')
            : t('browse_practitioners.empty_state_with_filters.title')
        }
        subtitle={
          noFiltersApplied
            ? t('browse_practitioners.empty_state.subtitle')
            : t('browse_practitioners.empty_state_with_filters.subtitle')
        }
      />
    );
  }

  return (
    <PractitionerCardsContainer>
      {data?.practitioners?.edges
        ?.map(
          (edge: (PractitionersEdges | null) | null) =>
            edge?.node as PractitionersEdgeNode,
        )
        .map((practitioner: PractitionersEdgeNode) =>
          practitioner ? (
            <PractitionerCard
              key={practitioner.id}
              practitioner={practitioner}
            />
          ) : null,
        )}
    </PractitionerCardsContainer>
  );
};
