import gql from 'graphql-tag';
import isNil from 'lodash/isNil';
import { graphql, DataValue } from '@apollo/client/react/hoc';
import { Errors } from '../Shared/Errors';

export interface HelpContentRootFolder {
  id: string;
  title: string;
  summary: string;
  folderType?: string;
  items: HelpContentRootFolder[];
}

export interface WithHelpContentRootApiResponse {
  helpContentRoot?: HelpContentRootFolder;
}

export interface WithHelpContentRootProps
  extends WithHelpContentRootApiResponse {
  loading: boolean;
  error?: Errors;
}

export const withHelpContentRootQuery = gql`
  query withHelpContentRootQuery {
    helpContentRoot {
      id
      title
      summary
      ... on ContentFolder {
        items {
          id
          title
          summary
          folderType
        }
      }
    }
  }
`;

export const mapDataToWithHelpContentRootProps = (
  data?: DataValue<WithHelpContentRootApiResponse, Record<string, never>>,
): WithHelpContentRootProps => {
  if (isNil(data) || data.loading) {
    return { loading: true };
  }

  if ('helpContentRoot' in data) {
    const { helpContentRoot } = data;

    return { loading: false, helpContentRoot };
  }

  return {
    error: Errors.ServerError,
    loading: false,
  };
};

export const withHelpContentRoot = graphql<
  Record<string, never>,
  WithHelpContentRootApiResponse,
  Record<string, never>,
  WithHelpContentRootProps
>(withHelpContentRootQuery, {
  props: ({ data }) => mapDataToWithHelpContentRootProps(data),
});
