import { LaptopUser, Legal, TabHelp } from 'icons';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const createHelpIcon = (IconComponent: React.ComponentType) => styled(
  IconComponent,
).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
  height: 16,
  width: 16,
  ['aria-hidden']: true,
}))`
  margin-right: ${rem(8)};
`;

const SupportIcon = createHelpIcon(TabHelp);
const LaptopUserIcon = createHelpIcon(LaptopUser);
const LegalIcon = createHelpIcon(Legal);

const iconLookupMap: {
  [key: string]: ReturnType<typeof createHelpIcon> | undefined;
} = {
  'crisis-support': SupportIcon,
  'financial-and-legal': LegalIcon,
  'critical-incident': LaptopUserIcon,
};

export const HelpIcon = ({ icon }: { icon: string }) => {
  const Icon = iconLookupMap[icon] || SupportIcon;

  return <Icon />;
};
