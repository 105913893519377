import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import { css, styled, ThemeInterface } from 'styles';
import { Ellipse, IconProps } from 'icons';
import { small, medium } from '../../../utils';
import TextButton from '../../TextButton';
import HeadingText from '../../Typography/HeadingText';
import BodyText from '../../Typography/BodyText';

export const Container = styled.div`
  width: 100%;
  min-height: ${rem('414px')};
  display: flex;
  position: relative;

  ${medium(css`
    min-height: ${rem('312px')};
    margin: 0 0 ${rem('20px')};
  `)}
`;

export const AbsoluteContent = styled(animated.div)`
  max-width: 100%
  min-height: ${rem('414px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  ${medium(css`
    min-height: ${rem('312px')};
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 0 ${rem('40px')} 0;
    padding-top: ${rem('20px')};
  `)}
`;

export const RelativeContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${medium(css`
    flex-direction: row;
    justify-content: flex-end;
  `)}
`;

export const PlusOneBackground = styled((props: IconProps) => (
  <Ellipse {...props} />
)).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.secondary,
  role: 'presentation',
  width: '1638px',
  height: '1162px',
}))`
  position: absolute;
  z-index -1;
  margin-bottom: -20px;
  bottom: 0;
  right: -500px;

  ${medium(css`
    margin-bottom: -190px;
    bottom: 0;
    right: -720px;
  `)}
`;

export const PlusOneImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${rem('210px')};
  height: 100%;
  padding-bottom: ${rem('16px')};

  ${small(css`
    max-width: ${rem('309px')};
  `)}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 ${rem('20px')};

  ${medium(css`
    align-items: start;
    text-align: left;
    width: 100%;
    flex-basis: 100%
    max-width: ${rem('323px')};
    padding: 0 0 0 ${rem('57px')};
  `)}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${rem('14px')} 0 ${rem('20px')};

  ${medium(css`
    justify-content: space-between;
  `)}
`;

export const DismissButton = styled(TextButton)`
  margin: 0 0 0 ${rem('10px')};

  ${medium(css`
    margin: 0 0 0 ${rem('14px')};
  `)}
`;

export const StyledLink = styled(Link)``;

export const FormHeader = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
  ],
}))`
  width: 100%;
`;

export const DismissedHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))`
  width: 100%;
  padding: 0 0 ${rem('8px')} 0;
`;

export const Message = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
  }),
)`
  width: 100%;
  padding: ${rem('5px')} 0 ${rem('10px')} 0;
`;
