import React from 'react';
import { IconProps } from '../IconProps';

const SvgHappiestFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M83.2 164.3c44.945 0 81.38-36.435 81.38-81.38 0-44.945-36.435-81.38-81.38-81.38-44.945 0-81.38 36.435-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#FFF964"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.2 164.3c44.945 0 81.38-36.435 81.38-81.38 0-44.945-36.435-81.38-81.38-81.38-44.945 0-81.38 36.435-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#ACBD30"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.55 91.11c-6.76 16.06-22.64 27.34-41.16 27.34-18.52 0-35.55-12.11-41.86-29.11M42.27 85.9a44.011 44.011 0 0 1-11.03 7.75M118.37 85.9c3.25 3.13 6.98 5.75 11.03 7.75M44.95 65.08c4.3-3.21 10.62-3.39 15.1-.43M117.08 65.08c-4.3-3.21-10.62-3.39-15.1-.43M39.21 76.43c-3.12 1.17-5.6 3.89-6.48 7.1M122.53 76.43c3.12 1.17 5.6 3.89 6.48 7.1"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHappiestFaceWithBorder;
