import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { css, styled, useTheme } from 'styles';
import { Button } from '@unmind/design-system-components-web';
import { GoogleCalendar as GoogleCalendarIcon } from 'icons';
import Modal from 'Shared/Modal/Modal';
import { useTrackServerside } from 'App/Tracking/serverside';
import { tracking } from 'App/Tracking';
import PrimaryButton from 'Shared/PrimaryButton';
import { ExternalLinkIcon } from 'Shared/Typography/ExternalLink';
import { AlertBox } from '../../../Shared/Form';
import { small } from '../../../utils';
import {
  SLACK_INSTALLATION_ERROR_CODE,
  SLACK_USER_NOT_FOUND,
} from '../constants';
import { Slack as SlackIntegrationCard } from './Slack';
import { IntegrationCard } from './IntegrationCard';
import {
  MSTeamsLogo,
  ModalContent,
  OutlookLogo,
  ThumbIcon,
  ModalTitle,
  ModalDescription,
  InDevelopmentTitle,
} from './styles';

export const Container = styled.div`
  display: grid;
  row-gap: ${rem(24)};
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${rem(24)};
  justify-items: start;

  ${small(css`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.button.primary.default.textColor};
`;

const ButtonText = styled.span<{ timeOutIsExecuting: boolean }>`
  color: ${({ theme, timeOutIsExecuting }) =>
    timeOutIsExecuting
      ? theme.button.primary.onDark.default.textColor
      : theme.button.primary.default.textColor};
`;

interface ExternalLinkButtonProps {
  label: string;
}

const ExternalLinkButton = ({ label }: ExternalLinkButtonProps) => (
  <ButtonContentContainer>
    {label}
    <ExternalLinkIcon />
  </ButtonContentContainer>
);

enum IntegrationType {
  Outlook = 'outlook',
  MSTeams = 'ms_teams',
  Slack = 'slack',
  GoogleCalendar = 'google_calendar',
}

interface ExpressInterestButtonProps {
  onClick(): void;
}

const ExpressInterestButton = ({ onClick }: ExpressInterestButtonProps) => {
  const { t: translate } = useTranslation('account');
  const [timeOutIsExecuting, setTimeOutIsExecuting] = useState(false);
  const theme = useTheme();

  let timer = 0;

  const buttonText = timeOutIsExecuting
    ? translate('integrations.in_development.express_interest_button.active')
    : translate('integrations.in_development.express_interest_button.default');

  const buttonColor = timeOutIsExecuting
    ? theme.colors.background.success
    : theme.button.primary.default.backgroundColor;

  useEffect(() => () => window.clearTimeout(timer), [timer]);

  const expressInterest = () => {
    if (!timeOutIsExecuting) {
      setTimeOutIsExecuting(true);
      onClick();
      timer = window.setTimeout(() => {
        setTimeOutIsExecuting(false);
      }, 3000);
    }
  };

  return (
    <Button
      style={{ backgroundColor: buttonColor }}
      onClick={expressInterest}
      label={
        <ButtonContentContainer>
          <ButtonText timeOutIsExecuting={timeOutIsExecuting}>
            {buttonText}
          </ButtonText>
          {timeOutIsExecuting ? null : <ThumbIcon />}
        </ButtonContentContainer>
      }
    ></Button>
  );
};

export const IntegrationsTab = () => {
  const { t: translate } = useTranslation('account');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const error = searchParams.get('error');
  const [falseDoorModalOpen, setFalseDoorModalOpen] = useState(false);
  const trackServerside = useTrackServerside();

  const sendTracking = (integrationType: IntegrationType) => {
    void tracking.track('integrations-card-clicked', {
      integrationType,
    });
    void trackServerside({
      eventName: 'INTEGRATION_REGISTERED_INTEREST',
      eventProperties: {
        integrationType,
      },
      brazeProperties: {
        integrationType,
      },
    });
  };

  const errorMessage = (() => {
    switch (error) {
      case SLACK_INSTALLATION_ERROR_CODE:
        return translate('integrations.slack.connection_denied_error');
      case SLACK_USER_NOT_FOUND:
        return translate('integrations.slack.user_not_found_error');
      default:
        return null;
    }
  })();

  return (
    <Container>
      {errorMessage ? (
        <AlertBox alertType="failed" message={errorMessage} />
      ) : null}
      <GridWrapper>
        <SlackIntegrationCard
          onCardClick={() => sendTracking(IntegrationType.Slack)}
        />
        <IntegrationCard
          logo={<MSTeamsLogo />}
          name={translate('integrations.ms_teams.name')}
          description={translate('integrations.ms_teams.description')}
          type={translate('integrations.ms_teams.type')}
          button={
            <PrimaryButton
              label={
                <ExternalLinkButton
                  label={translate('integrations.ms_teams.connection_button')}
                />
              }
              onClick={() => {
                sendTracking(IntegrationType.MSTeams);
                window.open(
                  'https://teams.microsoft.com/l/app/c7d6307e-8186-438f-a33f-66b22f269324?source=app-details-dialog',
                  '_blank',
                );
              }}
            />
          }
        />
        <IntegrationCard
          logo={<OutlookLogo />}
          name={translate('integrations.outlook.name')}
          description={translate('integrations.outlook.description')}
          type={translate('integrations.outlook.type')}
          button={
            <PrimaryButton
              label={
                <ExternalLinkButton
                  label={translate('integrations.outlook.connection_button')}
                />
              }
              onClick={() => {
                setFalseDoorModalOpen(!falseDoorModalOpen);
              }}
            />
          }
        />
      </GridWrapper>
      <InDevelopmentTitle>
        {translate('integrations.in_development.title')}
      </InDevelopmentTitle>
      <GridWrapper>
        <IntegrationCard
          live
          logo={<GoogleCalendarIcon height={32} width={32} />}
          name={translate('integrations.google_calendar.name')}
          description={translate('integrations.google_calendar.description')}
          type={translate('integrations.google_calendar.type')}
          button={
            <ExpressInterestButton
              onClick={() => {
                sendTracking(IntegrationType.GoogleCalendar);
              }}
            />
          }
        />
        <Modal
          closeHeader
          open={falseDoorModalOpen}
          onCloseCallback={() => setFalseDoorModalOpen(!falseDoorModalOpen)}
          modalContent={() => (
            <ModalContent>
              <ModalTitle>
                {translate('integrations.false_door.title')}
              </ModalTitle>
              <ModalDescription>
                {translate('integrations.false_door.description')}
              </ModalDescription>
              <ExpressInterestButton
                onClick={() => {
                  sendTracking(IntegrationType.Outlook);
                }}
              />
            </ModalContent>
          )}
        />
      </GridWrapper>
    </Container>
  );
};
