import { rem } from 'polished';
import { css } from 'styles';
import { small } from '../utils';
import { LARGE_HEIGHT, SMALL_HEIGHT } from '../utils/headerHeight';

/**
 * When scrolling an element into view - element.scrollIntoView()
 * the scroll does not take into account elements with `position:fixed;` which can sit on top of it.
 * In our case, the app header is fixed, and covers elements that are scrolled into view with the `block: start` option.
 *
 * The `scroll-margin` css property allows to provide an offset when scrolling.
 *
 * See the following resources:
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 * https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin-top
 * https://caniuse.com/#search=scroll-margin-top
 *
 * Although Safari supports the non-standard "scroll-snap-margin-top" property,
 * it does not apply it for scrollIntoView() - see https://bugs.webkit.org/show_bug.cgi?id=189265
 *
 */

const clearHeaderOnScroll = (offset: number = 0) => css`
  scroll-margin-top: ${rem(SMALL_HEIGHT + offset)};

  ${small(css`
    scroll-margin-top: ${rem(LARGE_HEIGHT + offset)};
  `)}
`;

export default clearHeaderOnScroll;
