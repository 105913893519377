import React from 'react';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import {
  getGoogleCalendarLink,
  getOffice365CalendarLink,
  getOutlookCalendarLink,
} from 'Talk/lib/calendar';
import { talkEventsQuery_unmindEvents_edges_node as TalkEvent } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { Dropdown, DropdownMenuItem } from '../Dropdown';
import {
  AddToCalendarButton,
  AddToCalendarButtonInner,
  AddToCalendarButtonLabel,
  ArrowIcon,
} from '../CalendarLinks/styles';

const trackLinkClick =
  (calendarType: 'google' | 'outlook' | 'office365') => () => {
    tracking.track('talk-event-add-to-calendar-selected', {
      calendarType,
    });
  };

export interface AddToCalendarDropdownProps {
  isOpen: boolean;
  setOpen(open: boolean): void;
  callURL: string;
  talkEvent: TalkEvent;
}

export const AddToCalendarDropdown = ({
  isOpen,
  setOpen,
  callURL,
  talkEvent,
}: AddToCalendarDropdownProps) => {
  const { t: translate } = useTranslation('talk');
  const linkParams = { t: translate, event: talkEvent, callURL };
  const googleCalendarLink = getGoogleCalendarLink(linkParams);
  const outlookCalendarLink = getOutlookCalendarLink(linkParams);
  const office365CalendarLink = getOffice365CalendarLink(linkParams);

  return (
    <Dropdown
      isOpen={isOpen}
      closeDrawer={() => setOpen(false)}
      triggerElement={
        <AddToCalendarButton
          label={
            <AddToCalendarButtonInner>
              <AddToCalendarButtonLabel>
                {translate('upcoming_events.calendar_links.add_to_calendar')}
              </AddToCalendarButtonLabel>
              <ArrowIcon $isOpen={isOpen} />
            </AddToCalendarButtonInner>
          }
          onClick={() => setOpen(!isOpen)}
        />
      }
      menuItems={[
        <DropdownMenuItem
          label={translate('upcoming_events.calendar_links.google')}
          onClick={trackLinkClick('google')}
          href={googleCalendarLink}
          target={'_blank'}
          forwardedAs={'a'}
        />,
        <DropdownMenuItem
          label={translate('upcoming_events.calendar_links.outlook')}
          onClick={trackLinkClick('outlook')}
          href={outlookCalendarLink}
          target={'_blank'}
          forwardedAs={'a'}
        />,
        <DropdownMenuItem
          label={translate('upcoming_events.calendar_links.office_365')}
          onClick={trackLinkClick('office365')}
          href={office365CalendarLink}
          target={'_blank'}
          forwardedAs={'a'}
        />,
      ]}
    />
  );
};
