// eslint-disable-next-line @typescript-eslint/tslint/config
import uuid from 'uuid/v4';
import { logException } from '../../logging';

export const SESSION_ID_KEY = 'userInteractionSessionId';
export const SESSION_EXPIRATION_KEY = 'userInteractionSessionExpiration';
export const SESSION_LENGTH_SECONDS = 30 * 60; // 30 minutes

export const getUTCTimestamp = () => Math.floor(new Date().getTime() / 1000);

/**
 * Generates and stores "user interaction sessions".
 *
 * New sessions are created if the expiration timestamp
 * of the current session is in the past (I.e. expired).
 *
 * The "userInteractionSessionId" is saved to localStorage
 * and used in our Apollo client setup, as a header value
 * to send with each request.
 */
export const updateInteractionSession = () => {
  try {
    const existingSessionId = localStorage.getItem(SESSION_ID_KEY);
    const existingSessionExpiration = Number(
      localStorage.getItem(SESSION_EXPIRATION_KEY),
    );

    if (
      existingSessionId === null ||
      existingSessionId === '' ||
      existingSessionExpiration < getUTCTimestamp()
    ) {
      localStorage.setItem(SESSION_ID_KEY, uuid());
      localStorage.setItem(
        SESSION_EXPIRATION_KEY,
        (getUTCTimestamp() + SESSION_LENGTH_SECONDS).toString(),
      );
    } else {
      localStorage.setItem(
        SESSION_EXPIRATION_KEY,
        (getUTCTimestamp() + SESSION_LENGTH_SECONDS).toString(),
      );
    }
  } catch (error) {
    logException(error);
  }
};
