import React from 'react';
import { IconProps } from '../IconProps';

const SvgHeartbeat = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg viewBox="0 0 36 36" width="1em" height="1em" {...props}>
    <path
      fill={primaryColor}
      d="M13.487 2.372c1.596.578 3.132 1.67 4.513 3.343 1.38-1.674 2.917-2.765 4.513-3.343a8.21 8.21 0 0 1 5.68.021c3.407 1.249 6.02 4.631 5.93 8.224-.03 1.143-.476 2.426-.853 3.336a19.46 19.46 0 0 1-.765 1.622l-.015.027-.004.008-.002.002v.002l-.986-.543.986.543a1.125 1.125 0 1 1-1.971-1.086l.002-.003.01-.018a10.293 10.293 0 0 0 .193-.377c.129-.26.3-.623.474-1.04.36-.87.664-1.828.681-2.53.064-2.496-1.823-5.09-4.454-6.054a5.96 5.96 0 0 0-4.14-.02c-1.432.52-2.95 1.658-4.344 3.74a1.125 1.125 0 0 1-1.87 0c-1.394-2.082-2.912-3.22-4.344-3.74a5.96 5.96 0 0 0-4.14.02C5.95 5.47 4.063 8.063 4.127 10.56c.017.702.32 1.66.682 2.53a17.226 17.226 0 0 0 .667 1.417l.01.018.001.003a1.125 1.125 0 0 1-1.97 1.086l.985-.543c-.986.543-.986.541-.987.541v-.002l-.005-.008-.015-.027-.052-.097a19.462 19.462 0 0 1-.713-1.525c-.377-.91-.824-2.193-.853-3.336-.09-3.593 2.522-6.975 5.93-8.224a8.21 8.21 0 0 1 5.68-.021zm4.534 10.08c.502.01.936.35 1.066.834l2.178 8.14 1.707-3.825a1.125 1.125 0 0 1 1.962-.168l1.166 1.742h6.898a1.125 1.125 0 0 1 0 2.25h-7.5c-.375 0-.725-.187-.934-.499l-.367-.549-2.17 4.864a1.125 1.125 0 0 1-2.114-.168l-1.99-7.434-1.848 5.982a1.125 1.125 0 0 1-2.05.227l-2.098-3.656-.491.734c-.209.312-.56.5-.935.5H3a1.125 1.125 0 0 1 0-2.25h6.9l1.166-1.742a1.125 1.125 0 0 1 1.91.065l1.713 2.986 2.236-7.24a1.125 1.125 0 0 1 1.096-.793zM9.03 24.667a1.125 1.125 0 0 1 1.585.14c1.816 2.171 3.822 3.99 5.381 5.269a42.59 42.59 0 0 0 1.906 1.478l.099.072.099-.072a42.59 42.59 0 0 0 1.906-1.478c1.56-1.28 3.566-3.098 5.381-5.268a1.125 1.125 0 1 1 1.726 1.443c-1.934 2.312-4.052 4.23-5.68 5.564a44.718 44.718 0 0 1-2.008 1.557 30.967 30.967 0 0 1-.74.524l-.044.03-.012.008-.004.002-.001.001L18 33l-.623.937-.001-.001-.004-.002-.012-.009-.043-.029-.159-.109a30.638 30.638 0 0 1-.582-.415 44.718 44.718 0 0 1-2.008-1.557c-1.627-1.335-3.746-3.252-5.68-5.564a1.125 1.125 0 0 1 .141-1.584zm8.348 9.27L18 33l.623.937c-.377.25-.869.25-1.246 0z"
    />
  </svg>
);

export default SvgHeartbeat;
