import React from 'react';
import { IconProps } from '../IconProps';

const SvgCoping = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Coping_svg__clip0_1095_24958)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M16.49 10.85h-3.87a.805.805 0 0 1-.78-.69c-.11-.85-.36-1.77-.68-2.48-.26-.57-.6-1.11-1.08-1.5-.48-.39-1.13-.62-1.74-.49-.61.13-1.15.65-1.18 1.27-.03.62.39 1.15.71 1.68.06.11.11.24.14.4.13.7.02 1.46-.41 2.02-1.35 1.79-1.36 3.71-.98 5.24.44 1.78 2.07 3.02 3.91 3.02h5.57"
        fill="#FFF9EF"
      />
      <path
        d="M16.49 10.85h-3.87a.805.805 0 0 1-.78-.69c-.11-.85-.36-1.77-.68-2.48-.26-.57-.6-1.11-1.08-1.5-.48-.39-1.13-.62-1.74-.49-.61.13-1.15.65-1.18 1.27-.03.62.39 1.15.71 1.68.06.11.11.24.14.4.13.7.02 1.46-.41 2.02-1.35 1.79-1.36 3.71-.98 5.24.44 1.78 2.07 3.02 3.91 3.02h5.57"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.87 10.82h-2.6a1.05 1.05 0 1 0 0 2.1h2.6a1.05 1.05 0 1 0 0-2.1zM17.22 12.99h-2.95a1.05 1.05 0 1 0 0 2.1h2.95a1.05 1.05 0 1 0 0-2.1zM16.71 17.21h-2.44a1.05 1.05 0 1 0 0 2.1h2.44a1.05 1.05 0 1 0 0-2.1zM17.5 15.09h-3.23a1.05 1.05 0 1 0 0 2.1h3.23a1.05 1.05 0 1 0 0-2.1z"
        fill="#FFF9EF"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73 8.4c-.29-.45-.59-.91-.56-1.44.03-.48.35-.9.78-1.13.22.33.46.78.68 1.11.12.18.25.37.3.59 0 .03.01.05 0 .08-.01.04-.05.06-.08.08-.37.24-.75.47-1.12.71z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Coping_svg__clip0_1095_24958">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCoping;
