import {
  BodyText,
  Button,
  Card,
  Container,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { Link } from 'react-router-dom';
import { rem } from 'polished';
import { styled } from 'styles';
import { Arrow, Close } from 'icons';
import { StylelessButton } from 'Shared/Button';

export const QuestionnaireContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  gap: 24px;
`;

export const QuestionnaireHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: auto;
  min-height: ${rem(24)};
`;

export const QuestionnaireForm = styled.form`
  height: 100%;
`;

export const CloseButton = styled(StylelessButton).attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const CloseIcon = styled(Close).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  ['aria-hidden']: true,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
`;

export const BackButton = styled(StylelessButton).attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
`;

export const BackIcon = styled(Arrow).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  ['aria-hidden']: true,
}))`
  width: ${rem(12)};
  height: ${rem(12)};
  transform: rotate(180deg);
  margin-right: 4px;
`;

export const BackText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})``;

export const QuestionCard = styled(Card)`
  max-width: ${rem(560)};
  margin: 0 auto auto;
`;

export const QuestionTextContainer = styled.div<{
  questionBeforeContext: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;

  ${({ questionBeforeContext }) =>
    questionBeforeContext &&
    `
    flex-direction: column-reverse;
  `}
`;

export const QuestionContextText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize18],
})``;

export const QuestionText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.bold,
  forwardedAs: 'legend',
}))``;

export const QuestionScaleBadgeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuestionScaleBadge = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  border-radius: 4px;
  padding: 8px 12px;
`;

export const QuestionCounter = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
  align: 'center',
})`
  margin-bottom: 40px;
`;

export const QuestionResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const QuestionnaireMetaContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const QuestionCTAButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  margin-left: auto;
`;

export const AlertContainer = styled.div`
  margin-top: 20px;
`;

export const ExitModalInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 38px 0;
`;

export const ExitModalHeading = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize22],
  weight: theme.typography.fontWeights.semiBold,
  tabIndex: -1,
}))`
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
`;

export const ExitModalCopy = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize16],
}))`
  margin-bottom: 16px;
`;

export const ExitModalCTA = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.staticPalette.red.red6};
  margin-left: auto;
  transition: 0.2s;

  > span {
    color: ${({ theme }) => theme.colors.staticPalette.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.staticPalette.red.red6};
    opacity: 0.6;
  }
`;

export const QuestionHelpText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize14],
})``;

export const QuestionHelpLink = styled(Link).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.medium,
}))`
  margin: 0;
`;
