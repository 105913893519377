import { Formik, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { USE_SHOW_PRIVACY_CONSENT_STEP_QUERY } from 'LoggedOut/SignUp/hooks/useShowPrivacyConsentStepOnSignup';
import { ShowPrivacyConsentStepOnSignup } from 'LoggedOut/SignUp/hooks/__generated__/ShowPrivacyConsentStepOnSignup';
import {
  StoringDataInEEACheckbox,
  ExplicitConsentCheckbox,
} from 'LoggedOut/SignUp/Checkboxes';
import RoutePath from '../../../App/RoutePath';
import { small, medium } from '../../../utils';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../../LoggedOut/AuthWrapper';
import {
  StyledForm,
  StyledSubmitButton,
} from '../../../LoggedOut/SignUp/Forms/CommonFormStyledComponents';
import { AlertBox } from '../../../Shared/Form';
import { updateExplicitPrivacyConsent } from './__generated__/updateExplicitPrivacyConsent';

const CheckboxWrapper = styled.div`
  margin-bottom: ${rem(28)};
`;

const PrivacyPointList = styled.ul`
  padding-left: 0px;
  margin: 0 ${rem(16)};
`;

const PrivacyPoint = styled.li`
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: ${rem(24)};
  margin-bottom: ${rem(24)};
  ${small(css`
    line-height: ${rem(32)};
  `)};
`;

const ExplicitPrivacyConsentFormContainer = styled(AuthWrapperContent)`
  font-size: ${rem(16)};

  ${small(css`
    font-size: ${rem(24)};
  `)};
  ${small(css`
    padding-top: 0px;
  `)};
  ${medium(css`
    padding-top: 0px;
  `)};
`;

const UPDATE_EXPLICIT_PRIVACY_CONSENT = gql`
  mutation updateExplicitPrivacyConsent($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput)
  }
`;

const ExplicitPrivacyConsentNeeded = () => {
  const { t: translate } = useTranslation(['shared', 'logged_out']);

  const formInitialValues = {
    explicitPrivacyConsentGiven: false,
    explicitEEADataStorageConsentGiven: false,
  };

  const [formError, setFormError] = useState(false);
  const { data } = useQuery<ShowPrivacyConsentStepOnSignup>(
    USE_SHOW_PRIVACY_CONSENT_STEP_QUERY,
  );
  const showEEAExplicitDataStorageConsent =
    data?.showPrivacyConsentStepOnSignup?.showEEAExplicitDataStorageConsent;

  const [updateExplicitPrivacyConsentMutation, { loading }] =
    useMutation<updateExplicitPrivacyConsent>(UPDATE_EXPLICIT_PRIVACY_CONSENT, {
      onCompleted: returnedData => {
        // update user returns a boolean indicating success or not
        if (!!returnedData.updateUser) {
          window.location.assign(
            `${window.location.origin}${RoutePath.SSOLoginSuccess}`,
          );
        }
      },
      onError: () => {
        setFormError(true);
      },
    });

  const onSubmitForm = async ({
    explicitPrivacyConsentGiven,
    explicitEEADataStorageConsentGiven,
  }: {
    explicitPrivacyConsentGiven: boolean;
    explicitEEADataStorageConsentGiven: boolean;
  }) => {
    await updateExplicitPrivacyConsentMutation({
      variables: {
        userInput: {
          explicitPrivacyConsentGiven,
          explicitEEADataStorageConsentGiven,
        },
      },
    });
  };

  const showEEAExplicitDataStorageConsentEvenIfNull =
    showEEAExplicitDataStorageConsent !== false;

  return (
    <AuthWrapper>
      <AuthWrapperBlock data-testid="privacy-consent-page">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:explicit_privacy_consent_needed.heading')}
        </AuthWrapperTitle>
        <ExplicitPrivacyConsentFormContainer>
          <PrivacyPointList>
            <PrivacyPoint data-testid="privacy-point-1">
              {translate('logged_out:explicit_privacy_consent_needed.point_1')}
            </PrivacyPoint>
            <PrivacyPoint data-testid="privacy-point-2">
              {translate('logged_out:explicit_privacy_consent_needed.point_2')}
            </PrivacyPoint>
          </PrivacyPointList>
          <Formik
            validate={values => {
              if (!values.explicitPrivacyConsentGiven) {
                return {
                  explicitPrivacyConsentGiven:
                    'Error: must accept the privacy consent',
                };
              }

              if (
                showEEAExplicitDataStorageConsentEvenIfNull &&
                !values.explicitEEADataStorageConsentGiven
              ) {
                return {
                  explicitEEADataStorageConsentGiven:
                    'Error: must accept the eea data storage consent',
                };
              }

              return {};
            }}
            initialValues={formInitialValues}
            onSubmit={async values => {
              await onSubmitForm(values);
            }}
          >
            {({ isValid, /*values,*/ setFieldValue }) => (
              <StyledForm>
                <div>
                  <CheckboxWrapper>
                    <Field name="explicitPrivacyConsentGiven">
                      {({ field }: FieldProps) => (
                        <ExplicitConsentCheckbox
                          {...field}
                          id="sso-sign-up-explicit-consent"
                          setFieldValue={setFieldValue}
                          checked={field.value as boolean}
                        />
                      )}
                    </Field>
                  </CheckboxWrapper>
                  {showEEAExplicitDataStorageConsentEvenIfNull ? (
                    <CheckboxWrapper>
                      <Field name="explicitEEADataStorageConsentGiven">
                        {({ field }: FieldProps) => (
                          <StoringDataInEEACheckbox
                            {...field}
                            id="storing-data-eea"
                            setFieldValue={setFieldValue}
                            checked={field.value as boolean}
                          />
                        )}
                      </Field>
                    </CheckboxWrapper>
                  ) : null}

                  <StyledSubmitButton
                    data-testid="sso-complete-your-account-button"
                    type="submit"
                    loading={loading}
                    label={translate(
                      'logged_out:explicit_privacy_consent_needed.submit_button.title',
                    )}
                    ariaLabel={translate(
                      'logged_out:explicit_privacy_consent_needed.submit_button.a11y_label',
                    )}
                    disabled={!isValid}
                  />
                </div>
                {formError ? (
                  <AlertBox
                    alertType="failed"
                    // for now, just add a generic error message
                    message={translate(
                      'logged_out:login.errors.default_login_error',
                    )}
                  />
                ) : null}
              </StyledForm>
            )}
          </Formik>
        </ExplicitPrivacyConsentFormContainer>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default ExplicitPrivacyConsentNeeded;
