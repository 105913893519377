import React from 'react';
import { IconProps } from '../IconProps';

const SvgMadeForManagersTool = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    strokeWidth={1.5}
    {...props}
  >
    <path
      d="M17.931 23.25H6.069l-1.542-6.969a1.139 1.139 0 0 1 .166-.885.881.881 0 0 1 .72-.4h13.174a.881.881 0 0 1 .72.4 1.139 1.139 0 0 1 .166.885zm-17.181 0h22.5"
      fill="none"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 18.75a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0-3 0zM8.445 2.781a6.848 6.848 0 0 0 4.93 2.094 6.851 6.851 0 0 0 2.713-.558"
      fill="none"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 4.875a4.125 4.125 0 1 0 8.25 0 4.125 4.125 0 1 0-8.25 0zM17 12.75a6.676 6.676 0 0 0-10 0"
      fill="none"
      stroke={primaryColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgMadeForManagersTool;
