import React from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ParseKeys, Namespace } from 'i18next';
import { Location } from 'icons';
import {
  practitionersQuery_practitioners_edges_node as Practitioner,
  practitionersQuery_practitioners_edges_node_details_specialities_areasOfExpertise as AreaOfExpertise,
  practitionersQuery_practitioners_edges_node_details_specialities_approaches as Approach,
} from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import {
  getAboutMeQuestions,
  getProfileSummary,
} from 'Talk/lib/constants/getProfileSummary';
import { getLocation } from 'Talk/lib/constants/getLocation';
import Briefcase from 'icons/generated/Briefcase';
import { parseBeGatewayId } from 'utils/relay';
import { CollapsibleAnswer } from 'Talk/components';
import { getISOLanguageFromCode } from 'utils/getISOLanguageFromCode';
import { TalkTranslate } from 'Talk/lib/talkTranslate';
import {
  Container,
  PractitionerTitleText,
  PractitionerNameText,
  SummaryText,
  StyledIcon,
  DimmedText,
  ItemRow,
  AboutMeContainer,
  Subheader,
} from './styles';
import { PillList } from './components/PillList';
import { Profession } from './constants';

type PractitionerProfileDetailsProps = {
  practitioner: Practitioner;
};

export const PractitionerProfileDetails = ({
  practitioner,
}: PractitionerProfileDetailsProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { t: translateLanguageName } =
    useTranslation<Namespace<'languages'>>('languages');
  const translateLanguageCode = (code: string) => {
    const { en: languageName } = getISOLanguageFromCode(code);

    return `${translateLanguageName(
      languageName as ParseKeys<Namespace<'languages'>>,
    )}`;
  };

  const {
    city,
    state,
    country,
    firstName,
    lastName,
    languages: languageResponse,
    details,
  } = practitioner;

  // As languages is a json array, empty responses are returned as empty strings
  const languages = languageResponse === '' ? null : languageResponse;

  const {
    profileQuestionResponses,
    type: practitionerType,
    specialities,
    profileInformation,
  } = details || {};

  const profileSummary = getProfileSummary(
    profileQuestionResponses,
    practitionerType ?? '',
  );
  const aboutMeSections = getAboutMeQuestions(profileQuestionResponses);
  const profession = profileInformation?.profession as Profession;
  const professionValue =
    Profession[profession] && !(profession === Profession.other)
      ? t(`practitioner_profile.professions.${profession}`)
      : null;

  return (
    <Container>
      <PractitionerTitleText>
        {professionValue || capitalize(practitionerType)}
      </PractitionerTitleText>
      <PractitionerNameText>{`${firstName} ${lastName}`}</PractitionerNameText>
      {profileInformation?.yearsExperience ? (
        <ItemRow>
          <StyledIcon Icon={Briefcase} />
          <DimmedText>
            {t('practitioner_profile.years_experience', {
              count: profileInformation.yearsExperience,
            })}
          </DimmedText>
        </ItemRow>
      ) : null}
      {(city || state || country) && (
        <ItemRow>
          <StyledIcon Icon={Location} />
          <DimmedText>{getLocation(practitioner)}</DimmedText>
        </ItemRow>
      )}
      <SummaryText>{profileSummary}</SummaryText>

      <PillList
        title={t('practitioner_profile.languages')}
        labels={languages?.map(translateLanguageCode)}
      />

      <PillList
        title={t('practitioner_profile.focus_areas')}
        labels={specialities?.areasOfExpertise
          ?.filter((area): area is AreaOfExpertise => area !== null)
          .map(area => TalkTranslate('specialities', area.translationKey))}
      />

      <PillList
        title={t('practitioner_profile.approaches')}
        labels={specialities?.approaches
          ?.filter((approach): approach is Approach => approach !== null)
          .map(approach =>
            TalkTranslate('specialities', approach.translationKey),
          )}
      />

      {!!aboutMeSections?.length && (
        <>
          <Subheader>{t('practitioner_profile.about_me')}</Subheader>
          <AboutMeContainer>
            {[...aboutMeSections]
              .sort(
                (a, b) =>
                  (parseBeGatewayId(a.profileQuestion.id) as number) -
                  (parseBeGatewayId(b.profileQuestion.id) as number),
              )
              .map(q => (
                <CollapsibleAnswer key={q.id} questionAnswer={q} />
              ))}
          </AboutMeContainer>
        </>
      )}
    </Container>
  );
};
