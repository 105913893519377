import { gql, useQuery } from '@apollo/client';
import { getTeamsTenantIds } from './__generated__/getTeamsTenantIds';

export const ORGANISATION_TEAMS_TENANT_IDS = gql`
  query getTeamsTenantIds {
    organisation {
      msTenantIds
    }
  }
`;

export const useOrganisationTeamsTenantIds = () => {
  const { data, loading, error } = useQuery<getTeamsTenantIds>(
    ORGANISATION_TEAMS_TENANT_IDS,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error };
};
