import React from 'react';
import { IconProps } from '../IconProps';
const SvgLink = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M6.916 3.665L8.503 2.08a3.832 3.832 0 015.418 5.418l-1.586 1.587M9.084 12.335l-1.587 1.586A3.832 3.832 0 012.08 8.503l1.586-1.587M10.167 5.833l-4.334 4.334"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLink;
