import React from 'react';
import { IconProps } from '../IconProps';

const SvgFocusTool = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#FFF4FB" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 50.859V192H85.179c3.488-9.308 6.421-19.639 9.407-30.158 8.145-28.69 16.688-58.783 37.987-73.355 25.902-17.721 54.199-5.412 81.9 6.639 27.915 12.143 55.226 24.023 78.871 4.646 15.248-12.496 29.237-28.72 42.524-44.127 1.385-1.606 2.762-3.203 4.132-4.786z"
      fill="#AB004A"
    />
  </svg>
);

export default SvgFocusTool;
