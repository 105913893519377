import { rem } from 'polished';

import { css } from 'styles';
import { ButtonThemeStyleProps } from 'styles/types/buttons';

import { ButtonSize } from './ButtonSize';

const buttonSizes = {
  [ButtonSize.small]: css`
    padding: ${rem(10)} ${rem(16)};
  `,
  [ButtonSize.medium]: css`
    min-height: ${rem(36)};
    padding: ${rem(12)} ${rem(20)};
  `,
};

const getButtonSize = ({ size = ButtonSize.medium }: BaseButtonStyleProps) =>
  buttonSizes[size];
export interface BaseButtonStyleProps {
  'aria-disabled'?: boolean | 'false' | 'true';
  disabled?: boolean;
  rounded?: boolean;
  size?: ButtonSize;
  styleProps?: ButtonThemeStyleProps;
}

const baseButtonStyles = css<BaseButtonStyleProps>`
  ${getButtonSize}
  ${({ styleProps }) =>
    styleProps?.hover &&
    css`
      &:hover {
        background-color: ${styleProps?.hover.backgroundColor};
      }
    `};

  ${({ styleProps }) =>
    styleProps?.active &&
    css`
      &:active {
        background-color: ${styleProps?.active.backgroundColor};
      }
    `};

  &:focus {
    outline: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: ${rem(-6)};
      left: ${rem(-6)};
      right: ${rem(-6)};
      bottom: ${rem(-6)};
      border: ${({ theme }) =>
        `${rem(theme.button.borderWidth)} solid ${
          theme.button.borderFocusColor
        }`};
      border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};
    }
  }
  border-radius: ${({ rounded, theme }) =>
    rounded
      ? `${rem(theme.button.borderRadius.rounded)}`
      : `${rem(theme.button.borderRadius.default)};`};
  border-style: solid;
  border-width: ${({ theme }) => rem(theme.button.borderWidth)};
  cursor: ${({ 'aria-disabled': ariaDisabled }) =>
    ariaDisabled ? 'default' : 'pointer'};
  display: block;

  ${({ disabled }) => Boolean(disabled) && 'pointer-events: none;'};

  /* custom style overrides */
  background-color: ${({ styleProps }) => styleProps?.backgroundColor};
  border-color: ${({ styleProps }) => styleProps?.borderColor};
`;

export default baseButtonStyles;
