import { ApolloError, MutationFunction, isApolloError } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { graphql } from '@apollo/client/react/hoc';

import { Errors } from '../../Shared/Errors';

export interface CustomIdentifier {
  fieldKey: string;
  fieldValue: string;
}

export interface CreateUserApiResponse {
  createUser: string;
}

export interface CreateUserVariables {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  subdomain: string;
  location?: string;
  department?: string;
  voucher?: string;
  customIdentifiers?: CustomIdentifier[];
}

export interface WithCreateUserProps {
  createUser(variables: CreateUserVariables): Promise<CreateUserResponse>;
}

export interface CreateUserResponse {
  email?: string;
  formError?: {
    code: Errors;
    message?: string;
  };
  fieldErrors?: string[];
}

export const CREATE_USER_MUTATION = gql`
  mutation WithCreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $subdomain: String!
    $location: String
    $customIdentifiers: [UserIdentifierInput]
    $department: String
    $voucher: String
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      subdomain: $subdomain
      customIdentifiers: $customIdentifiers
      location: $location
      department: $department
      voucher: $voucher
    )
  }
`;

export const createUser = async (
  variables: CreateUserVariables,
  mutate?: MutationFunction<CreateUserApiResponse, CreateUserVariables>,
) => {
  try {
    if (mutate) {
      const response = await mutate({
        variables,
      });

      //Linter throws error with if(response && response.data && ...etc), hence the nesting.
      if (response) {
        if (response.data && !isNil(response.data.createUser)) {
          return {
            email: response.data.createUser,
          };
        }
      }
    }
  } catch (error) {
    if (error instanceof Error && isApolloError(error)) {
      return handleCreateUserErrors(error);
    }
  }

  return {
    formError: {
      code: Errors.ServerError,
    },
  };
};

function getFieldErrors(error: ApolloError) {
  return error.graphQLErrors
    .filter(e => e.name === Errors.InvalidArgumentError)
    .map(e => {
      const err = e;

      return err.extensions ? err.extensions.argument : '';
    });
}

export function handleCreateUserErrors(error: ApolloError) {
  const fieldErrors = getFieldErrors(error);
  // The api only returns one error at a time so if there's an error now, may as well return it.
  if (fieldErrors.length > 0) {
    if (fieldErrors[0] !== 'customIdentifiers') {
      return {
        fieldErrors,
      };
    }
  }

  if (error.graphQLErrors.length > 0) {
    if (
      get(error.graphQLErrors[0], 'extensions.argument') === 'customIdentifiers'
    ) {
      return {
        formError: {
          code: Errors.InvalidCustomerIdentifier,
          message: error.graphQLErrors[0].message,
        },
      };
    }
  }

  return {
    formError: {
      code: Errors.ServerError,
    },
  };
}

export const withCreateUser = graphql<
  Record<string, unknown>,
  CreateUserApiResponse,
  CreateUserVariables,
  WithCreateUserProps
>(CREATE_USER_MUTATION, {
  options: {
    fetchPolicy: 'no-cache',
  },
  props: ({ mutate }) => ({
    createUser: async (variables: CreateUserVariables) =>
      createUser(variables, mutate),
  }),
});
