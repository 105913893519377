import { useQuery } from '@apollo/client';
import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useHistory } from 'react-router-dom';
import { css, styled } from 'styles';
import RoutePath from '../../../App/RoutePath';
import SkeletonCard from '../../../Courses/SkeletonCard';
import { SHORTS_CONTAINER_QUERY } from '../../../Services/Shorts/shorts.services';
import { shortsContainerQuery } from '../../../Services/Shorts/__generated__/shortsContainerQuery';
import PrimaryButton from '../../../Shared/PrimaryButton';
import BodyText from '../../../Shared/Typography/BodyText';
import HeadingText from '../../../Shared/Typography/HeadingText';
import { ShortCardVariants } from '../../../Shorts/constants';
import ShortCard from '../../../Shorts/ShortCard';
import { small } from '../../../utils';
import useViewportWidth from '../../../utils/useViewportWidth';
import { TRACK_WELLBEING_CONTENT_SLUG } from '../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const TwoColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled.div`
  flex-direction: column;
  max-width: 50%;

  &:last-of-type {
    ${small(css`
      margin-left: ${rem(52)};
    `)}
  }
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize24],
}))`
  margin-bottom: ${rem(14)};
  text-align: center;
  font-weight: 600;
  line-height: 140%;

  ${small(css`
    text-align: left;
  `)}
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: center;
  margin-bottom: ${rem(24)};

  ${small(css`
    text-align: left;
    margin-bottom: ${rem(36)};
  `)}
`;

const CardContainer = styled.div`
  width: 100%;

  ${small(css`
    max-width: ${rem(300)};
  `)}
`;

const StartButton = styled(PrimaryButton).attrs({ forwardedAs: 'a' })`
  margin-top: ${rem(24)};

  ${small(css`
    margin-top: ${rem(0)};
    width: fit-content;
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  `)}
`;

const SkeletonContainer = styled.div`
  width: ${rem(300)};
`;

const EmptyState = () => {
  const history = useHistory();
  const { t: translate } = useTranslation<Namespace<'track'>>('track');
  const { loading, data } = useQuery<shortsContainerQuery>(
    SHORTS_CONTAINER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      variables: {
        slug: TRACK_WELLBEING_CONTENT_SLUG,
      },
    },
  );

  const onStartButtonClick = () => {
    history.push(RoutePath.UnmindIndex);
  };

  const singleShortToDisplay = data?.getShortsByContainerSlug
    ? data?.getShortsByContainerSlug[0]
    : null;

  const viewportWidth = useViewportWidth();
  const isMobileWeb = viewportWidth < Breakpoints.S;

  {
    return isMobileWeb ? (
      <Wrapper>
        <Title>{translate('wellbeing.empty_state.title')}</Title>
        <Description>
          {translate('wellbeing.empty_state.description')}
        </Description>
        <CardContainer>
          {loading ? (
            <SkeletonCard variant={ShortCardVariants.HORIZONTAL} />
          ) : null}
          {singleShortToDisplay ? (
            <ShortCard
              tool={singleShortToDisplay}
              assetToken={data?.getAssetToken?.assetToken}
              showCategory
              source="track-wellbeing"
              variant={ShortCardVariants.HORIZONTAL}
            />
          ) : null}
        </CardContainer>
        <StartButton
          label={translate('wellbeing.empty_state.start_button.text')}
          onClick={onStartButtonClick}
          data-testid="start-button"
        />
      </Wrapper>
    ) : (
      <TwoColumnContainer>
        <ColumnContainer>
          <Title>{translate('wellbeing.empty_state.title')}</Title>
          <Description>
            {translate('wellbeing.empty_state.description')}
          </Description>
          <StartButton
            label={translate('wellbeing.empty_state.start_button.text')}
            onClick={onStartButtonClick}
            data-testid="start-button"
          />
        </ColumnContainer>
        <ColumnContainer>
          {loading ? (
            <SkeletonContainer>
              <SkeletonCard />
            </SkeletonContainer>
          ) : null}
          {singleShortToDisplay ? (
            <CardContainer>
              <ShortCard
                tool={singleShortToDisplay}
                assetToken={data?.getAssetToken?.assetToken}
                showCategory
                source="track-wellbeing"
                variant={ShortCardVariants.VERTICAL}
              />
            </CardContainer>
          ) : null}
        </ColumnContainer>
      </TwoColumnContainer>
    );
  }
};

export default EmptyState;
