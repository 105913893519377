import React from 'react';
import { IconProps } from '../IconProps';
const SvgTimer = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.02.006A.832.832 0 00.822.07a.792.792 0 00-.301.287.65.65 0 00-.09.352c-.001.203.058.35.2.498.094.1.196.16.327.197l.077.021 1.126.002 1.126.003v1.91c0 2.044 0 2.021.04 2.357A5.71 5.71 0 006.146 9.95a.62.62 0 01.078.048.513.513 0 01-.07.043 5.855 5.855 0 00-1.643 1.42 5.694 5.694 0 00-1.182 2.837c-.041.336-.04.313-.04 2.358v1.91l-1.127.002c-1.22.003-1.156.001-1.274.046a.775.775 0 00-.411.404.78.78 0 00-.002.524.742.742 0 00.465.43l.072.022h15.976l.072-.022a.742.742 0 00.465-.43.782.782 0 000-.52.761.761 0 00-.412-.408c-.119-.045-.055-.043-1.275-.046l-1.126-.002v-1.91c0-2.045 0-2.022-.04-2.358a5.716 5.716 0 00-2.818-4.252.635.635 0 01-.078-.048 6.581 6.581 0 00.532-.34 5.701 5.701 0 002.364-3.96c.04-.336.04-.313.04-2.358V1.43l1.126-.002 1.126-.002.077-.021a.734.734 0 00.483-.431.782.782 0 00.001-.52.742.742 0 00-.465-.43l-.072-.022H9.023C4.643-.002 1.042 0 1.02.005zM4.713 3.33c.004 1.996.003 1.965.038 2.226a4.29 4.29 0 003.532 3.664c.26.044.37.052.716.052.347 0 .457-.008.716-.052a4.28 4.28 0 003.544-3.772c.024-.23.028-.509.028-2.258V1.43H4.71l.003 1.902zm3.91 7.398a4.28 4.28 0 00-3.872 3.708c-.035.262-.034.23-.038 2.226l-.003 1.902h8.577v-1.762c0-1.749-.004-2.027-.028-2.258a4.284 4.284 0 00-3.696-3.796 5.44 5.44 0 00-.94-.02z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgTimer;
