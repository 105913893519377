import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';

const Container = styled.div`
  text-align: center;
`;

const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  margin: 0;

  /* Microsoft IE11 */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    max-width: ${rem('424px')};
    margin: 0 auto;
  }
  white-space: pre-wrap;
`;

const CtaButton = styled(PrimaryButton)`
  margin-top: ${rem(24)};
  display: inline-block;
`;

type PanelEmptyStateProps = {
  text: string;
  buttonText: string;
  buttonUrl: string;
  onButtonClick?(): void;
};

const PanelEmptyState = ({
  buttonText,
  buttonUrl,
  onButtonClick,
  text,
}: PanelEmptyStateProps) => (
  <Container>
    <EmptyText>{text}</EmptyText>
    <CtaButton
      to={buttonUrl}
      label={buttonText}
      size={ButtonSize.small}
      data-testid="empty-panel-button"
      onClick={onButtonClick}
    />
  </Container>
);

export default PanelEmptyState;
