import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import React, { useState } from 'react';
import { AddToCalendarDropdown } from '../AddToCalendarDropdown';
import { Container } from './styles';

interface CalendarLinksProps {
  callURL: string;
  event: Event;
}

export const CalendarLinks = ({ callURL, event }: CalendarLinksProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <AddToCalendarDropdown
        isOpen={isOpen}
        setOpen={setIsOpen}
        callURL={callURL}
        talkEvent={event}
      />
    </Container>
  );
};
