import { DayDropdownDropdown } from 'Shared/AddToCalendar/AddToCalendarModal/DayDropdown';
import React from 'react';

interface DeadlineProps {
  value: Date;
  onSelect(field: string, value: Date | string | number): void;
}

export const Deadline = ({ value, onSelect }: DeadlineProps) => {
  const today = new Date();

  const startDate = new Date(today);
  startDate.setDate(today.getDate() + 1);

  const lastAvailableEndDate = new Date(today);
  lastAvailableEndDate.setDate(today.getDate() + 30);

  return (
    <DayDropdownDropdown
      date={value}
      onSelect={onSelect}
      disabledDateRange={{
        after: lastAvailableEndDate,
        before: startDate,
      }}
    />
  );
};
