function mapLocalesToTarget(locales: string[], targetLocale: string) {
  return Object.assign(
    {},
    ...locales.map(locale => ({ [locale]: targetLocale })),
  );
}

export const DEFAULT_FALLBACK_LOCALE = 'en-GB';

// get the supported locales from the env var
const REACT_APP_DEFAULT_SUPPORTED_LOCALES =
  process.env.REACT_APP_DEFAULT_SUPPORTED_LOCALES;

export const DEFAULT_SUPPORTED_LOCALES = REACT_APP_DEFAULT_SUPPORTED_LOCALES
  ? REACT_APP_DEFAULT_SUPPORTED_LOCALES.split(',')
  : [DEFAULT_FALLBACK_LOCALE];

/** Supported locales in testing which should only be allowed in test organisations */
export const LOCALES_IN_TESTING = [
  'ar',
  'bn-BD',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-AU',
  'en-US',
  'es-419',
  'es-ES',
  'fa-IR',
  'fi-FI',
  'fr-FR',
  'he-IL',
  'hi-IN',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'jv-ID',
  'ko-KR',
  'lt-LT',
  'ms-MY',
  'nl-NL',
  'no',
  'pa',
  'pl-PL',
  'pt-BR',
  'ro-RO',
  'ru-RU',
  'sv-SE',
  'sw-KE',
  'ta-IN',
  'th-TH',
  'tl',
  'tr-TR',
  'ur-PK',
  'vi-VN',
  'zh-CN',
  'zh-Hant',
];

/** A list of production subdomains on which we have all locales enabled for testing purposes */
export const TEST_ORGANISATION_SUBDOMAINS = ['prod-testing', 'preview'];

/** Locales to map to Latin American Spanish (es-419) */
const LATAM_LOCALES = [
  'es-AR', // Argentina
  'es-BO', // Bolivia
  'es-CL', // Chile
  'es-CO', // Colombia
  'es-CR', // Costa Rica
  'es-DO', // Dominican Republic
  'es-EC', // Ecuador
  'es-GT', // Guatemala
  'es-HN', // Honduras
  'es-MX', // Mexico
  'es-NI', // Nicaragua
  'es-PA', // Panama
  'es-PE', // Peru
  'es-PR', // Puerto Rico
  'es-PY', // Paraguay
  'es-SV', // El Salvador
  'es-US', // United States
  'es-UY', // Uruguay
  'es-VE', // Venezuela
];

/** Locales to map to American English (en-US) */
const US_LOCALES = [
  'en-AS', // American Samoa
  'en-GU', // Guam
  'en-MP', // Northern Mariana Islands
  'en-PR', // Puerto Rico
  'en-UM', // US Outlying Islands
  'en-VI', // US Virgin Islands
];

/** Locales to map to German Germany (de-DE) */
const GERMAN_LOCALES = [
  'de-AT', // Austria
  'de-BE', // Belgium,
  'de-CH', // Switzerland
  'de-IT', // Italy
  'de-LI', // Liechtenstein
  'de-LU', // Luxembourg
  'gsw', // Swiss German
  'gsw-CH', // Swiss German (Switzerland)
  'gsw-FR', // Swiss German (France)
  'gsw-LI', // Swiss German (Liechtenstein)
  'nds', // Low German
  'nds-DE', // Low German (Germany)
  'nds-NL', //	Low German (Netherlands)
];

/** Locales to map to Italian Italy (it-IT) */
const ITALIAN_LOCALES = [
  'it-SM', // San Marino
  'it-CH', // Switzerland
  'it-VA', // Vatican City
];

/** Locales to map to Portuguese Brazil (pt-BR) */
const PORTUGUESE_LOCALES = [
  'pt-AO', // Angola
  'pt-CV', // Cape Verde
  'pt-GQ', // Equatorial Guinea
  'pt-FR', // France
  'pt-GW', // Guinea-Bissau
  'pt-LU', // Luxembourg
  'pt-MO', // Macao
  'pt-MZ', // Mozambique
  'pt-PT', // Portugal
  'pt-ST', // São Tomé & Príncipe
  'pt-CH', // Switzerland
  'pt-TL', // Timor-Leste
];

/** Locales to map to Norwegian (no) */
const NORWEGIAN_LOCALES = [
  'no-NO', // Norway
  'nb', // Norwegian Bokmål
  'nn', // Norwegian Nynorsk
];

/** Locales to map to Arabic (ar) */
const ARABIC_LOCALES = [
  'ar-EG', // Egyptian Arabic
];

/** Locales to map to Punjabi (pa) */
const PUNJABI_LOCALES = [
  'pa-PK', // Punjabi (Pakistan)
];

/** Locales to map to Filipino Tagalog (tl) */
const FILIPINO_LOCALES = [
  'fil-PH', // Filipino (Philippines)
];

/** Locales to map to Chinese Traditional (zh-Hant) */
const CHINESE_LOCALES = ['zh-Hant-HK', 'zh-Hant-MO', 'zh-Hant-TW'];

// Note: no mapping required for Polish as pl-PL is the only locale that uses Polish.

/**
 * Firefox only provides the first two characters of a locale,
 * so we have to decide which full locale to assign that against.
 */
const SHORT_LOCALE_MAP: Record<string, string> = {
  bn: 'bn-BD',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  es: 'es-419',
  fa: 'fa-IR',
  fi: 'fi-FI',
  fr: 'fr-FR',
  he: 'he-IL',
  hi: 'hi-IN',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  jv: 'jv-ID',
  ko: 'ko-KR',
  lt: 'lt-LT',
  ms: 'ms-MY',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sv: 'sv-SE',
  sw: 'sw-KE',
  ta: 'ta-IN',
  th: 'th-TH',
  tr: 'tr-TR',
  ur: 'ur-PK',
  vi: 'vi-VN',
  zh: 'zh-Hant',
};

/** Locales to map to another locale */
export const LOCALE_MAP: Record<string, string> = {
  ...mapLocalesToTarget(LATAM_LOCALES, 'es-419'),
  ...mapLocalesToTarget(US_LOCALES, 'en-US'),
  ...mapLocalesToTarget(GERMAN_LOCALES, 'de-DE'),
  ...mapLocalesToTarget(ITALIAN_LOCALES, 'it-IT'),
  ...mapLocalesToTarget(PORTUGUESE_LOCALES, 'pt-BR'),
  ...mapLocalesToTarget(NORWEGIAN_LOCALES, 'no'),
  ...mapLocalesToTarget(ARABIC_LOCALES, 'ar'),
  ...mapLocalesToTarget(PUNJABI_LOCALES, 'pa'),
  ...mapLocalesToTarget(FILIPINO_LOCALES, 'tl'),
  ...mapLocalesToTarget(CHINESE_LOCALES, 'zh-Hant'),
  ...SHORT_LOCALE_MAP,
};
