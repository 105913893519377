import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesPersonalGrowth = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M19.668 6.66c5.713-2.712 10.922.124 12.794 1.884 9.266 8.716-1.32 20.951-12.746 21.22-10.298.228-23.86-8.012-18.508-16.272 5.785-8.882 11.306-3.437 18.46-6.832z"
      fill="#BFEFF7"
    />
    <path
      d="M14.269 27.25c0-4.343-5.065-7.239-5.798-8.686-.512-1.012-.733-2.172 0-2.896.733-.724 1.832-.362 2.199 0C12.136 17.116 13.336 20 18 20c4.665 0 5.865-2.884 7.33-4.332.367-.362 1.467-.724 2.2 0 .733.724.512 1.884 0 2.896-.733 1.447-5.798 4.343-5.798 8.686"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={18} cy={12.75} stroke="#1B1A29" strokeWidth={1.5} r={3.75} />
  </svg>
);

export default SvgSeriesPersonalGrowth;
