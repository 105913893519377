import { rgba, rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { small, medium } from '../../utils';
import PrimaryButton from '../PrimaryButton';
import ExternalLink from '../Typography/ExternalLink';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const ErrorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    rgba(theme.colors.background.inverse, 0.9)};
  color: ${({ theme }) => theme.colors.staticPalette.white};
  text-align: center;
  z-index: 2;

  padding: ${rem(16)};
  ${small(css`
    padding: 0 ${rem(36)};
  `)}

  ${medium(css`
    padding: 0 ${rem(52)};
  `)}
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
`;

const ErrorTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  color: 'currentColor',
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.medium,
}))``;

const ErrorSubtitle = styled(BodyText).attrs(({ theme }) => ({
  color: 'currentColor',
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-top: ${rem(12)};
  ${small(css`
    margin-bottom: ${rem(24)};
  `)}
`;

const SupportEmail = styled(ExternalLink).attrs(() => ({
  color: 'currentColor',
  size: 'small',
}))``;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1 0 auto;

  ${small(css`
    align-items: flex-start;
  `)}
`;

const CloseMediaButton = styled(PrimaryButton).attrs(() => ({
  variant: 'onDarkBg',
}))`
  width: 100%;

  ${small(css`
    width: auto;
  `)}
`;

interface ErrorStateProps {
  onClickCloseMedia(event: React.MouseEvent<HTMLButtonElement>): void;
}

function ErrorState({ onClickCloseMedia }: ErrorStateProps) {
  const { t: translate } =
    useTranslation<Namespace<'media_player'>>('media_player');

  return (
    <ErrorOverlay data-testid="media-error-overlay">
      <TitlesContainer>
        <ErrorTitle>{translate('error.title')}</ErrorTitle>
        <ErrorSubtitle>
          {Trans({
            t: translate,
            i18nKey: 'error.contact_support',
            components: {
              support_link: <SupportEmail href="mailto:support@unmind.com" />,
            },
          })}
        </ErrorSubtitle>
      </TitlesContainer>
      <ButtonContainer>
        <CloseMediaButton
          onClick={onClickCloseMedia}
          label={translate('error.close_media_button')}
        />
      </ButtonContainer>
    </ErrorOverlay>
  );
}

export default ErrorState;
