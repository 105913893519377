import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';

import { Errors } from '../../Shared/Errors';

export interface ResetPasswordResponse {
  resetPassword: boolean;
}

interface ResetPasswordVariables {
  passwordToken: string;
  password: string;
  groupId: string;
}

export interface WithResetPasswordProps {
  resetPassword(
    variables: ResetPasswordVariables,
  ): Promise<ResetPasswordAPIResponse>;
}

export interface ResetPasswordAPIResponse {
  resetPassword: boolean;
  error?: Errors;
}

export const withResetPasswordMutation = gql`
  mutation ResetPassword(
    $passwordToken: String!
    $password: String!
    $groupId: String!
  ) {
    resetPassword(
      passwordToken: $passwordToken
      password: $password
      groupId: $groupId
    )
  }
`;

export const withResetPassword = graphql<
  Record<string, never>,
  ResetPasswordResponse,
  ResetPasswordVariables,
  WithResetPasswordProps
>(withResetPasswordMutation, {
  props: ({ mutate }) => ({
    resetPassword: async (variables: ResetPasswordVariables) => {
      try {
        if (mutate) {
          const response = await mutate({
            variables: variables,
          });

          if (response && response.data) {
            return {
              resetPassword: response.data.resetPassword,
              error: response.data.resetPassword
                ? undefined
                : Errors.ServerError,
            };
          }
        }
      } catch {
        return {
          resetPassword: false,
          error: Errors.ServerError,
        };
      }

      return {
        resetPassword: false,
        error: Errors.ServerError,
      };
    },
  }),
});
