import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { SectionHeadingTitle } from '../../../Explore/ExplorePage';
import ShortCard from '../../../Shorts/ShortCard';
import { medium, small } from '../../../utils';
import {
  ContentCardWrapper,
  HomeScreenSection,
} from '../PersonalisedTodayScreen';
import {
  PersonalisedTodayContent_user_dailySelection_afterWork,
  PersonalisedTodayContent_user_dailySelection_beforeWork,
} from '../__generated__/PersonalisedTodayContent';

const WellbeingBoostsWrapper = styled.div<{ numItems: number }>`
  display: grid;
  grid-gap: 16px;

  ${({ numItems }) =>
    small(css`
      grid-template-columns: repeat(${numItems > 2 ? 3 : 2}, 1fr);
    `)}

  ${medium(css`
    grid-gap: 32px;
  `)}
`;

type Props = {
  wellbeingBoosts: (
    | PersonalisedTodayContent_user_dailySelection_beforeWork
    | PersonalisedTodayContent_user_dailySelection_afterWork
  )[];
  assetToken: string | null | undefined;
};

const WellbeingBoosts = ({ wellbeingBoosts, assetToken }: Props) => {
  const { t: translate } = useTranslation<Namespace<'homepage'>>('homepage');

  return wellbeingBoosts.length ? (
    <HomeScreenSection>
      <SectionHeadingTitle>
        {translate('personalised_today.wellbeing_boosts_section.title')}
      </SectionHeadingTitle>
      <WellbeingBoostsWrapper numItems={wellbeingBoosts.length}>
        {wellbeingBoosts.map(item => (
          <ContentCardWrapper key={item.id}>
            <ShortCard
              tool={item}
              assetToken={assetToken}
              showDescription={false}
              showCategory
              source="today-wellbeing-boosts"
            />
          </ContentCardWrapper>
        ))}
      </WellbeingBoostsWrapper>
    </HomeScreenSection>
  ) : null;
};

export default WellbeingBoosts;
