import { Breakpoints } from '@unmind/design-system-theme';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styles';
import useViewportWidth from '../../../utils/useViewportWidth';
import {
  AnswerLabel,
  AnswerLabels,
  BackArrow,
  BackButton,
  Container,
  Content,
  Question,
  QuestionCounter,
  QuestionText,
  QuestionTextContainer,
  Radio,
  RadioList,
  RadioListItem,
} from '../../../Shared/Radio/Question';

import { ProgressBar } from '../../../Shared/Progress/ProgressBar';
import { Answer } from './Questions';
import { getIndexQuestionnaireQuestions_getIndexQuestionnaireQuestions_questions_scale } from './__generated__/getIndexQuestionnaireQuestions';

export interface RadioQuestionProps {
  className?: string;
  preQuestionText?: string;
  questionText: string;
  scale: getIndexQuestionnaireQuestions_getIndexQuestionnaireQuestions_questions_scale;
  questionNumber: number;
  questionId: string;
  totalQuestions: number;
  selectedOption?: string;
  onBackButtonClick(): void;
  onAnswerSelected(answer: Answer): void;
}

export const ID_QUESTION = 'question-text';

export const RadioQuestion = ({
  className,
  scale: { minimum, maximum, responseOptions },
  onAnswerSelected,
  onBackButtonClick,
  preQuestionText = '',
  questionNumber,
  questionId,
  questionText,
  selectedOption,
  totalQuestions,
}: RadioQuestionProps) => {
  const { t: translate } = useTranslation(['shared', 'unmind_index']);

  const theme = useTheme();
  const questionRef = useRef<HTMLDivElement>(null);
  const handleRadioChange = (option: string) => () => {
    onAnswerSelected({
      questionId,
      responseId: option,
    });

    // request from psychology to focus on the next question title,
    // rather than option to avoid any bias or influence
    // see: ENG-973 & ENG-943
    if (questionRef && questionRef.current) {
      questionRef.current.focus();
    }
  };

  if (totalQuestions === 0) {
    return null;
  }

  // we only display progress for current question once user has selected a radio
  const progress =
    ((questionNumber - (selectedOption !== undefined ? 0 : 1)) /
      totalQuestions) *
    100;

  const questionFullText = `${
    preQuestionText && preQuestionText
  } ${questionText}`;

  const RadioInputs = () => (
    <RadioList role="radiogroup">
      {responseOptions?.map((option, i) => (
        <RadioListItem
          key={`${option.id}`}
          role="presentation"
          data-testid={`radio-button-list-item-${i}`}
          inputs={responseOptions.length}
        >
          <Radio
            role="radio"
            checked={selectedOption === option.id}
            aria-checked={selectedOption === option.id}
            aria-label={option.responseLabel ?? undefined}
            onClick={handleRadioChange(option.id)}
            data-testid={`radio-button-${option.id}`}
          />
        </RadioListItem>
      ))}
    </RadioList>
  );

  const QuestionInput = () => {
    const viewportWidth = useViewportWidth();
    const isSmallScreen = viewportWidth < Breakpoints.XS;

    if (isSmallScreen) {
      return (
        <Question>
          <RadioInputs />
          <AnswerLabels>
            <AnswerLabel style={{ textAlign: 'left' }} aria-hidden="true">
              {minimum}
            </AnswerLabel>
            <AnswerLabel style={{ textAlign: 'right' }} aria-hidden="true">
              {maximum}
            </AnswerLabel>
          </AnswerLabels>
        </Question>
      );
    }

    return (
      <Question>
        <AnswerLabel style={{ textAlign: 'right' }} aria-hidden="true">
          {minimum}
        </AnswerLabel>
        <RadioInputs />
        <AnswerLabel style={{ textAlign: 'left' }} aria-hidden="true">
          {maximum}
        </AnswerLabel>
      </Question>
    );
  };

  return (
    <Container className={className} data-testid={`question-${questionNumber}`}>
      <ProgressBar
        progress={progress}
        borderColor={theme.colors.border.primary}
        rounded={false}
      />
      <Content>
        <QuestionTextContainer>
          <QuestionText
            tabIndex={0}
            ref={questionRef}
            data-testid={ID_QUESTION}
            aria-label={translate(
              'unmind_index:questionnaire.question.a11y_label',
              {
                question_number: questionNumber,
                question_count: totalQuestions,
                question_text: questionFullText,
              },
            )}
          >
            {questionFullText}
          </QuestionText>
        </QuestionTextContainer>
        <QuestionInput />
        {questionNumber !== 1 && (
          <BackButton data-testid="back-button" onClick={onBackButtonClick}>
            <BackArrow /> {translate('shared:radio_question.back_button.label')}
          </BackButton>
        )}
        <QuestionCounter>
          {translate('shared:radio_question.position', {
            current: questionNumber,
            total: totalQuestions,
          })}
        </QuestionCounter>
      </Content>
    </Container>
  );
};

export default RadioQuestion;
