import { Colors } from '@unmind/design-system-theme';
import React from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import { IconType } from 'icons';

interface SectionIconProps {
  icon: IconType;
  primaryColour?: Colors;
  secondaryColour?: Colors;
}

const SectionIcon = (props: SectionIconProps) => {
  const StyledIcon = styled(props.icon).attrs(({ theme }) => ({
    primaryColor: props.primaryColour || theme.colors.primary,
    secondaryColor: props.secondaryColour || theme.colors.text.primary,
  }))`
    height: ${rem(20)};
    width: ${rem(20)};
    margin-right: ${rem(10)};
  `;

  return <StyledIcon />;
};

export default SectionIcon;
