import { Colors, FontSizes, FontWeights } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React, { forwardRef, HTMLProps, ReactNode } from 'react';
import { css, styled, useTheme } from 'styles';
import { extraSmall, small, medium } from '../../utils';

export interface Props
  extends Omit<HTMLProps<HTMLElement>, 'as' | 'ref' | 'sizes'> {
  /**
   * An array of font sizes taken from the design tokens. These set the font size at the default, XS, S and M breakpoints respectively. At least 1 entry is required.
   */
  sizes: FontSizes[];
  /**
   * Currently defaults to shadow
   * Colors will ultimately get overhauled so this value (and the accepted type) is temporary.
   */
  color: Colors;
  /**
   * Defaults to 400
   * Available values; 400 | 500 | 700 | 800
   */
  weight?: FontWeights;
  /**
   * Defaults to `left`
   * The text alignment of the component.
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Defaults to `false`
   * Whether text should be italic
   */
  italic?: boolean;
  /**
   * Not needed for manual use, but Styled Components uses this to apply styles when styling a custom component, e.g. styled(Text)
   */
  className?: string;
  /**
   * Child elements to render
   */
  children?: ReactNode;
}

const StyledText = styled.span<
  Pick<Props, 'align' | 'weight' | 'italic'> & {
    textSizes: Props['sizes'];
    textColor?: Props['color'];
  }
>`
  display: block;
  text-align: ${({ align }) => align};
  color: ${({ textColor }) => textColor};
  font-weight: ${({ weight }) => weight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};

  ${({ textSizes }) => css`
    font-size: ${rem(textSizes[0])};

    ${textSizes[1] &&
    css`
      ${extraSmall(css`
        font-size: ${rem(textSizes[1])};
      `)}
    `}

    ${textSizes[2] &&
    css`
      ${small(css`
        font-size: ${rem(textSizes[2])};
      `)}
    `}

    ${textSizes[3] &&
    css`
      ${medium(css`
        font-size: ${rem(textSizes[3])};
      `)}
    `}
  `}
`;

/**
 * Use as little as possible!
 * In nearly all cases it should be possible to use an existing Typography component
 * (exported from the Typography index.ts, e.g BodyText.tsx, HeadingText.tsx etc)
 */
export const createFontStyle = ({
  size,
  weight,
}: {
  size: FontSizes;
  weight?: FontWeights;
}) => css`
  ${weight && `font-weight: ${weight}`};
  ${size && `font-size: ${rem(size)}`};
`;

/**
 * Do not render this component directly!
 * You should render it through the abstractions provided by the Typography components
 * (exported from Typography index.ts, e.g BodyText.tsx, HeadingText.tsx etc)
 */
const Base = forwardRef<HTMLElement, Props>(
  (
    {
      align = 'left',
      weight,
      italic = false,
      color,
      className,
      sizes,
      children,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();

    return (
      <StyledText
        align={align}
        textSizes={sizes}
        textColor={color}
        weight={weight ?? theme.typography.fontWeights.regular}
        italic={italic}
        className={className}
        ref={ref}
        {...props}
      >
        {children}
      </StyledText>
    );
  },
);

export default Base;
