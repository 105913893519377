import React from 'react';
import { styled } from 'styles';
import { SeriesSegmentType } from '../../types';
import SeriesSegment from './SeriesSegment';
import { UserSeriesSegment } from './SeriesSegment/SeriesSegment';
import { SegmentTrackingProps } from '.';

export interface SeriesSegmentsListProps {
  onToggle(segmentIdx: number): void;
  expandedSegmentOnLoad?: number;
  segmentsToShow: UserSeriesSegment[];
  segmentsLeft: number;
  totalNoOfSegments: number;
  dayCompleted: boolean;
  lastDayInSeries?: boolean;
  segmentTrackingProps: SegmentTrackingProps;
}

const SeriesSegmentListContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const SeriesSegmentsList = (props: SeriesSegmentsListProps) => {
  const {
    segmentsToShow,
    segmentsLeft,
    expandedSegmentOnLoad,
    dayCompleted,
    lastDayInSeries = false,
    segmentTrackingProps,
    totalNoOfSegments,
  } = props;

  return (
    <SeriesSegmentListContainer>
      {segmentsToShow.length > 0 &&
        segmentsToShow.map((segment, idx) => {
          const onToggle =
            (!dayCompleted && idx === expandedSegmentOnLoad) ||
            (segment.primarySegmentType === SeriesSegmentType.questionnaire &&
              segment.isComplete)
              ? undefined
              : props.onToggle;

          const isCompletedQuestionnaire =
            segment.primarySegmentType === SeriesSegmentType.questionnaire &&
            segment.isComplete;

          return (
            <React.Fragment key={idx}>
              <SeriesSegment
                {...segment}
                segmentTrackingProps={segmentTrackingProps}
                idx={idx}
                segmentsLeft={segmentsLeft}
                isExpanded={
                  idx === expandedSegmentOnLoad && !isCompletedQuestionnaire
                }
                onToggle={onToggle}
                isLast={idx === totalNoOfSegments - 1}
                lastDayInSeries={lastDayInSeries}
              />
            </React.Fragment>
          );
        })}
    </SeriesSegmentListContainer>
  );
};
