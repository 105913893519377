import styled, { css } from 'styled-components';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { rem } from 'polished';
import { medium } from 'utils';
import useViewportWidth from 'utils/useViewportWidth';
import { Breakpoints } from '@unmind/design-system-theme';

interface CellData {
  cell: ReactNode;
  clickable?: boolean;
  id: string;
  darkVariant?: boolean;
  onClick?(): void;
}
interface RowData {
  data: CellData[];
  flexible?: boolean;
  shortRow?: boolean;
  id: string;
}
export interface MosaicProps {
  rowEntries: RowData[];
  mobileLayoutRowEntries?: RowData[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: ${rem(20)};
`;

const RowStyling = css`
  flex-direction: row;

  &.short-row-sibling > div:last-child {
    border-bottom-right-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
  }

  &.short-row-sibling {
    border-bottom-right-radius: ${rem(20)};
  }

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `${rem(2)} solid ${theme.colors.background.secondary}`};
  }

  &:first-child > div:first-child {
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(0)};
  }

  &:first-child > div:last-child {
    border-top-right-radius: ${rem(20)};
    border-top-left-radius: ${rem(0)};
  }

  &:first-child > div:only-child {
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
  }

  &:only-child > div:only-child {
    border-radius: ${rem(20)} !important;
  }

  &:last-child > div:first-child:not(:only-child) {
    border-bottom-left-radius: ${rem(20)};
    border-bottom-right-radius: ${rem(0)};
  }

  &:last-child > div:last-child:not(:only-child) {
    border-bottom-right-radius: ${rem(20)};
    border-bottom-left-radius: ${rem(0)};
  }
`;

const Row = styled.div<{ shortRow: boolean; mobileLayout: boolean }>`
  display: flex;
  justify-content: center;
  width: ${({ shortRow }) => (shortRow ? 'auto' : '100%')};
  flex-direction: column;

  border-bottom: ${({ theme }) =>
    `${rem(2)} solid ${theme.colors.background.secondary}`};

  &:first-child > div:first-child {
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
  }

  &:last-child > div:last-child {
    border-bottom-left-radius: ${rem(20)};
    border-bottom-right-radius: ${rem(20)};
    border-top-right-radius: ${({ shortRow }) => (shortRow ? rem(20) : rem(0))};
  }

  &:last-child {
    border-bottom-right-radius: ${rem(20)};
    border-bottom-left-radius: ${rem(20)};
  }

  ${({ mobileLayout }) =>
    mobileLayout &&
    css`
      flex-direction: row;

      & > div {
        border-right: ${({ theme }) =>
          `${rem(2)} solid ${theme.colors.background.secondary}`};
      }

      &:not(:last-child) {
        border-bottom: ${({ theme }) =>
          `${rem(2)} solid ${theme.colors.background.secondary}`};
      }

      &:first-child > div:first-child {
        border-top-left-radius: ${rem(20)};
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0px;
      }

      &:first-child > div:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: ${rem(20)};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child > div:first-child {
        border-bottom-left-radius: ${rem(20)};
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 0px;
      }

      &:last-child > div:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: ${rem(20)};
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:last-child > div:only-child {
        border-bottom-right-radius: ${rem(20)};
        border-bottom-left-radius: ${rem(20)};
      }
    `};

  ${medium(
    css`
      ${RowStyling}
    `,
  )}
`;

const Cell = styled.div<{
  flexible?: boolean;
  clickable?: boolean;
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: ${({ flexible }) => (flexible ? '0 1 auto' : '1')};
  min-width: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.card};
  color: ${({ theme }) => theme.colors.text.primary};

  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  border: ${({ theme, selected }) =>
    selected && `${rem(1)} solid ${theme.colors.border.primary} !important`};

  transition: border 0.3s ease;

  &.dark-variant {
    color: ${({ theme }) => theme.button.primary.default.textColor};
    background-color: ${({ theme }) =>
      theme.button.primary.default.backgroundColor};
    border-right: ${({ theme }) =>
      `${rem(1)} solid ${theme.button.primary.default.textColor} !important`};
  }

  :hover {
    background-color: ${({ theme, clickable }) =>
      clickable && theme.colors.background.cardHover};
  }

  &.dark-variant:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.default.hover.backgroundColor};
  }

  &:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `${rem(2)} solid ${theme.colors.background.secondary}`};
  }

  &:last-child {
    flex: 1;
  }

  ${medium(css`
    &:not(:last-child) {
      border-right: ${({ theme }) =>
        `${rem(2)} solid ${theme.colors.background.secondary}`};
      border-bottom: none;
      border-top: none;
    }
    .dark-variant {
      border-right: ${({ theme }) =>
        `${rem(2)} solid ${theme.button.primary.default.textColor}`};
    }
  `)}
`;

interface ClickableCellProps {
  children: ReactNode;
  className?: string;
  flexible?: boolean;
  onClick?(): void;
}

const ClickableCell = ({
  children,
  flexible,
  className,
  onClick,
}: ClickableCellProps) => {
  const [selected, setSelected] = useState(false);
  const cellRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLDivElement;

      if (
        cellRef.current &&
        !(cellRef.current as HTMLDivElement).contains(target)
      ) {
        setSelected(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <Cell
      ref={cellRef}
      role="button"
      flexible={flexible}
      clickable={true}
      selected={selected}
      className={className}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        setSelected(true);
      }}
      onFocus={() => setSelected(true)}
      onBlur={() => setSelected(false)}
    >
      {children}
    </Cell>
  );
};

export const Mosaic = ({ rowEntries, mobileLayoutRowEntries }: MosaicProps) => {
  const viewportWidth = useViewportWidth();
  const isSmallScreen = viewportWidth < Breakpoints.M;
  const hasMobileLayout =
    mobileLayoutRowEntries && mobileLayoutRowEntries.length > 0;
  const layout =
    isSmallScreen && hasMobileLayout ? mobileLayoutRowEntries : rowEntries;

  return (
    <Container>
      {layout.map((row, rowIndex) => (
        <Row
          key={row.id}
          shortRow={row.shortRow || false}
          mobileLayout={(hasMobileLayout && isSmallScreen) || false}
          className={
            rowEntries[rowIndex - 1]?.shortRow ||
            rowEntries[rowIndex + 1]?.shortRow
              ? 'short-row-sibling'
              : ''
          }
        >
          {row.data.map(
            ({ cell, id, darkVariant, clickable = false, onClick }) =>
              clickable ? (
                <ClickableCell
                  key={id}
                  flexible={row.flexible}
                  className={darkVariant ? 'dark-variant' : ''}
                  onClick={onClick}
                >
                  {cell}
                </ClickableCell>
              ) : (
                <Cell
                  key={id}
                  flexible={row.flexible || false}
                  className={darkVariant ? 'dark-variant' : ''}
                >
                  {cell}
                </Cell>
              ),
          )}
        </Row>
      ))}
    </Container>
  );
};
