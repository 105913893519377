import React from 'react';
import { useTranslation } from 'react-i18next';
import { small } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { ActionButton } from 'LoggedOut/Start/ExperimentalStartPages/Shared/ActionButton';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { AlternativeHomePageSection } from './Shared/AlternativeHomePageSection/AlternativeHomePageSection';
import { ContentTile } from './Shared/ContentTile';
import { MediaTypes, navigateToPlayer } from './Shared/navigateToPlayer';
import { CreateAccountButton, SignInButton } from './Shared/Buttons';

export const UNMIND_TRAILER_VIDEO_SRC =
  'https://assets.unmind.com/video/upload/v1713537619/Product/Workplace_Engagement/Teaser_vid_-_UK.mp4';
export const UNMIND_TRAILER_THUMBNAIL =
  'https://assets.unmind.com/image/upload/v1713537581/Product/Workplace_Engagement/Teaser_vid_thumbnail.jpg';

const PlayerContainer = styled.div`
  width: 100%;
  margin-top: ${rem(40)};

  ${small(css`
    width: ${rem(844)};
  `)}
`;

const ButtonsContainer = styled.div`
  display: none;
  width: 100%;
  justify-content: space-around;
  & > * {
    width: 50%;
    text-size: ${({ theme }) => theme.typography.fontSizes.fontSize12};
  }

  & > :first-child {
    margin-right: ${rem(10)};
  }

  ${small(css`
    display: flex;
    justify-content: center;

    & > * {
      width: auto;
    }
  `)}
`;

export const UnmindTrailer = ({
  isTeamsExperiment = false,
}: {
  isTeamsExperiment?: boolean;
}) => {
  const { t: translate } = useTranslation('logged_out');
  const subdomain = getSubdomainFromUrl();
  const ctaNavigationPath =
    !subdomain || subdomain === 'search'
      ? RoutePath.SignInToYourOrganisation
      : RoutePath.Home;

  return (
    <AlternativeHomePageSection
      description={translate(
        'ms_teams_alternative_start.first_section.description',
      )}
      header={translate('ms_teams_alternative_start.first_section.header')}
    >
      <ButtonsContainer>
        {isTeamsExperiment ? (
          <ActionButton
            label="Get started with Unmind ->"
            path={ctaNavigationPath}
            onClick={() =>
              tracking.track('teams-start-page-cta-clicked', {
                abTestGroup: 'current-start-page-sept-2024',
                tenantId:
                  getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
                ctaLocation: 'start-page-header',
              })
            }
          />
        ) : (
          <>
            <CreateAccountButton />
            <SignInButton />
          </>
        )}
      </ButtonsContainer>
      <PlayerContainer>
        <ContentTile
          src={UNMIND_TRAILER_THUMBNAIL}
          title={'Welcome To Unmind'}
          onClick={() =>
            navigateToPlayer({
              mediaSrc: UNMIND_TRAILER_VIDEO_SRC,
              mediaType: MediaTypes.Video,
              title: translate(
                'ms_teams_alternative_start.first_section.video_title',
              ),
            })
          }
        />
      </PlayerContainer>
    </AlternativeHomePageSection>
  );
};
