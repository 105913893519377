import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { BodyText, MonoText } from '../../../Shared/Typography';

const SegmentsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SingleSegmentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LowSegment = styled.div<{ isActive: boolean }>`
  flex-direction: row;
  height: 16px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.index.bands.low : theme.colors.background.skeleton};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const MidSegment = styled.div<{ isActive: boolean }>`
  flex-direction: row;
  height: 16px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.index.bands.mid : theme.colors.background.skeleton};
  margin-left: 3px;
`;

const HighSegment = styled.div<{ isActive: boolean }>`
  flex-direction: row;
  height: 16px;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.index.bands.high
      : theme.colors.background.skeleton};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 3px;
`;

const LowSegmentRadiusOffset = styled.div`
  background-color: transparent;
  width: 7px;
  height: 16px;
`;

const HighSegmentRadiusOffset = styled.div`
  background-color: transparent;
  width: 8px;
  height: 16px;
`;

const SegmentMarginOffset = styled.div`
  background-color: transparent;
  width: 2px;
  height: 16px;
`;

const LabelWrapper = styled.div`
  flex: 1;
  background-color: transparent;
  height: 16px;
  bottom: 0;
  position: relative;
`;

const LabelWrapperInner = styled.div<{ position: number }>`
  position: absolute;
  bottom: 0.2px;
  z-index: 1;
  align-items: center;
  left: ${({ position }) => position}%;
`;

const LabelTextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.inverse};
  justify-content: center;
  text-align-last: center;
  align-items: center;
  border-radius: 4px;
  min-width: 42px;
  position: absolute;
  bottom: 22px;
  transform: translate(-50%, 0%);
`;

const LabelText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
}))`
  line-height: 100%;
  padding: 4px 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.staticPalette.white};
`;

const LabelStem = styled.div`
  background-color: ${({ theme }) => theme.colors.background.inverse};
  height: 22px;
  width: 2px;
`;

const SectionLabel = styled(MonoText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize10],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-top: 10px;
  text-align: center;
`;

interface Boundary {
  lower: number;
  upper: number;
}

interface LabelProps {
  score: number;
  boundary: Boundary;
}

const Label = ({ score, boundary }: LabelProps) => {
  const calculateLabelPosition = () =>
    ((score - boundary.lower) / (boundary.upper - boundary.lower)) * 100;

  return (
    <LabelWrapper>
      <LabelWrapperInner position={calculateLabelPosition()}>
        <LabelTextWrapper>
          <LabelText>{score}</LabelText>
        </LabelTextWrapper>
        <LabelStem />
      </LabelWrapperInner>
    </LabelWrapper>
  );
};

interface ScoreGraphicProps {
  score: number;
  boundaries: Boundary[];
  segmentLabels?: string[];
}

const ScoreGraphic = ({
  score,
  boundaries,
  segmentLabels,
}: ScoreGraphicProps) => {
  const { t: translate } = useTranslation('track');

  return (
    <SegmentsWrapper
      aria-label={translate('wellbeing.score_graphic.a11y_label', {
        lower_boundary: boundaries[0].lower,
        upper_boundary: boundaries[boundaries.length - 1].upper,
        score,
      })}
    >
      {boundaries.map((boundary, i) => {
        const isFirstItem = i === 0;
        const isLastItem = i === boundaries.length - 1;
        const isActive = score >= boundary.lower && score <= boundary.upper;

        if (isFirstItem) {
          return (
            <SingleSegmentWrapper>
              <LowSegment isActive={isActive}>
                {isActive ? <Label score={score} boundary={boundary} /> : null}
                <LowSegmentRadiusOffset />
                <SegmentMarginOffset />
              </LowSegment>
              {segmentLabels ? (
                <SectionLabel>{segmentLabels[i]}</SectionLabel>
              ) : null}
            </SingleSegmentWrapper>
          );
        }

        if (isLastItem) {
          return (
            <SingleSegmentWrapper>
              <HighSegment isActive={isActive}>
                {isActive ? <Label score={score} boundary={boundary} /> : null}
                <HighSegmentRadiusOffset />
              </HighSegment>
              {segmentLabels ? (
                <SectionLabel>
                  {segmentLabels[segmentLabels.length - 1]}
                </SectionLabel>
              ) : null}
            </SingleSegmentWrapper>
          );
        }

        return (
          <SingleSegmentWrapper>
            <MidSegment isActive={isActive}>
              {isActive ? <Label score={score} boundary={boundary} /> : null}
              <SegmentMarginOffset />
            </MidSegment>
            {segmentLabels ? (
              <SectionLabel>{segmentLabels[i]}</SectionLabel>
            ) : null}
          </SingleSegmentWrapper>
        );
      })}
    </SegmentsWrapper>
  );
};
export default ScoreGraphic;
