/**
 * Until we can configure apollo-tooling to parse Time schema types as Dates
 */
export interface TimeSlot {
  start: string;
  end: string;
}

export const getTimes = (availableTimes: TimeSlot[], day: Date): TimeSlot[] => {
  const times = availableTimes.filter(
    (d: TimeSlot) =>
      new Date(d.start).getDate() === day.getDate() &&
      new Date(d.start).getMonth() === day.getMonth(),
  );

  return times;
};
