import React from 'react';
import { css, styled } from 'styles';
import { IndicatorError } from 'icons';
import { rem } from 'polished';
import FormError from '../Typography/FormError';
import { small } from '../../utils';

interface FieldErrorProps {
  message: string | React.ReactNode;
  hiddenA11yText?: string;
}

const FormFieldErrorContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const FormFieldError = styled(FormError)`
  margin: 0;
`;

const FormFieldErrorIcon = styled(IndicatorError).attrs(({ theme }) => ({
  primaryColor: theme.colors.staticPalette.white,
  secondaryColor: theme.colors.text.error,
  height: '18',
  width: '18',
}))`
  transform: translateY(${rem(4)});
  margin-bottom: ${rem(16)};
  margin-right: ${rem(4)};
  flex-shrink: 0;

  ${small(css`
    margin-right: ${rem(8)};
  `)}
`;

const HiddenA11yText = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

function FieldError({ message, hiddenA11yText }: FieldErrorProps) {
  const getAccessibleMessage = () => (
    <>
      <HiddenA11yText>{hiddenA11yText}:</HiddenA11yText>
      {message}
    </>
  );

  return (
    <FormFieldErrorContainer>
      <FormFieldErrorIcon />
      <FormFieldError role="alert">
        {hiddenA11yText ? getAccessibleMessage() : message}
      </FormFieldError>
    </FormFieldErrorContainer>
  );
}

export default FieldError;
