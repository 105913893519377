import { defaultTo } from 'lodash';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { compose } from 'recompose';
import { css, styled } from 'styles';

import { TimerDispatch, withTimerDispatch } from '../../../../../Shared/Timer';

import { SegmentTrackingProps } from '../..';
import { tracking, TrackingEvent } from '../../../../../App/Tracking';
import { FullScreenTakeover } from '../../../../../Shared/FullScreenTakeover';
import Transcript from '../../../../../Shared/Transcript';
import useHandleEscapeKeyPress from '../../../../../Shared/MediaPlayerComponents/useHandleEscapeKeyPress';
import { SeriesSegmentType } from '../../../../types';
import { getMediaSrc, small } from '../../../../../utils';
import {
  withSegmentTranscript,
  WithSegmentTranscriptChildProps,
} from '../withSegmentTranscript';
import {
  CloudinaryMedia,
  withSeriesSegmentMedia,
} from '../withSeriesSegmentMedia';
import ListenButton from './ListenButton';
import WatchButton from './WatchButton';
import { MediaSegmentButtons } from './MediaSegmentButtons';
import MediaPlayer from './MediaPlayer';

interface OuterMediaSegmentProps {
  segmentTitle: string;
  segmentId: string;
  isLastSegmentInSeries: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
  segmentTrackingProps: SegmentTrackingProps;
  mediaType: SeriesSegmentType.video | SeriesSegmentType.audio;
}

interface AdhocHOCProps {
  slug: string;
  id: string;
  segmentNumber: number;
}

export interface MediaSegmentProps
  extends OuterMediaSegmentProps,
    WithSegmentTranscriptChildProps,
    AdhocHOCProps {
  timerDispatch: TimerDispatch;
  // The following three props should come from WithSeriesSegmentMedia
  // instead of being defined here, except that seriesTitle is defined
  // as optional in that interface
  mediaSrc: string;
  cloudinaryMedia: CloudinaryMedia;
  seriesTitle: string;
  assetToken: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${small(css`
    padding-right: ${rem('68px')};
  `)};
`;

export const MediaSegment = ({
  mediaSrc,
  mediaType,
  cloudinaryMedia,
  assetToken,
  id,
  segmentTitle,
  segmentId,
  seriesTitle,
  transcript,
  transcriptLoading,
  segmentTrackingProps,
  slug: seriesSlug,
  timerDispatch,
  isLastSegmentInSeries,
  isLastSegmentInDay,
  isComplete,
  segmentNumber,
}: MediaSegmentProps) => {
  const [playerShown, setPlayerShown] = useState(false);
  const [transcriptShown, setTranscriptShown] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<string>('');

  useEffect(() => {
    timerDispatch.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerShown]);

  useEffect(() => {
    if (assetToken) {
      setMediaUrl(
        getMediaSrc({
          cloudinaryMedia,
          assetToken,
          contentId: segmentId,
          legacyMediaUrl: mediaSrc,
        }),
      );
    }
  }, [cloudinaryMedia, assetToken, segmentId, mediaSrc]);

  const trackingProps = {
    ...segmentTrackingProps,
    seriesSlug,
    segmentId,
    segmentNumber,
    segmentTitle,
    isInitial: !isComplete,
  };

  const handlePlayerClose = () => {
    setPlayerShown(false);
    tracking.track('series-segment-closed', {
      ...trackingProps,
      segmentType: mediaType,
    });
  };

  const handleCloseTranscript = ({ method }: { method?: string }) => {
    setTranscriptShown(false);
    tracking.track('media-transcript-closed', {
      feature: 'series',
      medium: mediaType,
      source: 'segment',
      ...(method && { method }),
    });

    if (!playerShown) {
      tracking.track('series-segment-closed', {
        ...trackingProps,
        segmentType: 'transcript',
      });
    }
  };

  const togglePlayer = (): void => {
    setPlayerShown(!playerShown);
  };

  useHandleEscapeKeyPress(() =>
    handleCloseTranscript({ method: 'escape key' }),
  );

  const handleOpenTranscript = (): void => {
    setTranscriptShown(true);
    tracking.track('media-transcript-opened', {
      feature: 'series',
      medium: mediaType,
      source: 'segment',
    });
  };

  const handleNextStep = (): void => {
    if (playerShown) {
      togglePlayer();
    } else {
      handleCloseTranscript({ method: 'next step button' });
    }
  };

  const trackSeriesSegmentStartOrClose = (event: TrackingEvent) => () => {
    const segmentType = transcriptShown ? 'transcript' : mediaType;
    const isSeriesSegmentStarted = event === 'series-segment-started';

    tracking.track(event, {
      ...trackingProps,
      segmentType: isSeriesSegmentStarted ? undefined : segmentType,
    });
  };

  const trackSeriesMedia = (event: TrackingEvent) => () => {
    tracking.track(event, {
      ...trackingProps,
      mediaType,
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (transcriptShown) {
      const unblock = history.block((location, action) => {
        if (action === 'POP') {
          handleCloseTranscript({ method: 'browser back' });

          return false;
        }
      });

      return () => {
        unblock();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptShown]);

  return (
    <>
      <Wrapper>
        <MediaSegmentButtons
          togglePlayer={togglePlayer}
          onClickReadTranscript={handleOpenTranscript}
          trackSeriesSegmentStarted={trackSeriesSegmentStartOrClose(
            'series-segment-started',
          )}
          MediaButton={
            mediaType === SeriesSegmentType.audio ? ListenButton : WatchButton
          }
        />
      </Wrapper>
      {playerShown && (
        <MediaPlayer
          onClose={handlePlayerClose}
          onClickReplay={trackSeriesMedia('series-segment-media-replayed')}
          onClickPlay={trackSeriesMedia('series-segment-media-played')}
          onClickPause={trackSeriesMedia('series-segment-media-paused')}
          mediaSrc={mediaUrl}
          mediaType={mediaType}
          segmentId={segmentId}
          onNextStep={handleNextStep}
          isLastSegmentInSeries={isLastSegmentInSeries}
          isLastSegmentInDay={isLastSegmentInDay}
          isComplete={isComplete}
          timerDispatch={timerDispatch}
          segmentTitle={segmentTitle}
          seriesTitle={seriesTitle}
          seriesSlug={seriesSlug}
          segmentSlug={`/day/${segmentTrackingProps.dayNumber}/${segmentNumber}`}
          isTranscriptLoading={transcriptLoading}
          transcript={transcript}
        />
      )}
      <FullScreenTakeover shown={transcriptShown} hasWhiteBackground>
        {!transcriptLoading && (
          <Transcript
            title={segmentTitle}
            subtitle={seriesTitle}
            transcript={transcript}
            segmentId={id}
            onCloseButtonClick={() =>
              handleCloseTranscript({ method: 'close button' })
            }
            onNextStep={handleNextStep}
            timerDispatch={timerDispatch}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isComplete={isComplete}
          />
        )}
      </FullScreenTakeover>
    </>
  );
};

interface ParentProps
  extends RouteComponentProps<{ slug: string; segment: string }> {
  segmentId: string;
}

export default compose<MediaSegmentProps, OuterMediaSegmentProps>(
  withRouter,
  withTimerDispatch,
  (Component: React.ComponentClass<AdhocHOCProps>) => (props: ParentProps) =>
    (
      <Component
        slug={props.match.params.slug}
        id={props.segmentId}
        segmentNumber={defaultTo(Number(props.match.params.segment), 1)}
        {...props}
      />
    ),
  withSeriesSegmentMedia,
  withSegmentTranscript,
)(MediaSegment);
