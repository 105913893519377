import React, { ReactNode } from 'react';
import LocalAuthStatus from '../App/Auth/LocalAuthStatus';
import { StoreOverlay } from '../App/storeOverlay';
import AuthenticatedPage from '../Navigation/AuthenticatedPage';
import UnauthenticatedPage from '../Navigation/UnauthenticatedPage';

const PageWithNavigation = ({ children }: { children?: ReactNode }) => (
  <LocalAuthStatus>
    {({ state, isAuthenticated }) => (
      <>
        {state === 'READY' && isAuthenticated && (
          <AuthenticatedPage>{children}</AuthenticatedPage>
        )}
        {!isAuthenticated && (
          <UnauthenticatedPage>{children}</UnauthenticatedPage>
        )}

        <StoreOverlay />
      </>
    )}
  </LocalAuthStatus>
);

export default PageWithNavigation;
