import { QueryHookOptions, useQuery } from '@apollo/client';
import { EXPLORE_CATEGORY_QUERY } from './categoryPageQueries';
import {
  categoryContent as CategoryContent,
  categoryContentVariables,
} from './__generated__/categoryContent';

const useExploreCategoryQuery = (
  options: QueryHookOptions<CategoryContent, categoryContentVariables> & {
    variables: categoryContentVariables;
  },
) => {
  const { data, loading, error, refetch } = useQuery<
    CategoryContent,
    categoryContentVariables
  >(EXPLORE_CATEGORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    ...options,
  });

  return { data, loading, error, refetch };
};

export default useExploreCategoryQuery;
