import { Formik, Field, FieldProps } from 'formik';
import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import {
  ExplicitConsentCheckbox,
  MarketingCheckbox,
  StoringDataInEEACheckbox,
} from '../Checkboxes';
import { small } from '../../../utils';
import {
  AuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { UserDetails } from '../SignUp';
import { StyledForm, StyledSubmitButton } from './CommonFormStyledComponents';

const CheckboxWrapper = styled.div`
  margin-bottom: ${rem(28)};
`;

const PrivacyPointList = styled.ul`
  padding-left: 0px;
  margin: 0 ${rem(16)};
  & > *:not(:last-child) {
    margin-top: ${rem(24)};
  }
`;

const PrivacyPoint = styled.li`
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: ${rem(24)};
  margin-bottom: ${rem(24)};
  ${small(css`
    line-height: ${rem(32)};
  `)};
`;

export interface PrivacyConsentFormProps {
  onSubmit(details: UserDetails): void;
  error?: string;
  initialValues: UserDetails;
  loading?: boolean;
  name?: string;
  progressIndicator: React.ReactElement;
  showEEAExplicitDataStorageConsent?: boolean;
}

const PrivacyConsentForm = ({
  onSubmit,
  loading,
  name,
  progressIndicator,
  initialValues = {
    explicitPrivacyConsentGiven: false,
    marketingOptIn: false,
    explicitEEADataStorageConsentGiven: false,
  },
  showEEAExplicitDataStorageConsent = false,
}: PrivacyConsentFormProps) => {
  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);

  return (
    <AuthWrapper groupName={name} progressBar={progressIndicator}>
      <AuthWrapperBlock data-testid="privacy-consent-page">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:sign_up.forms.privacy_consent.heading')}
        </AuthWrapperTitle>
        <PrivacyPointList>
          <PrivacyPoint>
            {translate('logged_out:sign_up.forms.privacy_consent.point_1')}
          </PrivacyPoint>
          <PrivacyPoint>
            {translate('logged_out:sign_up.forms.privacy_consent.point_2')}
          </PrivacyPoint>
        </PrivacyPointList>{' '}
        <Formik
          validate={values => {
            if (
              !values.explicitPrivacyConsentGiven ||
              (showEEAExplicitDataStorageConsent &&
                !values.explicitEEADataStorageConsentGiven)
            ) {
              return {
                explicitPrivacyConsentGiven: false,
                explicitEEADataStorageConsentGiven: false,
              };
            }

            return {};
          }}
          initialValues={initialValues}
          isInitialValid={initialValues.explicitPrivacyConsentGiven}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {({ isValid, /*values,*/ setFieldValue }) => (
            <StyledForm>
              <div>
                <CheckboxWrapper>
                  <Field name="explicitPrivacyConsentGiven">
                    {({ field }: FieldProps) => (
                      <ExplicitConsentCheckbox
                        {...field}
                        id="sign-up-explicit-consent"
                        setFieldValue={setFieldValue}
                        checked={field.value as boolean}
                      />
                    )}
                  </Field>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Field name="marketingOptIn">
                    {({ field }: FieldProps) => (
                      <MarketingCheckbox
                        {...field}
                        id="sign-up-marketing"
                        setFieldValue={setFieldValue}
                        checked={field.value as boolean}
                      />
                    )}
                  </Field>
                </CheckboxWrapper>
                {showEEAExplicitDataStorageConsent ? (
                  <CheckboxWrapper>
                    <Field name="explicitEEADataStorageConsentGiven">
                      {({ field }: FieldProps) => (
                        <StoringDataInEEACheckbox
                          {...field}
                          id="storing-data-eea"
                          setFieldValue={setFieldValue}
                          checked={field.value as boolean}
                        />
                      )}
                    </Field>
                  </CheckboxWrapper>
                ) : null}
                <StyledSubmitButton
                  data-testid="submit-button"
                  type="submit"
                  loading={loading}
                  label={translate(
                    'logged_out:sign_up.forms.privacy_consent.submit_button.label',
                  )}
                  disabled={!isValid}
                />
              </div>
            </StyledForm>
          )}
        </Formik>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default PrivacyConsentForm;
