import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavTrackActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 2.733A2.571 2.571 0 0 0 21.429.162H2.57A2.571 2.571 0 0 0 0 2.733V21.59a2.571 2.571 0 0 0 2.571 2.572H21.43A2.571 2.571 0 0 0 24 21.59V2.733zm-4.383 4.344a.85.85 0 1 0-1.51-.782l-4.782 9.23-4.085-4.474a.85.85 0 0 0-1.303.057l-3.475 4.549a.85.85 0 1 0 1.351 1.032l2.857-3.741 4.22 4.624a.85.85 0 0 0 1.383-.182l5.344-10.313z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgNavTrackActive;
