import {
  getCheckInScores_allCheckins,
  getCheckInScores_lastMonthCheckins,
} from '../../__generated__/getCheckInScores';
import {
  CheckInScores,
  CheckInScores_feelings,
} from '../../__generated__/CheckInScores';
import { CheckInMoods_checkInMoods } from '../../MoodTracker/__generated__/CheckInMoods';

export function filterAllCheckInData(
  allCheckins: (getCheckInScores_allCheckins | null)[] | null,
): CheckInScores[] {
  return (
    allCheckins?.filter((c): c is getCheckInScores_allCheckins => !!c) || []
  );
}

export function filterLastMonthCheckinsData(
  lastMonthCheckins: (getCheckInScores_lastMonthCheckins | null)[] | null,
): getCheckInScores_lastMonthCheckins[] {
  return (
    lastMonthCheckins?.filter(
      (c): c is getCheckInScores_lastMonthCheckins => !!c,
    ) || []
  );
}

export function filterCheckInFeelingsData(
  allCheckinsFeelings: (CheckInScores_feelings | null)[] | null,
): CheckInScores_feelings[] {
  return (
    allCheckinsFeelings?.filter((c): c is CheckInScores_feelings => !!c) || []
  );
}

export function filterCheckInMoodsData(
  allCheckinsMoods: (CheckInMoods_checkInMoods | null)[] | null,
): CheckInMoods_checkInMoods[] {
  return (
    allCheckinsMoods?.filter((c): c is CheckInMoods_checkInMoods => !!c) || []
  );
}
