import { AdminTabType } from '../../../types';
import { tracking } from '../../../../App/Tracking';
import { DropdownOptions, trackingFilterTypes } from '../types';
import { UserGroupFilterStateKey } from '../../FilterState/types';
import { TimePeriod } from '../../../../__generated__/globalTypes';

export const trackSelectedOptions = (
  numberSelected: number,
  options: DropdownOptions,
  adminTabType: AdminTabType,
  filterType: UserGroupFilterStateKey,
  timeFilterPreset: TimePeriod,
) => {
  const restrictedOptionCount = options.filter(
    option => option.isDisabled,
  ).length;

  tracking.track('admin-dashboard-filter-option-selected', {
    dashboardType: adminTabType,
    filterType: trackingFilterTypes[filterType],
    numOfOptionsSelected: numberSelected,
    numOfOptionsAvailable: options.length,
    numOfOptionsRestricted: restrictedOptionCount,
    timePeriodSelected: timeFilterPreset,
  });
};
