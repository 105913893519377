import React from 'react';
import { IconProps } from '../IconProps';

const SvgUplift = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#F3FCF8" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 73.812c-10.026-7.508-20.489-15.048-31.446-21.326-20.817-11.928-42.225-7.513-64.107-3-21.715 4.479-43.897 9.054-66.431-2.151-18.53-9.215-27.827-25.786-36.692-41.585-1.085-1.933-2.163-3.855-3.251-5.75H340v73.812z"
      fill="#1F7A52"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 150.947V192H0v-52.703c16.225-9.329 33.87-17.731 52.683-24.893 54.214-20.638 77.398-5.053 104.546 13.197 29.184 19.618 62.949 42.317 144.764 26.409 16.396-3.188 28.896-4.077 38.007-3.063z"
      fill="#0A5132"
    />
  </svg>
);

export default SvgUplift;
