import { hideVisually, rem } from 'polished';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { getFocusState, StylelessButton } from '../Button';

const BackToTopButton = styled(StylelessButton)`
  ${hideVisually()};
  bottom: ${rem(20)};
  right: ${rem(20)};
  position: fixed;

  &:focus {
    ${getFocusState()};
    clip: auto;
    clip-path: none;
    height: auto;
    width: auto;
  }
`;

interface BackToTopProps {
  onClickTargetRef: RefObject<any>;
}

const BackToTop = ({ onClickTargetRef }: BackToTopProps) => {
  const { t: translate } = useTranslation('shared');

  const onButtonClick = () => {
    if (onClickTargetRef.current) {
      onClickTargetRef.current.focus();
    }
  };

  return (
    <BackToTopButton onClick={onButtonClick}>
      {translate('back_to_top_button.label')}
    </BackToTopButton>
  );
};

export default BackToTop;
