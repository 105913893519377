import React from 'react';
import { IconProps } from '../IconProps';

const SvgUnmindMonoOutlined = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M13.195.023H10.05c-.257 0-.394.172-.394.377 0 .548.924.873.924 2.67v5.632c0 2.122-.719 3.046-2.343 3.046-1.728 0-2.48-1.026-2.48-3.337V3.104c0-1.831.992-2.156.992-2.704 0-.205-.137-.377-.377-.377H.865C.625.023.488.195.488.4c0 .548.992.873.992 2.704v5.82c0 3.081 2.326 5.067 5.935 5.067h.12c3.317 0 5.045-1.815 5.045-5.29V3.105c0-1.831.992-2.156.992-2.704 0-.205-.137-.377-.377-.377z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
  </svg>
);

export default SvgUnmindMonoOutlined;
