import { Colors } from '@unmind/design-system-theme';
import React, { ReactNode } from 'react';
import { css, styled, useTheme } from 'styles';

interface CardProps {
  children: ReactNode;
  bgColor?: Colors;
  bordered?: boolean;
}

const CardOuter = styled.div<{
  backgroundColor: CardProps['bgColor'];
  bordered: CardProps['bordered'];
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 14px;
  padding: 12px;

  ${({ bordered, theme }) =>
    bordered &&
    css`
      border: 1px solid ${theme.colors.border.secondary};
    `}
`;

const Card = ({ bgColor, bordered, children, ...props }: CardProps) => {
  const { colors } = useTheme();
  const backgroundColor = bgColor ?? colors.background.card;

  return (
    <CardOuter backgroundColor={backgroundColor} bordered={bordered} {...props}>
      {children}
    </CardOuter>
  );
};

export default Card;
