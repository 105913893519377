import React, { useEffect } from 'react';
import { authentication } from '@microsoft/teams-js';

const MSTeamsSSOCallback = () => {
  useEffect(() => {
    authentication.notifySuccess('success');
  });

  return <></>;
};
export default MSTeamsSSOCallback;
