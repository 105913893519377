import React from 'react';
import { IconProps } from '../IconProps';

const SvgNeutralFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M83.2 164.3c44.945 0 81.38-36.435 81.38-81.38 0-44.945-36.435-81.38-81.38-81.38-44.945 0-81.38 36.435-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#BAF2FF"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.12 66.31a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.61 66.31a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M62.15 105.56c13.66 1.17 26.73 1.36 39.65.17"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgNeutralFaceWithBorder;
