import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql, DataValue } from '@apollo/client/react/hoc';
import { Errors } from '../../Shared/Errors';

export const SUBDOMAIN_QUERY = gql`
  query subAndParentDomain($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      parentDomain
      subDomain
    }
  }
`;

interface InputProps {
  subdomain: string;
}

interface SubdomainInfo {
  parentDomain: string | null;
  subDomain?: string;
}

export interface SubdomainApiResponse {
  subDomain?: SubdomainInfo[];
}

export interface WithValidSubdomainProps {
  loading: boolean;
  isValidSubdomain: boolean;
  error?: Errors;
  parentSubdomain?: string | null;
}

const mapDataToProps = (
  data?: DataValue<SubdomainApiResponse, InputProps>,
): WithValidSubdomainProps => {
  if (data && data.loading) {
    return {
      loading: true,
      isValidSubdomain: false,
    };
  }

  if (data && data.subDomain) {
    return {
      loading: false,
      parentSubdomain: get(data.subDomain[0], 'parentDomain'),
      isValidSubdomain: data.subDomain[0] !== undefined,
    };
  }

  return {
    loading: false,
    isValidSubdomain: false,
    error: Errors.ServerError,
  };
};

export const withValidSubdomain = graphql<
  InputProps,
  SubdomainApiResponse,
  InputProps,
  WithValidSubdomainProps
>(SUBDOMAIN_QUERY, {
  options: ({ subdomain }) => ({
    fetchPolicy: 'cache-first',
    variables: {
      subdomain,
    },
  }),
  props: ({ data }) => mapDataToProps(data),
  skip: props => props.subdomain === 'search',
});
