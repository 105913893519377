import React from 'react';
import { IconProps } from '../IconProps';

const SvgJumpForward = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 39.91 45.86"
    fill={primaryColor}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M33.93 8.33a1.5 1.5 0 1 0-2 2.23 17.36 17.36 0 1 1-16.6-3.67v.9a1.5 1.5 0 0 0 2.17 1.34L23.79 6a1.51 1.51 0 0 0 0-2.69L17.5.16a1.5 1.5 0 0 0-2.17 1.34v2.3a20.32 20.32 0 1 0 18.6 4.53z" />
    <path d="M19.33 19.11v9.55c0 6.8 11 6.8 11 0v-9.55c0-3.12-2.49-5.1-5.5-5.1s-5.5 1.99-5.5 5.1zm3-.08c0-1.28 1-2.09 2.5-2.09s2.5.81 2.5 2.09v9.71c0 2.79-5 2.79-5 0zM13.34 31.9a1.49 1.49 0 0 0 1.88 1.3 1.56 1.56 0 0 0 1.11-1.54V15.91a1.5 1.5 0 0 0-2.43-1L10 18l-.11.1a1.51 1.51 0 0 0 .11 2.29 1.58 1.58 0 0 0 1.9-.09l1.42-1.14V31.9z" />
  </svg>
);

export default SvgJumpForward;
