import i18n from '../../../i18n/config';
import { isValidEmail } from '../inputValidators';

export const validateFieldNotEmpty = (value: string, errorMessage: string) => {
  if (value === '') {
    return errorMessage;
  }

  return;
};

export const validateEmail = (email: string, invalidEmailText?: string) => {
  if (!isValidEmail(email)) {
    return (
      invalidEmailText ||
      i18n.t('shared:forms.validate_fields.invalid_email.text')
    );
  }

  return;
};

export interface EmailOnlyFormFields {
  emailInput: string;
}

export const validateEmailOnlyForm = ({
  emailInput,
}: EmailOnlyFormFields): { emailInput?: string } => {
  if (!emailInput) {
    return {};
  }

  const emailInputError = validateEmail(emailInput);
  if (emailInputError) {
    return { emailInput: emailInputError };
  }

  return {};
};

export const validatePasswordMatch = (pw: string, confirmPw: string) => {
  if (pw !== confirmPw) {
    return i18n.t('shared:forms.validate_fields.password_no_match.text');
  }

  return;
};
