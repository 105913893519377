import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { MutationFunction } from '@apollo/client';
import { Errors } from '../../../Shared/Errors';

export const mutation = gql`
  mutation withSendSeriesEndOfDayReminder(
    $seriesLink: String!
    $templateVars: [KeyPair]!
  ) {
    sendSeriesEndOfDayReminder(
      seriesLink: $seriesLink
      templateVars: $templateVars
    )
  }
`;

export interface WithSendSeriesEndOfDayReminderResponse {
  sendSeriesEndOfDayReminder: boolean;
}

export interface WithSendSeriesEndOfDayReminderChildProps {
  sendSeriesEndOfDayReminder(
    variables: SeriesEndOfDayReminderVariables,
  ): Promise<Response>;
}

export interface SeriesEndOfDayReminderVariables {
  seriesLink: string;
  templateVars: { [key: string]: string }[];
}

interface Response {
  success: boolean;
  error?: Errors;
}

export const sendSeriesEndOfDayReminder = async (
  variables: SeriesEndOfDayReminderVariables,
  mutate?: MutationFunction<
    WithSendSeriesEndOfDayReminderResponse,
    SeriesEndOfDayReminderVariables
  >,
) => {
  try {
    if (mutate) {
      const response = await mutate({ variables });

      if (response && response.data) {
        return {
          success: response.data.sendSeriesEndOfDayReminder,
        };
      }
    }
  } catch (e) {
    console.error(e); // to Sentry
  }

  return {
    success: false,
    error: Errors.ServerError,
  };
};

export const withSendSeriesEndOfDayReminder = graphql<
  Record<string, unknown>,
  WithSendSeriesEndOfDayReminderResponse,
  SeriesEndOfDayReminderVariables,
  WithSendSeriesEndOfDayReminderChildProps
>(mutation, {
  props: ({ mutate }) => ({
    sendSeriesEndOfDayReminder: async (
      variables: SeriesEndOfDayReminderVariables,
    ) => sendSeriesEndOfDayReminder(variables, mutate),
  }),
});
