import { rem } from 'polished';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SeriesSegmentIcons, styled, useTheme } from 'styles';
import { Clock, Success, UnmindMonoOutlined } from 'icons';
import { HeadingText } from '../../../../Shared/Typography';
import { SegmentsProgressIndicator } from './SegmentsProgressIndicator';

export const SegmentHeaderContainer = styled.a<{ showPointer: boolean }>`
  align-items: center;
  display: flex;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'default')};
  flex-flow: row wrap;
  height: ${rem('52px')};
  justify-content: flex-start;
  user-select: none;
`;

const SegmentIndicatorContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const SegmentDurationIcon = styled(Clock).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  secondaryColor: theme.colors.staticPalette.white,
}))`
  height: ${rem(20)};
  width: ${rem(20)};
  margin-left: ${rem(4)};
`;

export const SegmentIcon: React.FC<{
  icon: string;
}> = props => {
  const theme = useTheme();

  const StyledIcon = styled(
    SeriesSegmentIcons[props.icon] || UnmindMonoOutlined,
  ).attrs({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: 'transparent',
    'data-testid': 'segment-header-icon',
  })`
    height: ${rem('24px')};
    width: ${rem('24px')};
    margin-left: ${rem('-4px')};
  `;

  return <StyledIcon />;
};

export const SegmentDuration = styled(HeadingText).attrs(({ theme }) => ({
  level: 5,
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.secondary,
}))`
  display: inline;
  margin: ${rem('4px')} 0 0 ${rem('4px')};
  overflow: hidden;
  text-transform: uppercase;
`;

export const SegmentComplete = styled(Success).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.staticPalette.white,
  'data-testid': 'svg-success',
}))`
  height: ${rem('24px')};
  width: ${rem('24px')};
  margin-right: ${rem('-3px')};
  justify-self: flex-end;
`;

export const SegmentTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 4,
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  display: inline;
  margin: 0;
  margin-left: ${rem('6px')};
  overflow: hidden;
`;

const CourseInformationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface SegmentHeaderProps extends WithTranslation {
  durationSeconds?: number;
  idx: number;
  isComplete: boolean;
  isExpanded: boolean;
  onToggle?(idx: number): void;
  segmentsLeft: number;
  icon: string;
  title: string;
}

export interface SegmentHeaderState {
  animating: boolean;
  hovered: boolean;
}

export class SegmentHeader extends React.Component<
  SegmentHeaderProps,
  SegmentHeaderState
> {
  constructor(props: SegmentHeaderProps) {
    super(props);
    this.state = {
      animating: false,
      hovered: false,
    };
  }

  onClick = () => {
    const { idx, onToggle } = this.props;
    const { animating } = this.state;

    if (animating) {
      return;
    }

    this.setState(
      {
        animating: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ animating: false });
        }, 300);
      },
    );

    if (onToggle) {
      onToggle(idx);
    }
  };

  updateHovered = (isHovered: boolean) => () => {
    if (this.props.onToggle !== undefined) {
      this.setState({ hovered: isHovered });
    }
  };

  render() {
    const {
      durationSeconds,
      icon,
      isExpanded,
      isComplete,
      onToggle,
      segmentsLeft,
      title,
      t: translate,
    } = this.props;
    const durationMins =
      durationSeconds !== undefined ? Math.round(durationSeconds / 60) : 0;

    return (
      <SegmentHeaderContainer
        onClick={this.onClick}
        onMouseEnter={this.updateHovered(true)}
        onMouseLeave={this.updateHovered(false)}
        showPointer={onToggle !== undefined}
      >
        <SegmentIcon icon={icon} aria-hidden="true" />
        <CourseInformationContainer>
          <SegmentTitle>{title}</SegmentTitle>
          {durationMins !== 0 && isExpanded && (
            <React.Fragment>
              <SegmentDurationIcon aria-hidden="true" />
              <SegmentDuration>
                {translate('segment.duration', { duration: durationMins })}
              </SegmentDuration>
            </React.Fragment>
          )}
        </CourseInformationContainer>
        <SegmentIndicatorContainer>
          {isComplete ? (
            <SegmentComplete aria-hidden="true" />
          ) : (
            isExpanded && <SegmentsProgressIndicator itemsLeft={segmentsLeft} />
          )}
        </SegmentIndicatorContainer>
      </SegmentHeaderContainer>
    );
  }
}

export default withTranslation('courses')(SegmentHeader);
