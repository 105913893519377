import { BodyText, Button } from '@unmind/design-system-components-web';
import React, { useRef } from 'react';
import { styled } from 'styles';
import { isMSTeams } from 'utils/MSTeams';

const CopyLinkContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  justify-content: space-between;
  margin-top: 12px;
`;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 18px 0 12px 12px;
`;

const CopyLinkButton = styled(Button)`
  margin: 6px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

const CopyLinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const CopyLink = ({ value }: { value: string | null }) => {
  const selectRef = useRef<any>(null);

  const onClickCopy = async () => {
    if (isMSTeams()) {
      // workaround for MS Teams as the clipboard API isn't available
      // https://stackoverflow.com/questions/73552314/how-can-i-get-clipboard-permission-in-teams-add-on-app
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(selectRef.current);
      selection?.removeAllRanges();
      selection?.addRange(range);
      document.execCommand('copy');
    } else {
      await navigator.clipboard.writeText(value ?? '');
    }
  };

  return (
    <CopyLinkContainer>
      <Text ref={selectRef}>{value}</Text>
      <CopyLinkButton
        variant="primary"
        onClick={onClickCopy}
        label={
          <LabelContainer>
            <CopyLinkText>Copy</CopyLinkText>
          </LabelContainer>
        }
      />
    </CopyLinkContainer>
  );
};
