import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled from 'styled-components';
import { Chevron } from 'icons';
import { WrappingButton } from 'Shared/Accessibility';
import { TextArea as BaseTextArea } from 'Shared/Form/TextArea';
import { DurationLabel as BaseDurationLabel } from '../DurationLabel';

export const DateLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.bold,
}))`
  margin-bottom: ${rem(24)};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const DurationLabel = styled(BaseDurationLabel)`
  margin-bottom: ${rem(16)};
`;

export const BackButton = styled(WrappingButton)`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
  margin-bottom: ${rem(24)};
`;

export const BackIcon = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  width: rem(16),
  height: rem(16),
}))`
  transform: rotate(180deg);
`;

export const BackText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.link};
`;

export const Label = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'label',
}))`
  margin-bottom: ${rem(24)};
`;

export const MessageCaption = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  margin-bottom: ${rem(24)};
`;

export const TextArea = styled(BaseTextArea)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14}px;
  padding: ${rem(12)};
  margin-bottom: ${rem(24)};

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const MessageLengthCounter = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))<{ isMessageAtLimit: boolean }>`
  margin-bottom: ${rem(24)};
  color: ${({ theme, isMessageAtLimit }) =>
    isMessageAtLimit ? theme.colors.text.error : theme.colors.text.disabled};
`;
