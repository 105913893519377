import React, { ReactNode } from 'react';
import { SectionContainer, Text, TextContainer, Title } from './styles';

interface AlternativeHomePageSectionProps {
  header?: string;
  description?: string;
  children: ReactNode;
}

export const AlternativeHomePageSection = ({
  header,
  description,
  children,
}: AlternativeHomePageSectionProps) => (
  <SectionContainer>
    <TextContainer>
      {header && <Title>{header}</Title>}
      {description && <Text>{description}</Text>}
    </TextContainer>
    {children}
  </SectionContainer>
);
