import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled, useTheme } from 'styles';
import { small } from 'utils';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import BodyText from '../../../Shared/Typography/BodyText';

import { QuestionReview } from './QuestionReview';
import {
  SeriesReview as SeriesReviewData,
  SeriesReviewVariables,
} from './__generated__/SeriesReview';

const ReviewContainer = styled.div`
  margin-bottom: ${rem(50)};
  width: 100%;
  overflow-x: hidden;
`;

const DescriptionContainer = styled.div`
  border-bottom: ${({ theme }) =>
    `${rem(1)} solid ${theme.colors.border.secondary}`};
`;

const OutcomeDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  ${small(css`
    margin-top: ${rem(24)};
    margin-bottom: ${rem(42)};
  `)}
`;

interface CompletedStateProps {
  seriesId: string;
  seriesTitle: string;
}

export const SERIES_REVIEW_QUERY = gql`
  query SeriesReview($seriesId: String!) {
    seriesQuestionnaireAnswers(seriesId: $seriesId) {
      edges {
        relatedQuestion {
          id
          questionText
          scale {
            id
            minimumValue
            maximumValue
          }
        }
        node {
          id
          afterSeriesCompletionAnswer
          beforeSeriesCompletionAnswer
        }
      }
    }
  }
`;

const CompletedState = ({ seriesId, seriesTitle }: CompletedStateProps) => {
  const theme = useTheme();
  const { t: translate } = useTranslation<Namespace<'courses'>>('courses');
  const { data, loading } = useQuery<SeriesReviewData, SeriesReviewVariables>(
    SERIES_REVIEW_QUERY,
    {
      variables: { seriesId },
    },
  );

  return (
    <ReviewContainer>
      <DescriptionContainer>
        <BodyText
          sizes={[
            theme.typography.fontSizes.fontSize18,
            theme.typography.fontSizes.fontSize20,
            theme.typography.fontSizes.fontSize22,
            theme.typography.fontSizes.fontSize24,
          ]}
          weight={theme.typography.fontWeights.medium}
        >
          {translate('review.completed_state.heading')}
        </BodyText>
        <OutcomeDescription>
          {Trans({
            t: translate,
            i18nKey: 'review.completed_state.description',
            defaults:
              'In this section you can see the impact of <bold>{{course_title}}</bold>, comparing your Session 1 answers to those from the final session.',
            components: { bold: <strong /> },
            values: { course_title: seriesTitle },
          })}
        </OutcomeDescription>
      </DescriptionContainer>
      {loading || !data ? (
        <LoadingIndicator />
      ) : (
        data.seriesQuestionnaireAnswers?.edges?.map((edge, index) => {
          if (edge) {
            const { relatedQuestion, node } = edge;

            if (relatedQuestion && relatedQuestion.scale && node) {
              return (
                <QuestionReview
                  key={relatedQuestion.id || node.id || index}
                  questionNumber={index + 1}
                  question={relatedQuestion.questionText}
                  before={node.beforeSeriesCompletionAnswer}
                  after={node.afterSeriesCompletionAnswer}
                  minLabel={relatedQuestion.scale.minimumValue}
                  maxLabel={relatedQuestion.scale.maximumValue}
                />
              );
            }
          }
        })
      )}
    </ReviewContainer>
  );
};

export default CompletedState;
