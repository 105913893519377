import { Tick } from 'icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { rem } from 'polished';
import { BodyText } from '@unmind/design-system-components-web';
import { UPDATE_ACTION_TAKEN_MUTATION } from 'Compass/api/updateActionTakenMutation';
import { ActionTakenContext } from 'Compass/Components/CompassDashboard/ActionTakenProvider';
import { useMutation } from '@apollo/client';
import {
  updateActionTakenVariables,
  updateActionTaken as updateActionTakenMutation,
} from 'Compass/api/__generated__/updateActionTaken';
import {
  ACTION_DETAIL_QUERY,
  useActionDetail,
} from 'Compass/api/useActionDetail';
import { tracking } from 'App/Tracking';
import {
  mapThemeIdsToThemeName,
  mapThemeIdsToThemeType,
} from '../../constants';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  background-color: ${({ theme, checked, disabled }) =>
    disabled
      ? theme.button.primary.disabled.backgroundColor
      : checked
      ? theme.button.primary.default.backgroundColor
      : theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  cursor: pointer;
  margin-right: ${rem(8)};
  border-radius: ${rem(6)};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
`;

const TakeActionText = styled(BodyText)<{ disabled: boolean }>`
  font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize12)};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.button.primary.disabled.textColor
      : theme.colors.text.primary};
`;

const IconContainer = styled.div<{ checked: boolean }>`
  display: ${({ checked }) => (checked ? 'flex' : 'none')};
  align-items: center;
`;

const CheckIcon = styled(Tick).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.textColor,
  secondaryColor: theme.button.primary.default.textColor,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const Label = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${rem(8)};
  border-radius: ${rem(12)};

  &:hover > div {
    background-color: ${({ theme, checked }) =>
      checked && theme.button.primary.default.backgroundColor};
    border: 1px solid
      ${({ theme, checked }) => checked && theme.colors.border.primary};
  }
`;

interface TakeActionCheckboxProps {
  ariaLabel: string;
  actionId: string;
  scheduleId: string;
}

export const TakeActionCheckbox = ({
  ariaLabel,
  actionId,
  scheduleId,
}: TakeActionCheckboxProps) => {
  const { t: translate } = useTranslation('compass');
  const { data: actionDetailData, loading: actionDetailDataLoading } =
    useActionDetail({
      scheduleId,
      actionId,
    });
  const { recommendedActionDetail } = actionDetailData ?? {};
  const isChecked = recommendedActionDetail?.markedAsTaken || false;

  const { markActionTaken } = useContext(ActionTakenContext);

  const [updateActionTaken] = useMutation<
    updateActionTakenMutation,
    updateActionTakenVariables
  >(UPDATE_ACTION_TAKEN_MUTATION, {
    update: (cache, { data }) => {
      if (!data || !data.updateActionTaken?.success) return;

      const existingData = cache.readQuery<{
        recommendedActionDetail: {
          id: string;
          markedAsTaken: boolean;
        };
      }>({
        query: ACTION_DETAIL_QUERY,
        variables: { scheduleId, actionId },
      });

      if (!existingData) return;

      cache.writeQuery({
        query: ACTION_DETAIL_QUERY,
        variables: { scheduleId, actionId },
        data: {
          recommendedActionDetail: {
            ...existingData.recommendedActionDetail,
            markedAsTaken: !isChecked,
          },
        },
      });
    },
  });

  const handleCheckboxChange = async (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    const updateAction = async () => {
      if (!isChecked) {
        // if action is being taken, mark that it has been taken
        markActionTaken();
      }

      await updateActionTaken({
        variables: {
          input: {
            actionId,
            indexScheduleId: scheduleId,
            markedAsTaken: !isChecked,
          },
        },
      });
    };

    tracking.track('compass-action-taken-status-toggled', {
      actionId,
      scheduleId,
      themeId: recommendedActionDetail?.themeId,
      theme:
        mapThemeIdsToThemeName[
          recommendedActionDetail?.themeId as keyof typeof mapThemeIdsToThemeName
        ],
      themeType:
        mapThemeIdsToThemeType[
          recommendedActionDetail?.themeId as keyof typeof mapThemeIdsToThemeType
        ],
      riskCategory: recommendedActionDetail?.riskCategory,
      score: recommendedActionDetail?.score,
      actionTitle: recommendedActionDetail?.title,
      markedAsTaken: !isChecked, // We have to negate isChecked as it won't have been updated to reflect the change in value yet
    });

    if (e.type === 'keydown') {
      if ((e as React.KeyboardEvent<HTMLDivElement>).key === 'Enter') {
        await updateAction();

        return;
      }
    }

    await updateAction();
  };

  return (
    <Label checked={isChecked && !actionDetailDataLoading}>
      <HiddenCheckbox disabled={actionDetailDataLoading} />
      <StyledCheckbox
        disabled={actionDetailDataLoading}
        checked={isChecked}
        onClick={handleCheckboxChange}
        onKeyDown={handleCheckboxChange}
        tabIndex={0}
        aria-label={translate(
          'dashboard.recommended_actions.take_action_button.aria_label',
          { title: ariaLabel },
        )}
      >
        <IconContainer checked={isChecked && !actionDetailDataLoading}>
          <CheckIcon />
        </IconContainer>
      </StyledCheckbox>
      <TakeActionText disabled={actionDetailDataLoading}>
        {translate('dashboard.recommended_actions.take_action_button.label')}
      </TakeActionText>
    </Label>
  );
};
