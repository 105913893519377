import React from 'react';
import { IconProps } from '../IconProps';

const SvgPlayCircular = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <g filter="url(#PlayCircular_svg__filter0_b_446_4861)">
      <path
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        fill={primaryColor}
      />
      <path
        d="M23.625 12c0 6.42-5.205 11.625-11.625 11.625S.375 18.42.375 12 5.58.375 12 .375 23.625 5.58 23.625 12z"
        stroke={secondaryColor}
        strokeOpacity={0.95}
      />
    </g>
    <path
      d="M15.875 11.35a.75.75 0 0 1 0 1.3l-5.25 3.03a.75.75 0 0 1-1.125-.649V8.97a.75.75 0 0 1 1.125-.65l5.25 3.031z"
      fill={secondaryColor}
      fillOpacity={0.95}
    />
    <defs>
      <filter
        id="PlayCircular_svg__filter0_b_446_4861"
        x={-8}
        y={-8}
        width={40}
        height={40}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_446_4861"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_446_4861"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgPlayCircular;
