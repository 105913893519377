import React from 'react';
import { IconProps } from '../IconProps';

const SvgForwardSlash = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 6 10" fill="none" {...props}>
    <path
      d="M1.19 9l4-8"
      stroke={primaryColor}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgForwardSlash;
