import { Colors } from '@unmind/design-system-theme';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React from 'react';
import { styled, useTheme } from 'styles';
import { pluralize, secondsToMinutes } from '../../../adminHelpers';
import Panel, { PanelSection } from '../../Panel';
import { useFilteredQuery } from '../../FilterState';
import Metric from '../../Metric';
import {
  OrganisationSeriesInsights,
  OrganisationSeriesInsights_organisationSeries,
} from './__generated__/OrganisationSeriesInsights';

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    margin-right: ${rem(48)};
  }
`;

export const ORGANISATION_SERIES_QUERY: DocumentNode = gql`
  query OrganisationSeriesInsights($input: OrganisationSeriesInput!) {
    organisationSeries(input: $input) {
      totalSeconds
      mostCompleted {
        edges {
          node {
            title
          }
          completions
        }
        totalCompletions
      }
    }
  }
`;

const OrganisationTopSeries = ({
  organisationSeries,
  panelColor,
}: {
  organisationSeries: OrganisationSeriesInsights_organisationSeries;
  panelColor: Colors;
}) => {
  const topSeriesTitle =
    organisationSeries?.mostCompleted?.edges?.[0]?.node?.title ?? null;

  return topSeriesTitle ? (
    <PanelSection>
      <Metric
        data-testid="series-insights-most-completed"
        color={panelColor}
        title="Most completed"
        value={topSeriesTitle}
      />
    </PanelSection>
  ) : null;
};

export const SeriesInsightsPanel = () => {
  const { colors } = useTheme();
  const panelColor = colors.staticPalette.cornflower;
  const {
    data: { organisationSeries } = {},
    error,
    loading,
  } = useFilteredQuery<OrganisationSeriesInsights>(ORGANISATION_SERIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Panel
      color={panelColor}
      title="Courses"
      isLoading={loading}
      hasError={!isNil(error) || !organisationSeries}
    >
      {isNil(error) &&
      organisationSeries &&
      organisationSeries.mostCompleted ? (
        <>
          <PanelSection>
            <MetricContainer>
              <Metric
                data-testid="series-insights-usage-time-spent"
                color={panelColor}
                title="Time spent"
                value={secondsToMinutes(organisationSeries.totalSeconds)}
                unit={pluralize(
                  secondsToMinutes(organisationSeries.totalSeconds),
                  'min',
                )}
              />
              <Metric
                data-testid="series-insights-total-completed"
                color={panelColor}
                title="Completed"
                value={organisationSeries.mostCompleted.totalCompletions}
              />
            </MetricContainer>
          </PanelSection>
          <OrganisationTopSeries
            organisationSeries={organisationSeries}
            panelColor={panelColor}
          />
        </>
      ) : null}
    </Panel>
  );
};

export default SeriesInsightsPanel;
