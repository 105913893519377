import { FontSizes } from '@unmind/design-system-theme';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BodyText } from '@unmind/design-system-components-web';

type ChipSize = 'small' | 'medium' | 'large';

const ChipSizeStyles: Record<ChipSize, FontSizes[]> = {
  ['small']: [FontSizes.fontSize12],
  ['medium']: [FontSizes.fontSize14],
  ['large']: [FontSizes.fontSize16],
};

const ChipButton = styled.button<{
  $active: boolean;
}>`
  display: flex;
  align-items: center;
  background: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 8px 10px;
  border-radius: 20px;
  outline-offset: 1px;
  position: relative;

  ${({ $active, theme }) => css`
    ${$active
      ? css`
          background-color: ${theme.colors.background.inverse};
        `
      : css`
          transition: background-color 0.2s;

          &:hover {
            background-color: ${theme.button.secondary.default.hover
              .backgroundColor};
          }
        `}

    border: 1px solid ${theme.colors.border.primary};

    &:active {
      background-color: ${theme.colors.background.inverse};
    }

    &:focus {
      outline: 2px solid ${theme.colors.text.primary};
    }
  `}
`;

const ChipLabel = styled(BodyText).attrs<{ $size: ChipSize; $active: boolean }>(
  ({ $size, $active, theme }) => ({
    sizes: ChipSizeStyles[$size],
    color: $active ? theme.colors.text.inverse : theme.colors.text.primary,
  }),
)<{ $size: ChipSize; $active: boolean }>`
  line-height: 1;
`;

interface Props {
  /**
   * The text rendered within the Chip.
   */
  label: string;
  /**
   * A callback to be fired when clicking a Chip which is not currently active.
   */
  onActivate(): void;
  /**
   * A callback to be fired when clicking a currently active Chip.
   */
  onDeactivate(): void;
  /**
   * The size of the Chip label. Defaults to `small`.
   */
  size?: ChipSize;
}

/**
 * A compact, clickable element allowing users to trigger an action.
 *
 * Chips are `button` elements under the hood, and clicking them toggles their `active` state.
 */
export const ToggleChip = ({
  label,
  onActivate,
  onDeactivate,
  size = 'small',
}: Props) => {
  const [active, setActive] = useState(false);

  const onChipClick = () => {
    if (active) {
      onDeactivate();
    } else {
      onActivate();
    }

    setActive(!active);
  };

  return (
    <ChipButton
      onClick={onChipClick}
      $active={active}
      type="button"
      aria-pressed={active}
    >
      <ChipLabel $size={size} $active={active}>
        {label}
      </ChipLabel>
    </ChipButton>
  );
};
