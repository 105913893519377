import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { css, styled } from 'styles';
import { medium } from '../../utils';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${rem('52px')});
  width: 100%;

  ${medium(css`
    min-height: calc(100vh - ${rem('80px')});
  `)}
`;

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 ${rem('20px')};
  text-align: center;

  ${medium(css`
    align-items: start;
  `)}
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
`;

export const Header = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
}))`
  margin: ${rem('86px')} 0 ${rem('15px')} 0;
`;

export const Message = styled(BodyText)``;

const ButtonContainer = styled.div`
  margin: ${rem('34px')} ${rem('10px')} 0 ${rem('10px')};
  max-width: 100%;

  ${medium(css`
    margin: ${rem('34px')} ${rem('20px')} 0 0;
  `)}
`;

export interface SuccessStateProps {
  title: string;
  messages: string[];
  buttons?: ReactNode[];
  children: ReactNode;
}

export const SuccessState = ({
  title,
  messages,
  buttons,
  children,
}: SuccessStateProps) => (
  <Wrapper>
    <Container>
      <Header data-testid="success-state-title">{title}</Header>
      {messages.map((copy, index) => (
        <Message key={index}>{copy}</Message>
      ))}
      {buttons && buttons.length > 0 && (
        <ButtonRow>
          {buttons.map(button => (
            <ButtonContainer>{button}</ButtonContainer>
          ))}
        </ButtonRow>
      )}
    </Container>
    {children}
  </Wrapper>
);
