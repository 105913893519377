import { getUserLocale } from 'i18n/getUserLocale';
import { rem } from 'polished';
import React, { useState } from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

interface CurrencyInputProps {
  value: string;
  currency: string;
  onValueChange(value: string, name: string): void;
  name: string;
  error?: string;
  ariaLabel?: string;
}

export const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  padding: ${rem(16)};
  border-radius: ${rem(12)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  margin-bottom: ${rem(4)};
  &:focus-visible {
    outline: none;
  }
`;

const Container = styled.div`
  height: ${rem(70)};
  margin-bottom: ${rem(24)};
`;

export const SalaryInput = ({
  value,
  currency,
  name,
  onValueChange,
  ariaLabel,
}: CurrencyInputProps) => {
  const [editedValue, setEditedValue] = useState<string>(value);

  return (
    <Container>
      <StyledCurrencyInput
        id={'avgSalary'}
        aria-label={ariaLabel}
        name={name}
        decimalsLimit={2}
        intlConfig={{
          locale: getUserLocale(),
        }}
        prefix={currency}
        value={editedValue}
        allowNegativeValue={false}
        onValueChange={updatedValue => {
          if (!updatedValue) {
            return;
          }
          setEditedValue(updatedValue);
          onValueChange(updatedValue, name);
        }}
      />
    </Container>
  );
};
