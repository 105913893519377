import { styled } from 'styles';
import React from 'react';
import RoutePath from 'App/RoutePath';
import { rem } from 'polished';
import { small } from 'utils';
import { css } from 'styled-components';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { StickyFooter } from '../Shared/StickyFooter';
import { TalkStartPageHeader } from './TalkStartPageHeader';
import { TalkBody } from './TalkBody';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(75)};

  ${small(css`
    padding-bottom: 0;
  `)};
`;
export const TalkStartPage = () => {
  const subdomain = getSubdomainFromUrl();
  const ctaNavigationPath =
    !subdomain || subdomain === 'search'
      ? RoutePath.SignInToYourOrganisation
      : RoutePath.Talk;

  return (
    <Container data-testid="alternative-start">
      <TalkStartPageHeader ctaNavigationPath={ctaNavigationPath} />
      <TalkBody ctaNavigationPath={ctaNavigationPath} />
      <StickyFooter
        actionButtonLabel="Browse practitioners ->"
        path={ctaNavigationPath}
        onCtaClick={() => {
          tracking.track('teams-start-page-cta-clicked', {
            abTestGroup: 'talk-focussed-start-page-sept-2024',
            tenantId: getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
            ctaLocation: 'sticky-footer',
          });
        }}
      />
    </Container>
  );
};
