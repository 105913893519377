import { useQuery } from '@apollo/client';
import { practitionerFiltersQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerFiltersQuery';
import { useMemo } from 'react';

import { practitionerCountriesQuery_practitionerCountries } from 'Services/BeGateway/Practitioner/__generated__/practitionerCountriesQuery';
import { PRACTITIONER_FILTERS_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import {
  COUNTRIES_REQUIRING_STATES,
  countryRequiresState,
} from 'Talk/hooks/useLocationData';
import states from 'Talk/lib/constants/states.json';
import { Namespace, ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import i18n from '../../i18n/config';
import { getISOLanguageFromCode } from '../../utils/getISOLanguageFromCode';

function getCountryLabel(
  country: practitionerCountriesQuery_practitionerCountries,
) {
  if (country?.isoCodeTwo) {
    return i18n.t(
      `country:${country?.isoCodeTwo}` as ParseKeys<Namespace<'country'>>,
    );
  }

  if (country?.name) {
    return country?.name;
  }

  return '';
}

export function formatCountryOptions(
  countries: practitionerCountriesQuery_practitionerCountries[],
) {
  const countryOptions =
    countries && countries?.length > 0
      ? countries?.map(country => ({
          value: country?.name ?? '',
          label: getCountryLabel(country),
        }))
      : [];
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  return countryOptions;
}

export function formatStateOptions(country: string) {
  if (countryRequiresState(country)) {
    return states[country] && states[country].length > 0
      ? states[country].map(state => ({
          label: state.label,
          value: state.value,
        }))
      : [];
  }

  return [];
}

const useAvailableLanguages = ({
  country,
  region,
}: {
  country: {
    value: string;
    label: string;
  };
  region: {
    value: string;
    label: string;
  } | null;
}) => {
  const { t: translateLanguageName } =
    useTranslation<Namespace<'languages'>>('languages');

  const { data, loading, error } = useQuery<practitionerFiltersQuery>(
    PRACTITIONER_FILTERS_QUERY,
    {
      variables: {
        types: ['THERAPIST', 'COACH'],
        country: country.value,
        state: Boolean(region?.value) ? region?.value : null,
      },
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
      skip:
        !country.value ||
        (COUNTRIES_REQUIRING_STATES.includes(country.value) && !region?.value),
    },
  );

  const availableLanguages = useMemo(
    () =>
      data?.practitionerFilters?.languages?.map(lang => {
        const languageLabels = getISOLanguageFromCode(lang.name);

        return {
          value: lang.name,
          label: `${translateLanguageName(
            languageLabels.en as ParseKeys<Namespace<'languages'>>,
          )} (${languageLabels.native})`,
        };
      }) || [],
    [data, translateLanguageName],
  );

  return {
    availableLanguages,
    loading,
    error,
  };
};

export default useAvailableLanguages;
