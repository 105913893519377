import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import { styled } from 'styles';
import { VideoPlayer } from 'icons';
import RoutePath from 'App/RoutePath';
import SectionError from 'Shared/SectionError';
import {
  SubCategoryList,
  useExploreCategoryQuery,
  CategoryContentQueryTypes,
} from 'Explore';
import { HomeScreenSection } from '../../PersonalisedToday/PersonalisedTodayScreen';
import SectionHeading from '../SectionHeading';

const HIDDEN_CONTENT_SLUG = 'workplace-of-tomorrow';

const VideoIcon = styled(VideoPlayer).attrs(({ theme }) => ({
  primaryColor: theme.colors.brand.primary,
  secondaryColor: theme.colors.text.secondary,
  tertiaryColor: theme.colors.background.card,
  height: 32,
  width: 32,
  'aria-hidden': true,
}))``;

const ListWrapper = styled.div`
  margin-top: ${rem(40)};
`;

type ManagerShortCategoriesProps = {
  managerCategorySlug: string;
};

const ManagerShortCategories = ({
  managerCategorySlug,
}: ManagerShortCategoriesProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation('homepage');
  const { data, loading, error, refetch } = useExploreCategoryQuery({
    variables: { slug: managerCategorySlug },
  });

  if (loading && !data) {
    return <Loader />;
  }

  if (error) {
    return (
      <SectionError
        onRetry={async () => refetch({ slug: managerCategorySlug })}
      />
    );
  }

  if (data?.exploreCategory?.subCategories) {
    return (
      <HomeScreenSection>
        <SectionHeading
          title={translate('managers_today.manager_short_categories.title')}
          subtitle={translate(
            'managers_today.manager_short_categories.subtitle',
          )}
          iconComponent={<VideoIcon />}
        />
        <ListWrapper>
          <SubCategoryList
            categories={
              data.exploreCategory.subCategories.filter(
                subCategory => subCategory?.slug !== HIDDEN_CONTENT_SLUG,
              ) as CategoryContentQueryTypes.categoryContent_exploreCategory_subCategories[]
            }
            onNavigateExploreCategory={categorySlug => {
              history.push(`${RoutePath.Explore}/${categorySlug}`, {
                from: location.pathname,
              });
            }}
          />
        </ListWrapper>
      </HomeScreenSection>
    );
  }

  return null;
};

export default ManagerShortCategories;
