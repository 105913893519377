import React from 'react';
import { styled, ThemeInterface } from 'styles';
import { Arrow } from 'icons';
import { Link, LinkProps } from 'react-router-dom';
import BodyText from '../Typography/BodyText';

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  height: inherit;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  text-decoration: none;
  &:active {
    opacity: 0.5;
  }
`;

const StyledText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.link,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))``;

const ArrowIcon = styled(Arrow).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.link,
    width: 16,
    height: 12,
  }),
)`
  margin-left: 6px;
`;

export interface ArrowLinkProps extends LinkProps {
  text?: string;
  ariaLabel?: string;
  onClick?(): void;
}

const ArrowLink = ({ text, to, ariaLabel, onClick }: ArrowLinkProps) => (
  <StyledLink
    to={to}
    aria-label={ariaLabel ? ariaLabel : text}
    onClick={onClick}
  >
    {text ? <StyledText>{text}</StyledText> : null}
    <ArrowIcon />
  </StyledLink>
);

export default ArrowLink;
