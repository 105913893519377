import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsMindSnacks = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 13a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-8a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 21a1 1 0 0 1 .928.629l1.452 3.628A2 2 0 0 1 5.523 28H4.477a2 2 0 0 1-1.857-2.743l1.452-3.628A1 1 0 0 1 5 21zm0 3.693L4.477 26l-.928-.371.928.371h1.046L5 24.693zM11 15a1 1 0 0 1 1 1v7.307l5.947 2.23a3 3 0 0 0 2.106 0L26 23.307V16a1 1 0 1 1 2 0v8a1 1 0 0 1-.649.936l-6.595 2.474a5 5 0 0 1-3.512 0l-6.595-2.474A1 1 0 0 1 10 24v-8a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.138 7.68a5 5 0 0 1 3.72-.025l12.508 4.914a1 1 0 0 1 0 1.861L33 13.5l.367.93-1.475.581a4640.188 4640.188 0 0 1-11.155 4.387 4.831 4.831 0 0 1-3.474.016L4.647 14.658a1 1 0 0 1-.025-1.862l12.516-5.115zm13.13 5.822L20.127 9.517a3 3 0 0 0-2.232.015L7.735 13.684l10.234 3.859c.666.25 1.386.249 2.04-.007 1.772-.693 4.927-1.935 7.641-3.003l2.619-1.031z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsMindSnacks;
