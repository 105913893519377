import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategorySleep = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 26 28" fill="none" {...props}>
    <path
      d="M21.517 8.014v5.263M23.97 10.531h-4.895"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.379 7.022c0-2.301.508-4.463 1.411-6.42a13.293 13.293 0 0 0-3.852 1.41C1.403 5.675-.924 13.927 2.725 20.462c3.662 6.535 11.913 8.861 18.448 5.212A13.507 13.507 0 0 0 25 22.444c-8.15-.432-14.621-7.158-14.621-15.41v-.012z"
      fill="#FFF964"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategorySleep;
