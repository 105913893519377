import React from 'react';
import { ButtonThemeStyleProps } from 'styles/types/buttons';
import { useTheme } from 'styles';

import BaseButton, { BaseButtonProps } from '../Button';

type Variant = 'onLightBg' | 'onDarkBg';

export interface SecondaryButtonProps extends BaseButtonProps {
  variant?: Variant;
}

export const SecondaryButton = ({
  disabled,
  variant = 'onLightBg',
  ...props
}: SecondaryButtonProps) => {
  const theme = useTheme();
  let styleProps: ButtonThemeStyleProps = theme.button.secondary.default;

  if (variant === 'onDarkBg') {
    const darkStyles = theme.button.secondary.onDark;
    styleProps = disabled ? darkStyles.disabled : darkStyles.default;
  } else if (disabled) {
    styleProps = theme.button.secondary.disabled;
  }

  return <BaseButton disabled={disabled} {...props} styleProps={styleProps} />;
};

export default SecondaryButton;
