import React from 'react';
import { IconProps } from '../IconProps';
const SvgVideo = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.95.867C1.248.929.61 1.347.266 1.969c-.123.223-.21.49-.25.765C0 2.841 0 13.164.015 13.27c.04.276.127.543.25.766a2.15 2.15 0 001.695 1.102c.128.011 10.952.011 11.08 0a2.146 2.146 0 001.948-1.91c.01-.085.012-.237.012-.688 0-.318.002-.578.004-.578l1.522.673c.835.37 1.543.682 1.574.693.08.027.196.055.28.067.115.017.318.013.432-.008.365-.066.684-.26.901-.549.15-.198.266-.494.281-.715.001-.016.004.207.006.495.002.288.004-1.792.004-4.624 0-2.831-.002-4.908-.004-4.616-.002.293-.005.517-.006.499a1.43 1.43 0 00-1.894-1.2c-.03.01-.74.322-1.574.692l-1.522.673c-.002 0-.004-.26-.004-.578 0-.626-.004-.702-.044-.9a2.592 2.592 0 00-.143-.44c-.319-.706-.999-1.19-1.769-1.257-.114-.01-10.982-.01-11.095 0zm.07 1.432a.719.719 0 00-.423.248.684.684 0 00-.146.28c-.013.049-.014.306-.014 5.175 0 4.87 0 5.127.014 5.175a.684.684 0 00.146.28.707.707 0 00.434.25c.037.005 1.817.007 5.506.005l5.452-.002.072-.022a.739.739 0 00.482-.483l.022-.07.004-1.193.005-1.192.017-.066a.735.735 0 01.504-.515.566.566 0 01.19-.018c.115 0 .135.002.197.021.037.012.97.421 2.072.91 1.103.488 2.007.888 2.01.888.003 0 .005-1.785.005-3.968 0-2.182-.002-3.968-.005-3.968s-.902.398-1.998.884l-2.034.9a.726.726 0 01-.411.022.713.713 0 01-.53-.527l-.017-.067-.005-1.188-.004-1.188-.022-.07a.739.739 0 00-.482-.483l-.072-.023H7.525c-3.005-.001-5.482.001-5.505.005zM.005 8.002c0 2.832 0 3.99.002 2.574V5.428C.006 4.013.005 5.171.005 8.002z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgVideo;
