import { Maybe } from 'graphql/jsutils/Maybe';
import { get } from 'lodash';
import { Audio, IconType, VideoCamera, Volume } from 'icons';

export enum ToolTypes {
  video = 'video',
  audio = 'audio',
}

/** Deprecated in favour of toolMediaIcons which
 * uses the Volume icon instead of Headphones
 * I suggest we completely cut over once we
 * no longer use Tool cards in Home
 */
export const deprecatedToolMediaIcons: {
  [key in ToolTypes]?: IconType;
} = {
  [ToolTypes.video]: VideoCamera,
  [ToolTypes.audio]: Audio,
};

export const getDeprecatedToolMediaTypeIcon = (
  type: Maybe<string>,
): IconType | undefined => {
  if (type) {
    return get(deprecatedToolMediaIcons, type);
  }

  return;
};

export const toolMediaIcons: {
  [key in ToolTypes]?: IconType;
} = {
  [ToolTypes.video]: VideoCamera,
  [ToolTypes.audio]: Volume,
};

export const getToolMediaTypeIcon = (
  type: Maybe<string>,
): IconType | undefined => {
  if (type) {
    return get(toolMediaIcons, type);
  }

  return;
};
