import { rem } from 'polished';
import React from 'react';
import { ValueType } from 'react-select';
import styled, { css } from 'styled-components';
import Label from '../../../../Shared/Form/Label';
import {
  SingleSelect,
  SingleSelectOption,
} from '../../../../Shared/Form/SingleSelect';
import { medium } from '../../../../utils';
import { TimePeriod } from '../../../../__generated__/globalTypes';
import { useFilterState } from '../../FilterState';
import { UserGroupFilterState } from '../../FilterState/types';

const FieldLabel = styled(Label)`
  display: inline-flex;
  margin-bottom: ${rem(8)};
`;

const FilterContainer = styled.div`
  ${medium(css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    margin-bottom: ${rem(12)};
    margin-left: auto;
    margin-right: 0;
    min-width: calc((100%) / 4);
    width: fit-content;
    height: 100%;
  `)}
  margin-bottom: ${rem(8)};
`;

export const timeFilterLabels: Record<TimePeriod, string> = {
  [TimePeriod.LAST_30_DAYS]: 'Last 30 days',
  [TimePeriod.LAST_12_WEEKS]: 'Last 12 weeks',
  [TimePeriod.LAST_6_MONTHS]: 'Last 6 months',
  [TimePeriod.ALL_TIME]: 'All time',
};

const TimeFilterDropdown = () => {
  const { timeFilterPreset, setTimeFilterPreset, setUserGroupFilterState } =
    useFilterState();

  const timeFilterOptions: SingleSelectOption<TimePeriod>[] = [
    TimePeriod.LAST_30_DAYS,
    TimePeriod.LAST_12_WEEKS,
    TimePeriod.LAST_6_MONTHS,
    TimePeriod.ALL_TIME,
  ].map(timeFilterPresetKey => ({
    value: timeFilterPresetKey,
    label: timeFilterLabels[timeFilterPresetKey],
  }));

  const handleChange = (
    input: ValueType<SingleSelectOption<TimePeriod>, false>,
  ) => {
    const timeFilter = input?.value;

    if (timeFilter && timeFilter !== timeFilterPreset) {
      setTimeFilterPreset(timeFilter);
      setUserGroupFilterState(currentState => {
        if (currentState) {
          const newState = Object.keys(currentState).reduce(
            (acc, key) => ({ ...acc, [key]: [] }),
            {},
          ) as UserGroupFilterState;

          return newState;
        }
      });
    }
  };

  return (
    <FilterContainer>
      <FieldLabel htmlFor="dashboard-time-filter">
        Choose time period
      </FieldLabel>
      <SingleSelect
        inputId="dashboard-time-filter"
        options={timeFilterOptions}
        placeholder=""
        value={timeFilterOptions.find(
          option => option.value === timeFilterPreset,
        )}
        onChange={handleChange}
      />
    </FilterContainer>
  );
};

export default TimeFilterDropdown;
