import { styled } from 'styles';
import { BodyText, Button } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';

export const UpdateLocationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 24px;
`;

export const LocationFormTitle = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.semiBold,
  sizes: [FontSizes.fontSize18],
}))`
  margin-bottom: 8px;
`;

export const LocationFormCopy = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})`
  margin-bottom: 16px;
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  width: 100%;
`;

export const SubmitButton = styled(Button).attrs({
  variant: 'primary',
})`
  margin-top: 8px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
