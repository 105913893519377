import { rem } from 'polished';
import React, { useEffect } from 'react';
import { styled, ThemeInterface } from 'styles';
import { Chevron } from 'icons';
import { LineChartProps } from './LineChart';
import { LineChart } from '.';

const ScrollChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const ControlWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: theme.colors.text.secondary,
  }),
)`
  &:hover {
    *[stroke] {
      stroke: ${({ theme }) => theme.colors.text.primary};
    }

    *[fill]:not([fill='none']) {
      fill: #eee;
    }
  }
  cursor: pointer;
  position: absolute;
  background: ${({ theme }) => theme.colors.background.card};
  width: ${rem(32)};
  height: ${rem(32)};
  z-index: 1;
  border-radius: 50%;
  padding: ${rem(9)};
  ${({ theme }) => `box-shadow: 0 0 ${rem(5)} ${theme.colors.boxShadow}`};
`;

export const ArrowLeftIcon = styled(ArrowIcon)`
  left: ${rem(34)};
  transform: rotate(180deg);
`;

export const ArrowRightIcon = styled(ArrowIcon)`
  right: ${rem(34)};
`;

const INTERVAL = 850;

export const LineChartWithScroll: React.FC<LineChartProps> = ({
  data,
  hideYAxis,
  yMax,
  pointDisplayCount,
  width,
  height,
}) => {
  const [index, setIndex] = React.useState(-1);
  const [canMoveUp, setUpMovement] = React.useState(false);
  const [canMoveDown, setDownMovement] = React.useState(false);

  const moveUp = () => {
    if (canMoveUp && index < data.length) {
      setIndex(index + 1);
    }
  };

  const moveDown = () => {
    if (canMoveDown && index > pointDisplayCount) {
      setIndex(index - 1);
    }
  };

  let upHandle: number | undefined = window.setInterval(moveUp, INTERVAL);
  let downHandle: number | undefined = window.setInterval(moveDown, INTERVAL);

  const clearTimers = () => {
    if (upHandle !== undefined) {
      clearInterval(upHandle);
    }

    if (downHandle !== undefined) {
      clearInterval(downHandle);
    }
  };

  const enableUpMovement = () => {
    if (upHandle === undefined) {
      upHandle = window.setInterval(moveUp, INTERVAL);
    }
    setDownMovement(false);
    setUpMovement(true);
  };

  const enableDownMovement = () => {
    if (downHandle === undefined) {
      downHandle = window.setInterval(moveDown, INTERVAL);
    }
    setUpMovement(false);
    setDownMovement(true);
  };

  const disableMovement = () => {
    setUpMovement(false);
    setDownMovement(false);
    clearTimers();
  };

  const toEnd = () => {
    disableMovement();
    setIndex(data.length);
  };

  const toBeginning = () => {
    disableMovement();
    setIndex(pointDisplayCount);
  };

  useEffect(() => () => {
    clearTimers();
  });

  return (
    <ScrollChartWrapper>
      <ControlWrapper visible={index > pointDisplayCount || index === -1}>
        <ArrowLeftIcon
          onMouseEnter={enableDownMovement}
          onMouseLeave={disableMovement}
          onClick={toBeginning}
        />
      </ControlWrapper>
      <LineChart
        data={data}
        hideYAxis={hideYAxis}
        displayUpToIndex={index}
        pointDisplayCount={pointDisplayCount}
        height={height}
        width={width}
        yMax={yMax}
      />
      <ControlWrapper visible={index < data.length && index !== -1}>
        <ArrowRightIcon
          onMouseEnter={enableUpMovement}
          onMouseLeave={disableMovement}
          onClick={toEnd}
        />
      </ControlWrapper>
    </ScrollChartWrapper>
  );
};
