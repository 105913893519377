import { rem } from 'polished';
import styled from 'styled-components';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import PrimaryButton from 'Shared/PrimaryButton/PrimaryButton';
import { DurationLabel as BaseDurationLabel } from '../DurationLabel';

export const Container = styled.div``;

export const DurationLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(24)};
`;

export const DurationLabel = styled(BaseDurationLabel)`
  margin-bottom: ${rem(24)};
`;

export const Subheader = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
}))`
  margin-bottom: ${rem(12)};
`;

export const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  margin-bottom: ${rem(24)};
`;

export const BrowseButton = styled(PrimaryButton)`
  width: 100%;
`;
