import { useMutation } from '@apollo/client';
import { logException } from 'App/logging';
import { submitUserFeedback_submitFeedback } from 'StaticContent/hooks/__generated__/submitUserFeedback';
import { SubmitFeedbackInput } from '__generated__/globalTypes';
import gql from 'graphql-tag';

export const SEND_FEEDBACK_MUTATION = gql`
  mutation submitUserFeedback($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      id
      code
      success
    }
  }
`;

export const useSendFeedback = () => {
  const [mutateSendFeedback] = useMutation<submitUserFeedback_submitFeedback>(
    SEND_FEEDBACK_MUTATION,
    {
      onError: error => {
        logException(error);
      },
    },
  );
  const sendFeedback = async (input: SubmitFeedbackInput) => {
    await mutateSendFeedback({ variables: { input: { ...input } } });
  };

  return { sendFeedback };
};
