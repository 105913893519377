import React from 'react';
import { useKnownReferrer } from 'hooks/useKnownReferrer';
import { TimerDispatch } from '../Shared/Timer';
import { logException } from '../App/logging';
import { tracking } from '../App/Tracking';
import { AudioPlayer } from '../Shared/AudioPlayer';
import { FullScreenTakeover } from '../Shared/FullScreenTakeover';
import { MediaTrackingEvent } from '../Shared/MediaPlayerComponents';
import { mediaErrorCodeToMessageMapping } from '../Shared/MediaPlayerComponents/mediaErrorCodes';
import { VideoPlayer } from '../Shared/VideoPlayer';

export interface ToolMediaPlayerProps {
  isOpen: boolean;
  onClose(): void;
  closeButtonLabel: string;
  mediaSrc: string;
  mediaType: 'audio' | 'video';
  reclaimFocus?: boolean;
  timerDispatch: TimerDispatch;
  title: string;
  summary?: string;
  slug: string;
  hasTranscript?: boolean;
  isTranscriptShown: boolean;
  onEnded(): void;
  onClickPlay(): void;
  onClickPause(): void;
  onClickReplay(): void;
  onMediaHasLooped?(): void;
  onTranscriptClick({
    position,
    totalLength,
  }: {
    position: number;
    totalLength: number;
  }): void;
  mediaSessionId: string;
}
interface AdditionalEventProps {
  loopEnabled?: boolean;
  method?: string;
  contentSlug?: string;
  startPosition?: number;
  endPosition?: number;
  position?: number;
  isVolumeMuted?: boolean;
  sound?: number;
}

export const ToolMediaPlayer = ({
  isOpen,
  onClose,
  closeButtonLabel,
  mediaSrc,
  mediaType,
  reclaimFocus = false,
  timerDispatch,
  title,
  summary,
  slug,
  hasTranscript,
  onClickPlay,
  onClickPause,
  onClickReplay,
  onTranscriptClick,
  onEnded,
  onMediaHasLooped,
  mediaSessionId,
  isTranscriptShown,
}: ToolMediaPlayerProps) => {
  const referrer = useKnownReferrer();

  const trackMediaEvent = ({
    trackingEvent,
    mediaElement,
    additionalEventProps: additionalTrackingProps = {},
  }: {
    trackingEvent: MediaTrackingEvent;
    mediaElement: HTMLMediaElement;
    additionalEventProps?: AdditionalEventProps;
  }) => {
    tracking.track(trackingEvent, {
      feature: 'tool',
      medium: mediaType,
      totalLength: mediaElement.duration,
      contentSlug: slug,
      mediaSessionId,
      referrer: referrer,
      ...additionalTrackingProps,
    });
  };

  const sharedMediaProps = {
    backButtonLabel: closeButtonLabel,
    onBackButtonClick: onClose,
    mediaSrc,
    onClickPlay,
    onClickPause,
    onClickReplay,
    onEnded: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      onEnded();
      trackMediaEvent({
        trackingEvent: 'media-playback-ended',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      });
    },
    onResume: (
      playerRef: React.RefObject<HTMLMediaElement>,
      method: string,
    ) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-resumed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    onPause: (playerRef: React.RefObject<HTMLMediaElement>, method: string) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-paused',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    onSeek: ({
      playerRef,
      method,
      startPosition,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
      startPosition: number;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-seek',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            startPosition,
            endPosition: playerRef.current.currentTime,
          },
        });
      }
    },
    onError: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      if (mediaEvent.currentTarget.error) {
        const mediaError = mediaEvent.currentTarget.error;
        const errorCode = mediaEvent.currentTarget.error.code;

        trackMediaEvent({
          trackingEvent: 'media-load-failed',
          mediaElement: mediaEvent.currentTarget,
          additionalEventProps: {
            method: mediaErrorCodeToMessageMapping[errorCode],
            position: mediaEvent.currentTarget.currentTime,
          },
        });

        logException(new Error('media-load-failed error'), {
          fingerprint: ['media-load-failed-error'],
          tags: {
            ...(mediaError.message !== '' && {
              message: mediaError.message,
            }),
            code: String(mediaError.code),
          },
        });
      }
    },
    timerDispatch,
    title,
    summary,
    hasTranscript,
    onLoading: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) =>
      trackMediaEvent({
        trackingEvent: 'media-playback-buffer-started',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      }),
    onLoaded: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      trackMediaEvent({
        trackingEvent: 'media-playback-buffer-completed',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      });
    },
    onClose: ({
      playerRef,
      method,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-session-closed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
        onClose();
      }
    },
    onVolumeChange: ({
      playerRef,
      method,
      isVolumeMuted,
      sound,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
      isVolumeMuted: boolean;
      sound: number;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-volume-changed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            isVolumeMuted,
            sound,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    onTranscriptClick,
    isTranscriptShown,
    mediaSessionId,
    feature: 'tool',
    slug,
  };

  return (
    <FullScreenTakeover
      shown={isOpen}
      initFocus={false}
      reclaimFocus={reclaimFocus}
    >
      {mediaType === 'audio' ? (
        <AudioPlayer
          {...sharedMediaProps}
          onClickLoop={({ loopEnabled, method, playerRef }) => {
            if (playerRef.current) {
              trackMediaEvent({
                trackingEvent: 'media-playback-loop-toggled',
                mediaElement: playerRef.current,
                additionalEventProps: {
                  loopEnabled,
                  method,
                  position: playerRef.current.currentTime,
                },
              });
            }
          }}
          onMediaHasLooped={onMediaHasLooped}
          referrer={referrer}
        />
      ) : (
        <VideoPlayer {...sharedMediaProps} referrer={referrer} />
      )}
    </FullScreenTakeover>
  );
};

export default ToolMediaPlayer;
