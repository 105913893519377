import { css, styled } from 'styles';
import { rem } from 'polished';
import { small } from '../../utils';
import { SecondaryButton } from '../../Shared/SecondaryButton';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { BodyText } from '../../Shared/Typography';

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const FormWrapper = styled.div`
  text-align: left;

  ${small(css`
    max-width: ${rem(640)};
  `)}
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ManageUsersTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))``;

export const ManageUsersBlurb = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
}))`
  display: block;
  max-width: 100%;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(24)};
`;

export const AlertContainer = styled.div`
  padding-bottom: ${rem(24)};
`;

export const SubmitPanel = styled.div``;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoadingIndicatorText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 0;
  margin-bottom: ${rem(6)};
`;

export const FileUploadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${rem(36)};
`;

export const CancelButton = styled(SecondaryButton)`
  border: none;
`;
