import * as braze from '@braze/web-sdk';
import { captureBrazeException } from './brazeSdkErrors';

export const initialiseBrazeInAppMessaging = () => {
  if (process.env.REACT_APP_BRAZE_ENABLED !== 'true') {
    return;
  }

  try {
    braze.automaticallyShowInAppMessages();
  } catch (error) {
    captureBrazeException(error as Error);
  }
};
