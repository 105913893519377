import RoutePath from 'App/RoutePath';
import { useHistory } from 'react-router-dom';
import { isMSTeams } from './MSTeams';
import { Subdomain } from './getSubdomainFromUrl';

type RedirectParams = {
  subdomain: Subdomain;
  routePath: RoutePath;
  queryString?: string;
};
export type RedirectToSubdomain = {
  redirectToSubdomain(params: RedirectParams): void | string;
};

export const useRedirectToSubdomain = (): RedirectToSubdomain => {
  const history = useHistory();

  const redirectToSubdomain = ({
    subdomain,
    routePath,
    queryString,
  }: RedirectParams) => {
    if (isMSTeams()) {
      localStorage.setItem('subdomain', subdomain);

      history.push(routePath);

      return;
    }

    const protocol = window.location.protocol;
    const domain = process.env.REACT_APP_DOMAIN;
    const query = queryString ? `?${queryString}` : '';
    const url = `${protocol}//${subdomain}.${domain}${routePath}${query}`;

    window.location.assign(url);

    return;
  };

  return { redirectToSubdomain };
};
