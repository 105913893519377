import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { OpenExternalLink, Phone } from 'icons';
import useIsManagerExperienceActive from 'Home/Managers/useIsManagerExperienceActive';
import { isMSTeams } from 'utils/MSTeams';
import { Breakpoints } from '@unmind/design-system-theme';
import useViewportWidth from 'utils/useViewportWidth';
import { useLocation } from 'react-router';
import i18n from '../../i18n/config';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import useFeatureFlag, { FEATURE_FLAGS } from '../../flags/useFeatureFlag';
import { UserType, isEmployee } from '../../utils';
import { useOrganisationEntitlements } from '../../Shared/Entitlement';
import { DropdownMenu, MenuItem } from './DropdownMenu';

const Wrapper = styled.div`
  display: flex;
  width: 10%;
  justify-content: flex-end;
`;

const ProfilesAndSettings = styled.div`
  display: flex;
  align-items: center;
`;

const ExternalLinkContainer = styled.span`
  display: flex;
  align-items: center;
`;

const ExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
}))`
  margin-left: ${rem(4)};
  height: ${`${4 / 3}em`};
  width: ${`${4 / 3}em`};
`;

const HelpIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 12,
  width: 12,
  ['aria-hidden']: true,
}))`
  margin-left: 6px;
`;

const getIsAdmin = (permissions: string[] | undefined): boolean => {
  if (permissions && permissions.length > 0) {
    return ['admin', 'superadmin'].some(p => permissions.includes(p));
  }

  return false;
};

type MenuItemConfig = {
  key: string;
  label: React.ReactNode;
  isEnabled?: boolean;
  path?: string;
  onSelect?(): void;
  overrides?: Record<string, unknown>;
};

export interface AccountMenuProps {
  permissions?: string[];
  userType?: UserType;
  onLogout(): void;
  onNavigate(path: string): void;
}

export const AccountMenu = ({
  permissions,
  userType,
  onLogout,
  onNavigate,
}: AccountMenuProps) => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const { t: translate } = useTranslation('navigation');
  const plusOneEnabled = useFeatureFlag('plus-one');
  const workplaceInsightsEnabled = useFeatureFlag(
    'show-workplace-insights-nav-option',
  );
  const unmindHelpEnabled = useFeatureFlag(
    FEATURE_FLAGS.TALK_ENABLE_IMPROVE_HELP_ACCESS_WEB,
  );
  const isManagerExperienceActive = useIsManagerExperienceActive();
  const compassEnabled = useFeatureFlag(FEATURE_FLAGS.COMPASS);
  const viewportWidth = useViewportWidth();
  const location = useLocation();

  const isAdmin = getIsAdmin(permissions);
  const { entitlements } = useOrganisationEntitlements([
    { entitlement: 'workplaceIndex' },
    { entitlement: 'standaloneIndex', default: true },
    { entitlement: 'unmindHelp' },
  ]);

  const isSmallScreen = viewportWidth < Breakpoints.S;

  const trackUnmindHelp = () => {
    tracking.track('help_immediate_support_clicked', {
      origin: location.pathname,
      unmindHelpEnabled: entitlements.unmindHelp,
    });
  };

  const onOptionSelected = (menuItem: MenuItemConfig) => () => {
    const { key, path, label, onSelect } = menuItem;

    tracking.track('account-nav-menu-item-clicked', {
      menuItemKey: key,
      menuItemLabel: label,
    });

    if (onSelect) {
      onSelect();
    }

    if (path) {
      onNavigate(path);
    }
  };

  const onProductHelp = () => {
    const faqLink = i18n.t('shared:auth_wrapper_help_centre.faq_link.url');
    window.open(faqLink);
  };

  const toggleDropdownMenu = () => {
    setDropdownMenuOpen(!dropdownMenuOpen);
  };

  const closeDropdownMenu = () => {
    setDropdownMenuOpen(false);
  };

  const menuItemsConfig: MenuItemConfig[] = [
    {
      key: 'unmind-help',
      label: (
        <>
          {translate('account_menu.tabs.immediate_support')}
          <HelpIcon />
        </>
      ),
      path: RoutePath.Help,
      onSelect: trackUnmindHelp,
      isEnabled: unmindHelpEnabled && (isMSTeams() || isSmallScreen),
    },
    {
      key: 'account',
      label: translate('account_menu.tabs.account'),
      path: RoutePath.Account,
    },
    {
      key: 'plus-one',
      label: translate('account_menu.tabs.send_invite'),
      path: RoutePath.InviteColleague,
      isEnabled:
        Boolean(plusOneEnabled) &&
        isEmployee(userType) &&
        !entitlements.standaloneIndex,
    },
    {
      key: 'admin',
      label: translate('account_menu.tabs.admin'),
      path: RoutePath.Admin,
      isEnabled: isAdmin && !entitlements.standaloneIndex,
    },
    {
      key: 'workplace-index',
      label: translate('account_menu.tabs.workplace_index'),
      path: RoutePath.Dashboard,
      isEnabled: Boolean(
        isAdmin && workplaceInsightsEnabled && entitlements.workplaceIndex,
      ),
    },
    {
      key: 'compass',
      label: translate('account_menu.tabs.compass'),
      path: RoutePath.Compass,
      isEnabled: Boolean(isManagerExperienceActive && compassEnabled),
    },
    {
      key: 'help',
      label: translate('account_menu.tabs.help'),
      path: RoutePath.Help,
    },
    {
      key: 'product-help',
      label: (
        <ExternalLinkContainer>
          {translate('account_menu.tabs.product_help.title')}{' '}
          <ExternalLinkIcon />
        </ExternalLinkContainer>
      ),
      onSelect: onProductHelp,
      overrides: {
        as: 'a',
        'aria-label': translate('account_menu.tabs.product_help.a11y_label'),
        target: '_blank',
      },
    },
    {
      key: 'logout',
      label: translate('account_menu.tabs.log_out'),
      onSelect: onLogout,
    },
  ];

  const menuItems = menuItemsConfig
    .filter(({ isEnabled }) => isEnabled !== false)
    .map(menuItem => (
      <MenuItem
        data-testid={`menu-item-${menuItem.key}`}
        key={menuItem.key}
        label={menuItem.label}
        onClick={onOptionSelected(menuItem)}
        {...menuItem.overrides}
      />
    ));

  return (
    <Wrapper>
      <ProfilesAndSettings
        data-testid="menu-profile-settings"
        id="menu-profile-settings"
      >
        <DropdownMenu
          onClick={toggleDropdownMenu}
          open={dropdownMenuOpen}
          onRequestClose={closeDropdownMenu}
        >
          {menuItems}
        </DropdownMenu>
      </ProfilesAndSettings>
    </Wrapper>
  );
};

export default AccountMenu;
