import React from 'react';
import { IconProps } from '../IconProps';

const SvgPadlockShield = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11.572 21.236a1.593 1.593 0 0 1-1.146 0A15.393 15.393 0 0 1 .652 6.909V2.245A1.593 1.593 0 0 1 2.245.652h17.51a1.592 1.592 0 0 1 1.592 1.593v4.647a15.393 15.393 0 0 1-9.773 14.342l-.002.002z"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.418 8.611h7.164c.479 0 .796.32.796.796v4.776c0 .478-.319.795-.796.795H7.418c-.478 0-.795-.319-.795-.795V9.407c0-.479.319-.796.795-.796z"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.816 8.612V7.019A3.194 3.194 0 0 1 11 3.836a3.194 3.194 0 0 1 3.183 3.183v1.593"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgPadlockShield;
