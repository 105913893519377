import { BodyText, small } from '@unmind/design-system-components-web';
import { Success } from 'icons';
import { rem } from 'polished';
import { css, styled } from 'styles';

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeedbackCta = styled.div`
  padding-bottom: 10px;
`;

export const FeedbackFormWrapper = styled.div`
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  padding-top: 20px;

  ${small(css`
    display: block;
  `)}
`;

export const FreeTextFeedbackOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.overlay};

  ${small(css`
    display: block;
  `)}
`;

export const OuterFeedbackSubmittedConfirmation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerFeedbackSubmittedConfirmation = styled(BodyText).attrs(
  ({ theme }) => ({
    color: theme.colors.text.inverse,
    sizes: [theme.typography.fontSizes.fontSize16],
  }),
)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(10)};
  box-shadow: 0px 8px 16px -4px ${({ theme }) => theme.colors.background.skeleton},
    0px 16px 32px -8px ${({ theme }) => theme.colors.background.skeleton};
  padding: ${rem(8)} ${rem(16)};
  margin: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.background.inverse};
`;

export const SuccessIcon = styled(Success).attrs(({ theme }) => ({
  primaryColor: theme.colors.border.success,
  secondaryColor: theme.colors.background.card,
}))`
  margin-right: ${rem(8)};
`;

export const FeedbackButtonsWrapper = styled.div<{
  isFeedbackFormActive: boolean;
}>`
  display: flex;
  align-self: flex-start;
  padding-bottom: ${({ isFeedbackFormActive }) =>
    isFeedbackFormActive ? rem(24) : rem(0)};
`;
