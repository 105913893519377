import React from 'react';
import { IconProps } from '../IconProps';

const SvgSleep = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Sleep_svg__clip0_1095_24980)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M9.98 8.25c0-1.42.32-2.76.87-3.97-.81.17-1.62.45-2.38.87-4.04 2.26-5.48 7.37-3.22 11.41 2.26 4.04 7.37 5.48 11.41 3.22.94-.53 1.72-1.21 2.37-2-5.04-.27-9.04-4.42-9.04-9.53h-.01z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.83 8.86v2.41M17.95 10.01h-2.24"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Sleep_svg__clip0_1095_24980">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSleep;
