import { useAuth0 } from '@auth0/auth0-react';
import { Form, Formik, FormikActions } from 'formik';
import React, { MouseEvent, useRef, useState } from 'react';
import { useTheme } from 'styles';
import useIrisToken from '../../App/Iris/useIrisToken';
import { LABEL_ERROR_MESSAGE } from '../../Shared/AlertView';
import { AlertBox } from '../../Shared/Form';
import PrimaryButton from '../../Shared/PrimaryButton';
import { BodyText } from '../../Shared/Typography';
import { FormFields, ManageUsersAlert } from './ManageUsersShared';
import {
  sendLineManagersFileForValidation,
  uploadLineManagersFileToS3,
} from './uploadLineManagersFile';
import {
  AlertContainer,
  CancelButton,
  FileUploadButtonsContainer,
  FormField,
  FormWrapper,
  LoadingContainer,
  LoadingIndicatorText,
  ManageUsersBlurb,
  ManageUsersTitle,
  StyledLoadingIndicator,
  SubmitPanel,
  Wrapper,
} from './ManageUsers.styled';
import { getLineManagerErrorMsg } from './lineManagerStatusMessages';

export const DEFAULT_ERROR_MSG = 'Sorry, something went wrong';
export const UPLOAD_SUCCESS_MSG = 'Successfully uploaded line managers';

export type ManageLineManagersProps = FormFields;

export const ManageLineManagers = (props: ManageLineManagersProps) => {
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [currentStatus, setCurrentStatus] = useState<
    ManageUsersAlert | undefined
  >(undefined);

  const [usersToAdd, setUsersToAdd] = useState<number | undefined>(undefined);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { hasError, irisToken } = useIrisToken();
  const { getAccessTokenSilently, isAuthenticated: isAuthenticatedViaAuth0 } =
    useAuth0();
  const theme = useTheme();

  const clearFileInputField = () => {
    inputRef.current.value = '';
  };

  const onValidate = async (values: FormFields) => {
    const selectedFile = values.file as File;

    if (!currentFile) {
      setCurrentFile(selectedFile);
      const auth0JWT = isAuthenticatedViaAuth0
        ? await getAccessTokenSilently()
        : undefined;

      const resp = await sendLineManagersFileForValidation(
        selectedFile,
        irisToken,
        auth0JWT,
      );

      if (!resp) {
        return;
      }

      if (resp.error) {
        const errorMsg = getLineManagerErrorMsg(resp.message);
        setCurrentStatus({
          success: false,
          message: errorMsg,
        });
        setCurrentFile(undefined);
        clearFileInputField();
      } else {
        setUsersToAdd(resp.lineManagersToAdd);
      }
    }
  };

  const onSubmit = async (
    values: FormFields,
    { setSubmitting, resetForm }: FormikActions<FormFields>,
  ) => {
    if (!currentFile) {
      setCurrentStatus({
        success: false,
        message: DEFAULT_ERROR_MSG,
      });

      return;
    }
    const auth0JWT = isAuthenticatedViaAuth0
      ? await getAccessTokenSilently()
      : undefined;

    const resp = await uploadLineManagersFileToS3(
      currentFile,
      irisToken,
      auth0JWT,
    );

    if (!resp?.ok) {
      setCurrentStatus({
        success: false,
        message: DEFAULT_ERROR_MSG,
      });
    } else {
      setCurrentStatus({
        success: true,
        message: UPLOAD_SUCCESS_MSG,
      });
    }
    setCurrentFile(undefined);
    clearFileInputField();
    resetForm();
    setSubmitting(false);
  };

  // remove any existing status message and open the file chooser
  const chooseFile = (event: MouseEvent<HTMLButtonElement>) => {
    setCurrentStatus(undefined);
    inputRef.current.click();

    event.preventDefault();
  };

  const handleReset = (
    values: FormFields,
    { resetForm }: FormikActions<FormFields>,
  ) => {
    setCurrentStatus(undefined);
    setCurrentFile(undefined);
    setUsersToAdd(undefined);

    resetForm();
  };

  const isChooseFileDisabled = !!currentFile;

  return (
    <Wrapper>
      <FormWrapper>
        <Formik
          initialValues={props}
          onSubmit={onSubmit}
          validate={onValidate}
          onReset={handleReset}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              {(currentStatus || hasError) && (
                <AlertContainer>
                  {hasError && (
                    <AlertBox
                      alertType="failed"
                      message={LABEL_ERROR_MESSAGE}
                    />
                  )}
                  {!hasError && currentStatus && (
                    <AlertBox
                      alertType={currentStatus.success ? 'success' : 'failed'}
                      message={currentStatus.message}
                    />
                  )}
                </AlertContainer>
              )}
              <FormField>
                <ManageUsersTitle id="lineManagersTitle">
                  Add managers
                </ManageUsersTitle>
                <ManageUsersBlurb>
                  Click on the choose file button below to get started. Once
                  you’ve uploaded the file, your managers will gain access to
                  manager content.
                </ManageUsersBlurb>
                <input
                  ref={inputRef}
                  accept=".csv"
                  aria-labelledby="lineManagersTitle"
                  type="file"
                  data-testid="line-managers-file-input"
                  style={{ display: 'none' }} // hidden to allow us to style the button and remove text
                  onChange={event => {
                    setFieldValue(
                      'file',
                      event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null,
                    );
                  }}
                />
                {isChooseFileDisabled ? null : (
                  <PrimaryButton
                    label="Choose file"
                    onClick={chooseFile}
                    disabled={isChooseFileDisabled}
                    ariaLabel="Upload Line Managers File"
                  />
                )}
              </FormField>
              {!currentStatus && isChooseFileDisabled && (
                <SubmitPanel>
                  <FormField>
                    {usersToAdd !== undefined && (
                      <>
                        <BodyText
                          sizes={[theme.typography.fontSizes.fontSize16]}
                        >
                          {currentFile ? currentFile.name : ''}
                        </BodyText>
                        <BodyText
                          sizes={[
                            theme.typography.fontSizes.fontSize18,
                            theme.typography.fontSizes.fontSize20,
                            theme.typography.fontSizes.fontSize22,
                            theme.typography.fontSizes.fontSize24,
                          ]}
                        >
                          {usersToAdd.toLocaleString()}
                        </BodyText>
                        <BodyText
                          sizes={[theme.typography.fontSizes.fontSize16]}
                        >
                          user{usersToAdd !== 1 ? 's ' : ' '}will be added
                        </BodyText>
                      </>
                    )}
                    {usersToAdd === undefined && (
                      <LoadingContainer>
                        <StyledLoadingIndicator />
                        <LoadingIndicatorText>
                          Reviewing file...
                        </LoadingIndicatorText>
                      </LoadingContainer>
                    )}
                    <FileUploadButtonsContainer>
                      <PrimaryButton
                        id="submit"
                        name="submit"
                        disabled={usersToAdd === undefined}
                        label="Add users"
                        loading={isSubmitting}
                        type="submit"
                      />
                      <CancelButton
                        label="Cancel"
                        type="reset"
                        disabled={usersToAdd === undefined || isSubmitting}
                      />
                    </FileUploadButtonsContainer>
                  </FormField>
                </SubmitPanel>
              )}
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};

export default ManageLineManagers;
