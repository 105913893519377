import React, { useEffect } from 'react';

import { tracking } from '../../../App/Tracking';
import { RADIO_ANIMATION_MS } from '../../../Shared/Radio/Question';
import RadioQuestion from './Question';
import { getIndexQuestionnaireQuestions_getIndexQuestionnaireQuestions_questions } from './__generated__/getIndexQuestionnaireQuestions';

export type Answer = {
  questionId: string;
  responseId: string;
};

type Answers = Answer[];

export interface QuestionsProps {
  indexQuestions: getIndexQuestionnaireQuestions_getIndexQuestionnaireQuestions_questions[];
  className?: string;
  onComplete(answers: Answers): void;
  answers: Answers;
  setAnswers(answers: Answers): void;
  currentQuestion: number;
  setCurrentQuestion(question: number): void;
  sessionId: string;
}

export const Questions = ({
  className,
  indexQuestions,
  onComplete,
  answers,
  setAnswers,
  currentQuestion,
  setCurrentQuestion,
  sessionId,
}: QuestionsProps) => {
  const onBackButtonClick = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  useEffect(() => {
    const position = currentQuestion + 1;
    const indexQuestionOpened = {
      sessionId,
      title: indexQuestions[currentQuestion].questionText,
      position,
      remaining: indexQuestions.length - position,
    };

    tracking.track('index-question-opened', indexQuestionOpened);
  }, [currentQuestion, indexQuestions, sessionId]);

  const onAnswerSelected = async (answer: Answer) => {
    const newAnswers = [...answers];

    if (answers[currentQuestion] === undefined) {
      newAnswers.push(answer);
    } else {
      newAnswers.splice(currentQuestion, 1, answer);
    }

    setAnswers(newAnswers);

    return new Promise<void>(resolve => {
      // Allow time for the user to see the answer selected before moving on
      setTimeout(() => {
        if (currentQuestion < indexQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          onComplete(newAnswers);
        }
        resolve();
      }, RADIO_ANIMATION_MS + 200);
    });
  };

  return (
    <RadioQuestion
      scale={indexQuestions[currentQuestion].scale}
      className={className}
      preQuestionText={indexQuestions[currentQuestion].preQuestionText}
      questionText={indexQuestions[currentQuestion].questionText}
      questionNumber={currentQuestion + 1}
      questionId={indexQuestions[currentQuestion].id}
      totalQuestions={indexQuestions.length}
      onBackButtonClick={onBackButtonClick}
      onAnswerSelected={onAnswerSelected}
      selectedOption={answers[currentQuestion]?.responseId}
    />
  );
};

export default Questions;
