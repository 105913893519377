export enum SessionsImportantOptions {
  therapist_led = 'therapist_led',
  focus_on_feelings = 'focus_on_feelings',
  understand_relationships = 'understand_relationships',
  teach_skills = 'teach_skills',
  improve_focus = 'improve_focus',
  safe_reflection = 'safe_reflection',
  challenge = 'challenge',
  explore = 'explore',
  growth = 'growth',
  dont_know = 'dont_know',
}

export enum MatchingStateResponse {
  sessions_for = 'SESSIONS_FOR_RESPONSE',
  sessions_important = 'SESSIONS_IMPORTANT_RESPONSE',
  where_location = 'WHERE_LOCATION_RESPONSE',
  what_language = 'WHAT_LANGUAGE_RESPONSE',
  reset_matching = 'RESET_MATCHING_RESPONSES',
  add_language = 'ADD_LANGUAGE',
  set_question = 'SET_CURRENT_QUESTION',
}

export enum MatchingQuestionKeys {
  sessions_for = 'sessions_for',
  sessions_important = 'sessions_important',
  where_location = 'where_location',
  what_language = 'what_language',
}

type LanguageResponseType = {
  value: string;
  label: string;
};

type LocationResponseType = {
  country: {
    value: string;
    label: string;
  };
  region: {
    value: string;
    label: string;
  };
};

const INITIAL_LOCATION_STATE = {
  value: '',
  label: '',
};

export const INITIAL_STATE: MatchingStateType = {
  questionResponses: {
    [MatchingQuestionKeys.sessions_for]: '',
    [MatchingQuestionKeys.sessions_important]: [],
    [MatchingQuestionKeys.where_location]: {
      country: INITIAL_LOCATION_STATE,
      region: INITIAL_LOCATION_STATE,
    },
    [MatchingQuestionKeys.what_language]: [],
  },
};

export type MatchingStateType = {
  questionResponses: {
    [MatchingQuestionKeys.sessions_for]: string;
    [MatchingQuestionKeys.sessions_important]: SessionsImportantOptions[];
    [MatchingQuestionKeys.where_location]: LocationResponseType;
    [MatchingQuestionKeys.what_language]: LanguageResponseType[];
  };
};

export type MatchingAction =
  | {
      type: MatchingStateResponse.sessions_for;
      payload: string;
    }
  | {
      type: MatchingStateResponse.sessions_important;
      payload: SessionsImportantOptions[];
    }
  | {
      type: MatchingStateResponse.where_location;
      payload: LocationResponseType;
    }
  | {
      type: MatchingStateResponse.what_language;
      payload: LanguageResponseType[];
    }
  | {
      type: MatchingStateResponse.reset_matching;
    };

const setQuestionResponse = (
  state: MatchingStateType,
  questionKey: MatchingQuestionKeys,
  payload:
    | string
    | SessionsImportantOptions[]
    | LocationResponseType
    | LanguageResponseType[],
): MatchingStateType => {
  const responses = state.questionResponses;

  return {
    ...state,
    questionResponses: {
      ...responses,
      [questionKey]: payload,
    },
  };
};

export const responseReducer = (
  state: MatchingStateType,
  action: MatchingAction,
) => {
  switch (action.type) {
    case MatchingStateResponse[MatchingQuestionKeys.sessions_for]:
      return setQuestionResponse(
        state,
        MatchingQuestionKeys.sessions_for,
        action.payload,
      );
    case MatchingStateResponse[MatchingQuestionKeys.sessions_important]:
      return setQuestionResponse(
        state,
        MatchingQuestionKeys.sessions_important,
        action.payload,
      );
    case MatchingStateResponse[MatchingQuestionKeys.where_location]:
      return setQuestionResponse(
        state,
        MatchingQuestionKeys.where_location,
        action.payload,
      );
    case MatchingStateResponse[MatchingQuestionKeys.what_language]:
      return setQuestionResponse(
        state,
        MatchingQuestionKeys.what_language,
        action.payload,
      );
    case MatchingStateResponse.reset_matching:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
