import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import Loader from '../Loader';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  /* TODO: Remove this and let consuming component specify margin */
  margin: ${rem(80)} auto;
`;

export const ID_LOADING = 'loading-indicator';

const LoadingIndicator = () => (
  <Container data-testid={ID_LOADING}>
    <Loader />
  </Container>
);

export default LoadingIndicator;
