import React from 'react';
import { useTimerDispatch } from './TimerContext';

/**
 * Components wrapped in this HOC must be descendents of a component that
 * has the TimerProvider.
 * The context is available within the timerDispatch prop.
 *
 * @param WrappedComponent The component that will consume the context
 */
export const withTimerDispatch =
  (WrappedComponent: React.ComponentType<any>) => (props: any) => {
    const context = useTimerDispatch();

    return <WrappedComponent timerDispatch={context} {...props} />;
  };

export default withTimerDispatch;
