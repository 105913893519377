import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from 'styles';

import BackButton from 'Shared/Button/BackButton';
import { HeadingText } from '../../../Shared/Typography';
import RoutePath from '../../../App/RoutePath';

export interface SeriesDayHeaderProps {
  day: number;
  name: string;
  slug: string;
}

const Container = styled.div`
  margin: 0 0 0 7.8rem;
  position: relative;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  padding-top: ${rem('42px')};
  display: flex;
  justify-content: space-between;
  max-width: ${rem('710px')};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem('710px')};
  margin-bottom: ${rem('26px')};
`;

export const SeriesName = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.bold,
}))`
  margin: 0;
  overflow: hidden;
`;

export const SeriesDay = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.secondary,
}))`
  margin: 0.25rem 0 0 0;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: ${rem('14px')};
  left: ${rem('-86px')};
`;

export const SeriesDayHeader = (props: SeriesDayHeaderProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <Container>
      <ButtonContainer>
        <BackButton linkToDefault={`${RoutePath.Courses}/${props.slug}/`} />
      </ButtonContainer>
      <HeaderContainer>
        <TitleContainer>
          <SeriesName>{props.name}</SeriesName>
          <SeriesDay data-testid="series-day-text">
            {translate('course_day.session_number', {
              session_number: props.day,
            })}
          </SeriesDay>
        </TitleContainer>
      </HeaderContainer>
    </Container>
  );
};
