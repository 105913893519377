import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesBodyHealth = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M8.636 6.779c9.141-4.409 16.37-2.83 20.106 1.517 2.98 3.466 5.05 6.828 5.805 13.349.757 6.52-4.715 11.79-19.95 9.76C-.64 29.375-2.796 12.275 8.636 6.779z"
      fill="#BBF2D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.991 7.581C16.055 7.967 17.08 8.694 18 9.81c.92-1.116 1.944-1.843 3.008-2.229a5.473 5.473 0 0 1 3.788.014c2.27.833 4.013 3.088 3.952 5.483-.019.762-.317 1.617-.568 2.224a13.046 13.046 0 0 1-.51 1.081l-.01.018-.003.006v.001l-.001.001a.75.75 0 0 1-1.314-.723l.001-.003.006-.011.019-.035.009-.017.102-.2c.085-.173.2-.415.315-.693.241-.58.443-1.219.455-1.687.042-1.664-1.216-3.393-2.97-4.036a3.974 3.974 0 0 0-2.76-.013c-.954.346-1.967 1.105-2.896 2.493a.75.75 0 0 1-1.247 0c-.929-1.388-1.941-2.147-2.896-2.493a3.974 3.974 0 0 0-2.76.013c-1.754.643-3.011 2.372-2.97 4.036.013.468.215 1.107.456 1.687a11.489 11.489 0 0 0 .444.944l.007.012.001.003a.75.75 0 0 1-1.314.723l.657-.362-.657.361-.001-.002-.003-.005-.01-.018a7.76 7.76 0 0 1-.153-.296c-.097-.196-.226-.47-.357-.785-.251-.607-.55-1.462-.569-2.224-.06-2.395 1.682-4.65 3.953-5.483a5.473 5.473 0 0 1 3.787-.014zm3.023 6.72a.75.75 0 0 1 .71.556l1.453 5.427 1.137-2.55a.75.75 0 0 1 1.309-.112l.777 1.162h4.599a.75.75 0 1 1 0 1.5h-5a.75.75 0 0 1-.623-.333l-.245-.366-1.447 3.242a.75.75 0 0 1-1.409-.111l-1.327-4.957-1.231 3.988a.75.75 0 0 1-1.367.152l-1.4-2.439-.327.49a.75.75 0 0 1-.623.332H8a.75.75 0 0 1 0-1.5h4.6l.777-1.16a.75.75 0 0 1 1.274.043l1.142 1.99 1.49-4.826a.75.75 0 0 1 .731-.529zm-5.995 8.144a.75.75 0 0 1 1.057.093c1.21 1.447 2.548 2.66 3.587 3.513.519.425.96.759 1.271.985l.066.048.066-.048c.31-.226.752-.56 1.27-.985 1.04-.853 2.378-2.066 3.588-3.512a.75.75 0 1 1 1.15.962c-1.289 1.541-2.701 2.82-3.786 3.71a29.89 29.89 0 0 1-1.727 1.314 11.32 11.32 0 0 1-.106.073l-.028.019-.008.005-.003.002L18 28l-.415.625-.001-.001-.003-.002-.008-.005-.029-.02-.105-.072a29.832 29.832 0 0 1-1.727-1.314c-1.085-.89-2.497-2.169-3.787-3.71a.75.75 0 0 1 .094-1.056zm5.566 6.18L18 28l.415.625a.75.75 0 0 1-.83 0z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesBodyHealth;
