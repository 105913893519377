import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { styled } from 'styles';
import { HeadingText } from '../../../Shared/Typography';
import { PersonalisedTodayContent_user } from '../__generated__/PersonalisedTodayContent';
import { useTitle } from './useTitle';

const HeaderTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
  accessibilityAutoFocus: true,
}))`
  margin-bottom: 40px;

  &:focus {
    outline: none;
  }
`;

type Props = {
  firstName: Maybe<PersonalisedTodayContent_user['firstName']>;
};

const Header = ({ firstName }: Props) => {
  const title = useTitle(firstName);

  return <HeaderTitle>{title}</HeaderTitle>;
};

export default Header;
