const TRACKING_FEATURES = ['tools', 'series', 'checkin', 'help'];

const urlToFeature = (path: string) => {
  if (path.startsWith('/')) {
    const chunk = path.split('/')[1];
    if (TRACKING_FEATURES.includes(chunk)) {
      return chunk;
    }

    return 'unknown';
  }

  return 'external';
};

export default urlToFeature;
