import { ApolloQueryResult } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionError from '../../Shared/SectionError';
import GenericExploreContent from '../GenericExploreContent/GenericExploreContent';
import GenericExploreContentSkeleton from '../GenericExploreContent/GenericExploreContentSkeleton';
import { exploreNewReleases } from '../__generated__/exploreNewReleases';
import { Series, Tool } from '../types';
import {
  Container,
  SectionHeadingTitle,
  SectionTitleSkeletonPlaceholder,
} from '../ExplorePage';

export interface NewReleasesProps {
  content: Maybe<(Series | Tool)[]>;
  assetToken?: string | null;
  hasErrored: boolean;
  refetch(): Promise<ApolloQueryResult<exploreNewReleases>>;
  isLoading: boolean;
}

function NewReleases({
  content,
  isLoading,
  assetToken,
  hasErrored,
  refetch,
}: NewReleasesProps) {
  const { t: translate } = useTranslation('explore');

  const SectionTitle = () => (
    <SectionHeadingTitle data-testid="new-releases-title">
      {translate('new_releases_section.title')}
    </SectionHeadingTitle>
  );

  if (isLoading && !content?.length) {
    return (
      <Container>
        <SectionTitleSkeletonPlaceholder
          title={translate('new_releases_section.title')}
        />
        <GenericExploreContentSkeleton />
      </Container>
    );
  }

  if (hasErrored) {
    return (
      <Container>
        <SectionTitle />
        <SectionError onRetry={refetch} />
      </Container>
    );
  }

  return content?.length ? (
    <Container>
      <SectionTitle />
      <GenericExploreContent
        content={content}
        assetToken={assetToken}
        source="explore-new-releases"
      />
    </Container>
  ) : null;
}

export default NewReleases;
