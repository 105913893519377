import { StylelessButton } from 'Shared/Button';
import { HeadingText } from '@unmind/design-system-components-web';
import { Funnel } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { extraSmall } from 'utils';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: ${rem(24)};
  margin-bottom: ${rem(8)};

  ${extraSmall(css`
    width: auto;
    margin-bottom: 0;
  `)}
`;

export const FunnelIcon = styled(Funnel).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.borderColor,
  'aria-hidden': true,
}))`
  width: ${rem(18)};
  height: ${rem(18)};
  margin-right: ${rem(8)};
`;

export const Label = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.bold,
  level: 3,
}))``;

export const FilterPanelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 2;
`;

export const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${rem(24)};
  gap: ${rem(12)};
`;

export const ClearButton = styled(StylelessButton).attrs({
  'aria-label': 'Clear',
})`
  padding: 0 ${rem(8)};
  border-radius: ${rem(8)};
  line-height: auto;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.disabled : theme.colors.text.primary};
  text-decoration: underline;
  text-underline-position: from-font;
  -ms-text-underline-position: from-font;
`;
