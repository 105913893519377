import React from 'react';
import { DisplayPill } from 'Talk/components/Pill';
import { Subheader, PillContainer } from './styles';

interface PillListProps {
  title: string;
  labels?: string[];
}

export const PillList = ({ title, labels = [] }: PillListProps) =>
  labels.length ? (
    <>
      <Subheader>{title}</Subheader>
      <PillContainer>
        {labels.map((label, i) =>
          label ? <DisplayPill key={`${label}-${i}`} label={label} /> : null,
        )}
      </PillContainer>
    </>
  ) : null;
