import React from 'react';
import { IconProps } from '../IconProps';
const SvgMsTeamsOutlined = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
    <g clipPath="url(#MSTeamsOutlined_svg__clip0_405_2467)">
      <path
        d="M23.255 6.086a1.787 1.787 0 11-3.575 0 1.787 1.787 0 013.575 0z"
        stroke="#0D0300"
        strokeOpacity={0.6}
        strokeWidth={1.122}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="MSTeamsOutlined_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={17}
        y={9}
        width={8}
        height={11}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.236 18.456a3.63 3.63 0 002.44.937h.018a3.644 3.644 0 003.644-3.644V10.47a.992.992 0 00-.992-.991h-5.783a.981.981 0 01.956 1.003v6.021a5.875 5.875 0 01-.283 1.953z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#MSTeamsOutlined_svg__a)">
        <path
          d="M18.236 18.455l-1.067-.345-.225.691.54.487.752-.833zm-.673-8.977V8.356l-.028 2.244.028-1.122zm.956 1.004l-1.121-.026v.026h1.121zm0 6.021h-1.121v.027l1.121-.027zm2.158 1.768c-.65 0-1.242-.244-1.69-.649l-1.503 1.666a4.752 4.752 0 003.193 1.227v-2.244zm.017 0h-.017v2.244h.017v-2.244zm2.522-2.522a2.522 2.522 0 01-2.522 2.522v2.244a4.766 4.766 0 004.766-4.766h-2.244zm0-5.28v5.28h2.244v-5.28h-2.244zm.13.131a.13.13 0 01-.13-.13h2.244a2.113 2.113 0 00-2.114-2.114V10.6zm-5.783 0h5.784V8.356h-5.784V10.6zm2.078-.092a2.103 2.103 0 00-2.05-2.151l-.056 2.243a.14.14 0 01-.137-.144l2.243.052zm0 5.995v-6.021h-2.243v6.021h2.243zm-.338 2.298a6.995 6.995 0 00.338-2.324l-2.243.052a4.751 4.751 0 01-.23 1.58l2.135.692z"
          fill="#0D0300"
          fillOpacity={0.6}
        />
      </g>
      <mask
        id="MSTeamsOutlined_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={10}
        y={1}
        width={8}
        height={8}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.432 5.033a2.27 2.27 0 01-3.063 2.127c.007.047.01.095.01.144v1.03a3.392 3.392 0 10-2.345-1.986h1.277a2.27 2.27 0 114.12-1.315z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#MSTeamsOutlined_svg__b)">
        <path
          d="M13.369 7.16l.392-1.051a1.122 1.122 0 00-1.502 1.218l1.11-.167zm.01 1.174h-1.121c0 .52.357.972.864 1.091l.258-1.091zm-2.344-1.987L10 6.782c.175.416.582.687 1.034.687V6.347zm1.277 0V7.47a1.122 1.122 0 00.914-1.772l-.914.65zm1.85 2.077a3.392 3.392 0 003.392-3.392H15.31c0 .634-.514 1.148-1.148 1.148v2.244zm-1.185-.213c.37.138.77.213 1.185.213V6.18c-.143 0-.278-.025-.401-.071l-.784 2.102zm-.718-.884a.165.165 0 01-.001-.023h2.244c0-.105-.008-.21-.024-.311l-2.219.334zm-.001-.023v1.03h2.244v-1.03h-2.244zm1.904-.002c-.182 0-.357-.02-.525-.06l-.515 2.183c.335.08.683.121 1.04.121V7.302zm2.27-2.27a2.27 2.27 0 01-2.27 2.27v2.244a4.514 4.514 0 004.514-4.514h-2.244zm-2.27-2.27a2.27 2.27 0 012.27 2.27h2.244A4.514 4.514 0 0014.162.52v2.243zm-2.27 2.27a2.27 2.27 0 012.27-2.27V.52a4.514 4.514 0 00-4.514 4.513h2.244zm.177.88a2.26 2.26 0 01-.177-.88H9.648c0 .619.125 1.21.353 1.75l2.068-.87zm-1.034 1.557h1.277V5.225h-1.277V7.47zm-.265-2.437c0 .731.233 1.411.628 1.966l1.828-1.301a1.137 1.137 0 01-.212-.665H10.77zm3.392-3.391a3.392 3.392 0 00-3.392 3.391h2.244c0-.634.514-1.148 1.148-1.148V1.64zm3.392 3.391a3.392 3.392 0 00-3.392-3.391v2.243c.634 0 1.148.514 1.148 1.148h2.244z"
          fill="#0D0300"
          fillOpacity={0.6}
        />
      </g>
      <mask
        id="MSTeamsOutlined_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={8}
        y={9}
        width={12}
        height={14}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.279 17.828a5.888 5.888 0 005.622 4.696 5.886 5.886 0 005.74-6.02v-6.022a.981.981 0 00-.956-1.003h-5.306v7.393a.957.957 0 01-.957.956H8.28z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#MSTeamsOutlined_svg__c)">
        <path
          d="M8.279 17.828v-1.122a1.122 1.122 0 00-1.1 1.347l1.1-.225zm5.622 4.696l-.028 1.122h.055l-.027-1.122zm5.74-6.02h-1.122v.025l1.122-.026zm0-6.022l-1.122-.026v.026h1.122zm-.957-1.004l.028-1.121h-.028v1.121zm-5.305 0V8.356c-.62 0-1.122.503-1.122 1.122h1.122zm-.957 8.35v-1.122h-.004l.004 1.122zm0 0v1.122h.005l-.005-1.122zm-5.242.225a7.01 7.01 0 006.693 5.593l.055-2.243a4.766 4.766 0 01-4.55-3.8l-2.198.45zm6.748 5.593a7.008 7.008 0 006.835-7.169l-2.244.052a4.764 4.764 0 01-4.646 4.874l.055 2.243zm6.835-7.143v-6.021h-2.244v6.021h2.244zm0-5.995a2.103 2.103 0 00-2.05-2.151l-.056 2.243a.14.14 0 01-.138-.144l2.244.052zm-2.079-2.152H13.38V10.6h5.305V8.356zm-6.427 1.122v7.393H14.5V9.478h-2.244zm0 7.393c0-.091.074-.165.165-.165v2.244c1.148 0 2.079-.93 2.079-2.079h-2.244zm.165-.165H8.28v2.244h4.143v-2.244z"
          fill="#0D0300"
          fillOpacity={0.6}
        />
      </g>
      <path
        d="M12.423 5.786H2.855c-.838 0-1.517.68-1.517 1.518v9.567c0 .838.68 1.517 1.517 1.517h9.568c.838 0 1.517-.679 1.517-1.517V7.304c0-.838-.68-1.518-1.517-1.518zM5.045 9.691h2.524m0 0v5.61m0-5.61h2.525"
        stroke="#0D0300"
        strokeOpacity={0.6}
        strokeWidth={1.122}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="MSTeamsOutlined_svg__clip0_405_2467">
        <path fill="#fff" transform="translate(.338)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMsTeamsOutlined;
