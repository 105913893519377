import { Exclamation } from 'icons';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { styled } from 'styles';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  getSentAtTimestamp,
  updateInteractionSession,
} from '../../../App/Tracking/serverside';
import HelpCategory from '../../../Help/types/HelpCategory';
import { logException } from '../../../App/logging';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { BodyText } from '../../../Shared/Typography';
import { LOG_EVENT_MUTATION } from '../../../App/Tracking/serverside/withLogEvent';
import {
  HelpFolderQuery,
  HelpFolderQuery_helpContentRoot_items,
} from './__generated__/HelpFolderQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props =>
    props.theme.colors.staticPalette.orange.orange200};
  padding: 10px 14px;
  margin: 16px 0px;
  border-radius: 13px;
`;

const InformationIcon = styled(Exclamation).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  height: 16,
  width: 16,
}))`
  margin-top: 2px;
`;

const DisclaimerText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  flex: 1;
  line-height: 18px;
  margin-left: 8px;
  font-weight: 500;
`;

const LinkText = styled(Link).attrs(({ theme }) => ({
  color: theme.colors.text.link,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  line-height: 18px;
  text-decoration: underline;
  font-weight: 500;
`;

export const HELP_FOLDER_QUERY = gql`
  query HelpFolderQuery {
    helpContentRoot {
      id
      ... on ContentFolder {
        items {
          id
          title
          summary
          folderType
        }
      }
    }
  }
`;

export default function CrisisHelpDisclaimer() {
  const { t: translate } = useTranslation('track');
  const { data } = useQuery<HelpFolderQuery>(HELP_FOLDER_QUERY);
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);

  const trackServerside = async (
    eventName: string,
    eventProperties: Record<string, unknown>,
  ) => {
    try {
      updateInteractionSession();
      const sentAt = getSentAtTimestamp();
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties: {
              ...eventProperties,
              clientSentAtUtcTimestamp: sentAt,
            },
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  const urgentHelpCategory = data?.helpContentRoot?.items?.find(
    item => item?.folderType === HelpCategory.Urgent,
  );

  return (
    <Wrapper>
      <InformationIcon />
      <DisclaimerText>
        {Trans({
          t: translate,
          i18nKey: 'wellbeing.crisis_section.crisis_text',
          components: {
            link_text: (
              <LinkText
                aria-label={translate('wellbeing.crisis_section.a11y_hint')}
                to={`${RoutePath.Help}/${urgentHelpCategory?.id}`}
                data-testid="urgent-help-link"
                onClick={() => {
                  const { id, title } =
                    urgentHelpCategory as HelpFolderQuery_helpContentRoot_items;

                  tracking.track('help-resource-opened', {
                    resourceCardTitle: title,
                    source: 'category-score-screen',
                  });

                  void trackServerside('HELP_RESOURCE_CLICKED', {
                    title: title,
                    contentId: id,
                  });
                }}
              />
            ),
          },
          defaults:
            'If you feel like you can’t keep yourself safe, we suggest connecting with someone who can help you, whether that’s your doctor, a relative or colleague. You’ll find details of confidential crisis helplines in the <link_text>Urgent Help page</link_text>',
        })}
      </DisclaimerText>
    </Wrapper>
  );
}
