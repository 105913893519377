import { Maybe } from 'graphql/jsutils/Maybe';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Link } from 'react-router-dom';
import { HeadingText } from '@unmind/design-system-components-web';
import { PersonalisedTodayContent_exploreCategory } from 'Home/PersonalisedToday/__generated__/PersonalisedTodayContent';
import RoutePath from '../../App/RoutePath';
import ArrowButton from '../../Shared/ArrowButton';
import { small, medium, getMediaSrc } from '../../utils';
import {
  HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE,
  HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP,
} from '../../Explore/constants';
import { HeadingTitleWrapper } from '../../Explore/HeroBanner/HeroBannerSection';
import { SectionHeadingTitle } from '../../Explore/ExplorePage';
import { BodyText } from '../../Shared/Typography';

const SectionWrapper = styled.section`
  margin-bottom: ${rem(40)};

  ${small(css`
    margin-bottom: ${rem(64)};
  `)}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.background.card};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  flex-wrap: wrap-reverse;

  ${small(css`
    flex-wrap: nowrap;
  `)}
`;

export const CardImageContainer = styled(Link)`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;

  ${small(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 19px;
    max-width: 65%;
  `)}

  :hover > :nth-child(2) > :nth-child(1) {
    transform: scale(1.1);
    transition: transform 200ms ease-in-out;
  }

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)};
`;

export const StyledImage = styled.img`
  object-fit: cover;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;

  ${small(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 19px;
  `)}

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)}
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  ${small(css`
    align-items: flex-start;
    width: unset;
    max-width: 35%;
    padding: 24px;
  `)}
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.semiBold,
  align: 'center',
  level: 2,
}))`
  word-break: break-word;
  margin-bottom: ${rem(4)};
  margin-top: ${rem(16)};

  ${small(css`
    margin-top: ${rem(0)};
    margin-bottom: ${rem(16)};
    text-align: left;
  `)}
`;

export const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: 'regular',
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(16)};

  ${small(css`
    color: ${({ theme }) => theme.colors.text.primary};
    line-height: 1.4;
  `)}
`;

export const TextWrapper = styled.div`
  text-align: center;

  ${small(css`
    text-align: left;
    margin-right: ${rem(22)};
  `)}
`;

export type ManagersProps = {
  exploreCategory: Maybe<PersonalisedTodayContent_exploreCategory>;
  assetToken: string;
  onNavigateItem(category: string): void;
};

const Managers = ({
  exploreCategory,
  assetToken,
  onNavigateItem,
}: ManagersProps) => {
  const { t: translate } = useTranslation('homepage');

  if (
    !exploreCategory?.slug ||
    !exploreCategory?.cloudinaryThumbnail?.path ||
    !assetToken
  ) {
    return null;
  }

  const categorySlug = exploreCategory.slug;
  const managersPath = `${RoutePath.Explore}/${categorySlug}`;
  const imageSrc = getMediaSrc({
    cloudinaryMedia: exploreCategory.cloudinaryThumbnail,
    assetToken,
  });

  return (
    <SectionWrapper data-testid="homepage-managers-banner">
      <HeadingTitleWrapper>
        <SectionHeadingTitle>
          {translate('managers_section.title')}
        </SectionHeadingTitle>
      </HeadingTitleWrapper>
      <Card>
        <CardContentContainer data-testid="card-content-container">
          <TextWrapper>
            <Title>{translate('managers_section.card.title')}</Title>
            <Subtitle>{translate('managers_section.card.subtitle')}</Subtitle>
          </TextWrapper>
          <ArrowButton
            text={translate('managers_section.card.arrow_link_button.text')}
            onClick={() => onNavigateItem(categorySlug)}
            to={{
              pathname: managersPath,
            }}
            enableMobileWebLink={true}
          />
        </CardContentContainer>
        <CardImageContainer
          onClick={() => onNavigateItem(categorySlug)}
          to={{
            pathname: managersPath,
          }}
        >
          <StyledImage src={imageSrc} />
        </CardImageContainer>
      </Card>
    </SectionWrapper>
  );
};

export default Managers;
