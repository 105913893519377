import { css, styled } from 'styles';
import { rem } from 'polished';
import { BodyText, extraSmall } from '@unmind/design-system-components-web';
import { Sparkles, Profile, ChevronDown } from 'icons';
import { Message } from '../types';

// Chatbot styles

export const ChatbotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${rem(88)};
  max-width: ${rem(800)};
  min-height: 100%;
  margin: 0 auto;
`;

export const ChatbotMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  gap: ${rem(24)};
  padding: 0 ${rem(16)} ${rem(200)} ${rem(16)};

  ${extraSmall(css`
    padding: 0 ${rem(16)} ${rem(130)} ${rem(16)};
  `)}
`;

export const LoadingMoreWrapper = styled.div`
  align-self: center;
  padding: ${rem(16)} 0 ${rem(32)} 0;
`;

export const LoadingMoreErrorWrapper = styled.div`
  margin-bottom: 16px;
`;

export const FeatureUnavailableErrorWrapper = styled.div`
  max-width: 450px;
  margin: auto;
  margin-bottom: 16px;
`;

export const ChatbotFooter = styled.div`
  position: fixed;
  width: 100%;
  padding: 0 ${rem(24)} ${rem(100)} ${rem(24)};
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.background.primary};

  ${extraSmall(css`
    padding-bottom: ${rem(52)};
  `)}
`;

export const ChatBotFooterText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.primary,
}))`
  margin: 0 auto;
  max-width: ${rem(800)};
  padding-top: ${rem(16)};
  text-align: center;
`;

export const ChatBotInputContainer = styled.div`
  max-width: ${rem(800)};
  margin: 0 auto;
`;

export const ScrollIconContainer = styled.div`
  position: fixed;
  bottom: ${rem(166)};
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50px;

  background-color: ${({ theme }) => theme.colors.background.inverse};
  box-shadow: ${({ theme }) => `0px 0px 8px ${theme.colors.boxShadow}`};
`;

export const ScrollIcon = styled(ChevronDown).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
  width: theme.typography.fontSizes.fontSize16,
  height: theme.typography.fontSizes.fontSize16,
}))``;

// Common Styles

export const InputBar = styled.div`
  background-color: ${({ theme }) => theme.colors.background.input};
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  border-radius: ${rem(32)};
  padding: ${rem(8)} ${rem(8)} ${rem(8)} ${rem(16)};
  display: flex;
  align-items: center;
  flex: 1;
  min-height: ${rem(52)};

  &:focus-within {
    box-shadow: ${({ theme }) =>
      `0px 8px 16px 1px ${theme.colors.border.secondary}`};
  }
`;

export const SparklesIcon = styled(Sparkles).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-right: ${rem(10)};
`;

export const TextFieldWrapper = styled.div`
  flex: 1;
  margin-left: ${rem(-10)};
`;

export const MessageIconWrapper = styled.div<{ sender: Message['role'] }>`
  display: flex;
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  align-items: center;
  gap: 8.349px;
  border-radius: 50%;

  ${({ theme, sender }) =>
    sender === 'user' &&
    css`
      background: ${theme.colors.chatbot.messages.user.icon.background};
    `}

  ${({ theme, sender }) =>
    sender === 'assistant' &&
    css`
      background: ${theme.colors.chatbot.messages.assistant.icon.background};
    `}
`;

export const AssistantIcon = styled(Sparkles).attrs(({ theme }) => ({
  primaryColor: theme.colors.chatbot.messages.assistant.icon.fill,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
  margin: ${rem(6)};
`;

export const ProfileIcon = styled(Profile).attrs(({ theme }) => ({
  primaryColor: theme.colors.chatbot.messages.user.icon.background,
  secondaryColor: theme.colors.background.input,
}))`
  width: ${rem(28)};
  height: ${rem(28)};
`;

export const UserInitialText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin: auto;
`;
