import { rem } from 'polished';
import React, { ButtonHTMLAttributes, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from 'styles';
import { Back } from 'icons';
import { LastLocationContext } from 'App/Router/LastLocationProvider';
import RoutePath from 'App/RoutePath';
import { isMSTeams } from 'utils/MSTeams';

export interface BackButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  linkToDefault?: string;
}

const BackButtonIcon = styled(Back).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
  secondaryColor: theme.colors.staticPalette.white,
}))`
  width: ${rem(50)};
  height: ${rem(50)};
`;

const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

interface LocationWithIsEntryRoute extends Location {
  isEntryRoute: boolean;
}

const BackButton = (props: BackButtonProps) => {
  const { t: translate } = useTranslation('shared');
  const history = useHistory<LocationWithIsEntryRoute>();
  const location = useLocation<LocationWithIsEntryRoute>();
  const lastLocation = useContext(LastLocationContext);

  useEffect(() => {
    /**
     * Update the state.isEntryRoute property when a intital navigation occurs,
     * this is determined by the last location context not returning a previous
     * location.
     */
    if (!lastLocation) {
      history.replace({
        ...location,
        state: { ...location.state, isEntryRoute: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      onClick={() => {
        /**
         * This is to capture scenario where user may directly in browser navigate to
         * page directly with series back button, in which case pressing it should not
         * use go `history.goBack` method as that would take them to location that does
         * not match up with intended functionality of the button. Instead we now check the
         * state provided to the route to see if it was entry route to the app or not and
         * if not use provided `linkToDefault`.
         */

        /**
         * For MS Teams users we need to pass query params when switching from a url with
         * a subdomain to a url without this is so that we can remove the stored subdomain
         * but retain the MS Teams context.
         */

        if (isMSTeams() && !location.state?.isEntryRoute) {
          if (location.pathname === RoutePath.SignInToYourOrganisation) {
            return history.push(
              `${RoutePath.StartSplitScreen}?removeSubdomain=true`,
            );
          }

          const goBackPaths = [RoutePath.Login, RoutePath.AuthMethod];

          const goBackToOrgScreen = goBackPaths.includes(
            location.pathname as RoutePath,
          );

          if (goBackToOrgScreen) {
            return history.push(
              `${RoutePath.SignInToYourOrganisation}?removeSubdomain=true`,
            );
          }
        }

        if (location.state?.isEntryRoute && props.linkToDefault) {
          history.push({
            pathname: props.linkToDefault,
            state: {
              ...location.state,
            },
          });
        } else {
          history.goBack();
        }
      }}
      {...props}
      aria-label={translate('content_header.back_button.label')}
    >
      <BackButtonIcon />
    </Button>
  );
};

export default BackButton;
