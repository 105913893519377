import React from 'react';
import { Info } from 'icons';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { medium } from '../../../utils/breakpoints';
import { StylelessButton } from '../../../Shared/Button';
import { BodyText } from '../../../Shared/Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  justify-content: right;

  ${medium(css`
    display: none;
  `)};
`;

const InfoButton = styled(StylelessButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
  height: 12,
  width: 12,
}))`
  margin-right: 4px;
`;

const InfoButtonText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.text.link,
}))``;

const ScienceBehindScoresButton = ({
  onButtonClick,
}: {
  onButtonClick(): void;
}) => {
  const { t: translate } = useTranslation('track');

  return (
    <Wrapper>
      <InfoButton
        role="button"
        aria-label={translate('wellbeing.about_screen.trigger_button.text')}
        onClick={onButtonClick}
      >
        <InfoIcon />
        <InfoButtonText>
          {translate('wellbeing.about_screen.trigger_button.text')}
        </InfoButtonText>
      </InfoButton>
    </Wrapper>
  );
};

export default ScienceBehindScoresButton;
