import { tracking } from 'App/Tracking';
import { practitionersQuery_practitioners_edges_node as Practitioner } from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import { getLocation } from 'Talk/lib/constants/getLocation';
import { getProfileSummary } from 'Talk/lib/constants/getProfileSummary';
import { Namespace, ParseKeys } from 'i18next';
import { Lightning, Location } from 'icons';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoutePath from '../../../App/RoutePath';
import { parseBeGatewayId } from '../../../utils/relay';
import placeholderImg from '../../assets/browse_practitioners.png';
import { AvailabilityChip } from '../AvailabilityChip';
import { Profession } from '../PractitionerProfileDetails/constants';
import {
  DetailsContainer,
  FocusAreaPill,
  FocusAreaPills,
  FocusAreas,
  ImageWrapper,
  LocationIcon,
  LocationText,
  PractitionerCardContainer,
  PractitionerLocation,
  PractitionerNameText,
  PractitionerTypeText,
  SummaryText,
  SummaryWrapper,
} from './styles';

export type PractitionerCardProps = {
  practitioner: Practitioner;
};

export const PractitionerCard = ({ practitioner }: PractitionerCardProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const {
    id,
    city,
    state,
    country,
    firstName,
    lastName,
    photoUrls,
    details,
    availability,
  } = practitioner || {};
  const convertedId = parseBeGatewayId(id);
  const photoUrl = photoUrls?.[0]?.url ?? placeholderImg;

  const {
    profileQuestionResponses,
    type: practitionerType,
    specialities,
  } = details || {};

  const profileSummary = getProfileSummary(
    profileQuestionResponses,
    practitionerType,
  );

  const { areasOfExpertise = [] } = specialities || {};

  const translateAreaOfExpertise = (area?: string) =>
    area ? t(`specialities.${area}` as ParseKeys<Namespace<'talk'>>) : '';

  const practitionerName = `${firstName} ${lastName}`;
  const profession = details.profileInformation?.profession as Profession;
  const professionValue =
    Profession[profession] && !(profession === Profession.other)
      ? t(`practitioner_profile.professions.${profession}`)
      : null;
  const practitionerTypeLabel = professionValue || capitalize(details.type);

  const ariaLabel = `${
    `${practitionerName}. ` +
    `${practitionerTypeLabel}. ` +
    `${getLocation(practitioner) ?? ''}. ` +
    `${profileSummary ? `${profileSummary}. ` : ''}`
  }${
    areasOfExpertise?.length
      ? `${t(
          'browse_practitioners.practitioner_card.focus_areas',
        )}: ${areasOfExpertise
          ?.map(area => translateAreaOfExpertise(area?.translationKey))
          .join(', ')}
      `
      : ''
  }`;

  const trackPractitionerSelected = () => {
    tracking.track('talk-browse-practitioner-card-selected', {
      practitionerId: id,
    });
  };

  return (
    <PractitionerCardContainer
      aria-label={ariaLabel}
      to={{
        pathname: `${RoutePath.TalkBrowse}/${convertedId}`,
        state: { source: 'browse_list' },
      }}
      onClick={trackPractitionerSelected}
      data-cy={`${practitioner.firstName}-${practitioner.lastName}-card`}
    >
      {availability?.hasAvailability && (
        <AvailabilityChip
          label={t('browse_practitioners.practitioner_card.available')}
          Icon={Lightning}
        />
      )}
      <ImageWrapper src={photoUrl} alt={`${firstName}'s photo`} />
      <DetailsContainer>
        <div>
          <PractitionerNameText>
            {firstName} {lastName}
          </PractitionerNameText>
          <PractitionerTypeText>{practitionerTypeLabel}</PractitionerTypeText>
          {city || state || country ? (
            <PractitionerLocation>
              <LocationIcon Icon={Location}></LocationIcon>
              <LocationText>{getLocation(practitioner)}</LocationText>
            </PractitionerLocation>
          ) : null}
          <SummaryWrapper>
            <SummaryText>{profileSummary}</SummaryText>
          </SummaryWrapper>
        </div>
        {areasOfExpertise?.length ? (
          <FocusAreas>
            <FocusAreaPills>
              {areasOfExpertise?.slice(0, 5).map(area => (
                <FocusAreaPill key={area?.id}>
                  {translateAreaOfExpertise(area?.translationKey)}
                </FocusAreaPill>
              ))}
            </FocusAreaPills>
          </FocusAreas>
        ) : null}
      </DetailsContainer>
    </PractitionerCardContainer>
  );
};
