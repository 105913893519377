import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { UserDetails } from '../SignUp';
import EmailInputForm, { FormFields } from './EmailInputForm';

export interface EnterCustomIDEmailFormProps {
  businessName: string;
  showMarketingCheckbox?: boolean;
  onSubmit(details: UserDetails): void;
  progressIndicator: React.ReactElement;
  isInitialValid?: boolean;
  initialValues?: { emailInput: string; marketingOptIn?: boolean };
  isSSOFlow?: boolean;
}

const EnterCustomIDEmailForm = ({
  isInitialValid = false,
  initialValues = { emailInput: '', marketingOptIn: false },
  showMarketingCheckbox = false,
  isSSOFlow = false,
  ...props
}: EnterCustomIDEmailFormProps) => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <AuthWrapper
      groupName={props.businessName}
      progressBar={props.progressIndicator}
    >
      <AuthWrapperBlock data-testid="employee-id-email-page">
        <AuthWrapperTitle data-testid="form-title">
          {translate('sign_up.forms.custom_id_email.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {isSSOFlow
            ? translate('sign_up.forms.custom_id_email.sso_subtitle')
            : translate('sign_up.forms.custom_id_email.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailInputForm
            inputLabel={translate(
              'sign_up.forms.custom_id_email.email_field.label',
            )}
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            showMarketingCheckbox={showMarketingCheckbox}
            onSubmit={(values: FormFields) => {
              if (showMarketingCheckbox) {
                props.onSubmit({
                  email: values.emailInput,
                  marketingOptIn: values.marketingOptIn,
                });
              } else {
                props.onSubmit({
                  email: values.emailInput,
                });
              }
            }}
          />
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default EnterCustomIDEmailForm;
