import { ApolloQueryResult } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GenericExploreContentSkeleton from '../GenericExploreContent/GenericExploreContentSkeleton';
import RoutePath from '../../App/RoutePath';
import SectionError from '../../Shared/SectionError';
import ArrowLink from '../../Shared/ArrowLink';
import {
  Container,
  SectionHeadingTitle,
  SectionTitleSkeletonPlaceholder,
} from '../ExplorePage';
import GenericExploreContent from '../GenericExploreContent/GenericExploreContent';
import {
  getUserCourses,
  getUserCourses_getUserCourses_edges,
} from './__generated__/getUserCourses';

export interface YourCoursesProps {
  courses: Maybe<Maybe<getUserCourses_getUserCourses_edges>[]>;
  assetToken?: string | null;
  isLoading: boolean;
  hasErrored: boolean;
  refetch(): Promise<ApolloQueryResult<getUserCourses>>;
}

const YourCourses = ({
  courses,
  assetToken,
  isLoading,
  hasErrored,
  refetch,
}: YourCoursesProps) => {
  const { t: translate } = useTranslation('explore');

  let viewAll;

  if (courses) {
    viewAll = courses?.length > 3;
  }

  const SectionTitle = () => (
    <SectionHeadingTitle data-testid="your-courses-title">
      {translate('your_courses_section.title')}
    </SectionHeadingTitle>
  );

  const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
  `;

  const ArrowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
  `;

  if (isLoading) {
    return (
      <Container>
        <SectionTitleSkeletonPlaceholder
          title={translate('your_courses_section.title')}
        />
        <GenericExploreContentSkeleton />
      </Container>
    );
  }

  if (hasErrored) {
    return (
      <Container>
        <SectionTitle />
        <SectionError onRetry={refetch} />
      </Container>
    );
  }

  return courses?.length ? (
    <Container>
      <HeadingWrapper>
        <SectionTitle />
        <ArrowWrapper>
          {viewAll ? (
            <ArrowLink
              text={translate('your_courses_section.arrow_link_button.text')}
              to={RoutePath.YourCourses}
            />
          ) : null}
        </ArrowWrapper>
      </HeadingWrapper>
      <GenericExploreContent
        // @ts-ignore
        content={courses.map(edge => edge?.node).filter(edge => !!edge)}
        assetToken={assetToken}
        source="explore-your-courses"
      />
    </Container>
  ) : null;
};

export default YourCourses;
