import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { Highlight } from '../SeriesShared';

interface SeriesDay {
  id: string;
  title: string;
  orderNo: number;
  dayHighlight1: string;
  dayHighlight2: string;
  dayHighlight3: string;
  segments: {
    id: string;
    orderNo: number;
    title: string;
  }[];
}

export interface ExtendedSeriesDay {
  id: string;
  complete: boolean;
  day: number;
  locked: boolean;
  segmentsComplete: number;
  segmentsTotal: number;
  started: boolean;
  title: string;
  highlights: Highlight[];
}

interface Series {
  id: string;
  seriesDays: SeriesDay[];
  slug: string;
}

interface InputProps {
  slug: string;
}

interface WithSeriesDaysResponse {
  singleSeries: Series;
  seriesDaysCompleted: {
    daysCompleted: number;
    seriesId: string;
  }[];
  seriesSegmentsCompleted: {
    edges: {
      node: {
        segmentId: string;
        seriesId: string;
      };
    }[];
  };
}

export interface WithSeriesDaysChildProps {
  loading: boolean;
  seriesDays?: ExtendedSeriesDay[];
  slug?: string;
  seriesId?: string;
}

export const withSeriesDaysQuery = gql`
  query SeriesDays($slug: String!) {
    singleSeries(slug: $slug) {
      id
      seriesDays {
        id
        title
        orderNo
        dayHighlight1
        dayHighlight2
        dayHighlight3
        segments {
          id
          orderNo
        }
      }
      slug
    }

    seriesDaysCompleted {
      daysCompleted
      seriesId
    }

    seriesSegmentsCompleted {
      edges {
        node {
          segmentId
          seriesId
        }
      }
    }
  }
`;

const extendSeriesDays = (
  seriesDays: SeriesDay[],
  daysCompleted: number,
  completedSegments: string[],
): ExtendedSeriesDay[] =>
  [...seriesDays].map(
    (
      { segments, title, dayHighlight1, dayHighlight2, dayHighlight3, id },
      index,
    ) => {
      const day = index + 1;
      const segmentsTotal = segments.length;
      const complete = index < daysCompleted;
      const locked = index > daysCompleted;
      const segmentsComplete = [...segments]
        .sort(
          ({ orderNo: firstOrderNo }, { orderNo: secondOrderNo }) =>
            secondOrderNo - firstOrderNo,
        )
        .reduce<
          {
            complete: boolean;
            id: string;
            title: string;
          }[]
        >(
          (accumulator, segment, segmentIndex) => [
            ...accumulator,
            {
              ...segment,
              complete:
                complete ||
                (segmentIndex > 0 && accumulator[segmentIndex - 1].complete) ||
                completedSegments.findIndex(
                  completedSegment => completedSegment === segment.id,
                ) >= 0,
            },
          ],
          [],
        )
        .filter(segment => segment.complete).length;
      const started = segmentsComplete > 0;
      const highlights = [
        { text: dayHighlight1 },
        { text: dayHighlight2 },
        { text: dayHighlight3 },
      ];

      return {
        id,
        complete,
        day,
        locked,
        segmentsComplete,
        segmentsTotal,
        started,
        title,
        highlights,
      };
    },
  );

export const withSeriesDays = graphql<
  InputProps,
  WithSeriesDaysResponse,
  InputProps,
  WithSeriesDaysChildProps
>(withSeriesDaysQuery, {
  options: ({ slug }) => ({
    variables: {
      slug,
    },
    fetchPolicy: 'network-only',
  }),
  props: ({ data }) => {
    if (data && data.loading) {
      return {
        loading: true,
      };
    }

    if (
      data &&
      data.singleSeries &&
      data.seriesDaysCompleted &&
      data.seriesSegmentsCompleted
    ) {
      const { id: seriesId, seriesDays, slug } = data.singleSeries;
      const daysCompleted = get(
        data.seriesDaysCompleted.find(record => seriesId === record.seriesId),
        'daysCompleted',
        0,
      );
      const completedSegments = data.seriesSegmentsCompleted.edges
        .filter(({ node }) => seriesId === node.seriesId)
        .map(({ node }) => node.segmentId);

      return {
        seriesId,
        slug,
        loading: false,
        seriesDays: extendSeriesDays(
          seriesDays,
          daysCompleted,
          completedSegments,
        ),
      };
    }

    return {
      error: true,
      loading: false,
    };
  },
});
