import {
  CheckInScores,
  CheckInScores_feelings,
} from '../../__generated__/CheckInScores';
import { Mood } from '../../../../__generated__/globalTypes';
import { filterCheckInFeelingsData } from './filterCheckInData';

export interface CheckInByMoodWithTopFeelings {
  count: number;
  mood: Mood;
  moodId: number;
  topFeelings: string[];
}

type CountedFeelings = {
  [x: string]: { name: string; count: number };
};

function getTopFeelings(feelings: CheckInScores_feelings[]): string[] {
  const countedFeelingsByMood: CountedFeelings = feelings.reduce(
    (allFeelings: CountedFeelings, feeling: CheckInScores_feelings) => {
      const feelingName = feeling.name as string;
      if (feelingName in allFeelings) {
        allFeelings[feelingName].count += 1;
      } else {
        allFeelings[feelingName] = {
          name: feelingName,
          count: 1,
        };
      }

      return allFeelings;
    },
    {},
  );

  return Object.values(countedFeelingsByMood)
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
    .map(feeling => feeling.name);
}

type GroupedMood = {
  count: number;
  mood: Mood;
  moodId: number;
  feelings: CheckInScores_feelings[];
};

function groupCheckInsByMood(checkins: CheckInScores[]): GroupedMood[] {
  return checkins.reduce(
    (allCheckins: GroupedMood[], { mood, moodId, feelings }) => {
      const filterFellings = filterCheckInFeelingsData(feelings);
      const moodObject = allCheckins.find(checkin => checkin.mood === mood);

      if (moodObject) {
        moodObject.count += 1;
        moodObject.feelings.push(...filterFellings);
      } else {
        allCheckins.push({
          count: 1,
          feelings: [...filterFellings],
          mood: mood as Mood,
          moodId: moodId as number,
        });
      }

      return allCheckins;
    },
    [],
  );
}

export const getGroupedCheckInsByMood = (
  checkins: CheckInScores[],
): CheckInByMoodWithTopFeelings[] =>
  groupCheckInsByMood(checkins)
    .sort((a, b) => b.moodId - a.moodId)
    .map(mood => ({
      count: mood.count,
      mood: mood.mood,
      moodId: mood.moodId,
      topFeelings: getTopFeelings(mood.feelings),
    }));
