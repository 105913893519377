import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import useFeatureFlag from '../../flags/useFeatureFlag';
import { AlertBox } from '../../Shared/Form';
import { useAnonymityThresholdTracking } from './helpers/tracking';
import { SeriesPanel } from './panels/SeriesPanel';
import { ToolsPanel } from './panels/ToolsPanel';
import UserActivityPanel from './panels/UserActivityPanel';
import RegistrationPanel from './panels/UserRegistrationPanel';
import {
  DataAvailability,
  DataAvailabilityVariables,
} from './__generated__/DataAvailability';
import {
  PanelColumn,
  PanelContainer,
  PanelWrapper,
} from './DashboardContent.styled';
import FilterPanel from './FilterPanel';
import { AnonymityWarningMessage } from './helpers/useAnonymityCopy';
import AnonymityWarning from './AnonymityWarning';
import { useFilterState } from './FilterState';
import { stripUnusedQueryVariables } from './FilterState/helpers';
import ErrorMessage from './ErrorMessage';

export const DATA_AVAILABILITY_QUERY = gql`
  query DataAvailability(
    $startDate: Date!
    $endDate: Date!
    $locationIds: [String]
    $departmentIds: [String]
    $timePeriod: TimePeriod
  ) {
    organisationActiveUsers(
      input: {
        startDate: $startDate
        endDate: $endDate
        locationIds: $locationIds
        departmentIds: $departmentIds
        timePeriod: $timePeriod
      }
    ) {
      shouldBeAnonymised
    }
    currentOrganisationSignUps: organisationSignUps(
      input: {
        startDate: $startDate
        endDate: $endDate
        locationIds: $locationIds
      }
    ) {
      shouldBeAnonymised
    }
    totalOrganisationSignUps: organisationSignUps(
      input: { endDate: $endDate, locationIds: $locationIds }
    ) {
      shouldBeAnonymised
    }
  }
`;

export const ActivityContent = () => {
  const { t: translate } = useTranslation('admin');
  const { userGroupFilterState, timeFilterState, timeFilterPreset } =
    useFilterState();

  const nonEmptyUserGroupFilterState = stripUnusedQueryVariables(
    userGroupFilterState || {},
  );

  const {
    data: {
      organisationActiveUsers,
      currentOrganisationSignUps,
      totalOrganisationSignUps,
    } = {},
    loading,
  } = useQuery<DataAvailability, DataAvailabilityVariables>(
    DATA_AVAILABILITY_QUERY,
    {
      variables: {
        ...timeFilterState,
        ...nonEmptyUserGroupFilterState,
        timePeriod: timeFilterPreset,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const hideRegistrations = totalOrganisationSignUps?.shouldBeAnonymised;
  const hideCurrentRegistrations =
    currentOrganisationSignUps?.shouldBeAnonymised;
  const hideUserActivity = organisationActiveUsers?.shouldBeAnonymised;

  const showActiveUserBanner = useFeatureFlag(
    'show-active-user-tracking-change-notification',
  );

  useAnonymityThresholdTracking({
    hideRegistrations,
    hideCurrentRegistrations,
    hideUserActivity,
    loading,
    adminTabType: 'activity',
  });

  if (loading) {
    return (
      <>
        <FilterPanel key="filterPanel" />
        <LoadingIndicator />
      </>
    );
  }

  if (
    !organisationActiveUsers ||
    hideRegistrations === undefined ||
    hideCurrentRegistrations === undefined
  ) {
    return <ErrorMessage />;
  }

  if (!!hideRegistrations) {
    return (
      <AnonymityWarning message={AnonymityWarningMessage.USER_REGISTRATIONS} />
    );
  }

  if (!!hideUserActivity) {
    return (
      <>
        <PanelWrapper>
          <RegistrationPanel />
        </PanelWrapper>
        <AnonymityWarning message={AnonymityWarningMessage.ACTIVE_USERS} />
      </>
    );
  }

  return (
    <>
      {showActiveUserBanner ? (
        <AlertBox
          alertType="warning"
          message={translate('activity_content.banner')}
        />
      ) : null}
      <FilterPanel key="filterPanel" />
      <PanelContainer>
        <PanelColumn>
          <PanelWrapper>
            <RegistrationPanel />
          </PanelWrapper>
          <PanelWrapper>
            <SeriesPanel />
          </PanelWrapper>
        </PanelColumn>

        <PanelColumn>
          <PanelWrapper>
            <UserActivityPanel />
          </PanelWrapper>
          <PanelWrapper>
            <ToolsPanel />
          </PanelWrapper>
        </PanelColumn>
      </PanelContainer>
    </>
  );
};

export default ActivityContent;
