import React from 'react';
import {
  IndicatorProps,
  MenuProps,
  OptionProps,
  PlaceholderProps,
  components,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { FilterOptionType } from '../BrowseFilterPanel/types';
import * as S from './styles';

export const TalkFilterOption = (
  props: OptionProps<FilterOptionType, true>,
) => (
  <components.Option {...props}>
    <S.OptionContents>
      <S.OptionCheckbox isSelected={props.isSelected}>
        {props.isSelected && <S.OptionCheck />}
      </S.OptionCheckbox>
      <S.OptionLabel>{props.label}</S.OptionLabel>
    </S.OptionContents>
  </components.Option>
);

export const TalkFilterPlaceholder = (
  props: PlaceholderProps<FilterOptionType, true>,
) => {
  const count = props.getValue().length ?? 0;

  return (
    <components.Placeholder {...props}>
      <S.PlaceholderContainer>
        <S.PlaceholderLabel count={count}>{props.children}</S.PlaceholderLabel>
        {count ? <S.Count>{count}</S.Count> : null}
      </S.PlaceholderContainer>
    </components.Placeholder>
  );
};

export const TalkFilterDropdownIndicator = (
  props: IndicatorProps<FilterOptionType, true> & { isOpen: boolean },
) => (
  <components.DropdownIndicator {...props}>
    {props.isOpen ? <S.Up /> : <S.Down />}
  </components.DropdownIndicator>
);

type TalkFilterMenuProps = {
  description?: string;
  onDone(): void;
};

export const TalkFilterMenu = (
  props: MenuProps<FilterOptionType, true> & TalkFilterMenuProps,
) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const hasSelectedItems = props.getValue().length > 0;

  return (
    <components.Menu {...props}>
      <>
        <S.Description>{props.description}</S.Description>
        {props.children}
        <S.ButtonsContainer>
          <S.ClearButton
            onClick={props.clearValue}
            disabled={!hasSelectedItems}
          >
            {t('browse_practitioners.filter_panel.clear')}
          </S.ClearButton>
          <S.DoneButton
            label={t('browse_practitioners.filter_panel.done')}
            onClick={props.onDone}
          />
        </S.ButtonsContainer>
      </>
    </components.Menu>
  );
};
