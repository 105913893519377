import { Colors, FontSizes } from '@unmind/design-system-theme';
import React, { forwardRef, useRef } from 'react';
import { styled, useTheme } from 'styles';
import useAssignFocus from '../Accessibility/hooks/useAssignFocus';
import Base, { Props as BaseTextProps } from './Base';

export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;

export interface Props
  extends Omit<BaseTextProps, 'italic' | 'sizes' | 'color'> {
  /**
   * An array of font sizes taken from the design tokens. These set the font size at the default, XS, S and M breakpoints respectively. At least 1 entry is required.
   */
  sizes?: FontSizes[];
  color?: Colors;
  /**
   * Defaults to `2`
   * The HTML heading element which the component is rendered as - e.g. level={2} results in an `h2`.
   */
  level: HeadingLevels;
  /**
   * Defaults to `false`
   * Whether to apply focus to the element on mount. This is useful for accessibility purposes.
   * Use sparingly; once per page at most and generally on the `h1`
   */
  accessibilityAutoFocus?: boolean;
}

const StyledHeadingText = styled(Base)`
  font-family: ${({ theme }) => theme.typography.headingText.fontFamily};
  line-height: 130%;

  &:focus {
    outline: none;
  }
`;

const HeadingText = forwardRef<HTMLHeadingElement, Props>(
  (
    {
      level = 2,
      sizes = [
        FontSizes.fontSize16,
        FontSizes.fontSize20,
        FontSizes.fontSize24,
        FontSizes.fontSize28,
      ],
      align = 'left',
      weight,
      color,
      accessibilityAutoFocus = false,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const headerRef = useRef<HTMLHeadingElement>(null);
    useAssignFocus(headerRef, accessibilityAutoFocus);

    return (
      <StyledHeadingText
        sizes={sizes}
        align={align}
        color={color ?? theme.colors.text.primary}
        weight={weight ?? theme.typography.fontWeights.regular}
        ref={accessibilityAutoFocus ? headerRef : ref}
        tabIndex={accessibilityAutoFocus ? -1 : undefined}
        className={className}
        forwardedAs={
          `h${level}` as keyof JSX.IntrinsicElements | React.ComponentType<any>
        }
        {...props}
      >
        {children}
      </StyledHeadingText>
    );
  },
);

export default HeadingText;
