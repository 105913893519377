import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@unmind/design-system-components-web';
import { BodyText } from 'Shared/Typography';
import { useTheme } from 'styled-components';
import { FeedbackCategory } from '__generated__/globalTypes';
import { ToggleChip } from 'Shared/ToggleChip/ToggleChip';
import {
  Form,
  TitleWrapper,
  ToggleChipWrapper,
  TextArea,
  CloseIcon,
  ButtonsWrapper,
  SubmitButton,
} from './styles';

interface FeedbackUserInput {
  feedbackText: string;
  contactConsent: boolean;
  selectedTagOptions?: string[];
}

interface FeedbackFormProps {
  handleCloseClick(): void;
  handleSubmitClick(userInput: FeedbackUserInput): void;
  feedbackType: FeedbackCategory;
}

const FeedbackForm = ({
  handleCloseClick,
  handleSubmitClick,
  feedbackType,
}: FeedbackFormProps) => {
  const { t: translate } = useTranslation('chatbot');
  const { colors, typography } = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [selectedTagOptions, setSelectedTagOptions] = useState<string[]>([]);

  const handleOptionActivate = (option: string) => {
    setSelectedTagOptions(prevOptions => [...prevOptions, option]);
  };

  const handleOptionDeactivate = (option: string) => {
    setSelectedTagOptions(prevOptions =>
      prevOptions.filter(opt => opt !== option),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitClick({
      feedbackText: textareaValue,
      contactConsent: isChecked,
      selectedTagOptions,
    });
  };

  const negativeOptions = translate(
    'conversation.chatbot_feedback.negative_options',
    { returnObjects: true },
  );
  const positiveOptions = translate(
    'conversation.chatbot_feedback.positive_options',
    { returnObjects: true },
  );

  const options =
    feedbackType === FeedbackCategory.NEGATIVE
      ? negativeOptions
      : positiveOptions;

  useEffect(() => {
    setSelectedTagOptions([]);
  }, [feedbackType]);

  return (
    <Form onSubmit={handleSubmit} isChecked={isChecked}>
      <TitleWrapper>
        <BodyText
          weight={typography.fontWeights.bold}
          color={colors.text.primary}
          sizes={[typography.fontSizes.fontSize18]}
        >
          {translate('conversation.chatbot_feedback.title')}
        </BodyText>
        <CloseIcon
          role="button"
          tabIndex={0}
          aria-label={translate(
            'conversation.chatbot_feedback.close_button.a11y_label',
          )}
          onClick={handleCloseClick}
          data-testid="close-button"
        />
      </TitleWrapper>
      <ToggleChipWrapper>
        {options.map((option, index) => (
          <ToggleChip
            onActivate={() => handleOptionActivate(option)}
            onDeactivate={() => handleOptionDeactivate(option)}
            label={option}
            key={`${index}-${option}`}
          />
        ))}
      </ToggleChipWrapper>
      <TextArea
        onChange={e => setTextareaValue(e.target.value)}
        name="feedback"
        placeholder={translate(
          'conversation.chatbot_feedback.textarea.placeholder_text',
        )}
        value={textareaValue}
      />
      <Checkbox
        onChange={() => setIsChecked(!isChecked)}
        labelText={translate('conversation.chatbot_feedback.checkbox.label')}
        data-testid="contact-consent"
        checked={isChecked}
      />
      <ButtonsWrapper>
        <SubmitButton
          disabled={!textareaValue}
          aria-disabled={!textareaValue}
          label={translate('conversation.chatbot_feedback.submit_button.label')}
          variant="primary"
          type="submit"
          size="medium"
        />
      </ButtonsWrapper>
    </Form>
  );
};

export default FeedbackForm;
