import { BodyText, Button, large } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { css, styled } from 'styles';

export const PromptContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background.warning};
  border-color: ${({ theme }) => theme.colors.border.warning};
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
`;

export const CompletionContainer = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 24px 0 0;

  ${large(css`
    justify-content: flex-start;
    padding: 0;
  `)}
`;

export const Heading = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
  tabIndex: -1,
}))`
  margin-bottom: 2px;

  &:focus {
    outline: none;
  }
`;

export const Body = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize18],
})``;

export const CTAContainer = styled.div`
  margin-top: 24px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px;
`;

export const ModalHeading = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  tabIndex: -1,
}))`
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
`;

export const ModalCopy = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize18],
}))`
  margin-bottom: 16px;
`;

export const ModalSubText = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize14],
})`
  margin-bottom: 16px;
`;

export const ModalHelpLink = styled.a`
  font-size: inherit;
`;

export const ModalCTA = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  margin-left: auto;
`;
