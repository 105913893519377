import { styled } from 'styles';
import { PlusOne as PlusOneIcon, PlusOneHero as PlusOneHeroIcon } from 'icons';

export const PlusOneImage = styled(PlusOneIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.secondary,
  role: 'presentation',
}))`
  display: block;
`;

export const PlusOneHeroImage = styled(PlusOneHeroIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.secondary,
  role: 'presentation',
}))`
  display: block;
  width: 100%;
`;
