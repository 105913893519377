import RoutePath from 'App/RoutePath';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import useUniversalLogin from 'LoggedOut/UniversalLogin/useUniversalLogin';
import React, { useEffect } from 'react';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import LoadingIndicator from '../../Shared/LoadingIndicator';

export function MSTeamsSSO() {
  const subdomain = getSubdomainFromUrl();

  const { authProviderOrganisationId, loading } = useSubdomainInfo({
    subdomain,
  });

  const { loginWithUniversalLogin } = useUniversalLogin();

  useEffect(() => {
    const redirectToIdentityProvider = async () => {
      await loginWithUniversalLogin({
        authProviderOrganisationId: authProviderOrganisationId,
        returnTo: RoutePath.MSTeamsSSOCallback,
      });
    };

    if (authProviderOrganisationId) void redirectToIdentityProvider();
  }, [authProviderOrganisationId, loginWithUniversalLogin]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return <></>;
}
