import * as styledComponents from 'styled-components';
import { ThemeInterface } from './lightTheme';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeInterface {}
}

const {
  default: styled,
  createGlobalStyle,
  css,
  keyframes,
  ThemeConsumer,
  ThemeContext,
  ThemeProvider,
  withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export {
  createGlobalStyle,
  css,
  keyframes,
  ThemeConsumer,
  ThemeContext,
  ThemeProvider,
  withTheme,
};
export default styled;
