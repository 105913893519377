import { rem } from 'polished';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link } from 'react-router-dom';
import { styled } from 'styles';
import { Back } from 'icons';
import { logException } from '../../../App/logging';
import RoutePath from '../../../App/RoutePath';
import { FullScreenTakeover } from '../../../Shared/FullScreenTakeover';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import BodyText from '../../../Shared/Typography/BodyText';
import QuestionnaireHeader from './QuestionnaireHeader';

const StyledFullScreenTakeover = styled(FullScreenTakeover)`
  overflow-y: scroll;
  padding-bottom: ${rem(16)};
`;

const OutroText = styled(BodyText)`
  & + & {
    margin-top: ${rem(12)};
  }
`;

const OutroLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.link};
  margin: 0;
  font-weight: 700;
  text-decoration: none;
`;

export const SubmitButton = styled(PrimaryButton)`
  margin-top: ${rem(32)};
  margin-right: auto;
`;

const TakeoverContainer = styled.div`
  max-width: ${rem(710)};
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  position: relative;
`;

const ContentContainer = styled.div`
  margin: 0 ${rem(20)};
`;

export interface QuestionnaireOutroProps {
  goBackToQuestions(): void;
  submitAnswers(): Promise<void>;
}

enum UIState {
  READY = 'READY',
  SUBMITTING = 'SUBMITTING',
  ERROR = 'ERROR',
}

const QuestionnaireOutro = ({
  goBackToQuestions,
  submitAnswers,
}: QuestionnaireOutroProps) => {
  const [uiState, setUIState] = useState<UIState>(UIState.READY);

  const submitIndex = async () => {
    try {
      setUIState(UIState.SUBMITTING);
      await submitAnswers();
      setUIState(UIState.READY);
    } catch (error) {
      setUIState(UIState.ERROR);
      logException(error);
    }
  };

  const { t: translate } =
    useTranslation<Namespace<'unmind_index'>>('unmind_index');

  return (
    <>
      <StyledFullScreenTakeover shown={true} hasWhiteBackground={true}>
        <TakeoverContainer>
          <ContentContainer>
            <QuestionnaireHeader
              buttonAriaLabel={translate('index_outro.index_header.aria_label')}
              buttonIcon={Back}
              onButtonClick={goBackToQuestions}
              heading={translate('index_outro.index_header.heading')}
              headingSize="medium"
              fullScreenMode={true}
            />
            {Trans({
              t: translate,
              i18nKey: 'index_outro.paragraph_text',
              components: {
                index_complete_message: (
                  <OutroText data-testid="index-complete-message" />
                ),
                paragraph: <OutroText />,
                link_large: <OutroLink to={RoutePath.Help} target="__blank" />,
              },
            })}
            <SubmitButton
              size={ButtonSize.medium}
              label={translate('index_outro.submit_questionnaire_button.label')}
              onClick={submitIndex}
              disabled={uiState === UIState.SUBMITTING}
            />
          </ContentContainer>
        </TakeoverContainer>
      </StyledFullScreenTakeover>
    </>
  );
};

export default QuestionnaireOutro;
