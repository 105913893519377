import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { BodyText } from '../../../../../Shared/Typography';

export interface SegmentActionButtonProps {
  text: string;
}

const Button = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: ${rem('4px')};
  border: ${rem('1px')} solid ${({ theme }) => theme.colors.border.secondary};
  max-width: ${rem('300px')};
  height: ${rem('300px')};
  text-decoration: none;
`;

const StyledText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))`
  margin-top: ${rem('24px')};
  margin-bottom: ${rem('79px')};
  text-align: center;
`;

const SegmentActionButton: React.FC<SegmentActionButtonProps> = ({
  children,
  text,
}) => (
  <Button>
    {children}
    <StyledText>{text}</StyledText>
  </Button>
);

export default SegmentActionButton;
