import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getActionDetail } from './__generated__/getActionDetail';

export const ACTION_DETAIL_QUERY = gql`
  query getActionDetail($scheduleId: String!, $actionId: String!) {
    recommendedActionDetail(indexScheduleId: $scheduleId, actionId: $actionId) {
      actionId
      title
      themeId
      markedAsTaken
      score
      description
      riskCategory
    }
  }
`;

export const useActionDetail = ({
  scheduleId,
  actionId,
}: {
  scheduleId: string;
  actionId: string;
}) =>
  useQuery<getActionDetail>(ACTION_DETAIL_QUERY, {
    variables: {
      scheduleId,
      actionId,
    },
    fetchPolicy: 'cache-and-network',
  });
