import { rem } from 'polished';
import React, { ReactNode, useEffect } from 'react';
import { styled } from 'styles';
import { useTimerDispatch } from '../../../../../Shared/Timer';
import HTMLContent from './HTMLContent';

const ButtonContainer = styled('div')`
  margin-top: ${rem(32)};
`;

const IS_TIMER_CAPPED = true;

interface HTMLSegmentProps {
  html?: string | null;
  isExpanded: boolean;
  renderNextStepButton: ReactNode;
  onSegmentStart(): void;
}

function HTMLSegment({
  html,
  isExpanded,
  onSegmentStart,
  renderNextStepButton,
}: HTMLSegmentProps) {
  const { reset, start } = useTimerDispatch();

  useEffect(() => {
    if (isExpanded) {
      reset();
      start(IS_TIMER_CAPPED);
      onSegmentStart();
    } else {
      // TODO: Handle collapsed segment
      // The user cannot collapse the segment if it is not complete
      // If the segment is complete and the user collapses the segment, should it
      // - stop the timer? fire segment completed? fire segment closed?
    }

    return () => {
      // TODO: Handle segment unmount e.g. from navigation
      // Do we need to stop the timer if you navigate away?
    };
  }, [isExpanded, onSegmentStart, reset, start]);

  if (!html) {
    // TODO: Handle missing html in more user friendly way
    return null;
  }

  return (
    <>
      <HTMLContent dangerouslySetInnerHTML={{ __html: html }} />
      <ButtonContainer>{renderNextStepButton}</ButtonContainer>
    </>
  );
}

export default HTMLSegment;
