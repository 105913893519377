import { useQuery } from '@apollo/client';
import {
  TALK_EVENTS_QUERY,
  TALK_EVENT_REQUESTS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import { talkEventRequestsQuery } from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQuery';
import { talkEventsQuery } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { USER_QUERY } from 'Services/User/user.services';
import { userQuery } from 'Services/User/__generated__/userQuery';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { useToday } from './useToday';

interface UseUpcomingBookingsAndRequestsParams {
  limit?: number;
}

/**
 * Retrieves the user's upcoming sessions and / or active booking requests, limited to the `limit` provided.
 */
export function useUpcomingBookingsAndRequests({
  limit = 1,
}: UseUpcomingBookingsAndRequestsParams = {}) {
  const now = useToday();

  const {
    data: userData,
    loading: userQueryLoading,
    error: userQueryError,
  } = useQuery<userQuery>(USER_QUERY);

  const { id: userID } = userData?.user || {};
  const commonVariables = {
    first: limit,
    after: null,
    unmindUserId: userID ?? '',
  };

  const {
    data: events,
    loading: eventsLoading,
    error: eventsError,
  } = useQuery<talkEventsQuery>(TALK_EVENTS_QUERY, {
    variables: {
      ...commonVariables,
      filter: {
        where: {
          endsAt: {
            moreThan: now.toISOString(),
          },
        },
      },
    },
    skip: !userID,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const {
    data: eventRequests,
    loading: eventRequestsLoading,
    error: eventRequestsError,
  } = useQuery<talkEventRequestsQuery>(TALK_EVENT_REQUESTS_QUERY, {
    variables: {
      ...commonVariables,
    },
    skip: !userID,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const bookings = events?.unmindEvents?.edges;
  const requests = eventRequests?.unmindEventRequests?.edges;

  return {
    bookings,
    requests,
    hasBookingOrRequest: !!bookings?.length || !!requests?.length,
    loading: userQueryLoading || eventsLoading || eventRequestsLoading,
    errors: [userQueryError, eventRequestsError, eventsError],
  };
}
