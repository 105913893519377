import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Mood, moods } from '../types';
import { filterCheckInMoodsData } from '../MoodScores/helpers/filterCheckInData';
import {
  CheckInMoods,
  CheckInMoods_checkInMoods,
} from './__generated__/CheckInMoods';
import { MoodTrackerProps } from './MoodTracker';

export const CHECK_IN_MOODS_QUERY = gql`
  query CheckInMoods {
    checkInMoods {
      id
      key
    }
  }
`;

export interface WithCheckInMoodsProps {
  checkInMoods: Mood[];
  moodsLoading: boolean;
  hasMoodsError: boolean;
}

const mapMoodKeysToMoodArray = (
  checkInMoods: CheckInMoods_checkInMoods[],
): Mood[] => checkInMoods.map(mood => mood.key as Mood);

export const withCheckInMoods =
  (WrappedComponent: React.ComponentType<MoodTrackerProps>) =>
  (props: Omit<MoodTrackerProps, 'checkInMoods'>) => {
    let hasMoodsError = false;
    let moodsLoading = false;
    let checkInMoods = moods;

    const { data, error, loading } =
      useQuery<CheckInMoods>(CHECK_IN_MOODS_QUERY);

    if (data?.checkInMoods && data?.checkInMoods.length > 0) {
      checkInMoods = mapMoodKeysToMoodArray(
        filterCheckInMoodsData(data?.checkInMoods),
      );
    }

    if (error) {
      hasMoodsError = true;
    }

    moodsLoading = loading;

    return (
      <WrappedComponent
        {...props}
        checkInMoods={checkInMoods}
        moodsLoading={moodsLoading}
        hasMoodsError={hasMoodsError}
      />
    );
  };
