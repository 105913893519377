import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';

import RoutePath from '../../../App/RoutePath';
import { getBreadcrumbStateInfo } from '../../../App/routeHelpers';

import { shortsContainerQuery_getShortsByContainerSlug } from '../../../Services/Shorts/__generated__/shortsContainerQuery';

import { ScreenReaderContent } from '../../../Shared/Accessibility';
import { getToolMediaTypeIcon } from '../../../Tools/Cards/helpers/toolCardHelpers';
import PlayButton from '../../../Shorts/ShortCardButtons/PlayButton';

import * as S from './styles';

interface Props {
  short: shortsContainerQuery_getShortsByContainerSlug;
  assetToken: string;
}

export const ContentCard = memo(({ short }: Props) => {
  const { t } = useTranslation<Namespace<'shorts'>>('shorts');
  const location = useLocation();
  const breadcrumbState = getBreadcrumbStateInfo(location);

  /**
   * State
   */
  const {
    title,
    slug,
    estimatedMins,
    cloudinaryThumbnail,
    mediaType,
    summary,
  } = short;

  const shortMediaPlayerPath = `${RoutePath.Shorts}/${slug}`;
  const mediaTypeIcon = getToolMediaTypeIcon(mediaType?.value);

  /**
   * Return
   */
  return (
    <S.CardContainer
      to={{
        pathname: shortMediaPlayerPath,
        state: {
          ...breadcrumbState,
          onToolPlayerClose: {
            location: {
              pathname: location.pathname,
            },
          },
        },
      }}
    >
      <S.ImageContainer>
        <S.Image src={cloudinaryThumbnail?.path ?? ''} />
        <S.CardActionContainer>
          <PlayButton />
        </S.CardActionContainer>
      </S.ImageContainer>
      <S.CardContentContainer>
        <div>
          <S.CardTitle>{title}</S.CardTitle>
          <S.CardBody>{summary}</S.CardBody>
        </div>
        <S.CardMetadata>
          {mediaTypeIcon && (
            <>
              <S.CardMediaTypeIcon Icon={mediaTypeIcon} />
              <ScreenReaderContent as="span">
                {mediaType?.value}
              </ScreenReaderContent>
            </>
          )}
          {estimatedMins && (
            <S.DurationText>
              {t('short_card.duration_text', {
                duration: estimatedMins?.toString(),
              })}
            </S.DurationText>
          )}
        </S.CardMetadata>
      </S.CardContentContainer>
    </S.CardContainer>
  );
});
