import { practitionerMatchesQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerMatchesQuery';
import { PRACTITIONER_MATCHES_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import { useQuery } from '@apollo/client';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { useOrganisationEntitlements } from 'Shared/Entitlement';

export function useMatches() {
  const { entitlements, loading: entitlementsLoading } =
    useOrganisationEntitlements([{ entitlement: 'aiEnabled' }]);
  const aiMatchingEnabled = entitlements.aiEnabled;

  const {
    data: practitionerMatchesData,
    loading: practitionerMatchesLoading,
    error: practitionerMatchesError,
    refetch: refetchPractitionerMatches,
  } = useQuery<practitionerMatchesQuery>(PRACTITIONER_MATCHES_QUERY, {
    skip: !aiMatchingEnabled,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const practitionerMatches = (
    practitionerMatchesData?.practitionerMatches ?? []
  ).filter((match): match is NonNullable<typeof match> => match !== null);
  const userHasPractitionerMatches =
    !practitionerMatchesLoading && practitionerMatches.length > 0;

  return {
    userHasPractitionerMatches,
    practitionerMatches,
    practitionerMatchesLoading:
      practitionerMatchesLoading || entitlementsLoading,
    practitionerMatchesError,
    refetchPractitionerMatches,
  };
}
