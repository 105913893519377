import { Calendar } from 'icons';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { LinkProps } from 'react-router-dom';
import { ButtonSize } from '../../Shared/Button';
import PrimaryButton from '../../Shared/PrimaryButton';
import { Highlight } from '../types';
import { SeriesDayCardHighlights } from './SeriesDayCardHighlights';
import { Day, DayWrapper, Title, TitleWrapper } from './SeriesDaySlider';
import {
  EventSource,
  TrackEventDayOptions,
} from './SeriesHelpers/makeTrackSeriesEvent';

const Wrapper = styled.div`
  width: 100%;
  border-radius: ${rem(4)};
  overflow: hidden;
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
`;

const CallToAction = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: ${rem(18)} ${rem(28)} ${rem(28)};
`;

const CallToActionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(14)};
`;

const CalendarIcon = styled<any>(Calendar).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.primary,
  }),
)`
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: 8px;
  margin-top: 6px;
`;

const CallToActionButton = styled(PrimaryButton)``;

export interface SeriesDayCardProps {
  seriesStarted: boolean;
  dayNumber: number;
  dayId: string;
  dayTitle: string;
  linkTo: LinkProps['to'];
  dayHighlights: Highlight[];
  dayStarted: boolean;
  dayComplete: boolean;
  trackSeriesEvents(
    source: EventSource,
    dayOptions: TrackEventDayOptions,
  ): void;
}

const SeriesDayCard = (props: SeriesDayCardProps) => {
  const { t: translate } = useTranslation('courses');
  const {
    seriesStarted,
    dayNumber,
    dayId,
    dayTitle,
    linkTo,
    dayHighlights,
    dayStarted,
    dayComplete,
    trackSeriesEvents,
  } = props;

  return (
    <Wrapper>
      <CallToAction>
        <CallToActionHeading>
          <TitleWrapper>
            <CalendarIcon />
            <DayWrapper>
              <Day>
                {translate('course_day.session_number', {
                  session_number: dayNumber,
                })}
              </Day>
              <Title>{dayTitle}</Title>
            </DayWrapper>
          </TitleWrapper>
        </CallToActionHeading>
        <CallToActionButton
          to={linkTo}
          label={
            seriesStarted
              ? translate('course_day.cta_button.continue_variant.text')
              : translate('course_day.cta_button.begin_variant.text')
          }
          size={ButtonSize.medium}
          data-testid={`${seriesStarted ? 'continue' : 'begin'}-series-button`}
          onClick={() => {
            trackSeriesEvents('series-day-card', {
              dayNumber,
              dayStarted,
              dayComplete,
              dayId,
            });
          }}
        />
      </CallToAction>
      <SeriesDayCardHighlights
        sliderView={false}
        dayHighlights={dayHighlights}
      />
    </Wrapper>
  );
};

export default SeriesDayCard;
