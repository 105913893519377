import { css, styled, ThemeInterface } from 'styles';
import { rem } from 'polished';
import {
  BodyText,
  FeatureText,
  HeadingText,
} from '@unmind/design-system-components-web';
import { Link } from 'react-router-dom';
import { small } from '../../../utils';

import { Arrow, Talk } from '../../../icons';

export const CardWrapper = styled.div<{ defocused: boolean }>`
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid;
  background-color: ${({ defocused, theme }) =>
    defocused
      ? theme.colors.background.secondary
      : theme.colors.background.card};
  border-color: ${({ theme }) => theme.colors.border.secondary};
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;

  ${small(css`
    flex-wrap: nowrap;
  `)}
`;

export const CardContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const CardHeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CardHeading = styled(FeatureText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 2,
    sizes: [theme.typography.fontSizes.fontSize24],
    weight: theme.typography.fontWeights.medium,
  }),
)`
  line-height: 1.4;

  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 8px;

  ${small(css`
    margin-top: 20px;
  `)}
`;

export const CardBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(4)};
`;

export const MeetingText = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    color: theme.colors.text.link,
    forwardedAs: 'a',
    sizes: [theme.typography.fontSizes.fontSize16],
    weight: theme.typography.fontWeights.regular,
  }),
)`
  display: flex;
  justify-content: center;
`;

export const MeetingHeaderText = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    color: theme.colors.text.primary,
    level: 1,
    sizes: [theme.typography.fontSizes.fontSize18],
    weight: theme.typography.fontWeights.semiBold,
  }),
)``;

export const ImageWrapper = styled.div`
  height: ${rem(44)};
  width: ${rem(44)};
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 3px 6px -2px ${({ theme }) => theme.colors.boxShadow},
    0px 6px 12px -3px ${({ theme }) => theme.colors.boxShadow};
  margin-right: ${rem(12)};
  flex-shrink: 0;
`;

export const Image = styled.img.attrs({
  alt: '',
})`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: ${rem(44)};
`;

export const ChatIconContainer = styled.div`
  width: 14px;
  height: 14px;
  margin-right: ${rem(8)};
`;

export const ChatIcon = styled(Talk).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    width: 14,
    height: 14,
  }),
)``;

export const MetaActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Anchor = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.link,
  weight: theme.typography.fontWeights.regular,
}))`
  display: inline;
`;

export const BrowseLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize14)};
`;

export const BrowseLinkArrow = styled(Arrow).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  width: 16,
  height: 9,
}))`
  margin-left: 8px;
`;

export const UpcomingEventWrapper = styled.div`
  width: 100%;
`;

export const SessionInfo = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  color: theme.colors.text.secondary,
  weight: theme.typography.fontWeights.regular,
}))`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  width: 100%;

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      ${small(css`
        width: calc(50% - 1rem);
      `)}
    `}}

`;

export const EditWrapper = styled.div`
  margin-left: auto;
`;

export const HelpCentreLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 50%;
  > a {
    margin-left: ${rem(5)};
    font-size: ${({ theme }) => rem(theme.typography.fontSizes.fontSize16)};
  }
`;
