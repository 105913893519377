import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * @deprecated: Does not match the GraphQL schema
 */
export interface DeprecatedCheckin {
  __typename?: 'CheckIn';
  id?: number;
  dateCreated: string;
  feelings: Feeling[];
  moodId: number;
  note?: string;
}

export type CheckIn = {
  __typename?: 'CheckIn';
  id: number;
  createdAt?: Maybe<string>;
  dateCreated?: Maybe<string>;
  feelings?: Maybe<Feeling[]>;
  mood?: Maybe<MoodType>;
  moodId?: Maybe<number>;
  note?: Maybe<string>;
};

export type Feeling = {
  __typename?: 'Feeling';
  id: number;
  name: string;
};

export type CheckInMood = {
  __typename?: 'CheckInMood';
  id: number;
  key: MoodType;
  title?: string;
};

export const Mood = {
  Saddest: 'VERY_SAD',
  Sadder: 'SAD',
  Sad: 'SLIGHTLY_SAD',
  Neutral: 'OK',
  Happy: 'SLIGHTLY_HAPPY',
  Happier: 'HAPPY',
  Happiest: 'VERY_HAPPY',
} as const;

export type MoodType = typeof Mood[keyof typeof Mood];

export const moods = Object.values(Mood);
