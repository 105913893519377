import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { TimerDispatch } from '../Timer';
import {
  Status,
  MediaToggleMethod,
  MediaTrackingEvent,
} from '../MediaPlayerComponents';
import { logException } from '../../App/logging';
import { isIOS } from '../MediaPlayerComponents/isIOS';
import { keyboardHandlers } from '../MediaPlayerComponents/keyboardHandlers';
import { MediaVolumeChangeMethod } from '../MediaPlayerComponents/mediaVolumeChangeMethod';
import { tracking } from '../../App/Tracking';
import AudioPlayerUI from './AudioPlayerUI';

export interface AudioPlayerProps {
  backButtonLabel: string;
  hasTranscript?: boolean;
  onTranscriptClick({
    totalLength,
    position,
  }: {
    totalLength: number;
    position: number;
  }): void;
  onVolumeChange?({
    playerRef,
    method,
    isVolumeMuted,
    sound,
  }: {
    playerRef: React.Ref<HTMLAudioElement | null>;
    method: string;
    isVolumeMuted: boolean;
    sound: number;
  }): void;
  mediaSrc: string;
  timerDispatch: TimerDispatch;
  onClickLoop?({
    loopEnabled,
    method,
    playerRef,
  }: {
    loopEnabled: boolean;
    method: string;
    playerRef: React.RefObject<HTMLAudioElement>;
  }): void;
  onClickPlay?(): void;
  onClickReplay?(): void;
  onClickPause?(): void;
  onResume?(
    playerRef: React.Ref<HTMLAudioElement | null>,
    method: MediaToggleMethod,
  ): void;
  onPause?(
    playerRef: React.Ref<HTMLAudioElement | null>,
    method: MediaToggleMethod,
  ): void;
  onSeek?({
    playerRef,
    method,
    startPosition,
  }: {
    playerRef: React.Ref<HTMLMediaElement | null>;
    method: string;
    startPosition: number;
  }): void;
  onClose({
    playerRef,
    method,
  }: {
    playerRef: React.Ref<HTMLMediaElement | null>;
    method: string;
  }): void;
  onEnded?(mediaEvent: React.SyntheticEvent<HTMLMediaElement>): void;
  onError?(event: React.SyntheticEvent<HTMLAudioElement>): void;
  title: string;
  summary?: string;
  renderNextStepButton?: ReactNode;
  onLoading?(event: React.SyntheticEvent<HTMLAudioElement>): void;
  onLoaded?(event: React.SyntheticEvent<HTMLAudioElement>): void;
  onMediaHasLooped?(): void;
  isTranscriptShown: boolean;
  feature: string;
  mediaSessionId: string;
  slug: string;
  showCalendarIntegration?: boolean;
  referrer: string | null;
}

export const AUDIO_PLAYER_TEST_ID = 'audio-player';
const VOLUME_SLIDER_CLASS = 'unmind-media-volume-handle';
const AUDIO_JUMP_PERIOD_SECONDS = 10;
const DEFAULT_VOLUME = 50;

const trackMediaEvent = ({
  eventName,
  feature,
  duration,
  slug,
  mediaSessionId,
  extraTrackingProps,
  referrer,
}: {
  eventName: MediaTrackingEvent;
  feature: string;
  duration: number;
  slug: string;
  mediaSessionId: string;
  extraTrackingProps?: Record<string, unknown>;
  showCalendarIntegration?: boolean;
  referrer: string | null;
}) => {
  tracking.track(eventName, {
    feature,
    medium: 'audio',
    totalLength: duration,
    contentSlug: slug,
    mediaSessionId,
    referrer,
    ...extraTrackingProps,
  });
};

function AudioPlayer({
  backButtonLabel,
  onClose,
  onClickLoop,
  onClickPause,
  onClickPlay,
  onResume,
  onPause: onPauseTrack,
  onClickReplay,
  onSeek,
  onEnded,
  onError,
  hasTranscript,
  onTranscriptClick,
  onVolumeChange,
  mediaSrc,
  renderNextStepButton,
  timerDispatch,
  title,
  summary,
  onLoading,
  onLoaded,
  feature,
  mediaSessionId,
  slug,
  isTranscriptShown,
  showCalendarIntegration = true,
  referrer,
}: AudioPlayerProps) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [volume, setVolume] = useState(DEFAULT_VOLUME);
  const [previousVolume, setPreviousVolume] = useState(DEFAULT_VOLUME);

  const [status, setStatus] = useState<Status>('loading');

  const playerRef = React.useRef<HTMLAudioElement>(null);

  const [hasPausedBefore, setHasPausedBefore] = useState(false);
  const [statusBeforeLoading, setStatusBeforeLoading] = useState<
    'playing' | 'paused'
  >('paused');

  const [isDragging, setIsDragging] = useState(false);
  const [startDragTime, setStartDragTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [startSeekTime, setStartSeekTime] = useState(0);

  const [loop, setLoop] = useState(false);

  const [preventEscapeKeyCloseEvent, setPreventEscapeKeyCloseEvent] =
    useState(false);

  const progressHandler = document.querySelector(
    '.unmind-media-progress-handle',
  );

  const playMedia = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      const playPromise = player.play();

      // IE11 doesn't implement play() with a promise
      if (playPromise !== undefined) {
        playPromise.catch(logException);
      }
    }
  }, [playerRef]);

  const handleSetProgress = useCallback((progress: number) => {
    setCurrentTime(progress);

    const player = playerRef.current;
    if (player) {
      player.currentTime = progress;
    }
  }, []);

  const handleSeek = useCallback(
    ({ startPosition, method }: { startPosition: number; method: string }) => {
      if (playerRef && onSeek) {
        onSeek({ playerRef, method, startPosition });
      }
    },
    [onSeek],
  );

  const togglePlay = useCallback(
    (method: MediaToggleMethod) => {
      const player = playerRef.current;
      if (player) {
        if (method === 'add-to-calendar-button-clicked') {
          player.pause();
          setHasPausedBefore(true);
        }
        if (player.paused) {
          playMedia();

          if (!hasPausedBefore) {
            trackMediaEvent({
              eventName: 'media-playback-started',
              feature,
              duration,
              slug,
              mediaSessionId,
              extraTrackingProps: {
                mediaLooping: false,
                mediaReplay: false,
              },
              referrer,
            });
          }

          if (onResume && hasPausedBefore && playerRef) {
            onResume(playerRef, method);
          }
        } else {
          player.pause();
          setHasPausedBefore(true);
          if (onPauseTrack && playerRef) {
            onPauseTrack(playerRef, method);
          }
        }
      }
    },
    [
      duration,
      feature,
      hasPausedBefore,
      mediaSessionId,
      onPauseTrack,
      onResume,
      playMedia,
      slug,
      referrer,
    ],
  );

  const handleVolumeChange = (
    newVolume: number,
    method?: MediaVolumeChangeMethod,
  ) => {
    const player = playerRef.current;
    if (player) {
      // We want our volume scale to be 0-100 but the
      // HTMLAudioElement has a max of 1
      player.volume = newVolume / 100.0;
    }
    if (onVolumeChange && method) {
      onVolumeChange({
        playerRef,
        method,
        isVolumeMuted: newVolume === 0,
        sound: newVolume,
      });
    }
  };

  const toggleMute = (method: MediaVolumeChangeMethod) => {
    if (volume === 0) {
      handleVolumeChange(previousVolume, method);
    } else {
      setPreviousVolume(volume);
      handleVolumeChange(0, method);
    }
  };

  const handleVolumeChangeViaSlider = (newVolume: number) => {
    handleVolumeChange(newVolume, 'slider');
  };

  const toggleMuteViaButton = () => {
    toggleMute('toggle-mute-button-clicked');
  };

  const handleSpacePress = useCallback(
    (key: KeyboardEvent) => {
      if (
        keyboardHandlers.isSpace(key) &&
        !(key.target instanceof HTMLButtonElement) &&
        !isTranscriptShown
      ) {
        if (status !== 'paused') {
          setHasPausedBefore(true);
        }
        togglePlay('spacebar-pressed');
      }
    },
    [status, isTranscriptShown, togglePlay],
  );

  const handleMKeyDown = (key: KeyboardEvent) => {
    if (keyboardHandlers.isMKey(key)) {
      toggleMute('m-key-pressed');
    }
  };

  const handleMouseDragDown = useCallback(
    (e: MouseEvent) => {
      if (e.target === progressHandler) {
        setIsDragging(true);
      }
    },
    [progressHandler],
  );

  const handleMouseUpFromDrag = useCallback(() => {
    if (isDragging) {
      handleSeek({
        method: 'progress-handle-drag',
        startPosition: startDragTime,
      });
      setIsDragging(false);
    }
  }, [handleSeek, isDragging, startDragTime]);

  const handleArrowDown = useCallback(
    (key: KeyboardEvent) => {
      if (keyboardHandlers.isArrows(key)) {
        if (document.activeElement?.className === VOLUME_SLIDER_CLASS) {
          return;
        }
        if (!isSeeking) {
          setStartSeekTime(currentTime);
          setIsSeeking(true);
        }

        if (keyboardHandlers.isArrowRight(key)) {
          if (currentTime < duration - AUDIO_JUMP_PERIOD_SECONDS) {
            handleSetProgress(currentTime + AUDIO_JUMP_PERIOD_SECONDS);
          } else {
            handleSetProgress(duration);
          }
        } else {
          if (currentTime > AUDIO_JUMP_PERIOD_SECONDS) {
            handleSetProgress(currentTime - AUDIO_JUMP_PERIOD_SECONDS);
          } else {
            handleSetProgress(0);
          }
        }
      }
    },
    [currentTime, duration, handleSetProgress, isSeeking],
  );

  const handleArrowUp = useCallback(
    (key: KeyboardEvent) => {
      if (isSeeking) {
        // IE11 listens for 'Right'
        if (keyboardHandlers.isArrowRight(key)) {
          handleSeek({
            method: 'right-arrow-key',
            startPosition: startSeekTime,
          });
          setIsSeeking(false);
          // IE11 listens for 'Left'
        } else if (keyboardHandlers.isArrowLeft(key)) {
          handleSeek({
            method: 'left-arrow-key',
            startPosition: startSeekTime,
          });
          setIsSeeking(false);
        }
      }
    },
    [handleSeek, isSeeking, startSeekTime],
  );

  const onPlay = () => {
    timerDispatch.start(false);
    setStatus('playing');

    if (!hasPausedBefore) {
      trackMediaEvent({
        eventName: 'media-playback-started',
        feature,
        duration,
        slug,
        mediaSessionId,
        extraTrackingProps: {
          mediaLooping: false,
          mediaReplay: false,
        },
        referrer,
      });
    }
  };

  const onPause = () => {
    timerDispatch.stop();
    // audio is paused on error
    // persist error state so we can display error overlay
    if (status !== 'error') {
      setStatus('paused');
      setStatusBeforeLoading('paused');
    }
  };

  const handleEnded = (event: React.SyntheticEvent<HTMLAudioElement>) => {
    onEnded?.(event);

    if (loop) {
      handleSetProgress(0);
      playMedia();
      trackMediaEvent({
        eventName: 'media-playback-started',
        feature,
        duration,
        slug,
        mediaSessionId,
        extraTrackingProps: {
          mediaLooping: true,
          mediaReplay: false,
        },
        referrer,
      });
    } else {
      timerDispatch.stop();
      setStatus('ended');
      setStatusBeforeLoading('paused');
    }
  };

  const handleError = (event: React.SyntheticEvent<HTMLAudioElement>) => {
    if (onError) {
      onError(event);
    }

    timerDispatch.stop();
    setStatus('error');
  };

  const replayAudio = () => {
    onClickReplay?.();
    handleSetProgress(0);
    playMedia();
    trackMediaEvent({
      eventName: 'media-playback-started',
      feature,
      duration,
      slug,
      mediaSessionId,
      extraTrackingProps: {
        mediaLooping: false,
        mediaReplay: true,
      },
      referrer,
    });
  };

  const handleClickPlay = () => {
    if (onClickPlay) {
      onClickPlay();
      if (onResume && hasPausedBefore && playerRef) {
        onResume(playerRef, 'play-button-clicked');
      }
    }
    playMedia();
  };

  const handleClickPause = () => {
    if (onClickPause) {
      onClickPause();
    }
    togglePlay('pause-button-clicked');
  };

  const handleDrag = ({
    startDraggingValue,
  }: {
    startDraggingValue?: number;
  }) => {
    if (startDraggingValue || startDraggingValue === 0) {
      setIsDragging(true);
      setStartDragTime(startDraggingValue);
    } else {
      setIsDragging(false);
    }
  };

  const handleClickJumpBack = useCallback(
    ({ method }: { method: string }) => {
      const startTime = currentTime;

      if (currentTime > AUDIO_JUMP_PERIOD_SECONDS) {
        handleSetProgress(currentTime - AUDIO_JUMP_PERIOD_SECONDS);
      } else {
        handleSetProgress(0);
      }

      handleSeek({ method, startPosition: startTime });
    },
    [currentTime, handleSetProgress, handleSeek],
  );

  const handleClickJumpForward = useCallback(
    ({ method }: { method: string }) => {
      const startTime = currentTime;

      if (currentTime < duration - AUDIO_JUMP_PERIOD_SECONDS) {
        handleSetProgress(currentTime + AUDIO_JUMP_PERIOD_SECONDS);
      } else {
        handleSetProgress(duration);
      }

      handleSeek({ method, startPosition: startTime });
    },
    [currentTime, duration, handleSetProgress, handleSeek],
  );

  const handleClickLoop = (event: React.MouseEvent | KeyboardEvent) => {
    const method =
      event instanceof KeyboardEvent ? 'r-key-pressed' : 'loop-button-clicked';

    setLoop(prevLoopState => {
      const nextLoopState = !prevLoopState;
      onClickLoop?.({ playerRef, loopEnabled: nextLoopState, method });

      return nextLoopState;
    });
  };

  const handleRKeyDown = (event: KeyboardEvent) => {
    if (keyboardHandlers.isRKey(event)) {
      handleClickLoop(event);
    }
  };

  const handleLoading = (event: React.SyntheticEvent<HTMLAudioElement>) => {
    setStatus(currentStatus => {
      if (currentStatus === 'playing' || currentStatus === 'paused') {
        setStatusBeforeLoading(currentStatus);
      }

      return 'loading';
    });
    if (onLoading) {
      onLoading(event);
    }
  };

  const handleLoaded = (event: React.SyntheticEvent<HTMLAudioElement>) => {
    if (onLoaded && status === 'loading') {
      onLoaded(event);
    }
    setStatus(statusBeforeLoading);
  };

  const handleLoadedMetadata = (
    event: React.SyntheticEvent<HTMLAudioElement>,
  ) => {
    setDuration(event.currentTarget.duration);

    if (onLoading && !isIOS()) {
      onLoading(event);
    }
    // We use 'onloadedmetadata' (instead of oncanplay) to change the initial 'loading' state in iOS
    if (isIOS()) {
      setStatus(statusBeforeLoading);
    }
  };

  const handleClose = useCallback(
    (event: BeforeUnloadEvent | React.KeyboardEvent | React.MouseEvent) => {
      if (event instanceof BeforeUnloadEvent) {
        onClose({ playerRef, method: 'tab-closed' });
      } else if (event instanceof KeyboardEvent) {
        if (
          keyboardHandlers.isEscape(event) &&
          !isTranscriptShown &&
          !preventEscapeKeyCloseEvent
        ) {
          onClose({ playerRef, method: 'escape-key-pressed' });
        }
      } else if (event.nativeEvent instanceof MouseEvent) {
        onClose({ playerRef, method: 'back-button-clicked' });
      }
    },
    [onClose, isTranscriptShown, preventEscapeKeyCloseEvent],
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    handleSpacePress(event);
    handleMKeyDown(event);
    handleArrowDown(event);
    handleClose(event);
    handleRKeyDown(event);
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDragDown);
    window.addEventListener('mouseup', handleMouseUpFromDrag);
    document.addEventListener('keyup', handleArrowUp);
    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', handleClose);

    return () => {
      window.removeEventListener('mousedown', handleMouseDragDown);
      window.removeEventListener('mouseup', handleMouseUpFromDrag);
      document.removeEventListener('keyup', handleArrowUp);
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', handleClose);
    };
  });

  useEffect(() => {
    handleVolumeChange(DEFAULT_VOLUME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    /* eslint-disable jsx-a11y/media-has-caption */
    <>
      <AudioPlayerUI
        status={status}
        backButtonLabel={backButtonLabel}
        duration={duration}
        volume={volume}
        hasTranscript={hasTranscript}
        loop={loop}
        onProgressChange={handleSetProgress}
        onClickAudioPlayer={() => togglePlay('clickable-area-clicked')}
        onSeek={handleSeek}
        onClickBackButton={handleClose}
        onClickPause={handleClickPause}
        onClickPlay={handleClickPlay}
        onClickJumpForward={handleClickJumpForward}
        onClickJumpBack={handleClickJumpBack}
        onClickLoop={handleClickLoop}
        onVolumeChange={debounce(handleVolumeChangeViaSlider, 50)}
        onClickToggleMute={toggleMuteViaButton}
        onClickReplay={replayAudio}
        onClickTranscript={() => {
          const player = playerRef.current;
          if (player) {
            player.pause();
          }

          onTranscriptClick({ position: currentTime, totalLength: duration });
        }}
        currentTime={currentTime}
        renderNextStepButton={renderNextStepButton}
        title={title}
        summary={summary}
        handleDrag={handleDrag}
        handleCalendarModalToggle={() =>
          setPreventEscapeKeyCloseEvent(!preventEscapeKeyCloseEvent)
        }
        showCalendarIntegration={showCalendarIntegration}
      />
      <audio
        data-testid={AUDIO_PLAYER_TEST_ID}
        ref={playerRef}
        controls={false}
        src={mediaSrc}
        autoPlay={false}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={handleEnded}
        onError={handleError}
        onVolumeChange={({ currentTarget }) => {
          setVolume(currentTarget.volume * 100);
        }}
        onTimeUpdate={({ currentTarget }) => {
          setCurrentTime(currentTarget.currentTime);
        }}
        onLoadedMetadata={handleLoadedMetadata}
        onWaiting={handleLoading}
        onCanPlay={handleLoaded}
      />
    </>
  );
}

export default AudioPlayer;
