import React from 'react';
import { Namespace } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { CompassFurtherInfoPage } from './CompassFurtherInfoPage';
import * as S from './styles';

export const BenchmarkInfoPage = () => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');

  return (
    <CompassFurtherInfoPage title={translate('benchmarking_info_page.title')}>
      <S.InfoPanel>
        <S.Paragraph>
          <Trans t={translate} i18nKey={'benchmarking_info_page.para_1'} />
        </S.Paragraph>
        <S.Paragraph>{translate('benchmarking_info_page.para_2')}</S.Paragraph>
        <S.HeadingThree>
          {translate('benchmarking_info_page.risk_factors.title')}
        </S.HeadingThree>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'benchmarking_info_page.risk_factors.burnout'}
          />
        </S.Paragraph>
        <S.UnorderedList>
          <li>
            <Trans
              t={translate}
              i18nKey={'benchmarking_info_page.risk_factors.burnout_benchmark'}
            />
          </li>
        </S.UnorderedList>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'benchmarking_info_page.risk_factors.attrition'}
          />
        </S.Paragraph>
        <S.UnorderedList>
          <li>
            <Trans
              t={translate}
              i18nKey={
                'benchmarking_info_page.risk_factors.attrition_benchmark'
              }
            />
          </li>
        </S.UnorderedList>
        <S.Paragraph>
          <Trans
            t={translate}
            i18nKey={'benchmarking_info_page.risk_factors.lost_productivity'}
          />
        </S.Paragraph>
        <S.UnorderedList>
          <li>
            <Trans
              t={translate}
              i18nKey={
                'benchmarking_info_page.risk_factors.lost_productivity_benchmark'
              }
            />
          </li>
        </S.UnorderedList>
      </S.InfoPanel>
    </CompassFurtherInfoPage>
  );
};
