import React from 'react';
import { IconProps } from '../IconProps';

const SvgPlusOne = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 370 426" fill="none" {...props}>
    <path
      d="M232.976 295.097c39.588 0 71.681-32.092 71.681-71.681 0-39.588-32.093-71.681-71.681-71.681-39.588 0-71.681 32.093-71.681 71.681 0 39.589 32.093 71.681 71.681 71.681z"
      fill="#D4D1FE"
    />
    <path
      d="M314.128 32.005L119.959 0 99.073 126.713l194.169 32.005 20.886-126.713z"
      fill="#9992F9"
    />
    <path
      d="M314.124 32.012L119.955.007l-1.478 8.965 194.169 32.006 1.478-8.966z"
      fill="#D4D1FE"
    />
    <path
      d="M212.316 69.11c10.517 0 19.043-8.526 19.043-19.043s-8.526-19.043-19.043-19.043-19.043 8.526-19.043 19.043 8.526 19.043 19.043 19.043z"
      fill="#FFB4B9"
    />
    <path
      d="M142.896 133.943l81.733 13.475c-3.231-23.423-15.603-41.433-33.24-44.804-18.181-3.471-36.921 9.576-48.493 31.329z"
      fill="#FF7B84"
    />
    <path
      d="M149.604 63.115l-7.464-42.62 8.52-5.756-28.591-4.6a3.367 3.367 0 0 0-3.899 3.325l3.102 1.407 5.817 59.894a11.16 11.16 0 0 0 10.78 10.073l33.333.978 5.335-23.966-26.933 1.265z"
      fill="#FFB4B9"
    />
    <path
      d="M149.605 63.114h-10.283"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M225.177 77.47l-40.258-14.78c-8.701-3.194-18.345 1.27-21.539 9.97l-.003.008c-3.194 8.701 1.27 18.344 9.971 21.539l40.258 14.78c8.701 3.195 18.344-1.269 21.538-9.97l.003-.008c3.195-8.7-1.269-18.344-9.97-21.539z"
      fill="#6C61F7"
    />
    <path
      d="M214.904 96.641l14.39 48.951-11.556 47.49 8.271 14.461 6.252 6.438 9.987-5.894-3.323-14.135 11.983-45.114c.753-2.842.846-5.825.256-8.714l-10.135-49.968c-1.537-7.587-9.141-12.317-16.627-10.352-7.347 1.934-11.642 9.545-9.498 16.83v.007z"
      fill="#FFB4B9"
    />
    <path
      d="M182.199 140.413l.847-10.725M176.539 132.373l6.081 3.526"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M226.413 46.83l-22.522 1.592s-1.6-2.703-4.132-.792c-2.539 1.91 2.431 7.145 2.431 7.145l-2.912 18.142-12.745-6.454s-.303-37.084 25.792-36.284c27.811.846 20.34 33.216 17.901 46.333l-22.988-8.092c11.16 2.873 22.25-4.877 23.843-15.098l-4.668-6.492z"
      fill="#fff"
    />
    <path
      d="M216.303 56.623s3.448.319 4.186 2.221l-4.186-2.221z"
      fill="#FFB4B9"
    />
    <path
      d="M216.303 56.623s3.448.319 4.186 2.221"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M228.371 60.964c-1.849-.956-3.751 0-3.751 0l-1.973 6.508-2.858-1.375"
      fill="#FFB4B9"
    />
    <path
      d="M228.371 60.964c-1.849-.956-3.751 0-3.751 0l-1.973 6.508-2.858-1.375"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M215.091 65.195s-.637 1.01.737 2.276l-.737-2.276z"
      fill="#FFB4B9"
    />
    <path
      d="M215.091 65.195s-.637 1.01.737 2.276"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M202.322 59.023a2.4 2.4 0 1 0 0-4.798 2.4 2.4 0 0 0 0 4.798z"
      fill="#FFD438"
    />
    <path
      d="M144.463 17.271l9.273 1.693a3.23 3.23 0 0 0-2.602-4.155l-.474-.07-6.197.909v1.623z"
      fill="#FFB4B9"
    />
    <path
      d="M135.259 11.732s-3.898.598-2.337 7.223"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.145 369.998L78 391.596l21.598 33.144 33.144-21.597-21.597-33.145z"
      fill="#2031BD"
    />
    <path
      d="M369 424.914H1"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M329.75 296.141H132.961v128.422H329.75V296.141z" fill="#FF7B84" />
    <path d="M329.75 296.141H132.961v10.997H329.75v-10.997z" fill="#FFC1C6" />
    <path
      d="M159.374 335.368l-7.781 39.895-18.624-18.281v-15.991l-11.02 13.288a9.824 9.824 0 0 0-1.251 10.624l5.934 12.053 19.944 24.029c6.787 8.178 19.99 5.258 22.685-5.025l13.226-50.418-23.113-10.166v-.008z"
      fill="#6C61F7"
    />
    <path
      d="M132.961 356.983l-3.518-4"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M210.427 308.177l-40.314 14.626c-8.714 3.161-13.215 12.787-10.054 21.5l.003.007c3.161 8.714 12.787 13.215 21.5 10.054l40.315-14.626c8.713-3.161 13.214-12.787 10.053-21.5l-.003-.007c-3.161-8.714-12.787-13.215-21.5-10.054z"
      fill="#2031BD"
    />
    <path
      d="M239.189 195.002l-4.675-6.446a6.312 6.312 0 0 1 .629-8.155l9.964 10.982a6.332 6.332 0 0 1 1.414 5.918l-2.051 7.44 6.602 54.006a22.314 22.314 0 0 1-.435 7.829l-11.618 48.857c-2.059 8.667-11.728 13.071-19.618 8.939-6.283-3.293-8.993-10.834-6.26-17.373l18.22-43.452-7.898-60.468 2.555 4.465 6.252 6.439 9.987-5.895-3.068-13.094v.008z"
      fill="#6C61F7"
    />
    <path
      d="M216.711 346.139c-26.568 3.091-44.446 33.535-39.926 68.009a81.677 81.677 0 0 0 2.066 10.423h94.065c.986-6.858 1.064-14.143.085-21.614-4.52-34.467-29.714-59.901-56.282-56.81l-.008-.008z"
      fill="#fff"
    />
    <path
      d="M224.89 424.565l-4.939-26.39M231.826 393.46s-6.306 5.926-23.361 5.498"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M218.997 194.521l5.063-.552a4.895 4.895 0 0 1 4.955 2.773l.287.598a3.99 3.99 0 0 1 4.225 2.741l1.088 3.363-2.229.241 1.297 3.968-7.883 3.278a3.512 3.512 0 0 1-4.606-1.926l-4.38-10.85c-.66-1.631.427-3.448 2.175-3.634h.008z"
      fill="#6C61F7"
    />
    <path
      d="M156.288 301.825l8.753-.831c1.833-5.887.225-9.801-1.111-11.797l-8.76.831 1.118 11.797z"
      fill="#FFD438"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140.771 279.436a1.723 1.723 0 0 0 2.501-.248c.738-.971 2.221-.878 2.842.163a1.72 1.72 0 0 0 2.455.535c1.001-.691 2.384-.147 2.656 1.033a1.715 1.715 0 0 0 2.166 1.266 1.722 1.722 0 0 1 2.206 1.81 1.719 1.719 0 0 0 1.67 1.879 1.72 1.72 0 0 1 1.538 2.4 1.712 1.712 0 0 0 1.009 2.299 1.72 1.72 0 0 1 .722 2.757 1.724 1.724 0 0 0 .249 2.501c.971.737.877 2.221-.163 2.842a1.72 1.72 0 0 0-.536 2.454 1.724 1.724 0 0 1-1.033 2.656 1.717 1.717 0 0 0-1.266 2.167c.35 1.165-.59 2.314-1.809 2.206a1.718 1.718 0 0 0-1.88 1.669 1.72 1.72 0 0 1-2.4 1.538 1.712 1.712 0 0 0-2.298 1.01 1.721 1.721 0 0 1-2.757.722 1.723 1.723 0 0 0-2.501.249c-.738.97-2.221.877-2.843-.164a1.72 1.72 0 0 0-2.454-.535 1.725 1.725 0 0 1-2.656-1.033 1.715 1.715 0 0 0-2.167-1.266 1.721 1.721 0 0 1-2.205-1.81 1.719 1.719 0 0 0-1.67-1.879 1.72 1.72 0 0 1-1.538-2.4 1.713 1.713 0 0 0-1.009-2.299 1.722 1.722 0 0 1-.723-2.757 1.723 1.723 0 0 0-.248-2.501c-.971-.737-.878-2.221.163-2.842a1.72 1.72 0 0 0 .536-2.454 1.724 1.724 0 0 1 1.033-2.656 1.717 1.717 0 0 0 1.266-2.167c-.35-1.165.59-2.314 1.809-2.206a1.719 1.719 0 0 0 1.88-1.669 1.719 1.719 0 0 1 2.399-1.538 1.713 1.713 0 0 0 2.299-1.01 1.721 1.721 0 0 1 2.757-.722z"
      fill="#1B1A29"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M197.667 281.339l.264-.901a2.003 2.003 0 0 0-1.926-2.571h-2.058a19.611 19.611 0 0 0-12.938-3.642c-9.599.699-17.405 8.449-18.15 18.041-.948 12.115 9.094 22.149 21.217 21.194 9.452-.746 17.14-8.341 17.995-17.785.489-5.46-1.243-10.508-4.404-14.336z"
      fill="#6C61F7"
    />
    <path
      d="M199.003 293.843c-2.33 2.702-5.397 1.631-6.089-.691-.699-2.33 4.295-3.068 3.091-3.953-1.203-.886-6.205-3.029-4.722-7.363 1.461-4.271-1.359-6.182-1.452-6.236a19.616 19.616 0 0 0-7.354-1.429c-10.865 0-19.68 8.807-19.68 19.68 0 10.872 8.807 19.679 19.68 19.679 8.007 0 14.887-4.784 17.963-11.641.272-4.497-1.437-8.031-1.437-8.031v-.015z"
      fill="#1B1A29"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M175.41 291.893a7.626 7.626 0 1 0-.001-15.253 7.626 7.626 0 0 0 .001 15.253z"
      fill="#FFD438"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M171.751 254.747a1.73 1.73 0 0 0 2.485.397c.963-.746 2.376-.288 2.71.877a1.721 1.721 0 0 0 2.237 1.142 1.724 1.724 0 0 1 2.307 1.677 1.727 1.727 0 0 0 1.778 1.779 1.725 1.725 0 0 1 1.678 2.306 1.714 1.714 0 0 0 1.141 2.237 1.715 1.715 0 0 1 .878 2.71 1.719 1.719 0 0 0 .396 2.486 1.72 1.72 0 0 1 0 2.85 1.73 1.73 0 0 0-.396 2.485c.745.963.287 2.376-.878 2.71a1.72 1.72 0 0 0-1.141 2.237 1.725 1.725 0 0 1-1.678 2.307 1.726 1.726 0 0 0-1.778 1.778 1.725 1.725 0 0 1-2.307 1.678 1.714 1.714 0 0 0-2.237 1.141 1.715 1.715 0 0 1-2.71.878 1.717 1.717 0 0 0-2.485.396 1.72 1.72 0 0 1-2.85 0 1.731 1.731 0 0 0-2.486-.396c-.963.745-2.376.287-2.71-.878a1.72 1.72 0 0 0-2.237-1.141 1.725 1.725 0 0 1-2.306-1.678 1.727 1.727 0 0 0-1.779-1.778 1.724 1.724 0 0 1-1.677-2.307 1.716 1.716 0 0 0-1.142-2.237 1.715 1.715 0 0 1-.878-2.71 1.717 1.717 0 0 0-.396-2.485 1.72 1.72 0 0 1 0-2.85 1.73 1.73 0 0 0 .396-2.486c-.745-.963-.287-2.376.878-2.71a1.721 1.721 0 0 0 1.142-2.237 1.724 1.724 0 0 1 1.677-2.306 1.727 1.727 0 0 0 1.779-1.779 1.724 1.724 0 0 1 2.306-1.677 1.716 1.716 0 0 0 2.237-1.142 1.714 1.714 0 0 1 2.71-.877 1.72 1.72 0 0 0 2.486-.397 1.72 1.72 0 0 1 2.85 0z"
      fill="#1B1A29"
      stroke="#1B1A29"
      strokeWidth={1.129}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.24 299.705a3.907 3.907 0 1 0 .001-7.813 3.907 3.907 0 0 0-.001 7.813z"
      fill="#FFD438"
    />
    <path
      d="M120.599 189.273l1.188 5.319 9.223-2.061 2.137 9.564 5.465-1.221-2.137-9.564 9.271-2.072-1.188-5.319-9.272 2.072-2.126-9.515-5.465 1.221 2.126 9.515-9.222 2.061z"
      fill="#6C61F7"
    />
    <circle
      cx={133.327}
      cy={189.964}
      r={23}
      transform="rotate(-12.596 133.327 189.964)"
      stroke="#6C61F7"
      strokeWidth={5}
    />
  </svg>
);

export default SvgPlusOne;
