import type { ApolloClient, ApolloQueryResult } from '@apollo/client';
import gql from 'graphql-tag';

export const BRAZE_SDK_AUTHENTICATION_QUERY = gql`
  query GetBrazeSdkAuthenticationQuery {
    brazeSdkAuthentication(isMobile: false) {
      brazeJWT
    }
  }
`;

export type BrazeSdkAuthenticationResponse = {
  brazeSdkAuthentication: {
    brazeJWT: string;
  };
};

const brazeSdkAuthentication = async (
  client: ApolloClient<any>,
): Promise<ApolloQueryResult<BrazeSdkAuthenticationResponse>> =>
  client.query({
    query: BRAZE_SDK_AUTHENTICATION_QUERY,
    fetchPolicy: 'no-cache',
  });

export default brazeSdkAuthentication;
