import { rem } from 'polished';
import React from 'react';
import { styled, useTheme } from 'styles';
import BodyText from '../../../Shared/Typography/BodyText';
import { QuestionOutcome } from './QuestionOutcome';

export interface QuestionReviewProps {
  questionNumber: number;
  question: string;
  before: number | null;
  after: number | null;
  minLabel: string;
  maxLabel: string;
}

const Container = styled.div`
  margin-top: ${rem(32)};
  margin-bottom: ${rem(36)};
`;

const QuestionWrapper = styled.div`
  margin-top: ${rem(24)};
  width: 100%;
  display: flex;
`;

export const QuestionReview = (props: QuestionReviewProps) => {
  const theme = useTheme();
  const { minLabel, maxLabel, questionNumber, question, before, after } = props;

  return (
    <Container>
      <BodyText
        weight={theme.typography.fontWeights.medium}
      >{`${questionNumber}. “${question}”`}</BodyText>
      <QuestionWrapper data-testid="question-review-question-wrapper">
        <QuestionOutcome
          before={true}
          value={before}
          minLabel={minLabel}
          maxLabel={maxLabel}
        />
        <QuestionOutcome
          before={false}
          value={after}
          minLabel={minLabel}
          maxLabel={maxLabel}
        />
      </QuestionWrapper>
    </Container>
  );
};
