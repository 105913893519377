import React from 'react';
import { IconProps } from '../IconProps';
const SvgText = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 10" fill="none" {...props}>
    <path
      d="M.625 1h17.5M.625 4.75h15M.625 8.5h10.417"
      stroke="#19100D"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgText;
