import React from 'react';
import { IconProps } from '../IconProps';

const SvgVolume = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.178 1.322a.75.75 0 0 1 .43.678v20a.75.75 0 0 1-1.226.58l-7.65-6.259H2a.75.75 0 0 1-.75-.75V8.43A.75.75 0 0 1 2 7.68h4.732l7.65-6.26a.75.75 0 0 1 .796-.097zm-1.07 2.26L7.474 9.01A.75.75 0 0 1 7 9.18H2.75v5.642H7c.173 0 .34.06.475.17l6.632 5.426V3.583z"
      fill={primaryColor}
    />
    <path
      d="M19.429 6.286S22.286 8.43 22.286 12c0 3.572-2.857 5.715-2.857 5.715M18 9.143s1.429 1.026 1.429 2.857c0 1.83-1.429 2.857-1.429 2.857"
      stroke={primaryColor}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgVolume;
