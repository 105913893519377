import React from 'react';
import { IconProps } from '../IconProps';

const SvgGoogleCalendar = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M24.42 7.579l-7.578-.842-9.263.842L6.736 16l.842 8.421L16 25.474l8.422-1.053.842-8.631-.842-8.211z"
      fill="#fff"
    />
    <path
      d="M11.034 20.644c-.63-.425-1.066-1.046-1.304-1.867l1.462-.602c.132.505.364.897.694 1.174.329.278.729.415 1.196.415.478 0 .889-.145 1.232-.436.343-.29.515-.66.515-1.11 0-.458-.18-.833-.543-1.123-.362-.29-.816-.436-1.36-.436h-.844v-1.446h.758c.467 0 .861-.127 1.181-.38.32-.252.48-.597.48-1.037 0-.392-.143-.703-.43-.937-.286-.233-.648-.351-1.088-.351-.43 0-.77.113-1.023.343-.253.23-.436.511-.552.844l-1.446-.602c.192-.543.543-1.023 1.059-1.438.516-.415 1.175-.623 1.975-.623.591 0 1.124.114 1.596.343.471.23.842.547 1.109.951.267.407.4.862.4 1.367 0 .516-.124.951-.373 1.31a2.568 2.568 0 0 1-.915.822v.087c.477.2.867.505 1.174.915.306.411.46.901.46 1.474s-.146 1.084-.437 1.533c-.29.448-.692.802-1.202 1.059a3.79 3.79 0 0 1-1.724.387 3.57 3.57 0 0 1-2.05-.636zM20 13.394l-1.595 1.16-.803-1.217 2.878-2.076h1.104v9.792H20v-7.66z"
      fill="#1A73E8"
    />
    <path
      d="M24.422 32L32 24.421l-3.79-1.684-3.788 1.684-1.685 3.79L24.422 32z"
      fill="#EA4335"
    />
    <path
      d="M5.895 28.21L7.579 32H24.42v-7.579H7.579l-1.684 3.79z"
      fill="#34A853"
    />
    <path
      d="M2.526 0A2.526 2.526 0 0 0 0 2.526v21.895l3.79 1.684 3.789-1.684V7.579H24.42l1.684-3.79L24.421 0H2.526z"
      fill="#4285F4"
    />
    <path
      d="M0 24.421v5.053A2.526 2.526 0 0 0 2.526 32H7.58v-7.579H0z"
      fill="#188038"
    />
    <path
      d="M24.42 7.579V24.42H32V7.579l-3.79-1.684-3.79 1.684z"
      fill="#FBBC04"
    />
    <path
      d="M32 7.579V2.526A2.526 2.526 0 0 0 29.474 0H24.42v7.579H32z"
      fill="#1967D2"
    />
  </svg>
);

export default SvgGoogleCalendar;
