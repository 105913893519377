import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { small } from 'utils';
import {
  BodyText,
  Button,
  NationalText,
} from '@unmind/design-system-components-web';
import useViewportWidth from 'utils/useViewportWidth';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import { Breakpoints } from '@unmind/design-system-theme';
import NovaConversation from './Assets/nova_conversation.png';

const OuterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #001c45; // temporary colour, will be removed or added to design system later
  align-items: center;
`;

const InnerSectionContainer = styled.div`
  display: flex;
  max-width: ${rem(1252)};
  flex-direction: column;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(40)} ${rem(20)};

  ${small(css`
    padding: ${rem(60)};
  `)};
`;

const QuoteSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff9ef33; // temporary colour, will be removed or added to design system later
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  border-radius: ${rem(20)};
  gap: ${rem(40)};
  padding: ${rem(20)};
  margin: 0 ${rem(20)} ${rem(60)} ${rem(20)};

  ${small(css`
    flex-direction: row;
    margin: 0 ${rem(60)} ${rem(60)} ${rem(60)};
  `)}
`;

const QuoteBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteText = styled(BodyText)`
  color: ${({ theme }) => theme.colors.mood.neutral};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: ${rem(8)};
  max-width: ${rem(576)};
`;

const TextSection = styled.div`
  color: ${({ theme }) => theme.colors.mood.neutral};
`;
const ImageSection = styled.div`
  display: none;

  ${small(css`
    display: block;
  `)};
`;

const NovaTitle = styled(NationalText)`
  color: ${({ theme }) => theme.colors.mood.neutral};
  padding: ${rem(24)} ${rem(0)};
  max-width: ${rem(576)};
  font-size: ${rem(88)};
`;

const NovaDescription = styled(BodyText)`
  color: ${({ theme }) => theme.colors.mood.neutral};
  max-width: ${rem(576)};
  line-height: 172%;
`;

const NovaBadge = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mood.neutral};
  background-color: transparent;
  padding: ${rem(2)} ${rem(8)};
  pointer-events: none;

  > span {
    color: ${({ theme }) => theme.colors.mood.neutral};
  }
`;

const NovaDescriptionButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(40)};
`;

const NovaDescriptionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const NovaDescriptionButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mood.neutral};
  background-color: transparent;
  flex: 1 1 0px;
  margin-right: ${rem(11)};
  margin-bottom: ${rem(11)};
  max-width: ${rem(220)};
  align-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.mood.neutral};
  }

  &:hover > span {
    color: #001c45; // temporary colour, will be removed or added to design system later
  }

  > span {
    color: ${({ theme }) => theme.colors.mood.neutral};

    ${small(
      css`
        font-size: ${rem(16)};
      `,
    )};
    font-size: ${rem(13)};
  }
`;

const NovaCTASection = styled.div`
  display: flex;
  margin-top: ${rem(32)};
`;

const NovaCTAButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mood.neutral};
  background-color: ${({ theme }) => theme.colors.mood.neutral};
  color: transparent;

  > span {
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    font-size: ${({ theme }) => theme.typography.fontSizes.fontSize18}px;
  }

  &:hover > span {
    color: ${({ theme }) => theme.colors.mood.neutral};
  }
`;

const NovaImage = styled.img`
  width: 100%;
  margin-top: ${rem(10)};

  ${small(css`
    margin-top: 0;
    width: ${rem(500)};
  `)};
`;

export const NovaStartPageHeader = ({
  ctaNavigationPath,
}: {
  ctaNavigationPath: string;
}) => {
  const viewportWidth = useViewportWidth();
  const isMobileWeb = viewportWidth < Breakpoints.S;
  const tenantId = getMSTeamsUserContext()?.user?.tenant?.id || 'unknown';

  return (
    <OuterSectionContainer>
      <InnerSectionContainer>
        <ContentSection>
          <TextSection>
            <NovaBadge label="AI Coach"></NovaBadge>
            <NovaTitle>SAY HELLO TO NOVA</NovaTitle>
            <NovaDescription>
              ⏰ Available 24/7
              <br />
              💜 Empathetic support, guidance and motivation
              <br />
              👑 Tailored strategies and resources
              <br />
              🔒 Strict confidentiality and top-tier security
            </NovaDescription>
            <NovaDescriptionButtonsSection>
              <NovaDescriptionButtons>
                <NovaDescriptionButton
                  label="Managing stress"
                  to={ctaNavigationPath}
                  onClick={() => {
                    tracking.track('teams-start-page-cta-clicked', {
                      abTestGroup: 'nova-focussed-start-page-sept-2024',
                      tenantId,
                      ctaLocation: 'managing-stress-button',
                    });
                  }}
                ></NovaDescriptionButton>
                <NovaDescriptionButton
                  label="Goal setting"
                  to={ctaNavigationPath}
                  onClick={() => {
                    tracking.track('teams-start-page-cta-clicked', {
                      abTestGroup: 'nova-focussed-start-page-sept-2024',
                      tenantId,
                      ctaLocation: 'goal-setting-button',
                    });
                  }}
                ></NovaDescriptionButton>
              </NovaDescriptionButtons>
              <NovaDescriptionButtons>
                <NovaDescriptionButton
                  label="Finding motivation"
                  to={ctaNavigationPath}
                  onClick={() => {
                    tracking.track('teams-start-page-cta-clicked', {
                      abTestGroup: 'nova-focussed-start-page-sept-2024',
                      tenantId,
                      ctaLocation: 'finding-motivation-button',
                    });
                  }}
                ></NovaDescriptionButton>
                <NovaDescriptionButton
                  label={isMobileWeb ? 'Focus' : 'Focus & concentration'}
                  to={ctaNavigationPath}
                  onClick={() => {
                    tracking.track('teams-start-page-cta-clicked', {
                      abTestGroup: 'nova-focussed-start-page-sept-2024',
                      tenantId,
                      ctaLocation: 'focus-button',
                    });
                  }}
                ></NovaDescriptionButton>
              </NovaDescriptionButtons>
            </NovaDescriptionButtonsSection>
            <NovaCTASection>
              <NovaCTAButton
                role="button"
                label="Get started with Nova ->"
                to={ctaNavigationPath}
                onClick={() => {
                  tracking.track('teams-start-page-cta-clicked', {
                    abTestGroup: 'nova-focussed-start-page-sept-2024',
                    tenantId,
                    ctaLocation: 'start-page-header',
                  });
                }}
              ></NovaCTAButton>
            </NovaCTASection>
          </TextSection>
          <ImageSection>
            <NovaImage src={NovaConversation} aria-hidden />
          </ImageSection>
        </ContentSection>
        <QuoteSection>
          <QuoteBlock>
            <QuoteText>
              The coach responses were quick, pertinent and it felt very fluid.
              I came away from the chat with two actions which will positively
              improve my situation.
              <br />
              <br />
              Unmind user, June 2024 ★★★★★
            </QuoteText>
          </QuoteBlock>
          <QuoteBlock>
            <QuoteText>
              I find it helps my mood - and someone to talk to with solid advice
              is all I need every so often. Thankful for it.
              <br />
              <br />
              Unmind user, August 2024 ★★★★★
            </QuoteText>
          </QuoteBlock>
          <QuoteBlock>
            <QuoteText>
              I like that Nova came back with thought-provoking questions that
              really made me think outside my current consciousness.
              <br />
              <br />
              Unmind user, August 2024 ★★★★★
            </QuoteText>
          </QuoteBlock>
        </QuoteSection>
      </InnerSectionContainer>
    </OuterSectionContainer>
  );
};
