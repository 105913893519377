import { useEffect, RefObject } from 'react';

/**
 * Assigns focus to the provided element on mount or when `focusCondition` is truthy.
 * This is useful for alerting screen-reader users to page changes -
 * a common use-case would be to focus the H1 element of new screens on load.
 *
 * Make sure the provided element has a tabIndex when using this hook, and that the tabIndex is -1
 * if the element is not interactable.
 */
function useAssignFocus(
  elementRef: RefObject<HTMLElement>,
  focusCondition: boolean = true,
) {
  useEffect(() => {
    if (focusCondition) {
      elementRef?.current?.focus();
    }
  }, [elementRef, focusCondition]);
}

export default useAssignFocus;
