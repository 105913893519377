import { tracking } from '../../../App/Tracking';
import { Events } from '../../../App/Tracking/Events';

export type EventSource = 'series-day-card' | 'series-day-slider';

export interface TrackEventSeriesOptions {
  seriesId: string;
  slug: string;
}

export interface TrackEventDayOptions {
  dayStarted: boolean;
  dayId: string;
  dayNumber: number;
  dayComplete: boolean;
}

const picktrackSeriesEventsName = (
  dayStarted: boolean,
  dayComplete: boolean,
): Events => {
  if (dayComplete) {
    return 'series-day-reviewed';
  }

  if (dayStarted) {
    return 'series-day-continued';
  }

  return 'series-day-started';
};

const trackSeriesDayEvent = (
  dayStarted: boolean,
  dayComplete: boolean,
  source: string,
  seriesId: string,
  dayId: string,
  slug: string,
  dayNumber: number,
): void => {
  const trackingEventName = picktrackSeriesEventsName(dayStarted, dayComplete);

  tracking.track(trackingEventName, {
    source,
    seriesId,
    dayId,
    seriesSlug: slug,
    dayNumber,
  });
};

export const makeTrackSeriesEvents =
  ({ seriesId, slug }: TrackEventSeriesOptions) =>
  (
    source: EventSource,
    { dayId, dayStarted, dayComplete, dayNumber }: TrackEventDayOptions,
  ) => {
    trackSeriesDayEvent(
      dayStarted,
      dayComplete,
      source,
      seriesId,
      dayId,
      slug,
      dayNumber,
    );

    const isBeginningInitialDay = !dayStarted && dayNumber === 1;
    const isReviewingInitialDay = dayComplete && dayNumber === 1;

    if (isBeginningInitialDay || isReviewingInitialDay) {
      tracking.track('series-started', {
        source,
        seriesId,
        seriesSlug: slug,
        isInitial: isBeginningInitialDay,
      });
    }
  };
