export const keyboardHandlers = {
  isEscape: (event: KeyboardEvent) =>
    event.code === 'Escape' || event.key === 'Esc', // For IE11,
  isSpace: (event: KeyboardEvent) =>
    event.code === 'Space' || event.key === 'Spacebar', // For IE11,
  isArrows: (event: KeyboardEvent) =>
    event.key === 'ArrowRight' ||
    event.key === 'Right' || // For IE11
    event.key === 'ArrowLeft' ||
    event.key === 'Left', // For IE11,
  isArrowRight: (event: KeyboardEvent) =>
    event.key === 'ArrowRight' || event.key === 'Right',
  isArrowLeft: (event: KeyboardEvent) =>
    event.key === 'ArrowLeft' || event.key === 'Left',
  isMKey: (event: KeyboardEvent) =>
    event.code === 'KeyM' || event.key === 'm' || event.key === 'M',
  isRKey: (event: KeyboardEvent) =>
    event.code === 'KeyR' || event.key === 'r' || event.key === 'R',
  isCKey: (event: KeyboardEvent) =>
    event.code === 'KeyC' || event.key === 'c' || event.key === 'C',
};
