import { Maybe } from 'graphql/jsutils/Maybe';
import { PersonalisedTodayContent_user_dailySelection_beforeWork_exploreCategory as TodayExploreCategory } from '../Home/PersonalisedToday/__generated__/PersonalisedTodayContent';
import { ToolAttributes } from '../Tools/types';

export type CloudinaryMediaAsset = {
  path?: Maybe<string>;
  width?: Maybe<number>;
  height?: Maybe<number>;
  duration?: Maybe<number>;
};

export type Series = {
  __typename: string;
  id: string;
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  slug?: Maybe<string>;
  cloudinaryThumbnail?: Maybe<CloudinaryMediaAsset>;
  totalDays?: Maybe<number>;
  daysCompleted?: Maybe<number>;
  exploreCategory: Maybe<ExploreCategory>;
};

export type Tool = {
  __typename: string;
  id: string;
  title?: Maybe<string>;
  summary?: Maybe<string>;
  slug?: Maybe<string>;
  estimatedMins?: Maybe<number>;
  cloudinaryThumbnail?: Maybe<CloudinaryMediaAsset>;
  thumbnailMediaPath?: Maybe<string>;
  mediaType?: Maybe<ToolAttributes>;
  mediaSrc?: Maybe<ToolAttributes>;
  mediaAsset?: Maybe<CloudinaryMediaAsset>;
  exploreCategory: Maybe<ExploreCategory>;
  html?: Maybe<string>;
  isFavourite?: Maybe<boolean>;
};

export type ExploreCategory = {
  __typename: string;
  id: string;
  title?: Maybe<string>;
  summary?: Maybe<string>;
  slug?: Maybe<string>;
  internalName?: Maybe<string>;
  colourTheme?: Maybe<string>;
  cloudinaryThumbnail?: Maybe<CloudinaryMediaAsset>;
  thumbnailMediaPath?: Maybe<string>;
  topLevelCategory?: TodayExploreCategory['topLevelCategory'];
};

export enum ContentType {
  short = 'short',
  course = 'course',
  category = 'category',
  cheatSheet = 'cheat sheet',
}
