import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, ThemeInterface } from 'styles';
import { BodyText } from '../../Shared/Typography';
import { createFontStyle } from '../../Shared/Typography/Base';
import { Highlight } from '../types';

const Highlights = styled.div<{ isEmpty: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: 0 ${rem(28)} 0 ${rem(28)};
  padding-top: ${({ isEmpty }) => (isEmpty ? 0 : rem(18))};
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  position: relative;

  &::before {
    content: '';
    backdrop-filter: blur(32px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: inherit;
  }
`;

const HighlightsHeading = styled(BodyText).attrs(
  ({ theme, sliderView }: { theme: ThemeInterface; sliderView?: boolean }) => ({
    sizes: [
      sliderView
        ? theme.typography.fontSizes.fontSize16
        : theme.typography.fontSizes.fontSize18,
    ],
    forwardedAs: 'div',
  }),
)<{ sliderView: boolean }>`
  margin-bottom: ${rem(10)};
`;

const HighlightsList = styled.ul<{ sliderView: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: ${({ sliderView }) => (sliderView ? 0 : rem(14))};
`;

const HighlightsListItem = styled.li<{ sliderView: boolean }>`
  padding: 0 0 ${rem(8)};
  margin: 0;

  ${({ sliderView, theme }) =>
    createFontStyle({
      size: sliderView
        ? theme.typography.fontSizes.fontSize12
        : theme.typography.fontSizes.fontSize16,
      weight: theme.typography.fontWeights.medium,
    })};

  &:before {
    box-sizing: content-box;
    content: '•';
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 ${rem(6)} 0 0;
    position: relative;
  }
`;

export interface SeriesDayCardHighlightsProps {
  sliderView: boolean;
  dayHighlights: Highlight[];
}

export const SeriesDayCardHighlights = (
  props: SeriesDayCardHighlightsProps,
) => {
  const { t: translate } = useTranslation('courses');
  const { dayHighlights, sliderView } = props;

  const highlightsWithText = dayHighlights.filter(
    dayHighlight => !!dayHighlight.text,
  );

  return (
    <Highlights isEmpty={highlightsWithText.length === 0}>
      {highlightsWithText.length === 0 ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <HighlightsHeading sliderView={sliderView}>
            {translate('course_day.highlights.heading')}
          </HighlightsHeading>
          <HighlightsList sliderView={sliderView}>
            {highlightsWithText.map((dayHighlight, index) => (
              <HighlightsListItem
                sliderView={sliderView}
                key={`highlight${index}`}
              >
                {dayHighlight.text}
              </HighlightsListItem>
            ))}
          </HighlightsList>
        </React.Fragment>
      )}
    </Highlights>
  );
};
