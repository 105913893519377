import { useAuth0 } from '@auth0/auth0-react';
import { logException } from 'App/logging';
import { useEffect, useState } from 'react';

export const useAuthHeader = () => {
  const [authHeader, setAuthHeader] = useState<Record<string, string>>();
  const { getAccessTokenSilently, isAuthenticated: isAuthenticatedViaAuth0 } =
    useAuth0();

  useEffect(() => {
    const getAuthToken = async () => {
      if (isAuthenticatedViaAuth0) {
        try {
          const token = await getAccessTokenSilently();
          setAuthHeader({ ['x-api-authorization']: `Bearer ${token}` });

          return;
        } catch (e) {
          logException(e);
        }
      }

      setAuthHeader({ ['x-api-key']: localStorage.getItem('token') || '' });
    };

    void getAuthToken();
  }, [getAccessTokenSilently, isAuthenticatedViaAuth0]);

  return authHeader;
};
