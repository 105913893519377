import { rem } from 'polished';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { css, styled, ThemeInterface, useTheme } from 'styles';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import BodyText from '../../../Shared/Typography/BodyText';
import { assertNever, extraSmall, small, medium } from '../../../utils';
import MoodScoreEntryList from './MoodScoreEntryList';
import {
  withPaginatedCheckIns,
  WithPaginatedCheckInsProps,
} from './withPaginatedCheckIns';

const MoodScoreEntriesContainer = styled.section`
  border-top: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  padding-top: ${rem(32)};
  margin-top: ${rem(24)};
`;

const MoodScoreEntriesErrorContainer = styled.div`
  padding: ${rem(28)} 0;

  ${small(css`
    padding: ${rem(80)} 0;
  `)}

  ${medium(css`
    padding: ${rem(172)} 0;
  `)}
`;

const ErrorText = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
  }),
)`
  text-align: center;
`;

const ScrollableSection = styled.div`
  margin-top: ${rem(20)};
  overflow: auto;
  padding: 0 ${rem(20)};
  border: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  border-radius: ${rem(4)};

  ${extraSmall(css`
    max-height: ${rem(350)};
  `)}
`;

const LoadMoreContainer = styled.div`
  border-top: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  padding: ${rem(20)} 0;
`;

export const LoadMoreButton = styled(PrimaryButton)`
  margin: 0 auto;
`;
const LoadMoreErrorContainer = styled.div`
  margin-bottom: ${rem(20)};
`;

export const LoadMoreErrorState = () => {
  const { t: translate } = useTranslation('insights');

  return (
    <LoadMoreErrorContainer>
      <ErrorText>{translate('moods.check_in_entries.error_text')}</ErrorText>
      <ErrorText>
        {translate('moods.check_in_entries.try_again_text')}
      </ErrorText>
    </LoadMoreErrorContainer>
  );
};

export const ErrorState = () => {
  const { t: translate } = useTranslation('insights');

  return (
    <MoodScoreEntriesErrorContainer>
      <ErrorText>{translate('moods.check_in_entries.error_text')}</ErrorText>
      <ErrorText>
        {translate('moods.check_in_entries.try_again_text')}
      </ErrorText>
    </MoodScoreEntriesErrorContainer>
  );
};

const MoodScoreEntriesWrapper = ({ children }: { children: ReactNode }) => {
  const { t: translate } = useTranslation('insights');
  const theme = useTheme();

  return (
    <MoodScoreEntriesContainer data-testid="mood-score-entry">
      <BodyText sizes={[theme.typography.fontSizes.fontSize16]}>
        {translate('moods.check_in_entries.title')}
      </BodyText>
      {children}
    </MoodScoreEntriesContainer>
  );
};

export const MoodScoreEntries = (props: WithPaginatedCheckInsProps) => {
  const { t: translate } = useTranslation('insights');
  const [loadMoreError, setLoadMoreError] = useState(false);

  switch (props.status) {
    case 'loading':
      return (
        <MoodScoreEntriesWrapper>
          <LoadingIndicator />
        </MoodScoreEntriesWrapper>
      );
    case 'error':
      return (
        <MoodScoreEntriesWrapper>
          <ErrorState />
        </MoodScoreEntriesWrapper>
      );
    case 'ready':
    case 'loadingMore':
      return (
        <MoodScoreEntriesWrapper>
          <ScrollableSection>
            <MoodScoreEntryList entries={props.paginatedCheckIns} />
            {props.hasNextPage && (
              <LoadMoreContainer>
                {loadMoreError && <LoadMoreErrorState />}
                <LoadMoreButton
                  label={translate(
                    'moods.check_in_entries.more_checkins_button.label',
                  )}
                  onClick={async () =>
                    props
                      .loadMoreCheckIns()
                      .then(() => {
                        setLoadMoreError(false);
                      })
                      .catch(() => {
                        setLoadMoreError(true);
                      })
                  }
                  size={ButtonSize.small}
                  loading={props.status === 'loadingMore'}
                />
              </LoadMoreContainer>
            )}
          </ScrollableSection>
        </MoodScoreEntriesWrapper>
      );
    default:
      return assertNever(props);
  }
};

export default compose<WithPaginatedCheckInsProps, Record<string, unknown>>(
  withPaginatedCheckIns,
)(MoodScoreEntries);
