import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

export default function useViewportWidth() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      setViewportWidth(window.innerWidth);
    }, 100);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return viewportWidth;
}
