import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { BodyText, small } from '@unmind/design-system-components-web';
import { WarningDiamond } from 'icons';
import { useWellbeingScores } from 'Compass/api';
import {
  getWellbeingScores_wellbeingThemes as WellbeingTheme,
  getWellbeingScores_overallWellbeingScore as OverallWellbeingScore,
} from 'Compass/api/__generated__/getWellbeingScores';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { Risk_Category } from '__generated__/globalTypes';
import {
  BALANCE_THEME_ID,
  CLARITY_THEME_ID,
  CONNECTION_THEME_ID,
  EMPOWERMENT_THEME_ID,
  ThemeType,
} from '../constants';
import { SectionHeader } from './Components/SectionHeader';
import { Mosaic } from './Components/Mosaic';
import { ScoreWithColourStatus } from './Components/ScoreWithColourStatus';
import { WellbeingSelectedCard } from './Components/SelectedCards/WellbeingSelectedCard';

const WarningDiamondIcon = styled(WarningDiamond).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))`
  padding-bottom: ${rem(24)};
`;

const NoData = styled.div`
  width: ${rem(53)};
  height: ${rem(32)};
  margin: ${rem(34)} 0 ${rem(16)} 0;
  border-radius: ${rem(12)};
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(16)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${rem(20)};
`;

const WellbeingContainer = styled.div`
  margin-bottom: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${small(css`
    padding-bottom: 0;
  `)};
`;

interface WellbeingSectionProps {
  title: string;
  description: string;
  themeData?: WellbeingTheme;
  dataTestId?: string;
}

const WellbeingSection = ({
  title,
  description,
  themeData,
  dataTestId,
}: WellbeingSectionProps) => (
  <Container data-testid={dataTestId}>
    <Title>{title}</Title>
    {themeData ? (
      <ScoreWithColourStatus
        score={themeData.meanScore}
        riskCategory={themeData.riskCategory}
        themeId={themeData.id}
      />
    ) : (
      <NoData data-testid={'no-data-block'} />
    )}
    <Description>{description}</Description>
  </Container>
);

const OverallWellbeing = ({
  overallWellbeingData,
}: {
  overallWellbeingData?: OverallWellbeingScore | null;
}) => {
  const { t } = useTranslation('compass');

  return (
    <Container data-testid={'overall-wellbeing-score-panel'}>
      <Title>{t('dashboard.wellbeing.overall_score.title')}</Title>
      {overallWellbeingData ? (
        <ScoreWithColourStatus
          score={overallWellbeingData.meanScore}
          riskCategory={overallWellbeingData.riskCategory}
        />
      ) : (
        <NoData data-testid={'no-data-block'} />
      )}
      <Description>
        {t('dashboard.wellbeing.overall_score.description')}
      </Description>
    </Container>
  );
};

interface WellbeingProps {
  setSelectedCard(card: React.ReactNode): void;
  readyToShowData: boolean;
  scheduleId: string;
  trackOpenSelectedCard(
    title: string,
    riskCategory: Risk_Category,
    score: number,
    themeType: ThemeType,
  ): void;
}

type Section = {
  title: string;
  description: string;
  data?: WellbeingTheme;
  dataTestId?: string;
};

const findThemeDataById = (
  wellbeingThemes: WellbeingTheme[] | null,
  themeId: string,
) =>
  (wellbeingThemes ?? []).find(wellbeingTheme => wellbeingTheme.id === themeId);

export const Wellbeing = ({
  setSelectedCard,
  readyToShowData,
  scheduleId,
  trackOpenSelectedCard,
}: WellbeingProps) => {
  const { t } = useTranslation('compass');
  const { data: wellbeingScoreData, loading: wellbeingScoreLoading } =
    useWellbeingScores({
      indexScheduleId: scheduleId,
      skip: !readyToShowData,
    });

  if (wellbeingScoreLoading) {
    return <LoadingIndicator />;
  }

  const { wellbeingThemes } = wellbeingScoreData ?? {
    wellbeingThemes: [],
  };

  const empowermentThemeData = findThemeDataById(
    wellbeingThemes,
    EMPOWERMENT_THEME_ID,
  );
  const connectionThemeData = findThemeDataById(
    wellbeingThemes,
    CONNECTION_THEME_ID,
  );
  const clarityThemeData = findThemeDataById(wellbeingThemes, CLARITY_THEME_ID);
  const balanceThemeData = findThemeDataById(wellbeingThemes, BALANCE_THEME_ID);

  const sections: Section[] = [
    {
      title: t('dashboard.wellbeing.empowerment.title'),
      description: t('dashboard.wellbeing.empowerment.no_data'),
      data: empowermentThemeData,
      dataTestId: 'empowerment-wellbeing-panel',
    },
    {
      title: t('dashboard.wellbeing.connection.title'),
      description: t('dashboard.wellbeing.connection.no_data'),
      data: connectionThemeData,
      dataTestId: 'connection-wellbeing-panel',
    },
    {
      title: t('dashboard.wellbeing.clarity.title'),
      description: t('dashboard.wellbeing.clarity.no_data'),
      data: clarityThemeData,
      dataTestId: 'clarity-wellbeing-panel',
    },
    {
      title: t('dashboard.wellbeing.balance.title'),
      description: t('dashboard.wellbeing.balance.no_data'),
      data: balanceThemeData,
      dataTestId: 'balance-wellbeing-panel',
    },
  ];

  const renderSections = (sectionsList: Section[]) => ({
    id: uuid(),
    data: sectionsList.map(section => ({
      id: uuid(),
      cell: (
        <WellbeingSection
          key={uuid()}
          title={section.title}
          description={section.description}
          themeData={section.data}
          dataTestId={section.dataTestId}
        />
      ),
      clickable: readyToShowData,
      onClick: () => {
        if (section.data?.id) {
          setSelectedCard(
            <WellbeingSelectedCard
              closeCard={() => setSelectedCard(null)}
              indexScheduleId={scheduleId}
              themeId={section.data.id}
            />,
          );
          trackOpenSelectedCard(
            section.data.title,
            section.data.riskCategory,
            section.data.meanScore,
            ThemeType.WELLBEING,
          );
        }
      },
    })),
    flexible: false,
  });

  return (
    <WellbeingContainer>
      <SectionHeader
        text={t('dashboard.wellbeing.title')}
        icon={<WarningDiamondIcon />}
        description={t('dashboard.wellbeing.description')}
      />
      <Mosaic
        rowEntries={[
          {
            id: uuid(),
            data: [
              {
                id: uuid(),
                cell: (
                  <OverallWellbeing
                    overallWellbeingData={
                      wellbeingScoreData?.overallWellbeingScore
                    }
                  />
                ),
              },
            ],
            flexible: false,
          },
          renderSections([sections[0], sections[1]]),
          renderSections([sections[2], sections[3]]),
        ]}
      />
    </WellbeingContainer>
  );
};
