import { rem, transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import {
  IconProps,
  IconType,
  Pause,
  Play,
  Refresh,
  JumpBack,
  JumpForward,
} from 'icons';

import { small } from '../../utils';
import { ScreenReaderContent } from '../Accessibility';
import { buttonReset } from '../Button';
import { Status } from '../MediaPlayerComponents';

const Button = styled.button<{
  isRounded?: boolean;
  isAudioPlayer?: boolean;
}>`
  ${buttonReset}
  border: ${rem(2)} solid transparent;
  border-radius: ${({ theme }) => rem(theme.layout.borderRadii.radius4)};
  cursor: pointer;
  outline: none;
  width: ${rem(52)};
  height: ${rem(52)};
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props =>
    props.isAudioPlayer
      ? props.theme.colors.mediaPlayer.audio.icon.default
      : transparentize(0.2, props.theme.colors.staticPalette.white)};

  &:hover {
    color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.icon.hover
        : props.theme.colors.staticPalette.white};
  }

  &:focus {
    border-color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.borderFocus
        : props.theme.button.borderFocusColor};
    border-radius: ${({ isRounded }) => (isRounded ? rem(52) : rem(4))};
  }
`;

interface DynamicIconProps extends IconProps {
  Icon: IconType;
}

const DynamicIcon = ({ Icon, ...props }: DynamicIconProps) => (
  <Icon {...props} />
);

const ButtonIcon = styled(DynamicIcon).attrs({
  primaryColor: 'currentColor',
  // override the default icon template set to 1em
  width: undefined,
  height: 20,
})`
  overflow: visible;
  ${small(css`
    height: ${rem(44)};
  `)}
`;

interface MediaControlButtonProps {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  Icon: IconType;
  text: string;
  roundedButton?: boolean;
  isAudioPlayer?: boolean;
}

const MediaControlButton = ({
  Icon,
  onClick,
  text,
  roundedButton,
  isAudioPlayer,
}: MediaControlButtonProps) => (
  <Button
    onClick={onClick}
    isRounded={roundedButton}
    isAudioPlayer={isAudioPlayer}
  >
    <ButtonIcon Icon={Icon} />
    <ScreenReaderContent as="span">{text}</ScreenReaderContent>
  </Button>
);

const PlayIcon = styled(Play)`
  margin-left: ${rem(5)};

  ${small(css`
    margin-left: ${rem(7)};
  `)}
`;

type PlaybackControlStatuses = Extract<'playing' | 'paused' | 'ended', Status>;

type StatusMapping = {
  [status in PlaybackControlStatuses]: {
    label: string;
    icon: IconType;
    onClick: MediaControlButtonProps['onClick'];
  };
};

export const PlaybackControlButton = ({
  onClickPlay,
  onClickPause,
  onClickReplay,
  status,
  isAudioPlayer,
}: {
  onClickPlay: MediaControlButtonProps['onClick'];
  onClickPause: MediaControlButtonProps['onClick'];
  onClickReplay: MediaControlButtonProps['onClick'];
  status: Status;
  isAudioPlayer?: boolean;
}) => {
  const { t: translate } = useTranslation('media_player');
  const STATUS_TO_CONTROL_BUTTON_MAPPING: StatusMapping = {
    paused: {
      label: translate('controls.play'),
      icon: PlayIcon,
      onClick: onClickPlay,
    },
    playing: {
      label: translate('controls.pause'),
      icon: Pause,
      onClick: onClickPause,
    },
    ended: {
      label: translate('controls.replay'),
      icon: Refresh,
      onClick: onClickReplay,
    },
  };

  if (!(status in STATUS_TO_CONTROL_BUTTON_MAPPING)) {
    return null;
  }

  const { label, icon, onClick } =
    STATUS_TO_CONTROL_BUTTON_MAPPING[status as PlaybackControlStatuses];

  return (
    <MediaControlButton
      Icon={icon}
      onClick={onClick}
      text={label}
      isAudioPlayer={isAudioPlayer}
    />
  );
};

export const JumpBackButton = ({
  onClick,
  isAudioPlayer,
}: {
  onClick({ method }: { method: string }): void;
  isAudioPlayer?: boolean;
}) => {
  const { t: translate } = useTranslation('media_player');

  return (
    <MediaControlButton
      Icon={JumpBack}
      onClick={() => onClick({ method: 'rewind-10s-button' })}
      text={translate('controls.jump_back')}
      roundedButton
      isAudioPlayer={isAudioPlayer}
    />
  );
};

export const JumpForwardButton = ({
  onClick,
  isAudioPlayer,
}: {
  onClick({ method }: { method: string }): void;
  isAudioPlayer?: boolean;
}) => {
  const { t: translate } = useTranslation('media_player');

  return (
    <MediaControlButton
      Icon={JumpForward}
      onClick={() => onClick({ method: 'forward-10s-button' })}
      text={translate('controls.jump_forward')}
      roundedButton
      isAudioPlayer={isAudioPlayer}
    />
  );
};
