import { logException } from '../App/logging';
import { getDbUserLocale } from './getDbUserLocale';
import { getUserLocale } from './getUserLocale';
import { updateDbUserLocale } from './updateDbUserLocale';

export const updateDbUserLocaleIfNeeded = async () => {
  try {
    const userLocale = getUserLocale();
    const {
      data: {
        user: { userLocale: dbUserLocale },
      },
    } = await getDbUserLocale();

    const dbUserLocaleNeedsUpdating =
      dbUserLocale === null || dbUserLocale.value !== userLocale;

    if (dbUserLocaleNeedsUpdating) {
      const originalLocale = navigator.language;
      await updateDbUserLocale(userLocale, originalLocale);
    }
  } catch (error) {
    logException(error);
  }
};
