import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Office365Icon,
  OutlookIcon,
  AppleIcon,
  GoogleIcon,
  YahooIcon,
  IconLabel,
  CalendarLabel,
  RadioOption,
  Container,
} from './styles';

export enum CalendarType {
  Office365 = 'Office 365',
  Outlook = 'Outlook',
  Apple = 'Apple',
  Google = 'Google',
  Yahoo = 'Yahoo',
}

interface CalendarTypeRadioGroupProps {
  value?: string;
  onChange(field: string, value: string): void;
  field: string;
}

export const CalendarTypeRadioGroup = ({
  value,
  onChange,
  field,
}: CalendarTypeRadioGroupProps) => {
  const { t: translate } = useTranslation('shared');

  const supportedCalendars = [
    {
      icon: Office365Icon,
      label: CalendarType.Office365,
    },
    {
      icon: OutlookIcon,
      label: CalendarType.Outlook,
    },
    {
      icon: AppleIcon,
      label: CalendarType.Apple,
    },
    {
      icon: GoogleIcon,
      label: CalendarType.Google,
    },
    {
      icon: YahooIcon,
      label: CalendarType.Yahoo,
    },
  ];

  return (
    <Container
      role="radiogroup"
      aria-label={translate(
        'add_to_calendar.calendar_radio_group.accessibility_label',
      )}
    >
      {supportedCalendars.map(({ icon: Icon, label }) => (
        <IconLabel
          key={label}
          aria-checked={value === label ? 'true' : 'false'}
        >
          <RadioOption
            type="radio"
            role="radio"
            onChange={() => onChange(field, label)}
            checked={value === label}
            aria-checked={value === label}
            value={label}
            name="calendarType"
            aria-label={label}
          />
          <Icon aria-hidden="true" />
          <CalendarLabel>{label}</CalendarLabel>
        </IconLabel>
      ))}
    </Container>
  );
};
