import { useEffect } from 'react';

const Dashboard = () => {
  useEffect(() => {
    window.location.reload();
  });

  return null;
};

export default Dashboard;
