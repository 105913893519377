import { ApolloError, useMutation } from '@apollo/client';
import { addHours, isBefore, parseISO } from 'date-fns';
import { Alert } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { logException } from 'App/logging';
import {
  cancelEventAsGuestMutation,
  cancelEventAsGuestMutationVariables,
} from 'Services/BeGateway/Talk/__generated__/cancelEventAsGuestMutation';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import {
  CANCEL_EVENT_AS_GUEST_MUTATION,
  TALK_EVENTS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import {
  CancelEventInput,
  CancellationReasonType,
  CancellationType,
} from 'Services/BeGateway/__generated__/beGatewayTypes';
import Modal from 'Shared/Modal';
import { Form, Formik, FormikActions } from 'formik';
import { Namespace, ParseKeys } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BEGatewayQueryContext } from 'utils/apollo';
import * as Yup from 'yup';
import {
  CancellationReason,
  Container,
  FormStatusContainer,
  OptionsContainer,
  SubmitButton,
  TextBox,
  TextBoxLabel,
  Title,
  VisuallyHiddenRadioInput,
} from './styles';

export interface Props {
  event: Event;
  open: boolean;
  onClose(): void;
  onCancelEventSuccess?(): void;
}

enum FieldValues {
  FormError = 'formError',
  EventIDField = 'eventID',
  CancellationTypeField = 'cancellationType',
  CancellationReasonTypeField = 'cancellationReasonType',
  CancellationReasonField = 'cancellationReason',
}

type CancellationOption = {
  value: CancellationReasonType;
  label: string;
};

interface FormValues {
  [FieldValues.EventIDField]: string;
  [FieldValues.CancellationTypeField]: CancellationType;
  [FieldValues.CancellationReasonTypeField]: CancellationReasonType | undefined;
  [FieldValues.CancellationReasonField]?: string;
  [FieldValues.FormError]?: string;
}

const formValidationSchema = Yup.object().shape({
  [FieldValues.EventIDField]: Yup.string(),
  [FieldValues.CancellationTypeField]: Yup.string(),
  [FieldValues.CancellationReasonTypeField]: Yup.string(),
  [FieldValues.CancellationReasonField]: Yup.string()
    .notRequired()
    .max(1000, 'Message must be at most 1000 characters'),
});

const CANCELLATION_REASON_LIST: CancellationOption[] = [
  {
    value: CancellationReasonType.unavailable_at_time,
    label: 'talk:upcoming_events.cancel_event.form.reason.unavailable_at_time',
  },
  {
    value: CancellationReasonType.session_not_needed,
    label: 'talk:upcoming_events.cancel_event.form.reason.session_not_needed',
  },
  {
    value: CancellationReasonType.found_another_practitioner,
    label:
      'talk:upcoming_events.cancel_event.form.reason.found_another_practitioner',
  },
  {
    value: CancellationReasonType.no_longer_need_support,
    label:
      'talk:upcoming_events.cancel_event.form.reason.no_longer_need_support',
  },
  {
    value: CancellationReasonType.other,
    label: 'talk:upcoming_events.cancel_event.form.reason.other',
  },
];

export function CancelEvent({
  event,
  open,
  onCancelEventSuccess,
  onClose,
}: Props) {
  const { t } = useTranslation<Namespace<'talk' | 'shared'>>([
    'talk',
    'shared',
  ]);

  const isLateCancellation = useMemo(() => {
    if (event.beginsAt) {
      const dateToCheck = parseISO(event.beginsAt);
      const now = new Date();
      const nowPlus24Hours = addHours(now, 24);

      return (
        isBefore(dateToCheck, nowPlus24Hours) && isBefore(now, dateToCheck)
      );
    }

    return false;
  }, [event.beginsAt]);

  const [cancelEventMutation, { loading: mutationInFlight }] = useMutation<
    cancelEventAsGuestMutation,
    cancelEventAsGuestMutationVariables
  >(CANCEL_EVENT_AS_GUEST_MUTATION, {
    ...BEGatewayQueryContext,
    refetchQueries: [TALK_EVENTS_QUERY],
  });

  const handleSubmit = async (
    formValues: FormValues,
    { resetForm, setErrors }: FormikActions<FormValues>,
  ) => {
    const input: CancelEventInput = {
      eventID: event.id,
      cancellationType: CancellationType.guest,
      cancellationReasonType:
        formValues.cancellationReasonType as CancellationReasonType,
      ...(formValues.cancellationReasonType === CancellationReasonType.other
        ? {
            cancellationReason: formValues.cancellationReason,
          }
        : {}),
    };

    try {
      const response = await cancelEventMutation({ variables: { input } });

      const { eventID, cancellationReason, ...restInput } = input;
      tracking.track('talk-session-cancelled', {
        ...restInput,
        eventId: eventID,
        isLateCancellation,
      });

      if (response?.data?.cancelEvent) {
        resetForm();
        onCancelEventSuccess?.();
      } else {
        throw new Error(`Failed to cancel event ID: ${event.id}`);
      }
    } catch (e) {
      let sessionWith24HoursError;
      if (e instanceof ApolloError) {
        sessionWith24HoursError = e.graphQLErrors?.some(
          graphQLError =>
            graphQLError.extensions?.code ===
            'CANCEL_EVENT_WITHIN_24_HOURS_ERROR',
        );
      }
      setErrors({
        formError: sessionWith24HoursError
          ? t('talk:upcoming_events.edit_event.error.message')
          : t('shared:errors.messages.something_wrong'),
      });
      logException(e);
    }
  };

  return (
    <Modal
      open={open}
      onCloseCallback={onClose}
      closeHeader
      modalContent={() => (
        <Container>
          <Title accessibilityAutoFocus={open} id="cancellation-reason">
            {t('talk:upcoming_events.cancel_event.form.header')}
          </Title>
          <Formik
            initialValues={{
              eventID: event.id,
              cancellationType: CancellationType.guest,
              cancellationReason: undefined,
              cancellationReasonType: undefined,
              formError: undefined,
            }}
            validationSchema={formValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, isValid, errors }) => (
              <Form>
                {Boolean(errors?.formError) ? (
                  <FormStatusContainer role="alert">
                    <Alert
                      variant="error"
                      size="small"
                      title={errors?.formError ?? ''}
                    />
                  </FormStatusContainer>
                ) : null}
                <OptionsContainer
                  role="radiogroup"
                  aria-labelledby="cancellation-reason"
                >
                  {CANCELLATION_REASON_LIST.map(({ value, label }, index) => (
                    <CancellationReason
                      key={index}
                      data-cy={`talk-cancel-event-${value}-radio`}
                    >
                      <VisuallyHiddenRadioInput
                        tabIndex={
                          values.cancellationReasonType
                            ? values.cancellationReasonType === value
                              ? '0'
                              : '-1'
                            : index === 0
                            ? '0'
                            : '-1'
                        }
                        id={`radio-${value}`}
                        name={FieldValues.CancellationReasonTypeField}
                        value={value}
                      />
                      {t(label as ParseKeys<Namespace<'talk'>>, label)}
                    </CancellationReason>
                  ))}

                  {values.cancellationReasonType ===
                  CancellationReasonType.other ? (
                    <div>
                      <TextBoxLabel
                        htmlFor={FieldValues.CancellationReasonField}
                      >
                        {t(
                          'talk:upcoming_events.cancel_event.form.reason.other_label',
                        )}
                      </TextBoxLabel>
                      <TextBox
                        name={FieldValues.CancellationReasonField}
                        id={FieldValues.CancellationReasonField}
                        component="textarea"
                        data-cy="talk-cancel-event-freetext-reason"
                      />
                    </div>
                  ) : null}

                  <SubmitButton
                    label={t(
                      'talk:upcoming_events.cancel_event.form.submit_button.label',
                    )}
                    loading={mutationInFlight}
                    disabled={!isValid || mutationInFlight}
                    role="button"
                    name="Edit"
                  />
                </OptionsContainer>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    />
  );
}
