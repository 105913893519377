import { rem } from 'polished';
import { css, styled } from 'styles';
import { medium } from '../../utils';

export const PanelContainer = styled.div`
  ${medium(css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `)}
`;

export const PanelColumn = styled.div`
  ${medium(css`
    width: calc((100% - 48px) / 2);
  `)}
`;

export const PanelWrapper = styled.div`
  & > * {
    margin-top: ${rem(36)};
  }
`;
