import React from 'react';
import { IconProps } from '../IconProps';

const SvgQuestion = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 19.282A9.286 9.286 0 1 0 10 .711a9.286 9.286 0 0 0 0 18.571z"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.86 7.854a2.143 2.143 0 1 1 2.142 2.143v1.428"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.001 13.57a1.072 1.072 0 1 0 1.072 1.072A1.086 1.086 0 0 0 10 13.57z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
  </svg>
);

export default SvgQuestion;
