import { rem } from 'polished';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import BackButton from 'Shared/Button/BackButton';
import { AddToCalendar } from 'Shared/AddToCalendar';
import { useAssignFocus } from '../../Shared/Accessibility';
import { ProgressBar } from '../../Shared/Progress/ProgressBar';
import { TAB_WIDTH } from '../../Shared/Tabs/Tab';
import { HeadingText, BodyText, ExternalLink } from '../../Shared/Typography';
import { small } from '../../utils';
import RoutePath from '../../App/RoutePath';
import AlertDisclaimer from '../../Shared/AlertDisclaimer/AlertDisclaimer';
import { WithSingleSeriesChildProps } from './withSingleSeries';

export interface SeriesHeaderProps extends WithSingleSeriesChildProps {
  progress: number;
}

const Container = styled.div`
  margin: ${rem(20)} 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  &:focus {
    outline: none;
  }

  ${small(css`
    margin: ${rem(35)} 0 ${rem(25)};
    width: 100%;
  `)}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 100%;

  ${small(css`
    flex-basis: auto;
  `)}
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-right: ${rem(9)};
  max-width: ${rem(TAB_WIDTH)};
  ${small(css`
    width: ${rem(TAB_WIDTH)};
  `)}
  margin-top: ${rem(12)};
`;

const ProgressText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.bold,
}))``;

const Subtitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
}))``;

const Dot = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.2rem;
  margin: 0 0.5rem;
`;

const TimeCommitment = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  text-transform: uppercase;
`;

const CalendarContainer = styled.div`
  display: flex;
  width: ${rem(270)};
  position: relative;
  margin-top: ${rem(12)};
`;

const SeriesHeader = ({
  progress,
  singleSeries,
  singleSeriesLoading,
}: SeriesHeaderProps) => {
  const { t: translate } = useTranslation<Namespace<'courses'>>('courses');
  const contentRef = useRef<HTMLDivElement>(null);
  useAssignFocus(contentRef);

  if (!singleSeries || singleSeriesLoading) {
    return null;
  }

  const { minsPerDay, totalDays } = singleSeries;

  return (
    <Container ref={contentRef} tabIndex={-1}>
      {singleSeries?.isNotAnonymised ? (
        <AlertDisclaimer
          disclaimerText={Trans({
            t: translate,
            i18nKey: 'overview.tracking_alert_disclaimer.text',
            defaults:
              "We wanted to let you know that your company can now track the completion of <bold_text>{{series_title}}</bold_text>, as part of their commitment to supporting your professional development.<br /><br />However, we understand the importance of privacy and it's something we take seriously at Unmind. We want to reassure you that the rest of your platform usage remains completely anonymised and confidential. If you have any concerns, please reach out to <email_link>support@unmind.com</email_link>.",
            components: {
              bold_text: <strong />,
              email_link: (
                <ExternalLink
                  data-testid="support-email"
                  href="mailto:support@unmind.com"
                  style={{ textDecoration: 'underline' }}
                />
              ),
            },
            values: {
              series_title: singleSeries.title,
            },
          })}
        />
      ) : null}
      <Column>
        <BackButton linkToDefault={RoutePath.Explore} />
      </Column>
      <Column>
        <Title>{singleSeries.title}</Title>
        <Subtitle>{singleSeries.subtitle}</Subtitle>
        <TimeCommitment>
          <>
            {translate('overview.session_count', {
              count: totalDays,
            })}
            {minsPerDay &&
              Trans({
                t: translate,
                i18nKey: 'overview.duration',
                defaults:
                  '<dot>&sdot;</dot> about {{mins_per_day}} mins a session',
                components: { dot: <Dot /> },
                values: { mins_per_day: minsPerDay },
              })}
          </>
          <StyledProgressBar progress={progress} data-testid="progress-bar" />
          <ProgressText>{Math.round(progress)}%</ProgressText>
        </TimeCommitment>
      </Column>
      <Column>
        <CalendarContainer>
          <AddToCalendar
            useSmallButton={true}
            title={singleSeries.title}
            description={singleSeries.subtitle}
            buttonTextKey={'add_to_calendar.course_reminder_text'}
            contentPath={window.location.pathname}
          ></AddToCalendar>
        </CalendarContainer>
      </Column>
    </Container>
  );
};

export default SeriesHeader;
