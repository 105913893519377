import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { styled } from 'styles';

import { TimerDispatch, withTimerDispatch } from '../../../../../Shared/Timer';
import { ButtonSize } from '../../../../../Shared/Button';
import { getMediaSrc } from '../../../../../utils';
import NextButton from '../../Buttons/NextButton';

import { SegmentTrackingProps } from '../..';

export const SERIES_SEGMENT_IMAGE_QUERY = gql`
  query seriesSegmentImage($segmentId: String!) {
    seriesSegment(id: $segmentId) {
      mediaSrc {
        stringValue
      }
      cloudinaryMedia {
        path
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;

interface OuterImageSegmentProps {
  segmentTrackingProps: SegmentTrackingProps;
  segmentId: string;
  segmentTitle: string;
  isSegmentExpanded: boolean;
  isLastSegmentInSeries: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
}

export interface ImageSegmentProps extends OuterImageSegmentProps {
  timerDispatch: TimerDispatch;
}

export const Image = styled.img`
  max-width: ${rem('600px')};
  width: 100%;
  box-shadow: 0 0 ${rem('10px')} 0 rgba(0, 0, 0, 0.15);
  border-radius: ${rem('12px')};
  border: ${({ theme }) =>
    `solid ${rem('6px')} ${theme.colors.background.card}`};
  margin-top: ${rem('8px')};
  margin-bottom: ${rem('32px')};
  margin-left: ${rem('2px')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ImageSegment = (props: ImageSegmentProps) => {
  const {
    segmentId,
    segmentTitle = '',
    timerDispatch,
    isSegmentExpanded,
    isLastSegmentInSeries,
    isLastSegmentInDay,
    isComplete,
  } = props;

  const { data, loading } = useQuery(SERIES_SEGMENT_IMAGE_QUERY, {
    variables: { segmentId },
  });
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    if (data) {
      setImageSrc(
        getMediaSrc({
          cloudinaryMedia: data?.seriesSegment?.cloudinaryMedia,
          assetToken: data?.getAssetToken.assetToken,
          contentId: segmentId,
          legacyMediaUrl: data?.seriesSegment?.mediaSrc?.stringValue,
        }),
      );
    }
  }, [data, segmentId]);

  useEffect(() => {
    if (isSegmentExpanded) {
      timerDispatch.reset();
      timerDispatch.start(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSegmentExpanded]);

  const isLoading = loading || imageSrc === '';

  return isLoading ? null : (
    <React.Fragment>
      <div>
        <Image src={imageSrc} alt="series-segment-image" />
        <ButtonsWrapper>
          <NextButton
            size={ButtonSize.medium}
            segmentId={segmentId}
            segmentTitle={segmentTitle}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLastSegmentInDay}
            isComplete={isComplete}
          />
        </ButtonsWrapper>
      </div>
    </React.Fragment>
  );
};

export default compose<ImageSegmentProps, OuterImageSegmentProps>(
  withTimerDispatch,
)(ImageSegment);
