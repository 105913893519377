import { BodyText } from '@unmind/design-system-components-web';
import { css, styled, ThemeInterface } from 'styles';
import { rem, rgba, transparentize } from 'polished';
import { buttonReset } from 'Shared/Button';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';
import { small } from '../../utils';

type AddToCalendarButtonProps = {
  bordered?: boolean;
  iconOnly?: boolean;
  isVideoPlayer?: boolean;
  useSmallButton?: boolean;
};

export const AddToCalendarButton = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
    weight: theme.typography.fontWeights.medium,
  }),
)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ bordered }: AddToCalendarButtonProps) =>
    bordered &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.border.primary};
      padding: ${rem(8)} ${rem(18)};

      &:hover {
        background-color: ${({ theme }: { theme: ThemeInterface }) =>
          theme.button.secondary.default.hover.backgroundColor};
      }
    `}
  height: ${rem(43)};
  border-radius: 35px;
  text-align: left;
  justify-content: center;
  cursor: pointer;
`;

export const AddToCalendarIcon = styled(DynamicIcon).attrs(
  ({
    theme,
    isVideoPlayer,
  }: {
    theme: ThemeInterface;
    isVideoPlayer: boolean;
  }) => ({
    primaryColor: isVideoPlayer
      ? transparentize(0.2, theme.colors.staticPalette.white)
      : theme.colors.text.primary,
  }),
)`
  ${({ iconOnly, useSmallButton }: AddToCalendarButtonProps) =>
    iconOnly
      ? css`
          height: ${rem(20)};
          width: ${rem(20)};
          cursor: pointer;
        `
      : css`
          height: ${useSmallButton ? rem(16) : rem(18)};
          width: ${useSmallButton ? rem(16) : rem(18)};
          margin-right: ${rem(6)};
          margin-top: ${rem(2)};
        `}

  &:hover {
    color: ${({ theme, isVideoPlayer }) =>
      isVideoPlayer
        ? theme.colors.staticPalette.white
        : theme.colors.mediaPlayer.audio.icon.hover};
  }
`;

export const AddToCalendarText = styled(BodyText).attrs(
  ({
    theme,
    useSmallButton,
  }: {
    theme: ThemeInterface;
    useSmallButton: boolean;
  }) => ({
    sizes: useSmallButton
      ? [theme.typography.fontSizes.fontSize14]
      : [theme.typography.fontSizes.fontSize16],
  }),
)<{ useSmallButton: boolean }>`
  line-height: 1;
`;

export const AddToCalendarIconButton = styled.button<{
  isVideoPlayer?: boolean;
}>`
  ${buttonReset}
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  position: relative;

  ${small(css`
    height: ${rem(32)};
    width: ${rem(32)};
  `)}

  &:focus,
  &:hover {
    color: ${({ theme, isVideoPlayer }) =>
      isVideoPlayer
        ? theme.colors.staticPalette.white
        : theme.colors.mediaPlayer.audio.icon.hover};
  }

  &:focus {
    outline: ${({ theme, isVideoPlayer }) =>
      css`
        ${rem(2)} solid ${isVideoPlayer
          ? theme.button.borderFocusColor
          : theme.colors.mediaPlayer.audio.borderFocus}
      `};
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 10040;
  background: ${({ theme }) => rgba(theme.colors.background.inverse, 0.7)};
  visibility: visible;
`;
