import { gql, useQuery } from '@apollo/client';
import { getUserNotificationPreferences } from './__generated__/getUserNotificationPreferences';

export const USER_NOTIFICATION_PREFERENCES = gql`
  query getUserNotificationPreferences {
    user {
      notificationPreferences {
        teamsNotificationPrefs {
          communityParticipation
          productUpdates
          upcomingEvents
        }
        emailNotificationPrefs {
          communityParticipation
          productUpdates
          upcomingEvents
        }
      }
    }
  }
`;

export const useUserNotificationPreferences = () => {
  const { data, loading, error, refetch } =
    useQuery<getUserNotificationPreferences>(USER_NOTIFICATION_PREFERENCES, {
      errorPolicy: 'all',
      returnPartialData: false,
      fetchPolicy: 'cache-first',
    });

  return { data, loading, error, refetch };
};
