import { rem, rgba } from 'polished';
import { default as Slider } from 'rc-slider';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useResizeObserver from '@react-hook/resize-observer';

import { createGlobalStyle, css, styled } from 'styles';
import { isDesktop } from 'react-device-detect';
import BodyText from '../Typography/BodyText';
import ProgressBarTooltip from './ProgressBarTooltip';

import {
  calculateSeekTime,
  formatTime,
  remainingTime,
} from './progressBarHelpers';

export const PREFIX_CLASS = 'unmind-media-progress';

const baseHandleStyle = css`
  width: ${rem(12)};
  height: ${rem(12)};
  margin-top: ${rem(-4)};
  box-shadow: 1px 0px 3px 0px rgba(75, 75, 75, 0.4);
  border: none;
`;
const hoveredHandleStyle = css`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-top: ${rem(-6)};
`;

// The rc-slider style prop uses HTML style attribute under the hood. Custom CSS is needed here to override the library's default CSS and use pseudo-selectors.
const ProgressBarGlobalStyle = createGlobalStyle`
  .${PREFIX_CLASS} {
    color: ${({ theme }) => theme.colors.staticPalette.white};
    position: relative;
    height: ${rem(14)};
    padding: ${rem(5)} 0;
    width: 100%;
    touch-action: none;
    box-sizing: border-box;
    cursor: pointer;

    &-rail,
    &-track {
      position: absolute;
      height: ${rem(4)};
      border-radius: ${rem(6)};
      background-color: currentColor;
    }

    &-rail {
      width: 100%;
      opacity: 0.5;
    }

    &-track {
      left: 0;
    }

    &-handle {
      ${baseHandleStyle};
      position: absolute;
      cursor: grab;
      border-radius: 50%;
      background-color: currentColor;
      touch-action: pan-x;

      &:focus {
        outline: none;
        width: ${rem(18)};
        height: ${rem(18)};
        margin-top: ${rem(-7)};
        border: ${({ theme }) =>
          `${rem(3)} solid ${rgba(theme.colors.border.primary, 0.0)}`};
        box-shadow: 0 0 0 ${rem(1)} currentColor;
        background-clip: padding-box;
      }

      &-click-focused:focus {
        ${baseHandleStyle};

        &:hover {
            ${hoveredHandleStyle}
        }
      }

      &:hover, &:active {
        &:not(:focus) {
          ${hoveredHandleStyle}
        }
      }

      &:active {
        cursor: grabbing;
        ${hoveredHandleStyle}
      }
    }

  }
`;

const AudioProgressBarGlobalStyle = createGlobalStyle`
  .${PREFIX_CLASS} {
    color: ${({ theme }) => theme.colors.mediaPlayer.audio.icon.default};

    &-handle {
      &:focus {
        border: ${({ theme }) =>
          `${rem(3)} solid ${rgba(
            theme.colors.mediaPlayer.audio.borderFocus,
            0.0,
          )}`};
        box-shadow: 0 0 0 ${rem(1)} ${({ theme }) =>
  theme.colors.mediaPlayer.audio.borderFocus};
      }
    }

  }
`;

const Container = styled.div`
  pointer-events: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const TimeDisplay = styled(BodyText).attrs<TimeDisplayProps>(
  ({ theme, isAudioPlayer }) => ({
    color: isAudioPlayer
      ? theme.colors.mediaPlayer.audio.icon.default
      : theme.colors.staticPalette.white,
    sizes: [theme.typography.fontSizes.fontSize16],
  }),
)<TimeDisplayProps>`
  text-align: ${props => props.align};
  flex: 1 1 50%;
  position: relative;
`;

const SliderContainer = styled.div`
  width: 100%;
`;
interface TimeDisplayProps {
  align: 'left' | 'right';
  isAudioPlayer?: boolean;
}
export interface ProgressBarProps {
  duration: number;
  currentTime: number;
  handleDrag({ startDraggingValue }: { startDraggingValue?: number }): void;
  onKeyDown?(e: React.KeyboardEvent): void;
  onChange(progress: number): void;
  onSeek?({
    startPosition,
    method,
  }: {
    startPosition: number;
    method: string;
  }): void;
  isAudioPlayer?: boolean;
}

const ProgressBar = ({
  duration,
  currentTime,
  onChange,
  onSeek,
  handleDrag,
  onKeyDown,
  isAudioPlayer,
}: ProgressBarProps) => {
  const { t: translate } = useTranslation('media_player');
  const [leftPadding, setLeftPadding] = useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
  const [hoverTimestampXPosition, setHoverTimestampXPosition] = useState(0);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [seekTime, setSeekTime] = useState('');
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressHandleRef = useRef<HTMLDivElement>(null);

  const updateSizes = () => {
    if (progressBarRef.current) {
      setProgressBarWidth(progressBarRef.current.clientWidth);
      setLeftPadding(progressBarRef.current.getBoundingClientRect().left);
    }
  };

  useLayoutEffect(() => {
    updateSizes();
  }, []);

  useResizeObserver(progressBarRef, () => {
    updateSizes();
  });

  type RenderHandleParams = Parameters<
    Exclude<React.ComponentProps<typeof Slider>['handle'], undefined>
  >[0];

  const sliderHandle = (props: RenderHandleParams) => {
    const { Handle } = Slider;
    const ariaValueTextFormatterForHandle = (currentValue: number) =>
      translate('controls.progress_bar.accessibility_value', {
        currentTime: formatTime(currentValue),
        totalTime: formatTime(duration),
      });

    // a bug in rc-slider creates a long, ugly console warning when we pass the dragging prop through to the Handle. https://github.com/react-component/slider/issues/502
    // since it's not being used anyway, we can destructure it and not pass it through
    const { ariaValueTextFormatter, dragging, ...handleProps } = props;

    return (
      /**
       * rc-slider does appropriately output this with a role of `slider` but static analysis cannot know this
       */
      /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
      <div
        ref={progressHandleRef}
        onKeyDown={e => {
          if (
            // IE11 listens for 'Left' and 'Right'
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'Right' ||
            e.key === 'Left'
          ) {
            // We need to avoid the keyDown event from propagating from the progress handle
            // to the slider. This prevents the native behaviour from the rc-slider library
            // of updating the current time on a 0.25 seconds basis.
            e.stopPropagation();
          }
        }}
        onMouseDown={() => {
          handleDrag({ startDraggingValue: props.value });
        }}
      >
        <Handle
          ariaValueTextFormatter={ariaValueTextFormatterForHandle}
          {...handleProps}
        />
      </div>
    );
  };

  return (
    <Container id="seek-slider" onKeyDown={onKeyDown}>
      <ProgressBarGlobalStyle />
      {isAudioPlayer ? <AudioProgressBarGlobalStyle /> : null}
      <TimeDisplay
        align="left"
        aria-hidden="true"
        isAudioPlayer={isAudioPlayer}
      >
        {formatTime(currentTime)}
      </TimeDisplay>
      <TimeDisplay
        align="right"
        aria-hidden="true"
        isAudioPlayer={isAudioPlayer}
      >
        {remainingTime(duration, currentTime)}
      </TimeDisplay>
      {isTooltipVisible && (
        <ProgressBarTooltip
          xPosition={hoverTimestampXPosition}
          seekTime={seekTime}
        />
      )}
      <SliderContainer
        data-testid="progress-slider-container"
        onMouseMove={event => {
          setSeekTime(
            formatTime(
              calculateSeekTime(
                event.pageX - leftPadding,
                progressBarWidth,
                duration,
              ),
            ),
          );
          setHoverTimestampXPosition(event.pageX);
        }}
        onMouseEnter={() => (isDesktop ? setTooltipVisible(true) : null)}
        onMouseLeave={() => setTooltipVisible(false)}
        onMouseDown={(e: React.MouseEvent) => {
          if (
            progressHandleRef.current &&
            e.target !== progressHandleRef.current.firstChild &&
            onSeek
          ) {
            onSeek({
              method: 'progress-bar-clicked',
              startPosition: currentTime,
            });
          }
        }}
        ref={progressBarRef}
      >
        <Slider
          ariaLabelForHandle={translate(
            'controls.progress_bar.accessibility_name',
          )}
          prefixCls={PREFIX_CLASS}
          max={duration}
          onChange={onChange}
          value={currentTime}
          step={0.25}
          handle={sliderHandle}
        />
      </SliderContainer>
    </Container>
  );
};

export default ProgressBar;
