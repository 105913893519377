import React from 'react';
import { IconProps } from '../IconProps';

const SvgTrophy = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.604.003a.738.738 0 0 0-.585.53C.005.583.004.678.004 2.929c0 2.479 0 2.418.037 2.712a4.986 4.986 0 0 0 1.875 3.293 4.98 4.98 0 0 0 2.45 1.024c.069.007.09.013.093.022l.04.145c.193.69.545 1.383.996 1.96a5.716 5.716 0 0 0 3.743 2.148l.05.005v4.327h-2.15c-1.382 0-2.176.003-2.222.008a.65.65 0 0 0-.243.072.747.747 0 0 0-.37.464.906.906 0 0 0-.01.172c-.001.119.001.144.017.2a.732.732 0 0 0 .572.508c.07.011 10.166.011 10.236 0a.733.733 0 0 0 .57-.504c.021-.074.03-.24.017-.326a.73.73 0 0 0-.378-.514.65.65 0 0 0-.243-.072c-.046-.005-.84-.008-2.222-.008h-2.15v-4.326l.074-.01a5.734 5.734 0 0 0 2.75-1.171c.335-.264.704-.634.97-.973A5.748 5.748 0 0 0 15.54 9.98c.003-.01.025-.015.094-.022a4.978 4.978 0 0 0 3.303-1.877c.501-.641.84-1.394.983-2.183a4.78 4.78 0 0 0 .072-.641c0-.028.004-.053.008-.056.005-.003.008-.778.008-2.297 0-1.526-.003-2.29-.008-2.287-.005.003-.008-.005-.008-.018a.673.673 0 0 0-.106-.277.767.767 0 0 0-.41-.304L19.42.001h-9.396C4.856 0 .617 0 .604.002zm-.6 2.91c0 1.263 0 1.778.002 1.145V1.762c-.001-.63-.002-.112-.002 1.15zm1.428.386c0 1.935 0 1.972.032 2.194a3.573 3.573 0 0 0 2.81 3c.003 0 .006-1.59.006-3.532V1.429H1.432v1.87zM5.72 5.108c0 2.378.003 3.715.008 3.782a4.278 4.278 0 0 0 1.616 3.038c.69.547 1.516.862 2.408.917a4.299 4.299 0 0 0 2.728-.783 4.29 4.29 0 0 0 1.792-3.169 346 346 0 0 0 .008-3.786V1.43H5.72v3.679zm10-.147c0 1.943.003 3.532.006 3.532a3.53 3.53 0 0 0 1.414-.64c.455-.342.832-.8 1.072-1.3.176-.366.278-.714.334-1.132l.017-.132.003-1.93.002-1.93H15.72V4.96z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgTrophy;
