import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styles/styled';
import { rem } from 'polished';
import { BodyText, HeadingText } from 'Shared/Typography';
import PrimaryButton from 'Shared/PrimaryButton';
import RoutePath from 'App/RoutePath';

export const LEARN_MORE_ANCHOR = 'learn-more';

const CompassHeaderContainer = styled.div`
  max-width: ${rem(700)};
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize28],
  weight: theme.typography.fontWeights.semiBold,
  level: 1,
}))`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${rem(22)};
`;

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const GetStartedButton = styled(PrimaryButton)`
  margin-top: ${rem(16)};
  width: ${rem(155)};
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  lineHeight: theme.typography.lineHeights.lineHeight20,
  color: theme.colors.text.secondary,
}))`
  margin-bottom: ${rem(24)};
`;

interface CompassHeaderProps {
  toggleModal(): void;
  showGetStartedButton: boolean;
}

export const CompassHeader = ({
  toggleModal,
  showGetStartedButton: showButton,
}: CompassHeaderProps) => {
  const { t: translate } = useTranslation('compass');

  // When served from onboarding route hide get started button as this is just an information page
  const showGetStartedButton =
    window.location.pathname === RoutePath.Compass && showButton;

  return (
    <CompassHeaderContainer>
      <Title>{translate('header.title')}</Title>
      <Trans
        t={translate}
        i18nKey="header.description"
        defaults="<div>Welcome to Compass, a new feature designed to help you gain deeper insights into your team's wellbeing and productivity. With Compass you will receive tailored recommendations in order to take the right steps towards better team performance. <anchor>Learn more</anchor> about how Compass works.</div>"
        components={{
          anchor: <StyledAnchor href={`#${LEARN_MORE_ANCHOR}`} />,
          div: <Description />,
        }}
      />
      {showGetStartedButton && (
        <GetStartedButton
          onClick={toggleModal}
          label={translate('header.button')}
        />
      )}
    </CompassHeaderContainer>
  );
};
