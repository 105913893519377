import React from 'react';
import { IconProps } from '../IconProps';

const SvgHappiness = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Happiness_svg__clip0_1095_24956)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M18.63 7.42A8.094 8.094 0 0 0 8.8 5.39c-2.67 1.33-4.52 4.09-4.52 7.28a8.13 8.13 0 0 0 16.26 0c0-2-.72-3.83-1.92-5.24l.01-.01z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.47 14.02c1.42 2.02 5.35 3.14 8.05.07"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.67 10.73a.03.03 0 1 0 0-.06.03.03 0 0 0 0 .06z"
        fill="#391F17"
      />
      <path
        d="M15.67 11.23a.53.53 0 1 0 0-1.06.53.53 0 0 0 0 1.06zM9.46 10.76a.03.03 0 1 0 0-.06.03.03 0 0 0 0 .06z"
        fill="#391F17"
      />
      <path
        d="M9.46 11.26a.53.53 0 1 0 0-1.06.53.53 0 0 0 0 1.06z"
        fill="#391F17"
      />
    </g>
    <defs>
      <clipPath id="Happiness_svg__clip0_1095_24956">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHappiness;
