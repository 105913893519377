import React, { useState } from 'react';
import { UseSingleSeries_singleSeries } from 'Series/SingleSeries/__generated__/UseSingleSeries';
import { noop } from 'lodash';
import { CourseImpactQuestionnaireResponse } from '../../../../../__generated__/globalTypes';
import RadioQuestion from '../../../../../Shared/Radio/Question';

export interface ImpactQuestionnaireQuestionsProps {
  impactQuestionnaire: UseSingleSeries_singleSeries['impactQuestionnaire'];
  setImpactQuestionnaireAnswers(
    answers: CourseImpactQuestionnaireResponse[],
  ): void;
  nextSection(): void;
  prevSection(): void;
  seriesQuestionsCount?: number;
}

export function ImpactQuestionnaireQuestions({
  impactQuestionnaire,
  setImpactQuestionnaireAnswers,
  nextSection,
  prevSection,
  seriesQuestionsCount = 0,
}: ImpactQuestionnaireQuestionsProps) {
  const [answers, setAnswers] = useState<CourseImpactQuestionnaireResponse[]>(
    [],
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const nextStepDelay = 500;
  const impactQuestionnaireQuestions = impactQuestionnaire?.questions ?? [];

  if (!impactQuestionnaireQuestions.length) {
    return null;
  }

  function onBackButtonClick() {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    if (currentQuestionIndex === 0) {
      prevSection();
    }
  }

  function getUpdatedAnswers(
    answerId: string,
  ): CourseImpactQuestionnaireResponse[] {
    const newAnswers = [...answers];

    if (answers[currentQuestionIndex] === undefined) {
      return [
        ...answers,
        {
          questionId: impactQuestionnaireQuestions[currentQuestionIndex].id,
          responseId: answerId,
        },
      ];
    }
    const newAnswer = {
      questionId: answers[currentQuestionIndex].questionId,
      responseId: answerId,
    };

    newAnswers.splice(currentQuestionIndex, 1, newAnswer);

    return newAnswers;
  }

  function onAnswerSelected(answer: string) {
    const newAnswers = getUpdatedAnswers(answer);
    setAnswers(newAnswers);

    // next step/section
    setTimeout(() => {
      if (currentQuestionIndex < impactQuestionnaireQuestions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setImpactQuestionnaireAnswers(newAnswers);
        nextSection();
      }
    }, nextStepDelay);
  }

  const selectedIndex =
    answers[currentQuestionIndex] !== undefined
      ? impactQuestionnaireQuestions[
          currentQuestionIndex
        ].responseOptions?.findIndex(
          item => item.id === answers[currentQuestionIndex].responseId,
        )
      : undefined;

  return (
    <RadioQuestion
      questionText={`“${(
        impactQuestionnaireQuestions[currentQuestionIndex].text || ''
      ).trim()}”`}
      questionNumber={seriesQuestionsCount + currentQuestionIndex + 1}
      totalQuestions={
        seriesQuestionsCount + Number(impactQuestionnaireQuestions.length || 0)
      }
      onBackButtonClick={onBackButtonClick}
      onAnswerSelected={noop}
      onResponseOptionSelected={onAnswerSelected}
      selectedIndex={selectedIndex}
      responseOptions={impactQuestionnaireQuestions[0].responseOptions ?? []}
    />
  );
}

export default ImpactQuestionnaireQuestions;
