import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategoryRelax = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 26" fill="none" {...props}>
    <path
      d="M15.678 3.966v-.104A3.262 3.262 0 0 0 12.414.598a3.262 3.262 0 0 0-3.265 3.264c0 .667.207 1.287.552 1.805-.046.046-.08.092-.115.138a3.147 3.147 0 0 0-1.505-.38A3.262 3.262 0 0 0 4.816 8.69a3.262 3.262 0 0 0 3.265 3.264 3.244 3.244 0 0 0 2.505-1.195c.448.241.954.379 1.506.379a3.24 3.24 0 0 0 2.253-.908c.115.011.23.034.345.034a3.233 3.233 0 0 0 3.23-3.23c0-1.436-.955-2.643-2.253-3.068h.011z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.965 11.966A5.033 5.033 0 0 0 16.931 17a5.033 5.033 0 0 0 5.034 5.035A5.033 5.033 0 0 0 27 17a5.033 5.033 0 0 0-5.035-5.034zm0 7.114a2.08 2.08 0 1 1 0-4.16 2.08 2.08 0 0 1 0 4.16z"
      fill="#FFA76F"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.39 9.92c-.77 0-1.39.62-1.39 1.39v9.403c0 2.586 2.483 4.69 5.552 4.69h9.873c3.07 0 5.552-2.093 5.552-4.69V11.31c0-.77-.62-1.39-1.39-1.39H2.39z"
      fill="#FFA76F"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategoryRelax;
