import { rem } from 'polished';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'Shared/Form/Formik';
import styled from 'styled-components';
import { AccessibilityLabel } from './styles';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${rem(40)};
  justify-content: space-between;
  width: 100%;

  ul {
    max-height: ${rem(200)};
    overflow: auto;
  }

  & > div:last-child {
    margin-left: ${rem(4)};
  }

  & > div {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.border.secondary};
    border-radius: ${rem(12)};
  }

  & > div > button {
    background-color: transparent;
  }
`;

const getDropdownContent = () => {
  const date = new Date();
  const times: string[] = [];

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  while (date.getHours() < 24) {
    const timeString = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });

    times.push(timeString);

    if (date.getMinutes() === 45 && date.getHours() === 23) {
      break;
    }

    if (date.getMinutes() === 45) {
      date.setHours(date.getHours() + 1);
      date.setMinutes(0);
    } else {
      date.setMinutes(date.getMinutes() + 15);
    }
  }

  return times;
};

interface TimePickerDropdownProps {
  onSelect(field: string, value: number | string | Date): void;
  date: Date;
}

export const TimePickerDropdown = ({
  onSelect,
  date,
}: TimePickerDropdownProps) => {
  const { t: translate } = useTranslation('shared');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const time = `${hours}:${minutes}`;

  const cachedValue: string[] = useMemo(getDropdownContent, []);

  return (
    <FormikSelect
      name={'date'}
      // there is no label as there is always a default value however we need this to add the accessibility information
      LabelComponent={a11yProps => (
        <AccessibilityLabel {...a11yProps}>{''}</AccessibilityLabel>
      )}
      options={cachedValue.map((option: string) => ({
        label: option,
        value: option,
      }))}
      aria-label={translate(
        'add_to_calendar.time_dropdown.accessibility_label',
        { value: time },
      )}
      value={time}
      onChange={(value: string) => {
        const dateWithTime = date;
        dateWithTime?.setHours(parseInt(value.split(':')[0], 10));
        dateWithTime?.setMinutes(parseInt(value.split(':')[1], 10));
        if (dateWithTime) {
          onSelect('date', dateWithTime);
        }
      }}
      placeholder={translate('add_to_calendar.time_dropdown.placeholder', {
        value: time,
      })}
    />
  );
};
