import React from 'react';
import { IconProps } from '../IconProps';

const SvgExploreCategoryOvercome = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 30 28" fill="none" {...props}>
    <path
      d="M23.071 10.551c.035.81.278 1.621.707 2.293 1.158 1.841 3.115 2.895 5.222 3.543M12.244 15.368c1.494-.764 2.328-2.582 2.895-4.168.58-1.656 1.042-3.463 2.142-4.887.869-1.135 2.42-1.783 3.833-1.401.822.22 1.552.822 1.888 1.61.602 1.435-.348 3.068-1.633 3.763M2.946 21.934a8.03 8.03 0 0 0 3.288.672c4.482 0 4.482-3.01 8.963-3.01 4.481 0 4.493 3.01 8.986 3.01a8.12 8.12 0 0 0 2.536-.382M8.504 27.087c2.409-.776 3.138-2.675 6.693-2.675 3.358 0 4.204 1.69 6.311 2.536"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.197 14.766c4.493 0 4.493 3.011 8.986 3.011 1.899 0 3.138-.533 4.296-1.158a.635.635 0 0 0-.081-.104c-.243-.243-.567-.37-.88-.51-1.112-.498-2.189-1.123-3.046-2.003-.764-.787-1.598-2.061-1.494-3.22.047-.624.209-1.273.302-1.898l.52-3.602-6.658-1.517-5.581 8.419.243 3.393c.845-.463 1.853-.834 3.404-.834l-.011.023z"
      fill="#BAF2FF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 16.098l.394.208c1.308.741 2.64 1.471 4.852 1.471 4.481 0 4.481-3.01 8.963-3.01 4.48 0 4.492 3.01 8.985 3.01 2.039 0 3.335-.625 4.551-1.297h.209"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.382 16.306c1.309.741 2.64 1.483 4.852 1.483 3.196 0 4.122-1.529 6.01-2.397 1.494-.765 2.328-2.594 2.895-4.18.579-1.657 1.042-3.463 2.142-4.887.869-1.135 2.42-1.784 3.833-1.402.417.116.8.313 1.123.591l.116.081c-.984-.614-2.42-.162-2.999.845-.915 1.575.185 3.277 1.679 3.926h-.012s.035 0 .058.023c.15.058.29.116.44.15.186.047.371.082.568.093h.127c.186 0 .37 0 .544-.023.035 0 .058 0 .093-.011.093-.012.174-.024.266-.047h-.046c1.146-.243 2.177-1.03 2.698-2.073.846-1.725.405-3.902-.776-5.361-.96-1.204-2.431-1.992-3.96-2.154C14.409.233 11.561 7.61 8.168 11.86c-1.772 2.223-4.204 4.041-7.133 4.226l.034.047.325.173h-.012z"
      fill="#FFF9EF"
      stroke="#391F17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExploreCategoryOvercome;
