import RoutePath from 'App/RoutePath';
import SecondaryButton from 'Shared/SecondaryButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const SSOButton = styled(SecondaryButton)`
  width: 100%;
`;

interface SignUpWithSSOButtonProps {
  ssoProviderName?: string | null;
}

export const SignUpWithSSOButton = ({
  ssoProviderName,
}: SignUpWithSSOButtonProps) => {
  const { t: translate } = useTranslation('logged_out');
  const history = useHistory();

  const buttonText = ssoProviderName
    ? translate('sign_up.sso_signup_button.sso_provider', {
        sso_provider: ssoProviderName,
      })
    : translate('sign_up.sso_signup_button.default');

  return (
    <SSOButton
      onClick={() => {
        history.push(RoutePath.LoginWithSSO);
      }}
      label={buttonText}
    />
  );
};
