import React, { SyntheticEvent } from 'react';
import { DEFAULT_VOLUME } from './VideoPlayerContainer';

export interface VideoPlayerProps {
  videoSrc: string;
  handlePlay(): void;
  handlePause(): void;
  handleEnded(event: React.SyntheticEvent<HTMLVideoElement>): void;
  handleError(event: React.SyntheticEvent<HTMLVideoElement>): void;
  handleTimeUpdate(currentTime: number): void;
  handleVolumeUpdate(currentVolume: number): void;
  handleDurationAndLoading(event: React.SyntheticEvent<HTMLVideoElement>): void;
  handleLoading(event: React.SyntheticEvent<HTMLVideoElement>): void;
  handleLoaded(event: React.SyntheticEvent<HTMLVideoElement>): void;
  autoplay?: boolean;
  refToForwardToNativeVideoElement: React.RefObject<HTMLVideoElement>;
}

export const VIDEO_PLAYER_TEST_ID = 'video-player';

export default class VideoPlayer extends React.Component<VideoPlayerProps> {
  componentDidMount() {
    this.props.handleVolumeUpdate(DEFAULT_VOLUME);
    if (this.props.autoplay) {
      this.play();
    }
  }

  play = () => {
    const player = this.props.refToForwardToNativeVideoElement.current;
    if (player) {
      player.pause();
      const playPromise = player.play();

      // IE11 doesn't support promises, so we check
      // here for it being undefined to prevent an
      // error. The IE11 case is handled by the
      // onPlay event of <audio/ >
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.props.handlePlay();
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };

  pause = () => {
    const player = this.props.refToForwardToNativeVideoElement.current;
    if (player) {
      player.pause();
      this.props.handlePause();
    }
  };

  togglePlay = () => {
    const player = this.props.refToForwardToNativeVideoElement.current;
    if (player) {
      if (player.paused || player.ended) {
        this.play();
      } else {
        this.pause();
      }
    }
  };

  handleTimeUpdate = ({ currentTarget }: SyntheticEvent<HTMLVideoElement>) => {
    this.props.handleTimeUpdate(currentTarget.currentTime);
  };

  handleVolumeUpdate = ({
    currentTarget,
  }: SyntheticEvent<HTMLVideoElement>) => {
    this.props.handleVolumeUpdate(currentTarget.volume * 100);
  };

  handleLoadedMetadata = (event: SyntheticEvent<HTMLVideoElement>) => {
    this.props.handleDurationAndLoading(event);
  };

  setProgress = (progress: number) => {
    const player = this.props.refToForwardToNativeVideoElement.current;
    if (player) {
      player.currentTime = progress;
    }
  };

  render() {
    return (
      /* eslint-disable jsx-a11y/media-has-caption */
      <video
        data-testid={VIDEO_PLAYER_TEST_ID}
        ref={this.props.refToForwardToNativeVideoElement}
        controls={false}
        src={this.props.videoSrc}
        autoPlay={false}
        onError={this.props.handleError}
        onPlay={this.props.handlePlay}
        onEnded={this.props.handleEnded}
        onTimeUpdate={this.handleTimeUpdate}
        onVolumeChange={this.handleVolumeUpdate}
        onLoadedMetadata={this.handleLoadedMetadata}
        onWaiting={this.props.handleLoading}
        onCanPlay={this.props.handleLoaded}
        style={{ width: '100%', height: '100%' }}
        playsInline={true}
      />
    );
  }
}
