import React from 'react';
import { IconProps } from '../IconProps';

const SvgSleeptales = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#FEF9E7" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.915 0H340v134.489c-1.7-1.959-3.41-3.943-5.132-5.939-13.286-15.409-27.276-31.632-42.524-44.128-23.645-19.377-50.955-7.497-78.87 4.646-27.702 12.051-55.999 24.36-81.9 6.639-21.3-14.572-29.843-44.665-37.987-73.355C91.413 14.694 89.267 7.136 86.915 0z"
      fill="#B28C00"
    />
  </svg>
);

export default SvgSleeptales;
