/**
 * Once we bump apollo-client to @apollo/client we need to replace
 * this with the built-in relay merge function.
 * https://github.com/apollographql/apollo-client/blob/ca13754f00853f13838fcbd3095ce578d37a60f2/src/utilities/policies/pagination.ts#L58
 */
import { OperationVariables } from '@apollo/client';

type ConnectionEdge = {
  node: unknown | null;
};

type UserError = {
  message: string;
};

type PageResult =
  | {
      edges: (ConnectionEdge | null)[] | null;
      count?: number | null;
      pageInfo: unknown | null;
    }
  | UserError;

function isErrorResult(result: PageResult): result is UserError {
  return (result as UserError).message !== undefined;
}

function filterNullNodeEdge(
  edge: ConnectionEdge | null,
): edge is { node: Record<string, unknown> } {
  return edge?.node !== null;
}

function makeEmptyData<
  TData extends {
    [K in FieldName]: PageResult;
  },
  FieldName extends keyof TData = keyof TData,
>(fieldName: FieldName): TData {
  const emptyData = {
    [fieldName]: {
      edges: null,
      pageInfo: {
        hasPreviousPage: false,
        hasNextPage: false,
        startCursor: '',
        endCursor: '',
      },
      count: null,
    },
  } as TData;

  return emptyData;
}

export function mergeRelayPageResult<
  TData extends {
    [key in FieldName]: PageResult;
  },
  FieldName extends string,
>(fieldName: FieldName) {
  return (
    previousQuery: TData,
    {
      fetchMoreResult,
    }: { fetchMoreResult?: TData | undefined; variables?: OperationVariables },
  ): TData => {
    if (!previousQuery || !fetchMoreResult) {
      return makeEmptyData(fieldName);
    }

    const existing = previousQuery[fieldName] || {};
    const incoming = fetchMoreResult[fieldName] || {};

    // Check for UserError message in either previousQuery or fetchMoreResult indicating an error
    if (!existing || isErrorResult(existing)) {
      return makeEmptyData(fieldName);
    }

    if (!incoming || isErrorResult(incoming)) {
      return previousQuery;
    }

    const mergedEdges = [...(existing?.edges || []), ...(incoming?.edges || [])]
      .filter(filterNullNodeEdge)
      .filter(
        (edge, index, self) =>
          self.findIndex(e => e.node.id === edge.node.id) === index,
      );

    return {
      ...previousQuery,
      [fieldName]: {
        ...existing,
        ...incoming,
        edges: mergedEdges,
        count: incoming?.count || existing.count,
        pageInfo: incoming.pageInfo || existing.pageInfo,
      },
    };
  };
}
