import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styles';
import { HeadingText } from '../../Shared/Typography';

interface ProgressIndicatorProps {
  totalSteps: number;
  currentStep: number;
  disabled?: boolean;
}

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

const ProgressBackground = styled.div`
  position: relative;
  height: 9px;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
  border: black solid 1px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
`;

const ProgressBar = styled.div<{ currentStep: number; totalSteps: number }>`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transform: translateX(
    ${props => (props.currentStep / props.totalSteps) * 100}%
  );
  transition: transform 250ms ease-out;
  background: ${({ theme }) => theme.colors.brand.primary};
  border-radius: 0 0 0 4px;
  border-right: ${({ currentStep, totalSteps }) =>
    currentStep !== totalSteps ? 'black solid 1px' : ''};
`;

export function ProgressIndicator(props: ProgressIndicatorProps) {
  const { totalSteps, currentStep, disabled } = props;
  const { t: translate } = useTranslation('logged_out');

  // Clamp the current step so it doesn't go below -1 and above the total number of steps
  const step = Math.min(Math.max(currentStep, 0), totalSteps);

  return disabled ? (
    <ProgressBarContainer data-testid="progress-indicator"></ProgressBarContainer>
  ) : (
    <ProgressBarContainer data-testid="progress-indicator">
      <Title>
        {translate('sign_up.progress_indicator.title', {
          step,
          total_steps: totalSteps,
        })}
      </Title>
      <ProgressBackground>
        <ProgressBar
          role="progressbar"
          aria-valuenow={step}
          aria-valuemin={0}
          aria-valuemax={totalSteps}
          data-testid="progress-indicator-bar"
          currentStep={step}
          totalSteps={totalSteps}
        />
      </ProgressBackground>
    </ProgressBarContainer>
  );
}
