import { rem } from 'polished';
import { styled, ThemeInterface } from 'styles';
import BodyText from './BodyText';

const FormError = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    color: theme.colors.text.error,
    sizes: [theme.typography.fontSizes.fontSize16],
    role: 'alert',
  }),
)`
  margin: ${rem(15)} 0;

  a {
    color: inherit;
  }
`;

export default FormError;
