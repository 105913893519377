import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsLearn = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={primaryColor}
      strokeWidth={2.857}
    >
      <circle cx={28} cy={28} r={20} />
      <path d="M24.667 24.667H28V38M24.667 38h6.666M28 16.34a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667" />
    </g>
  </svg>
);

export default SvgToolsLearn;
