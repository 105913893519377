import React from 'react';
import { useTimerState } from './TimerContext';

/**
 * Components wrapped in this HOC must be descendents of a component that
 * has the TimerProvider.
 * The context is available within the timerState prop.
 *
 * @param WrappedComponent The component that will consume the context
 */
export const withTimerState =
  (WrappedComponent: React.ComponentType<any>) => (props: any) => {
    const context = useTimerState();

    return <WrappedComponent timerState={context} {...props} />;
  };

export default withTimerState;
