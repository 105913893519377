import React from 'react';
import { IconProps } from '../IconProps';

const SvgSoundscapes = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#FFF8F0" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.068 101.494c33.254-11.435 71.728-24.664 108.41-99.505.329-.672.657-1.335.985-1.989H340v192H93.385a382.435 382.435 0 0 1 4.076-15.1c16.255-55.685 42.672-64.769 73.607-75.406z"
      fill="#BD6702"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.176 0H0v73.812c2.738-1.955 5.64-6.81 9.184-12.736 7.748-12.959 18.557-31.036 37.389-35.099C70.377 20.843 91.99 8.374 95.176 0z"
      fill="#644F00"
    />
  </svg>
);

export default SvgSoundscapes;
