import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { AddToCalendarField } from './styles';

const EditableTextFieldInput = styled.input`
  border: none;
  width: ${rem(316)};
  text-overflow: ellipsis;
  background-color: transparent;
`;

const HiddenA11yLabel = styled.label`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

interface EditableTextFieldProps {
  value?: string;
  icon: React.ReactNode;
  onChange(field: string, value: string): void;
  field: string;
  accessibleLabel: string;
}

export const EditableTextField = ({
  icon,
  onChange,
  value,
  field,
  accessibleLabel,
}: EditableTextFieldProps) => (
  <AddToCalendarField>
    <HiddenA11yLabel htmlFor="search">{accessibleLabel}</HiddenA11yLabel>
    {icon}
    <EditableTextFieldInput
      tabIndex={0}
      onChange={e => {
        onChange(field, e.target.value);
      }}
      type="text"
      value={value}
      aria-label={field}
    />
  </AddToCalendarField>
);
