import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const LastLocationContext = createContext<string>('');

interface LastLocationProps {
  children: ReactNode;
}

const LastLocationProvider = ({ children }: LastLocationProps) => {
  const location = useLocation();

  const [recentLocations, setrecentLocations] = useState<{
    current: string;
    last: string;
  }>({
    current: '',
    last: '',
  });

  useEffect(() => {
    setrecentLocations({
      current: location.pathname,
      last: recentLocations.current,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <LastLocationContext.Provider value={recentLocations.last}>
      {children}
    </LastLocationContext.Provider>
  );
};

export default LastLocationProvider;
