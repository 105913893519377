import { rem } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { CategoryHeaderWave, IconType } from 'icons';
import DynamicIcon from '../../../Shared/DynamicIcon/DynamicIcon';
import { HeadingText } from '../../../Shared/Typography';
import BodyText from '../../../Shared/Typography/BodyText';
import { small, medium } from '../../../utils';
import BreadcrumbNavigation from '../../BreadcrumbNavigation/BreadcrumbNavigation';
import Container from '../../../Shared/Container';

const OuterContainer = styled.div``;

const BackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding-top: ${rem(32)};

  ${small(css`
    padding-top: ${rem(24)};
  `)}

  ${medium(css`
    padding-top: ${rem(30)};
  `)}
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: left;
`;

const SubHeading = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  padding-top: 8px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CardIcon = styled(DynamicIcon)`
  aspect-ratio: 1;
  width: 24px;
  height: 24px;
  margin-right: 9px;
`;

const CategoryWave = styled(DynamicIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.secondary,
}))`
  width: 100%;
  height: 32px;

  ${medium(css`
    height: 48px;
  `)}
`;

interface Props {
  title: string;
  summary?: string;
  icon?: IconType;
}

const WaveHeader = ({ title, summary, icon }: Props) => (
  <OuterContainer>
    <BackgroundContainer>
      <HeaderContainer>
        <HeadingWrapper>
          <Container>
            <BreadcrumbNavigation />
            <HeadingContainer>
              {icon ? <CardIcon Icon={icon} /> : null}
              <StyledHeading aria-roledescription="heading">
                {title}
              </StyledHeading>
            </HeadingContainer>
            {summary ? (
              <SubHeading aria-roledescription="subheading">
                {summary}
              </SubHeading>
            ) : null}
          </Container>
        </HeadingWrapper>
      </HeaderContainer>
    </BackgroundContainer>
    <CategoryWave Icon={CategoryHeaderWave}></CategoryWave>
  </OuterContainer>
);

export default WaveHeader;
