import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsCalm = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.04 15.684C10.745 16.69 10 18.168 10 20.15c0 2.258.151 5.289 1.251 7.732.542 1.204 1.3 2.228 2.352 2.955 1.047.723 2.455 1.2 4.385 1.2 1.93 0 3.34-.477 4.39-1.2 1.055-.728 1.814-1.753 2.36-2.956C25.842 25.438 26 22.407 26 20.152c0-1.98-.75-3.458-2.05-4.467-1.334-1.034-3.345-1.648-5.962-1.648-2.617 0-4.622.614-5.948 1.646zm-1.229-1.579c1.789-1.391 4.278-2.068 7.177-2.068s5.393.677 7.187 2.068C27.001 15.52 28 17.6 28 20.15c0 2.277-.143 5.69-1.44 8.555-.657 1.451-1.625 2.798-3.048 3.778-1.429.985-3.248 1.553-5.524 1.553s-4.094-.568-5.521-1.553c-1.421-.981-2.386-2.328-3.04-3.78C8.138 25.839 8 22.428 8 20.152c0-2.55.99-4.63 2.811-6.046z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.566 7.037C9.76 7.037 5 11.902 5 17.973c0 3.817 2.54 7.23 5.618 9.225l-1.087 1.678C6.089 26.646 3 22.682 3 17.973 3 10.858 8.596 5.037 15.566 5.037c1.924 0 3.535.33 4.765.907 1.22.572 2.15 1.434 2.51 2.53.376 1.139.064 2.307-.756 3.272-.8.942-2.087 1.71-3.785 2.245l-.6-1.908c1.48-.466 2.386-1.073 2.86-1.632.456-.535.5-.991.382-1.352-.133-.404-.55-.917-1.46-1.344-.9-.423-2.205-.718-3.915-.718z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 18.453c0-5.177-4.506-9.416-9.5-9.416v-2c6.05 0 11.5 5.086 11.5 11.416 0 4.586-3.32 8.66-6.895 10.476l-.906-1.784C28.282 25.58 31 22.105 31 18.453z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9 5.11c-2.338 1.023-4.133 2.63-5.006 4.374L7.106 8.59c1.127-2.256 3.332-4.148 5.993-5.313 2.67-1.168 5.878-1.639 9.095-.97C26.67 3.15 29 6.567 29 10.036h-2c0-2.528-1.667-5.108-5.184-5.767l-.02-.004c-2.78-.579-5.569-.174-7.895.843zM14 20.037a1 1 0 0 0-1 1 1 1 0 1 1-2 0 3 3 0 1 1 6 0 1 1 0 1 1-2 0 1 1 0 0 0-1-1zM15.69 27.313a1 1 0 0 0-1.389 1.44l.699-.716c-.699.715-.699.716-.698.716l.002.002.003.003.008.008.02.019.061.054a4.805 4.805 0 0 0 .917.594c.623.309 1.524.604 2.688.604 1.163 0 2.064-.296 2.686-.605.31-.154.549-.31.717-.435a3.513 3.513 0 0 0 .26-.212l.021-.02.008-.007.003-.003.002-.002-.698-.716.699.715a1 1 0 0 0-1.389-1.44l-.01.01a2.82 2.82 0 0 1-.504.32c-.383.19-.981.395-1.796.395-.814 0-1.413-.205-1.796-.395a2.818 2.818 0 0 1-.505-.32l-.01-.01zM22 20.037a1 1 0 0 0-1 1 1 1 0 1 1-2 0 3 3 0 1 1 6 0 1 1 0 1 1-2 0 1 1 0 0 0-1-1z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsCalm;
