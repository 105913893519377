import { get } from 'lodash';
import { ToolMediaFragment } from './types';

/**
 * Determine if the passed Tool has an audio media type.
 */
const isAudioTool = (tool: ToolMediaFragment) =>
  get(tool, 'mediaType.value') === 'audio';

export default isAudioTool;
