import React from 'react';
import { IconProps } from '../IconProps';
const SvgShieldCheck = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.074 1.185c-1.034 0-1.89.854-1.89 1.888v3.594c0 5.208 3.162 10.083 8.075 11.999.21.082.459.149.732.15.28.002.535-.066.75-.15 4.914-1.916 8.075-6.79 8.075-11.999V3.073a1.901 1.901 0 00-1.89-1.888H3.075z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.882 5.516c.343.346.374.944.069 1.333l-4.439 5.668c-.287.367-.775.422-1.12.128l-2.22-1.89c-.368-.312-.442-.904-.167-1.322.276-.417.798-.502 1.165-.189l1.607 1.368 3.93-5.018c.305-.39.831-.425 1.175-.078z"
      fill={secondaryColor}
    />
  </svg>
);
export default SvgShieldCheck;
