import React from 'react';
import { IconProps } from '../IconProps';

const SvgPlusOneHero = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 377 264" fill="none" {...props}>
    <path
      d="M332.627 233.55c34.361-21.886 51.989-63.189 41.23-102.444-6.707-24.58-24.321-46.579-65.707-46.959-94.523-.942-140.277-115.514-240.14-75.774-99.864 39.741-76.332 183.304 73.922 239.191 88.245 32.867 152.599 10.25 190.695-14.014z"
      fill="#F7F5F9"
    />
    <path d="M141.735 116.543H45.781v58.328h95.954v-58.328z" fill="#F6EBFE" />
    <path
      d="M45.78 116.614v58.327c0 20.472-25.01 26.564-37.975 38.425-10.568 9.652-7.341 12.227-7.341 12.227 2.261-13.162 26.773 9.779 26.364-4.136-.408-13.916 27.753 14.414 38.8-6.649 9.47-18.051-12.153-22.512-7.158-52.403 5.573-33.353 0-45.791 0-45.791H45.78zM338.052 15H237.483c-.778 0-1.409.63-1.409 1.407V45.02c0 .777.631 1.407 1.409 1.407h100.569c.778 0 1.409-.63 1.409-1.407V16.408c0-.778-.631-1.408-1.409-1.408z"
      fill="#8773B9"
    />
    <path
      d="M342.251 143.516H238.893V19.221a1.406 1.406 0 0 1 1.409-1.407h100.54a1.409 1.409 0 0 1 1.409 1.407v124.295zM290.586 49.24v31.425"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M233.904 154.265H347.26c.779 0 1.409-.63 1.409-1.407v-7.935c0-.777-.63-1.407-1.409-1.407H233.904c-.778 0-1.409.63-1.409 1.407v7.935c0 .777.631 1.407 1.409 1.407z"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path d="M242.902 143.515V49.239h-4.009v94.276h4.009z" fill="#333" />
    <path
      d="M290.586 89.522a4.492 4.492 0 0 0 4.495-4.488 4.492 4.492 0 0 0-4.495-4.489 4.491 4.491 0 0 0-4.495 4.489 4.491 4.491 0 0 0 4.495 4.488zM342.279 49.24H238.921V19.368a1.555 1.555 0 0 1 1.55-1.555h100.258a1.555 1.555 0 0 1 1.55 1.555v29.87z"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path d="M47.895 224.039v11.34h4.037v-10.553" fill="#333" />
    <path
      d="M47.895 224.039v11.34h4.037v-10.553"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M47.895 177.051v-58.327h95.953v116.655H47.895v-11.341m-3.98-1.161v17.349h103.921V116.979a3.115 3.115 0 0 0-3.107-3.102H45.464a1.556 1.556 0 0 0-1.55 1.555V222.877zM38.89 251.032h113.955c.779 0 1.409-.63 1.409-1.407v-7.992c0-.777-.63-1.407-1.409-1.407H38.89c-.778 0-1.409.63-1.409 1.407v7.992c0 .777.631 1.407 1.41 1.407zM119.766 134.046l-20.15 20.128M93.022 146.646l-10.766 10.757"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M47.895 118.725v58.327c0 20.472-25.012 26.564-37.976 38.425-10.568 9.652-7.341 12.227-7.341 12.227 2.261-13.163 26.773 9.779 26.364-4.137-.409-13.915 27.753 14.415 38.8-6.648 9.47-18.052-12.154-22.512-7.158-52.404 5.573-33.352 0-45.79 0-45.79h-12.69zM143.848 177.052H47.894"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M27.554 227.612s5.813-2.385 8.589-7.31c0 0-6.7-2.321-7.046 1.407-.345 3.729 1.022 4.116-1.543 5.903zM2.578 227.703s4.643 1.541 7.045-4.122c-.014 0-5.791-1.773-7.045 4.122z"
      fill="#333"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M29.809 220.246s8.454-6.254 10.843-11.6"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M257.831 93.482l-44.436 8.189 8.589 6.972 1.437 10.116 6.517-4.615 6.44 4.453 21.453-25.115z"
      fill="#fff"
    />
    <path
      d="M257.831 97l-44.436 8.189 8.589 6.972 1.437 10.116 6.517-4.615 6.44 4.453 21.453-25.114z"
      fill="#FCAEAE"
    />
    <path
      d="M229.938 114.144l-6.517 4.615-1.438-10.116 35.848-15.16"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M236.377 118.597l-10.554-7.288 32.008-17.827-21.454 25.115zM221.984 108.643l-8.589-6.972 44.436-8.189-35.847 15.161zM225.823 111.309l-2.402 7.45"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M213.416 114.264c-46.36 20.212-63.41-8.533-52.433-19.888 12.682-13.106 38.588 9.512 28.245 48.373-7.651 28.752 16.409 17.728 25.998 36.877 4.453 8.893 1.346 15.259-3.255 19.53 0 0-7.01 6.915-23.821 2.814-16.81-4.102-27.717-2.463-32.543 8.927"
      stroke="#333"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
    <path
      d="M114.848 46.723l-4.21 2.427 6.936 11.997 4.21-2.428-6.936-11.996zM273.62 207.239l-14.098-1.414 9.286 10.693 4.812-9.279z"
      stroke="#333"
      strokeLinejoin="round"
    />
    <path
      d="M335.367 133.773c2.974-6.768 2.974-1.112 2.227 1.744 0 0 2.705.746-2.706 5.769 0 0 2.438 2.033-11.428 2.216.007-.035 8.927-3.004 11.907-9.729zM279.785 110.127l-2.114-1.273a1.408 1.408 0 0 1-.655-1.287l.092-1.189-.127-1.105a.982.982 0 0 1 .409-.914l.521-.366 4.763-.577a3.694 3.694 0 0 0 2.184-1.062l.338-.331a3.708 3.708 0 0 1 2.212-1.062l4.439-.514 6.151 2.596a4.925 4.925 0 0 1 2.945 3.736l.549 3.405a3.441 3.441 0 0 1-1.331 3.299l-2.346 1.752 1.021 3.637 4.171 3.391a11.261 11.261 0 0 1 2.579 2.99l10.857 18.213h-23.271c-.423 1.133-1.191 2.223-2.593 2.315-3.001.211-2.48-2.287-2.48-2.287h.063-7.045a.814.814 0 0 1-.606-1.364 3.579 3.579 0 0 1 2.86-1.217l-.472-4.925a17.133 17.133 0 0 1 1.691-9.23l2.522-5.114-1.775-7.577-6.123-.767-.944-.556a.97.97 0 0 1 .557-1.808l2.438.155.704-.309-.641-.071a3.703 3.703 0 0 1-1.543-.584z"
      fill="#333"
    />
    <path
      d="M279.785 110.128l-2.114-1.274a1.401 1.401 0 0 1-.655-1.287l.092-1.189-.127-1.105a.982.982 0 0 1 .409-.914l.521-.366 4.763-.577a3.694 3.694 0 0 0 2.184-1.062l.338-.331a3.708 3.708 0 0 1 2.212-1.062s-2.726.957-2.064 3.398c.529 1.934 3.192 4.446 1.867 7.344 2.72 3.926 6.954.493 6.954.493-.148 3.405 3.65 3.039 3.65 3.039s-2.318 2.582-5.094 2.11c-2.776-.471-1.008 1.154-.486 2.814 1.176 3.701-2.516 2.737 0 7.697 2.515 4.959-3.037 6.465.93 15.66 0 0-.62 2.314-2.593 2.314-1.973 0-2.403-1.02-2.48-2.286h-7.046a.899.899 0 0 1-.671-.519.889.889 0 0 1 .065-.846c.508-1.126 1.663-1.161 2.861-1.217a51.516 51.516 0 0 1-.522-7.527c0-4.144 4.263-11.763 4.263-11.763l-1.902-7.598-5.996-.746-.944-.555a.968.968 0 0 1 .556-1.808l2.438.154.705-.309-.641-.07a3.717 3.717 0 0 1-1.473-.612z"
      fill="#fff"
    />
    <path
      d="M277.925 103.993s1.212.809.768 1.618c-.444.809-1.656 0-1.656 0l-.105-.914.993-.704z"
      fill="#333"
    />
    <path
      d="M279.785 110.127l-2.114-1.273a1.408 1.408 0 0 1-.655-1.287l.092-1.189-.127-1.105a.982.982 0 0 1 .409-.914l.521-.366 4.763-.577a3.694 3.694 0 0 0 2.184-1.062l.338-.331a3.708 3.708 0 0 1 2.212-1.062l4.439-.514 6.151 2.596a4.925 4.925 0 0 1 2.945 3.736l.549 3.405a3.441 3.441 0 0 1-1.331 3.299l-2.346 1.752 1.021 3.637 4.171 3.391a11.261 11.261 0 0 1 2.579 2.99l10.857 18.213h-23.271c-.423 1.133-1.191 2.223-2.593 2.315-3.001.211-2.48-2.287-2.48-2.287h.063-7.045a.814.814 0 0 1-.606-1.364 3.579 3.579 0 0 1 2.86-1.217l-.472-4.925a17.133 17.133 0 0 1 1.691-9.23l2.522-5.114-1.775-7.577-6.123-.767-.944-.556a.97.97 0 0 1-.408-1.217v0a.985.985 0 0 1 .965-.591l2.438.155.704-.309-.641-.071a3.703 3.703 0 0 1-1.543-.584v0z"
      stroke="#333"
      strokeMiterlimit={10}
    />
    <path
      d="M286.161 143.466c-.585-2.617.775-3.229.388-5.367v-.071h.366a.883.883 0 0 1 .881.873c0 1.765.14 3.517.225 4.565h-1.86z"
      fill="#333"
    />
  </svg>
);

export default SvgPlusOneHero;
