import React from 'react';
import { styled } from 'styles';
import LowerPurpleWave from './assets/LowerPurpleWave';
import UpperGreyWave from './assets/UpperGreyWave';

const PositionedGreyWave = styled(UpperGreyWave)`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 320px;
`;

const PositionedPurpleWave = styled(LowerPurpleWave)`
  position: fixed;
  left: 0;
  bottom: 0;
  max-width: 320px;
`;

const OverlayBackground = () => (
  <>
    <PositionedGreyWave />
    <PositionedPurpleWave />
  </>
);

export default OverlayBackground;
