import React from 'react';
import { IconProps } from '../IconProps';

const SvgVideoCamera = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M8.653 3.313H1.847c-.718 0-1.41.622-1.41 1.312v5.25c0 .69.692 1.313 1.41 1.313h6.806c.718 0 1.41-.623 1.41-1.313v-5.25c0-.69-.692-1.313-1.41-1.313zm-6.806.874h6.806c.254 0 .534.253.534.438v5.25c0 .185-.28.438-.534.438H1.847c-.254 0-.534-.253-.534-.438v-5.25c0-.185.28-.438.534-.438z"
      fill={primaryColor}
    />
    <path
      d="M12.885 3.822l-3.5 2.297a.438.438 0 0 0-.197.365v1.532c0 .147.074.285.197.365l3.5 2.297c.29.191.678-.018.678-.366V4.188a.437.437 0 0 0-.678-.365zm-.197 1.176V9.5l-2.626-1.723V6.721l2.626-1.723z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgVideoCamera;
