import gql from 'graphql-tag';

export const USER_COURSES_QUERY = gql`
  query getUserCourses($input: GetUserCoursesInput) {
    getUserCourses(input: $input) {
      edges {
        node {
          id
          title
          subtitle
          slug
          totalDays
          daysCompleted
          cloudinaryThumbnail {
            path
          }
          exploreCategory {
            id
            colourTheme
            internalName
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;
