import { FormikErrors } from 'formik';
import {
  isNonEmptyString,
  isValidEmail,
} from '../../../Shared/Form/inputValidators';
import { isPasswordValid } from '../../../Shared/ValidatorMatch/PasswordValidator';
import { VoucherCodeFormProps, VoucherFormFields } from './types';

type Validator = {
  validate(value?: string | boolean): boolean;
  message: string;
};

export const errorMessages = {
  department: 'Department is required',
  emailRequired: 'Email is required',
  emailInvalid: 'Email is invalid',
  firstName: 'First name is required',
  lastName: 'Last name is required',
  location: 'Location is required',
  password: 'Password is required',
  voucher: 'Voucher is required',
};

const formValidators: { [key in keyof VoucherFormFields]: Validator[] } = {
  department: [
    {
      validate: isNonEmptyString,
      message: errorMessages.department,
    },
  ],
  email: [
    { validate: isNonEmptyString, message: errorMessages.emailRequired },
    { validate: isValidEmail, message: errorMessages.emailInvalid },
  ],
  firstName: [{ validate: isNonEmptyString, message: errorMessages.firstName }],
  lastName: [{ validate: isNonEmptyString, message: errorMessages.lastName }],
  location: [{ validate: isNonEmptyString, message: errorMessages.location }],
  password: [{ validate: isPasswordValid, message: errorMessages.password }],
  voucher: [{ validate: isNonEmptyString, message: errorMessages.voucher }],
};

type CreateValidatorProps = Pick<
  VoucherCodeFormProps,
  'departments' | 'locations'
>;

export const createValidator =
  ({ departments, locations }: CreateValidatorProps) =>
  (values: VoucherFormFields): FormikErrors<VoucherFormFields> =>
    Object.keys(values).reduce((errors, key) => {
      const value = key as keyof VoucherFormFields;
      const validators = formValidators[value];

      if (
        (!locations.length && value === 'location') ||
        (!departments.length && value === 'department')
      ) {
        return errors;
      }

      for (const validator of validators) {
        if (!validator.validate(values[value])) {
          return { ...errors, [value]: validator.message };
        }
      }

      return errors;
    }, {});
