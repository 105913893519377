import React from 'react';
import { IconProps } from '../IconProps';
const SvgLightning = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M6.803.776v4.018h3.616l-5.223 6.429V7.205H1.58L6.803.776z"
      fill={primaryColor}
    />
    <path
      d="M6.803.776v4.018h3.616l-5.223 6.429V7.205H1.58L6.803.776z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLightning;
