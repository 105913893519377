import { BodyText, Button } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { NotificationTypes } from '../types';

const PreferenceTypeContainer = styled.div`
  margin: ${rem(48)} 0;
`;

const Divider = styled.hr`
  border: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
  margin-top: ${rem(24)};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled(BodyText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize20],
}))``;

const SubscriptionsText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.regular,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-top: ${rem(16)};
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.secondary,
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

export type NotificationPreferenceTypeProps = {
  title: string;
  description: string;
  subscriptions: {
    isTeamsEnabled: boolean;
    isEmailEnabled: boolean;
  };
  onClick(): void;
  testId: NotificationTypes;
  hideTeamsPreferences?: boolean;
};

export const NotificationPreferenceType = ({
  title,
  description,
  subscriptions,
  onClick,
  hideTeamsPreferences,
  testId,
}: NotificationPreferenceTypeProps) => {
  const { t: translate } = useTranslation('account');
  const emailPreferenceCentreIsEnabled = useFeatureFlag(
    FEATURE_FLAGS.SHOW_EMAIL_NOTIFICATIONS,
  );
  const allSubscriptionsDisabled = emailPreferenceCentreIsEnabled
    ? !Object.values(subscriptions).some(Boolean)
    : !subscriptions.isTeamsEnabled;

  const subscriptionsToShow = [];

  if (!allSubscriptionsDisabled) {
    if (subscriptions.isTeamsEnabled && !hideTeamsPreferences)
      subscriptionsToShow.push(
        translate('notification_preference_centre.preference_display.teams'),
      );

    if (subscriptions.isEmailEnabled && emailPreferenceCentreIsEnabled)
      subscriptionsToShow.push(
        translate('notification_preference_centre.preference_display.email'),
      );
  }

  return (
    <PreferenceTypeContainer data-testid={`${testId}-preference-type`}>
      <Container>
        <Heading>{title}</Heading>
        <Button
          label={translate('notification_preference_centre.edit_button')}
          onClick={onClick}
        ></Button>
      </Container>
      <Description>{description}</Description>
      {allSubscriptionsDisabled ? (
        <SubscriptionsText>
          {translate('notification_preference_centre.preference_display.off')}
        </SubscriptionsText>
      ) : (
        <SubscriptionsText>
          {translate('notification_preference_centre.preference_display.on', {
            preference_list: subscriptionsToShow.join(', '),
          })}
        </SubscriptionsText>
      )}
      <Divider />
    </PreferenceTypeContainer>
  );
};
