import React from 'react';

import { styled } from 'styles';
import ReadButton from './ReadButton';

interface MediaSegmentButtonsProps {
  onClickReadTranscript(): void;
  togglePlayer(): void;
  trackSeriesSegmentStarted(): void;
  MediaButton: React.FC;
}

export const SegmentButton = styled.button`
  width: 50%;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const MediaSegmentButtons: React.FC<MediaSegmentButtonsProps> = ({
  onClickReadTranscript,
  togglePlayer,
  trackSeriesSegmentStarted,
  MediaButton,
}) => (
  <>
    <SegmentButton
      data-testid="media-segment-button"
      onClick={() => {
        togglePlayer();
        trackSeriesSegmentStarted();
      }}
    >
      <MediaButton />
    </SegmentButton>
    <SegmentButton
      data-testid="transcript-segment-button"
      onClick={() => {
        onClickReadTranscript();
        trackSeriesSegmentStarted();
      }}
    >
      <ReadButton />
    </SegmentButton>
  </>
);
