import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { MoodFace } from '../../../Shared/MoodFace';
import { FieldSet } from '../../../Shared/Form/FieldSet';
import { TextArea } from '../../../Shared/Form/TextArea';
import { PrimaryButton } from '../../../Shared/PrimaryButton/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import { extraSmall, small, medium } from '../../../utils';
import useViewportWidth from '../../../utils/useViewportWidth';
import FieldError from '../../../Shared/Form/FieldError';
import { Mood } from '../types';
import { CheckInScores_feelings } from '../__generated__/CheckInScores';
import { filterCheckInFeelingsData } from '../MoodScores/helpers/filterCheckInData';
import BodyText from '../../../Shared/Typography/BodyText';
import Slider from './Slider';
import FeelingOption from './FeelingOption';

const SliderContainer = styled.div`
  margin: 0 auto;
  box-shadow: none;
  width: 85vw;

  ${small(css`
    width: ${rem(680)};
  `)}
`;

const Legend = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  color: theme.colors.text.primary,
  align: 'center',
  forwardedAs: 'legend',
}))``;

const FeelingPill = styled(FeelingOption)`
  flex: 0 0 auto;
  padding: ${rem(8)} ${rem(10)};
  min-width: 100%;

  ${extraSmall(css`
    min-width: 50%;
  `)}

  ${small(css`
    min-width: 33.3%;
  `)}

  ${medium(css`
    min-width: 25%;
  `)}
`;

const FeelingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${rem(28)} 0 ${rem(32)} 0;
`;

const CheckInSlide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Submit = styled(PrimaryButton)`
  margin: ${rem(30)} auto;
`;

export type MoodTrackerFormValues = {
  mood: Mood;
  feelingIds?: number[];
  note?: string;
};

export interface MoodTrackerFormProps {
  loading?: boolean;
  checkInMoods: Mood[];
  checkinFeelings: CheckInScores_feelings[];
  onSubmit(checkInFormValues: MoodTrackerFormValues): void;
  error: boolean;
}

const MoodTrackerForm = (props: MoodTrackerFormProps) => {
  const { t: translate } = useTranslation(['check_in', 'shared']);
  const viewportWidth = useViewportWidth();
  const isSmallScreen = viewportWidth < Breakpoints.XS;
  const SLIDE_SIZE = isSmallScreen ? 200 : 300;

  const [selectedMood, setSelectedMood] = useState(props.checkInMoods[3]);
  const [feelings, setFeelings] = useState<number[]>([]);
  const [note, setNote] = useState('');

  const onSlideChange = (index: number) => {
    setSelectedMood(props.checkInMoods[index]);
  };

  const getMoodSlides = () =>
    props.checkInMoods.map(mood => (
      <CheckInSlide key={mood}>
        <MoodFace
          mood={mood} // MoodFace still uses the old numeric values for mood
          active={mood === selectedMood}
          shadow={mood === selectedMood}
          size={SLIDE_SIZE - 100}
        />
      </CheckInSlide>
    ));

  const onFeelingSelect = (selected: boolean, id: string) => {
    const feelingId = Number(id);
    const feelingIndex = feelings.indexOf(feelingId);

    if (!selected && feelingIndex > -1) {
      const filteredFeelings = feelings.filter(
        feeling => feeling !== feelingId,
      );
      setFeelings(filteredFeelings);
    }

    if (selected && feelingIndex < 0) {
      setFeelings([...feelings, feelingId]);
    }
  };

  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event;
    const { value } = target;

    setNote(value);
  };

  return (
    <>
      <SliderContainer>
        <Slider
          ariaLabelledBy="mood"
          ariaValueText={props.checkInMoods}
          initialSlide={props.checkInMoods.indexOf(Mood.OK)}
          onSlideChange={onSlideChange}
          slideHeight={SLIDE_SIZE}
          slideWidth={SLIDE_SIZE}
        >
          {getMoodSlides()}
        </Slider>
      </SliderContainer>
      <FieldSet>
        <Legend>{translate('check_in:mood_prompt')}</Legend>
        <FeelingsContainer>
          {filterCheckInFeelingsData(props.checkinFeelings).map(feeling => (
            <FeelingPill
              label={String(feeling?.name)}
              id={String(feeling.id)}
              onSelectChange={onFeelingSelect}
              key={feeling.id}
            />
          ))}
        </FeelingsContainer>
      </FieldSet>
      <TextArea
        onChange={onChangeTextArea}
        placeholder={translate('check_in:note_placeholder')}
        aria-label={translate('check_in:note_placeholder')}
      />
      {props.error ? (
        <FieldError message={translate('shared:errors.messages.unexpected')} />
      ) : null}
      <Submit
        label={translate('check_in:submit_button.label')}
        size={ButtonSize.medium}
        onClick={() => {
          props.onSubmit({
            mood: selectedMood,
            feelingIds: feelings,
            note,
          });
        }}
        disabled={props.loading}
        loading={props.loading}
      />
    </>
  );
};

export default MoodTrackerForm;
