import { rem } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { small, medium } from '../../utils';
import Container from '../Container';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const BreadcrumbTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  display: inline-block;
  padding-bottom: ${rem('4px')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  margin-bottom: ${rem('20px')};

  ${small(css`
    margin-bottom: ${rem('30px')};
  `)}
`;

interface TitleProps {
  align?: string;
}

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.medium,
  accessibilityAutoFocus: true,
}))<TitleProps>`
  ${({ align }) => (align ? `text-align: ${align}` : '')};
  width: 100%;
  letter-spacing: -0.5px;
  &:focus {
    outline: none;
  }

  ${small(css`
    text-align: left;
  `)}
`;

const TitleContainer = styled(Container)`
  padding-top: ${rem('22px')}
  padding-bottom: ${rem('8px')};

  ${small(css`
    padding-top: ${rem('43px')}
    padding-bottom: ${rem('22px')};
  `)}

  ${medium(css`
    padding-top: ${rem('43px')}
    padding-bottom: ${rem('22px')};
  `)}
`;

interface PageTitleProps {
  breadcrumb?: string;
  children: string;
  subheading?: JSX.Element | null;
}

export const PageTitle = ({
  breadcrumb,
  children,
  subheading,
}: PageTitleProps) => {
  const align = breadcrumb ? 'left' : 'center';

  return (
    <TitleContainer>
      {breadcrumb ? (
        <div>
          <BreadcrumbTitle> {breadcrumb} </BreadcrumbTitle>
        </div>
      ) : null}
      <div>
        <Title align={align}>{children}</Title>
        {subheading}
      </div>
    </TitleContainer>
  );
};
