import React from 'react';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import {
  EapPrivacyContainer,
  EapPrivacyText,
  PrivacyLink,
  ExternalLinkIcon,
  LinkContainer,
} from './styles';

interface EapPrivacyPolicyProps {
  displayName: string;
  privacyLink: string;
  countryCode: string;
}

export const EapPrivacyPolicy: React.FC<EapPrivacyPolicyProps> = ({
  displayName,
  privacyLink,
  countryCode,
}) => {
  const { t: translate } = useTranslation('help');

  return (
    <EapPrivacyContainer>
      <EapPrivacyText>{translate('eap_privacy.description')}</EapPrivacyText>
      <LinkContainer>
        <EapPrivacyText>
          {translate('eap_privacy.privacy_link.description')}
        </EapPrivacyText>
        <PrivacyLink
          href={privacyLink}
          target="_blank"
          aria-label={translate('eap_privacy.privacy_link.a11y_label', {
            displayName,
          })}
          onClick={() => {
            tracking.track('help_eap_privacy_policy_clicked', {
              privacyPolicyLink: privacyLink,
              countryCode,
            });
          }}
        >
          {displayName}
          <ExternalLinkIcon />
        </PrivacyLink>
      </LinkContainer>
    </EapPrivacyContainer>
  );
};

export default EapPrivacyPolicy;
