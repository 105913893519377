import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { ContentTile, HeadingText } from '@unmind/design-system-components-web';
import { useQuery } from '@apollo/client';
import { FEATURED_CHEAT_SHEETS_QUERY } from 'Services/CheatSheets/cheatsheet.services';
import { FeaturedCheatSheets as FeaturedCheatSheetsQuery } from 'Services/CheatSheets/__generated__/FeaturedCheatSheets';
import { filterNonNull } from 'typescript/helpers';
import RoutePath from 'App/RoutePath';
import { SkeletonContentTile } from 'Shared/Skeleton';
import SectionError from 'Shared/SectionError/SectionError';

import { tracking } from 'App/Tracking';
import { medium, extraSmall } from '../../utils';

const RecommendationsWrapper = styled.div`
  margin-top: ${rem(35)};
`;

const RecommendationsSectionTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.medium,
  level: 1,
}))``;

const ContentCardWrapper = styled.div``;

const CheatSheetsWrapper = styled.div<{ numItems: number }>`
  margin-top: ${rem(24)};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${({ numItems }) =>
    extraSmall(css`
      grid-template-columns: repeat(${numItems > 2 ? numItems : 2}, 1fr);
    `)}

  ${medium(css`
    grid-gap: 32px;
  `)}
`;

type RecommendedCheatSheetsProps = {
  currentCheatSheetSlug: string | undefined;
  assetToken: string | null | undefined;
};

const RecommendedCheatSheets = ({
  assetToken,
  currentCheatSheetSlug,
}: RecommendedCheatSheetsProps) => {
  const { t: translate } =
    useTranslation<Namespace<'cheat_sheets'>>('cheat_sheets');

  const { data, loading, error, refetch } = useQuery<FeaturedCheatSheetsQuery>(
    FEATURED_CHEAT_SHEETS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
    },
  );

  if (loading && !data) {
    const numberOfLoadingTiles = 3;

    return (
      <CheatSheetsWrapper numItems={numberOfLoadingTiles}>
        {[...Array(numberOfLoadingTiles)].map((_, i) => (
          <SkeletonContentTile key={i} />
        ))}
      </CheatSheetsWrapper>
    );
  }

  if (error) {
    return <SectionError onRetry={async () => refetch()} />;
  }

  const recommendedCheatSheets = data?.featuredCheatSheets?.length
    ? data?.featuredCheatSheets
        .filter(filterNonNull)
        .filter(item => item.slug !== currentCheatSheetSlug)
    : [];

  return recommendedCheatSheets?.length ? (
    <RecommendationsWrapper>
      <RecommendationsSectionTitle>
        {translate('recommended_cheat_sheets.title')}
      </RecommendationsSectionTitle>
      <CheatSheetsWrapper numItems={recommendedCheatSheets.length}>
        {recommendedCheatSheets.map(item => {
          const contentItem = {
            itemType: 'cheatsheet' as 'cheatsheet',
            title: item.title,
            thumbnailUrl: `${item?.cloudinaryThumbnail?.path}?${assetToken}`,
            tags: [translate('recommended_cheat_sheets.content_tile.tags')],
          };

          return (
            <ContentCardWrapper key={item.id}>
              <ContentTile
                item={contentItem}
                to={`${RoutePath.CheatSheet}/${item.slug}`}
                hoverLabel={translate(
                  'recommended_cheat_sheets.content_tile.hover_label',
                )}
                onClick={() =>
                  tracking.track('content-selected', {
                    contentId: item.id,
                    contentSlug: item.slug,
                    contentType: contentItem.itemType,
                    source: 'recommended-cheat-sheets',
                  })
                }
              />
            </ContentCardWrapper>
          );
        })}
      </CheatSheetsWrapper>
    </RecommendationsWrapper>
  ) : null;
};

export default RecommendedCheatSheets;
