import { Colors } from '@unmind/design-system-theme';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'styles';
import { parse } from 'date-fns';
import formatPreciseNumber from 'utils/formatNumber/formatPreciseNumber';
import { AdminLineChart } from '../../../../Shared/Charts';
import { TimePeriod } from '../../../../__generated__/globalTypes';
import Panel, { PanelSection } from '../../Panel';
import {
  ANONYMITY_THRESHOLD,
  timeFilterBreakdownPeriod,
} from '../../constants';
import { useFilteredQuery, useFilterState } from '../../FilterState';
import Metric from '../../Metric';
import { BodyText } from '../../../../Shared/Typography';
import {
  UserActivity,
  UserActivity_organisationActiveUsers,
} from './__generated__/UserActivity';

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(32)};

  & > *:first-child {
    margin-right: ${rem(48)};
  }
`;

const CaptionContainer = styled.div`
  text-align: center;
`;

export const ACTIVE_USERS_QUERY: DocumentNode = gql`
  query UserActivity(
    $input: OrganisationInsightsQueryInput!
    $periodInput: BreakdownPeriod!
  ) {
    organisationActiveUsers(input: $input) {
      totalActiveUserCount
      activeUserCountByPeriod(period: $periodInput) {
        periodStart
        count
      }
      shouldBeAnonymised
    }
  }
`;

interface PanelContentsProps {
  organisationActiveUsers: UserActivity_organisationActiveUsers;
  panelColor: Colors;
}

const captionTemplates = {
  [TimePeriod.LAST_30_DAYS]: 'Daily active users in the last 30 days',
  [TimePeriod.LAST_12_WEEKS]: 'Weekly active users in the last 12 weeks',
  [TimePeriod.LAST_6_MONTHS]: 'Monthly active users in the last 6 months',
  [TimePeriod.ALL_TIME]: 'Monthly active users for all time',
};

const UserActivityPanelContents = ({
  organisationActiveUsers,
  panelColor,
}: PanelContentsProps) => {
  const { timeFilterPreset } = useFilterState();
  const { typography } = useTheme();

  const {
    totalActiveUserCount: activeUserCount,
    shouldBeAnonymised,
    activeUserCountByPeriod,
  } = organisationActiveUsers;
  const activeUserCountDisplayValue = shouldBeAnonymised
    ? `<${ANONYMITY_THRESHOLD}`
    : formatPreciseNumber(activeUserCount || 0);

  const userData =
    activeUserCountByPeriod?.map(({ periodStart, count }) => ({
      date: parse(periodStart, 'yyyy-MM-dd', new Date()),
      count,
    })) || [];

  return (
    <>
      <PanelSection>
        <MetricContainer>
          <Metric
            data-testid="user-insights-active"
            color={panelColor}
            title="Active users"
            value={activeUserCountDisplayValue ?? undefined}
          />
        </MetricContainer>
      </PanelSection>
      <AdminLineChart
        caption={captionTemplates[timeFilterPreset]}
        data={userData}
        isDataHidden={!!shouldBeAnonymised || !userData.length}
        height={216}
        title="Active users"
        description="The number of users who have logged in to Unmind over the selected period."
        dataPointsToDisplay={userData.length}
        dataLabelUnit="Active users"
      />
      <CaptionContainer>
        <BodyText sizes={[typography.fontSizes.fontSize12]}>
          {captionTemplates[timeFilterPreset]}
        </BodyText>
      </CaptionContainer>
    </>
  );
};
const UserActivityPanel = () => {
  const { colors } = useTheme();
  const { timeFilterPreset } = useFilterState();

  const panelColor = colors.staticPalette.topaz;

  const breakdownPeriod = timeFilterBreakdownPeriod[timeFilterPreset];

  const {
    data: { organisationActiveUsers } = {},
    loading,
    error,
  } = useFilteredQuery<UserActivity>(ACTIVE_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      periodInput: breakdownPeriod,
      input: {
        timePeriod: timeFilterPreset,
      },
    },
  });

  return (
    <Panel
      color={panelColor}
      title="Activity"
      isLoading={loading}
      hasError={!isNil(error) || !organisationActiveUsers}
    >
      {isNil(error) && !!organisationActiveUsers ? (
        <UserActivityPanelContents
          organisationActiveUsers={organisationActiveUsers}
          panelColor={panelColor}
        />
      ) : null}
    </Panel>
  );
};

export default UserActivityPanel;
