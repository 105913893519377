import i18n from '../i18n/config';

export const getPrivacyPolicyLink = (subdomain?: string) => {
  const privacyPolicyLinks = {
    nhs: i18n.t('shared:privacy_policy_link.nhs'),
    defaultLink: i18n.t('shared:privacy_policy_link.default'),
  };

  switch (subdomain) {
    case 'nhs':
      return privacyPolicyLinks.nhs;
    default:
      return privacyPolicyLinks.defaultLink;
  }
};
