import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavExplore = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.708c-5.688 0-10.299 4.611-10.299 10.3 0 5.687 4.611 10.298 10.299 10.298s10.299-4.61 10.299-10.299c0-5.688-4.611-10.299-10.299-10.299zm-12 10.3c0-6.628 5.373-12 12-12s12 5.372 12 12c0 6.627-5.373 12-12 12s-12-5.373-12-12z"
      fill={primaryColor}
    />
    <path
      d="M18.24 6.3a1 1 0 0 0-1.002-.25l-6.743 2.432a2.411 2.411 0 0 0-1.521 1.52l-2.432 6.705a1 1 0 0 0 .95 1.3.999.999 0 0 0 .381-.08l6.544-2.721a2.442 2.442 0 0 0 1.32-1.32L18.46 7.34a1 1 0 0 0-.22-1.04zm-5.744 7.415a1.711 1.711 0 1 1 1.71-1.711 1.7 1.7 0 0 1-1.71 1.711z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgNavExplore;
