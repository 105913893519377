import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { logException } from '../App/logging';
import { getUserLocale } from './getUserLocale';
import deDE from './translations/de-DE';
import enGB from './translations/en-GB';
import frFR from './translations/fr-FR';
import es419 from './translations/es-419';
import enUS from './translations/en-US';
import ptBR from './translations/pt-BR';
import zhCN from './translations/zh-CN';
import itIT from './translations/it-IT';
import plPL from './translations/pl-PL';
import ar from './translations/ar';
import bnBD from './translations/bn-BD';
import csCZ from './translations/cs-CZ';
import daDK from './translations/da-DK';
import elGR from './translations/el-GR';
import esES from './translations/es-ES';
import faIR from './translations/fa-IR';
import fiFI from './translations/fi-FI';
import tl from './translations/tl';
import heIL from './translations/he-IL';
import hiIN from './translations/hi-IN';
import huHU from './translations/hu-HU';
import jaJP from './translations/ja-JP';
import jvID from './translations/jv-ID';
import koKR from './translations/ko-KR';
import ltLT from './translations/lt-LT';
import msMY from './translations/ms-MY';
import nlNL from './translations/nl-NL';
import no from './translations/no';
import pa from './translations/pa';
import roRO from './translations/ro-RO';
import ruRU from './translations/ru-RU';
import svSE from './translations/sv-SE';
import swKE from './translations/sw-KE';
import taIN from './translations/ta-IN';
import thTH from './translations/th-TH';
import trTR from './translations/tr-TR';
import urPK from './translations/ur-PK';
import viVN from './translations/vi-VN';
import zhHant from './translations/zh-Hant';

const userLocale = getUserLocale();

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    compatibilityJSON: 'v3', // means that the word_plural format is used https://www.i18next.com/misc/migration-guide#json-format-v4-pluralization
    lng: userLocale,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    resources: {
      // note resources should match https://www.iana.org/assignments/language-tags/language-tags.xhtml
      'de-DE': deDE,
      'en-GB': enGB,
      'en-US': enUS,
      'es-419': es419,
      'fr-FR': frFR,
      'it-IT': itIT,
      'pl-PL': plPL,
      'pt-BR': ptBR,
      'zh-CN': zhCN,
      ar: ar,
      'bn-BD': bnBD,
      'cs-CZ': csCZ,
      'da-DK': daDK,
      'el-GR': elGR,
      'es-ES': esES,
      'fa-IR': faIR,
      'fi-FI': fiFI,
      tl: tl,
      'he-IL': heIL,
      'hi-IN': hiIN,
      'hu-HU': huHU,
      'ja-JP': jaJP,
      'jv-ID': jvID,
      'ko-KR': koKR,
      'lt-LT': ltLT,
      'ms-MY': msMY,
      'nl-NL': nlNL,
      no: no,
      pa: pa,
      'ro-RO': roRO,
      'ru-RU': ruRU,
      'sv-SE': svSE,
      'sw-KE': swKE,
      'ta-IN': taIN,
      'th-TH': thTH,
      'tr-TR': trTR,
      'ur-PK': urPK,
      'vi-VN': viVN,
      zh: zhHant,
    },
    ns: [
      'account',
      'admin',
      'chatbot',
      'check_in',
      'cheat_sheets',
      'compass',
      'courses',
      'country',
      'explore',
      'help',
      'homepage',
      'insights',
      'languages',
      'logged_out',
      'media_player',
      'navigation',
      'password_validation',
      'plus_one',
      'shared',
      'shorts',
      'talk',
      'tools',
      'track',
      'unmind_index',
    ],
  })
  .catch(logException);

export default i18n;
