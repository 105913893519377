import React from 'react';
import { createPortal } from 'react-dom';

export default class Modal extends React.Component {
  modalRoot: HTMLElement = document.createElement('div');
  modalContainer: HTMLElement = document.createElement('div');
  constructor(props: Record<string, unknown>) {
    super(props);
  }

  componentWillUnmount(): void {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.modalContainer);
      document.body.removeChild(this.modalRoot);
    }
  }

  componentDidMount(): void {
    this.modalRoot.setAttribute('id', 'modal');
    this.modalRoot.setAttribute('role', 'dialog');
    this.modalRoot.setAttribute('aria-modal', 'true');
    this.modalRoot.setAttribute('aria-live', 'assertive');
    document.body.appendChild(this.modalRoot);
    this.modalRoot.appendChild(this.modalContainer);
  }

  render() {
    if (this.modalRoot) {
      return createPortal(this.props.children, this.modalContainer);
    }
  }
}
