import { rem } from 'polished';
import React from 'react';
import { styled, ThemeInterface } from 'styles';
import { HeadingText } from '../../../Shared/Typography';

const ChildrenContainer = styled.div`
  text-align: center;
  margin: auto;
  padding: ${rem(10)};
`;

const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  flex-grow: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  padding-top: ${rem(41)};
`;

const PageTitle = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 1,
    sizes: [
      theme.typography.fontSizes.fontSize24,
      theme.typography.fontSizes.fontSize28,
      theme.typography.fontSizes.fontSize32,
      theme.typography.fontSizes.fontSize40,
    ],
    align: 'center',
  }),
)``;

interface Wrapper404Props {
  title: string;
  titleImage: any;
}

class Wrapper404 extends React.Component<Wrapper404Props> {
  render() {
    const { title, titleImage, children } = this.props;

    return (
      <PageContainer>
        <TitleContainer>
          <div>{titleImage}</div>
          <PageTitle>{title}</PageTitle>
        </TitleContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
      </PageContainer>
    );
  }
}

export default Wrapper404;
