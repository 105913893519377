import * as braze from '@braze/web-sdk';
import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { logDevOnlyInfo } from '../logging';
import {
  BrazeIdentityError,
  IDENTIFICATION_ERROR_MESSAGE,
  captureBrazeException,
} from './brazeSdkErrors';
import brazeSdkAuthentication from './brazeSdkAuthentication';

export const MERGE_BRAZE_PROFILES = gql`
  mutation MergeTeamsBrazeProfiles($input: MergeTeamsBrazeProfilesInput!) {
    mergeTeamsBrazeProfiles(input: $input) {
      code
      success
    }
  }
`;

export const identifyBrazeUser = async (
  client: ApolloClient<unknown>,
  userId: string,
) => {
  if (process.env.REACT_APP_BRAZE_ENABLED === 'true') {
    logDevOnlyInfo('identify a braze user');
    try {
      const response = await brazeSdkAuthentication(client);
      const brazeJWT = response.data.brazeSdkAuthentication.brazeJWT;
      // forces a new session start
      braze.changeUser(userId, brazeJWT);
    } catch (error) {
      try {
        captureBrazeException(
          new BrazeIdentityError(IDENTIFICATION_ERROR_MESSAGE, error as Error),
        );
        braze.changeUser(userId);
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        captureBrazeException(error as Error);
      }
    }
  }
};

export const identifyBrazeUserAndMergeProfiles = async (
  client: ApolloClient<unknown>,
  userId: string,
) => {
  void identifyBrazeUser(client, userId);

  if (isMSTeams()) {
    const teamsUserId = getMSTeamsUserContext()?.user?.id;

    if (!teamsUserId) {
      return;
    }
    try {
      await client.mutate({
        mutation: MERGE_BRAZE_PROFILES,
        variables: {
          input: { teamsUserId: teamsUserId },
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
};

/* Should be called when the site is loaded in an authenticated state, if called within the
configured session length, the previous session will be resumed, otherwise a new session is
tracked */
export const openBrazeSession = () => {
  if (process.env.REACT_APP_BRAZE_ENABLED === 'true') {
    try {
      /* will throw if braze hasn't been initialised, consider making initialization state
      available to braze operations in the future */
      braze.openSession();
    } catch (error) {
      captureBrazeException(error as Error);
    }
  }
};
