import { Form, Formik, FormikActions } from 'formik';
import React, { MouseEvent, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useIrisToken from '../../App/Iris/useIrisToken';
import { tracking } from '../../App/Tracking';
import { LABEL_ERROR_MESSAGE } from '../../Shared/AlertView';
import { AlertBox } from '../../Shared/Form';
import PrimaryButton from '../../Shared/PrimaryButton';
import { BodyText } from '../../Shared/Typography';
import { useTheme } from '../../styles';
import { FormFields, ManageUsersAlert, postFile } from './ManageUsersShared';
import {
  AlertContainer,
  CancelButton,
  FileUploadButtonsContainer,
  FormField,
  FormWrapper,
  LoadingContainer,
  LoadingIndicatorText,
  ManageUsersBlurb,
  ManageUsersTitle,
  StyledLoadingIndicator,
  SubmitPanel,
  Wrapper,
} from './ManageUsers.styled';

export const NO_USERS_ADDED_ERROR =
  'An unexpected error has occurred. No users were added.';

export type ManageJoinersProps = FormFields;

export const ManageJoiners = (props: ManageJoinersProps) => {
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [currentStatus, setCurrentStatus] = useState<
    ManageUsersAlert | undefined
  >(undefined);
  const [usersToAdd, setUsersToAdd] = useState<number | undefined>(undefined);

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { hasError, irisToken } = useIrisToken();
  const { getAccessTokenSilently, isAuthenticated: isAuthenticatedViaAuth0 } =
    useAuth0();
  const theme = useTheme();

  const clearFileInputField = () => {
    inputRef.current.value = '';
  };

  const onValidate = async (values: FormFields) => {
    const selectedFile = values.file as File;

    const auth0JWT = isAuthenticatedViaAuth0
      ? await getAccessTokenSilently()
      : undefined;

    if (!currentFile) {
      setCurrentFile(selectedFile);
      const resp = await postFile(
        `${process.env.REACT_APP_IRIS_BASE_URL}joiners/assess`,
        selectedFile,
        irisToken,
        auth0JWT,
      );

      if (resp) {
        if (resp.error) {
          setCurrentStatus({
            success: false,
            message: 'There was a problem with the file you selected.',
          });
          setCurrentFile(undefined);
          clearFileInputField();
        } else {
          setUsersToAdd(resp.identifiersToAdd);
        }
      }
    }
  };

  const onSubmit = async (
    values: FormFields,
    { setSubmitting, resetForm }: FormikActions<FormFields>,
  ) => {
    const auth0JWT = isAuthenticatedViaAuth0
      ? await getAccessTokenSilently()
      : undefined;

    if (!currentFile) {
      setCurrentStatus({
        success: false,
        message: NO_USERS_ADDED_ERROR,
      });
    } else {
      const resp = await postFile(
        `${process.env.REACT_APP_IRIS_BASE_URL}joiners/add`,
        currentFile,
        irisToken,
        auth0JWT,
      );

      if (resp) {
        if (resp.error || resp.usersAdded === undefined) {
          setCurrentStatus({
            success: false,
            message: NO_USERS_ADDED_ERROR,
          });
        } else {
          setCurrentStatus({
            success: true,
            message: `Successfully added ${resp.usersAdded.toLocaleString()} users.`,
          });
        }
        setCurrentFile(undefined);
        resetForm();
      }
    }
    setSubmitting(false);

    tracking.track('admin-joiners-submit-file');
  };

  // remove any existing status message and open the file chooser
  const chooseFile = (event: MouseEvent<HTMLButtonElement>) => {
    setCurrentStatus(undefined);
    inputRef.current.click();

    tracking.track('admin-joiners-choose-file');
    event.preventDefault();
  };

  const handleReset = (
    values: FormFields,
    { resetForm }: FormikActions<FormFields>,
  ) => {
    setCurrentStatus(undefined);
    setCurrentFile(undefined);
    setUsersToAdd(undefined);

    resetForm();
  };

  const isChooseFileDisabled = () => currentFile !== undefined;

  return (
    <Wrapper>
      <FormWrapper>
        <Formik
          initialValues={props}
          onSubmit={onSubmit}
          validate={onValidate}
          onReset={handleReset}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              {(currentStatus || hasError) && (
                <AlertContainer>
                  {hasError ? (
                    <AlertBox
                      alertType="failed"
                      message={LABEL_ERROR_MESSAGE}
                    />
                  ) : currentStatus ? (
                    <AlertBox
                      alertType={currentStatus.success ? 'success' : 'failed'}
                      message={currentStatus.message}
                    />
                  ) : null}
                </AlertContainer>
              )}
              <FormField>
                <ManageUsersTitle id="joinersTitle">Add users</ManageUsersTitle>
                <ManageUsersBlurb>
                  Click on the choose file button below to get started. Once
                  you’ve uploaded the file your users will be able to register
                  immediately.
                </ManageUsersBlurb>
                <input
                  data-testid="joinersInput"
                  ref={inputRef}
                  accept=".csv"
                  aria-labelledby="joinersTitle"
                  type="file"
                  style={{ display: 'none' }} // hidden to allow us to style the button and remove text
                  onChange={event => {
                    setFieldValue(
                      'file',
                      event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null,
                    );
                  }}
                />
                {isChooseFileDisabled() ? null : (
                  <PrimaryButton
                    label="Choose file"
                    onClick={chooseFile}
                    disabled={isChooseFileDisabled()}
                    ariaLabel="Upload Joiners File"
                  />
                )}
              </FormField>
              {currentStatus === undefined && isChooseFileDisabled() ? (
                <SubmitPanel>
                  <FormField>
                    {usersToAdd !== undefined ? (
                      <>
                        <BodyText
                          sizes={[theme.typography.fontSizes.fontSize16]}
                        >
                          {currentFile ? currentFile.name : ''}
                        </BodyText>
                        <BodyText
                          sizes={[
                            theme.typography.fontSizes.fontSize18,
                            theme.typography.fontSizes.fontSize20,
                            theme.typography.fontSizes.fontSize22,
                            theme.typography.fontSizes.fontSize24,
                          ]}
                        >
                          {usersToAdd.toLocaleString()}
                        </BodyText>
                        <BodyText
                          sizes={[theme.typography.fontSizes.fontSize16]}
                        >
                          user{usersToAdd !== 1 ? 's ' : ' '}will be added
                        </BodyText>
                      </>
                    ) : (
                      <LoadingContainer>
                        <StyledLoadingIndicator />
                        <LoadingIndicatorText>
                          Reviewing file...
                        </LoadingIndicatorText>
                      </LoadingContainer>
                    )}
                    <FileUploadButtonsContainer>
                      <PrimaryButton
                        id="submit"
                        name="submit"
                        disabled={usersToAdd === undefined}
                        label="Add users"
                        loading={isSubmitting}
                        type="submit"
                      />
                      <CancelButton
                        label="Cancel"
                        type="reset"
                        disabled={usersToAdd === undefined || isSubmitting}
                      />
                    </FileUploadButtonsContainer>
                  </FormField>
                </SubmitPanel>
              ) : null}
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};

export default ManageJoiners;
