import React from 'react';
import styled from 'styled-components';
import {
  ChampionsTraining,
  Overview,
  Tools,
  UnmindTrailer,
} from 'LoggedOut/Start/AlternativeMSTeamsStartPage/Sections';
import { StickyFooter } from 'LoggedOut/Start/AlternativeMSTeamsStartPage/StickyFooter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CurrentStartPage = () => (
  <Container data-testid="alternative-start">
    <StickyFooter isTeamsExperiment={true} />
    <UnmindTrailer isTeamsExperiment={true} />
    <Tools />
    <ChampionsTraining />
    <Overview />
  </Container>
);
