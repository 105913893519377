import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { small } from 'utils';
import { BodyText } from '../../Shared/Typography';

interface SeriesDescriptionProps {
  description: string;
  benefits: string[];
}

const DescriptionContainer = styled.div`
  width: 100%;

  ${small(css`
    width: ${rem('368px')};
    margin-right: ${rem('42px')};
  `)}
`;

const Paragraph = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  & + & {
    margin-top: ${rem(16)};
  }
`;

const BenefitList = styled.ul`
  padding: 0;
  margin: ${rem(16)} 0 0;
`;

const BenefitListItem = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'li',
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin: ${rem('16px')} 0 0 ${rem('16px')};
  &:before {
    box-sizing: content-box;
    content: '';
    display: inline-block;
    width: ${rem('6px')};
    height: ${rem('6px')};
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    margin: ${rem('9px')} 0 0 -${rem('16px')};
    position: absolute;
  }

  & + & {
    margin-top: ${rem(16)};
  }
`;

const SeriesDescription = ({
  description,
  benefits,
}: SeriesDescriptionProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <DescriptionContainer>
      <Paragraph>{description}</Paragraph>
      <Paragraph>{translate('overview.benefits_title')}</Paragraph>
      <BenefitList>
        {benefits.map(benefit => (
          <BenefitListItem key={benefit}>{benefit}</BenefitListItem>
        ))}
      </BenefitList>
    </DescriptionContainer>
  );
};

export default SeriesDescription;
