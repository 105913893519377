import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode | ReactNode[] | null;
  prependToRootComponent?: boolean;
}

const appRoot = document.getElementById('root') || document.body;
let portalRoot = document.getElementById('portal');

if (!portalRoot && appRoot) {
  portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', 'portal');
  appRoot.appendChild(portalRoot);
}

const Portal = ({ children, prependToRootComponent }: PortalProps) => {
  const [mounted, setMounted] = useState(false);
  const portalChildContainerRef = useRef(document.createElement('div'));

  useEffect(() => {
    const portalChildContainer = portalChildContainerRef.current;
    if (appRoot && portalChildContainer) {
      if (prependToRootComponent) {
        appRoot.prepend(portalChildContainer);
      } else {
        appRoot.appendChild(portalChildContainer);
      }
      setMounted(true);
    }

    return () => {
      if (appRoot && portalChildContainer) {
        appRoot.removeChild(portalChildContainer);
      }
    };
  }, []);

  return children
    ? createPortal(mounted ? children : null, portalChildContainerRef.current)
    : null;
};

export default Portal;
