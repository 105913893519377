import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScript } from '../../hooks/useScript';
import { useUnmindTalk } from '../../Talk/hooks';
import { ZendeskSettings, ZendeskWidget } from './ZendeskTypes';

declare global {
  interface Window {
    zESettings?: ZendeskSettings;
    zE?: ZendeskWidget;
  }
}

export function ZendeskChat() {
  const location = useLocation();
  const { unmindTalkIsAvailableForUser } = useUnmindTalk();
  const zendeskKey = process.env.REACT_APP_ZENDESK_CHAT_API_KEY;
  const [zendeskLoaded] = useScript(
    `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
    {
      crossOrigin: true,
      scriptId: 'ze-snippet',
      scriptType: 'text/javascript',
    },
  );

  // Initialise the Zendesk widget
  useEffect(() => {
    if (!zendeskLoaded || !window.zE) return;
    window.zESettings = {
      messenger: {
        launcher: {
          mobile: {
            labelVisible: false,
          },
        },
      },
    };

    // Show the widget if the user has Talk enabled and is on a page where it should be visible
    const isPermittedPage = location.pathname.includes('/talk');
    if (unmindTalkIsAvailableForUser && isPermittedPage) {
      window.zE('messenger', 'show');
    } else {
      window.zE('messenger', 'hide');
    }
  }, [location.pathname, unmindTalkIsAvailableForUser, zendeskLoaded]);

  return null;
}
