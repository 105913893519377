import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves2 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M305.147 192H0v-79.4a369.3 369.3 0 0 0 3.22 1.204c32.773 12.066 63.172 5.507 94.244-1.198 30.834-6.653 62.331-13.45 97.469-2.328 28.895 9.145 45.76 27.319 61.84 44.646 14.17 15.269 27.73 29.881 48.374 37.076z"
      fill={secondaryColor}
    />
    <path
      d="M155.718 0H340v99.447c-10.221-4.52-20.417-16.343-31.371-29.046-8.364-9.7-17.172-19.914-26.771-27.781-14.887-12.2-32.08-4.72-49.655 2.925-17.44 7.587-35.255 15.336-51.561 4.18-13.41-9.175-18.788-28.12-23.916-46.182-.336-1.186-.672-2.368-1.008-3.543z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgWaves2;
