import React from 'react';
import { OptionsType } from 'react-select';
import { rem } from 'polished';
import { css, styled } from 'styles';
import { AdminTabType } from '../../../types';
import {
  MultiSelect,
  MultiSelectOption,
} from '../../../../Shared/Form/MultiSelect';
import Label from '../../../../Shared/Form/Label';
import { medium } from '../../../../utils';
import {
  UserGroupFilterState,
  UserGroupFilterStateKey,
} from '../../FilterState/types';
import { useFilterState } from '../../FilterState';
import FilterOption from '../FilterOption';
import { getSelectedFilterValues } from '../helpers';
import { DropdownOptions } from '../types';
import { trackSelectedOptions } from './helpers';

interface Props {
  options: DropdownOptions;
  filterType: UserGroupFilterStateKey;
  adminTabType: AdminTabType;
}

const FieldLabel = styled(Label)`
  display: inline-flex;
  margin-bottom: ${rem(8)};
`;

const FilterContainer = styled.div`
  ${medium(css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    margin-right: ${rem(24)};
    margin-bottom: ${rem(12)};
    min-width: calc((100%) / 4);
  `)}
  margin-bottom: ${rem(8)};
`;

type FilterLabels = {
  [key in UserGroupFilterStateKey]: {
    title: string;
    placeholder: string;
  };
};

const FILTER_LABELS: FilterLabels = {
  departmentIds: {
    title: 'Choose departments',
    placeholder: 'All Departments',
  },
  locationIds: {
    title: 'Choose locations',
    placeholder: 'All Locations',
  },
};

const FilterDropdown = ({ options, filterType, adminTabType }: Props) => {
  const { setUserGroupFilterState, userGroupFilterState, timeFilterPreset } =
    useFilterState();
  const selectedFilterValues = getSelectedFilterValues(
    userGroupFilterState,
    options,
  );

  const { title, placeholder } = FILTER_LABELS[filterType];

  const updateUserGroupFilterState = (
    selectedFilterOptions: OptionsType<MultiSelectOption<string>>,
  ) => {
    const values = selectedFilterOptions.map(({ value }) => value);
    setUserGroupFilterState({
      [filterType]: values,
    } as UserGroupFilterState);
  };

  const handleChange = (
    selectedOptions: OptionsType<MultiSelectOption<string>>,
  ) => {
    trackSelectedOptions(
      selectedOptions.length,
      options,
      adminTabType,
      filterType,
      timeFilterPreset,
    );
    updateUserGroupFilterState(selectedOptions);
  };

  return (
    <FilterContainer>
      <FieldLabel htmlFor="dashboard-filter">{title}</FieldLabel>
      <MultiSelect
        inputId="dashboard-filter"
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        customOption={FilterOption}
        value={selectedFilterValues}
      />
    </FilterContainer>
  );
};

export default FilterDropdown;
