import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RoutePath from 'App/RoutePath';
import { AuthWrapper } from '../../AuthWrapper';
import {
  Container,
  Title,
  Description,
  InvalidInviteTokenSVG,
  Button,
} from './styles';

export const InvalidInviteToken = () => {
  const history = useHistory();
  const { t: translate } = useTranslation('logged_out');

  return (
    <AuthWrapper showFooter={false}>
      <Container data-testid="error-screen">
        <Title>
          {translate('sign_up.invalid_token.generic_error_screen.title')}
        </Title>
        <Description>
          {translate('sign_up.invalid_token.generic_error_screen.description')}
        </Description>
        <InvalidInviteTokenSVG />
        <Button
          type="submit"
          ariaLabel={translate(
            'sign_up.invalid_token.generic_error_screen.button_a11y_text',
          )}
          onClick={() => history.push(RoutePath.Home)}
          label={translate('sign_up.invalid_token.generic_error_screen.button')}
        />
      </Container>
    </AuthWrapper>
  );
};
