import React from 'react';
import { RowProps, useDayPicker, WeekNumber } from 'react-day-picker';
import { getUnixTime } from 'date-fns';
import { DayPickerDay } from './DayPickerDay';

export const DayPickerRow = (props: RowProps) => {
  const { styles, classNames, showWeekNumber, components } = useDayPicker();
  const DayComponent = components?.Day ?? DayPickerDay;
  const WeeknumberComponent = components?.WeekNumber ?? WeekNumber;

  return (
    <tr className={classNames.row} style={styles.row}>
      {showWeekNumber ? (
        <td className={classNames.cell} style={styles.cell}>
          <WeeknumberComponent number={props.weekNumber} dates={props.dates} />
        </td>
      ) : null}
      {props.dates.map(date => (
        <td
          className={classNames.cell}
          style={styles.cell}
          key={getUnixTime(date)}
        >
          <DayComponent displayMonth={props.displayMonth} date={date} />
        </td>
      ))}
    </tr>
  );
};
