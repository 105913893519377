export function pluralize<
  Singular extends string,
  Plural extends string = `${Singular}s`,
>(
  count: number,
  singularWord: Singular,
  pluralWord?: Plural,
): Singular | Plural {
  return count === 1
    ? singularWord
    : pluralWord || (`${singularWord}s` as Plural);
}
