import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsTransform = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.43 16.24L8.873 32h22.254L20.57 16.24A.774.774 0 0 0 20 16a.774.774 0 0 0-.57.24zM17.857 15A2.766 2.766 0 0 1 20 14c.823 0 1.618.359 2.143 1.001a1 1 0 0 1 .056.076l11.632 17.367A1 1 0 0 1 33 34H7a1 1 0 0 1-.83-1.556L17.8 15.076a1 1 0 0 1 .057-.076z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.48 23a1 1 0 0 1 .852.445l2 3a1 1 0 0 1-1.664 1.11l-1.131-1.697L4.783 32H7a1 1 0 1 1 0 2H3a1 1 0 0 1-.853-1.521l5.5-9A1 1 0 0 1 8.48 23zM12.228 23.365a1 1 0 0 1 1.408-.137l3.254 2.678.003.003c.157.13.395.119.537-.02l.89-.873c.93-.91 2.43-.911 3.36 0l.89.872c.142.14.38.15.537.02l.003-.002 3.255-2.678a1 1 0 1 1 1.27 1.544l-3.251 2.677h-.002a2.407 2.407 0 0 1-3.212-.132l-.89-.873a.407.407 0 0 0-.56 0l-.89.873a2.408 2.408 0 0 1-3.212.133l-3.253-2.678a1 1 0 0 1-.137-1.407zM20 2a1 1 0 0 1 1 1l-.011 12a1 1 0 0 1-2-.001l.011-12a1 1 0 0 1 1-1z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3a1 1 0 0 1 1-1h10.262c.71.004 1.309.443 1.575 1.056a1.85 1.85 0 0 1-.2 1.84m-.002.001l-1.55 2.1 1.556 2.105c.394.533.466 1.242.203 1.841A1.733 1.733 0 0 1 30.267 12H20a1 1 0 1 1 0-2h9.818l-1.414-1.911a1.854 1.854 0 0 1 0-2.182l.002-.003L29.812 4H20a1 1 0 0 1-1-1"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsTransform;
