import { Colors } from '@unmind/design-system-theme';
import React from 'react';
import { styled, ThemeInterface } from 'styles';
import BodyText from '../Typography/BodyText';

export interface LabelProps {
  children: string;
  className?: string;
  htmlFor?: string;
  color?: Colors;
  id?: string;
}

const StyledLabel = styled(BodyText).attrs(
  ({ theme, labelColor }: { theme: ThemeInterface; labelColor?: Colors }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
    forwardedAs: 'label',
    color: labelColor ?? theme.colors.text.secondary,
  }),
)<{ labelColor?: Colors }>``;

export default ({
  children,
  className,
  htmlFor,
  id,
  color,
  ...props
}: LabelProps) => (
  <BodyText>
    <StyledLabel
      className={className}
      htmlFor={htmlFor}
      id={id}
      labelColor={color}
      {...props}
    >
      {children}
    </StyledLabel>
  </BodyText>
);
