import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styles';
import { Loader } from '@unmind/design-system-components-web';
import { useQuery } from '@apollo/client';
import { userQuery } from 'Services/User/__generated__/userQuery';
import useInfiniteScroll, { Direction } from 'hooks/useInfiniteScroll';
import { USER_QUERY } from '../graphql';
import { ConversationUpdateType, ScrollLocation, UserMessage } from '../types';
import {
  useShowDisclaimer,
  useConversationHistory,
  useSubmitInitialMessage,
  useIsScrollAtBottom,
} from '../hooks';
import { messagesToConversationSessions } from '../utils';
import { useAuth0Token } from '../hooks/useAuth0Token';
import { ChatbotInput } from './ChatbotInput';
import { ChatbotSession } from './ChatbotSession';
import {
  ChatbotFooter,
  ChatBotFooterText,
  ChatBotInputContainer,
  ChatbotMessageWrapper,
  ChatbotWrapper,
  LoadingMoreErrorWrapper,
  LoadingMoreWrapper,
  FeatureUnavailableErrorWrapper,
  ScrollIconContainer,
  ScrollIcon,
} from './styles';
import { Error } from './Error';

const CONVERSATION_UPDATE_SCROLL_MAPPING: Record<
  ConversationUpdateType,
  ScrollLocation
> = {
  NEW_MESSAGE: 'BOTTOM',
  LOAD_MORE: 'CURRENT',
};

export interface ChatbotProps {
  /**
   * An optional initial message from landing page redirect
   */
  initialMessage?: UserMessage;
}

const redirectToSignIn = () => {
  const SIGNIN_PATH = '/?redirect=';
  const signinUrl = `${window.location.origin}${SIGNIN_PATH}${window.location.pathname}`;
  window.location.assign(signinUrl);
};

export const Chatbot = ({ initialMessage }: ChatbotProps) => {
  const theme = useTheme();
  const { t: translate } = useTranslation('chatbot');
  const disclaimerVisible = useShowDisclaimer();
  const [shouldSubmitInitialMessage, setSubmitInitialMessage] =
    useSubmitInitialMessage();
  const isScrollAtBottom = useIsScrollAtBottom(500);

  const [currentHeight, setCurrentHeight] = useState(
    document.body.scrollHeight,
  );
  const [scrollAction, setScrollAction] = useState<ScrollLocation>('BOTTOM');

  // Reference Objects
  const pageRef: MutableRefObject<null> = useRef(null);

  const { data: userData } = useQuery<userQuery>(USER_QUERY);
  const userFirstName = userData?.user?.firstName || '';

  function scrollToEnd() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight || window.innerHeight,
      });
    }, 10);
  }

  const handleConversationUpdated: Parameters<
    typeof useConversationHistory
  >[0]['onConversationUpdated'] = updateType => {
    setScrollAction(CONVERSATION_UPDATE_SCROLL_MAPPING[updateType]);
  };

  const {
    messages,
    isSubmittingMessage,
    isStreamingMessage,
    isLoadingHistory,
    hadErrorSubmittingMessage,
    hadErrorLoadingHistory,
    submitMessage,
    resubmitMessages,
    loadMoreMessages,
    retryLoadingHistory,
  } = useConversationHistory({
    onConversationUpdated: handleConversationUpdated,
  });

  /**
   * Only recalculate sessions if messages change not for any other re render behavior
   */
  const sessions = useMemo(
    () => messagesToConversationSessions(messages),
    [messages],
  );

  /**
   * This effect handles a initial message from the landing page redirect
   * It will submit the message if the history is loaded and the initial message
   * is set and if the shouldSubmitInitialMessage is true, the latter of the conditionals
   * prevents it from resubmitting the initial message on page reload.
   */
  useEffect(() => {
    if (initialMessage && shouldSubmitInitialMessage && !isLoadingHistory) {
      void setSubmitInitialMessage(false);
      void submitMessage(initialMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHistory, initialMessage, shouldSubmitInitialMessage]);

  /**
   * This useEffect hook handles the scroll position when new messages are added to the chat
   * and historical messages are loaded via infinite scroll.
   */
  useEffect(() => {
    if (sessions && sessions.length > 0) {
      if (scrollAction === 'BOTTOM') {
        scrollToEnd();
        setCurrentHeight(document.body.scrollHeight);
      } else if (scrollAction === 'CURRENT') {
        setTimeout(() => {
          // Calculate new scroll position
          const newHeight = document.body.scrollHeight;

          const newScrollPosition = newHeight - currentHeight;

          // Restore the scroll position
          window.scrollTo(0, newScrollPosition);
          setCurrentHeight(newHeight);
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  useInfiniteScroll(
    () => {
      setTimeout(() => {
        void loadMoreMessages();
      }, 500);
    },
    pageRef,
    Direction.UP,
  );

  /**
   * If the user's session has expired or Chat API returns 401, redirect to sign in
   */
  const auth0Token = useAuth0Token();
  const unmindAuthToken = localStorage.getItem('token');
  if (auth0Token !== undefined) {
    if (
      !(auth0Token || unmindAuthToken) ||
      hadErrorLoadingHistory === 'USER_SESSION_EXPIRED'
    ) {
      redirectToSignIn();
    }
  }

  /**
   * If the history is unavailable, show the feature unavailable screen
   */
  if (hadErrorLoadingHistory === 'FEATURE_UNAVAILABLE') {
    return (
      <ChatbotWrapper>
        <FeatureUnavailableErrorWrapper>
          <Error
            title={translate('conversation.something_went_wrong.title')}
            body={translate('conversation.something_went_wrong.body')}
            orientation="vertical"
          />
        </FeatureUnavailableErrorWrapper>
      </ChatbotWrapper>
    );
  }

  return (
    <ChatbotWrapper>
      {isLoadingHistory ? (
        <LoadingMoreWrapper
          aria-label={translate('conversation.loading.a11y_label')}
        >
          <Loader
            color={theme.colors.chatbot.messages.loading.bubbles}
            size={4}
          />
        </LoadingMoreWrapper>
      ) : hadErrorLoadingHistory ? (
        <LoadingMoreErrorWrapper>
          <Error
            title={translate(
              'conversation.conversation_errors.fetch_conversation_history_error.title',
            )}
            onTryAgainPress={retryLoadingHistory}
          />
        </LoadingMoreErrorWrapper>
      ) : null}

      <ChatbotMessageWrapper>
        {sessions &&
          sessions.map(({ date, messages: sessionMessages }, index) => (
            <ChatbotSession
              key={date}
              isCurrentOldestLoadedSession={index === 0 && sessions.length >= 1}
              date={date}
              messages={sessionMessages}
              loading={index === sessions.length - 1 && isSubmittingMessage}
              error={index === sessions.length - 1 && hadErrorSubmittingMessage}
              onSubmit={submitMessage}
              onReSubmit={resubmitMessages}
              userFirstName={userFirstName}
              hasInitialMessage={Boolean(initialMessage)}
              isDisclaimerShown={disclaimerVisible}
            />
          ))}
      </ChatbotMessageWrapper>

      {!isScrollAtBottom ? (
        <ScrollIconContainer
          a11y-role="button"
          aria-label={translate('conversation.scroll_to_bottom.a11y_label')}
          onClick={() => {
            setScrollAction('BOTTOM');
            scrollToEnd();
          }}
        >
          <ScrollIcon />
        </ScrollIconContainer>
      ) : null}

      <ChatbotFooter>
        <ChatBotInputContainer>
          <ChatbotInput
            canSubmit={
              !isSubmittingMessage && !isLoadingHistory && !isStreamingMessage
            }
            onSubmit={submitMessage}
          />
        </ChatBotInputContainer>
        <ChatBotFooterText>
          {translate('conversation.conversation_footer_text')}
        </ChatBotFooterText>
      </ChatbotFooter>
    </ChatbotWrapper>
  );
};
