import { LocationDescriptorObject } from 'history';
import findLastIndex from 'lodash/findLastIndex';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { small } from 'utils';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import RoutePath from '../../../App/RoutePath';
import Route404 from '../../../App/Router/Route404';
import { default as OuterContainer } from '../../../Shared/Container';
import { AlertBox } from '../../../Shared/Form';
import Tabs from '../../../Shared/Tabs';
import { redirectHelper } from '../../../utils/redirectHelper';
import { Highlight } from '../../SeriesShared';
import SeriesDayCard from '../SeriesDayCard';
import SeriesDaySlider from '../SeriesDaySlider';
import SeriesDescription from '../SeriesDescription';
import SeriesHeader from '../SeriesHeader';
import { makeTrackSeriesEvents } from '../SeriesHelpers/makeTrackSeriesEvent';
import SeriesReview from '../SeriesReview';
import {
  ExtendedSeriesDay,
  WithSeriesDaysChildProps,
  withSeriesDays,
} from '../withSeriesDays';
import {
  getActiveDayStatus,
  getActiveSeriesDay,
  isSeriesInProgress,
} from '../withSeriesDaysHelpers';
import {
  SingleSeries,
  WithSingleSeriesChildProps,
  withSingleSeries,
} from '../withSingleSeries';
import { NewCoursesOverview } from './NewCoursesOverview/NewCoursesOverview';

export interface SeriesProgress {
  seriesId: string;
  daysCompleted: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;

  ${small(css`
    flex-flow: column nowrap;
    flex-grow: 1;
  `)};
`;

const TabBody = styled.div<{ isWide?: boolean }>`
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${rem(80)};

  ${small(css`
    flex-direction: row;
  `)};
`;

const SeriesDayCardWrapper = styled.div`
  margin-top: ${rem(8)};
  flex: 1;
  width: 100%;

  ${small(css`
    max-width: ${rem(410)};
  `)}
`;

const StyledPageContainer = styled(OuterContainer)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const AlertContainer = styled(AlertBox)`
  margin-bottom: ${rem(24)};
`;
export interface SeriesOverviewProps
  extends WithSeriesDaysChildProps,
    WithSingleSeriesChildProps {
  activeTab: string;
  routeProps: RouteComponentProps;
  slug: string;
  updateHistory(location: LocationDescriptorObject): void;
}

const isSeriesCompleted = (
  seriesDays: ExtendedSeriesDay[] | undefined,
): boolean => {
  if (!seriesDays) {
    return false;
  }

  return seriesDays.every(day => day.complete);
};

const getSeriesProgressPercent = (
  seriesDays: ExtendedSeriesDay[] | undefined,
) => {
  if (seriesDays === undefined) {
    return 0;
  }

  const daysCompleted = findLastIndex(seriesDays, 'complete') + 1;

  return (daysCompleted / seriesDays.length) * 100;
};

export const getSeriesDayTitle = (
  singleSeries: SingleSeries,
  dayNumber: number,
): string => {
  if (!singleSeries) {
    return '';
  }

  const activeSeriesDay = singleSeries.seriesDays.find(
    ({ orderNo }) => orderNo === dayNumber,
  );

  if (!activeSeriesDay) {
    return '';
  }

  const { title } = activeSeriesDay;

  return title;
};

const getSeriesDayHighlights = (
  singleSeries: SingleSeries,
  dayNumber: number,
): Highlight[] => {
  if (!singleSeries) {
    return [];
  }

  const activeSeriesDay = singleSeries.seriesDays.find(
    ({ orderNo }) => orderNo === dayNumber,
  );

  if (!activeSeriesDay) {
    return [];
  }

  return activeSeriesDay.highlights;
};

export function SeriesOverview(props: SeriesOverviewProps) {
  const {
    activeTab,
    singleSeries,
    singleSeriesLoading,
    slug,
    seriesDays,
    updateHistory,
  } = props;

  const { t: translate } = useTranslation('courses');
  const showNewCoursesUi = useFeatureFlag(FEATURE_FLAGS.NEW_COURSES_UI);
  const pathname = window.location.pathname as RoutePath;

  if (pathname.includes(RoutePath.Series)) {
    return redirectHelper(`${RoutePath.Courses}/${slug}`);
  }

  if (seriesDays && seriesDays.length === 1) {
    return <Redirect to={`${RoutePath.Explore}`} />;
  }

  const tabs = [
    {
      title: translate('overview.navigation.overview_tab_button.title'),
      hash: '#overview',
    },
    {
      title: translate('overview.navigation.progress_tab_button.title'),
      hash: '#progress',
    },
    {
      title: translate('overview.navigation.review_tab_button.title'),
      hash: '#review',
    },
  ];

  if (!singleSeries) {
    if (Boolean(singleSeriesLoading)) {
      return null;
    }

    return <Route404 {...props.routeProps} />;
  }

  const activeDay = getActiveSeriesDay(seriesDays);
  const activeDayTitle = getSeriesDayTitle(singleSeries, activeDay);
  const seriesStarted = isSeriesInProgress(seriesDays);
  const { activeDayStarted, activeDayComplete, activeDayId } =
    getActiveDayStatus(activeDay, seriesDays);
  const activeDayLinkPath = `${RoutePath.Series}/${slug}/day/${activeDay}`;

  const trackSeriesEvents = makeTrackSeriesEvents({
    seriesId: singleSeries.id,
    slug: slug,
  });

  if (showNewCoursesUi) {
    return (
      <NewCoursesOverview
        course={singleSeries}
        activeDay={activeDay}
        slug={slug}
      />
    );
  }

  return (
    <Container>
      <StyledPageContainer>
        <SeriesHeader
          progress={getSeriesProgressPercent(seriesDays)}
          singleSeries={singleSeries}
          singleSeriesLoading={singleSeriesLoading}
        />
      </StyledPageContainer>
      <Tabs tabs={tabs} activeTab={activeTab} updateHistory={updateHistory}>
        <TabBody>
          <SeriesDescription
            description={singleSeries.description}
            benefits={[
              singleSeries.benefit1,
              singleSeries.benefit2,
              singleSeries.benefit3,
            ]}
          />
          <SeriesDayCardWrapper>
            {singleSeries.hasCompletedPreviousVersion && (
              <AlertContainer
                alertType="success"
                message={translate(
                  'overview.previous_version_completion_alert.text',
                )}
              />
            )}
            <SeriesDayCard
              seriesStarted={seriesStarted}
              linkTo={{
                pathname: activeDayLinkPath,
              }}
              dayNumber={activeDay}
              dayId={activeDayId}
              dayTitle={activeDayTitle}
              dayHighlights={getSeriesDayHighlights(singleSeries, activeDay)}
              dayStarted={activeDayStarted}
              dayComplete={activeDayComplete}
              trackSeriesEvents={trackSeriesEvents}
            />
          </SeriesDayCardWrapper>
        </TabBody>
        <TabBody isWide={true}>
          <SeriesDaySlider trackSeriesEvents={trackSeriesEvents} />
        </TabBody>
        <TabBody>
          {singleSeries.id && (
            <SeriesReview
              started={seriesStarted}
              seriesCompleted={isSeriesCompleted(seriesDays)}
              seriesId={singleSeries.id}
              seriesLink={{
                pathname: activeDayLinkPath,
              }}
              seriesTitle={singleSeries.title}
            />
          )}
        </TabBody>
      </Tabs>
    </Container>
  );
}

interface EnhancedComponentProps {
  activeTab: string;
  routeProps: RouteComponentProps;
  slug: string;
  updateHistory(location: LocationDescriptorObject): void;
}

export default compose<SeriesOverviewProps, SeriesOverviewProps>(
  withRouter,
  (Component: React.ComponentClass<EnhancedComponentProps>) =>
    (props: RouteComponentProps<{ slug: string }>) =>
      (
        <Component
          activeTab={props.location.hash}
          routeProps={props}
          slug={props.match.params.slug}
          updateHistory={location => {
            const locationWithState = {
              ...location,
              state: { ...props.location.state },
            };
            props.history.replace(locationWithState);
          }}
        />
      ),
  withSingleSeries,
  withSeriesDays,
)(SeriesOverview);
