import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { css, styled } from 'styles';
import { medium, small } from '../utils';

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const ResponsiveContainer = styled.div`
  display: block;
  padding: ${rem(0)} ${rem(20)} ${rem(20)};
  margin: 0 auto;
  max-width: ${rem(Breakpoints.M)};

  ${small(css`
    padding: ${rem(0)} ${rem(44)} ${rem(36)};
  `)}

  ${medium(css`
    padding: ${rem(0)} ${rem(64)} ${rem(36)};
  `)}
`;

export enum FormElementDisplay {
  Inline,
  Block,
}
