import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { LocalGeneralError } from '../../Shared/AlertView';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import useUniversalLogin from '../UniversalLogin/useUniversalLogin';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import {
  confirmSSOAccount,
  confirmSSOAccountVariables,
} from './__generated__/confirmSSOAccount';

export const SSO_CONFIRM_ACCOUNT_MUTATION = gql`
  mutation confirmSSOAccount($rid: String!) {
    confirmSSOAccount(rid: $rid)
  }
`;

const getRid = (location: Location): string | undefined => {
  const { search } = location;
  const query = queryString.parse(search);
  const { rid } = query;

  return rid as string;
};

const ConfirmSSOAccount = () => {
  const rid = getRid(location);
  const {
    authProviderOrganisationId,
    loading: subdomainLoading,
    error: subdomainError,
  } = useSubdomainInfo({
    subdomain: getSubdomainFromUrl(),
  });

  const { loginWithUniversalLogin } = useUniversalLogin();

  const [confirmAccount, { error: confirmationError }] = useMutation<
    confirmSSOAccount,
    confirmSSOAccountVariables
  >(SSO_CONFIRM_ACCOUNT_MUTATION, {
    onCompleted: async () =>
      loginWithUniversalLogin({
        authProviderOrganisationId: authProviderOrganisationId,
      }),
    onError: () => {},
  });

  const error = !rid || subdomainError || confirmationError;
  const initialising = subdomainLoading;

  useEffect(() => {
    if (!error && !initialising) {
      confirmAccount({
        variables: {
          rid: rid || '',
        },
      });
    }
  }, [rid, initialising, error]);

  if (error) {
    return <LocalGeneralError />;
  }

  return <LoadingIndicator />;
};

export default ConfirmSSOAccount;
