import { Series, Tool } from 'Explore/types';
import { rem } from 'polished';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Container from '../../Shared/Container';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { small } from '../../utils';
import { filterOutCollectionsFromContent } from '../Collections/collectionsHelpers';
import ContentSection from '../Content/ContentSection';
import { TemporaryStateContainer } from '../ExplorePage';
import GenericError from '../GenericError/GenericError';
import HeroBannerSection from '../HeroBanner/HeroBannerSection';
import SubCategorySection from '../SubCategory/SubCategorySection';
import { CategoryIcons } from '../categoryIcons';
import WaveHeader from './WaveHeader/WaveHeader';
import {
  categoryContent_exploreCategory,
  categoryContent_exploreCategory_content,
} from './__generated__/categoryContent';
import useExploreCategoryQuery from './useExploreCategoryQuery';

const CategoryScreenInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${rem(28)};
  padding-top: ${rem(32)};

  ${small(css`
    padding-top: ${rem(40)};
  `)};
`;

const CategoryPage = () => {
  const { category: categorySlug } = useParams<{
    category: string;
  }>();

  const { data, loading, error, refetch } = useExploreCategoryQuery({
    variables: { slug: categorySlug },
  });

  const category = data?.exploreCategory;
  const heroBanner = data?.exploreCategory?.featuredContent;
  const subCategories = data?.exploreCategory?.subCategories;
  const content = filterOutCollectionsFromContent(
    data?.exploreCategory?.content as (Series | Tool)[],
  );

  const assetToken = data?.getAssetToken?.assetToken;

  if (loading) {
    return (
      <TemporaryStateContainer>
        <LoadingIndicator />
      </TemporaryStateContainer>
    );
  }

  if (error) {
    return (
      <TemporaryStateContainer>
        <GenericError retry={true} onRetry={refetch} />
      </TemporaryStateContainer>
    );
  }

  return (
    <>
      <WaveHeader
        title={category?.title ?? ''}
        summary={category?.summary ?? undefined}
        icon={CategoryIcons[category?.slug as keyof typeof CategoryIcons]}
      />
      <Container>
        <CategoryScreenInner>
          {heroBanner && (
            <HeroBannerSection
              heroBannerContent={heroBanner}
              currentCategory={category as categoryContent_exploreCategory}
              source={'explore-category-hero-banner'}
              assetToken={data?.getAssetToken?.assetToken}
            />
          )}
          {subCategories && subCategories.length > 0 ? (
            <SubCategorySection
              title={category?.title}
              subCategories={subCategories}
              currentCategory={category as categoryContent_exploreCategory}
            />
          ) : null}
          {content && content.length > 0 ? (
            <ContentSection
              content={content as categoryContent_exploreCategory_content[]}
              source="explore-content-page"
              assetToken={assetToken}
            />
          ) : null}
        </CategoryScreenInner>
      </Container>
    </>
  );
};

export default CategoryPage;
