import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import type { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { logException } from 'App/logging';
import { useUnmindTalk } from 'Talk/hooks';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { LoginAction } from '../LoginAction';
import RoutePath from '../../RoutePath';
import { tracking } from '../../Tracking';
import getSubdomainFromUrl from '../../../utils/getSubdomainFromUrl';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import { identifyBrazeUserAndMergeProfiles } from '../../../App/braze/identifyBrazeUser';
import useIsFirstLoginClaim from './useIsFirstLoginClaim';

export interface SSOLoginSuccessProps {
  client?: ApolloClient<any>;
}

const SSOLoginSuccess = (props: SSOLoginSuccessProps) => {
  /**
   * Component that loads after successful authentication via Auth0 SSO
   * used to handle any required post-authenticated actions before
   * redirect to target location
   */

  const {
    loading: loadingIsFirstLoginClaim,
    isFirstLogin: isFirstLoginClaim,
    unmindUserId: userIdClaim,
  } = useIsFirstLoginClaim();

  useEffect(() => {
    if (userIdClaim) {
      if (props.client) {
        void identifyBrazeUserAndMergeProfiles(props.client, userIdClaim);
      } else {
        logException(
          'attempted to identify braze user but apollo client not initialised',
        );
      }
    }
  }, [props.client, userIdClaim]);

  const { unmindTalkIsAvailableForUser } = useUnmindTalk();
  const showTalkOnboardingModal = useFeatureFlag(
    FEATURE_FLAGS.SHOW_TALK_ONBOARDING_MODAL,
  );

  if (loadingIsFirstLoginClaim) {
    return <LoadingIndicator />;
  }

  const subdomain = getSubdomainFromUrl();
  tracking.track('login-successful', {
    subdomain,
    loginAction: LoginAction.SSO_LOGIN,
    isFirstLogin: isFirstLoginClaim,
    type: 'success',
  });

  if (isFirstLoginClaim) {
    if (showTalkOnboardingModal && unmindTalkIsAvailableForUser) {
      return (
        <Redirect
          to={{ pathname: RoutePath.Home, state: { isFirstLogin: true } }}
        />
      );
    } else {
      return <Redirect to={RoutePath.WelcomeToUnmind} />;
    }
  }

  const urlSearchParams = new URLSearchParams(location.search);
  const returnTo = urlSearchParams.get('returnto');

  if (returnTo) {
    return <Redirect to={returnTo} />;
  }

  return <Redirect to={RoutePath.Home} />;
};

export default withApollo<SSOLoginSuccessProps>(SSOLoginSuccess);
