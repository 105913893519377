import { ApolloClient, isApolloError } from '@apollo/client';
import { omit } from 'lodash';
import * as moment from 'moment-timezone';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { setDatadogUser } from 'App/Datadog/DatadogSetup';
import { logException } from '../logging';
import { Errors } from '../../Shared/Errors';
import { VIRGIN_PULSE_PARTNER_NAME } from '../../LoggedOut/VirginPulse/consts';
import { getUserLocale } from '../../i18n/getUserLocale';
import tracking from './tracking';
import { IdentifyUserForTracking } from './__generated__/IdentifyUserForTracking';

export const IDENTIFY_USER_FOR_TRACKING_QUERY = gql`
  query IdentifyUserForTracking {
    user {
      activationDate
      createdAt: created
      id
      firstName
      lastName
      locationData {
        id
        name
      }
      departmentData {
        id
        name
      }
      email
      timezone
      userType {
        id
        name
      }
      permissions {
        value
      }
      praiseDirectory {
        value
      }
      group {
        clientTier
        groupId
        groupName
        subdomain: subDomain
        group {
          isVirginPulseGroup
        }
      }
      abTestCohorts
      isLineManager
    }
  }
`;

export async function trackLoggedInUser(client: ApolloClient<any>) {
  const { data } = await client.query<IdentifyUserForTracking>({
    query: IDENTIFY_USER_FOR_TRACKING_QUERY,
  });

  if (data.user) {
    const {
      group,
      praiseDirectory,
      userType,
      permissions,
      departmentData,
      locationData,
      abTestCohorts,
      id: uuid,
      isLineManager,
      ...user
    } = data.user;

    const locale = getUserLocale();

    const timezone = user.timezone ? user.timezone : moment.tz.guess();

    const traits = {
      ...omit(user, '__typename'),
      firstName: user.firstName || undefined,
      lastName: user.lastName || undefined,
      email: user.email || undefined,
      id: uuid,
      uuid,
      timezone,
      subdomain: group?.subdomain ?? '',
      groupId: group?.groupId ?? '',
      praiseDirectoryEnabled: Boolean(Number(praiseDirectory?.value)),
      client: group?.groupName || undefined,
      clientTier: group?.clientTier || undefined,
      userType: userType?.name ?? '',
      department: departmentData?.name || undefined,
      departmentId: departmentData?.id || undefined,
      location: locationData?.name || undefined,
      locationId: locationData?.id || undefined,
      accessType: permissions?.value?.toLowerCase() ?? 'user',
      abTests: abTestCohorts,
      partner: group?.group?.isVirginPulseGroup
        ? VIRGIN_PULSE_PARTNER_NAME
        : null,
      locale: locale || '',
      identifiedAsLineManager: Boolean(isLineManager),
    };

    tracking.identifyUser({
      userId: uuid,
      traits,
    });

    if (window.clarity) {
      window.clarity('identify', uuid, {});
    }

    setDatadogUser({
      id: uuid,
      subDomain: traits.subdomain,
      isLineManager: traits.identifiedAsLineManager,
      organisationId: traits.groupId,
      locale: traits.locale,
      timezone,
      userType: traits.userType,
      role: traits.accessType,
      departmentName: traits.department,
      locationName: traits.location,
    });
  }
}

const ignoredErrors = [
  Errors.UnauthorizedError,
  Errors.TokenExpiredError,
  Errors.InternalServerError,
];

const useTrackLoggedInUser = (client: ApolloClient<any> | undefined) => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics && client) {
      trackLoggedInUser(client).catch(e => {
        if (isApolloError(e)) {
          if (e.graphQLErrors.length) {
            const [{ name }] = e.graphQLErrors;

            if (!ignoredErrors.includes(name as Errors)) {
              logException(e);
            }
          }
        } else {
          logException(e);
        }
      });
    }
  }, [client, location]);
};

export default useTrackLoggedInUser;
