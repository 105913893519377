import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { Namespace } from 'i18next';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { medium } from '@unmind/design-system-components-web';
import { CompassFurtherInfoPage } from './CompassFurtherInfoPage';
import * as S from './styles';
import HumanTouchToDigital from './assets/humanTouchToDigital.svg';

const TextContainer = styled.div`
  flex: 1 1 auto;

  ${medium(
    css`
      flex: 1 1 ${rem(260)};
    `,
  )}

  & > * + * {
    margin-top: ${rem(8)};
  }
`;

const ScienceIllustration = styled.img.attrs({
  src: HumanTouchToDigital,
  alt: '',
})`
  max-height: ${rem(130)};
`;

const SciencePanel = styled(S.InfoPanel)`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  ${medium(
    css`
      flex-direction: row;
    `,
  )}
`;

const ReferenceAnchor = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const ScienceHeadingThree = styled(S.HeadingThree)`
  margin-top: 0;
`;

const CalculatorHeadingThree = styled(S.HeadingThree)`
  margin-top: ${rem(32)};
`;

export const FinancialImpactCalculatorPage = () => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');

  return (
    <CompassFurtherInfoPage title={translate('financial_impact_calc.title')}>
      <SciencePanel>
        <TextContainer>
          <ScienceHeadingThree>
            {translate('financial_impact_calc.science_behind.title')}
          </ScienceHeadingThree>
          <S.Paragraph>
            {translate('financial_impact_calc.science_behind.para')}
          </S.Paragraph>
        </TextContainer>
        <ScienceIllustration />
      </SciencePanel>
      <S.InfoPanel>
        <S.HeadingTwo>
          {translate('financial_impact_calc.how_does_it_work.title')}
        </S.HeadingTwo>
        <S.Paragraph>
          {translate('financial_impact_calc.how_does_it_work.para_1')}
        </S.Paragraph>
        <Trans
          t={translate}
          i18nKey={'financial_impact_calc.how_does_it_work.indicators'}
          components={{
            unordered_list: <S.UnorderedList />,
            list_item: <li />,
          }}
        />
        <S.Paragraph>
          {translate('financial_impact_calc.how_does_it_work.para_2')}
        </S.Paragraph>
        <S.Paragraph>
          {translate('financial_impact_calc.how_does_it_work.para_3')}
        </S.Paragraph>
        <Trans
          t={translate}
          i18nKey={'financial_impact_calc.how_does_it_work.calculations'}
          components={{
            unordered_list: <S.UnorderedList />,
            list_item: <li />,
          }}
        />
        <CalculatorHeadingThree>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.title',
          )}
        </CalculatorHeadingThree>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.para',
          )}
        </S.Paragraph>
        <S.HeadingFour>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.presenteeism.title',
          )}
        </S.HeadingFour>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.presenteeism.para_1',
          )}
        </S.Paragraph>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.presenteeism.para_2',
          )}
        </S.Paragraph>
        <S.HeadingFour>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.attrition.title',
          )}
        </S.HeadingFour>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.figures_based_on.attrition.para',
          )}
        </S.Paragraph>
        <CalculatorHeadingThree>
          {translate(
            'financial_impact_calc.how_does_it_work.productivity.title',
          )}
        </CalculatorHeadingThree>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.productivity.para_1',
          )}
        </S.Paragraph>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.productivity.para_2',
          )}
        </S.Paragraph>
        <S.Paragraph>
          {translate(
            'financial_impact_calc.how_does_it_work.productivity.para_3',
          )}
        </S.Paragraph>
        <CalculatorHeadingThree>
          {translate('financial_impact_calc.how_does_it_work.references.title')}
        </CalculatorHeadingThree>
        <S.OrderedList>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_1',
            )}
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_2',
            )}
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_3',
            )}
          </li>
          <li>
            <Trans
              t={translate}
              i18nKey={
                'financial_impact_calc.how_does_it_work.references.ref_4'
              }
              components={{
                styled_link: (
                  <ReferenceAnchor
                    href={translate(
                      'financial_impact_calc.how_does_it_work.references.ref_4_link',
                    )}
                  />
                ),
              }}
            />
          </li>
          <li>
            <Trans
              t={translate}
              i18nKey={
                'financial_impact_calc.how_does_it_work.references.ref_5'
              }
              components={{
                styled_link: (
                  <ReferenceAnchor
                    href={translate(
                      'financial_impact_calc.how_does_it_work.references.ref_5_link',
                    )}
                  />
                ),
              }}
            />
          </li>
          <li>
            <Trans
              t={translate}
              i18nKey={
                'financial_impact_calc.how_does_it_work.references.ref_6'
              }
              components={{
                styled_link: (
                  <ReferenceAnchor
                    href={translate(
                      'financial_impact_calc.how_does_it_work.references.ref_6_link',
                    )}
                  />
                ),
              }}
            />
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_7',
            )}
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_8',
            )}
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_9',
            )}
          </li>
          <li>
            {translate(
              'financial_impact_calc.how_does_it_work.references.ref_10',
            )}
          </li>
        </S.OrderedList>
      </S.InfoPanel>
    </CompassFurtherInfoPage>
  );
};
