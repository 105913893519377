import { Risk_Category } from '__generated__/globalTypes';

// Risk question contentful ids
export const BURNOUT_QUESTION_ID = '2kR3r84tpPJxVfy4xNzIEw';
export const PRESENTEEISM_QUESTION_ID = '48VzdsLcxhdfYVdX4J2pXO';
export const ATTRITION_QUESTION_ID = '2KNa6tILJD6nmU23XSMZOK';

// Wellbeing theme contentful ids
export const EMPOWERMENT_THEME_ID = '4skDjTIUYegeeywCM4qsDm';
export const CONNECTION_THEME_ID = '6WxTYHOZWuIotdWSHu7axZ';
export const CLARITY_THEME_ID = 'TLqZtMpwX76CI2CxL15WB';
export const BALANCE_THEME_ID = 'ldNbZrbV0yjdAyjN89Lz1';
export const IMPACT_QUESTONS_THEME_ID = '1E5X3lQ3fxO5x0lZgIqR5x';

type ThemeIds =
  | typeof EMPOWERMENT_THEME_ID
  | typeof CONNECTION_THEME_ID
  | typeof CLARITY_THEME_ID
  | typeof BALANCE_THEME_ID
  | typeof IMPACT_QUESTONS_THEME_ID;

type ThemesTranslationKeys =
  | 'clarity'
  | 'burnout'
  | 'empowerment'
  | 'connection'
  | 'balance';

export type ActionTrackingData = {
  actionId: string;
  themeType: ThemeType;
  theme: string;
  themeId: string;
  scheduleId: string;
  riskCategory: Risk_Category;
  score: number;
};

export const mapThemeIdsToTranslationKeys: {
  [key in ThemeIds]: ThemesTranslationKeys;
} = {
  [EMPOWERMENT_THEME_ID]: 'empowerment',
  [CONNECTION_THEME_ID]: 'connection',
  [CLARITY_THEME_ID]: 'clarity',
  [BALANCE_THEME_ID]: 'balance',
  [IMPACT_QUESTONS_THEME_ID]: 'burnout',
};

export enum ThemeType {
  WELLBEING = 'Wellbeing',
  IMPACT = 'Impact',
}

export const mapThemeIdsToThemeType: {
  [key in ThemeIds]: ThemeType;
} = {
  [EMPOWERMENT_THEME_ID]: ThemeType.WELLBEING,
  [CONNECTION_THEME_ID]: ThemeType.WELLBEING,
  [CLARITY_THEME_ID]: ThemeType.WELLBEING,
  [BALANCE_THEME_ID]: ThemeType.WELLBEING,
  [IMPACT_QUESTONS_THEME_ID]: ThemeType.IMPACT,
};

export const mapThemeIdsToThemeName: {
  [key in ThemeIds]: string;
} = {
  [EMPOWERMENT_THEME_ID]: 'Empowerment',
  [CONNECTION_THEME_ID]: 'Connection',
  [CLARITY_THEME_ID]: 'Clarity',
  [BALANCE_THEME_ID]: 'Balance',
  [IMPACT_QUESTONS_THEME_ID]: 'Burnout',
};
