import { rem } from 'polished';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Close } from 'icons';
import { TimerDispatch } from '../Timer';
import { onNextStep as onNextStepType } from '../../Series/SingleSeries/SeriesDay/Buttons/ButtonsShared';
import NextButton from '../../Series/SingleSeries/SeriesDay/Buttons/NextButton';
import { small, medium } from '../../utils';
import { ScreenReaderContent } from '../Accessibility';
import { buttonReset } from '../Button';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: ${rem(16)} 0;
  display: block;
  flex: 0 0 auto;

  ${small(css`
    padding: ${rem(24)} 0;
  `)}
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
}))`
  margin: 0 auto;
  text-align: center;
  max-width: 80%;
`;

export const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin: 0 auto;
  padding-top: ${rem(4)};
  text-align: center;
  max-width: 80%;
`;

const TranscriptContent = styled.div`
  overflow-y: auto;
  padding: ${({ theme }) => `${rem(16)} ${rem(theme.grid.margins.mobile)} 0`};
  flex: 1 1 auto;

  ${small(css`
    padding: ${({ theme }) => `${rem(24)} ${rem(theme.grid.margins.tablet)} 0`};
  `)}

  ${medium(css`
    padding: 0;
  `)}

  /* Workaround as padding-bottom is ignored in non-Chrome browsers when overflow-y is
   * set */
  &:after {
    content: '';
    display: block;
    height: ${rem(24)};

    ${small(css`
      height: ${rem(52)};
    `)}

    ${medium(css`
      height: ${rem(84)};
    `)}
  }
`;

export const TranscriptHTML = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize18],
  forwardedAs: 'div',
}))`
  p:last-of-type {
    margin-bottom: 0;
  }

  ${medium(css`
    margin: 0 auto;
    width: ${rem(680)};
  `)}
`;

const SeriesNextButton = styled(NextButton)`
  margin: ${rem(24)} auto 0;
  width: 100%;

  ${small(css`
    margin: ${rem(36)} auto 0;
    width: auto;
  `)}

  ${medium(css`
    margin: ${rem(52)} auto 0;
  `)}
`;

const CloseIcon = styled(Close).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.secondary,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const CloseTranscriptButton = styled.button`
  ${buttonReset}
  width: ${rem(36)};
  height: ${rem(36)};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${rem(8)};
  right: ${rem(8)};

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
  &:focus {
    border: ${({ theme }) =>
      `${rem(2)} solid ${theme.button.borderFocusColor}`};
    outline: none;
  }

  ${small(css`
    top: ${rem(18)};
  `)}

  ${medium(css`
    right: ${rem(16)};
  `)}
`;

export interface TranscriptProps {
  segmentId?: string;
  title: string;
  subtitle?: string;
  transcript: string;
  onNextStep?: onNextStepType;
  timerDispatch: TimerDispatch;
  onCloseButtonClick?(): void;
  isLastSegmentInSeries?: boolean;
  isLastSegmentInDay?: boolean;
  isComplete?: boolean;
}

const Transcript = ({
  onCloseButtonClick,
  onNextStep,
  segmentId,
  subtitle,
  title,
  timerDispatch,
  transcript,
  isLastSegmentInSeries = false,
  isLastSegmentInDay = false,
  isComplete = false,
}: TranscriptProps) => {
  const { t: translate } = useTranslation('media_player');
  useEffect(() => {
    timerDispatch.reset();
    timerDispatch.start(true);
  }, [timerDispatch]);

  return (
    <Wrapper>
      <Header aria-label={translate('transcript.header')}>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <CloseTranscriptButton onClick={onCloseButtonClick}>
          <CloseIcon />
          <ScreenReaderContent as="span">
            {translate('transcript.close_button')}
          </ScreenReaderContent>
        </CloseTranscriptButton>
      </Header>
      <TranscriptContent>
        <TranscriptHTML
          dangerouslySetInnerHTML={{
            __html: transcript,
          }}
          data-testid="transcript"
        />
        {onNextStep && segmentId && (
          <SeriesNextButton
            segmentTitle={title}
            segmentId={segmentId}
            handleTakeOvers={onNextStep}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isLastSegmentInDay={isLastSegmentInDay}
            isComplete={isComplete}
          />
        )}
      </TranscriptContent>
    </Wrapper>
  );
};

export default Transcript;
