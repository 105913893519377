import { AppStore } from 'LoggedOut/AppStore/AppStore';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import styled from 'styled-components';
import { InviteALovedOne as InviteALovedOneIcon } from 'icons';
import { BodyText } from 'Shared/Typography';
import { medium } from 'utils';
import { css } from 'styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${medium(css`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const InformationPanelTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))``;

const InformationPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  padding: ${rem(16)};
  border-radius: ${rem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${rem(378)};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
`;

const MoreInfoText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.text.link,
  forwardedAs: 'a',
}))``;

const TitleText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin-bottom ${rem(24)};
`;

const InviteALovedOneImage = styled(InviteALovedOneIcon).attrs({
  width: rem(254),
  height: rem(204),
})`
  margin: ${rem(24)} 0;
`;

const AboutInviteALovedOneText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`margin-bottom ${rem(24)};
`;

export const InviteALovedOne = () => {
  const { t: translate } =
    useTranslation<Namespace<'invite_a_loved_one'>>('invite_a_loved_one');

  return (
    <Container>
      <div>
        <TitleText>{translate('title')}</TitleText>
        <AboutInviteALovedOneText>
          {translate('feature_description')}
        </AboutInviteALovedOneText>
        <AppStore helpText={translate('app_store_help_text')} />
      </div>
      <InformationPanel>
        <InformationPanelTitle>
          {translate('information_panel.header')}
        </InformationPanelTitle>
        <InviteALovedOneImage />
        <ul>
          <li>{translate('information_panel.bullet_points.one')}</li>
          <li>{translate('information_panel.bullet_points.two')}</li>
          <li>{translate('information_panel.bullet_points.three')}</li>
        </ul>
        <MoreInfoText
          href={translate('information_panel.faq.link')}
          aria-label={translate('information_panel.faq.a11y_label')}
          rel="noopener noreferrer"
          target="_blank"
        >
          {translate('information_panel.faq.description')}
        </MoreInfoText>
      </InformationPanel>
    </Container>
  );
};
