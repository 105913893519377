import { rem } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { tracking } from '../App/Tracking';
import {
  getSentAtTimestamp,
  ServersideTrackingEvent,
  updateInteractionSession,
} from '../App/Tracking/serverside';
import { HeadingText, BodyText } from '../Shared/Typography';
import { small, medium } from '../utils';
import Card from './Card';

const Container = styled(Card)`
  padding: ${rem(4)};
  width: 100%;

  ${small(css`
    width: 50%;
  `)}

  ${medium(css`
    width: 25%;
  `)}

  @supports (display: grid) {
    padding: 0;
    width: auto;
  }
`;

const OuterAspectRatioWrapper = styled.div<{ visibility: number }>`
visibility: ${({ visibility }) => (visibility === 1 ? 'visible' : 'hidden')}
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  box-shadow: 0 ${rem('2px')} ${rem('12px')} 0 rgba(0, 0, 0, 0.12);
  border-radius: ${rem('4px')};
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    min-height: 244px;
  }
`;

// istanbul ignore next
const InnerAspectRatioWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  background-color: ${({ theme }) => theme.colors.background.card};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

// istanbul ignore next
const ContentWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: ${rem('4px')};
  border: solid ${rem('1px')} ${({ theme }) => theme.colors.border.secondary};
  margin: ${rem('8px')};
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
  ],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.link,
}))`
  max-width: ${rem('180px')};
  margin: 0 0 ${rem('10px')};
  text-align: center;
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
  align: 'center',
}))`
  max-width: ${rem('180px')};
`;

const trackResourceOpened = (resourceTitle: string) => {
  tracking.track('help-resource-opened', {
    resourceCardTitle: resourceTitle,
  });
};

export interface ContentTileProps {
  title: string;
  id: string;
  description: string;
  linkTo: string;
  visible: boolean;
  trackServerside?(
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ): Promise<void>;
}

const ContentTile = ({
  title,
  id,
  description,
  linkTo,
  visible,
  trackServerside,
}: ContentTileProps) => (
  <Container
    data-testid="content-tile-link"
    aria-label={`${title} - ${description}`}
    to={visible ? linkTo : undefined}
    onClick={() => {
      if (trackServerside) {
        updateInteractionSession();
        void trackServerside('HELP_RESOURCE_CLICKED', {
          title,
          contentId: id,
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        });
      }
      trackResourceOpened(title);
    }}
  >
    <OuterAspectRatioWrapper visibility={visible ? 1 : 0}>
      <InnerAspectRatioWrapper>
        <ContentWrapper>
          <Title data-testid="content-tile-title">{title}</Title>
          <Description data-testid="content-tile-description">
            {description}
          </Description>
        </ContentWrapper>
      </InnerAspectRatioWrapper>
    </OuterAspectRatioWrapper>
  </Container>
);

export default ContentTile;
