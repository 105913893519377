import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';
import { useHistory } from 'react-router';
import { useKnownReferrer } from 'hooks/useKnownReferrer';
import { TimerDispatch } from '../../../../../Shared/Timer';
import { logException } from '../../../../../App/logging';
import { tracking } from '../../../../../App/Tracking';
import { AudioPlayer } from '../../../../../Shared/AudioPlayer';
import { FullScreenTakeover } from '../../../../../Shared/FullScreenTakeover';
import {
  MediaToggleMethod,
  MediaTrackingEvent,
} from '../../../../../Shared/MediaPlayerComponents';
import { mediaErrorCodeToMessageMapping } from '../../../../../Shared/MediaPlayerComponents/mediaErrorCodes';
import useHandleEscapeKeyPress from '../../../../../Shared/MediaPlayerComponents/useHandleEscapeKeyPress';
import Transcript from '../../../../../Shared/Transcript';
import { VideoPlayer } from '../../../../../Shared/VideoPlayer';
import { onNextStep as onNextStepType } from '../../Buttons/ButtonsShared';
import NextButton from '../../Buttons/NextButton';
import { TranscriptTakeover } from '../../../../../Shared/MediaPlayerComponents/TranscriptTakeover';

export interface MediaPlayerProps {
  onClose(): void;
  onClickReplay(): void;
  onClickPlay(): void;
  onClickPause(): void;
  segmentId: string;
  onNextStep: onNextStepType;
  isLastSegmentInSeries: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
  isTranscriptLoading: boolean;
  timerDispatch: TimerDispatch;
  segmentTitle: string;
  seriesTitle: string;
  seriesSlug: string;
  segmentSlug: string;
  transcript?: string | null;
  mediaSrc: string;
  mediaType: 'audio' | 'video';
  hasNextStep?: boolean;
  showCalendarIntegration?: boolean;
}

interface AdditionalEventProps {
  loopEnabled?: boolean;
  method?: string;
  startPosition?: number;
  endPosition?: number;
  position?: number;
  isVolumeMuted?: boolean;
  sound?: number;
}

const MediaPlayer = ({
  segmentId,
  onClose,
  onClickReplay,
  onClickPlay,
  onClickPause,
  onNextStep,
  isLastSegmentInSeries,
  isLastSegmentInDay,
  isComplete,
  timerDispatch,
  segmentTitle,
  seriesTitle,
  seriesSlug,
  segmentSlug,
  isTranscriptLoading,
  transcript,
  mediaSrc,
  mediaType,
  hasNextStep = true,
  showCalendarIntegration = true,
}: MediaPlayerProps) => {
  const { t: translate } = useTranslation('courses');
  const [showTranscript, setShowTranscript] = useState(false);
  // eslint-disable-next-line @typescript-eslint/tslint/config
  const [mediaSessionId] = useState(() => uuid());
  const referrer = useKnownReferrer();

  const closeTranscript = () => {
    setShowTranscript(false);

    tracking.track('media-transcript-closed', {
      feature: 'series',
      medium: mediaType,
      mediaSessionId,
      source: 'media-player',
    });
  };

  useHandleEscapeKeyPress(() => {
    if (showTranscript) {
      closeTranscript();
    }
  });

  const trackMediaEvent = ({
    trackingEvent,
    mediaElement,
    additionalEventProps: additionalTrackingProps = {},
  }: {
    trackingEvent: MediaTrackingEvent;
    mediaElement: HTMLMediaElement;
    additionalEventProps?: AdditionalEventProps;
  }) => {
    tracking.track(trackingEvent, {
      feature: 'series',
      medium: mediaType,
      mediaSessionId,
      totalLength: mediaElement.duration,
      contentSlug: `${seriesSlug}${segmentSlug}`,
      referrer: referrer,
      ...additionalTrackingProps,
    });
  };

  const sharedProps = {
    backButtonLabel: translate('media_player.back_button'),
    onBackButtonClick: onClose,
    hasTranscript: !!transcript,
    segmentId,
    onNextStep,
    isLastSegmentInSeries,
    isLastSegmentInDay,
    isComplete,
    mediaSrc,
    onClickReplay,
    onClickPlay,
    onClickPause,
    onSeek: ({
      playerRef,
      method,
      startPosition,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
      startPosition: number;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-seek',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            startPosition,
            endPosition: playerRef.current.currentTime,
          },
        });
      }
    },
    onResume: (
      playerRef: React.RefObject<HTMLMediaElement>,
      method: MediaToggleMethod,
    ) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-resumed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    onPause: (
      playerRef: React.RefObject<HTMLMediaElement>,
      method: MediaToggleMethod,
    ) => {
      if (playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-paused',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    onError: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      if (mediaEvent.currentTarget.error) {
        const mediaError = mediaEvent.currentTarget.error;
        const errorCode = mediaError.code;

        trackMediaEvent({
          trackingEvent: 'media-load-failed',
          mediaElement: mediaEvent.currentTarget,
          additionalEventProps: {
            method: mediaErrorCodeToMessageMapping[errorCode],
            position: mediaEvent.currentTarget.currentTime,
          },
        });

        logException(new Error('media-load-failed error'), {
          fingerprint: ['media-load-failed-error'],
          tags: {
            ...(mediaError.message !== '' && { message: mediaError.message }),
            code: String(mediaError.code),
          },
        });
      }
    },
    onLoading: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) =>
      trackMediaEvent({
        trackingEvent: 'media-playback-buffer-started',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      }),
    onLoaded: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      trackMediaEvent({
        trackingEvent: 'media-playback-buffer-completed',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      });
    },
    onEnded: (mediaEvent: React.SyntheticEvent<HTMLMediaElement>) => {
      trackMediaEvent({
        trackingEvent: 'media-playback-ended',
        mediaElement: mediaEvent.currentTarget,
        additionalEventProps: {
          position: mediaEvent.currentTarget.currentTime,
        },
      });
    },
    timerDispatch,
    title: segmentTitle,
    onTranscriptClick: ({
      position,
      totalLength,
    }: {
      position: number;
      totalLength: number;
    }) => {
      setShowTranscript(true);
      tracking.track('media-transcript-opened', {
        feature: 'series',
        medium: mediaType,
        position,
        totalLength,
        mediaSessionId,
        source: 'media-player',
      });
    },
    onClose: ({
      playerRef,
      method,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-session-closed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            position: playerRef.current.currentTime,
          },
        });
        onClose();
      }
    },
    onVolumeChange: ({
      playerRef,
      method,
      isVolumeMuted,
      sound,
    }: {
      playerRef: React.RefObject<HTMLMediaElement> | null;
      method: string;
      isVolumeMuted: boolean;
      sound: number;
    }) => {
      if (playerRef && playerRef.current) {
        trackMediaEvent({
          trackingEvent: 'media-playback-volume-changed',
          mediaElement: playerRef.current,
          additionalEventProps: {
            method,
            isVolumeMuted,
            sound,
            position: playerRef.current.currentTime,
          },
        });
      }
    },
    isTranscriptShown: showTranscript,
    mediaSessionId,
    feature: 'series',
    slug: `${seriesSlug}${segmentSlug}`,
    showCalendarIntegration,
  };

  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        if (showTranscript) {
          closeTranscript();
        } else {
          onClose();
        }

        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [showTranscript]);

  return (
    <>
      <FullScreenTakeover
        shown
        initFocus={false}
        reclaimFocus={!showTranscript}
      >
        {mediaType === 'audio' ? (
          <AudioPlayer
            {...sharedProps}
            onClickLoop={({ method, loopEnabled, playerRef }) => {
              if (playerRef.current) {
                trackMediaEvent({
                  trackingEvent: 'media-playback-loop-toggled',
                  mediaElement: playerRef.current,
                  additionalEventProps: {
                    loopEnabled,
                    method,
                    position: playerRef.current.currentTime,
                  },
                });
              }
            }}
            renderNextStepButton={
              hasNextStep ? (
                <NextButton
                  segmentId={segmentId}
                  segmentTitle={segmentTitle}
                  handleTakeOvers={onNextStep}
                  isLastSegmentInSeries={isLastSegmentInSeries}
                  isLastSegmentInDay={isLastSegmentInDay}
                  isComplete={isComplete}
                  variant="onDarkBg"
                />
              ) : null
            }
            referrer={referrer}
          />
        ) : (
          <VideoPlayer
            {...sharedProps}
            hasNextStep={hasNextStep}
            referrer={referrer}
          />
        )}
      </FullScreenTakeover>
      {!!transcript && !isTranscriptLoading && (
        <TranscriptTakeover show={showTranscript}>
          <Transcript
            title={segmentTitle}
            subtitle={seriesTitle}
            transcript={transcript}
            segmentId={segmentId}
            onCloseButtonClick={closeTranscript}
            onNextStep={onNextStep}
            timerDispatch={timerDispatch}
            isLastSegmentInSeries={isLastSegmentInSeries}
            isComplete={isComplete}
          />
        </TranscriptTakeover>
      )}
    </>
  );
};

export default MediaPlayer;
