import React, { useEffect } from 'react';
import { styled } from 'styles';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { NovaStartPage } from '../ExperimentalStartPages/NovaTeamsStartPage';
import { CurrentStartPage } from '../ExperimentalStartPages/CurrentStartPage';
import { TalkStartPage } from '../ExperimentalStartPages/TalkTeamsStartPage';
import { StickyFooter } from './StickyFooter';
import { Tools, UnmindTrailer, ChampionsTraining, Overview } from './Sections';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DefaultTeamsStartPage = () => (
  <Container data-testid="alternative-start">
    <StickyFooter />
    <UnmindTrailer />
    <Tools />
    <ChampionsTraining />
    <Overview />
  </Container>
);

export const AlternativeMSTeamsStartPage = () => {
  const teamsHomePageExperimentVariation = useFeatureFlag<string>(
    FEATURE_FLAGS.TEAMS_HOME_PAGE_EXPERIMENT,
  );

  useEffect(() => {
    const scroll = parseInt(localStorage.getItem('scrollPosition') || '0', 10);

    if (scroll && scroll !== 0) {
      window.scrollTo({
        top: scroll,
        behavior: 'auto',
      });
      localStorage.removeItem('scrollPosition');
    }
  }, []);

  switch (teamsHomePageExperimentVariation) {
    case 'current-start-page':
      return <CurrentStartPage />;
    case 'nova-focussed-start-page':
      return <NovaStartPage />;
    case 'talk-focussed-start-page':
      return <TalkStartPage />;
    default:
      return <DefaultTeamsStartPage />;
  }
};
