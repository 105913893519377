import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavHelp = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M2.166 12A12.01 12.01 0 0 1 12 2.166M25.834 12A12.01 12.01 0 0 0 16 2.166M16 25.834A12.01 12.01 0 0 0 25.834 16M12 25.834A12.01 12.01 0 0 1 2.166 16"
      stroke={primaryColor}
      strokeWidth={2}
    />
    <path
      d="M9 16H3a2 2 0 0 1-2-2v0a2 2 0 0 1 2-2h5.98M16 19v6a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2v-6M19.019 12H25a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-6M12 8.981V3a2 2 0 0 1 2-2v0a2 2 0 0 1 2 2v6"
      stroke={primaryColor}
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M20 14A6 6 0 1 1 8 14 6 6 0 0 1 20 14z"
      stroke={primaryColor}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgNavHelp;
