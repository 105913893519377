import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { styled } from 'styles';
import { BodyText } from '../../../../Shared/Typography';
import SegmentHeader from './SegmentHeader';
import { UserSeriesSegment } from './SeriesSegment';

const SegmentContainer = styled.div<{ isLast: boolean }>`
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  border-bottom: ${({ isLast }) => rem(isLast ? 1 : 0)} solid
    ${({ theme }) => theme.colors.border.secondary};
  max-width: ${rem(710)};
  margin: 0 auto;
`;

export const SegmentDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  p:first-child {
    margin-top: 0;
    margin-bottom: ${rem(20)};
  }
`;

const AnimationContainer = styled.div<{ expanded: boolean }>`
  transition: max-height 300ms ease-in-out;
  max-height: ${({ expanded }) => (expanded ? rem('3000px') : 0)};
  overflow: hidden;
  padding: ${({ expanded }) =>
    expanded ? `0 ${rem(9)} ${rem(32)}` : `0 ${rem(9)} 0`};
  margin: 0 -${rem(9)} 0;
`;

interface SeriesSegmentUIProps
  extends Pick<
    UserSeriesSegment,
    'durationSeconds' | 'icon' | 'introHTML' | 'title'
  > {
  children: ReactNode;
  idx: number;
  isComplete: boolean;
  isExpanded: boolean;
  isLast?: boolean;
  onToggle?(idx: number): void;
  segmentsLeft: number;
}

export default function SeriesSegmentUI({
  children,
  durationSeconds,
  icon,
  idx,
  isComplete,
  isExpanded,
  onToggle,
  segmentsLeft,
  title,
  introHTML,
  isLast = false,
}: SeriesSegmentUIProps) {
  return (
    <SegmentContainer isLast={isLast}>
      <SegmentHeader
        idx={idx}
        icon={icon}
        onToggle={onToggle}
        isComplete={isComplete}
        isExpanded={isExpanded}
        segmentsLeft={segmentsLeft}
        title={title}
        durationSeconds={durationSeconds}
      />
      <AnimationContainer expanded={isExpanded}>
        {introHTML && (
          <SegmentDescription dangerouslySetInnerHTML={{ __html: introHTML }} />
        )}
        {children}
      </AnimationContainer>
    </SegmentContainer>
  );
}
