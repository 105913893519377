import { UserGroupFilterStateKey } from '../../FilterState/types';

export const trackingFilterTypes: Record<UserGroupFilterStateKey, string> = {
  locationIds: 'location',
  departmentIds: 'department',
} as const;

export enum FilterSelectionMethod {
  USER_SELECTED_TAB = 'user-selected-tab',
  USER_SELECTED_FILTER = 'user-selected-filter',
  USER_SELECTED_TIME_PERIOD = 'user-selected-time-period',
  DEFAULT = 'default',
}
