import React from 'react';
import { IconProps } from '../IconProps';

const SvgInsufficientData = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 42 42" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.176 36.048a3.075 3.075 0 0 1-2.751 4.452h-14.65a3.075 3.075 0 0 1-2.75-4.452L27.348 21.4a3.075 3.075 0 0 1 5.502 0l7.325 14.648z"
      fill={secondaryColor}
      stroke={primaryColor}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1 35.3a.65.65 0 1 1 0 1.3.65.65 0 0 1 0-1.3M30.1 31.4v-5.2M6.7 11.9v15.6h7.8"
      stroke={primaryColor}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.7 22.3l4.66-4.66a2.6 2.6 0 0 1 3.68 0l1.006 1.006a2.6 2.6 0 0 0 4.067-.5l.62-1.04"
      stroke={primaryColor}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2 35.3H4.1a2.6 2.6 0 0 1-2.6-2.6V4.1a2.6 2.6 0 0 1 2.6-2.6h18.424a2.6 2.6 0 0 1 1.837.76l4.978 4.98a2.6 2.6 0 0 1 .761 1.836V13.2"
      stroke={primaryColor}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgInsufficientData;
