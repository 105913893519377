import {
  BodyText,
  HeadingText,
  Button,
  medium,
  extraSmall,
} from '@unmind/design-system-components-web';
import RoutePath from 'App/RoutePath';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${rem(20)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  display: flex;
  flex-direction: column;
  ${medium(css`
    flex-direction: row;
  `)}
`;

const TextWrapper = styled.div`
  padding: ${rem(16)};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(12)};
  align-items: center;

  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
}))``;

export const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: theme.typography.fontWeights.regular,
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(24)};
`;

export const NavigateButton = styled(Button)`
  margin: ${rem(8)};
`;

const ImageWrapper = styled.div`
  ${extraSmall(css`
    width: 75%;
  `)}

  ${medium(css`
    width: 33.33%;
    align-self: normal;
  `)}

  width: 85%;
  display: flex;
  align-items: flex-end;
  align-self: center;
`;

export const BannerImage = styled.img`
  width: 100%;
`;

const BetaBadge = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.button.primary.default};
  border-radius: ${rem(12)};
  padding: ${rem(3)} ${rem(6)};

  > span {
    color: ${({ theme }) => theme.button.primary};
    font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14}px;
  }
`;

const CompassHomepageBanner = () => {
  const { t: translate } = useTranslation('compass');
  const compassBannerImage = 'compass-homepage-banner';
  const translatedImageSrc = useTranslatedAssets(
    ['compass-homepage-banner'],
    'png',
  );

  return (
    <Container>
      <TextWrapper>
        <TitleContainer>
          <Title>{translate('homepage_banner.title')}</Title>
          <BetaBadge>{translate('homepage_banner.beta_tag')}</BetaBadge>
        </TitleContainer>
        <Subtitle>{translate('homepage_banner.subtitle')}</Subtitle>
        <NavigateButton
          label={translate('homepage_banner.navigate_button')}
          to={RoutePath.Compass}
          variant="primary"
        />
      </TextWrapper>
      <ImageWrapper>
        <BannerImage
          src={translatedImageSrc[compassBannerImage]}
          aria-hidden={true}
        />
      </ImageWrapper>
    </Container>
  );
};

export default CompassHomepageBanner;
