import React from 'react';
import { styled } from 'styles';
import { categoryContent_exploreCategory_subCategories as ExploreSubCategory } from '../../Category/__generated__/categoryContent';
import SubCategoryCard from '../SubCategoryCard/SubCategoryCard';

export interface SubCategoryListProps {
  categories: ExploreSubCategory[];
  onNavigateExploreCategory(slug: string, title: string): void;
}

const SubCategoryCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

function SubCategoryList({
  categories,
  onNavigateExploreCategory,
}: SubCategoryListProps) {
  return (
    <SubCategoryCardsContainer>
      {categories.map(category => (
        <SubCategoryCard
          key={category.id}
          category={category}
          onNavigateExploreCategory={onNavigateExploreCategory}
        />
      ))}
    </SubCategoryCardsContainer>
  );
}

export default SubCategoryList;
