import { StylelessButton } from 'Shared/Button';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const TimeSelectorContainer = styled.div.attrs({
  role: 'listbox',
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: ${rem(16)};
`;

export const TimeSlotButton = styled(StylelessButton).attrs<{
  selected: boolean;
}>(({ selected }) => ({
  role: 'option',
  'aria-selected': !!selected,
}))<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(8)};
  background: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${rem(16)};
  width: calc(50% - ${rem(8)});

  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.colors.background.inverse};
      color: ${({ theme }) => theme.colors.staticPalette.white};
    `}
`;
