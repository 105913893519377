import React from 'react';
import { IconProps } from '../IconProps';

const SvgEye = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64.868c-1.729.085-3.492.755-5.276 2.006-1.094.767-2.18 1.746-3.21 2.893a15.79 15.79 0 0 0-.688.812c-.23.32-.374.693-.432 1.116-.02.146-.02.471 0 .616.054.397.177.732.384 1.044.066.1.103.148.236.308a21.96 21.96 0 0 0 2.061 2.149c2.03 1.832 4.103 2.934 6.1 3.243 1.701.262 3.394-.04 5.152-.92 1.648-.824 3.32-2.16 4.877-3.896.271-.302.604-.694.688-.812.228-.316.375-.697.432-1.116.02-.145.02-.47 0-.616a2.497 2.497 0 0 0-.337-.972c-.089-.146-.182-.266-.485-.617-1.11-1.288-2.306-2.39-3.507-3.232C13.602 1.449 11.58.773 9.639.868zM9.65 2.3C7.983 2.4 6.238 3.17 4.447 4.6c-.877.7-1.813 1.62-2.631 2.588-.187.221-.217.262-.265.36a1.07 1.07 0 0 0 .052 1.008c.04.062.33.407.56.664.55.618 1.214 1.278 1.78 1.769 1.133.982 2.256 1.702 3.368 2.16 1.837.756 3.54.756 5.376 0 1.193-.491 2.411-1.293 3.612-2.376a21.536 21.536 0 0 0 1.54-1.557c.212-.237.515-.595.548-.647a1.06 1.06 0 0 0 0-1.13 19.741 19.741 0 0 0-2.696-2.727c-1.744-1.425-3.482-2.24-5.1-2.391a6.754 6.754 0 0 0-.94-.02zm.192 2.136a3.563 3.563 0 0 0-3.41 3.415c-.028.73.144 1.4.516 2.012.37.608.943 1.113 1.588 1.4.52.23 1.036.329 1.616.307a3.567 3.567 0 0 0 3.413-3.72 3.559 3.559 0 0 0-1.375-2.668c-.572-.445-1.31-.72-1.996-.743a3.259 3.259 0 0 0-.352-.003zm.036 1.428a2.144 2.144 0 0 0-2.009 1.9 3.005 3.005 0 0 0 0 .48c.06.464.235.851.532 1.184a2.13 2.13 0 0 0 1.997.679 2.14 2.14 0 0 0 1.62-2.817 2.15 2.15 0 0 0-1.58-1.383 2.522 2.522 0 0 0-.56-.043z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgEye;
