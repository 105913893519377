const formatLocale = (locale: string) => {
  const intlLocale = new Intl.Locale(locale);

  if (!intlLocale.region && !intlLocale.script) {
    return locale;
  }

  const parts = [intlLocale.language];

  if (intlLocale.script) {
    parts.push(intlLocale.script);
  }

  if (intlLocale.region) {
    parts.push(intlLocale.region.toUpperCase());
  }

  return parts.join('-');
};

export default formatLocale;
