import React from 'react';
import { IconProps } from '../IconProps';
const SvgWave = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#Wave_svg__clip0_469_351)">
      <g
        clipPath="url(#Wave_svg__clip1_469_351)"
        stroke={primaryColor}
        strokeOpacity={0.95}
        strokeWidth={1.25}
        strokeLinecap="round"
      >
        <path d="M18.75 5.61a4.136 4.136 0 00-.79-2.184M4.816 2.174A4.138 4.138 0 016.592.68" />
        <path
          d="M4.457 17.294l-.5-.57a8.684 8.684 0 01-1.61-2.686l-.995-2.656a1.61 1.61 0 012.985-1.211l.84 1.918c.63.168 2.073.825 2.81 2.102"
          strokeLinejoin="round"
        />
        <path
          d="M5.177 12.092l2.11-7.87a1.63 1.63 0 013.147.844L9.169 9.787l1.77-6.61a1.63 1.63 0 113.149.843l-1.94 7.24 1.096-4.092a1.63 1.63 0 113.148.844l-2.473 9.23a2.895 2.895 0 01-1.349 1.757l-.558.322"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="Wave_svg__clip0_469_351">
        <path fill={primaryColor} d="M0 0h20v20H0z" />
      </clipPath>
      <clipPath id="Wave_svg__clip1_469_351">
        <path fill={primaryColor} d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWave;
