import times from 'lodash/times';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import { BodyText } from '../../../../Shared/Typography';

interface ProgressIndicatorProps {
  itemsLeft: number;
}

interface SingleIndicatorProps {
  active?: boolean;
}

const Container = styled.div`
  display: flex;
`;

const DotContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

export const SingleSegmentIndicator = styled.div`
  display: flex;
  width: ${rem('20px')};
  height: ${rem('20px')};
  border-radius: 50%;
  border: ${rem('1px')} solid
    ${({ active, theme }) =>
      active === true
        ? theme.colors.primary
        : theme.colors.background.secondary};
  background: ${({ theme }) => theme.colors.background.primary};
  margin-left: ${({ active }: SingleIndicatorProps) =>
    active === true ? 0 : rem('24px')};
  position: relative;
`;

export const SingleIndicatorCenter = styled.div<{ active?: boolean }>`
  width: ${rem('6px')};
  height: ${rem('6px')};
  border-radius: 50%;
  margin: auto;
  position: relative;
  background: ${({ active, theme }) =>
    active === true ? theme.colors.primary : theme.colors.background.secondary};
`;

export const ItemsLeftNumber = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  padding-bottom: ${rem('1px')};
  padding-right: ${rem('1px')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  position: absolute;
  top: calc(50% - ${rem('1px')});
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: ${rem('2px')};
  background: ${({ theme }) => theme.colors.background.secondary};
`;

export const MAX_INDICATORS_TO_DISPLAY = 6;

export class SegmentsProgressIndicator extends React.Component<ProgressIndicatorProps> {
  render() {
    const { itemsLeft } = this.props;
    const maxSingleIndicators = MAX_INDICATORS_TO_DISPLAY;
    const moreLeft = itemsLeft - MAX_INDICATORS_TO_DISPLAY + 1;
    const indicators =
      itemsLeft <= maxSingleIndicators
        ? Math.max(itemsLeft, 0)
        : maxSingleIndicators - 1;

    return (
      <Container aria-hidden="true">
        <DotContainer>
          <Line />
          {times(indicators, idx => (
            <SingleSegmentIndicator
              key={idx}
              active={idx === 0}
              data-testid="segments-progress-indicator-dot"
            >
              <SingleIndicatorCenter active={idx === 0} />
            </SingleSegmentIndicator>
          ))}
          {moreLeft >= 2 && (
            <SingleSegmentIndicator data-testid="segments-progress-indicator-plus-remaining">
              <ItemsLeftNumber>+{moreLeft}</ItemsLeftNumber>
            </SingleSegmentIndicator>
          )}
        </DotContainer>
      </Container>
    );
  }
}
