import React, { ReactNode, useEffect, useState } from 'react';
import { styled } from 'styles';
import { FullScreenTakeover } from '../FullScreenTakeover';

export const SLIDE_DURATION_MS = 350;

export const Takeover = styled(FullScreenTakeover)<{
  transcriptUp: boolean;
}>`
  transition: transform ${SLIDE_DURATION_MS}ms;
  ${({ transcriptUp }) =>
    transcriptUp ? `transform: translateY(0);` : `transform: translateY(100%);`}
`;

export const TranscriptTakeover = ({
  show,
  children,
}: {
  show: boolean;
  children: ReactNode;
}) => {
  const [transcriptState, setTranscriptState] = useState<
    'hidden' | 'shown' | 'slidingDown'
  >('hidden');

  useEffect(() => {
    if (show) {
      setTranscriptState('shown');

      return;
    }

    if (transcriptState === 'shown') {
      setTranscriptState('slidingDown');

      setTimeout(() => {
        setTranscriptState('hidden');
      }, SLIDE_DURATION_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const transcriptShouldNotBeMounted = !(show || transcriptState !== 'hidden');

  if (transcriptShouldNotBeMounted) {
    return null;
  }

  return (
    <Takeover
      shown
      transcriptUp={transcriptState === 'shown'}
      hasWhiteBackground
    >
      {children}
    </Takeover>
  );
};
