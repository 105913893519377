import React from 'react';
import { IconProps } from '../IconProps';

const SvgEyeLid = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g
      clipPath="url(#EyeLid_svg__clip0_1124_26749)"
      stroke="#0D0300"
      strokeOpacity={0.95}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.633 6.132c1.348 2.504 4.14 4.225 7.368 4.225 3.227 0 6.02-1.72 7.367-4.225M4.114 8.056L1 10.666M7.807 10.092l-1.5 3.776M15.886 8.056L19 10.666M12.188 10.092l1.5 3.776" />
    </g>
    <defs>
      <clipPath id="EyeLid_svg__clip0_1124_26749">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyeLid;
