import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { styled, ThemeInterface } from 'styles';
import { Chevron, ForwardSlash } from 'icons';
import RoutePath from '../../App/RoutePath';
import { buttonReset } from '../../Shared/Button';
import { BodyText } from '../../Shared/Typography';
import useViewportWidth from '../../utils/useViewportWidth';
import { BreadcrumbHistory, BreadcrumbState } from './setBreadcrumbState';

const BreadcrumbContainer = styled.div`
  display: flex;
  margin-bottom: ${rem(28)};
`;

const BreadcrumbBackButton = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.text.link};
  }
`;

const BackButton = styled(BreadcrumbBackButton)`
  background: none;
  border: none;
  padding: 0;
`;

const MobileBackButton = styled.button`
  ${buttonReset}
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const BackContainer = styled.span`
  margin-right: ${rem(6)};
  display: flex;
  align-item: center:
  justify-content: center;
`;

const BackArrow = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
}))`
  transform: rotate(180deg);
  height: ${rem(10)};
  width: ${rem(10)};
`;

const BackText = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    color: theme.colors.text.link,
    sizes: [theme.typography.fontSizes.fontSize14],
  }),
)`
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.text.link};
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
`;

const Slash = styled(ForwardSlash).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
}))`
  height: ${rem(10)};
  width: ${rem(6)};
  margin: 0 ${rem(5)};
`;

const CurrentCategory = styled(BodyText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    sizes: [theme.typography.fontSizes.fontSize14],
  }),
)``;

const MobileBackArrow = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  transform: rotate(180deg);
  height: ${rem(16)};
  width: ${rem(16)};
`;

const BreadcrumbNavigation = () => {
  const { t: translate } = useTranslation('explore');
  const { state, key } = useLocation();
  const history = useHistory();
  const width = useViewportWidth();

  const getNewState: (index: number) => void = (index: number) => {
    const newBreadcrumbHistory = state.breadcrumbHistory.slice(0, index);

    return {
      breadcrumbHistory: newBreadcrumbHistory,
      currentTitle: state.breadcrumbHistory[index].fromTitle,
    } as BreadcrumbState;
  };

  //User has deep linked in and this is their initial location
  const isInitialLocation = !state && !key;

  /**
   * When a user has come from another page within the app and we
   * have passed the location when navigating the user.
   */
  const knownEntryPoint = state?.from && !state?.breadcrumbHistory;

  /**
   * When the user has come from somewhere else within app
   * but we have not passed the location when navigating the user
   * e.g. pressing the back button in the media player
   */
  const unknownEntryPoint = !state?.from && !state?.breadcrumbHistory && key;

  const handleBackNavigation = () => {
    if (isInitialLocation) {
      history.push(RoutePath.Explore);
    } else if (knownEntryPoint) {
      history.goBack();
    } else if (unknownEntryPoint) {
      history.push(RoutePath.Home);
    }
  };

  const defaultBreadcrumbs = () => (
    <BreadcrumbContainer data-testid="default-breadcrumb-navigation">
      <BreadcrumbBackButton to={{ pathname: RoutePath.Explore }}>
        <BackContainer>
          <BackArrow />
        </BackContainer>
        <BackText>{translate('title')}</BackText>
      </BreadcrumbBackButton>
    </BreadcrumbContainer>
  );

  // For mobile sized views only show back button (goes to /explore on deep linking)
  if (width < 600) {
    return (
      <BreadcrumbContainer data-testid="mobile-breadcrumb-navigation">
        <MobileBackButton
          onClick={handleBackNavigation}
          aria-label={translate('category_page.back_button.a11y_label')}
        >
          <MobileBackArrow />
        </MobileBackButton>
      </BreadcrumbContainer>
    );
  }

  // User has deep linked in or not come from a known entry point - show Explore default
  if (isInitialLocation) {
    return defaultBreadcrumbs();
  }

  if (state && state.breadcrumbHistory) {
    const past = state.breadcrumbHistory;

    // User has come from the Explore page - show Explore default
    if (
      (past[0].from === RoutePath.Explore && past.length < 2) ||
      !state.currentTitle
    ) {
      return defaultBreadcrumbs();
    }

    // User has come from a Category page - show Breadcrumbs
    return (
      <BreadcrumbContainer data-testid="category-breadcrumb-navigation">
        {past.map((item: BreadcrumbHistory, i: number) => {
          if (item.from && item.fromTitle) {
            return (
              <Breadcrumb key={`breadcrumb-${i}`}>
                <BreadcrumbBackButton
                  to={{
                    pathname: item.from,
                    state: getNewState(i),
                  }}
                >
                  <BackText>{item.fromTitle}</BackText>
                </BreadcrumbBackButton>
                <Slash aria-hidden />
              </Breadcrumb>
            );
          }
        })}
        <CurrentCategory>{state.currentTitle}</CurrentCategory>
      </BreadcrumbContainer>
    );
  }

  if (knownEntryPoint || unknownEntryPoint) {
    return (
      <BreadcrumbContainer data-testid="generic-breadcrumb-navigation">
        <BackButton as={'button'} onClick={handleBackNavigation}>
          <BackContainer>
            <BackArrow />
          </BackContainer>
          <BackText>{translate('category_page.back_button.label')}</BackText>
        </BackButton>
      </BreadcrumbContainer>
    );
  }

  return defaultBreadcrumbs();
};

export default BreadcrumbNavigation;
