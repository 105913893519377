import { lightTheme, Colors } from '@unmind/design-system-theme';
import { multiSelectTheme } from './multiSelect';
import { GridColumns, GridGutters, GridMargins } from './grid';
import ZIndex from './zIndex';

export const defaultTheme = {
  ...lightTheme,
  grid: {
    columns: GridColumns,
    gutters: GridGutters,
    margins: GridMargins,
  },
  zIndex: ZIndex,
  colors: {
    ...lightTheme.colors,
    multiSelect: multiSelectTheme,
    chatbot: {
      messages: {
        container: {
          background: Colors.glassDark100,
        },
        user: {
          text: Colors.glassDark900,
          icon: {
            background: Colors.lilac500,
          },
        },
        assistant: {
          background: Colors.cream100,
          text: Colors.glassDark900,
          icon: {
            background: Colors.glassDark900,
            fill: Colors.cream100,
          },
        },
        loading: {
          bubbles: Colors.glassDark500,
        },
      },
    },
    login: {
      text: Colors.gray8,
      panel: Colors.gray2,
      border: Colors.gray9,
    },
    index: {
      calmness: Colors.bluish,
      connection: Colors.chestnutRose,
      coping: Colors.burnt,
      fulfilment: Colors.amber,
      happiness: Colors.grass,
      health: Colors.topaz,
      sleep: Colors.plum,
      bands: {
        low: Colors.orange600,
        mid: Colors.blue500,
        high: Colors.kiwi500,
      },
    },
    mood: {
      saddest: Colors.orange600,
      sadder: Colors.orange500,
      sad: Colors.orange300,
      neutral: Colors.sky500,
      happy: Colors.kiwi400,
      happier: Colors.kiwi500,
      happiest: Colors.kiwi600,
    },
    signup: {
      privacyBackground: Colors.negroni,
      privacyIconForeground: Colors.gray9,
      privacyIconBackground: Colors.orange9,
    },
    start: {
      aboutBackground: Colors.hummingBirdBlue,
      iconForeground: Colors.gray9,
      iconBackground: Colors.aqua5,
    },
    welcome: {
      topBackground: Colors.yellow1,
      bottomBackground: Colors.white,
      iconForeground: Colors.gray9,
      iconBackground: Colors.yellow3,
    },
    series: {
      search: {
        background: Colors.gray2,
      },
    },
    mediaPlayer: {
      audio: {
        icon: {
          default: Colors.glassDark900,
          hover: Colors.glassDark700,
          background: Colors.glassDark200,
        },
        backButton: {
          hover: Colors.glassDark100,
        },
        borderFocus: Colors.blue700,
      },
    },
  },
};

type defaultThemeType = typeof defaultTheme;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ThemeInterface extends defaultThemeType {}

export type MoodColors = ThemeInterface['colors']['mood'];

export default defaultTheme;
