import { rem, rgba } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { medium } from '../../utils';
import BodyText from '../Typography/BodyText';

const TooltipContainer = styled.div<{
  minWidth: number;
  isLeftOffset: boolean;
}>`
  background-color: ${({ theme }) => rgba(theme.colors.background.inverse, 1)};
  border-radius: ${rem(4)};
  min-width: ${props => rem(props.minWidth)};
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: ${rem(44)};
  ${medium(css`
    bottom: ${rem(48)};
  `)}
  @media (max-width: ${props => rem(props.theme.layout.breakpoints.S)}) {
    left: ${props => (props.isLeftOffset ? '0%' : '50%')};
  }
`;

const TooltipText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.staticPalette.white,
}))`
  text-align: center;
  padding: ${rem(18)} ${rem(4)} 0 ${rem(4)};
  position: relative;
  bottom: ${rem(10)};
`;

const TooltipArrow = styled.div`
  position: absolute;
  right: 50%;
  bottom: ${rem(36)};
  width: ${rem(16)};
  height: ${rem(16)};
  left: 50%;
  background-color: ${({ theme }) => rgba(theme.colors.background.inverse, 1)};
  transform: translateX(-50%) rotate(45deg);
  ${medium(css`
    bottom: ${rem(40)};
  `)}
`;

interface MediaButtonTooltipProps {
  label: string;
  minWidth: number;
  isLeftOffset?: boolean;
}

const MediaButtonTooltip = ({
  label,
  minWidth,
  isLeftOffset = false,
}: MediaButtonTooltipProps) => (
  <>
    <TooltipContainer
      minWidth={minWidth}
      isLeftOffset={isLeftOffset}
      data-testid="progress-bar-tooltip"
    >
      <TooltipText role="tooltip">{label}</TooltipText>
    </TooltipContainer>
    <TooltipArrow />
  </>
);

export default MediaButtonTooltip;
