import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  QueryHookOptions,
  useQuery,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { omit } from 'lodash';
import { FilterSelectionMethod } from '../FilterPanel/types';
import { AdminTabType } from '../../types';
import { TimePeriod } from '../../../__generated__/globalTypes';
import {
  DashboardFilterState,
  PublicFilterState,
  UserGroupFilterState,
  UserGroupFilterStateKey,
} from './types';
import { getDateRange, getPreviousPeriodDateRange } from './getDateRange';
import { stripUnusedQueryVariables } from './helpers';

export const DEFAULT_TIME_FILTER = TimePeriod.LAST_30_DAYS;

export const FilterContext = createContext<DashboardFilterState | undefined>(
  undefined,
);

export const useFilterState = (): PublicFilterState => {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterState must be used within a FilterProvider');
  }

  const {
    timeFilterPreset,
    setUserGroupFilterState,
    userGroupFilterState,
    filterSelectionMethodRef,
    isInitialTabLoadRef,
  } = context;

  const timeFilterState = getDateRange(timeFilterPreset);

  const timeFilterPreviousPeriodState = getPreviousPeriodDateRange(
    timeFilterState,
    timeFilterPreset,
  );

  const setUserGroupFilterType = (
    filterType: UserGroupFilterStateKey,
    isDefault = false,
  ) => {
    if (!isDefault) {
      filterSelectionMethodRef.current =
        FilterSelectionMethod.USER_SELECTED_FILTER;
    }

    const currentUserGroupFilterType = userGroupFilterState
      ? Object.keys(userGroupFilterState)[0]
      : null;

    if (filterType !== currentUserGroupFilterType) {
      setUserGroupFilterState({
        [filterType]: [] as string[],
      } as UserGroupFilterState);
    }
  };

  const userGroupFilterType = userGroupFilterState
    ? (Object.keys(userGroupFilterState)[0] as UserGroupFilterStateKey)
    : undefined;

  return {
    ...context,
    setUserGroupFilterType,
    userGroupFilterType,
    timeFilterState,
    timeFilterPreviousPeriodState,
    filterSelectionMethod: filterSelectionMethodRef.current,
    isInitialTabLoad: isInitialTabLoadRef.current,
  };
};

export const FilterProvider = ({
  children,
  adminTabType,
}: {
  children?: ReactNode;
  adminTabType: AdminTabType;
}) => {
  const [timeFilterPreset, innerSetTimeFilterPreset] =
    useState<TimePeriod>(DEFAULT_TIME_FILTER);
  const [userGroupFilterState, setUserGroupFilterState] =
    useState<UserGroupFilterState>();

  const filterSelectionMethodRef = useRef<FilterSelectionMethod>(
    FilterSelectionMethod.DEFAULT,
  );
  const isInitialTabLoadRef = useRef<boolean>(true);

  const setTimeFilterPreset = (newTimeFilterPreset: TimePeriod) => {
    filterSelectionMethodRef.current =
      FilterSelectionMethod.USER_SELECTED_TIME_PERIOD;
    innerSetTimeFilterPreset(newTimeFilterPreset);
  };

  useEffect(() => {
    setUserGroupFilterState(currentState => {
      if (currentState) {
        const newState = Object.keys(currentState).reduce(
          (acc, key) => ({ ...acc, [key]: [] }),
          {},
        ) as UserGroupFilterState;

        return newState;
      }
    });

    if (isInitialTabLoadRef.current) {
      isInitialTabLoadRef.current = false;
    } else {
      filterSelectionMethodRef.current =
        FilterSelectionMethod.USER_SELECTED_TAB;
    }
  }, [adminTabType]);

  const filterState = {
    userGroupFilterState,
    timeFilterPreset,
    setUserGroupFilterState,
    setTimeFilterPreset,
    adminTabType,
    filterSelectionMethodRef,
    isInitialTabLoadRef,
  };

  return (
    <FilterContext.Provider value={filterState}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilteredQuery = <
  TData extends unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  /*
   * Omit defaultOptions and nextFetchPolicy because the compiler complains
   * about them for reasons I don't understand. This will cause problems if we
   * want to pass these arguments into useFilteredQuery, but we can cross that
   * bridge if we come to it.
   */
  options?: Omit<
    QueryHookOptions<TData, TVariables>,
    'defaultOptions' | 'nextFetchPolicy'
  >,
): QueryResult<TData> => {
  const { timeFilterState, userGroupFilterState } = useFilterState();

  const nullFreeUserGroupFilters = userGroupFilterState
    ? stripUnusedQueryVariables(userGroupFilterState)
    : {};

  const optionsVariables = omit(options?.variables, 'input');

  const variables: OperationVariables = {
    input: {
      ...timeFilterState,
      ...nullFreeUserGroupFilters,
      ...(options?.variables?.input || {}),
    },
    ...optionsVariables,
  };

  return useQuery<TData>(query, {
    ...options,
    variables,
  });
};

// For testing
const stubFilterState: Omit<
  DashboardFilterState,
  'filterSelectionMethodRef' | 'isInitialTabLoadRef'
> = {
  userGroupFilterState: undefined,
  timeFilterPreset: DEFAULT_TIME_FILTER,
  setUserGroupFilterState: () => undefined,
  setTimeFilterPreset: () => undefined,
  adminTabType: 'activity' as const,
};

export const TestingFilterProvider = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<DashboardFilterState>;
}) => {
  const stubFilterSelectionRef = useRef<FilterSelectionMethod>(
    FilterSelectionMethod.DEFAULT,
  );
  const stubIsInitialTabLoadRef = useRef<boolean>(true);

  return (
    <FilterContext.Provider
      value={{
        ...stubFilterState,
        filterSelectionMethodRef: stubFilterSelectionRef,
        isInitialTabLoadRef: stubIsInitialTabLoadRef,
        ...value,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
