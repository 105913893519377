import React from 'react';
import {
  getValidationResult,
  PasswordValidator,
} from '../ValidatorMatch/PasswordValidator';
import { Input, InputProps } from './Input';

export interface ValidatedPasswordInputProps extends InputProps {
  showValidator?: boolean;
  value: string;
}

const ValidatedPasswordInput = ({
  name = 'password',
  placeholder = 'Password',
  showValidator = true,
  value = '',
  ...props
}: ValidatedPasswordInputProps) => (
  <>
    <Input
      name={name}
      placeholder={placeholder}
      value={value}
      {...props}
      // override error as don't want to use Input error message and styling
      error={undefined}
      secure={true}
    />
    {showValidator && <PasswordValidator {...getValidationResult(value)} />}
  </>
);

export default ValidatedPasswordInput;
