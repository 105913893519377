// These must be the first lines in src/index.js in order for IE11 to work
/* eslint-disable import/no-unassigned-import */
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'regenerator-runtime/runtime';
/* eslint-enable import/no-unassigned-import */
import React from 'react';

import ReactDOM from 'react-dom';
import App from './App';
import { initializeLogging } from './App/logging';
// eslint-disable-next-line import/no-unassigned-import
import './i18n/config';

initializeLogging();

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  const axeConf = {
    rules: [{ id: 'color-contrast', enabled: false }],
  };
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000, axeConf);
}

ReactDOM.render(<App />, document.getElementById('root'));
