import React from 'react';
import { IconProps } from '../IconProps';

const SvgConnection = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Connection_svg__clip0_1095_24957)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M20.62 18.77v-8.65a2 2 0 0 0-2-2H6.29a2 2 0 0 0-2 2v8.65a2 2 0 0 0 2 2h12.33a2 2 0 0 0 2-2z"
        fill="#FFF9EF"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2 14.41c.22-.59.14-1.14-.09-1.61h6.5V6.68c0-1.1-.9-2-2-2H6.28c-1.1 0-2 .9-2 2v6.12h6.15c-.13.27-.22.58-.22.9 0 1.27 1.14 2.26 2.45 2.02.7-.13 1.29-.65 1.53-1.32l.01.01z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Connection_svg__clip0_1095_24957">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgConnection;
