import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_FALLBACK_LOCALE } from './defaultLocaleConfig';

export const useTranslatedAssets = (prefixes: string[], suffix: string) => {
  const { i18n } = useTranslation();
  const [assets, setAssets] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const importAssets = async (prefix: string, language: string) =>
      (await import(`./assets/${prefix}-${language}.${suffix}`))
        .default as string;

    const loadAssets = async () => {
      try {
        const importedAssets = await Promise.all(
          prefixes.map(async prefix => importAssets(prefix, i18n.language)),
        );

        setAssets(
          importedAssets.reduce(
            (acc, asset, index) => ({
              ...acc,
              [prefixes[index]]: asset,
            }),
            {},
          ),
        );
      } catch (error) {
        /**
         * If we're unable to import store badge image for a locale,
         * just fallback to import default locale store badge.
         *
         * This is to avoid throwing an error if a locale store badge
         * isn't present.
         */
        const importedAssets = await Promise.all(
          prefixes.map(async prefix =>
            importAssets(prefix, DEFAULT_FALLBACK_LOCALE),
          ),
        );

        setAssets(
          importedAssets.reduce(
            (acc, asset, index) => ({
              ...acc,
              [prefixes[index]]: asset,
            }),
            {},
          ),
        );
      }
    };

    void loadAssets();
  }, [i18n.language]);

  return assets;
};
