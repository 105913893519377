import React from 'react';
import { TimeSlot } from 'Talk/lib/availability';
import {
  FieldValues,
  FormValues,
} from 'Talk/components/PractitionerDrawer/types';
import { TimeSelector } from 'Talk/components/PractitionerDrawer/components/TimeSelector';
import { practitionerQuery_practitioner_availability } from 'Services/BeGateway/Practitioner/__generated__/practitionerQuery';
import { useHistory } from 'react-router';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import DayPicker from '../DayPicker';
import {
  BrowseButton,
  Container,
  Description,
  DurationLabel,
  Subheader,
} from './styles';

export interface SetFieldValue {
  (
    field: 'bookingStage' | 'selectedDate' | 'range',
    value?: FormValues[keyof FormValues] | undefined,
    shouldValidate?: boolean | undefined,
  ): void;
  (field: string, value: string): void;
}

interface BookingStageDateSelectProps {
  setFieldValue(field: string, value: unknown): void;
  setValues(values: FormValues): void;
  formValues: FormValues;
  availableTimes: practitionerQuery_practitioner_availability;
}

export const BookingStageDateSelect = ({
  availableTimes,
  formValues,
  setValues,
  setFieldValue,
}: BookingStageDateSelectProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const history = useHistory();

  const hasAvailableSlots =
    availableTimes.hasAvailability && availableTimes.times?.length;

  return (
    <Container>
      <DurationLabel duration={availableTimes.availabilityConfig?.duration} />
      <DayPicker formValues={formValues} setValues={setValues} />
      {hasAvailableSlots ? (
        <TimeSelector
          day={formValues[FieldValues.SelectedDate]}
          selectedSlot={formValues[FieldValues.SelectedSlot]}
          practitionerSlots={availableTimes.times as TimeSlot[]}
          onSelect={(slot: TimeSlot) =>
            setFieldValue(FieldValues.SelectedSlot, slot)
          }
        />
      ) : (
        <>
          <Subheader>{t('booking_flow.no_available_slots.heading')}</Subheader>
          <Description>{t('booking_flow.no_available_slots.body')}</Description>
          <BrowseButton
            role="button"
            onClick={() => history.push(RoutePath.TalkBrowse)}
            label={t('booking_flow.no_available_slots.button')}
          />
        </>
      )}
    </Container>
  );
};
