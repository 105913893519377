import React from 'react';
import { IconProps } from '../IconProps';

const SvgForward = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g fill="none" fillRule="evenodd" stroke={primaryColor} strokeWidth={2}>
      <circle fillRule="nonzero" fill={secondaryColor} cx={28} cy={28} r={19} />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.444 19.111L33.334 28l-8.89 8.889"
      />
    </g>
  </svg>
);

export default SvgForward;
