import React from 'react';
import { IconProps } from '../IconProps';

const SvgSuccess = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
      fill={primaryColor}
      fillOpacity={0.95}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.184 4.512a.833.833 0 0 1 .3 1.14l-5.095 8.73a1.628 1.628 0 0 1-1.798.762 1.596 1.596 0 0 1-.622-.312l-3.25-2.6A.833.833 0 1 1 5.76 10.93l3.213 2.57 5.07-8.688a.833.833 0 0 1 1.14-.3z"
      fill={secondaryColor}
      fillOpacity={0.95}
    />
  </svg>
);

export default SvgSuccess;
