import { ProfileProps } from './Profile';
interface ChangeUserDetailsProps extends Pick<ProfileProps, 'updateUser'> {
  firstName: string;
  lastName: string;
  praiseDirectory?: boolean;
  emailAddress: string;
  initialEmailAddress: string;
  location?: string;
  department?: string;
}

export const changeUserDetails = async ({
  firstName,
  lastName,
  emailAddress,
  praiseDirectory,
  initialEmailAddress,
  location,
  department,
  updateUser,
}: ChangeUserDetailsProps) => {
  const shouldUpdateEmailAddress = emailAddress !== initialEmailAddress;
  const { error: apiError } = await updateUser({
    firstName,
    lastName,
    praiseDirectory,
    emailAddress: shouldUpdateEmailAddress ? emailAddress : undefined,
    locationId: location,
    departmentId: department,
  });

  if (apiError) {
    return {
      success: false,
    };
  }

  return {
    success: true,
    emailAddressUpdate: shouldUpdateEmailAddress,
  };
};
