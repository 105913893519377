import React from 'react';
import { useTheme, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { useFilterState } from '../../Dashboard/FilterState';
import BodyText from '../../../Shared/Typography/BodyText';
import { TimePeriod } from '../../../__generated__/globalTypes';
import { renderUtcDate } from '../helpers';

const DateRangeHeadingText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-top: ${rem(8)};
`;

export const DateRangeHeading = () => {
  const { colors } = useTheme();

  const textColor = colors.text.secondary;

  const { t: translate } = useTranslation('admin');

  const { timeFilterPreset, timeFilterState } = useFilterState();

  const DATE_FORMAT = 'do MMMM yyyy';

  const currentStartDateLabel = renderUtcDate(
    timeFilterState.startDate,
    'yyyy-MM-dd',
    DATE_FORMAT,
  );

  const currentEndDateLabel = renderUtcDate(
    timeFilterState.endDate,
    'yyyy-MM-dd',
    DATE_FORMAT,
  );

  const labelTemplates = {
    [TimePeriod.LAST_30_DAYS]: `Data for last 30 days from ${currentStartDateLabel} to ${currentEndDateLabel}`,
    [TimePeriod.LAST_12_WEEKS]: `Data for last 12 weeks from ${currentStartDateLabel} to ${currentEndDateLabel}`,
    [TimePeriod.LAST_6_MONTHS]: `Data for last 6 months from ${currentStartDateLabel} to ${currentEndDateLabel}`,
    [TimePeriod.ALL_TIME]: `Data for all time to ${currentEndDateLabel}`,
  };

  const updatedAtText = translate('date_range_heading.update_frequency');

  return (
    <>
      <DateRangeHeadingText color={textColor}>
        {labelTemplates[timeFilterPreset]}
      </DateRangeHeadingText>
      <DateRangeHeadingText color={textColor}>
        {updatedAtText}
      </DateRangeHeadingText>
    </>
  );
};
