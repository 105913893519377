import i18n from '../i18n/config';

export const termsOfUseLinks = {
  nhs: i18n.t('shared:terms_of_use_link.nhs'),
  slaughterAndMay: i18n.t('shared:terms_of_use_link.slaughter_and_may'),
  defaultLink: i18n.t('shared:terms_of_use_link.default'),
};

export const getTermsOfUseLink = (subdomain?: string) => {
  switch (subdomain) {
    case 'nhs':
      return termsOfUseLinks.nhs;
    case 'slaughterandmay':
      return termsOfUseLinks.slaughterAndMay;
    default:
      return termsOfUseLinks.defaultLink;
  }
};
