import React from 'react';
import gql from 'graphql-tag';
import { css, styled } from 'styles';
import { useQuery } from '@apollo/client';
import SectionError from 'Shared/SectionError/SectionError';
import { rem } from 'polished';
import SkeletonCard from 'Courses/SkeletonCard';
import { small } from '@unmind/design-system-components-web';
import { getTodayFeaturedHero } from './__generated__/getTodayFeaturedHero';
import FeaturedDailyBoost from './FeaturedDailyBoost';
import FeaturedHero from './FeaturedHero';

export const TODAY_FEATURED_HERO_QUERY = gql`
  query getTodayFeaturedHero {
    homeAssembly {
      id
      featuredHeroCourses {
        id
        slug
        title
        subtitle
        totalDays
        daysCompleted
        cloudinaryThumbnail {
          path
        }
        exploreCategory {
          slug
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: grid;
  height: ${rem(350)};
  margin-bottom: ${rem(32)};

  ${small(css`
    height: ${rem(450)};
    margin-bottom: ${rem(40)};
  `)};
`;

export const FeaturedContentWrapper = styled.div`
  margin-top: ${rem(40)};
`;

type FeaturedContentProps = {
  assetToken: string | null | undefined;
};

const FeaturedContent = ({ assetToken }: FeaturedContentProps) => {
  const { data, loading, error, refetch } = useQuery<getTodayFeaturedHero>(
    TODAY_FEATURED_HERO_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
    },
  );

  if (loading) {
    return (
      <Container>
        <SkeletonCard />
      </Container>
    );
  }

  if (error) {
    return <SectionError onRetry={async () => refetch()} />;
  }

  const todayFeaturedHero = data?.homeAssembly?.featuredHeroCourses;

  return !todayFeaturedHero?.length ? (
    <FeaturedDailyBoost assetToken={assetToken} />
  ) : (
    <FeaturedHero assetToken={assetToken} course={todayFeaturedHero[0]} />
  );
};

export default FeaturedContent;
