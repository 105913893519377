import { BodyText, small } from '@unmind/design-system-components-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rem } from 'polished';
import { css } from 'styles';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { ActionButton } from '../ExperimentalStartPages/Shared/ActionButton';
import { CreateAccountButton, SignInButton } from './Sections/Shared/Buttons';

const Footer = styled.div`
  padding: ${rem(10)} 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.background.primary};

  ${small(css`
    flex-direction: row;
  `)}
`;

const ButtonsContainer = styled.div<{ isTeamsExperiment: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ isTeamsExperiment }) =>
    !isTeamsExperiment &&
    css`
      & > * {
        margin-right: ${rem(10)};
      }
    `}
`;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  ${small(css`
    margin-right: ${rem(10)};
  `)};
`;

const HideForMobileViewText = styled(Text)`
  display: none;
  ${small(css`
    display: flex;
  `)};
`;

const BoldedText = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  margin-bottom: ${rem(10)};

  ${small(css`
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    margin-bottom: 0;
  `)};
`;

export const StickyFooter = ({
  isTeamsExperiment = false,
}: {
  isTeamsExperiment?: boolean;
}) => {
  const { t: translate } = useTranslation('logged_out');
  const subdomain = getSubdomainFromUrl();
  const ctaNavigationPath =
    !subdomain || subdomain === 'search'
      ? RoutePath.SignInToYourOrganisation
      : RoutePath.Home;

  return (
    <Footer>
      <BoldedText>
        {translate('ms_teams_alternative_start.sticky_header.header')}
      </BoldedText>
      <HideForMobileViewText>
        {translate('ms_teams_alternative_start.sticky_header.description')}
      </HideForMobileViewText>
      <ButtonsContainer isTeamsExperiment={isTeamsExperiment}>
        {isTeamsExperiment ? (
          <ActionButton
            label="Get started with Unmind ->"
            path={ctaNavigationPath}
            onClick={() =>
              tracking.track('teams-start-page-cta-clicked', {
                abTestGroup: 'current-start-page-sept-2024',
                tenantId:
                  getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
                ctaLocation: 'sticky-footer',
              })
            }
          />
        ) : (
          <>
            <CreateAccountButton />
            <SignInButton />
          </>
        )}
      </ButtonsContainer>
    </Footer>
  );
};
