import RoutePath from '../../App/RoutePath';
import { categoryContent_exploreCategory as CategoryContent } from '../Category/__generated__/categoryContent';

export interface BreadcrumbHistory {
  from: string;
  fromTitle?: string;
}

export interface BreadcrumbState {
  breadcrumbHistory: BreadcrumbHistory[];
  currentTitle?: string;
}

export const setBreadcrumbState = ({
  currentState,
  currentCategory,
  currentTitle,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentState: any;
  currentCategory?: CategoryContent;
  currentTitle: string;
}) => {
  const newHistoryItem = {
    from: currentCategory
      ? `${RoutePath.Explore}/${currentCategory.slug}`
      : RoutePath.Explore,
    fromTitle: currentCategory ? currentCategory.title : null,
  };

  const newBreadcrumbHistory: BreadcrumbHistory[] =
    currentState &&
    currentState.breadcrumbHistory &&
    Array.isArray(currentState.breadcrumbHistory)
      ? [...currentState.breadcrumbHistory, newHistoryItem]
      : [
          {
            // Note: Explore is not a breadcrumb, but is needed for the breadcrumb history
            from: RoutePath.Explore,
          },
          newHistoryItem,
        ];

  return {
    breadcrumbHistory: newBreadcrumbHistory,
    currentTitle,
  } as BreadcrumbState;
};
