import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Error } from 'icons';
import { WrappingButton } from '../../Shared/Accessibility';
import { HeadingText, BodyText } from '../../Shared/Typography';
import { small } from '../../utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  margin: 16px 16px 20px 16px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;

  ${small(css`
    margin: 24px 20px 32px 20px;
  `)}
`;

const Contents = styled.div`
  text-align: center;
`;

const ErrorIcon = styled(Error).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.error,
  height: '24px',
  width: '24px',
}))`
  margin-bottom: 16px;
`;

const StyledHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  align: 'center',
}))`
  margin-bottom: 8px;
`;

const SubHeading = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  align: 'center',
}))`
  margin-bottom: 8px;
`;

const RetryText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.link,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-bottom: 8px;
`;

interface GenericErrorProps {
  retry?: boolean;
  onRetry?(): void;
}

const GenericError = ({
  retry = false,
  onRetry = () => null,
}: GenericErrorProps) => {
  const { t: translate } = useTranslation('explore');

  return (
    <Container>
      <Contents>
        <ErrorIcon />
        <StyledHeading>{translate('error.title')}</StyledHeading>
        {!retry && <SubHeading>{translate('error.subtitle')}</SubHeading>}
        {retry && (
          <WrappingButton
            onClick={() => {
              onRetry();
            }}
            aria-label={translate('error.retry_button.a11y_label')}
          >
            <RetryText>{translate('error.retry_button.text')}</RetryText>
          </WrappingButton>
        )}
      </Contents>
    </Container>
  );
};

export default GenericError;
