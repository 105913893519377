/* eslint-disable complexity */
import {
  de,
  enGB,
  enUS,
  es,
  fr,
  it,
  pl,
  ptBR,
  zhCN,
  ar,
  bn,
  cs,
  da,
  el,
  faIR,
  fi,
  he,
  hi,
  hu,
  ja,
  ko,
  lt,
  ms,
  nl,
  ro,
  ru,
  sv,
  ta,
  th,
  tr,
  vi,
  zhHK,
} from 'date-fns/locale';
import { getUserLocale } from './getUserLocale';

/**
 * Certain pieces of our UI use date-fns `format` functionality,
 * which requires a shorthand version of the locale in the format it is retrieved.
 *
 * For a list of date-fns supported locales:
 *
 * @see {@link https://github.com/date-fns/date-fns/blob/main/src/locale/index.ts}
 */
export const mapUserLocaleToShorthand = () => {
  const userLocale = getUserLocale();

  switch (userLocale) {
    case 'de-DE':
      return de;
    case 'en-US':
      return enUS;
    case 'es-419':
      return es;
    case 'fr-FR':
      return fr;
    case 'it-IT':
      return it;
    case 'pl-PL':
      return pl;
    case 'pt-BR':
      return ptBR;
    case 'zh-CN':
      return zhCN;
    case 'ar':
      return ar;
    case 'bn-BD':
      return bn;
    case 'cs-CZ':
      return cs;
    case 'da-DK':
      return da;
    case 'el-GR':
      return el;
    case 'es-ES':
      return es;
    case 'fa-IR':
      return faIR;
    case 'fi-FI':
      return fi;
    case 'he-IL':
      return he;
    case 'hi-IN':
      return hi;
    case 'hu-HU':
      return hu;
    case 'ja-JP':
      return ja;
    case 'ko-KR':
      return ko;
    case 'lt-LT':
      return lt;
    case 'ms-MY':
      return ms;
    case 'nl-NL':
      return nl;
    case 'ro-RO':
      return ro;
    case 'ru-RU':
      return ru;
    case 'sv-SE':
      return sv;
    case 'ta-IN':
      return ta;
    case 'th-TH':
      return th;
    case 'tr-TR':
      return tr;
    case 'vi-VN':
      return vi;
    case 'zh-HK':
      return zhHK;
    case 'zh-Hant':
      return zhHK;
    default:
      return enGB;

    // the following locales are not supported yet by date-fns:
    // filPH, jvID, noNO, paPK, swKE, urPK, zhHant
  }
};
