import { CURRENCY_SYMBOLS, DEFAULT_AVG_SALARY } from './averageSalaryHelpers';

export const formatOverallCost = (cost: number, currencySymbol: string) => {
  if (cost >= 1000000) {
    return `${currencySymbol}${(cost / 1000000).toFixed(0)}M`;
  }
  if (cost >= 1000) {
    return `${currencySymbol}${(cost / 1000).toFixed(0)}K`;
  }

  return `${currencySymbol}${cost}`;
};

export const getOverallCost = (
  initialCost: number,
  currency: string,
  averageSalary: string,
) => {
  const overallCost =
    averageSalary === DEFAULT_AVG_SALARY
      ? initialCost
      : (initialCost / parseInt(DEFAULT_AVG_SALARY, 10)) *
        parseInt(averageSalary, 10);

  return formatOverallCost(overallCost, CURRENCY_SYMBOLS[currency]);
};
