import React from 'react';
import { IconProps } from '../IconProps';
const SvgWand = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
    <g clipPath="url(#Wand_svg__clip0_1727_1715)">
      <path
        d="M15.348 2.27l-1.02 3.012a.498.498 0 000 .45l1.906 2.55a.486.486 0 01-.4.776h-3.182a.522.522 0 00-.4.207l-1.773 2.55a.486.486 0 01-.862-.134l-.948-3.036a.461.461 0 00-.315-.315l-3.036-.947a.486.486 0 01-.134-.863l2.599-1.833a.522.522 0 00.206-.4V1.104a.486.486 0 01.729-.401l2.55 1.906a.498.498 0 00.45.061l3.01-1.02a.486.486 0 01.62.62z"
        fill="#FFF964"
      />
      <path
        d="M15.348 2.27l-1.02 3.012a.498.498 0 000 .45l1.906 2.55a.486.486 0 01-.4.776h-3.182a.523.523 0 00-.4.207l-1.773 2.55a.486.486 0 01-.862-.134l-.948-3.036a.461.461 0 00-.315-.315l-3.036-.947a.486.486 0 01-.134-.863l2.599-1.833a.522.522 0 00.206-.4V1.104a.486.486 0 01.729-.401l2.55 1.906a.498.498 0 00.45.061l3.01-1.02a.486.486 0 01.62.62zM8.549 8.451L.607 16.393"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Wand_svg__clip0_1727_1715">
        <path fill="#fff" d="M0 0h17v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWand;
