import { Clear, ClearHover, Search as MagnifyingGlass } from 'icons';
import { rem } from 'polished';
import { default as React, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { css, styled, useTheme } from 'styles';
import { Link, useHistory } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { extraSmall, small } from '../../utils';
import { BodyText } from '../../Shared/Typography';
import RoutePath from '../../App/RoutePath';
import { ContentType } from '../../Explore/types';
import { tracking } from '../../App/Tracking';
import { ServersideTrackingEvent } from '../../App/Tracking/serverside';
import { LOG_EVENT_MUTATION } from '../../App/Tracking/serverside/withLogEvent';
import {
  exploreSearch_exploreSearch as ExploreSearchResponse,
  exploreSearch_exploreSearch_edges_node_Series as Series,
  exploreSearch_exploreSearch_edges_node_Tool as Tool,
  exploreSearch_exploreSearch_edges_node_HelpSearchText as HelpSearchText,
} from './__generated__/exploreSearch';
import ResultsList from './Results/ResultsList';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background.primary};
  z-index: ${({ theme }) => theme.zIndex.modal};
  padding: ${rem(20)} ${rem(16)};

  //Fixes issue to allow scrolling on firefox (mobile)
  @-moz-document url-prefix() {
    overflow: auto;
  }

  ${extraSmall(css`
    background-color: transparent;
    bottom: 0;
    padding: ${rem(94)} ${rem(24)} ${rem(20)};
  `)}
`;

const BackOverlay = styled(Link)`
  display: none;
  cursor: default;
  z-index: -1;
  background-color: ${({ theme }) => theme.colors.background.skeleton};

  ${extraSmall(css`
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `)}
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  ${small(css`
    max-width: ${rem(545)};
    margin: ${rem(13)} auto 0;
  `)}
`;

const SearchBar = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  box-shadow: ${({ theme }) =>
    `inset 0px 0px 0px 1px ${theme.colors.border.secondary}`};
  border-radius: ${rem(32)};
  padding: ${rem(8)} ${rem(8)} ${rem(8)} ${rem(16)};
  display: flex;
  align-items: center;
  flex: 1;
  height: ${rem(48)};
  margin-right: ${rem(12)};

  ${extraSmall(css`
    z-index: ${({ theme }) => theme.zIndex.modal};
    margin-top: ${rem(-18)};
    margin-right: 0;
  `)}

  ${small(css`
    margin-top: 0;
  `)}
`;

const SearchIcon = styled(MagnifyingGlass).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-right: ${rem(10)};
`;

const TextInput = styled.input`
  border: 0;
  outline: none;
  display: flex;
  flex: 1;

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

const ClearButton = styled.div`
  display: none;

  ${extraSmall(css`
    display: flex;
  `)}
`;

const ClearIcon = styled(Clear).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(32)};
  height: ${rem(32)};
`;

const ClearHoverIcon = styled(ClearHover).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(32)};
  height: ${rem(32)};
`;

const CancelButton = styled(Link)`
  ${extraSmall(css`
    display: none;
  `)}
`;

interface ModalProps {
  searchTerm: string;
  searchSessionId: string;
  setSearchTerm(term: string): void;
  runSearchQuery(text: string): void;
  setIsFirstSearch(isFirstSearch: boolean): void;
  results: ExploreSearchResponse | null;
  assetToken: string | null;
  isLoading: boolean;
  hasErrored?: ApolloError;
}

export type SearchAbortMethod = 'text-cleared' | 'cancel';

const Modal = ({
  searchTerm,
  searchSessionId,
  setSearchTerm,
  runSearchQuery,
  setIsFirstSearch,
  results,
  assetToken,
  isLoading,
  hasErrored,
}: ModalProps) => {
  const { colors, typography } = useTheme();
  const { t: translate } = useTranslation('explore');
  const [isClearHovered, setIsClearHovered] = useState(false);
  const history = useHistory();
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const showSearchList = Boolean(searchTerm);
  const resultsListRef = useRef(null);

  useEffect(() => {
    if (searchTerm && !results) {
      runSearchQuery(searchTerm);
    }
    setIsFirstSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const trackSearchAborted = (searchAbortMethod: SearchAbortMethod) => {
    tracking.track('search-aborted', {
      searchTerm,
      searchAbortMethod,
      searchResultCount: results?.totalCount,
    });
  };

  const trackResultSelected = async (
    contentType: ContentType,
    contentSlug: string,
    contentId: string,
    resultsPosition: number,
  ) => {
    const searchResultPosition = resultsPosition + 1;
    history.replace({
      pathname: location.pathname,
      search: `?q=${searchTerm}`,
      hash: location.hash,
    });

    tracking.track('search-result-selected', {
      contentType,
      contentSlug,
      contentId,
      searchTerm,
      searchResultPosition,
    });

    await trackServerside('SEARCH_RESULT_SELECTED', {
      searchSessionId,
      searchTerm,
      contentType,
      contentId,
      searchResultPosition,
      searchResultRank: results?.edges?.[resultsPosition]?.rank || 0,
    });
  };

  return (
    <Wrapper>
      <BackOverlay
        to={RoutePath.Explore}
        onClick={() => trackSearchAborted('cancel')}
      />
      <SearchWrapper>
        <SearchBar>
          <SearchIcon aria-hidden={true} />
          <TextInput
            name={translate('search.search_input.placeholder_text')}
            aria-label={translate('search.search_input.a11y_label')}
            value={searchTerm}
            onChange={event => {
              runSearchQuery(event.target.value);
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            type="search"
            role="combobox"
            aria-live="polite"
            aria-controls="matches"
            aria-expanded={searchTerm !== ''}
            autoComplete="off"
            spellCheck={false}
          />
          <ClearButton
            onMouseEnter={() => setIsClearHovered(true)}
            onMouseLeave={() => setIsClearHovered(false)}
            onClick={() => {
              setSearchTerm('');
              runSearchQuery('');
              trackSearchAborted('text-cleared');
            }}
            aria-label={translate('search.clear_button.a11y_label')}
            role="button"
          >
            {isClearHovered ? <ClearHoverIcon /> : <ClearIcon />}
          </ClearButton>
        </SearchBar>
        <CancelButton
          to={RoutePath.Explore}
          aria-label={translate('search.cancel_button.a11y_label')}
        >
          <Link
            to={RoutePath.Explore}
            onClick={() => trackSearchAborted('cancel')}
          >
            <BodyText
              color={colors.text.link}
              sizes={[typography.fontSizes.fontSize12]}
            >
              {translate('search.cancel_button.label')}
            </BodyText>
          </Link>
        </CancelButton>
      </SearchWrapper>
      <CSSTransition
        in={showSearchList}
        unmountOnExit={true}
        timeout={{ enter: 200, exit: 200 }}
        classNames={'search-results'}
        nodeRef={resultsListRef}
      >
        <ResultsList
          searchTerm={searchTerm}
          results={
            results?.edges?.map(result => result?.node) as (
              | Series
              | Tool
              | HelpSearchText
            )[]
          }
          assetToken={assetToken}
          runSearchQuery={runSearchQuery}
          isLoading={isLoading}
          hasErrored={hasErrored}
          trackResultSelected={trackResultSelected}
          ref={resultsListRef}
        />
      </CSSTransition>
    </Wrapper>
  );
};

export default Modal;
