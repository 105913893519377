import React from 'react';
import Container from 'Shared/Container';
import RoutePath from 'App/RoutePath';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { SingleSeries } from '../../withSingleSeries';
import { getSeriesDayTitle } from '../SeriesOverview';
import { CourseHeader } from './components/CourseHeader';
import { NextSessionLink } from './components/NextSessionLink';
import { CourseDescription } from './components/CourseDescription';
import { CourseContent } from './components/CourseContent';
import { useSingleSeriesWithSegments } from './useSingleSeriesWithSegments';
import { Container as CourseContentContainer } from './components/styles';
import { CourseProgress } from './components/CourseProgress';

interface NewCoursesOverviewProps {
  course: SingleSeries;
  activeDay: number;
  slug: string;
}
export const NewCoursesOverview = ({
  course,
  activeDay,
  slug,
}: NewCoursesOverviewProps) => {
  const { seriesData, seriesLoading } = useSingleSeriesWithSegments(slug);

  const activeDayTitle = getSeriesDayTitle(course, activeDay);
  const activeDayLinkPath = `${RoutePath.Series}/${slug}/day/${activeDay}`;

  return (
    <Container>
      <CourseHeader course={course} />
      <CourseContentContainer>
        <CourseProgress
          totalDays={course.seriesDays.length}
          daysCompleted={seriesData?.daysCompleted || 0}
        />
        <NextSessionLink
          activeDayTitle={activeDayTitle}
          activeDayLinkPath={activeDayLinkPath}
          activeDay={activeDay}
        />
        <CourseDescription course={course} />
        {seriesData && (
          <CourseContent
            course={seriesData}
            activeDayTitle={activeDayTitle}
            slug={slug}
          />
        )}
        {seriesLoading && <LoadingIndicator />}
      </CourseContentContainer>
    </Container>
  );
};
