import React, { SVGProps } from 'react';
import { get } from 'lodash';
import { styled, useTheme } from 'styles';
import {
  Waves1,
  Waves10,
  Waves11,
  Waves12,
  Waves2,
  Waves3,
  Waves4,
  Waves5,
  Waves6,
  Waves7,
  Waves8,
  Waves9,
} from 'icons';
import {
  DEFAULT_WAVE_PATTERN,
  NUMBER_OF_WAVE_PERMUTATIONS,
} from '../constants';

const TopLevelColours = {
  Blue: 'Blue',
  Aqua: 'Aqua',
  Green: 'Green',
  Orange: 'Orange',
  Purple: 'Purple',
  Pink: 'Pink',
  Yellow: 'Yellow',
};

const wavePatterns = {
  0: Waves1,
  1: Waves2,
  2: Waves3,
  3: Waves4,
  4: Waves5,
  5: Waves6,
  6: Waves7,
  7: Waves8,
  8: Waves9,
  9: Waves10,
  10: Waves11,
  11: Waves12,
};

interface FallbackWaveProps extends SVGProps<SVGSVGElement> {
  title: string;
  colourTheme?: string;
}

const FallbackWaves = ({
  title,
  colourTheme = TopLevelColours.Orange,
}: FallbackWaveProps) => {
  const theme = useTheme();
  const categoryColours = {
    [TopLevelColours.Blue]: {
      primaryColor: theme.colors.staticPalette.blue.blue7,
      secondaryColor: theme.colors.staticPalette.blue.blue6,
      tertiaryColor: theme.colors.staticPalette.blue.blue1,
    },
    [TopLevelColours.Aqua]: {
      primaryColor: theme.colors.staticPalette.aqua.aqua7,
      secondaryColor: theme.colors.staticPalette.aqua.aqua6,
      tertiaryColor: theme.colors.staticPalette.aqua.aqua1,
    },
    [TopLevelColours.Green]: {
      primaryColor: theme.colors.staticPalette.green.green7,
      secondaryColor: theme.colors.staticPalette.green.green6,
      tertiaryColor: theme.colors.staticPalette.green.green1,
    },
    [TopLevelColours.Orange]: {
      primaryColor: theme.colors.staticPalette.orange.orange5,
      secondaryColor: theme.colors.staticPalette.orange.orange4,
      tertiaryColor: theme.colors.staticPalette.orange.orange1,
    },
    [TopLevelColours.Purple]: {
      primaryColor: theme.colors.staticPalette.purple.purple7,
      secondaryColor: theme.colors.staticPalette.purple.purple6,
      tertiaryColor: theme.colors.staticPalette.purple.purple1,
    },
    [TopLevelColours.Pink]: {
      primaryColor: theme.colors.staticPalette.pink.pink7,
      secondaryColor: theme.colors.staticPalette.pink.pink6,
      tertiaryColor: theme.colors.staticPalette.pink.pink1,
    },
    [TopLevelColours.Yellow]: {
      primaryColor: theme.colors.staticPalette.yellow.yellow5,
      secondaryColor: theme.colors.staticPalette.yellow.yellow4,
      tertiaryColor: theme.colors.staticPalette.yellow.yellow1,
    },
  };
  const primaryColor = get(categoryColours, colourTheme).primaryColor;
  const secondaryColor = get(categoryColours, colourTheme).secondaryColor;
  const tertiaryColor = get(categoryColours, colourTheme).tertiaryColor;

  const wavePatternNumber = title
    ? title.length % NUMBER_OF_WAVE_PERMUTATIONS
    : DEFAULT_WAVE_PATTERN;

  const StyledWaves = styled(get(wavePatterns, wavePatternNumber)).attrs({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
  })``;

  return (
    <StyledWaves
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      data-testid="fallback-waves"
    />
  );
};

export default FallbackWaves;
