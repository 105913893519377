import queryString from 'query-string';

export enum UrlQueryParams {
  SEARCH_REDIRECT = 'isSearchRedirect',
  TOKEN = 'token',
  IRIS_TOKEN = 'irisToken',
}

export const encodeQueryParams = (
  params: Partial<Record<UrlQueryParams, string>>,
): string => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== ''),
  );

  return queryString.stringify(filteredParams);
};
