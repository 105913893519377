import { rem, rgba } from 'polished';
import React, { ReactNode, useEffect } from 'react';
import { AddToCalendar } from 'Shared/AddToCalendar';
import { isIOS } from 'Shared/MediaPlayerComponents/isIOS';
import { css, styled } from 'styles';
import { small, medium } from '../../utils';
import {
  BackButton as BaseBackButton,
  Status,
  TranscriptButton,
  JumpBackButton,
  JumpForwardButton,
  PlaybackControlButton,
} from '../MediaPlayerComponents';
import ErrorState from '../MediaPlayerComponents/ErrorState';
import { MediaLoadingIndicator } from '../MediaPlayerComponents/MediaLoadingIndicator';
import ProgressBar from '../MediaPlayerComponents/ProgressBar';
import useMediaPlayerControlVisibility from '../MediaPlayerComponents/useMediaPlayerControlVisibility';
import VolumeControl from '../MediaPlayerComponents/VolumeControl';
import HeadingText from '../Typography/HeadingText';

const SHOW_HIDE_DURATION = '300ms';

const Header = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  padding: ${rem(14)} ${rem(16)} ${rem(32)} ${rem(4)};
  transition: transform ${SHOW_HIDE_DURATION} ease-in-out;
  ${props =>
    props.show
      ? `transform: translateY(0rem);`
      : `transform: translateY(-100%);
  `}

  ${small(css`
    padding: ${rem(16)} ${rem(18)} ${rem(40)};
  `)}

  ${medium(css`
    padding: ${rem(40)} ${rem(32)} ${rem(80)};
  `)}
`;

const BackButton = styled(BaseBackButton)`
  flex-shrink: 0;
  margin-right: ${rem(4)};

  ${small(css`
    margin-right: ${rem(8)};
  `)}
`;

const VolumeControlContainer = styled(VolumeControl)`
  display: flex;
  flex: 1;
`;

const EmptyCell = styled.div`
  display: flex;
  flex: 1;
`;

const SettingsButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const PositionedTranscriptButton = styled(TranscriptButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ControlContainer = styled.div<{ show: boolean }>`
  /* Only allow controls to affect control visibility */
  pointer-events: none;
  padding: ${rem(16)} ${rem(24)};
  transition: transform ${SHOW_HIDE_DURATION} ease-in-out;

  ${props =>
    props.show
      ? `transform: translateY(0rem);`
      : `transform: translateY(100%);
  `}
  ${small(css`
    padding: ${rem(36)} ${rem(52)};
  `)}

  ${medium(css`
    padding: ${rem(52)} ${rem(52)};
  `)}
`;

const SubControlContainer = styled.div`
  > * {
    pointer-events: auto;
  }

  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: ${rem(52)};
  width: 100%;
`;

const PlaybackControlContainer = styled.div`
  margin-left: ${rem(16)};
  margin-right: ${rem(16)};
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding: 0 ${rem(16)};

  ${small(css`
    padding: 0 ${rem(36)};
  `)}

  ${medium(css`
    padding: 0 ${rem(52)};
  `)}

  > * + * {
    margin-left: ${rem(16)};
  }
`;

const CenterButtonContainer = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  color: theme.colors.staticPalette.white,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
  ],
  weight: theme.typography.fontWeights.medium,
}))``;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.staticPalette.black};
  position: relative;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > * {
    width: 100%;
    height: 100%;
  }
`;

const ClickableArea = styled.div<{ showMouse: boolean }>`
  flex-grow: 3;
  ${({ showMouse }) => (showMouse ? `` : `cursor: none;`)}
`;

const Overlay = styled.div<{ hasEnded: boolean; showControlsOverlay: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: opacity ${SHOW_HIDE_DURATION} ease-out;

  ${props =>
    props.showControlsOverlay
      ? `
    opacity: 1;
  `
      : `
    opacity: 0;
    cursor: none;
  `}

  ${({ hasEnded, theme }) => {
    const overlayColor = rgba(theme.colors.staticPalette.black, 0.9);

    return hasEnded
      ? `background: ${overlayColor};`
      : `background: linear-gradient(
      ${overlayColor} 0,
      transparent 30%,
      transparent 60%,
      ${overlayColor} 100%
    );`;
  }}
`;
export interface VideoPlayerUIProps {
  status: Status;
  backButtonLabel: string;
  currentTime: number;
  duration: number;
  volume: number;
  hasTranscript?: boolean;
  onAddToCalendarModalToggled?(open: boolean): void;
  onClickBackButton(event: React.MouseEvent<HTMLButtonElement>): void;
  onClickPause(): void;
  onClickPlay(): void;
  onClickReplay(): void;
  onClickJumpBack({ method }: { method: string }): void;
  onClickJumpForward({ method }: { method: string }): void;
  onClickTranscript?(): void;
  onClickVideo(): void;
  onSeek({ startPosition }: { startPosition: number }): void;
  onProgressChange(changeAmount: number): void;
  onVolumeChange(volume: number): void;
  onClickToggleMute(): void;
  renderNextStepButton?: ReactNode;
  renderVideo: ReactNode;
  title: string;
  summary?: string;
  isDragging: boolean;
  handleDrag({
    startDraggingValue,
  }: {
    startDraggingValue?: number | undefined;
  }): void;
  showCalendarIntegration?: boolean;
}

const VideoPlayerUI = ({
  status,
  backButtonLabel,
  currentTime = 0,
  duration,
  volume,
  hasTranscript = false,
  onClickBackButton,
  onClickPause,
  onClickPlay,
  onAddToCalendarModalToggled,
  onClickReplay,
  onClickJumpBack,
  onClickJumpForward,
  onClickTranscript,
  onClickVideo,
  onProgressChange,
  onVolumeChange,
  onClickToggleMute,
  onSeek,
  handleDrag,
  renderNextStepButton,
  renderVideo,
  title,
  summary,
  showCalendarIntegration = true,
}: VideoPlayerUIProps) => {
  const {
    isInterfaceVisible,
    handleMouseMove,
    onControlMouseOver,
    onControlMouseOut,
    onActionKeyPress,
  } = useMediaPlayerControlVisibility(status);

  useEffect(() => {
    const seekSlider = document.querySelector<HTMLElement>(
      '.unmind-media-progress-handle',
    );
    if (seekSlider) {
      seekSlider.focus();
    }
  }, []);

  const showJumpButtons = status !== 'ended';

  // Temporary fix for ENG-12714 as mute button doesn't work on iOS and is preventing publishing in MS Teams
  const displayMuteButton = !isIOS();

  return (
    <Container>
      <VideoWrapper>{renderVideo}</VideoWrapper>
      <Overlay
        data-testid="video-player-overlay"
        hasEnded={status === 'ended'}
        showControlsOverlay={isInterfaceVisible}
        onMouseMove={handleMouseMove}
        onKeyDown={onActionKeyPress}
      >
        <Header show={isInterfaceVisible} data-testid="video-player-header">
          <BackButton
            disabled={status === 'error'}
            label={backButtonLabel}
            onClick={onClickBackButton}
            onMouseOver={onControlMouseOver}
            onMouseOut={onControlMouseOut}
          />
          <Title>{title}</Title>
        </Header>
        {status !== 'ended' && (
          <ClickableArea
            data-testid="video-player-clickable-area"
            onClick={onClickVideo}
            showMouse={isInterfaceVisible}
          />
        )}
        {renderNextStepButton && status === 'ended' && (
          <ButtonContainer>{renderNextStepButton}</ButtonContainer>
        )}
        {status !== 'error' && (
          <ControlContainer
            show={isInterfaceVisible}
            onMouseOver={onControlMouseOver}
            onMouseOut={onControlMouseOut}
            data-testid="video-player-control-container"
          >
            <SubControlContainer data-testid="sub-control-container">
              {displayMuteButton ? (
                <VolumeControlContainer
                  data-testid="positioned-volume-control"
                  volume={volume}
                  onChange={onVolumeChange}
                  onClickToggleMute={onClickToggleMute}
                />
              ) : (
                <EmptyCell></EmptyCell>
              )}

              <CenterButtonContainer data-testid="center-button-container">
                {showJumpButtons && (
                  <JumpBackButton onClick={onClickJumpBack} />
                )}
                <PlaybackControlContainer data-testid="playback-control-container">
                  <PlaybackControlButton
                    status={status}
                    onClickPlay={onClickPlay}
                    onClickPause={onClickPause}
                    onClickReplay={onClickReplay}
                  />
                  {status === 'loading' && <MediaLoadingIndicator />}
                </PlaybackControlContainer>
                {showJumpButtons && (
                  <JumpForwardButton onClick={onClickJumpForward} />
                )}
              </CenterButtonContainer>
              <SettingsButtonsContainer>
                {showCalendarIntegration && (
                  <AddToCalendar
                    displayBorder={false}
                    isVideoPlayer={true}
                    description={summary}
                    contentPath={window.location.pathname}
                    title={title}
                    onAddToCalendarModalToggled={onAddToCalendarModalToggled}
                    isKeyDownListenerEnabled={true}
                  />
                )}
                {hasTranscript && (
                  <PositionedTranscriptButton onClick={onClickTranscript} />
                )}
              </SettingsButtonsContainer>
            </SubControlContainer>

            <ProgressBar
              currentTime={currentTime}
              duration={duration}
              onChange={onProgressChange}
              onSeek={onSeek}
              handleDrag={handleDrag}
            />
          </ControlContainer>
        )}
      </Overlay>
      {status === 'error' && (
        <ErrorState onClickCloseMedia={onClickBackButton} />
      )}
    </Container>
  );
};

export default VideoPlayerUI;
