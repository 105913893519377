import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves4 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M157.288 60.607C116.567 55.73 69.453 50.087 7.642 104.93A190.974 190.974 0 0 1 0 111.379V0h276.107a294.225 294.225 0 0 1-15.084 22.92c-33.504 46.098-65.853 42.224-103.735 37.687z"
      fill={primaryColor}
    />
    <path
      d="M340 99.454V192H131.939a276.085 276.085 0 0 1 16.136-20.324c27.93-31.995 48.275-29.903 72.1-27.453 25.61 2.633 55.241 5.68 102.635-32.757 6.524-5.291 12.233-9.248 17.19-12.012z"
      fill={secondaryColor}
    />
  </svg>
);

export default SvgWaves4;
