import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { Errors } from '../../Shared/Errors';
import { PlusOneFragment } from './plusOneFragment';
import { PlusOneUser } from './PlusOneUser';

export type WithPlusOneResponse = {
  user: {
    __typename: string;
    id: string;
    plusOne?: PlusOneUser;
    plusOneCTADismissed: {
      value: string | null;
    };
    createPlusOneVisited: {
      value: string | null;
    };
  };
};

export interface WithPlusOneProps {
  loading: boolean;
  id?: string;
  plusOne?: PlusOneUser;
  plusOneCTADismissed?: boolean;
  createPlusOneVisited?: boolean;
}

export const PLUS_ONE_QUERY = gql`
  query withPlusOne {
    user {
      id
      plusOne {
        ...PlusOneFragment
      }
      plusOneCTADismissed {
        value
      }
      createPlusOneVisited {
        value
      }
    }
  }
  ${PlusOneFragment}
`;

const withPlusOne = graphql<
  Record<string, unknown>,
  WithPlusOneResponse,
  Record<string, unknown>,
  WithPlusOneProps
>(PLUS_ONE_QUERY, {
  props: ({ data }) => {
    if (data && data.loading) {
      return {
        loading: true,
      };
    }

    if (data && data.user) {
      const createPlusOneVisited =
        get(data, 'user.createPlusOneVisited.value', null) === '1'
          ? true
          : false;
      const plusOneCTADismissed =
        get(data, 'user.plusOneCTADismissed.value', null) === '1'
          ? true
          : false;

      return {
        id: data.user.id,
        plusOne: data.user.plusOne
          ? {
              id: data.user.plusOne.id,
              firstName: data.user.plusOne.firstName,
              emailAddress: data.user.plusOne.emailAddress,
              status: data.user.plusOne.status,
              createdAt: data.user.plusOne.createdAt,
              // tslint:disable-next-line: strict-boolean-expressions
              joinedOn: data.user.plusOne.joinedOn
                ? data.user.plusOne.joinedOn
                : undefined,
            }
          : undefined,
        plusOneCTADismissed,
        createPlusOneVisited,
        loading: false,
      };
    }

    return {
      error: Errors.ServerError,
      loading: false,
    };
  },
});

export default withPlusOne;
