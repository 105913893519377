import React from 'react';
import { compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { AUTHENTICATED_QUERY } from '../Auth/authenticatedQuery';

export interface CheckAuthenticationProps {
  authenticated: boolean;
}

interface RedirectIfUnauthenticatedProps
  extends Pick<RouteComponentProps, 'location'> {
  authenticated: boolean;
  to: string;
}

const RedirectIfUnauthenticated: React.FC<RedirectIfUnauthenticatedProps> = ({
  authenticated: authenticatedInCache,
  location,
  to,
}) => {
  const isPathnameEqualToRedirectPath = () => location.pathname === to;

  if (authenticatedInCache || isPathnameEqualToRedirectPath()) {
    return null;
  }

  return <Redirect to={to} />;
};

export default compose<
  RedirectIfUnauthenticatedProps,
  Pick<RedirectIfUnauthenticatedProps, 'to'>
>(
  graphql<
    Record<string, unknown>,
    CheckAuthenticationProps,
    Record<string, unknown>,
    CheckAuthenticationProps
  >(AUTHENTICATED_QUERY, {
    props: ({ data }) => ({
      authenticated: Boolean(data && data.authenticated !== false),
    }),
  }),
  withRouter,
)(RedirectIfUnauthenticated);
