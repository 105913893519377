import React from 'react';
import { IconProps } from '../IconProps';
const SvgCalendarAdd = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill={primaryColor}
    {...props}
  >
    <path d="M9.667 6a3.667 3.667 0 110 7.333 3.667 3.667 0 010-7.333zm.166-6C11.03 0 12 .97 12 2.167v3.848a4.314 4.314 0 00-1-.473V3.667H1v6.166C1 10.478 1.522 11 2.167 11h3.375c.115.356.275.692.473 1H2.167A2.167 2.167 0 010 9.833V2.167C0 .97.97 0 2.167 0h7.666zm-.166 7.333l-.06.006a.334.334 0 00-.268.268l-.006.06v1.666H7.667l-.06.006a.334.334 0 00-.268.268l-.006.06.006.06a.334.334 0 00.268.268l.06.005h1.666v1.667l.006.06a.334.334 0 00.268.268l.06.005.06-.005a.334.334 0 00.268-.268l.005-.06V10h1.667l.06-.005a.334.334 0 00.268-.268l.005-.06-.005-.06a.334.334 0 00-.268-.268l-.06-.006H10V7.667l-.005-.06a.334.334 0 00-.268-.268l-.06-.006zM9.833 1H2.167C1.522 1 1 1.522 1 2.167v.5h10v-.5C11 1.522 10.478 1 9.833 1z" />
  </svg>
);
export default SvgCalendarAdd;
