import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { css, styled, ThemeInterface, useTheme } from 'styles';
import { ButtonThemeStyleProps } from 'styles/types/buttons';
import Loader from '../Loader';
import BodyText, { Props as TextProps } from '../Typography/BodyText';
import baseButtonStyles, { BaseButtonStyleProps } from './BaseStyles';
import { ButtonSize } from './ButtonSize';

export interface BaseButtonProps
  extends BaseButtonStyleProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  children?: never;
  label: string | ReactNode;
  ariaLabel?: string;
  loading?: boolean;
  textColor?: TextProps['color'];
  // TODO: split into a separate ButtonLink component
  to?: LinkProps['to'];
  styleProps?: ButtonThemeStyleProps;
}

export const buttonReset = css`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
`;

const linkReset = css`
  text-align: center;
  text-decoration: none;
`;

// This is needed to prevent styled-components passing these props
// through to the underlying DOM element and causing console warnings
const ButtonWithStrippedProps = ({
  loading,
  rounded,
  styleProps,
  ...rest
}: BaseButtonProps) => <button {...rest} />;
const LinkWithStrippedProps = ({
  styleProps,
  loading,
  rounded,
  ...rest
}: BaseButtonProps) => {
  if (rest.to !== undefined) {
    // @ts-ignore
    return <Link {...rest} to={rest.to} />;
  }
};

// TODO: split into a separate ButtonLink component
const Button = styled(ButtonWithStrippedProps).attrs(
  ({ to }: BaseButtonProps) => {
    if (to !== undefined) {
      return {
        as: LinkWithStrippedProps,
      };
    }
  },
)<BaseButtonProps>`
  ${buttonReset}
  ${linkReset}
  ${baseButtonStyles}

  /* position loader correctly */
  position: relative;

  ${({ loading }) => (Boolean(loading) ? 'pointer-events: none;' : undefined)};
`;

const ButtonText = styled(({ loading, ...rest }) => (
  <BodyText {...rest} align={'center'} />
))<{
  loading?: boolean;
}>`
  line-height: 1;
  opacity: ${props => (Boolean(props.loading) ? 0 : 1)};
  word-wrap: break-word;
`;

const LoadingState = styled(({ loading, ...rest }) => (
  <Loader {...rest} />
)).attrs(() => ({
  size: 4.8, // 12x12 px bubbles
}))<{ loading?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${props => (Boolean(props.loading) ? 1 : 0)};
`;

const getButtonTextSize = (size: string, theme: ThemeInterface) => {
  const { typography } = theme;

  switch (size) {
    case ButtonSize.small:
      return [typography.fontSizes.fontSize14];
    default:
      return [typography.fontSizes.fontSize16];
  }
};

const BaseButton = ({
  label,
  loading,
  size = ButtonSize.medium,
  ariaLabel,
  disabled,
  styleProps,
  ...props
}: BaseButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      {...props}
      aria-live={loading ? 'polite' : 'off'}
      aria-label={loading ? 'Loading' : ariaLabel}
      loading={loading}
      disabled={disabled}
      styleProps={styleProps}
      size={size}
    >
      <LoadingState color={styleProps?.textColor} loading={loading} />
      <ButtonText
        color={styleProps?.textColor}
        loading={loading}
        sizes={getButtonTextSize(size, theme)}
        weight={theme.typography.fontWeights.regular}
      >
        {label}
      </ButtonText>
    </Button>
  );
};

export default BaseButton;
