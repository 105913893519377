import { Colors } from '@unmind/design-system-theme';
import isUndefined from 'lodash/isUndefined';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { css, styled } from 'styles';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import Tooltip, { TooltipProps } from '../../../Shared/Tooltip';
import { HeadingText } from '../../../Shared/Typography';
import { small } from '../../../utils';
import ErrorMessage from '../ErrorMessage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  display: flex;
  padding: 0 0 ${rem(8)} 0;
`;

// istanbul ignore next
const PanelContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
  border-bottom: ${({ color }) => `solid ${rem(4)} ${color}`};
  border-radius: ${rem(4)};
  box-shadow: 0 0 ${rem(6)} ${rem(2)} rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(16)};
  min-height: ${rem(156)};
  flex: 1 1 auto;

  ${small(css`
    padding: ${rem(32)};
  `)}
`;

export type PanelProps = {
  title: string;
  color: Colors;
  children?: ReactNode;
  hasError?: boolean;
  isLoading?: boolean;
  tooltip?: TooltipProps;
};

const TooltipContainer = styled.div`
  display: inline-flex;
`;

const Panel = ({
  title,
  color,
  children,
  hasError = false,
  isLoading = false,
  tooltip,
}: PanelProps) => {
  const panelTitle = <Title>{title}</Title>;

  return (
    <Wrapper role="figure" aria-label={`${title} data of your organisation`}>
      <TitleContainer>
        {!!tooltip && !isUndefined(tooltip.labelCopy) ? (
          <TooltipContainer>
            {panelTitle}
            <Tooltip
              labelCopy={tooltip.labelCopy}
              placement={tooltip.placement}
              accessibilityId={tooltip.accessibilityId}
            >
              {tooltip.children}
            </Tooltip>
          </TooltipContainer>
        ) : (
          panelTitle
        )}
      </TitleContainer>
      <PanelContainer color={color}>
        {isLoading ? (
          <LoadingIndicator />
        ) : hasError ? (
          <ErrorMessage />
        ) : (
          children
        )}
      </PanelContainer>
    </Wrapper>
  );
};

export default Panel;
