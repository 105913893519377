import React from 'react';
import { Redirect } from 'react-router-dom';
import RoutePath from '../App/RoutePath';
import { DAILY_BOOST_CATEGORY_SLUG } from '../Shorts/constants';

export const redirectHelper = (
  newURL?: string,
  isDailyBoost?: boolean,
  toExplore?: boolean,
) =>
  toExplore ? (
    <Redirect to={`${RoutePath.Explore}`} />
  ) : isDailyBoost ? (
    <Redirect
      to={`${RoutePath.Shorts}/${DAILY_BOOST_CATEGORY_SLUG}/${
        newURL?.split('/')[2]
      }`}
    />
  ) : (
    <Redirect to={newURL ?? `${RoutePath.Explore}`} />
  );
