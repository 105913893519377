import React from 'react';
import { IconProps } from '../IconProps';

const SvgSadderFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M82.9 164.74c44.945 0 81.38-36.435 81.38-81.38 0-44.944-36.435-81.38-81.38-81.38-44.945 0-81.38 36.436-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#FFA76F"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.82 83.13a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.31 83.13a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M104.1 110.94c-6.26-4.61-13.98-7.26-21.77-7.34h-1.36c-7.31.07-14.55 2.41-20.6 6.51M43.67 71.55c4.76.06 9.54-1.27 13.57-3.8M119.95 71.55c-4.76.06-9.54-1.27-13.57-3.8"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSadderFaceWithBorder;
