import React, { useState } from 'react';
import { Button, BodyText } from '@unmind/design-system-components-web';
import { IconType, Thumb } from 'icons';
import { styled } from 'styles';
import DynamicIcon from 'Shared/DynamicIcon/DynamicIcon';
import { rem } from 'polished';
import { tracking } from 'App/Tracking';
import { Events } from 'App/Tracking/Events';
import { useMutation } from '@apollo/client';
import { LOG_EVENT_MUTATION } from 'App/Tracking/serverside/withLogEvent';
import { useTranslation } from 'react-i18next';
import { ThemeInterface } from '@unmind/design-system-theme';
import { logException } from 'App/logging';
import { EventName } from '__generated__/globalTypes';

export const LikeThumbIcon = styled(Thumb)``;

export const DislikeThumbIcon = styled(Thumb)`
  transform: rotate(180deg);
`;

const FeedbackButtonsWrapper = styled.div<{ showLabels: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${rem(180)};

  gap: ${({ showLabels }) => (showLabels ? rem(10) : rem(16))};
`;

const IconButtonLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${rem(4)};
`;

const IconButton = styled(Button)<{ active: boolean }>`
  padding: ${rem(9)} ${rem(16)};
  background-color: ${({ theme, active }) =>
    active
      ? theme.button.secondary.default.hover.backgroundColor
      : theme.button.secondary.default.backgroundColor};
`;

const Icon = styled(DynamicIcon).attrs(
  ({ $active, theme }: { theme: ThemeInterface; $active: boolean }) => ({
    width: 16,
    height: 16,
    primaryColor: $active ? 'none' : theme.colors.border.primary,
    secondaryColor: $active
      ? theme.colors.border.primary
      : theme.colors.background.secondary,
    tertiaryColor: $active
      ? theme.colors.background.primary
      : theme.colors.background.secondary,
  }),
)<{ $active: boolean }>``;

const IconButtonLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.medium,
}))`
  color: ${({ theme }) => theme.colors.text.primary};
`;

interface FeedbackButtonProps {
  active: boolean;
  buttonLabelText?: string;
  icon: IconType;
  onClick(): void;
  testId?: string;
}

export const FeedbackButton = ({
  active,
  buttonLabelText,
  icon,
  onClick,
  testId,
}: FeedbackButtonProps) => (
  <>
    {buttonLabelText ? (
      <IconButton
        onClick={() => {
          onClick();
        }}
        active={active}
        data-testid={testId}
        variant="secondary"
        label={
          <IconButtonLabelWrapper>
            <Icon $active={active} Icon={icon} />
            <IconButtonLabel>{buttonLabelText}</IconButtonLabel>
          </IconButtonLabelWrapper>
        }
      />
    ) : (
      <IconButtonLabelWrapper>
        <Icon
          onClick={() => {
            onClick();
          }}
          $active={active}
          data-testid={testId}
          Icon={icon}
        />
      </IconButtonLabelWrapper>
    )}
  </>
);

interface FeedbackButtonsProps {
  showLabels?: boolean;
  onPositiveClick?(activeState?: boolean): void;
  onNegativeClick?(activeState?: boolean): void;
  eventProperties: Record<string, unknown>;
  clientSideEventNames?: {
    positive: Events;
    negative: Events;
  };
  serverSideEventNames?: {
    positive: EventName;
    negative: EventName;
  };
}

const FeedbackButtons = ({
  showLabels = true,
  onPositiveClick,
  onNegativeClick,
  eventProperties,
  clientSideEventNames,
  serverSideEventNames,
}: FeedbackButtonsProps) => {
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const { t: translate } = useTranslation('shared');

  const [positiveActive, setPositiveActive] = useState(false);
  const [negativeActive, setNegativeActive] = useState(false);

  const trackClientside = (
    eventName: Events,
    properties: Record<string, unknown>,
  ) => {
    tracking.track(eventName, properties);
  };

  const trackServerside = async (
    eventName: EventName,
    properties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties: properties,
            brazeProperties: properties,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  return (
    <FeedbackButtonsWrapper showLabels={showLabels}>
      <FeedbackButton
        active={positiveActive}
        testId="positive-feedback-button"
        icon={LikeThumbIcon}
        buttonLabelText={
          showLabels
            ? translate('feedback_buttons.positive_button.label')
            : undefined
        }
        onClick={() => {
          if (onPositiveClick) {
            onPositiveClick(!positiveActive);
          }

          /** Toggle negative feedback to false if currently set at true and
           * are setting positive feedback to true.
           */
          if (!positiveActive && negativeActive) {
            setNegativeActive(!negativeActive);
          }
          setPositiveActive(!positiveActive);

          if (positiveActive) {
            return;
          }

          if (clientSideEventNames) {
            trackClientside(clientSideEventNames.positive, eventProperties);
          }
          if (serverSideEventNames) {
            void trackServerside(
              serverSideEventNames.positive,
              eventProperties,
            );
          }
        }}
      />
      <FeedbackButton
        active={negativeActive}
        testId="negative-feedback-button"
        icon={DislikeThumbIcon}
        buttonLabelText={
          showLabels
            ? translate('feedback_buttons.negative_button.label')
            : undefined
        }
        onClick={() => {
          if (onNegativeClick) {
            onNegativeClick(!negativeActive);
          }

          /** Toggle positive feedback to false if currently set at true and
           * are setting negative feedback to true.
           */
          if (!negativeActive && positiveActive) {
            setPositiveActive(!positiveActive);
          }
          setNegativeActive(!negativeActive);

          if (negativeActive) {
            return;
          }

          if (clientSideEventNames) {
            trackClientside(clientSideEventNames.negative, eventProperties);
          }
          if (serverSideEventNames) {
            void trackServerside(
              serverSideEventNames.negative,
              eventProperties,
            );
          }
        }}
      />
    </FeedbackButtonsWrapper>
  );
};

export default FeedbackButtons;
