import { styled, ThemeInterface, css } from 'styles';
import { rem } from 'polished';

import {
  HeadingText,
  medium,
  small,
} from '@unmind/design-system-components-web';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: 24px;
  border-radius: 20px;
  width: 100%;

  ${medium(css`
    width: auto;
  `)}
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`;

// remove when `talk-web-app-enable-outcome-measures-nov-23` flag is removed
export const DeprecatedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${rem(32)};
`;

export const Title = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 2,
    sizes: [theme.typography.fontSizes.fontSize20],
    weight: theme.typography.fontWeights.medium,
  }),
)`
  text-align: 'left';
  width: 100%;
`;

export const Subtitle = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 3,
    sizes: [theme.typography.fontSizes.fontSize16],
    weight: theme.typography.fontWeights.regular,
  }),
)`
  text-align: 'left';
  width: 100%;
  margin-top: ${rem(16)};
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap-reverse;
  gap: ${rem(16)};

  ${small(css`
    flex-wrap: nowrap;
    gap: ${rem(32)};
  `)}
`;
