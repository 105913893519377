import PrimaryButton from 'Shared/PrimaryButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SessionLink,
  SessionHeader,
  Session,
  CalendarIcon,
  SessionDescription,
} from './styles';

interface NextSessionLinkProps {
  activeDayTitle: string;
  activeDayLinkPath: string;
  activeDay: number;
}

export const NextSessionLink = ({
  activeDayTitle,
  activeDayLinkPath,
  activeDay,
}: NextSessionLinkProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <SessionLink>
      <SessionHeader>
        <Session>
          <CalendarIcon />
          {translate('course_day.session_number', {
            session_number: activeDay,
          })}
        </Session>
        <SessionDescription>{activeDayTitle}</SessionDescription>
      </SessionHeader>
      <PrimaryButton
        to={activeDayLinkPath}
        label={translate('course_day.cta_button.continue_variant.text')}
      />
    </SessionLink>
  );
};
