import { rem } from 'polished';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { styled, useTheme } from 'styles';

import { useAuth0 } from '@auth0/auth0-react';
import { OpenExternalLink } from 'icons';
import { tracking } from '../App/Tracking';
import {
  getSentAtTimestamp,
  ServersideTrackingEvent,
  updateInteractionSession,
} from '../App/Tracking/serverside';
import { ButtonSize } from '../Shared/Button';
import { Grid, GridItem } from '../Shared/Grid';
import SecondaryButton from '../Shared/SecondaryButton/SecondaryButton';
import { BodyText } from '../Shared/Typography';
import { getPrivacyPolicyLink } from '../utils';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import ChangePassword from './ChangePassword';

interface SecurityProps {
  trackServerside?(
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ): Promise<void>;
}

const StyledGrid = styled(Grid)`
  padding: 0;
`;

const SectionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${rem(20)} 0;
`;

const Divider = styled.hr`
  border: ${rem(1)} solid ${props => props.theme.colors.border.secondary};
`;

const Link = styled.a`
  text-decoration: none;
`;

const ExternalLinkContainer = styled.span`
  display: flex;
  align-items: center;
`;

const ExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
}))`
  margin-left: ${rem(4)};
  height: ${`${4 / 3}em`};
  width: ${`${4 / 3}em`};
`;

const Security = ({ trackServerside }: SecurityProps) => {
  const { typography } = useTheme();
  const subdomain = getSubdomainFromUrl();
  const [changingPassword, setChangingPassword] = useState(false);
  const { t: translate } = useTranslation('account');

  const toggleChangingPassword = () => {
    setChangingPassword(!changingPassword);
  };

  //for now all users authenticated via auth0 are SSO users, we will revist this after auth0 migration work
  const { isAuthenticated: isAuthenticatedViaAuth0 } = useAuth0();

  const changingPasswordLabelKey = !changingPassword
    ? 'security.change_password_button.label'
    : 'security.cancel_change_password_button.label';
  const a11yChangePasswordLabelKey = !changingPassword
    ? 'security.change_password_button.a11y_label'
    : 'security.cancel_change_password_button.a11y_label';

  return (
    <StyledGrid>
      {!isAuthenticatedViaAuth0 && (
        <>
          <GridItem fullWidth>
            <SectionHeader>
              <BodyText sizes={[typography.fontSizes.fontSize16]}>
                {translate('security.password_label')}
              </BodyText>
              <SecondaryButton
                onClick={() => {
                  toggleChangingPassword();
                  tracking.track('settings-password-change-clicked');
                }}
                ariaLabel={translate(changingPasswordLabelKey)}
                label={translate(a11yChangePasswordLabelKey)}
                size={ButtonSize.small}
              />
            </SectionHeader>
          </GridItem>
          <ChangePassword
            toggleChangingPassword={toggleChangingPassword}
            changingPassword={changingPassword}
          />
          <GridItem>
            <Divider />
          </GridItem>
        </>
      )}
      <GridItem fullWidth>
        <SectionHeader>
          <BodyText sizes={[typography.fontSizes.fontSize16]}>
            {translate('security.privacy_policy_button.label')}
          </BodyText>
          <Link
            href={getPrivacyPolicyLink(subdomain)}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => {
              if (trackServerside) {
                updateInteractionSession();
                void trackServerside('PRIVACY_POLICY_VIEWED', {
                  clientSentAtUtcTimestamp: getSentAtTimestamp(),
                });
              }
              tracking.track('settings-privacy-policy-view-clicked');
            }}
          >
            <SecondaryButton
              ariaLabel={translate('security.privacy_policy_button.a11y_label')}
              label={
                <ExternalLinkContainer>
                  {translate('security.privacy_policy_button.label')}
                  <ExternalLinkIcon />
                </ExternalLinkContainer>
              }
              size={ButtonSize.small}
            />
          </Link>
        </SectionHeader>
      </GridItem>
      <GridItem>
        <Divider />
      </GridItem>
    </StyledGrid>
  );
};

export default withTranslation('account')(Security);
