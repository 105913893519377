import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import { withAuthenticationViaGraphQL } from '../Auth';
import RoutePath from '../RoutePath';
import { RouteConfig } from '../routes';
import RedirectIfUnauthenticated from './RedirectIfUnauthenticated';
import RedirectIfAdditionalDetailsNeeded from './RedirectIfAdditionalDetailsNeeded';
import RouteWithPermissions from './RouteWithPermissions';
import RouteWithSubRoute from './RouteWithSubRoute';
import { storeRedirectTokensAndCleanUrl } from './storeRedirectTokensAndCleanUrl';

interface RouteWithAuthenticationProps extends RouteConfig, React.Attributes {
  authenticating: boolean;

  renderIfAuthenticating: React.ComponentType<RouteConfig>;
  renderIfUnauthorized: React.ComponentType<RouteConfig>;
  computedMatch: {
    url: string;
  };
}

export const RouteWithAuthentication: React.FC<RouteWithAuthenticationProps> =
  ({
    authenticating,
    renderIfAuthenticating: Authenticating,
    renderIfUnauthorized,
    ...props
  }) => {
    const location = useLocation();
    // The Auth0 login is initialised with a redirect URL - i.e. the location
    // Auth0 will return to post-login attempt, currently regardless of success.
    // Error params need to be retained in this case to be used after redirection.
    const error = new URLSearchParams(location.search).get('error');

    storeRedirectTokensAndCleanUrl();

    const getRedirect = () => {
      const search = props.location?.search || '';
      const hash = props.location?.hash || '';
      const redirectPath = `${props.computedMatch.url}${search}${hash}`;

      return encodeURIComponent(redirectPath);
    };

    const redirectPath = [
      RoutePath.Login,
      error ? location.search : '?',
      `redirect=${getRedirect()}`,
    ].join('');

    if (authenticating) {
      return <Authenticating {...props} />;
    }

    if (props.permissions && props.permissions.length > 0) {
      return (
        <Fragment>
          <RedirectIfUnauthenticated to={redirectPath} />
          <RedirectIfAdditionalDetailsNeeded />
          <RouteWithPermissions
            renderIfUnauthorized={renderIfUnauthorized}
            {...props}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <RedirectIfUnauthenticated to={redirectPath} />
        <RouteWithSubRoute {...props} />
      </Fragment>
    );
  };

export default withAuthenticationViaGraphQL(RouteWithAuthentication);
