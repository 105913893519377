import { hideVisually, rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { MAIN_CONTENT_ID } from '../../Navigation/AuthenticatedPage';
import { small } from '../../utils';
import { LARGE_HEIGHT, SMALL_HEIGHT } from '../../utils/headerHeight';

const SkipToMainContentLink = styled.a`
  ${hideVisually()};
  text-decoration: underline;
  background-color: ${({ theme }) => theme.colors.text.link};
  color: ${({ theme }) => theme.colors.staticPalette.white};
  padding: ${rem(6)};
  position: absolute;
  left: 0;
  top: ${rem(SMALL_HEIGHT)};

  ${small(css`
    top: ${rem(LARGE_HEIGHT)};
  `)}

  &:focus {
    clip: auto;
    clip-path: none;
    height: auto;
    width: auto;
  }
`;

const SkipToMainContent = () => {
  const { t: translate } = useTranslation('shared');

  return (
    <SkipToMainContentLink href={`#${MAIN_CONTENT_ID}`}>
      {translate('accessibility.skip_content_text')}
    </SkipToMainContentLink>
  );
};

export default SkipToMainContent;
