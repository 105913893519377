import * as Yup from 'yup';

export default {
  dateMonth: Yup.string()
    .required('Date of birth must include a month')
    .matches(
      /^([1-9]|0[1-9]|1[012])$/,
      'Date of birth must be a real date (eg. 12 15 1988)',
    )
    .test(
      'dateMonth',
      'Date of birth must be a real date  (eg. 12 15 1988)',
      (value: any) => value >= 1 && value <= 12,
    )
    .min(1, 'Date of birth must include a month')
    .max(2, 'Date of birth must be a real date  (eg. 12 15 1988)'),
  dateYear: Yup.string()
    .required('Date of birth must include a year')
    .test(
      'dateYear',
      'Date of birth must be a real date  (eg. 12 15 1988)',
      (value: any) => value >= 1900,
    )
    .matches(/^[0-9]+$/, 'Date of birth must be a real date  (eg. 12 15 1988)')
    .min(4, 'Date of birth must include a year')
    .max(4, 'Date of birth must be a real date  (eg. 12 15 1988)')
    .test(
      'dateYear',
      'Your date of birth is in the future. Please check it and try again. For further help visit our support page.',
      (value: any) => {
        const today = new Date();
        const presentYear = today.getFullYear();

        return value <= presentYear;
      },
    ),
  dateDay: Yup.string()
    .required('Date of birth must include a day')
    .test(
      'dateDay',
      'Date of birth must be a real date  (eg. 12 15 1988)',
      (day: any) => day >= 1 && day <= 31,
    )
    .matches(/^[0-9]+$/, 'Date of birth must be a real date  (eg. 12 15 1988)')
    .min(1, 'Date of birth must include a day')
    .max(2, 'Date of birth must be a real date  (eg. 12 15 1988)'),
};
