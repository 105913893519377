import gql from 'graphql-tag';
import {
  graphql,
  withApollo,
  WithApolloClient,
} from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { AUTHENTICATED_QUERY } from './authenticatedQuery';
import { withAuthenticationViaGraphQL as withAuthenticationViaGraphQLQueryResponse } from './__generated__/withAuthenticationViaGraphQL';

export interface WithAuthenticationViaGraphQLOutputProps {
  authenticated: boolean;
  authenticating: boolean;
  currentUserPermissions: string[];
}

const query = gql`
  query withAuthenticationViaGraphQL {
    user {
      id
      permissions {
        value
      }
    }
  }
`;

type Input = WithApolloClient<WithAuthenticationViaGraphQLOutputProps>;
type Response = withAuthenticationViaGraphQLQueryResponse;
type Variables = Record<string, unknown>;
type Props = WithAuthenticationViaGraphQLOutputProps;

// TODO: Properly type the props of the component produced by the HOC.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAuthenticationViaGraphQL = compose<any, any>(
  withApollo,
  graphql<Input, Response, Variables, Props>(query, {
    options: {
      fetchPolicy: 'no-cache',
    },
    props: ({ data, ownProps: { client } }) => {
      const authenticated = Boolean(
        data !== undefined && !data.loading && !data.error && data.user,
      );

      const setAuthenticated = () => {
        if (client) {
          client.writeQuery({
            query: AUTHENTICATED_QUERY,
            data: {
              authenticated: true,
            },
          });
        }
      };

      if (authenticated) {
        setAuthenticated();
      }

      return {
        authenticated,
        authenticating: Boolean(data && data.loading),
        currentUserPermissions: data?.user?.permissions?.value
          ? [data.user.permissions.value.toLowerCase()]
          : [],
      };
    },
  }),
);
