import React from 'react';
import { IconProps } from '../IconProps';
const SvgHelp = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#Help_svg__clip0_206_1019)">
      <g clipPath="url(#Help_svg__clip1_206_1019)">
        <path
          d="M10 19.286A9.286 9.286 0 1010 .715a9.286 9.286 0 000 18.57z"
          stroke="#0D0300"
          strokeOpacity={0.95}
          strokeWidth={1.25}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.857 7.857A2.143 2.143 0 1110 10v1.429"
          stroke="#0D0300"
          strokeOpacity={0.95}
          strokeWidth={1.25}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.571a1.072 1.072 0 101.071 1.072A1.086 1.086 0 0010 13.57z"
          fill={primaryColor}
          fillOpacity={0.95}
        />
      </g>
    </g>
    <defs>
      <clipPath id="Help_svg__clip0_206_1019">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
      <clipPath id="Help_svg__clip1_206_1019">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHelp;
