import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';
import {
  SingleSeries,
  SingleSeriesVariables,
} from './__generated__/SingleSeries';

export const SINGLE_SERIES_QUERY: DocumentNode = gql`
  query UseSingleSeries($slug: String!) {
    singleSeries(slug: $slug) {
      id
      title
      subtitle
      slug
      questions {
        id
        questionText
        scale {
          id
          minimumValue
          maximumValue
        }
      }
      impactQuestionnaire {
        id
        version
        questions {
          ... on FixedResponseQuestion {
            id
            text
            responseOptions {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export default function useSingleSeries(variables: SingleSeriesVariables) {
  return useQuery<SingleSeries>(SINGLE_SERIES_QUERY, {
    variables,
    fetchPolicy: 'cache-first',
  });
}

/**
 * WARNING: do not use this, unless absolutely necessary (i.e. cannot convert class component => fn component)
 * instead, use the `useSingleSeries` hook directly.
 */
export function withSingleSeries<P extends SingleSeriesVariables>(
  Component: React.ComponentType<P>,
) {
  return function SingleSeriesWrapper(props: P) {
    const { data, loading } = useSingleSeries({ slug: props.slug });

    return (
      <Component
        {...props}
        series={data?.singleSeries}
        singleSeriesLoading={loading}
      />
    );
  };
}
