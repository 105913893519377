import React from 'react';
import { IconProps } from '../IconProps';

const SvgHamburger = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 14" fill="none" {...props}>
    <path
      d="M1 1h18M1 7h18M1 13h18"
      stroke={primaryColor}
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHamburger;
