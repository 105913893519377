import { useTranslation } from 'react-i18next';
import { Namespace, ParseKeys } from 'i18next';

export const TalkTranslate = (
  keyValue: string,
  translationValue: string,
  defaultValue?: string,
) => {
  const key = `${keyValue}.${translationValue}`;
  const { t } = useTranslation<Namespace<'talk'>>('talk');

  if (defaultValue) {
    return t(key as ParseKeys<Namespace<'talk'>>, defaultValue);
  } else {
    return t(key as ParseKeys<Namespace<'talk'>>);
  }
};
