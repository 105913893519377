import React from 'react';

const LowerPurpleWave = ({ className }: { className?: string }) => (
  <svg
    className={className}
    preserveAspectRatio="xMinYMin meet"
    fill="none"
    height="160"
    viewBox="0 0 320 160"
    width="320"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m234.731 160h-234.731v-119.5242c24.1638 2.5816 36.0047 15.3366 49.7332 30.125 19.9456 21.4853 43.8756 47.2622 115.3688 52.3682 29.72 2.123 52.369 16.134 69.629 37.031z"
      fill="#5b3f8c"
      fillRule="evenodd"
    />
  </svg>
);

export default LowerPurpleWave;
