import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  BodyText,
  HeadingText,
  small,
} from '@unmind/design-system-components-web';
import { rem } from 'polished';
import { HeaderContainer } from './styles';
import HelpCountryButton from './HelpCountryButton';

const HelpBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: ${rem(24)};

  ${small(css`
    align-items: flex-start;
    flex-direction: row;
  `)};
`;

const HelpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
  align-items: center;

  ${small(css`
    align-items: flex-start;
  `)};
`;

const HelpTitle = styled(HeadingText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize32}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  text-align: center;

  ${small(css`
    text-align: left;
  `)};
`;

const HelpSubheading = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;

  ${small(css`
    text-align: left;
  `)};
`;

export type HelpHeaderProps = {
  countryCode: string;
  eapDisplayName?: string | null;
  onClickCountryPicker(): void;
};

const HelpHeader = ({
  countryCode,
  eapDisplayName,
  onClickCountryPicker,
}: HelpHeaderProps) => {
  const { t: translate } = useTranslation('help');

  return (
    <HeaderContainer>
      <HelpBanner>
        <HelpTextContainer>
          <HelpTitle level={1}>{translate('help_header.title')}</HelpTitle>
          {eapDisplayName && (
            <HelpSubheading>
              {translate('help_header.description', {
                eapDisplayName,
              })}
            </HelpSubheading>
          )}
        </HelpTextContainer>
        <HelpCountryButton
          countryCode={countryCode}
          onClick={onClickCountryPicker}
        />
      </HelpBanner>
    </HeaderContainer>
  );
};

export default HelpHeader;
