import { rem } from 'polished';
import React, { ReactElement } from 'react';
import { styled } from 'styles';
import { Error } from 'icons';
import BodyText from '../../../Shared/Typography/BodyText';

const ErrorContainer = styled.div`
  height: 100%;
`;

const IconContainer = styled.div`
  text-align: center;
`;

const ErrorIcon = styled(Error).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.error,
  height: 20,
  width: 20,
}))``;

const ErrorHeading = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: center;
  margin-top: ${rem(16)};
`;

const ErrorText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: center;
  margin-top: ${rem(4)};
`;

const ErrorMessage = ({
  icon = <ErrorIcon />,
  headline = 'Information could not be retrieved',
  description = 'Please try again later',
}: {
  icon?: ReactElement;
  headline?: string;
  description?: string;
}) => (
  <ErrorContainer>
    <IconContainer>{icon}</IconContainer>
    <ErrorHeading>{headline}</ErrorHeading>
    <ErrorText as="p">{description}</ErrorText>
  </ErrorContainer>
);

export default ErrorMessage;
