import { HeadingText } from 'Shared/Typography';
import { rem } from 'polished';
import styled from 'styled-components';

export const Subheader = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  lineHeight: theme.typography.lineHeights.lineHeight16,
  color: theme.colors.text.primary,
  level: 3,
}))`
  margin-top: ${rem(24)};
`;

export const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${rem(16)};
  gap: ${rem(8)};
`;
