import { Colors } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { css } from 'styles';

const getFocusState = (backgroundColor?: Colors | string) => css`
  outline: none;
  box-shadow: ${({ theme }) => `
      0 0 0 ${rem(2)} ${
    Boolean(backgroundColor) ? backgroundColor : theme.colors.background.primary
  },
      0 0 0 ${rem(4)} ${theme.button.borderFocusColor}
    `};
`;

export default getFocusState;
