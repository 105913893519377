import { Breakpoints } from '@unmind/design-system-theme';
import debounce from 'lodash/debounce';
import { rem } from 'polished';
import React from 'react';
import { css, styled } from 'styles';
import { Info } from 'icons';
import { tracking } from '../../App/Tracking';
import { SecondaryButton } from '../../Shared/SecondaryButton';
import { TooltipPlacement } from '../../Shared/Tooltip/Tooltip';
import { HeadingText, BodyText } from '../../Shared/Typography';
import { small, medium } from '../../utils';
import { PlusOneConfirmationModalType } from './PlusOneConfirmationModal';
import PlusOneStatusAccepted from './PlusOneStatusAccepted';
import PlusOneStatusInvited from './PlusOneStatusInvited';

const Container = styled.div`
  text-align: center;

  ${small(css`
    text-align: left;
  `)}
`;

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${rem(8)};

  ${small(css`
    justify-content: flex-start;
  `)}
`;

export const Subtitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.medium,
}))``;

export const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
}))`
  display: flex;
  width: ${rem(25)};
  height: ${rem(25)};
  margin-left: ${rem(5)};
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;

  ${medium(css`
    display: none;
  `)}
`;

export const TooltipLabel = styled.div`
  max-width: ${rem(270)};
`;

export const BaseText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  white-space: normal;

  ${small(css`
    display: inline-block;
    text-align: left;
    white-space: nowrap;
    margin-bottom: ${rem(12)};
  `)}
`;

export const InviteeDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: ${rem(36)};
  padding-bottom: ${rem(36)};

  ${small(css`
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: ${rem(52)};
    padding-bottom: ${rem(52)};
  `)}

  ${medium(css`
    border-bottom: ${({ theme }) =>
      `${rem(1)} solid ${theme.colors.border.secondary}`};
    flex-wrap: wrap;
    padding-bottom: ${rem(24)};
  `)}
`;

export const InviteeDetailsInner = styled.div`
  flex: 1 0 0;

  ${small(css`
    margin-right: ${rem(32)};
  `)}
`;

export const InviteName = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  display: inline-block;
`;

export const InviteDate = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  display: inline-block;
  margin-bottom: ${rem(4)};
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
`;

export const ActionButton = styled(SecondaryButton)`
  flex: 1 0 auto;
  margin-top: ${rem(36)};

  ${small(css`
    margin-top: 0;
  `)}
`;

export const tooltipPosition = (
  positionOnMobile: TooltipPlacement = 'bottom',
  positionOnTabletUp: TooltipPlacement = 'right',
) =>
  window.innerWidth < Breakpoints.XS ? positionOnMobile : positionOnTabletUp;

export const trackTooltip = debounce(() => {
  tracking.track('settings-plus-one-invite-tooltip-clicked');
}, 1000);

export enum PlusOneInvitationStatus {
  INVITED = 1,
  ACCEPTED = 2,
  REVOKED = 3,
  DELETED = 4,
  EXISTING_USER = 5,
  DUPLICATED_INVITE = 6,
}

const renderPlusOneStatus = ({
  plusOne,
  plusOneConfirmationModalCallback,
}: Props) => {
  switch (plusOne.status) {
    case PlusOneInvitationStatus.INVITED:
    case PlusOneInvitationStatus.EXISTING_USER:
    case PlusOneInvitationStatus.DUPLICATED_INVITE:
      return (
        <PlusOneStatusInvited
          plusOne={plusOne}
          plusOneConfirmationModalCallback={plusOneConfirmationModalCallback}
        />
      );
    case PlusOneInvitationStatus.ACCEPTED:
      return (
        <PlusOneStatusAccepted
          plusOne={plusOne}
          plusOneConfirmationModalCallback={plusOneConfirmationModalCallback}
        />
      );
    default:
      return <></>;
  }
};

export type PlusOne = {
  id: string;
  createdAt: string;
  emailAddress: string;
  firstName: string;
  status: number;
  joinedOn?: string;
};

interface Props {
  plusOne: PlusOne;
  plusOneConfirmationModalCallback(
    confType: PlusOneConfirmationModalType,
  ): void;
}

const PlusOneStatus = (props: Props) => (
  <Container aria-live="polite">{renderPlusOneStatus(props)}</Container>
);

export default PlusOneStatus;
