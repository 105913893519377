import gql from 'graphql-tag';

export const SEARCH_QUERY = gql`
  query exploreSearch($input: ExploreSearchInput!) {
    exploreSearch(input: $input) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        rank
        node {
          ... on HelpSearchText {
            __typename
            id
            title
            text
          }
          ... on Tool {
            __typename
            title
            id
            slug
            summary
            cloudinaryThumbnail {
              path
            }
            estimatedMins
            mediaType {
              value
            }
            exploreCategory {
              id
              colourTheme
              topLevelCategory {
                id
                title
              }
            }
          }
          ... on Series {
            __typename
            totalDays
            title
            id
            slug
            subtitle
            cloudinaryThumbnail {
              path
            }
            exploreCategory {
              id
              colourTheme
            }
          }
        }
      }
    }
    getAssetToken(input: { isMobile: true }) {
      assetToken
    }
  }
`;
