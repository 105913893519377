import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const RadioInput = styled.input.attrs(() => ({
  type: 'radio',
}))`
  /* reset user agent stylesheets */
  appearance: none;
  background-color: transparent;
  margin: 0;

  width: ${em(24)};
  height: ${em(24)};
  flex: 0 0 ${em(24)};
  border: ${em(1)} solid ${props => props.theme.colors.border.secondary};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${em(8)};
  color: ${props => props.theme.colors.text.primary};

  &::before {
    content: '';
    width: ${em(18)};
    height: ${em(18)};
    border-radius: 50%;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    background-color: ${props => props.theme.colors.primary};
  }
  &:checked {
    border: ${em(1)} solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.background.primary};
  }
  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
  }
`;

interface Props {
  label: string;
  handleChange(): void;
  isSelected: boolean;
}

const RadioOption = ({ label, handleChange, isSelected }: Props) => (
  <Label>
    <RadioInput onChange={handleChange} checked={isSelected} />
    {label}
  </Label>
);

export default RadioOption;
