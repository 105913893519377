import { Location, LocationDescriptorObject } from 'history';
import { BreadcrumbState } from 'Explore/BreadcrumbNavigation/setBreadcrumbState';
import { DAILY_BOOST_CATEGORY_SLUG } from '../../Shorts/constants';
import RoutePath from '../RoutePath';

export const getToolCategoryUrl = (categorySlug: string) =>
  `${RoutePath.Tools}/${categorySlug}`;

export const getToolPlayerUrl = (categorySlug: string, slug: string) =>
  `${getToolCategoryUrl(categorySlug)}/${slug}`;

interface ToolPlayerLocationState {
  onToolPlayerClose: OnCloseInfo;
}

export interface OnCloseInfo {
  location: LocationDescriptorObject;
  ariaLabel: string;
}

interface CreateToolPlayerLocation {
  categorySlug: string;
  slug: string;
  onCloseInfo?: OnCloseInfo;
}

interface CreateToolPlayerLocationNewTax {
  isDailyBoost?: boolean;
  slug: string;
  onCloseInfo?: OnCloseInfo;
}

export function createToolPlayerLocation({
  categorySlug,
  slug,
  onCloseInfo,
}: CreateToolPlayerLocation): LocationDescriptorObject<ToolPlayerLocationState> {
  const pathname = getToolPlayerUrl(categorySlug, slug);
  const location: LocationDescriptorObject = { pathname };

  if (onCloseInfo) {
    location.state = { onToolPlayerClose: onCloseInfo };
  }

  return location;
}

export function createToolPlayerLocationNewTax({
  isDailyBoost = false,
  slug,
}: CreateToolPlayerLocationNewTax): LocationDescriptorObject<ToolPlayerLocationState> {
  const pathname = isDailyBoost
    ? `${RoutePath.Shorts}/${DAILY_BOOST_CATEGORY_SLUG}/${slug}`
    : `${RoutePath.Shorts}/${slug}`;

  const location: LocationDescriptorObject = { pathname };

  return location;
}

export function getToolPlayerOnCloseInfo({
  state,
}: Location): OnCloseInfo | undefined {
  if (state && state.onToolPlayerClose) {
    return state.onToolPlayerClose;
  }
}

export function getBreadcrumbStateInfo({
  state,
}: Location): BreadcrumbState | undefined {
  if (state && state.breadcrumbHistory) {
    return {
      breadcrumbHistory: state.breadcrumbHistory,
      currentTitle: state.currentTitle ?? null,
    };
  }
}
