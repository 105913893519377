import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled } from 'styles';
import { CourseComputer } from 'icons';
import HeroBannerCourse from 'Explore/HeroBanner/HeroBannerCourse/HeroBannerCourse';
import { Series } from 'Explore/types';
import { rem } from 'polished';
import { tracking } from 'App/Tracking';
import RoutePath from 'App/RoutePath';
import ArrowLink from 'Shared/ArrowLink/ArrowLink';
import { useLocation } from 'react-router-dom';
import SectionHeading from '../SectionHeading';
import { HomeScreenSection } from '../../PersonalisedToday/PersonalisedTodayScreen';
import { getTodayFeaturedHero_homeAssembly_featuredHeroCourses } from './__generated__/getTodayFeaturedHero';
import { FeaturedContentWrapper } from './FeaturedContent';

const CourseComputerIcon = styled(CourseComputer).attrs(({ theme }) => ({
  primaryColor: theme.colors.border.primary,
  secondaryColor: theme.colors.brand.primary,
  tertiaryColor: theme.colors.background.input,
  height: 32,
  width: 32,
  'aria-hidden': true,
}))``;

const ArrowLinkWrapper = styled.div`
  margin-top: ${rem(16)};
`;

type FeaturedHeroProps = {
  course: getTodayFeaturedHero_homeAssembly_featuredHeroCourses;
  assetToken: string | null | undefined;
};

const onNavigateExploreCategory = (categorySlug?: string) => {
  tracking.track('homepage-line-manager-content-view-clicked', {
    categorySlug,
    source: 'today-managers-banner',
  });
};

const FeaturedHero = ({ course, assetToken }: FeaturedHeroProps) => {
  const { t: translate } = useTranslation<Namespace<'homepage'>>('homepage');

  const location = useLocation();
  const categorySlug = course?.exploreCategory?.slug;
  const managersPath = `${RoutePath.Explore}/${categorySlug}`;

  return (
    <HomeScreenSection>
      <SectionHeading
        title={translate(
          'managers_today.featured_banner.your_courses_section.title',
        )}
        subtitle={translate(
          'managers_today.featured_banner.your_courses_section.subtitle',
        )}
        iconComponent={<CourseComputerIcon />}
      />
      <ArrowLinkWrapper>
        <ArrowLink
          text={translate(
            'managers_today.featured_banner.your_courses_section.arrow_link_button.text',
          )}
          onClick={() => onNavigateExploreCategory(categorySlug)}
          to={{
            pathname: managersPath,
            state: { from: location.pathname },
          }}
        />
      </ArrowLinkWrapper>

      <FeaturedContentWrapper>
        <HeroBannerCourse
          series={course as Series}
          assetToken={assetToken}
          source="today-manager-hero-banner"
        />
      </FeaturedContentWrapper>
    </HomeScreenSection>
  );
};

export default FeaturedHero;
