import { IconProps, IconType, Volume, VolumeMuted } from 'icons';
import { hideVisually, rem, rgba, transparentize } from 'polished';
import { default as Slider } from 'rc-slider';
import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle, css, styled } from 'styles';
import { small } from '../../utils';
import { ScreenReaderContent } from '../Accessibility';
import { buttonReset } from '../Button';
import MediaButtonTooltip from './MediaButtonTooltip';

export interface VolumeControlProps {
  volume: number;
  onChange(volume: number): void;
  onClickToggleMute(): void;
  isAudioPlayer?: boolean;
}
export const PREFIX_CLASS = 'unmind-media-volume';

const baseHandleStyle = css`
  width: ${rem(12)};
  height: ${rem(12)};
  margin-top: ${rem(-4)};
  box-shadow: 1px 0px 3px 0px rgba(75, 75, 75, 0.4);
  border: none;
`;
const hoveredHandleStyle = css`
  width: ${rem(16)};
  height: ${rem(16)};
  margin-top: ${rem(-6)};
`;

const Container = styled.div`
  pointer-events: auto;
  width: 20%;
`;

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SliderBarContainer = styled.div`
  width: 50%;

  @media (max-width: ${props => rem(props.theme.layout.breakpoints.S)}) {
    ${hideVisually()}
  }
`;

const Button = styled.button<{
  isRounded?: boolean;
  isAudioPlayer?: boolean;
}>`
  ${buttonReset}
  border: ${rem(2)} solid transparent;
  border-radius: ${({ theme }) => rem(theme.layout.borderRadii.radius4)};
  cursor: pointer;
  outline: none;
  width: ${rem(32)};
  height: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${rem(8)};
  margin-left: ${rem(-5)};
  position: relative;

  color: ${props =>
    props.isAudioPlayer
      ? props.theme.colors.mediaPlayer.audio.icon.default
      : transparentize(0.2, props.theme.colors.staticPalette.white)};

  &:hover {
    color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.icon.hover
        : props.theme.colors.staticPalette.white};
  }

  &:focus {
    border-color: ${props =>
      props.isAudioPlayer
        ? props.theme.colors.mediaPlayer.audio.borderFocus
        : props.theme.button.borderFocusColor};
    border-radius: ${({ isRounded }) => (isRounded ? rem(52) : rem(4))};
  }
`;

const VolumeControlGlobalStyle = createGlobalStyle`
  .${PREFIX_CLASS} {
    color: ${({ theme }) => theme.colors.staticPalette.white};
    position: relative;
    height: ${rem(14)};
    padding: ${rem(5)} 0;
    touch-action: none;
    box-sizing: border-box;
    cursor: pointer;

    &-rail,
    &-track {
      position: absolute;
      height: ${rem(4)};
      border-radius: ${rem(6)};
      background-color: currentColor;
    }

    &-rail {
      width: 100%;
      opacity: 0.5;
    }

    &-track {
      left: 0;
    }

    &-handle {
      ${baseHandleStyle};
      position: absolute;
      cursor: grab;
      border-radius: 50%;
      background-color: currentColor;
      touch-action: pan-x;

      &:focus {
        outline: none;
        width: ${rem(18)};
        height: ${rem(18)};
        margin-top: ${rem(-7)};
        border: ${({ theme }) =>
          `${rem(3)} solid ${rgba(theme.colors.border.primary, 0.0)}`};
        box-shadow: 0 0 0 ${rem(1)} currentColor;
        background-clip: padding-box;
      }

      &-click-focused:focus {
        ${baseHandleStyle};

        &:hover {
            ${hoveredHandleStyle}
        }
      }

      &:hover, &:active {
        &:not(:focus) {
          ${hoveredHandleStyle}
        }
      }

      &:active {
        cursor: grabbing;
        ${hoveredHandleStyle}
      }
    }

  }
`;

const AudioVolumeControlGlobalStyle = createGlobalStyle`
  .${PREFIX_CLASS} {
    color: ${({ theme }) => theme.colors.mediaPlayer.audio.icon.default};

    &-handle {
      &:focus {
        border: ${({ theme }) =>
          `${rem(3)} solid ${rgba(
            theme.colors.mediaPlayer.audio.borderFocus,
            0.0,
          )}`};
        box-shadow: 0 0 0 ${rem(1)} ${({ theme }) =>
  theme.colors.mediaPlayer.audio.borderFocus};
      }
    }

  }
`;

interface DynamicIconProps extends IconProps {
  Icon: IconType;
}

const DynamicIcon = ({ Icon, ...props }: DynamicIconProps) => (
  <Icon {...props} />
);

const ButtonIcon = styled(DynamicIcon).attrs({
  primaryColor: 'currentColor',
  width: undefined,
  height: 20,
})`
  overflow: visible;
  ${small(css`
    height: ${rem(26)};
  `)}
`;

const VolumeControl = ({
  volume,
  onChange,
  onClickToggleMute,
  isAudioPlayer,
}: VolumeControlProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const { t: translate } = useTranslation('media_player');

  return (
    <Container data-testid="container">
      <VolumeControlGlobalStyle />
      {isAudioPlayer ? <AudioVolumeControlGlobalStyle /> : null}
      <SliderContainer data-testid="slider-container">
        <Button
          onClick={onClickToggleMute}
          onMouseEnter={() => (isDesktop ? setTooltipVisible(true) : null)}
          onMouseLeave={() => setTooltipVisible(false)}
          isAudioPlayer={isAudioPlayer}
        >
          {isTooltipVisible && (
            <MediaButtonTooltip
              label={
                volume === 0
                  ? translate('controls.volume.button.tooltip.unmute')
                  : translate('controls.volume.button.tooltip.mute')
              }
              minWidth={86}
            />
          )}
          <ButtonIcon Icon={volume === 0 ? VolumeMuted : Volume} />
          <ScreenReaderContent as="span">
            {volume === 0
              ? translate('controls.volume.button.accessibility_label.unmute')
              : translate('controls.volume.button.accessibility_label.mute')}
          </ScreenReaderContent>
        </Button>
        <SliderBarContainer>
          <Slider
            ariaLabelForHandle={translate(
              'controls.volume.slider.accessibility_label',
            )}
            prefixCls={PREFIX_CLASS}
            max={100}
            min={0}
            onChange={onChange}
            value={volume}
            step={1}
          />
        </SliderBarContainer>
      </SliderContainer>
    </Container>
  );
};

export default VolumeControl;
