import { styled, ThemeInterface } from 'styles';
import { rem } from 'polished';
import { HeadingText } from '@unmind/design-system-components-web';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 0 ${rem('8px')} ${rem('8px')} ${rem('8px')};
`;

export const ModalTitle = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 1,
    sizes: [theme.typography.fontSizes.fontSize20],
    weight: theme.typography.fontWeights.medium,
    id: 'modal-copy',
  }),
)`
  text-align: 'left';
  height: 100%;
  width: 100%;
`;
