import { BodyText } from '@unmind/design-system-components-web';
import PrimaryButton from 'Shared/PrimaryButton';
import { Error } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: ${rem(24)};
`;

export const ConfirmButton = styled(PrimaryButton)<{ $success?: boolean }>`
  width: 100%;

  ${({ $success }) =>
    $success &&
    css`
      background-color: ${({ theme }) => theme.colors.background.success};
    `}
`;

export const HelpLinkContainer = styled.div`
  margin-bottom: ${rem(16)};
`;

export const HelpText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  display: inline;
`;

export const Anchor = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  color: theme.colors.text.link,
  weight: theme.typography.fontWeights.regular,
}))`
  display: inline;
`;

export const ErrorIcon = styled(Error).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.error,
}))`
  width: ${rem(32)};
  height: ${rem(32)};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(16)};
  margin-bottom: ${rem(16)};
  padding: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.background.error};
  border: 1px solid ${({ theme }) => theme.colors.border.error};
  border-radius: ${rem(12)};
`;

export const ErrorText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.error,
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;
