import { get, startCase } from 'lodash';
import { rem } from 'polished';
import React, { ComponentType, HTMLAttributes } from 'react';
import { styled } from 'styles';
import {
  Calmness as CalmnessIcon,
  Connection as ConnectionIcon,
  Coping as CopingIcon,
  Fulfilment as FulfilmentIcon,
  Happiness as HappinessIcon,
  Health as HealthIcon,
  IconProps,
  Info as InfoIcon,
  Sleep as SleepIcon,
} from 'icons';
import { BodyText } from '../../../../Shared/Typography';
import Tooltip from '../../../../Shared/Tooltip';

const CATEGORY_ICONS = {
  calmness: CalmnessIcon,
  connection: ConnectionIcon,
  coping: CopingIcon,
  fulfilment: FulfilmentIcon,
  happiness: HappinessIcon,
  health: HealthIcon,
  sleep: SleepIcon,
};

const CategoryLabelBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
  padding: ${rem(12)} ${rem(12)};
  margin-bottom: ${rem(8)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${rem(4)};
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CategoryProgressInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${rem(8)};
`;

const CategoryLabelsWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const withIconStyle = (icon: ComponentType<IconProps>) => styled(icon).attrs(
  ({ theme }) => ({
    primaryColor: theme.colors.text.secondary,
  }),
)`
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${rem(8)};
  align-self: center;
`;

const CategoryLabel = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-transform: capitalize;
  overflow-wrap: break-word;
  max-width: 100%;
`;

const TooltipIcon = styled(withIconStyle(InfoIcon))`
  margin: ${rem(4)} 0;
`;

type IndexQuestionCategory = {
  key: string;
  name: string;
  averageScore: number;
  description?: string;
};

export type IndexCategoryListProps = HTMLAttributes<HTMLUListElement> & {
  items: IndexQuestionCategory[];
};

const IndexCategoryList = ({ items, className }: IndexCategoryListProps) => (
  <List className={className}>
    {items.map(({ averageScore, description, name, key }) => {
      const icon = get(CATEGORY_ICONS, key);

      const categoryLabel = startCase(name);
      const scoreLabel = `${Math.round(averageScore)}`;

      return (
        <ListItem
          id={name}
          data-test-id="item"
          key={`index-category-${name}-${averageScore}`}
          aria-label={`${categoryLabel} ${scoreLabel}`}
        >
          <CategoryLabelsWrapper aria-hidden={true}>
            {icon
              ? React.createElement(withIconStyle(icon), {
                  'aria-hidden': true,
                })
              : null}
            <CategoryLabelBody>
              <CategoryLabel>{categoryLabel}</CategoryLabel>
              <CategoryProgressInfo>
                <CategoryLabel>{scoreLabel}</CategoryLabel>
              </CategoryProgressInfo>
            </CategoryLabelBody>
          </CategoryLabelsWrapper>

          {description ? (
            <Tooltip
              labelCopy={description}
              accessibilityId={name.toLowerCase()}
            >
              <TooltipIcon />
            </Tooltip>
          ) : null}
        </ListItem>
      );
    })}
  </List>
);

export default IndexCategoryList;
