import clarity from 'lib/Clarity/clarity';
import { useLocation } from 'react-router';

declare global {
  interface Window {
    clarity?(action: string, ...args: unknown[]): unknown;
  }
}

const BLOCKLISTED_PROD_DOMAINS =
  process.env.REACT_APP_CLARITY_BLOCKLISTED_DOMAINS?.split(',');
const CLARITY_ID = process.env.REACT_APP_CLARITY_ID;

export const Clarity = () => {
  const location = useLocation();
  const isTalkPage = location.pathname.includes('/talk');

  const isBlocklistedSubdomain = !!BLOCKLISTED_PROD_DOMAINS?.find(
    domain => window?.location.hostname.indexOf(domain) === 0,
  );

  /*
   * We would have liked to only track the Talk pages with Clarity, but once you start Clarity it's
   * impossible to stop it, so the best we could do is start it when they go to Talk and never stop it.
   */
  if (
    isTalkPage &&
    !isBlocklistedSubdomain &&
    process.env.NODE_ENV === 'production'
  ) {
    const scriptElement = document.getElementById('clarity-script');
    if (CLARITY_ID && !scriptElement) {
      clarity(CLARITY_ID);
    }
  }

  return null;
};
