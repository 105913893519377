import React from 'react';
import { rem } from 'polished';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import { styled } from 'styles';

const SectionHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SectionHeadingTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize28],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
}))`
  margin-bottom: ${rem(2)};
  text-align: center;
`;

const SectionSubTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'p',
}))`
  margin: 0;
  text-align: center;
`;

interface SectionHeadingProps {
  title: string;
  subtitle?: string;
  iconComponent: React.ReactNode;
}

const SectionHeading = ({
  title,
  subtitle,
  iconComponent,
}: SectionHeadingProps) => (
  <SectionHeadingWrapper>
    {iconComponent}
    <SectionHeadingTitle tabIndex={0}>{title}</SectionHeadingTitle>
    {subtitle ? (
      <SectionSubTitle tabIndex={0}>{subtitle}</SectionSubTitle>
    ) : null}
  </SectionHeadingWrapper>
);

export default SectionHeading;
