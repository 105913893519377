import { BodyText, Card } from '@unmind/design-system-components-web';
import { Link } from 'react-router-dom';
import { FontSizes } from '@unmind/design-system-theme';
import RoutePath from 'App/RoutePath';
import { practitionersQuery_practitioners_edges_node as Practitioner } from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import matchAvatarPlaceholder from 'Talk/assets/browse_practitioners.png';
import { getProfileSummary } from 'Talk/lib/constants/getProfileSummary';
import { Namespace, ParseKeys } from 'i18next';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { parseBeGatewayId } from 'utils/relay';
import { Profession } from '../../PractitionerProfileDetails/constants';

const MatchCardOuter = styled(Card)<{ $orientation: Props['orientation'] }>`
  display: flex;

  ${({ $orientation }) =>
    $orientation === 'vertical'
      ? css`
          flex-direction: column;
          align-items: flex-start;
        `
      : css`
          flex-direction: row;
          align-items: center;
          padding: 16px;
        `}

  box-shadow: 0px 6px 12px -3px rgba(13, 3, 0, 0.05),
    0px 3px 6px -2px rgba(13, 3, 0, 0.05);

  transition: transform 200ms ease-in-out, transform 200ms ease-in-out;
  &:active {
    transform: scale(0.98);
    opacity: 0.98;
  }

  &:hover {
    transition: transform 200ms ease-in-out, transform 200ms ease-in-out;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
    transform: scale(1.01);
  }
`;

const MatchName = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [FontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
}))`
  margin-bottom: 4px;
  word-break: break-word;
`;

const MatchBody = styled(BodyText).attrs({
  sizes: [FontSizes.fontSize16],
})<{ $orientation?: Props['orientation'] }>`
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ $orientation }) =>
    $orientation === 'vertical' &&
    css`
      margin-bottom: 16px;
    `}
`;

export const MatchImage = styled.img<{
  src: string;
  $orientation: Props['orientation'];
}>`
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 16px;

  ${({ $orientation }) =>
    $orientation === 'vertical'
      ? css`
          max-height: 200px;
          width: 100%;
          margin-bottom: 16px;
        `
      : css`
          height: 100px;
          width: 100px;
          margin-right: 16px;
        `}
`;

interface Props {
  match: Practitioner;
  orientation: 'horizontal' | 'vertical';
  trackingSource: 'home_match' | 'browse_match';
}

export function MatchCard({ match, orientation, trackingSource }: Props) {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { id, firstName, lastName, photoUrls, details } = match || {};
  const normalisedId = parseBeGatewayId(id);
  const {
    profileQuestionResponses,
    type: practitionerType,
    specialities,
  } = details || {};
  const { areasOfExpertise = [] } = specialities || {};
  const translateAreaOfExpertise = (area?: string) =>
    area ? t(`specialities.${area}` as ParseKeys<Namespace<'talk'>>) : '';

  const profileSummary = getProfileSummary(
    profileQuestionResponses,
    practitionerType,
  );
  const profession = details.profileInformation?.profession as Profession;
  const professionValue =
    Profession[profession] && !(profession === Profession.other)
      ? t(`practitioner_profile.professions.${profession}`)
      : null;
  const practitionerName = `${firstName} ${lastName}`;

  const practitionerTypeLabel = professionValue || capitalize(details.type);
  const photoUrl = photoUrls?.[0]?.url ?? matchAvatarPlaceholder;

  const ariaLabel = `${
    `${practitionerName}. ` +
    `${practitionerTypeLabel}. ` +
    `${profileSummary ? `${profileSummary}. ` : ''}`
  }${
    areasOfExpertise?.length
      ? `${t(
          'browse_practitioners.practitioner_card.focus_areas',
        )}: ${areasOfExpertise
          ?.map(area => translateAreaOfExpertise(area?.translationKey))
          .join(', ')}
      `
      : ''
  }`;

  return (
    <MatchCardOuter
      forwardedAs={Link}
      to={{
        pathname: `${RoutePath.TalkBrowse}/${normalisedId}`,
        state: { source: trackingSource },
      }}
      aria-label={ariaLabel}
      $orientation={orientation}
    >
      <MatchImage
        src={photoUrl}
        alt={t('matching.match_card.image.alt', {
          name: firstName,
        })}
        $orientation={orientation}
      />
      <div>
        <MatchName>{`${firstName} ${lastName}`}</MatchName>
        <MatchBody $orientation={orientation}>
          {practitionerTypeLabel}
        </MatchBody>
        {profileSummary && orientation === 'vertical' ? (
          <MatchBody>{profileSummary}</MatchBody>
        ) : null}
      </div>
    </MatchCardOuter>
  );
}
