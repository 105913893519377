import React from 'react';
import { IconProps } from '../IconProps';

const SvgInterludes = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#F0FAFF" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 53.935C265.06 13.296 226.228 52.1 192.739 85.566c-14.644 14.634-28.266 28.247-43.439 33.749-37.346 13.544-64.098-1.718-91.647-17.434C39.803 91.698 21.618 81.323 0 78.471V0h340v53.935z"
      fill="#011165"
    />
  </svg>
);

export default SvgInterludes;
