import FeedbackButtons from 'Shared/FeedbackButtons/FeedbackButtons';
import { useSendFeedback } from 'StaticContent/hooks';
import { EventName, FeedbackCategory } from '__generated__/globalTypes';
import React, { useState } from 'react';
import FeedbackForm from './Form/FeedbackForm';
import {
  FeedbackButtonsWrapper,
  FeedbackContainer,
  FeedbackFormWrapper,
} from './styles';

export interface ChatbotFeedbackProps {
  messageId?: string;
  conversationId?: string;
}

type FeedbackChatbotTrackingEventProperties = {
  conversationId?: string;
  messageId?: string;
};

export const ChatbotFeedback = ({
  messageId,
  conversationId,
}: ChatbotFeedbackProps) => {
  const { sendFeedback } = useSendFeedback();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [feedbackState, setFeedbackState] = useState<FeedbackCategory | null>(
    null,
  );
  const isFeedbackFormActive = feedbackState !== null && !feedbackSubmitted;

  const feedbackEventProperties: FeedbackChatbotTrackingEventProperties = {
    messageId,
    conversationId,
  };

  return (
    <FeedbackContainer data-testid="chatbot-feedback-area">
      <FeedbackButtonsWrapper isFeedbackFormActive={isFeedbackFormActive}>
        <FeedbackButtons
          showLabels={false}
          onPositiveClick={activeState => {
            setFeedbackState(activeState ? FeedbackCategory.POSITIVE : null);
          }}
          onNegativeClick={activeState => {
            setFeedbackState(activeState ? FeedbackCategory.NEGATIVE : null);
          }}
          eventProperties={feedbackEventProperties}
          clientSideEventNames={{
            positive: 'liked-ai-coach-chatbot-message-clicked',
            negative: 'disliked-ai-coach-chatbot-message-clicked',
          }}
          serverSideEventNames={{
            positive: EventName.CHATBOT_MESSAGE_LIKED,
            negative: EventName.CHATBOT_MESSAGE_DISLIKED,
          }}
        />
      </FeedbackButtonsWrapper>

      {isFeedbackFormActive ? (
        <FeedbackFormWrapper tabIndex={-1}>
          <FeedbackForm
            feedbackType={feedbackState}
            handleCloseClick={() => {
              setFeedbackState(null);
            }}
            handleSubmitClick={async userInput => {
              setFeedbackState(null);
              setFeedbackSubmitted(true);

              void sendFeedback({
                source: 'ai-coach-chatbot',
                category: feedbackState,
                feedback: userInput.feedbackText,
                consentForContact: userInput.contactConsent,
                chatbotMessageFeedback: {
                  messageId: messageId || '',
                  conversationId: conversationId || '',
                },
                tags: userInput.selectedTagOptions,
              });
            }}
          />
        </FeedbackFormWrapper>
      ) : null}
    </FeedbackContainer>
  );
};
