export enum AddToCalendarContentType {
  COURSE = 'course',
  SHORT = 'short',
  DAILY_BOOST = 'daily-boost',
  UNKNOWN = 'unknown',
}

export const getContentTypeFromLink = (
  link: string,
): AddToCalendarContentType => {
  if (link.includes('/daily-boost')) {
    return AddToCalendarContentType.DAILY_BOOST;
  }
  if (link.includes('/shorts/')) {
    return AddToCalendarContentType.SHORT;
  }
  if (link.includes('/courses/')) {
    return AddToCalendarContentType.COURSE;
  }

  return AddToCalendarContentType.UNKNOWN;
};
