import React from 'react';
import debounce from 'lodash/debounce';
import { KnownReferrer } from 'hooks/useKnownReferrer';
import { TimerDispatch } from '../Timer';
import { onNextStep as onNextStepType } from '../../Series/SingleSeries/SeriesDay/Buttons/ButtonsShared';
import NextButton from '../../Series/SingleSeries/SeriesDay/Buttons/NextButton';
import { isIOS } from '../../Shared/MediaPlayerComponents/isIOS';
import {
  Status,
  MediaToggleMethod,
  MediaTrackingEvent,
} from '../MediaPlayerComponents';
import { keyboardHandlers } from '../MediaPlayerComponents/keyboardHandlers';
import { MediaVolumeChangeMethod } from '../MediaPlayerComponents/mediaVolumeChangeMethod';
import { tracking } from '../../App/Tracking';
import VideoPlayer from './VideoPlayer';
import VideoPlayerUI from './VideoPlayerUI';

interface VideoPlayerContainerState {
  status: Status;
  currentTime: number;
  duration: number;
  volume: number;
  previousVolume: number;
  videoMounted: boolean;
  statusBeforeLoading: 'playing' | 'paused';
  hasPausedBefore: boolean;
  isDragging: boolean;
  startDragTime: number;
  isSeeking: boolean;
  startSeekTime: number;
  hasPressedPlay: boolean;
  isCalendarModalOpen: boolean;
}

export interface VideoPlayerContainerProps {
  backButtonLabel: string;
  mediaSrc: string;
  title: string;
  summary?: string;
  subtitle?: string;
  segmentId?: string;
  hasTranscript?: boolean;
  onNextStep?: onNextStepType;
  secondarySegmentType?: string;
  timerDispatch: TimerDispatch;
  hasNextStep?: boolean;
  onClickPlay?(): void;
  onClickPause?(): void;
  onResume?(
    playerRef: React.Ref<HTMLAudioElement | null>,
    method: MediaToggleMethod,
  ): void;
  onPause?(
    playerRef: React.Ref<HTMLAudioElement | null>,
    method: MediaToggleMethod,
  ): void;
  onSeek?({
    playerRef,
    method,
    startPosition,
  }: {
    playerRef: React.Ref<HTMLMediaElement | null>;
    method: string;
    startPosition: number;
  }): void;
  onClickReplay?(): void;
  onEnded?(event: React.SyntheticEvent<HTMLVideoElement>): void;
  onError?(event: React.SyntheticEvent<HTMLVideoElement>): void;
  onTranscriptClick?({
    position,
    totalLength,
  }: {
    position: number;
    totalLength: number;
  }): void;
  onVolumeChange?({
    playerRef,
    method,
    isVolumeMuted,
    sound,
  }: {
    playerRef: React.Ref<HTMLAudioElement | null>;
    method: string;
    isVolumeMuted: boolean;
    sound: number;
  }): void;
  isLastSegmentInDay?: boolean;
  isComplete?: boolean;
  autoplay?: boolean;
  onLoading?(event: React.SyntheticEvent<HTMLVideoElement>): void;
  onLoaded?(event: React.SyntheticEvent<HTMLVideoElement>): void;
  onClose({
    playerRef,
    method,
  }: {
    playerRef: React.Ref<HTMLMediaElement | null>;
    method: string;
  }): void;
  isTranscriptShown: boolean;
  mediaSessionId: string;
  slug: string;
  feature: string;
  showCalendarIntegration?: boolean;
  referrer: KnownReferrer | null;
}

const VIDEO_JUMP_PERIOD_SECONDS = 10;
const VOLUME_SLIDER_CLASS = 'unmind-media-volume-handle';
export const DEFAULT_VOLUME = 50;

const trackMediaEvent = ({
  eventName,
  feature,
  duration,
  slug,
  mediaSessionId,
  extraTrackingProps,
  referrer,
}: {
  eventName: MediaTrackingEvent;
  feature: string;
  duration: number;
  slug: string;
  mediaSessionId: string;
  extraTrackingProps?: Record<string, unknown>;
  referrer: KnownReferrer | null;
}) => {
  tracking.track(eventName, {
    feature,
    medium: 'video',
    totalLength: duration,
    contentSlug: slug,
    mediaSessionId,
    referrer,
    ...extraTrackingProps,
  });
};

export class VideoPlayerContainer extends React.Component<
  VideoPlayerContainerProps,
  VideoPlayerContainerState
> {
  constructor(props: VideoPlayerContainerProps) {
    super(props);

    this.state = {
      status: 'loading',
      currentTime: 0,
      duration: 0,
      volume: DEFAULT_VOLUME,
      previousVolume: DEFAULT_VOLUME,
      videoMounted: false,
      statusBeforeLoading: 'paused',
      hasPausedBefore: false,
      isDragging: false,
      startDragTime: 0,
      isSeeking: false,
      startSeekTime: 0,
      hasPressedPlay: false,
      isCalendarModalOpen: false,
    };
  }

  static defaultProps = {
    hasTranscript: false,
  };

  componentMounted = false;
  videoPlayer = React.createRef<VideoPlayer>();
  nativeVideoElementRef = React.createRef<HTMLVideoElement>();

  handleSpacePress = (key: KeyboardEvent) => {
    if (
      // IE11 listens for key.key 'Spacebar' instead of key.code 'Space'
      keyboardHandlers.isSpace(key) &&
      !(key.target instanceof HTMLButtonElement) &&
      !this.props.isTranscriptShown
    ) {
      this.handleToggle('spacebar-pressed');
    }
  };

  handleMouseDragDown = (e: MouseEvent) => {
    const progressHandler = document.querySelector(
      '.unmind-media-progress-handle',
    );
    if (e.target === progressHandler) {
      this.setState({ isDragging: true });
    }
  };

  handleMouseUpFromDrag = () => {
    if (this.state.isDragging) {
      this.handleSeek({
        method: 'progress-handle-drag',
        startPosition: this.state.startDragTime,
      });
    }
    this.setState({ isDragging: false });
  };

  handleArrowUp = (key: KeyboardEvent) => {
    if (this.state.isSeeking) {
      if (keyboardHandlers.isArrowRight(key)) {
        this.handleSeek({
          method: 'right-arrow-key',
          startPosition: this.state.startSeekTime,
        });
        this.setState({ isSeeking: false });
      } else if (keyboardHandlers.isArrowLeft(key)) {
        this.handleSeek({
          method: 'left-arrow-key',
          startPosition: this.state.startSeekTime,
        });
        this.setState({ isSeeking: false });
      }
    }
  };

  handleArrowDown = (key: KeyboardEvent) => {
    const player = this.videoPlayer.current;
    const { currentTime, duration } = this.state;

    if (document.activeElement?.className === VOLUME_SLIDER_CLASS) {
      return;
    }

    if (player && keyboardHandlers.isArrows(key)) {
      if (!this.state.isSeeking) {
        this.setState({ startSeekTime: this.state.currentTime });
        this.setState({ isSeeking: true });
      }

      if (keyboardHandlers.isArrowRight(key)) {
        if (currentTime < duration - VIDEO_JUMP_PERIOD_SECONDS) {
          this.handleSetProgress(currentTime + VIDEO_JUMP_PERIOD_SECONDS);
        } else {
          this.handleSetProgress(duration);
        }
      } else {
        if (currentTime > VIDEO_JUMP_PERIOD_SECONDS) {
          this.handleSetProgress(currentTime - VIDEO_JUMP_PERIOD_SECONDS);
        } else {
          this.handleSetProgress(0);
        }
      }
    }
  };

  handleMKeyDown = (key: KeyboardEvent) => {
    if (keyboardHandlers.isMKey(key)) {
      this.toggleMute('m-key-pressed');
    }
  };

  handleClosePlayer = (
    event: BeforeUnloadEvent | React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (event instanceof BeforeUnloadEvent) {
      this.props.onClose({
        playerRef: this.nativeVideoElementRef,
        method: 'tab-closed',
      });
    } else if (event instanceof KeyboardEvent) {
      if (
        keyboardHandlers.isEscape(event) &&
        !this.props.isTranscriptShown &&
        !this.state.isCalendarModalOpen
      ) {
        this.props.onClose({
          playerRef: this.nativeVideoElementRef,
          method: 'escape-key-pressed',
        });
      }
    } else if (event.nativeEvent instanceof MouseEvent) {
      if (this.state.status === 'error') {
        this.props.onClose({
          playerRef: this.nativeVideoElementRef,
          method: 'error-close-button-clicked',
        });
      } else {
        this.props.onClose({
          playerRef: this.nativeVideoElementRef,
          method: 'back-button-clicked',
        });
      }
    }
  };

  componentDidMount() {
    this.componentMounted = true;
    this.setState({ videoMounted: true });
    window.addEventListener('keydown', this.handleSpacePress);
    window.addEventListener('mousedown', this.handleMouseDragDown);
    window.addEventListener('mouseup', this.handleMouseUpFromDrag);
    document.addEventListener('keydown', this.handleMKeyDown);
    document.addEventListener('keydown', this.handleArrowDown);
    document.addEventListener('keyup', this.handleArrowUp);
    document.addEventListener('keydown', this.handleClosePlayer);
    window.addEventListener('beforeunload', this.handleClosePlayer);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSpacePress);
    window.removeEventListener('mousedown', this.handleMouseDragDown);
    window.removeEventListener('mouseup', this.handleMouseUpFromDrag);
    document.removeEventListener('keydown', this.handleMKeyDown);
    document.removeEventListener('keydown', this.handleArrowDown);
    document.removeEventListener('keyup', this.handleArrowUp);
    document.removeEventListener('keydown', this.handleClosePlayer);
    window.removeEventListener('beforeunload', this.handleClosePlayer);
    this.componentMounted = false;
    this.setState({ videoMounted: false });
  }

  handleDrag = ({ startDraggingValue }: { startDraggingValue?: number }) => {
    if (startDraggingValue || startDraggingValue === 0) {
      this.setState({ startDragTime: startDraggingValue, isDragging: true });
    } else {
      this.setState({ isDragging: false });
    }
  };

  handleDurationAndLoading = (
    event: React.SyntheticEvent<HTMLVideoElement>,
  ) => {
    if (this.componentMounted) {
      this.setState({
        duration: event.currentTarget.duration,
      });

      // We only want to track this event for non-iOS platforms
      if (this.props.onLoading && !isIOS()) {
        this.props.onLoading(event);
      }

      if (isIOS()) {
        this.handleLoaded(event);
      }
    }
  };

  handleTimeUpdate = (currentTime: number) => {
    if (this.componentMounted) {
      this.setState({
        currentTime: currentTime,
      });
    }
  };

  handlePlay = () => {
    if (this.componentMounted) {
      this.setState({ status: 'playing' }, () => {
        this.props.timerDispatch.start(false);
      });
    }
  };

  handlePause = () => {
    if (this.componentMounted) {
      this.setState(
        {
          status: 'paused',
          statusBeforeLoading: 'paused',
          hasPausedBefore: true,
        },
        this.props.timerDispatch.stop,
      );
    }
  };

  handleEnded = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (this.componentMounted) {
      const { onEnded } = this.props;
      if (onEnded) {
        onEnded(event);
      }

      this.setState(
        { status: 'ended', statusBeforeLoading: 'paused' },
        this.props.timerDispatch.stop,
      );
    }
  };

  handleError = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const { onError, timerDispatch } = this.props;
    if (onError) {
      onError(event);
    }

    timerDispatch.stop();
    this.setState({ status: 'error' });
  };

  replayVideo = () => {
    const { onClickReplay } = this.props;
    if (onClickReplay) {
      onClickReplay();
    }

    trackMediaEvent({
      eventName: 'media-playback-started',
      feature: this.props.feature,
      duration: this.state.duration,
      slug: this.props.slug,
      mediaSessionId: this.props.mediaSessionId,
      extraTrackingProps: {
        mediaReplay: true,
        mediaLooping: false,
      },
      referrer: this.props.referrer,
    });

    if (this.componentMounted) {
      this.handleToggle();
      this.setState({
        status: 'playing',
        currentTime: 0,
      });
    }
  };

  handleClickPlay = () => {
    const { onClickPlay } = this.props;
    if (onClickPlay) {
      onClickPlay();
    }

    if (!this.state.hasPausedBefore) {
      trackMediaEvent({
        eventName: 'media-playback-started',
        feature: this.props.feature,
        duration: this.state.duration,
        slug: this.props.slug,
        mediaSessionId: this.props.mediaSessionId,
        extraTrackingProps: {
          mediaReplay: false,
          mediaLooping: false,
        },
        referrer: this.props.referrer,
      });
    }

    this.handleToggle('play-button-clicked');
  };

  handleClickPause = () => {
    const { onClickPause } = this.props;
    if (onClickPause) {
      onClickPause();
    }
    this.handleToggle('pause-button-clicked');
  };

  handleToggle = (method?: MediaToggleMethod) => {
    const player = this.videoPlayer.current;
    const { onResume } = this.props;
    const { onPause } = this.props;
    if (player) {
      if (
        method &&
        onResume &&
        this.state.hasPausedBefore &&
        this.state.status === 'paused'
      ) {
        onResume(this.nativeVideoElementRef, method);
      }
      if (
        (this.state.status === 'playing' || this.state.status === 'loading') &&
        onPause &&
        method
      ) {
        onPause(this.nativeVideoElementRef, method);
      }
      player.togglePlay();
    }
  };

  handleSetProgress = (progress: number) => {
    const player = this.videoPlayer.current;
    if (player) {
      player.setProgress(progress);
    }
    this.setState({ currentTime: progress });
  };

  handleVolumeUpdate = (
    newVolume: number,
    method?: MediaVolumeChangeMethod,
  ) => {
    const player = this.nativeVideoElementRef.current;
    if (player) {
      // We want our volume scale to be 0-100 but the
      // HTMLVideoElement has a max of 1
      player.volume = newVolume / 100;
      this.setState({ volume: newVolume });
    }
    const onVolumeChange = this.props.onVolumeChange;
    if (onVolumeChange && method) {
      onVolumeChange({
        playerRef: this.nativeVideoElementRef,
        method,
        isVolumeMuted: newVolume === 0,
        sound: newVolume,
      });
    }
  };

  handleVolumeUpdateViaSlider = (newVolume: number) => {
    this.handleVolumeUpdate(newVolume, 'slider');
  };

  toggleMute = (method: MediaVolumeChangeMethod) => {
    if (this.state.volume === 0) {
      this.handleVolumeUpdate(this.state.previousVolume, method);
    } else {
      this.setState({ previousVolume: this.state.volume });
      this.handleVolumeUpdate(0, method);
    }
  };

  toggleMuteViaButton = () => {
    this.toggleMute('toggle-mute-button-clicked');
  };

  handleSeek = ({
    startPosition,
    method,
  }: {
    startPosition: number;
    method: string;
  }) => {
    if (this.nativeVideoElementRef && this.props.onSeek) {
      this.props.onSeek({
        playerRef: this.nativeVideoElementRef,
        method,
        startPosition,
      });
    }
  };

  handleClickJumpForward = ({ method }: { method: string }) => {
    const player = this.videoPlayer.current;
    const { currentTime, duration } = this.state;
    const startTime = currentTime;

    if (player) {
      // if not near end
      if (currentTime < duration - VIDEO_JUMP_PERIOD_SECONDS) {
        this.handleSetProgress(currentTime + VIDEO_JUMP_PERIOD_SECONDS);
        // else just go to end
      } else {
        this.handleSetProgress(duration);
      }
      this.handleSeek({ startPosition: startTime, method });
    }
  };

  handleClickJumpBack = ({ method }: { method: string }) => {
    const player = this.videoPlayer.current;
    const { currentTime } = this.state;
    const startTime = currentTime;

    if (player) {
      // if not near end
      if (currentTime > VIDEO_JUMP_PERIOD_SECONDS) {
        this.handleSetProgress(currentTime - VIDEO_JUMP_PERIOD_SECONDS);
        // else just go to end
      } else {
        this.handleSetProgress(0);
      }
      this.handleSeek({ startPosition: startTime, method });
    }
  };

  handleNextStep = (event: React.MouseEvent) => {
    // stopPropagation is required to prevent toggling playback
    // from the layer beneath the button
    event.stopPropagation();
    this.setState({ videoMounted: false });
  };

  handleClose = (): void => {
    const { onNextStep } = this.props;

    if (onNextStep !== undefined) {
      this.setState(
        {
          videoMounted: false,
        },
        () => {
          onNextStep();
        },
      );
    }
  };

  handleLoading = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    this.setState(state => {
      if (state.status === 'playing' || state.status === 'paused') {
        return {
          ...state,
          statusBeforeLoading: state.status,
          status: 'loading',
        };
      } else {
        return state;
      }
    });
    if (this.props.onLoading) {
      this.props.onLoading(event);
    }
  };

  handleLoaded = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (
      this.props.onLoaded &&
      this.state.status === 'loading' &&
      event.type !== 'loadedmetadata'
    ) {
      this.props.onLoaded(event);
    }

    this.setState(state => ({
      ...state,
      status: state.statusBeforeLoading,
    }));
  };

  onAddToCalendarModalToggled = (isOpeningModal: boolean) => {
    this.setState({ isCalendarModalOpen: !this.state.isCalendarModalOpen });

    if (this.videoPlayer.current && this.state.hasPressedPlay) {
      if (this.state.status === 'playing') {
        this.videoPlayer.current.pause();
      } else if (this.state.status === 'paused' && !isOpeningModal) {
        this.videoPlayer.current.play();
      }
    }
  };

  render() {
    const {
      backButtonLabel,
      mediaSrc,
      title,
      summary,
      subtitle,
      segmentId,
      hasNextStep,
      isLastSegmentInDay = false,
      isComplete = false,
      hasTranscript,
      onTranscriptClick,
      autoplay,
    } = this.props;
    const { duration, volume, currentTime, status, videoMounted } = this.state;

    return (
      <>
        <VideoPlayerUI
          showCalendarIntegration={this.props.showCalendarIntegration}
          backButtonLabel={backButtonLabel}
          onClickBackButton={this.handleClosePlayer}
          currentTime={currentTime}
          duration={duration}
          volume={volume}
          title={title}
          summary={summary}
          status={status}
          onClickPlay={() => {
            this.setState({
              hasPressedPlay: true,
            });
            this.handleClickPlay();
          }}
          onClickPause={this.handleClickPause}
          onClickJumpBack={this.handleClickJumpBack}
          onClickJumpForward={this.handleClickJumpForward}
          onClickReplay={this.replayVideo}
          onClickToggleMute={this.toggleMuteViaButton}
          onSeek={this.handleSeek}
          hasTranscript={hasTranscript}
          isDragging={this.state.isDragging}
          handleDrag={this.handleDrag}
          onClickTranscript={() => {
            if (this.videoPlayer.current) {
              this.videoPlayer.current.pause();
            }

            if (onTranscriptClick) {
              onTranscriptClick({
                position: currentTime,
                totalLength: duration,
              });
            }
          }}
          onAddToCalendarModalToggled={this.onAddToCalendarModalToggled}
          onClickVideo={() => this.handleToggle('clickable-area-clicked')}
          onProgressChange={this.handleSetProgress}
          onVolumeChange={debounce(this.handleVolumeUpdateViaSlider, 50)}
          renderVideo={
            videoMounted && (
              <VideoPlayer
                ref={this.videoPlayer}
                handleDurationAndLoading={this.handleDurationAndLoading}
                refToForwardToNativeVideoElement={this.nativeVideoElementRef}
                handleEnded={this.handleEnded}
                handleError={this.handleError}
                handlePause={this.handlePause}
                handlePlay={this.handlePlay}
                handleTimeUpdate={this.handleTimeUpdate}
                handleVolumeUpdate={this.handleVolumeUpdate}
                handleLoaded={this.handleLoaded}
                handleLoading={this.handleLoading}
                videoSrc={mediaSrc}
                autoplay={autoplay}
              />
            )
          }
          renderNextStepButton={
            !!hasNextStep &&
            segmentId !== undefined && (
              <NextButton
                segmentId={segmentId}
                segmentTitle={subtitle ? subtitle : ''}
                onClick={this.handleNextStep}
                handleTakeOvers={this.handleClose}
                isLastSegmentInDay={isLastSegmentInDay}
                isComplete={isComplete}
              />
            )
          }
        />
      </>
    );
  }
}

export default VideoPlayerContainer;
