import { Clock } from 'icons';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { styled } from 'styles';
import Card from '../../../Shared/Card';
import BodyText from '../../../Shared/Typography/BodyText';
import { daysRemaining } from '../utils/timeBetweenIndex';

const Wrapper = styled(Card)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 8px;
  padding: 16px;
  flex-direction: row;
  align-items: center;
`;

export const ID_NEXT_INDEX_INFO_CONTAINER = 'next-index-info-container';

const IconContainer = styled.div`
  display: inherit;
`;

const Icon = styled(Clock).attrs(({ theme }) => ({
  height: 14,
  width: 14,
  primaryColor: theme.colors.text.secondary,
}))`
  margin-right: 12px;
`;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  display: inline-block;
`;

interface Props {
  latestScoreDate?: string;
  retakeFrequency: number;
}

const NextQuestionnaireCountdown = ({
  latestScoreDate,
  retakeFrequency,
}: Props) => {
  const { t: translate } =
    useTranslation<Namespace<'unmind_index'>>('unmind_index');

  if (!latestScoreDate) {
    return null;
  }

  return (
    <Wrapper data-testid={ID_NEXT_INDEX_INFO_CONTAINER}>
      <IconContainer aria-hidden="true">
        <Icon />
      </IconContainer>
      <Text>
        {Trans({
          t: translate,
          i18nKey: 'index_home.next_index.title',
          components: { bold: <Text /> },
          values: {
            count: daysRemaining(latestScoreDate, retakeFrequency),
          },
        })}
      </Text>
    </Wrapper>
  );
};

export default NextQuestionnaireCountdown;
