import { ThemeInterface } from '@unmind/design-system-theme';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import { Chevron } from 'icons';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { medium } from 'utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  margin-bottom: ${rem(16)};
  border-radius: ${rem(8)};
`;

export const ExpandableHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  cursor: default;
  gap: ${rem(16)};
  padding: ${rem(16)};

  ${medium(css`
    align-items: center;
  `)};
`;

export const QuestionText = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  lineHeight: theme.typography.lineHeights.lineHeight20,
  color: theme.colors.text.primary,
  level: 3,
}))``;

export const AnswerText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  lineHeight: theme.typography.lineHeights.lineHeight20,
  color: theme.colors.text.primary,
}))`
  overflow: hidden;
  padding: 0 ${rem(16)} ${rem(16)} ${rem(16)};
`;

export const ArrowIcon = styled(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    width: 14,
    height: 14,
  }),
)<{ $isOpen: boolean }>`
  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
  transform: ${props => (props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  margin-top: ${rem(4)};

  ${medium(css`
    margin-top: 0;
  `)};
`;
