import { BodyText, FeatureText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { small } from 'utils';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${small(css`
    max-width: 50%;
  `)};
`;

export const Title = styled(FeatureText)`
  text-align: center;
`;

export const Text = styled(BodyText)`
  margin: ${rem(32)} 0;
  text-align: left;
  white-space: pre-line;

  ${small(css`
    text-align: center;
  `)};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(15)} ${rem(10)};
  background-color: ${({ theme }) => theme.colors.background.primary};

  &:nth-of-type(2n) {
    background-color: ${({ theme }) => theme.colors.background.card};
  }

  &:nth-of-type(5n) {
    padding-bottom: ${rem(100)};
  }

  ${small(css`
    padding: ${rem(60)};
  `)};
`;
