import styled, { css } from 'styled-components';
import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import { Pencil, Question, Refresh, SendEnvelope, UserCheck } from 'icons';
import { small } from 'utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(BodyText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize28],
}))`
  padding-bottom: ${rem(20)};
  align-self: flex-start;

  ${small(css`
    padding: ${rem(0)};
  `)}
`;

export const SurveyStatusContainer = styled.div`
  display: flex;
  gap: ${rem(2)};
  flex-direction: column;

  ${small(css`
    flex-direction: row;
  `)}
`;

export const CurrentSurveyStatus = styled.div`
  display: flex;
  padding: ${rem(12)} ${rem(16)};
  background-color: ${({ theme }) => theme.colors.staticPalette.white};
  border-radius: ${rem(1000)};

  ${small(css`
    border-radius: ${rem(1000)} ${rem(0)} ${rem(0)} ${rem(1000)};
  `)}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(12)};
`;

export const AboutCompass = styled.div`
  display: flex;
  cursor: pointer;
`;

export const SendSurveyContainer = styled.div`
  padding: ${rem(12)} ${rem(16)};
  border-radius: ${rem(1000)};
  align-content: center;
  display: flex;
  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};
  cursor: pointer;

  ${small(css`
    border-radius: ${rem(0)} ${rem(1000)} ${rem(1000)} ${rem(0)};
  `)}
`;

export const SendSurveyIcon = styled(SendEnvelope).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
}))`
  margin-left: ${rem(12)};
`;

export const PencilIcon = styled(Pencil).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-right: ${rem(12)};
`;

export const SendSurveyButton = styled.div`
  align-content: center;
  color: ${({ theme }) => theme.colors.staticPalette.white};
  margin-right: ${rem(6)};
`;

export const CompletionRate = styled.div`
  padding: ${rem(12)} ${rem(16)};
  background-color: ${({ theme }) => theme.colors.staticPalette.white};
  border-radius: ${rem(1000)};
  align-content: center;
  display: flex;

  ${small(css`
    border-radius: ${rem(0)} ${rem(1000)} ${rem(1000)} ${rem(0)};
  `)}
`;

export const StatusText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  margin-right: ${rem(12)};
  align-content: center;
`;

export const StatusInfoText = styled.div<{ displayAsError?: boolean }>`
  align-content: center;
  color: ${({ theme, displayAsError }) =>
    displayAsError
      ? theme.colors.staticPalette.red.red6
      : theme.colors.text.primary};
`;

export const AboutIcon = styled(Question).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-right: ${rem(12)};
`;

export const UserCheckIcon = styled(UserCheck).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  margin-right: ${rem(12)};
`;

export const SurveyStatusBadge = styled.div<{
  innerColour: string;
  outerColour: string;
}>`
  width: ${rem(13)};
  height: ${rem(13)};
  min-width: ${rem(13)};
  min-height: ${rem(13)};
  background-color: ${({ innerColour }) => innerColour};
  border-radius: ${rem(100)};
  border: ${rem(3)} solid ${({ outerColour }) => outerColour};
  align-self: center;
  margin-right: ${rem(12)};
`;

export const IncompleteActionsContainer = styled.div`
  display: flex;
  gap: ${rem(2)};
  justify-content: flex-end;
`;

export const ExtendSurveyContainer = styled.div`
  border-radius: ${rem(0)} ${rem(1000)} ${rem(1000)} ${rem(0)};
  align-content: center;
  display: flex;
  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};
  cursor: pointer;
`;

export const RestartSurveyButton = styled.div`
  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};
  color: ${({ theme }) => theme.colors.staticPalette.white};
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const StatusBarButton = styled.div`
  align-content: center;
  width: 100%;
  height: 100%;
  padding: ${rem(12)} ${rem(16)};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CompassScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(32)} 0;
`;

export const RefreshIcon = styled(Refresh).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
}))`
  align-self: center;
  margin-left: ${rem(12)};
`;

export const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
`;

export const AverageSalary = styled.div`
  display: flex;
`;
