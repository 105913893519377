import { useQuery } from '@apollo/client';
import RoutePath from 'App/RoutePath';
import { TokenInvalidMessage } from '__generated__/globalTypes';
import useFeatureFlag from 'flags/useFeatureFlag';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const VALIDATE_TOKEN_QUERY = gql`
  query isValidInvitationToken($input: IsValidInvitationTokenInput!) {
    isValidInvitationToken(input: $input) {
      tokenIsValid
      message
    }
  }
`;

export const useValidateInvitationToken = (
  callback: (invitationToken: string) => void,
) => {
  const [isValidToken, setIsValidToken] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const invitedUserToken = new URLSearchParams(location.search).get(
    'invite_token',
  );

  const isNewInviteFlowEnabled = useFeatureFlag(
    'team-growth-new-send-invite-flow',
  );

  const shouldRunQuery =
    isNewInviteFlowEnabled && invitedUserToken && !isValidToken;

  const { data: validateInvitationTokenResponse } = useQuery(
    VALIDATE_TOKEN_QUERY,
    {
      variables: { input: { token: invitedUserToken } },

      skip: !shouldRunQuery,
    },
  );

  useEffect(() => {
    const tokenIsValid =
      validateInvitationTokenResponse?.isValidInvitationToken?.tokenIsValid;

    if (validateInvitationTokenResponse) {
      if (tokenIsValid && invitedUserToken) {
        setIsValidToken(true);
        callback(invitedUserToken);
      }
      if (!tokenIsValid) {
        const invalidTokenMessage =
          validateInvitationTokenResponse?.isValidInvitationToken?.message;

        if (
          invalidTokenMessage === TokenInvalidMessage.INVITEES_LIMIT_REACHED
        ) {
          history.push(RoutePath.InviteesLimitReached);
        } else {
          history.push(RoutePath.InvalidInviteToken);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateInvitationTokenResponse]);

  return {
    tokenIsValid: isValidToken,
    hasLovedOneInvitationCode: isNewInviteFlowEnabled && !!invitedUserToken,
  };
};
