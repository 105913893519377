import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { userQuery } from 'Services/User/__generated__/userQuery';
import { USER_QUERY } from '../graphql';
import { ConversationUpdateType, ScrollLocation, UserMessage } from '../types';
import {
  useShowDisclaimer,
  useIsScrollAtBottom,
  useHttpStream,
} from '../hooks';
import { messagesToConversationSessions } from '../utils';
import { ChatbotInput } from './ChatbotInput';
import { ChatbotSession } from './ChatbotSession';
import {
  ChatbotFooter,
  ChatBotFooterText,
  ChatBotInputContainer,
  ChatbotMessageWrapper,
  ChatbotWrapper,
  ScrollIconContainer,
  ScrollIcon,
} from './styles';

const CONVERSATION_UPDATE_SCROLL_MAPPING: Record<
  ConversationUpdateType,
  ScrollLocation
> = {
  NEW_MESSAGE: 'BOTTOM',
  LOAD_MORE: 'CURRENT',
};

export interface ChatbotProps {
  /**
   * An optional initial message from landing page redirect
   */
  initialMessage?: UserMessage;
}

export const ChatbotHttpStream = ({ initialMessage }: ChatbotProps) => {
  const { t: translate } = useTranslation('chatbot');
  const disclaimerVisible = useShowDisclaimer();
  const isScrollAtBottom = useIsScrollAtBottom(500);

  const [currentHeight, setCurrentHeight] = useState(
    document.body.scrollHeight,
  );
  const [scrollAction, setScrollAction] = useState<ScrollLocation>('BOTTOM');

  const { data: userData } = useQuery<userQuery>(USER_QUERY);
  const userFirstName = userData?.user?.firstName || '';

  const handleConversationUpdated: Parameters<
    typeof useHttpStream
  >[0]['onConversationUpdated'] = updateType => {
    setScrollAction(CONVERSATION_UPDATE_SCROLL_MAPPING[updateType]);
  };

  function scrollToEnd() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight || window.innerHeight,
      });
    }, 10);
  }

  const {
    messages,
    isSubmittingMessage,
    isStreamResponseComplete,
    submitMessage,
  } = useHttpStream({
    onConversationUpdated: handleConversationUpdated,
  });

  /**
   * Only recalculate sessions if messages change not for any other re render behavior
   */
  const sessions = useMemo(
    () => messagesToConversationSessions(messages),
    [messages],
  );

  /**
   * This useEffect hook handles the scroll position when new messages are added to the chat
   * and historical messages are loaded via infinite scroll.
   */
  useEffect(() => {
    if (sessions && sessions.length > 0) {
      if (scrollAction === 'BOTTOM') {
        scrollToEnd();
        setCurrentHeight(document.body.scrollHeight);
      } else if (scrollAction === 'CURRENT') {
        setTimeout(() => {
          // Calculate new scroll position
          const newHeight = document.body.scrollHeight;

          const newScrollPosition = newHeight - currentHeight;

          // Restore the scroll position
          window.scrollTo(0, newScrollPosition);
          setCurrentHeight(newHeight);
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  return (
    <ChatbotWrapper>
      <ChatbotMessageWrapper>
        {sessions &&
          sessions.map(({ date, messages: sessionMessages }, index) => (
            <ChatbotSession
              key={date}
              isCurrentOldestLoadedSession={index === 0 && sessions.length >= 1}
              date={date}
              messages={sessionMessages}
              loading={index === sessions.length - 1 && isSubmittingMessage}
              /**
               * Removing error handling for first pass at streaming demo
               * error={index === sessions.length - 1 && hadErrorSubmittingMessage}
               */
              onSubmit={submitMessage}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onReSubmit={async () => {}}
              userFirstName={userFirstName}
              hasInitialMessage={Boolean(initialMessage)}
              isDisclaimerShown={disclaimerVisible}
            />
          ))}
      </ChatbotMessageWrapper>

      {!isScrollAtBottom ? (
        <ScrollIconContainer
          a11y-role="button"
          aria-label={translate('conversation.scroll_to_bottom.a11y_label')}
          onClick={() => {
            scrollToEnd();
          }}
        >
          <ScrollIcon />
        </ScrollIconContainer>
      ) : null}

      <ChatbotFooter>
        <ChatBotInputContainer>
          <ChatbotInput
            canSubmit={!isSubmittingMessage && isStreamResponseComplete}
            onSubmit={submitMessage}
          />
        </ChatBotInputContainer>
        <ChatBotFooterText>
          {translate('conversation.conversation_footer_text')}
        </ChatBotFooterText>
      </ChatbotFooter>
    </ChatbotWrapper>
  );
};
