import React from 'react';
import { IconProps } from '../IconProps';
const SvgVisible = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 2.857c-2.206 0-4.206.974-5.81 2.13C2.58 6.147 1.304 7.536.533 8.47l-.007.008A2.437 2.437 0 000 10c0 .552.181 1.095.526 1.524l.007.008c.771.933 2.046 2.321 3.657 3.481 1.604 1.156 3.604 2.13 5.81 2.13 2.206 0 4.206-.974 5.81-2.13 1.61-1.16 2.886-2.548 3.657-3.481l.007-.008c.345-.429.526-.972.526-1.524 0-.551-.181-1.095-.526-1.523l-.007-.008c-.771-.933-2.046-2.321-3.657-3.482-1.604-1.156-3.604-2.13-5.81-2.13z"
      fill={primaryColor}
    />
    <path
      d="M6.786 10a3.215 3.215 0 106.429 0 3.215 3.215 0 00-6.43 0z"
      fill={secondaryColor}
    />
  </svg>
);
export default SvgVisible;
