import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves10 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 342 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h342v192H0z" />
    <path
      d="M341 96.919V0H219.063l.081.147c30.648 55.812 52.26 64.046 77.568 73.687 13.165 5.015 27.33 10.412 44.288 23.085z"
      fill={primaryColor}
    />
    <path
      d="M150.383 192c15.265-22.891 30.318-46.305 18.554-72.308-9.868-21.81-36.039-34.498-60.99-46.595-26.566-12.879-51.75-25.088-54.401-46.923C52.583 18.243 55.576 9.35 60.858 0H1v192h149.383z"
      fill={secondaryColor}
    />
  </svg>
);

export default SvgWaves10;
