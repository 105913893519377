import { logException } from '../App/logging';
import { getUserLocale } from './getUserLocale';

const getDeviceLanguageCode = () => {
  try {
    const userLocale = getUserLocale();

    if (!userLocale) {
      throw new Error('user locale not found');
    }
    const languageCode = userLocale?.split('-')[0];

    return languageCode;
  } catch (error) {
    logException(error);
    // default to english in braze to ensure push
    // notifications are still sent

    return 'en';
  }
};

export default getDeviceLanguageCode;
