import { css, styled } from 'styles';
import { rem, transparentize } from 'polished';
import { HeadingText, medium } from '@unmind/design-system-components-web';

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${rem(20)};
  overflow-x: auto;
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 3,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  display: flex;
  padding: 0 0 ${rem(8)} 0;
  margin-top: ${rem(8)};
`;

export const Table = styled.div.attrs({
  role: 'table',
})`
  display: table;
  width: 100%;
  padding: 0;
  border-radius: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.background.card};
  border: 1px solid ${props => props.theme.colors.border.secondary};
`;

export const TableRow = styled.div.attrs({
  tabIndex: 0,
  role: 'row',
})`
  display: table-row;
  border-radius: ${rem(8)};
`;

export const TitleRow = styled.div.attrs({
  role: 'row',
})`
  display: table-row;
  & > * {
    border-bottom: 1px solid
      ${({ theme }) => transparentize(0.85, theme.colors.border.primary)};
  }
  color: ${({ theme }) => transparentize(0.3, theme.colors.border.secondary)};

  & > *:first-child {
    width: 100%;
  }

  & > * + * {
    min-width: ${rem(20)};
    white-space: nowrap;
  }
`;

export const TitleText = styled.span.attrs({
  role: 'columnheader',
})`
  display: table-cell;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  padding: ${rem(12)} ${rem(20)};
`;

export const BaseCell = styled.span.attrs({
  role: 'cell',
})`
  display: table-cell;
  vertical-align: middle;
  padding: ${rem(12)} ${rem(20)};
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Panel = styled.div`
  ${medium(css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `)}
`;
