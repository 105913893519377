import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsSoundscapes = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 18a1 1 0 0 1-1 1h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1zM28 13a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-8a1 1 0 0 1 1-1zM23 10a1 1 0 0 1 1 1v14a1 1 0 1 1-2 0V11a1 1 0 0 1 1-1zM18 2a1 1 0 0 1 1 1v30a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zM13 8a1 1 0 0 1 1 1v19a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1zM8 14a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zM5 18a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgToolsSoundscapes;
