import { rem } from 'polished';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

const Bolded = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;

const Message = styled.div`
  white-space: pre-line;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: ${rem(24)};
  border-radius: ${rem(16)};
`;

interface TeamsMessagePreviewProps {
  surveyEndDate: string;
  managerFname?: string;
}

export const TeamsMessagePreview = ({
  surveyEndDate,
  managerFname,
}: TeamsMessagePreviewProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'create_compass_modal.teams_message_preview',
  });

  return (
    <Trans
      t={translate}
      i18nKey={'message'}
      values={{ managerName: managerFname, deadline: surveyEndDate }}
      defaults="<container>Your manager {{managerName}} is requesting you complete the Unmind Compass Check-In.\n\n Please carefully read each question and share your answers about how you're feeling about work at the moment. Your responses are completely anonymous. It’ll only take a minute of your time. And it will help your manager to drive meaningful change in your team. \n\n Please complete by the end of day on <bolded>{{deadline}}</bolded>.  Many thanks! 🙏</container>"
      components={{
        bolded: <Bolded />,
        container: <Message />,
      }}
    />
  );
};
