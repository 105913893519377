import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { css, styled } from 'styles';
import { extraSmall, small, medium, large } from '../../utils';

const Container = styled.div`
  padding-left: ${rem(24)};
  padding-right ${rem(24)};
  margin: 0 auto;
  width: 100%;

  ${extraSmall(css`
    padding-left: ${rem(24)};
    padding-right ${rem(24)};
  `)}

  ${small(css`
    padding-left: ${rem(32)};
    padding-right: ${rem(32)};
    width: ${rem(Breakpoints.S)};
  `)}

  ${medium(css`
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
    width: ${rem(Breakpoints.M)};
  `)}

  
  ${large(css`
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
    width: ${rem(Breakpoints.L)};
  `)}
`;

export default Container;
