import { Colors } from '@unmind/design-system-theme';
import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { styled, useTheme } from 'styles';
import { useTranslation } from 'react-i18next';
import AdminGroupedBarChart from '../../../../Shared/Charts/AdminGroupedBarChart';
import Panel, { PanelSection } from '../../Panel';
import { useFilterState } from '../../FilterState';
import { stripUnusedQueryVariables } from '../../FilterState/helpers';
import { AnonymityWarningMessage, renderUtcDate } from '../../helpers';
import { PanelAnonymityWarning } from '../../AnonymityWarning';
import i18n from '../../../../i18n/config';
import Metric, { MetricTitle } from '../../Metric';
import { NumberedList, NumberedListItem } from '../../NumberedList';
import { BodyText } from '../../../../Shared/Typography';
import {
  OrganisationCheckIns,
  OrganisationCheckInsVariables,
  OrganisationCheckIns_currentPeriod as OrganisationCheckInsPeriodOutput,
  OrganisationCheckIns_previousPeriod,
} from './__generated__/OrganisationCheckIns';

const formatDateLabel = (dateString: string) =>
  renderUtcDate(dateString, 'yyyy-MM-dd', 'do MMM yy');

const List = styled(NumberedList)`
  margin-top: ${rem(12)};
`;

export const getChartData = (
  {
    checkInCountByMood,
    totalCheckInCount,
  }: Pick<
    OrganisationCheckInsPeriodOutput,
    'checkInCountByMood' | 'totalCheckInCount'
  >,
  legend: string,
) => {
  if (checkInCountByMood && !isNil(totalCheckInCount)) {
    const data = checkInCountByMood.map(({ mood, count }) => {
      const name = mood;
      const percentage =
        totalCheckInCount > 0 ? (count / totalCheckInCount) * 100 : 0;

      return {
        name,
        value: percentage,
        label: [
          name,
          legend,
          i18n.t('admin:panels.check_in.chart.data.label_percentage', {
            percentage: `${Math.round(percentage) || '< 1'}`,
          }),
          i18n.t('admin:panels.check_in.chart.data.label_total', {
            count: count,
            total: totalCheckInCount,
          }),
        ],
      };
    });

    return { data, legend };
  }

  return { data: null, legend };
};

export const ADMIN_CHECK_INS_QUERY = gql`
  query OrganisationCheckIns(
    $currentPeriod: OrganisationCheckInsInput!
    $previousPeriod: OrganisationCheckInsInput!
  ) {
    currentPeriod: organisationCheckIns(input: $currentPeriod) {
      totalCheckInCount
      topFeelingNames(first: 3)
      checkInCountByMood {
        mood
        count
      }
      shouldBeAnonymised
    }
    previousPeriod: organisationCheckIns(input: $previousPeriod) {
      totalCheckInCount
      checkInCountByMood {
        mood
        count
      }
      shouldBeAnonymised
    }
  }
`;

const TopFeelings = ({
  topFeelingNames,
  panelColor,
}: {
  topFeelingNames: string[];
  panelColor: Colors;
}) => {
  const { typography } = useTheme();
  const { timeFilterState } = useFilterState();

  const currentStartDateLabel = formatDateLabel(timeFilterState.startDate);
  const currentEndDateLabel = formatDateLabel(timeFilterState.endDate);

  return (
    <PanelSection>
      <MetricTitle>
        Top feelings
        <BodyText sizes={[typography.fontSizes.fontSize12]}>
          {currentStartDateLabel} - {currentEndDateLabel}
        </BodyText>
      </MetricTitle>
      <List>
        {topFeelingNames.map(feeling => (
          <NumberedListItem key={feeling} numberColor={panelColor}>
            {feeling}
          </NumberedListItem>
        ))}
      </List>
    </PanelSection>
  );
};

const ComparisonChart = ({
  currentPeriod,
  previousPeriod,
  panelColor,
}: {
  currentPeriod: OrganisationCheckInsPeriodOutput;
  previousPeriod: OrganisationCheckIns_previousPeriod;
  panelColor: Colors;
}) => {
  const { colors } = useTheme();
  const { t: translate } = useTranslation('admin');

  const { timeFilterState, timeFilterPreviousPeriodState } = useFilterState();

  const currentStartDateLabel = formatDateLabel(timeFilterState.startDate);
  const currentEndDateLabel = formatDateLabel(timeFilterState.endDate);
  const previousStartDateLabel = formatDateLabel(
    timeFilterPreviousPeriodState.startDate,
  );
  const previousEndDateLabel = formatDateLabel(
    timeFilterPreviousPeriodState.endDate,
  );

  const data = !previousPeriod.shouldBeAnonymised
    ? {
        seriesOne: getChartData(
          currentPeriod,
          `${currentStartDateLabel} - ${currentEndDateLabel}`,
        ),
        seriesTwo: getChartData(
          previousPeriod,
          `${previousStartDateLabel} - ${previousEndDateLabel}`,
        ),
      }
    : {
        seriesOne: getChartData(
          currentPeriod,
          `${currentStartDateLabel} - ${currentEndDateLabel}`,
        ),
      };

  return (
    <PanelSection>
      <AdminGroupedBarChart
        caption={translate('panels.check_in.chart.caption')}
        categoriesHeader={translate('panels.check_in.chart.category_header')}
        colorScale={[panelColor, colors.staticPalette.blue.blue800]}
        height={324}
        data={data}
        title={translate('panels.check_in.chart.title')}
        description={translate('panels.check_in.chart.description')}
      />
    </PanelSection>
  );
};

const CheckInsPanel = () => {
  const { t: translate } = useTranslation('admin');
  const { colors } = useTheme();

  const panelProps = {
    title: translate('panels.check_in.title'),
    color: colors.staticPalette.bluish,
  };

  const {
    timeFilterState,
    timeFilterPreviousPeriodState,
    userGroupFilterState,
  } = useFilterState();
  const nullFreeUserGroupFilters = userGroupFilterState
    ? stripUnusedQueryVariables(userGroupFilterState)
    : {};

  const { data, error, loading } = useQuery<
    OrganisationCheckIns,
    OrganisationCheckInsVariables
  >(ADMIN_CHECK_INS_QUERY, {
    variables: {
      currentPeriod: {
        ...timeFilterState,
        ...nullFreeUserGroupFilters,
      },
      previousPeriod: {
        ...timeFilterPreviousPeriodState,
        ...nullFreeUserGroupFilters,
      },
    },
  });

  const { currentPeriod, previousPeriod } = data || {};
  const { totalCheckInCount, topFeelingNames, shouldBeAnonymised } =
    currentPeriod || {};

  if (shouldBeAnonymised) {
    return (
      <PanelAnonymityWarning
        {...panelProps}
        message={AnonymityWarningMessage.CHECK_IN_PANEL}
      />
    );
  }

  return (
    <Panel
      {...panelProps}
      isLoading={loading}
      hasError={
        !!error ||
        !currentPeriod ||
        !previousPeriod ||
        isNil(shouldBeAnonymised) ||
        isNil(totalCheckInCount)
      }
    >
      {!loading &&
      !error &&
      currentPeriod &&
      previousPeriod &&
      !isNil(shouldBeAnonymised) &&
      !isNil(totalCheckInCount) ? (
        <>
          <PanelSection>
            <Metric
              color={panelProps.color}
              title={translate('panels.check_in.metrics.total')}
              value={totalCheckInCount}
            />
          </PanelSection>
          <ComparisonChart
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            panelColor={panelProps.color}
          />
          {topFeelingNames && topFeelingNames.length ? (
            <TopFeelings
              topFeelingNames={topFeelingNames}
              panelColor={panelProps.color}
            />
          ) : null}
        </>
      ) : null}
    </Panel>
  );
};

export default CheckInsPanel;
