import React from 'react';
import { IconProps } from '../IconProps';

const SvgEnvelope = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
    <g fill={primaryColor} fillRule="evenodd">
      <path d="M14.48 16L28.5 27.403 42.52 16 44 17.733 29.24 29.74a1.178 1.178 0 0 1-1.48 0L13 17.733 14.48 16z" />
      <path d="M14.5 17.364c-.644 0-1.167.529-1.167 1.181v18.91c0 .652.523 1.181 1.167 1.181h28c.644 0 1.167-.529 1.167-1.181v-18.91c0-.652-.523-1.181-1.167-1.181h-28zM11 18.545C11 16.587 12.567 15 14.5 15h28c1.933 0 3.5 1.587 3.5 3.545v18.91C46 39.413 44.433 41 42.5 41h-28c-1.933 0-3.5-1.587-3.5-3.545v-18.91z" />
    </g>
  </svg>
);

export default SvgEnvelope;
