import { Colors } from '@unmind/design-system-theme';
import { styled, useTheme } from 'styles';
import { DocumentNode } from 'graphql';
import { rem } from 'polished';
import gql from 'graphql-tag';
import isNil from 'lodash/isNil';
import React from 'react';
import { pluralize, secondsToMinutes } from '../../../adminHelpers';
import Panel, { PanelSection } from '../../Panel';
import { useFilteredQuery } from '../../FilterState';
import { filterNonNull } from '../../../../typescript/helpers';
import Metric, { MetricTitle } from '../../Metric';
import { NumberedList, NumberedListItem } from '../../NumberedList';
import {
  OrganisationToolsInsights,
  OrganisationToolsInsights_organisationTools,
  OrganisationToolsInsights_organisationTools_topCategories,
} from './__generated__/OrganisationToolsInsights';

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    margin-right: ${rem(48)};
  }
`;

const List = styled(NumberedList)`
  margin-top: ${rem(12)};
`;

type TopTool = { title: string; __typename: 'OrganisationToolUsageData' };

const TopTools = ({
  topTools,
  color,
}: {
  topTools: TopTool[];
  color: Colors;
}) => (
  <PanelSection data-testid="tools-insights-top-tools">
    <MetricTitle>Top Shorts</MetricTitle>
    <List>
      {topTools.map(tool => (
        <NumberedListItem numberColor={color} key={tool.title}>
          {tool.title}
        </NumberedListItem>
      ))}
    </List>
  </PanelSection>
);

const TopCategories = ({
  topCategories,
  color,
}: {
  topCategories: OrganisationToolsInsights_organisationTools_topCategories;
  color: Colors;
}) => {
  const edges = topCategories.edges?.filter(filterNonNull) ?? [];
  const categories = edges.map(edge => edge?.node).filter(filterNonNull);

  return categories.length ? (
    <PanelSection data-testid="tools-insights-top-categories">
      <MetricTitle>Top categories</MetricTitle>
      <List>
        {categories.map(({ name }) => (
          <NumberedListItem numberColor={color} key={name}>
            {name}
          </NumberedListItem>
        ))}
      </List>
    </PanelSection>
  ) : null;
};

export const ORGANISATION_TOOLS_QUERY: DocumentNode = gql`
  query OrganisationToolsInsights($input: OrganisationToolsInput!) {
    organisationTools(input: $input) {
      playtime
      topTools(first: 3) {
        title
      }
      topCategories(first: 3) {
        edges {
          node {
            name
          }
        }
      }
    }
  }
`;

const ToolsInsightsPanelSection = ({
  organisationTools: { playtime, topTools, topCategories },
  panelColor,
}: {
  organisationTools: OrganisationToolsInsights_organisationTools;
  panelColor: Colors;
}) => {
  const toolsPlaytime = playtime ? playtime : 0;

  return (
    <>
      <PanelSection>
        <MetricContainer>
          <Metric
            data-testid="tools-insights-usage-playtime"
            color={panelColor}
            title="Playtime"
            value={secondsToMinutes(toolsPlaytime)}
            unit={pluralize(secondsToMinutes(toolsPlaytime), 'min')}
          />
        </MetricContainer>
      </PanelSection>
      {topTools.length ? (
        <TopTools color={panelColor} topTools={topTools} />
      ) : null}
      {topCategories ? (
        <TopCategories color={panelColor} topCategories={topCategories} />
      ) : null}
    </>
  );
};

const ToolsInsightsPanel = () => {
  const { colors } = useTheme();
  const panelColor = colors.staticPalette.grass;
  const {
    data: { organisationTools } = {},
    error,
    loading,
  } = useFilteredQuery<OrganisationToolsInsights>(ORGANISATION_TOOLS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Panel
      color={panelColor}
      title="Shorts"
      isLoading={loading}
      hasError={!isNil(error)}
    >
      {organisationTools ? (
        <ToolsInsightsPanelSection
          organisationTools={organisationTools}
          panelColor={panelColor}
        />
      ) : null}
    </Panel>
  );
};

export default ToolsInsightsPanel;
