import graphqlTag from 'graphql-tag';
import { graphql, DataValue } from '@apollo/client/react/hoc';
import { Errors } from '../../Shared/Errors';

export const VALIDATE_PLUSONE_INVITE = graphqlTag`
  query ValidatePlusOneInvite($input: ValidatePlusOneInviteInput!) {
    validatePlusOneInvite(input: $input) {
      inviteeEmail
    }
  }
`;

type ValidatePlusOneInviteInput = {
  plusOneInviteToken: string;
  subdomain: string;
};

type ValidatePlusOneInviteVariables = {
  input: ValidatePlusOneInviteInput;
};

export interface WithValidPlusOneInviteResponse {
  validatePlusOneInvite?: {
    __typename: string;
    inviteeEmail: string;
  };
  error?: Errors;
}

export interface WithValidPlusOneInviteProps {
  inviteeEmail?: string;
  __typename?: string;
  loading: boolean;
  error?: Errors;
}

export const mapDataToProps = (
  data?: DataValue<
    WithValidPlusOneInviteResponse,
    ValidatePlusOneInviteVariables
  >,
): WithValidPlusOneInviteProps => {
  if (data && data.loading) {
    return {
      loading: true,
    };
  }

  if (data && data.validatePlusOneInvite) {
    return {
      ...data.validatePlusOneInvite,
      loading: false,
    };
  }

  return {
    error: Errors.ServerError,
    loading: false,
  };
};

export const withValidPlusOneInvite = graphql<
  ValidatePlusOneInviteInput,
  WithValidPlusOneInviteResponse,
  ValidatePlusOneInviteVariables,
  WithValidPlusOneInviteProps
>(VALIDATE_PLUSONE_INVITE, {
  options: ({ plusOneInviteToken, subdomain }) => ({
    variables: { input: { plusOneInviteToken, subdomain } },
  }),
  props: ({ data }) => mapDataToProps(data),
});
