import { compareAsc, format, parse } from 'date-fns';
import { getTimezoneOffset } from 'App/Tracking/serverside';
import { ConversationSession, Message } from '../types';

export const messagesToConversationSessions = (
  messages: Message[],
): ConversationSession[] => {
  const mappedSessions = messages.reduce(
    (acc: ConversationSession[], message: Message) => {
      const date = new Date(message.createdAt);

      const timezoneOffset = getTimezoneOffset();
      if (timezoneOffset !== 0) {
        // Convert to local time
        date.setHours(date.getHours() - timezoneOffset / 60);
      }

      const formattedDate = format(date, 'MMMM d, yyyy');

      // Check to see if dated session already created in accumulated result
      const existingSessionIndex = acc.findIndex(
        session => session.date === formattedDate,
      );

      // If the date key exists on accumulated result then push new mapped message to it.
      if (existingSessionIndex > -1) {
        acc[existingSessionIndex].messages.push(message);
      } else {
        // If not create new session item for said date key
        acc.push({
          date: formattedDate,
          messages: [message],
        });
      }

      return acc;
    },
    [],
  );

  const todaysDate = new Date();

  // Check if there is a session for today and if not create one
  const todaysSession = mappedSessions.find(
    session => session.date === format(todaysDate, 'MMMM d, yyyy'),
  );
  if (!todaysSession) {
    mappedSessions.push({
      date: format(todaysDate, 'MMMM d, yyyy'),
      messages: [],
    });
  }

  return mappedSessions.sort((a, b) =>
    compareAsc(
      parse(a.date, 'MMMM d, yyyy', new Date()),
      parse(b.date, 'MMMM d, yyyy', new Date()),
    ),
  );
};
