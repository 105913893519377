import { useEffect, useState } from 'react';

export const ASSISTANT_LAST_INTRODUCTION_DISCLAIMER_DATE =
  'Assistant.assistantLastIntroductionDisclaimerDate';

export function useLastViewedDisclaimer(): [
  Date | null | undefined,
  (newState: Date) => Promise<void>,
] {
  const [lastViewedDate, setLocalState] = useState<Date | null>();

  useEffect(() => {
    const getHasSeenAssistantTips = async () => {
      const stored = localStorage.getItem(
        ASSISTANT_LAST_INTRODUCTION_DISCLAIMER_DATE,
      );

      if (!stored) {
        setLocalState(null);

        return;
      }

      const parsed = new Date(stored);
      if (isNaN(parsed.getTime())) {
        setLocalState(null);

        return;
      }

      setLocalState(parsed);
    };

    void getHasSeenAssistantTips();
  }, []);

  const setLastViewedDate = async (newState: Date) => {
    localStorage.setItem(
      ASSISTANT_LAST_INTRODUCTION_DISCLAIMER_DATE,
      newState.toISOString(),
    );
    setLocalState(newState);
  };

  return [lastViewedDate, setLastViewedDate];
}
