import React from 'react';
import { IconProps } from '../IconProps';

const SvgToolsInterludes = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" {...props}>
    <g
      clipPath="url(#ToolsInterludes_svg__clip0)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={primaryColor}
    >
      <path d="M9 26a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0zM26 23a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0z" />
      <path d="M29 5.584a1 1 0 0 0-1.25-.969l-13 3.365a1 1 0 0 0-.75.968V29a1 1 0 1 1-2 0V8.948a3 3 0 0 1 2.248-2.904l13-3.365A3 3 0 0 1 31 5.584V26a1 1 0 1 1-2 0V5.584z" />
      <path d="M30.256 10.467l-17 4.5-.512-1.934 17-4.5.512 1.934z" />
    </g>
    <defs>
      <clipPath id="ToolsInterludes_svg__clip0">
        <path d="M0 0h36v36H0V0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgToolsInterludes;
