import React from 'react';
import { IconProps } from '../IconProps';

const SvgArrowRightEncircled = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 29" fill="none" {...props}>
    <rect y={0.5} width={28} height={28} rx={14} fill={secondaryColor} />
    <path
      d="M6.571 14.5H21.43M6.571 14.5H21.43M17.429 18.5l4-4-4-4"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.429 18.5l4-4-4-4"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={0.5}
      y={1}
      width={27}
      height={27}
      rx={13.5}
      stroke={primaryColor}
      strokeOpacity={0.95}
    />
  </svg>
);

export default SvgArrowRightEncircled;
