import { useState, useEffect } from 'react';

export function useIsScrollAtBottom(bottomOffset: number): boolean {
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollHeight =
        window.document.body.scrollHeight < window.innerHeight
          ? window.innerHeight
          : window.document.body.scrollHeight - window.innerHeight;

      // Use Math.max to ensure that the scrollHeight is never negative, otherwise isAtBottom will always be true
      const isAtBottom =
        window.scrollY >= Math.max(0, scrollHeight - bottomOffset);

      setIsScrollAtBottom(isAtBottom);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bottomOffset]);

  return isScrollAtBottom;
}
