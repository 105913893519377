import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeadingTitle } from '../../Explore/ExplorePage';
import HeroBannerShort, {
  HeroBannerProps,
} from '../../Explore/HeroBanner/HeroBannerShort/HeroBannerShort';
import { HomeScreenSection } from './PersonalisedTodayScreen';

const HeroBanner = ({ tool, assetToken }: Omit<HeroBannerProps, 'source'>) => {
  const { t: translate } = useTranslation('homepage');

  return (
    <HomeScreenSection>
      <SectionHeadingTitle>
        {translate('personalised_today.hero_banner.title')}
      </SectionHeadingTitle>
      <HeroBannerShort
        tool={tool}
        assetToken={assetToken}
        source="today-hero-banner"
      />
    </HomeScreenSection>
  );
};

export default HeroBanner;
