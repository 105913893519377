import { format, utcToZonedTime } from 'date-fns-tz';
import { Namespace, TFunction } from 'i18next';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { convertToZonedTime, formatDate } from './dates';

type TalkTFunction = TFunction<Namespace<'talk'>>;

const googleTimeFormat = "yyyyLLdd'T'HHmm00'Z'";
const microsoftTimeFormat = "yyyy-LL-dd'T'HH:mm:00'Z'";

const getCalendarEventTitle = (params: LinkParams) => {
  const { t, event } = params;

  return t('upcoming_events.calendar_event.title', {
    firstName: event.userProfile.firstName,
  });
};

const getCalendarEventTimes = ({ event }: LinkParams, timeFormat: string) => ({
  beginsAt: format(utcToZonedTime(event.beginsAt, ''), timeFormat),
  endsAt: format(utcToZonedTime(event.endsAt, ''), timeFormat),
});

const getCalendarEventDescription = (
  { t, event, callURL }: LinkParams,
  br = '\n',
) => {
  const { userProfile, beginsAt, session, timeZone } = event;
  const { firstName, lastName } = userProfile;
  const { externalRef, password } = session || {};

  const formattedDate = formatDate(beginsAt);
  const formattedTimezone = convertToZonedTime(timeZone);

  const descriptionBody = t('upcoming_events.calendar_event.description', {
    firstName,
    lastName,
    date: formattedDate,
    timeZone: formattedTimezone,
  });
  const videoLink = t('upcoming_events.calendar_event.video_link', { callURL });
  const id = t('upcoming_events.calendar_event.id', { id: externalRef });
  const passwordText = t('upcoming_events.calendar_event.password', {
    password,
  });

  return `${descriptionBody}${br}${br}${videoLink}${br}${id}${br}${passwordText}`;
};

interface LinkParams {
  t: TalkTFunction;
  event: Event;
  callURL: string;
}

export const getGoogleCalendarLink = (params: LinkParams) => {
  const baseURL = 'https://calendar.google.com/calendar/render';
  const { beginsAt, endsAt } = getCalendarEventTimes(params, googleTimeFormat);
  const calendarEventName = getCalendarEventTitle(params);
  const calendarEventDescription = getCalendarEventDescription(params);

  const eventDetails = {
    action: 'TEMPLATE',
    text: calendarEventName,
    details: calendarEventDescription,
    trp: 'true',
    dates: `${beginsAt}/${endsAt}`,
  };
  const searchParams = new URLSearchParams(eventDetails);

  return `${baseURL}?${searchParams.toString()}`;
};

export const getOutlookCalendarLink = (params: LinkParams) => {
  // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/discussions/47#discussion-5173442
  // Note this should not be copy-pasted to mobile!
  const baseUrl = 'https://outlook.live.com/calendar/0/action/compose';

  const { beginsAt, endsAt } = getCalendarEventTimes(
    params,
    microsoftTimeFormat,
  );
  const calendarEventName = getCalendarEventTitle(params);
  const calendarEventDescription = getCalendarEventDescription(params, '<br/>');

  const eventDetails = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: beginsAt,
    enddt: endsAt,
    subject: calendarEventName,
    body: calendarEventDescription,
  };
  const searchParams = new URLSearchParams(eventDetails);

  return `${baseUrl}?${searchParams.toString()}`;
};

export const getOffice365CalendarLink = (params: LinkParams) => {
  // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/discussions/47#discussion-5173442
  // Note this should not be copy-pasted to mobile!
  const baseUrl = 'https://outlook.office.com/calendar/0/action/compose';

  const { beginsAt, endsAt } = getCalendarEventTimes(
    params,
    microsoftTimeFormat,
  );
  const calendarEventName = getCalendarEventTitle(params);
  const calendarEventDescription = getCalendarEventDescription(params, '<br/>');

  const eventDetails = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: beginsAt,
    enddt: endsAt,
    subject: calendarEventName,
    body: calendarEventDescription,
  };
  const searchParams = new URLSearchParams(eventDetails);

  return `${baseUrl}?${searchParams.toString()}`;
};
