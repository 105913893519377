import gql from 'graphql-tag';
import { MutateProps, withMutation } from '@apollo/client/react/hoc';
import { Event } from './Events';

export const LOG_EVENT_MUTATION = gql`
  mutation LogEvent($input: LogEventInput!) {
    logEvent(input: $input) {
      code
      success
    }
  }
`;

type LogEventInput = {
  eventName: Event;
  eventProperties?: unknown;
};

type LogEventVariables = {
  input: LogEventInput;
};

type LogEventOutput = {
  __typename?: 'LogEventPayload';
  logEvent: {
    code: string;
    success: boolean;
  };
};

export type LogEventResponse = {
  logEvent: LogEventOutput;
};

type defaultChildProps = MutateProps<LogEventResponse, LogEventVariables>;

export interface LogEventProps
  extends Omit<defaultChildProps, 'mutate' | 'result'> {
  logEvent: defaultChildProps['mutate'];
}

export const withLogEvent = withMutation<
  Record<string, unknown>,
  LogEventResponse,
  LogEventVariables,
  LogEventProps
>(LOG_EVENT_MUTATION, { name: 'logEvent' });
