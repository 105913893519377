import { BodyText, small } from '@unmind/design-system-components-web';
import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { css } from 'styles';
import RoutePath from 'App/RoutePath';
import { ActionButton } from './ActionButton';

const Footer = styled.div`
  padding: ${rem(10)} 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
  gap: ${rem(16)};

  ${small(css`
    flex-direction: row;
  `)}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: ${rem(10)};
  }
`;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  display: none;

  ${small(css`
    display: block;
    margin-right: ${rem(10)};
  `)};
`;

const BoldedText = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  margin-bottom: ${rem(10)};

  ${small(css`
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    margin-bottom: 0;
  `)};
`;

type StickyFooterProps = {
  actionButtonLabel: string;
  path: RoutePath;
  onCtaClick(): void;
};

export const StickyFooter = ({
  actionButtonLabel,
  path,
  onCtaClick,
}: StickyFooterProps) => (
  <Footer>
    <BoldedText>Ready to make a change in your mental health?</BoldedText>
    <ButtonsContainer>
      <ActionButton
        label={actionButtonLabel}
        path={path}
        onClick={onCtaClick}
      />
    </ButtonsContainer>
  </Footer>
);
