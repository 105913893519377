import { SingleSeries } from 'Series/SingleSeries/withSingleSeries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CourseDescription as CourseDescriptionText,
  CourseDescriptionBenefits,
  CourseDescriptionBenefitsList,
} from './styles';

interface CourseDescriptionProps {
  course: SingleSeries;
}

export const CourseDescription = ({ course }: CourseDescriptionProps) => {
  const { t: translate } = useTranslation('courses');

  const benefits = [course.benefit1, course.benefit2, course.benefit3];

  return (
    <>
      <CourseDescriptionText>{course.description}</CourseDescriptionText>
      <CourseDescriptionText>
        {translate('overview.benefits_title')}
      </CourseDescriptionText>
      <CourseDescriptionBenefitsList>
        {benefits.map(benefit => (
          <CourseDescriptionBenefits key={benefit}>
            {benefit}
          </CourseDescriptionBenefits>
        ))}
      </CourseDescriptionBenefitsList>
    </>
  );
};
