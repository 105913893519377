import React from 'react';
import { IconProps } from '../IconProps';

const SvgBriefcase = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g
      clipPath="url(#Briefcase_svg__clip0_929_17308)"
      stroke="#0D0300"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M13.923 4.77H2.077C1.482 4.77 1 5.252 1 5.846v7.539c0 .595.482 1.077 1.077 1.077h11.846c.595 0 1.077-.482 1.077-1.077V5.846c0-.594-.482-1.076-1.077-1.076zM1 9.078h14"
        strokeOpacity={0.7}
      />
      <path
        d="M8 8v2.154M11.231 4.77a3.23 3.23 0 0 0-6.461 0"
        strokeOpacity={0.95}
      />
    </g>
    <defs>
      <clipPath id="Briefcase_svg__clip0_929_17308">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBriefcase;
