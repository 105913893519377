import React from 'react';
import { IconProps } from '../IconProps';
const SvgCheckSquare = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      clipPath="url(#CheckSquare_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M20.6.9H3.4A2.6 2.6 0 001 3.4v17.2C.9 22 2 23 3.4 23h17.2c1.4 0 2.5-1.1 2.5-2.5V3.4C23.1 2 22 1 20.6 1"
        fill={primaryColor}
      />
      <path
        d="M17.8 7.1q.9.9.2 1.8l-6.9 8.6q-.8 1-1.7.2l-3.5-2.5a1.3 1.3 0 111.6-2l2.4 1.7L16 7.3q.8-.8 1.8-.2"
        fill={secondaryColor}
      />
    </g>
    <defs>
      <clipPath id="CheckSquare_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckSquare;
