import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Errors } from '../Shared/Errors';

export const SUBDOMAIN_DIVISIONS_QUERY = gql`
  query GetSubdomainDivisions($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      locations {
        id
        name
      }
      departments {
        id
        name
      }
    }
  }
`;

type SubdomainDivision = {
  id: string;
  name: string;
};

type SubdomainDivisions = {
  locations?: SubdomainDivision[];
  departments?: SubdomainDivision[];
};

type Response = {
  subDomain: SubdomainDivisions[];
};

type Variables = {
  subdomain: string;
};

export interface WithSubdomainDivisionsProps extends SubdomainDivisions {
  error?: Errors;
  loading: boolean;
}

const withSubdomainDivisions = graphql<
  Variables,
  Response,
  Variables,
  WithSubdomainDivisionsProps
>(SUBDOMAIN_DIVISIONS_QUERY, {
  options: ({ subdomain }) => ({
    variables: { subdomain },
  }),
  props: ({ data }) => {
    if (data && data.loading) {
      return {
        loading: true,
      };
    }

    if (data && data.subDomain) {
      return {
        loading: false,
        locations:
          Array.isArray(data.subDomain) && data.subDomain[0]
            ? data.subDomain[0].locations
            : undefined,
        departments:
          Array.isArray(data.subDomain) && data.subDomain[0]
            ? data.subDomain[0].departments
            : undefined,
      };
    }

    return { loading: false, error: Errors.ServerError };
  },
  skip: props => !props.subdomain,
});

export default withSubdomainDivisions;
