import { ProgressBar } from '@unmind/design-system-components-web/dist/components/ProgressBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CourseProgressProps {
  daysCompleted: number;
  totalDays: number;
}

export const CourseProgress = ({
  daysCompleted,
  totalDays,
}: CourseProgressProps) => {
  const { t: translate } = useTranslation('courses');

  const courseProgress = Math.round((daysCompleted / totalDays) * 100);

  return (
    <ProgressBar
      progress={courseProgress}
      label={translate('course_card.progress_indicator.text', {
        courseProgress: courseProgress,
      })}
    />
  );
};
