import { ApolloError, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation SendPasswordReset($email: String!, $groupId: String!) {
    sendPasswordReset(email: $email, groupId: $groupId)
  }
`;

export const REQUEST_PASSWORD_RESET_WITHOUT_ORG_ID_MUTATION = gql`
  mutation SendPasswordResetWithoutOrgId($email: String!) {
    sendPasswordResetWithoutOrgId(email: $email) {
      success
      code
    }
  }
`;

type ResetParams = {
  email: string;
  groupId?: string;
};

interface useRequestPasswordResetProps {
  onErrorCallback(error: string): void;
  subdomainless: boolean;
  onSuccessCallback(): void;
}

export const useRequestPasswordReset = ({
  onErrorCallback,
  subdomainless,
  onSuccessCallback,
}: useRequestPasswordResetProps) => {
  const MUTATION = subdomainless
    ? REQUEST_PASSWORD_RESET_WITHOUT_ORG_ID_MUTATION
    : REQUEST_PASSWORD_RESET_MUTATION;

  const [requestPasswordReset, { loading }] = useMutation<unknown, ResetParams>(
    MUTATION,
    {
      onError: (err: ApolloError) => {
        const error =
          err.graphQLErrors.length > 0 ? err.graphQLErrors[0].name : '';
        onErrorCallback(error);
      },
      onCompleted: onSuccessCallback,
    },
  );

  return {
    requestPasswordReset,
    loading,
  };
};
