import { useMutation } from '@apollo/client';
import { Alert } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { logException } from 'App/logging';
import {
  matchToPractitionersMutation as matchToPractitionersPayload,
  matchToPractitionersMutationVariables as matchToPractitionersVariables,
} from 'Services/BeGateway/Practitioner/__generated__/matchToPractitionersMutation';
import {
  MATCH_TO_PRACTITIONERS_MUTATION,
  PRACTITIONER_MATCHES_QUERY,
} from 'Services/BeGateway/Practitioner/practitioner.services';
import { FullScreenTakeover } from 'Shared/FullScreenTakeover';
import {
  COUNTRIES_REQUIRING_STATES,
  useLocationData,
} from 'Talk/hooks/useLocationData';
import { Namespace } from 'i18next';
import React, { useReducer, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BEGatewayQueryContext } from 'utils/apollo';
import { UPDATE_USER_MUTATION } from '../../../../Account/withUpdateUser';
import { UserLocationInput } from '../../SetLocation/SetLocationForm';
import {
  MatchingQuestionnaire,
  createMatchingQuestionKeys,
} from './Questionnaire';
import {
  INITIAL_STATE,
  MatchingQuestionKeys,
  MatchingStateResponse,
  responseReducer,
} from './state';
import {
  AlertContainer,
  BackButton,
  BackIcon,
  BackText,
  CloseButton,
  CloseIcon,
  DisclaimerTextContainer,
  QuestionCard,
  QuestionnaireContainer,
  QuestionnaireHeader,
} from './styles';

export interface Props {
  showTakeover: boolean;
  onClose(): void;
}

export function MatchingQuestionnaireTakeover({
  showTakeover = false,
  onClose,
}: Props) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [languageCount, setLanguageCount] = useState(1);
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const { userLocationData, hasValidLocationData } = useLocationData();
  const [matchingQuestionState, setMatchingQuestionState] = useReducer(
    responseReducer,
    INITIAL_STATE,
  );

  useEffect(() => {
    if (hasValidLocationData) {
      setMatchingQuestionState({
        type: MatchingStateResponse[MatchingQuestionKeys.where_location],
        payload: {
          country: {
            value: userLocationData?.user?.country?.value ?? '',
            label: userLocationData?.user?.country?.value ?? '',
          },
          region: {
            value: userLocationData?.user?.state?.value ?? '',
            label: userLocationData?.user?.state?.value ?? '',
          },
        },
      });
    }
  }, [userLocationData, hasValidLocationData]);
  const { t } = useTranslation<Namespace<'talk' | 'shared'>>([
    'talk',
    'shared',
  ]);
  const { questionResponses } = matchingQuestionState;
  const [updateUserLocation, { loading: updateUserMutationInFlight }] =
    useMutation(UPDATE_USER_MUTATION);

  const [submitMatchingQuestionnaire, { loading: submitMutationInFlight }] =
    useMutation<matchToPractitionersPayload, matchToPractitionersVariables>(
      MATCH_TO_PRACTITIONERS_MUTATION,
      {
        refetchQueries: [PRACTITIONER_MATCHES_QUERY],
        ...BEGatewayQueryContext,
      },
    );

  const handleQuestionnaireSubmit = async () => {
    const userCountry =
      questionResponses[MatchingQuestionKeys.where_location].country.value;
    const userRegion =
      questionResponses[MatchingQuestionKeys.where_location].region.value;

    const matchingInput = {
      sessionContext: questionResponses[MatchingQuestionKeys.sessions_for],
      sessionPreferences: questionResponses[
        MatchingQuestionKeys.sessions_important
      ] as string[],
      location: {
        country: userCountry,
        region: COUNTRIES_REQUIRING_STATES.includes(userCountry)
          ? userRegion
          : null,
      },
      languages: questionResponses[MatchingQuestionKeys.what_language].map(
        l => l.value,
      ),
    };

    try {
      let userLocationInput: UserLocationInput = {
        country: userCountry,
        state: null,
      };
      if (COUNTRIES_REQUIRING_STATES.includes(userCountry)) {
        userLocationInput = {
          ...userLocationInput,
          state: matchingInput?.location?.region ?? null,
        };
      }

      await updateUserLocation({
        variables: {
          userInput: userLocationInput,
        },
      });
      await submitMatchingQuestionnaire({
        variables: { input: matchingInput },
      });

      setFormSubmissionError(false);
      setMatchingQuestionState({
        type: MatchingStateResponse.reset_matching,
      });

      tracking.track('talk-matching-completed');

      onClose();
    } catch (e) {
      setFormSubmissionError(true);
      logException(e);
    }
  };

  const handleQuestionnaireCloseClick = () => {
    const questionKeys = createMatchingQuestionKeys();

    tracking.track('talk-matching-abandoned', {
      currentQuestionKey: questionKeys[currentQuestion],
    });
    onClose();
    setCurrentQuestion(0);
    setLanguageCount(1);
    setMatchingQuestionState({
      type: MatchingStateResponse.reset_matching,
    });
  };

  return (
    <FullScreenTakeover shown={showTakeover} scrollable allowForOverlay={true}>
      <QuestionnaireContainer>
        <QuestionnaireHeader>
          {currentQuestion >= 1 && (
            <BackButton onClick={() => setCurrentQuestion(currentQuestion - 1)}>
              <BackIcon />
              <BackText>
                {t('talk:matching.questionnaire.previous_button.label')}
              </BackText>
            </BackButton>
          )}

          <CloseButton
            onClick={handleQuestionnaireCloseClick}
            aria-label={t(
              'talk:matching.questionnaire.close_button.a11y_label',
            )}
          >
            <CloseIcon />
          </CloseButton>
        </QuestionnaireHeader>
        <QuestionCard>
          <MatchingQuestionnaire
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            questionResponses={questionResponses}
            setMatchingQuestionState={setMatchingQuestionState}
            addedLanguagesCount={languageCount}
            setLanguageCount={setLanguageCount}
            onQuestionnaireSubmit={handleQuestionnaireSubmit}
            questionnaireSubmissionInFlight={
              submitMutationInFlight || updateUserMutationInFlight
            }
          />
          {formSubmissionError ? (
            <AlertContainer>
              <Alert
                variant="error"
                size="small"
                title={t('shared:errors.messages.something_wrong')}
              />
            </AlertContainer>
          ) : null}
        </QuestionCard>
        <DisclaimerTextContainer>
          {t('talk:matching.questionnaire.disclaimer')}
        </DisclaimerTextContainer>
      </QuestionnaireContainer>
    </FullScreenTakeover>
  );
}
