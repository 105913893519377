import React from 'react';
import { IconProps } from '../IconProps';
const SvgPlus = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 28 29" fill="none" {...props}>
    <path
      d="M0 14.79c0-7.731 6.268-14 14-14s14 6.269 14 14c0 7.733-6.268 14-14 14s-14-6.267-14-14z"
      fill={secondaryColor}
      fillOpacity={0.05}
    />
    <path
      d="M19 9.79l-10 10M9 9.79l10 10"
      stroke="#0D0300"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={primaryColor}
    />
  </svg>
);
export default SvgPlus;
