import { logException } from '../../App/logging';

type PresignedUrlResponseBody = {
  s3UploadUrl: string;
};

type AuthHeader =
  | {
      'x-iris-token': string;
    }
  | {
      'x-api-authorization': string;
    }
  | Record<string, never>;

const createAuthHeader = (
  irisToken: string | undefined,
  auth0JWT: string | undefined,
): AuthHeader => {
  if (irisToken) {
    return { 'x-iris-token': irisToken };
  } else if (auth0JWT) {
    return { 'x-api-authorization': `Bearer ${auth0JWT}` };
  }

  return {};
};

const getPresignedUrl = async (
  irisToken: string | undefined,
  auth0JWT: string | undefined,
) => {
  const authHeader = createAuthHeader(irisToken, auth0JWT);

  return fetch(
    `${process.env.REACT_APP_IRIS_BASE_URL}line-managers-generate-upload-url`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader,
      },
    },
  ).then(
    async (response): Promise<PresignedUrlResponseBody> => response.json(),
  );
};

export const uploadLineManagersFileToS3 = async (
  file: File,
  irisToken: string | undefined,
  auth0JWT: string | undefined,
) =>
  getPresignedUrl(irisToken, auth0JWT)
    .then(async ({ s3UploadUrl }) =>
      fetch(s3UploadUrl, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'text/csv',
        },
        body: file,
      }),
    )
    .catch(logException);

export const sendLineManagersFileForValidation = async (
  file: File,
  irisToken: string | undefined,
  auth0JWT: string | undefined,
) => {
  const authHeader = createAuthHeader(irisToken, auth0JWT);

  const headers = {
    'Content-Type': 'text/csv',
    Accept: 'application/json',
    ...authHeader,
  } as { [key: string]: string };

  return fetch(`${process.env.REACT_APP_IRIS_BASE_URL}validate-line-managers`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers,
    body: file,
  })
    .then(async response => response.json())
    .catch(logException);
};
