import gql from 'graphql-tag';
import { Maybe } from 'graphql/jsutils/Maybe';
import { graphql } from '@apollo/client/react/hoc';
import { seriesSegmentMedia as WithSeriesSegmentMediaResponse } from './__generated__/seriesSegmentMedia';

interface InputProps {
  slug: string;
  id: string;
}

interface Variables {
  slug: string;
  id: string;
}

export interface CloudinaryMedia {
  path: Maybe<string | null>;
}

export interface WithSeriesSegmentMediaChildProps {
  mediaLoading: boolean;
  segmentTitle: string | null;
  seriesTitle: Maybe<string | null>;
  mediaSrc: Maybe<string | null>;
  cloudinaryMedia?: CloudinaryMedia | null;
  assetToken: string;
}

export const withSeriesSegmentMediaQuery = gql`
  query seriesSegmentMedia($slug: String!, $id: String!) {
    singleSeries(slug: $slug) {
      id
      title
    }
    seriesSegment(id: $id) {
      title
      mediaSrc {
        stringValue
      }
      cloudinaryMedia {
        path
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;

export const withSeriesSegmentMedia = graphql<
  InputProps,
  WithSeriesSegmentMediaResponse,
  Variables,
  WithSeriesSegmentMediaChildProps
>(withSeriesSegmentMediaQuery, {
  options: ({ slug, id }) => ({
    variables: { slug, id },
  }),
  props: ({ data }) => {
    const seriesSegment =
      data && data.seriesSegment
        ? data.seriesSegment
        : {
            title: '',
            mediaSrc: { stringValue: '' },
            cloudinaryMedia: { path: '' },
          };

    return {
      mediaLoading: data !== undefined ? data.loading : true,
      seriesTitle: data && data.singleSeries ? data.singleSeries.title : '',
      segmentTitle: seriesSegment.title,
      mediaSrc: seriesSegment.mediaSrc?.stringValue,
      cloudinaryMedia: {
        path: seriesSegment.cloudinaryMedia?.path,
      },
      assetToken: data?.getAssetToken?.assetToken || '',
    };
  },
  skip: ({ id }) => Boolean(!id),
});
