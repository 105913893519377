import React, { useEffect } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { tracking } from 'App/Tracking';
import { ActionTrackingData } from '../../constants';

const MarkdownContainer = styled.span`
  p {
    font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16};
    line-height: 140%;
  }

  li {
    line-height: 140%;
  }

  & > strong {
    font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  }

  a {
    color: ${({ theme }) => theme.colors.text.primary};
    text-decoration: underline;
  }

  & a:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  ol {
    padding-left: ${rem(24)};
  }

  ul {
    padding-left: ${rem(16)};
    list-style-type: disc;
  }
`;

type MarkdownContainerProps = {
  htmlContent: string;
  actionTrackingData: ActionTrackingData;
};

const onLinkClick = (link: string, actionData: ActionTrackingData) => {
  tracking.track('compass-action-link-clicked', {
    ...actionData,
    path: link,
  });
};

const handleActionsContentClick = (
  event: React.MouseEvent,
  actionData: ActionTrackingData,
) => {
  if (event.target instanceof HTMLAnchorElement) {
    if (onLinkClick) {
      onLinkClick(event.target?.href, actionData);
    }
  }
};

export const ActionsMarkdownContainer = ({
  htmlContent,
  actionTrackingData,
}: MarkdownContainerProps) => {
  useEffect(() => {
    const links = document.querySelectorAll(`${MarkdownContainer} a`);
    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });
  }, []);

  return (
    <MarkdownContainer
      dangerouslySetInnerHTML={{
        __html: htmlContent,
      }}
      onClick={e => handleActionsContentClick(e, actionTrackingData)}
    ></MarkdownContainer>
  );
};
