import React, { useContext } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation, Trans } from 'react-i18next';
import { SalaryContext } from 'Compass/Components/CompassDashboard/SalaryProvider';
import RoutePath from 'App/RoutePath';
import { useRiskQuestionDetail } from 'Compass/api';
import {
  getRiskQuestionDetail_riskQuestionDetail_responseDistributions as RiskResponseDistribution,
  getRiskQuestionDetail_riskQuestionDetail_bottom2PercentageBenchmark as RiskBenchmarkResult,
} from 'Compass/api/__generated__/getRiskQuestionDetail';
import { BURNOUT_QUESTION_ID } from 'Compass/Components/CompassDashboard/constants';
import { tracking } from 'App/Tracking';
import { Score, CardHeader } from '../styles';
import { StatusBar } from '../ScoreWithColourStatus';
import { getOverallCost } from '../../utils/getOverallCost';
import { getAverageSalaryDisplay } from '../../utils/averageSalaryHelpers';
import { RecommendedActionsAccordion } from '../RecommendedActionsAccordion';
import { CardTitle, CardText, CardLink, ChangeSalaryButton } from './styles';
import { SelectedCard } from './SelectedCard';
import {
  DistributionType,
  RelatedQuestion,
  RelatedQuestionsSection,
} from './RelatedQuestionsSection';
import { LoadingSelectedCard } from './LoadingSelectedCard';

interface RiskSelectedCardProps {
  riskQuestionId: string;
  indexScheduleId: string;
  closeCard(): void;
  openAverageSalarySelectedCard(): void;
}

const RiskHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > ${CardTitle} {
    margin-right: ${rem(16)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cost = styled(CardText).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.semiBold,
}))`
  margin-top: 0;
  margin-right: ${rem(4)};
  display: contents;
`;

const TextContainer = styled(CardText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  display: contents;
`;

const InformationContainer = styled.div`
  & > ${CardHeader}:not(:first-child) {
    margin-top: ${rem(32)};
  }
`;

const RelatedQuestionContainer = styled.div`
  padding-top: ${rem(32)};
`;

const DescriptionContainer = styled.div``;

interface OverviewProps {
  score?: number;
  description?: string;
}

const Overview = ({ score, description }: OverviewProps) => {
  if (!score || !description) {
    return null;
  }

  return (
    <Container data-testid={'risk-selected-card-overview'}>
      <Score large>{score}%</Score>
      <CardText>{description}</CardText>
    </Container>
  );
};

const Definition = ({ definitionHtml }: { definitionHtml?: string }) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.cards.risks',
  });

  return (
    <DescriptionContainer data-testid={'risk-selected-card-definition'}>
      <CardHeader>{translate('definition_header')}</CardHeader>
      {definitionHtml && (
        <CardText dangerouslySetInnerHTML={{ __html: definitionHtml }} />
      )}
    </DescriptionContainer>
  );
};

interface InformationProps {
  questionText?: string;
  questionId?: string;
  overallCost?: number | null;
  responseDistributions?: RiskResponseDistribution[] | null;
  bottom2PercentageBenchmark?: RiskBenchmarkResult | null;
  openAverageSalarySelectedCard(): void;
  scheduleId: string;
}

const Information = ({
  openAverageSalarySelectedCard,
  questionId,
  questionText,
  overallCost,
  responseDistributions,
  bottom2PercentageBenchmark,
  scheduleId,
}: InformationProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.cards.risks',
  });
  const { averageSalary, currency } = useContext(SalaryContext);

  if (!responseDistributions || !bottom2PercentageBenchmark || !questionText) {
    return null;
  }

  const relatedQuestions: RelatedQuestion[] = [
    {
      questionText: questionText,
      responseDistributions: (responseDistributions || []).map(
        ({ label, responses, responseOptionId }) => ({
          label,
          responseCount: responses,
          responseOptionId: responseOptionId ?? '',
        }),
      ),
      distributionType:
        responseDistributions.filter(
          response => response.responseNumValue === null,
        ).length > 0
          ? DistributionType.RATING
          : DistributionType.PERCENT,
    },
  ];

  return (
    <InformationContainer data-testid={'risk-selected-card-information'}>
      {questionId !== BURNOUT_QUESTION_ID && (
        <>
          <CardHeader>{translate('potential_costs.header')}</CardHeader>
          <Trans
            t={translate}
            values={{
              salary: getAverageSalaryDisplay(averageSalary, currency),
              cost: getOverallCost(overallCost || 0, currency, averageSalary),
            }}
            i18nKey={`potential_costs.text`}
            components={{
              container: <TextContainer />,
              learn_more_link: <CardLink to={RoutePath.CompassCalculator} />,
              change_salary_link: (
                <ChangeSalaryButton
                  role="button"
                  onClick={openAverageSalarySelectedCard}
                />
              ),
              cost: <Cost />,
            }}
          />
        </>
      )}
      <>
        <CardHeader>{translate('benchmark.header')}</CardHeader>
        <Trans
          t={translate}
          i18nKey={`benchmark.text`}
          values={{
            benchmark: `${
              bottom2PercentageBenchmark.difference > 0 ? '+' : ''
            }${bottom2PercentageBenchmark.difference}`,
          }}
          components={{
            container: <TextContainer />,
            cost: <Cost />,
            learn_more_link: (
              <CardLink
                to={RoutePath.CompassBenchmarking}
                onClick={() =>
                  tracking.track('compass-schedule-link-clicked', {
                    path: RoutePath.CompassBenchmarking,
                    scheduleId,
                  })
                }
              />
            ),
          }}
        />
      </>
      <RelatedQuestionContainer>
        <RelatedQuestionsSection relatedQuestions={relatedQuestions} />
      </RelatedQuestionContainer>
    </InformationContainer>
  );
};

export const RiskSelectedCard = ({
  riskQuestionId,
  indexScheduleId,
  closeCard,
  openAverageSalarySelectedCard,
}: RiskSelectedCardProps) => {
  const { t: translate } = useTranslation('compass', {
    keyPrefix: 'dashboard.cards.risks',
  });
  const { data: riskQuestionDetailData, loading: riskQuestionDetailLoading } =
    useRiskQuestionDetail({
      questionId: riskQuestionId,
      indexScheduleId,
    });

  if (riskQuestionDetailLoading) {
    return <LoadingSelectedCard closeCard={closeCard} />;
  }

  const {
    title,
    riskCategory,
    bottom2Percentage,
    description,
    id: questionId,
    overallCost,
    questionText,
    responseDistributions,
    bottom2PercentageBenchmark,
    definition,
  } = riskQuestionDetailData?.riskQuestionDetail || {};

  const actions =
    riskQuestionDetailData?.riskQuestionDetail?.recommendedActions || [];

  return (
    <SelectedCard
      closeCard={closeCard}
      header={
        <RiskHeader data-testid={'risk-selected-card-header'}>
          <CardTitle>{title}</CardTitle>
          {riskCategory && <StatusBar riskCategory={riskCategory} />}
        </RiskHeader>
      }
      content={
        <>
          <Overview score={bottom2Percentage} description={description} />
          <Information
            scheduleId={indexScheduleId}
            questionId={questionId}
            overallCost={overallCost}
            questionText={questionText}
            bottom2PercentageBenchmark={bottom2PercentageBenchmark}
            responseDistributions={responseDistributions}
            openAverageSalarySelectedCard={openAverageSalarySelectedCard}
          />
          <Definition definitionHtml={definition} />
          {actions && actions.length > 0 && (
            <RecommendedActionsAccordion
              recommendedActions={actions}
              title={translate('accordion_title', {
                title: title,
              })}
              scheduleId={indexScheduleId}
            />
          )}
        </>
      }
    />
  );
};
