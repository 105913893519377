import { rem } from 'polished';
import React from 'react';

import { styled, ThemeInterface } from 'styles';
import { ScreenReaderContent } from '../Accessibility';
import Loader from '../Loader';
import { ID_LOADING } from '../LoadingIndicator';

const StyledLoader = styled(Loader).attrs(
  ({
    theme,
    isAudioPlayer,
  }: {
    theme: ThemeInterface;
    isAudioPlayer?: boolean;
  }) => ({
    color: isAudioPlayer
      ? theme.colors.mediaPlayer.audio.icon.default
      : theme.colors.staticPalette.white,
  }),
)``;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(52)};
`;

export const MediaLoadingIndicator = ({
  isAudioPlayer,
}: {
  isAudioPlayer?: boolean;
}) => (
  <Container data-testid={ID_LOADING}>
    <StyledLoader isAudioPlayer={isAudioPlayer} />
    <ScreenReaderContent as="span">Loading</ScreenReaderContent>
  </Container>
);
