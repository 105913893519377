import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { SeriesSegmentType } from '../../types';
export interface SeriesInputProps {
  id: string;
}

export interface SeriesVariables {
  id: string;
}

interface WithSeriesDayResponse {
  seriesDay: {
    id: string;
    segments: SeriesSegmentResponse[];
    title: string;
    hasReminder: boolean;
  };
}

export interface SeriesDay {
  id: string;
  segments: SeriesSegment[];
  title: string;
  hasReminder: boolean;
}

interface SeriesSegment {
  durationSeconds?: number;
  icon: string;
  id: string;
  mediaType: string;
  orderNo: number;
  title: string;
  html?: string;
  introHTML: string | null;
  bodyHTML?: string | null;
  primarySegmentType: SeriesSegmentType;
}

export interface SeriesSegmentResponse {
  id: string;
  title: string;
  html?: string;
  introHTML: string | null;
  bodyHTML?: string | null;
  orderNo: number;
  durationSeconds?: {
    numericValue: number;
  };
  icon: {
    stringValue: string;
  };
  mediaType: {
    stringValue: string;
  };
  mediaSrc: {
    stringValue: string;
  };
}

export interface WithSeriesDayChildProps {
  seriesDayLoading?: boolean;
  seriesDay?: SeriesDay;
}

export const lookupSegmentType = (segmentType: string): SeriesSegmentType =>
  // tslint:disable-next-line: prefer-type-cast
  SeriesSegmentType[segmentType as keyof typeof SeriesSegmentType];

export const withSeriesDayQuery = gql`
  query SeriesDay($id: String!) {
    seriesDay(id: $id) {
      id
      title
      hasReminder
      segments {
        id
        title
        html
        introHTML
        orderNo
        durationSeconds {
          numericValue
        }
        icon {
          stringValue
        }
        mediaType {
          stringValue
        }
        mediaSrc {
          stringValue
        }
        ... on SeriesSegmentText {
          bodyHTML
        }
      }
    }
  }
`;

export const withSeriesDay = graphql<
  SeriesInputProps,
  WithSeriesDayResponse,
  SeriesVariables,
  WithSeriesDayChildProps
>(withSeriesDayQuery, {
  options: ({ id }) => ({
    variables: { id },
  }),
  props: ({ data }) => {
    const loading = get(data, 'loading', true);

    if (data && data.seriesDay) {
      const seriesDaySegments = data.seriesDay.segments
        .map(segment => ({
          durationSeconds:
            segment.durationSeconds !== undefined
              ? segment.durationSeconds.numericValue
              : undefined,
          icon: segment.icon.stringValue,
          id: segment.id,
          mediaType: segment.mediaType.stringValue,
          orderNo: segment.orderNo,
          title: segment.title,
          html: segment.html,
          introHTML: segment.introHTML,
          bodyHTML: segment.bodyHTML,
          primarySegmentType: lookupSegmentType(segment.mediaType.stringValue),
        }))
        .sort((a, b) => a.orderNo - b.orderNo);

      const seriesDayToReturn = {
        id: data.seriesDay.id,
        segments: seriesDaySegments,
        title: data.seriesDay.title,
        hasReminder: data.seriesDay.hasReminder,
      };

      return {
        seriesDay: seriesDayToReturn,
        seriesDayLoading: loading,
      };
    }

    return {
      seriesDayLoading: loading,
    };
  },
  skip: props => !props.id,
});
