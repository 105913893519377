import { LDContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { getUserLocale } from '../i18n/getUserLocale';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import { logException } from '../App/logging';
import { FeatureFlagUserAttrs, featureFlagUserContextActions } from './types';

export const LAUNCH_DARKLY_USER_KEY = 'launch-darkly-user';
/*
  The Launch Darkly SDK is slightly different between React and React Native.
  In the React Native SDK, a key is required for both anonymous and non-anonymous users.
  In the React SDK, the key can be omitted when anonymous is true.
*/

/*
  Note: The hook below and the HOC withFeatureFlagUserContext currently implement the
  same functionality. This is so that the containing functionality can be used in other
  components without adding the complexity of passing down props into it.

  Future preference is to use this hook over the HOC unless not possible to do so.
*/

const useFeatureFlagUserContext = (): featureFlagUserContextActions => {
  const client = useLDClient();

  if (!client) {
    console.warn('LDClient not available');

    return {
      identifyFeatureFlagUser: undefined,
      anonymizeFeatureFlagUser: undefined,
    };
  }

  const anonymizeFeatureFlagUser = async () => {
    localStorage.removeItem(LAUNCH_DARKLY_USER_KEY);
    const subdomain = getSubdomainFromUrl();
    const teamsContext = getMSTeamsUserContext();
    const teamsTenantId = isMSTeams() ? teamsContext?.user?.tenant?.id : null;
    const userData: LDContext = {
      kind: 'user',
      anonymous: true,
      subdomain,
      ...(teamsTenantId && { tenantId: teamsTenantId }),
    };

    localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));

    try {
      await client.waitForInitialization();
      // In client-side LaunchDarkly SDKs, the identify feature allows you to change
      // user context as they are configured to operate for one user at a time
      await client.identify(userData); // returns promise resolving to null
    } catch (e) {
      logException(e);
    }
  };

  const identifyFeatureFlagUser = async ({
    subdomain,
    userId,
    userTraits = [''],
    tenantId,
  }: FeatureFlagUserAttrs) => {
    const locale = getUserLocale();

    const customAttrs = {
      subdomain,
      userTraits,
      locale,
      tenantId,
    };

    const userData: LDContext = {
      kind: 'user',
      anonymous: false,
      key: userId,
      ...customAttrs,
    };

    localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));

    try {
      await client.waitForInitialization();
      await client.identify(userData); // returns promise resolving to null
    } catch (e) {
      logException(e);
    }
  };

  return {
    identifyFeatureFlagUser,
    anonymizeFeatureFlagUser,
  };
};

export default useFeatureFlagUserContext;
