import React from 'react';
import { IconProps } from '../IconProps';

const SvgCourseCompleteTick = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" height="1em" viewBox="0 0 10 10" width="1em" {...props}>
    <circle cx={5} cy={5} fill="#32C283" r={5} />
    <path
      d="M7.5 4L4.179 6.884a.488.488 0 0 1-.32.116.488.488 0 0 1-.32-.116L2.5 5.977"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgCourseCompleteTick;
