import { Form, Formik, FormikActions } from 'formik';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { rem } from 'polished';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { Info } from 'icons';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useSubdomainInfo } from '../../LoggedOut/SignUp/useSubdomainInfo';
import { tracking } from '../../App/Tracking';
import {
  ChildProps as SubdomainFromUrlProps,
  withSubdomainFromUrl,
} from '../../LoggedOut/withSubdomainFromUrl';
import { LocalGeneralError } from '../../Shared/AlertView';
import { Errors } from '../../Shared/Errors';
import { AlertBox, InputSize } from '../../Shared/Form';
import {
  FormikInput,
  FormikSelect,
  validateEmail,
  validateFieldNotEmpty,
} from '../../Shared/Form/Formik';
import Label from '../../Shared/Form/Label';
import { SelectSize } from '../../Shared/Form/Select';
import { Grid, GridItem } from '../../Shared/Grid';
import LoadingIndicator, {
  withLoadingIndicator,
} from '../../Shared/LoadingIndicator';
import PrimaryButton from '../../Shared/PrimaryButton';
import { ButtonSize } from '../../Shared/Button';
import Tooltip, { useTooltipState } from '../../Shared/Tooltip';
import { isEmployee, isPlusOne, small } from '../../utils';
import withSubdomainDivisions, {
  WithSubdomainDivisionsProps,
} from '../withSubdomainDivisions';
import withUpdateUser from '../withUpdateUser';
import withUser, { WithUserChildProps } from '../withUser';
import { withUserType, WithUserTypeProps } from '../withUserType';
import { changeUserDetails } from './helper';

const StyledGrid = styled(Grid)`
  padding: 0;
`;

const FormField = styled.div`
  margin-bottom: ${rem(24)};
  width: 100%;
`;

const FieldLabel = styled(Label)`
  display: inline-flex;
  margin-bottom: ${rem(8)};
`;

const FieldLabelWrapper = styled.div`
  display: inline-flex;
`;

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  ${small(css`
    width: auto;
  `)}
`;

const AlertGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SectionSeparator = styled.div`
  ${small(css`
    margin-bottom: ${rem(12)}};
  `)}
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
  secondaryColor: theme.colors.text.secondary,
}))`
  display: flex;
  width: ${rem(20)};
  height: ${rem(20)};
  margin-left: ${rem(5)};
  margin-top: ${rem(9)};
`;

const TooltipContainer = styled.div`
  display: inline-flex;
`;

const AlertContainer = styled(AlertBox)`
  margin-bottom: ${rem(24)};
`;

const trackTooltip = debounce(() => {
  tracking.track('settings-plus-one-email-tooltip-clicked');
}, 1000);

export interface ProfileProps
  extends Omit<WithUserChildProps, 'loading'>,
    Pick<WithUserTypeProps, 'userType'>,
    Pick<WithSubdomainDivisionsProps, 'locations' | 'departments'>,
    SubdomainFromUrlProps {
  userId: string;
  confirmEmailUpdateSuccess: boolean;
  confirmEmailUpdateError: boolean;
  updateUser({
    firstName,
    lastName,
    emailAddress,
    praiseDirectory,
    locationId,
    departmentId,
  }: {
    firstName?: string;
    lastName?: string;
    praiseDirectory?: boolean;
    emailAddress?: string;
    locationId?: string;
    departmentId?: string;
  }): Promise<{
    success: boolean;
    error?: Errors;
  }>;
}

interface ProfileOutProps
  extends Omit<WithUserChildProps, 'loading'>,
    Pick<WithSubdomainDivisionsProps, 'locations' | 'departments'> {
  confirmEmailUpdateSuccess: boolean;
  confirmEmailUpdateError: boolean;
}

export interface FormFields {
  [property: string]: boolean | string | undefined;
  firstName: string;
  lastName: string;
  praiseDirectory?: boolean;
  emailAddress: string;
  initialEmailAddress: string;
  location?: string;
  department?: string;
}

export const Profile: React.FC<ProfileProps> = ({
  error: loadingError,
  firstName = '',
  lastName = '',
  email = '',
  praiseDirectory = true,
  locationId,
  departmentId,
  confirmEmailUpdateSuccess,
  confirmEmailUpdateError,
  locations,
  departments,
  userType,
  updateUser,
  subdomain,
}) => {
  const formikRef = useRef<Formik<FormFields>>(null);
  const { t: translate } = useTranslation('account');

  const [message, setMessage] = useState(
    translate('profile.messages.default_success'),
  );
  const { open: tooltipOpen, handlers: tooltipHandlers } = useTooltipState();

  //for now all users authenticated via auth0 are SSO users, we will revist this after auth0 migration work
  const { isAuthenticated: isAuthenticatedViaAuth0, isLoading: loadingAuth0 } =
    useAuth0();
  const { groupFileFields, loading: loadingSubdomainInfo } = useSubdomainInfo({
    subdomain,
  });

  useEffect(() => {
    if (confirmEmailUpdateSuccess) {
      tracking.track('email-reset-confirmed');
      setMessage(translate('profile.messages.confirm_email_update_success'));
    }

    if (confirmEmailUpdateError) {
      setMessage(translate('profile.messages.confirm_email_update_error'));
    }
  }, []);

  if (Boolean(loadingError)) {
    return <LocalGeneralError />;
  }

  if (loadingSubdomainInfo || loadingAuth0) {
    return <LoadingIndicator />;
  }

  let isEmailOrganisation = true;

  if (Array.isArray(groupFileFields) && groupFileFields.length > 0) {
    isEmailOrganisation = groupFileFields.some(
      groupFileField => groupFileField?.fileMappingField === 'email',
    );
  }

  const isPlusOneUser = isPlusOne(userType);

  const initialValues: FormFields = {
    firstName,
    lastName,
    emailAddress: email,
    initialEmailAddress: email,
    praiseDirectory,
    ...(locationId ? { location: locationId } : undefined),
    ...(departmentId ? { department: departmentId } : undefined),
  };

  const onSubmit = async (
    formFields: FormFields,
    actions: Pick<
      FormikActions<FormFields>,
      'setStatus' | 'setSubmitting' | 'resetForm'
    >,
  ) => {
    actions.setSubmitting(true);
    actions.setStatus('');

    const response = await changeUserDetails({
      updateUser,
      ...formFields,
      praiseDirectory: isPlusOneUser ? undefined : formFields.praiseDirectory,
    });
    actions.resetForm(formFields);

    if (!response.success) {
      actions.setStatus('failed');
    } else {
      if (response.emailAddressUpdate) {
        tracking.track('email-reset-request');
      }

      tracking.track('settings-changes-saved', {
        settingsField: uniq([
          ...Object.entries(initialValues).reduce<string[]>(
            (accumulator, [key, value]) =>
              formFields[key] === value ? accumulator : [...accumulator, key],
            [],
          ),
          ...Object.entries(formFields).reduce<string[]>(
            (accumulator, [key, value]) =>
              initialValues[key] === value
                ? accumulator
                : [...accumulator, key],
            [],
          ),
        ]),
      });

      actions.setStatus('success');
    }

    const getUpdateUserDetailsMessage = () => {
      if (!response.success) {
        return translate('profile.messages.failed');
      }

      return response.emailAddressUpdate
        ? translate('profile.messages.email_update_success', {
            email: formFields.emailAddress,
          })
        : translate('profile.messages.default_success');
    };

    setMessage(getUpdateUserDetailsMessage());
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      ref={formikRef}
      validateOnBlur
    >
      {({ dirty, isSubmitting, isValid, status, values }) => (
        <Form>
          <StyledGrid>
            <AlertGridItem fullWidth>
              {message ===
                translate('profile.messages.confirm_email_update_success') ||
              status === 'success' ? (
                <AlertContainer alertType="success" message={message} />
              ) : null}
              {message ===
                translate('profile.messages.confirm_email_update_error') ||
              status === 'failed' ? (
                <AlertContainer alertType="failed" message={message} />
              ) : null}
            </AlertGridItem>
            <GridItem sm={4} md={4} lg={6}>
              <FormField>
                <FieldLabel htmlFor="firstName">
                  {translate('profile.first_name_input_label')}
                </FieldLabel>
                <FormikInput
                  id="firstName"
                  inputSize={InputSize.regular}
                  name="firstName"
                  value={values.firstName}
                  validate={e =>
                    validateFieldNotEmpty(
                      e,
                      translate('profile.validation.first_name'),
                    )
                  }
                />
              </FormField>
            </GridItem>
            <GridItem sm={4} md={4} lg={6}>
              <FormField>
                <FieldLabel htmlFor="lastName">
                  {translate('profile.last_name_input_label')}
                </FieldLabel>
                <FormikInput
                  id="lastName"
                  inputSize={InputSize.regular}
                  name="lastName"
                  value={values.lastName}
                  validate={e =>
                    validateFieldNotEmpty(
                      e,
                      translate('profile.validation.last_name'),
                    )
                  }
                />
              </FormField>
            </GridItem>

            <GridItem sm={4} md={4} lg={6}>
              <FormField>
                {isPlusOneUser ? (
                  <TooltipContainer {...tooltipHandlers}>
                    <FieldLabelWrapper>
                      <FieldLabel htmlFor="emailAddress">
                        {translate('profile.email_input_label')}
                      </FieldLabel>
                    </FieldLabelWrapper>

                    <Tooltip
                      labelCopy={translate(
                        'profile.plus_one_user.email_tooltip',
                      )}
                      placement="right"
                      open={tooltipOpen}
                      accessibilityId="disabled-plusone-email-tooltip"
                      data-testid="settings-plusone-email-tooltip"
                      onMouseEnter={() => {
                        trackTooltip();
                        tooltipHandlers.onMouseEnter();
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </TooltipContainer>
                ) : (
                  <FieldLabel htmlFor="emailAddress">
                    {translate('profile.email_input_label')}
                  </FieldLabel>
                )}

                <FormikInput
                  disabled={
                    isPlusOneUser ||
                    (isAuthenticatedViaAuth0 && isEmailOrganisation)
                  }
                  id="emailAddress"
                  inputSize={InputSize.regular}
                  name="emailAddress"
                  type="email"
                  value={values.emailAddress}
                  validate={value =>
                    validateEmail(value, translate('profile.validation.email'))
                  }
                />
              </FormField>
            </GridItem>

            {isEmployee(userType) ? (
              <Fragment>
                {locations && locations.length > 0 ? (
                  <GridItem sm={4} md={4} lg={6}>
                    <FormField>
                      <FormikSelect
                        name="location"
                        placeholder={translate('profile.location_select_label')}
                        options={locations.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                        size={SelectSize.Regular}
                        LabelComponent={a11yProps => (
                          <FieldLabel {...a11yProps}>
                            {translate('profile.location_select_label')}
                          </FieldLabel>
                        )}
                      />
                    </FormField>
                  </GridItem>
                ) : null}

                {departments && departments.length > 0 ? (
                  <GridItem sm={4} md={4} lg={6}>
                    <FormField>
                      <FormikSelect
                        name="department"
                        placeholder={translate(
                          'profile.department_select_placeholder',
                        )}
                        options={departments.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))}
                        size={SelectSize.Regular}
                        LabelComponent={a11yProps => (
                          <FieldLabel {...a11yProps}>
                            {translate('profile.department_select_label')}
                          </FieldLabel>
                        )}
                      />
                    </FormField>
                  </GridItem>
                ) : null}
              </Fragment>
            ) : (
              <GridItem fullWidth>
                <SectionSeparator />
              </GridItem>
            )}
            <GridItem fullWidth>
              <SubmitButton
                disabled={!dirty || !isValid}
                ariaLabel={translate('profile.save_button.a11y_label')}
                label={translate('profile.save_button.label')}
                loading={isSubmitting}
                size={ButtonSize.medium}
                type="submit"
              />
            </GridItem>
          </StyledGrid>
        </Form>
      )}
    </Formik>
  );
};

export default compose<ProfileProps, ProfileOutProps>(
  withSubdomainFromUrl,
  withSubdomainDivisions,
  withUser,
  withLoadingIndicator,
  withUpdateUser,
  withUserType,
)(Profile);
