import React from 'react';

const UpperGreyWave = ({ className }: { className?: string }) => (
  <svg
    className={className}
    preserveAspectRatio="xMaxYMax meet"
    fill="none"
    height="160"
    viewBox="0 0 320 160"
    width="320"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m320 142.836v-142.836h-247.5377c17.8347 34.7411 44.6347 60.244 84.9067 63.1172 68.901 4.9158 91.963 29.7342 111.186 50.4208 13.88 14.936 25.758 27.719 51.445 29.298z"
      fill="#d9d9d9"
      fillRule="evenodd"
    />
  </svg>
);

export default UpperGreyWave;
