import { MultiSelectOption } from '../../../Shared/Form/MultiSelect';
import { UserGroupFilterStateKey } from '../FilterState/types';

export const trackingFilterTypes: Record<UserGroupFilterStateKey, string> = {
  locationIds: 'location',
  departmentIds: 'department',
} as const;

export type DashboardFilterOptions = Record<
  UserGroupFilterStateKey,
  MultiSelectOption<string>[]
>;

export enum FilterSelectionMethod {
  USER_SELECTED_TAB = 'user-selected-tab',
  USER_SELECTED_FILTER = 'user-selected-filter',
  USER_SELECTED_TIME_PERIOD = 'user-selected-time-period',
  DEFAULT = 'default',
}

export type DropdownOptions = MultiSelectOption<string>[];
