import { Maybe } from 'graphql/jsutils/Maybe';
import { rem } from 'polished';
import React from 'react';
import { styled } from 'styles';
import Container from '../Shared/Container';
import { ServersideTrackingEvent } from '../App/Tracking/serverside';
import ContentHeader from '../Shared/ContentHeader/ContentHeader';
import ContentList from './ContentList';
import HelpDisclaimer from './HelpDisclaimer';
import { ContentItem } from './withHelpContentFolder';

export interface HelpContentProps {
  title: string;
  summary: string;
  description?: string;
  items: ContentItem[];
  onBack(): void;
  expandedItemId?: string;
  trackServerside?(
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ): Promise<void>;
  assetToken: Maybe<string>;
}

const Footer = styled('footer')`
  margin-top: ${rem(32)};
`;

export const HelpContent = ({
  items,
  onBack,
  title,
  summary,
  description,
  expandedItemId,
  trackServerside,
  assetToken,
}: HelpContentProps) => (
  <Container>
    <ContentHeader
      data-testid="help-content-header"
      title={title}
      summary={summary}
      description={description}
      onBackButtonClick={onBack}
    />
    <ContentList
      data-testid="help-content-list"
      contentListItems={items.map(item => ({ ...item, assetToken }))}
      expandedItemId={expandedItemId}
      trackServerside={trackServerside}
    />
    <Footer>
      <HelpDisclaimer />
    </Footer>
  </Container>
);

export default HelpContent;
