import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@unmind/design-system-components-web';
import { ErrorButtonWrapper } from './styles';

type ErrorProps = {
  title: string;
  body?: string;
  orientation?: 'vertical' | 'horizontal';
  onTryAgainPress?(): void;
};

function Error({ onTryAgainPress, title, body, orientation }: ErrorProps) {
  const { t: translate } = useTranslation('chatbot');

  return (
    <Alert
      variant="error"
      size="small"
      title={title}
      body={body}
      orientation={orientation}
    >
      {onTryAgainPress ? (
        <ErrorButtonWrapper>
          <Button
            label={translate(
              'conversation.conversation_errors.retry_button.label',
            )}
            aria-label={translate(
              'conversation.conversation_errors.retry_button.label',
            )}
            variant="secondary"
            size="small"
            onClick={onTryAgainPress}
          />
        </ErrorButtonWrapper>
      ) : null}
    </Alert>
  );
}

export { Error };
