import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { small } from 'utils';
import {
  BodyText,
  Button,
  NationalText,
} from '@unmind/design-system-components-web';
import { ContentTile } from 'LoggedOut/Start/AlternativeMSTeamsStartPage/Sections/Shared/ContentTile';
import {
  MediaTypes,
  navigateToPlayer,
} from 'LoggedOut/Start/AlternativeMSTeamsStartPage/Sections/Shared/navigateToPlayer';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import TalkTrailerThumbnail from './Assets/talk_trailer_thumbnail.png';

const OuterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2d150d;
  align-items: center;
`;

const InnerSectionContainer = styled.div`
  display: flex;
  max-width: ${rem(1252)};
  flex-direction: column;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(40)} ${rem(20)};

  ${small(css`
    padding: ${rem(60)};
    flex-direction: row;
  `)};
`;

const QuoteSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff9ef33;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  border-radius: ${rem(20)};
  gap: ${rem(40)};
  padding: ${rem(20)};
  margin: 0 ${rem(20)} ${rem(60)} ${rem(20)};

  ${small(css`
    flex-direction: row;
    margin: 0 ${rem(60)} ${rem(60)} ${rem(60)};
  `)}
`;

const QuoteBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteText = styled(BodyText)`
  color: #ffd7cc;
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: ${rem(8)};
  max-width: ${rem(576)};
`;

const TextSection = styled.div`
  color: #ffd7cc;
`;

const TalkTitle = styled(NationalText)`
  color: #ffd7cc;
  padding: ${rem(24)} ${rem(0)};
  max-width: ${rem(576)};
  font-size: ${rem(60)};

  ${small(css`
    font-size: ${rem(88)};
  `)};
`;

const TalkDescription = styled(BodyText)`
  color: #ffd7cc;
  max-width: ${rem(576)};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize20}px;
  line-height: 172%;
`;

const TalkBadge = styled(Button)`
  border-color: #ffd7cc;
  background-color: transparent;
  padding: ${rem(2)} ${rem(8)};
  pointer-events: none;

  > span {
    color: #ffd7cc;
  }
`;

const TalkCTASection = styled.div`
  display: flex;
  margin-top: ${rem(32)};
`;

const TalkCTAButton = styled(Button)`
  border-color: #ffd7cc;
  background-color: #ffd7cc;
  color: transparent;

  > span {
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    font-size: ${({ theme }) => theme.typography.fontSizes.fontSize18}px;
  }

  &:hover > span {
    color: #ffd7cc;
  }
`;

const PlayerContainer = styled.div`
  width: 100%;
  margin-top: ${rem(40)};
  max-width: ${rem(608)};
`;

const TalkTrailerURL =
  'https://assets.unmind.com/video/upload/v1725959887/Talk_experimental_homepage_trailer_en-GB.mov';

export const TalkStartPageHeader = ({
  ctaNavigationPath,
}: {
  ctaNavigationPath: string;
}) => (
  <OuterSectionContainer>
    <InnerSectionContainer>
      <ContentSection>
        <TextSection>
          <TalkBadge label="Talk"></TalkBadge>
          <TalkTitle>THERAPISTS AND COACHES, FOR FREE</TalkTitle>
          <TalkDescription>
            🔍 Browse our wide range of therapists and coaches
            <br />
            ⚡️ Instant booking, appointments within a week
            <br />
            🕊️ Completely free to you
            <br />
            🤫 100% confidential
          </TalkDescription>
          <TalkCTASection>
            <TalkCTAButton
              role="button"
              label="Browse practitioners ->"
              to={ctaNavigationPath}
              onClick={() => {
                tracking.track('teams-start-page-cta-clicked', {
                  abTestGroup: 'talk-focussed-start-page-sept-2024',
                  tenantId:
                    getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
                  ctaLocation: 'start-page-header',
                });
              }}
            ></TalkCTAButton>
          </TalkCTASection>
        </TextSection>
        <PlayerContainer aria-hidden>
          <ContentTile
            src={TalkTrailerThumbnail}
            title={'Welcome To Unmind'}
            onClick={() =>
              navigateToPlayer({
                mediaSrc: TalkTrailerURL,
                mediaType: MediaTypes.Video,
                title: 'Talk Trailer',
              })
            }
          />
        </PlayerContainer>
      </ContentSection>
      <QuoteSection>
        <QuoteBlock>
          <QuoteText>
            I was going through a rough patch in my personal life. Unmind Talk
            helped me get back to normal again.
            <br />
            <br />
            <br />
            Unmind user, October 2023 ★★★★★
          </QuoteText>
        </QuoteBlock>
        <QuoteBlock>
          <QuoteText>
            The psychotherapist I am working with is really helpful - we’ve
            built a good relationship and she’s helping me navigate some
            difficult issues.
            <br />
            <br />
            Unmind user, April 2024 ★★★★★
          </QuoteText>
        </QuoteBlock>
        <QuoteBlock>
          <QuoteText>
            My therapist is helping me to understand aspects of my life, my
            character and the reasons behind decisions or what I’m drawn to.
            <br />
            <br />
            Unmind user, October 2023 ★★★★★
          </QuoteText>
        </QuoteBlock>
      </QuoteSection>
    </InnerSectionContainer>
  </OuterSectionContainer>
);
