import range from 'lodash/range';
import React, { MouseEvent } from 'react';
import RoutePath from '../App/RoutePath';
import { ServersideTrackingEvent } from '../App/Tracking/serverside';
import ContentFolderView from './ContentFolderView';
import ContentTile from './ContentTile';
import { ContentFolder, ContentNode } from './withHelpContentFolder';

type DisplayTile = ContentNode & {
  visible?: boolean;
};

interface HelpFolderProps {
  title: string;
  items: ContentFolder[];
  onBack(event: MouseEvent<HTMLButtonElement>): void;
  trackServerside?(
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ): Promise<void>;
}

export const HelpFolder = ({
  title,
  items,
  onBack,
  trackServerside,
}: HelpFolderProps) => {
  const generateEmptyDisplayTiles = (tilesToGenerate: number): DisplayTile[] =>
    tilesToGenerate > 0
      ? range(tilesToGenerate).map(id => ({
          __typename: 'ContentPlaceholder',
          parentId: '',
          id: `${id}-invisible`,
          title: '',
          summary: '',
          items: [],
          visible: false,
        }))
      : [];
  const displayTiles: DisplayTile[] = [
    ...items,
    ...generateEmptyDisplayTiles(4 - items.length),
  ];

  return (
    <ContentFolderView
      data-testid="content-folder"
      title={title}
      onBackButtonClick={onBack}
    >
      {displayTiles.map(
        ({ title: itemTitle, summary: itemSummary, id: itemId, visible }) => (
          <ContentTile
            key={itemId}
            title={itemTitle}
            id={itemId}
            description={itemSummary}
            linkTo={`${RoutePath.Help}/${itemId}/`}
            data-testid={'content-tile'}
            visible={visible === false ? false : true}
            trackServerside={trackServerside}
          />
        ),
      )}
    </ContentFolderView>
  );
};

export default HelpFolder;
