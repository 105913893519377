import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import {
  Wrapper,
  DropdownMenuList,
  DropdownMenuPopover,
  MenuItemText,
} from './styles';

export interface DropdownMenuItemProps {
  label: string;
  menuIsOpen?: boolean;
  [key: string]: any;
}

export const DropdownMenuItem = ({
  label,
  menuIsOpen,
  ...props
}: DropdownMenuItemProps) => (
  <MenuItemText tabIndex={menuIsOpen ? 0 : -1} role="menuitem" {...props}>
    {label}
  </MenuItemText>
);

export interface DropdownProps {
  isOpen: boolean;
  closeDrawer(): void;
  triggerElement: ReactNode;
  menuItems: ReactElement[];
}

export const Dropdown = ({
  closeDrawer,
  isOpen,
  triggerElement,
  menuItems,
}: DropdownProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleKeyboardClose = (event: KeyboardEvent): void => {
    const escKeyPressed = event.key === 'Esc' || event.key === 'Escape';
    if (isOpen && escKeyPressed) {
      closeDrawer();
    }

    return;
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardClose, {
      capture: true,
    });

    return () => {
      document.removeEventListener('keydown', handleKeyboardClose, {
        capture: true,
      });
    };
  }, [isOpen]);

  return (
    <Wrapper ref={wrapperRef}>
      {triggerElement}
      <DropdownMenuPopover
        isOpen={isOpen}
        aria-hidden={!isOpen}
        id="dropdown-menu"
      >
        <DropdownMenuList role="menu">
          {menuItems.map((item, i) => (
            <li role="presentation" key={i}>
              {React.cloneElement(item, {
                ...item.props,
                menuIsOpen: isOpen,
              })}
            </li>
          ))}
        </DropdownMenuList>
      </DropdownMenuPopover>
    </Wrapper>
  );
};
