import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { css, styled } from 'styles';
import { useTranslation } from 'react-i18next';
import langmap from 'langmap';
import { rem } from 'polished';
import { Error as ExclamationMarkIcon } from 'icons';
import { useAuth0 } from '@auth0/auth0-react';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { logException } from '../../App/logging';
import { LOG_EVENT_MUTATION } from '../../App/Tracking/serverside/withLogEvent';
import RadioOption from '../../Admin/Dashboard/FilterPanel/RadioButtons/RadioOption';
import { FieldSet } from '../../Shared/Form/FieldSet';
import { small } from '../../utils';
import i18n from '../../i18n/config';
import PrimaryButton from '../../Shared/PrimaryButton';
import { Grid, GridItem } from '../../Shared/Grid';
import {
  DEFAULT_SUPPORTED_LOCALES,
  LOCALES_IN_TESTING,
  TEST_ORGANISATION_SUBDOMAINS,
} from '../../i18n/defaultLocaleConfig';
import { tracking } from '../../App/Tracking';
import { IN_APP_SELECTED_LOCALE_KEY } from '../../i18n/getUserLocale';
import { updateDbUserLocale } from '../../i18n/updateDbUserLocale';
import { BodyText } from '../../Shared/Typography';

export const SETTINGS_IN_APP_LANGUAGE_CHANGED =
  'SETTINGS_IN_APP_LANGUAGE_CHANGED';

const StyledGrid = styled(Grid)`
  padding: 0;
`;

const RadioGroup = styled.div`
  margin: 16px 0;
`;

const OptionContainer = styled.div`
  display: flex;
  padding: 8px 0;
`;

const StyledLegend = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
  forwardedAs: 'legend',
}))``;

const SaveButton = styled(PrimaryButton)`
  width: 100%;
  ${small(css`
    width: auto;
  `)}
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0px;
`;

const ErrorIcon = styled(ExclamationMarkIcon).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.error,
  height: 16,
  width: 16,
}))`
  margin-right: ${rem(8)};
`;

const ErrorMessage = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.error,
  sizes: [theme.typography.fontSizes.fontSize14],
  role: 'alert',
}))``;

const getLocaleOptions = () => {
  const subdomain = getSubdomainFromUrl();
  const localeOptions = DEFAULT_SUPPORTED_LOCALES;

  if (TEST_ORGANISATION_SUBDOMAINS.includes(subdomain)) {
    localeOptions.push(...LOCALES_IN_TESTING);
  }

  return Array.from(new Set(localeOptions)).sort();
};

function SelectLanguagePage() {
  const initialLanguageValue = i18n.language;
  const localeOptions = getLocaleOptions();
  const { t: translate } = useTranslation(['account']);
  const [selectedLocale, setSelectedLocale] = useState(initialLanguageValue);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const { isAuthenticated: isAuthenticatedViaAuth0 } = useAuth0();

  const changeLanguageHandler = async (locale: string) => {
    if (locale !== initialLanguageValue) {
      try {
        localStorage.setItem(IN_APP_SELECTED_LOCALE_KEY, locale);
        tracking.track('settings-in-app-language-changed', { locale });

        const properties = {
          newLanguage: locale,
        };

        await logEvent({
          variables: {
            input: {
              eventName: SETTINGS_IN_APP_LANGUAGE_CHANGED,
              brazeProperties: properties,
              eventProperties: properties,
            },
          },
        });

        /**
         * We cannot update the locale in the db for SSO users because this
         * mutation uses an apollo client that does not send the necessary
         * auth token in its request headers.
         *
         * https://unmind.atlassian.net/browse/ENG-9468
         */
        if (!isAuthenticatedViaAuth0) {
          await updateDbUserLocale(locale, initialLanguageValue);
        }
        await i18n.changeLanguage(locale);

        /**
         * This is required to update the locale sent in request headers
         * as we continue to use the value from when the app was initialised.
         *
         * Without this, dynamic content will display in the locale
         * in which the app was originally initialised.
         */
        window.location.reload();
      } catch (e) {
        setShouldDisplayError(true);
        logException(e);
      }
    }
  };

  const handleOptionChange = (localeCode: string) => {
    setSelectedLocale(localeCode);
    setShouldDisplayError(false);
  };

  return (
    <StyledGrid>
      <GridItem fullWidth>
        <FieldSet>
          <StyledLegend>{translate('account:language.legend')}</StyledLegend>
          <RadioGroup role="radiogroup">
            {localeOptions.map(locale =>
              langmap[locale] ? (
                <OptionContainer key={locale}>
                  <RadioOption
                    label={langmap[locale].nativeName}
                    handleChange={() => handleOptionChange(locale)}
                    isSelected={selectedLocale === locale}
                  />
                </OptionContainer>
              ) : null,
            )}
          </RadioGroup>
          <SaveButton
            disabled={selectedLocale === initialLanguageValue}
            label={translate('account:language.save_button.label')}
            size="medium"
            type="submit"
            onClick={async () => changeLanguageHandler(selectedLocale)}
          />
        </FieldSet>
        {shouldDisplayError ? (
          <ErrorContainer>
            <ErrorIcon />
            <ErrorMessage>
              {translate('account:language.error_state.message')}
            </ErrorMessage>
          </ErrorContainer>
        ) : null}
      </GridItem>
    </StyledGrid>
  );
}

export default SelectLanguagePage;
