import React from 'react';
import { IconType } from 'icons';
import { Chip, Label, ChipIcon } from './styles';

interface AvailabilityChipProps {
  label: string;
  type?: 'primary' | 'secondary';
  Icon?: IconType;
}

export const AvailabilityChip = ({
  label,
  type = 'primary',
  Icon,
}: AvailabilityChipProps) => (
  <Chip $type={type}>
    {Icon ? <ChipIcon Icon={Icon} /> : null}
    <Label>{label}</Label>
  </Chip>
);
