import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { small } from 'utils';

interface DrawerProps {
  side?: 'left' | 'right';
  isOpen?: boolean;
}

interface WrapperProps extends DrawerProps {
  isContentVisible: boolean;
}

export const Wrapper = styled.div.attrs<WrapperProps>(({ isOpen }) => ({
  'aria-hidden': !isOpen,
}))<WrapperProps>`
  visibility: ${({ isContentVisible }) =>
    isContentVisible ? 'visible' : 'hidden'};
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  transition: ${({ isOpen }) =>
    isOpen
      ? 'transform .3s cubic-bezier(.78,.14,.15,.86)'
      : 'width 0s ease 0.3s, height 0s ease 0.3s, transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)'};
  width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  outline: none !important;

  > * {
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  ${({ side }) => (side === 'left' ? `left: 0;` : `right: 0;`)}
`;

export const Mask = styled.div<DrawerProps>`
  background: rgba(13, 3, 0, 0.45);
  width: 100%;
  opacity: ${({ isOpen }) => (isOpen ? '.45;' : '0')};
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  position: absolute;
  top: 0;
  left: 0;
  transition: ${({ isOpen }) =>
    isOpen
      ? 'opacity .3s cubic-bezier(.78,.14,.15,.86)'
      : 'opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), height 0s ease 0.3s'};
`;

export const DrawerWrapper = styled.div<DrawerProps>`
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.background.card};
  transform: ${({ isOpen, side }) =>
    !isOpen && side === 'left'
      ? 'translateX(-100%)'
      : !isOpen && side === 'right'
      ? 'translateX(+100%)'
      : 'translateX(0)'};

  min-width: 100%;
  max-width: 35vw;
  width: 100%;
  ${small(css`
    width: 500px;
    min-width: 425px;
  `)}
  ${({ side }) => (side === 'left' ? `left: 0;` : `right: 0;`)}
  border-radius: ${rem(16)} 0 0 ${rem(16)};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

export const DrawerContent = styled.div<DrawerProps>`
  height: 100%;
  overflow: auto;
  z-index: 1;
  position: relative;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  transition: opacity 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;
