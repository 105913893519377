import React from 'react';
import { IconProps } from '../IconProps';

const SvgOpenExternalLink = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.231 7.924v4.154a.923.923 0 0 1-.923.923H3.923A.924.924 0 0 1 3 12.078V4.693a.923.923 0 0 1 .923-.923h4.154M11.77 1H15v3.23M15 1L9.925 6.077"
      stroke={primaryColor}
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgOpenExternalLink;
