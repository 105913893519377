import React from 'react';
import styled from 'styled-components';
import { SelectContainer, TimePickerDropdown } from './TimePickerDropdown';
import { AddToCalendarField, ClockIcon } from './styles';
import { DurationDropdown } from './DurationDropdown';
import { DayDropdownDropdown } from './DayDropdown';

const Dropdown = styled.div`
  width: 100%;
`;

interface DateTimeDurationSelectorGroupProps {
  date: Date;
  duration: number;
  onSelect(field: string, value: Date | string | number): void;
}

export const DateTimeDurationSelectorGroup = ({
  date,
  duration,
  onSelect,
}: DateTimeDurationSelectorGroupProps) => (
  <AddToCalendarField multipleFields>
    <ClockIcon />
    <Dropdown>
      <DayDropdownDropdown date={date} onSelect={onSelect} />
      <SelectContainer>
        <TimePickerDropdown date={date} onSelect={onSelect} />
        <DurationDropdown onSelect={onSelect} duration={duration} />
      </SelectContainer>
    </Dropdown>
  </AddToCalendarField>
);
