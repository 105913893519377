import React from 'react';
import { IconProps } from '../IconProps';

const SvgTransform = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill="#F1EBFF" d="M0 0h340v192H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M340 38.505V0H0v131.704l2.313-1.622c16.655-11.686 34.192-23.99 52.035-32.372 27.67-12.997 51.24 5.209 75.332 23.817 23.907 18.467 48.329 37.33 77.781 26.491 24.219-8.913 39.87-35.998 54.792-61.82 15.888-27.493 30.948-53.555 54.642-54.732 7.264-.36 15.048 2.351 23.105 7.039z"
      fill="#7600CC"
    />
  </svg>
);

export default SvgTransform;
