export enum KnownReferrer {
  Nova = 'nova',
}

export const useKnownReferrer = () => {
  const referrer = new URLSearchParams(window.location.search).get('referrer');

  return (
    (referrer
      ? Object.values(KnownReferrer).find(value => value === referrer)
      : undefined) ?? null
  );
};
