export const DEFAULT_AVG_SALARY = '40000';
export const DEFAULT_AVG_SALARY_CURRENCY_SYMBOL = '£';
export const DEFAULT_AVG_SALARY_CURRENCY = 'GBP';

export const AVG_SALARY_STORAGE_KEY = 'averageSalary';
export const CURRENCY_STORAGE_KEY = 'currency';

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  USD: '$',
  GBP: '£',
  AUD: 'A$',
  CNY: '¥',
  EUR: '€',
  BRL: 'R$',
  ARS: '$',
  MXN: '$',
  INR: '₹',
};

export const getFormattedAverageSalary = (averageSalary: string) =>
  parseInt(averageSalary, 10).toLocaleString();

export const getAverageSalaryDisplay = (
  averageSalary: string,
  currency: string,
) =>
  `${
    CURRENCY_SYMBOLS[currency] || DEFAULT_AVG_SALARY_CURRENCY_SYMBOL
  }${getFormattedAverageSalary(averageSalary)}`;
