import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(32)} 0;
`;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(16)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
  weight: theme.typography.fontWeights.semiBold,
  color: theme.colors.text.primary,
}))`
  margin-left: ${rem(8)};
`;

interface SectionHeaderProps {
  text: string;
  icon: React.ReactNode;
  description: string;
}

export const SectionHeader = ({
  text,
  icon,
  description,
}: SectionHeaderProps) => (
  <Container>
    <Header>
      {icon}
      <Text>{text}</Text>
    </Header>
    <Description>{description}</Description>
  </Container>
);
