type ValueOf<T> = T[keyof T];

export const TAB_HASHES = {
  ACTIVITY: '#activity' as const,
  WELLBEING: '#wellbeing' as const,
  MANAGER_REPORTING: '#manager_reporting' as const,
  MANAGE_USERS: '#manage_users' as const,
};

export type TabHash = ValueOf<typeof TAB_HASHES>;
