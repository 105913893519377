export enum MessageType {
  APP_READY = 'appReady',
  BOOKING_FLOW_COMPLETE = 'bookingFlowComplete',
  CLOSE_PRACTITIONER = 'closePractitioner',
}

export enum BookingType {
  BookingRequest = 'bookingRequest',
  DirectBooking = 'directBooking',
}

export type FrankieIFrameMessage = {
  messageType: MessageType;
  // Deprecated in favour of the appReady message type
  frankieCareReady?: boolean;
  data?: {
    bookingType: BookingType;
  };
};
