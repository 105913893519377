import React from 'react';
import { IconProps } from '../IconProps';

const SvgSadFaceWithBorder = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 166 166" fill="none" {...props}>
    <path
      d="M82.9 164.74c44.945 0 81.38-36.435 81.38-81.38 0-44.944-36.435-81.38-81.38-81.38-44.945 0-81.38 36.436-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#FFF964"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.82 66.74a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.31 66.74a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M75.69 137.9c3.12 1.77 7.25 1.58 10.19-.47M104.59 107.11c-5.58-3.65-14.09-5.94-22.34-5.97h-.35c-8.25.03-16.76 2.32-22.34 5.97"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.9 164.74c44.945 0 81.38-36.435 81.38-81.38 0-44.944-36.435-81.38-81.38-81.38-44.945 0-81.38 36.436-81.38 81.38 0 44.945 36.435 81.38 81.38 81.38z"
      fill="#FFD3B8"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.82 66.74a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26zM109.31 66.74a3.13 3.13 0 1 0 0-6.26 3.13 3.13 0 0 0 0 6.26z"
      fill="#391F17"
      stroke="#391F17"
      strokeMiterlimit={10}
    />
    <path
      d="M104.59 107.11c-5.58-3.65-14.09-5.94-22.34-5.97h-.35c-8.25.03-16.76 2.32-22.34 5.97"
      stroke="#391F17"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSadFaceWithBorder;
