import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { small } from '../../utils';
import { categoryContent_exploreCategory as CategoryContent } from '../Category/__generated__/categoryContent';
import { Container, SectionHeadingTitle } from '../ExplorePage';
import {
  exploreContent_exploreFeaturedHero_ExploreCategory as Tool,
  exploreContent_exploreFeaturedHero_Series as Series,
  exploreContent_exploreFeaturedHero_Tool as ExploreCategory,
} from '../__generated__/exploreContent';
import HeroBannerCategory from './HeroBannerCategory/HeroBannerCategory';
import HeroBannerCourse from './HeroBannerCourse/HeroBannerCourse';
import HeroBannerShort from './HeroBannerShort/HeroBannerShort';

export const HeadingTitleWrapper = styled.div`
  text-align: center;

  ${small(css`
    text-align: left;
  `)}
`;

interface HeroBannerSectionProps {
  heroBannerContent: Tool | Series | ExploreCategory;
  assetToken?: string | null;
  source: string;
  currentCategory?: CategoryContent;
}

const HeroBannerSection = ({
  heroBannerContent,
  assetToken,
  source,
  currentCategory,
}: HeroBannerSectionProps) => {
  const { t: translate } = useTranslation('explore');

  return (
    <Container>
      <HeadingTitleWrapper>
        <SectionHeadingTitle>
          {translate('featured_hero_banner.section_header')}
        </SectionHeadingTitle>
      </HeadingTitleWrapper>
      {heroBannerContent.__typename === 'Tool' && (
        <HeroBannerShort
          tool={heroBannerContent}
          assetToken={assetToken}
          source={source}
        />
      )}
      {heroBannerContent.__typename === 'Series' && (
        <HeroBannerCourse
          series={heroBannerContent}
          assetToken={assetToken}
          source={source}
        />
      )}
      {heroBannerContent.__typename === 'ExploreCategory' && (
        <HeroBannerCategory
          category={heroBannerContent}
          assetToken={assetToken}
          source={source}
          currentCategory={currentCategory}
        />
      )}
    </Container>
  );
};

export default HeroBannerSection;
