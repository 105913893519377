import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { css, styled } from 'styles';
import RoutePath from '../../App/RoutePath';
import { FullPageGeneralError } from '../../Shared/AlertView';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { small, medium } from '../../utils';
import { HeadingText } from '../../Shared/Typography';
import Questionnaire from './Questionnaire';
import { getUserIndexInsights } from './Score/__generated__/getUserIndexInsights';
import { hasCompletedIndexRecently } from './utils/timeBetweenIndex';
import withIndexRetakeFrequency, {
  MINIMUM_DAYS_BETWEEN_INDEXES,
  WithIndexRetakeFrequencyProps,
} from './Questionnaire/withIndexRetakeFrequency';

const QuestionnaireContainer = styled.div`
  display: block;
  padding: 0 ${rem(16)} ${rem(20)};
  margin: 0 auto;
  height: auto;
  max-width: ${rem(1280)};

  ${small(css`
    padding: 0 ${rem(36)} ${rem(36)};
  `)}

  ${medium(css`
    padding: 0 ${rem(52)} ${rem(36)};
  `)}
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${rem(24)} 0;

  ${small(css`
    justify-content: start;
    margin: ${rem(52)} 0 ${rem(32)} 0;
  `)}
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
}))`
  margin: 0;
`;

export const USER_INDEX_INSIGHTS_QUERY = gql`
  query getUserIndexInsights {
    userIndexInsights {
      id
      date
      overallScore: overall
      categories {
        key
        name
        averageScore: score
        band
        shortDescription
      }
    }
  }
`;

interface UnmindIndexProps
  extends WithIndexRetakeFrequencyProps,
    Pick<RouteComponentProps, 'location'>,
    Pick<RouteComponentProps, 'history'> {}

export const UnmindIndex = (props: UnmindIndexProps) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const {
    history,
    location,
    indexFrequency = MINIMUM_DAYS_BETWEEN_INDEXES,
  } = props;
  const { loading, data, error } = useQuery<getUserIndexInsights>(
    USER_INDEX_INSIGHTS_QUERY,
  );

  useEffect(() => {
    if (location.state && location.state.showQuestionnaire) {
      history.replace({
        pathname: location.pathname,
        state: {
          routeBackOnHide: location.state.routeBackOnHide,
        },
      });
      setShowQuestionnaire(true);
    }
  }, [location.state, history, location.pathname]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (Boolean(error)) {
    return <FullPageGeneralError />;
  }

  const latestIndexScore = data?.userIndexInsights?.[0];
  const nextIndexAvailable =
    !hasCompletedIndexRecently(indexFrequency, latestIndexScore?.date) ||
    showQuestionnaire;

  return nextIndexAvailable ? (
    <QuestionnaireContainer>
      <Questionnaire hideQuestionnaire={() => history.push(RoutePath.Track)} />
    </QuestionnaireContainer>
  ) : (
    <Redirect to={RoutePath.Track} />
  );
};

export default withIndexRetakeFrequency(UnmindIndex);
