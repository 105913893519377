import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';
import { FullPageGeneralError } from '../../Shared/AlertView';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { TrackEmptyStateContainer } from '../Track';
import withIndexRetakeFrequency, {
  MINIMUM_DAYS_BETWEEN_INDEXES,
  WithIndexRetakeFrequencyProps,
} from './Questionnaire/withIndexRetakeFrequency';
import WellbeingScoreInsights from './Score/WellbeingScoreInsights';
import { UserWellbeingTrackerScoresForScoreScreen } from './__generated__/UserWellbeingTrackerScoresForScoreScreen';
import EmptyState from './EmptyState';
import { isNotNull } from './utils/isNotNull';

export const USER_WELLBEING_TRACKER_QUERY = gql`
  query UserWellbeingTrackerScoresForScoreScreen {
    user {
      id
      wellbeingTrackerScores {
        edges {
          node {
            id
            date
            overallScore: overall
            categories {
              key
              name
              score
              band
              shortDescription
            }
          }
        }
      }
    }
    wellbeingTracker {
      bands {
        name
        lower
        upper
      }
    }
  }
`;

type Props = WithIndexRetakeFrequencyProps;

export const Wellbeing = ({
  indexFrequency = MINIMUM_DAYS_BETWEEN_INDEXES,
}: Props) => {
  const { loading, data, error } =
    useQuery<UserWellbeingTrackerScoresForScoreScreen>(
      USER_WELLBEING_TRACKER_QUERY,
    );

  if (loading) {
    return <LoadingIndicator />;
  }

  if (Boolean(error)) {
    return <FullPageGeneralError />;
  }

  if (!data?.user?.wellbeingTrackerScores?.edges?.length) {
    return (
      <TrackEmptyStateContainer>
        <EmptyState />
      </TrackEmptyStateContainer>
    );
  }

  const wellbeingTrackerEntryNodes =
    data.user.wellbeingTrackerScores.edges
      .filter(isNotNull)
      .map(edge => edge.node) ?? [];

  const bands = data.wellbeingTracker?.bands ?? [];

  return (
    <WellbeingScoreInsights
      wellbeingTrackerEntryNodes={wellbeingTrackerEntryNodes}
      bands={bands}
      retakeFrequency={indexFrequency}
    />
  );
};

export default withIndexRetakeFrequency(Wellbeing);
