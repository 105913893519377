/**
 * Convert seconds to minutes
 */
export const secondsToMinutes = (seconds: number) => Math.ceil(seconds / 60);

/**
 * pluralize sting
 */
export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;
