import {
  BodyText,
  ToggleSwitch,
  small,
} from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { Envelope, MSTeamsOutlined } from 'icons';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import {
  NOTIFICATION_CHANNEL_TO_PREFERENCE,
  NotificationChannel,
  NotificationTypes,
  UpdateNotificationType,
} from '../types';

const QuickNotificationPreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border.secondary};
  padding: ${rem(16)};
`;

const Header = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))``;

const Description = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  color: theme.colors.text.secondary,
}))`
  margin-top: ${rem(8)};
  margin-bottom: ${rem(16)};
`;

const PreferenceDescription = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.secondary,
}))``;

const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${rem(0)};

  ${small(css`
    &:first-of-type {
      margin-right: ${rem(64)};
    }
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${rem(16)};
`;

const PreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${small(css`
    flex-direction: row;
  `)};
`;

const IconContainer = styled.div``;

interface PreferencesProps {
  title: string;
  description: string;
  icon: ReactNode;
  isChecked: boolean;
  disableToggles: boolean;
  notificationChannel: NotificationChannel;
  handleUpdatePreferences(
    notificationChannel: NotificationChannel,
    toggledOn: boolean,
  ): void;
}

const Preferences = ({
  title,
  description,
  icon,
  isChecked,
  disableToggles,
  notificationChannel,
  handleUpdatePreferences,
}: PreferencesProps) => (
  <PreferenceContainer>
    <ToggleSwitch
      label={title}
      isChecked={isChecked}
      disabled={disableToggles}
      onChange={() => {
        handleUpdatePreferences(notificationChannel, !isChecked);
        tracking.track('all-notification-preferences-updated', {
          notificationChannel,
          notificationValue: !isChecked ? 'on' : 'off',
        });
      }}
    />
    <IconContainer>{icon}</IconContainer>
    <Container>
      <Header>{title}</Header>
      <PreferenceDescription>{description}</PreferenceDescription>
    </Container>
  </PreferenceContainer>
);

const MSTeamsIcon = styled(MSTeamsOutlined).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.text.secondary,
  tertiaryColor: theme.colors.text.secondary,
}))`
  width: ${rem(28)};
  height: ${rem(28)};
  margin-left: ${rem(16)};
`;

const EnvelopeIcon = styled(Envelope).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.text.secondary,
  tertiaryColor: theme.colors.text.secondary,
}))`
  width: ${rem(28)};
  height: ${rem(28)};
  margin-left: ${rem(16)};
`;

type QuickNotificationPreferencesProps = {
  subscriptions: { isTeamsEnabled: boolean; isEmailEnabled: boolean }[];
  updatePreferences(preference: UpdateNotificationType): void;
  disableToggles: boolean;
  hideTeamsPreferences?: boolean;
};

export const QuickNotificationPreferences = ({
  subscriptions,
  updatePreferences,
  disableToggles,
  hideTeamsPreferences,
}: QuickNotificationPreferencesProps) => {
  const { t: translate } = useTranslation('account');
  const emailPreferenceCentreIsEnabled = useFeatureFlag(
    FEATURE_FLAGS.SHOW_EMAIL_NOTIFICATIONS,
  );

  const handleUpdatePreferences = (
    notificationChannel: NotificationChannel,
    toggledOn: boolean,
  ) => {
    const prefs: UpdateNotificationType = {
      [NOTIFICATION_CHANNEL_TO_PREFERENCE[notificationChannel]]: {
        [NotificationTypes.productUpdates]: toggledOn,
        [NotificationTypes.communityParticipation]: toggledOn,
        [NotificationTypes.upcomingEvents]: toggledOn,
      },
    };

    updatePreferences(prefs);
  };

  const isTeamsEnabled = subscriptions.every(
    subscription => subscription.isTeamsEnabled,
  );

  const isEmailEnabled = subscriptions.every(
    subscription => subscription.isEmailEnabled,
  );

  return (
    <QuickNotificationPreferencesContainer>
      <Header>
        {translate('notification_preference_centre.all_notifications.title')}
      </Header>
      <Description>
        {translate(
          'notification_preference_centre.all_notifications.description',
        )}
      </Description>
      <PreferencesContainer>
        {!hideTeamsPreferences && (
          <Preferences
            title={translate('notification_preference_centre.teams.title')}
            description={translate(
              'notification_preference_centre.teams.description',
            )}
            disableToggles={disableToggles}
            icon={<MSTeamsIcon />}
            isChecked={isTeamsEnabled}
            notificationChannel={NotificationChannel.Teams}
            handleUpdatePreferences={handleUpdatePreferences}
          />
        )}
        {emailPreferenceCentreIsEnabled && (
          <Preferences
            disableToggles={disableToggles}
            title={translate('notification_preference_centre.email.title')}
            description={translate(
              'notification_preference_centre.email.description',
            )}
            icon={<EnvelopeIcon />}
            isChecked={isEmailEnabled}
            notificationChannel={NotificationChannel.Email}
            handleUpdatePreferences={handleUpdatePreferences}
          />
        )}
      </PreferencesContainer>
    </QuickNotificationPreferencesContainer>
  );
};
