import React from 'react';
import { CaptionLabelProps, useDayPicker } from 'react-day-picker';

export const DayPickerCaptionLabel = (props: CaptionLabelProps) => {
  const {
    locale,
    classNames,
    styles,
    formatters: { formatCaption },
  } = useDayPicker();

  return (
    <div
      className={classNames.caption_label}
      style={styles.caption_label}
      id={props.id}
    >
      {formatCaption(props.displayMonth, { locale })}
    </div>
  );
};
