export enum Profession {
  accredited_mental_health_social_worker = 'accredited_mental_health_social_worker',
  clinical_psychologist = 'clinical_psychologist',
  coach = 'coach',
  counselling_psychologist = 'counselling_psychologist',
  counsellor = 'counsellor',
  licensed_clinical_social_worker = 'licensed_clinical_social_worker',
  licensed_mental_health_counselor = 'licensed_mental_health_counselor',
  licensed_professional_counselor = 'licensed_professional_counselor',
  licensed_marriage_and_family_therapist = 'licensed_marriage_and_family_therapist',
  psychoanalyst = 'psychoanalyst',
  psychotherapist = 'psychotherapist',
  other = 'other',
}
