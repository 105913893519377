import React from 'react';
import { IconProps } from '../IconProps';

const SvgEllipse = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      d="M681.342 612.802C1213.13 823.492 1167 418.629 1453.35 469.831c286.36 51.201 433.67 431.896 321.62 666.139-112.05 234.23-379.64 215.36-651.49 209.52-1632.181-35.01-973.922-943.378-442.138-732.688z"
      fill="#F7F5F9"
    />
  </svg>
);

export default SvgEllipse;
