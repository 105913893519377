import { Colors } from '@unmind/design-system-theme';

export const multiSelectTheme = {
  multiValue: {
    backgroundColor: Colors.white,
  },
  multiValueRemove: {
    backgroundColor: Colors.white,
    focused: {
      backgroundColor: Colors.red2,
    },
  },
  option: {
    hover: {
      backgroundColor: Colors.white,
    },
    active: {
      backgroundColor: Colors.white,
    },
    backgroundColor: Colors.white,
  },
};
