import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { medium, extraSmall } from 'utils';
import { PractitionerCardContainer } from '../PractitionerCard/styles';

const gap = 30;
const spacingTwoCol = 15; // magic number warning - these are hardcoded and removed from width of container to compensate for gap above.
const spacingThreeCol = 17;

export const PractitionerCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: ${rem(32)};
  padding-bottom: ${rem(24)};
  gap: ${rem(gap)};

  ${extraSmall(css`
    margin-top: ${rem(48)};
  `)}

  > ${PractitionerCardContainer} {
    width: 100%;

    ${extraSmall(css`
      width: calc(50% - ${rem(spacingTwoCol)});
    `)}

    ${medium(css`
      width: calc(33% - ${rem(spacingThreeCol)});
    `)}
  }
`;

export const ErrorContainer = styled.div`
  margin-top: ${rem(24)};
`;
