import { styled } from 'styles';
import { rem } from 'polished';
import getFocusState from '../Button/getFocusState';

export const InteractiveContainer = styled.div<{
  hasError: boolean;
  focused?: boolean;
}>`
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: ${rem(4)};
  border: ${({ theme, hasError }) =>
    `1px solid ${
      hasError ? theme.colors.background.error : theme.colors.text.secondary
    }`};
  ${({ focused }) => (focused ? getFocusState() : ``)}
  &:hover {
    border: ${({ theme, hasError }) =>
      `1px solid ${
        hasError ? theme.colors.background.error : theme.colors.text.secondary
      }`};
  }
`;
