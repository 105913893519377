import React from 'react';
import { IconProps } from '../IconProps';

const SvgHealth = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 25 25" fill="none" {...props}>
    <g clipPath="url(#Health_svg__clip0_1095_24959)">
      <path fill={primaryColor} d="M.5.5h24v24H.5z" />
      <path
        d="M9.84 8.29c-1.2-1.2-3.15-1.2-4.35 0l-.12.12c-1.2 1.2-1.2 3.15 0 4.35l3.9 3.9 4.47-4.47-3.9-3.9z"
        fill="#FFF964"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6 19.64l.12-.12c1.2-1.2 1.2-3.15 0-4.35l-3.45-3.45-4.47 4.47 3.45 3.45c1.2 1.2 3.15 1.2 4.35 0z"
        fill="#FFF9EF"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.62 5.92v3.2M19.11 7.46h-2.98"
        stroke="#391F17"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="Health_svg__clip0_1095_24959">
        <path
          fill={primaryColor}
          transform="translate(.5 .5)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHealth;
