import React from 'react';
import { IconProps } from '../IconProps';

const SvgSeriesFoundations = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.679 3.594c4.21-.372 7.711 2.752 10.71 5.84 2.977 3.052 5.632 6.618 5.518 10.87-.12 4.677-2.039 9.575-6.11 11.738-3.882 2.054-8.306-.327-12.47-1.9-4.1-1.539-9.041-2.591-10.61-6.738-1.581-4.21 1.059-8.493 3.479-12.206 2.34-3.573 5.228-7.229 9.483-7.604z"
      fill="#99E1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.262 10.391a2.441 2.441 0 0 0-.784-.141 2.441 2.441 0 0 0-.857.17L9.62 14.51a.75.75 0 0 0-.457.69v4.646L7.3 24.774a.75.75 0 0 0-.049.265v.01c0 .064 0 .232.032.393.016.083.055.238.16.39.124.179.36.367.695.367h3.28c.312 0 .543-.152.654-.235.113-.084.214-.186.275-.247l.01-.01a.75.75 0 0 0 .22-.531v-.137a.75.75 0 0 0-.048-.265l-1.865-4.928v-3.512l2.6 1.103v4.186c0 .248.06.54.228.792.152.228.397.424.719.485l4.395 2.131a.75.75 0 0 0 .09.037c.563.188 1.14.188 1.704 0a.75.75 0 0 0 .09-.037l4.509-2.186.008-.004c.27-.135.466-.334.596-.528a1.28 1.28 0 0 0 .228-.69v-4.31l1.984-.866c.392-.142.685-.429.832-.796.145-.364.129-.754.015-1.096a.75.75 0 0 0-.173-.285l-.004-.006-.013-.02-.017-.024a1.414 1.414 0 0 0-.615-.525l-7.515-3.28a.745.745 0 0 0-.063-.024zm4.068 7.576l-4.006 1.748a2.441 2.441 0 0 1-.847.165 2.441 2.441 0 0 1-.839-.162l-3.875-1.644V21.5l4.447 2.157c.227.068.446.068.673 0L24.33 21.5v-3.534zm-12.552-2.79l7.425-3.374a.897.897 0 0 1 .275-.053c.06 0 .14.01.28.054l7.45 3.252h.001l.01.014-7.462 3.256a.908.908 0 0 1-.28.054.917.917 0 0 1-.282-.055l-7.417-3.147zm2.986 6.458v-.004.004zm-4.85.468l.982 2.596H8.93l.982-2.596z"
      fill="#1B1A29"
    />
  </svg>
);

export default SvgSeriesFoundations;
