import graphqlTag from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Errors } from '../../Shared/Errors';

export const REMOVE_PLUS_ONE_USER = graphqlTag`
  mutation RemovePlusOneUser {
    removePlusOneUser {
      user {
        id
        plusOne {
          id
        }
      }
    }
  }
`;

type RemovePlusOneUserOutput = {
  user: {
    id: string;
    plusOne: null;
  };
};

interface Response {
  error?: Errors;
  removePlusOneUser: RemovePlusOneUserOutput | null;
}
export interface RemovePlusOneUserProps {
  error?: Errors;
  removePlusOneUser(): Promise<Response>;
}

const withRemovePlusOneUser = graphql<
  Record<string, unknown>,
  Response,
  Record<string, unknown>,
  RemovePlusOneUserProps
>(REMOVE_PLUS_ONE_USER, {
  name: 'removePlusOneUser',
});

export default withRemovePlusOneUser;
