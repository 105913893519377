import gql from 'graphql-tag';
import isNil from 'lodash/isNil';
import { graphql, DataValue } from '@apollo/client/react/hoc';
import { Errors } from '../Shared/Errors';

interface ContentBase {
  __typename: string;
  id: string;
  title: string;
  summary: string;
  parentId: string;
  folderType?: string;
}

export interface ContentItem extends ContentBase {
  body: string;
  icon: string | null;
  iconAsset: {
    path: string | null;
  };
}

export interface ContentFolder extends ContentBase {
  description?: string;
  items: ContentNode[];
}

export type ContentNode = ContentItem | ContentFolder;

export interface WithHelpContentFolderInputProps {
  id: string;
}

export interface WithHelpContentFolderResponse {
  contentNode: ContentFolder;
  helpContentRoot: {
    id: string;
  };
  getAssetToken: {
    assetToken: string;
  };
}

export interface WithHelpContentFolderProps {
  helpContent?: ContentNode;
  rootId?: string;
  assetToken?: string;
  loading: boolean;
  error?: Errors;
}

const query = gql`
  query helpContentFolder($id: String!) {
    helpContentRoot {
      id
    }
    contentNode(where: { id: $id }) {
      __typename
      id
      parentId
      title
      summary
      folderType
      ... on ContentFolder {
        description
        items {
          id
          title
          summary
          folderType
          ... on ContentItem {
            body
            icon
            iconAsset {
              path
            }
          }
        }
      }
    }
    getAssetToken(input: { isMobile: false }) {
      assetToken
    }
  }
`;

export const mapDataToWithHelpContentFolderProps = (
  data:
    | DataValue<WithHelpContentFolderResponse, WithHelpContentFolderInputProps>
    | undefined,
): WithHelpContentFolderProps => {
  if (isNil(data) || data.loading) {
    return { loading: true };
  }

  const { helpContentRoot, contentNode, getAssetToken } = data;

  if (contentNode && helpContentRoot && 'id' in helpContentRoot) {
    return {
      loading: false,
      helpContent: contentNode,
      rootId: helpContentRoot.id,
      assetToken: getAssetToken?.assetToken,
    };
  }

  return {
    error: Errors.ServerError,
    loading: false,
  };
};

export const withHelpContentFolder = graphql<
  WithHelpContentFolderInputProps,
  WithHelpContentFolderResponse,
  WithHelpContentFolderInputProps,
  WithHelpContentFolderProps
>(query, {
  options: ({ id }) => ({ variables: { id }, fetchPolicy: 'no-cache' }),
  props: ({ data }) => mapDataToWithHelpContentFolderProps(data),
});
