import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SizeMe } from 'react-sizeme';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryZoomContainer,
} from 'victory';

import { Colors } from '@unmind/design-system-theme';
import { styled, ThemeContext, ThemeInterface } from 'styles';

import { ScreenReaderContent } from '../../Shared/Accessibility';
import ChartTooltip from './ChartTooltip';
import { IndexScore } from './types';
import getEntryWithLabel from './getEntryWithLabel';
import mapYearToEveryMonthExceptThisYear from './mapYearToEveryMonthExceptThisYear';

// These wrappers are needed to make sizeme work correctly when placed within a flexbox container
const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TICK_WIDTH = 12;
export const ID_INDEX_LINE_GRAPH = 'index-line-graph';

const getChartTheme = (
  { colors, typography }: ThemeInterface,
  plotColor: Colors,
) => ({
  axis: {
    style: {
      axis: {
        fill: 'transparent',
        stroke: colors.border.secondary,
        strokeWidth: 1,
      },
      grid: {
        stroke: colors.border.secondary,
        strokeWidth: 1,
      },
      tickLabels: {
        fill: colors.text.secondary,
        fontFamily: typography.bodyText.fontFamily,
        fontSize: typography.fontSizes.fontSize12,
        stroke: 'transparent',
        textTransform: 'uppercase',
      },
    },
  },
  dependentAxis: {
    style: {
      axis: { stroke: colors.border.secondary },
      ticks: {
        size: TICK_WIDTH,
        stroke: colors.border.secondary,
        strokeWidth: 1,
      },
      grid: {
        stroke: colors.border.secondary,
        strokeWidth: 1,
      },
      tickLabels: {
        fill: colors.text.secondary,
        fontFamily: typography.bodyText.fontFamily,
        fontSize: typography.fontSizes.fontSize12,
        stroke: 'transparent',
        textTransform: 'uppercase',
      },
    },
  },
  line: {
    style: {
      data: { stroke: plotColor, strokeWidth: 2 },
    },
  },
  scatter: {
    style: {
      data: { fill: plotColor },
    },
  },
});

const Y_TICK_VALUES = [0, 33.33, 66.66, 100];

/**
 * Calculates the offset required for the month label
 * based on the total number of months
 */
const getMonthLabelOffset = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  width: number | null,
) => {
  if (width === null) {
    return 0;
  }

  const numberOfMonths = endDate.diff(startDate, 'months') + 1;

  return (width - TICK_WIDTH) / numberOfMonths / 2;
};

export interface IndexLineChartProps {
  caption: string;
  data?: IndexScore[];
  endDate: moment.Moment;
  height?: number;
  startDate: moment.Moment;
  plotColor?: Colors;
}

const IndexLineChart = ({
  caption,
  data,
  endDate,
  height,
  plotColor,
  startDate,
}: IndexLineChartProps) => {
  const { t: translate } = useTranslation('insights');

  const theme = useContext(ThemeContext);

  if (!data) {
    return null;
  }

  const chartTheme = getChartTheme(
    theme,
    plotColor !== undefined ? plotColor : theme.colors.primary,
  );
  const sortedData = data.sort(
    ({ date: dateA }, { date: dateB }) =>
      moment(dateB).valueOf() - moment(dateA).valueOf(),
  );
  const dataWithLabels = sortedData.map((entry, index) =>
    getEntryWithLabel(entry, index, sortedData),
  );

  const a11yTableData = dataWithLabels
    // remove entries without value
    .filter(({ value }) => typeof value === 'number' && !isNaN(value))
    .map(({ date, value }) => ({
      x: moment(date).format('DD-MMMM-YY'),
      y: `${value}%`,
    }))
    // most-recent first
    .reverse();

  return (
    <SizeMe monitorWidth={true}>
      {({ size: { width } }) => {
        const dx = getMonthLabelOffset(startDate, endDate, width);

        return (
          <Wrapper style={{ height }}>
            <InnerWrapper aria-hidden={true} data-testid={ID_INDEX_LINE_GRAPH}>
              <VictoryChart
                containerComponent={
                  <VictoryZoomContainer
                    allowPan={false}
                    allowZoom={false}
                    height={height}
                    responsive={false}
                    width={width !== null ? width : undefined}
                    zoomDimension={'x'}
                    zoomDomain={{
                      x: [
                        moment(startDate).subtract(1, 'hour').toDate(),
                        moment(endDate).toDate(),
                      ],
                      y: [0, 100],
                    }}
                  />
                }
                height={height}
                padding={{
                  top: 0,
                  bottom: 24,
                  left: TICK_WIDTH,
                  right: TICK_WIDTH,
                }}
                scale={{ x: 'time' }}
                // @ts-ignore
                theme={chartTheme}
                width={width !== null ? width : undefined}
              >
                <VictoryAxis
                  standalone={false}
                  tickLabelComponent={
                    <VictoryLabel dx={dx} dy={8} lineHeight={16 / 12} />
                  }
                  tickFormat={(date: Date) =>
                    moment(date) <= endDate
                      ? mapYearToEveryMonthExceptThisYear(
                          moment(date).month(),
                          moment(date).year(),
                        )
                      : ''
                  }
                />
                <VictoryAxis
                  dependentAxis
                  standalone={false}
                  style={{
                    axis: { stroke: 'none' },
                  }}
                  tickFormat={(tick: number) => (tick === 100 ? '100%' : '')}
                  tickLabelComponent={
                    <VictoryLabel
                      dx={TICK_WIDTH + 4}
                      dy={8}
                      textAnchor="start"
                    />
                  }
                  tickValues={Y_TICK_VALUES}
                />
                <VictoryAxis
                  dependentAxis
                  orientation="right"
                  standalone={false}
                  tickFormat={() => ''}
                  tickValues={Y_TICK_VALUES}
                />
                <VictoryLine
                  data={data}
                  interpolation="monotoneX"
                  x="date"
                  y="value"
                />
                <VictoryScatter
                  data={dataWithLabels}
                  size={5}
                  x="date"
                  y="value"
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => [
                          {
                            target: 'labels',
                            mutation: () => ({ active: true }),
                          },
                        ],
                        onMouseOut: () => [
                          {
                            target: 'labels',
                            mutation: () => ({ active: false }),
                          },
                        ],
                      },
                    },
                  ]}
                  labelComponent={<ChartTooltip dy={-8} />}
                />
              </VictoryChart>
            </InnerWrapper>

            <ScreenReaderContent as="table">
              <caption>{caption}</caption>
              <tbody>
                <tr>
                  <th scope="col">
                    {translate(
                      'charts.index_line_chart.screen_reader_table.column_1.header',
                    )}
                  </th>
                  <th scope="col">
                    {translate(
                      'charts.index_line_chart.screen_reader_table.column_2.header',
                    )}
                  </th>
                </tr>

                {a11yTableData.map(({ x, y }, idx) => (
                  <tr key={`A11yTable-${caption}-${x},${y}-${idx}`}>
                    <td>{x}</td>
                    <td>{y}</td>
                  </tr>
                ))}
              </tbody>
            </ScreenReaderContent>
          </Wrapper>
        );
      }}
    </SizeMe>
  );
};

export default IndexLineChart;
