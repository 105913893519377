import moment from 'moment';
import { UserWellbeingTrackerScoresForScoreScreen_user_wellbeingTrackerScores_edges_node as WellbeingScore } from 'Track/Wellbeing/__generated__/UserWellbeingTrackerScoresForScoreScreen';
import {
  CategoryName as WellbeingCategoryName,
  IndexScore as WellbeingScoreByType,
} from '../../types/types';

export const removeDuplicateDates = (
  trackerData: WellbeingScore[],
): WellbeingScore[] =>
  trackerData.reduce(
    (deDupedTrackerData: WellbeingScore[], currentValue: WellbeingScore) => {
      const trackerScoreObject = deDupedTrackerData.find(
        (index: WellbeingScore) =>
          moment(index.date).format('YYYY-MM-DD') ===
          moment(currentValue.date).format('YYYY-MM-DD'),
      );

      if (!trackerScoreObject) {
        deDupedTrackerData.push(currentValue);
      }

      return deDupedTrackerData;
    },
    [],
  );

type CategoryScoresByType = {
  [key in WellbeingCategoryName]: WellbeingScoreByType[];
};
export type ScoresByType = {
  total: WellbeingScoreByType[];
} & CategoryScoresByType;

/**
 * TODO: Should look to remove the need for client side mapping of
 * category scores and instead do this on the API and make the object
 * requestable via the `wellbeingTrackerScores` interface.
 */
const getScoresByType = (
  wellbeingTrackerScores: WellbeingScore[],
): ScoresByType => {
  const deDupedTrackerData = removeDuplicateDates(wellbeingTrackerScores);
  const scoresByType: ScoresByType = {
    total: [],
    happiness: [],
    calmness: [],
    coping: [],
    sleep: [],
    health: [],
    fulfilment: [],
    connection: [],
  };

  deDupedTrackerData.forEach(
    ({ date, overallScore, categories }: WellbeingScore) => {
      categories.forEach((category: { key: string; score: number }) => {
        const scoreKey = category.key as keyof CategoryScoresByType;

        scoresByType[scoreKey].push({
          date: new Date(date),
          value: category.score,
        });
      });

      scoresByType.total.push({
        date: new Date(date),
        value: overallScore,
      });
    },
  );

  return scoresByType;
};

export default getScoresByType;
