import { useTranslation, Trans } from 'react-i18next';
import { CheckSquare } from 'icons';
import React, { ReactElement, useContext } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { rem } from 'polished';
import { Description } from 'Compass/Components/CompassDashboardHeader/styles';
import { SurveyStatus } from 'Compass/Components/CompassDashboardHeader/CompassDashboardHeader';
import { v4 as uuid } from 'uuid';
import { pluralize } from 'utils/pluralize';
import { Namespace } from 'i18next';
import { Breakpoints, Colors } from '@unmind/design-system-theme';
import useViewportWidth from 'utils/useViewportWidth';
import { Risk_Category } from '__generated__/globalTypes';
import { useActions } from 'Compass/api/useActions';
import { getActions_recommendedActions as Action } from 'Compass/api/__generated__/getActions';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { tracking } from 'App/Tracking';
import { ActionTakenModal } from 'Compass/Components/ActionTakenModal';
import { ActionTakenContext } from 'Compass/Components/CompassDashboard/ActionTakenProvider';
import RoutePath from 'App/RoutePath';
import { useHistory } from 'react-router';
import { useSubmitInitialMessage } from 'Assistant/Chatbot/hooks';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { getLatestSurveyInfo } from '../getLatestSurveyInfo';
import {
  IMPACT_QUESTONS_THEME_ID,
  mapThemeIdsToThemeName,
  mapThemeIdsToThemeType,
  mapThemeIdsToTranslationKeys,
} from '../constants';
import { SectionHeader } from './Components/SectionHeader';
import { Mosaic } from './Components/Mosaic';
import {
  getStatusColor,
  getDisplayValue,
  ScoreType,
} from './Components/ScoreWithColourStatus';
import { CardHeader } from './Components/styles';
import { TakeActionCheckbox } from './Components/TakeActionCheckbox';
import { RecommendedActionsSelectedCard } from './Components/SelectedCards/RecommendedActionsSelectedCard';

const CheckSquareIcon = styled(CheckSquare).attrs(({ theme }) => ({
  primaryColor: Colors.neutralDark300,
  secondaryColor: theme.colors.text.primary,
}))`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const TextOnlyCell = styled(Description)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: ${rem(20)};
  margin: 0;
`;

const RecommendedActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${rem(20)};
  width: 100%;
  margin: 0;
`;

const RecommendedActionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

interface RecommendedActionsNoDataProps {
  daysUntilSurveyEnds?: number;
  dayOrDays: 'singular' | 'plural';
  surveyStatus: SurveyStatus;
}

const RecommendedActionsNoData = ({
  daysUntilSurveyEnds,
  dayOrDays,
  surveyStatus,
}: RecommendedActionsNoDataProps) => {
  const { t: translate } = useTranslation('compass');

  return (
    <TextOnlyCell>
      {surveyStatus === SurveyStatus.INCOMPLETE
        ? translate('dashboard.recommended_actions.incomplete')
        : translate(`dashboard.recommended_actions.no_data.${dayOrDays}`, {
            numOfDays: daysUntilSurveyEnds,
          })}
    </TextOnlyCell>
  );
};

const StyledThemeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${rem(32)};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize14};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  flex-wrap: wrap;
`;

const StatusIcon = styled.div<{ color: string }>`
  width: ${rem(10)};
  height: ${rem(10)};
  min-width: ${rem(10)};
  min-height: ${rem(10)};
  background-color: ${({ color }) => color};
  border-radius: ${rem(100)};
  align-self: center;
  margin-right: ${rem(4)};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${rem(8)};
`;

const BoldedText = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  margin-left: ${rem(4)};
`;

const ActionScore = ({
  score,
  scoreType,
  designTheme,
  riskCategory,
}: {
  score: number;
  scoreType: ScoreType;
  designTheme: DefaultTheme;
  riskCategory: Risk_Category;
}) => {
  const statusIconColor = getStatusColor(designTheme, riskCategory);

  return (
    <StatusContainer>
      {statusIconColor && <StatusIcon color={statusIconColor} />}
      {getDisplayValue(score, scoreType)}
    </StatusContainer>
  );
};

interface NovaAction {
  title: string;
  theme?: string | null;
}

const RecommendedActionCell = ({
  index,
  action,
  status,
  scheduleId,
}: {
  index: number;
  action: Action | NovaAction;
  status?: ReactElement;
  scheduleId: string;
}) => {
  const { t: translate } = useTranslation<Namespace<'compass'>>('compass');
  const viewportWidth = useViewportWidth();
  const isSmallScreen = viewportWidth < Breakpoints.S;

  // If there's no action ID, this means it not a traditional action but a Nova action
  if (!(action as Action).actionId) {
    return (
      <RecommendedActionContainer data-testid="recommended-action-item">
        <RecommendedActionContent>
          <CardHeader>
            {index}. {action.title}
          </CardHeader>
          <Trans
            t={translate}
            i18nKey={`dashboard.recommended_actions.nova_action_subtext`}
            values={{ theme: (action as NovaAction).theme }}
            components={{
              container: <StyledThemeContainer />,
              strong: <BoldedText />,
            }}
          />
        </RecommendedActionContent>
      </RecommendedActionContainer>
    );
  }

  return (
    <RecommendedActionContainer data-testid="recommended-action-item">
      <RecommendedActionContent>
        <CardHeader>
          {index}. {action.title}
        </CardHeader>
        <Trans
          t={translate}
          i18nKey={`dashboard.recommended_actions.supporting_theme_text.${
            mapThemeIdsToTranslationKeys[
              (action as Action)
                .themeId as keyof typeof mapThemeIdsToTranslationKeys
            ]
          }`}
          components={{
            container: <StyledThemeContainer />,
            status: status ? status : <></>,
            strong: <BoldedText />,
          }}
        />
      </RecommendedActionContent>
      {!isSmallScreen && (
        <TakeActionCheckbox
          ariaLabel={action.title || ''}
          actionId={(action as Action).actionId}
          scheduleId={scheduleId}
        />
      )}
    </RecommendedActionContainer>
  );
};

interface RecommendedActionsProps {
  endDate: Date;
  surveyStatus: SurveyStatus;
  scheduleId: string;
  setSelectedCard(card: React.ReactNode): void;
  readyToShowData: boolean;
}

export const RecommendedActions = ({
  endDate,
  surveyStatus,
  scheduleId,
  setSelectedCard,
  readyToShowData,
}: RecommendedActionsProps) => {
  const { t: translate } = useTranslation('compass');
  const currentDate = new Date();
  const { days } = getLatestSurveyInfo(surveyStatus, currentDate, endDate);
  const dayOrDays = pluralize(days || 0, 'singular', 'plural');
  const designTheme = useTheme();
  const history = useHistory();
  const isNovaEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);
  const isCompassNovaRecEnabled = useFeatureFlag(
    FEATURE_FLAGS.COMPASS_SHOW_NOVA_RECOMMENDATION,
  );
  const [shouldSubmitInitialMessage, setSubmitInitialMessage] =
    useSubmitInitialMessage();
  const { data: actionsData, loading: actionsLoading } = useActions({
    indexScheduleId: scheduleId,
    skip: !readyToShowData,
  });
  const priorityActions =
    actionsData &&
    actionsData?.recommendedActions?.filter(action => action.orderNumber < 5);

  const priorityThemeId =
    actionsData &&
    actionsData.recommendedActions &&
    actionsData?.recommendedActions[0].themeId;
  const priorityThemeName =
    priorityThemeId &&
    mapThemeIdsToThemeName[
      priorityThemeId as keyof typeof mapThemeIdsToThemeName
    ];

  const novaAction: NovaAction = {
    title: translate('dashboard.recommended_actions.nova_action', {
      theme: priorityThemeName,
    }),
    theme: priorityThemeName,
  };

  const { showActionTakenModal, setShowActionTakenModal } =
    useContext(ActionTakenContext);

  if (actionsLoading) {
    return <LoadingIndicator />;
  }

  const handleNovaRedirect = async () => {
    const novaMessage = {
      id: uuid(),
      createdAt: new Date().toISOString(),
      content: translate('dashboard.recommended_actions.nova_message', {
        theme: priorityThemeName,
      }),
    };
    if (!shouldSubmitInitialMessage) {
      await setSubmitInitialMessage(true);
    }

    tracking.track('compass-action-nova-clicked', {
      scheduleId,
      themeType:
        mapThemeIdsToThemeType[
          priorityThemeId as keyof typeof mapThemeIdsToThemeType
        ],
      theme:
        mapThemeIdsToThemeName[
          priorityThemeId as keyof typeof mapThemeIdsToThemeName
        ],
      location: 'DASHBOARD',
    });

    history.push(RoutePath.Chatbot, novaMessage);
  };

  const onClick = (action: Action) => {
    setSelectedCard(
      <RecommendedActionsSelectedCard
        title={action.title || ''}
        scheduleId={scheduleId}
        actionId={action.actionId}
        closeCard={() => setSelectedCard(null)}
      />,
    );

    tracking.track('compass-action-opened', {
      actionId: action.actionId,
      scheduleId,
      themeType:
        mapThemeIdsToThemeType[
          action.themeId as keyof typeof mapThemeIdsToThemeType
        ],
      theme:
        mapThemeIdsToThemeName[
          action.themeId as keyof typeof mapThemeIdsToThemeName
        ],
      riskCategory: action.riskCategory,
      score: action.score,
      actionTitle: action.title,
      markedAsTaken: action.markedAsTaken,
      location: 'DASHBOARD',
    });
  };

  const actionRows = [];

  // Build the rows of actions if they exist
  if (priorityActions && priorityActions.length > 0) {
    // Push the recommended actions into the list
    actionRows.push(
      ...priorityActions.map((action, index) => ({
        id: uuid(),
        data: [
          {
            id: uuid(),
            cell: (
              <RecommendedActionCell
                key={action.actionId}
                data-testid={`recommended-action-item`}
                index={index + 1}
                action={action}
                scheduleId={scheduleId}
                status={
                  <ActionScore
                    score={action.score}
                    designTheme={designTheme}
                    scoreType={
                      action.themeId === IMPACT_QUESTONS_THEME_ID
                        ? ScoreType.PERCENTAGE
                        : ScoreType.FIVE_POINT
                    }
                    riskCategory={action.riskCategory}
                  />
                }
              />
            ),
            onClick: () => onClick(action),
            clickable: true,
          },
        ],
      })),
    );

    // If Nova is enabled & enabled within Compass, push this action into the list
    if (isNovaEnabled && isCompassNovaRecEnabled) {
      actionRows.push({
        id: uuid(),
        data: [
          {
            id: uuid(),
            cell: (
              <RecommendedActionCell
                key={uuid()}
                data-testid={`recommended-action-item`}
                index={actionRows.length + 1}
                action={novaAction}
                scheduleId={scheduleId}
              />
            ),
            onClick: handleNovaRedirect,
            clickable: true,
          },
        ],
      });

      // Push a static "more actions available" tile into the list
      actionRows.push({
        id: uuid(),
        data: [
          {
            id: uuid(),
            cell: (
              <TextOnlyCell>
                {translate('dashboard.recommended_actions.more_actions')}
              </TextOnlyCell>
            ),
            onClick: () => {},
            clickable: false,
          },
        ],
      });
    }
  }

  return (
    <>
      <SectionHeader
        text={translate('dashboard.recommended_actions.title')}
        icon={<CheckSquareIcon />}
        description={translate('dashboard.recommended_actions.description')}
      />
      <Mosaic
        data-testid="recommended-actions"
        rowEntries={
          priorityActions && priorityActions.length > 0
            ? actionRows
            : [
                {
                  id: uuid(),
                  data: [
                    {
                      id: uuid(),
                      cell: (
                        <RecommendedActionsNoData
                          surveyStatus={surveyStatus}
                          daysUntilSurveyEnds={days}
                          dayOrDays={dayOrDays}
                        />
                      ),
                    },
                  ],
                },
              ]
        }
      />
      {showActionTakenModal && (
        <ActionTakenModal closeModal={() => setShowActionTakenModal(false)} />
      )}
    </>
  );
};
