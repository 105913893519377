import { rem } from 'polished';
import React, { ReactNode, useEffect, useState } from 'react';
import { css, styled } from 'styles';
import { isIOS } from 'Shared/MediaPlayerComponents/isIOS';
import { AddToCalendar } from 'Shared/AddToCalendar';
import { BodyText } from '@unmind/design-system-components-web';
import { DAILY_BOOST_CATEGORY_SLUG } from 'Shorts/constants';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { small, medium, extraSmall } from '../../utils';
import {
  BackButton,
  JumpBackButton,
  JumpForwardButton,
  Status,
  TranscriptButton,
  PlaybackControlButton,
  LoopButton,
} from '../MediaPlayerComponents';
import ErrorState from '../MediaPlayerComponents/ErrorState';
import { MediaLoadingIndicator } from '../MediaPlayerComponents/MediaLoadingIndicator';
import VolumeControl from '../MediaPlayerComponents/VolumeControl';
import ProgressBar from '../MediaPlayerComponents/ProgressBar';
import useMediaPlayerControlVisibility from '../MediaPlayerComponents/useMediaPlayerControlVisibility';
import HeadingText from '../Typography/HeadingText';
import AudioAnimation from './AudioAnimation';

const SHOW_HIDE_DURATION = '300ms';

const Header = styled.div<{ show: boolean }>`
  padding: ${rem(14)} ${rem(16)} ${rem(4)} ${rem(4)};
  transition: transform ${SHOW_HIDE_DURATION} ease-in-out;
  ${props =>
    props.show
      ? `transform: translateY(0rem);`
      : `transform: translateY(-100%);
  `}

  ${small(css`
    padding: ${rem(16)} ${rem(18)} ${rem(4)};
  `)}

  ${medium(css`
    padding: ${rem(40)} ${rem(32)} ${rem(4)};
  `)}
`;

export const OVERLAY_TEST_ID = 'audio-player-overlay';
const Overlay = styled.div<{ show: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity ${SHOW_HIDE_DURATION};
  ${props =>
    props.show
      ? `opacity: 1;`
      : `opacity: 0;
         cursor: none;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  z-index: 1;
  padding: 0 ${rem(16)};

  ${small(css`
    padding: 0 ${rem(36)};
  `)}

  ${medium(css`
    padding: 0 ${rem(52)};
  `)}
`;

const CenterButtonContainer = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;

  ${small(css`
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
  `)}
`;

const ControlContainer = styled.div<{ show: boolean }>`
  padding: ${rem(16)} ${rem(24)};
  pointer-events: none;
  transition: transform ${SHOW_HIDE_DURATION} ease-in-out;
  ${props =>
    props.show
      ? `transform: translateY(0rem);`
      : `transform: translateY(100%);
  `}

  ${small(css`
    padding: ${rem(36)} ${rem(52)};
  `)}

  ${medium(css`
    padding: ${rem(52)} ${rem(52)};
  `)}
`;

const VolumeControlContainer = styled(VolumeControl)`
  display: flex;
  flex: 1;
`;

const EmptyCell = styled.div`
  display: flex;
  flex: 1;
`;

const SubControlContainer = styled.div`
  > * {
    pointer-events: auto;
  }

  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(52)};
  width: 100%;
`;

const ButtonContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  visibility: ${props => !props.isVisible && 'hidden'};
  margin-top: ${rem(24)};

  ${small(css`
    margin-top: ${rem(36)};
  `)}

  ${medium(css`
    margin-top: ${rem(52)};
  `)}

  > * + * {
    margin-left: ${rem(16)};
  }
`;

const CalendarContainer = styled.div`
  margin-top: ${rem(23)};
  display: flex;
  width: ${rem(245)};
  position: relative;
`;

const PositionedSettingsButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  & > * + * {
    margin-left: ${rem(4)};

    ${small(css`
      margin-left: ${rem(8)};
    `)}
  }
`;

const PlaybackControlContainer = styled.div`
  align-items: center;
  justify-content: center;
  ${small(css`
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
  `)}
`;

const TitleWrapper = styled.div<{ show: boolean }>`
  transition: transform ${SHOW_HIDE_DURATION} ease-in-out;

  ${props =>
    props.show
      ? `transform: translateX(0rem);`
      : `transform: translateX(-100%);
`}
`;

const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.medium,
}))<{ isAudioPlayer?: boolean }>`
  ${({ isAudioPlayer, theme }) =>
    isAudioPlayer
      ? `color: ${theme.colors.mediaPlayer.audio.icon.default}`
      : `color: ${theme.colors.staticPalette.white}`}
  font-size: ${rem(52)}
  ${medium(css`
    max-width: 50%;
  `)}
  position: relative;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (orientation: landscape),
    (min-width: ${props => rem(props.theme.layout.breakpoints.S)}) {
    background-size: 190vw 177vh;
    background-position: right -12% bottom 5%;
  }
`;

const SubTitleContainer = styled(BodyText)`
  margin-top: ${rem(23)};
`;

const CalendarIconContainer = styled.div`
  position: relative;
  display: none;

  ${extraSmall(css`
    display: flex;
  `)}
`;

export interface AudioPlayerUIProps {
  status: Status;
  backButtonLabel: string;
  currentTime: number;
  duration: number;
  volume: number;
  hasTranscript?: boolean;
  loop: boolean;
  onClickBackButton(event: React.MouseEvent<HTMLButtonElement>): void;
  onClickPause(): void;
  onClickPlay(): void;
  onClickAudioPlayer(): void;
  onClickReplay(): void;
  onClickJumpBack({ method }: { method: string }): void;
  onClickJumpForward({ method }: { method: string }): void;
  onVolumeChange(volume: number): void;
  onClickLoop(event: React.MouseEvent<HTMLButtonElement>): void;
  onClickToggleMute(): void;
  onClickTranscript?(): void;
  onProgressChange(changeAmount: number): void;
  handleDrag({ startDraggingValue }: { startDraggingValue?: number }): void;
  onSeek({
    startPosition,
    method,
  }: {
    startPosition: number;
    method: string;
  }): void;
  renderNextStepButton?: ReactNode;
  title: string;
  summary?: string;
  handleCalendarModalToggle(): void;
  showCalendarIntegration?: boolean;
}

const AudioPlayerUI = ({
  status,
  backButtonLabel,
  currentTime = 0,
  duration,
  volume,
  hasTranscript = false,
  loop,
  onClickBackButton,
  onClickPause,
  onClickPlay,
  onClickAudioPlayer,
  onClickReplay,
  onClickJumpBack,
  onClickJumpForward,
  onVolumeChange,
  onClickLoop,
  onClickToggleMute,
  onClickTranscript,
  onProgressChange,
  onSeek,
  handleDrag,
  renderNextStepButton,
  title,
  summary,
  handleCalendarModalToggle,
  showCalendarIntegration = true,
}: AudioPlayerUIProps) => {
  const {
    isInterfaceVisible,
    handleMouseMove,
    onControlMouseOver,
    onControlMouseOut,
    onActionKeyPress,
  } = useMediaPlayerControlVisibility(status);
  const { t: translate } =
    useTranslation<Namespace<'media_player'>>('media_player');

  const [hasPressedPlay, setHasPressedPlay] = useState(false);

  useEffect(() => {
    focusViaSelector('.unmind-media-progress-handle');
  }, []);

  const showJumpButtons = status !== 'ended';

  const focusViaSelector = (selector: string) => {
    const element = document.querySelector<HTMLElement>(selector);
    element?.focus();
  };

  const skipAnimationFocusFromBackButton = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && e.shiftKey) {
      focusViaSelector('.unmind-media-progress-handle');
      e.preventDefault();
    }
  };

  const skipAnimationFocusFromSlider = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      focusViaSelector('#audio-player-back-button');
      e.preventDefault();
    }
  };

  const isDailyBoost =
    location.pathname.split('/')[2] === DAILY_BOOST_CATEGORY_SLUG;

  // Temporary fix for ENG-12714 as mute button doesn't work on iOS and is preventing publishing in MS Teams
  const displayMuteButton = !isIOS();

  const contentPath = window.location.pathname;

  const onAddToCalendarModalToggled = (isOpeningModal: boolean) => {
    handleCalendarModalToggle();

    if (hasPressedPlay) {
      if (status === 'playing') {
        onClickPause();
      } else if (status === 'paused' && !isOpeningModal) {
        onClickPlay();
      }
    }
  };

  return (
    <Container>
      {status !== 'error' && <AudioAnimation isPaused={status !== 'playing'} />}
      <Overlay
        show={isInterfaceVisible}
        data-testid={OVERLAY_TEST_ID}
        onMouseMove={handleMouseMove}
        onKeyDown={onActionKeyPress}
      >
        <Header show={isInterfaceVisible} data-testid="audio-player-header">
          <BackButton
            disabled={status === 'error'}
            label={backButtonLabel}
            onClick={onClickBackButton}
            onMouseOver={onControlMouseOver}
            onMouseOut={onControlMouseOut}
            onKeyDown={skipAnimationFocusFromBackButton}
            id="audio-player-back-button"
            data-testid="audio-player-back-button"
            isAudioPlayer={true}
          />
        </Header>
        <ContentContainer
          data-testid="audio-player-clickable-area"
          onClick={status !== 'ended' ? onClickAudioPlayer : undefined}
        >
          <TitleWrapper show={isInterfaceVisible}>
            <Title isAudioPlayer={true}>{title}</Title>
          </TitleWrapper>
          {showCalendarIntegration && (
            <>
              {isDailyBoost && (
                <SubTitleContainer>
                  {translate('daily_boost.subtitle')}
                </SubTitleContainer>
              )}
              <CalendarContainer onClick={e => e.stopPropagation()}>
                <AddToCalendar
                  buttonTextKey={'add_to_calendar.text'}
                  description={summary}
                  title={title}
                  onAddToCalendarModalToggled={onAddToCalendarModalToggled}
                  contentPath={contentPath}
                ></AddToCalendar>
              </CalendarContainer>
            </>
          )}
          <ButtonContainer isVisible={status === 'ended'}>
            {renderNextStepButton}
          </ButtonContainer>
        </ContentContainer>
        {status !== 'error' && (
          <ControlContainer
            show={isInterfaceVisible}
            data-testid="audio-player-control-container"
            onMouseOver={onControlMouseOver}
            onMouseOut={onControlMouseOut}
          >
            <SubControlContainer>
              {displayMuteButton ? (
                <VolumeControlContainer
                  data-testid="positioned-volume-control"
                  volume={volume}
                  onChange={onVolumeChange}
                  onClickToggleMute={onClickToggleMute}
                  isAudioPlayer={true}
                />
              ) : (
                <EmptyCell></EmptyCell>
              )}

              <CenterButtonContainer>
                {showJumpButtons && (
                  <JumpBackButton
                    onClick={onClickJumpBack}
                    isAudioPlayer={true}
                  />
                )}
                <PlaybackControlContainer>
                  <PlaybackControlButton
                    status={status}
                    onClickPlay={() => {
                      setHasPressedPlay(true);
                      onClickPlay();
                    }}
                    onClickPause={onClickPause}
                    onClickReplay={onClickReplay}
                    isAudioPlayer={true}
                  />
                  {status === 'loading' && (
                    <MediaLoadingIndicator isAudioPlayer={true} />
                  )}
                </PlaybackControlContainer>
                {showJumpButtons && (
                  <JumpForwardButton
                    onClick={onClickJumpForward}
                    isAudioPlayer={true}
                  />
                )}
              </CenterButtonContainer>
              <PositionedSettingsButtons>
                {showCalendarIntegration && (
                  <CalendarIconContainer>
                    <AddToCalendar
                      displayBorder={false}
                      description={summary}
                      contentPath={contentPath}
                      title={title}
                      isKeyDownListenerEnabled={true}
                      onAddToCalendarModalToggled={onAddToCalendarModalToggled}
                    />
                  </CalendarIconContainer>
                )}
                <LoopButton
                  onClick={onClickLoop}
                  loop={loop}
                  isAudioPlayer={true}
                />
                {hasTranscript && (
                  <TranscriptButton
                    onClick={onClickTranscript}
                    isAudioPlayer={true}
                  />
                )}
              </PositionedSettingsButtons>
            </SubControlContainer>
            <ProgressBar
              handleDrag={handleDrag}
              currentTime={currentTime}
              duration={duration}
              onChange={onProgressChange}
              onSeek={onSeek}
              onKeyDown={skipAnimationFocusFromSlider}
              isAudioPlayer={true}
            />
          </ControlContainer>
        )}
      </Overlay>
      {status === 'error' && (
        <ErrorState onClickCloseMedia={onClickBackButton} />
      )}
    </Container>
  );
};

export default AudioPlayerUI;
