import { useQuery } from '@apollo/client';
import {
  talkContactSessionLimitOverridesQuery,
  talkContactSessionLimitOverridesQuery_contacts_edges,
} from 'Services/BeGateway/Talk/__generated__/talkContactSessionLimitOverridesQuery';
import {
  talkUserSessionLimitsQuery,
  talkUserSessionLimitsQueryVariables,
} from 'Services/BeGateway/Talk/__generated__/talkUserSessionLimitsQuery';
import {
  USER_SESSION_LIMITS_QUERY,
  TALK_CONTACT_SESSION_LIMITS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import { defaultQueryConfig, BEGatewayQueryContext } from 'utils/apollo';
import { useTalkUserDetails } from '.';

export type TalkUserSessionLimits = Omit<
  ReturnType<typeof useTalkUserSessionLimits>,
  'loading' | 'error'
>;

export const useTalkUserSessionLimits = () => {
  const {
    user: { id, organisationSessionLimit },
  } = useTalkUserDetails();

  const {
    data: sessionLimitData,
    loading,
    error,
  } = useQuery<talkUserSessionLimitsQuery, talkUserSessionLimitsQueryVariables>(
    USER_SESSION_LIMITS_QUERY,
    {
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
    },
  );

  const {
    data: overrideData,
    loading: overrideLoading,
    error: overrideError,
  } = useQuery<talkContactSessionLimitOverridesQuery>(
    TALK_CONTACT_SESSION_LIMITS_QUERY,
    {
      variables: {
        first: 1,
        unmindUserId: id ?? '',
      },
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
    },
  );

  const sessionLimitOverrides =
    (overrideData?.contacts?.edges?.filter(
      Boolean,
    ) as talkContactSessionLimitOverridesQuery_contacts_edges[]) || null;
  const sessionLimit =
    sessionLimitOverrides?.[0]?.node?.sessionLimitOverride ||
    organisationSessionLimit;
  const remainingSessions =
    sessionLimitData?.unmindUserSessionLimits?.amountRemaining || 0;

  return {
    loading: loading || overrideLoading,
    error: error || overrideError,
    sessionLimit,
    remainingSessions,
  };
};
