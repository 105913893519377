import React from 'react';
import { IconProps } from '../IconProps';

const SvgGraph = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.604.004a.738.738 0 0 0-.585.53C.004.586.004.889.004 10c0 9.11 0 9.413.015 9.464a.719.719 0 0 0 .2.333c.097.094.19.148.317.184.05.014.353.015 9.464.015 9.118 0 9.413 0 9.464-.015.26-.074.476-.298.52-.541a.388.388 0 0 1 .017-.066c.004-.008.006-3.904.005-9.388-.001-6.035-.003-8.099-.006-5.794-.002 1.969-.005 3.57-.006 3.56a.673.673 0 0 0-.246-.439c-.03-.026-1.184-.852-2.564-1.837-1.633-1.167-2.533-1.805-2.58-1.83a.562.562 0 0 0-.308-.073.64.64 0 0 0-.18.017.72.72 0 0 0-.373.231A887.88 887.88 0 0 0 11.08 7.8c-1.44 2.16-2.62 3.927-2.623 3.927-.003 0-.676-.67-1.494-1.489A174.142 174.142 0 0 0 5.43 8.714a.697.697 0 0 0-.512-.14.629.629 0 0 0-.246.073.746.746 0 0 0-.37.464.906.906 0 0 0-.01.172.58.58 0 0 0 .017.2.732.732 0 0 0 .133.248c.08.099 3.672 3.683 3.727 3.719.109.07.208.105.33.116.23.02.422-.05.583-.212.035-.036.71-1.04 2.715-4.05a1113.01 1113.01 0 0 1 2.675-4.001c.003 0 .996.707 2.206 1.571s2.226 1.587 2.257 1.605c.224.134.533.12.759-.034a.884.884 0 0 0 .18-.186.777.777 0 0 0 .12-.292c0-.016.003 2.506.003 5.604 0 4.633-.001 5.622-.01 5.575a.72.72 0 0 0-.527-.558l-.065-.017-8.982-.002-8.982-.002-.002-8.982L1.428.603 1.41.538A.723.723 0 0 0 .85.01 1.077 1.077 0 0 0 .604.004zM.004 10c0 5.159 0 7.27.002 4.69V5.31C.005 2.73.004 4.84.004 10z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgGraph;
