import { rem, rgba } from 'polished';
import { css, styled } from 'styles';
import { PlayCircular } from 'icons';
import { medium } from '../../utils';

const PlayButton = styled(PlayCircular).attrs(({ theme }) => ({
  width: rem(32),
  height: rem(32),
  primaryColor: theme.button.primary.default.backgroundColor,
  secondaryColor: theme.button.primary.default.textColor,
}))`
  border-radius: 50%;
  backdrop-filter: blur(2px);
  background-color: ${({ theme }) =>
    rgba(theme.colors.background.primary, 0.65)};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.12);

  transition: all 0.15s ease-in-out;

  ${medium(css`
    width: ${rem(40)};
    height: ${rem(40)};
  `)}
`;

export default PlayButton;
