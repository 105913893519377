import React from 'react';
import { IconProps } from '../IconProps';

const SvgJumpBack = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 39.91 45.86"
    fill={primaryColor}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M25 3.7V1.5A1.5 1.5 0 0 0 22.83.16L16.54 3.3a1.51 1.51 0 0 0 0 2.69l6.29 3.14A1.5 1.5 0 0 0 25 7.79v-1a17.34 17.34 0 1 1-16.21 3.77 1.5 1.5 0 0 0-2-2.23A20.36 20.36 0 1 0 25 3.7z" />
    <path d="M31 28.67v-9.55c0-6.81-11-6.81-11 0v9.55c0 6.8 11 6.8 11 0zM23 19c0-2.79 5-2.79 5 0v9.71c0 2.79-5 2.79-5 0zM9.68 20.39a1.58 1.58 0 0 0 1.9-.09L13 19.16V31.9a1.49 1.49 0 0 0 1.88 1.3A1.56 1.56 0 0 0 16 31.66V15.91a1.5 1.5 0 0 0-2.43-1L9.63 18l-.11.1a1.51 1.51 0 0 0 .16 2.29z" />
  </svg>
);

export default SvgJumpBack;
