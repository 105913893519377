import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { Highlight } from '../SeriesShared';

interface Response {
  singleSeries: {
    id: string;
    title: string;
    subtitle: string;
    totalDays: number;
    minsPerDay?: {
      stringValue: number;
    };
    benefit1: {
      stringValue: string;
    };
    benefit2: {
      stringValue: string;
    };
    benefit3: {
      stringValue: string;
    };
    description: {
      longValue: string;
    };
    seriesDays: {
      id: string; // Unused but needed to avoid caching bug (see ENG-1285)
      title: string;
      orderNo: number;
      dayHighlight1: string;
      dayHighlight2: string;
      dayHighlight3: string;
    }[];
    hasCompletedPreviousVersion: boolean;
    isNotAnonymised?: boolean;
  };
}

export interface InputProps {
  slug: string;
}

export interface Variables {
  slug: string;
}

export interface SingleSeries {
  id: string;
  title: string;
  subtitle: string;
  totalDays: number;
  minsPerDay?: number;
  benefit1: string;
  benefit2: string;
  benefit3: string;
  description: string;
  seriesDays: {
    title: string;
    orderNo: number;
    highlights: Highlight[];
  }[];
  hasCompletedPreviousVersion: boolean;
  isNotAnonymised?: boolean;
}

export interface WithSingleSeriesChildProps {
  singleSeriesLoading: boolean;
  singleSeries?: SingleSeries;
}

export const withSingleSeriesQuery = gql`
  query WithSingleSeries($slug: String!) {
    singleSeries(slug: $slug) {
      id
      title
      subtitle
      totalDays
      benefit1 {
        stringValue
      }
      benefit2 {
        stringValue
      }
      benefit3 {
        stringValue
      }
      description {
        longValue
      }
      minsPerDay {
        numericValue
      }
      seriesDays {
        id # Unused but needed to avoid caching bug (see ENG-1285)
        title
        orderNo
        dayHighlight1
        dayHighlight2
        dayHighlight3
      }
      hasCompletedPreviousVersion
      isNotAnonymised
    }
  }
`;

export const withSingleSeries = graphql<
  InputProps,
  Response,
  Variables,
  WithSingleSeriesChildProps
>(withSingleSeriesQuery, {
  // @ts-ignore: Manually tested fetch policy behaviour
  options: ({ slug }) => ({
    variables: { slug },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data }) => {
    const minsPerDay = get(data, 'singleSeries.minsPerDay.numericValue');
    const description = get(data, 'singleSeries.description.longValue');
    const benefit1 = get(data, 'singleSeries.benefit1.stringValue');
    const benefit2 = get(data, 'singleSeries.benefit2.stringValue');
    const benefit3 = get(data, 'singleSeries.benefit3.stringValue');
    const hasCompletedPreviousVersion = get(
      data,
      'singleSeries.hasCompletedPreviousVersion',
      false,
    );

    if (data && data.singleSeries) {
      const daysObj = data.singleSeries.seriesDays.map(
        ({ orderNo, title, dayHighlight1, dayHighlight2, dayHighlight3 }) => {
          const highlights = [
            { text: dayHighlight1 },
            { text: dayHighlight2 },
            { text: dayHighlight3 },
          ];

          return {
            highlights,
            orderNo,
            title,
          };
        },
      );

      return {
        singleSeries: {
          ...data.singleSeries,
          benefit1,
          benefit2,
          benefit3,
          description,
          minsPerDay,
          seriesDays: daysObj,
          hasCompletedPreviousVersion,
        },
        singleSeriesLoading: false,
      } as WithSingleSeriesChildProps;
    }

    return {
      singleSeries: undefined,
      singleSeriesLoading: data !== undefined ? data.loading : true,
    };
  },
});
