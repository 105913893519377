import gql from 'graphql-tag';

export const PlusOneFragment = gql`
  fragment PlusOneFragment on PlusOne {
    id
    firstName
    emailAddress
    status
    createdAt
    joinedOn
  }
`;
