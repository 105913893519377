/**
 * Calculate the total number of steps that the user must take to get to the end
 * of the sign up flow.
 */
export function calculateNumberOfSteps(
  showCollectWorkDetails?: boolean,
  showExplicitPrivacyCheckbox?: boolean,
) {
  let numberOfSteps = 1;

  // If the locations or departments have items in the array, the work details page
  // will be rendered, which adds another step to the process.
  if (showCollectWorkDetails) {
    numberOfSteps++;
  }

  // If we should show the separate privacy consent page, that adds a step
  // Remove this if statement once the feature flag `show-explicit-privacy-checkbox` is gone
  // and we have a permanent solution for all this consent stuff
  if (showExplicitPrivacyCheckbox) {
    numberOfSteps++;
  }

  return numberOfSteps;
}

export const calculateCurrentStep = (showCollectWorkDetails?: boolean) => ({
  emailStepCount: 1,
  workDetailsStepCount: 2,
  privacyConsentStepCount: showCollectWorkDetails ? 3 : 2,
});
