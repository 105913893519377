import { findLastIndex } from 'lodash';
import { ExtendedSeriesDay } from './withSeriesDays';

export const isSeriesInProgress = (
  seriesDays: ExtendedSeriesDay[] | undefined,
): boolean =>
  seriesDays !== undefined &&
  seriesDays.some(({ segmentsComplete }) => segmentsComplete > 0) &&
  !seriesDays.every(({ complete }) => complete);

export const isSeriesComplete = (
  seriesDays: ExtendedSeriesDay[] | undefined,
): boolean =>
  seriesDays !== undefined && seriesDays.every(({ complete }) => complete);

export const getActiveSeriesDay = (
  seriesDays: ExtendedSeriesDay[] | undefined,
): number =>
  isSeriesInProgress(seriesDays)
    ? findLastIndex(seriesDays, ({ complete }) => complete) + 2
    : 1;

export interface ActiveDayStatus {
  activeDayStarted: boolean;
  activeDayComplete: boolean;
  activeDayId: string;
}

const defaultDayStatus: ActiveDayStatus = {
  activeDayStarted: false,
  activeDayComplete: false,
  activeDayId: '',
};

export const getActiveDayStatus = (
  activeDay: number,
  seriesDays: ExtendedSeriesDay[] | undefined,
): ActiveDayStatus => {
  if (seriesDays === undefined) {
    return defaultDayStatus;
  }

  const activeSeriesDay = seriesDays.find(({ day }) => day === activeDay);

  if (activeSeriesDay === undefined) {
    return defaultDayStatus;
  }

  return {
    activeDayStarted: activeSeriesDay.started,
    activeDayComplete: activeSeriesDay.complete,
    activeDayId: activeSeriesDay.id,
  };
};
