import gql from 'graphql-tag';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';

interface InputProps {
  id: string;
}

interface Response {
  seriesSegment: {
    transcript: {
      unknownValue: string;
    };
  };
}

interface Variables {
  id: string;
}

export interface WithSegmentTranscriptChildProps {
  transcriptLoading: boolean;
  transcript: string;
}

export const withSegmentTranscriptQuery = gql`
  query seriesSegmentTranscript($id: String!) {
    seriesSegment(id: $id) {
      transcript {
        unknownValue
      }
    }
  }
`;

export const withSegmentTranscript = graphql<
  InputProps,
  Response,
  Variables,
  WithSegmentTranscriptChildProps
>(withSegmentTranscriptQuery, {
  options: ({ id }) => ({
    variables: { id },
  }),
  props: ({ data }) => {
    const transcript = get(data, 'seriesSegment.transcript.unknownValue', '');

    return {
      transcriptLoading: Boolean(data && data.loading),
      transcript: transcript,
    };
  },
});
