import React from 'react';
import { IconProps } from '../IconProps';

const SvgCalendar = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.855.011a.732.732 0 0 0-.55.522L4.283.61l-.002 2.18c-.002 1.53 0 2.207.006 2.272a.7.7 0 0 0 .094.3.89.89 0 0 0 .254.254.734.734 0 0 0 .592.061.733.733 0 0 0 .474-.526c.011-.046.013-.152.016-.815l.003-.762 3.265-.002 3.266-.002.06-.017a.744.744 0 0 0 .522-.504.575.575 0 0 0 .018-.195.547.547 0 0 0-.064-.309.736.736 0 0 0-.328-.33.632.632 0 0 0-.263-.073 353.73 353.73 0 0 0-3.279-.008H5.72l-.003-.762L5.714.61 5.693.532a.731.731 0 0 0-.57-.526.988.988 0 0 0-.268.004zm10 0a.732.732 0 0 0-.55.522l-.022.078-.002 2.18c-.002 1.53 0 2.207.006 2.272a.7.7 0 0 0 .094.3.89.89 0 0 0 .254.254.734.734 0 0 0 .592.061.733.733 0 0 0 .474-.526c.011-.046.013-.152.016-.815l.003-.762 1.133.002 1.134.002.057.018a.727.727 0 0 1 .502.51c.016.056.017.068.019 1.542l.002 1.486H1.431V5.681c0-.95.003-1.472.008-1.506a.713.713 0 0 1 .694-.6c.177-.013.283-.042.388-.111a.744.744 0 0 0 .312-.415.576.576 0 0 0 .018-.194.547.547 0 0 0-.064-.309.785.785 0 0 0-.282-.308.702.702 0 0 0-.398-.095A2.141 2.141 0 0 0 .011 4.049c-.011.094-.012.896-.01 7.07.002 6.718.003 6.967.017 7.052a2.153 2.153 0 0 0 1.817 1.809c.093.015.32.015 8.164.015 7.844 0 8.07 0 8.164-.015.383-.06.746-.22 1.044-.458a2.72 2.72 0 0 0 .315-.315c.14-.175.268-.4.346-.608a2.25 2.25 0 0 0 .123-.547c0-.015.003-.03.008-.033.005-.003.008-2.33.008-6.957 0-3.824-.003-6.95-.006-6.948a.268.268 0 0 1-.016-.086 2.158 2.158 0 0 0-.453-1.08 2.517 2.517 0 0 0-.339-.339 2.199 2.199 0 0 0-.902-.425c-.216-.044-.18-.043-1.422-.047l-1.15-.003-.002-.762-.003-.761-.021-.078a.731.731 0 0 0-.57-.526.989.989 0 0 0-.268.004zM.003 11.071c0 3.826 0 5.39.002 3.478V7.593C.004 5.68.003 7.245.003 11.071zm1.43 2.206l.002 4.702.022.072a.72.72 0 0 0 .49.49l.072.022h15.96l.072-.022a.72.72 0 0 0 .49-.49l.022-.072.002-4.702.002-4.702H1.431l.002 4.702z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgCalendar;
