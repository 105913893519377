import { rem } from 'polished';
import React, { useContext, useState } from 'react';
import {
  ActionMeta,
  default as Select,
  OptionProps,
  StylesConfig,
  ValueType,
} from 'react-select';
import { ThemeContext } from 'styled-components';
import { css, styled, ThemeInterface } from 'styles';
import { FormElementDisplay } from '../Layout';
import getFocusState from '../Button/getFocusState';
import BodyText from '../Typography/BodyText';
import { InteractiveContainer } from './InteractiveContainer';

export enum SingleSelectSize {
  Compact = 'Compact',
  Regular = 'Regular',
  Big = 'Big',
}

// The types in the library for react-select are not complete,
// we've had to augment them below to reflect what actually
// comes through.

type IsMulti = false;

const getSingleSelectStyles = <T extends BaseOptionValue>(
  theme: ThemeInterface,
  size?: SingleSelectSize,
): StylesConfig<SingleSelectOption<T>, IsMulti> => {
  const {
    colors: { multiSelect: singleSelectTheme },
  } = theme;

  return {
    menu: provided => ({
      ...provided,
      zIndex: +2,
    }),
    placeholder: provided => ({
      ...provided,
      opacity: 1,
      color: theme.colors.text.secondary,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.colors.text.primary,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: state.isFocused
        ? `0 0 0 1px ${theme.colors.primary}`
        : 'transparent',
    }),
    input: provided => ({
      ...provided,
      marginTop: '4px',
      marginBottom: '4px',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: size === SingleSelectSize.Compact ? '5px' : '8px',
    }),
    option: (provided, state) => ({
      ...provided,
      ':hover': {
        backgroundColor: singleSelectTheme.option.hover.backgroundColor,
      },
      ':active': {
        backgroundColor: singleSelectTheme.option.active.backgroundColor,
      },
      backgroundColor: state.isFocused
        ? singleSelectTheme.option.backgroundColor
        : 'transparent',
      color: state.isDisabled
        ? theme.colors.text.disabled
        : theme.colors.text.primary,
    }),
  };
};

const Container = styled(InteractiveContainer)<{
  containerType?: FormElementDisplay;
  open: boolean;
}>`
  position: relative;
  flex-direction: column;
  display: inline-block;
  width: 100%;
  border: ${rem('1px')} solid ${({ theme }) => theme.colors.border.secondary}
    ${({ containerType }) =>
      containerType === FormElementDisplay.Inline &&
      css`
        display: flex;
        width: auto;
        border: none;
      `}
    ${({ open }) => {
      if (open) {
        return css`
          border: ${rem('1px')} solid ${({ theme }) => theme.colors.primary};
          ${getFocusState()}
        `;
      }
    }};
`;

export type BaseOptionValue = string | number;

export interface SingleSelectOption<T extends BaseOptionValue> {
  label: string;
  value: T;
  isDisabled?: boolean;
}

interface SingleSelectProps<T extends BaseOptionValue> {
  onChange?(
    value: ValueType<SingleSelectOption<T>, IsMulti>,
    action?: ActionMeta<SingleSelectOption<T>>,
  ): void;
  error?: string;
  options?: SingleSelectOption<T>[];
  defaultSelectedValue?: SingleSelectOption<T>;
  placeholder: string;

  /**
   * Changes component's behaviour between Inline and Block
   */
  display?: FormElementDisplay;
  inputId?: string;
  size?: SingleSelectSize;
  customOption?(
    props: OptionProps<SingleSelectOption<T>, IsMulti>,
  ): JSX.Element;
  value?: SingleSelectOption<T>;
  ariaLabel?: string;

  /**
   * Priorise select options in a modal
   */
  isInModal?: boolean;
}

export const Error = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  color: theme.colors.text.error,
}))`
  margin: ${rem('4px')} 0 ${rem('10px')};
  text-align: left;
`;

export const SingleSelect = <T extends BaseOptionValue>({
  onChange,
  placeholder = 'Select',
  options = [],
  display,
  error,
  defaultSelectedValue,
  inputId,
  size,
  customOption,
  value,
  isInModal = false,
  ariaLabel,
}: SingleSelectProps<T>) => {
  const themeContext: ThemeInterface = useContext(ThemeContext);
  const SingleSelectStyles = getSingleSelectStyles<T>(themeContext, size);

  const [isMenuOpen, setisMenuOpen] = useState(false);

  const hasError = Boolean(error);

  return (
    <>
      <Container containerType={display} hasError={hasError} open={isMenuOpen}>
        <Select
          options={options}
          isOptionDisabled={option => !!option.isDisabled}
          placeholder={placeholder}
          styles={
            !isInModal
              ? SingleSelectStyles
              : {
                  ...SingleSelectStyles,
                  menuPortal: base => ({ ...base, zIndex: 10050 }),
                }
          }
          defaultValue={defaultSelectedValue}
          onMenuClose={() => setisMenuOpen(false)}
          onMenuOpen={() => setisMenuOpen(true)}
          onChange={onChange}
          inputId={inputId}
          value={value}
          instanceId={inputId}
          customOption={customOption}
          menuPortalTarget={isInModal ? document.body : undefined}
          aria-label={ariaLabel}
        />
      </Container>
      {hasError && <Error>{error}</Error>}{' '}
    </>
  );
};
