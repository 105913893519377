import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { Error } from 'icons';
import { HeadingText } from '../../../Shared/Typography';
import { small } from '../../../utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  margin: 16px 16px 20px 16px;
  border-radius: 8px;

  ${small(css`
    margin: 24px 20px 32px 20px;
  `)}
`;

const Contents = styled.div`
  text-align: center;
`;

const ErrorIcon = styled(Error).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.error,
  height: '24px',
  width: '24px',
}))`
  margin-bottom: 16px;
`;

const StyledHeading = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  align: 'center',
}))`
  margin-bottom: 8px;
`;

const ErrorMessage = () => {
  const { t: translate } = useTranslation('shared');

  return (
    <Container>
      <Contents>
        <ErrorIcon />
        <StyledHeading>{translate('errors.messages.failed')}</StyledHeading>
      </Contents>
    </Container>
  );
};

export default ErrorMessage;
