import gql from 'graphql-tag';

/*
 *  Fragments
 */
export const TALK_PRACTITIONER_PROFILE = gql`
  fragment talkPractitionerProfile on UserProfile {
    id
    firstName
    lastName
    photoUrls
    phone
  }
`;

export const TALK_SESSION_FRAGMENT = gql`
  fragment talkSessionFragment on Session {
    id
    password
    externalRef
  }
`;

export const TALK_EVENT_GUESTS = gql`
  fragment talkEventGuests on EventGuest {
    id
    externalRef
  }
`;

export const TALK_EVENT_FRAGMENT = gql`
  ${TALK_SESSION_FRAGMENT}
  ${TALK_PRACTITIONER_PROFILE}
  ${TALK_EVENT_GUESTS}
  fragment talkEventFragment on Event {
    id
    name
    accountId
    description
    location
    beginsAt
    endsAt
    recurring
    timeZone
    type
    source
    createdBy
    comment
    status
    icalendar
    zoomMeetingDetails
    session {
      ...talkSessionFragment
    }
    userProfile {
      ...talkPractitionerProfile
    }
    eventGuests {
      ...talkEventGuests
    }
  }
`;

/*
 * Queries
 */
export const TALK_EVENTS_QUERY = gql`
  ${TALK_EVENT_FRAGMENT}
  query talkEventsQuery(
    $first: Int! = 10
    $after: String
    $unmindUserId: String!
    $ordering: [EventOrdering!]
    $filter: EventFilter
  ) {
    unmindEvents(
      first: $first
      after: $after
      ordering: $ordering
      filter: $filter
      unmindUserId: $unmindUserId
    ) {
      count
      edges {
        node {
          ...talkEventFragment
          eventGuests {
            id
            assessmentStatus
            contact {
              id
              unmindUserId
            }
          }
        }
      }
    }
  }
`;

export const TALK_EVENTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN = gql`
  query talkEventsQueryForPractitionersOtherThan(
    $first: Int! = 1
    $after: String
    $unmindUserId: String!
    $ordering: [EventOrdering!]
    $filter: EventFilter
  ) {
    unmindEvents(
      first: $first
      after: $after
      ordering: $ordering
      filter: $filter
      unmindUserId: $unmindUserId
    ) {
      count
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const TALK_EVENT_REQUESTS_QUERY = gql`
  query talkEventRequestsQuery(
    $first: Int! = 10
    $unmindUserId: String!
    $filter: EventRequestFilter
  ) {
    unmindEventRequests(
      first: $first
      unmindUserId: $unmindUserId
      filter: $filter
    ) {
      count
      edges {
        node {
          id
          accountId
          preferredPractitioner {
            id
            firstName
            lastName
            photoUrls
            timeZone
          }
        }
      }
    }
  }
`;

export const TALK_EVENT_REQUESTS_QUERY_FOR_PRACTITIONERS_OTHER_THAN = gql`
  query talkEventRequestsQueryForPractitionersOtherThan(
    $first: Int! = 1
    $unmindUserId: String!
    $filter: EventRequestFilter
  ) {
    unmindEventRequests(
      first: $first
      unmindUserId: $unmindUserId
      filter: $filter
    ) {
      count
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const USER_SESSION_LIMITS_QUERY = gql`
  query talkUserSessionLimitsQuery($input: PractitionerTypeEnum) {
    unmindUserSessionLimits(input: $input) {
      amountRemaining
      sessionLimitType
    }
  }
`;

export const CANCEL_EVENT_AS_GUEST_MUTATION = gql`
  mutation cancelEventAsGuestMutation($input: CancelEventInput!) {
    cancelEvent(input: $input)
  }
`;

export const SUBMIT_ASSESSMENT_MUTATION = gql`
  mutation submitOutcomeMeasuresAssessment($input: SubmitAssessmentInput!) {
    submitAssessment(input: $input) {
      success
    }
  }
`;

export const TALK_CONTACT_SESSION_LIMITS_QUERY = gql`
  query talkContactSessionLimitOverridesQuery(
    $first: Int! = 1
    $unmindUserId: String!
  ) {
    contacts(
      first: $first
      filter: {
        where: {
          unmindUserId: { equalTo: $unmindUserId }
          sessionLimitOverride: { notNull: true }
        }
      }
      ordering: [{ sort: SESSION_LIMIT_OVERRIDE, direction: DESC }]
    ) {
      edges {
        node {
          id
          sessionLimitOverride
        }
      }
    }
  }
`;
