import React from 'react';
import { IconProps } from '../IconProps';

const SvgWaves7 = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 340 192" fill="none" {...props}>
    <path fill={tertiaryColor} d="M0 0h340v192H0z" />
    <path
      d="M340 50.859V192H85.179c3.488-9.308 6.421-19.639 9.408-30.158 8.144-28.69 16.687-58.783 37.987-73.355 25.901-17.721 54.198-5.412 81.9 6.639 27.915 12.143 55.225 24.023 78.87 4.646 15.248-12.496 29.238-28.72 42.524-44.127 1.385-1.606 2.762-3.203 4.132-4.786z"
      fill={secondaryColor}
    />
  </svg>
);

export default SvgWaves7;
