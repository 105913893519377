import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from 'Shared/Typography/ExternalLink';
import { ButtonSize } from 'Shared/Button';
import Loader from '../../../Shared/Loader';
import { BodyText } from '../../../Shared/Typography';
import getSubdomainFromUrl from '../../../utils/getSubdomainFromUrl';
import { styled, useTheme } from '../../../styles';
import { useLinkSlackUserEffect, useSlackIntegration } from '../hooks';
import { useUnlinkSlackUser } from '../hooks/useUnlinkSlackUser';
import { getSentAtTimestamp } from '../../../App/Tracking/serverside';
import ErrorMessage from './ErrorMessage';
import {
  Card,
  SlackLogo,
  IntegrationName,
  IntegrationType,
  IntegrationDesc,
  IntegrationActionsContainer,
  IntegrationButtonText,
  DisconnectButton,
  IntegrationLink,
  IntegrationBanner,
  IntegrationHeader,
} from './styles';
import { ButtonContainer } from './IntegrationCard';

const NotConnectedMessage = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))``;

interface SlackProps {
  onCardClick(): void;
}

export const Slack = ({ onCardClick }: SlackProps) => {
  const { t: translate } = useTranslation('account');
  const theme = useTheme();
  const slackApiUrl = process.env.REACT_APP_SLACK_API_URL;

  const { isConnecting } = useLinkSlackUserEffect();
  const { data, loading, error } = useSlackIntegration();
  const [unlinkSlackUserToUnmind] = useUnlinkSlackUser();

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return <ErrorMessage />;
  }

  const getConnectButtonData = () => {
    if (data.slackIntegration?.appId && data.slackIntegration.teams[0]) {
      // Org has installed the slack app
      if (data.isSlackUser && data.slackUserId) {
        // User already connected
        const teamId = data.slackIntegration.teams[0].id;
        const appId = data.slackIntegration.appId;

        return {
          label: translate('integrations.slack.connection_button.connected', {
            teamName: data.slackIntegration.teams[0].name,
          }),
          url: `https://slack.com/app_redirect?team=${teamId}&app=${appId}`,
          slackUserId: data.slackUserId,
        };
      } else {
        // User not connected - invite to connect
        const subDomain = getSubdomainFromUrl();

        return {
          label: translate('integrations.slack.connection_button.connect'),
          url: `${slackApiUrl}/slack/install/${subDomain}`,
        };
      }
    } else if (data.isAdminUser) {
      // Org hasn't installed the app, but current user is admin so let them connect
      const subDomain = getSubdomainFromUrl();

      return {
        label: translate('integrations.slack.connection_button.connect'),
        url: `${slackApiUrl}/slack/install/${subDomain}`,
      };
    }

    return { label: translate('integrations.slack.not_connected_message') };
  };

  const connectButtonData = getConnectButtonData();

  const workspaceName = data?.slackIntegration?.teams[0]?.name;

  return (
    <Card onClick={onCardClick}>
      <IntegrationHeader>
        <SlackLogo aria-hidden />
        <IntegrationBanner>
          {workspaceName &&
            translate('integrations.slack.connected_to_workspace', {
              workspaceName,
            })}
        </IntegrationBanner>
      </IntegrationHeader>
      <IntegrationName>{translate('integrations.slack.name')}</IntegrationName>
      <IntegrationType>{translate('integrations.slack.type')}</IntegrationType>
      <IntegrationDesc>
        {translate('integrations.slack.description')}
      </IntegrationDesc>
      <ButtonContainer>
        {connectButtonData.url ? (
          <IntegrationActionsContainer isSlackUser={data.isSlackUser}>
            <IntegrationLink
              href={connectButtonData.url}
              rel="noopener noreferrer"
              size={ButtonSize.medium}
              styleProps={theme.button.primary.default}
            >
              <IntegrationButtonText
                sizes={[theme.typography.fontSizes.fontSize16]}
              >
                {isConnecting
                  ? translate('integrations.slack.connection_button.connecting')
                  : connectButtonData.label}
                <ExternalLinkIcon />
              </IntegrationButtonText>
            </IntegrationLink>
            {connectButtonData.slackUserId ? (
              <DisconnectButton
                label={translate(
                  'integrations.slack.connection_button.disconnect',
                )}
                onClick={() => {
                  void unlinkSlackUserToUnmind({
                    variables: {
                      clientSentAtUtcTimestamp: getSentAtTimestamp(),
                      slackUserId: connectButtonData.slackUserId,
                    },
                  });
                }}
              />
            ) : null}
          </IntegrationActionsContainer>
        ) : (
          <NotConnectedMessage>{connectButtonData.label}</NotConnectedMessage>
        )}
      </ButtonContainer>
    </Card>
  );
};
