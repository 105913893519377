import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavTrack = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3 2.571a.871.871 0 0 0-.871-.871H2.57a.871.871 0 0 0-.871.871V21.43a.872.872 0 0 0 .871.871h18.86a.872.872 0 0 0 .871-.871V2.57zm.947-1.818c.482.482.753 1.136.753 1.818V21.43A2.57 2.57 0 0 1 21.429 24H2.57A2.571 2.571 0 0 1 0 21.429V2.57A2.571 2.571 0 0 1 2.571 0H21.43c.682 0 1.336.27 1.818.753z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.253 5.77a.85.85 0 0 1 .364 1.145l-5.344 10.313a.85.85 0 0 1-1.383.182l-4.22-4.624-2.857 3.74a.85.85 0 1 1-1.351-1.03l3.474-4.55a.85.85 0 0 1 1.304-.057l4.084 4.474 4.783-9.23a.85.85 0 0 1 1.146-.364z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgNavTrack;
