import { isNullOrUndefined } from 'util';
import { round } from 'lodash';

import { ChartData } from './LineChart';

export type DataChunk = {
  hideLine: boolean;
  hideScatter: boolean;
  data: ChartData[];
};

export const getChartEdges = (
  data: DataChunk[],
  displayUpToIndex: number,
  pointsToDisplay: number,
): { start: number; end: number } => {
  const monthsCount = data.reduce(
    (total, { data: chunkData }, index) =>
      index === 0 || index === data.length - 1
        ? total + chunkData.length
        : total + chunkData.length - 1,
    0,
  );
  const end = displayUpToIndex >= 0 ? displayUpToIndex : monthsCount;
  const start = end - pointsToDisplay + 1;

  return {
    start,
    end,
  };
};

/**
 * Splits data into sequences with and without values, keeping data order
 *
 * @param data Chart data to be split
 */
export const splitDataIntoSequences = (data: ChartData[]): ChartData[][] => {
  if (!data.length) {
    return [];
  }

  const chunks: ChartData[][] = [];
  let tempChunk: ChartData[] = [];

  data.forEach(({ x, y }, index) => {
    const previousPoint = data[index - 1];
    const isPreviousPointEmpty =
      !isNullOrUndefined(previousPoint) && previousPoint.y === 0;
    const isCurrentPointEmpty = y === 0;
    const isFirstPoint = index === 0;

    if (isCurrentPointEmpty) {
      if (isPreviousPointEmpty || isFirstPoint) {
        tempChunk.push({ x, y });
      } else {
        chunks.push(tempChunk);

        tempChunk = [{ x, y }];
      }
    } else if (isPreviousPointEmpty) {
      chunks.push(tempChunk);

      tempChunk = [{ x, y }];
    } else {
      tempChunk.push({ x, y });
    }
  });

  chunks.push(tempChunk);

  return chunks;
};

/**
 * Replace 0 values with average value between two sequences with data, to create a straight line between them
 *
 * @param chunks With-without value sequences of data
 */
export const replaceEmptyValues = (chunks: ChartData[][]) =>
  chunks.reduce((tempResult: DataChunk[], chunk, index) => {
    const isEmptyChunk = chunk[0].y === 0;
    // leave empty values for empty chunks on both of the edges
    if ((index === 0 || index === chunks.length - 1) && isEmptyChunk) {
      return [
        ...tempResult,
        {
          hideLine: true,
          hideScatter: true,
          data: chunk,
        },
      ];
    }

    // create straight line through last point with value to next point with value
    if (isEmptyChunk) {
      const previousChunk = chunks[index - 1];
      const nextChunk = chunks[index + 1];
      const leftPoint = previousChunk[previousChunk.length - 1];
      const rightPoint = nextChunk[0];
      const distanceBetweenPoints = rightPoint.y - leftPoint.y;

      return [
        ...tempResult,
        {
          hideLine: false,
          hideScatter: true,
          data: [
            leftPoint,
            ...chunk.map(({ x }, chunkIndex) => ({
              x,
              y: round(
                leftPoint.y +
                  (distanceBetweenPoints / (chunk.length + 1)) *
                    (chunkIndex + 1),
                1,
              ),
            })),
            rightPoint,
          ],
        },
      ];
    }

    // pass through
    return [
      ...tempResult,
      {
        hideLine: false,
        hideScatter: false,
        data: chunk,
      },
    ];
  }, []);

export const getDataWithoutEmptyValues = (data: ChartData[]) => {
  const sequences = splitDataIntoSequences(data);

  return replaceEmptyValues(sequences);
};
