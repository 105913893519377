import React from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { getUserLocale } from 'i18n/getUserLocale';
import { Container, SectionHeadingTitle } from 'Explore/ExplorePage';
import { useTranslation } from 'react-i18next';
import FeaturedExploreContent from '../../Explore/GenericExploreContent/GenericExploreContent';
import { Series, Tool } from '../../Explore/types';
import FeaturedCheatSheets from './CheatSheets/FeaturedCheatSheets';
import ManagerShortCategories from './ManagerShortCategories/ManagerShortCategories';
import FeaturedContent from './FeaturedHero/FeaturedContent';
import { SUPPORTED_LOCALES } from './constants';
import CompassHomepageBanner from './CompassHomepageBanner';

const Section = styled.section`
  margin-bottom: ${rem(100)};
`;

type ManagersTodayProps = {
  assetToken: string;
  lineManagerCategorySlug: string;
  featuredContent: (Series | Tool)[];
};

const ManagersToday = ({
  assetToken,
  lineManagerCategorySlug,
  featuredContent,
}: ManagersTodayProps) => {
  const { t: translate } = useTranslation('explore');
  const userLocale = getUserLocale();
  const shouldShowManagerCheatSheets = useFeatureFlag(
    FEATURE_FLAGS.SHOW_MANAGER_CHEAT_SHEET_WEB,
  );

  const shouldShowCompass = useFeatureFlag(FEATURE_FLAGS.COMPASS);

  const shouldDisplayManagerCheatSheets =
    SUPPORTED_LOCALES.includes(userLocale) && shouldShowManagerCheatSheets;

  const shouldDisplayFeaturedExploreContent =
    !shouldDisplayManagerCheatSheets &&
    featuredContent &&
    featuredContent.length > 0;

  const getConditionalContent = () => {
    if (shouldDisplayManagerCheatSheets) {
      return (
        <Section>
          <FeaturedCheatSheets assetToken={assetToken} />
        </Section>
      );
    } else if (shouldDisplayFeaturedExploreContent) {
      return (
        <Container>
          <SectionHeadingTitle data-testid="featured-content-title">
            {translate('featured_content_section.title')}
          </SectionHeadingTitle>
          <FeaturedExploreContent
            content={featuredContent}
            assetToken={assetToken}
            source="managers-today-featured-content"
          />
        </Container>
      );
    }
  };

  return (
    <div data-testid="managers-today">
      {shouldShowCompass && (
        <Section>
          <CompassHomepageBanner />
        </Section>
      )}
      <Section>
        <FeaturedContent assetToken={assetToken} />
      </Section>
      {getConditionalContent()}
      <Section>
        <ManagerShortCategories managerCategorySlug={lineManagerCategorySlug} />
      </Section>
    </div>
  );
};

export default ManagersToday;
