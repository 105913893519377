export const getToolSummaryFromHtml = (
  html: string | null,
): string | undefined => {
  const parser = new DOMParser();

  if (!html) return;

  // The summary property on the tool is a duration string, so we're extracting the summary from the html property
  const textContent = parser.parseFromString(html, 'text/html').documentElement
    .textContent;

  if (!textContent) return;

  return textContent;
};
