import React from 'react';
import { IconProps } from '../IconProps';

const SvgClose = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.62.002a.723.723 0 0 0-.58.47.59.59 0 0 0-.034.25.65.65 0 0 0 .121.396c.024.033 1.559 1.574 4.449 4.465l4.413 4.414-4.42 4.422C1.688 17.3.137 18.857.118 18.885a.718.718 0 0 0-.091.198.52.52 0 0 0-.022.19.59.59 0 0 0 .036.25c.071.2.234.362.433.434a.59.59 0 0 0 .25.036.65.65 0 0 0 .396-.121c.034-.024 1.575-1.56 4.466-4.45L10 11.01l4.414 4.413c2.891 2.89 4.432 4.426 4.466 4.45a.65.65 0 0 0 .396.12.643.643 0 0 0 .424-.127.715.715 0 0 0 .284-.433.392.392 0 0 1 .017-.069.47.47 0 0 0-.001-.17c-.005.003-.008-.005-.008-.018a.788.788 0 0 0-.061-.204.948.948 0 0 0-.057-.094c-.024-.034-1.559-1.575-4.45-4.466l-4.412-4.414 4.413-4.414c2.89-2.89 4.425-4.432 4.449-4.466a.954.954 0 0 0 .057-.093.798.798 0 0 0 .06-.203c0-.013.005-.025.009-.028a.273.273 0 0 0 .008-.089c0-.054-.003-.082-.008-.079-.005.003-.008-.004-.008-.017a.62.62 0 0 0-.034-.143.745.745 0 0 0-.348-.39.62.62 0 0 0-.334-.073.52.52 0 0 0-.19.022.718.718 0 0 0-.198.092c-.029.018-1.586 1.57-4.466 4.449L10 8.985 5.587 4.572C2.696 1.682 1.155.147 1.12.123A.633.633 0 0 0 .74.001a1.106 1.106 0 0 0-.12 0zM.005.713c0 .049.001.067.003.042a.807.807 0 0 0 0-.088C.005.644.004.665.004.713zm0 18.568c0 .049.001.068.003.042a.807.807 0 0 0 0-.088c-.002-.023-.003-.002-.003.046z"
      fill={primaryColor}
    />
  </svg>
);

export default SvgClose;
