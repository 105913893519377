import gql from 'graphql-tag';
import { AUTHENTICATED_QUERY } from 'App/Auth/authenticatedQuery';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { AuthApiResponse, AuthPayload, getAuthPayload } from '../../App/Auth';

const AUTH_FIELDS = gql`
  fragment AuthFields on AuthPayload {
    activationDate
    id
    token
    iris_token
    email
    firstName
    lastName
    userType {
      name
    }
    permissions {
      value
    }
    createdAt
    groupId
    groupName
    clientTier
    subdomain
    praiseDirectory {
      value
    }
    departmentId
    departmentName
    locationId
    userTraits
    locationName
    abTestCohorts
  }
`;

export const LOGIN_MUTATION = gql`
  ${AUTH_FIELDS}
  mutation Login(
    $emailAddress: String!
    $password: String!
    $groupId: String!
    $isMobile: Boolean
  ) {
    login(
      email: $emailAddress
      password: $password
      groupId: $groupId
      isMobile: $isMobile
    ) {
      ...AuthFields
    }
  }
`;

export const LOGIN_WITHOUT_ORG_ID_MUTATION = gql`
  ${AUTH_FIELDS}
  mutation loginWithoutOrganisationId(
    $emailAddress: String!
    $password: String!
    $isMobile: Boolean
  ) {
    loginWithoutOrganisationId(
      input: { email: $emailAddress, password: $password, isMobile: $isMobile }
    ) {
      ...AuthFields
    }
  }
`;

export interface LoginAPIResponse {
  login: AuthApiResponse;
}

export interface LoginWithoutOrgIdAPIResponse {
  loginWithoutOrganisationId: AuthApiResponse;
}

export interface LoginVariables {
  emailAddress: string;
  password: string;
  groupId?: string | null;
  isMobile?: boolean;
}

export const useLogin = (
  onErrorCallback: (error?: string) => void,
  isSubdomainlessLogin = false,
) => {
  const mutationOptions: MutationHookOptions = {
    update: cache => {
      cache.writeQuery({
        query: AUTHENTICATED_QUERY,
        data: {
          authenticated: true,
        },
      });
    },
    fetchPolicy: 'no-cache',
    context: {
      timeout: 15000,
    },
    onError: error => {
      const graphQLError =
        error.graphQLErrors.length > 0 ? error.graphQLErrors[0].name : '';

      onErrorCallback(graphQLError);
    },
  };

  const [loginMutation, { loading: loginLoading }] =
    useMutation<LoginAPIResponse>(LOGIN_MUTATION, mutationOptions);

  const [subdomainlessLoginMutation, { loading: loginWithoutOrgIdLoading }] =
    useMutation<LoginWithoutOrgIdAPIResponse>(
      LOGIN_WITHOUT_ORG_ID_MUTATION,
      mutationOptions,
    );

  const login = async (
    variables: LoginVariables,
  ): Promise<AuthPayload | void> => {
    try {
      const { data } = await loginMutation({
        variables: {
          emailAddress: variables.emailAddress,
          password: variables.password,
          groupId: variables.groupId,
        },
      });

      if (data?.login !== undefined) {
        return getAuthPayload(data.login);
      }
    } catch (error) {
      onErrorCallback();
    }
  };

  const loginWithoutOrgId = async (
    variables: LoginVariables,
  ): Promise<AuthPayload | void> => {
    try {
      const { data } = await subdomainlessLoginMutation({
        variables: {
          emailAddress: variables.emailAddress,
          password: variables.password,
        },
      });

      if (data?.loginWithoutOrganisationId !== undefined) {
        return getAuthPayload(data.loginWithoutOrganisationId);
      }
    } catch (error) {
      onErrorCallback();
    }
  };

  return {
    login: isSubdomainlessLogin ? loginWithoutOrgId : login,
    loading: isSubdomainlessLogin ? loginWithoutOrgIdLoading : loginLoading,
  };
};
