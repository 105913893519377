import { rem } from 'polished';
import { css, styled } from 'styles';
import { OpenExternalLink } from 'icons';
import { small } from '../../utils';
import { BodyText } from '../../Shared/Typography';
import { createTextContentWidth } from './AuthWrapper.styled';

export const HelpCentreContainer = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HelpCentreTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  flex-shrink: 0;
  ${createTextContentWidth()};
`;

export const LinkArea = styled(BodyText)`
  display: flex;
  flex-direction: row;

  ${small(css`
    display: inline-flex;
    flex-wrap: wrap;
  `)}
`;

const DefaultLink = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize12,
  ],
  weight: theme.typography.fontWeights.medium,
  forwardedAs: 'a',
}))`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin: ${rem(8)} 0 0 0;
  padding: ${rem(4)} ${rem(10)};

  ${small(css`
    padding: ${rem(4)} ${rem(24)};
  `)}
  border-radius: ${rem(24)};
`;

export const FAQLink = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.text.link};

  &:hover {
    color: ${({ theme }) => theme.colors.text.link};
    text-decoration: underline;
  }
`;

export const ExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
  height: 16,
  width: 16,
}))`
  margin-left: ${rem(4)};
`;
