import { ComponentType } from 'react';
import { branch, renderComponent } from 'recompose';
import LoadingIndicator from './LoadingIndicator';

interface LoadingProps {
  loading?: boolean;
}

const withLoadingIndicator: <T>(
  BaseComponent: ComponentType<T>,
) => ComponentType<T & LoadingProps> = branch(
  ({ loading = false }) => loading,
  renderComponent(LoadingIndicator),
);

export default withLoadingIndicator;
