import tracking from './tracking';

// Mapping to camelCase is required for Data's naming standards
const UTM_PARAM_NAMES_MAP = {
  utm_campaign: 'utmCampaign',
  utm_source: 'utmSource',
  utm_medium: 'utmMedium',
  utm_term: 'utmTerm',
  utm_content: 'utmContent',
};

interface UtmParameters {
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmContent?: string;
}

const getUtmParametersFromSearch = (search: string): UtmParameters =>
  search
    .slice(1)
    .split('&')
    .reduce<UtmParameters>((utmParamsObj, currentKeyPair) => {
      const [key, value] = currentKeyPair.split('=');

      if (Object.keys(UTM_PARAM_NAMES_MAP).includes(key)) {
        const utmKey =
          UTM_PARAM_NAMES_MAP[key as keyof typeof UTM_PARAM_NAMES_MAP];
        utmParamsObj[utmKey as keyof UtmParameters] = decodeURIComponent(value);
      }

      return utmParamsObj;
    }, {});

const trackUtmParamsIfAvailable = ({
  pathname: path,
  search,
}: {
  pathname: string;
  search: string;
}) => {
  const utmParameters = getUtmParametersFromSearch(search);
  const utmParamsAvailable = Object.keys(utmParameters).length > 0;

  if (utmParamsAvailable) {
    tracking.track('utm-link-clicked', { ...utmParameters, path });
  }
};

export default trackUtmParamsIfAvailable;
