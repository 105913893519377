import { useEffect } from 'react';

// this refresh will cause the request to go to cloudfront and redirect a user to the WorkplaceIndex frontend
const CompassCheckIn = () => {
  useEffect(() => {
    window.location.reload();
  });

  return null;
};

export default CompassCheckIn;
