import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { AuthTalk } from '../../components';
import { FiltersCollection } from '../../components/BrowseFilterPanel/types';
import { TalkBrowsePage } from '../TalkBrowsePage/TalkBrowsePage';
import { Container } from '../TalkHome/styles';
import { TalkPractitionerPage } from '../TalkPractitionerPage';

export const TalkBrowseIndex = () => {
  const { state: locationState } = useLocation<{
    source?: string;
  }>();
  const params = useParams<{ practitionerId: string }>();
  const { practitionerId } = params;
  const [selectedFilters, setSelectedFilters] = useState<FiltersCollection>({
    languages: [],
    areasOfExpertise: [],
    approaches: [],
  });

  /**
   * Handlers
   */
  const onFilterChange = (newFilters: FiltersCollection) => {
    setSelectedFilters(newFilters);
  };

  /**
   * Return
   */
  return (
    <AuthTalk>
      <Container>
        <>
          {/* TODO: TalkBrowsePage and TalkPractitionerPage should be seperate URLs */}
          {!practitionerId ? (
            <TalkBrowsePage
              onFilterChange={onFilterChange}
              selectedFilters={selectedFilters}
            />
          ) : (
            <TalkPractitionerPage source={locationState?.source} />
          )}
        </>
      </Container>
    </AuthTalk>
  );
};
