import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import RoutePath from '../../../App/RoutePath';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../../AuthWrapper';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../../EmailConfirmation/EmailConfirmationShared';
import { EmailAddress } from '../../EmailConfirmation/EmailConfirmation.styled';

function VoucherCodeConfirmationPage(props: RouteChildrenProps) {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const email = props.location.state?.email || 'your email';

  return (
    <AuthWrapper>
      <AuthWrapperBlock>
        <AuthWrapperTitle>
          {translate('sign_up.voucher_code_confirmation.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle>
          {Trans({
            t: translate,
            i18nKey: 'sign_up.voucher_code_confirmation.subtitle',
            values: { email },
            defaults:
              "You'll get an email from us any moment now. We've sent it to <email_address>{{email}}</email_address>",
            components: { email_address: <EmailAddress /> },
          })}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailConfirmationHelperText>
            {Trans({
              t: translate,
              i18nKey: 'sign_up.voucher_code_confirmation.helper_text',
              defaults:
                'Wrong email? <confirmation_link>Try again</confirmation_link>',
              components: {
                confirmation_link: (
                  <Link
                    data-testid="try-again-link"
                    to={RoutePath.VoucherCodeSignUp}
                  />
                ),
              },
            })}
          </EmailConfirmationHelperText>
        </AuthWrapperContent>
        <EmailConfirmationFooter />
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default VoucherCodeConfirmationPage;
