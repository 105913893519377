import gql from 'graphql-tag';

export const CheckInScoresFragment = gql`
  fragment CheckInScores on CheckIn {
    id
    createdAt
    dateCreated
    feelings {
      id
      name
    }
    mood
    moodId
    note
  }
`;

export const CHECK_IN_SCORES_QUERY = gql`
  query getCheckInScores {
    lastMonthCheckins: checkins(timeframe: MONTH) {
      id
      mood
      moodId
    }
    allCheckins: checkins {
      ...CheckInScores
    }
  }
  ${CheckInScoresFragment}
`;
