import { isApolloError } from '@apollo/client';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Errors, getError } from '../../Shared/Errors';

interface Response {
  success: boolean;
  error: Errors;
}

export interface WithRefreshAuthTokenChildProps {
  refreshAuthToken(): Promise<Partial<Response>>;
}

export const withRefreshAuthTokenChildPropsMutation = gql`
  mutation ping {
    ping
  }
`;

export default graphql<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  WithRefreshAuthTokenChildProps
>(withRefreshAuthTokenChildPropsMutation, {
  props: ({ mutate }) => ({
    refreshAuthToken: async () => {
      try {
        if (mutate) {
          const response = await mutate();

          if (typeof response === 'object' && response.data !== undefined) {
            return {
              success: true,
            };
          }
        }
      } catch (error) {
        if (error instanceof Error && isApolloError(error)) {
          if (error.graphQLErrors.length > 0) {
            const [{ name: graphQLErrorName }] = error.graphQLErrors;
            const mappedError = getError(graphQLErrorName);

            return {
              error: Boolean(mappedError) ? mappedError : Errors.ServerError,
            };
          }
        }
      }

      return {
        error: Errors.ServerError,
      };
    },
  }),
});
