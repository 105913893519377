import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelledCheckbox, { LabelledCheckboxProps } from './LabelledCheckbox';

const MarketingCheckbox = (props: LabelledCheckboxProps) => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <LabelledCheckbox {...props} data-testid="marketing-checkbox">
      {translate('sign_up.marketing_checkbox.label')}
    </LabelledCheckbox>
  );
};

export default MarketingCheckbox;
