import isUndefined from 'lodash/isUndefined';
import { rem } from 'polished';
import React from 'react';

import { styled, ThemeInterface } from 'styles';
import BodyText from '../Typography/BodyText';

interface ValidatorMatchProps {
  matchValue: 'match' | 'noMatch' | 'indeterminate';
}

interface MatchColorMap {
  theme: ThemeInterface;
  matchValue: ValidatorMatchProps['matchValue'];
}

const matchColorMap = ({ matchValue, theme }: MatchColorMap) => {
  const colorMap = {
    indeterminate: theme.colors.text.secondary,
    match: theme.colors.text.error,
    noMatch: theme.colors.staticPalette.green.green7,
  };

  return colorMap[matchValue];
};

const MatchText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))<{ matchValue: MatchColorMap['matchValue'] }>`
  color: ${({ matchValue, theme }) => matchColorMap({ matchValue, theme })};
  border-bottom: ${rem('1px')} solid
    ${({ matchValue, theme }) => matchColorMap({ matchValue, theme })};
  padding-bottom: ${rem('1px')};
`;

export const ValidatorMatch: React.FC<ValidatorMatchProps> = props => (
  <MatchText {...props} />
);

export const matchError = (error?: boolean): ValidatorMatchProps => ({
  matchValue: isUndefined(error)
    ? 'indeterminate'
    : error
    ? 'noMatch'
    : 'match',
});
