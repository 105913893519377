import { differenceInDays, addDays } from 'date-fns';
import { SurveyStatus } from '../CompassDashboardHeader/CompassDashboardHeader';

export const surveyMessageKey =
  'dashboard.latest_survey_status.survey_status_messages';

const getSurveyStatus = (surveyStatus: SurveyStatus, numOfDays?: number) => {
  const currentSurveyMessage = {
    [SurveyStatus.RUNNING]: {
      textKey: `${surveyMessageKey}.running`,
      days: numOfDays,
    },
    [SurveyStatus.COMPLETE]: {
      textKey: `${surveyMessageKey}.complete`,
      days: numOfDays,
    },
    [SurveyStatus.INCOMPLETE]: { textKey: `${surveyMessageKey}.incomplete` },
    [SurveyStatus.READY]: { textKey: `${surveyMessageKey}.ready` },
  };

  return currentSurveyMessage[surveyStatus];
};

type LatestSurveyInfo = {
  textKey: string;
  days?: number;
};

export const getLatestSurveyInfo = (
  surveyStatus: SurveyStatus,
  currentDate: Date,
  endDate: Date,
): LatestSurveyInfo => {
  const daysUntilSurveyEnds = differenceInDays(endDate, currentDate);

  if (surveyStatus === SurveyStatus.RUNNING) {
    return getSurveyStatus(SurveyStatus.RUNNING, daysUntilSurveyEnds + 1); // Add 1 to include the current day
  }

  const earliestNewSurveyStartDate = addDays(endDate, 14);
  const numberOfDaysUntilNewSurvey = differenceInDays(
    earliestNewSurveyStartDate,
    currentDate,
  );

  // We want to show that the survey is complete and a countdown of days until the new survey
  if (surveyStatus === SurveyStatus.COMPLETE) {
    return getSurveyStatus(SurveyStatus.COMPLETE, numberOfDaysUntilNewSurvey);
  }

  // We want to show that the survey has finished but we didn't receive enough responses
  if (surveyStatus === SurveyStatus.INCOMPLETE) {
    return getSurveyStatus(SurveyStatus.INCOMPLETE);
  }

  // We want to show that the previous survey has finished and that 14 days have passed, meaning a new survey can start
  if (surveyStatus === SurveyStatus.READY) {
    return getSurveyStatus(SurveyStatus.READY);
  }

  // If none of the above conditions are met, we default to the survey being ready
  return getSurveyStatus(SurveyStatus.READY);
};
