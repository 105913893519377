import { practitionersQuery_practitioners_edges_node as TPractitoner } from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';

export const getLocation = ({
  country: { name: countryName },
  state,
  city,
}: TPractitoner): string | null => {
  if (state && countryName) return `${state}, ${countryName}`;
  if (city && countryName) return `${city}, ${countryName}`;

  return city || state || countryName;
};
