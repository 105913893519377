import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const MINIMUM_DAYS_BETWEEN_INDEXES = 14;

export type IndexRetakeFrequencyResponse = {
  getIndexRetakeFrequency: {
    __typename: string;
    indexFrequency: number;
  };
};

export interface WithIndexRetakeFrequencyProps {
  indexFrequency?: number;
}

export const INDEX_RETAKE_FREQUENCY_QUERY = gql`
  query getIndexRetakeFrequency {
    getIndexRetakeFrequency {
      indexFrequency
    }
  }
`;

const withIndexRetakeFrequency =
  <T,>(WrappedComponent: React.ComponentType<T>) =>
  (props: T) => {
    const { data } = useQuery<IndexRetakeFrequencyResponse>(
      INDEX_RETAKE_FREQUENCY_QUERY,
      {
        fetchPolicy: 'cache-first',
      },
    );

    const indexFrequency =
      data?.getIndexRetakeFrequency?.indexFrequency ||
      MINIMUM_DAYS_BETWEEN_INDEXES;

    return <WrappedComponent {...props} indexFrequency={indexFrequency} />;
  };

export default withIndexRetakeFrequency;
