import React from 'react';
import { Formik } from 'formik';
import PrimaryButton from 'Shared/PrimaryButton';
import SecondaryButton from 'Shared/SecondaryButton';
import { useTranslation } from 'react-i18next';
import {
  CalendarEvent,
  google,
  office365,
  yahoo,
  outlook,
  ics,
} from 'calendar-link';
import { tracking } from 'App/Tracking';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import {
  Title,
  ContentContainer,
  Container,
  PencilIcon,
  TextIcon,
  ButtonContainer,
  CloseIconButton,
  CloseIcon,
  HeaderContainer,
} from './styles';
import { EditableTextField } from './EditableTextField';
import { DateTimeDurationSelectorGroup } from './DateTimeDurationSelectorGroup';
import { Cadence, CadenceDropdown } from './CadenceDropdown';
import {
  CalendarType,
  CalendarTypeRadioGroup,
} from './CalendarTypeRadioGroup/CalendarTypeRadioGroup';
import { getContentTypeFromLink } from './getContentTypeFromLink';

interface AddToCalendarModalProps {
  onClose(): void;
  title: string;
  description?: string;
  contentPath: string;
}

export interface AddToCalendarValues {
  title: string;
  description: string;
  date: Date;
  duration: number;
  cadence: Cadence;
  calendarType?: string;
}

interface OpenCalendar extends AddToCalendarValues {
  link: string;
}

export const AddToCalendarModal = ({
  onClose,
  title,
  description,
  contentPath,
}: AddToCalendarModalProps) => {
  const { t: translate } = useTranslation('shared');

  const initialDate = new Date();
  const roundedMinutes = Math.round(initialDate.getMinutes() / 15) * 15;
  initialDate.setMinutes(roundedMinutes, 0, 0);
  const link = `https://${getSubdomainFromUrl()}.${window.location.hostname
    .split('.')
    .slice(1)
    .join('.')}${contentPath}`;

  const initialValues: AddToCalendarValues = {
    title,
    description: description || '',
    date: initialDate,
    cadence: Cadence.Weekly,
    duration: 10,
  };

  const openCalendar = (values: OpenCalendar) => {
    let calendarLink = '';
    const event: CalendarEvent = {
      title: values.title,
      description: translate('add_to_calendar.modal_description', {
        description: values.description,
        link: values.link,
      }),
      start: values.date,
      duration: [values.duration, 'minutes'],
    };

    const eventWithCadence =
      values.cadence === Cadence.Once
        ? event
        : {
            ...event,
            rRule: `FREQ=${values.cadence}`,
            recur: 'RRULE',
          };

    switch (values.calendarType) {
      case CalendarType.Google:
        calendarLink = google(eventWithCadence);
        break;
      case CalendarType.Office365:
        calendarLink = office365(eventWithCadence);
        break;
      case CalendarType.Yahoo:
        calendarLink = yahoo(eventWithCadence);
        break;
      case CalendarType.Outlook:
        calendarLink = outlook(eventWithCadence);
        break;
      case CalendarType.Apple:
        calendarLink = ics(eventWithCadence);
        break;
      default:
        console.error('Unknown calendar type:', values.calendarType);
    }

    if (calendarLink) {
      window.open(calendarLink, '_blank');
    } else {
      console.error(
        'Calendar link generation failed for:',
        values.calendarType,
      );
    }
  };

  const onSubmit = (values: AddToCalendarValues) => {
    tracking.track('add-to-calendar-event-added', {
      calendarType: values.calendarType,
      content: link,
      calendarEventDate: values.date.toISOString(),
      cadence: values.cadence,
      contentType: getContentTypeFromLink(contentPath),
    });
    openCalendar({
      ...values,
      link: `${link}?utm_source=${values.calendarType?.toLowerCase()}_calendar`,
    });
    onClose();
  };

  const cadenceIsSupported: CalendarType[] = [
    CalendarType.Google,
    CalendarType.Apple,
  ];

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values, handleSubmit, setFieldValue }) => (
        <Container onSubmit={handleSubmit}>
          <HeaderContainer>
            <Title>{translate('add_to_calendar.modal.title')}</Title>
            <CloseIconButton
              onClick={onClose}
              role="button"
              tabIndex={0}
              aria-describedby={translate('add_to_calendar.modal.close')}
            >
              <CloseIcon />
            </CloseIconButton>
          </HeaderContainer>
          <ContentContainer>
            <EditableTextField
              accessibleLabel={translate(
                'add_to_calendar.title.accessibility_label',
              )}
              value={values.title}
              icon={<PencilIcon aria-hidden="true" />}
              onChange={setFieldValue}
              field={'title'}
            />
            <EditableTextField
              accessibleLabel={translate(
                'add_to_calendar.description.accessibility_label',
              )}
              value={values.description}
              icon={<TextIcon aria-hidden="true" />}
              onChange={setFieldValue}
              field={'description'}
            />
            <DateTimeDurationSelectorGroup
              date={values.date}
              duration={values.duration}
              onSelect={setFieldValue}
            />
            {(cadenceIsSupported.includes(
              values.calendarType as CalendarType,
            ) ||
              !values.calendarType) && (
              <CadenceDropdown
                onChange={setFieldValue}
                value={values.cadence}
              />
            )}
            <CalendarTypeRadioGroup
              field="calendarType"
              value={values.calendarType}
              onChange={setFieldValue}
            />
          </ContentContainer>
          <ButtonContainer>
            <SecondaryButton
              label={translate('add_to_calendar.modal.close')}
              onClick={onClose}
              type="button"
            />
            <PrimaryButton
              role="link"
              disabled={values.calendarType ? false : true}
              aria-disabled={values.calendarType ? false : true}
              label={translate('add_to_calendar.modal.button')}
              type="submit"
            />
          </ButtonContainer>
        </Container>
      )}
    </Formik>
  );
};
