import { practitionersQuery_practitioners_edges_node_details_profileQuestionResponses as PractitionerDetails } from 'Services/BeGateway/Practitioner/__generated__/practitionersQuery';
import { parseBeGatewayId } from '../../../utils/relay';

export const PROFILE_SUMMARY_IDS: Record<string, number> = {
  therapist: 5,
  coach: 1,
  dietitian: 1,
};

export const getProfileSummary = (
  profileQuestionResponses: PractitionerDetails[] | null,
  practitionerType: string,
): string | null => {
  if (!profileQuestionResponses) {
    return null;
  }

  const summaryResponse = profileQuestionResponses.find(response => {
    const id = parseBeGatewayId(response?.profileQuestion?.id);

    return id && PROFILE_SUMMARY_IDS[practitionerType] === id;
  });

  return summaryResponse?.answer ?? null;
};

export const getAboutMeQuestions = (
  profileQuestionResponses: PractitionerDetails[] | null,
) => {
  if (!profileQuestionResponses) {
    return [];
  }

  return profileQuestionResponses.filter(
    ({ profileQuestion }) =>
      !Object.values(PROFILE_SUMMARY_IDS).includes(
        parseBeGatewayId(profileQuestion.id) as number,
      ),
  );
};
