import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { getWellbeingScores } from './__generated__/getWellbeingScores';

export const WELLBEING_SCORES_QUERY = gql`
  query getWellbeingScores($indexScheduleId: String!) {
    wellbeingThemes(indexScheduleId: $indexScheduleId) {
      id
      title
      description
      meanScore
      riskCategory
    }
    overallWellbeingScore(indexScheduleId: $indexScheduleId) {
      meanScore
      riskCategory
    }
  }
`;

export const useWellbeingScores = ({
  indexScheduleId,
  skip,
}: {
  indexScheduleId: string;
  skip: boolean;
}) =>
  useQuery<getWellbeingScores>(WELLBEING_SCORES_QUERY, {
    variables: {
      indexScheduleId,
    },
    skip,
  });
