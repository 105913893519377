import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { small } from 'utils';
import { BodyText, HeadingText } from '@unmind/design-system-components-web';
import PrimaryButton from 'Shared/PrimaryButton';
import { tracking } from 'App/Tracking';
import { getMSTeamsUserContext } from 'utils/MSTeams';
import NovaConversation from './Assets/nova_conversation_2.png';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  background-color: ${({ theme }) => theme.colors.background.primary};
  align-items: center;
  justify-content: space-between;
  padding: ${rem(40)} ${rem(20)} ${rem(5)} ${rem(20)};
  gap: ${rem(40)};
  align-self: center;

  ${small(css`
    padding: ${rem(60)} ${rem(60)} ${rem(100)} ${rem(60)};
    flex-direction: row;
    gap: ${rem(100)};
  `)};
`;

const ImageSection = styled.div`
  display: flex;
`;
const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(576)};
  gap: ${rem(16)};
`;

const NovaImage = styled.img`
  width: 100%;
  margin-top: ${rem(10)};

  ${small(css`
    margin-top: 0;
    width: ${rem(500)};
  `)};
`;

const NovaTitle = styled(HeadingText)`
  font-size: ${rem(30)};
  font-weight: 600;
`;
const NovaText = styled(BodyText)``;
const NovaCTAButton = styled(PrimaryButton)`
  max-width: ${rem(240)};
  display: none;

  ${small(css`
    display: block;
  `)};
`;

export const NovaBody = ({
  ctaNavigationPath,
}: {
  ctaNavigationPath: string;
}) => (
  <SectionContainer>
    <ImageSection>
      <NovaImage src={NovaConversation} aria-hidden />
    </ImageSection>
    <DescriptionSection>
      <NovaTitle level={2}>Quick chat. Big impact.</NovaTitle>
      <NovaText>
        Nova gives you the space to talk through everything from workplace
        challenges to tips for your next performance review.
        <br />
        <br />
        Giving you personalized wellbeing content recommendations straight from
        the Unmind platform, it gives you what you need, when you need it most.
      </NovaText>
      <NovaCTAButton
        label="Get started with Nova ->"
        to={ctaNavigationPath}
        onClick={() => {
          tracking.track('teams-start-page-cta-clicked', {
            abTestGroup: 'nova-focussed-start-page-sept-2024',
            tenantId: getMSTeamsUserContext()?.user?.tenant?.id || 'unknown',
            ctaLocation: 'start-page-body',
          });
        }}
      ></NovaCTAButton>
    </DescriptionSection>
  </SectionContainer>
);
