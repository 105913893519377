import React from 'react';
import LoadingIndicator from 'Shared/LoadingIndicator';
import styled from 'styled-components';
import { rem } from 'polished';
import { RIGHT_CONTAINER_DESKTOP_PX_WIDTH } from 'Compass/Components/CompassDashboard/styles';
import { SelectedCard } from './SelectedCard';

interface LoadingSelectedCardProps {
  closeCard(): void;
}

const LoadingIndicatorContainer = styled.div`
  width: ${rem(RIGHT_CONTAINER_DESKTOP_PX_WIDTH)};
`;

export const LoadingSelectedCard = ({
  closeCard,
}: LoadingSelectedCardProps) => (
  <SelectedCard
    closeCard={closeCard}
    header={
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    }
    content={null}
  />
);
