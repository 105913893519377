import { Container, medium } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const RIGHT_CONTAINER_DESKTOP_PX_WIDTH = 400;

export const StyledPageContainer = styled(Container)`
  display: flex;
  width: 100%;
  padding-top: ${rem(56)};
`;

export const LeftContainer = styled.div`
  width: ${rem(700)};
`;

export const RightContainer = styled.div`
  width: 0;

  ${medium(
    css`
      width: ${rem(RIGHT_CONTAINER_DESKTOP_PX_WIDTH)};
    `,
  )}
`;
