import React, { useContext } from 'react';
import { ThemeContext, ThemeInterface } from 'styles';
import BaseButton, { BaseButtonProps } from '../Button';

type Variant = 'default' | 'destructive';

export interface TextButtonProps
  extends Exclude<
    BaseButtonProps,
    'backgroundColor' | 'borderColor' | 'textColor'
  > {
  variant?: Variant;
}

const getDefaultStyle = ({ theme }: { theme: ThemeInterface }) =>
  theme.button.text.default;

const getDestructiveStyle = ({ theme }: { theme: ThemeInterface }) =>
  theme.button.text.destructive;

export const TextButton = ({
  variant = 'default',
  ...props
}: TextButtonProps) => {
  const theme = useContext(ThemeContext);
  const styleProps =
    variant === 'destructive'
      ? getDestructiveStyle({ theme })
      : getDefaultStyle({ theme });

  return <BaseButton {...props} styleProps={styleProps} />;
};

export default TextButton;
