import { useCallback, useEffect, useState } from 'react';
import { getSentAtTimestamp } from 'App/Tracking/serverside';
import { tracking } from 'App/Tracking';
import { logException } from 'App/logging';
import { conversationMessagesConversationMessagesGet as getConversationHistory } from 'Assistant/__generated__/client';
import {
  ConversationMessagePagination,
  ConversationMessagesConversationMessagesGetParams as ConversationQueryParameters,
  ConversationStreamingResponse,
  GenerateResponseConversationPostParams,
  ConversationInput,
} from 'Assistant/__generated__/client.schemas';
import {
  ConversationUpdateType,
  HistoryErrorType,
  Message,
  UserMessage,
} from '../types';
import { ChatbotMessageRole } from '../enums/ChatbotMessageRole';
import { textDecoder } from '../utils/textEncodings';
import {
  mapHistoryErrorType,
  mapHistoryToMessage,
  mapMessageToInput,
} from '../utils/mapConversationHistory';
import { isHTTPValidationError } from '../utils/isValidationError';
import { useAuthHeader } from './useAuthHeader';

const { REACT_APP_CHAT_API_URL } = process.env;

type ConversationHistory = {
  isLoading: boolean;
  hadError: boolean;
  messages: Message[];
} & Pick<ConversationMessagePagination, 'hasPrevious' | 'startCursor'>;

export type ConversationHistoryOutput = {
  messages: Message[];
  isLoadingHistory: boolean;
  isSubmittingMessage: boolean;
  isStreamingMessage: boolean;
  hadErrorSubmittingMessage: boolean;
  hadErrorLoadingHistory: HistoryErrorType;
  submitMessage(message: UserMessage): Promise<void>;
  resubmitMessages(): Promise<void>;
  loadMoreMessages(): Promise<void>;
  retryLoadingHistory(): Promise<void>;
};

type ChatbotTextMessage = {
  value: string;
  type: string;
};

export type AssistantStreamMessage = {
  id: string;
  content: [ChatbotTextMessage];
};

type UserConversationHistoryParams = {
  onConversationUpdated?(updateType: ConversationUpdateType): void;
  initialChunkSize?: number;
};

const CONVERSATION_CHUNK_SIZE = 20;
const CONVERSATION_CHAT_MODEL_ID = 'default';

export function useConversationHistory({
  onConversationUpdated,
  initialChunkSize,
}: UserConversationHistoryParams): ConversationHistoryOutput {
  const [history, setHistory] = useState<ConversationHistory>({
    isLoading: true,
    hadError: false,
    messages: [],
    hasPrevious: true,
  });
  const [conversationId, setConversationId] = useState<string>();
  const [isSubmittingMessage, setIsSubmittingMessage] =
    useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [isSubmittingError, setIsSubmittingError] = useState<boolean>(false);
  const [featureUnavailable, setFeatureUnavailable] = useState<boolean>(false);
  const [userSessionExpired, setUserSessionExpired] = useState<boolean>(false);
  const accessTokenHeader = useAuthHeader();

  const fetchConversationHistory = useCallback(
    async (params: ConversationQueryParameters) => {
      // Don't refetch history when conversation ID is set initially (Indicated by repeat call with no before param)
      if (!accessTokenHeader || (conversationId && !params.before)) {
        return;
      }

      try {
        setHistory(prevHistory => ({
          ...prevHistory,
          isLoading: true,
        }));

        const response = await getConversationHistory(params, {
          headers: {
            'client-platform': 'web',
            ...accessTokenHeader,
          },
        });

        /**
         * We handle validation based errors here as they are
         * not handled by the fetch function.
         */
        if (response.status === 401) {
          setUserSessionExpired(true);

          return;
        }
        if (
          response.status === 422 &&
          isHTTPValidationError(response.data) &&
          response.data.detail.length > 0
        ) {
          logException(response.data.detail[0].msg);
          setFeatureUnavailable(true);

          return;
        }

        if (
          response.data.conversation_id &&
          conversationId !== response.data.conversation_id
        ) {
          setConversationId(response.data.conversation_id);
        }

        const mapped = response.data.items.map(mapHistoryToMessage);
        setHistory(prevHistory => ({
          ...prevHistory,
          messages: [...mapped, ...prevHistory.messages],
          isLoading: false,
          hadError: false,
          hasPrevious: response.data.hasPrevious,
          startCursor: response.data.startCursor,
        }));
      } catch (ex) {
        logException(ex);
        setHistory(prevHistory => ({
          ...prevHistory,
          isLoading: false,
          hadError: true,
        }));
      }
    },
    [accessTokenHeader, conversationId],
  );

  const handleStreamingChunk = (chunk: ConversationStreamingResponse) => {
    if (!chunk.content.value || !chunk.id) {
      return;
    }
    const mappedChunk: Message = {
      id: chunk.id,
      source: chunk.content.value,
      role: ChatbotMessageRole.assistant,
      createdAt: new Date().toISOString(),
    };

    // Append chunk to the last message in state or create a new message in state
    setHistory(prevHistory => {
      const lastMessageIndex = prevHistory.messages.length - 1;
      const updatedMessages = [...prevHistory.messages];
      const lastMessage = updatedMessages[lastMessageIndex];

      if (
        lastMessage &&
        lastMessage.role === ChatbotMessageRole.assistant &&
        lastMessage.id === mappedChunk.id
      ) {
        lastMessage.source += mappedChunk.source;

        return {
          ...prevHistory,
          messages: updatedMessages,
        };
      } else {
        return {
          ...prevHistory,
          messages: [...prevHistory.messages, mappedChunk],
        };
      }
    });
  };

  const submitChatbotMessage = async (
    message: Message,
    params: GenerateResponseConversationPostParams,
  ) => {
    try {
      setIsSubmittingMessage(true);

      const conversationInput: ConversationInput = {
        message: mapMessageToInput(message),
        metadata: {
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        },
      };

      const response = await fetch(
        `${REACT_APP_CHAT_API_URL}/conversation?chat_model_id=${params.chat_model_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'client-platform': 'web',
            ...accessTokenHeader,
          },
          body: JSON.stringify(conversationInput),
        },
      );

      if (!response.body) {
        throw new Error('No response body');
      }

      setIsStreaming(true);

      const reader = response.body.getReader();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value && value.byteLength > 0) {
          const chunk = textDecoder(value);
          // Process the chunk
          const decodedChunks = chunk.split('\n');
          for (const decodedChunk of decodedChunks) {
            if (chunk && decodedChunk.trim().length > 0) {
              const chunkMessage: ConversationStreamingResponse =
                JSON.parse(decodedChunk);

              /**
               * We handle validation based errors here as they are
               * not handled by the fetch function. These errors still
               * come via stream so need to be decoded and handled.
               */
              if (response.status === 401) {
                setIsSubmittingMessage(false);
                setUserSessionExpired(true);

                return;
              }
              if (
                response.status === 422 &&
                isHTTPValidationError(chunkMessage) &&
                chunkMessage.detail.length > 0
              ) {
                setIsSubmittingMessage(false);
                throw chunkMessage.detail[0].msg;
              } else {
                setIsSubmittingMessage(false);
                handleStreamingChunk(chunkMessage);
              }
            }
          }
        }
      }
      // Once stream is complete set isStreaming to false
      setIsStreaming(false);
    } catch (ex) {
      logException(ex);
      // If stream has errored set isStreaming to false
      setIsStreaming(false);
      // Remove loading animation
      setIsSubmittingMessage(false);
      // Set error state to true to show error message
      setIsSubmittingError(true);
      // Update the scroll view upon each new chunk of message added to the state
      onConversationUpdated?.('NEW_MESSAGE');
    }
  };

  useEffect(() => {
    void fetchConversationHistory({
      amount: initialChunkSize || CONVERSATION_CHUNK_SIZE,
    });
  }, [fetchConversationHistory, initialChunkSize]);

  const submitMessage: ConversationHistoryOutput['submitMessage'] =
    async message => {
      onConversationUpdated?.('NEW_MESSAGE');
      const newMessage: Message = { ...message, role: ChatbotMessageRole.user };

      /**
       * There is a server side event which better tracks user messages
       * sent to the bot. This is an extra client side event so data can
       * be easily surfaced through amplitude.
       */
      tracking.track('ai-coach-chatbot-message-sent', {
        source: 'ai-coach-chatbot-ui',
        conversationId,
      });

      setHistory(previous => ({
        ...previous,
        messages: [...previous.messages, newMessage],
      }));

      await submitChatbotMessage(newMessage, {
        chat_model_id: CONVERSATION_CHAT_MODEL_ID,
      });
    };

  const resubmitMessages: ConversationHistoryOutput['resubmitMessages'] =
    async () => {
      const latestMessage = history.messages[history.messages.length - 1];
      if (latestMessage.role !== ChatbotMessageRole.user) {
        logException(
          new Error(
            `Latest message is not a user message. Conversation ID: ${conversationId}`,
          ),
        );
      }

      setIsSubmittingError(false);

      await submitChatbotMessage(latestMessage, {
        chat_model_id: CONVERSATION_CHAT_MODEL_ID,
      });
    };

  const loadMoreMessages: ConversationHistoryOutput['loadMoreMessages'] =
    async () => {
      if (!history.hasPrevious || !history.startCursor || history.isLoading) {
        return;
      }

      onConversationUpdated?.('LOAD_MORE');
      await fetchConversationHistory({
        amount: CONVERSATION_CHUNK_SIZE,
        before: history.startCursor,
      });
    };

  const retryLoadingHistory: ConversationHistoryOutput['retryLoadingHistory'] =
    async () => {
      if (history.messages.length === 0) {
        onConversationUpdated?.('LOAD_MORE');
      }

      await fetchConversationHistory({
        amount: CONVERSATION_CHUNK_SIZE,
        before: history.startCursor,
      });
    };

  return {
    messages: history.messages,
    isLoadingHistory: history.isLoading,
    isSubmittingMessage: isSubmittingMessage,
    isStreamingMessage: isStreaming,
    hadErrorSubmittingMessage: isSubmittingError,
    hadErrorLoadingHistory: mapHistoryErrorType(
      history.hadError,
      featureUnavailable,
      userSessionExpired,
    ),
    submitMessage,
    resubmitMessages,
    loadMoreMessages,
    retryLoadingHistory,
  };
}
