import isNil from 'lodash/isNil';
import { rem } from 'polished';
import React from 'react';
import {
  CustomTypeOptions,
  useTranslation,
  withTranslation,
} from 'react-i18next';
import { css, styled } from 'styles';

import { small } from '../../utils';
import { isNonEmptyString } from '../Form/inputValidators';
import { matchError, ValidatorMatch } from './ValidatorMatch';

const PasswordValidators = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type PasswordValidationTranslationOptions =
  CustomTypeOptions['resources']['password_validation'];

type ValidTranslationKey = keyof PasswordValidationTranslationOptions;

export interface PasswordValidation {
  length?: boolean;
  specialchar?: boolean;
  numbers?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  [key: string]: boolean | undefined;
}

export interface PasswordValidatorProps {
  className?: string;
}

export const getValidationResult = (password?: string): PasswordValidation => {
  if (isNil(password) || password === '') {
    return {
      length: undefined,
      specialchar: undefined,
      numbers: undefined,
      uppercase: undefined,
      lowercase: undefined,
    };
  }

  return {
    length: password.length >= 8 && password.length <= 24,
    specialchar: /[^A-Za-z0-9]/.test(password),
    numbers: /[0-9]/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
  };
};

export const isPasswordValid = (result: string) => {
  const validationResult = getValidationResult(result);

  return (
    isNonEmptyString(result) && !Object.values(validationResult).includes(false)
  );
};

const PasswordValidatorMatch = styled(ValidatorMatch)`
  margin-right: ${rem('15px')};
  margin-bottom: ${rem('10px')};

  ${small(css`
    margin-right: ${rem('40px')};
  `)}
`;

export const PasswordValidator = (
  props: PasswordValidation & PasswordValidatorProps,
) => {
  const { t: translate } = useTranslation('password_validation');

  const validatorLabel = (key: string): string => {
    let labelTranslationKey: ValidTranslationKey | '';

    switch (key) {
      case 'length':
        labelTranslationKey = 'characters';
        break;
      case 'numbers':
        labelTranslationKey = 'numbers';
        break;
      case 'lowercase':
        labelTranslationKey = 'lowercase';
        break;
      case 'uppercase':
        labelTranslationKey = 'uppercase';
        break;
      case 'specialchar':
        labelTranslationKey = 'specialchar';
        break;
      default:
        labelTranslationKey = '';
    }

    return labelTranslationKey === ''
      ? labelTranslationKey
      : translate(labelTranslationKey);
  };

  return (
    <PasswordValidators className={props.className}>
      {Object.keys(props).map(key => (
        <PasswordValidatorMatch
          key={key}
          matchValue={matchError(props[key] as boolean).matchValue}
        >
          {validatorLabel(key)}
        </PasswordValidatorMatch>
      ))}
    </PasswordValidators>
  );
};

export default withTranslation('password_validation')(PasswordValidator);
