import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { rem } from 'polished';
import { IconType } from 'icons';
import styled, { css } from 'styled-components';
import RoutePath from '../../App/RoutePath';
import DynamicIcon from '../../Shared/DynamicIcon/DynamicIcon';
import { extraSmall, small } from '../../utils';
import { BodyText } from '../../Shared/Typography';
import { tracking } from '../../App/Tracking';
import { ContentType, ExploreCategory } from '../types';

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%
  max-width: 220;
  border-radius: 8px;
  padding: 12px 8px;
  border:  ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};

  ${small(css`
    padding: 20px 16px;
  `)}

  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;

  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const StyledText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.primary,
}))`
  flex-shrink: 1;

  ${small(css`
    font-size: 18px;
  `)}
`;

const CardIcon = styled(DynamicIcon)`
  aspect-ratio: 1;
  width: ${rem(22)};
  height: ${rem(22)};

  ${extraSmall(css`
    width: ${rem(28)};
    height: ${rem(28)};
  `)}
`;

interface CategoryButtonProps {
  category: ExploreCategory;
  accessibilityLabel?: string;
  Icon?: IconType;
  children?: ReactNode;
}

const CategoryButton = ({ category, Icon }: CategoryButtonProps) => {
  const { id, title, slug, internalName } = category;
  const path = `${RoutePath.Explore}/${slug}`;

  const trackCategoryClicked = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: null,
      contentType: ContentType.category,
      source: 'explore-category-button',
      contentCategoryPath: internalName,
      isFavourite: null,
    });
  };

  return (
    <StyledLink
      data-testid="top-level-category-button"
      to={{
        pathname: path,
        state: {
          breadcrumbHistory: [
            {
              from: RoutePath.Explore,
            },
          ],
        },
      }}
      onClick={trackCategoryClicked}
      aria-label={title as string}
    >
      <Inner>
        <StyledText>{title}</StyledText>
        {Icon && <CardIcon Icon={Icon} />}
      </Inner>
    </StyledLink>
  );
};

export default CategoryButton;
